/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import enums from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';

@makeBooleanProperties('STATUS_TYPE')
export default class OrganizationPromotionUserStatusTypeSummary extends BaseModel {
  @attr('number') statusTypeId;
  @attr('number') userCount;

  @enums.computed('name', 'statusTypeId', 'statusTypeId', 'STATUS_TYPE') statusType;

  @computed('statusType', 'isInActive', 'isPending')
  get name() {
    if (this.isPending) {
      return 'Pending';
    } else if (this.isInActive) {
      return 'Disqualified';
    }
    return 'Active';
  }
}
