/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';

export default Component.extend({
  //region Attributes
  /**
   * @property {Boolean}
   */
  'hide-loading-spinner': false,
  /**
   * @property {Boolean}
   */
  isLoading: false,
  /**
   * @property {Boolean}
   */
  administer: false,
  /**
   * @property {String}
   */
  tileHeader: '',
  /**
   * @property {Object}
   */
  emptyMessage: null,
  /**
   * Must be the string name of an Ember Route for {{link-to}}
   * @type {String}
   */
  archivedPage: '',
  /**
   * @property {Object}
   */
  paging: null,
  setMessageStatus() {},
  showCalendar() {},
  //endregion

  //region Properties
  page: null,
  checklistSteps: null,
  singleMessageCampaign: null,
  /**
   * Determines if `Copy to...` shows up in the campain overflow
   * @type {Boolean}
   */
  showCopyToOrganizations: false,
  //endregion

  showPaging: computed('paging.{pageSize,totalRecords}', function () {
    return get(this, 'paging.pageSize') < get(this, 'paging.totalRecords');
  }),

  showFooter: computed('messageCampaigns.length', 'showPaging', 'showArchiveLink', function () {
    return this.showArchiveLink || (get(this, 'messageCampaigns.length') > 0 && this.showPaging);
  }),

  messageCampaignsWithOverflow: computed(() => [
    'SingleEmail',
    'DripCampaign',
    'Birthday',
    'Newsletter',
    'WeddingAnniversary',
    'Reminder',
  ]),
  //endregion
  //endregion

  //region Actions
  actions: {
    setMessageStatus(message, status) {
      this.setMessageStatus(message, status);
    },
    showCalendar() {
      this.showCalendar();
    },
    cancelCopyToOrganizations() {
      set(this, 'showCopyToOrganizations', false);
    },
  },
  //endregion
});
