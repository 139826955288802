/* eslint-disable  ember/require-tagless-components, ember/no-classic-components, ember/no-computed-properties-in-native-classes */
import Component from '@ember/component';
import { computed, get, action } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';

const AUDIENCE_ROUTE = 'organizations.organization.data.audiences.audience';

export default class AudienceListItemComponent extends Component {
  @service('snackbar') snackbar;
  @service('store') store;
  @service('current') current;

  audience = null;
  onlyHasInteractiveContentOrContests = false;
  hasData = false;
  canCopy = true;

  'is-admin' = false;
  'download-audience-report' = null;
  'merge-optin-audience' = null;
  'change-archived-status' = x => x;

  classNames = [
    'audience-list-item',
    'material-list-tile',
    'with-avatar',
    'multi-line',
    'ssEditable',
    'material-list-tile__centered-icons',
    'group/list',
  ];

  classNameBindings = [
    'audience.isSubscriptionAudience:SubscriptionAudience',
    'audience.isSegmentedAudience:SegmentedAudience',
    'audience.isThirdPartyAudience:SegmentedAudience',
    'audience.isArchived:audience-list-item--archived',
  ];

  @computed('audience.isArchived')
  get statusActionLabel() {
    return get(this, 'audience.isArchived') ? 'Unarchive' : 'Archive';
  }

  get isInheritedAudience() {
    const optinFieldId = get(this, 'audience.optinFieldId');
    const field = this.fields?.findBy('id', optinFieldId?.toString());
    return this.audience?.isSubscriptionAudience && field?.organizationId != +this.current?.organization?.id;
  }

  @computed('onlyHasInteractiveContentOrContests', 'audience.{audienceType,latestCount,isSubscriptionAudience}')
  get audienceLink() {
    const linkIfAudienceWithMembers =
      this.onlyHasInteractiveContentOrContests || !this.hasData
        ? `${AUDIENCE_ROUTE}.members`
        : `${AUDIENCE_ROUTE}.dashboard`;
    const linkIfEmptyAudience = this.audience.isSubscriptionAudience
      ? `${AUDIENCE_ROUTE}.setup`
      : `${AUDIENCE_ROUTE}.filters`;
    return this.audience.latestCount ? linkIfAudienceWithMembers : linkIfEmptyAudience;
  }

  @computed('audience.latestCountDate')
  get disableRecalculateButton() {
    return moment(this.audience.latestCountDate).add(1, 'h').toDate() > new Date();
  }

  @action
  closeDropdown(menu) {
    menu.actions?.close();
  }

  toastMessageTime(time) {
    const timeInSeconds = Math.round(time / 1000);

    if (timeInSeconds >= 1 && timeInSeconds <= 10) {
      return 'a few seconds';
    } else if (timeInSeconds >= 11 && timeInSeconds <= 59) {
      return 'under a minute';
    } else if (timeInSeconds >= 60 && timeInSeconds <= 180) {
      return 'a few minutes';
    }

    return 'a little while. Check back later.';
  }

  recalculateTask = task({ drop: true }, async () => {
    const response = await this.store.queryRecord('audience', {
      id: this.audience.id,
      forceUpdateCount: true,
    });

    if (response.estimatedCalculationTime <= 0) return;

    const message = this.toastMessageTime(response.estimatedCalculationTime);
    this.snackbar.show(
      `Recalculation will continue even if you leave the page. The calculation should be done in ${message}`,
      null,
      7000
    );

    await timeout(response.estimatedCalculationTime);
    await this.store.queryRecord('audience', { id: this.audience.id, BypassCache: true });
  });
}
