/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  //region Ember Hooks
  classNames: ['attribute-editor__attribute'],
  tagName: 'tr',
  //endregion

  //region Attributes
  label: '',
  'attr-name': '',
  'component-attributes': null,
  'component-id': null,
  'article-category': null,
  'attr-type': null,
  'set-attribute'() {},
  //endregion

  //region Computed Properties
  value: computed('component-attributes.@each.{value,defaultValue}', 'attr-name', function () {
    const componentAttrs = this['component-attributes'];
    const attrName = this['attr-name'];
    return componentAttrs[attrName].value || componentAttrs[attrName].defaultValue;
  }),
  //endregion

  //region Attributes
  actions: {
    setAttribute(value) {
      this['set-attribute'](this['attr-name'], value);
    },
  },
  //endregion
});
