import { setProperties } from '@ember/object';
import flatten from 'lodash/flatten';
import { POWER_SEED_PAIRING_MAP, POWERS } from 'partner/utils/bracket/constants';
import { Iteration, Matchup } from 'partner/utils/bracket/matchup-constructors';

/**
 * Switching out a matchup entry's belongsTo matchup will not dirty the matchup entry
 * This will be used to tell us which matchup entries we actually need to save
 * @param {Object} matchupEntry
 * @param {Object} matchup
 */
const setDirtyState = (matchupEntry, matchup) => {
  if (matchupEntry.matchup !== matchup) {
    matchupEntry.toggleDirtyMatchupState(true);
  }
};
/**
 * @param {Array} existingMatchups
 */
const createBracketMatchups = existingMatchups => {
  const result = [];

  let numberOfMatchupsToCreate = existingMatchups.length + 1;
  let displayOrder = numberOfMatchupsToCreate;
  let iteration = 1;

  while (numberOfMatchupsToCreate >= 1) {
    const matchupForThisIteration = existingMatchups.findBy('iteration', iteration);
    const matchupForPreviousIteration = existingMatchups.findBy('iteration', iteration - 1);
    const datesForIteration = matchupForThisIteration
      ? Iteration.getDatesFor(matchupForThisIteration)
      : matchupForPreviousIteration
      ? Iteration.createDatesFrom(matchupForPreviousIteration)
      : Iteration.createNewDates();

    const currentIteration = new Iteration({ iteration, ...datesForIteration });

    for (let j = 0; j < numberOfMatchupsToCreate / 2; j++) {
      result.push(new Matchup(displayOrder, currentIteration));
      displayOrder--;
    }

    numberOfMatchupsToCreate = numberOfMatchupsToCreate / 2;
    iteration++;
  }

  return result;
};
/**
 * @param {Array} existingMatchups
 */
const removeBracketMatchups = existingMatchups => {
  const result = [];

  let numberOfMatchupsToRemove = (existingMatchups.length + 1) / 2;
  let iteration = 1;

  while (numberOfMatchupsToRemove >= 1) {
    const sortedMatchups = existingMatchups.filterBy('iteration', iteration).sortBy('displayOrder');
    const matchupsToRemoveFromIteration = sortedMatchups.slice(Math.floor(sortedMatchups.length / 2));
    result.push(...matchupsToRemoveFromIteration);

    numberOfMatchupsToRemove = numberOfMatchupsToRemove / 2;
    iteration++;
  }

  return result;
};
/**
 * @param {Array} matchupEntries
 * @param {Array} matchups
 */
const distributeSeededEntries = (matchupEntries, matchups) => {
  const seedPairs = POWER_SEED_PAIRING_MAP[POWERS.find(power => power >= matchupEntries.length)];
  return flatten(
    seedPairs.map((seedPair, pairIndex) =>
      seedPair
        .map((seed, seedIndex) => {
          const matchupEntry = matchupEntries.findBy('seedValue', seed);
          if (matchupEntry) {
            const matchup = matchups.findBy('displayOrder', pairIndex + 1);
            setDirtyState(matchupEntry, matchup);
            setProperties(matchupEntry, {
              matchup,
              displayOrder: seedIndex + 1,
              displayColumn: seedIndex + 1,
            });
            return matchupEntry;
          }
          return null;
        })
        .filter(Boolean)
    )
  );
};

export { createBracketMatchups, distributeSeededEntries, removeBracketMatchups };
