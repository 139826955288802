/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { EMAIL_DYNAMIC_TOKENS } from 'partner/utils/constants';
import RSVP from 'rsvp';

const SETUP_EMAIL_ROUTE = 'organizations.organization.data.audiences.audience.setup.email';

export default Controller.extend({
  //region Ember Hooks
  queryParams: ['messageVersionId'],
  snackbar: service(),
  enums: service(),
  organizationController: controller('organizations.organization'),
  audienceController: controller('organizations.organization.data.audiences.audience'),
  //endregion

  //region Properties
  /**
   * @type {Number}
   */
  messageVersionId: null,
  /**
   * @type {Boolean}
   */
  isAnythingSaving: false,
  /**
   * @type {Boolean}
   */
  showWelcomeEmailUpsell: false,
  //endregion

  //region Computed Properties
  filteredMessageVersion: computed('model.messageVersions', 'messageVersionId', function () {
    return get(this, 'model.messageVersions').findBy('id', this.messageVersionId);
  }),

  messageCampaignTypeViews: computed('model.{messageVersions.[],messageCampaign}', function () {
    const messageCampaign = get(this, 'model.messageCampaign');
    const messageVersions = get(this, 'model.messageVersions');
    return [
      {
        messageCampaignViews: [messageCampaign].map(messageCampaign => ({ messageCampaign, messageVersions })),
      },
    ];
  }),

  get audienceEmailDynamicTokens() {
    return this.model.tokens
      .filterBy('dynamicTokenType')
      .filter(token => EMAIL_DYNAMIC_TOKENS.includes(token.dynamicTokenType));
  },
  //endregion

  //region Actions
  actions: {
    async updateChecklistStep() {
      const messageCampaign = get(this, 'model.messageCampaign');
      if (get(messageCampaign, 'isConfirmed')) {
        this.send('checkChecklistStep', SETUP_EMAIL_ROUTE);
      } else {
        this.send('uncheckChecklistStep', SETUP_EMAIL_ROUTE);
      }
    },
    async save(design) {
      set(this, 'isAnythingSaving', true);
      try {
        const dirtyTokenContents = get(design, 'tokenContents').filterBy('hasDirtyAttributes');
        await RSVP.all(dirtyTokenContents.map(x => x.save()));
        if (get(design, 'hasDirtyAttributes')) {
          await design.save();
        }
      } finally {
        set(this, 'isAnythingSaving', false);
      }
    },
    copyMessageVersion() {
      //TODO: creating another welcome email once multiple welcome emails for one audience is implemented
    },
    removeVersion() {
      //TODO: deleting a welcome email when you have multiple welcome emails for one audience
    },
    setActiveVersion() {
      //TODO: changing query param to switch between different messages
    },
  },
  //endregion
});
