import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

/**
 * Dashboard Sweepstakes Route
 * /o/:organization_id/op/:organization_promotion_id/dashboard/sweepstakes
 * @type {Ember.Route}
 */
export default class extends Route {
  @service('session') session;
  @service('store') store;
  @service('enums') enums;

  model() {
    const { organizationPromotion } = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion'
    );
    const { id: organizationPromotionId, hasSyndicates, promotion } = organizationPromotion;
    const promotionId = promotion.id;

    const fieldTypeId = this.enums.findWhere('FIELD_TYPE', {
      name: 'Optin',
    });

    const params = {
      organizationId: this.modelFor('organizations.organization').organization.id,
      organizationPromotionId: organizationPromotion.id,
    };

    const chartParams = {
      organizationId: this.modelFor('organizations.organization').organization.id,
      ...(hasSyndicates ? { promotionId } : { organizationPromotionId }),
    };

    return hash({
      categoricalCharts: this.store.query('categorical-chart', chartParams),
      eventCharts: this.store.query('event-chart', chartParams),
      matchups: this.store
        .query('matchup', {
          ...params,
          excludeSecondaryMatchups: true,
        })
        .then(x => x.sortBy('endDate')),
      optinFields: this.store
        .query('field', {
          ...params,
          fieldTypeId,
          filterByOrganizationPromotion: true,
          IncludeExtraChances: true,
        })
        .then(x => x.filterBy('fieldType', 'Optin')),
      winners: this.store.query('winner', params),
      games: this.store.query('game', params),
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const { matchups } = model;

    if (matchups.length > 0 && matchups.length === 1) {
      controller.selectedRoundId = matchups[0]?.id;
    }

    const matchupRounds = matchups.map(match => {
      const formattedDates = match.shortFormattedDates.replace(' - ', ' to ');
      const matchupRound = {
        id: match.id,
        roundName: `${match.name} - ${formattedDates}`,
      };
      return matchupRound;
    });
    controller.matchupRounds = matchupRounds;
  }
}
