import Service, { inject as service } from '@ember/service';
import type CurrentService from './current';
import moment from 'moment';

type paramsType = {
  full?: boolean;
  withTime?: boolean;
  withDay?: boolean;
};

export default class DefaultFormatService extends Service {
  @service declare current: CurrentService;

  private _locale = 'en-US';

  constructor(...args: any) {
    super(...args);
    this.setDateFormat();
  }

  get locale() {
    return this._locale;
  }

  private setDateFormat() {
    try {
      const country = this.current.organization?.location?.countryName
        ? this.current.organization.location.countryName
        : 'United States of America';

      this._locale = !['Canada', 'United States of America'].includes(country) ? 'en-GB' : 'en-US';
    } catch (e) {
      console.error('unable to find default country for this org', e);
    } finally {
      moment.locale(this._locale);
      moment.defaultFormat = 'L';
    }
  }

  resetDateFormat() {
    this.setDateFormat();
  }

  getDateFormat(hash?: paramsType) {
    let defaultDateFormat = moment.localeData().longDateFormat('L');

    if (hash?.full) {
      defaultDateFormat = `dddd, ${defaultDateFormat} [at] h:mma`;
    } else {
      if (hash?.withDay) {
        defaultDateFormat = `dddd, ${defaultDateFormat}`;
      }
      if (hash?.withTime) {
        defaultDateFormat = `${defaultDateFormat} [at] h:mma`;
      }
    }

    return defaultDateFormat;
  }
}
