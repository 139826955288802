/* eslint-disable ember/no-on-calls-in-components, ember/no-mixins, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components*/
import Component from '@ember/component';
import { observer, set } from '@ember/object';
import { on } from '@ember/object/evented';
import PermittedComponent from 'partner/mixins/permitted-component';

export default Component.extend(PermittedComponent, {
  //region Ember Hooks
  classNames: ['ssRadio'],
  tagName: 'input',
  attributeBindings: ['type', 'checked:checked', 'disabled', 'tabindex', 'name', 'autofocus', 'form'],
  //endregion

  //region Properties
  type: 'radio',
  checked: false,
  //endregion

  //region Events
  click() {
    set(this, 'selection', this.value);
  },
  //endregion

  //region Observers
  onDataChange: on(
    'init',
    observer('selection', 'value', function () {
      set(this, 'checked', this.selection === this.value);
    })
  ),
  //endregion
});
