import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"ssTile\" ...attributes>\n  <div class=\"pt-4 mx-6 mb-3 flex justify-between\">\n    <span class=\"text-lg font-bold\">\n      {{@title}}\n    </span>\n    <button type=\"button\" class=\"icon-button icon-button--square\" {{on \"click\" (toggle \"isExpanded\" this)}}>\n      <Ui::Icon @name={{if this.isExpanded \"expand_more\" \"expand_less\"}} />\n    </button>\n  </div>\n  <div\n    class=\"ssTileContent max-h-[1000px] !pt-0 transition-all duration-300 overflow-hidden\n      {{unless this.isExpanded '!max-h-0 !py-0 !my-0'}}\"\n  >\n    {{yield}}\n  </div>\n</div>\n", {"contents":"<div class=\"ssTile\" ...attributes>\n  <div class=\"pt-4 mx-6 mb-3 flex justify-between\">\n    <span class=\"text-lg font-bold\">\n      {{@title}}\n    </span>\n    <button type=\"button\" class=\"icon-button icon-button--square\" {{on \"click\" (toggle \"isExpanded\" this)}}>\n      <Ui::Icon @name={{if this.isExpanded \"expand_more\" \"expand_less\"}} />\n    </button>\n  </div>\n  <div\n    class=\"ssTileContent max-h-[1000px] !pt-0 transition-all duration-300 overflow-hidden\n      {{unless this.isExpanded '!max-h-0 !py-0 !my-0'}}\"\n  >\n    {{yield}}\n  </div>\n</div>\n","moduleName":"partner/components/ui/collapsible-card.hbs","parseOptions":{"srcName":"partner/components/ui/collapsible-card.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface CollapsibleCardSignature {
  Element: HTMLDivElement;
  Args: {
    title: string;
    expanded: boolean;
  };
}

export default class CollapsibleCardComponent extends Component<CollapsibleCardSignature> {
  @tracked isExpanded = this.args.expanded || false;
}
