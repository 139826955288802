/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Route from '@ember/routing/route';
import AppContextRoute from 'partner/mixins/app-context-route';
import SecondStreetAdminRouteMixin from 'partner/mixins/second-street-admin-route';

export default Route.extend(AppContextRoute, SecondStreetAdminRouteMixin, {
  model() {
    const { organizationSalesPackages } = this.modelFor('organizations.organization');
    return organizationSalesPackages;
  },
});
