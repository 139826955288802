import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

/**
 * Dashboard Quiz Route
 * /o/:organization_id/op/:organization_promotion_id/dashboard/quiz
 * @type {Ember.Route}
 */
export default class extends Route {
  @service('session') session;
  @service('store') store;
  @service('enums') enums;

  model() {
    const { organizationPromotion } = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion'
    );
    const params = {
      organizationId: this.modelFor('organizations.organization').organization.id,
      organizationPromotionId: organizationPromotion.id,
    };
    const fieldTypeId = this.enums.findWhere('FIELD_TYPE', { name: 'Optin' });

    return RSVP.hash({
      promotion: organizationPromotion.promotion,
      sweepstakes: this.store.queryRecord('sweepstakes', params),
      categoricalCharts: this.store.query('categoricalChart', params),
      eventCharts: this.store.query('eventChart', params),
      registrationTypeSetting: this.store.queryRecord('setting', { key: 'Registration_Type', ...params }),
      outcomes: this.store.query('outcome', params),
      winners: this.store.query('winner', params),
      matchups: this.store
        .query('matchup', {
          ...params,
          excludeSecondaryMatchups: true,
        })
        .then(x => x.sortBy('endDate')),
      optinFields: this.store
        .query('field', {
          ...params,
          fieldTypeId,
          filterByOrganizationPromotion: true,
          IncludeExtraChances: true,
        })
        .then(x => x.filterBy('fieldType', 'Optin')),
    }).then(hash => {
      if (hash.sweepstakes?.isEnabled) {
        hash.matchupEntries = this.store.query('matchupEntry', {
          ...params,
          pageSize: 1,
          matchupId: get(hash, 'sweepstakes.matchupId'),
        });

        hash.matchupEntryRanks = this.store.query('matchupEntryRank', params);
        hash.sweepstakesWinners = this.store.query('winner', { matchupId: hash.sweepstakes.matchupId });
      }

      return RSVP.hash(hash);
    });
  }
}
