/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { computed, get } from '@ember/object';
import { alias, equal, mapBy, not, or, readOnly } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { isPresent } from '@ember/utils';
import DS from 'ember-data';
import { containsAny } from 'partner/utils/computed';
import dirtyProperty from 'partner/utils/dirty-property';

const { attr, belongsTo } = DS;

export default Mixin.create({
  //region Attributes
  body: attr('string'),
  fromName: attr('string'),
  replyTo: attr('string'),
  subject: attr('string'),
  plainTextBody: attr('string'),
  //endregion

  //region Relationships
  message: belongsTo('message', { async: true }),
  messageBodyTemplate: belongsTo('messageBodyTemplate', { async: true }),
  senderAccount: belongsTo('senderAccount', { async: true }),
  //endregion

  //region Computed Properties
  preheaderTokenContent: computed('tokenContents.{[],@each.tokenCategory}', function () {
    return (this.tokenContents || []).findBy('tokenCategory', 'From & Subject');
  }),

  tokenContents: alias('messageVersionTemplateTokenContents'),

  renderedContent: alias('body'),

  template: alias('messageBodyTemplate'),

  messageBodyTemplateTokens: readOnly('template.tokens'),

  isTemplate: equal('bodySourceType', 'Template'),

  isCustom: equal('bodySourceType', 'Custom'),

  isScrapeUrl: equal('bodySourceType', 'Scrape Url'),

  isSubjectDirty: dirtyProperty('subject'),
  isFromNameDirty: dirtyProperty('fromName'),
  isReplyToDirty: dirtyProperty('replyTo'),
  isBodyDirty: dirtyProperty('body'),
  isPlainTextBodyDirty: dirtyProperty('plainTextBody'),

  errorAttributes: mapBy('errors', 'attribute'),

  fromError: containsAny('errorAttributes', 'sender_account_id', 'fromName', 'replyTo'),
  subjectError: containsAny('errorAttributes', 'subject'),
  bodyHtmlError: containsAny('errorAttributes', 'body'),
  plainTextError: containsAny('errorAttributes', 'plainTextBody'),

  isFromComplete: computed('fromName', 'replyTo', function () {
    return isPresent(this.fromName) && isPresent(this.replyTo);
  }),

  showPreheader: computed('isTemplate', 'template', function () {
    return this.isTemplate && get(this, 'template.tokens').findBy('key', 'preheaderText');
  }),

  isSubjectComplete: computed('showPreheader', 'subject', 'preheaderTokenContent.isComplete', function () {
    const preheaderIsComplete = get(this, 'preheaderTokenContent.isComplete');
    const subjectChanged = isPresent(this.subject) && this.subject !== 'Subject Line Text';
    return this.showPreheader ? subjectChanged && preheaderIsComplete : subjectChanged;
  }),

  isSubjectOrPreheaderDirty: or('isSubjectDirty', 'preheaderTokenContent.hasDirtyAttributes'),

  preheaderTextError: computed('preheaderTokenContent', 'preheaderTokenContent.isValid', function () {
    return this.preheaderTokenContent && !get(this, 'preheaderTokenContent.isValid');
  }),

  preheaderIsValid: not('preheaderTextError'),

  bodyHtmlIsValid: not('bodyHtmlError'),

  plainTextIsValid: not('plainTextError'),

  isFromAndSubjectDirty: or(
    'isFromNameDirty',
    'isReplyToDirty',
    'isSubjectDirty',
    'preheaderTokenContent.hasDirtyAttributes'
  ),

  isFromDirty: or('isReplyToDirty', 'isFromNameDirty'),
  //endregion
});
