/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { set } from '@ember/object';
import { equal, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  //region Dependencies
  notifications: service(),
  //endregion

  init() {
    this._super(...arguments);
    this.showFilters =
      this.params.searchValue || this.params.sortColumn || this.params.sortDirection || this.params.promotionSubTypeId;
  },

  //region Attributes
  'is-setting-status': false,
  //endregion

  //region Computed Properties
  hasEngagement: or('organization.{hasVotersChoice,hasQuiz,hasPickem,hasSurvey}'),

  hasContesting: or('organization.{hasPickem,hasSweepstakes,hasUGCVoting,hasUGCSweepstakes}'),

  isEvents: equal('promotionTypeCategory', 'event'),
  isEcommerce: equal('promotionTypeCategory', 'ecommerce'),
  isInteractiveContent: equal('promotionTypeCategory', 'interactiveContent'),
  //endreigon

  actions: {
    showCalendar() {
      set(this, 'isCalendarVisible', true);
    },
    hideCalendar() {
      set(this, 'isCalendarVisible', false);
    },
  },
});
