import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';
import type MatchupModel from './matchup';
import type VotingEntryModel from './voting-entry';

export default class NominationEntriesSummaryModel extends BaseModel {
  @attr('number') declare count: number;
  @attr('number') declare mediaItemId: number;
  @attr('number') declare locationId: number;
  @attr('string') declare name: string;
  @attr('string') declare description: string;
  @attr('string') declare fullAddress: string;

  @belongsTo('matchup', { async: true }) declare matchup: AsyncBelongsTo<MatchupModel>;
  @belongsTo('voting-entry', { async: true }) declare votingEntry: AsyncBelongsTo<VotingEntryModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'nomination-entries-summary': NominationEntriesSummaryModel;
  }
}
