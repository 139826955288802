/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { cancel, later } from '@ember/runloop';

const FAILED_VIDEO_POLLING_DELAY_SECONDS = 10;

export default Component.extend({
  //region Ember Hooks
  attributeBindings: ['width', 'height', 'src'],
  willDestroyElement() {
    this._super(...arguments);
    cancel(this.tryAgainTimer);
    const video = this.element.querySelector('video');
    video.removeEventListener('loadeddata', this.onVideoLoadedData);
  },
  didInsertElement() {
    this._super(...arguments);
    this.onVideoLoadedData = () => {
      set(this, 'videoReady', true);
    };
    this.attemptVideo();
  },
  //endregion

  //region Attributes
  'dips-url': null,
  'media-item-id': null,
  width: computed('height', {
    get() {
      if (this._width) {
        return this._width;
      }

      return this.height ? null : 300;
    },

    set(_key, value) {
      return (this._width = value);
    },
  }),
  height: null,
  /**
   * Pass in string "autoplay" in handlebars if you would like the video to auto-play on page load
   */
  'auto-play': '',
  //endregion

  //region Properties
  /**
   * How many times has the video failed to load?
   * @private
   * @type {Number}
   */
  attempts: 0,
  videoReady: false,
  //endregion

  //region Computed Properties
  src: computed('dips-url', 'media-item-id', 'width', 'height', function () {
    const dipsUrl = this['dips-url'];
    const mediaItemId = this['media-item-id'];
    const { width } = this;
    const { height } = this;
    const queryString = [width ? `width=${width}` : null, height ? `height=${height}` : null].compact().join('&');
    if (dipsUrl && mediaItemId) {
      return `//${dipsUrl}/videos/${mediaItemId}?${queryString}`;
    }
    return;
  }),
  /**
   * The image URL representing the initial frame displayed for the video before clicking play
   * We incorporate "attempts" because a video tag's poster image has no .load() message like the video file itself does.
   * Bumping the "attempts" query paraming makes sure we're periodically refreshing the poster image.
   */
  poster: computed('dips-url', 'media-item-id', 'width', 'attempts', function () {
    const { width } = this;
    const queryString = width ? `width=${width}` : null;
    return `//${this['dips-url']}/${this['media-item-id']}?${queryString}&attempts=${this.attempts}`;
  }),
  //endregion

  //region Methods
  attemptVideo() {
    if (!this.videoReady) {
      const video = this.element.querySelector('video');
      // the loadeddata event on a video tag is fired when the data for the current frame is loaded. This is a way for us
      // to know there have not been any errors and the video is ready to be played.
      video.addEventListener('loadeddata', this.onVideoLoadedData, { once: true });
      video.load();
      this.incrementProperty('attempts');
      const timer = later(this, this.attemptVideo.bind(this), FAILED_VIDEO_POLLING_DELAY_SECONDS * 1000);
      set(this, 'tryAgainTimer', timer);
    } else {
      return false;
    }
  },
  //endregion
});
