/* eslint-disable ember/no-mixins, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import { on } from '@ember/object/evented';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

/**
 * OrganizationPromotion Route
 * No URL - anything under /o/:organization_id/op/:organization_promotion_id
 * @type {Ember.Route}
 * @mixes AppContextRoute
 */
export default Route.extend(AppContextRoute, {
  //region Dependencies
  appContext: service('app-context'),
  checklist: service(),
  current: service(),
  enums: service(),
  features: service(),
  navigation: service(),
  reports: service(),
  session: service(),
  settings: service(),
  snackbar: service(),
  store: service(),
  adMetrics: service('ad-metrics'),

  /**
   * We may not have the full model from the server yet. Reload it to be sure.
   * @see http://emberjs.com/api/classes/Ember.Route.html#method_model
   * @returns {Promise<OrganizationPromotionModel>}
   */
  async model(params) {
    const organizationPromotionId = params.organization_promotion_id;

    const organizationPromotion = await this.store.findRecord('organization-promotion', organizationPromotionId, {
      reload: true,
    });
    await this.store.query('promotion', { organizationPromotionId, promotionId: organizationPromotion.promotion.id });

    // Set custom headers for scoping all the requests to this promotion
    this.session.addCustomHeaders({
      'X-Organization-Promotion-Id': organizationPromotion.id,
      'X-Promotion-Id': organizationPromotion.promotion.id,
    });
    this.checklist.set('promotionTypeId', organizationPromotion.promotion.id);

    // Fetch partner users so we can display the no access message
    const allowedPartnerUsers =
      organizationPromotion.promotion.isBallot && this.features.get('HasAdminPromotionWhitelist')
        ? this.store.query('organization-promotion-partner-user-allowed-list', {}).then(users => users.toArray())
        : [];

    return RSVP.hash({
      allowedPartnerUsers,
      organizationPromotion,
    });
  },

  afterModel(model) {
    const { organizationPromotion } = model;

    // tell the outer navigation wrapper we're in a promo
    this.navigation.setEntity(organizationPromotion);

    // For easier debugging, this IS intended to make it to production.
    console.info(`OrganizationPromotion GUID: ${get(organizationPromotion, 'uniqueId')}`); //eslint-disable-line no-console
    this.current.setOrganizationPromotion(organizationPromotion);
  },

  /**
   * Remove X-Organization-Promotion-Id and X-Promotion-Id headers
   */
  deactivate() {
    this.session.removeCustomHeader('X-Organization-Promotion-Id');
    this.session.removeCustomHeader('X-Promotion-Id');
    this.current.setOrganizationPromotion(null);

    // tell the outer navigation wrapper we're no longer in a promo
    this.navigation.setEntity(null);
  },

  // Causes the app bar to render on activation -- otherwise it takes a long time to render
  notifyOnActivation: on('activate', function () {
    this.appContext.setAndNotify(this.routeName);
    this.send('saveHistory', get(this.modelFor(this.routeName), 'organizationPromotion'));
  }),

  setupController(controller, model) {
    this._super(controller, model);

    // Fetch the taggable entity for displaying the tags icon on the nav bar
    // No need to block the route on this
    void this.fetchTaggableEntity();
  },

  resetController(controller) {
    set(controller, 'isEditingName', false);
    set(controller, 'isViewingReport', false);
  },

  /**
   * @deprecated - Use ReportsService#download instead
   *
   * Download a report.
   * This returns a promise that is resolved when the method is complete.
   * @param {string} reportType - The string value of the associated ReportType enum
   * @param {number} matchupId - The id of the matchup in which the report is being requested for
   * @param {number} [fieldId] - the field id
   */
  async downloadReport(reportType, matchupId, fieldId, dataParams = {}) {
    if (reportType === 'Ads') {
      this.adMetrics.fetchAndDownloadAdMetricsCSV();
      return;
    }

    set(this, `controller.isPreparing${reportType}Report`, true);

    try {
      await this.reports.download(reportType, {
        data: {
          matchup_id: matchupId,
          ...(reportType == 'SubscribedUsers' && fieldId ? { field_id: fieldId } : {}),
          ...dataParams,
        },
      });

      if (reportType === 'MediaDownloadRequests') {
        this.snackbar.show("Compiling your photos... We'll email you when they're ready!", 'OK');
      }
    } finally {
      set(this, `controller.isPreparing${reportType}Report`, false);
    }
  },

  /**
   * Fetch the taggable entity for the organization promotion,
   * so we can display that tags icon on the nav bar
   */
  async fetchTaggableEntity() {
    const entityTypeId = this.enums.findWhere('ENTITY_TYPE', { name: 'OrganizationPromotion' });
    const taggableEntity = await this.store.queryRecord('taggable-entity', {
      tier: 1,
      organizationId: this.modelFor('organizations.organization').organization.id,
      taggableEntityId: `${entityTypeId}-${this.controller.model.organizationPromotion.id}`,
    });

    set(this.controller, 'taggableEntity', taggableEntity);
  },

  actions: {
    /**
     * @deprecated - Use ReportsService#download instead
     */
    downloadSubscribedUsersReport(matchupId, fieldId) {
      return this.downloadReport('SubscribedUsers', matchupId, fieldId);
    },
    /**
     * @deprecated - Use ReportsService#download instead
     */
    downloadRegisteredUsersReport(matchupId) {
      return this.downloadReport('RegisteredUsers', matchupId);
    },
    /**
     * @deprecated - Use ReportsService#download instead
     */
    downloadSweepstakesEntriesReport(matchupId) {
      return this.downloadReport('SweepstakesEntries', matchupId);
    },
    /**
     * @deprecated - Use ReportsService#download instead
     */
    downloadSurveyReport() {
      return this.downloadReport('SurveyResponses');
    },
    /**
     * @deprecated - Use ReportsService#download instead
     */
    downloadWinnersReport(matchupId) {
      return this.downloadReport('Winners', matchupId);
    },
    /**
     * @deprecated - Use ReportsService#download instead
     */
    downloadVotesReport(matchupId) {
      return this.downloadReport('Votes', matchupId);
    },

    /**
     * Download a report for a specified report type
     * @param {string} reportType - The report type as named in the report type enums
     * @param {number} matchupId - The id of the selected matchup for non-optin reports
     * @param {number} fieldId - The id of the selected optin's field id for optin reports
     */
    downloadReport(...args) {
      return this.downloadReport(...args);
    },

    willTransition() {
      set(this, 'controller.preserveScrollPosition', false);
    },
  },
  //endregion
});
