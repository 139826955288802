/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { get, set } from '@ember/object';
import { or } from '@ember/object/computed';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import PermittedComponent from 'partner/mixins/permitted-component';

/**
 * File upload component.
 * @type {Ember.Component}
 * @see http://emberjs.com/guides/components
 * @see Scripts\templates\components\ss-file-upload.hbs
 */
export default Component.extend(PermittedComponent, {
  //region Dependencies
  session: service(),
  uploader: service(),
  //endregion

  //region Ember Hooks
  tagName: 'form',
  classNames: ['ssFileUploadForm'],
  attributeBindings: ['action', 'enctype', 'target', 'method'],
  didInsertElement() {
    this._super(...arguments);
    this.buildAjaxUploader();
  },
  //endregion

  //region Attributes
  'is-uploading-audience': false,
  'error-display'() {},
  /**
   * Change the button text to say Uploading... while uploading.
   * If this is set, it will override the old loading state which showed the loading state outside of the button.
   * @type Boolean
   */
  'show-button-loading-state': null,
  /**
   * Parameter to be sent to the addingFile action(defined by addingFile property).
   * Use this when you are uploading multiple files in a controller and you to know what to do
   * with the mediaItem that comes back from uploading
   */
  addingFileParameter: null,
  enctype: 'multipart/form-data',
  method: 'post',
  isCurrentlyUploading: false,
  accept: 'image/*',
  /**
   * Used to indicate what type of paper entries will be imported. If left null, it is to be assumed that the user is not
   * uploading paper entries.
   * @type String
   */
  'paper-entries': null,
  /**
   * Pass in false if you want to opt out of the ss-file-upload's default button styling
   */
  'is-button': true,
  /**
   * Hides the default "please try again" message if you choose to add your own error messages outside the component
   * @type Boolean
   */
  'has-custom-error-message': false,
  /**
   * The single model to which you would like to "attach" the created {@link MediaItem}
   * @type {DS.Model}
   */
  model: null,
  'is-voting-ballot-import': false,
  'is-nomination-and-voting-ballot-import': false,
  //endregion

  //region Computed Properties
  imagesOnly: equal('accept', 'image/*'),
  videosOnly: equal('accept', 'video/mp4,video/*'),
  isMedia: or('imageOnly', 'videosOnly'),
  //endregion

  //region Methods
  buildAjaxUploader() {
    // const pluralizedJsonItemType = this.isMedia ? 'media_items' : 'import_files';
    let apiEndpoint;
    // eslint-disable-next-line prefer-const
    apiEndpoint = this.uploader.getApiEndpoint(
      this.imagesOnly,
      this.videosOnly,
      this['paper-entries'],
      this['is-voting-ballot-import'],
      this['is-nomination-and-voting-ballot-import']
    );

    const queryParams =
      apiEndpoint === 'audience_import_files' && this['is-uploading-audience']
        ? `?audienceId=${get(this, 'model.id')}`
        : null;

    this.element.onchange = async event => {
      const [file] = event.target.files;
      if (!file) return;

      if (!this.uploader.isValidFile(file, this.accept)) {
        const itemType = this.isMedia ? 'mediaItem' : 'importFile';
        const propertyName = get(this, 'propertyName') || `${itemType}Id`;
        this.addModelError(
          propertyName,
          `Invalid file type. Valid file types include: ${this.uploader.getValidFileTypes(this.accept)}`
        );
        return;
      }

      this.clearModelErrors();
      this.prepareFileUpload();
      const response = await this.uploader.uploadFile(file, apiEndpoint, queryParams);
      if (response.hasErrors) {
        this.completeFailedFileUpload(response.errors);
        if (this['error-display']) {
          this['error-display']();
        }
      } else {
        this.completeSuccessfulFileUpload(response);
      }
    };
  },
  prepareFileUpload() {
    set(this, 'problemUploading', false);
    set(this, 'isCurrentlyUploading', true);
    if (this.uploadingAction) {
      this.uploadingAction();
    }
  },
  completeSuccessfulFileUpload({ responseItem, errors }) {
    if (!isEmpty(errors)) {
      responseItem.forceDirty();

      // TODO: check this
      // becameInvalid(responseItem);

      const fileUploadErrors = get(responseItem, 'errors');
      errors.forEach(err => fileUploadErrors.add(err.key, [err.message]));
    }

    /**
     * 'addingFile' refers to the attribute on the component in the template.
     * Example of usage in .hbs file:  {{#ss-file-upload addingFile="addMediaItem"}}Upload{{/ss-file-upload}}
     * This allows us to use different actions with the same component.
     * For this example when addingFile is "sent" it will in turn trigger the addMediaItem action
     * on the nearest action and then bubble up route chain.
     * @see http://emberjs.com/guides/components/sending-actions-from-components-to-your-application
     */
    if (this.addingFileParameter) {
      this.addingFile(responseItem, this.addingFileParameter);
    } else {
      this.addingFile(responseItem);
    }
    set(this, 'isCurrentlyUploading', false);

    if (this.uploadEndedAction) {
      this.uploadEndedAction();
    }
    /**
     * We are rerendering here to reset the file input, which is unusually complex to do otherwise...
     */
    this.rerender();

    // Reset the form so it can detect if the same file is uploaded twice in a row:
    this.element.reset();

    return responseItem;
  },
  completeFailedFileUpload(errors) {
    if (this.errorsAction) {
      this.errorsAction(errors);
    }
    set(this, 'problemUploading', true);
    set(this, 'isCurrentlyUploading', false);
    if (this.uploadEndedAction) {
      this.uploadEndedAction();
    }

    // Reset the form so it can detect if the same file is uploaded twice in a row:
    this.element.reset();
  },
  clearModelErrors() {
    const { model } = this;
    if (model && get(model, 'errors.length') > 0) {
      /**
       * Manually clearing the errors that were manually added
       */
      get(model, 'errors').clear();
    }
  },
  /**
   * This inserts an error message into the model
   * @param propertyName
   * @param errorMessage
   */
  addModelError(propertyName, errorMessage) {
    const { model } = this;
    this.element.querySelector('input[type="file"]').value = '';

    if (model) {
      model.forceDirty();
      get(model, 'errors').add(propertyName, [errorMessage]);
    }
  },
  //endregion
});
