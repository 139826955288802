import { attr, belongsTo, hasMany } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class FormPageModel extends BaseModel {
  //region Properties
  @attr('string') name;
  @attr('number') pageNumber;
  @attr('number') displayOrder;
  //endregion Properties

  //region Relationships
  @belongsTo('form', { async: false }) form;
  @hasMany('form-field', { async: false }) formFields;
  //endregion

  //region Computed Properties
  get sortedFormFields() {
    return this.formFields.sortBy('displayOrder');
  }

  get additionalFormFields() {
    return this.sortedFormFields.filter(({ isRoot }) => !isRoot);
  }

  get rootFormFields() {
    return this.sortedFormFields.filter(({ isRoot }) => isRoot);
  }
  //endregion
}
