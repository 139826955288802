/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, get } from '@ember/object';
import { alias, gte } from '@ember/object/computed';
import { inflect } from 'partner/helpers/inflect';
import ABTestSummary from 'partner/mixins/ab-test-summary';

export default Controller.extend(ABTestSummary, {
  //region Properties
  isLoadingSchedule: false,
  //endregion

  //region Computed Properties
  messageCampaign: alias('model.messageCampaign'),

  singleMessageCampaign: alias('model.singleMessageCampaign'),
  /**
   * Show Dashboard
   * Do not show the dashboard until the message has been sent to at least one person.
   * @type {Ember.ComputedProperty}
   */
  showDashboard: gte('model.messagingStatistic.peopleSentToCount', 1),

  sentTime: computed(
    'messageCampaign.usesSingleMessageCampaign',
    'singleMessageCampaign.{formattedScheduleStartDate,isScheduled}',
    function () {
      const usesSingleMessageCampaign = get(this, 'messageCampaign.usesSingleMessageCampaign');
      const scheduleStartDate = get(this, 'singleMessageCampaign.formattedScheduleStartDate');
      const isScheduled = get(this, 'singleMessageCampaign.isScheduled');

      return scheduleStartDate && usesSingleMessageCampaign && isScheduled ? ` on ${scheduleStartDate}` : '';
    }
  ),

  abTestOngoing: computed(
    'singleMessageCampaign.{scheduleStartDate,endOfABTestingDate,messageVersions.length}',
    function () {
      const scheduleStartDate = get(this, 'singleMessageCampaign.scheduleStartDate');
      const endOfABTestingDate = get(this, 'singleMessageCampaign.endOfABTestingDate');
      const numberOfMessageVersions = get(this, 'singleMessageCampaign.messageVersions.length');
      const now = new Date();

      return now > scheduleStartDate && now < endOfABTestingDate && numberOfMessageVersions > 1;
    }
  ),

  messageCampaignIsComplete: computed(
    'messageCampaign.isInvite',
    'model.{checklist.isComplete,messages.firstObject.isScheduledSentOrSending}',
    function () {
      const isInvite = get(this, 'messageCampaign.isInvite');
      const checklistSetupIsComplete = get(this, 'model.checklist.isComplete');
      const hasBeenConfirmed = get(this, 'model.messages.firstObject.isScheduledSentOrSending');

      return isInvite ? hasBeenConfirmed : checklistSetupIsComplete;
    }
  ),
  /**
   * abTestMessage
   * The message that will display on the dashboard page during an A/B Test
   */
  abTestMessage: computed(
    'testRecipients',
    'nonABRecipients',
    'formattedScheduleStartDate',
    'formattedEndOfABTestingDate',
    function () {
      const { testRecipients } = this;
      const { nonABRecipients } = this;
      const { formattedScheduleStartDate } = this;
      const { formattedEndOfABTestingDate } = this;

      return `${testRecipients} ${inflect([
        testRecipients,
        'person',
      ])} received the A/B Test on ${formattedScheduleStartDate}. ${nonABRecipients} ${inflect([
        nonABRecipients,
        'person',
      ])} will receive the winning version as soon as the winner is determined or on ${formattedEndOfABTestingDate}.`;
    }
  ),
  /**
   * beforeSendMessage
   * The message that will display on the dashboard page before you've sent a single message.
   */
  beforeSendMessage: computed('sentTime', function () {
    const { sentTime } = this;
    return `Check back here once your message has been sent${sentTime} to see how it's performing.`;
  }),
  //endregion
});
