import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as controller } from '@ember/controller';

export default class OrganizationsOrganizationDataAudiencesAudienceController extends Controller {
  //region Properties
  queryParams = ['isEditingName'];
  isEditingName = false;
  visibleWhen =
    'organizations.organization.data.audiences.audience organizations.organization.data.audiences.audience.setup';
  //endregion

  @controller('organizations.organization.data.audiences')
  audiencesController;

  //region Actions
  @action
  editName() {
    set(this, 'isEditingName', true);
  }

  @action
  stopEditingName() {
    set(this, 'isEditingName', false);
  }
  //endregion
}
