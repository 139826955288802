import ApplicationSerializer from './application';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

type ResourceHash = {
  id: string;
  matchup_entry_id: string;
  matchup_entries: {
    id: string;
    entry_id: string;
    entries: object;
    entry_locations: {
      id: string;
    }[];
  };
  entry_field_values: {
    id: string;
    entry_id: string;
  }[];
  entry_locations: {
    id: string;
  }[];
};

export default class EntrantProfileSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    matchupEntries: { embedded: 'always' },
    entityLinks: { embedded: 'always' },
  };
  normalize(modelClass: any, resourceHash: ResourceHash) {
    const { id } = resourceHash;
    resourceHash['matchup_entry_id'] = `matchup_entry_${id}`;
    resourceHash['matchup_entries'] = {
      id: `matchup_entry_${id}`,
      entry_id: `entry_${id}`,
      entries: {
        id: `entry_${id}`,
        entry_field_values: resourceHash['entry_field_values'].map((element, index) => {
          element['id'] = `entry_field_value_${index}_${id}`;
          element['entry_id'] = `entry_${id}`;
          return element;
        }),
      },
      entry_locations: resourceHash['entry_locations'].map((element, index) => {
        element['id'] = `entry_location_${index}_${id}`;
        return element;
      }),
    };

    return super.normalize(modelClass, resourceHash);
  }
}
