import { isEmpty } from '@ember/utils';

/**
 * Calculates whether the user has the Data product and can interact with our data tools.
 * @param {OrganizationSsProduct[]} organizationSsProducts
 * @returns {boolean}
 */
export const hasDataProduct = organizationSsProducts => {
  if (!isEmpty(organizationSsProducts)) {
    return !isEmpty(organizationSsProducts.findBy('ssProduct.isData', true));
  }

  return false;
};

export default {
  hasDataProduct,
};
