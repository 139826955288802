/* eslint-disable ember/use-ember-get-and-set, ember/no-observers, ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed, observer } from '@ember/object';
import { or } from '@ember/object/computed';
import { on } from '@ember/object/evented';
import { inject as service } from '@ember/service';
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import Copyable from 'partner/mixins/copyable-model';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend(Copyable, {
  //region Ember Hooks
  enums: service(),
  //endregion

  //region Properties
  autoBrandingUrl: DS.attr('string'),
  browserPlatformTypes: DS.attr('arrayFromCommaDelimitedString'),
  wrapperMethodTypeId: DS.attr('number'),
  wrapperHtml: DS.attr('string'),
  wrapperToken: DS.attr('string'),
  //endregion

  //region Relationships
  designVersion: DS.belongsTo('design-version', {
    async: false,
  }),
  //endregion

  //region Observers
  showWrapperConfigChanged: observer('showWrapperConfig', function () {
    const { showWrapperConfig } = this;
    if (!showWrapperConfig) {
      this.set('wrapperMethodTypeId', 0);
    }
  }),
  /**
   * Sets two key properties isForSmallDevices and isForLargeDevices.
   * This was being done via two computed properties, but for some reason it wasn't updating correctly.
   * "Large Devices" groups together Desktop and Tablet browser platform types.
   * @type {Ember.Observer}
   */
  browserPlatformTypesChanged: on(
    'init',
    observer('browserPlatformTypes', function () {
      let forLarge = false;
      let forSmall = false;
      const browserTypeIds = this.browserPlatformTypes;
      if (browserTypeIds) {
        const desktopTypeId = this.enums.findWhere('BROWSER_PLATFORM_TYPE', {
          name: 'Desktop',
        });
        const tabletTypeId = this.enums.findWhere('BROWSER_PLATFORM_TYPE', {
          name: 'Tablets',
        });
        const mobileTypeId = this.enums.findWhere('BROWSER_PLATFORM_TYPE', {
          name: 'Mobile',
        });

        if (browserTypeIds.indexOf(desktopTypeId) >= 0 || browserTypeIds.indexOf(tabletTypeId) >= 0) {
          forLarge = true;
        } else if (browserTypeIds.indexOf(mobileTypeId) >= 0) {
          forSmall = true;
        }
      }
      this.set('isForLargeDevices', forLarge);
      this.set('isForSmallDevices', forSmall);
    })
  ),
  //endregion

  //region Computed Properties
  wrapperMethodType: enums.computed('name', 'wrapperMethodTypeId', 'wrapperMethodTypeId', 'WRAPPER_METHOD_TYPE'),
  showWrapper: or('showWrapperConfig', 'usesWrapper'),
  usesWrapper: computed('wrapperMethodTypeId', function () {
    const { wrapperMethodTypeId } = this;
    const noWrapperId = this.enums.findWhere('WRAPPER_METHOD_TYPE', {
      name: 'No Wrapper',
    });
    return wrapperMethodTypeId !== noWrapperId;
  }),
  usesScrapePage: computed('wrapperMethodTypeId', function () {
    const { wrapperMethodTypeId } = this;
    const wrapperId = this.enums.findWhere('WRAPPER_METHOD_TYPE', {
      name: 'Scrape Page',
    });
    return wrapperMethodTypeId === wrapperId;
  }),
  usesCustomHtml: computed('wrapperMethodTypeId', function () {
    const { wrapperMethodTypeId } = this;
    const wrapperId = this.enums.findWhere('WRAPPER_METHOD_TYPE', {
      name: 'Custom HTML',
    });
    return wrapperMethodTypeId === wrapperId;
  }),
  //endregion
});
