/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default Route.extend({
  //region Ember Hooks
  permissions: service(),
  router: service(),
  snackbar: service(),
  store: service(),
  defaultFormat: service('default-format'),

  model(params) {
    const messageVersionHistoryId = get(params, 'message_version_history_id');
    const approvalDisabled = this.permissions.noAdministerAccess('MessagingMessage');
    if (!approvalDisabled) {
      return this.store
        .createRecord('message-approval', {
          [get(params, 'resend') ? 'resend' : 'isApproved']: true,
          messageVersionHistoryId,
        })
        .save();
    }
  },

  redirect(model) {
    const { snackbar } = this;
    if (!model) {
      snackbar.show('You do not have permission to approve messages.', null, 5000, 'error');
      return;
    }
    const scheduledDate = moment(get(model, 'scheduleInstanceDate'));
    const date = scheduledDate.format(this.defaultFormat.getDateFormat());
    if (get(model, 'isApproved')) {
      const messageCampaignModel = this.modelFor('organizations.organization.message-campaigns.message-campaign');
      const messageCampaign = get(messageCampaignModel, 'messageCampaign');
      const message = get(messageCampaign, 'isNewsletter')
        ? `Your message was approved and will be sent on ${date}.`
        : `Your message that was scheduled for ${date} has been approved and is being sent.`;
      snackbar.show(message);
    } else if (get(model, 'resend')) {
      snackbar.show(
        `Your message that was scheduled for ${date} has been updated and re-sent to your inbox for approval.`
      );
    }
    this.router.replaceWith('organizations.organization.message-campaigns.message-campaign.dashboard');
  },
  //endregion

  //region Actions
  actions: {
    error(error) {
      const { snackbar } = this;
      const errorStatus = get(error, 'status');
      const adjustedErrorData = `${`${get(error, 'responseJSON.errors.firstObject.data')}`.replace(/Z$/, '')}Z`; // API Bug Workaround: It sometimes sends times with Z, sometimes without
      const scheduleInstanceDate = moment(adjustedErrorData).format(this.defaultFormat.getDateFormat());
      if (errorStatus === 409) {
        // message already approved
        snackbar.show(
          `Your message that was scheduled for ${scheduleInstanceDate} has already been approved and can no longer be changed.`,
          null
        );
      } else if (errorStatus === 410) {
        // message has expired
        snackbar.show(
          `Your message that was scheduled for ${scheduleInstanceDate} has expired and will not be sent.`,
          null,
          5000,
          'error'
        );
      }
      this.router.replaceWith('organizations.organization.message-campaigns.message-campaign.dashboard');
    },
  },
  //endregion
});
