import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Boulevard::ModalDialog\n  @isCloseable={{false}}\n  class=\"ssPromotionBasics {{this.organizationPromotion.promotion.promotionClassName}}\"\n>\n  <MdcLinearProgress @indeterminate={{this.saveTask.isRunning}} />\n\n  <OrganizationPromotion::BasicsForm\n    @title={{this.title}}\n    @organizationPromotion={{this.organizationPromotion}}\n    @onSubmit={{perform this.saveTask}}\n    @onCancel={{this.handleCancel}}\n  />\n</Boulevard::ModalDialog>\n", {"contents":"<Boulevard::ModalDialog\n  @isCloseable={{false}}\n  class=\"ssPromotionBasics {{this.organizationPromotion.promotion.promotionClassName}}\"\n>\n  <MdcLinearProgress @indeterminate={{this.saveTask.isRunning}} />\n\n  <OrganizationPromotion::BasicsForm\n    @title={{this.title}}\n    @organizationPromotion={{this.organizationPromotion}}\n    @onSubmit={{perform this.saveTask}}\n    @onCancel={{this.handleCancel}}\n  />\n</Boulevard::ModalDialog>\n","moduleName":"partner/components/organization-promotion/create-form.hbs","parseOptions":{"srcName":"partner/components/organization-promotion/create-form.hbs"}});
import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import enums from 'ember-cli-ss-enums/services/enums';
import { task } from 'ember-concurrency';
import type InterestTagModel from 'partner/models/interest-tag';
import type PromotionModel from 'partner/models/promotion';
import type PromotionPresetModel from 'partner/models/promotion-preset';
import type OrganizationPromotionManagerService from 'partner/services/organization-promotion-manager';
import type SnackbarService from 'secondstreet-common/services/snackbar';
import type { Model } from './basics-form';

const typeIdFor = (name: string) => enums.findWhere('PROMOTION_TYPE', { name });
const subTypeIdFor = (name: string) => enums.findWhere('PROMOTION_SUB_TYPE', { name });

const PRESETS: Record<number, Partial<PromotionModel>> = {
  1: { promotionTypeId: typeIdFor('Sweepstakes'), promotionSubTypeId: subTypeIdFor('SweepstakesSimple') }, // Sweepstakes
  3: { promotionTypeId: typeIdFor('Sweepstakes'), promotionSubTypeId: subTypeIdFor('SweepstakesCodeword') }, // Codeword Sweeps
  4: { promotionTypeId: typeIdFor('Quiz'), promotionSubTypeId: subTypeIdFor('QuizPersonality') }, // Personality Quiz
  5: { promotionTypeId: typeIdFor('Quiz'), promotionSubTypeId: subTypeIdFor('QuizTrivia') }, // Trivia Quiz
  6: { promotionTypeId: typeIdFor('UGCSweepstakes'), promotionSubTypeId: subTypeIdFor('UGCSweepstakesStandard') }, // Photo Sweeps
  7: { promotionTypeId: typeIdFor('Survey'), promotionSubTypeId: subTypeIdFor('Survey') }, // Survey
  9: { promotionTypeId: typeIdFor('UGCVoting'), promotionSubTypeId: subTypeIdFor('PhotoVotingStandard') }, // Photo Contest
  10: { promotionTypeId: typeIdFor('UGCGallery'), promotionSubTypeId: subTypeIdFor('PhotoGallery') }, // Community Gallery
  11: { promotionTypeId: typeIdFor('UGCSweepstakes'), promotionSubTypeId: subTypeIdFor('UGCSweepstakesVideo') }, // Video Sweeps
  12: { promotionTypeId: typeIdFor('UGCVoting'), promotionSubTypeId: subTypeIdFor('VideoVotingStandard') }, // Video Contest
  13: { promotionTypeId: typeIdFor('Ballot'), promotionSubTypeId: subTypeIdFor('VotingBallot') }, // Voting Ballot
  14: { promotionTypeId: typeIdFor('Ballot'), promotionSubTypeId: subTypeIdFor('NominationAndVotingBallot') }, // Nomination & Voting Ballot
  15: { promotionTypeId: typeIdFor('Poll'), promotionSubTypeId: subTypeIdFor('PollStandard') }, // Poll
  16: { promotionTypeId: typeIdFor('VotingBracket'), promotionSubTypeId: subTypeIdFor('VotingBracket') }, // Voting Bracket
  17: { promotionTypeId: typeIdFor('EventSignup'), promotionSubTypeId: subTypeIdFor('EventSignup') }, // Event
  18: { promotionTypeId: typeIdFor('PaymentForm'), promotionSubTypeId: subTypeIdFor('PaymentForm') }, // Payment Form
};

interface OrganizationPromotionCreateFormSignature {
  Element: HTMLDivElement;
  Args: {
    isCreatedFromTurnkey?: boolean;
    onCreate: (organizationPromotion: any) => void;
    onCancel: () => void;
  } & (
    | {
        promotionPresetId: number;
        promotionPreset: undefined;
      }
    | {
        promotionPresetId: undefined;
        promotionPreset: PromotionPresetModel;
      }
  );
}

export default class OrganizationPromotionCreateFormComponent extends Component<OrganizationPromotionCreateFormSignature> {
  @service declare organizationPromotionManager: OrganizationPromotionManagerService;
  @service declare snackbar: SnackbarService;
  @service declare store: Store;

  @tracked promotion: PromotionModel;
  @tracked organizationPromotion: any;
  @tracked interestTags: InterestTagModel[] = [];

  constructor(owner: unknown, args: OrganizationPromotionCreateFormSignature['Args']) {
    super(owner, args);

    const attrs = this.args.promotionPreset
      ? {
          promotionPresetId: this.args.promotionPreset.id,
          promotionTypeId: this.args.promotionPreset.promotionTypeId,
          promotionSubTypeId: this.args.promotionPreset.promotionSubTypeId,
          copyEntries: true,
          isCreatedFromTurnkey: this.args.isCreatedFromTurnkey ?? false,
        }
      : this.args.promotionPresetId
      ? {
          promotionPresetId: this.args.promotionPresetId,
          ...PRESETS[this.args.promotionPresetId],
        }
      : undefined;

    if (!attrs) throw new Error('Either promotionPreset or promotionPresetId must be provided');

    this.promotion = this.store.createRecord('promotion', attrs);
    this.organizationPromotion = this.store.createRecord('organization-promotion', {
      name: '',
      defaultLanguageId: enums.findWhere('LANGUAGE', { code: 'en-US' }),
      promotions: this.promotion,
    });
  }

  get title() {
    const title = enums.findWhere('PROMOTION_SUB_TYPE', { id: this.promotion.promotionSubTypeId }, 'publicName');
    return `Create New ${title || 'Promotion'}`;
  }

  saveTask = task({ drop: true }, async (model: Model) => {
    try {
      this.promotion.isSyndicated = model.isSyndicated;

      await this.promotion.save();

      // Creating a new promotion, creates a new organization-promotion
      this.organizationPromotion = await this.store.queryRecord('organization-promotion', {
        organizationPromotionId: this.promotion.organizationPromotions.firstObject.id,
        promotionId: this.promotion.id,
      });
      this.organizationPromotion.setProperties({
        name: model.name,
        defaultLanguageId: model.defaultLanguageId,
      });
      await this.organizationPromotion.save();

      await this.organizationPromotionManager.setup(this.organizationPromotion, {
        interestTags: model.interestTags,
        partnerUsers: model.partnerUsers,
      });

      this.args.onCreate(this.organizationPromotion);
    } catch (e) {
      this.snackbar.exception(e);
    }
  });

  handleCancel = () => {
    this.promotion.destroyRecord();
    this.organizationPromotion.destroyRecord();
    this.args.onCancel();
  };
}
