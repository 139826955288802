/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { equal } from '@ember/object/computed';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

export default BaseModel.extend({
  //region Properties
  organizationId: attr('number'),

  interactionsCount: attr('number'),

  promotionsCount: attr('number'),

  usersCount: attr('number'),

  usersWithRecentInteractionsCount: attr('number'),

  recentUsersAddedCount: attr('number'),

  recentUsersLostCount: attr('number'),

  recentUserAttributesAddedCount: attr('number'),
  //endregion

  //region Computed Properties
  usersCountIsOne: equal('usersCount', 1),
  //endregion

  //region Relationships
  organization: belongsTo('organization', { async: false }),
  //endregion
});
