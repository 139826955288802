/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { observer } from '@ember/object';

/**
 * Creates <img> with the given src. If img fails to load the img.src is changed to the altSrc.
 * Example:
 *    {{ss-img classNames="ssClassYouWant"
 *             src=urlThatMayNotWork
 *             altSrc=knownWorkingImageUrl
 *             title=""}}
 */
export default Component.extend({
  tagName: 'img',
  attributeBindings: ['alt', 'src', 'width', 'height', 'role'],

  imageSourceChanged: observer('src', function () {
    this.element.setAttribute('src', this.src);
  }),

  willInsertElement() {
    this._super(...arguments);
    let alreadyAttempted = false;
    const { src } = this;
    const { altSrc } = this;

    this.element.setAttribute('src', src);
    if (this.title) {
      this.element.setAttribute('title', this.title);
    }
    if (this.width) {
      this.element.setAttribute('width', this.width);
    }
    if (this.height) {
      this.element.setAttribute('height', this.height);
    }
    if (this.altSrc) {
      //if there is a problem with the img.src it will be replaced with the altSrc
      this.element.onerror = event => {
        if (!alreadyAttempted) {
          event.target.setAttribute('src', altSrc);
          event.target.setAttribute('title', 'Image no longer available from source.');
          alreadyAttempted = true;
        } else {
          event.target.setAttribute('alt', 'No image available');
          event.target.setAttribute('title', 'Image no longer available from source.');
        }
      };
    }
  },
});
