/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed, get } from '@ember/object';
import { alias, gt, not } from '@ember/object/computed';
import { guidFor } from '@ember/object/internals';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

export default BaseModel.extend({
  //region Properties
  name: attr('string'),
  text: attr('string'),
  displayOrder: attr('number'),
  idForOptionTypeContext: attr('number'),
  fieldOptionTypeId: attr('number'),
  //endregion

  //region Relationships
  field: belongsTo('field', { async: false }),
  //endregion

  //region Computed Properties
  isEditable: alias('field.isEditable'),
  isReadonly: not('isEditable'),
  guid: computed('', function () {
    return guidFor(this);
  }),
  hasErrors: gt('errors.length', 0),

  displayIndex: computed('field.fieldOptions.[]', {
    get() {
      return this._displayIndex ?? get(this, 'field.fieldOptions').indexOf(this) + 1;
    },

    set(_key, value) {
      return (this._displayIndex = value);
    },
  }),
  //endregion
});
