/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import DS from 'ember-data';
import isDate from 'lodash/isDate';
import dirtyProperty from 'partner/utils/dirty-property';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend({
  //region Properties
  confirmationEmailAddress: DS.attr('string'),
  eblastEmailVersionId: DS.attr('number'),
  isLocked: DS.attr('boolean'),
  isSent: DS.attr('boolean'),
  replyTo: DS.attr('string'),
  scheduleDate: DS.attr('date'),
  //endregion

  //region Relationships
  messageTemplate: DS.belongsTo('message-template', { async: true }),
  //endregion

  //region Computed Properties
  status: computed('scheduleDate', function () {
    return isDate(this.scheduleDate) ? 'bestPractice' : 'incomplete';
  }),
  isScheduleDateDirty: dirtyProperty('scheduleDate'),
  hasBeenScheduled: computed('isNew', 'isDeleted', 'scheduleDate', function () {
    return isDate(this.scheduleDate) && !this.isNew && !this.isDeleted;
  }),
  //endregion
});
