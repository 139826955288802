/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { empty } from '@ember/object/computed';

/**
 * Component that displays DS.Errors for a certain attribute.
 * Needed because for some reason you can NOT use {{#each errors}}, when errors is an instance of DS.Errors.
 *
 * @example {{ss-errors-for errors=model.errors attribute='endPercent'}}
 *
 * @see https://github.com/emberjs/data/issues/1683
 * @param [material=false] {Boolean} - Whether this should be styled to follow a material input
 * @type {Ember.Component}
 */
export default Component.extend({
  tagName: 'ss-errors-for',
  isHidden: empty('attributeErrors'),
  classNameBindings: ['material'],
  attributeBindings: ['isHidden:hidden'],

  material: false,

  /**
   * Used in view to display DS.Errors for a certain attribute.
   * Example: {{ss-errors-for errors=model.errors attribute='name'}}
   * In above example errors is an instance of DS.Errors
   */
  attributeErrors: computed('attribute', 'errors.content.[]', function () {
    const { attribute } = this;
    const { errors } = this;

    if (errors) {
      if (!get(errors, 'isEmpty')) {
        return get(errors, 'content')
          .map(x => ({
            attribute: get(x, 'attribute'),
            message: get(x, 'message'),
          }))
          .filterBy('attribute', attribute);
      }
    }

    return [];
  }),
});
