/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

export default Route.extend(AppContextRoute, {
  //region Ember Hooks
  router: service(),
  session: service(),
  store: service(),

  model() {
    return RSVP.hash({
      isAnythingSaving: false,
      partnerUser: this.store.createRecord('partner-user', { isEditable: true }),
      partnerUserInvite: this.store.createRecord('partnerUserInvite'),
      roles: this.store.findAll('role'),
      organizations: this.store.findAll('organization'),
      editorPartnerUserOrganizationRoles: this.store
        .query('partner-user-organization-role', {
          partnerUserId: get(this, 'session.data.authenticated.organization_users.user_id'),
          includeImplicitRoles: true,
        })
        .then(x => x.toArray()),
      explicitEditorPartnerUserOrganizationRoles: this.store
        .query('partner-user-organization-role', {
          partnerUserId: get(this, 'session.data.authenticated.organization_users.user_id'),
        })
        .then(x => x.toArray()),
    }).then(hash => {
      const isAdmin = get(this.controllerFor('application'), 'isAdminUser');
      let newPartnerUserOrganizationRoles;
      if (isAdmin) {
        // There aren't any great "smart defaults" for internal support / admin users.
        newPartnerUserOrganizationRoles = [];
      } else {
        newPartnerUserOrganizationRoles = get(hash, 'explicitEditorPartnerUserOrganizationRoles').map(editorOrgRole => {
          // We want to create a copy for each partnerUserOrganizationRole the editor has, and those copies will
          // become the new user's partnerUserOrganizationRoles.
          get(hash, 'partnerUser.organizations').addObject(get(editorOrgRole, 'organization'));
          return this.store.createRecord('partner-user-organization-role', {
            roles: get(editorOrgRole, 'roles'),
            partnerUser: get(hash, 'partnerUser'),
            organization: get(editorOrgRole, 'organization'),
          });
        });
      }
      set(hash, 'partnerUserOrganizationRoles', newPartnerUserOrganizationRoles);
      return hash;
    });
  },
  //endregion

  //region Actions
  actions: {
    goBack() {
      if (window.history.length >= 2) {
        window.history.back();
      } else {
        this.router.transitionTo('users.index');
      }
    },
    addOrganizationRoles(organization, roles) {
      const puors = get(this.modelFor('users.new'), 'partnerUserOrganizationRoles');
      this.send(
        'addOrganizationPartnerUserRoles',
        organization,
        roles,
        get(this.modelFor('users.new'), 'partnerUser'),
        puors
      );
    },
    removeOrganizationRoles(organization) {
      const puors = get(this.modelFor('users.new'), 'partnerUserOrganizationRoles');
      this.send('removeOrganizationPartnerUserRoles', organization, puors);
    },
    willTransition(transition) {
      const model = this.modelFor('users.new');

      const partnerUser = get(model, 'partnerUser');
      const isAnythingDirty =
        get(partnerUser, 'hasDirtyAttributes') ||
        get(model, 'partnerUserOrganizationRoles').any(x => get(x, 'hasDirtyAttributes'));
      let confirmed = !isAnythingDirty || !get(this, 'controller.canCreateNewUsers');
      confirmed = confirmed
        ? confirmed
        : window.confirm('Are you sure you want to leave? Your unsaved changes may be lost.');
      if (!confirmed) {
        return transition.abort();
      }

      const partnerUserInvite = get(model, 'partnerUserInvite');
      // if we have an unsaved password reset request record, destroy it
      if (get(partnerUserInvite, 'isNew')) {
        partnerUserInvite.destroyRecord();
      }
    },
    save() {
      this.send('savePartnerUser', this.modelFor('users.new'));
    },
    addRoles(partnerUserOrganizationRole, ...roles) {
      this.send(
        'addPartnerUserRoles',
        partnerUserOrganizationRole,
        get(this.modelFor('users.new'), 'partnerUser'),
        ...roles
      );
    },
    removeRoles(partnerUserOrganizationRole, ...roles) {
      this.send('removePartnerUserRoles', partnerUserOrganizationRole, ...roles);
    },
  },
  //endregion
});
