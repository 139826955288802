import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import CreatePromotionComponent from 'partner/components/create-promotion';

export default class CreateInteractiveContentComponent extends CreatePromotionComponent {
  @service declare router: RouterService;

  @tracked selectedPromotionPresetId: number | null = null;

  handleCreate = (organizationPromotion: any) => {
    void this.router.transitionTo(
      'organizations.organization.organization-promotions.organization-promotion',
      organizationPromotion.id,
      { queryParams: { isCreating: false } }
    );
  };
}
