/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { equal } from '@ember/object/computed';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend({
  //region Properties
  organizationPromotionId: DS.attr('number'),
  percentComplete: DS.attr('number'),
  organizationUserId: DS.attr('number'),
  messageCampaignId: DS.attr('number'),
  audienceId: DS.attr('number'),
  //endregion

  //region Computed Properties
  isComplete: equal('percentComplete', 100),
  //endregion

  //region Relationships
  checklistStep: DS.belongsTo('checklist-step'),
  //endregion
});
