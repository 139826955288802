/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import moment from 'moment';
import BaseModel from 'secondstreet-common/models/base';
import { inject as service } from '@ember/service';

const { attr } = DS;

export default BaseModel.extend({
  current: service(),
  defaultFormat: service('default-format'),

  //region Attributes
  audienceId: attr('string'),
  audienceTypeId: attr('number'),
  name: attr('string'),
  consumerUserId: attr('number'),
  audienceJoinDate: attr('date'),
  hasImportFileFilter: attr('boolean'),
  //endregion

  //region Computed Properties
  isSubscriptionAudience: equal('audienceTypeId', enums.findWhere('AUDIENCE_TYPE', { name: 'Single Optin' }, 'id')),

  isSegmentedAudience: equal('audienceTypeId', enums.findWhere('AUDIENCE_TYPE', { name: 'Custom Filtered' }, 'id')),

  formattedDate: computed('audienceJoinDate', function () {
    return moment(this.audienceJoinDate).format(this.defaultFormat.getDateFormat());
  }),

  formattedName: computed('name', function () {
    return this.name?.replaceAll('{{Organization.Name}}', this.current.organization.name);
  }),

  cannotRemove: computed('isSegmentedAudience', 'hasImportFileFilter', function () {
    return this.isSegmentedAudience && !this.hasImportFileFilter;
  }),
  //endregion
});
