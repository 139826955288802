import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"amount-input\" ...attributes>\n  <label for=\"amount-input\" class=\"amount-input__label amount-input__label--sr-only\">What is the price</label>\n  <input\n    class=\"amount-input__value ss-material-input {{@inputClass}} {{if this.disabled \"disabled\"}}\"\n    id=\"amount-input\"\n    type=\"number\"\n    {{on \"focusout\" this.focusOut}}\n    oninput={{this.onInput}}\n    value={{this.displayedAmount}}\n    min={{this.min}}\n    max={{this.max}}\n    step=\"0.01\"\n    placeholder=\"0.00\"\n    disabled={{@disabled}}\n  >\n</div>\n", {"contents":"<div class=\"amount-input\" ...attributes>\n  <label for=\"amount-input\" class=\"amount-input__label amount-input__label--sr-only\">What is the price</label>\n  <input\n    class=\"amount-input__value ss-material-input {{@inputClass}} {{if this.disabled \"disabled\"}}\"\n    id=\"amount-input\"\n    type=\"number\"\n    {{on \"focusout\" this.focusOut}}\n    oninput={{this.onInput}}\n    value={{this.displayedAmount}}\n    min={{this.min}}\n    max={{this.max}}\n    step=\"0.01\"\n    placeholder=\"0.00\"\n    disabled={{@disabled}}\n  >\n</div>\n","moduleName":"partner/components/ss-amount-input.hbs","parseOptions":{"srcName":"partner/components/ss-amount-input.hbs"}});
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { formatMoney } from 'secondstreet-common/utils/format-money';

// Set by product
const PRICE_MINIMUM = 1;
const PRICE_MAXIMUM = 999999;

export default class SsAmountInputComponent extends Component {
  constructor() {
    super(...arguments);
    this.displayedAmount = this.stringifiedPrice();
  }

  //region Properties
  @tracked
  displayedAmount;

  min = PRICE_MINIMUM;
  max = PRICE_MAXIMUM;
  //endregion

  //region Methods
  /**
   * @param {Number} value
   * @returns {String}
   */
  validateAndFormatAmount(value) {
    return formatMoney(Math.min(Math.max(value, this.min), this.max));
  }
  /**
   * @returns {String}
   */
  stringifiedPrice() {
    return this.args.value ? formatMoney(this.args.value) : '';
  }
  //endregion

  //region Actions
  @action
  focusOut({ target: { value } }) {
    if (isPresent(value)) {
      this.displayedAmount = this.validateAndFormatAmount(parseFloat(value));
    } else {
      this.displayedAmount = this.stringifiedPrice();
    }
  }

  @action
  onInput({ target: { value } }) {
    if (isPresent(value)) {
      this.args.onChange(parseFloat(this.validateAndFormatAmount(parseFloat(value))));
    }
  }
  //endregion
}
