/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { gt, readOnly, sort } from '@ember/object/computed';
import take from 'lodash/take';
import { keepSorting, simpleSort } from 'secondstreet-common/utils/sorting';

const sumOn = (fn, entities) => (entities ? entities.reduce((acc, e) => fn(e) + acc, 0) : 0);
const firstTwo = x => take(x || [], 2);

const areFormSubmissionsEntries = op => get(op, 'promotion.promotionSubType') === 'Survey';
const getOr = (entity, prop, fallback) => get(entity, prop) || fallback;
const orZero = prop => entity => getOr(entity, prop, 0);
const normal = prop => entity => getOr(entity, prop, '').toLowerCase();

const submissions = orZero('numEligibleFormsubmissions');
const votes = orZero('numEligibleVotes');
const rawEntries = orZero('numEligibleEntries');
const entries = op => rawEntries(op) + (areFormSubmissionsEntries(op) ? submissions(op) : 0);
const opens = orZero('opens');
const clicks = orZero('clicks');
const latestCount = orZero('latestCount');
const name = normal('name');
const rmcName = normal('messageCampaignName');

const orgPromoSort = (a, b) =>
  keepSorting(simpleSort(entries(b) + votes(b), entries(a) + votes(a)), simpleSort(name(a), name(b)));

export default Component.extend({
  //region Attributes
  /**
   * @type {OrganizationPromotion[]}
   */
  'recent-promotions': null,

  /**
   * User Permission
   * @type {Boolean}
   */
  'can-create-promotions': false,

  /**
   * Product
   * @type {Boolean}
   */
  'has-promotions': true,

  /**
   * @type {OrganizationPromotion[]}
   */
  'recent-interactive-content': null,

  /**
   * User Permission
   * @type {Boolean}
   */
  'can-create-interactive-content': false,

  /**
   * Product
   * @type {Boolean}
   */
  'has-interactive-content': true,

  /**
   * @type {RecentMessageCampaign[]}
   */
  'recent-message-campaigns': null,

  /**
   * Product
   * @type {Boolean}
   */
  'has-email': true,

  /**
   * User Permission
   * @type {Boolean}
   */
  'can-create-emails': false,

  /**
   * @type {Audience[]}
   */
  audiences: null,

  /**
   * Product
   * @type {Boolean}
   */
  'has-audience-insights': true,

  /**
   * User Permission
   * @type {Boolean}
   */
  'can-create-audiences': false,

  /**
   * @type {Function}
   */
  'create-promotion': x => x,

  /**
   * @type {Function}
   */
  'create-interactive-content': x => x,

  /**
   * @type {Function}
   */
  'create-email': x => x,

  /**
   * @type {Function}
   */
  'create-audience': x => x,
  //endregion

  //region Ember Hooks
  classNames: ['recently-engaging-stats'],
  //endregion

  //region Computed Properties
  _people: readOnly('organization-statistics.usersWithRecentInteractionsCount'),

  _promotionsEntries: computed('recent-promotions.@each.numEligibleEntries', function () {
    return sumOn(entries, this['recent-promotions']);
  }),

  _promotionsVotes: computed('recent-promotions.@each.numEligibleVotes', function () {
    return sumOn(votes, this['recent-promotions']);
  }),

  _sortedPromotions: sort('recent-promotions', orgPromoSort),

  _collapsedPromotions: computed('_sortedPromotions.[]', function () {
    return firstTwo(this._sortedPromotions);
  }),

  _promotionsCanExpand: gt('recent-promotions.length', 2),

  _interactiveEntries: computed('recent-interactive-content.@each.numEligibleEntries', function () {
    return sumOn(entries, this['recent-interactive-content']);
  }),

  _interactiveVotes: computed('recent-interactive-content.@each.numEligibleVotes', function () {
    return sumOn(votes, this['recent-interactive-content']);
  }),

  _sortedInteractiveContent: sort('recent-interactive-content', orgPromoSort),

  _collapsedInteractiveContent: computed('_sortedInteractiveContent.[]', function () {
    return firstTwo(this._sortedInteractiveContent);
  }),

  _interactiveCanExpand: gt('recent-interactive-content.length', 2),

  _emailOpens: computed('recent-message-campaigns.@each.opens', function () {
    return sumOn(opens, this['recent-message-campaigns']);
  }),

  _emailClicks: computed('recent-message-campaigns.@each.clicks', function () {
    return sumOn(clicks, this['recent-message-campaigns']);
  }),

  _sortedMessageCampaigns: sort('recent-message-campaigns', (a, b) =>
    keepSorting(simpleSort(opens(b) + clicks(b), opens(a) + clicks(a)), simpleSort(rmcName(a), rmcName(b)))
  ),

  _collapsedMessageCampaigns: computed('_sortedMessageCampaigns.[]', function () {
    return firstTwo(this._sortedMessageCampaigns);
  }),

  _emailCanExpand: gt('recent-message-campaigns.length', 2),

  _sortedAudiences: sort('audiences', (a, b) =>
    keepSorting(simpleSort(latestCount(b), latestCount(a)), simpleSort(name(a), name(b)))
  ),

  _collapsedAudiences: computed('_sortedAudiences.[]', function () {
    return firstTwo(this._sortedAudiences);
  }),
  //endregion
});
