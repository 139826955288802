/* eslint-disable ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import ApplicationSerializer from 'partner/serializers/application';

export default ApplicationSerializer.extend(DS.EmbeddedRecordsMixin, {
  attrs: {
    messageVersions: { embedded: 'always' },
    // This is meant to be a read-only property, so the serializer has it
    // configured not to serialize into the JSON for PUT and POST requests.
    messageSendingStatusTypeId: { serialize: false },
  },
});
