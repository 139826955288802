import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"winner-laurels\">\n  <div class=\"winning-entry-laurels\">\n    <div class=\"laurels-container\">\n      {{svg-jar \"winner-laurels\"}}\n      <div class=\"svg-overlay\">\n        <div class=\"laurels-text\">\n          {{this.matchupPlaceName}}\n        </div>\n      </div>\n      {{#unless @oneCategory}}\n        <div class=\"entry-category\">&ldquo;{{@categoryName}}&rdquo;</div>\n      {{/unless}}\n    </div>\n  </div>\n</div>\n", {"contents":"<div class=\"winner-laurels\">\n  <div class=\"winning-entry-laurels\">\n    <div class=\"laurels-container\">\n      {{svg-jar \"winner-laurels\"}}\n      <div class=\"svg-overlay\">\n        <div class=\"laurels-text\">\n          {{this.matchupPlaceName}}\n        </div>\n      </div>\n      {{#unless @oneCategory}}\n        <div class=\"entry-category\">&ldquo;{{@categoryName}}&rdquo;</div>\n      {{/unless}}\n    </div>\n  </div>\n</div>\n","moduleName":"partner/components/winner-laurels.hbs","parseOptions":{"srcName":"partner/components/winner-laurels.hbs"}});
/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@glimmer/component';

export default class WinnerLaurels extends Component {
  // region Properties
  get matchupPlaceName() {
    const matchupPlace = this.args.matchupPlaces.filter(
      mp => mp.endRank >= this.args.rank && this.args.rank >= mp.startRank
    );
    return matchupPlace.firstObject.name;
  }
  //endregion
}
