import Controller from '@ember/controller';
import { action, get, getProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default class OrganizationsOrganizationLicensesLicenseCancelController extends Controller {
  //region Dependencies
  @service
  enums;
  //endregion

  //region Properties
  get cancellationReasonTypes() {
    return this.enums.data.CANCELLATION_REASON_TYPE;
  }

  get isValid() {
    const { cancellationReasonTypeId, cancellationNotificationDate, cancelDate, billingEndDate } = getProperties(
      this.model,
      'cancellationReasonTypeId',
      'cancellationNotificationDate',
      'cancelDate',
      'billingEndDate'
    );

    return (
      isPresent(cancellationReasonTypeId) &&
      isPresent(cancellationNotificationDate) &&
      isPresent(cancelDate) &&
      isPresent(billingEndDate)
    );
  }
  //endregion

  //region Methods
  async saveChanges() {
    const { model } = this;
    if (model.hasDirtyAttributes) {
      const organizationSalesPackages = get(model, 'organization.organizationSalesPackages').toArray();
      await model.save();
      get(model, 'organization.organizationSalesPackages').addObjects(organizationSalesPackages);
      await this.replaceRoute('organizations.organization.settings', get(model, 'organization.id'));
    }
  }
  //endregion

  //region Actions
  @action
  getByPath(path, object) {
    return get(object || {}, path);
  }

  @action
  save() {
    this.saveChanges();
  }

  @action
  saveOnEnter({ key }) {
    if (key !== 'Enter') {
      return;
    }
    this.saveChanges();
  }
  //endregion
}
