/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, get, set } from '@ember/object';
import { empty, sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Controller.extend({
  //region Dependencies
  enums: service(),
  //endregion

  //region Properties
  showRemoveConfirmation: false,
  templateToRemove: null,
  //endregion

  //region Computed Properties
  sortProperties: computed(() => ['name']),
  confirmationTitle: computed('templateToRemove', function () {
    const { templateToRemove } = this;
    if (templateToRemove) {
      return `Are you sure you want to delete ${get(templateToRemove, 'name')}?`;
    }
    return null;
  }),
  showCurrentEmptyMessage: empty('model'),
  sortedTemplates: sort('model', 'sortProperties'),
  //endregion
  actions: {
    deleteMessageBodyTemplate(template) {
      this.toggleProperty('showRemoveConfirmation');
      set(this, 'templateToRemove', template);
    },
    removeTemplate() {
      const { templateToRemove } = this;
      this.model.removeObject(templateToRemove);
      this.toggleProperty('showRemoveConfirmation');
      set(templateToRemove, 'statusTypeId', this.enums.findWhere('STATUS_TYPE', { name: 'Deleted' }));
      templateToRemove.save();
    },
    cancelRemove() {
      set(this, 'templateToRemove', null);
      this.toggleProperty('showRemoveConfirmation');
    },
  },
});
