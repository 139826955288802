import { isArray } from '@ember/array';
import { endpoint } from 'secondstreet-common/utils/url';
import ApplicationAdapter from './application';

export default class OrganizationPromotionAdapter extends ApplicationAdapter {
  handleResponse(status, headers, payload, requestData) {
    if (isArray(payload.organization_promotions)) {
      payload.organization_promotions = payload.organization_promotions.map(data => {
        data.links = this.#generateLinks(data);

        return data;
      });
    } else if (payload.organization_promotions) {
      payload.organization_promotions.links = this.#generateLinks(payload.organization_promotions);
    }

    return super.handleResponse(status, headers, payload, requestData);
  }

  #generateLinks(data) {
    return {
      organizationPromotionLinks: endpoint('organization_promotion_link', {
        organizationPromotionId: data.id,
        BypassCache: true,
      }),
    };
  }
}
