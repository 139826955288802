/* eslint-disable ember/use-ember-data-rfc-395-imports */
import type { default as Store, Snapshot } from '@ember-data/store';
import { inject as service } from '@ember/service';
import { ModelSchema } from 'ember-data';
import type ModelRegistry from 'ember-data/types/registries/model';
import type SentryService from 'partner/services/sentry';
import type RSVP from 'rsvp';
import type LoggerService from 'secondstreet-common/services/logger';
import ApplicationAdapter from './application';

type Result = {
  design_template: [
    {
      id: number;
      name: string;
      template_content: string;
      is_inherited: boolean;
      design_template_type_id: number;
      design_template_promotion_types: {
        id: number;
        promotion_type_id: number;
        design_template_id: number;
        organization_id: number;
        is_enforced: boolean;
      }[];
    }
  ];
};

export default class DesignTemplateAdapter extends ApplicationAdapter {
  @service declare logger: LoggerService;
  @service declare sentry: SentryService;

  createRecord<K extends keyof ModelRegistry = 'any'>(
    store: Store,
    type: ModelSchema<K>,
    snapshot: Snapshot<K>
  ): RSVP.Promise<any> {
    return super.createRecord(store, type, snapshot).then((result: Result) => {
      this.unloadPreviousRelationshipsFor(snapshot.record as any);

      return result;
    });
  }

  updateRecord<K extends keyof ModelRegistry = ''>(
    store: Store,
    type: ModelSchema<K>,
    snapshot: Snapshot<K>
  ): RSVP.Promise<any> {
    return super.updateRecord(store, type, snapshot).then((result: Result) => {
      this.unloadPreviousRelationshipsFor(snapshot.record as any);
      return result;
    });
  }

  /**
   * Ember Data cannot handle cases when new relationships are create in the same request,
   * so it keeps the old ones in memory as "new" ones (without an id).
   * We need to unload the "entry" as well as the backend returns a new address,
   * which keeps the existing record in dirty state.
   */
  private unloadPreviousRelationshipsFor(designTemplate: any) {
    if (!designTemplate) return;

    try {
      designTemplate.designTemplatePromotionTypes
        .filterBy('isNew')
        .forEach((promotionType: { unloadRecord: () => any }) => promotionType.unloadRecord());
    } catch (e) {
      this.sentry.captureException(e);
      this.logger.error(e);
    }
  }
}
