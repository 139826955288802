/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, getProperties, set, setProperties } from '@ember/object';
import { filter } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

const isDefined = property => typeof property !== 'undefined';

const INHERITED_TDC_PROPERTIES_TO_COPY = ['value', 'linkUrl', 'altText', 'mediaItemId', 'token'];

export default Component.extend({
  //region Ember Hooks
  didReceiveAttrs() {
    this._super();
    if (this.activeToken.tokenDefaultContent) {
      return set(this, 'activeDefaultContent', this.activeToken.tokenDefaultContent);
    }

    if (this.activeToken.placeholderTokenDefaultContent) {
      return set(this, 'activeDefaultContent', this.activeToken.placeholderTokenDefaultContent);
    }

    const newTokenDefaultContent = this.store.createRecord('token-default-content', { token: this.activeToken });
    return set(this, 'activeDefaultContent', newTokenDefaultContent);
  },
  //endregion

  //region Dependencies
  store: service(),
  //endregion

  //region Properties
  isResetting: false,
  isAddingImageFromLibrary: false,
  activeDefaultContent: null,
  //endregion

  //region Computed Properties
  socialHeaderTextTokens: filter('dynamicTokens', function (dynamicTokens) {
    return this.socialHeaderTextTokenFilter(dynamicTokens);
  }),
  textInputFieldTokens: computed('dynamicTokens.@each.key', function () {
    return this.dynamicTokens.filter(this.textInputFieldTokenFilter).sortBy('name');
  }),

  saveEnabled: computed(
    'placeholderAndDefaultContentsAreDifferent',
    'activeDefaultContent.hasDirtyAttributes',
    'activeToken.hasCustomValue',
    function () {
      return (
        this.placeholderAndDefaultContentsAreDifferent &&
        (this.activeDefaultContent.hasDirtyAttributes || this.activeToken.hasCustomValue)
      );
    }
  ),
  placeholderAndDefaultContentsAreDifferent: computed(
    'activeToken.placeholderTokenDefaultContent.{value,linkUrl,mediaItemId}',
    'activeDefaultContent.{value,linkUrl,mediaItemId}',
    function () {
      if (
        this.activeToken.placeholderTokenDefaultContent &&
        this.activeDefaultContent !== this.activeToken.placeholderTokenDefaultContent
      ) {
        return isEmpty(
          ['value', 'linkUrl', 'mediaItemId'].filter(property => {
            const placeholderProperty = this.activeToken.placeholderTokenDefaultContent[property];
            const defaultProperty = this.activeDefaultContent[property];
            return (
              isDefined(placeholderProperty) && isDefined(defaultProperty) && placeholderProperty === defaultProperty
            );
          })
        );
      }
      return true;
    }
  ),
  //endregion

  //region Actions
  actions: {
    async updateImage(isDips, value) {
      // If WYSIWYG is active
      if (this.activeDefaultContent.token.tokenContentType === 'HtmlText') {
        await set(this, 'activeDefaultContent.libraryImage', value);
        set(this, 'activeDefaultContent.libraryImage', null);
      }

      setProperties(this.activeDefaultContent, {
        value: isDips ? '' : value,
        mediaItemId: isDips ? value.id : '',
      });
    },
    updateTokenContentProperty(property, value) {
      set(this, `activeDefaultContent.${property}`, value);
    },
    async reset() {
      await this.activeDefaultContent.destroyRecord();
      set(this, 'isResetting', false);
      this.setTokenId();
    },

    cancel() {
      this.activeDefaultContent.rollbackAttributes();
      this.setTokenId();
    },
    async save() {
      if (this.activeDefaultContent.hasDirtyAttributes) {
        if (this.activeDefaultContent.isInherited && this.organizationId != 1) {
          const newTokenDefaultContent = this.store.createRecord(
            'token-default-content',
            getProperties(this.activeDefaultContent, INHERITED_TDC_PROPERTIES_TO_COPY)
          );
          this.activeDefaultContent.rollbackAttributes();
          set(this, 'activeDefaultContent', newTokenDefaultContent);
          await newTokenDefaultContent.save();
        } else {
          await this.activeDefaultContent.save();
        }
      }
      this.setTokenId();
    },
  },
  //endregion
});
