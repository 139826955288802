/* eslint-disable ember/no-get, ember/no-computed-properties-in-native-classes */
import { AsyncBelongsTo, attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import { computed, get } from '@ember/object';
import { alias, equal, not, reads } from '@ember/object/computed';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import enums from 'ember-cli-ss-enums/services/enums';
import moment from 'moment';
import type { BallotEntriesSortType } from 'partner/types';
import dirtyProperty from 'partner/utils/dirty-property';
import validate from 'partner/utils/validate';
import BaseModel from 'secondstreet-common/models/base';
import type MatchupEntryModel from './matchup-entry';
import type MatchupGroupModel from './matchup-group';

export default class MatchupModel extends BaseModel {
  @service declare enums: EnumsService;

  @attr('number') declare iteration: number;
  @attr('number') declare gameGroupId: number; // TODO: Relationship
  @attr('string', { defaultValue: '' }) declare name: string;
  @attr('date') declare startDate: Date;
  @attr('date') declare endDate: Date;
  @attr('date') declare selectionStartDate: Date;
  @attr('date') declare selectionEndDate: Date;
  @attr('number') declare displayOrder: number;
  @attr('string') declare matchupType: string;
  @attr('number') declare entriesAllowedNumber: number;
  @attr('number') declare votesAllowedNumber: number;
  @attr('number') declare entryIntervalTypeId: number;
  @attr('number') declare voteIntervalTypeId: number;
  @attr('string') declare description: string;
  @attr('string') declare address: string;
  @attr('number') declare instagramSyncStatusTypeId: number;
  @attr('boolean') declare isWinnersPublished: boolean;
  @attr('boolean') declare hasMatchupEntries: boolean;
  @attr('number') declare matchupClassificationTypeId: number;
  @attr('number') declare entriesSortTypeId: number;
  @attr('string', { defaultValue: '' }) declare helpText: string;

  @belongsTo('matchup-group', { async: true, inverse: 'matchups' })
  declare matchupGroup: AsyncBelongsTo<MatchupGroupModel>;
  @hasMany('matchup-entry', { async: false, inverse: 'matchup' })
  declare matchupEntries: SyncHasMany<MatchupEntryModel>;
  @hasMany('matchup-codeword', { async: false }) declare matchupCodewords: any[];
  @hasMany('matchup-hashtag', { async: false }) declare matchupHashtags: any[];

  @tracked isCurrentMatchup = false;
  @tracked isStartDateOverlap = false;
  @tracked isEndDateOverlap = false;

  /**
   * Used to display the number of entries in the matchup.
   * It should be preloaded manually when the matchup is loaded.
   */
  @tracked matchupEntriesCount: number | '-' = '-';

  /**
   * Used to display the matchup form instead of the matchup preview during ballot setup
   */
  @tracked wasDefaultMatchup = false;

  /**
   * Used for preventing the sorting while editing a matchup
   */
  @tracked cachedName?: string;

  @enums.computed('name', 'entriesSortTypeId', 'entriesSortTypeId', 'ENTRIES_SORT_TYPE')
  declare entriesSortType: Exclude<BallotEntriesSortType, 'Nominations'>;

  /**
   * When the default Matchup is saved for ballots we will update the displayOrder to be 1
   */
  @equal('displayOrder', 0) declare isBallotDefaultMatchup: boolean;
  @not('errors.isEmpty') declare hasErrors: boolean;
  @reads('isOverlap') declare isInvalid: boolean;
  @reads('matchupGroup.id') declare matchupGroupId: MatchupGroupModel['id'];

  get guid() {
    return guidFor(this);
  }

  @computed('matchupEntries.[]', 'matchupEntriesCount')
  get dynamicMatchupEntriesCount(): number | '-' {
    return this.hasMany('matchupEntries' as any).value()
      ? (this.matchupEntries.length as number)
      : this.matchupEntriesCount;
  }

  @computed(
    'startDate',
    'endDate',
    'selectionStartDate',
    'selectionEndDate',
    'hasDirtyAttributes',
    '_data.{startDate,endDate,selectionStartDate,selectionEndDate}'
  )
  get isComplete() {
    // eslint-disable-next-line ember/classic-decorator-no-classic-methods
    const isDirty = this.get('hasDirtyAttributes');

    const currentStartDate = this.startDate;
    const oldStartDate = get(this, '_data.startDate') as Date;
    const hasStartDate = moment(currentStartDate).isValid();
    const hadStartDate = moment(oldStartDate).isValid();

    const currentEndDate = this.endDate;
    const oldEndDate = get(this, '_data.endDate') as Date;
    const hasEndDate = moment(currentEndDate).isValid();
    const hadEndDate = moment(oldEndDate).isValid();

    const currentSelectionStartDate = this.selectionStartDate;
    const oldSelectionStartDate = get(this, '_data.selectionStartDate') as Date;
    const hasSelectionStartDate = moment(currentSelectionStartDate).isValid();
    const hadSelectionStartDate = moment(oldSelectionStartDate).isValid();

    const currentSelectionEndDate = this.selectionEndDate;
    const oldSelectionEndDate = get(this, '_data.selectionEndDate') as Date;
    const hasSelectionEndDate = moment(currentSelectionEndDate).isValid();
    const hadSelectionEndDate = moment(oldSelectionEndDate).isValid();

    if (!hasStartDate || !hasEndDate) {
      return false;
    } // No start or end date
    if (!hadEndDate && hasEndDate && isDirty) {
      return false;
    } // Didn't have, has now, not yet saved.
    if (!hadStartDate && hasStartDate && isDirty) {
      return false;
    } // Didn't have, has now, not yet saved.
    if (!hasSelectionStartDate || !hasSelectionEndDate) {
      return false;
    } // No selection start or end date
    if (!hadSelectionEndDate && hasSelectionEndDate && isDirty) {
      return false;
    } // Didn't have, has now, not yet saved.
    if (!hadSelectionStartDate && hasSelectionStartDate && isDirty) {
      return false;
    } // Didn't have, has now, not yet saved.

    return true;
  }

  @computed('isComplete')
  get status() {
    return this.isComplete ? 'bestPractice' : 'incomplete';
  }

  @computed('startDate', 'endDate')
  get shortFormattedDates() {
    return `${this.formatDate(this.startDate, 'NA')} - ${this.formatDate(this.endDate, 'No End Date')}`;
  }

  @computed('matchupCodewords.[]')
  get noCodeWords() {
    return isEmpty(this.matchupCodewords);
  }

  @computed('matchupCodewords.[]')
  get moreThanOneMatchupCodeword() {
    return (
      this.matchupCodewords.filter(
        (matchupCodeword: any) => !(get(matchupCodeword, 'isDeleted') && get(matchupCodeword, 'hasDirtyAttributes'))
      ).length > 1
    );
  }

  /**
   * UGC Sweeps and UGC Voting/Gallery use different enums for configuring their entry intervals. When referencing these computed properties,
   * make sure you are referencing the correct one based on which promotion type you're dealing with.
   */
  @computed('enums', 'entryIntervalTypeId', 'entriesAllowedNumber')
  get ugcSweepsEntryIntervalType() {
    return this.enums.findWhere(
      'UGC_SWEEPS_ENTRY_OPTIONS',
      {
        entryIntervalTypeId: this.entryIntervalTypeId,
        entriesAllowedNumber: this.entriesAllowedNumber,
      },
      {}
    );
  }

  @computed('enums', 'entryIntervalTypeId', 'entriesAllowedNumber')
  get ugcVotingEntryIntervalType() {
    return this.enums.findWhere(
      'UGC_VOTING_ENTRY_OPTIONS',
      {
        entryIntervalTypeId: this.entryIntervalTypeId,
        entriesAllowedNumber: this.entriesAllowedNumber,
      },
      {}
    );
  }

  /**
   * Nomination interval types reference UGC Voting entry interval types for now since they currently have the same
   * values with the exception of unlimited entries.
   */
  @alias('ugcVotingEntryIntervalType') nominationEntryIntervalType!: MatchupModel['ugcVotingEntryIntervalType'];

  /**
   * Please only use this for UGC Voting.
   * You probably want `entryIntervalType` if you're looking at a property like this for UGC Sweepstakes or UGC Gallery.
   */
  @computed('enums', 'voteIntervalTypeId', 'votesAllowedNumber')
  get voteIntervalType() {
    return this.enums.findWhere(
      'UGC_VOTING_ENTRY_OPTIONS',
      {
        entryIntervalTypeId: this.voteIntervalTypeId,
        entriesAllowedNumber: this.votesAllowedNumber,
      },
      {}
    );
  }

  /**
   * Nomination interval types reference UGC Voting entry interval types for now since they currently have the same
   * values with the exception of unlimited entries.
   */
  @alias('voteIntervalType') nominationIntervalType!: MatchupModel['voteIntervalType'];

  @computed('startDate')
  get matchupHasStarted() {
    return new Date() >= this.startDate;
  }

  @computed('endDate')
  get matchupHasEnded() {
    return new Date() >= this.endDate;
  }

  @not('matchupHasStarted') matchupHasNotStarted!: boolean;

  @dirtyProperty('startDate') isStartDateDirty!: boolean;
  @dirtyProperty('endDate') isEndDateDirty!: boolean;
  @dirtyProperty('selectionStartDate') isSelectionStartDateDirty!: boolean;
  @dirtyProperty('selectionEndDate') isSelectionEndDateDirty!: boolean;
  @dirtyProperty('entryIntervalTypeId') isEntryIntervalTypeDirty!: boolean;
  @dirtyProperty('voteIntervalTypeId') isVoteIntervalTypeDirty!: boolean;
  @dirtyProperty('entriesAllowedNumber') isEntriesAllowedNumberDirty!: boolean;
  @dirtyProperty('votesAllowedNumber') isVotesAllowedNumberDirty!: boolean;

  @computed('enums', 'matchupClassificationTypeId')
  get matchupClassificationType() {
    return this.enums.findWhere('MATCHUP_CLASSIFICATION_TYPE', { id: this.matchupClassificationTypeId }, {});
  }

  get hasAds(): boolean {
    return isPresent(this.store.peekAll('sponsored-post').findBy('ownerEntityId', +this.id));
  }

  /**
   * Stringified round name for the purpose of listing unique iterations of a bracket
   */
  @computed('iteration')
  get roundName() {
    return `Round ${this.iteration}`;
  }

  /**
   * Used to turn a date object into 12/30/2014 @11:45PM
   */
  formatDate(date: Date, nonDateText: string): string {
    const momentDate = moment(date);

    if (momentDate.isValid()) {
      return momentDate.format('M/D @hh:mmA');
    }

    return nonDateText;
  }

  /**
   * Date comparison that checks for an overlap of start and end dates.
   * Note: If compareMatchup is this (self-comparison), checks if startDate is before endDate.
   */
  overlaps(compareMatchup: MatchupModel): boolean {
    if (compareMatchup.id === this.id) {
      // comparing Matchup to itself
      const start = this.startDate;
      const end = this.endDate;

      if (start && end && moment(start).isValid() && moment(end).isValid()) {
        return start > end;
      }
    } else {
      const doesOverlap = (which: 'startDate' | 'endDate') =>
        get(this, which) &&
        validate.dateInRange(get(this, which), get(compareMatchup, 'startDate'), get(compareMatchup, 'endDate'));

      this.isStartDateOverlap = doesOverlap('startDate');
      this.isEndDateOverlap = doesOverlap('endDate');

      return doesOverlap('startDate') || doesOverlap('endDate');
    }

    return false;
  }

  incrementMatchupEntriesCount() {
    if (typeof this.matchupEntriesCount == 'number') {
      this.matchupEntriesCount++;
    }
  }

  decrementMatchupEntriesCount() {
    if (typeof this.matchupEntriesCount == 'number') {
      this.matchupEntriesCount--;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    matchup: MatchupModel;
  }
}
