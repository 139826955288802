/* global Redactor */

Redactor.add('plugin', 'fontspacing', {
  translations: {
    en: {
      fontspacing: {
        title: 'Font Spacing',
        cancel: 'Cancel',
        done: 'Set',
      },
    },
  },

  start() {
    const button = {
      title: '## fontspacing.title ##',
      icon: '<i class="material-symbols-outlined text-lg">format_letter_spacing_2</i>',
      command: 'fontspacing.popup',
      position: {
        after: 'lineheight',
      },
      blocks: {
        all: true,
        except: ['image', 'line', 'noneditable'],
      },
    };

    this.app.toolbar.add('fontspacing', button);
  },
  popup(_e, button) {
    this.app.context.close();

    const stack = this.app.create('stack');

    stack.create('fontspacing', {
      title: '## fontspacing.title ##',
      width: '200px',
      form: {
        letterSpacing: { type: 'number', min: 1, label: 'Letter Spacing' },
        lineHeight: { type: 'number', min: 1, label: 'Line Height' },
      },
      footer: {
        cancel: { title: '## fontspacing.cancel ##', command: 'modal.close' },
        save: { title: '## fontspacing.done ##', command: 'fontspacing.save', type: 'primary' },
      },
    });

    const $removeLetterSpacingButton = this.createRemoveButton('Remove Letter Spacing', this.removeLetterSpacing);
    const $removeLineHeightButton = this.createRemoveButton('Remove Line Height', this.removeLineHeight);

    const selection = this.app.create('selection');
    const $inline = selection.getInline();
    const instance = this.app.block.get();

    if (instance) {
      const $block = instance.getBlock();
      this.app.editor.save($block);
    }

    this.app.modal.open({ name: 'fontspacing', stack, focus: 'text', button });

    const $inputLetterSpacing = stack.$form?.find('input')?.get?.(0);
    const $inputLineHeight = stack.$form?.find('input')?.get?.(1);

    $inputLetterSpacing.style.minWidth = '156px';
    $inputLetterSpacing.min = 1;
    $inputLetterSpacing.value = '';

    $inputLineHeight.style.minWidth = '156px';
    $inputLineHeight.min = 1;
    $inputLineHeight.value = '';

    if (instance) {
      const style = instance.getStyle();

      if (selection.isCollapsed() && style && style['letter-spacing']) {
        $inputLetterSpacing.value = style['letter-spacing']?.slice(0, -2);
      }
      if ($inline.style && $inline.style.letterSpacing !== '') {
        $inputLetterSpacing.value = $inline.style.letterSpacing?.slice(0, -2);
      }

      if (selection.isCollapsed() && style && style['line-height']) {
        $inputLineHeight.value = style['line-height']?.slice(0, -2);
      }

      if ($inline.style && $inline.style.lineHeight !== '') {
        $inputLineHeight.value = $inline.style.lineHeight?.slice(0, -2);
      }
    }

    stack.$stack.get().appendChild($removeLetterSpacingButton);
    stack.$stack.get().appendChild($removeLineHeightButton);
  },
  createRemoveButton(title, action) {
    const $button = document.createElement('div');

    $button.style.margin = '0 18px 18px 18px';
    $button.style.width = 'fit-content';
    $button.style.cursor = 'pointer';
    $button.style.color = 'black';
    $button.textContent = title;
    $button.onclick = () => action.call(this);

    return $button;
  },
  save(stack) {
    const $inputLetterSpacing = stack.$form?.find('input')?.get?.(0);
    const $inputLineHeight = stack.$form?.find('input')?.get?.(1);

    this.app.modal.close();
    this.app.editor.restore();

    const params = { tag: 'span', style: {} };
    if (!isNaN(Number($inputLetterSpacing.value))) {
      params.style['letter-spacing'] = `${$inputLetterSpacing.value}px`;
    }

    if (!isNaN(Number($inputLineHeight.value))) {
      params.style['line-height'] = `${$inputLineHeight.value}px`;
    }
    const selection = this.app.create('selection');
    const $block = this.app.block.get();

    if (selection.isCollapsed()) {
      $block.setStyle({ 'letter-spacing': `${$inputLetterSpacing.value}px` });
      $block.setStyle({ 'line-height': `${$inputLineHeight.value}px` });
      return;
    }

    this.app.inline.set(params);
  },
  removeLetterSpacing() {
    this.app.modal.close();

    const params = { style: 'letter-spacing' };
    const selection = this.app.create('selection');
    const $block = this.app.block.get();

    if (selection.isCollapsed()) {
      $block.setStyle({ 'letter-spacing': '' });
      return;
    }

    this.app.inline.remove(params);
  },
  removeLineHeight() {
    this.app.modal.close();

    const params = { style: 'line-height' };
    const selection = this.app.create('selection');
    const $block = this.app.block.get();

    if (selection.isCollapsed()) {
      $block.setStyle({ 'line-height': '' });
      return;
    }

    this.app.inline.remove(params);
  },
});
