/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { gte } from '@ember/object/computed';
import { MAX_TOKEN_CONTENT_URL_LENGTH } from 'partner/utils/constants';

export default Component.extend({
  // region Ember Hooks
  classNames: 'linkable-text-token-contents',
  //endregion

  //region Attributes
  updateTokenContentProperty() {},
  value: '',
  errorAttributeForValue: 'value',
  errorAttributeForLinkUrl: 'linkUrl',
  isDisabled: false,
  errors: null,
  linkUrl: null,
  randomNumberTokens: null,
  //endregion

  //region Properties
  maxTokenContentUrlLength: MAX_TOKEN_CONTENT_URL_LENGTH,
  //endregion

  //region Computed Properties
  urlCharacterLimitReached: gte('linkUrl.length', MAX_TOKEN_CONTENT_URL_LENGTH),
  //endregion

  //region Actions
  actions: {
    updateValue({ target: { value } }) {
      this.updateTokenContentProperty('value', value);
    },
    updateLinkUrl(value) {
      this.updateTokenContentProperty('linkUrl', value);
    },
  },
  //endregion
});
