/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set, setProperties } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { camelize, capitalize } from '@ember/string';
import { isEmpty, isPresent } from '@ember/utils';

/**
 * Token keys for content blocks that we do not want to allow the user to add to their templates
 * @type {Array}
 */
const TOKEN_BLACKLIST = [
  'itemHeadline1',
  'itemHeadline2',
  'itemHeadline3',
  'itemHeadline4',
  'itemButton1',
  'itemButton2',
  'itemButton3',
  'itemButton4',
  'menu1',
  'menu2',
  'menu3',
  'menu4',
  'menu5',
  'itemHeadline5',
  'itemHeadline6',
  'itemButton5',
  'itemButton6',
  'itemDescription1',
  'itemDescription2',
  'itemDescription3',
  'itemDescription4',
  'itemDescription5',
  'itemDescription6',
  'preheaderText',
  'facebookPageUrl',
  'twitterProfileUrl',
  'instagramProfileUrl',
  'pinterestProfileUrl',
  'tumblrBlogUrl',
  'youtubeChannelUrl',
  'linkedinProfileUrl',
  'snapchatProfileUrl',
  'vimeoUrl',
  'mediumBlogUrl',
  'itemImage1',
  'itemImage2',
  'itemImage3',
  'itemImage4',
  'itemImage5',
  'itemImage6',
];

export default Component.extend({
  //region Attributes
  'selected-component': null,
  'template-tokens': null,
  'set-attribute'() {},
  'add-new-token-to-template'() {},
  'is-setting-attribute': false,
  //endregion

  //region Dependencies
  store: service(),
  enums: service(),
  //endregion

  //region Ember Hooks
  didReceiveAttrs() {
    this._super();
    if (!this['is-setting-attribute']) {
      setProperties(this, {
        isEditing: get(this, 'selected-component.isNew'),
        queryOrNewName: '',
      });
    }
  },
  //endregion

  //region Properties
  isEditing: false,
  queryOrNewName: '',
  newTokenName: '',
  showModal: false,
  newTokenErrors: null,
  //endregion

  //region Computed Properties
  contentBlockTypeLabel: computed('selected-component.tag', function () {
    const tag = get(this, 'selected-component.tag');
    switch (tag) {
      case 'ss-text':
        return 'Text';
      case 'ss-button':
        return 'Button';
      case 'ss-image':
        return 'Image';
      case 'ss-editable-html':
        return 'Editable HTML';
      default:
        return '';
    }
  }),

  selectedToken: computed('selected-component.{attrsInfo,tag}', 'template-tokens', function () {
    const attrsInfo = get(this, 'selected-component.attrsInfo');
    const tag = get(this, 'selected-component.tag');
    const tokens = this['template-tokens'];
    const attr = tag === 'ss-image' ? 'src' : 'content';
    const contentBlock = attrsInfo[attr].value || attrsInfo[attr].defaultValue;
    const matchingToken = tokens.findBy('key', contentBlock.match(/\{\{(.+)\}\}/)[1]);
    return matchingToken ? get(matchingToken, 'name') : null;
  }),

  showContentBlockSelector: computed('selectedToken', 'isEditing', function () {
    return !this.selectedToken || this.isEditing;
  }),

  validTokensForThisComponent: computed('template-tokens', 'selected-component.tag', 'queryOrNewName', function () {
    const tokens = this['template-tokens'];
    const tag = get(this, 'selected-component.tag');
    const query = this.queryOrNewName;
    const isValidTokenContentType = token => {
      const tokenContentType = get(token, 'tokenContentType');
      switch (tag) {
        case 'ss-text':
          return tokenContentType === 'HtmlText' || tokenContentType === 'PlainText';
        case 'ss-button':
          return tokenContentType === 'LinkableText';
        case 'ss-image':
          return tokenContentType === 'LinkableImage';
        case 'ss-editable-html':
          return tokenContentType === 'RawHtml';
      }
      return false;
    };

    const matchesQuery = token => {
      if (!isPresent(query)) {
        return true;
      }
      return (
        token.label.toLowerCase().includes(query.toLowerCase()) ||
        token.value.toLowerCase().includes(query.toLowerCase())
      );
    };

    return tokens
      .filter(isValidTokenContentType)
      .reject(token => TOKEN_BLACKLIST.includes(get(token, 'key')))
      .map(token => ({ label: get(token, 'name'), value: get(token, 'key') }))
      .filter(matchesQuery);
  }),

  noExactMatch: computed('queryOrNewName', 'template-tokens', function () {
    const query = this.queryOrNewName;
    const tokens = this['template-tokens'];
    const tokenNames = tokens.map(x => get(x, 'name').toLowerCase());
    const tokenKeys = tokens.map(x => get(x, 'key').toLowerCase());
    return (
      !tokenKeys.includes(query.toLowerCase().replace(/[^\w]|_/g, '')) && !tokenNames.includes(query.toLowerCase())
    );
  }),
  //endregion

  //region Methods
  updateSearchResults(value) {
    set(this, 'queryOrNewName', value);
  },

  cleanUpEditState() {
    setProperties(this, {
      isEditing: false,
      showModal: false,
      queryOrNewName: '',
      newTokenErrors: null,
    });
  },
  //endregion

  //region Actions
  actions: {
    updateSearchResults({ target: { value } }) {
      debounce(this, this.updateSearchResults, value, 750);
    },
    setContentBlock(value) {
      const attr = get(this, 'selected-component.tag') === 'ss-image' ? 'src' : 'content';
      this['set-attribute'](attr, `{{${value}}}`);
      set(this, 'isEditing', false);
      set(this, 'queryOrNewName', '');
    },
    async createContentBlock() {
      const tokenName = this.queryOrNewName;
      const getTokenContentTypeId = type => this.enums.findWhere('TOKEN_CONTENT_TYPE', { name: type }, 'id');
      const tokenContentTypeId = {
        'ss-image': getTokenContentTypeId('LinkableImage'),
        'ss-text': getTokenContentTypeId('HtmlText'),
        'ss-button': getTokenContentTypeId('LinkableText'),
        'ss-editable-html': getTokenContentTypeId('RawHtml'),
      };
      const attr = get(this, 'selected-component.tag') === 'ss-image' ? 'src' : 'content';
      const tokenNameParts = tokenName.split(' ').filter(isPresent).map(capitalize);
      const name = tokenNameParts.join(' ');
      const newToken = this.store.createRecord('token', {
        name,
        category: 'Header & Body',
        key: camelize(tokenName).replace(/[^\w]|_/g, ''), // strips out anything that is not an alphanumeric character
        tokenTypeId: 1,
        tokenContentTypeId: tokenContentTypeId[get(this, 'selected-component.tag')],
      });

      set(this, 'newTokenName', name);

      try {
        await newToken.save();
      } catch (_) {
        set(this, 'newTokenErrors', newToken.errors);
      } finally {
        set(this, 'newTokenName', '');
      }

      if (isEmpty(this.newTokenErrors)) {
        this.cleanUpEditState();
        this['add-new-token-to-template'](attr, newToken);
      }
    },
    openModal() {
      set(this, 'showModal', true);
    },
    closeModal() {
      this.cleanUpEditState();
    },
  },
  //endregion
});
