/* eslint-disable ember/no-computed-properties-in-native-classes */
import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { not, or } from '@ember/object/computed';
import { isBlank } from '@ember/utils';
import Inflector from 'ember-inflector';
import dirtyProperty from 'partner/utils/dirty-property';
import BaseModel from 'secondstreet-common/models/base';
import type MatchupModel from './matchup';

Inflector.inflector.uncountable('sweepstakes');

export default class SweepstakesModel extends BaseModel {
  @attr('boolean', { defaultValue: false }) declare isEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare isECommerceEnabled: boolean;
  @attr('date') declare startDate: Date;
  @attr('date') declare endDate: Date;
  @attr('number', { defaultValue: 1 }) declare numberOfWinners: number;
  @attr('number') declare matchupId: number;

  @belongsTo('matchup', { async: true }) declare matchup: AsyncBelongsTo<MatchupModel>;

  @not('isEnabled') declare isDisabled: boolean;
  @or('startDateIsDirty', 'endDateIsDirty') declare areDatesDirty: boolean;

  @dirtyProperty('isEnabled') declare isEnabledIsDirty: boolean;
  @dirtyProperty('startDate') declare startDateIsDirty: boolean;
  @dirtyProperty('endDate') declare endDateIsDirty: boolean;
  @dirtyProperty('numberOfWinners') declare isNumberOfWinnersDirty: boolean;
  @dirtyProperty('isECommerceEnabled') declare isECommerceParticipationDirty: boolean;

  @computed('startDate', 'endDate')
  get areDatesComplete() {
    return !isBlank(this.startDate) && !isBlank(this.endDate);
  }

  @computed('numberOfWinners')
  get isNumberOfWinnersComplete() {
    return !isBlank(this.numberOfWinners);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    sweepstakes: SweepstakesModel;
  }
}
