import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class extends Route {
  @service('store') store;
  @service('settings') settings;

  get organizationId() {
    return this.modelFor('organizations.organization').organization.id;
  }

  async model() {
    const [tokens] = await Promise.all([this.fetchTokens(), this.settings.preload('dips_url')]);

    return {
      dipsUrl: this.settings.getFor('dips_url'),
      tokens,
    };
  }

  async fetchTokens() {
    const tokens = await this.store.query('token', { organizationId: this.organizationId });

    return tokens.sortBy('name');
  }
}
