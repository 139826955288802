/* eslint-disable ember/no-mixins */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

/**
 * Turnkeys Route
 * /o/:organization_id/op/new/turnkeys
 * @type {Ember.Route}
 * @mixes AppContextRoute
 */
export default class NewTurnkeysRoute extends Route.extend(AppContextRoute) {
  @service enums;
  @service router;
  @service settings;
  @service store;

  async model() {
    const { organization } = this.modelFor('organizations.organization');

    const turnkeyCategories = await this.store.query('turnkey-category', { organizationId: organization.id });
    const trendingCategoryId = this.enums.findWhere('TURNKEY_CATEGORY', { name: 'Trending Now' });
    const sortedCategories = turnkeyCategories.filter(category => category.id != trendingCategoryId);
    const trending = turnkeyCategories.filter(category => category.id == trendingCategoryId);

    return RSVP.hash({
      turnkeyCategories,
      sortedCategories,
      dipsUrl: this.settings.preload('dips_url').then(() => this.settings.getFor('dips_url')),
      trending: trending.firstObject,
      organization,
      organizations: this.store.findAll('organization').then(x => x.toArray()),
    });
  }
}
