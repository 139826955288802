/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import PromotionTypeRoute from 'partner/mixins/promotion-type-route';

/**
 * Outcomes Route
 * Everything beneath /o/:organization_id/op/:organization_promotion_id/setup/quiz/outcomes
 * @type {Ember.Route}
 * @mixes PromotionTypeRoute
 */
export default Route.extend(PromotionTypeRoute, {
  //region Ember Hooks
  model() {
    return get(
      this.modelFor('organizations.organization.organization-promotions.organization-promotion.setup.quiz'),
      'outcomes'
    );
  },
  //endregion
});
