/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { get, set } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { bindKeyboardShortcuts, unbindKeyboardShortcuts } from 'ember-keyboard-shortcuts';
import AppContextRoute from 'partner/mixins/app-context-route';
import ResetScrollRoute from 'partner/mixins/reset-scroll-route';
import RSVP from 'rsvp';

/**
 * Organization Promotion List Route Mixin
 * @type {Ember.Mixin}
 * @mixes AppContextRoute
 * @mixes ResetScrollRoute
 */
export default Mixin.create(AppContextRoute, ResetScrollRoute, {
  //region Ember Hooks
  /**
   * Force the router to reload the model when any of these URL query params change.
   * See: http://emberjs.com/guides/routing/query-params/#toc_opting-into-a-full-transition
   */
  queryParams: {
    statusTypeId: { refreshModel: true },
    promotionTypeId: { refreshModel: true },
    promotionTypeCategoryId: { refreshModel: true },
    pageIndex: { refreshModel: true },
    sortColumn: { refreshModel: true },
    sortDirection: { refreshModel: true },
    isCurrent: { refreshModel: true },
    searchValue: { refreshModel: true },
    promotionSubTypeId: { refreshModel: true },
  },
  isInteractiveContent: false,
  isEvents: false,
  tour: service(),
  confetti: service(),
  store: service(),
  settings: service(),

  model(params) {
    const organization = get(this.modelFor('organizations.organization'), 'organization');

    const hash = {
      currentOrganizationPromotions: this.loadOrganizationPromotions(this.generateApiRequestParameters(params)),
      _settings: this.settings.preload('dips_url'),
    };
    if (get(organization, 'hasOffers') && isEmpty(params.promotionTypeId)) {
      hash.commerceProductsSummary = this.store
        .query('commerceProductsSummary', {
          organizationId: get(organization, 'id'),
        })
        .then(results => get(results, 'firstObject'));
    }
    return RSVP.hash(hash).then(sources =>
      RSVP.hash({
        organizationPromotions: [],
        currentOrganizationPromotions: sources.currentOrganizationPromotions.organizationPromotions,
        currentMeta: sources.currentOrganizationPromotions.meta,
        commerceProductsSummary: sources.commerceProductsSummary,
        dipsUrl: this.settings.getValueFor('dips_url'),
        params,
      })
    );
  },
  activate() {
    set(this, 'keyboardShortcuts', { esc: 'cancel' });
    bindKeyboardShortcuts(this);
  },
  deactivate() {
    unbindKeyboardShortcuts(this);
  },
  //endregion

  keyboardShortcuts: null,
  //endregion

  //region Methods
  /**
   * Return a hash containing a list of organization promotions and its associated paging meta.
   * CAUTION: Due to an Ember issue regarding fetching paging meta data, this method should
   * not be refactored to do anything async after receiving the organizationPromotions and before caching the meta.
   */
  loadOrganizationPromotions(apiRequestParams) {
    return this.store.query('organization-promotion', apiRequestParams).then(organizationPromotions => {
      const meta = get(organizationPromotions, 'meta') || {};
      return { organizationPromotions, meta };
    });
  },
  generateApiRequestParameters(params) {
    // Populate a request object with shared request parameters:
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');
    const apiRequestParams = {
      organizationId,
      includeKPI: true,
      pageSize: 25,
      ...params,
    };

    return apiRequestParams;
  },

  //endregion

  actions: {
    willTransition() {
      this._super(...arguments);
      set(this, 'controller.isCreating', false);
      set(this, 'controller.pageIndex', 1);
      this.tour.trigger('cancel');
      this.confetti.trigger('cancel');
    },

    async revertOrgPromoStatus(orgPromo, status) {
      set(this, 'controller.isSettingStatus', true);
      set(orgPromo, 'statusTypeId', status);
      await orgPromo.save();
      set(this, 'controller.isSettingStatus', false);
    },
  },
});
