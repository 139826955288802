/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, getProperties, set } from '@ember/object';
import { filter, filterBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Renderer from 'isomorphic-template-renderer';
import $ from 'jquery';
import ENV from 'partner/config/environment';
import getDocumentHeight from 'partner/utils/get-document-height';
import { spreadIf } from 'secondstreet-common/utils/functional';
import TemplateBuilder from 'template-builder';

const tokenContentProps = [
  'value',
  'linkUrl',
  'altText',
  'itemTitle',
  'itemImageLinkUrl',
  'itemButtonLinkUrl',
  'itemButtonText',
  'mediaItemId',
  'itemImageExternalSrcUrl',
];

function walkMj(mj, func) {
  func(mj);
  if (mj.children) {
    for (const child of mj.children) {
      walkMj(child, func);
    }
  }
}

export default Component.extend({
  //region Attributes
  'dips-url': null,
  'is-selecting-template': false,
  'second-street-templates': null,
  'update-template'() {},
  'add-new-token'() {},
  'done-selecting-template'() {},
  template: null,
  tokens: null,
  'return-to-email': null,
  //endregion

  //region Ember Hooks
  classNames: ['template-builder'],
  didInsertElement() {
    this._super(...arguments);

    this.keyboardListener = e => {
      if ((e.metaKey || e.ctrlKey) && this.templateBuilder) {
        switch (e.key.toLowerCase()) {
          case 'z': {
            if (e.shiftKey) {
              this.templateBuilder.redo();
            } else {
              this.templateBuilder.undo();
            }
            const { selectedComponent } = this;
            if (selectedComponent) {
              this.templateBuilder.selectComponent(selectedComponent.id);
            }
            e.preventDefault();
            return false;
          }
          case 'y':
            this.templateBuilder.redo();
            e.preventDefault();
            return false;
          default:
            break;
        }
      }
    };

    // If selecting a SS template, wait until the default one is picked by the
    // templates component via setTemplateContent to generate the template
    // builder. This avoids multiple preview renders in a single frame, which
    // seems to completely break the iframe in Safari.
    if (!this['is-selecting-template']) {
      const mjmlJson = get(this, 'template.mjmlJson');
      const mj = mjmlJson ? JSON.parse(mjmlJson) : null;
      this.generateTemplateBuilder(mj);
    }
  },
  willDestroyElement() {
    this._super(...arguments);
    this.destroyTemplateBuilder();
  },
  //endregion

  //region Dependencies
  store: service(),
  enums: service(),
  //endregion

  //region Properties
  templateComponents: computed(() => [
    {
      label: 'Text',
      icon: 'text_format',
      isDraggable: true,
      tag: 'ss-text',
    },
    {
      label: 'Image',
      icon: 'image',
      isDraggable: true,
      tag: 'ss-image',
    },
    {
      label: 'Button',
      icon: 'open_in_new',
      isDraggable: true,
      tag: 'ss-button',
    },
    {
      label: 'Articles',
      icon: 'art_track',
      isDraggable: true,
      tag: 'ss-articles',
    },
    {
      label: 'Menu',
      icon: 'menu',
      isDraggable: true,
      tag: 'ss-menu-links',
    },
    {
      label: 'Social Links',
      icon: 'share',
      isDraggable: true,
      tag: 'ss-social-icons',
    },
    {
      label: 'Section',
      icon: 'crop_free',
      isDraggable: true,
      tag: 'ss-section',
    },
    {
      label: 'Divider',
      icon: 'border_horizontal',
      isDraggable: true,
      tag: 'ss-divider',
    },
    {
      label: 'Spacer',
      icon: 'format_line_spacing',
      isDraggable: true,
      tag: 'ss-spacer',
    },
    {
      label: 'Static HTML',
      icon: 'code',
      isDraggable: true,
      tag: 'ss-static-html',
    },
    {
      label: 'Editable HTML',
      icon: 'settings_ethernet',
      isDraggable: true,
      tag: 'ss-editable-html',
    },
    {
      isDraggable: false,
      tag: 'ss-web-version-link',
    },
    {
      isDraggable: false,
      tag: 'ss-footer',
    },
  ]),
  sectionLayouts: computed(() => [
    {
      label: 'One Column',
      value: 'one-column',
      image: 'One_Column',
    },
    {
      label: 'Two Column',
      value: 'two-column',
      image: 'Two_Column',
    },
    {
      label: 'Right Sidebar',
      value: 'right-sidebar',
      image: 'Right_Sidebar',
    },
    {
      label: 'Left Sidebar',
      value: 'left-sidebar',
      image: 'Left_Sidebar',
    },
  ]),
  selectedComponent: null,
  templateBuilder: null,
  mjWithIds: null,
  currentTab: 'add',
  isProduction: ENV.environment === 'production',
  //endregion

  //region Computed Properties
  templateTokens: filterBy('tokens', 'tokenType', 'Template'),

  dynamicTokens: filterBy('tokens', 'tokenType', 'Dynamic'),

  filteredDynamicTokens: filter(
    'dynamicTokens',
    token =>
      ![
        'System.ReadTracking',
        'System.BatchQueueID',
        'User.ID',
        'User.FullName',
        'Promotion.Url',
        'Promotion.UrlEncoded',
        'Promotion.Name',
        'Promotion.NameEncoded',
      ].includes(token.key)
  ),

  dynamicTokenKeys: computed('filteredDynamicTokens', function () {
    return ['application', ...(this.filteredDynamicTokens || []).mapBy('key')];
  }),

  tokenContentsData: computed(`templateTokens.@each.{${tokenContentProps.join(',')}}`, function () {
    const { templateTokens } = this;
    const getTokenDefaultContents = x => {
      const allTokenContents = this.store.peekAll('token-default-content').toArray();
      const tokenContentsForThisToken = allTokenContents.filterBy('tokenId', x.id);
      return tokenContentsForThisToken.findBy('isInherited', false) || tokenContentsForThisToken[0] || {};
    };
    return templateTokens.map(token => ({
      tokenKey: token.key,
      tokenTypeId: token.tokenTypeId,
      tokenContentTypeId: token.tokenContentTypeId,
      tokenAllowMultiple: token.allowMultiple,
      isDisabled: false,
      ...spreadIf(
        get(token, 'tokenDefaultContents').toArray().length > 0,
        getProperties(getTokenDefaultContents(token), ...tokenContentProps)
      ),
      ...spreadIf(token.key === 'item', {
        itemPublishDate: new Date(),
      }),
    }));
  }),

  primaryColor: computed('tokenContentsData.@each.tokenKey', function () {
    const primaryColorToken = this.tokenContentsData.findBy('tokenKey', 'colorCodePrimary');
    return primaryColorToken.value;
  }),

  secondaryColor: computed('tokenContentsData.@each.tokenKey', function () {
    const secondaryColorToken = this.tokenContentsData.findBy('tokenKey', 'colorCodeSecondary');
    return secondaryColorToken.value;
  }),
  //endregion

  //region Methods
  async generateTemplateBuilder(template) {
    const [iframe] = $('#template-builder');
    const [componentDrawer] = $('#template-builder-component-drawer');

    const updateListener = mjWithIds => {
      set(this, 'mjWithIds', mjWithIds);
      const mjWithoutIds = TemplateBuilder.cleanMj(mjWithIds);
      const hbs = TemplateBuilder.mjToHbs(mjWithoutIds);
      if (!this['is-selecting-template']) {
        this['update-template'](mjWithoutIds, hbs);
      }
    };

    const selectListener = component => {
      this.selectComponent(component);
    };

    const hbsToHtml = (hbs, { itemCount }) =>
      Renderer.render(
        `https://${get(this, 'dips-url.value')}`,
        hbs,
        this.generateTokenContentsData(itemCount),
        this.dynamicTokenKeys,
        []
      );

    iframe.addEventListener('load', () => {
      this.resizeIframe();
      iframe.contentDocument.addEventListener('keydown', this.keyboardListener);
    });

    const templateBuilder = TemplateBuilder.create({
      iframe,
      componentDrawer,
      template,
      updateListener,
      selectListener,
      hbsToHtml,
      mode: this['is-selecting-template'] ? 'preview' : 'edit',
    });
    set(this, 'templateBuilder', templateBuilder);

    document.addEventListener('keydown', this.keyboardListener);
  },

  destroyTemplateBuilder() {
    const [iframe] = $('#template-builder');
    document.removeEventListener('keydown', this.keyboardListener);
    if (iframe && iframe.contentDocument) {
      iframe.contentDocument.removeEventListener('keydown', this.keyboardListener);
    }
  },

  generateTokenContentsData(articleItemCount) {
    const { tokenContentsData } = this;
    const itemToken = tokenContentsData.findBy('tokenKey', 'item');

    // create a clone so we don't have to modify the existing data that comes back from API
    // the clone should omit items initially because we will be re-adding items based on count
    const clonedTokenContentsData = tokenContentsData.slice().rejectBy('tokenKey', 'item');

    for (let i = 0; i < articleItemCount; i++) {
      clonedTokenContentsData.push(itemToken);
    }
    return clonedTokenContentsData;
  },

  selectComponent(componentInfo) {
    if (!componentInfo) {
      set(this, 'selectedComponent', null);
      return;
    }

    const { id, tag } = componentInfo;
    const targetComponent = this.templateComponents.findBy('tag', tag);

    set(this, 'selectedComponent', {
      ...componentInfo,
      name: this.templateBuilder.getComponentName(id),
      isDraggable: targetComponent.isDraggable,
    });
  },

  setAttribute(componentId, attr, value) {
    this.templateBuilder.setAttribute(componentId, attr, value);
    this.templateBuilder.selectComponent(componentId);
  },

  resizeIframe() {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }
    const iframe = $('#template-builder');
    const [iframeNode] = iframe;
    const previewHeight = getDocumentHeight(iframeNode.contentDocument);
    iframe.css('height', previewHeight);
  },

  hasMjWithTag(tagName) {
    let hasElements = false;

    walkMj(this.mjWithIds, mj => {
      if (mj.tagName === tagName) {
        hasElements = true;
      }
    });

    return hasElements;
  },
  //endregion

  //region Actions
  actions: {
    getComponentName(id) {
      return this.templateBuilder.getComponentName(id);
    },
    selectComponent(id) {
      this.templateBuilder.selectComponent(id);
    },
    clearSelectedComponent() {
      this.templateBuilder.clearSelected();
    },
    highlightComponent(id) {
      this.templateBuilder.highlightComponent(id);
    },
    clearHighlightedComponent() {
      this.templateBuilder.clearHighlighted();
    },
    setAttribute(attr, value) {
      this.setAttribute(this.selectedComponent.id, attr, value);
    },
    removeComponent(componentId) {
      this.templateBuilder.removeComponent(componentId);
    },
    duplicateComponent(componentId) {
      this.templateBuilder.duplicateComponent(componentId);
    },
    addNewTokenToTemplate(attr, newToken) {
      this['add-new-token'](newToken);
      this.setAttribute(this.selectedComponent.id, attr, `{{${newToken.key}}}`);
    },
    setTemplateContent(mj) {
      const { templateBuilder } = this;
      if (templateBuilder) {
        templateBuilder.setTemplate(mj);
      } else {
        this.generateTemplateBuilder(mj);
      }
    },
    doneSelectingTemplate(template) {
      this['done-selecting-template'](template.mjmlJson, template.body);
    },
    stopPreviewMode() {
      this.templateBuilder.setMode('edit');
    },
    returnToEmail() {
      this['return-to-email'](this.hasMjWithTag('ss-articles'));
    },
  },
  //endregion
});
