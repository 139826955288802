/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import { createMatchupGroupViews, createMatchupStatusCounts } from 'partner/utils/matchup-group-views';
import RSVP from 'rsvp';
import { alphabeticalByProperty } from 'secondstreet-common/utils/sorting';

export default Route.extend(AppContextRoute, {
  //region Dependencies
  enums: service(),
  router: service(),
  store: service(),
  settings: service(),
  //endregion

  //region Ember Hooks
  queryParams: {
    matchupId: {
      refreshModel: true,
      replace: false,
    },
  },
  /**
   * @param {Object} params - Expects { matchupId<Number> }
   */
  async model(params) {
    if (params.openModal) {
      set(this, 'openModal', true);
    }

    const data = await RSVP.hash({
      _settings: this.settings.preload(['dips_url', 'category_sort_criteria']),
      matchups: this.store.query('matchup', { excludeSecondaryMatchups: true }),
      matchupGroups: this.store.query('matchup-group', {}),
      votingEntries: this.store.query('voting-entry', { matchupId: params.matchupId }),
      ballotMatchupSummaries: this.store.query('ballot-matchup-summary', {}),
      nominationEntriesSummaries: this.store.query('nomination-entries-summary', { matchupId: params.matchupId }),
    });

    return {
      ...data,
      matchups: data.matchups.sortBy('displayOrder'),
      dipsUrl: this.settings.getFor('dips_url'),
      matchupGroups: data.matchupGroups.toArray().sortBy('displayOrder'),
      votingEntries: data.votingEntries.toArray(),
      ballotMatchupSummaries:
        this.settings.getValueFor('category_sort_criteria') == '1'
          ? data.ballotMatchupSummaries.toArray().sort(alphabeticalByProperty('name'))
          : data.ballotMatchupSummaries.toArray().sortBy('displayOrder'),
    };
  },

  setupController(controller, model) {
    const { ballotMatchupSummaries } = model;
    if (this.openModal) {
      set(controller, 'showModal', true);
      set(controller, 'openModal', null);
      set(this, 'openModal', undefined);
    }
    set(controller, 'allMatchupGroupViews', createMatchupGroupViews(ballotMatchupSummaries, controller.matchupId));
    set(controller, 'matchupStatusCounts', createMatchupStatusCounts(ballotMatchupSummaries));
    set(
      controller,
      'pendingMatchupGroupViews',
      createMatchupGroupViews(ballotMatchupSummaries.filterBy('pendingCount'), controller.matchupId)
    );

    return this._super(...arguments);
  },

  redirect(model, transition) {
    const firstMatchup = get(model.matchups.sortBy('matchupGroup.displayOrder', 'displayOrder'), 'firstObject');
    const matchupId = transition.to.queryParams.matchupId || get(firstMatchup, 'id');
    return this.router.transitionTo({ queryParams: { matchupId } });
  },
  //endregion

  //region Methods
  async updateMatchupStatusCounts() {
    const ballotMatchupSummaries = await this.store.query('ballot-matchup-summary', {
      organizationPromotionId: this.controller.organizationPromotion.id,
    });

    set(
      this,
      'controller.matchupStatusCounts',
      createMatchupStatusCounts(ballotMatchupSummaries.toArray(), this.controller.matchupId)
    );
  },
  //endregion

  //region Actions
  actions: {
    updateMatchupStatusCounts() {
      this.updateMatchupStatusCounts();
    },

    // Disable the loading route template
    loading() {
      return false;
    },
  },
  //endregion
});
