/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import clone from 'lodash/clone';
import flatten from 'lodash/flatten';

function getId(mj) {
  return mj.attributes['template-builder-id'];
}

function cloneWithoutChildren(mj) {
  const childlessMj = clone(mj);
  delete childlessMj.children;
  return childlessMj;
}

function flatChildren(mj) {
  if (!mj.children) {
    return [];
  }
  return flatten(mj.children.map(child => [cloneWithoutChildren(child), ...flatChildren(child)]));
}

export default Component.extend({
  //region Attributes
  mjml: null,
  'template-components': null,
  'template-tokens': null,
  'get-component-name'() {},
  'select-component'() {},
  'clear-selected-component'() {},
  'highlight-component'() {},
  'clear-highlighted-component'() {},
  //endregion

  //region Properties
  openSectionId: '',
  //endregion

  //region Computed Properties
  treeComponents: computed('mjml', function () {
    const { mjml } = this;
    if (!mjml.children) {
      return [];
    }
    const body = mjml.children.findBy('tagName', 'mj-body');
    if (!body || !body.children) {
      return [];
    }
    return this.mjChildrenToTreeView(body.children);
  }),
  //endregion

  //region Methods
  mjChildrenToTreeView(children) {
    return children.filter(getId).map(child => {
      const id = getId(child);
      const tagsWithContentBlocks = ['ss-button', 'ss-text'];
      const { icon } = this['template-components'].findBy('tag', child.tagName);
      return {
        id,
        name:
          child.tagName === 'ss-image'
            ? this.findContentBlockName(child.attributes.src)
            : tagsWithContentBlocks.includes(child.tagName)
            ? this.findContentBlockName(child.content)
            : this['get-component-name'](id),
        icon,
        children: !child.children ? undefined : this.mjChildrenToTreeView(flatChildren(child)),
      };
    });
  },

  findContentBlockName(content) {
    const [, contentKey] = content.match(/\{\{(.+)\}\}/);
    const templateTokens = this['template-tokens'];
    const token = templateTokens.findBy('key', contentKey);
    return get(token, 'name') || '';
  },
  //endregion

  //region Actions
  actions: {
    setOpenSectionId(id) {
      set(this, 'openSectionId', this.openSectionId === id ? '' : id);
    },
  },
  //endregion
});
