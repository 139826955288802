/* eslint-disable ember/no-mixins */
import Route from '@ember/routing/route';
import AppContextRoute from 'partner/mixins/app-context-route';
import { inject as service } from '@ember/service';

import type Store from '@ember-data/store';
import type { ModelFrom } from 'partner/utils/types';
import type CurrentService from 'partner/services/current';

export type Model = ModelFrom<OrganizationsOrganizationStripeRoute>;

export default class OrganizationsOrganizationStripeRoute extends Route.extend(AppContextRoute) {
  @service declare store: Store;
  @service declare current: CurrentService;

  async model() {
    const organizationId = this.current.organization.id;
    const organizationPaymentProcessors = await this.store.query('organization-payment-processor', { organizationId });

    return { organizationPaymentProcessors };
  }
}

declare module '@ember/routing/route' {
  interface Registry {
    'organizations.organization.stripe': OrganizationsOrganizationStripeRoute;
  }
}
