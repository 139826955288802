/**
 * Check whether the value lies in within the start and end.
 * @param {Date} value
 * @param {Date} start
 * @param {Date?} end
 * @returns {boolean}
 */
export function dateInRange(value, start, end) {
  return value >= start && (!end || value <= end);
}
