/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;

export default BaseModel.extend({
  //region Properties
  masterMessageCampaignId: attr('number'),
  syndicateMessageCampaignId: attr('number'),
  syndicateOrganizationId: attr('number'),
  useSyndicateDefaultTokenContent: attr('boolean'),
  //endregion
});
