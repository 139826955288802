/* eslint-disable ember/no-mixins */
import Route from '@ember/routing/route';
import AppContextRoute from 'partner/mixins/app-context-route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import enums from 'ember-cli-ss-enums/services/enums';
import type SettingsService from 'partner/services/settings';
import type Store from '@ember-data/store';
import type CurrentService from 'partner/services/current';
import type { ModelFrom } from 'partner/utils/types';

export type Model = ModelFrom<OrganizationsOrganizationDoubleOptinConfirmationRoute>;

const messageCampaignTypeId = enums.findWhere('MESSAGE_CAMPAIGN_TYPE', { name: 'DoubleOptinConfirmation' });
const messageCampaignPresetId = enums.findWhere('MESSAGE_CAMPAIGN_PRESETS', { name: 'DoubleOptinConfirmation' });

export default class OrganizationsOrganizationDoubleOptinConfirmationRoute extends Route.extend(AppContextRoute) {
  @service declare settings: SettingsService;
  @service declare store: Store;
  @service declare current: CurrentService;

  async model() {
    const { id: organizationId } = this.current.organization;
    const results = (
      await this.store.query('message-campaign', {
        messageCampaignTypeId,
        organizationId,
      })
    ).toArray();
    let messageCampaign =
      results.find(messageCampaign => organizationId == messageCampaign.organizationId) || results.firstObject;

    let messageCampaignId = messageCampaign.id;

    if (messageCampaign.organizationId !== organizationId) {
      messageCampaign = await this.#createMessageCampaign();

      messageCampaignId = messageCampaign.id;
    }

    return RSVP.hash({
      _messages: this.store.query('message', { messageCampaignId, messageCampaignTypeId }),
      _settings: this.settings.preload('dips_url'),
      senderAccounts: this.store.query('sender-account', { organizationId }),
      tokens: this.store.query('token', { organizationId }),
      templates: this.store.query('message-body-template', { organizationId }),
      tokenContents: this.store
        .query('message-version-template-token-content', { messageCampaignId })
        .then(x => x.toArray()),
      messageCampaign,
    });
  }

  async #createMessageCampaign() {
    const messageCampaign = this.store.createRecord('message-campaign', { messageCampaignPresetId });
    await messageCampaign.save();
    return messageCampaign;
  }
}
