/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { set } from '@ember/object';

export default Component.extend({
  //region Attributes
  isLoading: false,
  showBorders: true,
  //endregion

  //region Ember Hooks
  classNames: ['material-list'],
  classNameBindings: ['isLoading', 'showBorders'],
  //endregion

  //region Actions
  actions: {
    changePage(page) {
      set(this, 'paging.pageIndex', page);
    },
  },
  //endregion
});
