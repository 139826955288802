/* eslint-disable ember/no-computed-properties-in-native-classes */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';

/**
 * @deprecated use partner/components/quiz/question-options instead
 */
export default class QuestionOptions extends Component {
  @service('store') store;
  @service screen;

  /**
   * Holds a reference to the currently active question option, and is passed
   * down to each question option component so they can close themselves.
   * @type {QuestionOptionComponent?}
   */
  @tracked activeQuestionOption = null;

  get questionOptions() {
    return this.args['question-options'] || [];
  }

  @computed('sortedQuestionOptions.length', 'screen.width')
  get locateAddItem() {
    if (this.screen.width > 960) {
      return this.sortedQuestionOptions.length % 4 == 0;
    }
    return this.sortedQuestionOptions.length % 2 == 0;
  }

  @computed('questionOptions.@each.{displayOrder,isDeleted}')
  get sortedQuestionOptions() {
    return this.questionOptions.reject(option => option.isDeleted).sortBy('displayOrder');
  }

  @action
  createOption() {
    const displayOrder =
      this.questionOptions.reduce(
        (pValue, option) => (option.displayOrder ? Math.max(pValue, option.displayOrder) : pValue),
        0
      ) + 1;

    const newQuestionOption = this.store.createRecord('questionOption', {
      displayOrder,
      name: `Option ${displayOrder}`,
      isJustCreated: true,
    });

    this.questionOptions.addObject(newQuestionOption);

    return newQuestionOption;
  }

  @action
  reorderQuestionOptions(questionOptions) {
    questionOptions.forEach((questionOption, index) => {
      questionOption.displayOrder = index + 1;
    });
  }

  /**
   * Notify the component that one of its children is changing edit state
   * @function questionOptionIsEditing
   * @param {Boolean} isEditing - Whether the changed Question Option is going to the edit state or not
   * @param {QuestionOptionComponent} component - The changing Question Option's component
   */
  @action
  questionOptionIsEditing(isEditing, component) {
    this.activeQuestionOption = isEditing ? component : null;
  }

  @action
  questionBranchingSelected(sourceQuestionOption, targetQuestionId) {
    this.args['question-branching-selected'](sourceQuestionOption, targetQuestionId);
  }
}
