/* eslint-disable ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { inject as controller } from '@ember/controller';
import { computed, set } from '@ember/object';
import { bool, mapBy, readOnly, alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { EMAIL_DYNAMIC_TOKENS } from 'partner/utils/constants';
import RSVP from 'rsvp';
import { insertIf } from 'secondstreet-common/utils/functional';

const buildViewsFor = (type, messageCampaigns) =>
  messageCampaigns.filterBy('messageCampaignType', type).map(messageCampaign => ({
    messageCampaign,
    messageVersions: messageCampaign.messages.firstObject.messageVersions,
  }));

const PAYMENT_FORM_DYNAMIC_TOKENS = {
  Receipt: ['Order'],
  Invite: ['Product'],
};

const BASE_TOKEN_KEYS = [
  'Promotion.Name',
  'Promotion.NameEncoded',
  'Promotion.Url',
  'Promotion.UrlEncoded',
  'Promotion.UserReferralUrl',
  'Promotion.UserReferralUrlEncoded',
];

export default Controller.extend({
  //region Ember Hooks
  queryParams: ['messageVersionId'],
  //endregion

  //region Dependencies
  enums: service(),
  snackbar: service(),
  features: service(),
  organizationController: controller('organizations.organization'),
  organizationPromotionController: controller(
    'organizations.organization.organization-promotions.organization-promotion'
  ),
  organizationPromotionSetupController: controller(
    'organizations.organization.organization-promotions.organization-promotion.setup'
  ),
  permissions: service(),
  //endregion

  //region Properties
  messageVersionId: null,
  isAnythingSaving: false,
  isUpdatingRecipientsCount: false,
  //endregion

  //region Computed Properties
  modelMessageCampaigns: alias('model.messageCampaigns'),
  modelMessageCampaignsMessages: mapBy('modelMessageCampaigns', 'messages'),
  messages: mapBy('modelMessageCampaignsMessages', 'firstObject'),
  modelMessageVersions: mapBy('messages', 'messageVersions'),
  messageVersions: mapBy('modelMessageVersions', 'firstObject'),
  orgHasInviteEmails: readOnly('features.hasInviteEmails'),
  orgHasThankYouEmails: readOnly('features.hasThankYouEmails'),
  orgHasReminderEmails: readOnly('features.hasReminderEmails'),
  promoTypeHasReminderEmails: readOnly('organizationPromotionController.promotion.hasReminderEmails'),
  promoTypeHasReceiptEmails: readOnly('organizationPromotionController.promotion.hasReceiptEmails'),
  registrationEnabled: bool('organizationPromotionSetupController.model.registrationEnabledSetting.value'),
  supportInfo: computed('model.designTokens.@each.value', function () {
    return this.model.designTokenContents.findBy('token.key', 'supportInfo')?.value;
  }),
  productName: computed('model.designTokens.@each.value', function () {
    return this.model.designTokenContents.findBy('token.key', 'productName')?.value;
  }),
  activeMessageVersion: computed('messageVersions.[]', 'messageVersionId', function () {
    return this.messageVersions.findBy('id', this.messageVersionId);
  }).readOnly(),

  activeMessageCampaignId: readOnly('activeMessageVersion.message.messageCampaign.id'),
  activeMessageCampaign: computed('activeMessageCampaignId', 'modelMessageCampaigns.length', function () {
    return this.modelMessageCampaigns.findBy('id', this.activeMessageCampaignId);
  }).readOnly(),
  activeMessageCampaignAudiences: computed(
    'model.messageCampaignAudiences.@each.messageCampaign',
    'activeMessageCampaign',
    function () {
      return this.model.messageCampaignAudiences.filterBy('messageCampaign', this.activeMessageCampaign);
    }
  ),

  hasMessaging: readOnly('organizationController.model.organization.hasMessaging'),

  messageCampaignTypeViews: computed(
    'modelMessageCampaigns.[]',
    'promoTypeHasReminderEmails',
    'orgHasInviteEmails',
    'orgHasThankYouEmails',
    'orgHasReminderEmails',
    function () {
      return [
        ...insertIf(this.orgHasThankYouEmails && this.registrationEnabled && !this.promoTypeHasReceiptEmails, {
          type: 'ThankYou',
          emptyStateButtonText: 'Add Thank You Email',
          messageCampaignViews: buildViewsFor('ThankYou', this.modelMessageCampaigns),
        }),
        ...insertIf(this.promoTypeHasReceiptEmails, {
          type: 'Receipt',
          emptyStateButtonText: 'Add Receipt Email',
          messageCampaignViews: buildViewsFor('Receipt', this.modelMessageCampaigns),
        }),
        ...insertIf(this.orgHasReminderEmails && this.promoTypeHasReminderEmails, {
          type: 'Reminder',
          emptyStateButtonText: 'Add Reminder Email',
          messageCampaignViews: buildViewsFor('Reminder', this.modelMessageCampaigns),
        }),
        ...insertIf(this.orgHasInviteEmails, {
          type: 'Invite',
          emptyStateButtonText: 'Add Invite Email',
          canAddMoreMessageCampaigns: true,
          messageCampaignViews: buildViewsFor('Invite', this.modelMessageCampaigns),
        }),
      ];
    }
  ),
  emailDesignerDisabled: computed(
    'promoTypeHasReminderEmails',
    'modelMessageCampaigns',
    'permissions',
    'orgHasInviteEmails',
    'orgHasThankYouEmails',
    'orgHasReminderEmails',
    'registrationEnabled',
    function () {
      const thankYouEmailNotDisplayed = !this.orgHasThankYouEmails || !this.registrationEnabled;
      const reminderEmailNotDisplayed = !this.orgHasReminderEmails || !this.promoTypeHasReminderEmails;
      const notAdminAndNoMessageCampaigns =
        isEmpty(this.modelMessageCampaigns) && !this.permissions.getAccessLevel('MessageCampaign').administer;

      return (
        (thankYouEmailNotDisplayed && reminderEmailNotDisplayed && !this.orgHasInviteEmails) ||
        notAdminAndNoMessageCampaigns
      );
    }
  ),
  promotionEmailDynamicTokens: computed('activeMessageCampaign', function () {
    const dynamicTokens = this.model.tokens.filterBy('dynamicTokenType');

    const baseTokens = [
      ...dynamicTokens.filter(token => EMAIL_DYNAMIC_TOKENS.includes(token.dynamicTokenType)),
      ...dynamicTokens.filter(token => BASE_TOKEN_KEYS.includes(token.key)),
    ];

    switch (this.organizationPromotionController.promotion.promotionType) {
      case 'VotingBracket':
        return [
          ...baseTokens,
          ...dynamicTokens.filter(token =>
            [
              'Promotion.SelectionStartDate',
              'Promotion.SelectionEndDate',
              'Promotion.CurrentRoundEndDate',
              'Promotion.CurrentRoundNumber',
            ].includes(token.key)
          ),
        ];
      case 'EventSignup':
        return [...baseTokens, ...dynamicTokens.filter(token => ['Event'].includes(token.dynamicTokenType))];
      case 'PaymentForm':
        return [
          ...baseTokens,
          ...dynamicTokens.filter(token => ['Promotion.SupportInfo'].includes(token.key)),
          ...dynamicTokens.filter(token =>
            PAYMENT_FORM_DYNAMIC_TOKENS[this.activeMessageCampaign.messageCampaignType].includes(token.dynamicTokenType)
          ),
        ];
      case 'UGCSweepstakes':
      case 'Sweepstakes':
      case 'UGCGallery':
        return [
          ...baseTokens,
          ...dynamicTokens.filter(token =>
            ['Promotion.EntryStartDate', 'Promotion.EntryEndDate', 'Promotion.EntryFrequency'].includes(token.key)
          ),
        ];
      case 'Ballot':
      case 'UGCVoting':
        return [
          ...baseTokens,
          ...dynamicTokens.filter(token =>
            [
              'Promotion.SelectionStartDate',
              'Promotion.SelectionEndDate',
              'Promotion.SelectionFrequency',
              'Promotion.EntryStartDate',
              'Promotion.EntryEndDate',
              'Promotion.EntryFrequency',
            ].includes(token.key)
          ),
        ];
      default:
        return baseTokens;
    }
  }),
  //endregion

  //region Actions
  actions: {
    updateChecklistStep() {
      this.send('emailChecklistStepLogic');
    },
    setActiveCampaign(messageCampaignView) {
      set(this, 'messageVersionId', messageCampaignView.messageVersions.firstObject.id);

      if (this.activeMessageCampaign.cannotCurrentlyEdit) {
        this.snackbar.show('This message has been sent and can no longer be edited.', '', -1);
      }
    },

    removeMessageCampaign(messageCampaignToBeDeleted) {
      const { messageCampaigns, messageCampaignAudiences } = this.model;

      //when deleting message campaigns, status type change is the only thing the API really needs
      set(messageCampaignToBeDeleted, 'statusTypeId', this.enums.findWhere('STATUS_TYPE', { name: 'Deleted' }));
      const associatedAudiences = messageCampaignAudiences.filterBy('messageCampaign', messageCampaignToBeDeleted);
      this.send('updatePromotionEmails', 'removeObject', messageCampaignToBeDeleted);
      this.send('updateDisplayedPromotionEmails', 'removeObject', messageCampaignToBeDeleted);
      messageCampaignAudiences.removeObjects(associatedAudiences);
      messageCampaignToBeDeleted.saveOrDestroy();

      //if we removed the only unconfirmed message campaign or the last message campaign, we need to update the checklist step
      this.send('emailChecklistStepLogic');

      //we only need to change the query param if the message campaign that was removed was the active message campaign
      const messageVersionToBeDeleted = messageCampaignToBeDeleted.messages.firstObject.messageVersions.firstObject;
      if (messageVersionToBeDeleted.id !== this.messageVersionId) {
        return;
      }

      if (isEmpty(messageCampaigns)) {
        return set(this, 'messageVersionId', null);
      }

      //we should first try to transition to the first message version of the same type as the message campaign that was removed
      //if the removed message campaign was the last of its type, we need to transition to the first message version of the first type
      const similarMessageCampaigns = messageCampaigns.filterBy(
        'messageCampaignType',
        messageCampaignToBeDeleted.messageCampaignType
      );
      const transitionToMessageCampaign = similarMessageCampaigns.firstObject || messageCampaigns.firstObject;
      const newActiveMessageVersion = transitionToMessageCampaign.messages.firstObject.messageVersions.firstObject;
      return set(this, 'messageVersionId', newActiveMessageVersion.id);
    },
    async save(design) {
      set(this, 'isAnythingSaving', true);
      try {
        const dirtyTokenContents = design.tokenContents.filterBy('hasDirtyAttributes');
        await RSVP.all(dirtyTokenContents.map(x => x.save()));
        if (design.hasDirtyAttributes) {
          await design.save();
        }
      } finally {
        set(this, 'isAnythingSaving', false);
      }
    },
    updateAudienceModel(updateAction, newAudience) {
      this.model.messageCampaignAudiences[updateAction](newAudience);
    },
  },
  //endregion
});
