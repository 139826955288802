import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class InheritableModel extends BaseModel {
  @attr('boolean') declare isInherited: boolean;

  /**
   * Inherited models cannot be saved directly. They need to be "uninherited" first,
   * which means that instead of updating the existing model, we create a new one
   */
  save(options?: { adapterOptions?: object | undefined }) {
    if (this.isDirty && this.isInherited) {
      this.uninherit();
    }

    return super.save(options);
  }

  /**
   * Roll back the model, including restoring its previously inherited state if it had one.
   */
  rollbackAttributes() {
    super.rollbackAttributes();

    // We are manually marking the record as "new" during the save process,
    // which means that we need to revert this operation manually here as well.
    // This is a private API and may change.
    // @ts-ignore
    this.currentState.recordData._isNew = false;
  }

  private uninherit() {
    if (!this.isInherited) return;

    // We want to mark the record as "new" as this will trigger a POST request
    // instead of a PUT request, which will create a new record instead of
    // updating the existing one.
    // Sadly, this is a private API and may change.
    // @ts-ignore
    this.currentState.recordData._isNew = true;

    this.isInherited = false;
  }
}
