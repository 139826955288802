import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * Legacy dashboard route which only serves to redirect user to new dashboard route
 * @type {Ember.Route}
 */
export default class DashboardRoute extends Route {
  @service router;

  redirect() {
    this.router.transitionTo('organizations.organization.dashboard');
  }
}
