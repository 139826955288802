import { get, getProperties, set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import Renderer from 'isomorphic-template-renderer';
import ENV from 'partner/config/environment';
import * as React from 'react';
import OptinComponent from 'ss-embed';

const PREVIEW_CONTRAST_COLOR = '#000';
const PREVIEW_FONT = 'Muli';

const tokenContentProps = [
  'title',
  'value',
  'linkUrl',
  'altText',
  'itemTitle',
  'itemImageLinkUrl',
  'itemButtonLinkUrl',
  'itemButtonText',
  'isDisabled',
  'mediaItemId',
  'itemImageExternalSrcUrl',
  'displayOrder',
  'itemPublishDate',
  'messageVersionFeedId',
];

const messageFeedProps = [
  'sectionHeaderText',
  'buttonText',
  'sectionMediaItemId',
  'sectionImageExternalSrcUrl',
  'sectionImageAltText',
  'sectionImageLinkUrl',
  'isHeadlineEnabled',
  'isImageEnabled',
  'isDescriptionEnabled',
  'isButtonEnabled',
  'isPublishDateEnabled',
  'statusTypeId',
  'items',
  'id',
];

const getTokenContentsData = tokenContents =>
  tokenContents
    .map(tokenContent => ({
      tokenKey: tokenContent.token.key,
      tokenTypeId: tokenContent.token.tokenTypeId,
      tokenContentTypeId: tokenContent.token.tokenContentTypeId,
      tokenAllowMultiple: tokenContent.token.allowMultiple,
      ...getProperties(tokenContent, ...tokenContentProps),
    }))
    .sortBy('displayOrder');

const dynamicTokenKeys = tokens => tokens.filterBy('tokenType', 'Dynamic').mapBy('key');

const messageVersionFeedData = messageVersionFeeds => {
  const feeds = messageVersionFeeds
    .rejectBy('isDisabled')
    .rejectBy('isFeedUrlDirty')
    .map(feed => getProperties(feed, ...messageFeedProps));
  feeds.rejectBy('items').forEach(feed => set(feed, 'items', []));
  feeds.forEach(feed => set(feed, 'id', parseInt(feed.id, 10)));
  return feeds;
};

const templateRendererData = (dipsUrlValue, templateContent, tokenContents, tokens, messageVersionFeeds = []) => [
  `https://${dipsUrlValue}`,
  templateContent,
  getTokenContentsData(tokenContents),
  dynamicTokenKeys(tokens),
  messageVersionFeedData(messageVersionFeeds),
];

const generateRenderedContent = (design, tokens, dipsUrlValue = '') =>
  Renderer.render(
    ...templateRendererData(
      dipsUrlValue,
      get(design, 'template.templateContent'),
      design.tokenContents,
      tokens,
      design.messageVersionFeeds
    )
  );

function getWidgetFormData(widgetForm) {
  const formPage = widgetForm.formPages.firstObject;
  const formFields = formPage.formFields.map(field => ({
    id: parseInt(field.id),
    display_order: field.displayOrder,
    field_id: parseInt(field.field.id),
    form_page_id: parseInt(field.formPage.id),
    is_required: field.isRequired,
    label_text: field.labelText,
    default_value: field.defaultValue,
    fields: {
      id: parseInt(field.field.id),
      field_type_id: parseInt(field.field.fieldTypeId),
    },
  }));
  const formPages = [
    {
      id: parseInt(formPage.id),
      form_id: parseInt(formPage.form.id),
      form_fields: formFields.sortBy('display_order'),
    },
  ];
  return {
    id: parseInt(widgetForm.id),
    form_pages: formPages,
  };
}

const getWidgetData = (design, dipsUrl, widgetForm) => {
  const tokenContentsData = getTokenContentsData(design.tokenContents);

  const findTokenContentsDataValueFor = tokenKey => {
    const token = tokenContentsData.findBy('tokenKey', tokenKey);
    return token && !token.isDisabled ? token.value : '';
  };

  const findTokenContentsDataValueForImage = () => {
    const token = tokenContentsData.findBy('tokenKey', 'signupImage');
    if (token.isDisabled) {
      return '';
    }
    return isEmpty(token.value) ? `https://${dipsUrl.value}/${token.mediaItemId}` : token.value;
  };

  const findTokenContentSignupOptins = () => {
    const optins = design.tokenContents.filter(
      tokenContent => tokenContent.token.key === 'signupOptin' && !tokenContent.isDisabled
    );
    return optins.map(optin => ({
      field_id: optin.fieldId,
      value: optin.value,
    }));
  };

  const findTokenContentForThanks = () => {
    const token = tokenContentsData.findBy('tokenKey', 'signupThanksMessage');
    return {
      message: token?.value || '',
      buttonText: token?.title || '',
      buttonUrl: token?.linkUrl || '',
    };
  };

  return {
    additionalCss: findTokenContentsDataValueFor('additionalCss'),
    contrastColor: PREVIEW_CONTRAST_COLOR,
    googleFont: PREVIEW_FONT,
    optins: findTokenContentSignupOptins(),
    primaryColor: findTokenContentsDataValueFor('primaryColor'),
    signupButtonText: findTokenContentsDataValueFor('signupButtonText'),
    signupHeader: findTokenContentsDataValueFor('signupHeader'),
    signupImage: findTokenContentsDataValueForImage(),
    signupLabel: findTokenContentsDataValueFor('signupLabel'),
    signupSubheader: findTokenContentsDataValueFor('signupSubheader'),
    signupThanksButtonText: findTokenContentForThanks().buttonText,
    signupThanksMessage: findTokenContentForThanks().message,
    signupThanksRedirectUrl: findTokenContentForThanks().buttonUrl,
    text: {
      saved: 'Saved!',
      saving: 'Saving...',
      savingError: 'Error!',
    },
    forms: [getWidgetFormData(widgetForm)],
    // eslint-disable-next-line ember/use-ember-get-and-set
    widgetType: design.template.get('designTemplateTypeId'),
  };
};

const generateWidgetPreview = (design, dipsUrl, widgetForm, showThanks = false) =>
  React.createElement(OptinComponent, {
    data: getWidgetData(design, dipsUrl, widgetForm),
    apiUrl: `${ENV.APP.API_HOST}/${ENV.APP.API_NAMESPACE}`,
    designId: design.id,
    unstyled: false,
    showThanks,
    isPreview: true,
    formSubmitted: () => {},
    optinChanged: () => {},
    sizeChanged: () => {},
    text: {
      saved: 'Saved!',
      saving: 'Saving...',
      savingError: 'There was a problem. Please try again.',
      required: 'Required',
    },
  });

export { generateRenderedContent, generateWidgetPreview, templateRendererData };
