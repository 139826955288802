import { ParamsType } from 'partner/services/preview';
import { ugcEntrantPage } from './ugc-photo';

const getThanksPreview = (params?: ParamsType) => {
  return {
    id: 'box-1119',
    uniqueTypeNumber: '1119',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'box-1120',
        uniqueTypeNumber: '1120',
        deletable: true,
        type: 'promotion-box',
        blocks: [
          {
            id: 'heading-21',
            uniqueTypeNumber: '21',
            deletable: true,
            type: 'promotion-heading',
            blocks: [],
            data: {
              level: 1,
              text: 'CLOSE',
              additionalCss: '.arc-promotion-promotion-0 .arc-heading-heading-21 {\n     \n  }\n  \n  ',
            },
            styles: {
              font: {
                color: '#2196f3',
                weight: 400,
                family: 'Open Sans',
                size: '14',
                align: 'end',
              },
              size: {
                custom: false,
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
          {
            id: 'text-1',
            uniqueTypeNumber: '1',
            deletable: true,
            type: 'promotion-text',
            blocks: [],
            data: {
              text: params?.message,
              additionalCss: '.arc-promotion-promotion-0 .arc-text-text-1 {\n     \n  }\n  \n  ',
            },
            styles: {
              font: {
                color: '#000000',
                weight: 400,
                family: 'Open Sans',
              },
              spacing: {
                marginBlock: '20',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
        ],
        data: {
          additionalCss:
            '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-1120 {\n  width: 75%;    \n  }\n  \n  ',
        },
        styles: {
          layout: {
            display: 'flex',
            direction: 'column',
          },
          size: {
            custom: true,
            height: '200',
          },
          spacing: {
            paddingBlock: '10',
            paddingInline: '30',
            marginBlock: '20',
            marginInline: '20',
          },
          border: {
            radius: '4',
            style: 'solid',
            width: 1,
            color: '#c2c6cc',
          },
          boxShadow: {
            hasShadow: true,
            color: '#000000',
            x: '0',
            y: '0',
            blur: '10',
            spread: '0',
          },
          effects: {
            opacity: '',
          },
          background: {
            type: 'color',
            color: '#ffffff',
            opacity: 100,
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss:
        '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-1119 {\n  position: absolute; z-index: 1; max-width: 960px; \n top: 0;   \n  }\n  \n  ',
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        justify: 'center',
      },
      background: {
        type: 'color',
        color: '#ffffff',
        opacity: '50',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};
export default (params?: ParamsType) => {
  const galleryView = ugcEntrantPage({ ...(params ? params : {}), isPending: true });
  if (!params?.hasThanksMessage) {
    return galleryView;
  }
  return [...galleryView, getThanksPreview(params)];
};
