/* eslint-disable ember/no-mixins */
import AppContextRoute from 'partner/mixins/app-context-route';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type { ModelFrom } from 'partner/utils/types';
import type Store from '@ember-data/store';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import type CurrentService from 'partner/services/current';
import RSVP from 'rsvp';

export type Model = ModelFrom<OrganizationsOrganizationFormsIndexRoute>;

export default class OrganizationsOrganizationFormsIndexRoute extends Route.extend(AppContextRoute) {
  @service declare store: Store;
  @service declare enums: EnumsService;
  @service declare current: CurrentService;

  beforeModel() {
    this.store.unloadAll('form-default-setting');
  }

  async model() {
    const { id: organizationId } = this.current.organization;

    const { forms, formDefaultSettings } = await RSVP.hash({
      forms: this.store
        .query('form', {
          organizationId,
          registrationOnly: true,
        })
        .then(forms => forms.toArray()),
      formDefaultSettings: this.store
        .query('form-default-setting', { organizationId })
        .then(formDefaultSetting => formDefaultSetting.toArray()),
    });

    return {
      forms: forms.reject(
        form => form.formTypeId === this.enums.findWhere('FORM_TYPE', { name: 'EmailOptinPreferences' })
      ),
      defaultFormForCopyId: formDefaultSettings.findBy('promotionTypeId', 5)?.defaultFormId ?? -701,
      formDefaultSettings,
    };
  }
}
