import { set, get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { categoricalChartCategories } from 'secondstreet-common/utils/data-manipulation';
import RSVP from 'rsvp';

export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionDashboardShareRoute extends Route {
  @service('session') session;
  @service('store') store;
  @service('email-notification') emailNotificationService;
  @service('settings') settings;
  @service current;
  @service('enums') enums;

  async model() {
    const { organization } = this.current;
    const organizationPromotionId = this.current.organizationPromotion.id;
    const { designs, emailNotifications } = await this.findOrCreateDesigns(
      this.store,
      organizationPromotionId,
      organization
    );

    const hash = await RSVP.hash({
      categoricalCharts: this.store.query('categorical-chart', this.current.toSyndicatedParams()),
      designTokenContents: designs.length
        ? await Promise.all(
            designs.map(d =>
              this.store.query('design-token-content', {
                designId: d.id,
                isSyndicated: this.current.promotion.isSyndicated,
              })
            )
          )
        : [],
      designs,
      _settings: this.settings.preload('dips_url'),
      organization,
      organizationPromotion: this.modelFor('organizations.organization.organization-promotions.organization-promotion')
        .organizationPromotion,
      emailNotifications: emailNotifications.toArray(),
    });

    return {
      ...hash,
      dipsUrl: this.settings.getFor('dips_url'),
    };
  }

  async findOrCreateDesigns(store, organizationPromotionId, organization) {
    let designs;

    const designTemplateTypeId = this.enums.findWhere('TEMPLATE_TYPE', { name: 'Dashboard Share' });

    const { dashboardShareDesigns, designTemplates, emailNotifications } = await RSVP.hash({
      dashboardShareDesigns: store
        .query('design', { organizationPromotionId, designTemplateType: designTemplateTypeId })
        .then(x => x.toArray()),
      designTemplates: store.query('design-template', {
        organizationId: organization.id,
        designTemplateTypeId,
      }),
      emailNotifications: store.query('email-notification', { emailNotificationTypeId: 1 }),
    });
    const designTemplate = designTemplates.findBy('id', '41'); //TODO: this needs to be dynamic based on promo type! Might require new enums

    if (!dashboardShareDesigns.length) {
      const newDashboardShareDesign = store.createRecord('design');
      set(newDashboardShareDesign, 'designTemplate', designTemplate);
      try {
        await newDashboardShareDesign.save();
        designs = [newDashboardShareDesign];
      } catch (e) {
        console.error(e);
      }
    } else {
      designs = dashboardShareDesigns;
    }

    return { designs, emailNotifications };
  }

  afterModel(hash) {
    // For easier debugging, this IS intended to make it to production.
    hash.designs.forEach(design => console.info(`Shared Dashboard GUID: ${design.designGuid}`)); //eslint-disable-line no-console

    hash.categoricalCharts.forEach(chart => categoricalChartCategories(chart, this, this.current.toSyndicatedParams()));

    hash.designs.forEach(design => {
      if (!get(design, 'emailNotification')) {
        this.emailNotificationService.createDraftEmailNotification(design, hash.organizationPromotion);
      }
    });
  }
}
