import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';

export default class PerformanceReportFixedHeaderController extends Controller {
  private resizeObserver?: ResizeObserver;

  @tracked fixedHeaderOffset = 0;

  setupOffset = (element: HTMLDivElement) => {
    this.setOffset();
    this.resizeObserver = new ResizeObserver(() => {
      debounce(this, this.setOffset, 100);
    });

    if (element) {
      this.resizeObserver.observe(element);
    }
  };

  cleanupOffset = () => {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  };

  setOffset = () => {
    const selectors = ['.filter-section', '.blvd-paging'];

    this.fixedHeaderOffset =
      88 +
      selectors.reduce((total, selector) => {
        const element = document.querySelector(selector);
        return total + (element?.clientHeight || 0);
      }, 0);
  };
}
