import { attr, belongsTo } from '@ember-data/model';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import BaseModel from 'secondstreet-common/models/base';

@makeBooleanProperties('LOGIC_TYPE')
export default class MatchupLogicCondition extends BaseModel {
  @attr('number') logicTypeId;
  @attr('string') value;
  @attr('date') dateCreated;
  @attr('date') dateModified;
  @attr('boolean') isInherited;

  @belongsTo('matchup-logic', { async: true }) matchupLogic;
}
