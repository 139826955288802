/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash, ember/no-computed-properties-in-native-classes */
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import PerformanceReportCommonRote, { ParamsType } from 'partner/-base/performance-report-route';
import type CurrentService from 'partner/services/current';
import type Store from '@ember-data/store';
import type { ModelFrom } from 'partner/utils/types';
import type PerformanceReportController from 'partner/controllers/organizations/organization/organization-promotions/performance-report';
import RSVP from 'rsvp';

export type Model = ModelFrom<PerformanceReport>;

export default class PerformanceReport extends PerformanceReportCommonRote {
  @service declare store: Store;
  @service declare current: CurrentService;

  async model(params: ParamsType) {
    const { isChain } = this.current.organization;
    const isSummary = isChain && params.view !== 'promotions';

    const { promotionPerformanceStatistics, organizationPromotionStatistics } = await RSVP.hash({
      orgs: isChain && !isSummary ? this.store.findAll('organization') : [],
      organizationPromotionStatistics: isSummary
        ? await this.store.query(
            'organization-promotion-statistic',
            this.assembleQueryParameters({
              ...params,
              page: 1,
              pageSize: 10000,
            })
          )
        : [],
      promotionPerformanceStatistics: !isSummary
        ? this.store.query('promotion-performance-statistic', this.assembleQueryParameters(params))
        : [],
    });

    const organizationPromotionStatisticsArray = organizationPromotionStatistics.toArray();

    if (
      isSummary &&
      organizationPromotionStatisticsArray.length &&
      !organizationPromotionStatisticsArray.findBy('organizationId', this.current.organization.id)
    ) {
      organizationPromotionStatisticsArray.addObject(
        this.store.peekRecord('organization-promotion-statistic', this.current.organization.id) ||
          this.store.createRecord('organization-promotion-statistic', {
            id: this.current.organization.id,
          })
      );
    }

    const { meta } = (isSummary ? organizationPromotionStatistics : promotionPerformanceStatistics) as unknown as {
      meta: { performanceStatisticsTotalCounts: object };
    };

    return {
      promotionPerformanceStatistics: promotionPerformanceStatistics.toArray(),
      organizationPromotionStatistics: organizationPromotionStatisticsArray,
      selectedOrgs: params.organizationIds,
      selectedTypes: params.promotionSubTypeIds,
      meta,
    };
  }

  setupController(controller: PerformanceReportController, model: Model) {
    super.setupController(controller, model);
    set(controller, 'tempSelectedOrgs', model.selectedOrgs);
    set(controller, 'tempSelectedTypes', model.selectedTypes);
  }
}
