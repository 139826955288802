/* eslint-disable @typescript-eslint/restrict-template-expressions, ember/no-computed-properties-in-native-classes */
import { AsyncBelongsTo, AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, and, bool, equal, filterBy, gt } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import enums from 'ember-cli-ss-enums/services/enums';
import moment from 'moment';
import dirtyProperty from 'partner/utils/dirty-property';
import BaseModel from 'secondstreet-common/models/base';
import formatNumber from 'secondstreet-common/utils/format-number';
import type AudienceFilterModel from './audience-filter';
import { inject as service } from '@ember/service';
import type CurrentService from 'partner/services/current';

const regex = /\{\{Organization\.Name\}\}/g;
export default class AudienceModel extends BaseModel {
  @service declare current: CurrentService;
  @attr('number', { defaultValue: 2 }) declare ageOperatorTypeId: number;
  @attr('number', { defaultValue: 2 }) declare fieldOperatorTypeId: number;
  @attr('number', { defaultValue: 2 }) declare genderOperatorTypeId: number;
  @attr('number', { defaultValue: 2 }) declare interestTagOperatorTypeId: number;
  @attr('number', { defaultValue: 2 }) declare locationOperatorTypeId: number;
  @attr('number', { defaultValue: 2 }) declare optInOperatorTypeId: number;
  @attr('number', { defaultValue: 2 }) declare importFileOperatorTypeId: number;
  @attr('number', { defaultValue: 2 }) declare promotionParticipationOperatorTypeId: number;
  @attr('number', { defaultValue: 2 }) declare messageInteractionOperatorTypeId: number;
  @attr('number', { defaultValue: 2 }) declare purchaseBehaviorOperatorTypeId: number;
  @attr('number', { defaultValue: 1 }) declare audienceTypeId: number;
  @attr('number') declare latestCount: number;
  @attr('date') declare latestCountDate: Date;
  @attr('number') declare estimatedCalculationTime: number;
  @attr('string') declare name: string;
  @attr('number') declare statusTypeId: number;
  @attr('string') declare salesSheetUrl: string;
  @attr('boolean') declare isLegacyAudience: boolean;
  @attr('boolean') declare isProcessing: boolean;
  @attr('boolean') declare isCalculating: boolean;
  @attr('date') declare dateCreated: Date;
  @attr('boolean') declare isStarred: boolean;
  @attr('date') declare lastSentDate: Date;
  @attr('string') declare fieldLabelText: string;
  @attr('string') declare fieldHelpText: string;
  @attr('number') declare membersOrganizationId: number;
  @attr('boolean') declare isRecommendedForIntegration: boolean;
  @attr('number') declare optinFieldId: number;

  @tracked isRollingBack = false;

  @belongsTo('organization', { async: true }) declare organization: AsyncBelongsTo<any>;
  @belongsTo('field', { async: true }) declare optinField: AsyncBelongsTo<any>;
  @hasMany('audience-filter', { async: true }) declare audienceFilters: AsyncHasMany<AudienceFilterModel>;
  @hasMany('message-campaign-audience', { async: true }) declare messageCampaignAudience: AsyncHasMany<any>;
  @belongsTo('partner-organization-user') declare createdByOrganizationUser: AsyncBelongsTo<any>;
  @hasMany('audience-integration') declare audienceIntegrations: AsyncHasMany<any>;

  @gt('optinField.globalOptinUserOutgoingMappings.length', 0) declare hasThirdPartyOptin: boolean;

  @computed('dateCreated')
  get isRecentlyCreated() {
    return moment(this.dateCreated).isAfter(moment().subtract(1, 'week'));
  }

  @bool('lastSentDate') declare isSent: boolean;
  @alias('audienceFilters.firstObject.entityId') entityId?: number;

  @enums.computed('name', 'ageOperatorTypeId', 'ageOperatorTypeId', 'OPERATOR_TYPE') declare ageOperatorType: string;
  @enums.computed('name', 'fieldOperatorTypeId', 'fieldOperatorTypeId', 'OPERATOR_TYPE')
  declare fieldOperatorType: string;
  @enums.computed('name', 'genderOperatorTypeId', 'genderOperatorTypeId', 'OPERATOR_TYPE')
  declare genderOperatorType: string;
  @enums.computed('name', 'interestTagOperatorTypeId', 'interestTagOperatorTypeId', 'OPERATOR_TYPE')
  declare interestTagOperatorType: string;
  @enums.computed('name', 'locationOperatorTypeId', 'locationOperatorTypeId', 'OPERATOR_TYPE')
  declare locationOperatorType: string;
  @enums.computed('name', 'optInOperatorTypeId', 'optInOperatorTypeId', 'OPERATOR_TYPE')
  declare optInOperatorType: string;
  @enums.computed(
    'name',
    'promotionParticipationOperatorTypeId',
    'promotionParticipationOperatorTypeId',
    'OPERATOR_TYPE'
  )
  declare promotionParticipationOperatorType: string;
  @enums.computed('name', 'messageInteractionOperatorTypeId', 'messageInteractionOperatorTypeId', 'OPERATOR_TYPE')
  declare messageInteractionOperatorType: string;
  @enums.computed('name', 'statusTypeId') declare statusType: string;

  @filterBy('audienceFilters', 'isDeleted', false) declare maintainedAudienceFilters: AudienceFilterModel[];
  @filterBy('maintainedAudienceFilters', 'audienceFilterType', 'InterestTag')
  declare interestTagFilters: AudienceFilterModel[];
  @filterBy('maintainedAudienceFilters', 'audienceFilterType', 'Age') declare ageFilters: AudienceFilterModel[];
  @filterBy('maintainedAudienceFilters', 'audienceFilterType', 'Gender') declare genderFilters: AudienceFilterModel[];
  @filterBy('maintainedAudienceFilters', 'audienceFilterType', 'Optin') declare optinFilters: AudienceFilterModel[];
  @filterBy('maintainedAudienceFilters', 'audienceFilterType', 'Field') declare fieldFilters: AudienceFilterModel[];
  @filterBy('maintainedAudienceFilters', 'audienceFilterType', 'PromotionParticipation')
  declare promotionParticipationFilters: AudienceFilterModel[];
  @filterBy('maintainedAudienceFilters', 'audienceFilterType', 'MessageInteraction')
  declare messageInteractionFilters: AudienceFilterModel[];
  @filterBy('maintainedAudienceFilters', 'audienceFilterType', 'PurchaseBehavior')
  declare purchaseBehaviorFilters: AudienceFilterModel[];
  @filterBy('maintainedAudienceFilters', 'audienceFilterType', 'AudienceMember')
  declare audienceMemberFilters: AudienceFilterModel[];
  @filterBy('maintainedAudienceFilters', 'audienceFilterType', 'ImportFile')
  declare importFileFilters: AudienceFilterModel[];

  get prettyCount() {
    return `${formatNumber(this.latestCount)} People`;
  }

  get formattedName() {
    return this.name?.replace(regex, this.current.organization.name);
  }

  get formattedFieldLabelText() {
    return this.fieldLabelText?.replace(regex, this.current.organization.name);
  }

  @dirtyProperty('name') declare isNameDirty: boolean;
  @dirtyProperty('fieldLabelText') declare isFieldLabelTextDirty: boolean;
  @dirtyProperty('fieldHelpText') declare isFieldHelpTextDirty: boolean;
  @dirtyProperty('isStarred') declare isStarredDirty: boolean;

  @equal('audienceTypeId', enums.findWhere('AUDIENCE_TYPE', { name: 'Single Optin' }, 'id'))
  declare isSubscriptionAudience: boolean;
  @equal('audienceTypeId', enums.findWhere('AUDIENCE_TYPE', { name: 'Custom Filtered' }, 'id'))
  declare isSegmentedAudience: boolean;
  @equal('audienceTypeId', enums.findWhere('AUDIENCE_TYPE', { name: 'Advertiser' }, 'id'))
  declare isThirdPartyAudience: boolean;
  @equal('statusTypeId', enums.findWhere('STATUS_TYPE', { name: 'InActive' }, 'id')) declare isArchived: boolean;
  @enums.computed('publicName', 'audienceTypeId') declare audienceType: string;

  @and('createdByOrganizationUser.{firstName,lastName}') declare audienceCreatorHasFullName: boolean;

  @computed('audienceCreatorHasFullName', 'createdByOrganizationUser.{firstName,lastName}')
  get audienceCreatorFullName() {
    const fullName = this.audienceCreatorHasFullName;
    const firstName = this.createdByOrganizationUser.get('firstName');
    const lastName = this.createdByOrganizationUser.get('lastName');

    return fullName ? `${firstName} ${lastName}` : '';
  }

  get locationFilters() {
    return this.maintainedAudienceFilters.filter((audienceFilter: AudienceFilterModel) =>
      ['PostalCode', 'StateProvince', 'LocationDistancePostalCode'].includes(audienceFilter.audienceFilterType)
    );
  }

  @gt('audienceIntegrations.length', 0) declare hasExistingIntegration: boolean;

  @computed('audienceIntegrations.@each.integrationExternalServiceId')
  get adestraIntegration() {
    return this.audienceIntegrations.find(
      integration =>
        integration.integrationExternalServiceId ===
        enums.findWhere('AUDIENCE_INTEGRATION_TYPE', { name: 'Adestra' }, 'id')
    );
  }
}
