import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';

interface Message {
  promptText: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export default class DeliberateConfirmationService extends Service {
  @tracked message?: Message;

  private _deferred?: RSVP.Deferred<boolean | null>;

  /**
   * @property {Boolean} showModal indicates if there is a message or not
   */
  get showModal() {
    return Boolean(this.message);
  }

  get promptText() {
    return this.message?.promptText;
  }

  get confirmButtonText() {
    return this.message?.confirmButtonText;
  }

  get cancelButtonText() {
    return this.message?.cancelButtonText;
  }

  /**
   * Create a new DeliberateConfirmationMessage
   */
  show(message: Message | string) {
    if (typeof message === 'string') message = { promptText: message };

    this.message = {
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      ...message,
    };

    this._deferred = RSVP.defer();

    return this._deferred.promise;
  }

  hide() {
    this.message = undefined;
  }

  confirm() {
    this._deferred?.resolve(true);
    this.hide();
  }

  cancel() {
    this._deferred?.resolve(false);
    this.hide();
  }

  resetConfirmedStatus() {
    this._deferred?.resolve(null);
    this._deferred = undefined;
  }
}
