import { isEmpty } from '@ember/utils';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

export function nextDisplayProperties(votingEntries) {
  if (isEmpty(votingEntries)) {
    return { displayColumn: 1, displayOrder: 1 };
  }

  const columns = groupBy(votingEntries, x => x.displayColumn);
  const col1 = columns['1'];
  const col2 = columns['2'];

  if (!col1) {
    return { displayColumn: 1, displayOrder: 1 };
  }

  if (!col2) {
    return { displayColumn: 2, displayOrder: 1 };
  }

  const shortestColumn = col1.length !== col2.length ? (col1.length < col2.length ? '1' : '2') : null;
  const lowestColumn =
    shortestColumn ||
    sortBy(Object.keys(columns), k => columns[k].mapBy('displayOrder').sort().reverse()[0]).firstObject;
  const nextDisplayOrder = columns[lowestColumn].mapBy('displayOrder').sort().reverse()[0] + 1;

  return { displayColumn: parseInt(lowestColumn), displayOrder: parseInt(nextDisplayOrder) };
}
