import { computed, get } from '@ember/object';
import { pluralize, singularize } from 'ember-inflector';
import moment from 'moment';
import { days, parseMonthly, parseYearly } from 'partner/utils/recurring-pattern';
import { ordinalize, oxfordList } from 'secondstreet-common/utils/english';
import { DeveloperError } from 'secondstreet-common/utils/errors';

const SUPPORTED_FORMATS = ['hh:mma', 'h:mma', 'hh:mm a', 'h:mm a', 'ha', 'h a', 'hha', 'hh a'];

const computeDirectionText = scheduleDelayValue => {
  if (scheduleDelayValue < 0) {
    return 'before';
  }
  if (scheduleDelayValue === 0) {
    return 'on';
  }
  if (scheduleDelayValue > 0) {
    return 'after';
  }
};

export const computedRecurringWords = function (
  scheduleRecurringPattern,
  startDate,
  scheduleDelayDatepartTypeId,
  scheduleDelayValue
) {
  return computed(scheduleRecurringPattern, startDate, scheduleDelayDatepartTypeId, scheduleDelayValue, {
    get() {
      const recurringPattern = get(this, scheduleRecurringPattern);
      const datepartTypeId = get(this, scheduleDelayDatepartTypeId);
      const delay = get(this, scheduleDelayValue);

      const sendAfterNewRss = datepartTypeId === this.enums.findWhere('DATEPART_TYPE', { name: 'EveryMinute' }, 'id');

      const datepart = datepartTypeId
        ? this.enums.findWhere('DATEPART_TYPE', { id: datepartTypeId }, 'noun').toLowerCase()
        : null;
      let frequency;
      if (sendAfterNewRss) {
        frequency =
          delay > 0
            ? `${pluralize(delay, datepart)} after an article is published`
            : `every time an article is published`;
      } else {
        frequency = delay > 1 ? `every ${ordinalize(delay)} ${datepart}` : `every ${datepart}`;
      }

      let pattern;
      if (recurringPattern) {
        let parsed;

        switch (datepart) {
          case 'day':
            pattern = null;
            break;
          case 'week': {
            const dayStrings = recurringPattern
              .split(',')
              .map(day => moment.weekdays()[days.indexOf(day.toLowerCase())]);
            pattern = `${oxfordList(dayStrings)}`;
            break;
          }
          case 'month':
            parsed = parseMonthly(recurringPattern);
            if (parsed.isDateBased) {
              pattern = `the ${ordinalize(parsed.number)} of the month`;
            } else {
              const weekday = moment.weekdays()[parsed.weekdayIndex];
              pattern = `the ${ordinalize(parsed.occurrence)} ${weekday} of the month`;
            }
            break;
          case 'year': {
            parsed = parseYearly(recurringPattern);
            const month = moment.months()[parsed.monthIndex];
            pattern = `${month} ${ordinalize(parsed.number)}`;
            break;
          }
          default:
            throw new Error('Could not create natural language for this schedule.');
        }
      }

      const time = moment(get(this, startDate)).format('h:mma');
      if (sendAfterNewRss) {
        return pattern ? `${frequency} on ${pattern}` : `${frequency}`;
      }
      return pattern ? `${frequency} on ${pattern} at ${time}` : `${frequency} at ${time}`;
    },
    set() {
      throw new DeveloperError(`Please don't even try to set this. Natural language processing is too hard.`);
    },
  });
};

export const computedAnnualEmailTypeOffsetWords = function (
  scheduleStartTime,
  scheduleDelayValue,
  scheduleDelayDatepartTypeId,
  emailDisplayName
) {
  return computed(scheduleStartTime, scheduleDelayValue, scheduleDelayDatepartTypeId, emailDisplayName, function () {
    const time = moment(get(this, scheduleStartTime), SUPPORTED_FORMATS).format('h:mma');
    const datepartTypeId = get(this, scheduleDelayDatepartTypeId);
    const delay = Math.abs(get(this, scheduleDelayValue));
    const displayName = get(this, emailDisplayName);

    const datepart = datepartTypeId
      ? this.enums.findWhere('DATEPART_TYPE', { id: datepartTypeId }, 'noun').toLowerCase()
      : null;
    const formattedDatePart = delay === 1 ? singularize(datepart) : pluralize(datepart);
    let delayAndFormattedDatePartString = `${delay} ${formattedDatePart}`;
    if (delay === 0) {
      delayAndFormattedDatePartString = '';
    }

    return `${delayAndFormattedDatePartString} ${computeDirectionText(get(this, scheduleDelayValue))} a recipient's ${displayName} at ${time}`;
  });
};

export const computedDripOffsetDate = function (scheduleStartTime, scheduleDelayValue, scheduleDelayDatepartTypeId) {
  return computed(scheduleStartTime, scheduleDelayValue, scheduleDelayDatepartTypeId, function () {
    const datepartTypeId = get(this, scheduleDelayDatepartTypeId);
    const delay = Math.abs(get(this, scheduleDelayValue));

    const datepart = datepartTypeId
      ? this.enums.findWhere('DATEPART_TYPE', { id: datepartTypeId }, 'noun').toLowerCase()
      : null;
    const formattedDatePart = delay === 1 ? singularize(datepart) : pluralize(datepart);

    return `${delay} ${formattedDatePart}`;
  });
};

export const computedFormattedTime = function (scheduleStartTime) {
  return computed(scheduleStartTime, function () {
    return moment(get(this, scheduleStartTime), SUPPORTED_FORMATS).format('h:mma');
  });
};

export const computedSchedulePrefix = function (messageCampaignType, isSent) {
  return computed(messageCampaignType, function () {
    const type = get(this, messageCampaignType);
    if (get(this, isSent)) {
      if (type === 'SingleSMS' || type === 'SingleEmail' || type === 'Invite') {
        return 'Sent on';
      }
      return 'Ended on';
    }
    return 'Sends';
  });
};

export default {
  computedRecurringWords,
  computedAnnualEmailTypeOffsetWords,
  computedSchedulePrefix,
  computedDripOffsetDate,
  computedFormattedTime,
};
