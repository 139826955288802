import { set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { POWERS } from 'partner/utils/bracket/constants';
import { Iteration, Matchup } from 'partner/utils/bracket/matchup-constructors';
import { distributeSeededEntries } from 'partner/utils/bracket/setup';

/**
 * Checks for the correct display orders and names; if any are changed they will have to be saved
 * @param {Array} matchupsInRound - sorted by display order
 * @param {Number} currentRound
 */
const repairedMatchups = (matchupsInRound, currentRound) => {
  const result = [];
  for (let i = 0; i < matchupsInRound.length; i++) {
    const expectedDisplayOrder = i + 1;
    const currentMatchup = matchupsInRound[i];
    const expectedName = `Round ${currentRound} - Matchup ${expectedDisplayOrder}`;
    if (currentMatchup.displayOrder !== expectedDisplayOrder || currentMatchup.name !== expectedName) {
      result.push(currentMatchup);
    }
    set(currentMatchup, 'displayOrder', expectedDisplayOrder);
    set(currentMatchup, 'name', expectedName);
  }
  return result;
};
/**
 * Creates missing matchups based on a pre-existing matchup from the same round
 * @param {Number} numberOfMissingMatchups
 * @param {Object} matchupTemplate
 * @param {Object} store
 */
const restoredMatchups = (numberOfMissingMatchups, iterationProps) => {
  const result = [];
  for (let i = 1; i <= numberOfMissingMatchups; i++) {
    result.push(new Matchup(i, new Iteration({ ...iterationProps })));
  }
  return result;
};
/**
 * Tries to fix bad matchup data using the number of matchup entries as a source of truth
 *
 * Examples of bad data that will be fixed:
 *      - excess matchups from excess rounds will all be removed
 *      - excess matchups from existing rounds will be removed
 *      - matchup display orders in each round will run from 1 to NUMBER_OF_MATCHUPS
 *      - machup names in each round will run from 'Round X - Matchup 1' to 'Round X - Matchup NUMBER_OF_MATCHUPS'
 *      - missing matchups for a round will be re-created, using an existing matchup from the same round as a template
 *      - the final round matchup will be restored entirely, using a matchup from a previous round as a template
 *
 * Examples of bad data that will NOT be fixed:
 *      - this will not be able to restore an entire middle round from nothing (eg an empty Round 2 from a 4-Round bracket) due to complexities with start and end dates
 *
 * @param {Number} numberOfMatchupEntries
 * @param {Array} matchups
 * @param {Object} store
 */
const repairBracketMatchups = (numberOfMatchupEntries, matchups) => {
  const matchupsToRemove = [];
  const matchupsToFix = [];
  const matchupsToAdd = [];

  // if we do not have matchup entries remove all matchups
  if (numberOfMatchupEntries === 0) {
    matchupsToRemove.push(...matchups);
    return { matchupsToRemove, matchupsToFix, matchupsToAdd };
  }

  // we start with the largest possible round given the number of matchup entries (ie the final round)
  let currentRound = POWERS.indexOf(POWERS.find(power => power >= numberOfMatchupEntries)) + 1;
  // we start with the number matchups we expect to be in the final round
  let expectedNumberOfMatchupsInRound = 1;

  // any matchups that belong to rounds that cannot exist need to be removed
  matchupsToRemove.push(...matchups.filter(matchup => matchup.iteration > currentRound));

  while (currentRound > 0) {
    const matchupsInRound = matchups.filterBy('iteration', currentRound).sortBy('displayOrder');

    // no matchups in the current round
    if (isEmpty(matchupsInRound)) {
      const matchupFromPreviousRound = matchups.findBy('iteration', currentRound - 1);
      // if we only expect one matchup in this round and there is a previous round matchup, we know we want to create the final round matchup
      if (expectedNumberOfMatchupsInRound === 1 && matchupFromPreviousRound) {
        const iterationProps = {
          iteration: matchupFromPreviousRound.iteration + 1,
          ...Iteration.createDatesFrom(matchupFromPreviousRound),
        };
        matchupsToAdd.push(...restoredMatchups(1, iterationProps));
        // otherwise, we're trying to restore an entire round in the middle of the bracket, which is complicated given the dates (but could be a TODO)
      } else {
        console.error(new Error(`Unable to restore the bracket due to Round ${currentRound} not having any matchups.`));
      }
      expectedNumberOfMatchupsInRound = expectedNumberOfMatchupsInRound * 2;
      currentRound--;
      continue;
    }

    // if the number of matchups we have matches the number we expect to have, we just need to check their display orders and names
    if (matchupsInRound.length === expectedNumberOfMatchupsInRound) {
      const fixedMatchups = repairedMatchups(matchupsInRound, currentRound);

      matchupsToFix.push(...fixedMatchups);
      // if we have more matchups than we expect to have, we shave off the excess matchups and check the display orders and names of the matchups we want to keep
    } else if (matchupsInRound.length > expectedNumberOfMatchupsInRound) {
      const excessMatchups = matchupsInRound.slice(expectedNumberOfMatchupsInRound);
      matchupsToRemove.push(...excessMatchups);
      const matchupsToKeep = matchupsInRound.slice(0, expectedNumberOfMatchupsInRound);
      const fixedMatchups = repairedMatchups(matchupsToKeep, currentRound);

      matchupsToFix.push(...fixedMatchups);
      // if we have fewer matchups than we expect to have, we create the number we need using a pre-existing matchup in the round
      // then we reset the display orders and names of ALL matchups
    } else if (matchupsInRound.length < expectedNumberOfMatchupsInRound) {
      const numberOfMissingMatchups = expectedNumberOfMatchupsInRound - matchupsInRound.length;
      const iterationProps = {
        iteration: matchupsInRound[0].iteration,
        ...Iteration.getDatesFor(matchupsInRound[0]),
      };
      const newMatchups = restoredMatchups(numberOfMissingMatchups, iterationProps);
      const fixedMatchups = repairedMatchups([...matchupsInRound, ...newMatchups], currentRound);

      matchupsToFix.push(...fixedMatchups.filter(matchup => !(matchup instanceof Matchup)));
      matchupsToAdd.push(...fixedMatchups.filter(matchup => matchup instanceof Matchup));
    }

    expectedNumberOfMatchupsInRound = expectedNumberOfMatchupsInRound * 2;
    currentRound--;
  }
  return { matchupsToRemove, matchupsToFix, matchupsToAdd };
};

/**
 * Tries to fix bad matchup entry data by running the matchup entries through the distribution algorithm
 *
 * @param {Array} matchupEntries
 * @param {Array} matchups
 */
const repairBracketMatchupEntries = (matchupEntries, matchups) =>
  distributeSeededEntries(matchupEntries, matchups).filterBy('hasDirtyMatchupOrAttributes');

export { repairBracketMatchups, repairBracketMatchupEntries };
