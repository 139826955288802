import TokenContentEditorForImageWithoutAltText, {
  type TokenContentEditorForImageWithoutAltTextSignature,
} from 'partner/components/token-content-editor-for-image-without-alt-text';

export interface TokenContentEditorForImageSignature extends TokenContentEditorForImageWithoutAltTextSignature {
  Args: TokenContentEditorForImageWithoutAltTextSignature['Args'] & {
    altText?: string;
  };
}

export default class TokenContentEditorForImageComponent<
  T extends TokenContentEditorForImageSignature
> extends TokenContentEditorForImageWithoutAltText<T> {
  updateAltText = ({ target: { value } }: Event & { target: HTMLInputElement }) => {
    this.args.updateTokenContentProperty('altText', value);
  };
}
