/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';

/**
 * Dashboard section, used to separate different categories of information within a dashboard.
 * Currently iterated in message campaign dashboards and within an audience dashboard.
 */
export default Component.extend({
  //region Ember Hooks
  classNames: ['dashboard-section'],
  //endregion
});
