/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import isAnyPath from 'partner/utils/is-any-path';

export default Controller.extend({
  //region Ember Hooks
  application: controller(),
  //endregion

  //region Computed Properties
  canCreateNewUsers: isAnyPath('hasAdminUserCreationPermissions', ['model.editorPartnerUserOrganizationRoles.[]']),
  //endregion
});
