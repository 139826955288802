/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { empty, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import enums from 'ember-cli-ss-enums/services/enums';

const datepartMap = {
  Day: {
    max: 364,
  },
  Week: {
    max: 51,
  },
  Month: {
    max: 11,
  },
};

const DATEPART_TYPE = 'DATEPART_TYPE';

export default Component.extend({
  //region Attributes
  valueChanged() {},
  value: null,
  eventName: '',
  disabled: false,
  datepartTypeIdChanged() {},
  datepartTypeId: null,
  //endregion

  //region Ember Hooks
  enums: service(),
  //endregion

  //region Computed Properties
  datepartType: enums.computed('noun', 'datepartTypeId', 'datepartTypeId', DATEPART_TYPE),
  max: computed('datepartType', function () {
    if (!this.datepartType) {
      return datepartMap.Day.max;
    }
    return datepartMap[this.datepartType].max;
  }),
  valueSign: computed('value', 'absoluteValue', function () {
    return this.value / this.absoluteValue;
  }),
  absoluteValue: computed('value', function () {
    return Math.abs(this.value);
  }),
  hasNoValue: empty('value'),
  directionIsOn: equal('value', 0),
  //endregion

  //region Actions
  actions: {
    numberInputChanged({ target: { value } }) {
      if (isEmpty(value)) {
        this.valueChanged(-1);
        return;
      }
      if (this.hasNoValue) {
        this.valueChanged(parseInt(value, 10) * -1);
        const id = this.enums.findWhere(DATEPART_TYPE, { noun: 'Day' }, 'id');
        this.datepartTypeIdChanged(id);
        return;
      }
      const newValue = parseInt(value, 10) * this.valueSign;
      this.valueChanged(newValue);
    },
    datepartSelectChanged(value) {
      if (this.absoluteValue > datepartMap[value].max) {
        this.valueChanged(this.valueSign * datepartMap[value].max);
      }
      const id = this.enums.findWhere(DATEPART_TYPE, { noun: value }, 'id');
      this.datepartTypeIdChanged(id);
    },
    directionSelectChanged(value) {
      if (value === 'on') {
        this.valueChanged(0);
        const id = this.enums.findWhere(DATEPART_TYPE, { noun: 'Day' }, 'id');
        this.datepartTypeIdChanged(id);
      } else {
        if (this.value === 0) {
          if (value === 'before') {
            this.valueChanged(-1);
          }
          if (value === 'after') {
            this.valueChanged(1);
          }
        } else {
          this.valueChanged(this.value * -1);
        }
      }
    },
  },
  //endregion
});
