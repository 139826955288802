/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { set, action } from '@ember/object';
import AudienceCommonRoute from 'partner/mixins/audience-common-route';

export default class ArchivedRoute extends AudienceCommonRoute {
  @action
  async unarchive(audience) {
    set(audience, 'statusTypeId', 1);
    await audience.save();
    this.snackbar.show('This audience has been made active.', 'Undo').then(async () => {
      set(audience, 'statusTypeId', 2);
      await audience.save();
    });
  }
}
