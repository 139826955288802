/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { ParamsType } from 'partner/services/preview';
import { registrationForm } from './common';
const photo = ({ id, width, height }: { id: string; width?: number; height?: number }) => {
  return {
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'image-2',
        uniqueTypeNumber: '2',
        deletable: true,
        type: 'promotion-image',
        blocks: [],
        data: {
          src: `https://media.secondstreetapp.com/${6550274}?width=107&height=80&cropmode=fit&anchor=center&quality=60&format=auto`,
          alt: '',
          url: '',
          target: '_blank',
          additionalCss: `.arc-promotion-promotion-0 .arc-image-image-${id} {\n   \n  }\n  \n  `,
        },
        styles: {
          size: {
            custom: true,
            width: '40',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-box-box-${id} {\n   \n  }\n  \n  `,
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
      },
      size: {
        custom: width || height,
        width,
        height,
      },
      border: {
        radius: '4',
      },
      background: {
        type: 'color',
        color: '#f1f3f3',
        opacity: 100,
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const questionText = (id: string) => {
  return {
    id: 'heading-3',
    uniqueTypeNumber: `${id}3`,
    deletable: true,
    type: 'promotion-heading',
    blocks: [],
    data: {
      level: 1,
      text: 'Answer #',
      additionalCss: `.arc-heading-heading-${id}3 {\n     \n  }\n  \n  `,
    },
    styles: {
      font: {
        color: '#000000',
        weight: 400,
        family: 'Open Sans',
        size: '16',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const card = (id: string) => {
  return {
    id: 'box-4',
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-box',
    blocks: [photo({ id: `${id}1` }), questionText(id)],
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-box-box-${id} { }\n  \n  `,
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        justify: 'center',
        align: 'center',
        gapX: '8',
        gapY: '8',
      },
      background: {
        type: 'color',
        color: '#ffffff',
        opacity: 100,
      },
      border: {
        style: 'solid',
        width: 1,
        color: '#e0e3e6',
        radius: '4',
      },
      size: {
        custom: true,
        height: '192',
      },
      spacing: {
        paddingBlock: '8',
        paddingInline: '8',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const questionBlock1 = (params?: ParamsType) => {
  return {
    id: 'box-2',
    uniqueTypeNumber: '2',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      photo({ id: '12', height: 250 }),
      {
        type: 'promotion-box',
        uniqueTypeNumber: '13',
        blocks: [
          {
            id: 'heading-1',
            uniqueTypeNumber: '1',
            deletable: true,
            type: 'promotion-heading',
            blocks: [],
            data: {
              level: 1,
              text: `1.`,
              additionalCss: '.arc-heading-heading-1 {\n     \n  }\n  \n  ',
            },
            styles: {
              font: {
                color: params?.promotionColor,
                weight: 700,
                family: 'Open Sans',
                size: '28',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
          {
            id: 'heading-2',
            uniqueTypeNumber: '2',
            deletable: true,
            type: 'promotion-heading',
            blocks: [],
            data: {
              level: 1,
              text: 'Example question?',
              additionalCss: '.arc-heading-heading-2 {\n     \n  }\n  \n  ',
            },
            styles: {
              font: {
                color: '#000000',
                weight: 600,
                family: 'Open Sans',
                size: '28',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
        ],
        styles: {
          layout: {
            display: 'flex',
            direction: 'row',
            gapX: '8',
          },
        },
      },
      {
        id: 'box-3',
        uniqueTypeNumber: '3',
        deletable: true,
        type: 'promotion-grid',
        blocks: [card('51'), card('52'), card('53'), card('54')],
        data: {
          columns: '1:1:1:1',
        },
        styles: {
          layout: {
            display: 'grid',
            direction: 'row',
            gapX: params?.isSmall ? '' : '8',
            gapY: params?.isSmall ? '' : '8',
          },
          spacing: {
            marginBlock: '20',
          },
        },
        dataMobile: {
          columns: '1:1',
        },
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-2 {\n max-width:720px ;   \n  }\n  \n  `,
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
      },
      spacing: {
        marginBlock: '16',
        marginInline: '',
        paddingBlock: '20',
        paddingInline: '20',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const header = ({
  id,
  text,
  hasProgressBar,
  promotionColor,
  promotionTextColor,
}: {
  id: string;
  text: string;
  hasProgressBar?: boolean;
  promotionColor?: string;
  promotionTextColor?: string;
}) => {
  return {
    type: 'promotion-box',
    uniqueTypeNumber: id,
    blocks: [
      {
        uniqueTypeNumber: parseInt(id) + 1,
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text,
        },
        styles: {
          font: {
            color: promotionTextColor,
            weight: hasProgressBar ? 600 : 400,
            family: 'Open Sans',
            size: hasProgressBar ? '12' : '24',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      ...(hasProgressBar ? [progressBar(promotionTextColor)] : []),
    ],
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-${id} {\n min-height:44px ;   \n  }\n  \n  `,
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
        gapX: 8,
        gapY: 8,
      },
      background: {
        type: 'color',
        color: promotionColor,
        opacity: 100,
      },
    },
  };
};

const progressBar = (color?: string) => {
  return {
    type: 'promotion-box',
    uniqueTypeNumber: '61',
    blocks: [
      {
        type: 'promotion-box',
        uniqueTypeNumber: '62',
        blocks: [],
        styles: {
          layout: {
            display: 'block',
          },
          size: {
            custom: true,
            width: 30,
            height: 4,
          },
          background: {
            type: 'color',
            color,
          },
          border: {
            radius: 20,
          },
        },
      },
    ],
    styles: {
      layout: {
        display: 'block',
      },
      size: {
        custom: true,
        width: 172,
        height: 4,
      },
      background: {
        type: 'color',
        color: '#000000',
        opacity: 20,
      },
      border: {
        radius: 20,
      },
    },
  };
};

export default (params?: ParamsType) => [
  {
    type: 'promotion-box',
    uniqueTypeNumber: '101',
    blocks: [
      header({
        id: '29',
        text: 'Question 1 of 10',
        hasProgressBar: true,
        promotionColor: params?.promotionColor,
        promotionTextColor: params?.promotionTextColor,
      }),
      questionBlock1(params),
    ],
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
      },
    },
    data: {
      additionalCss:
        '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-101 { border-bottom: 1px solid #cfcfcf; }',
    },
  },
];

export const quizForm = (params?: ParamsType) => [
  {
    type: 'promotion-box',
    uniqueTypeNumber: '101',
    blocks: [
      header({
        id: '29',
        text: 'Almost Done...',
        promotionColor: params?.promotionColor,
        promotionTextColor: params?.promotionTextColor,
      }),
      registrationForm({ ...params, isPoll: false }),
    ],
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
      },
    },
    data: {
      additionalCss:
        '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-101 { border-bottom: 1px solid #cfcfcf; }',
    },
  },
];

export const quizThanks = (params?: ParamsType) => [
  {
    type: 'promotion-box',
    uniqueTypeNumber: '101',
    blocks: [
      header({
        id: '29',
        text: 'Result',
        promotionColor: params?.promotionColor,
        promotionTextColor: params?.promotionTextColor,
      }),
      {
        type: 'promotion-box',
        uniqueTypeNumber: '102',
        blocks: [
          {
            type: 'promotion-box',
            uniqueTypeNumber: '41',
            blocks: [
              {
                id: 'heading-3',
                uniqueTypeNumber: '44',
                deletable: true,
                type: 'promotion-heading',
                blocks: [],
                data: {
                  level: 1,
                  text: 'Outcome Name',
                },
                styles: {
                  font: {
                    color: '#000000',
                    weight: 700,
                    family: 'Open Sans',
                    size: '28',
                    align: 'center',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
              photo({ id: '17', height: 250 }),
              {
                id: 'heading-3',
                uniqueTypeNumber: '45',
                deletable: true,
                type: 'promotion-heading',
                blocks: [],
                data: {
                  level: 1,
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut',
                },
                styles: {
                  font: {
                    color: '#000000',
                    weight: 400,
                    family: 'Open Sans',
                    size: '14',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
            ],
            data: {
              additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-41 { margin-left: auto; margin-right: auto;} `,
            },
            styles: {
              layout: {
                display: 'flex',
                direction: 'column',
                gapX: '16',
                gapY: '16',
              },
              border: {
                style: 'solid',
                width: 1,
                color: '#e0e3e6',
                radius: '4',
              },
              size: {
                custom: true,
                width: 860,
              },
              spacing: {
                marginBlock: '24',
                marginInline: '8',
                paddingBlock: '24',
                paddingInline: '24',
              },
            },
          },
          ...(params?.hasThanksMessage
            ? [
                {
                  type: 'promotion-box',
                  uniqueTypeNumber: '42',
                  blocks: [
                    {
                      uniqueTypeNumber: '45',
                      deletable: true,
                      type: 'promotion-text',
                      blocks: [],
                      data: {
                        level: 1,
                        text: params?.message,
                      },
                      styles: {
                        font: {
                          color: '#000000',
                          weight: 400,
                          family: 'Open Sans',
                          size: '14',
                        },
                      },
                      dataMobile: {},
                      stylesMobile: {},
                      dataTablet: {},
                      stylesTablet: {},
                    },
                  ],
                  data: {
                    additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-42 {\n margin-left: auto; margin-right: auto; margin-top: 0;  \n  }\n  \n  `,
                  },
                  styles: {
                    layout: {
                      display: 'flex',
                      direction: 'column',
                      gapX: '16',
                      gapY: '16',
                    },
                    size: {
                      custom: true,
                      width: 860,
                    },
                    border: {
                      style: 'solid',
                      width: 1,
                      color: '#e0e3e6',
                      radius: '4',
                    },

                    spacing: {
                      marginBlock: '24',
                      marginInline: '8',
                      paddingBlock: '24',
                      paddingInline: '24',
                    },
                  },
                },
              ]
            : []),
          {
            type: 'promotion-box',
            uniqueTypeNumber: '49',
            blocks: [
              {
                id: 'heading-3',
                uniqueTypeNumber: '51',
                deletable: true,
                type: 'promotion-heading',
                blocks: [],
                data: {
                  level: 1,
                  text: params?.isQuizTrivia ? 'You got 2 out of 8 correct!' : 'Not the result you expected?',
                },
                styles: {
                  font: {
                    color: '#000000',
                    weight: 600,
                    family: 'Open Sans',
                    size: '18',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
              {
                id: 'button-1',
                uniqueTypeNumber: '1',
                deletable: true,
                type: 'promotion-button',
                blocks: [],
                data: {
                  href: '',
                  text: `<span class="material-icons">cached</span> RETAKE`,
                  target: '_blank',
                  url: '',
                  additionalCss:
                    '.arc-promotion-promotion-0 .arc-promotion-button-promotion-button-1 { margin-left: auto; }\n  \n  ',
                },
                styles: {
                  border: {
                    radius: 5,
                  },
                  spacing: {
                    paddingBlock: 10,
                    paddingInline: 10,
                  },
                  background: {},
                  font: {
                    color: '#000000',
                    align: 'center',
                    family: 'Open Sans',
                    weight: 400,
                    size: '14',
                    spacing: '1.25',
                  },
                  size: {
                    custom: true,
                    height: '40',
                    width: '',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
            ],
            styles: {
              size: {
                custom: true,
                width: 472,
              },
              layout: {
                display: 'flex',
                direction: 'row',
                gapX: '16',
                gapY: '16',
                align: 'center',
              },
              border: {
                style: 'solid',
                width: 1,
                color: params?.promotionColor,
                radius: '4',
              },
              background: {
                type: 'color',
                color: params?.promotionColor,
                opacity: 10,
              },
              spacing: {
                paddingBlock: '24',
                paddingInline: '16',
              },
            },
          },
          {
            type: 'promotion-box',
            uniqueTypeNumber: '50',
            blocks: [
              {
                id: 'heading-3',
                uniqueTypeNumber: '53',
                deletable: true,
                type: 'promotion-heading',
                blocks: [],
                data: {
                  level: 1,
                  text: 'Share your result and see what your friends get!',
                },
                styles: {
                  font: {
                    color: '#000000',
                    weight: 600,
                    family: 'Open Sans',
                    size: '18',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
              {
                id: 'social-1',
                uniqueTypeNumber: '111',
                deletable: true,
                type: 'social',
                blocks: [],
                data: {
                  facebook: true,
                  email: true,
                  x: true,
                  type: 'circle',
                },
                styles: {
                  social: {
                    backgroundColor: '',
                    color: '#ffffff',
                  },
                  layout: {
                    gapX: 4,
                    gapY: 4,
                    direction: 'row',
                  },
                },
              },
            ],
            styles: {
              size: {
                custom: true,
                width: 472,
              },
              layout: {
                display: 'flex',
                direction: 'column',
                gapX: '16',
                gapY: '16',
                align: 'center',
                justify: 'center',
              },
              spacing: {
                paddingBlock: '24',
                paddingInline: '16',
              },
            },
          },
        ],
        styles: {
          layout: {
            display: 'flex',
            direction: 'column',
            align: 'center',
            justify: 'center',
          },
          spacing: {
            paddingInline: '8',
            paddingBlock: '16',
          },
        },
      },
    ],
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
      },
    },
    data: {
      additionalCss:
        '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-101 { border-bottom: 1px solid #cfcfcf; }',
    },
  },
];
