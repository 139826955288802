/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/named-functions-in-promises */ // FIXME
import { get, set, action } from '@ember/object';
import AudienceCommonRoute from 'partner/mixins/audience-common-route';

/**
 * Audiences Index Route
 * /o/:organization_id/data/audiences
 * @type {Ember.Route}
 * @mixes AppContextRoute
 * @mixes ResetScrollRoute
 */
export default class IndexRoute extends AudienceCommonRoute {
  constructor() {
    super(...arguments);

    this.router.on('routeWillChange', () => {
      if (this.controller) {
        set(this, 'controller.isCreating', false);
      }
    });
  }

  @action
  async archive(audience) {
    set(audience, 'statusTypeId', 2);
    try {
      await audience.save();
      //get(this, 'currentModel.audiences').removeObject(audience);
      this.snackbar.show('Audience archived. Phew, it always feels nice to tidy up a bit.', 'Undo').then(async () => {
        set(audience, 'statusTypeId', 1);
        await audience.save();
        //get(this, 'currentModel.audiences').addObject(audience);
      });
    } catch (e) {
      const message = get(e, 'message.errors.firstObject.message') || get(audience, 'errors.firstObject.message');
      audience.rollbackAttributes();
      this.snackbar.show(`${message}`, '', 12000, 'error');
    }
  }
}
