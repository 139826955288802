/* eslint-disable ember/no-mixins, ember/no-computed-properties-in-native-classes, ember/no-classic-components */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import FormDesignerFormFieldContentsMixin from 'partner/mixins/form-designer-form-field-contents';

const GOOGLE_PLAY_BADGE = {
  1: '/Images/Logos/google_play_badge_1.svg',
  2: '/Images/Logos/google_play_badge_2.svg',
  3: '/Images/Logos/google_play_badge_3.svg',
};

const APPLE_APP_STORE_BADGE = {
  1: '/Images/Logos/apple_app_store_1.svg',
  2: '/Images/Logos/apple_app_store_2.svg',
  3: '/Images/Logos/apple_app_store_3.svg',
};

export default class extends Component.extend(FormDesignerFormFieldContentsMixin) {
  @computed('form-field.field.fieldOptions.@each.displayOrder')
  get sortedFieldOptions() {
    const fieldOptions = get(this, 'form-field.field.fieldOptions');

    if (!isEmpty(fieldOptions)) {
      return fieldOptions.sortBy('displayOrder');
    }

    return [];
  }

  @computed('organization-promotion.defaultLanguageId')
  get androidImageSource() {
    return GOOGLE_PLAY_BADGE[this['organization-promotion'].defaultLanguageId];
  }

  // Returns the image source for the apple app store badge based on the regional code
  @computed('organization-promotion.defaultLanguageId')
  get iosImageSource() {
    return APPLE_APP_STORE_BADGE[this['organization-promotion'].defaultLanguageId];
  }
}
