import ENV from 'partner/config/environment';
import CommonAdapter from 'secondstreet-common/adapters/common-adapter';
import { inject as service } from '@ember/service';
import type SessionService from 'partner/services/-private/session';

export default class ApplicationAdapter extends CommonAdapter {
  @service declare session: SessionService;

  namespace: string = ENV.APP.API_NAMESPACE;
  host: string = ENV.APP.API_HOST;

  get headers() {
    return {
      'X-Api-Key': ENV.APP.API_KEY,
      ...this.session.headers,
    };
  }
}
