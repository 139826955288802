/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import AppContextRoute from 'partner/mixins/app-context-route';
import { user } from 'partner/utils/model-hooks';

export default Route.extend(AppContextRoute, {
  router: service(),
  snackbar: service(),
  session: service(),
  store: service(),

  //region Ember Hooks
  model(params) {
    return user(params, this);
  },
  //endregion
  // to take the user id from the model and
  // pass it to the url
  serialize(model) {
    return { user_id: model.partnerUser.id };
  },
  //region Methods
  showError() {
    const userId = get(this.modelFor('users.user'), 'partnerUser.id');
    this.snackbar.show(`Problem Impersonating (UserId: ${userId})`, 'Dismiss', -1, 'error');
  },
  //endregion

  //region Actions
  actions: {
    goBack() {
      if (window.history.length >= 2) {
        window.history.back();
      } else {
        this.router.transitionTo('users.index');
      }
    },
    addOrganizationRoles(organization, roles) {
      const puors = get(this.modelFor('users.user'), 'partnerUserOrganizationRoles');
      this.send(
        'addOrganizationPartnerUserRoles',
        organization,
        roles,
        get(this.modelFor('users.user'), 'partnerUser'),
        puors
      );
    },
    removeOrganizationRoles(organization) {
      const puors = get(this.modelFor('users.user'), 'partnerUserOrganizationRoles');
      this.send('removeOrganizationPartnerUserRoles', organization, puors);
    },
    willTransition(transition) {
      const model = this.modelFor('users.user');

      const partnerUser = get(model, 'partnerUser');
      const isAnythingDirty =
        get(partnerUser, 'hasDirtyAttributes') ||
        get(model, 'partnerUserOrganizationRoles').any(x => get(x, 'hasDirtyAttributes'));
      let confirmed = !isAnythingDirty || get(partnerUser, 'isDeleted');
      confirmed = confirmed
        ? confirmed
        : window.confirm('Are you sure you want to leave? Your unsaved changes may be lost.');
      if (confirmed) {
        partnerUser.rollbackAttributes();
      } else {
        return transition.abort();
      }

      const passwordResetRequest = get(model, 'passwordResetRequest');
      // if we have an unsaved password reset request record, destroy it
      if (get(passwordResetRequest, 'isNew')) {
        passwordResetRequest.destroyRecord();
      }
    },
    deleteUser() {
      const partnerUser = get(this.modelFor('users.user'), 'partnerUser');
      const fullName = get(partnerUser, 'fullName');
      const partnerUserName = isBlank(fullName) ? get(partnerUser, 'emailAddress') : fullName;
      partnerUser.destroyRecord().then(() => {
        this.snackbar.show(`${partnerUserName} has been deleted.`);
        this.router.transitionTo('users');
      });
    },
    save() {
      this.send('savePartnerUser', this.modelFor('users.user'));
    },
    addRoles(partnerUserOrganizationRole, ...roles) {
      this.send(
        'addPartnerUserRoles',
        partnerUserOrganizationRole,
        get(this.modelFor('users.user'), 'partnerUser'),
        ...roles
      );
    },
    removeRoles(partnerUserOrganizationRole, ...roles) {
      this.send('removePartnerUserRoles', partnerUserOrganizationRole, ...roles);
    },
  },
  //endregion
});
