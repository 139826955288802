/* eslint-disable ember/no-mixins */
import { setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import PromotionTypeRoute from 'partner/mixins/promotion-type-route';
import { dashboard } from 'partner/utils/model-hooks';

export default class extends Route.extend(PromotionTypeRoute) {
  // following services are required and used in 'dashboard('Ballot')' method
  @service('session') session;
  @service('store') store;
  @service('settings') settings;

  model(...args) {
    return dashboard('Ballot').call(this, ...args);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      setProperties(controller, {
        selectedGroup: null,
        isImportingVotes: false,
        isImportingNominations: false,
        uploadErrors: null,
      });
    }
  }
}
