/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/* globals jstz */
import Component from '@ember/component';
import { set, computed } from '@ember/object';
import { and, filterBy, not, mapBy } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import difference from 'lodash/difference';
import { next } from '@ember/runloop';
import enums from 'ember-cli-ss-enums/services/enums';

const FREQUENCY_TYPE_HOURLY = enums.findWhere('OPTIN_NOTIFICATION_FREQUENCY_TYPE', { name: 'Hourly' }, {});
const FREQUENCY_TYPE_DAILY = enums.findWhere('OPTIN_NOTIFICATION_FREQUENCY_TYPE', { name: 'Daily' }, {});
const FREQUENCY_TYPE_WEEKLY = enums.findWhere('OPTIN_NOTIFICATION_FREQUENCY_TYPE', { name: 'Weekly' }, {});
const FREQUENCY_TYPE_NEVER = enums.findWhere('OPTIN_NOTIFICATION_FREQUENCY_TYPE', { name: 'Never' }, {});

export default Component.extend({
  timeZone: jstz.determine().name(),

  //region Attributes
  /** @type {boolean} */
  isEditable: false,
  /** @type {boolean} */
  isSharing: false,
  /** @type {OptinNotification[]} */
  optinNotifications: null,
  /** @type {OptinNotificationSharedDataField[]} */
  optinNotificationSharedDataFields: null,
  /** @type {Field[]} */
  shareableFields: null,
  /** @type {emailCallback} */
  addEmailAddress() {},
  /** @type {emailCallback} */
  removeEmailAddress() {},
  /** @type {fieldCallback} */
  addSharedField() {},
  /** @type {fieldCallback} */
  removeSharedField() {},

  postMessageNotifications: null,
  notificationFrequencies: null,
  selectedFrequency: null,
  //endregion

  //region Computed Properties
  isEnabled: and('isEditable', 'isSharing').readOnly(),
  isDisabled: not('isEnabled').readOnly(),

  allEmailAddresses: mapBy('optinNotifications', 'emailAddress').readOnly(),

  emailAddresses: computed('optinNotifications.@each.{isDeleted,emailAddress}', function () {
    return this.optinNotifications.rejectBy('isDeleted').mapBy('emailAddress');
  }).readOnly(),

  deletedEmailAddresses: computed('optinNotifications.@each.{isDeleted,emailAddress}', function () {
    return this.optinNotifications.filterBy('isDeleted').mapBy('emailAddress');
  }).readOnly(),

  sharedFields: computed('optinNotificationSharedDataFields.@each.sharedDataField', function () {
    const sharedFields = this.optinNotificationSharedDataFields.mapBy('sharedDataField');
    const emailField = sharedFields.findBy('name', 'Email');
    if (emailField) {
      sharedFields.removeObject(emailField);
      return [emailField, ...sharedFields];
    }
    return sharedFields;
  }).readOnly(),

  deletedFields: computed('optinNotificationSharedDataFields.@each.{isDeleted,sharedDataField}', function () {
    return this.optinNotificationSharedDataFields.filterBy('isDeleted').mapBy('sharedDataField');
  }).readOnly(),

  optinRecipientsStatus: computed('emailAddresses.[]', function () {
    return isEmpty(this.emailAddresses) ? 'incomplete' : 'bestPractice';
  }).readOnly(),

  notificationFrequencyStatus: computed('selectedFrequencyTypeId', function () {
    return isNaN(this.selectedFrequencyTypeId) ? 'incomplete' : 'bestPractice';
  }).readOnly(),

  optinFieldStatus: computed('sharedFields.[]', function () {
    return isEmpty(this.sharedFields) ? 'incomplete' : 'bestPractice';
  }).readOnly(),

  disabledFields: filterBy('shareableFields', 'name', 'Email').readOnly(),

  emailAddressesString: computed('allEmailAddresses.[]', function () {
    return this.allEmailAddresses.join(';');
  }).readOnly(),

  postMessageSummaryOptinsEmailString: computed('postMessageNotifications.[]', function () {
    return this.postMessageNotifications ? this.postMessageNotifications.mapBy('emailAddress').join(';') : '';
  }).readOnly(),

  postMessageEmails: computed('postMessageNotifications.@each.{isDeleted,emailAddress}', function () {
    return this.postMessageNotifications
      ? this.postMessageNotifications.rejectBy('isDeleted').mapBy('emailAddress')
      : [];
  }).readOnly(),

  deletedpostMessageEmailAddresses: computed('postMessageNotifications.@each.{isDeleted,emailAddress}', function () {
    return this.postMessageNotifications
      ? this.postMessageNotifications.filterBy('isDeleted').mapBy('emailAddress')
      : [];
  }).readOnly(),

  postMessageEmailAlreadySent: computed('postMessageNotifications.[]', function () {
    return this.postMessageNotifications
      ? this.postMessageNotifications.any(({ lastNotificationDate }) => lastNotificationDate)
      : false;
  }).readOnly(),

  postMessageNotificationsStatus: computed('postMessageEmails.[]', function () {
    return isEmpty(this.postMessageEmails) || !this.optinMessageCampaignFrequency ? 'incomplete' : 'bestPractice';
  }).readOnly(),

  init() {
    this._super(...arguments);
    this.optinMessageCampaignFrequency =
      this.postMessageNotifications?.firstObject?.optinNotificationFrequencyTypeId ?? 5;
    this.optinMessageCampaignScheduleDate = this.postMessageNotifications?.firstObject?.scheduledDate ?? null;

    this.notificationFrequencies = [
      FREQUENCY_TYPE_HOURLY,
      FREQUENCY_TYPE_DAILY,
      FREQUENCY_TYPE_WEEKLY,
      FREQUENCY_TYPE_NEVER,
    ];

    this.selectedFrequency = this.notificationFrequencies.findBy('id', this.selectedFrequencyTypeId);
  },

  //endregion

  actions: {
    parseEmailAddresses(emailAddressesString) {
      const emailAddresses = emailAddressesString ? emailAddressesString.split(';') : [];
      difference(emailAddresses, this.emailAddresses).forEach(this.addEmailAddress);
      difference(this.emailAddresses, emailAddresses).forEach(email => {
        this.removeEmailAddress(email);
      });
    },

    parsePostMessageEmails(emailAddressesString) {
      const emailAddresses = emailAddressesString ? emailAddressesString.split(';') : [];
      difference(emailAddresses, this.postMessageEmails).forEach(email => {
        this.addPostNotificationEmailAddress(
          email,
          this.optinMessageCampaignFrequency,
          this.optinMessageCampaignScheduleDate
        );
      });
      difference(this.postMessageEmails, emailAddresses).forEach(email => {
        this.removePostMessageEmailAddress(email);
      });
    },

    setOptinMessageCampaignScheduleDate(scheduledDate) {
      set(this, 'optinMessageCampaignScheduleDate', scheduledDate);
      this.updatePostMessageOptins &&
        this.updatePostMessageOptins({
          optinNotificationFrequencyTypeId: 5,
          scheduledDate,
        });
    },

    setOptinMessageCampaignSchedule() {
      set(this, 'optinMessageCampaignScheduleDate', null);
      this.updatePostMessageOptins &&
        this.updatePostMessageOptins({
          optinNotificationFrequencyTypeId: 6,
          scheduledDate: null,
        });
    },

    clearOptinMessageCampaignSchedule() {
      set(this, 'optinMessageCampaignScheduleDate', null);
      this.updatePostMessageOptins &&
        this.updatePostMessageOptins({
          optinNotificationFrequencyTypeId: 5,
          scheduledDate: null,
        });
    },

    focusScheduleDate() {
      next(() => document.querySelector('.ssDateTimePicker').focus());
    },
  },
});
