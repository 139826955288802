import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class LogoutRoute extends Route {
  @service('appcues') appcues;
  @service('session') session;
  @service('local-storage') localStorage;

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
  }

  afterModel() {
    this.appcues.reset();
    this.session.invalidate();
    this.localStorage.clear();
  }
}
