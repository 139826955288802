import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class MessagingStatisticsOrganizationModel extends BaseModel {
  @attr('number') declare organizationId: number;
  @attr('number') declare parentOrganizationId: number;
  @attr('number') declare scheduleInstanceCount: number;
  @attr('number') declare sentCount: number;
  @attr('number') declare clickedCount: number;
  @attr('number') declare clickedPercentage: number;
  @attr('number') declare openedCount: number;
  @attr('number') declare openedPercentage: number;
  @attr('number') declare optedOutCount: number;
  @attr('number') declare optedOutPercentage: number;
  @attr('number') declare receivedCount: number;
  @attr('number') declare receivedPercentage: number;

  @attr('string') declare organizationName: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'messaging-statistics-organization': MessagingStatisticsOrganizationModel;
  }
}
