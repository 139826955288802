/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import DS from 'ember-data';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend({
  //region Properties
  name: DS.attr('string'),
  languageId: DS.attr('number'),
  promotionTypeId: DS.attr('number'),
  templateContent: DS.attr('string'),
  statusTypeId: DS.attr('number'),
  isInherited: DS.attr('boolean'),
  //endregion

  //region Computed Properties
  compiledTemplateContent: computed('templateContent', function () {
    return Handlebars.compile(this.templateContent);
  }),
  //endregion

  //region Relationships
  templateElements: DS.hasMany('template-element', { async: false }),
  //endregion
});
