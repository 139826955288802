/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/use-ember-get-and-set */ // FIXME
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

export default Route.extend(AppContextRoute, {
  //region Dependencies
  router: service(),
  session: service(),
  store: service(),
  //endregion

  //region Ember Hooks
  model() {
    return RSVP.hash({
      partnerUsers: this.store.query('partner-user', { BypassCache: true }).then(users => users.sortBy('fullName')),
      roles: this.store.findAll('role'),
    }).then(hash => {
      hash.editorPartnerUserOrganizationRoles = this.store
        .query('partner-user-organization-role', {
          partnerUserId: get(this, 'session.data.authenticated.organization_users.user_id'),
          includeImplicitRoles: true,
        })
        .then(x => x.toArray());
      return RSVP.hash(hash);
    });
  },
  //endregion

  //region Actions
  actions: {
    createNewPartnerUser() {
      this.router.transitionTo('users.new');
    },
    willTransition() {
      const passwordResetRequest = get(this.modelFor('users'), 'passwordResetRequest');
      // if we have an unsaved password reset request record, destroy it
      if (get(passwordResetRequest, 'isNew')) {
        passwordResetRequest.destroyRecord();
      }
      // clear out the search term, email, name, organization, and the create users boolean
      this.controllerFor('users.index').setProperties({
        searchTerm: '',
        searchTermEmail: '',
        searchTermName: '',
        searchTermOrganization: '',
        searchTermCreateUsers: false,
        advancedSearch: false,
        seachClickedToggle: true,
        defaultPlaceHolderText: true,
        showAdvancedModal: false,
      });
    },

    loadUser(user) {
      if (isEmpty(get(user, 'isEditable'))) {
        return RSVP.hash({
          partnerUser: this.store.findRecord('partner-user', get(user, 'id'), {
            reload: true,
          }),
        });
      }
    },
  },
  //endregion
});
