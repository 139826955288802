/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, getProperties, set, setProperties } from '@ember/object';
import { filterBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  //region Attributes
  /**
   * @property {Function}
   */
  'close-modal'() {},
  /**
   * @property {Function}
   */
  'select-item'() {},
  /**
   * @property {Function}
   */
  'filter-items': x => x,
  /**
   * @property {Object}
   */
  params: null,
  /**
   * @property {String}
   */
  model: '',
  /**
   * The property by which to search fetched data
   * @property {String}
   */
  'property-to-use': 'name',
  /**
   * @property {Boolean}
   */
  'show-icons': true,
  /**
   * @property {Boolean}
   */
  readonly: false,
  //endregion

  //region Ember Hooks
  classNames: ['starred-items-modal'],
  store: service(),

  async didInsertElement() {
    this._super(...arguments);

    set(this, 'isFetchingData', true);

    const { model, params } = getProperties(this, 'model', 'params');
    const initialList = await this.store.query(model, params || {});
    const itemsToDisplay = this['filter-items'](initialList) || initialList;

    setProperties(this, {
      items: itemsToDisplay,
      isFetchingData: false,
    });
  },
  //endregion

  //region Properties
  showingUnstarredItems: false,
  searchInput: '',
  isFetchingData: false,
  items: null,
  //endregion

  //region Computed Properties
  starredItems: filterBy('items', 'isStarred', true),

  nonStarredItems: filterBy('items', 'isStarred', false),

  searchResults: computed('items', 'searchInput', 'property-to-use', function () {
    const { items } = this;
    const input = this.searchInput;
    const prop = this['property-to-use'];
    return items.filter(item => get(item, prop).toLowerCase().includes(input.toLowerCase()));
  }),
  //endregion

  init() {
    this._super(...arguments);

    set(this, 'params', this.params || {});
  },

  //region Actions
  actions: {
    updateSearch({ target: { value } }) {
      set(this, 'searchInput', value);
    },
  },
  //endregion
});
