/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { computed } from '@ember/object';
import FormController from 'partner/controllers/form-controller';

export default FormController.extend({
  //region Ember Hooks
  queryParams: ['designId'],
  //endregion

  //region Properties
  designId: null,
  isAnythingSaving: false,
  isLoadingWidgetForm: false,
  //endregion

  //region Computed Properties
  activeDesign: computed('model.designs', 'designId', function () {
    return this.model.designs.findBy('id', this.designId);
  }),
  //endregion
});
