/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { A } from '@ember/array';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { mapBy } from '@ember/object/computed';
import { insertIf } from 'secondstreet-common/utils/functional';

export default Component.extend({
  //region Ember Hooks
  classNames: ['download-audience-report'],
  //endregion

  //region Attributes
  /**
   * The audience to download a report for
   * @type {Audience?}
   */
  audience: null,
  /**
   * The action for closing this component
   * @type {Function}
   */
  close: x => x,
  /**
   * Is a report being generated/downloaded?
   * @type {Boolean}
   */
  'is-downloading': false,
  //endregion

  //region Properties
  showAddCustomFieldsModal: false,
  //endregion

  //region Computed Properties
  /**
   * A static list of the fields always included in the audience report
   * @type {ComputedProperty}
   */
  staticReportFields: computed('audience.isSubscriptionAudience', function () {
    return [
      'Email Address',
      'First Name',
      'Last Name',
      'Gender',
      'Birthdate',
      'Mobile Phone',
      'Address 1',
      'Address 2',
      'City',
      'State',
      'Postal Code',
      'Sendable?',
      'Sending Status',
      'Last Activity Date',
      'Audience Join Date',
      ...insertIf(this.audience.isSubscriptionAudience, 'Opt-in Source'),
    ];
  }),
  /**
   * Array of selected custom fields to be included in the report
   * @type {Array}
   */
  selectedCustomFields: computed(() => A([])),

  selectedCustomFieldIds: mapBy('selectedCustomFields', 'id'),
  //endregion

  //region Actions
  actions: {
    findUnselectedFields(fields) {
      const { selectedCustomFields } = this;
      return fields.reject(field => selectedCustomFields.mapBy('id').includes(field.id));
    },
    addCustomField(field) {
      this.selectedCustomFields.addObject(field);
    },
    removeCustomField(field) {
      this.selectedCustomFields.removeObject(field);
    },
  },
  //endregion
});
