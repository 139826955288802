/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { set } from '@ember/object';
import { computed, get } from '@ember/object';
import { equal, gte } from '@ember/object/computed';

export default Component.extend({
  //region Ember Hooks
  tagName: '',
  //endregion

  //region Attributes
  list: null,
  //endregion

  //region Hooks
  init() {
    this._super(...arguments);
    if (!this.list) {
      set(this, 'list', []);
    }
  },
  //endregion

  //region Computed Properties
  followWithCommas: computed('list.[]', function () {
    return this.list.slice(0, -2);
  }),
  followWithCommaAnd: computed('list.[]', function () {
    return this.list.objectAt(get(this, 'list.length') - 2);
  }),
  isOne: equal('list.length', 1),
  isTwo: equal('list.length', 2),
  isMany: gte('list.length', 3),
  //endregion
});
