/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import dirtyProperty from 'partner/utils/dirty-property';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

export default BaseModel.extend({
  //region Ember Hooks
  enums: service(),
  //endregion

  //region Properties
  feedUrl: attr('string', { defaultValue: 'https://abcnews.go.com/abcnews/topstories' }),
  messageVersionFeedArticleContentTypeId: attr('number', { defaultValue: 1 }),
  messageVersionFeedArticleQuantityTypeId: attr('number', { defaultValue: 2 }),
  articleQuantity: attr('number', { defaultValue: 3 }),
  timeQuantity: attr('number', { defaultValue: 24 }),
  timeZoneId: attr('number'),
  isHeadlineEnabled: attr('boolean', { defaultValue: true }),
  isImageEnabled: attr('boolean', { defaultValue: true }),
  isDescriptionEnabled: attr('boolean', { defaultValue: true }),
  isPublishDateEnabled: attr('boolean', { defaultValue: true }),
  isButtonEnabled: attr('boolean', { defaultValue: true }),
  buttonText: attr('string', { defaultValue: 'Read more' }),
  sectionHeaderText: attr('string'),
  sectionMediaItemId: attr('number'),
  sectionImageExternalSrcUrl: attr('string'),
  sectionImageLinkUrl: attr('string'),
  sectionImageAltText: attr('string'),
  statusTypeId: attr('number', { defaultValue: 1 }),
  items: attr('array-of-camelcased-objects-from-snakecased-objects'),
  useFeedDisplayOrder: attr('boolean', { defaultValue: false }),
  //endregion

  //region Relationships
  messageVersion: belongsTo('message-version'),
  isFeedUrlDirty: dirtyProperty('feedUrl'),
  //endregion

  //region Computed Properties
  articleContentType: enums.computed(
    'name',
    'messageVersionFeedArticleContentTypeId',
    'messageVersionFeedArticleContentTypeId',
    'ARTICLE_CONTENT_TYPE'
  ),

  isDisabled: computed('statusTypeId', {
    get() {
      return this.statusTypeId === 2;
    },
    set(key, value) {
      if (value) {
        set(this, 'statusTypeId', this.enums.findWhere('STATUS_TYPE', { name: 'InActive' }));
      } else {
        set(this, 'statusTypeId', this.enums.findWhere('STATUS_TYPE', { name: 'Active' }));
      }
      return value;
    },
  }),

  isComplete: computed(
    'isDisabled',
    'isFeedUrlDirty',
    'isHeadlineEnabled',
    'isDescriptionEnabled',
    'sectionImageLinkUrl',
    'sectionImageAltText',
    'sectionImageExternalSrcUrl',
    function () {
      const disabled = this.isDisabled;
      const dirtyUrl = this.isFeedUrlDirty;
      const headline = this.isHeadlineEnabled;
      const description = this.isDescriptionEnabled;
      const imageUrl = this.sectionImageLinkUrl;
      const altText = this.sectionImageAltText;
      const srcUrl = this.sectionImageExternalSrcUrl;
      const atLeastOneElement = headline || description;

      return (
        disabled ||
        (atLeastOneElement &&
          !dirtyUrl &&
          imageUrl !== '' &&
          altText !== '' &&
          srcUrl !== 'http://media.secondstreetapp.com/77028?width=1200')
      );
    }
  ),
  //endregion
});
