/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed, get, set } from '@ember/object';
import { alias, and, equal, gte, mapBy, not, or, readOnly, union } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty } from '@ember/utils';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import enums from 'ember-cli-ss-enums/services/enums';
import moment from 'moment';
import {
  computedAnnualEmailTypeOffsetWords,
  computedRecurringWords,
  computedSchedulePrefix,
} from 'partner/utils/computed-schedule-date-summaries';
import dirtyProperty from 'partner/utils/dirty-property';
import RSVP from 'rsvp';
import BaseModel from 'secondstreet-common/models/base';
import { DeveloperError } from 'secondstreet-common/utils/errors';

const DESIGNER_FLYOUT_LABEL = {
  SingleEmail: 'Version',
  Welcome: 'Welcome Email',
  Invite: 'Invite Email',
  ThankYou: 'Thank You Email',
  Reminder: 'Reminder Email',
  Receipt: 'Receipt Email',
  DoubleOptinConfirmation: 'Double Opt In Email',
  FALLBACK: 'Message',
};

@makeBooleanProperties('MESSAGE_CAMPAIGN_TYPE')
export default class MessageCampaign extends BaseModel {
  @service enums;
  @service permissions;
  @service store;

  @attr('string') name;
  @attr('number') messageCampaignTypeId;
  @attr('number') statusTypeId;
  @attr('string') scheduleDescription;
  @attr('number') scheduleDelayValue;
  @attr('number') scheduleDelayDatepartTypeId;
  @attr('string', { defaultValue: '' }) scheduleRecurringPattern;
  @attr('string') scheduleStartTime;
  @attr('date') startDate;
  @attr('date') dateSent;
  @attr('number') audienceMemberCount;
  @attr('date') audienceMemberCountDate;
  @attr('array-of-strings-from-semicolon-delimited-string') additionalRecipients;
  @attr('number') messageCampaignPresetId;
  @attr('number') displayOrder;
  @attr('date') firstSendDate;
  @attr('boolean') isLegacyMessage;
  @attr('number') legacyMessageId;
  @attr('boolean') sendToExistingAudienceMembers;
  @attr('boolean') sendToUnregisteredOnly;
  @attr('number') copyMessageCampaignId; //Only used for POST when copying a messageCampaign
  @attr('string') externalId;
  @attr('number') sendingStatusTypeId;
  @attr('boolean') isConfirmed;
  @attr('number') peopleSentToCount;
  @attr('boolean') sendOnConfirm;
  @attr('number') audienceId;
  @attr('boolean') sendToEngagedOnly;
  @attr('number') unengagedAudienceMemberCount;
  @attr('date') dateCreated;
  @attr('number') syndicateCount;
  @attr('string') syndicatedFrom;
  @attr('number') targetOrganizationPromotionId;
  @attr('number') organizationPromotionId;
  @attr('boolean', { defaultValue: false }) copyDates;
  @attr('number') kpi;
  @attr('string') organizationId;

  @belongsTo('partner-organization-user') createdByOrganizationUser;
  @belongsTo('organization', { async: false }) organization;
  @belongsTo('message-campaign-category', { async: false }) messageCampaignCategory;
  @hasMany('message', { async: false }) messages;

  @computed('messages.@each.schedule')
  get schedules() {
    return this.messages.mapBy('schedule').compact();
  }

  /**
   * Fake async relationship.
   * @returns {Ember.RSVP.Promise} - Resolves with a {@link SingleMessageCampaign} or null
   */
  @computed('id', 'usesSingleMessageCampaign')
  get singleMessageCampaign() {
    if (this.usesSingleMessageCampaign) {
      return this.store.find('single-message-campaign', this.id);
    }
    return RSVP.Promise.resolve(null);
  }

  set singleMessageCampaign(_) {
    throw new DeveloperError('This is not a real relationship, so you cannot edit it.');
  }

  @computed('usesSingleMessage', 'messages.[]')
  get singleMessage() {
    if (!this.usesSingleMessage || this.messages.length !== 1) {
      return null;
    }
    return this.messages.firstObject;
  }

  @computed('usesSingleMessage', 'schedules.[]')
  get singleSchedule() {
    if (!this.usesSingleMessage || this.schedules.length !== 1) {
      return null;
    }
    return this.schedules.firstObject;
  }

  @mapBy('messages', 'messageVersions') messageVersionsIntermediary;

  @computed('usesSingleMessage', 'messageVersionsIntermediary.@each.[]')
  get messageVersions() {
    return this.usesSingleMessage
      ? this.messageVersionsIntermediary.firstObject?.toArray()
      : this.messageVersionsIntermediary.mapBy('firstObject').compact();
  }

  @computed('hasDirtyAttributes', '{messages,schedules}.@each.hasDirtyAttributes')
  get isAnythingDirty() {
    return (
      this.hasDirtyAttributes || this.messages.isAny('hasDirtyAttributes') || this.schedules.isAny('hasDirtyAttributes')
    );
  }

  @computed('id')
  get idNumber() {
    return parseInt(this.id, 10);
  }

  @computedRecurringWords('scheduleRecurringPattern', 'startDate', 'scheduleDelayDatepartTypeId', 'scheduleDelayValue')
  recurringWords;

  @computedAnnualEmailTypeOffsetWords(
    'scheduleStartTime',
    'scheduleDelayValue',
    'scheduleDelayDatepartTypeId',
    'emailDisplayName'
  )
  annualEmailTypeOffsetWords;

  @enums.computed('name', 'sendingStatusTypeId', 'sendingStatusTypeId', 'MESSAGE_SENDING_STATUS_TYPE')
  sendingStatusType;
  @enums.computed('name', 'messageCampaignTypeId') messageCampaignType;
  @equal('sendingStatusTypeId', 5) isSent;

  @computed('statusTypeId')
  get isArchived() {
    return this.statusTypeId === this.enums.findWhere('STATUS_TYPE', { name: 'InActive' });
  }

  @computed('sendingStatusTypeId')
  get isIncomplete() {
    return this.sendingStatusTypeId === 0 || this.sendingStatusTypeId === undefined;
  }

  /**
   * Returns true if not a single instance of the message campaign has been sent.
   * This is not necessarily the inverse of "isSent" because "isSent" currently only looks
   * at one sendingStatusTypeId.
   * @type {Boolean}
   */
  @computed('sendingStatusType')
  get hasNotSent() {
    return ['Incomplete', 'Scheduled'].includes(this.sendingStatusType);
  }

  @computedSchedulePrefix('messageCampaignType', 'isSent') schedulePrefix;

  @or(
    'isSingleEmail',
    'isBirthday',
    'isNewsletter',
    'isInvite',
    'isThankYou',
    'isReceipt',
    'isReminder',
    'isWeddingAnniversary'
  )
  usesSingleMessage;

  // TODO: [SMC Refactor] Remove once singleMessageCampaign is gone.
  @readOnly('usesSingleMessage') usesSingleMessageCampaign;
  @or('isSingleEmail', 'isInvite', 'isThankYou') hasABTesting;

  @computed('messageCampaignType', 'syndicateCount', 'hasSent')
  get canAddMoreMessageVersions() {
    if (['SingleEmail', 'DripCampaign'].includes(this.messageCampaignType) && isBlank(this.syndicateCount)) {
      return !this.hasSent;
    }
    return false;
  }

  @computed('messageCampaignType', 'hasSent', 'sendingStatusType', 'syndicateCount')
  get cannotCurrentlyEdit() {
    //Campaigns cannot be edited after they have been syndicated or if the user is not an admin
    if (this.syndicateCount || !this.permissions.getAccessLevel('MessageCampaign').administer) {
      return true;
    }

    switch (this.messageCampaignType) {
      //Campaigns that cannot be edited after they have gone out the FIRST time
      case 'SingleEmail':
      case 'Invite':
        return this.hasSent;
      //Campaigns that cannot be edited after they have gone out the LAST time
      case 'Birthday':
      case 'Newsletter':
      case 'WeddingAnniversary':
        return this.sendingStatusType === 'Sent';
      case 'Welcome':
        return !this.permissions.getAccessLevel('OptInAudience').administer;
      //Campaigns that can ALWAYS be edited
      case 'DripCampaign':
      case 'ThankYou':
      case 'Reminder':
      case 'Receipt':
      case 'DoubleOptinConfirmation':
        return false;
      default:
        throw new Error('Unrecognized message campaign type');
    }
  }

  @not('cannotCurrentlyEdit') canCurrentlyEdit;
  @and('isDripCampaign', 'hasStarted') dripCampaignInProgress;

  @computed('messageCampaignType')
  get displayName() {
    return DESIGNER_FLYOUT_LABEL[this.messageCampaignType] || DESIGNER_FLYOUT_LABEL.FALLBACK;
  }

  /**
   * These message campaigns are pre-confirmed and cannot be unconfirmed
   */
  @readOnly('isReceipt') isAlwaysConfirmed;

  /**
   * Whether or not Schedule and Recipients steps are part of the Design step
   */
  @readOnly('isInvite') scheduleAndRecipientsInDesignStep;

  /**
   * Whether or not the Test & Confirm step is part of the Design Step
   */
  @or('isWelcome', 'isInvite', 'isThankYou', 'isReminder', 'isDoubleOptinConfirmation') quickConfirmation;

  /**
   * Message campaigns with one general dashboard
   */
  @or('isSingleEmail', 'isBirthday', 'isInvite', 'isWelcome', 'isWeddingAnniversary') hasSingleDashboard;

  /**
   * Message campaigns that use the RSS service to import individual articles
   */
  @or('isSingleEmail', 'isDripCampaign', 'isBirthday', 'isWelcome', 'isWeddingAnniversary') usesArticleImportFeature;

  /**
   * Describes message campaigns that are tied to promotions
   */
  @or('isInvite', 'isThankYou', 'isReminder', 'isReceipt') isPromoEmail;

  @computed('name')
  get isNameDirty() {
    // Force it to pretend not to be dirty if the name is blank.
    return isBlank(this.name) ? false : !isEmpty(this.changedAttributes().name);
  }

  @computed('name')
  get nameStatus() {
    return isBlank(this.name) ? 'incomplete' : 'bestPractice';
  }

  @computed('messageCampaignCategory')
  get categoryStatus() {
    return isEmpty(this.messageCampaignCategory) ? 'incomplete' : 'bestPractice';
  }

  @computed('nameStatus', 'categoryStatus')
  get isSaveEnabled() {
    return this.categoryStatus !== 'incomplete' && this.nameStatus !== 'incomplete';
  }

  /**
   * Be wary of this, it doesn't always return the proper inverse of sendToExistingAudienceMembers...
   */
  @not('sendToExistingAudienceMembers') onlyNewMembers;
  @dirtyProperty('sendToExistingAudienceMembers') isSendToExistingAudienceMembersDirty;

  /**
   * If this is true, the campaign type is both:
   *   - Recurring; it lasts for more than one send.
   *   - Audience Hot-Swappable; target audiences can be added / removed.
   */
  @readOnly('isNewsletter') canAudiencesBeReplacedOverTime;

  /**
   * Returns an estimated recipients count.  In general, this returns the audienceMemberCount until the first message is sent.
   * At that point, it begins returning the peopleSentToCount from the latest send attempt instead.
   * But if it can have its audiences swapped out, the `peopleSentToCount` can be way too large.
   */
  @computed(
    'isWelcome',
    'canAudiencesBeReplacedOverTime',
    'sendingStatusType',
    'peopleSentToCount',
    'audienceMemberCount'
  )
  get estimatedRecipientsCount() {
    const { sendingStatusType } = this;
    const { audienceMemberCount } = this;
    const { peopleSentToCount } = this;

    if (this.canAudiencesBeReplacedOverTime) {
      return audienceMemberCount;
    }
    if (this.isWelcome || ['SentAndScheduled', 'Sent'].includes(sendingStatusType)) {
      return peopleSentToCount;
    }
    return audienceMemberCount;
  }

  @computed('messageSendingStatusType', 'sendingStatusType', 'undoPhase', 'singleSchedule.hasStarted')
  get hasStarted() {
    const status = this.messageSendingStatusType || this.sendingStatusType;
    return (
      get(this, 'singleSchedule.hasStarted') ||
      (typeof status === 'string' && status !== 'Incomplete' && status !== 'Scheduled') ||
      this.undoPhase
    );
  }

  undoPhase = false;

  @equal('sendingStatusType', 'SentAndScheduled') isSentAndScheduled;
  @and('createdByOrganizationUser.{firstName,lastName}') messageCampaignCreatorHasFullName;

  @computed('messageCampaignCreatorHasFullName', 'createdByOrganizationUser.{firstName,lastName}')
  get messageCampaignCreatorFullName() {
    if (!this.messageCampaignCreatorHasFullName) {
      return '';
    }
    return `${get(this, 'createdByOrganizationUser.firstName')} ${get(this, 'createdByOrganizationUser.lastName')}`;
  }

  @computed('messageCampaignType')
  get emailDisplayName() {
    return this.messageCampaignType
      .match(/[A-Z][a-z]+/g)
      .join(' ')
      .toLowerCase();
  }

  @or('isSingleEmail', 'isNewsletter', 'isInvite') hasMessageVersionHistories;
  @or('isBirthday', 'isWeddingAnniversary') isAnnualMessageCampaign;
  @gte('audienceMemberCount', 1) hasRecipients;

  //endregion

  // Region Shim Properties - For SingleMessageCampaign/MessageCampaign merge refactor
  // Developer note: this.singleMessageCampaign.get() has to be used when referencing because
  // this.singleMessageCampaign is a Proxy object.

  // @Deprecated
  @computed('singleMessage.isScheduled', 'usesSingleMessageCampaign')
  get isScheduled() {
    if (this.usesSingleMessageCampaign) {
      return get(this.singleMessage, 'isScheduled');
    }
    return false;
  }

  set isScheduled(value) {
    if (this.usesSingleMessageCampaign && this.singleMessage.sendingStatusTypeId < 2) {
      return set(
        this,
        'singleMessage.sendingStatusTypeId',
        value
          ? this.enums.findWhere('MESSAGE_SENDING_STATUS_TYPE', { name: 'Scheduled' })
          : this.enums.findWhere('MESSAGE_SENDING_STATUS_TYPE', { name: 'Incomplete' })
      );
    }

    return false;
  }

  // TODO: [SMC Refactor] In business logic, a lot of these should really read
  // from singleSchedule or singleMessage for clarity
  @alias('sendingStatusTypeId') messageSendingStatusTypeId;
  @alias('singleMessage.id') messageId;
  @alias('singleMessage.sendingStatusType') messageSendingStatusType;
  @alias('singleMessage.testDurationMinutes') messageTestDurationMinutes;
  @alias('singleMessage.testDurationOpenCount') messageTestDurationOpenCount;
  @alias('singleMessage.approvalMinutesBeforeSend') messageApprovalMinutesBeforeSend;
  @alias('singleMessage.communicationMediumTypeId') messageCommunicationMediumTypeId;
  @alias('singleMessage.testPercentage') messageTestPercentage;
  @alias('singleMessage.testWinningCriteriaId') messageTestWinningCriteriaId;
  @alias('singleMessage.isApprovalRequired') messageIsApprovalRequired;

  @computed('messageSendingStatusType')
  get isScheduledSentOrSending() {
    return ['Scheduled', 'Sent', 'Sending', 'SentAndScheduled', 'TestSend'].includes(this.messageSendingStatusType);
  }

  // TODO: [SMC Refactor] To be 1:1 w/ Single Message campaign, needs
  // to be an int. may not get away with alias
  @alias('singleSchedule.id')
  scheduleId;

  @alias('singleSchedule.scheduleTypeId')
  scheduleTypeId;

  @alias('singleSchedule.startDate')
  scheduleStartDate;

  @alias('singleSchedule.endDate')
  scheduleEndDate;

  @alias('singleSchedule.numberOfRecurrences')
  scheduleNumberOfRecurrences;

  @alias('singleSchedule.sendNow')
  sendNow;

  @alias('singleSchedule.sendNowOffset')
  sendNowOffset;

  /**
   * This is NOT the end of the entire campaign. It is the end of the A/B test period.
   */
  @computed('scheduleStartDate', 'messageTestDurationMinutes')
  get endOfABTestingDate() {
    const { scheduleStartDate } = this;
    if (scheduleStartDate) {
      const messageTestDurationMinutes = this.messageTestDurationMinutes || 0;
      return moment(scheduleStartDate).add(messageTestDurationMinutes, 'minutes').toDate();
    }
    return null;
  }

  @computed('scheduleStartDate')
  get formattedScheduleStartDate() {
    const { scheduleStartDate } = this;
    if (scheduleStartDate) {
      const date = moment(scheduleStartDate);
      return `${date.format('dddd, MMMM Do YYYY')} at ${date.format('hh:mma')}`;
    }
    return null;
  }

  @enums.computed('name', 'scheduleRecurringDatepartTypeId', 'DATEPART_TYPE')
  scheduleDelayDatepartType;

  @computed('messageSendingStatusType', 'sendingStatusType')
  get hasSent() {
    return ['Sent'].includes(this.messageSendingStatusType || this.sendingStatusType);
  }

  @computed('messageSendingStatusType', 'sendingStatusType')
  get hasSentAndScheduled() {
    return ['SentAndScheduled'].includes(this.messageSendingStatusType || this.sendingStatusType);
  }

  @alias('singleMessage.isTestDurationMinutesDirty')
  isMessageTestDurationMinutesDirty;

  @alias('singleMessage.isTestDurationOpenCountDirty')
  isMessageTestDurationOpenCountDirty;

  @alias('singleMessage.isTestPercentageDirty')
  isMessageTestPercentageDirty;

  @alias('singleMessage.isTestWinningCriteriaIdDirty')
  isMessageTestWinningCriteriaIdDirty;

  @alias('singleSchedule.isStartDateDirty')
  isScheduleStartDateDirty;

  @equal('messageSendingStatusType', 'Sending')
  isSending;

  @enums.computed('name', 'messageTestWinningCriteriaId', 'messageTestWinningCriteriaId')
  messageTestWinningCriteria;

  @not('hasABTesting')
  noABTesting;

  // TODO: [SMC Refactor] Will these aliases work for input validation?
  @alias('singleMessage.errors')
  singleMessageErrors;

  @alias('singleSchedule.errors')
  singleScheduleErrors;

  @union('errors', 'singleMessageErrors', 'singleScheduleErrors')
  allErrors;

  //endregion

  // This needs to be used over messageCampaign.rollbackAttributes
  // to keep all of its relationships up to date since
  // we're no longer using the Single Message Campaign model
  rollBackAll() {
    this.messages.map(x => x.rollbackAttributes());
    this.schedules.map(x => x.rollbackAttributes());
    this.rollbackAttributes();
  }

  async saveAll() {
    await Promise.all([
      ...this.messages.filterBy('hasDirtyAttributes').map(x => x.save()),
      ...this.schedules.filterBy('hasDirtyAttributes').map(x => x.save()),
      this.save(),
    ]);

    // TODO: [SMC Refactor] Remove once singleMessageCampaign is removed
    const singleMessageCampaign = await this.singleMessageCampaign;
    await singleMessageCampaign.reload();
  }
}
