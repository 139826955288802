/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { deprecate } from '@ember/debug';
import Component from '@ember/component';

/**
 * Component for a material styled checkbox
 * Yields to the block if you pass one for the label, or uses the `label` attr if you don't pass a block.
 */
export default Component.extend({
  //region Ember Hooks
  tagName: 'ss-checkbox-material',
  classNameBindings: ['disabled'],
  attributeBindings: ['data-test'],
  //endregion

  //region Attributes
  'two-way': true,
  'hide-label': false,
  disabled: false,
  'data-test': '',
  'label-position': 'after',
  //endregion

  //region Events
  click() {
    if (!this.disabled) {
      if (this['two-way']) {
        this.toggleProperty('value');
        deprecate('Using two-way binding for {{ss-checkbox-material}} is deprecated.', false, {
          id: 'ss-checkbox-material-two-way',
          until: 'Ember 4',
          for: 'ss-checkbox-material',
          since: '0.0.0',
        });
      } else if (this.changed) {
        this.changed(!this.value);
      }
    }
  },
  //endregion
});
