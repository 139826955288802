/* eslint-disable ember/use-ember-data-rfc-395-imports */
import type { default as Store, Snapshot } from '@ember-data/store';
import { inject as service } from '@ember/service';
import { ModelSchema } from 'ember-data';
import type ModelRegistry from 'ember-data/types/registries/model';
import type SentryService from 'partner/services/sentry';
import type RSVP from 'rsvp';
import type LoggerService from 'secondstreet-common/services/logger';
import ApplicationAdapter from './application';
import ParticipationAreaModel from 'partner/models/participation-area';

type Result = {
  participation_area: [
    {
      id: number;
      country_id: number;
      country_name: string;
      state_provinces: {
        id: number;
        state_province_id: number;
        state_province_name: string;
      }[];
      postal_codes: {
        id: number;
        postal_code: string;
      }[];
    }
  ];
};

export default class ParticipationAreaAdapter extends ApplicationAdapter {
  @service declare logger: LoggerService;
  @service declare sentry: SentryService;

  createRecord<K extends keyof ModelRegistry = 'participation-area'>(
    store: Store,
    type: ModelSchema<K>,
    snapshot: Snapshot<K>
  ): RSVP.Promise<any> {
    return super.createRecord(store, type, snapshot).then((result: Result) => {
      this.unloadPreviousRelationshipsFor(snapshot.record as ParticipationAreaModel);

      return result;
    });
  }

  updateRecord<K extends keyof ModelRegistry = 'participation-area'>(
    store: Store,
    type: ModelSchema<K>,
    snapshot: Snapshot<K>
  ): RSVP.Promise<any> {
    return super.updateRecord(store, type, snapshot).then((result: Result) => {
      this.unloadPreviousRelationshipsFor(snapshot.record as ParticipationAreaModel);

      if (!result.participation_area[0]?.state_provinces) {
        result.participation_area[0].state_provinces = [];
      }

      if (!result.participation_area[0]?.postal_codes) {
        result.participation_area[0].postal_codes = [];
      }
      return result;
    });
  }

  /**
   * Ember Data cannot handle cases when new relationships are create in the same request,
   * so it keeps the old ones in memory as "new" ones (without an id).
   * We need to unload the "entry" as well as the backend returns a new address,
   * which keeps the existing record in dirty state.
   */
  private unloadPreviousRelationshipsFor(participationArea: ParticipationAreaModel) {
    if (!participationArea) return;

    try {
      participationArea.stateProvinces.filterBy('isNew').forEach(state => state.unloadRecord());
      participationArea.postalCodes.filterBy('isNew').forEach(pincode => pincode.unloadRecord());
    } catch (e) {
      this.sentry.captureException(e);
      this.logger.error(e);
    }
  }
}
