/* eslint-disable ember/no-get */
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
const MODERATE_NOMINATIONS_ROUTE =
  'organizations.organization.organization-promotions.organization-promotion.moderate-nominations';

export default class ModerateNominationsApproveRoute extends Route {
  @service enums;
  @service store;
  @service settings;

  async model() {
    const { matchupId } = this.paramsFor(MODERATE_NOMINATIONS_ROUTE);
    const nominationEntriesSummaries = get(this.modelFor(MODERATE_NOMINATIONS_ROUTE), 'nominationEntriesSummaries');

    nominationEntriesSummaries.filterBy('votingEntry', null).forEach(nes => {
      this.store.createRecord('voting-entry', {
        matchupId: get(nes, 'matchup.id'),
        statusTypeId: this.enums.findWhere('STATUS_TYPE', {
          name: 'Submitted',
        }),
        mediaItemId: get(nes, 'mediaItemId'),
      });
    });

    return RSVP.hash({
      matchupId,
      _settings: this.settings.preload(['allow_manual_additions', 'allow_ties', 'approved_entrants_for_voting'], {
        force: true,
      }),
    });
  }
}
