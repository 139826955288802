export const getFonts = () => {
  return [
    'Archivo',
    'Arial',
    'Helvetica',
    'Georgia',
    'Times New Roman',
    'Monospace',
    'Aleo',
    'Asap Condensed',
    'Barlow',
    'BioRhyme',
    'Bitter',
    'Caladea',
    'Caveat',
    'Chivo',
    'Courgette',
    'Dancing Script',
    'DM Sans',
    'Enriqueta',
    'Fraunces',
    'Great Vibes',
    'Inter',
    'Lato',
    'Manrope',
    'Merriweather',
    'Montserrat',
    'Muli',
    'Noto Sans',
    'Nunito',
    'Open Sans',
    'Oswald',
    'Pacifico',
    'Playfair Display',
    'Poppins',
    'Proza Libre',
    'PT Serif',
    'Raleway',
    'Roboto',
    'Roboto Condensed',
    'Rochester',
    'Rokkitt',
    'Sacramento',
    'Source Sans Pro',
    'Source Serif Pro',
  ].sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));
};
