import flattenDeep from 'lodash/flattenDeep';

/**
 * @param {Field[]} fields
 * @returns {GlobalOptin[]}
 */
export default function (fields) {
  const globalOptins = flattenDeep(fields.map(x => x.globalOptin)).compact();
  return globalOptins.filter(
    x =>
      globalOptins.filter(
        y =>
          // For now, we'll just do an exact name match.
          x.name === y.name
      ).length > 1
  );
}
