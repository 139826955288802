/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, observer, set } from '@ember/object';
import { equal, readOnly } from '@ember/object/computed';
import { next } from '@ember/runloop';
import { isEmpty } from '@ember/utils';

export default Component.extend({
  //region Computed Properties
  schedules: computed('model.messages.@each.schedule', function () {
    return get(this, 'model.messages').map(x => get(x, 'schedule'));
  }),
  personalizedDate: computed(
    'model.{dateFields,messages.firstObject.schedule.relatedEventEntityId}',
    'isPersonalizedDate',
    function () {
      let personalizedDate = { name: 'Select Personalized Date' };
      if (this.isPersonalizedDate) {
        const dateFieldId = get(this, 'model.messages.firstObject.schedule.relatedEventEntityId');
        if (dateFieldId) {
          personalizedDate = get(this, 'model.dateFields').findBy('id', dateFieldId.toString());
        }
      }
      return personalizedDate;
    }
  ),
  showPersonalDateModal: false,
  isPersonalizedDate: equal('model.messages.firstObject.schedule.scheduleTypeId', 6),
  dripMessages: computed('model.messages.length', 'teaserMessages', function () {
    return get(this, 'model.messages.length') < 1 ? this.teaserMessages : get(this, 'model.messages');
  }),
  isTriggerDateDirty: readOnly('model.messages.firstObject.schedule.isScheduleTypeIdDirty'),
  areDatesDirty: computed(
    'schedules.@each.{isStartTimeDirty,isDelayDatepartTypeDirty,isDelayValueDirty}',
    'isTriggerDateDirty',
    function () {
      get(this, 'model.messages').forEach(message => {
        if (
          get(message, 'schedule.isStartTimeDirty') ||
          get(message, 'schedule.isDelayDatepartTypeDirty') ||
          get(message, 'schedule.isDelayValueDirty' || this.isTriggerDateDirty)
        ) {
          return true;
        }
      });
      return false;
    }
  ),
  triggerStatus: computed('excludedAudiences.length', function () {
    return isEmpty(this.isPersonalizedDate) ? 'incomplete' : 'bestPractice';
  }),
  dateStatus: computed('schedules.@each.startTime', 'isTriggerDateDirty', function () {
    let dateStatus = 'bestPractice';
    get(this, 'model.messages').forEach(message => {
      if (isEmpty(get(message, 'schedule.startTime'))) {
        if (
          this.isTriggerDateDirty ||
          this.isPersonalizedDate ||
          (!this.isPersonalizedDate && isEmpty(get(message, 'schedule.scheduleTypeId')))
        ) {
          dateStatus = 'incomplete';
        }
      }
    });
    return dateStatus;
  }),
  isChecklistStepSatisfied: computed(
    'schedules.@each.{delayDatepartTypeId,delayValue,startTime}',
    'triggerStatus',
    'dateStatus',
    function () {
      let dripScheduled = true;
      get(this, 'model.messages').forEach(message => {
        if (get(message, 'schedule.delayDatepartTypeId') !== 1) {
          if (isEmpty(get(message, 'schedule.delayValue')) || isEmpty(get(message, 'schedule.startTime'))) {
            dripScheduled = false;
          }
        }
      });
      // Read them out here to avoid JS boolean shortcutting *not* reading them all the first time through.
      const { dateStatus } = this;
      const { triggerStatus } = this;
      return dateStatus === 'bestPractice' && triggerStatus === 'bestPractice' && dripScheduled;
    }
  ),
  //endregion

  //region Observers
  sendChecklistStepSatisfaction: observer(
    'schedules.@each.{delayDatepartTypeId,delayValue,startTime}',
    'triggerStatus',
    'dateStatus',
    function () {
      this['checklist-step-satisfied'](this.isChecklistStepSatisfied);
    }
  ),
  //endregion
  lastSetTime: '',
  teaserMessages: computed(() => [
    {
      isTeaser: true,
      schedule: {
        delayValue: 'immediately',
        delayDatepartTypeId: 1,
      },
      messageVersions: [{ subject: 'Create Message 1 in the Message Section.' }],
    },
    {
      schedule: {
        delayValue: 1,
        delayDatepartTypeId: 4,
      },
      messageVersions: [{ subject: 'Create Message 2 in the Message Section.' }],
    },
  ]),

  //region Actions
  actions: {
    setPersonalizedDate(isPersonalizedDate) {
      if (this.model.messageCampaign.isConfirmed) return;

      if (isPersonalizedDate) {
        set(this, 'showPersonalDateModal', true);
        set(
          this,
          'model.messages.firstObject.schedule.relatedEventEntityId',
          parseInt(get(this, 'personalizedDate.id'))
        );
      } else {
        set(this, 'model.messages.firstObject.schedule.scheduleTypeId', 4);
        set(this, 'model.messages.firstObject.schedule.relatedEventEntityId', undefined);
        if (get(this, 'model.messages.firstObject.schedule.delayValue') < 0) {
          set(this, 'model.messages.firstObject.schedule.delayValue', 0);
          set(this, 'model.messages.firstObject.schedule.delayDatepartTypeId', 1);
        }
        this.save();
      }
    },
    setSelectedOperator(operatorType) {
      set(this, 'model.messages.firstObject.schedule.relatedEventEntityId', operatorType.id);
      set(this, 'model.messages.firstObject.schedule.scheduleTypeId', 6);
      next(() => this.save());
    },
    change(message) {
      const time = this.lastSetTime;
      const selectedValue = parseInt(this.element.querySelector(`#ss-select-${message.id}`).value);

      set(message, 'schedule.dripDelayDatepartValue', selectedValue);
      if (Math.abs(selectedValue) === 1) {
        set(message, 'schedule.delayValue', 0);
      } else if (get(message, 'schedule.delayValue') === 0) {
        set(message, 'schedule.delayValue', 1);
      }

      if (
        get(message, 'schedule.delayDatepartTypeId') > 1 &&
        isEmpty(get(message, 'schedule.startTime')) &&
        !isEmpty(get(message, 'schedule.updatedTime'))
      ) {
        set(message, 'schedule.updatedTime', time);
        set(message, 'schedule.startTime', time);
      }
    },
    focusOutDrip(time) {
      const messages = get(this, 'model.messages');
      messages.forEach(message => {
        if (get(message, 'schedule.delayDatepartTypeId') > 1 && isEmpty(get(message, 'schedule.startTime'))) {
          set(message, 'schedule.updatedTime', time);
          set(message, 'schedule.startTime', time);
        }
      });
      set(this, 'lastSetTime', time);
    },
    showRemoveConfirmation(message) {
      set(this, 'messageToDelete', message);
      this.toggleProperty('showRemoveConfirmation');
    },
    removeMessage() {
      const { messageToDelete } = this;
      this.removeMessage(messageToDelete);
      this.toggleProperty('showRemoveConfirmation');
    },
    cancelRemove() {
      set(this, 'messageVersionToBeDeleted', null);
      this.toggleProperty('showRemoveConfirmation');
    },
  },
  //endregion
});
