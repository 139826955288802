import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"loading-container\">\n  <div class=\"loading-item\" >\n    <MdcLinearProgress @indeterminate={{true}} />\n  </div>\n</div>\n", {"contents":"<div class=\"loading-container\">\n  <div class=\"loading-item\" >\n    <MdcLinearProgress @indeterminate={{true}} />\n  </div>\n</div>\n","moduleName":"partner/components/loading-animation.hbs","parseOptions":{"srcName":"partner/components/loading-animation.hbs"}});
import Component from '@glimmer/component';
import range from 'lodash/range';
import shuffle from 'lodash/shuffle';

export default class LoadingAnimation extends Component {
  //region Properties
  get randomizedAnimation() {
    return shuffle(
      range(1, 9)
        .concat(range(10, 18))
        .map(n => `animatedIcon${n}`)
    );
  }
  //endregion
}
