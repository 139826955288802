import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @showEmptyState}}\n  <p class=\"engagement-dashboard__empty\">Chart has no data.</p>\n{{else}}\n  <Chart::Loader @charts={{array @allUsersChart @newUsersChart}} @height=\"200px\" class=\"shadow-none\">\n    {{#if @allUsersChart.categories}}\n      <SsChart\n        @type=\"area\"\n        @data={{this.data.main}}\n        @secondData={{this.data.secondary}}\n        @firstDataLabel=\"Existing\"\n        @secondDataLabel=\"New\"\n        @selectedInterval=\"days\"\n        @yAxisFromZero={{true}}\n        @outerHeight={{200}}\n        @horizontalMargin={{0}}\n        @verticalMargin={{0}}\n        @minSlicePercent={{0}}\n        @maxLabelHeight={{35}}\n      />\n    {{else}}\n      <p class=\"engagement-dashboard__empty\">Hang tight, we're building your chart. Check back at the top of the hour.</p>\n    {{/if}}\n  </Chart::Loader>\n{{/if}}\n", {"contents":"{{#if @showEmptyState}}\n  <p class=\"engagement-dashboard__empty\">Chart has no data.</p>\n{{else}}\n  <Chart::Loader @charts={{array @allUsersChart @newUsersChart}} @height=\"200px\" class=\"shadow-none\">\n    {{#if @allUsersChart.categories}}\n      <SsChart\n        @type=\"area\"\n        @data={{this.data.main}}\n        @secondData={{this.data.secondary}}\n        @firstDataLabel=\"Existing\"\n        @secondDataLabel=\"New\"\n        @selectedInterval=\"days\"\n        @yAxisFromZero={{true}}\n        @outerHeight={{200}}\n        @horizontalMargin={{0}}\n        @verticalMargin={{0}}\n        @minSlicePercent={{0}}\n        @maxLabelHeight={{35}}\n      />\n    {{else}}\n      <p class=\"engagement-dashboard__empty\">Hang tight, we're building your chart. Check back at the top of the hour.</p>\n    {{/if}}\n  </Chart::Loader>\n{{/if}}\n","moduleName":"partner/components/dashboards/people-chart.hbs","parseOptions":{"srcName":"partner/components/dashboards/people-chart.hbs"}});
import Component from '@glimmer/component';
import { parseData } from 'partner/utils/parse-chart-data';

export default class DashboardsPeopleChartComponent extends Component {
  get data() {
    return parseData({
      mainCategories: this.args.allUsersChart.categories,
      secondaryCategories: this.args.newUsersChart.categories,
      aggregate: false,
      interval: 'days',
    });
  }
}
