/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { notEmpty, or } from '@ember/object/computed';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

export default Component.extend({
  store: service(),

  //region Properties
  searchInput: '',
  searchValue: '',
  search: '',
  //endregion

  //region Attributes
  'merge-action'() {},
  'close-action'() {},
  'select-name'() {},
  //endRegion

  init() {
    this._super(...arguments);
    this.loadAudience();
  },

  loadAudience() {
    this.store
      .query('audience', {
        BypassCache: true,
        isSegmentedAudience: false,
      })
      .then(audiences => {
        set(this, 'allItems', audiences.toArray());
      });
  },

  //region Computed Properties
  selectedMergeOptins: computed('chosen-items.[]', 'new-optin', function () {
    if (this.hasThirdPartyOrInherited) {
      return this['chosen-items'];
    }

    const mergedAudiences = [];
    const masterAudience = this['chosen-items'].findBy('id', get(this, 'new-optin.id'));
    const subAudience = this['chosen-items'].rejectBy('id', get(this, 'new-optin.id'));
    mergedAudiences.addObject(masterAudience);
    mergedAudiences.addObject(get(subAudience, 'firstObject'));
    return mergedAudiences;
  }),
  isMergingThirdPartyOptin: computed('chosen-items.[]', function () {
    return this['chosen-items'].isAny('hasThirdPartyOptin');
  }),
  isMergingInheritedOptin: computed('chosen-items.[]', function () {
    return this['chosen-items'].isAny('optinField.isInherited');
  }),
  hasThirdPartyOrInherited: or('isMergingThirdPartyOptin', 'isMergingInheritedOptin'),
  isMergingOptinWithNotifications: computed('chosen-items.[]', function () {
    return this['chosen-items'].isAny('optinField.hasOptinNotifications');
  }),
  showSearchResults: notEmpty('searchValue'),
  items: computed('allItems.[]', 'searchValue', function () {
    const audiences = this.allItems || [];
    const search = this.searchValue.toLowerCase();

    if (isBlank(search)) {
      return audiences;
    }

    let matches = [];
    const searchForMatches = audiences => {
      if (audiences) {
        matches = audiences.filter(
          audience => get(audience, 'name') && get(audience, 'name').toLowerCase().indexOf(search.toLowerCase()) > -1
        );
      }
    };
    searchForMatches(audiences);
    return matches;
  }),
  //We need to mimic the hbs union of the list order on the audience index.hbs
  sortedItems: computed('items', function () {
    return [
      ...this.items
        .filterBy('isStarred')
        .filterBy('isRecentlyCreated')
        .rejectBy('isLegacyAudience')
        .sortBy('dateCreated')
        .reverse(),
      ...this.items
        .filterBy('isStarred')
        .filterBy('isSent')
        .rejectBy('isRecentlyCreated')
        .rejectBy('isLegacyAudience')
        .sortBy('lastSentDate')
        .reverse(),
      ...this.items
        .filterBy('isStarred')
        .rejectBy('isSent')
        .rejectBy('isRecentlyCreated')
        .rejectBy('isLegacyAudience')
        .sortBy('latestCount')
        .reverse(),
      ...this.items.filterBy('isStarred').filterBy('isLegacyAudience').sortBy('name'),
      ...this.items
        .rejectBy('isStarred')
        .filterBy('isRecentlyCreated')
        .rejectBy('isLegacyAudience')
        .sortBy('dateCreated')
        .reverse(),
      ...this.items
        .rejectBy('isStarred')
        .filterBy('isSent')
        .rejectBy('isRecentlyCreated')
        .rejectBy('isLegacyAudience')
        .sortBy('lastSentDate')
        .reverse(),
      ...this.items
        .rejectBy('isStarred')
        .rejectBy('isSent')
        .rejectBy('isRecentlyCreated')
        .rejectBy('isLegacyAudience')
        .sortBy('latestCount')
        .reverse(),
      ...this.items.rejectBy('isStarred').filterBy('isLegacyAudience').sortBy('name'),
    ];
  }),
  //endregion

  //region Methods
  /**
   * Returns true if the name of the item matches the search input
   * @private
   */
  _updateSearch(searchValue) {
    set(this, 'searchValue', searchValue);
  },
  //endregion

  //region Actions
  actions: {
    searchValueChanged(event) {
      debounce(this, this._updateSearch, event.target.value, 250);
    },
    confirmMerge(audiences) {
      this.store
        .createRecord('fieldMergeQueue', {
          masterFieldId: get(audiences, 'firstObject.optinField.id'),
          subFieldId: get(audiences, 'lastObject.optinField.id'),
        })
        .save();
      this['merge-action'](audiences);
    },
    close() {
      this['close-action']();
    },
    mergedNameChanged(audience) {
      this['select-name'](audience);
    },
  },
  //endregion
});
