/* eslint-disable ember/no-mixins, ember/no-get, ember/no-computed-properties-in-native-classes */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { inject as service } from '@ember/service';
import PerformanceReportCommonController from 'partner/-base/performance-report-controller';
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import camelizeKeys from 'partner/utils/camelize-keys';
import {
  type SummaryTypeModel,
  createOrganizationTree,
  getOrganizationColumns,
} from 'partner/utils/performance-report';
import CurrentService from 'partner/services/current';
import type ReportsService from 'partner/services/reports';
import OrganizationPerformanceStatisticModel from 'partner/models/organization-performance-statistic';
import type { Model } from 'partner/routes/organizations/organization/performance-report';

export default class PerformanceReportController extends PerformanceReportCommonController {
  @service declare reports: ReportsService;
  @service declare current: CurrentService;

  declare model: Model;

  @computed('rows')
  get columns() {
    const expandableColumn = this.rows && this.rows.any((row: any) => row.children);
    return expandableColumn
      ? [
          {
            label: '',
            width: 'max-content',
          },
          ...getOrganizationColumns(),
        ]
      : getOrganizationColumns();
  }

  @computed('model.meta.performanceStatisticsTotalCounts')
  get stats() {
    return this.model?.meta?.performanceStatisticsTotalCounts
      ? camelizeKeys(this.model.meta.performanceStatisticsTotalCounts)
      : [];
  }

  @computed('orgId', 'model.organizationPerformanceStatistics.[]', 'sortColumn', 'sortDirection')
  get organizationTree() {
    return createOrganizationTree(
      this.orgId,
      this?.model?.organizationPerformanceStatistics as SummaryTypeModel[],
      this.sortColumn || 'organizationName',
      this.sortDirection || 'asc'
    );
  }

  @computed('organizationTree.children', 'organizationIds', 'model.organizationPerformanceStatistics.[]')
  get rows(): OrganizationPerformanceStatisticModel[] {
    const selectedOrgIds = this.organizationIds ? this.organizationIds?.split(',') : [];
    if (isPresent(selectedOrgIds)) {
      return this?.model?.organizationPerformanceStatistics?.filter((org: OrganizationPerformanceStatisticModel) =>
        selectedOrgIds.includes(`${org.id}`)
      );
    }
    return isPresent(this.organizationTree?.children)
      ? (this.organizationTree?.children?.reject(
          org => !org?.organizationName
        ) as OrganizationPerformanceStatisticModel[])
      : isPresent(this.organizationTree)
      ? ([this.organizationTree] as OrganizationPerformanceStatisticModel[])
      : [];
  }

  get summaryStats(): { header: string; primary: string }[] {
    return [
      {
        header: 'Promotions',
        primary: 'totalPromotionRunCount',
      },
      {
        header: 'People',
        primary: 'totalUserCount',
      },
      {
        header: 'New People',
        primary: 'totalNewUserCount',
      },
      {
        header: 'New Opt Ins',
        primary: 'totalOptInCount',
      },
      {
        header: 'Emails Sent',
        primary: 'totalEmailCount',
      },
    ];
  }
}

declare module '@ember/controller' {
  interface Registry {
    'organizations.organization.performance-report': PerformanceReportController;
  }
}
