/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { not } from '@ember/object/computed';
import PermittedComponent from 'partner/mixins/permitted-component';

export default Component.extend(PermittedComponent, {
  // region ember hooks
  tagName: 'ss-entry-form-form-field',
  classNameBindings: ['disabled'],
  // end region

  // region properties
  showModal: false,
  //end region

  //region Attributes
  /**
   * If you want to manually disable the component, pass this as true.
   */
  'is-disabled': false,
  'hide-label': false,
  // end region

  // region computed properties
  /**
   * Don't overwrite this from the outside! Also don't overwrite `disabled`.
   * If you do those things, it will cause permissions issues.
   */
  isDisabled: computed('administer', 'disabled', 'is-disabled', function () {
    return !this.administer || this.disabled || this['is-disabled'];
  }),
  disabled: not('form-field'),
  isClickable: not('isDisabled'),
  // end region

  // region actions
  actions: {
    toggleShowModal() {
      if (!this.isDisabled) {
        this.toggleProperty('showModal');
        this['changed-form-field-value'](true);
      }
    },
  },
  // end region
});
