import Service from '@ember/service';
import { Loader } from '@googlemaps/js-api-loader';
import RSVP from 'rsvp';
import ENV from 'partner/config/environment';

export interface Place {
  googlePlaceId: string | null;
  fullAddress: string;
  label?: string;
  secondaryLabel?: string;
  latitude?: number;
  longitude?: number;
}

export default class GoogleMapsService extends Service {
  private _loader = new Loader({ apiKey: ENV.googleMaps.apiKey });

  get loader() {
    return this._loader;
  }

  async searchPlaces(query: string): Promise<Place[]> {
    const { AutocompleteService, PlacesServiceStatus } = await this.loader.importLibrary('places');
    const service = new AutocompleteService();

    return new RSVP.Promise((resolve, reject) => {
      void service.getPlacePredictions({ input: query }, (results, status) => {
        if (![PlacesServiceStatus.OK, PlacesServiceStatus.ZERO_RESULTS].includes(status)) reject(status);

        resolve(
          (results || []).map(result => ({
            googlePlaceId: result.place_id,
            fullAddress: result.description,
            label: result.structured_formatting.main_text,
            secondaryLabel: result.structured_formatting.secondary_text,
          }))
        );
      });
    });
  }

  async getPlaceDetails(place: Place): Promise<Place> {
    const { PlacesService, PlacesServiceStatus } = await this.loader.importLibrary('places');
    const service = new PlacesService(document.createElement('div'));

    return new RSVP.Promise((resolve, reject) => {
      if (!place.googlePlaceId) return resolve(place);

      void service.getDetails({ placeId: place.googlePlaceId }, (result, status) => {
        if (status != PlacesServiceStatus.OK) return reject(status);

        resolve({
          ...place,
          latitude: result?.geometry?.location?.lat(),
          longitude: result?.geometry?.location?.lng(),
          secondaryLabel: result?.formatted_address,
        });
      });
    });
  }
}
