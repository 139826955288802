export default ({ message }) => `
<div data-token="thankYouMessage">
	<div class="ssQuiz font-['Open_Sans']">
	<div class="ssQuestionProgressCalloutFeature">
		<h2 class="ssQuestionProgressCalloutText text-center text-white">
			Result
		</h2>
	</div>
  	<div class="liquid-container">
			<div class="liquid-child" style="top: 0px; left: 0px;">
				<div class="ssOutcome ssMainContent">
  				<div class="ssOutcomeFeature ssTile">
      			<h2 class="ssOutcomeHeader ssTileHeader">Outcome Name</h2>
      			<div class="ssTileContent">
							<img class="ssOutcomeImage" src="https://media.secondstreetapp.com/250194?width=1170&height=1170&quality=60">
          		<p class="ssOutcomeDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eu eleifend lectus, congue blandit quam. Ut pretium, felis sit amet rhoncus finibus.</p>
      			</div>
					</div>
					<div class="ssOutcomeSecondary">
					  <div class="ssTile">
              <div class="extra-chances extra-chances--secondary">
                <div class="extra-chances__column extra-chances__column--secondary-row extra-chances__column--full-width extra-chances__custom-thanks-message">
                  ${message}
                </div>
                     </div>
            </div>
    				<div class="ssOutcomeShare ssTile">
      				<div class="ssTileContent">
        				<h3 class="ssOutcomePrompt">Share your result and see what your friends get!</h3>
      				</div>
      				<ul class="ssShareListCompact ssShareList">
								<li class="ssShareListItem">
    							<a class="ssShareLink ssShareLinkFacebook" href="#" >
      							<i class="ssIcon-facebook"></i>
      							<span class="ssShareLinkText">Share</span>
  								</a>
								</li>
							  <li class="ssShareListItem">
							    <a class="ssShareLink ssShareLinkTwitter" href="#">
							      <i class="ssIcon-twitter"></i>
							      <span class="ssShareLinkText">Tweet</span>
							    </a>
							  </li>
								<li class="ssShareListItem">
							    <a class="ssShareLink ssShareLinkEmail" href="#">
							      <i class="ssIcon-envelope"></i>
							      <span class="ssShareLinkText">Email</span>
							    </a>
							  </li>
							  <li class="ssShareListItem">
							    <a class="ssShareLink ssShareLinkPinterest" href="#">
							      <i class="ssIcon-pinterest"></i>
							      <span class="ssShareLinkText">Pin It</span>
							    </a>
							  </li>
							</ul>
						</div>
    				<div class="ssOutcomeRetake ssTile">
				      <div class="ssTileContent">
				        <h3 class="ssOutcomePrompt">Not the result you expected?</h3>
				      </div>
				      <button type="button" class="ssButton ssButtonTileFooter ssButtonRetake">
				        <i class="ssIcon ssIcon-refresh"></i>
				        Retake Quiz
				      </button>
    				</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
`;
