/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { set } from '@ember/object';
import { or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import FormController from 'partner/controllers/form-controller';

export default FormController.extend({
  current: service(),
  //region Ember Hooks
  notifications: service(),
  queryParams: ['editing-name', 'optin-id'],
  //endregion

  //region Properties
  didSomethingChange: false,
  'editing-name': false,
  'optin-id': null,
  //endregion

  //region Computed Properties
  isAnythingDirty: or('model.form.hasDirtyAttributes', 'didSomethingChange', 'editing-name'),

  get starredFields() {
    return this.model.form.isInherited
      ? this.model.starredFields.filterBy('organizationId', +this.current.organization.id)
      : this.model.starredFields;
  },
  //endregion

  //region Actions
  actions: {
    toggleEditingName() {
      this.model.form.rollbackAttributes();
      this.toggleProperty('editing-name');
    },
    toggleEditingGlobalOptinId(globalOptinId) {
      set(this, 'optin-id', globalOptinId || null);
    },
  },
  //endregion
});
