/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { not } from '@ember/object/computed';

export default Component.extend({
  //region Ember Hooks
  tagName: '',
  //endregion

  //region Computed Properties
  isChosen: computed('item', 'chosenItems.[]', function () {
    if (!this.chosenItems) {
      return false;
    }

    return this.chosenItems.includes(this.item);
  }),

  isEnabled: computed('isChosen', 'item', 'chosenItems.length', 'hasThirdPartyOrInherited', function () {
    if (this.isChosen) {
      return true;
    }

    //disable all items if it is calculating
    if (this.item.isCalculating) {
      return false;
    }
    //disable all items if there are already 2 selected to be merged
    if (this.chosenItems.length === 2) {
      return false;
    }
    //disable all other optins that contain a 3rd party optin if the selected one contains a 3rd party optin
    if (this.hasThirdPartyOrInherited && this.item.hasThirdPartyOptin) {
      return false;
    }
    //disable all other optins that are inherited if the selected one contains an inherited optin
    if (this.hasThirdPartyOrInherited && this.item.optinField.isInherited) {
      return false;
    }

    return true;
  }),
  isDisabled: not('isEnabled'),
  //endregion

  //region Actions
  actions: {
    removeItem() {
      if (this.isDisabled) {
        return;
      }
      this.itemRemoved(this.item);
    },
    chooseItem() {
      if (this.isDisabled) {
        return;
      }
      this.itemAdded(this.item);
    },
  },
  //endregion
});
