import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::IconButton\n  @modifier=\"primary-square\"\n  @icon={{this.icon}}\n  @tooltip={{this.tooltip}}\n  @tooltipDynamic={{true}}\n  @isLoading={{this.fetchCopyValueTask.isRunning}}\n  {{on \"click\" (perform this.copyTask)}}\n  ...attributes\n/>\n", {"contents":"<Ui::IconButton\n  @modifier=\"primary-square\"\n  @icon={{this.icon}}\n  @tooltip={{this.tooltip}}\n  @tooltipDynamic={{true}}\n  @isLoading={{this.fetchCopyValueTask.isRunning}}\n  {{on \"click\" (perform this.copyTask)}}\n  ...attributes\n/>\n","moduleName":"partner/components/ui/copy-button.hbs","parseOptions":{"srcName":"partner/components/ui/copy-button.hbs"}});
/* globals ClipboardItem */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import isFunction from 'lodash/isFunction';
import duration from 'secondstreet-common/utils/duration';

export default class UiCopyButtonComponent extends Component {
  @service('sentry') sentry;

  get tooltip() {
    return this.isJustCopyTaskRunning ? 'Copied!' : this.args.tooltip || 'Click to copy';
  }

  get icon() {
    return this.isJustCopyTaskRunning ? 'check' : this.args.icon || 'content_copy';
  }

  get isJustCopyTaskRunning() {
    return !this.fetchCopyValueTask.isRunning && this.copyTask.isRunning;
  }

  fetchCopyValueTask = task({ drop: true }, async () => {
    if (isFunction(this.args.value)) {
      return this.args.value();
    }

    return this.args.value;
  });

  copyTask = task({ drop: true }, async () => {
    try {
      // Safari requires calling navigator.clipboard.write() immediately after a user gesture,
      // that's why we resolve the value inside a ClipboardItem.
      if ('ClipboardItem' in window) {
        const clipboardItem = new ClipboardItem({
          'text/plain': this.fetchCopyValueTask.perform().then(value => new Blob([value], { type: 'text/plain' })),
        });

        await navigator.clipboard.write([clipboardItem]);
      } else {
        const text = await this.fetchCopyValueTask.perform();
        await navigator.clipboard.writeText(text);
      }

      await timeout(duration(2000));
    } catch (e) {
      this.sentry.captureException(e);
    }
  });
}
