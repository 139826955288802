/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { getOwner } from '@ember/application';
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import AudienceFiltersLoadDataRoute from 'partner/mixins/audience-filters-load-data-route';
import { endpoint } from 'secondstreet-common/utils/url';

/**
 * Audiences Route
 * No URL - Everything under /o/:organization_id/data/audiences
 * @type {Ember.Route}
 * @mixes AudienceFiltersLoadDataRoute
 */
export default Route.extend(AudienceFiltersLoadDataRoute, {
  //region Ember Hooks
  enums: service('enums'),
  session: service('session'),

  model() {
    return this.loadAudienceFilterData();
  },
  //endregion

  //region Actions
  actions: {
    async downloadAudienceReport(audience, fieldIds = null) {
      try {
        set(this, 'controller.downloadInProcess', true);
        const reportTypeId = this.enums.findWhere('REPORT_TYPE', {
          name: 'AudienceContacts',
        });
        const audienceId = get(audience, 'id');
        const data = isEmpty(fieldIds)
          ? `{"reports":[{ "report_type_id": ${reportTypeId}, "audience_id": ${audienceId} }]}`
          : `{"reports":[{ "report_type_id": ${reportTypeId}, "audience_id": ${audienceId}, "custom_field_ids": [${fieldIds}] }]}`;
        const responseData = await this.session.request(endpoint('reports'), {
          type: 'POST',
          data,
          headers: getOwner(this).lookup('adapter:application').headers,
          contentType: 'application/json',
          dataType: 'json',
        });
        window.location.href = responseData.reports[0].file_url;
      } catch (e) {
        throw new Error(`downloadAudienceReport - ${e}`);
      } finally {
        set(this, 'controller.downloadInProcess', false);
      }
    },
  },
  //endregion
});
