import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class MatchupEntriesSearchResultModel extends BaseModel {
  @attr('number') declare entryId: number;
  @attr('number') declare matchupId: number;
  @attr('string') declare entryName: string;
  @attr('string') declare matchupName: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'matchup-entries-search-result': MatchupEntriesSearchResultModel;
  }
}
