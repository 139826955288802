/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* globals jstz */
import { A } from '@ember/array';
import { inject as controller } from '@ember/controller';
import { computed, get, observer, set, setProperties } from '@ember/object';
import { alias, empty, gt, or, readOnly, sort } from '@ember/object/computed';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import ENUMS from 'ember-cli-ss-enums/utils/enum-data';
import flattenDeep from 'lodash/flattenDeep';
import forEach from 'lodash/forEach';
import isNumber from 'lodash/isNumber';
import moment from 'moment';
import FormController from 'partner/controllers/form-controller';
import SetupExtraChances from 'partner/mixins/setup-extra-chances';
import { options, transformToDatesRounds } from 'partner/utils/dates-rounds';
import isAnyPath from 'partner/utils/is-any-path';
import RSVP from 'rsvp';
import { UserError } from 'secondstreet-common/utils/errors';
import { dateInRange } from 'partner/utils/date-in-range';

const settingModels = [
  'voteIntervalTypeIdSetting',
  'votesAllowedNumberSetting',
  'entryIntervalTypeIdSetting',
  'entriesAllowedNumberSetting',
  'allowWriteInsSetting',
  'surveyFrequencySetting',
  'approvedEntrantDisplaySetting',
];

//The models used in this controller to be uses in conjunction with isAnyPath
const relevantModels = [
  ...settingModels.map(settingsModel => `model.${settingsModel}`),
  'organizationPromotion',
  'firstPlace',
  'matchups.[]',
  'matchupPlaces.[]',
  'relevantMatchupCodewords.[]',
  'deletedMatchups.[]',
  'model.extraChancesForm',
  'extraChancesForm',
  'primaryGame',
];

const fieldForType = name =>
  computed('model.fields.@each.id', function () {
    const fieldId = `${this.enums.findWhere('FIELD', { name }, 'id')}`;
    return this.model.fields?.findBy('id', fieldId);
  });
const formFieldForField = fieldPath =>
  computed('entryForm.formPages.firstObject.formFields.[]', fieldPath, function () {
    const formFields = get(this, 'entryForm.formPages.firstObject.formFields');
    return formFields ? formFields.findBy('field', get(this, fieldPath)) : null;
  });

const promosWithOnlyVoting = ['VotingBallot'];
const promosWithOnlySubmissions = ['PhotoGallery'];

/**
 * Setup Dates & Prizes Controller
 * /o/:organization_id/op/:organization_promotion_id/setup/dates-prizes
 * @type {Ember.Controller}
 */
export default FormController.extend(SetupExtraChances, {
  //region Dependencies
  features: service(),
  store: service(),
  enums: service(),
  snackbar: service(),
  deliberateConfirmation: service('deliberate-confirmation'),
  setup: controller('organizations.organization.organization-promotions.organization-promotion.setup'),
  organizationPromotionController: controller(
    'organizations.organization.organization-promotions.organization-promotion'
  ),
  organizationController: controller('organizations.organization'),
  //endregion

  //region Properties
  matchupSorting: computed(() => ['startDate:asc']),
  deletedMatchups: null,
  highlightOverlap: false,
  hasFormFieldsChanged: false,
  isSaving: false,

  roundDigitLength: computed('sortedMatchups.length', function () {
    return this.sortedMatchups.length.toString().length;
  }),
  /**
   * If undefined, a matchup has never been opened.
   * If null, all matchups have been closed.
   * If a {@link Matchup}, that matchup has been opened.
   * @private
   */
  _openedMatchup: undefined,
  entryIntervalTypes: computed(() => [
    ENUMS.UGC_SWEEPS_ENTRY_OPTIONS.findBy('name', 'Once per Round'),
    ENUMS.UGC_SWEEPS_ENTRY_OPTIONS.findBy('name', 'Once per Hour'),
    ENUMS.UGC_SWEEPS_ENTRY_OPTIONS.findBy('name', 'Once per Day'),
    ENUMS.UGC_SWEEPS_ENTRY_OPTIONS.findBy('name', 'Once per Week'),
    ENUMS.UGC_SWEEPS_ENTRY_OPTIONS.findBy('name', 'An unlimited number of times'),
  ]),
  /**
   * Because there are no rounds in UGC Voting, it has slightly different data for the entry options enums.
   */
  ugcVotingEntryIntervalTypes: computed(() => [
    ENUMS.UGC_VOTING_ENTRY_OPTIONS.findBy('name', 'One time only'),
    ENUMS.UGC_VOTING_ENTRY_OPTIONS.findBy('name', 'Once per Hour'),
    ENUMS.UGC_VOTING_ENTRY_OPTIONS.findBy('name', 'Once per Day'),
    ENUMS.UGC_VOTING_ENTRY_OPTIONS.findBy('name', 'Once per Week'),
    ENUMS.UGC_VOTING_ENTRY_OPTIONS.findBy('name', 'An unlimited number of times'),
  ]),
  selectionIntervalTypes: computed(() => [
    ENUMS.UGC_VOTING_ENTRY_OPTIONS.findBy('name', 'One time only'),
    ENUMS.UGC_VOTING_ENTRY_OPTIONS.findBy('name', 'Once per Hour'),
    ENUMS.UGC_VOTING_ENTRY_OPTIONS.findBy('name', 'Once per Day'),
    ENUMS.UGC_VOTING_ENTRY_OPTIONS.findBy('name', 'Once per Week'),
  ]),
  //endregion

  init() {
    this._super(...arguments);

    set(this, 'deletedMatchups', this.deletedMatchups || []);
  },

  //region Methods
  /**
   * @throws {UserError}
   */
  validate() {
    const ensure = (property, value, message, callback) => {
      const valid = this[property] === value;
      if (callback) {
        callback(valid);
      }
      if (!valid) {
        this.snackbar.show(message, 'Dismiss', 5000, 'error');
        throw new UserError(message);
      }
    };
    if (!this.canOverlap) {
      ensure('isOverlap', false, 'Rounds must not overlap.', valid => set(this, 'highlightOverlap', !valid));
    }
  },
  /**
   * Sorts all matchups based on startDate, and then updates round numbers and display orders as necessary.
   * This is done to correct issues where rounds are deleted and/or re-ordered, which messes up the logic
   * in the matchup naming.
   */
  adjustMatchupData(matchups) {
    matchups
      .filterBy('isDeleted', false)
      .sortBy('startDate')
      .forEach((matchup, index) => {
        setProperties(matchup, {
          name: this.organizationPromotion.promotion.isUGCSweepstakes ? `gallery` : `Round ${index + 1}`,
          displayOrder: index + 1,
          iteration: index + 1,
        });
      });
  },

  get sweepstakePromotions() {
    return (
      this.organizationPromotion.promotion.isQuiz ||
      this.organizationPromotion.promotion.isPoll ||
      this.organizationPromotion.promotion.isPhotoGallery
    );
  },

  get startDate() {
    return this.organizationPromotion.promotion.isPhotoGallery
      ? this.organizationPromotion.submissionStartDate
      : this.sortedMatchups.firstObject.startDate;
  },

  get endDate() {
    return this.organizationPromotion.promotion.isPhotoGallery
      ? this.organizationPromotion.submissionEndDate
      : this.sortedMatchups.firstObject.endDate;
  },

  get isSweepstakesStartDateInRange() {
    return dateInRange(this.sweepstakes.startDate, this.startDate, this.endDate);
  },

  get isSweepstakesEndDateInRange() {
    return dateInRange(this.sweepstakes.endDate, this.startDate, this.endDate);
  },

  /**
   * Applies a default label text to the form field if it's missing so uneditable form fields can also pass API validation.
   * @param form
   */
  applyFormFieldDefaultLabelText(form) {
    form.formPages.forEach(formPage => {
      formPage.formFields.forEach(formField => {
        if (!formField.labelText && formField.field.labelText) {
          set(formField, 'labelText', formField.field.labelText);
        }
      });
    });
  },
  async save() {
    this.validate();

    const matchups = [];

    matchups.addObjects(this.matchups);
    // TODO: Probably not needed
    matchups.addObjects(this.deletedMatchups);

    /*
      Backend needs at least 1 matchup to be always present, so before deleting any matchups we
      confirm that at least 1 entry is present.
      If not, then we first save added matchups and then save deleted matchups.
    */
    const atLeastOneMatchupIsAlreadySaved = this.matchups.find(({ isDeleted, isNew }) => !isNew && !isDeleted);

    if (!this.organizationPromotion.matchupsAsCategories && !this.organizationPromotion.promotion.isEventSignup) {
      // Take all the rounds and correct their data
      this.adjustMatchupData(matchups);
    }

    const matchupPromises = RSVP.all(
      (atLeastOneMatchupIsAlreadySaved ? matchups : matchups.rejectBy('isDeleted'))
        .filterBy('isDirty', true)
        .map(matchup => {
          if (!get(matchup, 'errors.isEmpty')) {
            get(matchup, 'errors').clear();
          }

          if (!matchup.isDeleted) {
            const { promotion } = this.organizationPromotion;

            if (promotion.isPhotoVotingStandard || promotion.isVideoVotingStandard || promotion.isPhotoGallery) {
              // The following values come from settings and can get out of sync
              // since saving of matchups and setting occurs on dates-prizes
              setProperties(matchup, {
                voteIntervalTypeId: null,
                entryIntervalTypeId: null,
                votesAllowedNumber: null,
                entriesAllowedNumber: null,
                entriesSortTypeId: null,
              });
            }
          }

          return matchup.saveOrDestroy();
        })
    );

    const settingSaves = RSVP.all(
      settingModels
        .map(path => {
          if (this.model[path]?.hasDirtyAttributes) {
            return this.saveSetting(this.model[path], path);
          }
          return null;
        })
        .compact()
    );

    const extraChanceRule = this.model.extraChancesForm;
    const extraChanceFormFieldSaves =
      (this.isExtraChanceRuleDirty || this.areExtraChanceFieldsDirty) && !this.shouldUseAutoSave
        ? extraChanceRule?.save()
        : RSVP.resolve(true);

    if (this.shouldUseAutoSave) {
      await extraChanceRule?.save();
    }
    // save the title and caption fields
    /*
     *
     * This is a hacky way for saving the entry form fields
     * we are assuming it is an Ember Data bug
     * the solution is that we keep track if something is
     * changed using the property hasFormFieldsChanged
     * that gets set to true when a formField is deleted, created,
     * or the modal is open
     *
     */
    let entryFormSave = RSVP.resolve(true);

    if (this.hasFormFieldsChanged && this.entryForm) {
      // Make sure each form field has a label text value first to avoid validation errors:
      this.applyFormFieldDefaultLabelText(this.entryForm);

      entryFormSave = this.entryForm.save().catch(e => {
        console.error(e);
      });
    }
    // end saving title and caption fields

    return RSVP.all([
      settingSaves,
      extraChanceFormFieldSaves,
      entryFormSave,
      matchupPromises
        .then(() =>
          atLeastOneMatchupIsAlreadySaved
            ? RSVP.resolve(true)
            : RSVP.all(this.deletedMatchups.map(matchup => matchup.save()))
        )
        .then(async () => {
          const matchupPlaces = this.matchupPlaces?.toArray();

          if (!matchupPlaces) return;

          for (let i = 0; i < matchupPlaces.length; i++) {
            const matchupPlace = matchupPlaces[i];

            if (matchupPlace) {
              const endRank = i
                ? matchupPlaces.objectAt(i - 1).endRank + parseInt(matchupPlace.endRank, 10)
                : parseInt(matchupPlace.endRank, 10);

              if (!isNumber(endRank) || endRank < 1) {
                matchupPlace.endRank = 1;
              }

              if (matchupPlace.isDirty) {
                await this.saveMatchupPlace(matchupPlace);
              }
            }
          }

          this.promotionMatchupHashtags.forEach(hashtag => {
            if (hashtag.isDirty) {
              hashtag.saveOrDestroy();
            }
          });
        })
        .then(() => {
          if (
            this.sweepstakes.isEnabled &&
            (this.organizationPromotion.promotion.isUGCVoting || this.organizationPromotion.promotion.isBallot)
          ) {
            set(
              this,
              'sweepstakes.startDate',
              this.organizationPromotion.promotion.isVotingBallot
                ? this.organizationPromotion.selectionStartDate
                : this.organizationPromotion.submissionStartDate
            );
            set(this, 'sweepstakes.endDate', this.organizationPromotion.selectionEndDate);
            return this.sweepstakes.save();
          }
          return RSVP.Promise.resolve(); // Nothing to save
        })
        .then(() =>
          // Save the dirty codewords
          RSVP.all(
            this.relevantMatchupCodewords.map(matchupCodeword =>
              matchupCodeword.matchup.isValid && matchupCodeword.isDirty
                ? matchupCodeword.saveOrDestroy()
                : matchupCodeword
            )
          )
        )
        .then(
          () => (this.primaryGame.hasDirtyAttributes ? this.primaryGame.save() : RSVP.Promise.resolve()) // Nothing to save
        )
        .then(() => {
          if (this.organizationPromotion.hasDirtyAttributes) {
            this.syncDates(this.organizationPromotion);
            return this.organizationPromotion.save();
          }
        })
        .then(() => this.organizationPromotionController.model.organizationPromotion.reload())
        .then(() => {
          if (!this.sweepstakePromotions) return;
          if (isEmpty(this.sweepstakes.startDate)) return;
          if (isEmpty(this.sweepstakes.endDate)) return;
          if (this.isSweepstakesStartDateInRange && this.isSweepstakesEndDateInRange) return;

          if (!this.isSweepstakesStartDateInRange) {
            set(this, 'sweepstakes.startDate', this.organizationPromotion.submissionStartDate);
          }

          if (!this.isSweepstakesEndDateInRange) {
            set(
              this,
              'sweepstakes.endDate',
              this.organizationPromotion.submissionEndDate || this.organizationPromotion.submissionStartDate
            );
          }
          return this.sweepstakes.save();
        })
        .then(() => set(this, 'hasFormFieldsChanged', false)),
    ]);
  },
  syncDates(organizationPromotion) {
    const getDate = type => organizationPromotion[`${type}Date`];
    //set selection dates if promotion only has a submission phase
    if (promosWithOnlySubmissions.includes(organizationPromotion.promotion.promotionSubType)) {
      setProperties(organizationPromotion, {
        selectionStartDate: getDate('submissionStart'),
        selectionEndDate: getDate('submissionEnd'),
      });
    }
    //set submission dates if promotion only has a selection (i.e. voting) phase
    if (promosWithOnlyVoting.includes(organizationPromotion.promotion.promotionSubType)) {
      setProperties(organizationPromotion, {
        submissionStartDate: getDate('selectionStart'),
        submissionEndDate: getDate('selectionEnd'),
      });
    }
  },
  saveSetting(setting, path) {
    if (setting.isInherited) {
      const settingToSave = setting.createCopy(['id', 'isInherited']);
      setting.rollbackAttributes();
      this.setNewSetting(settingToSave, path);
      return settingToSave.save();
    }
    return setting.save();
  },
  setNewSetting(setting, path) {
    set(this.model, path, setting);
  },

  async savePrimaryGame() {
    //region HAX
    // When the embedded matchups are returned in the games response
    // the date objects of the matchups cause ember to believe
    // the matchup is dirty when in fact the date is the same.
    // rollbackAttributes fixes the matchup without changing the date.
    try {
      await this.primaryGame.save();
      this.matchups.forEach(matchup => matchup.rollbackAttributes());
    } finally {
      set(this, 'isSaving', false);
    }
  },

  async saveMatchupPlace(matchupPlace) {
    if (isPresent(get(matchupPlace, 'errors'))) {
      get(matchupPlace, 'errors').clear();
    }

    await matchupPlace.saveOrDestroy();
  },
  /**
   * Create a {@link partner/models/codeword}
   * @param {String} value - The name of the {@link partner/models/codeword} to be created
   * @returns {partner/models/codeword} - The newly created {@link partner/models/codeword}
   */
  createMatchupCodeword(matchup, value) {
    const newCodewordRecord = this.store.createRecord('matchupCodeword', {
      matchup,
      value,
    });
    set(newCodewordRecord, 'isJustCreated', true); /** For use in {@link partner/components/focus-just-created} */
    return matchup.matchupCodewords.pushObject(newCodewordRecord);
  },
  /**
   * Rollback the dirty stuff when leaving the dates prizes page
   * @private
   */
  _rollbackUnsaved() {
    const { matchups, firstPlace: matchupPlace, organizationPromotion, promotionMatchupHashtags } = this;
    const { games } = this.model;
    if (this.hasFormFieldsChanged) {
      const entryFormFormPages = get(this, 'entryForm.formPages') || [];
      const extraChancesFormFormPages = get(this, 'extraChancesForm.formPages') || [];
      const deletedExtraChancesFormFields = get(this, 'extraChancesForm.deletedRecords') || [];
      const formFields = entryFormFormPages
        .concat(extraChancesFormFormPages)
        .reduce((previous, item) => previous.concat(get(item, 'formFields') || []))
        .toArray()
        .concat(deletedExtraChancesFormFields);
      if (formFields) {
        formFields
          .filterBy('hasDirtyAttributes')
          .forEach(formField => (formField.isNew ? formField.deleteRecord() : formField.rollbackAttributes()));
      }
      set(this, 'hasFormFieldsChanged', false);
    }
    promotionMatchupHashtags.filterBy('hasDirtyAttributes').forEach(matchupHashtag => {
      matchupHashtag.isNew ? matchupHashtag.deleteRecord() : matchupHashtag.rollbackAttributes();
      if (matchupHashtag.isDeleted) {
        matchupHashtag.rollbackAttributes();
      }
    });
    matchups.filterBy('hasDirtyAttributes').forEach(matchup => {
      if (isPresent(get(matchup, 'matchupCodewords').toArray())) {
        get(matchup, 'matchupCodewords')
          .toArray()
          .filterBy('hasDirtyAttributes', true)
          .forEach(dirtyCodeword =>
            dirtyCodeword.isNew ? dirtyCodeword.deleteRecord() : dirtyCodeword.rollbackAttributes()
          );
      }
      return matchup.isNew ? matchup.deleteRecord() : matchup.rollbackAttributes();
    });

    settingModels.forEach(path => {
      if (this.model[path]?.hasDirtyAttributes) {
        this.model[path].rollbackAttributes();
      }
    });

    if (matchupPlace && matchupPlace.hasDirtyAttributes) {
      matchupPlace.rollbackAttributes();
    }
    if (organizationPromotion.hasDirtyAttributes) {
      organizationPromotion.rollbackAttributes();
    }
    if (games?.firstObject.hasDirtyAttributes) {
      games.firstObject.rollbackAttributes();
    }
  },
  /**
   * In a voting ballot, enabled/disabling write-ins will trigger whether
   * the Ballot checklist step should be checked/unchecked
   * @private
   */
  _checkUncheckBallotStep() {
    if (this.organizationPromotion.promotion.isVotingBallot) {
      if (this.model.allowWriteInsSetting?.value) {
        this.send(
          'checkChecklistStep',
          'organizations.organization.organization-promotions.organization-promotion.setup.ballot'
        );
      } else {
        this.send('verifyAndToggleBallotChecklistStep', {
          route: 'organizations.organization.organization-promotions.organization-promotion.setup.ballot',
          viaWriteIns: true,
        });
      }
    }
  },

  getPromptMessage() {
    if (!this.isSweepstakesStartDateInRange && !this.isSweepstakesEndDateInRange) {
      return "The sweepstakes start and end dates is out of promotion date range and will automatically be updated to match the promotion's start and end date.";
    } else if (!this.isSweepstakesStartDateInRange) {
      return "The sweepstakes start date is out of promotion date range and will automatically be updated to match the promotion's start date.";
    } else if (!this.isSweepstakesEndDateInRange) {
      return "The sweepstakes end date is out of promotion date range and will automatically be updated to match the promotion's end date.";
    }
  },

  async showDeliberateConfirmation() {
    if (
      this.sweepstakes.isEnabled &&
      this.sweepstakePromotions &&
      (!this.isSweepstakesStartDateInRange || !this.isSweepstakesEndDateInRange)
    ) {
      return this.deliberateConfirmation.show({
        promptText: this.getPromptMessage(),
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, apply changes',
      });
    }

    return true;
  },
  //endregion Methods

  //region Computed Properties
  entryForm: readOnly('model.entryForm'),
  sweepstakes: readOnly('model.sweepstakes'),
  titleField: fieldForType('PhotoTitle'),
  captionField: fieldForType('PhotoCaption'),
  mediaReleaseField: fieldForType('MediaReleaseConsent'),
  numEligibleVotes: readOnly('setup.organizationPromotion.numEligibleVotes'),
  matchupPlacingCriteria: readOnly('model.matchupPlacingCriteria'),
  matchupPlaces: readOnly('model.matchupPlaces'),
  firstPlace: alias('model.firstPlace'),
  matchupsNotCreated: empty('matchups'),
  /**
   * The public interface to the `_openedMatchup` property.
   * @returns {Matchup?}
   */
  openMatchup: computed('sortedMatchups.{firstObject,length}', 'matchups.@each.isCurrentMatchup', '_openedMatchup', {
    get() {
      if (this.sortedMatchups.length === 1) {
        return this.sortedMatchups.firstObject;
      } else if (this._openedMatchup || this._openedMatchup === undefined) {
        return this._openedMatchup;
      }
      return this.matchups.findBy('isCurrentMatchup', true);
    },
    set(key, value) {
      set(this, '_openedMatchup', value);
      return value;
    },
  }),
  rounds: computed('representativeMatchups.@each.{startDate,endDate}', function () {
    return transformToDatesRounds(this.representativeMatchups);
  }),
  matchups: computed(
    'model.matchups',
    'setup.model.matchups',
    'organizationPromotion.promotion.isVotingBracket',
    function () {
      return this.organizationPromotion.promotion.isVotingBracket ? this.setup.model.matchups : this.model.matchups;
    }
  ),
  durationOptions: computed(() => options(['1 Hour', '24 Hours', '2 Days', '3 Days', '1 Week', 'Set Manually'])),
  lastRoundOngoing: computed('representativeMatchups.lastObject.{startDate,endDate}', function () {
    const now = new Date();
    return (
      this.representativeMatchups?.lastObject?.startDate < now && now < this.representativeMatchups?.lastObject?.endDate
    );
  }),
  representativeAds: computed('primaryGame.iterationAds.length', {
    get() {
      return this.primaryGame.iterationAds.sortBy('iteration');
    },
  }),
  representativeMatchups: computed('matchups.length', {
    get() {
      return this.matchups.filterBy('displayOrder', 1).sortBy('iteration');
    },
  }),
  registrantLimitEnabled: computed('primaryGame.entriesPerMatchupMax', {
    get() {
      return this.primaryGame.entriesPerMatchupMax > 0;
    },
    set(key, value) {
      if (value) {
        set(this, 'primaryGame.entriesPerMatchupMax', null);
        next(() => {
          document.querySelector('.entries-per-matchup-max').focus();
        });
      } else {
        set(this, 'primaryGame.entriesPerMatchupMax', -1);
      }
      return value;
    },
  }),
  selectedEntryIntervalOption: computed(
    'organizationPromotionType',
    'sortedMatchups.@each.{entryIntervalTypeId,entriesAllowedNumber}',
    function () {
      if (this.organizationPromotionType === 'UGCVoting') {
        // Photo Voting only has one round, so we just grab the first round's data
        const round = this.sortedMatchups.firstObject;
        return this.enums.findWhere(
          'UGC_VOTING_ENTRY_OPTIONS',
          {
            entryIntervalTypeId: round.entryIntervalTypeId,
            entriesAllowedNumber: round.entriesAllowedNumber,
          },
          {}
        );
      }
      // Photo Sweepstakes handles this on a per-round basis in the dates-prizes-ugc-sweepstakes component
      return null;
    }
  ),
  emptyCodeword: empty('matchup.newMatchupCodewordValue'),
  titleFormField: formFieldForField('titleField'),
  captionFormField: formFieldForField('captionField'),
  mediaReleaseFormField: formFieldForField('mediaReleaseField'),
  organizationPromotion: alias('organizationPromotionController.model.organizationPromotion'),
  organizationPromotionType: alias('organizationPromotion.promotion.promotionTypeName'),
  sortedMatchups: sort('matchups', 'matchupSorting'),
  /**
   * Calculate if any of the models for this object are dirty.
   */
  promotionMatchupHashtags: computed('matchups.@each.matchupHashtags', function () {
    return flattenDeep(this.matchups.map(matchup => matchup.matchupHashtags.toArray().map(hashtag => hashtag)));
  }),
  areAnyMatchupHashtagsDirty: computed('promotionMatchupHashtags.@each.isDirty', function () {
    return this.promotionMatchupHashtags.isAny('isDirty');
  }),
  areAnyModelsDirty: isAnyPath('isDirty', relevantModels.slice().addObject('setup')),
  isAnythingDirty: or(
    'areAnyModelsDirty',
    'hasFormFieldsChanged',
    'isExtraChanceRuleDirty',
    'areExtraChanceFieldsDirty',
    'areAnyMatchupHashtagsDirty'
  ),
  /**
   * Calculate if the models that would prevent leaving the page are dirty
   */
  areAnyRelevantModelsDirty: isAnyPath('hasDirtyAttributes', relevantModels),
  isAnythingRelevantDirty: or('areAnyRelevantModelsDirty', 'hasFormFieldsChanged', 'areAnyMatchupHashtagsDirty'),
  /**
   * Calculate if anything is being saved.
   */
  isAnythingSaving: isAnyPath('isSaving', relevantModels.slice().addObject('setup')),
  /**
   * This calculation is used for styling the setup step checklist to see if any matchups changed.
   */
  areRoundsDirty: isAnyPath('hasDirtyAttributes', ['matchups.[]', 'relevantMatchupCodewords.[]']),
  roundStatus: computed('matchups.@each.status', function () {
    const { matchups } = this;
    return matchups && this.matchups.isAny('isComplete') ? 'bestPractice' : 'incomplete';
  }),
  moreThanOneMatchup: gt('matchups.length', 1),
  timeZone: computed('', () => jstz.determine().name()),
  /**
   * Used to track when new codewords are created
   */
  isCodewordRequired: readOnly('model.codewordRequiredSetting.value'),
  isVotingBracket: readOnly('organizationPromotion.promotion.isVotingBracket'),
  isCodewordSweepstakes: readOnly('organizationPromotion.promotion.isSweepstakesCodeword'),
  isVideoSweeps: readOnly('organizationPromotion.promotion.isUGCSweepstakesVideo'),
  isPhotoSweeps: readOnly('organizationPromotion.promotion.isUGCSweepstakesStandard'),
  uploadedMediaType: computed('isVideoSweeps', 'isPhotoSweeps', function () {
    if (this.isVideoSweeps) {
      return 'video';
    } else if (this.isPhotoSweeps) {
      return 'photo';
    }
    return null;
  }),
  /**
   * A list of all codewords, both active and deleted, since the last time the page was saved.
   */
  relevantMatchupCodewords: A([]),
  canOverlap: computed('organizationPromotion.promotion.promotionType', function () {
    return ['UGCVoting', 'UGCGallery', 'Ballot', 'VotingBracket'].includes(
      this.organizationPromotion.promotion.promotionType
    );
  }),
  isOverlap: computed('matchups.@each.isOverlap', function () {
    return this.matchups.any(x => x.isOverlap);
  }),
  firstMatchup: alias('matchups.firstObject'),
  primaryGame: computed('model.games.@each.isPrimary', function () {
    return this.model.games.findBy('isPrimary');
  }),
  //endregion

  //region Observers
  /**
   * Validate that none of the rounds overlap each other.
   * If any do this sets a controller property indicating such.
   * Also sets property moreThanOneRound.
   */
  matchupsChanged: observer(
    'matchups.@each.startDate',
    'matchups.@each.endDate',
    'matchups.@each.isDeleted',
    'canOverlap',
    function () {
      if (this.canOverlap) {
        return;
      }

      const { matchups } = this;
      const innerMatchups = matchups;
      const overlapping = [];
      const matchupCount = matchups.length;

      matchups.forEach(matchup => {
        const { isDeleted } = matchup;
        innerMatchups.forEach(compareMatchup => {
          // ignore deleted matchups from overlap comparison
          // Only check for overlapping matchups when editing a multi-round promotion. Otherwise, allow the API to validate instead.
          if (!isDeleted && !compareMatchup.isDeleted && matchupCount > 1 && matchup.overlaps(compareMatchup)) {
            overlapping.push(matchup);
            overlapping.push(compareMatchup);
          } else if (!compareMatchup.isDestroying && !compareMatchup.isDestroyed) {
            set(compareMatchup, 'isOverlap', false);
          }
        });

        forEach(overlapping, m => {
          if (!m.isDestroying && !m.isDestroyed) {
            set(m, 'isOverlap', true);
          }
        });
      });
      set(this, 'highlightOverlap', false);
    }
  ),
  //endregion

  //region Actions
  actions: {
    changeDate(date, property) {
      set(this, property, date);
    },
    async saveMatchupRounds(rounds) {
      const matchupsWithChangedDate = this.representativeMatchups.filter(
        (matchup, index) =>
          !(
            moment(rounds[index].startDate).isSame(matchup.selectionStartDate) &&
            moment(rounds[index].endDate).isSame(matchup.selectionEndDate)
          )
      );

      if (matchupsWithChangedDate.length === 0) {
        return;
      }
      set(this, 'isSaving', true);
      matchupsWithChangedDate.forEach(matchupRepresentative => {
        const { iteration } = matchupRepresentative;
        const matchupsToUpdate = this.matchups.filterBy('iteration', iteration);
        matchupsToUpdate.forEach(matchup => {
          setProperties(matchup, {
            startDate: rounds[iteration - 1].startDate,
            selectionStartDate: rounds[iteration - 1].startDate,
            endDate: rounds[iteration - 1].endDate,
            selectionEndDate: rounds[iteration - 1].endDate,
          });
        });
      });

      setProperties(this.primaryGame, {
        startDate: this.startDate,
        endDate: this.endDate,
      });

      await this.savePrimaryGame();
      if (this.sweepstakes.isEnabled) {
        set(this, 'sweepstakes.startDate', this.primaryGame.startDate);
        set(this, 'sweepstakes.endDate', this.primaryGame.endDate);
        this.sweepstakes.save();
      }
    },
    toggleMatchup(matchup) {
      if (matchup === this.openMatchup) {
        set(this, 'openMatchup', null);
      } else {
        set(this, 'openMatchup', matchup);
      }
    },
    deleteFormField(formField) {
      if (formField) {
        formField.deleteRecord();
        set(this, 'hasFormFieldsChanged', true);
        this.entryForm.formPages.firstObject.formFields.removeObject(formField);
      }
    },
    createFormField(formField, displayOrder, defaultLabelText, field) {
      if (!formField) {
        const newFormField = this.store.createRecord('form-field');
        setProperties(newFormField, {
          displayOrder,
          isRequired: true,
          labelText: defaultLabelText,
          isRemovable: true,
          field,
          formPage: this.entryForm.formPages.firstObject,
        });
        set(this, 'hasFormFieldsChanged', true);
        this.entryForm.formPages.firstObject.formFields.addObject(newFormField);
      }
    },
    /**
     * Creates a {@link partner/models/codeword} when the newCodewordValue property changes,
     * then resets the newCodewordName property
     * @type {Ember.Observable}
     */
    createCodeword() {
      this.matchups.forEach(matchup => {
        const value = matchup.newMatchupCodewordValue;
        if (isPresent(value)) {
          this.relevantMatchupCodewords.pushObject(this.createMatchupCodeword(matchup, value));
          next(() => set(matchup, 'newMatchupCodewordValue', ''));
        }
      });
    },
    async save() {
      if (!(await this.showDeliberateConfirmation())) return;

      await this.save();
      this.send('checkChecklistStep');
      this._checkUncheckBallotStep();
    },
    async saveAndContinue() {
      if (!(await this.showDeliberateConfirmation())) return;

      await this.save();
      this.send('checkChecklistStep');
      this._checkUncheckBallotStep();
      this.send('continue');
    },
    /**
     * Creates a {Matchup} and then adds it to the current list.
     */
    addRound(name) {
      let iteration,
        startDate,
        endDate,
        selectionStartDate,
        selectionEndDate,
        entriesAllowedNumber,
        entryIntervalTypeId,
        votesAllowedNumber,
        voteIntervalTypeId;
      const { sortedMatchups } = this;
      const firstMatchup = get(sortedMatchups, 'firstObject');
      const lastMatchup = get(sortedMatchups, 'lastObject');
      if (this.organizationPromotion.matchupsAsCategories) {
        // Category matchups within the same promotion all share the same start and end date
        const promo = this.organizationPromotion;
        startDate = new Date();
        startDate.setTime(promo.submissionStartDate.getTime());
        endDate = new Date();
        endDate.setTime(promo.submissionEndDate.getTime());
        selectionStartDate = new Date();
        selectionStartDate.setTime(promo.selectionStartDate.getTime());
        selectionEndDate = new Date();
        selectionEndDate.setTime(promo.selectionEndDate.getTime());
        entriesAllowedNumber = get(firstMatchup, 'entriesAllowedNumber');
        entryIntervalTypeId = get(firstMatchup, 'entryIntervalTypeId');
        // "Iteration" is not needed for categories so the back-end asks that we just set it as 1
        iteration = 1;
      } else if (isPresent(lastMatchup) && isPresent(lastMatchup.endDate)) {
        startDate = new Date();
        startDate.setTime(lastMatchup.endDate.getTime() + 1 * 60 * 1000);
        endDate = new Date();
        endDate.setTime(startDate.getTime() + 60 * 60 * 24 * 1000);
      }
      if (this.organizationPromotion.promotion.isUGCVoting) {
        votesAllowedNumber = get(firstMatchup, 'votesAllowedNumber');
        voteIntervalTypeId = get(firstMatchup, 'voteIntervalTypeId');
      }
      const displayOrder = sortedMatchups.length + 1;
      const newMatchup = this.store.createRecord('matchup', {
        name: name === undefined ? `gallery` : name,
        displayOrder,
        gameGroupId: lastMatchup.gameGroupId,
        matchupType: lastMatchup.matchupType,
        iteration: iteration || displayOrder,
        startDate,
        endDate,
        selectionStartDate,
        selectionEndDate,
        entriesAllowedNumber: entriesAllowedNumber || 1,
        entryIntervalTypeId:
          entryIntervalTypeId ||
          this.enums.findWhere('ENTRY_INTERVAL_TYPE', {
            name: 'Round',
          }),
        votesAllowedNumber: votesAllowedNumber || null,
        voteIntervalTypeId: voteIntervalTypeId || null,
      });
      set(this, 'openMatchup', newMatchup);
      sortedMatchups.forEach(matchup => {
        set(matchup, 'isCurrentMatchup', false);
      });
      this.matchups.addObject(newMatchup);
      return newMatchup;
    },
    /**
     * Marks a round as isDeleted which will change the ui style for the matchup.
     * If the to be deleted matchup isNew and then it is removed from the list of matchups instantly.
     * @param {Matchup} matchup that is to be removed
     */
    removeRound(matchup) {
      const { matchups } = this;

      if (matchups && matchups.length > 1) {
        // Delete associated hashtags
        matchup.matchupHashtags.map(hashtag => hashtag.deleteRecord());
        if (isPresent(matchup.matchupCodewords.toArray())) {
          this.relevantMatchupCodewords.removeObjects(matchup.matchupCodewords.toArray());
        }
        matchup.deleteRecord();

        if (matchup.isNew) {
          matchups.removeObject(matchup);
        } else {
          // deletes still need to be saved, so therefore can be rolled back
          this.deletedMatchups.addObject(matchup);
        }

        set(this, 'openMatchup', this.sortedMatchups.lastObject);
      }
    },
    /**
     * Delete a MatchupCodeword.
     */
    removeMatchupCodeword(matchupCodeword) {
      if (get(matchupCodeword, 'isNew')) {
        this.relevantMatchupCodewords.removeObject(matchupCodeword);
        matchupCodeword.deleteRecord();
      } else if (matchupCodeword.matchup.isValid) {
        //if you remove a code word from a matchup this not valid ember will throw an exception
        matchupCodeword.deleteRecord();
      }
    },
    rollback() {
      this._rollbackUnsaved();
    },
    rollbackMatchup(matchup) {
      matchup.rollbackAttributes();
    },
    frequencyChanged(matchup, entryIntervalType) {
      set(matchup, 'entryIntervalTypeId', entryIntervalType.entryIntervalTypeId);
      set(matchup, 'entriesAllowedNumber', entryIntervalType.entriesAllowedNumber);
    },
    votingFrequencyChanged(entryIntervalType) {
      const { model } = this;
      set(model, 'voteIntervalTypeIdSetting.value', entryIntervalType.entryIntervalTypeId);
      set(model, 'votesAllowedNumberSetting.value', entryIntervalType.entriesAllowedNumber);

      if (this.organizationPromotion.promotion.isVotingBallot) {
        // in voting ballots, entries and votes are functionally the same thing
        this.send('entryFrequencyChanged', entryIntervalType);
      }
    },
    entryFrequencyChanged(entryIntervalType) {
      const { model } = this;
      set(model, 'entryIntervalTypeIdSetting.value', entryIntervalType.entryIntervalTypeId);
      set(model, 'entriesAllowedNumberSetting.value', entryIntervalType.entriesAllowedNumber);
    },
    changeWriteInsEnabled(value) {
      set(this, 'model.allowWriteInsSetting.value', value);
    },
    toggleEditingGlobalOptinId(globalOptinId) {
      set(this, 'optin-id', globalOptinId || null);
    },
    async saveSettingValue(setting) {
      set(this, 'isSaving', true);
      await this.saveSetting(this.model[setting], setting);
      set(this, 'isSaving', false);
    },
    replaceEnabledSetting(newSetting) {
      this.send('replaceRegistrationEnabledSetting', newSetting);
    },
  },
  //endregion
});
