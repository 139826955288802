/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed, get } from '@ember/object';
import { gt, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isBlank, isPresent } from '@ember/utils';
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import moment from 'moment';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

export default BaseModel.extend({
  //region Services
  enums: service(),
  //endregion

  //region Attributes
  salesPackageId: attr('number'),
  startDate: attr('date'),
  cancelDate: attr('date'),
  cancellationNotificationDate: attr('date'),
  cancellationReasonNotes: attr('string'),
  cancellationReasonTypeId: attr('number'),
  recurringFee: attr('number'),
  setupFee: attr('number'),
  billingFrequencyTypeId: attr('number'),
  messagingUsageBillingTypeId: attr('number', { defaultValue: 0 }),
  billingStartDate: attr('date'),
  billingEndDate: attr('date'),
  salesforceId: attr('string'),
  salesPersonId: attr('number'),
  customBillingPlanName: attr('string'),
  numberOfIncludedEmails: attr('number'),
  emailOverageRate: attr('number', { defaultValue: 0 }),
  //endregion

  //region Relationships
  /**
   * Will be populated when upgrading or downgrading
   */
  previousOrganizationSalesPackage: belongsTo('organization-sales-package', { async: false }),
  organization: belongsTo('organization', { async: false, inverse: 'organizationSalesPackages' }),
  invoiceRecipient: belongsTo('invoice-recipient'),
  //endregion

  //region Computed Properties
  billingFrequency: computed('billingFrequencyTypeId', function () {
    return this.enums.findWhere('BILLING_FREQUENCY_TYPE', { id: this.billingFrequencyTypeId }, {});
  }),
  cancellationReasonTypeName: enums.computed(
    'name',
    'cancellationReasonTypeId',
    'cancellationReasonTypeId',
    'CANCELLATION_REASON_TYPE'
  ),

  messageUsageBillingType: computed('messagingUsageBillingTypeId', function () {
    return this.enums.findWhere('BILLING_TYPE', { id: this.messagingUsageBillingTypeId }, {});
  }),

  salesPackage: computed('salesPackageId', function () {
    return this.enums.findWhere('SALES_PACKAGES', { id: this.salesPackageId }, {});
  }),

  salesPackageName: readOnly('salesPackage.name'),

  salesPerson: computed('salesPersonId', function () {
    return this.enums.findWhere('SALES_PEOPLE', { id: this.salesPersonId }, {});
  }),

  salesPersonName: readOnly('salesPerson.name'),

  annualCost: computed('billingFrequency', 'recurringFee', function () {
    const { billingFrequency } = this;
    return isPresent(billingFrequency) ? get(billingFrequency, 'frequency') * this.recurringFee : 0;
  }),

  isActive: computed('startDate', 'cancelDate', function () {
    const { startDate } = this;
    const { cancelDate } = this;
    return isPresent(startDate) && (isBlank(cancelDate) || moment().isBefore(moment(cancelDate)));
  }),

  isRecurring: gt('recurring', 0),
  //endregion
});
