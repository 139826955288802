import { ParamsType } from 'partner/services/preview';
import { header, subHeader, photo, card } from './common';

export default (params?: ParamsType) => [
  header({ promotionColor: params?.promotionColor, promotionTextColor: params?.promotionTextColor }),
  {
    id: 'box-3',
    uniqueTypeNumber: '3',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'heading-2',
        uniqueTypeNumber: '2',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: 'Enter until m/dd @ h:mm pm',
          additionalCss: '.arc-promotion-promotion-0 .arc-heading-heading-2 {\n     \n  }\n  \n  ',
        },
        styles: {
          font: {
            color: '#6b7786',
            weight: 400,
            family: 'Open Sans',
            size: '14',
            height: '20',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      {
        id: 'box-4',
        uniqueTypeNumber: '4',
        deletable: true,
        type: 'promotion-box',
        blocks: [
          {
            id: 'heading-3',
            uniqueTypeNumber: '3',
            deletable: true,
            type: 'promotion-heading',
            blocks: [],
            data: {
              level: 1,
              text: `<i class="material-icons">photo_camera</i> ${
                params?.isCommunityGallery ? 'Post Your Own!' : 'Enter Your Own!'
              }`,
              additionalCss:
                '.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-3 {\n display:flex; align-items:center; gap: 4px;   white-space: nowrap;  \n  }\n  \n  ',
            },
            styles: {
              font: {
                color: params?.promotionTextColor,
                weight: 600,
                family: 'Open Sans',
                size: '14',
                height: '20',
                spacing: '1.25',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
        ],
        data: {
          additionalCss:
            '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-4 {\n     width: fit-content;\n  }\n  \n  ',
        },
        styles: {
          layout: {
            display: 'flex',
            direction: 'row',
            align: 'center',
            justify: 'center',
            gapX: '8',
            gapY: '8',
          },
          background: {
            type: 'color',
            color: params?.promotionColor,
            opacity: 100,
          },
          spacing: {
            paddingInline: '16',
          },
          border: {
            radius: '4',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss: '.arc-promotion-promotion-0 .arc-box-box-3 {\n     \n  }\n  \n  ',
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
        gapX: '8',
        gapY: '8',
      },
      spacing: {
        marginBlock: '64',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  },
  {
    uniqueTypeNumber: '40',
    type: 'promotion-box',
    blocks:
      params?.layoutType == 'Justified'
        ? [
            {
              uniqueTypeNumber: '1',
              deletable: true,
              type: 'promotion-grid',
              blocks: [
                photo({ id: '4341', isVideo: params?.isVideo }),
                photo({ id: '4341', isVideo: params?.isVideo }),
              ],
              data: {
                columns: '2:1',
                additionalCss: '.arc-grid-grid-1 {\n     \n  }\n  \n  ',
              },
              styles: {
                layout: {
                  display: 'grid',
                  direction: 'row',
                  gapX: '10',
                  gapY: '10',
                },
                size: {
                  custom: true,
                  height: '302',
                },
                spacing: {
                  paddingInline: '64',
                },
              },
              dataMobile: {
                columns: '1',
              },
              stylesMobile: {},
              dataTablet: {
                columns: '1',
              },
              stylesTablet: {},
            },
            {
              uniqueTypeNumber: '2',
              deletable: true,
              type: 'promotion-grid',
              blocks: [
                photo({ id: '4341', isVideo: params?.isVideo }),
                photo({ id: '4341', isVideo: params?.isVideo }),
              ],
              data: {
                columns: '1:2',
                additionalCss: '.arc-grid-grid-1 {\n     \n  }\n  \n  ',
              },
              styles: {
                layout: {
                  display: 'grid',
                  direction: 'row',
                  gapX: '10',
                  gapY: '10',
                },
                size: {
                  custom: true,
                  height: '302',
                },
                spacing: {
                  paddingInline: '64',
                },
              },
              dataMobile: {
                columns: '1',
              },
              stylesMobile: {},
              dataTablet: {
                columns: '1',
              },
              stylesTablet: {},
            },
          ]
        : params?.layoutType == 'Card'
        ? [
            {
              id: 'box-6',
              uniqueTypeNumber: '1',
              deletable: true,
              type: 'promotion-grid',
              blocks: [
                card({ id: '1111', fitContent: true, width: 200, height: 200, isVideo: params?.isVideo }),
                card({ id: '1112', fitContent: true, width: 200, height: 200, isVideo: params?.isVideo }),
                card({ id: '1113', fitContent: true, width: 200, height: 200, isVideo: params?.isVideo }),
                card({ id: '1114', fitContent: true, width: 200, height: 200, isVideo: params?.isVideo }),
                card({ id: '1115', fitContent: true, width: 200, height: 200, isVideo: params?.isVideo }),
                card({ id: '1116', fitContent: true, width: 200, height: 200, isVideo: params?.isVideo }),
              ],
              data: {
                columns: '1:1:1',
                additionalCss:
                  '.arc-promotion-promotion-0 .arc-promotion-grid-promotion-grid-1 {\n     justify-items: center;\n  }\n  \n  ',
              },
              dataMobile: {
                columns: '1',
              },
              styles: {
                layout: {
                  display: 'grid',
                  direction: 'row',
                  wrap: 'wrap',
                  gapX: '10',
                  gapY: '10',
                },
                spacing: {
                  marginInline: '64',
                },
                size: {
                  custom: true,
                  width: 720,
                },
              },
              stylesMobile: {},
              dataTablet: {},
              stylesTablet: {},
            },
          ]
        : [
            {
              id: 'box-6',
              uniqueTypeNumber: '6',
              deletable: true,
              type: 'promotion-box',
              blocks: [
                photo({ id: '1111', width: 200, height: 200, isVideo: params?.isVideo }),
                photo({ id: '1112', width: 200, height: 200, isVideo: params?.isVideo }),
                photo({ id: '1113', width: 200, height: 200, isVideo: params?.isVideo }),
                photo({ id: '1114', width: 200, height: 200, isVideo: params?.isVideo }),
                photo({ id: '1115', width: 200, height: 200, isVideo: params?.isVideo }),
                photo({ id: '1116', width: 200, height: 200, isVideo: params?.isVideo }),
                photo({ id: '1117', width: 200, height: 200, isVideo: params?.isVideo }),
                photo({ id: '1118', width: 200, height: 200, isVideo: params?.isVideo }),
              ],
              data: {
                additionalCss:
                  '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-6 {\n     justify-content: center;\n  }\n  \n  ',
              },
              styles: {
                layout: {
                  display: 'flex',
                  direction: 'row',
                  wrap: 'wrap',
                  gapX: '10',
                  gapY: '10',
                },
                spacing: {
                  marginInline: '64',
                },
              },
              dataMobile: {},
              stylesMobile: {},
              dataTablet: {},
              stylesTablet: {},
            },
          ],
    data: {
      additionalCss:
        '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-40 {\n  margin-bottom: 64px;   \n  }\n  \n  ',
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
        gapX: '8',
        gapY: '8',
      },
      spacing: {},
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  },
];

const getText = (id: string, text: string) => {
  return {
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-heading',
    blocks: [],
    data: {
      level: 1,
      text,
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-${id} {\n     flex-grow: 1;\n  }\n  \n  `,
    },
    styles: {
      font: {
        color: '#000000',
        weight: 600,
        family: 'Open Sans',
        size: '14',
        height: '24',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

export const ugcFormPage = (params?: ParamsType) => [
  header({ promotionColor: params?.promotionColor, promotionTextColor: params?.promotionTextColor }),
  {
    id: 'box-2',
    uniqueTypeNumber: '2',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'box-3',
        uniqueTypeNumber: '3',
        deletable: true,
        type: 'promotion-box',
        blocks: [
          {
            id: 'heading-2',
            uniqueTypeNumber: '2',
            deletable: true,
            type: 'promotion-heading',
            blocks: [],
            data: {
              level: 1,
              text: 'Submit Your Entry',
              additionalCss: '.arc-heading-heading-2 {\n     \n  }\n  \n  ',
            },
            styles: {
              font: {
                color: '#616161',
                weight: 600,
                family: 'Open Sans',
                size: '18',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
          {
            id: 'heading-3',
            uniqueTypeNumber: '3',
            deletable: true,
            type: 'promotion-heading',
            blocks: [],
            data: {
              level: 1,
              text: 'Fill out the form to enter the Gallery\n\n',
              additionalCss: '.arc-heading-heading-3 {\n     \n  }\n  \n  ',
            },
            styles: {
              font: {
                color: '#616161',
                weight: 400,
                family: 'Open Sans',
                size: '14',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
          {
            id: 'box-4',
            uniqueTypeNumber: '4',
            deletable: true,
            type: 'promotion-box',
            blocks: [
              {
                id: 'box-5',
                uniqueTypeNumber: '5',
                deletable: true,
                type: 'promotion-box',
                blocks: [
                  getText('4', 'Your Media'),
                  {
                    id: 'heading-5',
                    uniqueTypeNumber: '5',
                    deletable: true,
                    type: 'promotion-heading',
                    blocks: [],
                    data: {
                      level: 1,
                      text: 'Required',
                      additionalCss: '.arc-heading-heading-4 {\n  }\n  \n  ',
                    },
                    styles: {
                      font: {
                        color: '#252b31',
                        weight: '',
                        family: 'Open Sans',
                        size: '12',
                        height: '16',
                        align: 'start',
                      },
                    },
                    dataMobile: {},
                    stylesMobile: {},
                    dataTablet: {},
                    stylesTablet: {},
                  },
                ],
                data: {
                  additionalCss:
                    '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-5 {\n     height: fit-content;\n  }\n  \n  ',
                },
                styles: {
                  layout: {
                    display: 'flex',
                    direction: 'row',
                    align: 'center',
                  },
                  font: {
                    color: '#757575',
                  },
                  size: {
                    custom: true,
                    height: '15',
                  },
                  spacing: {
                    marginBlock: '5',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
              {
                id: 'box-6',
                uniqueTypeNumber: '6',
                deletable: true,
                type: 'promotion-box',
                blocks: [
                  {
                    id: 'button-1',
                    uniqueTypeNumber: '1',
                    deletable: true,
                    type: 'promotion-button',
                    blocks: [],
                    data: {
                      href: '',
                      text: `Upload ${params?.isVideo ? 'Video' : 'Image'}`,
                      target: '_blank',
                      url: '',
                      additionalCss: '.arc-button-button-1 {\n     \n  }\n  \n  ',
                    },
                    styles: {
                      border: {
                        radius: 5,
                      },
                      spacing: {
                        paddingBlock: '5',
                        paddingInline: '',
                      },
                      background: {
                        type: 'color',
                        color: '#2196f3',
                      },
                      font: {
                        color: '#ffffff',
                        align: 'center',
                        family: 'Open Sans',
                        weight: 400,
                        size: '14',
                      },
                      size: {
                        custom: true,
                        width: '192',
                        height: '33',
                      },
                    },
                    dataMobile: {},
                    stylesMobile: {},
                    dataTablet: {},
                    stylesTablet: {},
                  },
                ],
                data: {
                  additionalCss: '.arc-box-box-6 {\n     \n  }\n  \n  ',
                },
                styles: {
                  layout: {
                    display: 'flex',
                    direction: 'column',
                    align: 'center',
                    justify: 'center',
                  },
                  border: {
                    style: 'dashed',
                    width: '2',
                    color: '#6b7786',
                    radius: '4',
                  },
                  size: {
                    custom: true,
                    height: '175',
                  },
                  background: {
                    type: 'color',
                    color: '#ffffff',
                    opacity: 100,
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
            ],
            data: {
              additionalCss: '.arc-box-box-4 {\n     \n  }\n  \n  ',
            },
            styles: {
              layout: {
                display: 'flex',
                direction: 'column',
              },
              spacing: {
                marginBlock: '10',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
          {
            id: 'box-13',
            uniqueTypeNumber: '13',
            deletable: true,
            type: 'promotion-box',
            blocks: [
              {
                id: 'box-14',
                uniqueTypeNumber: '14',
                deletable: true,
                type: 'promotion-box',
                blocks: [getText('10', 'Title')],
                data: {
                  additionalCss:
                    '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-5 {\n     height: fit-content;\n  }\n  \n  ',
                },
                styles: {
                  layout: {
                    display: 'flex',
                    direction: 'row',
                    align: 'center',
                  },
                  font: {
                    color: '#757575',
                  },
                  size: {
                    custom: true,
                    height: '15',
                  },
                  spacing: {
                    marginBlock: '5',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
              {
                id: 'box-15',
                uniqueTypeNumber: '15',
                deletable: true,
                type: 'promotion-box',
                blocks: [],
                data: {
                  additionalCss: '.arc-box-box-6 {\n     \n  }\n  \n  ',
                },
                styles: {
                  layout: {
                    display: 'flex',
                    direction: 'column',
                  },
                  border: {
                    style: 'solid',
                    width: 1,
                    color: '#6b7786',
                    radius: '4',
                  },
                  size: {
                    custom: true,
                    height: '40',
                  },
                  background: {
                    type: 'color',
                    color: '#ffffff',
                    opacity: 100,
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
            ],
            data: {
              additionalCss: '.arc-box-box-4 {\n     \n  }\n  \n  ',
            },
            styles: {
              layout: {
                display: 'flex',
                direction: 'column',
              },
              spacing: {
                marginBlock: '10',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
          {
            id: 'box-7',
            uniqueTypeNumber: '7',
            deletable: true,
            type: 'promotion-box',
            blocks: [
              {
                id: 'box-8',
                uniqueTypeNumber: '8',
                deletable: true,
                type: 'promotion-box',
                blocks: [getText('6', 'Caption')],
                data: {
                  additionalCss:
                    '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-5 {\n     height: fit-content;\n  }\n  \n  ',
                },
                styles: {
                  layout: {
                    display: 'flex',
                    direction: 'row',
                    align: 'center',
                  },
                  font: {
                    color: '#757575',
                  },
                  size: {
                    custom: true,
                    height: '15',
                  },
                  spacing: {
                    marginBlock: '5',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
              {
                id: 'box-9',
                uniqueTypeNumber: '9',
                deletable: true,
                type: 'promotion-box',
                blocks: [],
                data: {
                  additionalCss: '.arc-box-box-6 {\n     \n  }\n  \n  ',
                },
                styles: {
                  layout: {
                    display: 'flex',
                    direction: 'column',
                  },
                  border: {
                    style: 'solid',
                    width: 1,
                    color: '#6b7786',
                    radius: '4',
                  },
                  size: {
                    custom: true,
                    height: '88',
                  },
                  background: {
                    type: 'color',
                    color: '#ffffff',
                    opacity: 100,
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
            ],
            data: {
              additionalCss: '.arc-box-box-4 {\n     \n  }\n  \n  ',
            },
            styles: {
              layout: {
                display: 'flex',
                direction: 'column',
              },
              spacing: {
                marginBlock: '20',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
          {
            id: 'box-16',
            uniqueTypeNumber: '16',
            deletable: true,
            type: 'promotion-box',
            blocks: [
              {
                id: 'heading-12',
                uniqueTypeNumber: '12',
                deletable: true,
                type: 'promotion-heading',
                blocks: [],
                data: {
                  level: 1,
                  text: 'Submit',
                  additionalCss: '.arc-heading-heading-12 {\n     \n  }\n  \n  ',
                },
                styles: {
                  font: {
                    color: params?.promotionTextColor,
                    weight: 400,
                    family: 'Open Sans',
                    size: '20',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
            ],
            data: {
              additionalCss: '.arc-box-box-16 {\n     \n  }\n  \n  ',
            },
            styles: {
              layout: {
                display: 'flex',
                direction: 'column',
                align: 'center',
                justify: 'center',
              },
              background: {
                type: 'color',
                color: params?.promotionColor,
                opacity: 100,
              },
              spacing: {
                marginBlock: '21',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
          {
            id: 'box-17',
            uniqueTypeNumber: '17',
            deletable: true,
            type: 'promotion-box',
            blocks: [
              {
                id: 'heading-112',
                uniqueTypeNumber: '112',
                deletable: true,
                type: 'promotion-heading',
                blocks: [],
                data: {
                  level: 1,
                  text: 'Cancel',
                  additionalCss: '.arc-heading-heading-112 {\n     \n  }\n  \n  ',
                },
                styles: {
                  font: {
                    color: '#199cd0',
                    weight: 400,
                    family: 'Open Sans',
                    size: '20',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
            ],
            data: {
              additionalCss: '.arc-box-box-16 {\n     \n  }\n  \n  ',
            },
            styles: {
              layout: {
                display: 'flex',
                direction: 'column',
                align: 'center',
                justify: 'center',
              },
              background: {},
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
        ],
        data: {
          additionalCss: '.arc-box-box-3 {\n     \n  }\n  \n  ',
        },
        styles: {
          layout: {
            display: 'flex',
            direction: 'column',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss:
        '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-2 {\n     max-width: 450px;\n        margin: auto;\n  }\n  \n  ',
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
      },
      spacing: {
        paddingBlock: '32',
        paddingInline: '32',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  },
];

export const ugcEntrantPage = (params?: ParamsType) => [
  header({
    email: 'user@secondstreet.com',
    promotionColor: params?.promotionColor,
    promotionTextColor: params?.promotionTextColor,
  }),
  subHeader('2', 'Gallery', params?.promotionColor, params?.promotionTextColor),
  {
    id: 'box-61',
    uniqueTypeNumber: '61',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'box-62',
        uniqueTypeNumber: '62',
        deletable: true,
        type: 'promotion-box',
        data: {
          additionalCss:
            '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-62 {\n position: relative; max-width: 440px;   \n  }\n  \n  ',
        },
        blocks: [
          ...(params?.isPending
            ? []
            : [
                {
                  id: 'grid-2',
                  uniqueTypeNumber: '2',
                  deletable: true,
                  type: 'promotion-grid',
                  blocks: [
                    {
                      id: 'box-153',
                      uniqueTypeNumber: '153',
                      deletable: true,
                      type: 'promotion-box',
                      blocks: [
                        {
                          id: 'heading-44',
                          uniqueTypeNumber: '44',
                          deletable: true,
                          type: 'promotion-heading',
                          blocks: [],
                          data: {
                            level: 1,
                            text: '< PREVIOUS',
                            additionalCss: '.arc-heading-heading-44 {\n     \n  }\n  \n  ',
                          },
                          styles: {
                            font: {
                              color: '#00c3c3',
                              weight: 400,
                              family: 'Open Sans',
                              size: '16',
                            },
                          },
                          dataMobile: {},
                          stylesMobile: {},
                          dataTablet: {},
                          stylesTablet: {},
                        },
                      ],
                      data: {
                        additionalCss: '.arc-box-box-153 {\n     \n  }\n  \n  ',
                      },
                      styles: {
                        layout: {
                          display: 'flex',
                          direction: 'column',
                          align: 'center',
                        },
                      },
                      dataMobile: {},
                      stylesMobile: {},
                      dataTablet: {},
                      stylesTablet: {},
                    },
                    {
                      id: 'box-155',
                      uniqueTypeNumber: '155',
                      deletable: true,
                      type: 'promotion-box',
                      blocks: [
                        {
                          id: 'heading-46',
                          uniqueTypeNumber: '46',
                          deletable: true,
                          type: 'promotion-heading',
                          blocks: [],
                          data: {
                            level: 1,
                            text: 'NEXT >',
                            additionalCss: '.arc-heading-heading-44 {\n     \n  }\n  \n  ',
                          },
                          styles: {
                            font: {
                              color: '#00c3c3',
                              weight: 400,
                              family: 'Open Sans',
                              size: '16',
                              align: 'end',
                            },
                          },
                          dataMobile: {},
                          stylesMobile: {},
                          dataTablet: {},
                          stylesTablet: {},
                        },
                      ],
                      data: {
                        additionalCss: '.arc-box-box-155 {\n     \n  }\n  \n  ',
                      },
                      styles: {
                        layout: {
                          display: 'flex',
                          direction: 'column',
                          align: 'center',
                        },
                      },
                      dataMobile: {},
                      stylesMobile: {},
                      dataTablet: {},
                      stylesTablet: {},
                    },
                  ],
                  data: {
                    columns: '1:1',
                    additionalCss: '.arc-grid-grid-2 {\n     \n  }\n  \n  ',
                  },
                  styles: {
                    layout: {
                      display: 'grid',
                      direction: 'row',
                    },
                    spacing: {
                      paddingInline: '20',
                    },
                  },
                  dataMobile: {
                    columns: '1:1',
                  },
                  stylesMobile: {},
                  dataTablet: {
                    columns: '1:1',
                  },
                  stylesTablet: {},
                },
              ]),
          ...(params?.isPending
            ? [
                {
                  id: 'box-1212',
                  uniqueTypeNumber: '1212',
                  deletable: true,
                  type: 'promotion-box',
                  blocks: [
                    {
                      id: 'image-3',
                      uniqueTypeNumber: '3',
                      deletable: true,
                      type: 'promotion-image',
                      blocks: [],
                      data: {
                        src: 'https://media.secondstreetapp.com/6548560?width=107&height=80&cropmode=fit&anchor=center&quality=60&format=auto',
                        alt: '',
                        url: '',
                        target: '_blank',
                        additionalCss:
                          '.arc-promotion-promotion-0 .arc-promotion-image-promotion-image-3 {\n     margin-top: 4px;\n  }\n  \n  ',
                      },
                      styles: {
                        size: {
                          custom: true,
                          width: '18',
                          height: '',
                        },
                        spacing: {
                          marginBlock: '',
                        },
                      },
                      dataMobile: {},
                      stylesMobile: {},
                      dataTablet: {},
                      stylesTablet: {},
                    },
                    {
                      id: 'heading-6',
                      uniqueTypeNumber: '6',
                      deletable: true,
                      type: 'promotion-heading',
                      blocks: [],
                      data: {
                        level: 5,
                        text: 'Pending Approval',
                        additionalCss: '.arc-heading-heading-6 {\n     \n  }\n  \n  ',
                      },
                      styles: {
                        font: {
                          color: '#ffffff',
                          weight: 400,
                          family: 'Open Sans',
                          size: '14',
                        },
                        spacing: {
                          marginBlock: '4',
                        },
                      },
                      dataMobile: {},
                      stylesMobile: {},
                      dataTablet: {},
                      stylesTablet: {},
                    },
                  ],
                  data: {
                    additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-1212 {\n  position: absolute; z-index: 1; margin: 10px; width: -webkit-fill-available; \n  }\n  \n  `,
                  },
                  styles: {
                    layout: {
                      display: 'flex',
                      direction: 'row',
                      gapX: '4',
                      gapY: '4',
                      align: 'center',
                      justify: '',
                    },
                    background: {
                      type: 'color',
                      color: '#ff6500',
                      opacity: 100,
                    },
                    spacing: {
                      paddingBlock: '5',
                      paddingInline: '10',
                    },
                    size: {
                      custom: true,
                      height: '36',
                    },
                  },
                  dataMobile: {},
                  stylesMobile: {},
                  dataTablet: {},
                  stylesTablet: {},
                },
              ]
            : []),
          card({
            id: '121',
            height: 440,
            width: 400,
            isVideo: params?.isVideo,
            titleColor: '#707070',
            titleSize: '20',
            titleWeight: '700',
            descriptionColor: '#6b7786',
            descriptionSize: '16',
            descriptionWeight: '400',
          }),
        ],
      },
    ],
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
      },
      spacing: {
        marginBlock: '48',
      },
    },
  },
];
