import Controller from '@ember/controller';
import type { Model } from 'partner/routes/organizations/organization/legal/design-templates/legal-design-template-route';
import { action } from '@ember/object';

export default class LegalDesignTemplatesCommonController extends Controller {
  declare model: Model;

  @action
  removeTemplate(template: any) {
    switch (template.designTemplateType) {
      case 'Privacy Policy':
        this.model.privacyPolicyTemplates.removeObject(template);
        break;
      case 'Rules':
        this.model.rulesTemplates.removeObject(template);
        break;
      case 'Terms of Service':
        this.model.termsOfServiceTemplates.removeObject(template);
        break;
    }
  }
}
