import PromotionAdsRoute from 'partner/-base/promotion-ads-route';
import RSVP from 'rsvp';

export default class PromotionSetupAdsBracketRoute extends PromotionAdsRoute {
  model() {
    return RSVP.hash({
      _settings: this.settings.preload(['dips_url', 'category_sort_criteria']),
      matchups: this.store.query('matchup', { excludeSecondaryMatchups: true }).then(x => x.toArray()),
      matchupGroups: [],
      ads: this.store.query('sponsored-post', { BypassCache: true }).then(x => x.toArray()),
      matchupEntries: [],
    });
  }
}
