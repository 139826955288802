/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { observer, set } from '@ember/object';
import { isBlank, isEmpty } from '@ember/utils';
import moment from 'moment';
import SsInputComponent from 'partner/components/ss-input';

const SUPPORTED_FORMATS = ['hh:mma', 'h:mma', 'hh:mm a', 'h:mm a', 'ha', 'h a', 'hha', 'hh a'];

/**
 * Example usage: {{time-picker time=myTime display-format="" data-format="" action=(action setTime)}}
 *
 * Even though this is an input, please don't bind to its value; bind to its time instead.
 */
export default SsInputComponent.extend({
  init() {
    this._super(...arguments);
    /**
     * When the time-picker first renders, populate the input value with the model's time.
     */
    if (this.time) {
      const time = moment(this.time, SUPPORTED_FORMATS).format(this['display-format']);
      set(this, 'value', time);
    }
  },

  //region Attributes
  /**
   * @type {String}
   */
  time: '',
  /**
   * @type {String}
   */
  updatedTime: '',
  /**
   * @type {String}
   */
  'display-format': 'h:mma',
  /**
   * @type {String}
   */
  'data-format': 'HH:mm:ss',
  //endregion

  //region Observers
  /**
   * When the data changes, update the value
   */
  updateTime: observer('updatedTime', function () {
    if (isEmpty(this.time)) {
      const time = moment(this.updatedTime, SUPPORTED_FORMATS).format(this['display-format']);
      set(this, 'value', time);
    }
  }),

  /**
   * When you clear the model's time, clear out the input value as well.
   */
  timeCleared: observer('time', function () {
    if (isBlank(this.time)) {
      set(this, 'value', '');
    }
  }),

  /**
   * When the input value changes, immediately update the time stored on the model.
   */
  valueChanged: observer('value', function () {
    if (this.value) {
      if (this.value !== 'Invalid date') {
        const time = moment(this.value, SUPPORTED_FORMATS).format(this['data-format']);
        set(this, 'time', time);
      } else {
        set(this, 'value', '');
      }
    } else {
      set(this, 'time', undefined);
    }
  }),
  //endregion

  //region Ember Hooks
  focusOut() {
    if (this.value) {
      const value = moment(this.value, SUPPORTED_FORMATS).format(this['display-format']);
      set(this, 'value', value);
    }
    if (this.action && typeof this.action === 'function') {
      this.action(this.time);
    }
  },
  //endregion
});
