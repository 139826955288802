/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/use-ember-get-and-set, ember/avoid-leaking-state-in-ember-objects */ // FIXME
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import ENUMS from 'ember-cli-ss-enums/utils/enum-data';
import isAnyPath from 'partner/utils/is-any-path';

export default Controller.extend({
  enums: service(),
  queryParams: ['languageId'],
  keySortingAsc: ['key:asc'],
  languageId: 1,
  languages: ENUMS['LANGUAGE'],
  language: computed('languageId', function () {
    return this.enums.findWhere('LANGUAGE', { id: this.languageId }, {});
  }),
  languageSettings: computed('model.[]', 'languageId', function () {
    return this.model.filter(setting => setting.get('languageId') === this.languageId);
  }),
  sortedLanguageSettings: sort('languageSettings', 'keySortingAsc'),
  filteredSortedLanguageSettings: computed('sortedLanguageSettings', 'searchTerm', function () {
    let settings = this.sortedLanguageSettings;
    const { searchTerm } = this;
    if (searchTerm) {
      settings = settings.filter(
        setting =>
          setting.get('value').toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
          setting.get('key').toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      );
    }
    return settings;
  }),
  isAnythingSaving: isAnyPath('isSaving', ['model.[]']),
  isAnythingDirty: isAnyPath('hasDirtyAttributes', ['model.[]']),
  actions: {
    languageChanged(language) {
      this.set('languageId', language.id);
    },
  },
});
