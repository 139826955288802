import { attr, belongsTo } from '@ember-data/model';
import enums from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';

export default class extends BaseModel {
  //region Properties
  @attr('number')
  statusTypeId;

  @attr('number')
  integrationExternalServiceId;

  @attr('string')
  externalServiceAccountLogin;

  @attr('string')
  externalServiceAudienceIdentifier;

  @attr('string')
  externalServiceAudienceName;

  @attr('boolean')
  syncOptOuts;

  @attr('string')
  additionalNotes;

  @attr('boolean')
  hasSyncError;

  @attr('string')
  lastSyncMessage;
  //endregion

  //region Relationships
  @belongsTo('audience') audience;
  @belongsTo('organization') organization;
  //endregion

  //region Computed Properties
  get isActive() {
    return this.statusTypeId === enums.findWhere('STATUS_TYPE', { name: 'Active' }, 'id');
  }

  get isInActive() {
    return this.statusTypeId === enums.findWhere('STATUS_TYPE', { name: 'InActive' }, 'id');
  }
  //endregion
}
