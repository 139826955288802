import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class LoginIndexController extends Controller {
  //region Properties
  queryParams = ['code', 'state', 'uniqueUri'];
  @tracked isLoggingIn = false;
  @tracked errors = [];
  @tracked showPasswordField = false;
  //endregion
}
