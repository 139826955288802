/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  //region Dependencies
  checklist: service(),
  //endregion

  //region Attributes
  audience: null,
  'check-checklist-step': x => x,
  'update-audience-count': x => x,
  'next-step': x => x,
  //endregion

  //region Properties
  /**
   * @property {Boolean} uploadComplete
   */
  uploadComplete: false,
  //endregion

  //region Computed Properties
  status: computed('uploadComplete', function () {
    return this.uploadComplete ? 'bestPractice' : 'incomplete';
  }),

  buttonText: computed('uploadComplete', function () {
    return this.uploadComplete ? 'Upload Another' : 'Upload';
  }),
  //endregion

  //region Actions
  actions: {
    uploadComplete() {
      set(this, 'uploadComplete', true);
      this['update-audience-count']();
      this['check-checklist-step']();
    },
    continue() {
      this['next-step']();
      this['check-checklist-step']();
    },
  },
  //endregion
});
