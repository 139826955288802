/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

export default Route.extend(AppContextRoute, {
  store: service(),

  async model() {
    const currentOrganization = this.modelFor('organizations.organization', 'organization');

    const hash = await RSVP.hash({
      promotionFeedItems: this.store.query('promotion-feed-item', {
        organizationId: currentOrganization.id,
      }),
      searchResults: this.store.query('organization-promotion', {
        isCurrent: true,
        statusTypeId: 1,
        pageSize: 50,
      }),
      domains: this.store.query('domain', {
        organizationId: currentOrganization.id,
      }),
      promotionFeedTitleSetting: this.store.query('setting', { key: 'promotion_feed_title' }),
    });

    const promotionFeedLinkDomain = hash.domains.filter(domain => domain.isPrimary).firstObject;
    const formattedPromotionFeedLink = promotionFeedLinkDomain
      ? promotionFeedLinkDomain.isSslSupported
        ? `https://${promotionFeedLinkDomain.hostname}`
        : `http://${promotionFeedLinkDomain.hostname}`
      : '';

    const promotionFeedTitleSettingArray = hash.promotionFeedTitleSetting.toArray();

    // If there is not title setting, create one
    if (isEmpty(hash.promotionFeedTitleSetting)) {
      const titleSetting = this.store.createRecord('setting', {
        organization: currentOrganization.organization,
        key: 'promotion_feed_title',
        value: '',
      });

      await titleSetting.save();

      promotionFeedTitleSettingArray.addObject(titleSetting);
    }

    return {
      promotionFeedItems: hash.promotionFeedItems.toArray(),
      searchResults: hash.searchResults,
      promotionFeedLink: formattedPromotionFeedLink,
      titleSetting: promotionFeedTitleSettingArray,
    };
  },

  afterModel(model) {
    // Load all promotions associated with the feed items, this way we don't
    // have to await when getting Proxy object properties
    return Promise.all([...model.promotionFeedItems.mapBy('organizationPromotion')]);
  },

  // region Actions
  actions: {
    async reorderPromotionsFeedItems(reorderedList) {
      reorderedList.forEach((listItem, index) => {
        set(listItem, 'displayOrder', index + 1);
      });

      Promise.all([...reorderedList.filterBy('hasDirtyAttributes').map(listItem => listItem.save())]);
    },
    async searchPromotions(searchValue) {
      // Search string is empty
      // We get up to 50 results as a buffer for filtering out
      // promotions already added to the Promotions Feed, even
      // if we're only displaying 5
      const query = { isCurrent: true, statusTypeId: 1, pageSize: 50 };

      if (searchValue.length > 0) {
        query.isCurrent = true;
        query.searchValue = searchValue;
        query.statusTypeId = 1;
        query.pageSize = 9999;
      }

      const searchResults = await this.store.query('organization-promotion', query);

      set(this.controller, 'model.searchResults', searchResults);
    },
    async addPromotionToFeed(promotion) {
      const now = new Date();

      const promotionFeedItem = this.store.createRecord('promotion-feed-item', {
        organizationPromotion: promotion,
        displayOrder: promotion.startDate < now ? this.controller.model.promotionFeedItems.length + 1 : null,
      });

      promotionFeedItem.save();

      this.controller.model.promotionFeedItems.addObject(promotionFeedItem);
    },
    async removePromotionFeedItemFromFeed(promotionFeedItem) {
      this.controller.model.promotionFeedItems.removeObject(promotionFeedItem);
      return promotionFeedItem.destroyRecord();
    },
  },
});
