import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { endpoint } from 'secondstreet-common/utils/url';

export default class SetupTotpRoute extends Route {
  //region Dependencies
  @service session;
  @service router;
  //endregion

  //region Properties
  @tracked currentSession = null;
  //endregion

  //region Hooks
  redirect() {
    if (!this.session.isAuthenticated) {
      this.router.replaceWith('login');
    } else if (this.session.attemptedTransition?.targetName !== 'reset-password') {
      if (!this.session.isTwoFactorUser || this.session.isTwoFactorAuthComplete) {
        window.history.replaceState({}, '', '/');
        this.router.replaceWith('users.user', this.session.data?.authenticated?.organization_users.user_id);
      } else if (this.session.isTwoFactorAuthIncomplete) {
        this.router.replaceWith('login.totp');
      }
    }
  }

  beforeModel() {
    if (!this.session.isAuthenticated) {
      this.router.replaceWith('login');
    }
  }

  async model() {
    // This isn't something we want loaded into ember data
    const { two_factor_authentication_keys } = await this.session.request(endpoint('two_factor_authentication_keys'), {
      type: 'GET',
      headers: getOwner(this).lookup('adapter:application').headers,
      contentType: 'application/json',
    });
    return {
      secretKey: two_factor_authentication_keys?.firstObject.secret_key,
      userEmail: this.session.data?.authenticated.username,
    };
  }
  afterModel() {
    set(this, 'currentSession', this.session.data?.authenticated);
  }
  //endregion

  //region Actions
  @action
  async submit2fa(code) {
    const token = this.currentSession?.access_token;
    const data = {
      token,
      id: this.currentSession?.id,
      access_code: code,
    };
    try {
      await this.session.authenticate('authenticator:secondStreet', data);
      set(this, 'currentSession', null);
      this.router.transitionTo('setup.totp-success');
    } catch ({ payload: { errors } }) {
      set(this, 'controller.errors', errors);
    }
  }
  //endregion
}
