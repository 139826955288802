/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, hasMany } = DS;

export default BaseModel.extend({
  //region Properties
  name: attr('string'),
  description: attr('string'),
  promotionTypeId: attr('number'),
  //endregion

  //region Relationships
  checklistSteps: hasMany('checklist-step', { async: false }),
  //endregion

  //region Computed Properties
  isComplete: computed('checklistSteps.@each.isComplete', function () {
    return this.checklistSteps.every(step => step.isComplete);
  }),
  sortedChecklistSteps: computed('checklistSteps.@each.displayOrder', function () {
    return this.checklistSteps.sortBy('displayOrder');
  }),
  //endregion
});
