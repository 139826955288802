import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import enums from 'ember-cli-ss-enums/services/enums';
import { allSettled, task } from 'ember-concurrency';
import { UNSAVED_CHANGES_ALERT } from 'partner/utils/constants';

// region enums
const ADESTRA_ORGANIZATION_ATTRIBUTE_TYPE_ID = enums.findWhere(
  'ORGANIZATION_ATTRIBUTE_TYPE',
  { name: 'HasAdestraIntegration' },
  'id'
);
const ACTIVE_STATUS_TYPE_ID = enums.findWhere('STATUS_TYPE', { name: 'Active' }, 'id');
const INACTIVE_STATUS_TYPE_ID = enums.findWhere('STATUS_TYPE', { name: 'InActive' }, 'id');
// endregion

// region messages
const InvalidErrorMessage = 'Invalid API key.';
const DefaultAdestraErrorMessage = 'Something went wrong while trying to send this audience.';
const IntegrationSuccessMessage = 'You have successfully updated your Adestra integration.';
const DoubleConfirmationMessage = "Are you sure you're ready to send opt ins to Adestra?";
// endregion
export default class OrganizationsOrganizationDataAudiencesAdestraController extends Controller {
  @service appcues;
  @service deliberateConfirmation;
  @service snackbar;
  @service router;
  @service store;

  @tracked invalidKeyError = null;
  @tracked authorized = false;
  @tracked searchValue = '';
  @tracked confirmedLeave = false;

  // key || workspace
  @tracked adestraConnectionStep = 'key';
  @tracked adestraWorkspaceCoretable;
  @tracked apiKey;
  @tracked selectedWorkspace;
  @tracked selectedCoreTable;

  // region Computed
  get hasIntegration() {
    return this.audiences.some(audience => this.hasExistingAdestraIntegrationHelper(audience));
  }

  get audiencesSearchResult() {
    return this.audiences.filter(audience => this.isSearchResult(this.searchValue, audience.name));
  }

  get audiences() {
    return this.model?.audiences || [];
  }

  get isDirty() {
    return this.audiences.some(audience => audience?.adestraIntegration?.hasDirtyAttributes);
  }

  get connectDisabled() {
    return this.adestraConnectionStep === 'workspace' && !(this.selectedCoreTable && this.selectedWorkspace);
  }

  get coreTables() {
    return this.adestraWorkspaceCoretable?.coreTables;
  }

  get apiAuthButtonText() {
    if (this.adestraConnectionStep === 'key') {
      return this.connect.isRunning ? 'connecting' : 'connect';
    }
    return 'continue';
  }
  // endregion

  // region Helpers
  hasExistingAdestraIntegrationHelper(audience) {
    return audience.adestraIntegration?.statusTypeId && audience.adestraIntegration.dirtyType !== 'created';
  }

  isSearchResult(searchValue, item = '') {
    return item.toLowerCase().includes(searchValue.trim().toLowerCase());
  }
  // endregion

  @action
  isSwitchedOn(audience) {
    return audience.adestraIntegration?.isActive;
  }

  @action
  hasOtherIntegrations(audience) {
    return audience.hasExistingIntegration && !audience.adestraIntegration;
  }

  @action
  getErrorMessage(audience) {
    if (audience.adestraIntegration && !audience.adestraIntegration?.isValid) {
      const errors = audience.adestraIntegration.errors.messages;
      let message;
      if (Array.isArray(errors)) [message] = errors;
      return message || DefaultAdestraErrorMessage;
    }
  }

  @action
  apiKeyChanged(key) {
    this.invalidKeyError = null;
    this.apiKey = key;
  }

  @action
  workspaceChanged(workspace) {
    this.selectedWorkspace = workspace;
  }

  @action
  coreTableChanged(coreTable) {
    this.selectedCoreTable = coreTable;
  }

  @action
  updateSearch(event) {
    this.searchValue = event.target.value;
  }

  @action
  toggleAudience(audience) {
    if (audience.adestraIntegration?.hasDirtyAttributes) {
      return audience.adestraIntegration.rollbackAttributes();
    }
    if (audience.adestraIntegration?.hasSyncError && audience.adestraIntegration?.isActive) {
      audience.adestraIntegration.hasSyncError = false;
    }
    if (!audience.adestraIntegration?.statusTypeId) {
      this.createAdestraIntegration(audience);
    }
    audience.adestraIntegration.statusTypeId = audience.adestraIntegration.isActive
      ? INACTIVE_STATUS_TYPE_ID
      : ACTIVE_STATUS_TYPE_ID;
  }

  completeIntegration = task({ drop: true }, async () => {
    const isConfirmed = await this.deliberateConfirmation.show({
      promptText: DoubleConfirmationMessage,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    });

    if (isConfirmed) {
      const result = await this.submitIntegratedAudiences();
      // state = fulfilled || rejected
      const hasSuccess = result.some(({ state, value }) => state === 'fulfilled' && value.isValid);
      if (hasSuccess) this.showSuccessToast();
    }
  });

  showSuccessToast() {
    this.snackbar.show(IntegrationSuccessMessage);
  }

  submitIntegratedAudiences() {
    const audiences = this.audiences.filter(audience => audience.adestraIntegration?.hasDirtyAttributes);
    const integrations = audiences.map(audience => this._saveAdestraIntegration.perform(audience));
    return allSettled(integrations);
  }

  _saveAdestraIntegration = task(async audience => {
    const { adestraIntegration } = audience;
    await adestraIntegration.save();
    return adestraIntegration;
  });

  connect = task({ drop: true }, async () => {
    try {
      if (this.adestraConnectionStep === 'key') {
        this.adestraWorkspaceCoretable = await this.store.queryRecord('adestraWorkspacesCoretable', {
          Token: this.apiKey,
        });
        this.adestraConnectionStep = 'workspace';
        return;
      }

      const attributeValueMap = {
        token: this.apiKey,
        workspace_id: this.selectedWorkspace?.id,
        core_table_id: this.selectedCoreTable?.id,
      };
      await this.store
        .createRecord('organizationAttribute', {
          organization: this.model.organization,
          organizationAttributeTypeId: ADESTRA_ORGANIZATION_ATTRIBUTE_TYPE_ID,
          attributeValueMap,
        })
        .save();
      await this.router.transitionTo('organizations.organization.adestra.configure-fields');

      this.appcues.track('Connected Adestra Integration');
    } catch (error) {
      this.handleError(error);
    }
  });

  handleError(error) {
    let serverError;
    try {
      serverError = error.errors[0].detail;
      this.invalidKeyError = serverError.includes('InvalidToken') ? InvalidErrorMessage : serverError;
    } catch (error) {
      this.invalidKeyError = serverError ? serverError : InvalidErrorMessage;
    }
  }

  // Transition event listener
  addExitHandler() {
    this.router.on('routeWillChange', this, this.exitHandler);
  }

  removeExitHandler() {
    this.router.off('routeWillChange', this, this.exitHandler);
  }

  exitHandler(transition) {
    if (this.confirmedLeave || !this.isDirty || transition.isAborted || !transition.targetName) return;

    const confirmed = window.confirm(UNSAVED_CHANGES_ALERT);
    if (confirmed) this.confirmedLeave = true;
    else transition.abort();
  }
}
