/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { not } from '@ember/object/computed';
import { isEmpty, typeOf } from '@ember/utils';

export default Component.extend({
  //region Ember Hooks
  tagName: '',
  //endregion

  //region Attributes
  /**
   * @property {DS.Model?}
   */
  item: null,
  /**
   * @property {Boolean}
   */
  'is-tags': false,
  /**
   * @property {Boolean}
   */
  'is-optins': false,
  /**
   * @property {Boolean}
   */
  'is-notify': false,
  /**
   * @property {Boolean}
   */
  'is-promotions': false,
  /**
   * @property {Boolean}
   */
  'is-messages': false,
  /**
   * @property {Boolean}
   */
  'secondary-chosen-items': false,
  /**
   * @property {String?}
   */
  'secondary-text-path': null,
  /**
   * Note: This will be mutated.
   * @property {Array}
   */
  'chosen-items': null,
  /**
   * @property {Function?}
   */
  'item-removed': null,
  /**
   * @property {Function?}
   */
  'item-added': null,
  /**
   * @property {Boolean}
   */
  'is-audiences': false,
  //endregion

  //region Properties
  isExpanded: false,
  //endregion

  //region Computed Properties
  isExpandable: computed(
    'tags-and-taggable-entities-map',
    'is-fields',
    'item.{fieldOptions,childInterestTags}.[]',
    'taggableEntities.[]',
    function () {
      const hasTaggableEntities = !isEmpty(this.taggableEntities);
      const hasFieldOptions = !isEmpty(this.item.fieldOptions);
      const hasChildInterestTags = !isEmpty(this.item.childInterestTags);

      if (this['tags-and-taggable-entities-map']) {
        return hasTaggableEntities || hasChildInterestTags;
      }

      if (this['is-fields']) {
        return hasFieldOptions;
      }

      return hasChildInterestTags;
    }
  ),
  cantBeChosen: computed('is-fields', 'item.isFakeCategory', function () {
    return this.item.isFakeCategory || (this['is-fields'] && !isEmpty(this.item.fieldType));
  }),
  canBeChosen: not('cantBeChosen'),
  isChosen: computed('item', 'chosen-items.[]', function () {
    const chosenItems = this['chosen-items'];
    if (!chosenItems) {
      return false;
    }
    if (this['secondary-chosen-items']) {
      return chosenItems.includes(window.parseInt(this.item.promotion.id));
    }
    return chosenItems.includes(this.item);
  }),
  taggableEntities: computed('item', 'tags-and-taggable-entities-map.[]', function () {
    const interestTag = this.item;
    const tagsAndTaggableEntitiesMap = this['tags-and-taggable-entities-map'];
    if (tagsAndTaggableEntitiesMap) {
      /* eslint-disable-next-line ember/use-ember-get-and-set */
      const results = tagsAndTaggableEntitiesMap.get(interestTag);
      return results ? results.sortBy('name') : [];
    }
    return null;
  }),
  kpiUnits: computed('is-messages', 'is-promotions', 'item.promotion.{isVotersChoice,isBallot}', function () {
    if (this['is-messages']) {
      return 'Recipients';
    } else if (this['is-promotions'] && (this.item.promotion.isVotersChoice || this.item.promotion.isBallot)) {
      return 'Votes';
    }
    return 'Entries';
  }),
  //endregion

  init() {
    this._super(...arguments);

    set(this, 'chosen-items', this['chosen-items'] || []);
  },

  //region Actions
  actions: {
    expandItem() {
      this.toggleProperty('isExpanded', true);
    },
    removeItem() {
      //This action can be used TWO WAYS. See below
      if (!this.disabled) {
        const action = this['item-removed'];
        const { item } = this;
        if (action && typeOf(action) === 'function') {
          //1) execute a passed in "call back" function
          action(item);
        } else {
          //2) plain old handle the action here and DON'T BUBBLE
          this['chosen-items'].removeObject(item);
        }
      }
    },
    chooseItem() {
      //This action can be used TWO WAYS. See below
      if (!this.disabled & !this.cantBeChosen) {
        const action = this['item-added'];
        const { item } = this;
        if (action && typeOf(action) === 'function') {
          //1) execute a passed in "call back" function
          action(item);
        } else {
          //2) plain old handle the action here and DON'T BUBBLE
          this['chosen-items'].addObject(item);
        }
      }
    },
  },
  //endregion
});
