import { getOwner } from '@ember/application';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import copyForm from 'partner/utils/api/copy-form';
import { tracked } from '@glimmer/tracking';
/**
 * New Organization Form Route
 * /o/:organization_id/op/new/:promotion_preset_id
 * This exists to show a loading screen while we're creating a new Organization Form.
 * @type {Ember.Route}
 */
export default class OrganizationsOrganizationFormsNewRoute extends Route {
  @service router;
  @service session;
  @service store;

  @tracked defaultFormForCopy = null;

  get formsIndexRoute() {
    return this.modelFor('organizations.organization.forms.index');
  }

  async beforeModel(transition) {
    const id = transition?.to?.queryParams?.formId ?? this.formsIndexRoute.defaultFormForCopyId;
    this.defaultFormForCopy = await this.store.find('form', id);
  }

  async model(params, transition) {
    const newForm = await copyForm(
      this.defaultFormForCopy,
      this.store,
      this.session,
      getOwner(this).lookup('adapter:application')
    );
    newForm.isJustCreated = true;
    newForm.allowEditingName = !transition?.to?.queryParams?.formId;

    return newForm;
  }

  /**
   * Transparently create the Form for the user, then transition it without leaving this page in the history.
   */
  redirect(model) {
    this.router.transitionTo('organizations.organization.forms.form', model.id);
  }
}
