/* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes, ember/no-observers, ember/no-mixins, ember/require-tagless-components, ember/no-component-lifecycle-hooks, ember/no-jquery, ember/no-incorrect-calls-with-inline-anonymous-functions, ember/closure-actions */
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';
import AudienceCommonController from 'partner/mixins/audience-common-controller';
import { get, set, action, computed } from '@ember/object';
import checkPolling from 'partner/utils/check-polling';

export default class IndexController extends AudienceCommonController {
  @service('enums')
  enums;

  @service('features')
  features;

  @service('snackbar')
  snackbar;

  @service('store')
  store;

  @controller('organizations.organization.data.audiences')
  audiencesController;

  @controller('organizations.organization')
  organizationController;

  //region Properties
  /**
   * Is the report being generated/downloaded?
   * @type {Boolean}
   */
  isDownloadingReport = false;

  /**
   * The audience used for generating a report download
   * @type {?Object}
   */
  selectedReportAudience = null;

  /**
   * Is the merge optin audiece modal visible
   * @type {?Boolean}
   */
  showMergeOptinAudienceModal = false;

  newOptin = null;

  selectedOptinMergeAudience = null;

  statusTypeId = 1;

  selectedOptinMergeAudience = [];

  //endregion

  @computed('model.audiences.length')
  get showAudienceOnboarding() {
    return !this.searchValue && this.model.audiences.length === 1 && !this.model.audiences.firstObject.latestCount;
  }

  //endregion

  @action
  selectName(audience) {
    set(this, 'newOptin', audience);
  }

  @action
  selectOptinToMerge(audience) {
    this.selectedOptinMergeAudience.addObject(audience);
    set(this, 'showMergeOptinAudienceModal', true);
  }

  @action
  closeMergeOptinAudienceModal() {
    set(this, 'selectedOptinMergeAudience', []);
    set(this, 'showMergeOptinAudienceModal', false);
  }

  @action
  addAudienceToMerge(audience) {
    const { selectedOptinMergeAudience } = this;
    selectedOptinMergeAudience.addObject(audience);
    set(this, 'newOptin', get(selectedOptinMergeAudience, 'firstObject'));
    if (
      (selectedOptinMergeAudience.length > 1 && get(audience, 'optinField.isInherited')) ||
      get(audience, 'optinField.hasThirdPartyOptin')
    ) {
      const temp = get(selectedOptinMergeAudience, 'firstObject');
      selectedOptinMergeAudience.removeObject(temp);
      selectedOptinMergeAudience.addObject(temp);
    }
  }

  @action
  mergeAudiences(mergedAudiences) {
    const masterAudience = get(mergedAudiences, 'firstObject');
    const removedAudience = get(mergedAudiences, 'lastObject');
    set(this, 'showMergeOptinAudienceModal', false);
    set(masterAudience, 'isCalculating', true);
    checkPolling(masterAudience, get(masterAudience, 'id'), this, false);
    set(this, 'selectedOptinMergeAudience', []);
    get(this, 'model.audiences').removeObject(removedAudience);
    this.snackbar.show('Two Opt-ins Merged!', 'OK');
  }

  @action
  removeAudienceFromMerge(audience) {
    this.selectedOptinMergeAudience.removeObject(audience);
    set(this, 'newOptin', get(this, 'selectedOptinMergeAudience.firstObject'));
  }
}
