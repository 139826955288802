import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import type { default as DefaultFormatService } from 'partner/services/default-format';

type paramsType = {
  full?: boolean;
  withTime?: boolean;
  withDay?: boolean;
};

export default class DefaultDateFormatHelper extends Helper {
  @service declare defaultFormat: DefaultFormatService;

  compute([hash]: [paramsType]) {
    return this.defaultFormat.getDateFormat(hash);
  }
}
