/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-classic-components, ember/no-mixins, ember/require-tagless-components, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { not, or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import PermittedComponent from 'partner/mixins/permitted-component';

export default class ModerateEntryCardComponent extends Component.extend(PermittedComponent) {
  //region Dependencies
  @service store;
  @service screen;
  @service enums;
  @service('deliberate-confirmation') deliberateConfirmation;
  //endregion

  //region Ember Hooks
  tagName = 'div';
  attributeBindings = [
    'matchup-entry.displayColumn:data-display-column',
    'matchup-entry.displayOrder:data-display-order',
    'matchup-entry.id:data-entry-id',
  ];
  classNameBindings = [
    'isMatchupEntryCard:ssMatchupEntry',
    'beingRemoved',
    'page-being-removed:being-removed',
    'is-being-edited:is-being-edited',
    'disabled:moderate-entry-card--disabled',
  ];
  didRender() {
    super.didRender(...arguments);
    const entryImage = this.element.querySelector('.image-container img');
    this.hasRendered = true;
    if (this['image-loaded'] && entryImage) {
      entryImage.onload = () => {
        this['image-loaded']();
      };
      // manually set the img src so that the "load" event gets fired
      entryImage.src = `//${this['dips-url'].value}/${this['matchup-entry'].photoUpload?.value}?width=600&cacheBust=${this.cacheBust}&rotate=${this.mediaItemTransform?.rotate}`;
    }
  }
  //endregion

  //region Properties
  isMatchupEntryCard = true;
  isModerationInterface = true;

  @tracked
  hasRendered = false;

  @tracked
  expandedCaption = false;

  @tracked
  beingRemoved = false;

  @tracked
  newStatus = '';

  @tracked
  isMediaFlyoutOpen = false;

  @tracked
  isAddingImageFromLibrary = false;

  @tracked
  isCurrentlyUploadingVideo = false;

  @tracked
  isCurrentlyUploadingImage = false;

  @tracked
  mediaItemTransform = null;

  @tracked
  mediaCaption = '';

  @tracked
  matchupEntryToRemove = null;

  @or('matchup-entry.entry.{hasDirtyEntryFieldValues,hasDirtyAttributes}', 'mediaItemTransform.hasDirtyAttributes')
  isAnythingDirty;

  get canBeEdited() {
    return this.edit && !this['is-being-edited'] && !this.disabled;
  }

  @computed('mediaItemTransform.rotate')
  get cacheBust() {
    return Math.random();
  }

  get showExpandCollapseIcon() {
    if (this.expandedCaption) {
      return true;
    }
    const currentElement = document.getElementById(this.elementId);
    if (currentElement) {
      const [captionElement] = [].slice.call(currentElement.getElementsByClassName('entry-caption'));
      return captionElement.scrollHeight > captionElement.clientHeight;
    }
    return false;
  }

  get sourceSummaryText() {
    switch (this['matchup-entry'].sourceType?.value) {
      case 'Instagram':
        return 'Posted on Instagram by';
      case 'YouTube':
        return 'Submitted from YouTube by';
      default:
        return 'Uploaded by';
    }
  }

  get onlyOneSocialLink() {
    return this['matchup-entry'].hasOnlyOneSocialField;
  }

  @not('matchup-entry.isFromInstagram')
  canEditTitle;

  @readOnly('matchup-entry.isUploadedPhoto')
  canRotate;

  get isSaveDisabled() {
    const title = this['matchup-entry'].mediaTitle?.value;
    return !this.isModerationInterface && (!title || isEmpty(title.trim()));
  }
  //endregion

  //region Methods
  /**
   * Creates an entry field value for a given entry field name.
   * Options include: 'Photo Upload', 'Media Title', 'Media Caption', 'Entry Source Type'
   * @type {EntryFieldValue}
   * @returns {Promise}
   */
  createEntryFieldValue(entryFieldName, value) {
    const { entry } = this['matchup-entry'];
    const { matchup } = this['matchup-entry'];
    const fieldId = this.enums.findWhere('ENTRY_FIELD', {
      name: entryFieldName,
    });

    return this.store.createRecord('entry-field-value', {
      entry,
      fieldId,
      matchup,
      value,
    });
  }
  /**
   * If there is not an entry field value for the particular type, this creates one.  Otherwise, it updates the value.
   * @param entryFieldValue
   * @param entryFieldName
   * @param value
   */
  setEntryFieldValue(entryFieldValue, entryFieldName, value) {
    if (!entryFieldValue) {
      this.createEntryFieldValue(entryFieldName, value);
    } else {
      entryFieldValue.value = value;
    }
  }
  /**
   * This erases an EntryFieldValue's value
   * @param entryFieldValue
   */
  unsetEntryFieldValue(entryFieldValue) {
    if (entryFieldValue) {
      entryFieldValue.value = null;
    }
  }
  /**
   * This adds a media item to a matchup entry
   * @param mediaItem
   * @param isVideo
   */
  addMediaItem(mediaItem, isVideo = false) {
    const mediaType = isVideo ? 'video' : 'photo';
    const uploadEntryFieldValue = this['matchup-entry']?.[`${mediaType}Upload`];
    const sourceTypeEntryFieldValue = this['matchup-entry'].sourceType;
    const sourceMediaTypeEntryFieldValue = this['matchup-entry'].sourceMediaType;
    const mediaItemId = mediaItem?.id;

    this.isMediaFlyoutOpen = false;

    this.setEntryFieldValue(uploadEntryFieldValue, isVideo ? 'Video Entry Upload' : 'Photo Upload', mediaItemId);
    this.setEntryFieldValue(sourceTypeEntryFieldValue, 'Entry Source Type', 'SecondStreet');
    this.setEntryFieldValue(sourceMediaTypeEntryFieldValue, 'Entry Source Media Type', isVideo ? 'video' : 'image');
  }
  /**
   * This adds a YouTube video to a matchup entry
   * @param youTubeVideoId
   * @param youTubeVideoUrl
   */
  addYouTube(youTubeVideoId, youTubeVideoUrl) {
    const sourceTypeEntryFieldValue = this['matchup-entry'].sourceType;
    const sourceMediaTypeEntryFieldValue = this['matchup-entry'].sourceMediaType;
    const sourcePostIdEntryFieldValue = this['matchup-entry'].sourcePostId;
    const videoUrlFieldValue = this['matchup-entry'].videoUrl;

    this.setEntryFieldValue(sourceTypeEntryFieldValue, 'Entry Source Type', 'YouTube');
    this.setEntryFieldValue(sourceMediaTypeEntryFieldValue, 'Entry Source Media Type', 'video');
    this.setEntryFieldValue(sourcePostIdEntryFieldValue, 'Entry Source Post Id', youTubeVideoId);
    this.setEntryFieldValue(videoUrlFieldValue, 'Entry Source Video Url', youTubeVideoUrl);
  }
  //endregion

  //region Actions
  @action
  addImageMediaItem(mediaItem) {
    this.addMediaItem(mediaItem);
  }

  @action
  addVideoMediaItem(mediaItem) {
    this.addMediaItem(mediaItem, true);
  }

  @action
  startUpload(type) {
    this[`isCurrentlyUploading${type}`] = true;
  }

  @action
  endUpload(type) {
    this[`isCurrentlyUploading${type}`] = false;
  }

  @action
  editMediaTitle() {
    if (!this['matchup-entry'].mediaTitle) {
      this.createEntryFieldValue('Media Title');
    }
  }

  @action
  editMediaCaption() {
    if (!this['matchup-entry'].mediaCaption) {
      this.createEntryFieldValue('Media Caption');
    }
  }

  @action
  editContactInfo(property, fieldType) {
    if (!this['matchup-entry'][property]) {
      this.createEntryFieldValue(fieldType);
    }
  }

  @action
  removeMediaItem() {
    this.unsetEntryFieldValue(this['matchup-entry'].videoUpload);
    this.unsetEntryFieldValue(this['matchup-entry'].photoUpload);
    this.unsetEntryFieldValue(this['matchup-entry'].sourceMediaType);
    this.unsetEntryFieldValue(this['matchup-entry'].sourceType);
    this.unsetEntryFieldValue(this['matchup-entry'].sourcePostId);
  }

  @action
  async changeStatus(matchupEntry, newStatusTypeId) {
    this.beingRemoved = true;
    const user = matchupEntry.entry.organizationPromotionUser;
    if (newStatusTypeId === this['approved-id'] && user?.isInActive) {
      const confirmed = await this.deliberateConfirmation.show(
        'The person that submitted this entry is disqualified. Approving this entry will reactivate the person. Do you want to proceed ?'
      );

      if (confirmed) {
        user.statusTypeId = this.enums.findWhere('STATUS_TYPE', { name: 'Active' }, 'id');
        await user.save();
      } else {
        return;
      }
    }
    this.newStatus = newStatusTypeId === this['approved-id'] ? 'approved' : 'rejected';
    await this['change-status'](matchupEntry, newStatusTypeId);
  }

  @action
  toggleCaptionHeight() {
    this.expandedCaption = !this.expandedCaption;
  }

  @action
  rotate() {
    let { mediaItemTransform } = this;
    if (!mediaItemTransform || !mediaItemTransform.isNew) {
      //create a new transform if there isn't one or if the image has already been edited and saved
      //the mediaItemTransform endpoint only supports POST
      mediaItemTransform = this.store.createRecord('media-item-transform', {
        mediaItemId: this['matchup-entry'].photoUpload?.value,
      });
      this.mediaItemTransform = mediaItemTransform;
    }

    const currentRotation = mediaItemTransform.rotate || 0;
    let nextRotation = null;
    switch (currentRotation) {
      case 0:
        nextRotation = 90;
        break;
      case 90:
        nextRotation = 180;
        break;
      case 180:
        nextRotation = 270;
        break;
      case 270:
        nextRotation = 0;
        break;
    }
    if (nextRotation === 0) {
      //rotating back to "normal", no longer need an object to represent the rotation
      this.mediaItemTransform = null;
    } else {
      mediaItemTransform.rotate = nextRotation;
    }
  }

  @action
  addYouTubeVideo(youTubeVideoId, youTubeVideoUrl) {
    this.addYouTube(youTubeVideoId, youTubeVideoUrl);
  }

  @action
  confirmRemove() {
    this.remove(this.matchupEntryToRemove);
    this.matchupEntryToRemove = null;
  }

  @action
  setMatchupEntryName({ target: { value } }) {
    this['matchup-entry'].mediaTitle.value = value;
    this['matchup-entry'].entry.name = value;
  }
  //endregion
}
