import ApplicationSerializer from './application';
import { guidFor } from '@ember/object/internals';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

export default class TaggableTokenSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    entityInterestTags: { deserialize: 'records', serialize: false },
  };

  normalize(modelClass, resourceHash = {}) {
    resourceHash['id'] = guidFor(resourceHash);
    resourceHash['entity_id'] = resourceHash['item_id'];
    return super.normalize(...arguments);
  }
}
