/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import type { SetupQuizModel } from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/quiz';
import type CurrentService from 'partner/services/current';

export default class SetupQuizController extends Controller {
  @service declare current: CurrentService;

  declare model: SetupQuizModel;

  @computed('model.outcomes.@each.isNew')
  get outcomesStatus() {
    return this.model.outcomes.rejectBy('isNew').get('length') > 0 ? 'bestPractice' : 'incomplete';
  }

  @computed('current.promotion.isPoll', 'model.questions.@each.{isNew,hasPollBestPracticeQuestionOptionsCount}')
  get questionsStatus() {
    if (this.current.promotion.isPoll) {
      // at least one question and all questions have at least two answers
      return isEmpty(this.model.questions.filterBy('hasPollBestPracticeQuestionOptionsCount', false))
        ? 'bestPractice'
        : 'incomplete';
    }

    return this.model.questions.rejectBy('isNew').get('length') > 0 ? 'bestPractice' : 'incomplete';
  }
}

declare module '@ember/controller' {
  interface Registry {
    'organizations.organization.organization-promotions.organization-promotion.setup.quiz': SetupQuizController;
  }
}
