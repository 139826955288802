/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-mixins */
import Controller from '@ember/controller';
import { action, computed, set, setProperties } from '@ember/object';
import { alias, or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import HasCategoricalCharts from 'partner/mixins/has-categorical-charts';
import HasEventCharts from 'partner/mixins/has-event-charts';
import NeedsOrganizationPromotionController from 'partner/mixins/needs-organization-promotion-controller';
import { emptyTimeSeriesData } from 'partner/utils/charts';
import { firstByProperty } from 'partner/utils/computed';
import { wait } from 'partner/utils/promises';
import RSVP from 'rsvp';
import { alphabeticalByProperty } from 'secondstreet-common/utils/sorting';
import { endpoint } from 'secondstreet-common/utils/url';

export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionDashboardQuizController extends Controller.extend(
  HasCategoricalCharts,
  HasEventCharts,
  NeedsOrganizationPromotionController
) {
  //region Dependencies
  @service permissions;
  @service snackbar;
  @service settings;
  @service current;
  @service store;
  @service adMetrics;
  @service session;
  //endregion

  //region Properties
  @tracked
  currentSweepsWinnerPage = 1;

  @tracked
  selectedGroup = null;

  @tracked
  uploadedFile = null;

  @tracked
  uploadErrors = null;

  @tracked
  isImportingEntries = false;

  @tracked
  winnersCardTransition = 'toLeft';

  @tracked
  isAnythingSaving = false;

  @tracked
  isShowingPublishAllWinnersModal = false;

  @tracked adStats = [];
  //endregion

  //region Computed Properties
  @alias('model.organizationPromotion.promotion.isNominationAndVotingBallot')
  isNomination;

  @readOnly('organizationPromotion.kpi')
  totalEntries;

  @readOnly('model.organizationPromotion.numEligibleUsers')
  numEligibleUsers;

  @readOnly('model.organizationPromotion.numEligibleVotes')
  numEligibleVotes;

  @readOnly('model.organizationPromotion.numUniquePeopleWithVotes')
  numUniqueVotes;

  @readOnly('model.organizationPromotion.numEligibleEntries')
  numEligibleEntries;

  @readOnly('model.organizationPromotion.numUniquePeopleWithEnteries')
  numUniqueEntries;

  @readOnly('model.sweepstakesWinners.length')
  numSweepsWinners;

  @readOnly('model.matchups.firstObject.isWinnersPublished')
  isWinnersPublished;

  @readOnly('model.matchups.firstObject.startDate')
  contestStartDate;

  @readOnly('model.matchups.firstObject.endDate')
  contestEndDate;

  @readOnly('model.matchups.firstObject.selectionStartDate')
  contestSelectionStartDate;

  @readOnly('model.matchups.firstObject.selectionEndDate')
  contestSelectionEndDate;

  @or('selectedGroup.name', 'model.matchupGroups.firstObject.name')
  selectedGroupName;

  @or('selectedGroup.id', 'model.matchupGroups.firstObject.id')
  selectedGroupId;

  get emptyTimeSeriesData() {
    return emptyTimeSeriesData(10)();
  }

  get hasGroups() {
    return this.model.matchupGroups.length > 1 && this.contestSelectionStartDate < new Date().getTime();
  }

  @computed('model.matchups', 'selectedGroupId')
  get selectedMatchups() {
    return this.model.matchups.filterBy('matchupGroup.id', this.selectedGroupId);
  }

  @computed('selectedMatchups.@each.isWinnersPublished')
  get areSelectedGroupWinnersPublished() {
    return this.selectedMatchups.isEvery('isWinnersPublished');
  }

  @computed(
    'areSelectedGroupWinnersPublished',
    'hasGroups',
    'selectedGroupName',
    'publishSelectedMatchupWinnersTask.isRunning',
    'unpublishSelectedMatchupWinnersTask.isRunning'
  )
  get publishGroupButtonText() {
    if (this.publishSelectedMatchupWinnersTask.isRunning) return 'Publishing';
    if (this.unpublishSelectedMatchupWinnersTask.isRunning) return 'Unpublishing';

    const groupName = this.hasGroups ? `${this.selectedGroupName} ` : '';
    return `${this.areSelectedGroupWinnersPublished ? 'Unpublish' : 'Publish'} ${groupName}Winners`;
  }

  @computed('model.organizationPromotion.promotion.isVotingBallot', 'isImportingNominations')
  get sampleImportLink() {
    if (this.isImportingNominations) {
      return '/media/final/resources/importfiles/SecondStreet_NominationAndVotingBallot_ExternalNominations_ImportTemplate.xlsx';
    }

    return `/media/final/resources/importfiles/SecondStreet_${
      this.model.organizationPromotion.promotion.isVotingBallot ? 'Voting' : 'NominationAndVoting'
    }Ballot_ExternalVotes_ImportTemplate.xlsx`;
  }

  @computed('model.organizationPromotion.promotion.isVotingBallot', 'isImportingNominations')
  get sampleImportName() {
    if (this.isImportingNominations) {
      return 'Second Street Nomination And Voting Ballot External Nominations Import Template';
    }

    return this.model.organizationPromotion.promotion.isVotingBallot
      ? 'Second Street Voting Ballot External Votes Import Template'
      : 'Second Street Nomination And Voting Ballot External Votes Import Template';
  }

  @firstByProperty('name', 'Entry', 'model.eventCharts')
  nominationsChart;

  /**
   * First Matchup: returns the first overall matchup, taking into account groups and resorting.
   * @returns {Matchup}
   */
  get firstMatchup() {
    const { matchupGroups } = this.model;
    const { matchups } = this.model;

    if (matchupGroups.length) {
      return matchups.findBy('matchupGroup.id', matchupGroups[0].id);
    }
    return matchups[0];
  }

  get disableImportActions() {
    return !!this.uploadedFile || this.isImportingEntries;
  }

  @action
  async setWinnersPublishedStatus(selectedMatchups, isWinnersPublished) {
    this.isAnythingSaving = true;
    await RSVP.all([
      selectedMatchups.map(matchup => {
        if (matchup.isWinnersPublished !== isWinnersPublished) {
          matchup.isWinnersPublished = isWinnersPublished;
          return matchup.save();
        }
        return matchup;
      }),
      wait(1500), // Delay the saving animation for a second in case it goes too quickly
    ]);
    this.isAnythingSaving = false;
  }

  @action
  startImportingEntries(type) {
    set(this, `isImporting${type}`, true);
  }

  @action
  stopImportingEntries() {
    setProperties(this, {
      isImportingVotes: false,
      isImportingNominations: false,
      uploadErrors: null,
      uploadedFile: null,
    });
  }

  @action
  uploadStarted() {
    this.uploadErrors = null;
    this.isImportingEntries = true;
  }

  @action
  fileUploaded(importedFile) {
    this.uploadedFile = importedFile;
    this.isImportingEntries = false;
  }

  @action
  showErrors(errors) {
    if (errors) {
      this.uploadErrors = errors;
    } else {
      this.uploadErrors = [
        {
          message:
            'There was a problem with processing your upload. Please try saving your file as a CSV and uploading again.',
        },
      ];
    }
    this.isImportingEntries = false;
  }

  @action
  getSortedMatchups(matchups) {
    return this.settings.getValueFor('category_sort_criteria') == '1'
      ? matchups.toArray().sort(alphabeticalByProperty('name'))
      : matchups.sortBy('displayOrder');
  }
  //endregion

  publishAllWinnersTask = task({ drop: true }, async () => {
    const gameId = await this.getGameId();
    await this.publishWinners({ gameId, publishWinners: true });
  });

  publishSelectedMatchupWinnersTask = task({ drop: true }, async () => {
    const gameId = this.selectedGroupId ? null : await this.getGameId();
    await this.publishWinners({ publishWinners: true, gameId });
  });

  unpublishSelectedMatchupWinnersTask = task({ drop: true }, async () => {
    const gameId = this.selectedGroupId ? null : await this.getGameId();
    await this.publishWinners({ publishWinners: false, gameId });
  });

  loadAdStatsTask = task({ restartable: true }, async () => {
    this.adStats = await this.adMetrics.fetchAdMetrics();
  });

  downloadAdsReportTask = task({ drop: true }, async () => {
    await this.adMetrics.downloadAdMetricsCSV(this.adStats);
  });

  async getGameId() {
    return (await this.store.query('game', this.current.toParams())).toArray().findBy('isPrimary')?.id;
  }

  async publishWinners({ gameId = null, publishWinners = true } = {}) {
    const groupName = this.hasGroups ? `${this.selectedGroupName} ` : '';
    const action = publishWinners ? 'published' : 'unpublished';
    const message = gameId
      ? `All winners have been ${action}.`
      : publishWinners
      ? `All ${groupName}winners have been ${action}.`
      : `All ${groupName}winners have been ${action}.`;
    try {
      await this.session.request(endpoint('publish_winners'), {
        type: 'POST',
        data: {
          publish_winners: [
            {
              game_id: gameId,
              matchup_group_id: gameId ? null : this.selectedGroupId,
              publish_winners: publishWinners,
            },
          ],
        },
      });
      (gameId ? this.model.matchups : this.selectedMatchups).forEach(matchup =>
        matchup.set('isWinnersPublished', publishWinners)
      );
      this.snackbar.show(message);
    } catch (e) {
      this.snackbar.exception(e);
    } finally {
      this.isShowingPublishAllWinnersModal = false;
    }
  }
}
