/* eslint-disable ember/no-mixins */
import Route from '@ember/routing/route';
import AppContextRoute from 'partner/mixins/app-context-route';

export default class extends Route.extend(AppContextRoute) {
  model() {
    return {
      organization: this.modelFor('organizations.organization').organization,
    };
  }
}
