/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { not } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  //region Ember Hooks
  tagName: '',
  //endregion

  //region Properties
  isExpanded: false,
  store: service(),
  //endregion

  //region Computed Properties
  title: computed('item.{name,latestCount}', 'is-organization', function () {
    return this['is-organization']
      ? get(this, 'item.name')
      : `${get(this, 'item.name')} (${get(this, 'item.latestCount')} people)`;
  }),
  tierClass: computed('item.tier', 'tier', function () {
    const tier = get(this, 'item.tier') || this.tier;
    let className = null;
    // Because these orgs are already under a org, the tiers are off by one
    if (tier === 2) {
      className = 'ssTierOne';
    } else if (tier === 3) {
      className = 'ssTierTwo';
    } else if (tier === 4) {
      className = 'ssTierThree';
    } else if (tier === 5) {
      className = 'ssTierFour';
    } else if (tier >= 6) {
      className = 'ssTierFive';
    }
    return className;
  }),
  isExpandable: readOnly('is-organization'),
  canBeChosen: not('is-organization'),
  isChosen: computed('item', 'chosen-items.[]', function () {
    if (!this['chosen-items']) {
      return false;
    }
    return this['chosen-items'].includes(this.item);
  }),
  //endregion

  //region Methods
  async loadAudiences(organization) {
    set(this, 'loading', true);

    const audiences = await this.store.query('audience', { organizationId: get(organization, 'id') });

    set(this, 'audiences', audiences.rejectBy('isThirdPartyAudience'));
    set(this, 'loading', false);
  },
  //endregion

  //region Actions
  actions: {
    expandItem() {
      this.toggleProperty('isExpanded');
      if (this.isExpanded) {
        this.loadAudiences(this.item);
      }
    },
    removeItem() {
      this['item-removed'](this.item);
    },
    chooseItem() {
      if (!this.disabled && this.canBeChosen) {
        this['item-added'](this.item);
      }
    },
  },
  //endregion
});
