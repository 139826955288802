/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/use-ember-get-and-set */ // FIXME
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import AppContextRoute from 'partner/mixins/app-context-route';

/**
 * Organization Data
 * /o/:organization_id/data
 * @type {Ember.Route}
 * @mixes AppContextRoute
 */
export default Route.extend(AppContextRoute, {
  model() {
    return this.modelFor('organizations.organization');
  },

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('organization', get(model, 'organization'));
    controller.set('visibleWhen', 'organizations.organization.data');
  },
});
