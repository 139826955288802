/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import PromotionTypeName from 'partner/mixins/promotion-type-name';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;

export default BaseModel.extend(PromotionTypeName, {
  //region Properties
  promotionName: attr('string'),
  promotionId: attr('number'),
  promotionTypeId: attr('number'),
  promotionSubTypeId: attr('number'),
  organizationId: attr('number'),
  organizationName: attr('string'),
  //endregion
});
