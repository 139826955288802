/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;

export default BaseModel.extend({
  //region Ember Hooks
  enums: service(),
  //endregion

  //region Properties
  entityType: attr('string'),
  entityTypeId: attr('number'),
  permissionTypeId: attr('number'),
  //endregion

  //region Computed Properties
  isView: computed('permissionTypeId', function () {
    const id = this.enums.findWhere('PERMISSION_TYPE', { name: 'View' });
    return id === parseInt(this.permissionTypeId, 10);
  }),
  isAdminister: computed('permissionTypeId', function () {
    const id = this.enums.findWhere('PERMISSION_TYPE', {
      name: 'Administer',
    });
    return id === parseInt(this.permissionTypeId, 10);
  }),
  //endregion
});
