/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { or } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import enums from 'ember-cli-ss-enums/services/enums';

export default Mixin.create({
  /**
   * If promotions has a subType use it, otherwise return promo type name
   * Used to add a class that is specific to the type
   */
  promotionClassName: or('promotionSubTypeName', 'promotionTypeName'),
  promotionTypeName: enums.computed('name', 'promotionTypeId'),
  promotionSubTypeName: enums.computed('name', 'promotionSubTypeId'),
});
