/* eslint-disable ember/no-mixins, ember/no-classic-classes */
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AdminRoute from 'partner/mixins/admin-route';
import AppContextRoute from 'partner/mixins/app-context-route';

export default class OrganizationsOrganizationFeatureFlagsRoute extends Route.extend(AppContextRoute, AdminRoute) {
  @service('store') store;

  //region Ember Hooks
  async model() {
    const [organizationAttributeTypes, organizations, organizationAttributes] = await Promise.all([
      this.store.query('organizationAttributeType', {}),
      this.store.query('organization', { FlattenList: true }),
      this.store.query('organizationAttribute', { GetAllOrganizationAttributes: true }),
    ]);

    return {
      organizationAttributeTypes: organizationAttributeTypes.sortBy('name'),
      organizations,
      organizationAttributes: organizationAttributes.toArray(),
      rootOrganization: organizations.findBy('id', '1'),
    };
  }
  //endregion

  //region Actions
  @action
  refreshModel() {
    this.refresh();
  }
  //endregion
}
