import { isBlank } from '@ember/utils';
import request from 'ember-ajax/request';
import camelCase from 'lodash/camelCase';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import ENV from 'partner/config/environment';

/**
 * Convertes keys from snake_cased to camelCased
 * [{ first_name: "Billy", last_name: "Bob" }] to [{ firstName: "Billy", lastName: "Bob" }]
 * @param array
 */
const deserialize = array => array.map(obj => fromPairs(toPairs(obj).map(([key, value]) => [camelCase(key), value])));

export default async (rssFeedUrl = '', useFeedDisplayOrder = false) => {
  if (isBlank(rssFeedUrl)) {
    return Promise.resolve([]);
  }
  const rssCall = async feedUrl => {
    feedUrl = feedUrl.startsWith('http') ? feedUrl : `http://${feedUrl}`;
    const encodedUri = window.encodeURIComponent(feedUrl);
    const headers = { 'X-Api-Key': ENV.APP.API_KEY };
    const namespaces = isBlank(ENV.APP.API_NAMESPACE) ? '' : `/${ENV.APP.API_NAMESPACE}`;
    const url = `${ENV.APP.API_HOST || ''}${namespaces}/rss_feed_contents?rssFeedUrl=${encodedUri}${
      useFeedDisplayOrder ? '&useFeedDisplayOrder=true' : ''
    }`;
    const response = await request(url, headers);
    const rssItems = deserialize(response.rss_feed_contents[0].rss_items);
    return useFeedDisplayOrder ? rssItems : rssItems.sortBy('publishDate').reverse();
  };
  try {
    return await rssCall(rssFeedUrl);
  } catch (e) {
    console.error(e);
  }
  try {
    let alteredRssFeedUrl;
    if (!(rssFeedUrl.endsWith('/feed') || rssFeedUrl.endsWith('/feed/'))) {
      const suffix = rssFeedUrl.endsWith('/') ? 'feed/' : '/feed/';
      alteredRssFeedUrl = `${rssFeedUrl}${suffix}`;
    }
    return await rssCall(alteredRssFeedUrl);
  } catch (e) {
    console.error(e);
    return Promise.resolve([]);
  }
};
