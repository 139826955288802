/* global Redactor */

Redactor.add('plugin', 'indent', {
  translations: {
    en: {
      indent: {
        indent: 'Indent',
        outdent: 'Outdent',
      },
    },
  },
  defaults: {
    toolbar: false,
    context: false,
    indentSize: 20,
    maxIndentSize: 300,
  },
  start() {
    const indentButton = {
      title: '## indent.indent ##',
      icon: '<i class="material-icons text-lg text-black">format_indent_increase</i>',
      command: 'indent.indent',
      position: { after: 'move-down' },
    };
    const outdentButton = {
      title: '## indent.outdent ##',
      icon: '<i class="material-icons text-lg text-black">format_indent_decrease</i>',
      command: 'indent.outdent',
      position: { after: 'indent' },
    };

    this.app.control.add('indent', indentButton);
    this.app.control.add('outdent', outdentButton);

    if (this.opts.is('indent.toolbar')) {
      this.app.toolbar.add('indent', indentButton);
      this.app.toolbar.add('outdent', outdentButton);
    }

    if (this.opts.is('indent.context')) {
      this.app.context.add('indent', indentButton);
      this.app.context.add('outdent', outdentButton);
    }
  },
  getIndentLevel($block) {
    const style = $block.getStyle();
    const level = 0;

    if (!style || !style['margin-left']) return level;

    return parseInt(style['margin-left']);
  },
  indent() {
    const $block = this.app.block.get();
    const indentLevel = this.getIndentLevel($block);
    const indentSize = this.opts.get('indent.indentSize');
    const maxIndent = this.opts.get('indent.maxIndentSize');

    if (indentLevel === maxIndent) return;

    if (indentLevel === 0) {
      $block.setStyle({ 'margin-left': `${indentSize}px` });
    } else {
      $block.setStyle({ 'margin-left': `${indentLevel + indentSize}px` });
    }

    this.app.control.updatePosition();
    this.app.context.updatePosition();
    this.app.dropdown.updatePosition();
  },
  outdent() {
    const $block = this.app.block.get();
    const indentSize = this.opts.get('indent.indentSize');
    let indentLevel = this.getIndentLevel($block);

    indentLevel -= indentSize;

    if (indentLevel <= 0) {
      $block.setStyle({ 'margin-left': '' });
    } else {
      $block.setStyle({ 'margin-left': `${indentLevel}px` });
    }

    this.app.control.updatePosition();
    this.app.context.updatePosition();
    this.app.dropdown.updatePosition();
  },
});
