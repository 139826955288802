/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { inject as controller } from '@ember/controller';
import { computed, get, set } from '@ember/object';
import { alias, or } from '@ember/object/computed';
import FormController from 'partner/controllers/form-controller';
import SetupExtraChances from 'partner/mixins/setup-extra-chances';
import isAnyPath from 'partner/utils/is-any-path';
import { inject as service } from '@ember/service';

export default FormController.extend(SetupExtraChances, {
  //region Ember Hooks
  setup: controller('organizations/organization/organization-promotions/organization-promotion/setup'),

  current: service(),
  //endregion

  //region Computed Properties
  promotion: alias('model.organizationPromotion.promotion'),
  isAnythingDirty: or(
    'model.sweepstakes.hasDirtyAttributes',
    'model.minimumVotes.hasDirtyAttributes',
    'model.minimumNominations.hasDirtyAttributes',
    'isExtraChanceRuleDirty',
    'areExtraChanceFieldsDirty',
    'setup.hasDirtyAttributes',
    'didSomethingChange'
  ),
  isAnythingSaving: isAnyPath('isSaving', [
    'model.sweepstakes',
    'model.registrationEnabledSetting',
    'model.minimumVotes',
    'model.minimumNominations',
    'setup',
    'model.extraChancesForm',
  ]),
  stepOneLabel: computed('promotion.promotionType', function () {
    let action = 'registers';
    if (get(this, 'promotion.isUGCVoting')) {
      action = 'enters or votes';
    } else if (get(this, 'promotion.isBallot')) {
      action = 'participates';
    }
    return `Do you want everyone who ${action} to have one chance at winning a prize in a random drawing?`;
  }),
  //endregion

  //region Methods
  async save() {
    if (this.form) {
      await this.form.save();
    } else {
      await this.extraChancesForm.save();
    }
    set(this, 'didSomethingChange', false);
  },
  //endregion

  //region Actions
  actions: {
    toggleEditingName() {
      this.toggleProperty('editing-name');
    },
    toggleEditingGlobalOptinId(globalOptinId) {
      set(this, 'optin-id', globalOptinId || null);
    },
    setSweepsParticipationDefaults() {
      if (this.current.promotion.isNominationAndVotingBallot) {
        if (this.model.minimumNominations.isInherited) this.model.minimumNominations.value = 1;
      }
      if (this.model.minimumVotes.isInherited) this.model.minimumVotes.value = 1;
    },
    rollbackSweepsParticipationDefaults() {
      if (this.current.promotion.isNominationAndVotingBallot) {
        this.model.minimumNominations.rollbackAttributes();
      }
      this.model.minimumVotes.rollbackAttributes();
    },

    setMinimumNominationsError(event) {
      set(this, 'minimumNominationsError', event.target.value < 1 || event.target.value > 100);
    },
    setMinimumVotesError(event) {
      set(this, 'minimumVotesError', event.target.value < 1 || event.target.value > 100);
    },
  },
  //endregion
});
