/* eslint-disable ember/no-mixins */
import Route from '@ember/routing/route';
import PromotionTypeRoute from 'partner/mixins/promotion-type-route';

/**
 * Moderate Index Route
 * /o/:organization_id/op/:organization_promotion_id/dashboard/moderate
 * @type {Ember.Route}
 * @mixes PromotionTypeRoute
 */
export default Route.extend(PromotionTypeRoute);
