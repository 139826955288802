/* eslint-disable ember/no-mixins */
import { setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import PromotionTypeRoute from 'partner/mixins/promotion-type-route';
import { dashboard } from 'partner/utils/model-hooks';

export default class extends Route.extend(PromotionTypeRoute) {
  @service('session') session;
  @service('store') store;
  @service('settings') settings;

  model(...args) {
    return dashboard('VotingBracket').call(this, ...args);
  }

  async afterModel(model) {
    const organizationPromotionId = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion'
    ).organizationPromotion.id;

    const matchupEntries = await this.store.query('matchup-entry', {
      organizationPromotionId,
      filterByOrganizationPromotion: true,
      pageSize: 10000,
      pageIndex: 1,
    });
    const matchupEntryVotes = await this.store.query('matchup-entry-vote', {
      organizationPromotionId,
    });

    model.matchupEntryVotes = matchupEntryVotes.toArray();
    model.matchupEntries = matchupEntries;
  }

  resetController(controller) {
    setProperties(controller, {
      selectedRound: null,
      limitData: true,
    });
  }
}
