/* eslint-disable ember/no-computed-properties-in-native-classes */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, computed, get } from '@ember/object';
import { mapBy } from '@ember/object/computed';
import { debounce } from '@ember/runloop';

export default class OrganizationPromotionsFeedComponent extends Component {
  @tracked isModalOpen = false;
  @tracked searchInput = '';
  @tracked isLoading = false;

  @computed('args.promotionFeedItems.[]')
  get promotionFeedItems() {
    return this.args.promotionFeedItems || [];
  }

  @computed('args.promotionSearchResults.[]')
  get promotionSearchResults() {
    return this.args.promotionSearchResults || [];
  }

  @mapBy('activePromotionFeedItems', 'organizationPromotion')
  activePromotionsInFeed;

  @mapBy('upcomingPromotionFeedItems', 'organizationPromotion')
  upcomingPromotionsInFeed;

  @computed('promotionFeedItems.@each.{displayOrder,organizationPromotion}')
  get activePromotionFeedItems() {
    const now = new Date();

    return this.promotionFeedItems
      .filter(
        item =>
          get(item.organizationPromotion, 'startDate') < now ||
          get(item.organizationPromotion, 'promotion.hasNoStartDate')
      )
      .sortBy('displayOrder');
  }

  @computed('promotionFeedItems.@each.{displayOrder,organizationPromotion}')
  get upcomingPromotionFeedItems() {
    const now = new Date();

    return this.promotionFeedItems
      .filter(item => get(item.organizationPromotion, 'startDate') > now)
      .sortBy('organizationPromotion.startDate');
  }

  @computed('promotionSearchResults.@each.id', 'promotionFeedItems.@each.organizationPromotion')
  get formattedSearchResults() {
    const promotionsAlreadyInFeedIds = this.promotionFeedItems.mapBy('organizationPromotion.id');

    let filteredSearchResults = this.promotionSearchResults.reject(result =>
      promotionsAlreadyInFeedIds.includes(result.id)
    );

    // Default state, only show 5 items
    if (this.searchInput.length === 0) {
      filteredSearchResults = filteredSearchResults?.slice(0, 5);
    }

    return filteredSearchResults;
  }

  async _search(event) {
    this.searchInput = event.target.value;
    this.isLoading = true;

    await this.args.searchPromotions(this.searchInput);

    this.isLoading = false;
  }

  @action
  async reorderList(list) {
    this.isLoading = true;
    await this.args.reorderPromotionsFeedItems(list);
    this.isLoading = false;
  }

  @action
  search(event) {
    debounce(this, this._search, event, 250);
  }

  @action
  openLink(siteLink) {
    window.open(siteLink);
  }

  @action
  resetModalState() {
    this.isModalOpen = false;
    this.searchInput = '';
    this.args.searchPromotions(this.searchInput);
  }
}
