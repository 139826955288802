/* eslint-disable ember/no-mixins, ember/no-classic-components, ember/no-computed-properties-in-native-classes */
import Component from '@ember/component';
import { computed } from '@ember/object';
import PermittedComponent from 'partner/mixins/permitted-component';

/**
 * To be used inside of a @see Promotion.PermissionsBlock.  This component will only be visible
 * if the block component has VIEW rights, but not ADMIN.
 *
 * Example:
 *  <SsPermissionsViewOnly}}
 *    //html for users with view only access (non admins)
 *  </SsPermissionsViewOnly}}
 *
 *  <SsPermissionsAdminister>
 *    //html only for admins
 *  </SsPermissionsAdminister>
 *
 * @see Promotion.PermittedComponent
 * @type {Ember.Component}
 */
export default class SsPermissionsViewOnly extends Component.extend(PermittedComponent) {
  @computed('view', 'administer')
  get viewOnly() {
    return this.view && !this.administer;
  }
}
