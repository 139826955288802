/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import OrganizationPromotionListController from 'partner/mixins/organization-promotion-list-controller';

/**
 * OrganizationPromotions Index Controller
 * /o/:organization_id/events
 * @type {Ember.Controller}
 * @mixes OrganizationPromotionListController
 */
export default Controller.extend(OrganizationPromotionListController, {
  queryParams: ['isCreating'],
  isCreating: false,
  promotionTypeCategoryId: 3,
});
