/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { oneWay } from '@ember/object/computed';
import { isBlank } from '@ember/utils';
import $ from 'jquery';

export default Component.extend({
  // region properties
  _searchTermEmail: oneWay('searchTermEmail'),
  _searchTermName: oneWay('searchTermName'),
  _searchTermOrganization: oneWay('searchTermOrganization'),
  _searchTermCreateUsers: oneWay('searchTermCreateUsers'),
  // end region

  // region computed
  isValidAdvancedSearch: computed(
    '_searchTermEmail',
    '_searchTermName',
    '_searchTermOrganization',
    '_searchTermCreateUsers',
    function () {
      return (
        !isBlank(this._searchTermEmail) ||
        !isBlank(this._searchTermName) ||
        !isBlank(this._searchTermOrganization) ||
        this._searchTermCreateUsers
      );
    }
  ),
  // end region

  //region Ember Hooks
  bodyListener(event) {
    if (
      !$(event.target).parents(`#${this.elementId}`).length &&
      !$(event.target).hasClass('check-icon') &&
      !$(event.target).hasClass('ssAdvancedUsersSearchIcon')
    ) {
      this.closeAdvancedSearch();
    }
  },
  didInsertElement() {
    this._super(...arguments);
    $('body').on('click.advancedsearch', event => this.bodyListener(event));
  },
  willDestroyElement() {
    this._super(...arguments);
    $('body').off('click.advancedsearch');
  },
  //endregion

  //region actions
  actions: {
    _searchTermCreateUsersChanged(newValue) {
      set(this, '_searchTermCreateUsers', newValue);
    },
    _searchAdvanced() {
      this.searchTermEmailChanged(this._searchTermEmail);
      this.searchTermNameChanged(this._searchTermName);
      this.searchTermOrganizationChanged(this._searchTermOrganization);
      this.searchTermCreateUsersChanged(this._searchTermCreateUsers);
      this.searchAdvanced();
    },
  },
  //endregion
});
