/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import BaseRoute from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/quiz/questions/base';
import RSVP from 'rsvp';

/**
 * Questions New Route
 * /o/:organization_id/op/:organization_promotion_id/setup/quiz/questions/new
 * @type {Ember.Route}
 */
export default BaseRoute.extend({
  router: service(),
  store: service(),

  //region Ember Hooks
  model() {
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');
    const organizationPromotionId = get(
      this.modelFor('organizations.organization.organization-promotions.organization-promotion'),
      'organizationPromotion.id'
    );
    const questions = get(
      this.modelFor('organizations.organization.organization-promotions.organization-promotion.setup.quiz'),
      'questions'
    );

    return RSVP.hash({
      question: this.createQuestion(),
      outcomes: this.store.query('outcome', {
        organizationId,
        organizationPromotionId,
      }),
      questions,
    });
  },
  //endregion

  //region Methods
  createQuestion() {
    const question = this.store.createRecord('question');
    set(
      question,
      'promotion',
      get(
        this.modelFor('organizations.organization.organization-promotions.organization-promotion'),
        'organizationPromotion.promotion'
      )
    );
    const questions = get(
      this.modelFor('organizations.organization.organization-promotions.organization-promotion.setup.quiz'),
      'questions'
    );
    const displayOrder = questions ? get(questions, 'length') + 1 : 1;
    set(question, 'displayOrder', displayOrder);

    return question;
  },
  /**
   * Get a new question to create
   */
  newQuestion() {
    if (this.isQuestionValid) {
      set(
        this.modelFor(
          'organizations.organization.organization-promotions.organization-promotion.setup.quiz.questions.new'
        ),
        'question',
        this.createQuestion()
      );
    }
  },
  //endregion

  //region Actions
  actions: {
    saveAndRunSimulation() {
      this.save().then(hash =>
        this.router.replaceWith(
          'organizations.organization.organization-promotions.organization-promotion.setup.quiz.questions.question',
          get(hash, 'question.id'),
          { queryParams: { balance: true } }
        )
      );
    },
  },
  //endregion
});
