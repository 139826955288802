/* eslint-disable ember/no-on-calls-in-components, ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash */
/* globals jstz */
import Component from '@ember/component';
import { computed, get, observer, set } from '@ember/object';
import { on } from '@ember/object/evented';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import moment from 'moment';
import PermittedComponent from 'partner/mixins/permitted-component';
import areAttrsChanged from 'partner/utils/are-attrs-changed';

export default Component.extend(PermittedComponent, {
  //region Ember Hooks
  enums: service(),
  //endregion

  //region Properties
  timeZone: jstz.determine().name(),
  showTimeZoneInfo: false,
  //endregion

  //region Computed Properties
  startDateStatus: computed('model.messageCampaign.scheduleStartDate', {
    get() {
      return !isEmpty(get(this, 'model.messageCampaign.scheduleStartDate')) ? 'HasStartDate' : 'NoStartDate';
    },
    set(key, value) {
      const localDate = moment().format('YYYY-MM-DD');
      const startDate = get(this, 'model.messageCampaign.scheduleStartDate') || moment(localDate).toDate();
      set(this, 'model.messageCampaign.scheduleStartDate', value === 'HasStartDate' ? startDate : null);
      return value;
    },
  }),
  endDateStatus: computed('model.messageCampaign.scheduleEndDate', {
    get() {
      return !isEmpty(get(this, 'model.messageCampaign.scheduleEndDate')) ? 'HasEndDate' : 'NoEndDate';
    },
    set(key, value) {
      set(this, 'model.messageCampaign.scheduleEndDate', value === 'HasEndDate' ? this.endDate : null);
      return value;
    },
  }),
  isLockedDown: computed('model.messageCampaign.messageSendingStatusType', 'disabled', function () {
    return this.disabled || get(this, 'model.messageCampaign.messageSendingStatusType') === 'Sent';
  }),
  isStartDateLockedDown: computed('isLockedDown', 'model.messageCampaign.messageSendingStatusType', function () {
    return this.isLockedDown || get(this, 'model.messageCampaign.messageSendingStatusType') === 'SentAndScheduled';
  }),
  endDate: computed('model.messageCampaign.scheduleEndDate', function () {
    const yearFromNow = moment().add(1, 'year').toDate();
    return get(this, 'model.messageCampaign.scheduleEndDate') || yearFromNow;
  }),

  step1status: computed('model.messageCampaign.singleSchedule.{delayDatepartTypeId,delayValue}', function () {
    const singleSchedule = get(this, 'model.messageCampaign.singleSchedule');
    return get(singleSchedule, 'delayDatepartTypeId') && get(singleSchedule, 'delayValue') !== ''
      ? 'bestPractice'
      : 'incomplete';
  }),
  step2status: computed('model.messageCampaign.singleSchedule.startTime', function () {
    const singleSchedule = get(this, 'model.messageCampaign.singleSchedule');
    return get(singleSchedule, 'startTime') ? 'bestPractice' : 'incomplete';
  }),
  step3status: 'bestPractice',
  step4status: 'bestPractice',
  isStep1Dirty: areAttrsChanged({
    'model.messageCampaign.singleSchedule': ['delayDatepartTypeId', 'delayValue'],
  }),
  isStep2Dirty: areAttrsChanged({
    'model.messageCampaign.singleSchedule': ['startTime'],
  }),
  isStep3Dirty: areAttrsChanged({
    'model.messageCampaign.singleSchedule': ['startDate'],
  }),
  isStep4Dirty: areAttrsChanged({
    'model.messageCampaign.singleSchedule': ['endDate'],
  }),
  isChecklistStepSatisfied: computed(
    'model.messageCampaign.singleSchedule.{startTime,delayDatepartTypeId,delayValue}',
    function () {
      return (
        this.step1status === 'bestPractice' &&
        this.step2status === 'bestPractice' &&
        this.step3status === 'bestPractice' &&
        this.step4status === 'bestPractice'
      );
    }
  ),
  //endregion

  //region Observers
  scheduling: on(
    'init',
    observer('model.messageCampaign.singleSchedule.{startTime,delayDatepartTypeId,delayValue}', function () {
      set(this, 'model.messageCampaign.isScheduled', this.isChecklistStepSatisfied);
    })
  ),

  sendChecklistStepSatisfaction: observer(
    'model.messageCampaign.isScheduled',
    'step1status',
    'step2status',
    'step3status',
    'step4status',
    function () {
      this['checklist-step-satisfied'](this.isChecklistStepSatisfied);
    }
  ),
  //endregion

  // This passes down the `this` context from the parent to take advantage
  // of `scheduleOnce` and the ember runloop so we don't have a race condition
  // on saves
  callSaveWithParentContext() {
    this.save();
  },
  //region Actions
  actions: {
    valueChanged(value) {
      set(this, 'model.messageCampaign.singleSchedule.delayValue', value);
      scheduleOnce('actions', this, this.callSaveWithParentContext);
    },
    datepartTypeIdChanged(value) {
      set(this, 'model.messageCampaign.singleSchedule.delayDatepartTypeId', value);
      scheduleOnce('actions', this, this.callSaveWithParentContext);
    },
    unschedule() {
      set(this, 'model.messageCampaign.singleSchedule.delayValue', null);
      set(this, 'selectedOption', null);
      set(this, 'model.messageCampaign.singleSchedule.delayDatepartTypeId', undefined); // this is done to prevent the data from being dirty if the original state was "undefined"
      set(this, 'model.messageCampaign.singleSchedule.startTime', undefined); // this is done to prevent the data from being dirty if the original state was "undefined"
    },
  },
  //endregion
});
