/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { equal } from '@ember/object/computed';
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import InheritableModel from 'partner/-base/inheritable-model';

export default InheritableModel.extend({
  name: DS.attr('string'),
  organizationId: DS.attr('number'),
  statusTypeId: DS.attr('number'),

  //region Computed Properties
  statusType: enums.computed('name', 'statusTypeId', 'statusTypeId', 'STATUS_TYPE'),
  /**
   * Can't use isDeleted because that conflicts with DS.Model.isDeleted
   */
  hasDeletedStatusType: equal('statusType', 'Deleted'),
  //endregion
});
