/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr } from '@ember-data/model';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import enums from 'ember-cli-ss-enums/services/enums';
import type { FacebookService } from 'partner/services/facebook';
import BaseModel from 'secondstreet-common/models/base';
import { DeveloperError } from 'secondstreet-common/utils/errors';

export default class DomainModel extends BaseModel {
  @service declare facebook: FacebookService;

  @attr('string') declare hostname: string;
  @attr('number') declare domainTypeId: number;
  @attr('string') declare name: string;
  @attr('string') declare externalLocationId: string;
  @attr('string') declare externalApiKeyId: string;
  @attr('string') declare externalUserId: string;
  @attr('number') declare statusTypeId: number;
  @attr('boolean') declare isSslSupported: boolean;
  @attr('boolean') declare isInherited: boolean;
  @attr('boolean') declare isPrimary: boolean;

  @enums.computed('name', 'statusTypeId', 'statusTypeId', 'STATUS_TYPE') declare statusType: string;
  @enums.computed('name', 'domainTypeId', 'domainTypeId', 'DOMAIN_TYPE') declare domainType: string;

  @computed('externalLocationId', 'domainType', 'facebook.pages.@each.id')
  get facebookPage() {
    return this.domainType === 'FacebookPage'
      ? (this.facebook.pages || []).findBy('id', this.externalLocationId)
      : undefined;
  }

  set facebookPage(value) {
    if (this.domainType !== 'FacebookPage') {
      throw new DeveloperError('You cannot add a Facebook Page to a non-facebook domain');
    }

    set(this, 'externalLocationId', value.id);
  }

  @computed('domainType', 'hostname', 'name')
  get subdomain() {
    let subdomain = '';

    if (this.hostname.indexOf('.secondstreetapp.com') > 0) {
      subdomain = this.hostname.replace('.secondstreetapp.com', '');
    }

    if (this.domainType === 'EmbeddedHostSource') {
      subdomain = this.name;
    }
    return subdomain;
  }

  @computed('hostname')
  get isOldPlatform() {
    return this.hostname.indexOf('.upickem.net') > 0;
  }
}
