/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-mixins */
import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';
import PromotionTypeName from 'partner/mixins/promotion-type-name';

const ExtendedModel = BaseModel.extend(PromotionTypeName) as unknown as typeof BaseModel;
export default class PromotionPerformanceStatisticModel extends ExtendedModel {
  @attr('number') declare entrants: number;
  @attr('number') declare newOptins: number;
  @attr('number') declare newUsers: number;
  @attr('number') declare users: number;
  @attr('number') declare votes: number;
  @attr('number') declare nominations: number;
  @attr('number') declare leads: number;
  @attr('number') declare promotionTypeId: number;
  @attr('number') declare promotionSubTypeId: number;
  @attr('number') declare organizationId: number;

  @attr('string') declare organizationName: string;
  @attr('string') declare promotionName: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'promotion-performance-statistic': PromotionPerformanceStatisticModel;
  }
}
