import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{on-intersect (perform this.fetchTask) once=true}} ...attributes>\n  {{yield this.publicAPI}}\n</div>\n", {"contents":"<div {{on-intersect (perform this.fetchTask) once=true}} ...attributes>\n  {{yield this.publicAPI}}\n</div>\n","moduleName":"partner/components/chart/loader/winners.hbs","parseOptions":{"srcName":"partner/components/chart/loader/winners.hbs"}});
import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, type TaskForAsyncTaskFunction } from 'ember-concurrency';
import type MatchupEntryRankModel from 'partner/models/matchup-entry-rank';
import type MatchupGroupModel from 'partner/models/matchup-group';
import type MatchupPlaceModel from 'partner/models/matchup-place';
import findMatchupPlacingCriteria from 'partner/utils/find-matchup-placing-criteria';
import RSVP from 'rsvp';

interface PublicAPI {
  matchupPlaces: MatchupPlaceModel[];
  matchupEntryRanks: MatchupEntryRankModel[];
  numberOfWinners: number;
  isLoading: boolean;
  fetchMatchupEntryRanksTask: TaskForAsyncTaskFunction<
    { keepLatest: boolean },
    (matchupGroup: MatchupGroupModel) => Promise<void>
  >;
}

interface ChartLoaderWinnersSignature {
  Element: HTMLDivElement;
  Args: {
    promotionType: 'Ballot' | 'UGCVoting';
    matchupGroups: MatchupGroupModel[];
  };
  Blocks: {
    default: [PublicAPI];
  };
}

export default class ChartLoaderWinnersComponent extends Component<ChartLoaderWinnersSignature> {
  @service declare store: Store;

  @tracked matchupPlaces: MatchupPlaceModel[] = [];
  @tracked matchupEntryRanks: MatchupEntryRankModel[] = [];
  @tracked numberOfWinners = 0;

  get publicAPI(): PublicAPI {
    return {
      matchupPlaces: this.matchupPlaces,
      matchupEntryRanks: this.matchupEntryRanks,
      numberOfWinners: this.numberOfWinners,
      isLoading: this.fetchTask.isRunning,
      fetchMatchupEntryRanksTask: this.fetchMatchupEntryRanksTask,
    };
  }

  private get endRank() {
    return this.matchupPlaces.lastObject?.endRank || 0;
  }

  fetchTask = task({ restartable: true }, async () => {
    const { matchupPlaces } = await RSVP.hash({
      matchupPlaces: this.store.query('matchup-place', {
        matchupPlacingCriteriaId: findMatchupPlacingCriteria(this.args.promotionType),
      }),
    });

    this.matchupPlaces = matchupPlaces.toArray().sortBy('endRank');

    let matchupEntryRanks;

    if (this.args.promotionType == 'Ballot') {
      matchupEntryRanks = await this.store.query('matchup-entry-rank', { calculateRanks: true });
      this.numberOfWinners = matchupEntryRanks.length as number;

      if (this.args.matchupGroups.firstObject) {
        matchupEntryRanks = await this.store.query('matchup-entry-rank', {
          matchupGroupId: this.args.matchupGroups.firstObject.id,
          calculateRanks: true,
        });
      }
    } else if (this.args.promotionType == 'UGCVoting') {
      matchupEntryRanks = await this.store.query('matchup-entry-rank', {
        calculateRanks: true,
      });
    } else {
      matchupEntryRanks = await this.store.query('matchup-entry-rank', {});
    }

    this.matchupEntryRanks = matchupEntryRanks.toArray().filter(({ rank }) => rank <= this.endRank);
  });

  fetchMatchupEntryRanksTask = task({ keepLatest: true }, async (matchupGroup: MatchupGroupModel) => {
    const matchupEntryRanks = await this.store.query('matchup-entry-rank', {
      matchupGroupId: matchupGroup.id,
      calculateRanks: true,
    });

    this.matchupEntryRanks = matchupEntryRanks.toArray().filter(({ rank }) => rank <= this.endRank);
  });
}
