/* eslint-disable ember/no-computed-properties-in-native-classes, ember/use-brace-expansion */
import { set } from '@ember/object';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type { Model } from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/ecommerce';
import type CurrentService from 'partner/services/current';

export default class SetupECommerceController extends Controller {
  @service declare checklist: any;
  @service declare current: CurrentService;
  @service declare router: RouterService;
  @service declare store: Store;

  declare model: Model;

  defaultPaymentMessage = 'There is a required payment step after registration to complete your entry.';

  @tracked isECommerceEnabled = false;
  @tracked _isPerSubmissionPaymentEnabled = false;
  @tracked isPerVotePaymentEnabled = false;
  @tracked submissionFees = 0;
  @tracked voteFees = 0;
  @tracked paymentMessage: string = this.defaultPaymentMessage;

  get isPerSubmissionPaymentEnabled() {
    return this._isPerSubmissionPaymentEnabled || !this.current.promotion.isUGCVoting;
  }

  get isECommerceEnabledDirty() {
    return (
      (this.hasStripeIntegration &&
        !this.model.organizationPromotionPayment?.get('isDeleted') &&
        !this.isECommerceEnabled) ||
      (this.model.organizationPromotionPayment?.get('isDeleted') && this.isECommerceEnabled)
    );
  }

  @computed(
    'submissionFees',
    'voteFees',
    'isECommerceEnabled',
    'model.organizationPromotionPayment.submissionFees',
    'model.organizationPromotionPayment.voteFees'
  )
  get isPaymentAmountsDirty() {
    if (!this.isECommerceEnabled) {
      return false;
    }

    return (
      this.submissionFees !== this.model.organizationPromotionPayment?.submissionFees ||
      this.voteFees !== this.model.organizationPromotionPayment?.voteFees
    );
  }

  get isPaymentMessageDirty() {
    if (!this.isECommerceEnabled) {
      return false;
    }

    return this.paymentMessage !== this.model.organizationPromotionPayment?.paymentMessage;
  }

  get isPaymentAmountsComplete() {
    const result =
      (this.isPerSubmissionPaymentEnabled && this.submissionFees && this.submissionFees > 0) ||
      (this.isPerVotePaymentEnabled && this.voteFees && this.voteFees > 0);

    if (result) {
      this.checklist.checkChecklistStep(this.router.currentRouteName);
    } else {
      this.checklist.uncheckChecklistStep(this.router.currentRouteName);
    }

    return result;
  }

  get isAnythingDirty() {
    if (!this.hasStripeIntegration || (!this.isECommerceEnabled && !this.model.organizationPromotionPayment)) {
      return false;
    }

    return this.isPaymentAmountsDirty || this.isPaymentMessageDirty || this.isECommerceEnabledDirty;
  }

  get hasStripeIntegration() {
    return this.model.organizationPaymentProcessors.any((processor: any) => processor.paymentProcessorId === 1);
  }

  get isStripeIntegrationErrored() {
    return this.model.organizationPaymentProcessors.any(
      (processor: any) => processor.paymentProcessorId === 1 && !processor.isValid
    );
  }

  get isECommerceStepComplete() {
    return this.isECommerceEnabled ? this.isPaymentAmountsComplete : true;
  }

  get isSweepstakesEnabled() {
    return this.model.sweepstakes?.isEnabled;
  }

  @action
  setup() {
    this._isPerSubmissionPaymentEnabled = false;
    this.isPerVotePaymentEnabled = false;
    this.paymentMessage = this.defaultPaymentMessage;

    set(this, 'isECommerceEnabled', this.model.organizationPromotionPayment !== null);

    if (this.isECommerceEnabled) {
      this.paymentMessage = this.model.organizationPromotionPayment.paymentMessage || this.defaultPaymentMessage;
      set(this, 'submissionFees', this.model.organizationPromotionPayment.submissionFees);
      set(this, 'voteFees', this.model.organizationPromotionPayment.voteFees);

      if (this.current.promotion.isUGCSweepstakes) {
        this._isPerSubmissionPaymentEnabled = true;
        return;
      }

      if (this.submissionFees > 0) this._isPerSubmissionPaymentEnabled = true;
      if (this.voteFees > 0) this.isPerVotePaymentEnabled = true;
    } else {
      set(this, 'voteFees', 0);
      set(this, 'submissionFees', 0);
    }

    if (this.isECommerceStepComplete) {
      this.checklist.checkChecklistStep(this.router.currentRouteName);
    }
  }

  togglePaymentEnabled = (type: string, value: boolean) => {
    if (type === 'submission') {
      set(this, 'submissionFees', value ? this.model.organizationPromotionPayment?.submissionFees || 1 : 0);
      this._isPerSubmissionPaymentEnabled = value;
    } else {
      set(this, 'voteFees', value ? this.model.organizationPromotionPayment?.voteFees || 1 : 0);
      this.isPerVotePaymentEnabled = value;
    }
  };

  @action
  togglePerVotePaymentEnabled(value: boolean) {
    set(this, 'voteFees', value ? 1 : 0);
    this.isPerVotePaymentEnabled = value;
  }

  @action
  handleContinue() {
    this.send('continue');
  }

  enableECommerce = () => {
    set(this, 'isECommerceEnabled', true);

    if (this.model.organizationPromotionPayment?.get('isDeleted')) {
      this.paymentMessage = this.defaultPaymentMessage;
      set(this, 'submissionFees', 0);
      set(this, 'voteFees', 0);
    }
  };

  createPromotionPayment() {
    set(
      this,
      // @ts-ignore
      'model.organizationPromotionPayment',
      this.store.createRecord('organization-promotion-payment', {
        paymentMessage: this.paymentMessage,
        submissionFees: this.submissionFees,
        voteFees: this.voteFees,
      })
    );
  }

  saveTask = task({ enqueue: true }, async () => {
    if (!this.isECommerceEnabled) {
      if (this.model.organizationPromotionPayment) {
        this.model.organizationPromotionPayment.deleteRecord();
      }
    } else {
      if (!this.model.organizationPromotionPayment || this.model.organizationPromotionPayment.get('isDeleted')) {
        this.createPromotionPayment();
      }

      set(this.model.organizationPromotionPayment, 'submissionFees', this.submissionFees || 0);
      set(this.model.organizationPromotionPayment, 'voteFees', this.voteFees || 0);

      if (!this.paymentMessage) this.paymentMessage = this.defaultPaymentMessage;
      this.model.organizationPromotionPayment.paymentMessage = this.paymentMessage;
    }

    if (this.hasStripeIntegration) {
      await this.model.organizationPromotionPayment.save();
    }
  });

  saveAndContinueTask = task({ enqueue: true }, async () => {
    await this.saveTask.perform();
    this.handleContinue();
  });
}
