import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<TemplateDesigner::Controls::Wrapper class=\"h-auto\">\n  <Form::Uploader\n    @value={{if @data.videoSrc (hash value=this.videoSrc type=this.type meta=@data.poster)}}\n    @accepts={{array \"video\" \"youtube\"}}\n    @onChange={{this.onAddVideo}}\n    @onRemove={{fn @onDataChange \"videoSrc\" undefined}}\n    @dimensions=\"16:9\"\n    @hideUnsplash={{false}}\n    class={{if @data.videoSrc \"mx-4\"}}\n    test-uploader-ad\n  />\n</TemplateDesigner::Controls::Wrapper>", {"contents":"<TemplateDesigner::Controls::Wrapper class=\"h-auto\">\n  <Form::Uploader\n    @value={{if @data.videoSrc (hash value=this.videoSrc type=this.type meta=@data.poster)}}\n    @accepts={{array \"video\" \"youtube\"}}\n    @onChange={{this.onAddVideo}}\n    @onRemove={{fn @onDataChange \"videoSrc\" undefined}}\n    @dimensions=\"16:9\"\n    @hideUnsplash={{false}}\n    class={{if @data.videoSrc \"mx-4\"}}\n    test-uploader-ad\n  />\n</TemplateDesigner::Controls::Wrapper>","moduleName":"partner/components/template-designer/library/attributes/video-settings.hbs","parseOptions":{"srcName":"partner/components/template-designer/library/attributes/video-settings.hbs"}});
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { action } from '@ember/object';

import Component from '@glimmer/component';
import type { SsArcAttributeSignature } from '@ss/arc/arc/types';
import MediaItemModel from 'partner/models/media-item';
import { inject as service } from '@ember/service';
import type SettingsService from 'partner/services/settings';
import { ArcVideoBlock } from '@ss/arc/arc/blocks';

const getYouTubeVideoId = (url: string) => {
  return url.split('/').pop();
};

export default class TemplateDesignerLibraryAttributesVideoSettingsComponent extends Component<SsArcAttributeSignature> {
  @service declare settings: SettingsService;

  get block() {
    return this.args.blockUnit as ArcVideoBlock;
  }

  get type() {
    return this.block.isFromYoutube ? 'youtube' : 'video';
  }

  get videoSrc() {
    return this.block.isFromYoutube ? getYouTubeVideoId(this.block.data.videoSrc) : this.block.data.videoSrc;
  }

  @action
  onAddVideo({ type, mediaItem, value }: { type: string; mediaItem: MediaItemModel; value: string }) {
    let src = '';
    let poster = '';

    if (type == 'video') {
      src = `//${this.settings.getValueFor('dips_url')}/videos/${value}`;
      poster = `${mediaItem.dipsUrl}?width=1170&quality=60&format=auto`;
    }

    if (type == 'youtube') {
      src = `https://www.youtube.com/embed/${value}`;
    }

    this.args.onDataChange('videoSrc', src);
    this.args.onDataChange('poster', poster);
  }
}
