/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { next } from '@ember/runloop';
import PermittedComponent from 'partner/mixins/permitted-component';

export default Component.extend(PermittedComponent, {
  //region Ember Hooks
  tagName: 'fab-flyout',
  classNameBindings: ['isExpanded'],
  //endregion

  //region Attributes
  'is-expandable': true,
  'show-if-view-only': null,
  //endregion

  //region Properties
  isExpanded: false,
  //endregion

  //region Methods
  click() {
    if (this['is-expandable']) {
      this.toggle();
    }
  },
  toggle() {
    if (this['is-expandable']) {
      this.toggleProperty('isExpanded');
      this.animate(); // This is gross, but the way Ember re-renders things prevents CSS transitions declared in CSS from working.
    }
  },
  animate() {
    next(this, () => {
      const element = this.element.querySelector('.rotating-plus');
      if (element) {
        element.style.transform = `rotate(${this.isExpanded ? 135 : 0}deg)`;
      }
    });
  },
  //endregion

  //region Actions
  actions: {
    toggle() {
      this.toggle();
    },
  },
  //endregion
});
