import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"entry-form-edit-field-values\" ...attributes>\n  {{#each this.formFieldsAndValues key=\"id\" as |item|}}\n    {{#let (component item.component) as |FieldComponent|}}\n      <FieldComponent\n        @formField={{item.formField}}\n        @entryFieldValues={{item.entryFieldValues}}\n        @onChange={{@onChange}}\n        @disabled={{@disabled}}\n        @placeholder={{@placeholder}}\n        data-test-form-field={{item.formField.id}}\n      />\n    {{/let}}\n  {{/each}}\n</div>\n", {"contents":"<div class=\"entry-form-edit-field-values\" ...attributes>\n  {{#each this.formFieldsAndValues key=\"id\" as |item|}}\n    {{#let (component item.component) as |FieldComponent|}}\n      <FieldComponent\n        @formField={{item.formField}}\n        @entryFieldValues={{item.entryFieldValues}}\n        @onChange={{@onChange}}\n        @disabled={{@disabled}}\n        @placeholder={{@placeholder}}\n        data-test-form-field={{item.formField.id}}\n      />\n    {{/let}}\n  {{/each}}\n</div>\n","moduleName":"partner/components/entry-form/edit-field-values.hbs","parseOptions":{"srcName":"partner/components/entry-form/edit-field-values.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

const FIELD_COMPONENTS = {
  SingleCheckbox: 'entry-form/field/single-checkbox',
  CustomDateInput: 'entry-form/field/custom-date-input',
  SelectSingle: 'entry-form/field/select-single',
  Checkboxes: 'entry-form/field/checkboxes',
  RadioButtons: 'entry-form/field/radio-buttons',
  NumberInput: 'entry-form/field/number-input',
  Textbox: 'entry-form/field/textbox',
  Textarea: 'entry-form/field/textarea',
};

export default class EntryFormEditFieldValuesComponent extends Component {
  //region Injections
  @service store;
  //endregion

  //region Computed Properties
  get formFieldsAndValues() {
    return this.args.entryForm.formPages.firstObject.sortedFormFields
      .rejectBy('isMediaReleaseConsent')
      .reduce((acc, formField) => {
        const component = FIELD_COMPONENTS[formField.fieldType];
        const entryFieldValues = this.entryFieldValues.filter(
          ({ fieldId }) =>
            // eslint-disable-next-line eqeqeq
            fieldId == formField.field.id
        );

        return component ? [...acc, { component, formField, entryFieldValues }] : acc;
      }, []);
  }

  get entryFieldValues() {
    return this.args.matchupEntry.entry.entryFieldValues;
  }
  //endregion
}
