/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';

export default Component.extend({
  //region Attributes
  updateTokenContentProperty() {},
  value: null,
  tokenId: null,
  isDisabled: false,
  //endregion

  //region Actions
  actions: {
    setColor(hex) {
      this.updateTokenContentProperty('value', hex);
    },
  },
  //endregion
});
