// For ember-ace configuration, see: https://github.com/dfreeman/ember-ace/blob/main/README.md#configuring-ace
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { config } from 'ace-builds';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/theme-tomorrow_night_eighties';
import cssWorkerUrl from 'ace-builds/src-noconflict/worker-css?resource';
import htmlWorkerUrl from 'ace-builds/src-noconflict/worker-html?resource';
import jsWorkerUrl from 'ace-builds/src-noconflict/worker-javascript?resource';

// Enable background workers for syntax highlighting
config.setModuleUrl('ace/mode/javascript_worker', jsWorkerUrl);
config.setModuleUrl('ace/mode/css_worker', cssWorkerUrl);
config.setModuleUrl('ace/mode/html_worker', htmlWorkerUrl);

type TokenContentEditorForCodeArgs = {
  updateTokenContentProperty: (property: string, value: string) => void;
};

export default class TokenContentEditorForCodeComponent extends Component<TokenContentEditorForCodeArgs> {
  value: string | null = null;
  errors: string[] | null = null;

  @action
  updateValue(value: string) {
    this.args.updateTokenContentProperty('value', value);
  }
}
