/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend({
  //region Properties
  name: DS.attr('string'),
  externalAppTypeId: DS.attr('number'),
  externalSourceApplicationId: DS.attr('string'),
  externalSourceKey: DS.attr('string'),
  //endregion

  //region Computed Properties
  externalAppTypeName: enums.computed('name', 'externalAppTypeId'),
  //endregion

  //region Relationships
  organization: DS.belongsTo('organization', {
    async: false,
  }),
  //endregion
});
