import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AutomaticRoute extends Route {
  @service router;

  get moderatePeopleModel() {
    return this.modelFor('organizations.organization.organization-promotions.organization-promotion.moderate-people');
  }

  async model() {
    return {
      ...this.moderatePeopleModel,
    };
  }

  redirect(model) {
    if (!model.detectedFraud) {
      this.router.transitionTo(
        'organizations.organization.organization-promotions.organization-promotion.moderate-people'
      );
    }
  }
}
