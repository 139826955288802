/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  //region Dependencies
  features: service(),
  //endregion

  //region Attributes
  closed() {},
  //endregion
});
