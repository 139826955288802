/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import EmailReportDownload from 'partner/mixins/email-report-download';
import HasCategoricalCharts from 'partner/mixins/has-categorical-charts';

export default Component.extend(HasCategoricalCharts, EmailReportDownload, {
  //region Ember Hooks
  classNames: ['messaging-dashboard'],
  classNameBindings: ['flatAppBar:instance'],
  //endregion

  //region Attributes
  /**
   * TODO: passing in the model is not ideal and should be refactored, but care should be taken: it will affect the mixins, which will affect other parts of the app
   */
  model: null,
  /**
   * A general message campaign; describes Single Email, Invite, Birthday, Wedding Anniversary, Welcome, Drip, (not Drip Message), and Newsletter (not Newsletter Instance) dashboards
   */
  'campaign-view': null,
  /**
   * A single message campaign; describes Single Email, Invite, Birthday, Wedding Anniversary, Welcome, Drip Message, and Newsletter Instance dashboards
   */
  'message-view': null,
  'refresh-stats'() {},
  'update-schedule-instance-page'() {},
  //endregion

  //region Computed Properties
  messageCampaignId: alias('model.messageCampaign.id'),
  messageId: alias('model.messagingStatistic.messageId'),

  messageVersionHistoryId: computed(
    'message-view',
    'model.messageCampaign.{isNewsletter,hasMessageVersionHistories}',
    'model.{messagingStatistic.messageVersionHistoryId,messageVersionHistory.firstObject.id}',
    function () {
      if (!get(this, 'model.messageCampaign.hasMessageVersionHistories')) {
        return;
      }

      return get(this, 'model.messageCampaign.isNewsletter') && this['message-view']
        ? get(this, 'model.messagingStatistic.messageVersionHistoryId')
        : get(this, 'model.messageVersionHistory.firstObject.id');
    }
  ),

  flatAppBar: computed('model.messageCampaign.{isDripCampaign,isNewsletter}', 'message-view', function () {
    return (
      (get(this, 'model.messageCampaign.isDripCampaign') || get(this, 'model.messageCampaign.isNewsletter')) &&
      this['message-view']
    );
  }),
  //endregion
});
