/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import flattenDeep from 'lodash/flattenDeep';
import AppContextRoute from 'partner/mixins/app-context-route';
import { isEmpty } from '@ember/utils';
import RSVP from 'rsvp';

export default Route.extend(AppContextRoute, {
  store: service(),
  current: service(),
  enums: service(),

  //region Ember Hooks
  model(params) {
    const organization = get(this.modelFor('organizations.organization'), 'organization');
    const formSettings = this.store.peekAll('form-default-setting')?.toArray();
    return RSVP.hash({
      form: this.store.find('form', params.form_id),
      organization,
      starredFields: this.store.query('field', { isStarred: true }).then(fields => fields.toArray()),
      searchResults: [],
      formDefaultSettings: isEmpty(formSettings)
        ? this.store
            .query('form-default-setting', {
              organizationId: this.current.organization.id,
            })
            .then(formDefaultSetting => formDefaultSetting.toArray())
        : formSettings,
      toggleSetting: this.store.query('setting', {
        key: 'default_form_is_enforced',
        targetEntityId: params.form_id,
        targetEntityTypeId: this.enums.findWhere('ENTITY_TYPE', { name: 'Form' }, 'id'),
      }),
      tokens: this.store.query('token', { organizationId: this.current.organization.id }),
    });
  },

  async setupController(controller, model) {
    this._super(controller, model);
    if (get(model, 'form.isJustCreated')) {
      if (get(model, 'form.allowEditingName')) {
        set(model, 'form.name', '');
        set(controller, 'editing-name', true);
      } else {
        set(model, 'form.name', `${model.form.name} copy`);
        await this.saveForm();
      }
      set(model, 'form.isJustCreated', false);
      set(model, 'form.allowEditingName', false);
    }
  },

  async saveForm() {
    try {
      const routeModel = this.modelFor(this.routeName);
      await routeModel.form.save();
      const formFields = flattenDeep(
        routeModel.form.formPages.mapBy('formFields').map(formFields => formFields.toArray())
      );
      await Promise.all(formFields.rejectBy('formPage').map(orphanFormField => orphanFormField.reload()));
      set(this, 'controller.didSomethingChange', false);
    } finally {
      set(this, 'controller.isAnythingSaving', false);
    }
  },
  //endregion

  //region Actions
  actions: {
    fieldStarred(field) {
      const method = get(field, 'isStarred') ? 'addObject' : 'removeObject';
      get(this.modelFor(this.routeName), 'starredFields')[method](field);
    },
    async save() {
      set(this, 'controller.editing-name', false);
      set(this, 'controller.isAnythingSaving', true);
      this.saveForm();
    },
  },
  //endregion
});
