/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';

const ROUTE_NAME = 'organizations.organization.organization-promotions.organization-promotion.setup.entries';

const UPLOADED_MEDIA_TYPES = {
  PhotoVotingStandard: 'photo',
  PhotoGallery: 'photo',
  VideoVotingStandard: 'video',
  UGCSweepstakesStandard: 'photo',
  UGCSweepstakesVideo: 'video',
};

export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionSetupEntriesController extends Controller {
  //region Dependencies
  @service checklist;
  //endregion

  //region Computed Properties
  get entryForm() {
    return this.model.entryForm;
  }

  get formPage() {
    return this.entryForm.formPages.firstObject;
  }

  get isEntryFormDirty() {
    return this.formPage.formFields.isAny('isDirty');
  }

  get isAnythingDirty() {
    return this.isEntryFormDirty;
  }

  get isAnythingSaving() {
    return this.saveTask.isRunning || this.changePendingEntriesEmailRecipientsSettingTask.isRunning;
  }

  get pendingEntriesEmailRecipientsStepStatus() {
    return isPresent(this.model.pendingEntriesEmailRecipientsSetting?.value) ? 'bestPractice' : 'incomplete';
  }

  get uploadedMediaType() {
    return UPLOADED_MEDIA_TYPES[this.model.organizationPromotion.promotion.promotionSubType] || 'media';
  }

  get checkAllMediaType() {
    const formFields = this.entryForm.formPages.firstObject.formFields.firstObject;
    return formFields.allowVideoUpload || formFields.allowPhotoUpload;
  }

  //endregion

  //region Actions
  @action
  handleContinue() {
    // @see partner/app/routes/application.js:74
    this.send('continue');
  }
  //endregion

  //region Tasks
  saveTask = task({ enqueue: true }, async () => {
    // We want the errors to be handled by the components that are calling this action
    await this.entryForm.save();
  });

  saveAndContinueTask = task({ enqueue: true }, async () => {
    await this.saveTask.perform();
    this.handleContinue();
  });

  changePendingEntriesEmailRecipientsSettingTask = task(async value => {
    this.model.pendingEntriesEmailRecipientsSetting.value = value;

    try {
      await this.model.pendingEntriesEmailRecipientsSetting.save();

      isPresent(value)
        ? this.checklist.checkChecklistStep(ROUTE_NAME)
        : this.checklist.uncheckChecklistStep(ROUTE_NAME);
    } catch (e) {
      console.error(e);
    }
  });
  //endregion
}
