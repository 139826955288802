import { inject as service } from '@ember/service';
import EmberSimpleAuthSessionService from 'ember-simple-auth/services/session';
import ENV from 'partner/config/environment';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import type SentryService from 'partner/services/sentry';

type Headers = Record<string, string | null | undefined>;

export default class SessionService extends EmberSimpleAuthSessionService<SecondStreetSessionData> {
  @service declare ajax: any;
  @service declare enums: EnumsService;
  @service declare sentry: SentryService;

  #customHeaders: Headers = {};

  get headers(): Headers {
    return {
      Authorization: this.accessToken,
      'X-Api-Key': ENV.APP.API_KEY,
      ...this.#customHeaders,
    };
  }

  get accessToken(): string {
    return this.data?.authenticated?.access_token || '';
  }

  get isSuperAdmin(): boolean {
    const loggedInOrganizationUserTypeId = this.data?.authenticated?.organization_users?.user_type_id;

    if (loggedInOrganizationUserTypeId) {
      const adminUserTypeIds = [
        this.enums.findWhere('USER_TYPE', { name: 'SecondStreetAdmin' }, 'id'),
        this.enums.findWhere('USER_TYPE', { name: 'SuperAdmin' }, 'id'),
      ];

      return adminUserTypeIds.includes(loggedInOrganizationUserTypeId);
    }

    return false;
  }

  get isTwoFactorAuthRequired(): boolean {
    return Boolean(this.data?.authenticated?.is_two_factor_auth_required);
  }

  // TODO: What defines a 2FA user will most likely change
  // when we continue developing the epic
  get isTwoFactorUser(): boolean {
    return this.isSuperAdmin || this.isTwoFactorAuthRequired;
  }

  get isTwoFactorAuthComplete(): boolean {
    return Boolean(this.data?.authenticated?.is_two_factor_auth_complete);
  }

  get isTwoFactorAuthDeviceSetUp(): boolean {
    return Boolean(this.data?.authenticated?.is_two_factor_auth_device_setup_complete);
  }

  get isTwoFactorAuthIncomplete(): boolean {
    return this.isTwoFactorAuthRequired && this.isTwoFactorAuthDeviceSetUp && !this.isTwoFactorAuthComplete;
  }

  /**
   * Wraps ember-ajax's basic `request` method & signature to add the Authorization header
   */
  request<T>(
    url: string,
    options: {
      headers?: Headers;
      type?: 'GET' | 'POST' | 'PUT' | 'DELETE';
      contentType?: boolean | string;
      processData?: boolean;
      data?: any;
      xhr?: any;
    } = {}
  ): Promise<T> {
    options.headers = {
      ...this.headers,
      ...(options.headers ?? {}),
    };

    return this.ajax.request(url, {
      contentType: 'application/json',
      ...options,
    });
  }

  handleAuthentication(routeAfterAuthentication: string) {
    if (this.isTwoFactorAuthRequired) {
      if (this.isTwoFactorAuthIncomplete) {
        routeAfterAuthentication = 'login.totp';
      } else if (!this.isTwoFactorAuthDeviceSetUp) {
        routeAfterAuthentication = 'setup.totp';
      }
    }

    this.sentry.setupUser();

    return super.handleAuthentication(routeAfterAuthentication);
  }

  addCustomHeaders(headers: Headers) {
    this.#customHeaders = { ...this.#customHeaders, ...headers };
  }

  removeCustomHeader(headerName: string) {
    delete this.#customHeaders[headerName];
  }
}
