/* eslint-disable ember/no-get */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class IndexRoute extends Route {
  @service router;

  redirect() {
    const messageCampaignController = this.controllerFor(
      'organizations.organization.message-campaigns.message-campaign'
    );

    if (!get(messageCampaignController, 'is-editing-basics')) {
      const { checklist } = this.modelFor('organizations.organization.message-campaigns.message-campaign.setup');
      const destination =
        checklist?.sortedChecklistSteps.firstObject.destination ||
        'organizations.organization.message-campaigns.message-campaign.setup.message-versions';

      this.router.replaceWith(destination);
    } else {
      set(messageCampaignController, 'needsRedirect', true);
    }
  }
}
