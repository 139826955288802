/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  //region Ember Hooks
  tagName: '',
  //endregion

  //region Computed Properties
  isReadOnly: computed('isCustom', 'firstEditableRound', 'round', 'isEndDate', function () {
    if (this.isCustom) {
      return false;
    }

    if (this.round !== this.firstEditableRound || this.isEndDate) {
      return true;
    }

    const now = new Date();
    return now > this.round.startDate && this.numberOfVotes > 0;
  }),
  isDisabledInput: computed('isCustom', 'date', 'numberOfVotes', 'isDisabled', function () {
    if (!this.isCustom || this.numberOfVotes === 0) {
      return false;
    }

    if (this.isDisabled) {
      return true;
    }

    const now = new Date();
    return now > this.date;
  }),
  //endregion
});
