/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import PermittedActionComponent from 'partner/components/permitted-action';

export default PermittedActionComponent.extend({
  //region Ember Hooks
  classNames: ['action-needed-alert__action-item', 'action-needed-alert__button'],
  classNameBindings: ['is-raised:action-needed-alert__button--raised'],
  tagName: 'button',
  //endregion
});
