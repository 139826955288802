import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
  /**
   * If it's a 400 (Validation Failed) error, pretend it succeeded.
   * Then, on the individual models we'll observe the embeddedErrors property and manually add things to Errors.
   */
  isInvalid(status) {
    if (status === 400) {
      this.attachValidationErrors(...arguments);
      return false;
    }
    return this._super(...arguments);
  },
});
