/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr, belongsTo } from '@ember-data/model';
import { computed, get } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { isBlank } from '@ember/utils';
import { parseOneAddress } from 'email-addresses';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import enums from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';

@makeBooleanProperties('SENDER_ACCOUNT_VALIDATION_TYPE', 'name', 'senderAccountValidationTypeId')
export default class SenderAccount extends BaseModel {
  @attr('number') senderAccountTypeId;
  @attr('number') statusTypeId;
  @attr('string') fromAccount;
  @attr('string') fromName;
  @attr('string') replyTo;
  @attr('string') externalAccountId;
  @attr('string') externalAccountToken;
  @attr('boolean') isPrimary;
  @attr('boolean', { defaultValue: true }) forwardsToBouncebackAccount;
  @attr('number') dnsVerificationStatusId;
  @attr('boolean', { defaultValue: false }) isAssociated;

  /**
   * An API Bug has this coming out as an embedded record instead of a TypeID property
   */
  @belongsTo('sender-account-validation-type', { async: false }) senderAccountValidationTypes;

  //endregion

  //region Computed Properties
  /**
   * @private
   */
  @computed('fromAccount')
  get fromAccountParsed() {
    const { fromAccount } = this;
    return isBlank(fromAccount) ? null : parseOneAddress({ input: fromAccount, simple: false });
  }

  @readOnly('fromAccountParsed.parts.domain.semantic') fromAccountDomain;
  @readOnly('fromAccountParsed.parts.address.semantic') fromAccountAddress;

  @computed('senderAccountValidationTypes.id')
  get senderAccountValidationTypeId() {
    return parseInt(get(this, 'senderAccountValidationTypes.id'), 10);
  }

  @enums.computed('name', 'senderAccountValidationTypeId', 'senderAccountValidationTypeId') senderAccountValidationType;

  @computed('senderAccountValidationType')
  get validationStatus() {
    return (
      {
        Failed: 'Failed',
        'Failed Feedback': 'Failed',
        'Failed TXT Lookup': 'Failed',
        'Valid And Verified': 'Verified',
        'Starting Retest': 'Verified',
        'Passed TXT Lookup': 'Verified',
        'Feedback Test Email Sent': 'Verified',
        'Newly Created': 'Validating',
        'TXT Record OK': 'Validating',
        'TXT Record Good': 'Validating',
      }[this.senderAccountValidationType] || 'Pending'
    );
  }

  @computed('validationStatus')
  get canSendAtAll() {
    return this.validationStatus !== 'Failed' && this.validationStatus !== 'Validating';
  }

  @enums.computed('name', 'dnsVerificationStatusId', 'dnsVerificationStatusId') dnsVerificationStatus;

  @computed('validationStatus', 'dnsVerificationStatus')
  get sendableStatus() {
    const { validationStatus } = this;

    return validationStatus === 'Validating' || validationStatus === 'Failed'
      ? validationStatus
      : this.dnsVerificationStatus;
  }
}
