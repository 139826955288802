/* eslint-disable ember/no-mixins */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AdminRoute from 'partner/mixins/admin-route';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

export default class OrganizationsOrganizationNotificationsRoute extends Route.extend(AppContextRoute, AdminRoute) {
  @service store;

  model() {
    const { organization } = this.modelFor('organizations.organization');

    return RSVP.hash({
      organization,
      partnerNotifications: this.store
        .query('partner-notification', { update: true, isAdministration: true })
        .then(x => x.toArray()),
      targetableProducts: this.store.findAll('ss-product').then(x => x.filterBy('isPartnerNotificationTargetable')),
    });
  }
}
