/* eslint-disable ember/no-new-mixins, ember/no-mixins */
import Mixin from '@ember/object/mixin';
import IsSecondStreetAdminMixin from 'partner/mixins/is-second-street-admin';

export default Mixin.create(IsSecondStreetAdminMixin, {
  beforeModel(transition) {
    this._super(...arguments);

    if (!this.isAdminUser) {
      transition.abort();
    }
  },
});
