/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/* globals FB */
import Component from '@ember/component';

export default Component.extend({
  classNames: ['ssFacebookAuthBox'],
  didInsertElement() {
    this._super(...arguments);
    FB.XFBML.parse();
  },
});
