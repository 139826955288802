/* eslint-disable ember/no-computed-properties-in-native-classes */
import { AsyncHasMany, attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import { computed, set } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import enums from 'ember-cli-ss-enums/services/enums';
import { task } from 'ember-concurrency';
import type CurrentService from 'partner/services/current';
import votingEntryKpiCount from 'partner/utils/voting-entry-kpi-count';
import RSVP from 'rsvp';
import BaseModel from 'secondstreet-common/models/base';
import type EntryLocationModel from './entry-location';
import type MatchupModel from './matchup';
import type NominationEntriesSummaryModel from './nomination-entries-summary';

@makeBooleanProperties('STATUS_TYPE')
export default class VotingEntryModel extends BaseModel {
  @service declare current: CurrentService;

  // Attributes
  @attr('string') declare name: string;
  @attr('string') declare description: string;
  @attr('number') declare statusTypeId: number;
  @attr('number') declare matchupId: number;
  @attr('number') declare matchupEntryId: number;
  @attr('string') declare facebookUrl: string;
  @attr('number') declare mediaItemId: number;
  @attr('number') declare logoMediaItemId: number;
  @attr('string') declare phone: string;
  @attr('string') declare twitterHandle: string;
  @attr('string') declare websiteUrl: string;
  @attr('string') declare youtubeChannelUrl: string;
  @attr('string') declare instagramHandle: string;
  @attr('string') declare tiktokHandle: string;
  @attr('string') declare snapchatHandle: string;
  @attr('string') declare linkedinProfileUrl: string;
  @attr('string') declare vimeoChannelUrl: string;
  @attr('string') declare fullAddress: string;
  @attr('string') declare sourceType: string;
  @attr('string') declare sourceMediaType: string;
  @attr('string') declare sourcePostId: string;
  @attr('number') declare masterEntryId: number;
  @attr('number') declare locationId: number;
  @attr('boolean') declare splitLocations: boolean;
  @attr('boolean') declare createdForMerge: boolean;
  @attr('number', { defaultValue: 1 }) declare displayColumn: number;
  @attr('number', { defaultValue: 0 }) declare displayOrder: number;
  @attr('number', { defaultValue: 0 }) declare voteCount: number;
  @attr('boolean', { defaultValue: false }) declare bypassNameValidation: boolean;
  @attr('boolean', { defaultValue: false }) declare isAutoApproved: boolean;
  @attr('boolean') declare isFeatured: boolean;
  @attr('boolean', { defaultValue: false }) declare selectedForVoting: boolean;
  @attr('boolean', { defaultValue: false }) declare automaticallyAddEntrants: boolean;

  @belongsTo('matchup') declare matchup: MatchupModel;
  @hasMany('entry-location', { async: false }) declare entryLocations: SyncHasMany<EntryLocationModel>;
  @hasMany('nomination-entries-summary', { async: true })
  declare nominationEntriesSummaries: AsyncHasMany<NominationEntriesSummaryModel>;

  @belongsTo('voting-entry', { async: false, inverse: 'childVotingEntries' }) declare masterEntry: VotingEntryModel;
  @hasMany('voting-entry', { async: false, inverse: 'masterEntry' })
  declare childVotingEntries: SyncHasMany<VotingEntryModel>;

  get isVideoEntry(): boolean {
    return this.sourceMediaType == 'video';
  }

  get isPhotoEntry(): boolean {
    return this.sourceMediaType == 'image';
  }

  get isFromYouTube(): boolean {
    return this.sourceType == 'YouTube';
  }

  get guid(): string {
    return guidFor(this);
  }

  @computed(
    'twitterHandle',
    'youtubeChannelUrl',
    'instagramHandle',
    'linkedinProfileUrl',
    'vimeoChannelUrl',
    'facebookUrl',
    'snapchatHandle',
    'tiktokHandle'
  )
  get hasOnlyOneSocialField(): boolean {
    const socialFields: Array<keyof VotingEntryModel> = [
      'twitterHandle',
      'youtubeChannelUrl',
      'instagramHandle',
      'linkedinProfileUrl',
      'vimeoChannelUrl',
      'facebookUrl',
      'snapchatHandle',
      'tiktokHandle',
    ];
    return socialFields.filter(prop => this[prop]).length == 1;
  }

  @computed(
    'masterEntryId',
    'statusTypeId',
    'childVotingEntries.@each.voteCount',
    'current.promotion.isVotingBallot',
    'nominationEntriesSummaries.@each.count'
  )
  get count() {
    return votingEntryKpiCount(this, this.current?.promotion?.isVotingBallot, this.childVotingEntries);
  }

  @enums.computed('name', 'statusTypeId', 'statusTypeId', 'STATUS_TYPE')
  declare statusType: string;

  @computed('nominationEntriesSummaries.@each.count')
  get totalCount() {
    return this.nominationEntriesSummaries.mapBy('count').reduce((x, y) => x + y, 0);
  }

  /**
   * Change the statusType of the entry and all of its related entries to "Rejected"
   */
  reject() {
    return this.changeStatusTypeTask.perform('Rejected');
  }

  /**
   * Change the statusType of the entry and all of its related entries to "Submitted"
   */
  unreject() {
    return this.changeStatusTypeTask.perform('Submitted');
  }

  changeStatusTypeTask = task({ drop: true }, async statusType => {
    const votingEntries: VotingEntryModel[] = [
      this,
      ...this.store.peekAll('voting-entry').filter(({ masterEntryId }) => `${masterEntryId as number}` == this.id),
    ];

    await RSVP.all(
      votingEntries.map(votingEntry => {
        set(votingEntry, 'statusType', statusType);

        return !!votingEntry.hasDirtyAttributes && votingEntry.save();
      })
    );
  });

  // Props from @makeBooleanProperties('STATUS_TYPE')
  declare isRejected: boolean;
  declare isSubmitted: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'voting-entry': VotingEntryModel;
  }
}
