import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  {{did-insert (perform this.fetchTask @matchupId @filters)}}\n  {{did-update (perform this.fetchTask @matchupId @filters)}}\n  {{will-destroy this.cleanup}}\n  ...attributes\n>\n  {{yield this.votingEntries this.tasks this.state}}\n</div>", {"contents":"<div\n  {{did-insert (perform this.fetchTask @matchupId @filters)}}\n  {{did-update (perform this.fetchTask @matchupId @filters)}}\n  {{will-destroy this.cleanup}}\n  ...attributes\n>\n  {{yield this.votingEntries this.tasks this.state}}\n</div>","moduleName":"partner/components/ballot/voting-entry-manager.hbs","parseOptions":{"srcName":"partner/components/ballot/voting-entry-manager.hbs"}});
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action, setProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import isEqual from 'lodash/isEqual';
import { endpoint } from 'secondstreet-common/utils/url';
import { safeUnload } from 'partner/utils/ember-data';
import { set } from '@ember/object';
import sanitizeString from 'partner/utils/sanitize-string';
import { doneMessage, getBestMasterVotingEntry } from 'partner/utils/merge-items';
import RSVP from 'rsvp';
import { isArray } from '@ember/array';
import { simplyByProperty } from 'secondstreet-common/utils/sorting';
import type Store from '@ember-data/store';
import type SnackbarService from 'secondstreet-common/services/snackbar';
import type { TaskForAsyncTaskFunction } from 'ember-concurrency';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import type VotingEntryModel from 'partner/models/voting-entry';
import type MatchupEntryModel from 'partner/models/matchup-entry';
import type SessionService from 'partner/services/-private/session';
import type MergeSuggestionsService from 'partner/services/merge-suggestions';
import type SettingsService from 'partner/services/settings';
import type CurrentService from 'partner/services/current';
import type RouterService from '@ember/routing/router-service';

export type Tasks = {
  fetch: TaskForAsyncTaskFunction<{ restartable: boolean }, (matchupId: string, filters?: Filters) => Promise<void>>;
  reorder: TaskForAsyncTaskFunction<{ drop: boolean }, (matchupEntries: VotingEntryModel[]) => Promise<void>>;
  approve: TaskForAsyncTaskFunction<{ drop: boolean }, (matchupEntry: VotingEntryModel) => Promise<void>>;
  unapprove: TaskForAsyncTaskFunction<{ drop: boolean }, (matchupEntry: VotingEntryModel) => Promise<void>>;
  reject: TaskForAsyncTaskFunction<
    { drop: boolean },
    (matchupEntry: VotingEntryModel, openDrawer: any) => Promise<void>
  >;
  unReject: TaskForAsyncTaskFunction<{ drop: boolean }, (matchupEntry: VotingEntryModel) => Promise<void>>;
  move: TaskForAsyncTaskFunction<
    { drop: boolean },
    (votingEntry: VotingEntryModel, openMatchup: any, matchupId: number) => Promise<MatchupEntryModel | null>
  >;
  unmerge: TaskForAsyncTaskFunction<{ drop: boolean }, (matchupEntry: VotingEntryModel) => Promise<void>>;
  merge: TaskForAsyncTaskFunction<
    { drop: boolean },
    (votingEntries: VotingEntryModel[], highlight: any, proposedName: string) => Promise<void>
  >;
  mergeAndApprove: TaskForAsyncTaskFunction<
    { drop: boolean },
    (votingEntries: VotingEntryModel[], highlight: any, proposedName: string) => Promise<void>
  >;
  approveSelected: TaskForAsyncTaskFunction<
    { drop: boolean },
    (votingEntries: VotingEntryModel[], proposedName: string) => Promise<void>
  >;
  mergeSuggested: TaskForAsyncTaskFunction<
    { drop: boolean },
    (votingEntries: VotingEntryModel[], proposedName: string) => Promise<void>
  >;
  mergeAndApproveSuggested: TaskForAsyncTaskFunction<
    { drop: boolean },
    (votingEntries: VotingEntryModel[], proposedName: string) => Promise<void>
  >;
  update: TaskForAsyncTaskFunction<
    { drop: boolean },
    (matchupEntry: VotingEntryModel, options: Partial<keyof VotingEntryModel>) => Promise<void>
  >;
};

type Filters = Pick<Partial<VotingEntryModel>, 'statusTypeId'>;

interface BallotVotingEntryManagerSignature {
  Element: HTMLDivElement;
  Args: {
    matchupId: number | string;
    filters?: Filters;
  };
  Blocks: {
    default: [votingEntries: VotingEntryModel[], tasks: Tasks, merging: boolean, approving: boolean];
  };
}
export default class BallotVotingEntryManagerComponent extends Component<BallotVotingEntryManagerSignature> {
  @service declare snackbar: SnackbarService;
  @service declare store: Store;
  @service declare enums: EnumsService;
  @service declare session: SessionService;
  @service('merge-suggestions') declare mergeSuggestionsService: MergeSuggestionsService;
  @service declare settings: SettingsService;
  @service declare current: CurrentService;
  @service declare router: RouterService;

  private previousQueryParams?: Record<string, unknown>;
  @tracked suggestions: any = [];

  @cached
  get votingEntries(): VotingEntryModel[] {
    const filters = this.args.filters || {};
    const filterKeys = Object.keys(filters) as Array<keyof Filters>;

    return this.store
      .peekAll('voting-entry')
      .filter(
        (votingEntry: VotingEntryModel) =>
          votingEntry.matchupId == this.args.matchupId && filterKeys.every(key => filters[key] == votingEntry[key])
      );
  }

  get tasks(): Tasks {
    return {
      fetch: this.fetchTask,
      reorder: this.reorderTask,
      approve: this.approveTask,
      unapprove: this.unapproveTask,
      reject: this.rejectTask,
      unReject: this.unRejectTask,
      move: this.moveTask,
      unmerge: this.unmergeTask,
      merge: this.mergeTask,
      mergeAndApprove: this.mergeAndApproveTask,
      approveSelected: this.approveSelectedTask,
      mergeSuggested: this.mergeSuggestedTask,
      mergeAndApproveSuggested: this.mergeAndApproveSuggestedTask,
      update: this.updateTask,
    };
  }

  get state() {
    return {
      merging: this.merging,
      approving: this.approving,
      entriesSelectedForVoting: this.entriesSelectedForVoting,
    };
  }

  get entriesSelectedForVoting(): VotingEntryModel[] {
    if (
      !this.current?.promotion?.isNominationAndVotingBallot ||
      !this.current.organizationPromotion.submissionHasEnded ||
      this.router.currentRouteName !==
        'organizations.organization.organization-promotions.organization-promotion.moderate-nominations.approve'
    ) {
      return [];
    }

    const maxAllowed = this.settings.getValueFor('approved_entrants_for_voting');
    const includeTies = this.settings.getValueFor('allow_ties');

    if (maxAllowed < 1) return [];

    const sortedByVotes = this.votingEntries
      .filterBy('statusType', 'Active')
      .sort((a, b) => simplyByProperty('count')(b, a));
    let eligibleVotingEntries: VotingEntryModel[] = [];

    if (includeTies) {
      const [firstPart, secondPart] = [sortedByVotes.slice(0, maxAllowed - 1), sortedByVotes.slice(maxAllowed - 1)];
      eligibleVotingEntries.pushObjects(firstPart);
      eligibleVotingEntries.pushObjects(secondPart?.filterBy('count', secondPart.firstObject?.count));
    } else {
      eligibleVotingEntries = sortedByVotes.slice(0, maxAllowed);
    }

    return eligibleVotingEntries;
  }

  get merging() {
    return this.mergeTask.isRunning || this.mergeSuggestedTask.isRunning;
  }

  get approving() {
    return (
      this.approveSelectedTask.isRunning ||
      this.mergeAndApproveTask.isRunning ||
      this.mergeAndApproveSuggestedTask.isRunning
    );
  }

  fetchTask = task({ restartable: true }, async (matchupId: string, filters: Filters) => {
    const queryParams = { matchupId, ...filters };

    if (isEqual(this.previousQueryParams, queryParams)) return;

    await this.store.query('voting-entry', queryParams);

    this.previousQueryParams = queryParams;

    this.mergeSuggestionsService.generateSuggestions(this.votingEntries);
  });

  reorderTask = task({ keepLatest: true }, async (votingEntries: VotingEntryModel[]) => {
    votingEntries.forEach((votingEntry, index) => {
      votingEntry.displayOrder = index + 1;
    });

    try {
      await Promise.all(votingEntries.filterBy('hasDirtyAttributes').map(votingEntry => votingEntry.save()));
    } catch (e) {
      this.snackbar.exception(e);
    }
  });

  approveTask = task({ drop: true }, async (votingEntry: VotingEntryModel) => {
    try {
      await this.#approve(votingEntry);
    } catch (e) {
      this.snackbar.exception(e);
    }
  });

  unapproveTask = task({ drop: true }, async (votingEntry: VotingEntryModel) => {
    votingEntry.statusTypeId = this.enums.findWhere('STATUS_TYPE', { name: 'Submitted' });

    try {
      await votingEntry.save();
    } catch (e) {
      this.snackbar.exception(e);
    }
  });

  rejectTask = task({ drop: true }, async (votingEntry: VotingEntryModel, openDrawer) => {
    try {
      await votingEntry.reject();

      this.mergeSuggestionsService.excludeSuggestion(votingEntry);

      if (typeof openDrawer == 'function') {
        this.snackbar.show(doneMessage(1, '', 'items', 'rejected'), 'VIEW REJECTED').then(() => {
          openDrawer('Rejected', votingEntry);
        });
      } else {
        this.snackbar.show(doneMessage(1, '', 'items', 'rejected'));
      }
    } catch (e) {
      this.snackbar.exception(e);
    }
  });

  unRejectTask = task({ drop: true }, async (votingEntry: VotingEntryModel) => {
    try {
      await votingEntry.unreject();

      this.snackbar.show(doneMessage(1, '', 'items', 'added back'));
    } catch (e) {
      this.snackbar.exception(e);
    }
  });

  unmergeTask = task({ drop: true }, async (masterVotingEntry: VotingEntryModel) => {
    try {
      const { id } = masterVotingEntry;

      const response: any = await this.session.request(endpoint('voting_entries_unmerge'), {
        type: 'PUT',
        data: {
          voting_entries: [
            {
              ...masterVotingEntry.serialize(),
              id,
            },
          ],
        },
      });

      this.store.pushPayload({
        votingEntries: response.voting_entries,
      });

      /*
        if the impacted entries were indeed created just to merge other entries and it has no nominations,
        it gets deleted from backend while un-merge,
        hence we remove it from ui and unload it from store
      */
      [masterVotingEntry, ...masterVotingEntry.childVotingEntries?.toArray()].forEach(votingEntry => {
        if (
          votingEntry.createdForMerge &&
          !response.voting_entries.find(({ id }: { id: number }) => `${id}` == votingEntry.id)
        ) {
          safeUnload(votingEntry, this.store);
        }
      });

      this.snackbar.show(`${masterVotingEntry.name as string} was unmerged.`);
    } catch (e) {
      this.snackbar.exception(e);
    }
  });

  mergeTask = task(
    { drop: true },
    async (votingEntries: VotingEntryModel[], highlightVotingEntry, proposedName: string) => {
      try {
        const mergedVotingEntry = await this.#merge(votingEntries, proposedName);

        this.snackbar.show(doneMessage(votingEntries.length, 'selected', 'items', 'merged'));
        highlightVotingEntry(mergedVotingEntry);
      } catch (e) {
        this.snackbar.exception(e);
      }
    }
  );

  mergeAndApproveTask = task(
    { drop: true },
    async (votingEntries: VotingEntryModel[], highlightVotingEntry, proposedName: string) => {
      try {
        const mergedVotingEntry = await this.#merge(votingEntries, proposedName);

        await this.#approve(mergedVotingEntry);

        this.snackbar.show(doneMessage(votingEntries.length, 'selected', 'items', 'merged and approved'));
        highlightVotingEntry(mergedVotingEntry);
      } catch (e) {
        this.snackbar.exception(e);
      }
    }
  );

  mergeSuggestedTask = task({ drop: true }, async (votingEntries: VotingEntryModel[], proposedName: string) => {
    try {
      if (isArray(votingEntries) && proposedName) {
        await this.#merge(votingEntries, proposedName);

        this.snackbar.show(`${votingEntries.length} suggested items were merged.`);
      } else {
        await RSVP.all(
          this.mergeSuggestionsService.suggestions.map(suggestion =>
            this.#merge(suggestion.votingEntries, suggestion.name)
          )
        );

        this.snackbar.show(`All suggested items were merged.`);
      }

      this.mergeSuggestionsService.generateSuggestions(this.votingEntries);
    } catch (e) {
      this.snackbar.exception(e);
    }
  });

  mergeAndApproveSuggestedTask = task(
    { drop: true },
    async (votingEntries: VotingEntryModel[], proposedName: string) => {
      try {
        if (isArray(votingEntries) && proposedName) {
          const mergedEntry = await this.#merge(votingEntries, proposedName);

          await this.#approve(mergedEntry);

          this.snackbar.show(`${votingEntries.length} suggested items were merged and approved.`);
        } else {
          await RSVP.all(
            this.mergeSuggestionsService.suggestions.map(async suggestion => {
              const mergedEntry = await this.#merge(suggestion.votingEntries, suggestion.name);

              return this.#approve(mergedEntry);
            })
          );

          this.snackbar.show(`All suggested items were merged and approved.`);
        }

        this.mergeSuggestionsService.generateSuggestions(this.votingEntries);
      } catch (e) {
        this.snackbar.exception(e);
      }
    }
  );

  approveSelectedTask = task({ drop: true }, async (votingEntries: VotingEntryModel[]) => {
    try {
      await RSVP.all(votingEntries.map(votingEntry => this.#approve(votingEntry)));

      this.snackbar.show(doneMessage(votingEntries.length, 'selected', 'items', 'approved'));
    } catch (e) {
      this.snackbar.exception(e);
    }
  });

  moveTask = task({ drop: true }, async (votingEntry: VotingEntryModel, openMatchup: any, targetMatchupId: number) => {
    let result = null;

    const matchupEntry = await this.store.findRecord('matchup-entry', votingEntry.matchupEntryId);
    const targetMatchup = this.store.peekRecord('matchup', targetMatchupId);

    if (targetMatchup) {
      void set(matchupEntry, 'matchup', targetMatchup);
    }

    try {
      result = await matchupEntry.save();

      set(votingEntry, 'matchupId', targetMatchupId);

      this.snackbar.show('The entrant has been moved to the category', targetMatchup?.name).then(() => {
        openMatchup(targetMatchupId);
      });
    } catch (e) {
      this.snackbar.exception(e);
    }

    return result;
  });

  updateTask = task({ drop: true }, async (votingEntry: VotingEntryModel, options) => {
    setProperties(votingEntry, options);

    try {
      await votingEntry.save();
    } catch (e) {
      this.snackbar.exception(e);
    }
  });

  #approve(votingEntry: VotingEntryModel | null) {
    if (!votingEntry) return;

    votingEntry.statusTypeId = this.enums.findWhere('STATUS_TYPE', { name: 'Active' });

    return votingEntry.save();
  }

  async #merge(votingEntries: VotingEntryModel[], proposedName?: string) {
    const best: any = getBestMasterVotingEntry(votingEntries.slice().sortBy('isActive').reverse(), proposedName);
    const obsoleteEntries = votingEntries.reject(x => x === best);

    const name = sanitizeString(proposedName ? proposedName : best.name);
    const useOldEntry = sanitizeString(best.name).toLowerCase() === name.toLowerCase();
    const id = useOldEntry ? best.id : null;
    const serializedObject = best.serialize();

    const response: any = await this.session.request(endpoint('voting_entries_merge'), {
      type: 'PUT',
      data: {
        voting_entries: [
          {
            ...serializedObject,
            id,
            name,
            child_entries: useOldEntry ? obsoleteEntries.map(({ id }) => id) : votingEntries.map(({ id }) => id),
          },
        ],
      },
    });

    this.store.pushPayload({
      votingEntries: response.voting_entries,
    });

    const votingEntry = this.store.peekRecord(
      'voting-entry',
      response.voting_entries.find((ve: any) => !ve.master_entry_id)?.id
    );

    return votingEntry;
  }

  @action
  cleanup() {
    this.mergeSuggestionsService.resetSuggestions();
  }
}
