import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  class=\"facebook-login-button\"\n  type=\"button\"\n  {{on \"click\" this.loginWithFacebook}}\n>\n  <span class=\"facebook-login-button__icon material-icons\">facebook</span>\n  <p class=\"facebook-login-button__text\">\n    {{if @useContinueWith \"Continue With Facebook\" \"Log In With Facebook\"}}\n  </p>\n</button>\n", {"contents":"<button\n  class=\"facebook-login-button\"\n  type=\"button\"\n  {{on \"click\" this.loginWithFacebook}}\n>\n  <span class=\"facebook-login-button__icon material-icons\">facebook</span>\n  <p class=\"facebook-login-button__text\">\n    {{if @useContinueWith \"Continue With Facebook\" \"Log In With Facebook\"}}\n  </p>\n</button>\n","moduleName":"partner/components/facebook-login-button.hbs","parseOptions":{"srcName":"partner/components/facebook-login-button.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class FacebookLoginButtonComponent extends Component {
  @action
  loginWithFacebook() {
    window.FB.login(() => false, {
      scope:
        'pages_show_list,pages_manage_metadata,pages_read_engagement,instagram_basic,instagram_manage_comments,business_management',
    });
  }
}
