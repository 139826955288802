/**
 * A curried function that takes a regular expression and returns a function that takes the string to search
 * and an object that holds a mapping of what to search for and what to replace the matches with.
 *
 * Example 1A:
 * Given the RegEx \{{JohnTravolta}}\g
 * Returns: (string, tokensToReplace) => string.replace(\{{JohnTravolta}}\g, match => tokensToReplace[match])
 *
 * @param {Object}
 * @returns {Function}
 */
export default function replaceTokensInPreview(tokenRegEx) {
  /**
   * Example 2B:
   * Given the String '<p>{{JohnTravolta}}</p>'
   * Given the Object { '{{JohnTravolta}}': 'Nicolas Cage' }
   * Returns: '<p>Nicolas Cage</p>'
   *
   * @param {String}
   * @param {Object}
   * @returns {String}
   */
  return (string, tokensToReplace) => string.replace(tokenRegEx, match => tokensToReplace[match]);
}
