import { later } from '@ember/runloop';
import { Promise as EmberPromise } from 'rsvp';

export const wait = function (ms) {
  return new EmberPromise(resolve => {
    later(null, resolve, ms);
  });
};

export default {
  wait,
};
