/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { attr, belongsTo } from '@ember-data/model';
import { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import isDate from 'lodash/isDate';
import BaseModel from 'secondstreet-common/models/base';

const NOT_ENOUGH_FOLLOWERS_ID = [2069016, 200];

export default BaseModel.extend({
  //region Properties
  statusTypeId: attr('number'),
  rootFolder: attr('string'),
  isPrimary: attr('boolean'),
  externalAppId: attr('string'),
  startDate: attr('date'),
  endDate: attr('date'),
  publishErrorCodeId: attr('number'),
  publishErrorMessage: attr('string'),
  //endregion

  //region Computed Properties
  isComplete: computed('startDate', 'endDate', 'hasDirtyAttributes', function () {
    const isDirty = this.hasDirtyAttributes;

    const currentStartDate = this.startDate;
    const oldStartDate = get(this, '_data.startDate');
    const hasStartDate = isDate(currentStartDate);
    const hadStartDate = isDate(oldStartDate);

    const currentEndDate = this.endDate;
    const oldEndDate = get(this, '_data.endDate');
    const hasEndDate = isDate(currentEndDate);
    const hadEndDate = isDate(oldEndDate);

    if (!hasStartDate || !hasEndDate) {
      return false;
    } // No start or end date
    if (!hadEndDate && hasEndDate && isDirty) {
      return false;
    } // Didn't have, has now, not yet saved.
    if (!hadStartDate && hasStartDate && isDirty) {
      return false;
    } // Didn't have, has now, not yet saved.
    return true;
  }),
  status: computed('isComplete', function () {
    return this.isComplete ? 'bestPractice' : 'incomplete';
  }),
  url: computed('domain.hostname', 'rootFolder', function () {
    return `http://${get(this, 'domain.hostname')}/${this.rootFolder}`;
  }),
  shortcutUrl: computed('shortcutDomain.hostname', 'rootFolder', function () {
    return `http://${get(this, 'shortcutDomain.hostname')}/${this.rootFolder}`;
  }),
  activeStatus: computed('startDate', 'endDate', function () {
    const now = new Date();
    const { startDate } = this;
    const { endDate } = this;

    if (startDate && endDate) {
      if (now < startDate) {
        return 'Scheduled';
      }
      if (startDate <= now && now < endDate) {
        return 'Live';
      }
      if (now >= endDate) {
        return 'Inactive';
      }
      return;
    }
    return 'Inactive';
  }),
  facebookSrcUrl: computed('domain.facebookPage.picture.url', function () {
    return get(this, 'domain.facebookPage')
      ? get(this, 'domain.facebookPage.picture.url')
      : '/Images/facebook-flag-image.png';
  }),
  notEnoughFollowers: computed('publishErrorCodeId', function () {
    return NOT_ENOUGH_FOLLOWERS_ID.includes(this.publishErrorCodeId);
  }),
  //endregion

  //region Relationships
  domain: belongsTo('domain', { async: true, inverse: null }),
  domainType: alias('domain.domainType'),
  shortcutDomain: belongsTo('domain', { async: true, inverse: null }),
  organizationPromotion: belongsTo('organization-promotion', { async: true }),
  //endregion
});
