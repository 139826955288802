/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import { alias, equal, or } from '@ember/object/computed';
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo, hasMany } = DS;

/**
 * @typedef {DS.Model} Design
 * @implements DesignModel
 */
export default BaseModel.extend({
  //region Attributes
  name: attr('string'),
  renderedContent: attr('string', { defaultValue: '' }),
  audienceId: attr('number'), // TODO: Relationship
  isApproved: attr('boolean', { defaultValue: false }),
  designGuid: attr('string'),
  isLocked: attr('boolean', { defaultValue: false }),
  //endregion

  //region Relationships
  designTemplate: belongsTo('design-template'),
  emailNotification: belongsTo('email-notification', { async: false }),
  designTokenContents: hasMany('design-token-content', { async: false }),
  //endregion

  //region Properties
  /**
   * Toggled to true by the widget designer to show a complete state for the Embed Code designer flyout
   * @property {Boolean}
   */
  hasGrabbedCode: false,
  isTemplate: equal('designMethodType', 'Template'),
  isCustom: equal('designMethodType', 'Custom'),
  //endregion

  //region Computed Properties
  template: alias('designTemplate'),
  tokenContents: alias('designTokenContents'),
  /**
   * Used to calculate overall completeness of setup step
   * Determines whether or not to show a complete state for individual designs, if there are multiple
   */
  isComplete: computed('isTemplate', 'isApproved', 'designTokenContents.@each.isComplete', function () {
    if (this.isLegal) {
      if (this.isApproved) {
        return this.isTemplate ? this.designTokenContents.isEvery('isComplete') : true;
      }
      return false;
    }
    return this.designTokenContents.isEvery('isComplete');
  }),

  designMethodTypeId: alias('designTemplate.designMethodTypeId'),

  isEmbedded: equal('designTemplate.designTemplateType', 'Audience - Embedded'),

  isExitIntent: equal('designTemplate.designTemplateType', 'Audience - Exit Intent'),

  isPromotion: equal('designTemplate.designTemplateType', 'Promotion'),

  isDashboardShare: equal('designTemplate.designTemplateType', 'Dashboard Share'),

  isPrivacyPolicy: equal('designTemplate.designTemplateType', 'Privacy Policy'),

  isRules: equal('designTemplate.designTemplateType', 'Rules'),

  isTermsOfService: equal('designTemplate.designTemplateType', 'Terms of Service'),

  isLegal: or('isPrivacyPolicy', 'isRules', 'isTermsOfService'),

  designMethodType: enums.computed('name', 'designMethodTypeId', 'designMethodTypeId', 'DESIGN_METHOD_TYPE'),

  iconName: computed('isEmbedded', function () {
    return this.isEmbedded ? 'art_track' : 'settings_overscan';
  }),
  //endregion

  destroyRecord() {
    const { emailNotification } = this;
    this._super().then(() => {
      if (emailNotification) {
        emailNotification.destroyRecord();
      }
    });
  },
});
