import BaseModel from 'secondstreet-common/models/base';
import { attr } from '@ember-data/model';

export default class PromotionCopyModel extends BaseModel {
  @attr('number') declare destinationOrganizationId: number;
  @attr('number') declare processingStatusType: number;
  @attr('number') declare promotionCopyType: number;
  @attr('number') declare sourceOrganizationPromotionId: number;
  @attr('number') declare sourcePromotionId: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'promotion-copy': PromotionCopyModel;
  }
}
