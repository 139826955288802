import { get } from '@ember/object';
import flatten from 'lodash/flatten';

/**
 * Completely unloads an Ember Data record, performing the necessary incantations first.
 * @deprecated Just use `store.unloadRecord(record)` instead
 * @param {DS.Model} record
 * @param {DS.Store} store
 */
export const safeUnload = function (record, store) {
  store.unloadRecord(record);
};

/**
 * Gets a flat array of all the grandchildren in a relationship.
 * For example, nestedRelationship(Array<FormPage>, 'formField') gets an array of all FormFields belonging to all the FormPages.
 * @param {DS.Model[]} arr
 * @param {String} rel - Must point to a DS.hasMany relationship on each item in `arr`.
 */
export const nestedRelationship = (arr, rel) => flatten(arr.map(x => (get(x, rel) || []).toArray())).compact();

export default {
  safeUnload,
  nestedRelationship,
};
