/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/avoid-leaking-state-in-ember-objects */ // FIXME
import Controller from '@ember/controller';
import { sort } from '@ember/object/computed';
import NeedsOrganizationPromotionController from 'partner/mixins/needs-organization-promotion-controller';

export default Controller.extend(NeedsOrganizationPromotionController, {
  //region Properties
  sortProperties: ['startPercent:asc', 'name:asc'],
  //endregion

  //region Computed Properties
  arrangedContent: sort('model', 'sortProperties'),
  //endregion
});
