import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import type CurrentService from 'partner/services/current';
import type Store from '@ember-data/store';
import type { ModelFrom } from 'partner/utils/types';

export type Model = ModelFrom<IndexRoute>;

/**
 * OrganizationPromotion Index Route
 * /o/:organization_id/op/:organization_promotion_id
 * @type {Ember.Route}
 */
export default class IndexRoute extends Route {
  @service declare current: CurrentService;
  @service declare router: RouterService;
  @service declare store: Store;

  async model() {
    const shouldGoToSetup = this.current.promotion?.isTimeSensitive
      ? await this.hasNotStared()
      : this.current.promotion?.isFresh;

    return { shouldGoToSetup };
  }

  /**
   * Redirect the user to setup or dashboard, based on the state of their promotion.
   */
  redirect({ shouldGoToSetup }: Model) {
    const route = shouldGoToSetup
      ? 'organizations.organization.organization-promotions.organization-promotion.setup'
      : 'organizations.organization.organization-promotions.organization-promotion.dashboard';

    void this.router.transitionTo(route);
  }

  private async hasNotStared() {
    const today = new Date();
    const matchups = await this.store.query('matchup', { excludeSecondaryMatchups: true });

    return !matchups.any(matchup => today >= matchup.startDate);
  }
}
