/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { Promise as EmberPromise } from 'rsvp';

export default Component.extend({
  store: service(),

  campaign: computed('audience-filter.entityId', function () {
    const entityId = get(this, 'audience-filter.entityId');

    return entityId ? this.store.find('messageCampaign', entityId) : EmberPromise.resolve();
  }),
});
