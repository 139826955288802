/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import $ from 'jquery';

export default Component.extend({
  //region Attributes
  'min-font-size': 8,
  'max-font-size': 528,
  //endregion

  //region Ember Hooks
  didRender() {
    this._super(...arguments);
    $(this.element).bigtext({
      minfontsize: this['min-font-size'],
      maxfontsize: this['max-font-size'],
    });
  },
  //endregion
});
