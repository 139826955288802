/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

/**
 * TaggableEntityCategory Route
 * /o/:organization_id/data/tags/categories/:taggable_entity_category_id
 * @type {Ember.Route}
 * @mixes AppContextRoute
 */
export default Route.extend(AppContextRoute, {
  store: service(),

  //region Ember Hooks
  async model(params) {
    const taggableEntityCategoryId = params.taggable_entity_category_id;
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');

    return RSVP.hash({
      taggableEntityCategory: this.store.find('taggable-entity-category', taggableEntityCategoryId),
      taggableEntities: this.store.query('taggable-entity', {
        taggableEntityCategoryId,
        tier: 1,
        organizationId,
      }),
    });
  },
  //endregion
});
