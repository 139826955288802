import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class MarkdownEditor extends Component {
  //region Properties
  @tracked isEditing = false;
  //endregion

  //region Methods
  @action
  stopEditing(callback = () => {}) {
    this.isEditing = false;
    return callback();
  }

  @action
  safeTriggerCallback(callbackName, ...args) {
    this.args[callbackName]?.(...args);
  }
  //endregion
}
