/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import { hash } from 'rsvp';

export default Route.extend(AppContextRoute, {
  store: service(),

  //region Ember Hooks
  model() {
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');
    return hash({
      organization: get(this.modelFor('organizations.organization'), 'organization'),
      messageCampaignCategories: this.store
        .query('messageCampaignCategory', {
          organizationId,
          isInherited: false,
        })
        .then(x => x.toArray()),
    });
  },
  //endregion
});
