import { modifier } from 'ember-modifier';
import scrollIntoView, { type StandardBehaviorOptions } from 'scroll-into-view-if-needed';

export default modifier(
  (
    element,
    _positionalParams,
    {
      shouldScroll,
      options = { scrollMode: 'if-needed', behavior: 'smooth' },
    }: { shouldScroll: boolean; options: StandardBehaviorOptions }
  ) => {
    if (!shouldScroll) return;

    scrollIntoView(element, options);
  },
  { eager: false }
);
