import { helper } from '@ember/component/helper';
import _isEqual from 'lodash/isEqual';

/*
 * Performs a deep comparison between two values to determine if they are equivalent
 *
 * @param {*} value The value to compare.
 * @param {*} other The other value to compare.
 * @returns {Boolean} Returns true if the values are equivalent, else false.
 */
export function LodashIsEqual([value, other]) {
  return _isEqual(value, other);
}

export default helper(LodashIsEqual);
