/* global Redactor */

Redactor.add('plugin', 'images', {
  translations: {
    en: {
      images: {
        add: 'Add',
        image: 'Image',
        cancel: 'Cancel',
        addImage: 'Add Image',
        addViaUrl: 'Add via URL',
        uploadImage: 'Upload Image',
        searchLibrary: 'Search Library',
        addImageFromUrl: 'Add Image From URL',
        placeholder: 'Drag to upload a file<br>or click to select',
      },
    },
  },
  defaults: {
    disableLibrary: false,
    uploadRoute: '',
    isMessageCampaign: false,
  },
  subscribe: {
    'images.changedLibraryImage'(event) {
      if (!event.params) {
        return;
      }

      if (event.params.dipsUrl) {
        this.insertImage(`${event.params.dipsUrl}?width=1200`);
      } else {
        this.insertImage(event.params);
      }

      this.app.dropdown.close();
    },
  },
  start() {
    const button = {
      title: '## images.image ##',
      icon: '<i class="material-icons-outlined text-lg text-black leading-none">add_photo_alternate</i>',
      command: 'images.popup',
      position: {
        before: 'bold',
      },
      blocks: {
        all: true,
        except: ['image'],
      },
    };

    this.app.toolbar.add('addimage', button);
    this.app.addbar.add('addimage', button);

    this.app.hotkeys.add('ctrl+shift+i, meta+shift+i', {
      title: 'Add image',
      name: 'meta+shift+i',
      command: 'images.popup',
    });
  },
  popup(e, button) {
    const items = {
      link: {
        title: '## images.addViaUrl ##',
        icon: '<i class="material-icons-outlined text-lg text-black">link</i>',
        command: 'images.linkToImage',
      },
    };

    if (!this.opts.get('images.disableLibrary')) {
      items.search = {
        title: '## images.searchLibrary ##',
        icon: '<i class="material-icons-outlined text-lg text-black">image_search</i>',
        command: 'images.searchImage',
      };
    }

    // if file upload is supported by browser
    if (typeof window.FormData !== 'undefined') {
      items.upload = {
        title: '## images.uploadImage ##',
        icon: '<i class="material-icons-outlined text-lg text-black">publish</i>',
        command: 'images.uploadImage',
      };
    }

    const dropdown = this.app.create('dropdown', 'images', {
      title: '## images.addImage ##',
      width: '220px',
      items,
    });

    this.app.dropdown.open(e, button, dropdown);
  },
  uploadImage(button) {
    const stack = this.app.create('stack');
    const selection = this.app.create('selection');

    stack.create('uploadimage', {
      title: '## images.uploadImage ##',
      width: '300px',
      form: {
        file: {
          type: 'upload',
          upload: {
            type: 'file',
            box: true,
            url: this.opts.get('images.uploadRoute'),
            data: false,
            placeholder: 'Drag to upload a file or click to select',
            success: 'images.onFileSuccess',
          },
        },
      },
    });

    stack.setData({ title: selection.getText() });

    this.app.modal.open({ name: 'uploadimage', stack, button });
  },
  searchImage() {
    this.app.dropdown.close();
    this.app.broadcast('images.searchImage');
  },
  linkToImage(button) {
    const stack = this.app.create('stack');

    stack.create('linkimage', {
      title: '## images.addImageFromUrl ##',
      width: '300px',
      form: {
        url: { type: 'input' },
      },
      footer: {
        save: { title: '## images.add ##', command: 'images.addFromUrl', type: 'primary' },
        cancel: { title: '## images.cancel ##', command: 'popup.close' },
      },
    });

    const instance = this.app.block.get();

    if (instance) {
      const $block = instance.getBlock();
      const url = $block.attr('url');

      stack.setData({ url });
    }

    this.app.modal.open({ name: 'linkimage', stack, button });
  },
  addFromUrl(stack) {
    this.app.modal.close();

    const data = stack.getData();

    if (!data.url) return;

    this.insertImage(data.url);
  },
  onFileSuccess(response) {
    this.app.modal.close();

    let imageWidth = '100%';

    if (this.opts.get('images.isMessageCampaign')) {
      const width = this.opts.get('images.width');
      imageWidth = `${width <= 600 ? width : 600}`;
    }

    response['media_items'].forEach(file => {
      this.insertImage(file.dips_url, { width: imageWidth });
    });
  },
  insertImage(url, params = { width: '100%' }) {
    const selection = this.app.create('selection');
    const caret = this.app.create('caret');

    //firefox has cursor in wrong position
    if (selection.getBlock()) {
      caret.set(selection.get().current, 'after');
    }

    const images = {
      image: {
        url,
        alt: 'Image',
        width: params.width,
      },
    };

    this.app.image.insert(images);
  },
});
