/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/named-functions-in-promises */ // FIXME
import Controller from '@ember/controller';
import { computed, get, set, setProperties } from '@ember/object';
import { empty, sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import flattenDeep from 'lodash/flattenDeep';
import uniq from 'lodash/uniq';

export default Controller.extend({
  //region Dependencies
  permissions: service(),
  enums: service(),
  store: service(),
  //endregion

  //region Properties
  activeCategory: null,
  promotionSearchResults: null,
  isAddingImageFromLibrary: false,
  isAddImageFlyoutOpen: false,
  //endregion

  //region Computed Properties
  categoryIncomplete: empty('newCategory.name'),
  turnkeyCategories: computed('model.sortedCategories.length', function () {
    return get(this, 'model.sortedCategories');
  }),
  /**
   * An array of turnkeys in the "Trending Now" category
   * @type {Ember.ComputedProperty}
   * @returns {Array}
   */
  trendingTurnkeys: computed('promotionPresets.[]', function () {
    const trendingCategoryId = this.enums.findWhere('TURNKEY_CATEGORY', { name: 'Trending Now' });
    const trending = get(this, 'model.turnkeyCategories').filter(
      category => get(category, 'id') === trendingCategoryId
    );
    return !isEmpty(trending) ? get(trending, 'firstObject.promotionPresets') : [];
  }),
  promotionPresets: computed(
    'model.turnkeyCategories.@each.promotionPresets',
    'targetCategory.promotionPresets.length',
    function () {
      return flattenDeep(this.model.turnkeyCategories.map(category => category.promotionPresets.toArray()));
    }
  ),
  /**
   * A list of promotionIds for all of the promotionPresets
   * in targetCategory
   * @type {Ember.ComputedProperty}
   * @returns {Array}
   */
  targetPromotionPresetIds: computed('targetCategory', function () {
    return get(this, 'targetCategory.promotionPresets').map(x => get(x, 'promotionId'));
  }),
  promotionPresetsSorting: computed(() => ['isFeatured:desc', 'dateCreated:desc']),
  sortedTurnkeys: sort('trendingTurnkeys', 'promotionPresetsSorting'),
  /**
   * organizationIds for all of the organizations the current user has access to
   * @type {Ember.ComputedProperty}
   * @returns {Array}
   */
  organizationIds: computed('model.organizations.[]', function () {
    return get(this, 'model.organizations').map(org => parseInt(get(org, 'id'), 10));
  }),
  /**
   * All categorized promotionPresets (this does NOT include trending turnkeys)
   * @type {Ember.ComputedProperty}
   * @returns {Array}
   */
  customPromotionPresets: computed('promotionPresets.[]', 'organizationIds', function () {
    const { organizationIds } = this;
    return this.promotionPresets.filter(preset => organizationIds.indexOf(get(preset, 'organizationId')) > -1);
  }),
  /**
   * Array of unique categorized and trending custom promotionPresets
   * @type {Ember.ComputedProperty}
   * @returns {Array}
   */
  uniqueCustomPromotionPresets: computed('promotionPresets.[]', 'organizationIds', 'sortedTurnkeys.[]', function () {
    const { organizationIds } = this;
    const allPromotionPresets = this.promotionPresets.concat(this.sortedTurnkeys);
    const allUniquePromotionPresets = uniq(allPromotionPresets, x => get(x, 'name'));
    return allUniquePromotionPresets.filter(preset => organizationIds.indexOf(get(preset, 'organizationId')) > -1);
  }),
  /**
   * The rollup turnkeyCategory for all custom promotionPresets
   * @type {Ember.ComputedProperty}
   * @returns {TurnkeyCategory}
   */
  customCategory: computed('uniqueCustomPromotionPresets.[]', function () {
    return {
      name: 'Custom',
      displayOrder: 0,
      promotionPresets: this.uniqueCustomPromotionPresets,
    };
  }),
  /**
   * All turnkeyCategories to be displayed, including the customCategory
   * @type {Ember.ComputedProperty}
   * @returns {Array}
   */
  displayCategories: computed('customCategory', 'model.sortedCategories.[]', function () {
    return [this.customCategory].concat(get(this, 'model.sortedCategories'));
  }),
  //endregion

  init() {
    this._super(...arguments);

    set(this, 'promotionSearchResults', this.promotionSearchResults || []);
  },

  //region Actions
  actions: {
    showTurnkeyRemoveConfirmation(turnkey, category) {
      set(this, 'turnkeyToBeDeleted', turnkey);
      set(this, 'targetCategory', category);
      this.toggleProperty('showRemoveTurnkeyConfirmation');
    },
    setActiveCategory(category = null) {
      set(this, 'activeCategory', category);
    },
    addTurnkey(targetCategory) {
      set(this, 'targetCategory', targetCategory);
      set(this, 'isAddingTurnkey', true);
    },
    removeTurnkey() {
      const turnkey = this.turnkeyToBeDeleted;
      get(this, 'targetCategory.promotionPresets').removeObject(turnkey);
      if (get(turnkey, 'turnkeyCategories.length') === 0) {
        turnkey.destroyRecord();
      } else {
        turnkey.save();
      }
      set(this, 'turnkeyToBeDeleted', null);
      set(this, 'targetCategory', null);
      this.toggleProperty('showRemoveTurnkeyConfirmation');
    },
    cancelTurnkeyRemove() {
      set(this, 'turnkeyToBeDeleted', null);
      this.toggleProperty('showRemoveTurnkeyConfirmation');
    },
    cancelCreateCategory() {
      set(this, 'isCreatingCategory', false);
      this.newCategory.rollbackAttributes();
      set(this, 'newCategory', null);
    },
    createCategory() {
      const newCategory = this.store.createRecord('turnkeyCategory');
      set(this, 'newCategory', newCategory);
      set(this, 'isCreatingCategory', true);
    },
    editCategory(category) {
      set(this, 'newCategory', category);
      set(this, 'isCreatingCategory', true);
    },
    stopAddingTurnkey() {
      set(this, 'isAddingTurnkey', false);
      set(this, 'targetCategory', null);
    },
    finishCreatingCategory() {
      if (!get(this, 'newCategory.id')) {
        get(this, 'model.sortedCategories').addObject(this.newCategory);
      }
      this.newCategory.save();
      set(this, 'isCreatingCategory', false);
      set(this, 'newCategory', null);
    },
    changeImage(item) {
      set(this, 'selectedTurnkey', item);
      set(this, 'isChangingImage', true);
    },
    stopChangingImage() {
      this.selectedTurnkey.save();
      set(this, 'isChangingImage', false);
      set(this, 'selectedTurnkey', null);
    },
    addCategoryMediaItem(newMediaItem) {
      set(this, 'isAddImageFlyoutOpen', false);
      set(this, 'newCategory.mediaItemId', get(newMediaItem, 'id'));
    },
    addTurnkeyMediaItem(newMediaItem) {
      set(this, 'isAddImageFlyoutOpen', false);
      set(this, 'selectedTurnkey.mediaItemId', get(newMediaItem, 'id'));
    },
    addPromotion(promotionSummary) {
      const presetExists = this.promotionPresets.includes(
        this.promotionPresets.findBy('promotionId', parseInt(get(promotionSummary, 'promotionId')))
      );
      if (presetExists) {
        const existingPreset = this.promotionPresets.findBy(
          'promotionId',
          parseInt(get(promotionSummary, 'promotionId'))
        );
        return get(existingPreset, 'turnkeyCategories').then(array => {
          array.addObject(this.targetCategory);
          set(this, 'isAddingTurnkey', false);
          existingPreset.save().then(() => {
            set(this, 'targetCategory', null);
            return set(this, 'isAddingTurnkey', false);
          });
        });
      }
      const newTurnkey = this.store.createRecord('promotionPreset');
      setProperties(newTurnkey, {
        name: get(promotionSummary, 'promotionName'),
        promotionId: get(promotionSummary, 'promotionId'),
        organizationId: get(promotionSummary, 'organizationId'),
        statusTypeId: 1,
      });
      get(newTurnkey, 'turnkeyCategories').then(array => {
        array.addObject(this.targetCategory);
      });
      get(this, 'targetCategory.promotionPresets').addObject(newTurnkey);
      newTurnkey.save().then(() => {
        set(this, 'targetCategory', null);
        return set(this, 'isAddingTurnkey', false);
      });
    },
    removePromotion(promotionSummary) {
      const turnkey = get(this, 'targetCategory.promotionPresets').findBy(
        'promotionId',
        parseInt(get(promotionSummary, 'promotionId'))
      );
      get(this, 'targetCategory.promotionPresets').removeObject(turnkey);
      if (get(turnkey, 'turnkeyCategories.length') === 0) {
        turnkey.destroyRecord();
        set(this, 'targetCategory', null);
        return set(this, 'isAddingTurnkey', false);
      }
      turnkey.save().then(() => {
        set(this, 'targetCategory', null);
        return set(this, 'isAddingTurnkey', false);
      });
    },
  },
  //endregion
});
