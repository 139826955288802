import Component from '@glimmer/component';

export default class SsTextAreaComponent extends Component {
  get hasErrors() {
    if (!this.args.errors) {
      return false;
    }

    return this.args.errors.has(this.args.attribute);
  }
}
