/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ParamsType } from 'partner/services/preview';
import { statusText } from './ballot-group';
import { header } from './common';

const DESCRIPTIONS = [
  'Sugar plum jujubes brownie cheesecake marshmallow. Toffee brownie cake candy canes bonbon gingerbread chupa chups. Gingerbread danish croissant gummies brownie icing ice cream.',
  'Oat cake dessert sugar plum marzipan tart carrot cake donut lollipop. Wafer chocolate cake jujubes sweet. Halvah jelly fruitcake donut gingerbread. Cheesecake bonbon topping marshmallow tootsie roll oat cake powder oat cake. Tart sesame snaps jelly beans. Jujubes sweet roll chocolate cake. Soufflé soufflé cupcake.',
];

const getDescription = () => {
  return DESCRIPTIONS[Math.floor(Math.random() * 2)];
};

const getVoteButtonBlock = () => {
  return {
    id: 'box-34',
    uniqueTypeNumber: '34',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'button-2',
        uniqueTypeNumber: '2',
        deletable: true,
        type: 'promotion-button',
        blocks: [],
        data: {
          href: '',
          text: 'VOTE',
          target: '_blank',
          url: '',
        },
        styles: {
          border: {
            radius: 5,
          },
          spacing: {
            paddingBlock: 8,
            paddingInline: 18,
          },
          background: {
            type: 'color',
            color: '#4caf50',
          },
          font: {
            color: '#ffffff',
            align: 'center',
          },
        },
      },
    ],
    data: {},
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        justify: 'end',
      },
      spacing: {
        paddingInline: '16',
      },
    },
  };
};

const getMenuButton = () => {
  return {
    uniqueTypeNumber: '17',
    deletable: true,
    type: 'promotion-button',
    blocks: [],
    data: {
      href: '',
      text: `<i class="material-icons text-lg text-gray-800">menu</i>`,
      target: '_blank',
      url: '',
      additionalCss:
        '.arc-promotion-promotion-0 .arc-promotion-button-promotion-button-17 {\n max-width: none; display: flex;\n     align-items: center;\n  }\n  \n  ',
    },
    styles: {
      border: {
        radius: 4,
        style: 'solid',
        width: 1,
        color: '#838fa0',
      },
      spacing: {
        marginBlock: 20,
        marginInline: 10,
        paddingBlock: 8,
        paddingInline: 8,
      },
      background: {
        type: 'color',
        color: '#eeeeee',
      },
      size: {
        custom: true,
        height: '38',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const card = ({ id, narrowView }: { id: string; narrowView?: boolean }) => {
  return {
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'image-6',
        uniqueTypeNumber: '6',
        deletable: true,
        type: 'promotion-image',
        blocks: [],
        data: {
          src: 'https://media.secondstreetapp.com/250194?width=1170&height=1170&quality=60',
          alt: '',
          width: 200,
          height: 200,
          url: '',
          target: '_blank',
        },
        styles: {
          border: {
            radius: '',
            topLeftRadius: '4',
            topRightRadius: '4',
          },
        },
      },
      {
        uniqueTypeNumber: '27',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 3,
          text: 'Entrant Name',
        },
        styles: {
          font: {
            color: '#252b31',
            size: 18,
            family: 'Open Sans',
            weight: 600,
          },
          spacing: {
            marginBlock: '8',
            marginInline: '16',
          },
        },
      },
      {
        id: 'heading-28',
        uniqueTypeNumber: '28',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: getDescription(),
        },
        styles: {
          font: {
            color: '#6b7786',
            family: 'Helvetica,sans-serif',
            weight: 400,
            size: '14',
            height: '21',
          },
          spacing: {
            marginBlock: '',
            marginInline: '16',
          },
        },
      },
      getVoteButtonBlock(),
    ],
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-${id} {\n  height:fit-content;
        ${
          narrowView
            ? 'box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), inset 0 3px 1px -2px rgba(0, 0, 0, 0.12);'
            : ''
        }
      \n  }\n  \n  `,
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
      },
      size: {
        width: '210',
      },
      border: {
        radius: '4',
        style: narrowView ? 'none' : 'solid',
        width: '1',
        color: '#bfc6ce',
      },

      spacing: {},
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
  };
};

const card2 = ({ id, narrowView }: { id: string; narrowView?: boolean }) => {
  return {
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        uniqueTypeNumber: '29',
        deletable: true,
        type: 'promotion-box',
        blocks: [
          {
            uniqueTypeNumber: '18',
            deletable: true,
            type: 'promotion-heading',
            blocks: [],
            data: {
              level: 3,
              text: 'Entrant Name',
            },
            styles: {
              font: {
                color: '#000000',
                size: narrowView ? 14 : 18,
                family: 'Open Sans',
              },
              spacing: {},
            },
          },
          {
            uniqueTypeNumber: '24',
            deletable: true,
            type: 'promotion-heading',
            blocks: [],
            data: {
              level: 3,
              text: `<span class="material-icons text-base text-gray-500 relative ">place</span> 123 Example Road`,
              additionalCss:
                '.arc-promotion-promotion-0 .arc-promotion-button-promotion-button-24 span {\n  display: flex;\n     align-items: center;\n  }\n  \n  ',
            },
            styles: {
              font: {
                color: '#6b7786',
                size: 14,
                family: 'Open Sans',
              },
              spacing: {},
            },
          },
        ],
        styles: {
          layout: {
            display: 'flex',
            direction: 'column',
            gapX: 2,
            gapY: 2,
          },

          spacing: {
            paddingInline: narrowView ? '' : 16,
            paddingBlock: narrowView ? '' : 16,
          },
        },
      },
      getVoteButtonBlock(),
    ],
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-${id} {\n ${
        narrowView ? 'border-bottom: 1px solid #e0e0e0;' : ''
      }  height:fit-content; \n  }\n  \n  `,
    },
    styles: {
      layout: {
        display: 'flex',
        direction: narrowView ? 'row' : 'column',
        gapX: narrowView ? 16 : 0,
        gapY: narrowView ? 16 : 0,
      },
      size: {
        width: '210',
      },
      border: {
        radius: '4',
        style: narrowView ? 'none' : 'solid',
        width: '1',
        color: '#bfc6ce',
      },

      spacing: {
        paddingBlock: narrowView ? 16 : 0,
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
  };
};

const textCategory = (id: string) => {
  return {
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-heading',
    blocks: [],
    data: {
      level: 1,
      text: 'Category',
      additionalCss: ``,
    },
    styles: {
      font: {
        color: '#000000',
        weight: 700,
        family: 'Open Sans',
        size: '18',
        align: 'center',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

export const mainLayout = (blocks: any[]) => {
  return {
    uniqueTypeNumber: '5',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        uniqueTypeNumber: '6',
        deletable: true,
        type: 'promotion-box',
        blocks: [getMenuButton()],
        data: {
          additionalCss:
            '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-6 {\n height: auto; border-right: 1px solid #bfc6ce; \n  }\n  \n  ',
        },
        styles: {
          layout: {
            display: 'flex',
            direction: 'row',
          },
          size: {
            custom: true,
            width: 27,
          },
          background: {
            type: 'color',
            color: '#eeeeee',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      ...blocks,
    ],
    data: {
      additionalCss: '',
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'row',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

export const featuredEntrants = (params?: { gridColumnLayout?: string; narrowView?: boolean }) => {
  return {
    uniqueTypeNumber: '17',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        uniqueTypeNumber: '16',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 3,
          text: 'Featured Entrants',
        },
        styles: {
          font: {
            color: '#9e9e9e',
            size: 16,
            family: 'Open Sans',
          },
          spacing: {
            paddingInline: 16,
          },
        },
      },
      {
        uniqueTypeNumber: '1',
        deletable: true,
        type: 'promotion-grid',
        blocks: [
          card({ id: '21', narrowView: params?.narrowView }),
          card({ id: '22', narrowView: params?.narrowView }),
          ...(params?.gridColumnLayout ? [card({ id: '22' })] : []),
        ],
        data: {
          columns: params?.gridColumnLayout || '1:1',
          additionalCss: '.arc-promotion-promotion-0 .arc-grid-grid-1 {\n  \n  }\n  \n  ',
        },
        styles: {
          layout: {
            display: 'grid',
            direction: 'row',
            gapX: '20',
            gapY: '20',
          },
          size: {},
          spacing: {
            paddingInline: 20,
          },
        },
        dataMobile: {
          columns: '1',
        },
        stylesMobile: {},
        dataTablet: {
          columns: '1:1',
        },
        stylesTablet: {},
      },
    ],
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        gapX: 8,
        gapY: 8,
      },
      spacing: {
        paddingBlock: params?.narrowView ? 0 : 16,
      },
    },
  };
};

export const allEntrants = (params?: { narrowView?: boolean }) => {
  return {
    uniqueTypeNumber: '18',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        uniqueTypeNumber: '16',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 3,
          text: 'Entrants',
        },
        styles: {
          font: {
            color: '#9e9e9e',
            size: 16,
            family: 'Open Sans',
          },
          spacing: {
            paddingInline: 16,
          },
        },
      },
      {
        uniqueTypeNumber: '2',
        deletable: true,
        type: 'promotion-grid',
        blocks: [
          card2({ id: '31', narrowView: params?.narrowView }),
          card2({ id: '32', narrowView: params?.narrowView }),
        ],
        data: {
          columns: params?.narrowView ? '1' : '1:1',
          additionalCss: '.arc-promotion-promotion-0 .arc-grid-grid-2 {\n  \n  }\n  \n  ',
        },
        styles: {
          layout: {
            display: 'grid',
            direction: 'row',
            gapX: '20',
            gapY: '20',
          },
          size: {},
          spacing: {
            paddingInline: 20,
          },
        },
        dataMobile: {
          columns: '1',
        },
        stylesMobile: {},
        dataTablet: {
          columns: '1:1',
        },
        stylesTablet: {},
      },
    ],
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        gapX: 8,
        gapY: 8,
      },
      spacing: {
        paddingBlock: 16,
      },
    },
  };
};

export default (params?: ParamsType) => [
  header({
    title: 'Already Voted?',
    promotionColor: params?.promotionColor,
    promotionTextColor: params?.promotionTextColor,
  }),
  mainLayout([
    {
      uniqueTypeNumber: '25',
      deletable: true,
      type: 'promotion-box',
      blocks: [
        statusText({
          marginTop: 16,
        }),
        {
          uniqueTypeNumber: '26',
          deletable: true,
          type: 'promotion-box',
          blocks: [textCategory('21'), featuredEntrants(), allEntrants()],
          data: {
            additionalCss:
              '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-26 {\n border-top: 1px solid #bfc6ce; border-bottom: 1px solid #bfc6ce; \n  }\n  \n  ',
          },
          styles: {
            layout: {
              display: 'flex',
              direction: 'column',
              align: 'center',
              justify: 'center',
              gapX: 20,
              gapY: 20,
            },
            spacing: {
              paddingInline: 16,
              paddingBlock: 16,
            },
          },
          dataMobile: {},
          stylesMobile: {},
          dataTablet: {},
          stylesTablet: {},
        },
      ],
      data: {
        additionalCss: '.arc-promotion-promotion-0 .arc-box-box-25 {\n  \n  }\n  \n  ',
      },
      styles: {
        layout: {
          display: 'flex',
          direction: 'column',
          align: 'center',
          justify: 'center',
          gapX: 20,
          gapY: 20,
        },
        spacing: {
          paddingInline: 16,
        },
      },
      dataMobile: {},
      stylesMobile: {},
      dataTablet: {},
      stylesTablet: {},
    },
  ]),
];
