import Controller from '@ember/controller';
import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { endpoint } from 'secondstreet-common/utils/url';

export default class extends Controller {
  @service('current') current;
  @service('router') router;
  @service('sentry') sentry;
  @service('session') session;
  @service('snackbar') snackbar;

  refreshDetectedFraud() {
    return getOwner(this)
      .lookup('route:organizations.organization.organization-promotions.organization-promotion.moderate-people')
      .refreshDetectedFraud();
  }

  disqualifyUsersTask = task({ drop: true }, async () => {
    try {
      await this.session.request(
        endpoint('organization_promotion_users_bulk', {
          OrganizationPromotionId: this.current.organizationPromotion.id,
          DeactivateFraud: true,
        }),
        {
          type: 'PUT',
          data: {
            organization_promotion_users: [{ status_type_id: 2 }],
          },
        }
      );

      this.refreshDetectedFraud();
      this.router.transitionTo(
        'organizations.organization.organization-promotions.organization-promotion.moderate-people'
      );
      this.snackbar.show('All suspected fraudulent people were disqualified!');
    } catch (e) {
      this.sentry.captureException(e);
      this.snackbar.show('Something went wrong. Please try again later.');
    }
  });
}
