import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class={{@class}} id=\"qrcode\" {{did-insert this.initQRCode}}></div>\n", {"contents":"<div class={{@class}} id=\"qrcode\" {{did-insert this.initQRCode}}></div>\n","moduleName":"partner/components/two-factor-qr-code.hbs","parseOptions":{"srcName":"partner/components/two-factor-qr-code.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class TwoFactorQrCodeComponent extends Component {
  @action
  initQRCode() {
    const element = document.getElementById('qrcode');
    const { QRCode } = window;

    if (QRCode) {
      new QRCode(
        element,
        encodeURI(
          `otpauth://totp/Second Street:${this.args.userEmail}?secret=${this.args.secretKey}&issuer=Second Street`
        )
      );
    }
  }
}
