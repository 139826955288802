import Route from '@ember/routing/route';
import type { ModelFrom } from 'partner/utils/types';

export type Model = ModelFrom<LegalDesignTemplatesCommonRoute>;

export default class LegalDesignTemplatesCommonRoute extends Route {
  model() {
    return {
      privacyPolicyTemplates: this.designTemplateModel.privacyPolicyTemplates,
      rulesTemplates: this.designTemplateModel.rulesTemplates,
      termsOfServiceTemplates: this.designTemplateModel.termsOfServiceTemplates,
      designTokens: this.designTemplateModel.designTokens,
    };
  }

  private get designTemplateModel() {
    return this.modelFor('organizations.organization.legal.design-templates') as any;
  }
}
