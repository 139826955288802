import shareList from './share-list';
import ballotSideNav from './ballot-side-nav';
import ballotEntrantWide from './ballot-entrant-wide';
import featuredEntrantsPages from './ballot-featured-entrants-pages';

export default `
  <div class="ssUGCGallery font-['Open_Sans']">
    <div class="ssUserInfo template-color-primary-background-color">
      <div class="user-info-container"></div>
    </div>
    <div class="ssUGCGallery">
      ${ballotSideNav}
      <div class="ssGalleryWrapper bg-white !p-0 !pl-12 !pr-4 flex flex-col gap-4">
        <div class="round-info">
          <div class="round-info__voting-status">
            <div class="round-info__header">
                Vote until m/dd @ h:mm pm
            </div>
          </div>
        </div>
        <div class="w-full flex justify-evenly items-center sm:-ml-3">
          <div class="flex items-center text-gray-500 rounded pl-1.5 pr-2 py-0.5 mt-0.5 hover:bg-[#EEEEEE] cursor-pointer">
            <i class="material-icons text-2xl">keyboard_double_arrow_left</i>
            <div class="ml-2 font-bold text-sm hidden sm:block">Previous</div>
          </div>
          <div class="text-center text-lg font-bold py-4 mx-8">Category</div>
          <div class="flex items-center text-gray-500 rounded pl-2 pr-1.5 py-0.5 mt-0.5 hover:bg-[#EEEEEE] cursor-pointer">
            <div class="mr-2 font-bold text-sm hidden sm:block">Next</div>
            <i class="material-icons text-2xl">keyboard_double_arrow_right</i>
          </div>
        </div>
        ${featuredEntrantsPages}
        <div class="max-w-full px-0.5">
          <div>
            <div class="ssTileExternalHeaderContainer !mb-2 !ml-1">
              Entrants
            </div>
            <div class="grid sm:grid-cols-4 auto-rows-fr gap-x-4 gap-y-4">
              ${ballotEntrantWide}
              ${ballotEntrantWide}
              ${ballotEntrantWide}
              ${ballotEntrantWide}
            </div>
          </div>
        </div>
        <div class="flex w-min self-center gap-x-4">
          <div class="next-group border-2 border-solid border-green-600 rounded hover:bg-green-600 hover:text-white !mt-4 text-green-600 py-2 px-8 text-center inline-flex justify-center !w-min">
            <div class="inline-flex shrink overflow-hidden items-center">
              <i class="material-icons text-2xl inline-block shrink-0 leading-[0.5rem]">keyboard_arrow_left</i>
              <span class="group-label mx-2 uppercase whitespace-nowrap text-ellipsis overflow-hidden shrink hidden sm:inline">
                Category
              </span>
            </div>
          </div>
          <div class="next-group border-2 border-solid border-green-600 rounded hover:bg-green-600 hover:text-white !mt-4 text-green-600 py-2 px-8 text-center inline-flex justify-center !w-min">
            <div class="inline-flex shrink overflow-hidden items-center">
              <span class="group-label mx-2 uppercase whitespace-nowrap text-ellipsis overflow-hidden shrink hidden sm:inline">
                Category
              </span>
              <i class="material-icons text-2xl inline-block shrink-0 leading-[0.5rem]">keyboard_arrow_right</i>
            </div>
          </div>
        </div>
      </div>
      ${shareList}
    </div>
  </div>
`;
