/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/use-ember-get-and-set, ember/use-brace-expansion */ // FIXME
import Controller from '@ember/controller';
import { computed, get, set } from '@ember/object';
import { alias, equal, gt, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import ToolbarNavigation from 'partner/mixins/toolbar-navigation';
import { alphabeticalByProperty } from 'secondstreet-common/utils/sorting';

export default Controller.extend(ToolbarNavigation, {
  router: service(),
  settings: service(),

  //region Ember Hooks
  queryParams: ['matchupId', 'secondaryMatchup'],
  //endregion

  //region Properties
  updatingWinnersPublishing: false,
  allMatchupGroupViews: null,
  matchupId: null,
  /**
   * Only set to true if user goes to a sweepstakes in photo voting
   * Setting to null excludes it from the url as a queryParam
   * @type {Boolean}
   */
  secondaryMatchup: null,
  isCategorySideNavExpanded: true,
  //endregion

  //region Computed Properties
  voteBasedWinners: or(
    'model.organizationPromotion.promotion.isPhotoVotingStandard',
    'model.organizationPromotion.promotion.isVideoVotingStandard',
    'model.organizationPromotion.promotion.isBallot'
  ),
  organizationPromotion: alias('model.organizationPromotion'),
  selectedMatchup: alias('model.selectedMatchup'),
  oneMatchup: equal('model.matchups.length', 1),
  hasMultipleMatchups: gt('model.matchups.length', 1),

  isWinnersPublished: computed('model.selectedMatchup.isWinnersPublished', {
    get() {
      return get(this, 'model.selectedMatchup.isWinnersPublished');
    },
    set(_key, value) {
      return value;
    },
  }),
  changedPublishWinnersMessage: computed('isWinnersPublished', 'winners.[]', function () {
    const winnersPrefix = get(this, 'winners.length') === 1 ? ' Winner has' : ' Winners have';
    return this.isWinnersPublished
      ? `${winnersPrefix} been published to your site.`
      : `${winnersPrefix} been removed from your site.`;
  }),
  /**
   * Winners
   * This returns a collection of calculated winners based on how many potential winners you have specified
   * for the promotion.
   * @type {Ember.ComputedProperty}
   */
  winners: computed(
    'model.matchupPlace.@each.endRank',
    'model.matchupEntryRanks.@each.rank',
    'model.organizationPromotion.promotion.isBallot',
    function () {
      return get(this, 'model.matchupEntryRanks').filter(
        matchupEntryRank =>
          matchupEntryRank.get('rank') <=
          (get(this, 'model.organizationPromotion.promotion.isBallot')
            ? get(this, 'model.matchupPlace.lastObject.endRank')
            : get(this, 'model.matchupPlace.endRank'))
      );
    }
  ),
  /**
   * Non-Winners
   * This returns a collection of entries that didn't win
   * @type {Ember.ComputedProperty}
   */
  nonWinners: computed('winners', 'model.matchupEntryRanks.@each.rank', function () {
    const { winners } = this;
    return get(this, 'model.matchupEntryRanks').reject(matchupEntryRank => winners.includes(matchupEntryRank));
  }),
  flattenedMatchups: computed('allMatchupGroupViews.@each.{matchups,displayOrder}', function () {
    return this.allMatchupGroupViews
      ?.sortBy('displayOrder')
      .map(mgv =>
        this.settings.getValueFor('category_sort_criteria') == '1'
          ? mgv.matchups.sort(alphabeticalByProperty('name'))
          : mgv.matchups.sortBy('displayOrder')
      )
      .flat();
  }),
  appBarTitle: computed(
    'voteBasedWinners',
    'model.isSecondaryMatchup',
    'organizationPromotion.{matchupsAsCategories,name}',
    'selectedMatchup.{name,_dynamicRoundName}',
    function () {
      if (this.voteBasedWinners && !this.model.isSecondaryMatchup) {
        if (this.oneMatchup) {
          return 'Winners';
        }
        return `Winners - ${
          this.organizationPromotion.matchupsAsCategories
            ? this.model.selectedMatchup.name
            : this.model.selectedMatchup['_dynamicRoundName']
        }`;
      }
      if (this.model.isSecondaryMatchup) {
        return 'Sweepstakes Winners';
      } else if (this.oneMatchup) {
        return `${this.organizationPromotion.name} Winners`;
      }
      return `Winners - ${this.model.selectedMatchup['_dynamicRoundName']}`;
    }
  ),
  showCategorySideNav: computed(
    'voteBasedWinners',
    'model.isSecondaryMatchup',
    'oneMatchup',
    'organizationPromotion.matchupsAsCategories',
    function () {
      if (this.voteBasedWinners && !this.model.isSecondaryMatchup) {
        return !this.oneMatchup;
      }
      return !this.oneMatchup && !this.organizationPromotion.matchupsAsCategories;
    }
  ),
  //endregion

  //region Actions
  actions: {
    changeMatchup(matchup) {
      this.router.transitionTo({ queryParams: { matchupId: matchup.id } });
      set(this, 'showModal', false);
    },
    toggleCategorySideNav() {
      this.toggleProperty('isCategorySideNavExpanded');
    },
    triggerUpdateMatchupGroupViews() {
      if (this.allMatchupGroupViews && this.model.organizationPromotion.promotion.isBallot) {
        this.send('updateMatchupGroupViews');
      }
    },
  },
  //endregion
});
