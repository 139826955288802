/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { isPresent } from '@ember/utils';

export default Component.extend({
  //region Attributes
  /**
   * When the user attempts to submit the login form, this action is sent.
   * @param {Object<username,password>} params
   */
  'form-submitted': x => x,

  /**
   * When the user changes the username field, this action is sent with the new value.
   * @param {String} newValue
   */
  'username-changed': x => x,

  /**
   * When the user changes the password field, this action is sent with the new value.
   * @param {String} newValue
   */
  'password-changed': x => x,

  /**
   * @type {?APIError[]}
   */
  errors: null,

  showPasswordField: false,
  //endregion

  //region Ember Hooks
  didUpdateAttrs() {
    this._super();
    set(this, 'hasTextChanged', false); // New attrs mean we might be getting new errors!
  },

  classNames: ['login-index-page'],
  //endregion

  //region Properties
  /**
   * @type {String}
   */
  username: '',

  /**
   * @type {String}
   */
  password: '',

  /**
   * @type {Boolean}
   */
  hasTextChanged: false,
  //endregion

  //region Computed Properties
  /**
   * @returns {Boolean}
   */
  isSubmitDisabled: computed('errors.[]', 'hasTextChanged', function () {
    return isPresent(this.errors) && !this.hasTextChanged;
  }),
  /**
   * @returns {Boolean}
   */
  isPasswordInvalid: computed('errors.@each.message', 'hasTextChanged', function () {
    return this.hasTextChanged ? false : isPresent(this.errors);
  }),
  /**
   * @returns {?String}
   */
  passwordValidation: computed('errors.@each.message', 'hasTextChanged', function () {
    return this.hasTextChanged ? null : this.errors && get(this, 'errors.firstObject.message');
  }),
  //endregion

  //region Actions
  actions: {
    /**
     * @param {String} prop
     * @param {String} value
     */
    formChanged(prop, value) {
      set(this, prop, value);
      get(this, `${prop}-changed`)(value);
      set(this, 'hasTextChanged', true);
    },

    formSubmitted() {
      if (this.showPasswordField) {
        return this['form-submitted']({
          username: this.username,
          password: this.password,
        });
      }
      return this['check-email']({
        username: this.username,
      });
    },
  },
  //endregion
});
