import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import SetupDesignsController from 'partner/controllers/organizations/organization/organization-promotions/organization-promotion/setup/designs';
import type CurrentService from 'partner/services/current';
import { ModelFrom } from 'partner/utils/types';
import RSVP from 'rsvp';
import enums from 'ember-cli-ss-enums/services/enums';
import type TemplateDesignerService from 'partner/services/template-designer';

export type Model = ModelFrom<DesignRoute>;

export default class DesignRoute extends Route {
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare current: CurrentService;
  @service declare templateDesigner: TemplateDesignerService;

  async model() {
    const designTemplateTypeId = enums.findWhere('TEMPLATE_TYPE', { name: 'Custom Template' });

    const fromTurnkey = this.current.organizationPromotion.isCreatedFromTurnkey;

    const hash = await RSVP.hash({
      useOldTemplateSetting: this.store.queryRecord('setting', { key: 'use_old_design_templates' }),
      design: this.store.queryRecord('design', { designTemplateType: designTemplateTypeId, BypassCache: true }),
    });

    if (fromTurnkey && !hash.design) {
      hash.design = await this.templateDesigner.createNewTurnkeyTemplateDesign();
    }

    return hash;
  }

  redirect(model: Model): void {
    if (!model.useOldTemplateSetting || model.useOldTemplateSetting.value) {
      void this.router.transitionTo(
        'organizations.organization.organization-promotions.organization-promotion.setup.designs.legacy'
      );
      return;
    }
    if (model.design) {
      void this.router.transitionTo(
        'organizations.organization.organization-promotions.organization-promotion.setup.designs.current'
      );
      return;
    }
    void this.router.transitionTo(
      'organizations.organization.organization-promotions.organization-promotion.setup.designs'
    );
    return;
  }

  setupController(controller: SetupDesignsController, model: Model): void {
    if (model.useOldTemplateSetting) {
      controller.showTemplateSwitcher = !model.useOldTemplateSetting.value && !model.design;
      controller.useOldTemplateSetting = model.useOldTemplateSetting;
    }
  }
}
