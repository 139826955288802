import { get } from '@ember/object';
import { isPresent } from '@ember/utils';
import votingEntryKpiCount from 'partner/utils/voting-entry-kpi-count';
import { simplyByProperty, keepSorting, alphabeticalByProperty } from 'secondstreet-common/utils/sorting';
import { inflect } from 'partner/helpers/inflect';
import sanitizeString from 'partner/utils/sanitize-string';

export const mergeItems = (votingEntries, hasWriteins) => {
  const votingEntryMergeItems = votingEntries
    .reject(ve => ve.masterEntryId)
    .rejectBy('statusType', 'Rejected')
    .map(votingEntryToNominationMergeItem(votingEntries, hasWriteins));

  return votingEntryMergeItems.sort(alphabeticalByProperty('name'));
};

/**
 * @typedef {Object} NominationMergeItem
 * @property {Number} id
 * @property {String} name
 * @property {Boolean} isMerged
 * @property {Boolean} isPending
 * @property {Number} count
 * @property {?NominationEntriesSummary} nominationEntriesSummary
 * @property {?VotingEntry} votingEntry
 * @property {Number} locationCount
 * @property {Boolean} canSplitByLocation
 */
export const votingEntryToNominationMergeItem = (votingEntries, hasWriteins) => votingEntry => {
  const childVotingEntries = votingEntries.filter(ve => `${ve.masterEntryId}` === `${votingEntry.id}`);
  const { nominationEntriesSummaries } = votingEntry;
  const allNamesMatch =
    isPresent(nominationEntriesSummaries) &&
    nominationEntriesSummaries.isEvery('name', get(nominationEntriesSummaries, 'firstObject.name'));
  const uniqueLocationCount = childVotingEntries
    .mapBy('locationId')
    .addObject(votingEntry.locationId)
    .compact()
    .uniq().length;
  const isMerged = isPresent(childVotingEntries);

  return {
    isMerged,
    isPending: votingEntry.statusType === 'Submitted',
    id: votingEntry.id,
    name: votingEntry.displayName || '',
    count: votingEntryKpiCount(votingEntry, hasWriteins, childVotingEntries),
    votingEntry,
    uniqueLocationCount,
    canSplitByLocation: isMerged && uniqueLocationCount > 1 && allNamesMatch,
  };
};

export const preferredNominationMergeItemOrder = items => {
  const res = items
    .slice()
    .sort((a, b) =>
      keepSorting(
        simplyByProperty('isSubmitted')(a, b),
        simplyByProperty('count')(b, a),
        alphabeticalByProperty('name')(a, b)
      )
    );

  return res;
};

export const getBestMasterVotingEntry = (items, name) => {
  const exactNameMatch = items.find(item => item.name.toLowerCase() === sanitizeString(name).toLowerCase());

  if (exactNameMatch) {
    return exactNameMatch;
  }

  return preferredNominationMergeItemOrder(items).firstObject || items[0];
};

export const doneMessage = (num, source, type, action) =>
  `${num} ${source} ${inflect([num, type])} ${inflect([num, 'was'])} ${action}`;

export default {
  mergeItems,
  votingEntryToNominationMergeItem,
  preferredNominationMergeItemOrder,
  doneMessage,
  getBestMasterVotingEntry,
};
