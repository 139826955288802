/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';

/**
 * External App Route
 * /o/:organization_id/settings/external_app/:external_app_id
 */
export default Route.extend(AppContextRoute, {
  store: service(),

  //region Ember Hooks
  model(params) {
    return this.store.find('external-app', params.external_app_id);
  },
  //endregion

  //region Actions
  actions: {
    save() {
      this.currentModel.save().catch(e => {
        console.error(e);
      });
    },
  },
  //endregion
});
