/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

const ROOT_ORG_ID = 1;

export default Route.extend(AppContextRoute, {
  store: service(),
  settings: service(),

  //region Ember Hooks
  async model(params) {
    const emailTemplateId = params.message_body_template_id;
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');
    const { emailTemplate, secondStreetTemplates, tokens } = await RSVP.hash({
      emailTemplate: this.store.query('message-body-template', { id: emailTemplateId }),
      secondStreetTemplates: this.store.query('message-body-template', { organizationId }),
      tokens: this.store.query('token', { organizationId }),
      _settings: this.settings.preload('dips_url'),
    });

    return {
      template: emailTemplate.objectAt(0),
      secondStreetTemplates: secondStreetTemplates
        // gets the organization id of each template without fetching the organization record
        .filter(template => template.belongsTo('organization').id() === ROOT_ORG_ID.toString())
        .toArray(),
      dipsUrl: this.settings.getFor('dips_url'),
      tokens: tokens.toArray(),
    };
  },
  resetController(controller) {
    setProperties(controller, {
      isSelectingTemplate: false,
      messageVersionId: null,
      messageCampaignId: null,
      organizationPromotionId: null,
      audienceId: null,
      messageCampaignType: null,
    });
  },
  //endregion

  //region Actions
  actions: {
    willTransition() {
      get(this.modelFor(this.routeName), 'template').rollbackAttributes();
    },
  },
  //endregion
});
