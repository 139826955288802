/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/**
 * This is a full-height intermediary app bar that will only be used in the following locations
 *  o/:id/op/:id/setup/
 *  o/:id/op/:id/dashboard/
 *  o/:id/message-campaigns/:id/setup/
 *  o/:id/message-campaigns/:id/dashboard/
 *  o/:id/data/audiences/:id/filters
 *  o/:id/data/audiences/:id/sales-sheet
 *
 * It will not used anywhere we do a skinny app bar
 **/

export { default } from 'partner/components/app-bar';
