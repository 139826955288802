/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import WinnerDisplay from 'partner/mixins/winner-display';

/**
 * This is designed to display winners on /o/:id/op/:id/winners
 */
export default Component.extend(WinnerDisplay, {
  //region Attributes
  'is-image-entry': false,
  'registration-form-fields': null,
  winners: null,
  'repicked-matchup-entry-id': null,
  'calculating-winners': null,
  //endregion

  isVisible: computed('selectedWinnerFilter', function () {
    return !(this.selectedWinnerFilter === 'Pick Entire Round Winners' && this.noWinners);
  }),

  //region Computed Properties
  beingRepicked: computed('repicked-matchup-entry-id', 'calculating-winners', function () {
    const calculatingWinners = this['calculating-winners'];
    const repickedMatchupEntryId = this['repicked-matchup-entry-id'];
    const repickedWinnerRank = this['repicked-winner-rank'];
    const winner = get(this, 'winner');

    return (
      calculatingWinners &&
      (winner.fakeWinner ? repickedWinnerRank === winner.rank : repickedMatchupEntryId === winner.matchupEntry?.id)
    );
  }),

  isDateAfterToday: computed('winner.nextEligibilityDate', function () {
    return new Date() < new Date(this.winner.nextEligibilityDate);
  }),

  organizationName: computed(
    'winner.matchupEntry.@each.organizationPromotionId',
    'organizationPromotion.promotion.organizationPromotions.[]',
    function () {
      return this.organizationPromotion.promotion.organizationPromotions.findBy(
        'id',
        this.winner.matchupEntry.organizationPromotionId.toString()
      ).organizationName;
    }
  ),
  //endregion
});
