import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type { ModelFrom } from 'partner/utils/types';

export type Model = ModelFrom<SetupBallotMatchupGroupsEditRoute>;

export default class SetupBallotMatchupGroupsEditRoute extends Route {
  @service declare store: Store;

  async model({ matchup_group_id }: { matchup_group_id: string }) {
    const matchupGroup = await this.store.findRecord('matchup-group', matchup_group_id, { reload: true });

    return { matchupGroup };
  }
}
