/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

/**
 * The default value(s) for a Token for a specific Organization.
 */
export default BaseModel.extend({
  value: attr('string'),
  linkUrl: attr('string'),
  altText: attr('string'),
  mediaItemId: attr('number'),
  tokenId: attr('string'),
  isPlaceholder: attr('boolean', { defaultValue: false }),
  itemTitle: attr('string'),
  itemImageExternalSrcUrl: attr('string'),
  itemImageLinkUrl: attr('string'),
  itemButtonLinkUrl: attr('string'),
  itemButtonText: attr('string'),
  isInherited: attr('boolean', { defaultValue: false }),

  //region Relationships
  token: belongsTo('token', {
    async: false,
    inverse: 'tokenDefaultContents',
  }),
  //endregion
});
