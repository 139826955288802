import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import PromotionTypeRoute from 'partner/-base/promotion-type-route';
import type { ModelFrom } from 'partner/utils/types';

export type SetupQuizIndexModel = ModelFrom<SetupQuizIndexRoute>;

export default class SetupQuizIndexRoute extends PromotionTypeRoute {
  @service declare store: Store;

  model() {
    return this.store.query('outcome', {
      includeProbabilities: true,
    });
  }
}
