/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set, setProperties } from '@ember/object';
import { not, or } from '@ember/object/computed';
import moment from 'moment';
import PermittedComponent from 'partner/mixins/permitted-component';
import { findPreset, makePresets } from 'partner/utils/recurring-delay-presets';
import { DAYS_IN_WEEK } from 'partner/utils/recurring-pattern';

export default Component.extend(PermittedComponent, {
  //region Ember Hooks
  tagName: 'recurring-delay',
  didReceiveAttrs() {
    this._super(...arguments);
    if (this['base-date']) {
      const presets = makePresets(this['base-date']);
      const typeId = this['type-id'];
      const hasPreset = typeId && findPreset(presets, typeId, this.pattern, this.delay);
      set(this, 'defaultDelay', presets[hasPreset || 'Daily'].delay);
      if (typeId && !hasPreset) {
        set(this, 'hasCustomized', true);
      }
    }
    set(this, 'delayOnSentOnNewRssItems', !this.delay);
  },
  //endregion

  //region Attributes
  /**
   * @type {Function}
   */
  'on-change': () => {},
  /**
   * @type {Number?}
   */
  delay: null,
  /**
   * @type {Number?}
   */
  'type-id': null,
  /**
   * @type {Date?}
   */
  'base-date': null,
  /**
   * @type {String}
   */
  pattern: '',
  /**
   * @type {Boolean}
   */
  disabled: false,
  //endregion

  //region Properties
  /**
   * If the user is setting a custom recurrence, we don't want to pick a selection as they change things.
   * @type {Boolean}
   */
  hasCustomized: false,
  //endregion

  //region Computed Properties
  noBaseDate: not('base-date'),
  inputsDisabled: or('noBaseDate', 'disabled'),
  /**
   * We don't have dependent keys here because
   */
  selectedOption: computed('hasCustomized', 'base-date', 'type-id', 'pattern', 'delay', {
    get() {
      if (this.hasCustomized) {
        // If the user is setting a custom recurrence, we don't want to pick a selection as they change things.
        return 'Custom';
      }
      if (this['base-date'] && (this.delay || (this.delay === 0 && this['type-id'] === 2)) && this['type-id']) {
        const presets = makePresets(this['base-date']);
        return findPreset(presets, this['type-id'], this.pattern, this.delay) || 'Custom';
      }
      return null;
    },
    set(key, value, previousValue) {
      if (previousValue === 'EveryMinute' && value === 'Custom') {
        setProperties(this, { delay: null, 'type-id': null });
      }
      set(this, 'hasCustomized', value === 'Custom');
      if (value !== 'Custom') {
        // let the user work from where they were if they're moving to custom.
        const defaults = makePresets(this['base-date'])[value];
        defaults.defaultDelay = defaults.delay;
        setProperties(this, defaults);
      }
      return value;
    },
  }),
  baseDateWeekday: computed('base-date', function () {
    return moment.weekdays()[moment(this['base-date']).day()];
  }),
  baseDateOccurrence: computed('base-date', function () {
    return Math.ceil(moment(this['base-date']).date() / DAYS_IN_WEEK);
  }),
  delayInput: computed('delay', {
    get() {
      return `${this.delay}`;
    },
    set(key, value) {
      set(this, 'delay', Number.parseInt(value, 10));
      return `${value}`;
    },
  }),
  //endregion

  //region Actions
  actions: {
    optionSelected(selectedValue) {
      if (selectedValue === 'EveryMinute') {
        set(this, 'delayOnSentOnNewRssItems', false);
        set(this, 'type-id', 2);
        set(this, 'schedule-type-id', 7);
      } else {
        set(this, 'schedule-type-id', 2);
      }
      set(this, 'selectedOption', selectedValue);
    },
  },
  //endregion
});
