import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type { ModelFrom } from 'partner/utils/types';

export type Model = ModelFrom<MessageCampaignsRoute>;

export default class MessageCampaignsRoute extends Route {
  @service declare store: Store;
  async model() {
    const messageCampaignCategories = await this.store.query('message-campaign-category', {});
    return { messageCampaignCategories };
  }
}
