import ballotWideScroll from './ballot-wide-scroll';
import ballotNarrowScroll from './ballot-narrow-scroll';
import ballotCategoryDrawers from './ballot-category-drawers';
import ballotCategoryPages from './ballot-category-pages';

export default ({ layout }) => {
  switch (layout) {
    case 'Narrow Scroll':
      return ballotNarrowScroll;
    case 'Wide Scroll':
      return ballotWideScroll;
    case 'Category Drawers':
      return ballotCategoryDrawers;
    case 'Category Pages':
      return ballotCategoryPages;
  }
};
