/* eslint-disable ember/no-get, ember/no-classic-classes */
import { inject as controller } from '@ember/controller';
import { computed, get } from '@ember/object';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import BaseController from 'partner/controllers/organizations/organization/organization-promotions/organization-promotion/setup/quiz/questions/base';

export default BaseController.extend({
  store: service(),

  //region Ember Hooks
  organizationController: controller('organizations.organization'),
  quiz: controller('organizations.organization.organization-promotions.organization-promotion.setup.quiz'),
  queryParams: ['balance'],
  //endregion

  //region Properties
  balance: false,
  isAddingImageFromLibrary: false,
  //endregion

  //region Computed Properties
  simulationPromise: computed('balance', function () {
    const organizationId = get(this, 'organizationController.model.organization.id');
    const organizationPromotionId = get(this, 'organizationPromotionController.model.organizationPromotion.id');

    if (!this.balance) {
      return null;
    }

    return this.store.query('outcome', {
      organizationId,
      organizationPromotionId,
      includeProbabilities: true,
    });
  }),
  simulationLoaded: equal('simulationPromise.isFulfilled', true),
  //endregion
});
