/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
/* eslint-disable ember/use-ember-get-and-set */ // FIXME
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend({
  //region Properties
  name: DS.attr('string'),
  displayOrder: DS.attr('number'),
  //endregion

  //region Relationships
  form: DS.belongsTo('form', {
    async: false,
  }),
  formFields: DS.hasMany('form-field', {
    async: false,
  }),
  //endregion

  //region Computed Properties
  sortedFormFields: sort('formFields', (a, b) => {
    const sortProperty = 'displayOrder';
    return a.get(sortProperty) - b.get(sortProperty);
  }),
  validFormFields: computed('formFields', function () {
    return this.formFields.filter(formField => !formField.get('isDeleted'));
  }),
  //endregion
});
