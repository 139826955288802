import { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';
import type PartnerUserModel from './partner-user';

export default class OrganizationPromotionPartnerUserAllowedListModel extends BaseModel {
  @attr('string') declare emailAddress: string;
  @attr('string') declare partnerUserId: string;

  @belongsTo('organization-promotion', { async: false }) declare organizationPromotion: any;
  @belongsTo('partner-user', { async: true }) declare partnerUser: AsyncBelongsTo<PartnerUserModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'organization-promotion-partner-user-allowed-list': OrganizationPromotionPartnerUserAllowedListModel;
  }
}
