import { ParamsType } from 'partner/services/preview';
import { header, subHeader, card } from './common';

const ENTRANT_TITLE = 'Entrant Name X';
const ENTRANT_DESCRIPTION = 'Entrant description text will go here. Anything over 200 characters will truncate.';

const entrantSideNavGroupSection = (id: string, selected?: boolean) => {
  return {
    id: 'box-144',
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'heading-42',
        uniqueTypeNumber: id,
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: 'Entrant Name',
        },
        styles: {
          font: {
            color: selected ? '#000000' : '#6b7786',
            weight: 400,
            family: 'Open Sans',
            size: '14',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      {
        id: 'heading-43',
        uniqueTypeNumber: id,
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: 'Entrant Name',
        },
        styles: {
          font: {
            color: selected ? '#000000' : '#6b7786',
            weight: 400,
            family: 'Open Sans',
            size: '14',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss: '.arc-box-box-144 {\n     \n  }\n  \n  ',
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        gapX: '4',
        gapY: '4',
      },
      spacing: {
        paddingInline: '16',
        paddingBlock: '16',
      },
      background: {
        type: 'color',
        color: selected ? '#f3f3f3' : '#ffffff',
      },
      border: {
        style: 'solid',
        width: '1',
        color: '#e0e3e6',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const getVersusCard = (color?: string) => {
  return {
    id: 'box-1212',
    uniqueTypeNumber: '1212',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'heading-48',
        uniqueTypeNumber: '48',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: 'VS',
          additionalCss: '.arc-heading-heading-48 {\n     \n  }\n  \n  ',
        },
        styles: {
          font: {
            color,
            weight: 700,
            family: 'Open Sans',
            size: '20',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss:
        '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-1212 {\n  position: absolute; top: calc(50% - 100px); left: calc(50% - 18px);  \n  }\n  \n  ',
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
      },
      background: {
        type: 'color',
        color: '#ffffff',
        opacity: 100,
      },
      size: {
        custom: true,
        width: '36',
        height: '36',
      },
      border: {
        radius: '36',
      },
      boxShadow: {
        hasShadow: true,
        color: '#9e9e9e',
        x: '1',
        y: '2',
        blur: '4',
        spread: '0',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

export default (params?: ParamsType) => [
  header({
    title: 'Already Voted?',
    promotionColor: params?.promotionColor,
    promotionTextColor: params?.promotionTextColor,
  }),
  subHeader('21', 'Bracket', params?.promotionColor, params?.promotionTextColor),

  {
    id: 'grid-1',
    uniqueTypeNumber: '1',
    deletable: true,
    type: 'promotion-grid',
    blocks: [
      {
        id: 'box-142',
        uniqueTypeNumber: '142',
        deletable: true,
        type: 'promotion-box',
        blocks: [
          entrantSideNavGroupSection('144'),
          entrantSideNavGroupSection('145', true),
          entrantSideNavGroupSection('146'),
          entrantSideNavGroupSection('147'),
          entrantSideNavGroupSection('148'),
          entrantSideNavGroupSection('149'),
          entrantSideNavGroupSection('150'),
          entrantSideNavGroupSection('151'),
        ],
        data: {
          additionalCss: '.arc-box-box-142 {\n     \n  }\n  \n  ',
        },
        styles: {
          layout: {
            display: 'flex',
            direction: 'column',
          },
        },
        dataMobile: {
          additionalCss:
            '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-142 {\n  display: none;   \n  }\n  \n  ',
        },
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      {
        id: 'box-143',
        uniqueTypeNumber: '143',
        deletable: true,
        type: 'promotion-box',
        blocks: [
          {
            type: 'promotion-box',
            uniqueTypeNumber: '260',
            styles: {
              layout: {
                display: 'flex',
                direction: 'column',
              },
              size: {
                custom: true,
                height: 60,
              },
            },
            blocks: [
              {
                id: 'grid-2',
                uniqueTypeNumber: '2',
                deletable: true,
                type: 'promotion-grid',
                blocks: [
                  {
                    id: 'box-153',
                    uniqueTypeNumber: '153',
                    deletable: true,
                    type: 'promotion-box',
                    blocks: [
                      {
                        id: 'heading-58',
                        uniqueTypeNumber: '58',
                        deletable: true,
                        type: 'promotion-heading',
                        blocks: [],
                        data: {
                          level: 1,
                          text: '< PREVIOUS',
                          additionalCss:
                            '.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-58 {\n  width: fit-content;   \n  }\n  \n  ',
                        },
                        styles: {
                          font: {
                            color: params?.promotionColor,
                            weight: 600,
                            family: 'Open Sans',
                            size: '14',
                            align: 'end',
                            spacing: '1.25',
                          },
                        },
                        dataMobile: {},
                        stylesMobile: {},
                        dataTablet: {},
                        stylesTablet: {},
                      },
                    ],
                    data: {
                      additionalCss: '.arc-box-box-153 {\n     \n  }\n  \n  ',
                    },
                    styles: {
                      layout: {
                        display: 'flex',
                        direction: 'column',
                        align: 'center',
                      },
                    },
                    dataMobile: {},
                    stylesMobile: {},
                    dataTablet: {},
                    stylesTablet: {},
                  },
                  {
                    id: 'box-154',
                    uniqueTypeNumber: '154',
                    deletable: true,
                    type: 'promotion-box',
                    blocks: [
                      {
                        id: 'heading-45',
                        uniqueTypeNumber: '451',
                        deletable: true,
                        type: 'promotion-heading',
                        blocks: [],
                        data: {
                          level: 1,
                          text: 'Matchup 1/8',
                          additionalCss: '.arc-heading-heading-45 {\n     \n  }\n  \n  ',
                        },
                        styles: {
                          font: {
                            color: '#757575',
                            weight: 400,
                            family: 'Open Sans',
                            size: '13',
                            align: 'center',
                          },
                        },
                        dataMobile: {},
                        stylesMobile: {},
                        dataTablet: {},
                        stylesTablet: {},
                      },
                    ],
                    data: {
                      additionalCss: '.arc-box-box-154 {\n     \n  }\n  \n  ',
                    },
                    styles: {
                      layout: {
                        display: 'flex',
                        direction: 'column',
                        align: 'center',
                      },
                    },
                    dataMobile: {},
                    stylesMobile: {},
                    dataTablet: {},
                    stylesTablet: {},
                  },
                  {
                    id: 'box-155',
                    uniqueTypeNumber: '155',
                    deletable: true,
                    type: 'promotion-box',
                    blocks: [
                      {
                        id: 'heading-46',
                        uniqueTypeNumber: '46',
                        deletable: true,
                        type: 'promotion-heading',
                        blocks: [],
                        data: {
                          level: 1,
                          text: 'NEXT >',
                          additionalCss: '.arc-heading-heading-44 {\n     \n  }\n  \n  ',
                        },
                        styles: {
                          font: {
                            color: params?.promotionColor,
                            weight: 600,
                            family: 'Open Sans',
                            size: '14',
                            align: 'end',
                            spacing: '1.25',
                          },
                        },
                        dataMobile: {},
                        stylesMobile: {},
                        dataTablet: {},
                        stylesTablet: {},
                      },
                    ],
                    data: {
                      additionalCss: '.arc-box-box-155 {\n     \n  }\n  \n  ',
                    },
                    styles: {
                      layout: {
                        display: 'flex',
                        direction: 'column',
                        align: 'center',
                      },
                    },
                    dataMobile: {},
                    stylesMobile: {},
                    dataTablet: {},
                    stylesTablet: {},
                  },
                ],
                data: {
                  columns: '1:1:1',
                  additionalCss: '.arc-grid-grid-2 {\n     \n  }\n  \n  ',
                },
                styles: {
                  layout: {
                    display: 'grid',
                    direction: 'row',
                  },
                  spacing: {
                    paddingInline: '20',
                  },
                },
                dataMobile: {
                  columns: '1:1:1',
                },
                stylesMobile: {},
                dataTablet: {
                  columns: '1:1:1',
                },
                stylesTablet: {},
              },
            ],
          },
          {
            id: 'box-261',
            uniqueTypeNumber: '261',
            deletable: true,
            type: 'promotion-box',
            blocks: [
              {
                id: 'heading-47',
                uniqueTypeNumber: '47',
                deletable: true,
                type: 'promotion-heading',
                blocks: [],
                data: {
                  level: 1,
                  text: 'Vote starting mm/dd/yyyy @ h:mmpm',
                  additionalCss: '.arc-heading-heading-47 {\n     \n  }\n  \n  ',
                },
                styles: {
                  font: {
                    color: '#707070',
                    weight: 400,
                    family: 'Open Sans',
                    size: '14',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
            ],
            data: {
              additionalCss: '.arc-box-box-261 {\n     \n  }\n  \n  ',
            },
            styles: {
              layout: {
                display: 'flex',
                direction: 'column',
                align: 'center',
                justify: 'center',
              },
              size: {
                custom: true,
                height: 48,
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
          {
            id: 'box-262',
            uniqueTypeNumber: '262',
            deletable: true,
            type: 'promotion-box',
            blocks: [
              {
                id: 'box-263',
                uniqueTypeNumber: '263',
                deletable: true,
                type: 'promotion-box',
                blocks: [
                  card({
                    id: '121',
                    height: 200,
                    fitContent: true,
                    hasButton: true,
                    title: ENTRANT_TITLE,
                    description: ENTRANT_DESCRIPTION,
                  }),
                  card({
                    id: '121',
                    height: 200,
                    fitContent: true,
                    hasButton: true,
                    title: ENTRANT_TITLE,
                    description: ENTRANT_DESCRIPTION,
                  }),
                  getVersusCard(params?.promotionColor),
                ],
                data: {
                  additionalCss:
                    '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-263 {\n     width: fit-content; position:relative; \n  }\n  \n  ',
                },
                styles: {
                  layout: {
                    display: 'flex',
                    direction: 'row',
                  },
                  spacing: {
                    marginBlock: '32',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
            ],
            data: {
              additionalCss: '.arc-box-box-262 {\n     \n  }\n  \n  ',
            },
            styles: {
              layout: {
                display: 'flex',
                direction: 'column',
                align: 'center',
                justify: 'center',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
        ],
        data: {
          additionalCss:
            '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-143 {\n  max-width:428px; margin: 16px auto;   \n  }\n  \n  ',
        },
        styles: {
          layout: {
            display: 'flex',
            direction: 'column',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      columns: '1:2',
      additionalCss: '.arc-grid-grid-1 {\n     \n  }\n  \n  ',
    },
    styles: {
      border: {
        style: 'solid',
        width: '1',
        color: '#e0e3e6',
      },
      layout: {
        display: 'grid',
        direction: 'row',
      },
    },
    dataMobile: {
      columns: '1',
    },
    stylesMobile: {},
    dataTablet: {
      columns: '1:2',
    },
    stylesTablet: {},
  },
];
