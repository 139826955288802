/* globals Modernizr */

export default {
  name: 'modernizr',
  initialize() {
    Modernizr.addTest('webkit-appearance', () => Modernizr.testProp('webkitAppearance'));
    Modernizr.addTest('moz-appearance', () => Modernizr.testProp('mozAppearance'));
    Modernizr.addTest('variables-support', () => window.CSS && CSS.supports('(--foo: red)'));
  },
};
