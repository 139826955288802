/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { UNSAVED_CHANGES_ALERT } from 'partner/utils/constants';
import RSVP from 'rsvp';

export default Route.extend({
  store: service(),

  async model() {
    const dynamicTokenKeys = [
      'User First Name',
      'User Last Name',
      'User Birthdate',
      'User Gender',
      'User City',
      'User State',
      'User Postal Code',
    ];

    const hash = await RSVP.hash({
      tokens: this.store.query('token', {
        tokenTypeId: 2,
      }),
      settings: this.store.query('setting', {
        key: 'messaging_dynamic_token_fallback',
      }),
    });

    const settings = hash.settings.toArray();
    const filteredTokens = hash.tokens
      .filter(token => isPresent(token.fieldId))
      .filter(token => dynamicTokenKeys.includes(token.name));

    const fieldIds = filteredTokens.map(token => token.fieldId);

    const existingSettings = [];
    settings.forEach(setting => {
      existingSettings.push(setting.targetEntityId);
    });

    await Promise.all(
      fieldIds
        .map(id => {
          const settingExists = existingSettings.includes(id);
          if (!settingExists) {
            const setting = this.store.createRecord('setting', {
              key: 'messaging_dynamic_token_fallback',
              value: '',
              targetEntityId: id,
              targetEntityTypeId: 14,
            });

            settings.addObject(setting);
            return setting.save();
          }
          return null;
        })
        .filter(settingsPromise => isPresent(settingsPromise))
    );

    return dynamicTokenKeys.map(key => {
      const token = filteredTokens.findBy('name', key);
      return {
        name: key.replace('User ', ''),
        setting: settings.findBy('targetEntityId', token.fieldId),
        token,
      };
    });
  },

  //region Actions
  actions: {
    willTransition(transition) {
      const { model } = this.controller;
      const dirtyItems = model.filter(item => item.setting.hasDirtyAttributes);
      if (isPresent(dirtyItems)) {
        const confirm = window.confirm(UNSAVED_CHANGES_ALERT);
        if (!confirm) {
          transition.abort();
        } else {
          model.forEach(item => item.setting.rollbackAttributes());
        }
      }
    },
  },
  //endregion
});
