/* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { setProperties } from '@ember/object';
import { computed, get, set } from '@ember/object';
import { readOnly, sort, filterBy } from '@ember/object/computed';
import { equal } from '@ember/object/computed';
import { next, scheduleOnce } from '@ember/runloop';
import { isEmpty } from '@ember/utils';
import PermittedComponent from 'partner/mixins/permitted-component';

export default Component.extend(PermittedComponent, {
  //region Ember Hooks
  init() {
    this._super(...arguments);
    set(this, 'field-type-blacklist', this['field-type-blacklist'] || []);
    set(this, 'unstarred-fields', this['unstarred-fields'] || []);
  },
  //endregion

  //region Attributes
  /**
   * If enabled, hides the Add Page button and the page counter at the top of the form
   * @property {Boolean}
   */
  'single-page-form': false,
  /**
   * @property {String}
   */
  'field-type': '',
  /**
   * @property {Array}
   */
  'unstarred-fields': null,
  /**
   * @property {Array}
   */
  'field-type-blacklist': null,
  /**
   * @property {Boolean}
   */
  'can-create-custom-fields': true,
  'disable-help-text': false,
  'dips-url': null,
  'something-changed'() {},
  //endregion

  //region Ember Hooks
  tagName: 'form-designer-form-page',
  //endregion

  //region Properties
  isAddingFormField: false,
  isListVisible: true,
  'is-anything-saving': false,
  //endregion

  //region Computed Properties
  form: readOnly('form-page.form'),
  formPage: readOnly('form-page'),
  formFieldsSorting: computed(() => ['displayOrder:asc']),
  sortedFormFields: sort('formPage.formFields', 'formFieldsSorting'),
  extraChancesEnabled: equal('form.formType', 'ExtraChances'),

  nonEditableFields: filterBy('sortedFormFields', 'isEditable', false),
  editableFields: filterBy('sortedFormFields', 'isEditable', true),
  //endregion

  //region Actions
  actions: {
    toggleAddingFormField() {
      this.toggleProperty('isAddingFormField');
    },

    removeFormField(formField) {
      // Delete newly created fields
      if (get(formField, 'field.isNew')) {
        get(formField, 'field').deleteRecord();
      } else if (get(formField, 'field.hasDirtyAttributes')) {
        // Roll back dirty fields
        get(formField, 'field').rollbackAttributes();
      }

      if (get(formField, 'field.fieldOptions')) {
        // Delete newly created field options
        const newFieldOptions = get(formField, 'field.fieldOptions').filterBy('isNew');
        newFieldOptions.forEach(fo => {
          fo.deleteRecord();
        });

        // Rollback dirty field options
        const dirtyFieldOptions = get(formField, 'field.fieldOptions').filterBy('hasDirtyAttributes');
        dirtyFieldOptions.forEach(fo => {
          fo.rollbackAttributes();
        });
      }

      get(this, 'formPage.formFields').removeObject(formField);
      get(this, 'form.deletedRecords').addObject(formField);
      set(formField, 'formPage', null);
      formField.deleteRecord();

      //region HAX
      // Toggling listVisible tricks the each to rerender
      // Otherwise non deleted elements disappear from DOM
      set(this, 'isListVisible', false);
      const scroll = [window.pageXOffset, window.pageYOffset];
      next(() => {
        if (!this.formPage.isDeleted) {
          set(this, 'isListVisible', true);
          scheduleOnce('afterRender', () => window.scrollTo(...scroll));
        }
      });

      this.removeFormPageIfEmpty();
      //endregion

      this['something-changed']();
    },

    reorderFormFields(formFields) {
      formFields.forEach((formField, index) => {
        setProperties(formField, { displayOrder: index + 1, formPage: this.formPage });
      });

      this.patchTwitterFollowFields();
      this.formPage.form?.formPages.forEach(formPage => this.removeFormPageIfEmpty(formPage));
      this['something-changed']();
    },
  },

  removeFormPageIfEmpty(formPage) {
    formPage = formPage || this.formPage;

    if (isEmpty(formPage.formFields) && formPage.pageNumber > 1) {
      this.removeFormPage(formPage);
    }
  },

  /**
   * Twitter Follow fields can't be required on page 1 so un-require if we meet that criteria
   */
  patchTwitterFollowFields() {
    if (this.formPage.pageNumber != 1) return;

    this.formPage.formFields.forEach(formField => {
      if (formField.field.isTwitterFollowApi) {
        set(formField, 'isRequired', false);
      }
    });
  },
});
