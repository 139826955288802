import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class QuestionOptionBranchingComponent extends Component {
  //region Properties
  get branchingChoices() {
    const nextQuestion = { value: null, name: 'The Next Question' };
    const theOutcome = { value: '-1', name: 'The Outcome' };
    const questions = this.args.questions
      .sortBy('displayOrder')
      .map((question, index) => ({
        value: question.id,
        name: `${index + 1}. ${question.name}`,
      }))
      .rejectBy('value', this.args.questionOption.question.id);

    return [nextQuestion].concat(questions).concat([theOutcome]);
  }

  get selectedBranchingChoice() {
    return this.branchingChoices.findBy('value', this.args.questionOption.nextQuestionId);
  }
  //endregion

  //region Actions
  @action
  questionBranchingSelected(target) {
    this.args.questionBranchingSelected(this.args.questionOption, target.value);
  }
  //endregion
}
