/* eslint-disable ember/no-mixins, ember/no-get, ember/no-computed-properties-in-native-classes */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { inject as service } from '@ember/service';
import PerformanceReportCommonController from 'partner/-base/performance-report-controller';
import { set, action, computed, setProperties } from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';
import camelizeKeys from 'partner/utils/camelize-keys';
import {
  createOrganizationTree,
  getPromotionColumns,
  getPromotionSummaryColumns,
  FORMATTED_PROMOTION_TYPES,
  type SummaryTypeModel,
} from 'partner/utils/performance-report';
import CurrentService from 'partner/services/current';
import type ReportsService from 'partner/services/reports';
import type { Model } from 'partner/routes/organizations/organization/organization-promotions/performance-report';
import type { ReportType } from 'partner/services/reports';

const VIEW_TYPES = [
  {
    id: 'summary',
    name: 'Summary',
  },
  {
    id: 'promotions',
    name: 'Promotions',
  },
];

const STATUS_TYPES = [
  {
    id: 0,
    name: 'Both',
  },
  {
    id: 1,
    name: 'Yes',
  },
  {
    id: 2,
    name: 'No',
  },
];

export default class PerformanceReportController extends PerformanceReportCommonController {
  //region Ember Dependencies

  @service declare reports: ReportsService;
  @service declare current: CurrentService;

  declare model: Model;

  viewTypes = VIEW_TYPES;
  statusTypes = STATUS_TYPES;

  @computed('model.meta.performanceStatisticsTotalCounts')
  get stats() {
    return this?.model?.meta?.performanceStatisticsTotalCounts
      ? camelizeKeys(this.model.meta.performanceStatisticsTotalCounts)
      : [];
  }

  get promotionIndividualColumns() {
    return getPromotionColumns(this.isChain);
  }

  @computed('isChain', 'rows')
  get summaryColumns() {
    const expandableColumn = this.rows?.any((row: any) => row.children);
    const columns = getPromotionSummaryColumns();
    return expandableColumn
      ? [
          {
            label: '',
            width: 'max-content',
          },
          ...columns,
        ]
      : columns;
  }

  get columns() {
    return this.isSummary ? this.summaryColumns : this.promotionIndividualColumns;
  }

  @computed('orgId', 'model.organizationPromotionStatistics.[]', 'sortColumn', 'sortDirection')
  get organizationTree() {
    return createOrganizationTree(
      this.orgId,
      this.model.organizationPromotionStatistics as SummaryTypeModel[],
      this.sortColumn || 'organizationName',
      this.sortDirection || 'asc'
    );
  }

  @computed('isSummary', 'organizationTree.children', 'model.promotionPerformanceStatistics')
  get rows() {
    return this.isSummary
      ? isPresent(this.organizationTree?.children)
        ? this.organizationTree?.children.reject(
            ({ organizationName }: { organizationName: string }) => !organizationName
          )
        : isPresent(this.organizationTree)
        ? [this.organizationTree]
        : []
      : this.model.promotionPerformanceStatistics;
  }

  get summaryStats() {
    return [
      ...(this.isSummary
        ? [
            {
              header: 'Promotions',
              primary: 'totalPromotionRunCount',
            },
          ]
        : []),
      {
        header: 'People',
        primary: 'totalUserCount',
      },
      {
        header: 'New People',
        primary: 'totalNewUserCount',
      },
      {
        header: 'Entries',
        primary: 'totalEntrantCount',
      },
      {
        header: 'Noms',
        primary: 'totalNominationCount',
      },
      {
        header: 'Votes',
        primary: 'totalVoteCount',
      },
      {
        header: 'New Opt Ins',
        primary: 'totalOptInCount',
      },
      {
        header: 'Leads',
        primary: 'totalLeadsCount',
      },
    ];
  }

  get promotionSubTypes() {
    return [this.allOption, ...FORMATTED_PROMOTION_TYPES];
  }

  @computed('viewTypes', 'view')
  get selectedViewType() {
    return this.viewTypes.findBy('id', this.view);
  }

  @computed('statusTypes', 'status')
  get selectedStatusType() {
    return this.statusTypes.findBy('id', this.status);
  }

  @computed('allOption', 'promotionSubTypes', 'tempSelectedTypes')
  get selectedPromotionSubTypes() {
    const selectedTypes = this.tempSelectedTypes ? this.tempSelectedTypes.split(',') : [];
    if (isEmpty(selectedTypes)) return [this.allOption];
    return FORMATTED_PROMOTION_TYPES.filter(type => selectedTypes.includes(`${type.id}`));
  }

  get reportType(): ReportType {
    return this.isSummary ? 'OrganizationPromotionPerformance' : 'PromotionPerformance';
  }

  @action
  changeView({ id }: { id: string }) {
    setProperties(this, {
      page: 1,
      view: id,
      organizationIds: '',
      promotionSubTypeIds: '',
      sortColumn: undefined,
      sortDirection: null,
      name: '',
    });
  }

  @action
  changeStatus({ id }: { id: number }) {
    set(this, 'status', id);
  }
}

declare module '@ember/controller' {
  interface Registry {
    'organizations.organization.organization-promotions.performance-report': PerformanceReportController;
  }
}
