/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { set, setProperties } from '@ember/object';

export default Component.extend({
  //region Properties
  uploadingImage: false,
  isFlyoutOpen: false,
  //endregion

  //region Attributes
  openImageLibrary() {},
  isDisabled: false,
  dipsUrl: null,
  mediaItemId: null,
  errors: null,
  title: null,
  value: null,
  //endregion

  //region Actions
  actions: {
    addMediaItem(newMediaItem) {
      if (!newMediaItem) return;
      setProperties(this, {
        mediaItemId: newMediaItem.id,
        uploadingImage: false,
        isFlyoutOpen: false,
      });
      this.updateTokenContentProperty('mediaItemId', newMediaItem.id);
    },
    uploadStarted() {
      set(this, 'uploadingImage', true);
    },
    uploadEnded() {
      set(this, 'uploadingImage', false);
    },
    updateTitle({ target: { value } }) {
      this.updateTokenContentProperty('title', value);
    },
    updateValue(value) {
      this.updateTokenContentProperty('value', value);
    },
  },
  //endregion
});
