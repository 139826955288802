/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;

/**
 * @typedef {DS.Model} Audience
 */
export default BaseModel.extend({
  //region Properties
  masterFieldId: attr('number'),
  subFieldId: attr('number'),
  //endregion
});
