import TokenContentEditorForImage, {
  type TokenContentEditorForImageSignature,
} from 'partner/components/token-content-editor-for-image';
import { MAX_TOKEN_CONTENT_URL_LENGTH } from 'partner/utils/constants';

export interface TokenContentEditorForLinkableImageSignature extends TokenContentEditorForImageSignature {
  Args: TokenContentEditorForImageSignature['Args'] & {
    linkUrl?: string;
  };
}

export default class TokenContentEditorForLinkableImageComponent extends TokenContentEditorForImage<TokenContentEditorForLinkableImageSignature> {
  maxTokenContentUrlLength = MAX_TOKEN_CONTENT_URL_LENGTH;

  get linkUrlCharacterLimitReached() {
    return this.args.linkUrl && this.args.linkUrl.length >= this.maxTokenContentUrlLength;
  }

  updateLinkUrl = (value: string) => {
    this.args.updateTokenContentProperty('linkUrl', value);
  };
}
