import type { User, Primitive } from '@sentry/types';
import Service, { inject as service } from '@ember/service';
import * as Sentry from '@sentry/ember';
import type SessionService from './-private/session';

export default class SentryService extends Service {
  @service declare session: SessionService;

  captureException(error: any) {
    Sentry.captureException(error);
  }

  captureMessage(message: string) {
    Sentry.captureMessage(message);
  }

  setUser(user: User | null) {
    Sentry.setUser(user);
  }

  setTag(key: string, value: Primitive) {
    Sentry.setTag(key, value);
  }

  setupUser() {
    if (this.session.isAuthenticated && this.session.data?.authenticated) {
      const { id, organization_users } = this.session.data.authenticated;

      this.setUser({
        id: `${id}`,
        username: [organization_users?.first_name, organization_users?.last_name].filter(Boolean).join(' '),
      });
    } else {
      this.setUser(null);
    }
  }
}
