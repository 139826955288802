/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  //region Attributes
  thumbnailMediaItemId: '',
  thumbnailUrl: '',
  dipsUrl: '',
  //endregion

  //region Computed Properties
  templateImageSrc: computed('thumbnailUrl', 'thumbnailMediaItemId', 'dipsUrl', function () {
    return this.thumbnailUrl || `//${this.dipsUrl}/${this.thumbnailMediaItemId}?width=290`;
  }),
  //endregion
});
