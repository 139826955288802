/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { set } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import $ from 'jquery';
import RSVP from 'rsvp';

export default Component.extend({
  //region Dependencies
  store: service(),
  //endregion

  //region Ember Hooks
  didInsertElement() {
    this._super(...arguments);
    this._setSearchInputFocus();
  },
  //endregion

  //region Attributes
  /**
   * The list of promotionPreset Ids, used to show promotionSummaries that
   * have are already selected as turnkeys (could change in the future)
   * @type {Array}
   */
  'promotion-preset-ids': null,
  /**
   * The action for closing the modal
   */
  close: x => x,
  /**
   * The action for removing the promotionSummary as a turnkey
   */
  'remove-item': x => x,
  /**
   * The action for adding the promotionSummary as a turnkey
   */
  'add-item': x => x,
  //endregion

  //region Properties
  searchInput: '',
  searchResults: null,
  isLoadingSearchResults: false,
  //endregion

  init() {
    this._super(...arguments);

    set(this, 'promotion-preset-ids', this['promotion-preset-ids'] || []);
  },

  //region Methods
  /**
   * @private
   */
  _updateSearch() {
    if (!this.isDestroyed) {
      const searchValue = this.searchInput;
      set(this, 'isLoadingSearchResults', true);

      if (!isEmpty(searchValue)) {
        return this.store.query('promotionSummary', { searchValue }).then(searchResults => {
          set(this, 'searchResults', searchResults);
          set(this, 'isLoadingSearchResults', false);
          return searchResults;
        });
      }
      return RSVP.resolve([]);
    }
  },
  /**
   * @private
   */
  _setSearchInputFocus() {
    $('.ssViewAllItemsInput').focus();
  },
  //endregion

  //region Actions
  actions: {
    updateSearchInput({ target: { value } }) {
      set(this, 'searchInput', value);
      debounce(this, this._updateSearch, 250);
    },
  },
  //endregion
});
