/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/use-ember-get-and-set */ // FIXME
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import groupBy from 'lodash/groupBy';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

export default Route.extend(AppContextRoute, {
  //region Ember Hooks
  snackbar: service(),
  store: service(),
  router: service(),
  notifications: service(),

  beforeModel() {
    this.store.unloadAll('searchResult');
  },
  model() {
    return RSVP.hash({
      previousRoute: this.router.currentRouteName,
      onlyHasInteractiveContentOrContests:
        this.modelFor('organizations.organization').organization.onlyHasInteractiveContentOrContests,
    });
  },
  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('searchValue', '');
      controller.set('groupedConsumerUsers', null);
      controller.set('selectedSubTypes', []);
      controller.set('selectedStartDate', undefined);
      controller.set('selectedEndDate', undefined);
      controller.set('includeArchived', false);
      controller.set('shouldCloseDropdown', true);
      controller.set('_selectedSubTypes', []);
      controller.set('_selectedStartDate', undefined);
      controller.set('_selectedEndDate', undefined);
      controller.set('_includeArchived', false);
    }
  },
  //endregion

  //region Actions
  actions: {
    goBack() {
      const previousRoute = get(this.modelFor('organizations.organization.search'), 'previousRoute');
      if (
        previousRoute.indexOf('people.person') > -1 ||
        previousRoute.indexOf('loading') > -1 ||
        previousRoute.indexOf('search') > -1
      ) {
        this.router.transitionTo('organizations.organization.organization-promotions.index');
      } else {
        if (window.history.length >= 2) {
          window.history.back();
        } else {
          this.router.transitionTo('organizations.organization.organization-promotions.index');
        }
      }
    },

    getSearchResults(params) {
      if (!isEmpty(params.searchValue)) {
        set(this, 'controller.isLoadingSearchResults', true);
        this.store
          .query('searchResult', {
            organizationId: get(this.modelFor('organizations.organization'), 'organization.id'),
            ...params,
          })
          .then(searchResults => {
            const orgPromos = get(searchResults, 'firstObject.organizationPromotions');
            // search_results return embedded organization_promotions and promotions records, but Ember Data does not associate them for some reason
            orgPromos.forEach(orgPromo => {
              set(orgPromo, 'promotions', this.store.peekRecord('promotion', orgPromo.promotionId));
            });
            const audiences = get(searchResults, 'firstObject.audiences');
            const messageCampaigns = get(searchResults, 'firstObject.messageCampaigns');
            const groupedConsumerUsers = groupBy(get(searchResults, 'firstObject.consumerUsers').toArray(), u =>
              get(u, 'organizationName')
            );
            const groupedConsumerUsersArray = Object.keys(groupedConsumerUsers).map(user => groupedConsumerUsers[user]);
            set(this, 'controller.organizationPromotions', orgPromos);
            set(this, 'controller.audiences', audiences);
            set(this, 'controller.messageCampaigns', messageCampaigns);
            set(this, 'controller.groupedConsumerUsers', groupedConsumerUsersArray);
            set(this, 'controller.consumerUsersTotalRecords', get(searchResults, 'meta.consumerUsersTotalRecords'));
            set(this, 'controller.isLoadingSearchResults', false);
          });
      }
    },
    setMessageStatus(message, status) {
      set(message, 'statusTypeId', status);
      message.save().then(() => {
        this.refreshList(message);
        this.checkForPageOutOfRange(message);
      });
    },
    async setAudienceStatus(audience) {
      const newStatusTypeId = get(audience, 'isArchived') ? 1 : 2;
      set(audience, 'statusTypeId', newStatusTypeId);
      try {
        await audience.save();
      } catch (e) {
        const message = get(audience, 'errors.firstObject.message');
        audience.rollbackAttributes();
        this.snackbar.show(`${message}`, '', 12000, 'error');
      }
    },
  },
  //endregion
});
