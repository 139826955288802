/* eslint-disable ember/no-mixins */
import { AsyncHasMany, attr, hasMany } from '@ember-data/model';
import enums from 'ember-cli-ss-enums/services/enums';
import PromotionTypeName from 'partner/mixins/promotion-type-name';
import BaseModel from 'secondstreet-common/models/base';
import type TurnkeyCategoryModel from './turnkey-category';

export default class PromotionPresetModel extends BaseModel.extend(PromotionTypeName) {
  @attr('string') declare name: string;
  @attr('boolean') declare isDefault: boolean;
  @attr('boolean') declare isFeatured: boolean;
  @attr('number') declare displayOrder: number;
  @attr('number') declare promotionSubTypeId: number;
  @attr('number') declare promotionTypeId: number;
  @attr('number') declare promotionId: number;
  @attr('number') declare statusTypeId: number;
  @attr('number') declare legacyContestId: number;
  @attr('date') declare dateModified: Date;
  @attr('date') declare dateCreated: Date;
  @attr('number') declare mediaItemId: number;
  @attr('number') declare organizationId: number;

  @hasMany('turnkey-category', { async: true }) declare turnkeyCategories: AsyncHasMany<TurnkeyCategoryModel>;

  get isTrending() {
    const id = enums.findWhere('TURNKEY_CATEGORY', { name: 'Trending Now' });
    return !!this.turnkeyCategories.findBy('id', `${id}`);
  }

  get isContest() {
    return [3, 4, 5, 8, 13].includes(this.promotionTypeId);
  }

  get isInteractiveContent() {
    return [1, 2, 6, 7, 9, 10, 11, 12, 14].includes(this.promotionTypeId);
  }

  get isEventSignup() {
    return [15].includes(this.promotionTypeId);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'promotion-preset': PromotionPresetModel;
  }
}
