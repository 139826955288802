/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';

function getDateErrors(dateType) {
  return computed('errors', `round.${dateType}`, function () {
    const currentDate = this.round.startDate;
    const issueWithCurrentRound = this.round.startDate >= this.round.endDate;
    let issueWithPreviousRounds = false;
    let issueWithFutureRounds = false;

    for (let i = 0; i < this.rounds.length; i++) {
      const { startDate, endDate } = this.rounds[i];

      if (i < this.rounds.indexOf(this.round) && (currentDate <= startDate || currentDate < endDate)) {
        issueWithPreviousRounds = true;
      } else if (i > this.rounds.indexOf(this.round) && (currentDate > startDate || currentDate >= endDate)) {
        issueWithFutureRounds = true;
      }
    }

    return (this.errors && issueWithCurrentRound) || issueWithPreviousRounds || issueWithFutureRounds;
  });
}

export default Component.extend({
  //region Ember Hooks
  tagName: '',
  //endregion

  //region Computed Properties
  startDateHasErrors: getDateErrors('startDate'),
  endDateHasErrors: getDateErrors('endDate'),
  //endregion
});
