/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  //region Ember Hooks
  enums: service(),
  tagName: '',
  //endregion

  //region Computed Properties
  ugcSweepsEntryIntervalOptions: computed('enums', function () {
    return get(this, 'enums.data.UGC_SWEEPS_ENTRY_OPTIONS');
  }),
  selectedEntryIntervalOption: computed('matchup.{entryIntervalTypeId,entriesAllowedNumber}', function () {
    return this.enums.findWhere(
      'UGC_SWEEPS_ENTRY_OPTIONS',
      {
        entryIntervalTypeId: get(this, 'matchup.entryIntervalTypeId'),
        entriesAllowedNumber: get(this, 'matchup.entriesAllowedNumber'),
      },
      {}
    );
  }),

  //endregion

  //region Actions
  actions: {
    removeRound(matchup) {
      if (this['matchup-removed'] && typeof this['matchup-removed'] === 'function') {
        this['matchup-removed'](matchup);
      }
    },
    frequencyChanged(ugcSweepsEntryIntervalOption) {
      set(this, 'matchup.entryIntervalTypeId', get(ugcSweepsEntryIntervalOption, 'entryIntervalTypeId'));
      set(this, 'matchup.entriesAllowedNumber', get(ugcSweepsEntryIntervalOption, 'entriesAllowedNumber'));
    },
  },
  //endregion
});
