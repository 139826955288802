/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, getProperties, set, setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import difference from 'lodash/difference';
import flatten from 'lodash/flatten';
import { setMessageIsConfirmed } from 'partner/utils/message-campaign-helpers';
import { generateBodyAndSave } from 'partner/utils/message-version';
import RSVP from 'rsvp';
import { spreadIf } from 'secondstreet-common/utils/functional';
import { alphabeticalByProperty, keepSorting, simplyByProperty } from 'secondstreet-common/utils/sorting';

const ORGANIZATION_PROMOTION_ROUTE = 'organizations.organization.organization-promotions.organization-promotion';
const ORGANIZATION_PROMOTION_SETUP_ROUTE =
  'organizations.organization.organization-promotions.organization-promotion.setup';

const tokenKeyAssociations = {
  mainImage: {
    designTokenKey: 'templateImage',
    sharedAttributes: ['value', 'mediaItemId', 'isDisabled'],
    additionalAttributes: {
      linkUrl: '{{Promotion.Url}}',
      altText: '{{Promotion.Name}}',
    },
  },
  colorCodePrimary: {
    designTokenKey: 'primaryColor',
    sharedAttributes: ['value', 'isDisabled'],
  },
  colorCodeSecondary: {
    designTokenKey: 'primaryColor',
    sharedAttributes: ['value', 'isDisabled'],
  },
};

/**
 * Orders message campaigns as they appear in the UI: Thank You Email, Invite Email 1, Invite Email 2, etc.
 * NOTE: we should never trust the order of message campaigns in memory. The user can add and remove new
 * message campaigns at any point. In addition, the API returns message campaigns of both types in the same
 * request, which will not reflect their order of appearance in the designer. Use this function to target
 * a message campaign that appears in a specific place in the UI.
 *
 * This intentionally sorts the array in-place, to keep it in sync with the setup model's array.
 * @param {Array} messageCampaigns
 */
const sortedMessageCampaigns = messageCampaigns =>
  messageCampaigns.sort((a, b) =>
    keepSorting(alphabeticalByProperty('messageCampaignType')(b, a), simplyByProperty('id')(a, b))
  );

export default Route.extend({
  //region Dependencies
  enums: service(),
  features: service(),
  store: service(),
  router: service(),
  settings: service(),
  //endregion

  shouldPreloadMessages: true,

  get messageCampaigns() {
    return sortedMessageCampaigns(this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE).displayedPromotionEmails);
  },

  async beforeModel(transition) {
    // Preload the messages of the messageCampaigns
    if (this.shouldPreloadMessages) {
      this.shouldPreloadMessages = false;

      await RSVP.all(
        this.messageCampaigns.map(messageCampaign =>
          this.store.query('message', { messageCampaignId: messageCampaign.id })
        )
      );
    }

    const messages = this.messageCampaigns.mapBy('messages.firstObject');
    const messageVersions = messages.mapBy('messageVersions.firstObject');
    const messageVersionIds = messageVersions.mapBy('id');

    let redirectQueryParams;

    if (
      messageVersions.firstObject &&
      (!transition.to.queryParams.messageVersionId ||
        !messageVersionIds.includes(transition.to.queryParams.messageVersionId))
    ) {
      redirectQueryParams = { messageVersionId: messageVersions.firstObject.id };
    }

    if (!messageVersions.firstObject && transition.to.queryParams.messageVersionId) {
      redirectQueryParams = { messageVersionId: null };
    }

    if (redirectQueryParams) {
      transition.abort();

      this.router.replaceWith(this.fullRouteName, { queryParams: redirectQueryParams });
    }
  },

  async model() {
    const organizationId = this.modelFor('organizations.organization').organization.id;
    const organizationPromotionModel = this.modelFor(ORGANIZATION_PROMOTION_ROUTE);
    const messageCampaigns = sortedMessageCampaigns(
      this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE).displayedPromotionEmails
    );
    const { promotion } = organizationPromotionModel.organizationPromotion;

    const { audiencesByCampaign } = await RSVP.hash({
      tokenContentsByCampaign: RSVP.all(
        messageCampaigns.map(messageCampaign =>
          this.store.query('message-version-template-token-content', { messageCampaignId: messageCampaign.id })
        )
      ),
      audiencesByCampaign: RSVP.all(
        messageCampaigns
          .rejectBy('isThankYou')
          .map(messageCampaign =>
            this.store.query('message-campaign-audience', { messageCampaignId: messageCampaign.id })
          )
      ),
    });

    return RSVP.hash({
      organizationPromotion: organizationPromotionModel.organizationPromotion,
      _settings: this.settings.preload('dips_url'),
      senderAccounts: this.store.query('sender-account', { organizationId }),
      tokens: this.store.query('token', { organizationId }),
      templates: this.store.query('message-body-template', { organizationId }),
      locations: this.store.query('location', { isPrimary: true, organizationId }),
      audiences: this.store.query('audience', { organizationId }),
      designTokenContents: this.fetchDesignTokenContents(),
      messageCampaignAudiences: flatten(audiencesByCampaign.map(audiences => audiences.toArray())),
      matchups: this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE).matchups,
      promotionProduct: this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE).promotionProduct,
      tokenFallbackSettings: this.store.query('setting', { key: 'messaging_dynamic_token_fallback' }),
      messageCampaigns,
      ...spreadIf(['Ballot', 'UGCVoting'].includes(promotion.promotionType), {
        voteIntervalTypeIdSetting: this.store.queryRecord('setting', { key: 'vote_allowed_interval' }),
        votesAllowedNumberSetting: this.store.queryRecord('setting', { key: 'votes_allowed_number' }),
        entryIntervalTypeIdSetting: this.store.queryRecord('setting', { key: 'entries_allowed_interval' }),
        entriesAllowedNumberSetting: this.store.queryRecord('setting', { key: 'entries_allowed_number' }),
      }),
    });
  },

  async afterModel(model) {
    this._super(...arguments);

    const { tokens, designTokenContents, messageCampaigns } = model;
    const dipsUrl = this.settings.getValueFor('dips_url');
    const messages = messageCampaigns.mapBy('messages.firstObject');
    const messageVersions = messages.mapBy('messageVersions.firstObject');

    // in case a message version uses a deleted custom template, which would not be returned in a GET to message body templates
    await Promise.all(messageVersions.mapBy('messageBodyTemplate'));

    const editableTemplateMessageVersions = messageVersions
      .filterBy('message.messageCampaign.canCurrentlyEdit')
      .filterBy('isTemplate');

    // run this on every route load in case new tokens were added to a custom template
    await Promise.all(
      editableTemplateMessageVersions.map(messageVersion =>
        this.createTemplateTokenContents(messageVersion, designTokenContents)
      )
    );

    // run this on every route load in case a custom template was edited
    if (editableTemplateMessageVersions.length) {
      generateBodyAndSave(editableTemplateMessageVersions, dipsUrl, tokens);
    }

    try {
      // save changes to message versions
      await RSVP.allSettled(messageVersions.filterBy('message.messageCampaign.canCurrentlyEdit').map(mv => mv.save()));
    } finally {
      const failedMessageVersions = messageVersions.rejectBy('isValid');
      failedMessageVersions.forEach(mv => {
        setMessageIsConfirmed({
          usesSMC: false,
          messageCampaign: get(mv, 'message.messageCampaign.content'),
          value: false,
        });
      });
    }

    // Refresh the messages next time you come to this route
    this.shouldPreloadMessages = true;
  },

  setupController(controller, model) {
    this._super(...arguments);
    // All new invite emails start with a default message campaign audience and a likely-outdated audience count
    //
    // We assume an invite email without an audience member count date means it has just been created (which also means
    // there should only be one invite email), so we find it and trigger a count update for it.
    //
    // All subsequent visits to this route should NOT trigger a count update and count updates for any added
    // invite emails are triggered elsewhere (upon creation).
    const outdatedInviteEmails = model.messageCampaigns.filterBy('isInvite').rejectBy('audienceMemberCountDate');
    outdatedInviteEmails.map(email => this.updateMemberCount(email.id));
  },
  //endregion

  //region Methods
  async createTemplateTokenContents(messageVersion, designTokenContents) {
    const template = await messageVersion.messageBodyTemplate;
    const missingTokens = difference(template.tokens.toArray(), messageVersion.tokenContents.mapBy('token'));

    // If an item token content has a link url, but no source image (either from a media item id or an external source)
    // we assume the promo was just created and the item token content should be edited with the template image from the design step
    const itemTokenContent = messageVersion.messageVersionTemplateTokenContents.findBy('token.key', 'item');
    const itemTokenContentNeedsImage =
      itemTokenContent?.itemImageLinkUrl && !itemTokenContent.mediaItemId && !itemTokenContent.itemImageExternalSrcUrl;

    if (itemTokenContentNeedsImage) {
      this.editItemTokenContent(itemTokenContent, designTokenContents);
    }

    // create missing tokens
    const newTokenContents = missingTokens.map(token => {
      const tokenContent = this.store.createRecord('message-version-template-token-content');
      tokenContent.initializeValues(token, messageVersion);

      const tokenKeyAssociation = tokenKeyAssociations[token.key];
      if (!tokenKeyAssociation) {
        return tokenContent;
      }

      const designTokenContent = designTokenContents.findBy('token.key', tokenKeyAssociation.designTokenKey);
      if (!designTokenContent || !designTokenContent.isValidForInviteEmailContent) {
        return tokenContent;
      }

      const inheritedAttributes = getProperties(designTokenContent, ...tokenKeyAssociation.sharedAttributes);
      setProperties(tokenContent, { ...inheritedAttributes, ...tokenKeyAssociation.additionalAttributes });

      return tokenContent;
    });

    return RSVP.all(newTokenContents.map(tokenContent => tokenContent.save()));
  },
  editItemTokenContent(itemTokenContent, designTokenContents) {
    const templateImageTokenContent = designTokenContents.findBy('token.key', 'templateImage');

    // if the template image token content is complete (i.e. not the default image), use it; otherwise use the default that comes with the item
    if (templateImageTokenContent.isValidForInviteEmailContent) {
      setProperties(itemTokenContent, {
        mediaItemId: templateImageTokenContent.mediaItemId,
        itemImageExternalSrcUrl: templateImageTokenContent.value,
      });
    } else {
      set(
        itemTokenContent,
        'itemImageExternalSrcUrl',
        itemTokenContent.token.placeholderTokenDefaultContent.itemImageExternalSrcUrl
      );
    }

    itemTokenContent.save();
  },
  buildMessageCampaignName(messageCampaignType) {
    const existingMessageCampaigns = this.controller.model.messageCampaigns.filterBy(
      'messageCampaignType',
      messageCampaignType
    );
    const organizationPromotionName = this.modelFor(ORGANIZATION_PROMOTION_ROUTE).organizationPromotion.name;
    return `${organizationPromotionName} ${messageCampaignType.split(/(?=[A-Z])/).join(' ')} Email ${
      existingMessageCampaigns.length + 1
    }`;
  },

  addDataToModelAndTransition(newMessageCampaign, newMessageCampaignAudiences) {
    // we do not want to refresh the model because we do not want to disrupt the UI, even briefly
    this.send('updatePromotionEmails', 'addObject', newMessageCampaign);
    this.send('updateDisplayedPromotionEmails', 'addObject', newMessageCampaign);

    this.controller.model.messageCampaignAudiences.addObjects(newMessageCampaignAudiences);

    const firstNewMessageVersion = newMessageCampaign.messages.firstObject.messageVersions.firstObject;
    set(this, 'controller.messageVersionId', firstNewMessageVersion.id);
  },

  async updateMemberCount(id) {
    set(this, 'controller.isUpdatingRecipientsCount', true);
    await this.store.query('message-campaign', { id, updateCount: true });
    set(this, 'controller.isUpdatingRecipientsCount', false);
  },

  async fetchDesignTokenContents() {
    const designTemplateTypeId = this.enums.findWhere('TEMPLATE_TYPE', { name: 'Promotion' });

    const { promotionDesign } = await RSVP.hash({
      promotionDesign: this.store.queryRecord('design', { designTemplateType: designTemplateTypeId }),
      designTemplates: this.store.query('design-template', { designTemplateTypeId }),
      _designTokens: this.store.query('design-token', {}), // Preload
    });

    return promotionDesign
      ? this.store.query('design-token-content', { designId: promotionDesign.id }).then(x => x.toArray())
      : [];
  },
  //endregion

  //region Actions
  actions: {
    didTransition() {
      this.send('emailChecklistStepLogic');
    },

    async createNewMessageCampaign(messageCampaignType) {
      set(this, 'controller.isAnythingSaving', true);

      const dipsUrl = this.settings.getValueFor('dips_url');
      const { tokens, designTokenContents } = this.controller.model;
      const messageCampaignPresetId = this.enums.findWhere(
        'MESSAGE_CAMPAIGN_PRESETS',
        { name: messageCampaignType },
        'id'
      );
      const organizationPromotionId = this.modelFor(ORGANIZATION_PROMOTION_ROUTE).organizationPromotion.id;
      const name = this.buildMessageCampaignName(messageCampaignType);

      try {
        const newMessageCampaign = await this.store
          .createRecord('message-campaign', { messageCampaignPresetId, organizationPromotionId, name })
          .save();

        // If we are creating a first-of-type invite email, it will come with a default message campaign audience and a
        // likely-outdated audience count, which means we should trigger a count update
        if (messageCampaignType === 'Invite') {
          this.updateMemberCount(newMessageCampaign.id);
        }

        const { newMessages, newMessageCampaignAudiences } = await RSVP.hash({
          newMessages: this.store.query('message', {
            messageCampaignId: newMessageCampaign.id,
          }),
          newMessageCampaignAudiences: this.store.query('message-campaign-audience', {
            messageCampaignId: newMessageCampaign.id,
          }),
          newTokenContents: this.store.query('message-version-template-token-content', {
            messageCampaignId: newMessageCampaign.id,
          }),
        });
        const newMessageVersions = newMessages.firstObject.messageVersions;

        await RSVP.all(
          newMessageVersions.map(messageVersion =>
            this.createTemplateTokenContents(messageVersion, designTokenContents)
          )
        );
        generateBodyAndSave(newMessageVersions, dipsUrl, tokens);
        try {
          // save changes to message versions
          await RSVP.allSettled(newMessageVersions.map(nmv => nmv.save()));
        } finally {
          setMessageIsConfirmed({
            usesSMC: false,
            messageCampaign: newMessageCampaign,
            value: false,
          });
        }

        this.addDataToModelAndTransition(newMessageCampaign, newMessageCampaignAudiences);
      } finally {
        set(this, 'controller.isAnythingSaving', false);
      }

      // if you're adding a first-of-type email it will always be unconfirmed
      this.send('uncheckChecklistStep', this.routeName);
    },

    async copyMessageCampaign(messageCampaignTypeView) {
      set(this, 'controller.isAnythingSaving', true);

      const lastMessageCampaign = messageCampaignTypeView.messageCampaignViews.lastObject.messageCampaign;
      const copyMessageCampaignId = lastMessageCampaign.id;
      const name = this.buildMessageCampaignName(lastMessageCampaign.messageCampaignType);

      try {
        const newMessageCampaign = await this.store
          .createRecord('message-campaign', { copyMessageCampaignId, name })
          .save();
        const { newMessageCampaignAudiences } = await RSVP.hash({
          newMessageCampaignAudiences: this.store.query('message-campaign-audience', {
            messageCampaignId: newMessageCampaign.id,
          }),
          newTemplateTokenContents: this.store.query('message-version-template-token-content', {
            messageCampaignId: newMessageCampaign.id,
          }),
          newMessages: this.store.query('message', {
            messageCampaignId: newMessageCampaign.id,
          }),
        });

        this.addDataToModelAndTransition(newMessageCampaign, newMessageCampaignAudiences);
      } finally {
        set(this, 'controller.isAnythingSaving', false);
      }

      // if you're copying an Invite Email, it will not copy over the Schedule, so it will always be unconfirmed
      this.send('uncheckChecklistStep', this.routeName);
    },
    updateInviteRecipientsCount(messageCampaignId) {
      return this.updateMemberCount(messageCampaignId);
    },
  },
  //endregion
});
