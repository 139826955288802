/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';

export default Component.extend({
  //region Ember Hooks
  tagName: 'ss-accordion-item',
  classNameBindings: [
    'canShowWarning:ssHasWarning',
    'isInvalid:ssInvalid',
    'isDeleted:ssIsDeleted',
    'isDirty:ssIsDirty',
    'isComplete:ssIsBestPractice',
    'is-expanded:ssAccordionOpen:ssAccordionClosed',
  ],
  //endregion

  //region Attributes
  /**
   * Will be yielded to the block. Provides no additional functionality.
   * This is mostly for convenience, in case your templates use {{compare}} for whether an item should be expanded and
   * you don't want to repeat that multiple times in your template.
   * @type {Boolean?}
   */
  'is-expanded': null,
  //endregion
});
