/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import moment from 'moment';
import PromotionTypeRoute from 'partner/mixins/promotion-type-route';
import { FRIEND_REFERRAL_FIELD_ID } from 'partner/utils/constants';
import RSVP from 'rsvp';
import { unloadEmptyRecords } from 'partner/utils/store-unload';

const ORGANIZATION_PROMOTION_SETUP_ROUTE =
  'organizations.organization.organization-promotions.organization-promotion.setup';

const ORGANIZATION_PROMOTION_SWEEPSTAKES_ROUTE =
  'organizations.organization.organization-promotions.organization-promotion.setup.sweepstakes';

export default Route.extend(PromotionTypeRoute, {
  //region Dependencies
  enums: service(),
  checklist: service(),
  snackbar: service(),
  router: service(),
  store: service(),
  settings: service(),
  current: service(),
  //endregion

  //region Ember Hooks
  /**
   * If the user can't see this checklist step (for example, if they don't have licensing), don't leave them on this route.
   * We're not doing this in the redirect hook because we don't even want to run the model hook if they shouldn't be here.
   */
  beforeModel(...args) {
    this._super(...args);

    unloadEmptyRecords(this.store, 'field');

    const SWEEPSTAKES_CHECKLIST_STEP_TYPE_ID = this.checklist.getChecklistStepId(
      ORGANIZATION_PROMOTION_SWEEPSTAKES_ROUTE
    );
    const steps = get(
      this.modelFor('organizations.organization.organization-promotions.organization-promotion.setup'),
      'checklist.checklistSteps'
    );
    if (isEmpty(steps.findBy('checklistStepTypeId', SWEEPSTAKES_CHECKLIST_STEP_TYPE_ID))) {
      this.router.replaceWith(this.checklist.getNextStep(SWEEPSTAKES_CHECKLIST_STEP_TYPE_ID));
    }
  },
  async model() {
    const organizationId = this.modelFor('organizations.organization').organization.id;
    const { organizationPromotion } = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion'
    );
    const { id: organizationPromotionId } = organizationPromotion;
    const formTypeId = this.enums.findWhere('FORM_TYPE', { name: 'ExtraChances' });
    const { isQuiz } = organizationPromotion.promotion;
    const { games, registrationEnabledSetting } = this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE);

    const hash = await RSVP.hash({
      organizationPromotion,
      organizationPromotionPayment: this.store.queryRecord('organization-promotion-payment', {}),
      sweepstakes: this.store.queryRecord('sweepstakes', { organizationId, organizationPromotionId }),
      registrationEnabledSetting,
      extraChancesField: isQuiz ? this.store.findRecord('field', FRIEND_REFERRAL_FIELD_ID) : null, // The API doesn't include this with starred fields
      starredFields: isQuiz ? this.store.query('field', { isStarred: true }) : null,
      extraChancesForm: isQuiz ? this.store.queryRecord('form', { formTypeId }) : null,
      _settings: this.settings.preload(['dips_url', 'minimum_nominations', 'minimum_votes'], { force: true }),
      organization: get(this.modelFor('organizations.organization'), 'organization'),
      games,
    });

    return {
      ...hash,
      dipsUrl: this.settings.getFor('dips_url'),
      minimumNominations: this.settings.getFor('minimum_nominations'),
      minimumVotes: this.settings.getFor('minimum_votes'),
    };
  },
  //endregion

  afterModel() {
    this.send('checkChecklistStep', this.fullRouteName);
  },

  setupController(controller) {
    this._super(...arguments);

    set(controller, 'minimumVotesError', false);
    set(controller, 'minimumNominationsError', false);
  },

  //region Methods
  async save() {
    const model = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion.setup.sweepstakes'
    );
    const { organizationPromotion } = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion'
    );
    const { minimumNominations, minimumVotes } = model;
    await Promise.all([
      minimumNominations.isDirty ? minimumNominations.save() : Promise.resolve(null),
      minimumVotes.isDirty ? minimumVotes.save() : Promise.resolve(null),
    ]);
    const { sweepstakes, games } = model;

    if (
      sweepstakes.isEnabledIsDirty &&
      (organizationPromotion.promotion.isUGCVoting || organizationPromotion.promotion.isBallot)
    ) {
      set(sweepstakes, 'startDate', organizationPromotion.submissionStartDate);
      set(sweepstakes, 'endDate', organizationPromotion.selectionEndDate);
    }

    if (sweepstakes.isEnabledIsDirty && organizationPromotion.promotion.isVotingBracket) {
      const now = moment();
      const primaryGame = games.findBy('isPrimary');
      set(sweepstakes, 'startDate', primaryGame.startDate);
      set(sweepstakes, 'endDate', primaryGame.endDate || moment(now).add(1, 'weeks').toDate());
    }

    await (sweepstakes.hasDirtyAttributes ? sweepstakes.save() : RSVP.resolve(sweepstakes));

    if (sweepstakes.isEnabled) {
      if (model.organizationPromotionPayment) {
        model.organizationPromotionPayment.deleteRecord();
        await model.organizationPromotionPayment.save();
      }

      this.snackbar.show('eCommerce has been disabled.', 'OK');
    }

    const DISABLED = this.enums.findWhere('REGISTRATION_TYPE', {
      name: 'Disabled',
    });
    const OPTIONAL = this.enums.findWhere('REGISTRATION_TYPE', {
      name: 'Optional',
    });

    if (sweepstakes.isEnabled && model.registrationEnabledSetting.value === DISABLED) {
      /*
        if enabledSetting is inherited one, we create a new setting and replace this inherited setting
        with newly created setting after save
      */
      const reloadSetting = model.registrationEnabledSetting.isInherited;
      set(model, 'registrationEnabledSetting.value', OPTIONAL);
      await model.registrationEnabledSetting.save();
      if (reloadSetting) {
        const setupRouteModel = this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE);
        const newSetting = await this.store.queryRecord('setting', { key: 'Registration_Type' });
        set(model, 'registrationEnabledSetting', newSetting);
        set(setupRouteModel, 'registrationEnabledSetting', newSetting);
      }

      this.snackbar.show('Registration has been enabled.', 'OK');
    }

    const { extraChancesForm } = model;
    if (extraChancesForm) {
      const formFields = get(extraChancesForm, 'formPages.firstObject.formFields') || [];
      const formFieldsDirty = formFields.isAny('hasDirtyAttributes');
      const formDirty = extraChancesForm.hasDirtyAttributes;
      const { deletedRecords } = extraChancesForm;
      if ((formDirty || formFieldsDirty || deletedRecords) && sweepstakes.isEnabled) {
        await model.extraChancesForm.save();
      }
    }

    this.checklist.checkChecklistStep(
      'organizations.organization.organization-promotions.organization-promotion.setup.sweepstakes'
    );

    set(this, 'controller.didSomethingChange', false);
  },
  //endregion

  //region Actions
  actions: {
    toggleSweepstakes() {
      this.modelFor(
        'organizations.organization.organization-promotions.organization-promotion.setup.sweepstakes'
      ).sweepstakes.toggleProperty('isEnabled');
    },
    save() {
      this.save();
    },
    saveAndContinue() {
      this.save().then(() => {
        this.send('continue');
      });
    },
    willTransition(transition) {
      const { sweepstakes, minimumNominations, minimumVotes } = this.modelFor(
        'organizations.organization.organization-promotions.organization-promotion.setup.sweepstakes'
      );
      let confirmed = !(
        sweepstakes.hasDirtyAttributes ||
        minimumNominations.hasDirtyAttributes ||
        minimumVotes.hasDirtyAttributes
      );
      confirmed = confirmed ? confirmed : window.confirm('Are you sure you want to leave without saving?');
      if (confirmed) {
        sweepstakes.rollbackAttributes();
        minimumNominations.rollbackAttributes();
        minimumVotes.rollbackAttributes();
        this._super(transition);
      } else {
        transition.abort();
      }
    },
    extraChancesLimitChanged(extraChanceOption) {
      const model = this.modelFor(
        'organizations.organization.organization-promotions.organization-promotion.setup.sweepstakes'
      );
      const { extraChanceRule } = model;
      set(extraChanceRule, 'chancesPerReferral', extraChanceOption.chancesPerReferral);
      set(extraChanceRule, 'maximumReferrals', extraChanceOption.maximumReferrals);
    },
  },
  somethingChanged() {
    set(this, 'controller.didSomethingChange', true);
  },
  //endregion
});
