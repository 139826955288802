/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import { singularize } from 'ember-inflector';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;

export default BaseModel.extend({
  //region Ember Hooks
  enums: service(),
  //endregion

  //region Properties
  untaggedCount: attr('number'),
  categoryName: attr('string'),
  //endregion

  //region Computed Properties
  categoryNameSingular: computed('categoryName', function () {
    return singularize(this.categoryName);
  }),
  categoryClassName: computed('categoryName', function () {
    return this.enums.findWhere('TAGGABLE_ENTITY_CATEGORY', { categoryName: this.categoryName }, 'categoryClass');
  }),

  multiTierTaggingEnabled: computed('categoryName', function () {
    return this.enums.findWhere('TAGGABLE_ENTITY_CATEGORY', { categoryName: this.categoryName }, 'multiTierTagging');
  }),
  //endregion
});
