/**
 * This determines the current environment (production/development/qa)
 * @deprecated - This is not using Ember CLI Environments.
 */
export default function (obj) {
  // Create the object if it doesn't exist
  if (!obj) {
    obj = {};
  }
  // Build tbe object properties for any that are missing
  if (typeof obj.production === 'undefined') {
    obj.production = 'production';
  }
  if (typeof obj.development === 'undefined') {
    obj.development = 'development';
  }
  if (typeof obj.qa === 'undefined') {
    obj.qa = 'qa';
  }
  // Detect what environment we're on
  const conditions = {
    'partners.secondstreet.com': 'production',
    'partners.dev.secondstreet.com': 'development',
    'partners.qa.secondstreet.com': 'qa',
  };
  let environment = conditions[window.location.hostname];
  if (window.location.hostname.startsWith('partners-qa-staging-')) {
    environment = 'production';
  } else if (window.location.hostname.startsWith('partners-qa-')) {
    environment = 'qa';
  }
  // If environment isn't set, it's probably localhost (or fancy dev stuff)!
  if (typeof environment === 'undefined') {
    environment = 'development';
  }
  // Give them the proper value based on current environment
  return obj[environment];
}
