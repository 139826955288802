import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class DashboardHistoryChipComponent extends Component {
  //region Ember Hooks
  @service
  enums;
  //endregion

  //region Computed Properties
  get promotionSubType() {
    return this.enums.findWhere('PROMOTION_SUB_TYPE', { id: this.args.historyItem.promotionSubTypeId }, 'name');
  }

  get messageCampaignType() {
    return this.enums.findWhere('MESSAGE_CAMPAIGN_TYPE', { id: this.args.historyItem.messageCampaignTypeId }, 'name');
  }
  //endregion
}
