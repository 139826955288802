/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  //region Dependencies
  enums: service(),
  session: service(),
  //endregion

  //region Properties
  statusTypeId: null,
  promotionTypeId: null,
  archived: false,
  isSettingStatus: false,
  //endregion

  //region Computed Properties
  promotionTypeIdInt: computed('promotionTypeId', function () {
    return window.parseInt(this.promotionTypeId, 10);
  }),
  showPromotionCreationButton: readOnly('promotionTypeId'),
  organization: alias('organizationController.model.organization'),
  /**
   * This will have to change when more than one promotionType is displayed on the page at a time
   */
  promotionCreationId: computed('promotionTypeId', function () {
    return this.enums.findWhere('PROMOTION_TYPE', { id: this.promotionTypeIdInt }, 'creationId');
  }),
  //endregion
});
