import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"split-time\" ...attributes>\n  <SsDateTimePicker\n    @resetOnNull={{true}}\n    @material={{true}}\n    @date={{this.datePart}}\n    @momentDateFormat={{default-date-format (hash withDay=true)}}\n    @disable-past-dates={{true}}\n    @show-time={{false}}\n    @classModifiers={{array \"split-time\"}}\n    @ssDateTimePicker={{false}}\n    @errors={{@errors}}\n    @attribute={{@errorsAttribute}}\n    @onChange={{this.changeDatePart}}\n  />\n  <span class=\"split-time__divider\"> at </span>\n  <TimePicker\n    @resetOnNull={{true}}\n    class=\"ss-material-input mc-time-picker split-time__time-picker\"\n    @time={{this.timePart}}\n    @display-format=\"hh:mm a\"\n    @data-format=\"HH:mm:ss\"\n    @errors={{@errors}}\n    @attribute={{@errorsAttribute}}\n    @action={{this.changeTimePart}}\n  />\n</div>\n", {"contents":"<div class=\"split-time\" ...attributes>\n  <SsDateTimePicker\n    @resetOnNull={{true}}\n    @material={{true}}\n    @date={{this.datePart}}\n    @momentDateFormat={{default-date-format (hash withDay=true)}}\n    @disable-past-dates={{true}}\n    @show-time={{false}}\n    @classModifiers={{array \"split-time\"}}\n    @ssDateTimePicker={{false}}\n    @errors={{@errors}}\n    @attribute={{@errorsAttribute}}\n    @onChange={{this.changeDatePart}}\n  />\n  <span class=\"split-time__divider\"> at </span>\n  <TimePicker\n    @resetOnNull={{true}}\n    class=\"ss-material-input mc-time-picker split-time__time-picker\"\n    @time={{this.timePart}}\n    @display-format=\"hh:mm a\"\n    @data-format=\"HH:mm:ss\"\n    @errors={{@errors}}\n    @attribute={{@errorsAttribute}}\n    @action={{this.changeTimePart}}\n  />\n</div>\n","moduleName":"partner/components/split-time.hbs","parseOptions":{"srcName":"partner/components/split-time.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

export default class SplitTimeComponent extends Component {
  extractTime(time) {
    return moment(time, ['hh:mma', 'h:mma', 'hh:mm a', 'h:mm a', 'ha', 'h a', 'hha', 'hh a']).format('hh:mm a');
  }

  @tracked timePart = this.extractTime(this.args.dateTime);

  get datePart() {
    return moment(this.args.dateTime).set({ h: 0, m: 0, s: 0, ms: 0 }).toDate();
  }
  /**
   * @function changedatePart - action, will update the year, month, and day of the dateTime
   * @param {Date} date - the JavaScript date object set by the date picker
   */
  @action
  changeDatePart(date) {
    const newDate = new Date(this.args.dateTime.valueOf());
    newDate.setDate(date.getDate());
    newDate.setMonth(date.getMonth());
    newDate.setFullYear(date.getFullYear());
    this.args.changeDate(newDate, this.args.dateProperty);
  }

  /**
   * @function changeTimePart - action, will update the hour, minutes, and seconds of the dateTime
   * @param {String} time - the time string in format `HH:mm:SS`
   */
  @action
  changeTimePart(time) {
    const newDate = new Date(this.args.dateTime.valueOf());
    if (!time) {
      time = this.extractTime(this.args.dateTime);
    }
    const parts = time.split(':');
    if (parts.length !== 3) {
      return this.timePart;
    }
    newDate.setHours(parts[0]);
    newDate.setMinutes(parts[1]);
    newDate.setSeconds(parts[2]);
    this.args.changeDate(newDate, this.args.dateProperty);
  }
}
