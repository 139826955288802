import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';
import type MatchupPlacingCriteriaModel from './matchup-placing-criteria';

export default class MatchupPlaceModel extends BaseModel {
  @service declare store: Store;
  @service declare enums: EnumsService;

  @attr('number') declare startRank: number;
  @attr('number') declare endRank: number;
  @attr('string') declare name: string;
  @attr('number') declare ownerEntityType: number;
  @attr('number') declare ownerEntityId: number;
  @attr('date') declare dateCreated: Date;
  @attr('date') declare dateModified: Date;
  @attr('boolean') declare isInherited: boolean;
  @attr('string') declare primaryColor: string | null;
  @attr('number') declare mediaSize: number | null;
  @attr('number') declare mediaItemId: number | null;
  @attr('string') declare mediaExternalSourceUrl: string | null;
  @attr('string') declare mediaFileName: string | null;

  @belongsTo('matchup-placing-criteria', { async: true })
  declare matchupPlacingCriteria: AsyncBelongsTo<MatchupPlacingCriteriaModel>;

  get numberOfWinners() {
    return this.endRank - this.startRank + 1;
  }

  get status() {
    //complete, incomplete, bestPractice
    return !isEmpty(this.name) && !isEmpty(this.endRank) ? 'bestPractice' : 'incomplete';
  }

  get hasLargeSize() {
    return (
      this.mediaSize ==
      this.enums.findWhere('MEDIA_SIZE_TYPE', {
        name: 'Large',
      })
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'matchup-place': MatchupPlaceModel;
  }
}
