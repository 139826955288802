const sum = [(a, b) => a + b, 0];

/**
 * Depending on what phase the promotion is in, this might be a count of votes or nominations.
 * If there are no write-ins, it's a count of nominations. Otherwise, it returns a total vote count.
 * @param votingEntry {VotingEntry}
 * @param hasWriteins {Boolean}
 * @param [childVotingEntries] {VotingEntry}
 * @returns {Number}
 */
export default function (votingEntry, hasWriteins, childVotingEntries) {
  const { nominationEntriesSummaries } = votingEntry;

  return hasWriteins
    ? votingEntry.voteCount +
        childVotingEntries.mapBy('voteCount').reduce(...sum) +
        nominationEntriesSummaries.mapBy('count').reduce(...sum)
    : votingEntry.totalCount; // Nominations
}
