/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-mixins */
import Controller from '@ember/controller';
import { readOnly } from '@ember/object/computed';
import HasCategoricalCharts from 'partner/mixins/has-categorical-charts';
import HasEventCharts from 'partner/mixins/has-event-charts';
import NeedsOrganizationPromotionController from 'partner/mixins/needs-organization-promotion-controller';

export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionDashboardPaymentFormController extends Controller.extend(
  HasCategoricalCharts,
  HasEventCharts,
  NeedsOrganizationPromotionController
) {
  get stripeVersionLinkDomain() {
    if (window.location.hostname === 'partners.secondstreet.com' || window.location.hostname.includes('qa-staging')) {
      return '';
    }
    return '/test';
  }

  get stripeVersionLink() {
    const { accountId } = this.model.stripePaymentProcessor;
    const { id } = this.model.organizationPromotion;
    return `https://dashboard.stripe.com/${accountId}${this.stripeVersionLinkDomain}/search?query=OrganizationPromotionId%3A${id}`;
  }

  @readOnly('organizationPromotion.numEligibleUsers')
  numEligibleUsers;
}
