/* eslint-disable ember/no-mixins */
import { inject as service } from '@ember/service';
import { set, action } from '@ember/object';
import Route from '@ember/routing/route';
import AppContextRoute from 'partner/mixins/app-context-route';

export default class SenderAccountsRoute extends Route.extend(AppContextRoute) {
  @service('enums') enums;
  @service('snackbar') snackbar;
  @service('store') store;
  @service('deliberate-confirmation') deliberateConfirmation;

  async model() {
    const organizationId = this.modelFor('organizations.organization').organization.id;
    const senderAccounts = await this.store.query('senderAccount', { organizationId });

    return senderAccounts.toArray();
  }

  confirmDeleteFromEmail(senderAccount) {
    return senderAccount.isAssociated
      ? {
          promptText: `This from address is used in an upcoming or ongoing email campaign. Would you like to delete this from address anyway and replace it with the default from address for this organization?`,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }
      : {
          promptText: `Are you sure you want to delete ${senderAccount.fromAccount}?`,
          cancelButtonText: 'No, cancel',
          confirmButtonText: 'Yes, delete',
        };
  }

  @action
  create() {
    set(this, 'controller.currentSenderAccount', this.store.createRecord('sender-account'));
    set(this, 'controller.showSenderAccountForm', true);
  }

  @action
  edit(senderAccount) {
    set(this, 'controller.currentSenderAccount', senderAccount);
    set(this, 'controller.showSenderAccountForm', true);
  }

  @action
  closeCreateFromModal() {
    set(this, 'controller.showSenderAccountForm', false);
  }

  @action
  async showRemoveConfirmation(senderAccount) {
    const confirmed = await this.deliberateConfirmation.show(this.confirmDeleteFromEmail(senderAccount));

    if (confirmed) {
      try {
        await senderAccount.destroyRecord();
        this.refresh();
      } catch (error) {
        this.snackbar.show(senderAccount.errors.firstObject.message, 'dismiss', -1, 'error', true);
      }
    }

    this.deliberateConfirmation.resetConfirmedStatus();
  }
}
