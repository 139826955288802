import Inflector from 'ember-inflector';

export function initialize() {
  Inflector.inflector.plural(/^is$/, 'are');
  Inflector.inflector.singular(/^are$/, 'is');
  Inflector.inflector.plural(/^was$/, 'were');
  Inflector.inflector.singular(/^was$/, 'was');
  Inflector.inflector.singular(/^were$/, 'was');
  Inflector.inflector.plural(/^were$/, 'were');

  Inflector.inflector.irregular('person added', 'people added');
  Inflector.inflector.irregular('person lost', 'people lost');
  Inflector.inflector.uncountable('interactive content');
}

export default {
  name: 'inflection',
  initialize,
};
