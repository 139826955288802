/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */ // FIXME
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import { scheduleOnce } from '@ember/runloop';
import $ from 'jquery';
import camelCase from 'lodash/camelCase';

const itemTokenContentsByCategory = {
  headline: ['itemTitle', 'linkUrl'],
  image: ['altText', 'itemImageLinkUrl', 'itemImageExternalSrcUrl', 'mediaItemId'],
  description: ['value'],
  button: ['itemButtonText', 'itemButtonLinkUrl'],
};

const itemDefaultValuesByCategory = {
  headline: ['itemTitle'],
  image: ['itemImageExternalSrcUrl', 'altText'],
  description: ['value'],
  button: ['itemButtonText', 'itemButtonLinkUrl'],
};

const itemCategoryTokenPropertyMap = {
  headline: {
    value: 'itemTitle',
    linkUrl: 'linkUrl',
  },
  image: {
    value: 'itemImageExternalSrcUrl',
    mediaItemId: 'mediaItemId',
    altText: 'altText',
    linkUrl: 'itemImageLinkUrl',
  },
  description: {
    value: 'value',
  },
  button: {
    value: 'itemButtonText',
    linkUrl: 'itemButtonLinkUrl',
  },
};

export default Component.extend({
  //region Ember Hooks
  classNames: 'article-token-contents',
  // endregion

  //region Attributes
  /**
   * Default values for itemTitle, mediaItemId, value, itemButtonText, and itemButtonLinkUrl
   * @type {?Object}
   */
  tokenDefaultContents: null,
  /**
   * Headline Text
   * @type {?String}
   */
  itemTitle: null,
  /**
   * Headline Link URL
   * @type {?String}
   */
  linkUrl: null,
  /**
   * Image altText
   * @type {?String}
   */
  altText: null,
  /**
   * Image Link URL
   * @type {?String}
   */
  itemImageLinkUrl: null,
  /**
   * Image Source URL
   * @type {?String}
   */
  itemImageExternalSrcUrl: null,
  /**
   * Item Media Item Id
   * @type {?Number}
   */
  mediaItemId: null,
  /**
   * Button Text
   * @type {?String}
   */
  itemButtonText: null,
  /**
   * Button Link URL
   * @type {?String}
   */
  itemButtonLinkUrl: null,
  /**
   * Description Text
   * @type {?String}
   */
  value: null,
  /**
   * @property {Boolean}
   */
  isDisabled: null,
  openImageLibrary() {},
  libraryImage: null,
  //endregion

  //region Properties
  /**
   * Currently active (expanded) item category
   * @type {?String}
   */
  activeItemCategory: null,
  //endregion

  //region Computed Properties
  /**
   * An array of attributes with validation errors
   * @param errors
   * @property {Ember.ComputedProperty<Boolean>}
   */
  attributesWithErrors: computed('errors.content.[]', function () {
    const errors = get(this, 'errors.content');
    return errors ? errors.mapBy('attribute') : [];
  }),
  /**
   * @param headlineText
   * @param headlineLink
   * @property {Ember.ComputedProperty<Boolean>}
   */
  headlineHasValues: computed('itemTitle', 'linkUrl', function () {
    return !!(this.itemTitle || this.linkUrl);
  }),
  /**
   * @param errors
   * @property {Ember.ComputedProperty<Boolean>}
   */
  headlineHasErrors: computed('attributesWithErrors', function () {
    return this.attributesWithErrors ? this.attributesWithErrors.includes('itemTitle') : false;
  }),
  /**
   * @param altText
   * @param link
   * @param source
   * @param mediaItemId
   * @property {Ember.ComputedProperty<Boolean>}
   */
  imageHasValues: computed('altText', 'itemImageLinkUrl', 'itemImageExternalSrcUrl', 'mediaItemId', function () {
    return !!(this.altText || this.itemImageLinkUrl || this.itemImageExternalSrcUrl || this.mediaItemId);
  }),
  /**
   * @param errors
   * @property {Ember.ComputedProperty<Boolean>}
   */
  imageHasErrors: computed('attributesWithErrors', function () {
    return this.attributesWithErrors
      ? this.attributesWithErrors.includes('mediaItemId') || this.attributesWithErrors.includes('altText')
      : false;
  }),
  /**
   * @param value
   * @property {Ember.ComputedProperty<Boolean>}
   */
  descriptionHasValues: notEmpty('value'),
  /**
   * @param buttonText
   * @param buttonLink
   * @property {Ember.ComputedProperty<Boolean>}
   */
  buttonHasValues: computed('itemButtonText', 'itemButtonLinkUrl', function () {
    return !!(this.itemButtonText || this.itemButtonLinkUrl);
  }),
  /**
   * @param errors
   * @property {Ember.ComputedProperty<Boolean>}
   */
  buttonHasErrors: computed('attributesWithErrors', function () {
    return this.attributesWithErrors
      ? this.attributesWithErrors.includes('itemButtonText') || this.attributesWithErrors.includes('itemButtonLinkUrl')
      : false;
  }),
  //endregion

  //region Actions
  actions: {
    setActiveCategory(category) {
      if (this.activeItemCategory === category) {
        set(this, 'activeItemCategory', null);
      } else {
        set(this, 'activeItemCategory', category);
        scheduleOnce('afterRender', null, () =>
          $('.flyout-contents').scrollTop($(`.token-category--${category}`).position().top - 50)
        );
      }
    },

    toggleCategoryFields(category) {
      if (this[`${category}HasValues`]) {
        itemTokenContentsByCategory[category].forEach(attribute => {
          this.updateTokenContentProperty(attribute, null);
        });
        if (this.activeItemCategory === category) {
          set(this, 'activeItemCategory', null);
        }
      } else {
        itemDefaultValuesByCategory[category].forEach(attribute => {
          this.updateTokenContentProperty(attribute, this.tokenDefaultContents[camelCase(attribute)]);
        });
        if (!this.activeItemCategory) {
          set(this, 'activeItemCategory', category);
        }
      }
    },
    updateItemTokenContentProperty(property, value) {
      this.updateTokenContentProperty(itemCategoryTokenPropertyMap[this.activeItemCategory][property], value);
    },
  },
  //endregion
});
