/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, get } from '@ember/object';

export default Controller.extend({
  //region Computed Properties
  messageVersion: computed('model.message.firstObject.messageVersions.[]', function () {
    return get(this, 'model.message.firstObject.messageVersions.firstObject');
  }),
  messageIndex: computed('model.{message.firstObject,messageCampaign.messages}', function () {
    const messages = get(this, 'model.messageCampaign.messages');
    return messages.indexOf(get(this, 'model.message.firstObject')) + 1;
  }),
  //endregion
});
