/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/use-ember-get-and-set */ // FIXME
import Controller, { inject as controller } from '@ember/controller';
import { get, observer, set } from '@ember/object';
import { assign } from '@ember/polyfills';
import { inject as service } from '@ember/service';

export default Controller.extend({
  //region Ember Hooks
  organization: controller('organizations.organization'),
  organizationPromotion: controller('organizations.organization.organization-promotions.organization-promotion'),
  enums: service(),
  router: service(),
  store: service(),
  deliberateConfirmation: service(),
  //endregion

  //region Properties
  searchTerm: '',
  defaultPageSize: 50,
  //endregion

  //region Observers
  matchupChanged: observer('selectedMatchup', function () {
    // transition to matchups/#/entries based on selected matchup
    const matchup = this.selectedMatchup;
    this.router.transitionTo(
      'organizations.organization.organization-promotions.organization-promotion.matchups.matchup.entries',
      matchup
    );
  }),
  //endregion

  //region Methods
  /**
   * Change the qualification of an entry.
   * @param {Promotion.EntriesOrganizationUser} entryUser
   * @param {number} statusTypeId
   */
  async changeQualification(entryUser, statusTypeId) {
    const entryId = entryUser.get('entryId');
    const entry = await this.store.find('entry', entryId);
    await get(entry, 'matchup');
    set(entry, 'statusTypeId', statusTypeId);
    await entry.save();
    set(entryUser, 'statusTypeId', statusTypeId);
  },

  async incrementPromotionEntryLimit() {
    const organizationId = get(this, 'organization.model.organization.id');
    const organizationPromotionId = get(this, 'organizationPromotion.model.organizationPromotion.id');

    const games = await this.store.query('game', { organizationId, organizationPromotionId }, { reload: true });
    const game = games.firstObject;
    if (!game || game.entriesPerMatchupMax < 1) {
      return;
    }

    set(game, 'entriesPerMatchupMax', game.entriesPerMatchupMax + 1);
    await game.save();
  },

  search(searchValue, page) {
    const matchupId = get(this, 'model.id');
    const organizationId = get(this, 'organization.model.organization.id');
    const organizationPromotionId = get(this, 'organizationPromotion.model.organizationPromotion.id');

    // Search params
    const params = searchValue ? { searchValue, matchupId } : { matchupId };
    assign(params, {
      // Paging params
      pageSize: this.defaultPageSize,
      pageIndex: page || 1,
      // General params
      organizationId,
      organizationPromotionId,
    });

    this.store.query('entriesOrganizationUser', params).then(users => {
      set(this, 'entries', users);
      set(this, 'searching', true);
      set(this, 'paging', get(users, 'meta'));
    });
  },
  //endregion

  //region Actions
  actions: {
    changeEntryPage(page) {
      const { searchTerm } = this;
      this.search(searchTerm, page);
    },
    search(searchTerm) {
      this.search(searchTerm);
    },
    async qualify(entryUser) {
      let isConfirmed = true;
      const matchupSummary = await this.store.findRecord('matchup-summary', this.model.id, { reload: true });
      if (matchupSummary.entryLimitReached) {
        isConfirmed = await this.deliberateConfirmation.show({
          promptText: 'Requalifying this entry will increase your entry limit by 1',
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
        });
      }
      if (matchupSummary.entryLimitReached && !isConfirmed) {
        return;
      }
      if (!matchupSummary.entryLimitReached || isConfirmed) {
        const activeStatus = get(this, 'enums.data').STATUS_TYPE.findBy('name', 'Active');
        await this.changeQualification(entryUser, activeStatus.id);
      }
      if (matchupSummary.entryLimitReached && isConfirmed) {
        await this.incrementPromotionEntryLimit();
      }
    },
    disqualify(entryUser) {
      const inActiveStatus = get(this, 'enums.data').STATUS_TYPE.findBy('name', 'InActive');
      this.changeQualification(entryUser, inActiveStatus.id);
    },
  },
  //endregion
});
