/* eslint-disable ember/no-classic-classes */
import { getOwner } from '@ember/application';
import { set } from '@ember/object';
import Evented from '@ember/object/evented';
import { run } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import { camelize } from '@ember/string';

const _stepFor = (service, routeName) => {
  const checklistStepTypeId = service.getChecklistStepId(routeName);
  const checklistSteps = service.checklist?.checklistSteps || [];
  return checklistSteps.findBy('checklistStepTypeId', checklistStepTypeId);
};

const _getChecklistStepStatus = (service, routeName) => {
  const step = _stepFor(service, routeName);
  return step?.checklistStepStatus?.percentComplete || 0;
};

const _setChecklistStepStatus = async (service, routeName, value) => {
  const checklistStep = _stepFor(service, routeName);
  if (checklistStep) {
    const statuses = await checklistStep.checklistStepStatuses;
    let { checklistStepStatus } = checklistStep;
    if (!checklistStepStatus || checklistStepStatus.percentComplete !== value) {
      if (!checklistStepStatus) {
        const store = getOwner(service).lookup('service:store');
        run(() => {
          checklistStepStatus = store.createRecord('checklistStepStatus', {
            percentComplete: value,
          });
          // The organizationPromotionId or messageCampaignId or audienceId should be set/cleared upon entering/leaving
          // organizationPromotion.setup and messageCampaign.setup and audience.setup routes
          if (service.organizationPromotionId) {
            set(checklistStepStatus, 'organizationPromotionId', service.organizationPromotionId);
          } else if (service.messageCampaignId) {
            set(checklistStepStatus, 'messageCampaignId', service.messageCampaignId);
          } else {
            set(checklistStepStatus, 'audienceId', service.audienceId);
          }
        });
        run(() => statuses.addObject(checklistStepStatus));
      } else {
        run(() => set(checklistStepStatus, 'percentComplete', value));
      }
      const organizationUserId = service.session.data.authenticated.organization_users.id;
      run(async () => {
        set(checklistStepStatus, 'organizationUserId', organizationUserId);
        await checklistStepStatus.save();
      });
    }
  }
};

const _getChecklistEnumValueByPromotionType = (service, checklistStepTypeId, propertyName) => {
  const checklistStepEnum = service.getChecklistStepEnum(checklistStepTypeId);
  const { messageCampaignId, messageCampaignTypeId } = service;
  if (messageCampaignTypeId && messageCampaignId) {
    // If these are both set, the service is currently being used for messaging
    let messageCampaignTypeName = service.enums.findWhere(
      'MESSAGE_CAMPAIGN_TYPE',
      { id: messageCampaignTypeId },
      'name'
    );
    messageCampaignTypeName = messageCampaignTypeName ? camelize(messageCampaignTypeName) : null;
    const destinationObject = checklistStepEnum[propertyName] || {};
    //return default value if campaign specific config does not exist
    return destinationObject[messageCampaignTypeName] || destinationObject.default;
  }
  const { audienceId } = service; // If this is set, the service is currently being used for audiences
  if (audienceId) {
    const audience = service.store.peekRecord('audience', audienceId);
    const id = parseInt(audience.id);
    let audienceTypePublicName = service.enums.findWhere('AUDIENCE_TYPE', { id }, 'publicName');
    audienceTypePublicName = audienceTypePublicName ? camelize(audienceTypePublicName) : null;
    const destinationObject = checklistStepEnum[propertyName] || {};
    //return default value if campaign specific config does not exist
    return destinationObject[audienceTypePublicName] || destinationObject.default;
  }
  let promotionTypeName = service.enums.findWhere('PROMOTION_TYPE', { id: service.promotionTypeId }, 'name');
  promotionTypeName = promotionTypeName ? camelize(promotionTypeName) : null;
  const destinationObject = checklistStepEnum[propertyName] || {};
  //return default value if promo specific config does not exist
  return destinationObject[promotionTypeName] || destinationObject.default;
};

const _getChecklistEnumValueByPromotionSubTypeId = (
  service,
  checklistStepTypeId,
  propertyName,
  promotionSubTypeId = null
) => {
  const checklistStepEnum = service.getChecklistStepEnum(checklistStepTypeId);
  // some iconClasses differ per promotionSubType. if the iconClass config is an object then find the
  // the related propertyName for that subType. If it's not an object, just use the value
  if (promotionSubTypeId && typeof checklistStepEnum[propertyName] === 'object') {
    let subTypeName = service.enums.findWhere('PROMOTION_SUB_TYPE', { id: promotionSubTypeId }, 'name');
    subTypeName = camelize(subTypeName);
    if (checklistStepEnum[propertyName][subTypeName]) {
      //specific icon for this sub type
      return checklistStepEnum[propertyName][subTypeName];
    }
  }
  //iconClass could be an object or just a string value
  return typeof checklistStepEnum[propertyName] === 'object'
    ? checklistStepEnum[propertyName].default
    : checklistStepEnum[propertyName];
};

/**
 * Checklist service that encapsulates logic for what the link for each step should be.
 * @see Promotion.ENUMS (CHECKLIST_STEP_TYPE)
 */
export default Service.extend(Evented, {
  //region Ember Hooks
  enums: service(),
  store: service(),
  session: service(),
  //endregion

  //region Methods
  /**
   * Gets the checklistStepId based on the given routeName
   * @param routeName that will be checked against config in @see Promotion.ENUMS
   * @returns {Number} checklistStepId
   */
  getChecklistStepId(routeName) {
    return this.enums.findWhere('CHECKLIST_STEP_TYPE', {
      relatedRoutes: [routeName],
    });
  },

  getChecklistStepEnum(checklistStepTypeId) {
    const searchObject = { id: checklistStepTypeId };
    return this.enums.findWhere('CHECKLIST_STEP_TYPE', searchObject, {});
  },

  getDestination(checklistStepTypeId) {
    return _getChecklistEnumValueByPromotionType(this, checklistStepTypeId, 'destination');
  },

  getIconClass(checklistStepTypeId, promotionSubTypeId) {
    return _getChecklistEnumValueByPromotionSubTypeId(this, checklistStepTypeId, 'iconClass', promotionSubTypeId);
  },

  getIconName(checklistStepTypeId, promotionSubTypeId) {
    return _getChecklistEnumValueByPromotionSubTypeId(this, checklistStepTypeId, 'iconName', promotionSubTypeId);
  },

  getNextStep(checklistStepTypeId) {
    return _getChecklistEnumValueByPromotionType(this, checklistStepTypeId, 'nextStep');
  },

  setChecklistStepStatus(routeName, desiredStatus) {
    const currentStatus = _getChecklistStepStatus(this, routeName);
    if (currentStatus !== desiredStatus) {
      _setChecklistStepStatus(this, routeName, desiredStatus).then(() => {
        //send the current checklist to be used by listeners to make sure they care about this event
        this.trigger('stepUpdated', this.checklist);
        const action = desiredStatus === 100 ? 'stepChecked' : 'stepUnchecked';
        this.trigger(action, this.checklist);
      });
    }
  },
  checkChecklistStep(routeName) {
    this.setChecklistStepStatus(routeName, 100);
  },

  uncheckChecklistStep(routeName) {
    this.setChecklistStepStatus(routeName, 0);
  },

  isChecklistStepChecked(routeName) {
    return _getChecklistStepStatus(this, routeName) === 100;
  },
  //endregion
});
