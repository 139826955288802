/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

/**
 * @typedef {DS.Model} GlobalOptin
 */
export default BaseModel.extend({
  //region Stored Properties
  name: DS.attr('string'),
  defaultLabel: DS.attr('string'),
  statusTypeId: DS.attr('number'),
  //endregion

  //region Computed Properties
  statusType: enums.computed('name', 'statusTypeId'),
  //endregion

  //region Relationships
  organization: DS.belongsTo('organization', {
    async: false,
  }),
  //endregion
});
