import { ParamsType } from 'partner/services/preview';
import ballotNarrowScroll from './ballot-narrow-scroll';
import ballotCategoryDrawers from './ballot-category-drawers';
import ballotCategoryPages from './ballot-category-pages';
import ballotWideScroll from './ballot-wide-scroll';

export default (params?: ParamsType) => {
  switch (params?.ballotLayoutType) {
    case 'Narrow Scroll':
      return ballotNarrowScroll(params);
    case 'Wide Scroll':
      return ballotWideScroll(params);
    case 'Category Drawers':
      return ballotCategoryDrawers(params);
    case 'Category Pages':
      return ballotCategoryPages(params);
    default:
      return '';
  }
};
