/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  //region Attributes
  'layout-options': null,
  'is-articles': false,
  'component-attributes': null,
  'set-attribute'() {},
  //endregion

  //region Ember Hooks
  classNames: ['layout-editor'],
  //endregion

  //region Computed Properties
  value: computed('component-attributes.@each.{value,defaultValue}', 'layoutAttribute', function () {
    const componentAttrs = this['component-attributes'];
    const layoutAttr = this.layoutAttribute;
    return componentAttrs[layoutAttr].value || componentAttrs[layoutAttr].defaultValue;
  }),

  layoutAttribute: computed('is-articles', function () {
    return this['is-articles'] ? 'layout-style' : 'layout';
  }),
  //endregion

  //region Actions
  actions: {
    findAttrType(attr) {
      return this['component-attributes'][attr].attrType;
    },
  },
  //endregion
});
