/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed, get } from '@ember/object';
import { alias, oneWay } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';
import MessageVersionProperties from 'partner/mixins/message-version-properties';
import moment from 'moment';
import BaseModel from 'secondstreet-common/models/base';
import { inject as service } from '@ember/service';

const { attr, belongsTo } = DS;

export default BaseModel.extend(MessageVersionProperties, {
  defaultFormat: service('default-format'),

  //region Properties
  isApprovalMessageSent: attr('boolean'),
  isApproved: attr('boolean'),
  isManuallyModified: attr('boolean'),
  isSent: attr('boolean'),
  scheduleInstanceId: attr('number'),
  dateSent: attr('date'),
  messageVersionId: attr('number'),
  interactionCount: attr('number'),
  //end region

  //region Relationships
  messageVersion: belongsTo('messageVersion', { async: true }),
  messageCampaign: belongsTo('messageCampaign', { async: true }),
  //end region

  //region ComputedProperties
  messageVersionFeeds: oneWay('messageVersion.messageVersionFeeds'),
  bodySourceType: alias('messageVersion.bodySourceType'),

  isEditable: computed('isApprovalMessageSent', 'isApproved', 'isSent', function () {
    return this.isApprovalMessageSent && !this.isApproved && !this.isSent;
  }),

  isComplete: computed(
    'replyTo',
    'subject',
    'fromName',
    'body',
    'messageBodyTemplateTokens.@each.id',
    'tokenContents.@each.{tokenId,isIncomplete}',
    function () {
      const { replyTo } = this;
      const { subject } = this;
      const { fromName } = this;
      const { body } = this;
      const messageBodyTemplateTokens = this.messageBodyTemplateTokens || [];
      const tokenContents = this.tokenContents || [];

      // TODO: Don't hard-code a test for 'Subject Line Text' here.
      if ([replyTo, subject, fromName, body].any(isEmpty) || subject === 'Subject Line Text') {
        return false;
      }

      const templateIssues = messageBodyTemplateTokens.any(content =>
        tokenContents.any(x => get(x, 'tokenId') === get(content, 'id') && get(x, 'isIncomplete'))
      );
      return !templateIssues;
    }
  ),

  formattedDateSent: computed('dateSent', function () {
    return moment(this.dateSent).format(this.defaultFormat.getDateFormat());
  }),

  //end region
});
