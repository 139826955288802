import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { dashboard } from 'partner/utils/model-hooks';

/**
 * UGC Sweepstakes Dashboard Route
 * /o/:organization_id/op/:organization_promotion_id/dashboard/ugc-sweepstakes
 * @type {Ember.Route}
 */
export default class extends Route {
  //region Dependencies
  @service('enums') enums;
  @service('session') session;
  @service('store') store;
  @service('settings') settings;

  model(...args) {
    return dashboard('UGCSweepstakes').call(this, ...args);
  }
}
