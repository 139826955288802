/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default Component.extend({
  //region Attributes
  'component-attributes': null,
  'is-social-links': false,
  'is-menu-links': false,
  'set-attribute'() {},
  //endregion

  //region Ember Hooks
  classNames: ['links-editor'],
  //endregion

  //region Computed Properties
  socialOptions: computed(() => [
    {
      name: 'Facebook',
      value: 'facebook',
    },
    {
      name: 'X',
      value: 'x',
    },
    {
      name: 'Instagram',
      value: 'instagram',
    },
    {
      name: 'Pinterest',
      value: 'pinterest',
    },
    {
      name: 'Tiktok',
      value: 'tiktok',
    },
    {
      name: 'Tumblr',
      value: 'tumblr',
    },
    {
      name: 'LinkedIn',
      value: 'linkedin',
    },
    {
      name: 'Snapchat',
      value: 'snapchat',
    },
    {
      name: 'YouTube',
      value: 'youtube',
    },
    {
      name: 'Medium',
      value: 'medium',
    },
    {
      name: 'Vimeo',
      value: 'vimeo',
    },
  ]),

  menuOptions: computed(() => [
    {
      name: 'Menu Link 1',
      value: '1',
    },
    {
      name: 'Menu Link 2',
      value: '2',
    },
    {
      name: 'Menu Link 3',
      value: '3',
    },
    {
      name: 'Menu Link 4',
      value: '4',
    },
    {
      name: 'Menu Link 5',
      value: '5',
    },
  ]),

  links: computed('component-attributes', 'is-social-links', 'linksAttribute', function () {
    const componentAttributes = this['component-attributes'];
    const isSocial = this['is-social-links'];
    const attr = this.linksAttribute;
    const value =
      componentAttributes[attr].value === undefined
        ? componentAttributes[attr].defaultValue
        : componentAttributes[attr].value;
    return isEmpty(value)
      ? []
      : value
          .split(',')
          .map(x => (isSocial ? { label: `${x} Default Link`, value: x } : { label: `Menu Link ${x}`, value: x }));
  }),

  unusedSocialOptions: computed('socialOptions', 'links', function () {
    const { socialOptions } = this;
    const { links } = this;
    return socialOptions.reject(option => links.mapBy('value').includes(option.value));
  }),

  unusedMenuOptions: computed('menuOptions', 'links', function () {
    const socialOptions = this.menuOptions;
    const { links } = this;
    return socialOptions.reject(option => links.mapBy('value').includes(option.value));
  }),

  linksAttribute: computed('is-social-links', function () {
    return this['is-social-links'] ? 'icons' : 'links';
  }),
  //endregion

  //region Methods
  setAttribute(value) {
    this['set-attribute'](this.linksAttribute, value);
  },
  //endregion

  //region Actions
  actions: {
    addLink(value) {
      const links = this.links.mapBy('value').slice();
      links.push(value);
      this.setAttribute(links);
    },
    removeLink(value) {
      const links = this.links.mapBy('value').slice();
      const index = links.indexOf(value);
      links.splice(index, 1);
      this.setAttribute(links);
    },
    reorderItems(reorderedLinks) {
      this.setAttribute(reorderedLinks.mapBy('value'));
    },
  },
  //endregion
});
