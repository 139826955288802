/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { inject as controller } from '@ember/controller';
import { computed, get, set, setProperties } from '@ember/object';
import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { normalize } from 'secondstreet-common/utils/english';

const statusTypeIdFor = name =>
  computed(function () {
    return this.enums.findWhere('STATUS_TYPE', { name }, 'id');
  });

export default Controller.extend({
  //region Dependencies
  enums: service(),
  snackbar: service(),
  store: service(),

  personController: controller('organizations.organization.people.person'),
  //endregion

  //region Ember Hooks
  init() {
    this._super(...arguments);
    set(this, 'matchupEntriesByOrganizationPromotion', {});
    set(this, 'organizationPromotionDomainsByOrganizationPromotion', {});
    set(this, 'entryFormsByOrganizationPromotion', {});
  },
  //endregion

  //region Properties
  filterText: '',
  isSaving: false,
  isLoadingRecords: false,
  matchupEntryBeingEdited: null,
  expandedOrganizationPromotion: null,
  selectedMoveItem: null,
  matchups: null,

  /**
   * Object whose keys are OP IDs, and whose values are arrays of MatchupEntries belonging to the current user
   */
  matchupEntriesByOrganizationPromotion: null,

  /**
   * Object whose keys are OP IDs, and whose values are the primary OrganizationPromotionDomain
   */
  organizationPromotionDomainsByOrganizationPromotion: null,

  /**
   * Object whose keys are OP IDs, and whose values are the entry forms
   */
  entryFormsByOrganizationPromotion: null,
  //endregion

  //region Computed Properties
  pendingId: statusTypeIdFor('Submitted'),
  approvedId: statusTypeIdFor('Active'),
  rejectedId: statusTypeIdFor('Rejected'),

  filteredOrganizationPromotions: computed('model.organizationPromotions.[]', 'filterText', function () {
    const organizationPromotions = get(this, 'model.organizationPromotions');
    const { filterText } = this;
    return filterText
      ? organizationPromotions.filter(op => normalize(get(op, 'name')).includes(normalize(filterText)))
      : organizationPromotions;
  }),
  //endregion

  //region Methods
  async fetchOtherRecords(organizationPromotion) {
    const organizationPromotionId = get(organizationPromotion, 'id');
    const entryKey = `matchupEntriesByOrganizationPromotion.${organizationPromotionId}`;
    const domainKey = `organizationPromotionDomainsByOrganizationPromotion.${organizationPromotionId}`;
    const entryFormKey = `entryFormsByOrganizationPromotion.${organizationPromotionId}`;

    // No need to check the entryFormKey, as the promotion might not have an entry form
    if (get(this, entryKey) && get(this, domainKey)) {
      return;
    }

    set(this, 'isLoadingRecords', true);
    const promotionId = get(organizationPromotion, 'promotion.id');

    const [organizationPromotionDomains, matchupEntries, entryForms, matchups] = await Promise.all([
      this.store.query('organization-promotion-domain', {
        promotionId,
        organizationPromotionId,
      }),
      this.store.query('matchup-entry', {
        organizationUserId: get(this, 'personController.model.consumerUser.id'),
        promotionId,
        organizationPromotionId,
      }),

      this.store.query('form', {
        promotionId,
        organizationPromotionId,
        formTypeId: this.enums.findWhere('FORM_TYPE', { name: 'EntrySubmission' }),
      }),

      // If the matchups were embedded in the matchup-entry
      // or if the matchup-entry -> matchup relationship was async,
      // this call could be eliminated.
      this.store.query('matchup', {
        promotionId,
        organizationPromotionId,
      }),
    ]);

    run(() => {
      set(this, domainKey, get(organizationPromotionDomains, 'firstObject'));
      set(this, entryKey, matchupEntries.toArray());
      set(this, entryFormKey, get(entryForms, 'firstObject'));
      set(this, 'matchups', matchups);
      set(this, 'isLoadingRecords', false);
    });
  },
  //endregion

  //region Actions
  actions: {
    async changeStatus(matchupEntries, newStatusTypeId, onSuccess = null) {
      if (!Array.isArray(matchupEntries)) {
        matchupEntries = [matchupEntries];
      }
      await Promise.all(
        matchupEntries.map(matchupEntry => {
          set(matchupEntry, 'statusTypeId', newStatusTypeId);
          return matchupEntry.save();
        })
      );
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    },

    async saveMatchupEntry(matchupEntry, mediaItemTransform = null) {
      set(this, 'isSaving', true);

      await matchupEntry.saveWithEntryFieldValues(mediaItemTransform);

      run(() => setProperties(this, { isSaving: false, matchupEntryBeingEdited: null }));
    },

    toggle(organizationPromotion) {
      if (this.expandedOrganizationPromotion) {
        return set(this, 'expandedOrganizationPromotion', null);
      }

      set(this, 'expandedOrganizationPromotion', organizationPromotion);
      this.fetchOtherRecords(organizationPromotion);
    },

    toggleSearch() {
      if (this.isSearching) {
        return setProperties(this, { isSearching: false, searchText: '' });
      }
      set(this, 'isSearching', true);
    },

    async moveEntrant(matchupId) {
      const matchupEntry = await this.store.findRecord('matchup-entry', this.selectedMoveItem.entry.matchupEntry.id);
      const targetMatchup = this.store.peekRecord('matchup', matchupId);

      set(matchupEntry, 'matchup', targetMatchup);
      set(this, 'selectedMoveItem', null);

      try {
        await matchupEntry.save();
        this.snackbar.show(`The entrant has been moved to the category ${matchupEntry.matchup.name}`);
      } catch (e) {
        this.snackbar.show('There was a problem moving the entrant category', 'Dismiss', -1, 'error');
      }
    },
  },
  //endregion
});
