/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, set } from '@ember/object';
import { alias, mapBy, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import groupBy from 'lodash/groupBy';
import HasCategoricalCharts from 'partner/mixins/has-categorical-charts';
import HasEventCharts from 'partner/mixins/has-event-charts';
import NeedsOrganizationPromotionController from 'partner/mixins/needs-organization-promotion-controller';
import TickTock from 'partner/mixins/tick-tock';
import { emptyTimeSeriesData } from 'partner/utils/charts';
import { firstByProperty } from 'partner/utils/computed';

export default Controller.extend(HasCategoricalCharts, HasEventCharts, TickTock, NeedsOrganizationPromotionController, {
  //region Properties
  selectedRound: null,
  limitData: true,
  currentSweepsWinnerPage: 1,
  winnersCardTransition: 'toLeft',
  //endregion

  //region Dependencies
  permissions: service(),
  //endregion

  //region Computed Properties
  numEligibleUsers: readOnly('organizationPromotion.numEligibleUsers'),
  numEligibleVotes: readOnly('organizationPromotion.numEligibleVotes'),
  numUniqueVotes: readOnly('model.organizationPromotion.numUniquePeopleWithVotes'),
  sortedMatchups: computed('model.matchups.@each.startDate', function () {
    return this.model.matchups.sortBy('startDate');
  }),
  contestStartDate: alias('sortedMatchups.firstObject.startDate'),
  contestEndDate: alias('sortedMatchups.lastObject.endDate'),
  votesEmptyStateText: computed('contestStartDate', 'tock', function () {
    return this.contestStartDate > this.tock ? 'Waiting for Voting to Start' : 'Waiting for Votes';
  }),
  emptyTimeSeriesData: computed(() => emptyTimeSeriesData(10)()),
  votesChart: firstByProperty('name', 'Vote', 'model.eventCharts'),
  votesChartProperties: computed(
    'votesChart.{areCategoriesLoadedAndEmpty,cumulativeCategories}',
    'emptyTimeSeriesData',
    function () {
      const loadedAndEmpty = this.votesChart.areCategoriesLoadedAndEmpty;
      const { cumulativeCategories } = this.votesChart;
      const { emptyTimeSeriesData } = this;
      return this.getChartProperties(loadedAndEmpty, cumulativeCategories, emptyTimeSeriesData);
    }
  ),
  finalMatchup: computed('model.matchups.@each.iteration', function () {
    return this.model.matchups.sortBy('iteration').lastObject;
  }),
  winnerMatchups: mapBy('model.winners', 'matchup'),
  matchupsInSelectedRound: computed(
    'model.matchups.@each.iteration',
    'selectedRound.iteration',
    'mostRelevantRound.iteration',
    function () {
      return this.model.matchups.filterBy(
        'iteration',
        this.selectedRound ? this.selectedRound.iteration : this.mostRelevantRound.iteration
      );
    }
  ),
  noBracketWinner: computed('finalMatchup.id', 'winnerMatchups.@each.id', 'tock', 'contestEndDate', function () {
    const winnerMatchupIds = this.winnerMatchups.mapBy('id');
    return this.contestEndDate > this.tock || !winnerMatchupIds.includes(this.finalMatchup.id);
  }),
  hasBracketEnded: computed('contestEndDate', function () {
    return this.contestEndDate <= new Date();
  }),
  hasBracketStarted: computed('contestStartDate', function () {
    return this.contestStartDate <= new Date();
  }),
  winnersCardEmptyStateMessage: computed('tock', 'contestStartDate', function () {
    return this.contestStartDate > this.tock ? 'Voting has not started yet.' : 'Voting is in progress.';
  }),
  matchupsUniqueByIteration: computed('sortedMatchups.@each.iteration', function () {
    return this.sortedMatchups.uniqBy('iteration');
  }),
  mostRelevantRound: computed('matchupsUniqueByIteration.@each.{startDate,endDate}', 'hasBracketStarted', function () {
    const today = new Date();
    const { matchupsUniqueByIteration } = this;
    const currentMatchup = matchupsUniqueByIteration.find(
      matchup => today >= matchup.startDate && today <= matchup.endDate
    );
    if (!this.hasBracketStarted) {
      return matchupsUniqueByIteration.firstObject;
    }
    if (currentMatchup) {
      return currentMatchup;
    }
    return matchupsUniqueByIteration.filter(matchup => today >= matchup.endDate).lastObject;
  }),
  noVotesForThisRound: computed(
    'selectedRound.iteration',
    'mostRelevantRound.iteration',
    'model.matchupEntryVotes.@each.{iteration,voteCount}',
    function () {
      return this.model.matchupEntryVotes
        .filterBy('iteration', this.selectedRound ? this.selectedRound.iteration : this.mostRelevantRound.iteration)
        .every(x => !x.voteCount);
    }
  ),
  matchupChartData: computed('model.matchupEntryVotes', 'matchupsInSelectedRound.@each.{id,displayOrder}', function () {
    const pairedEntrants = groupBy(this.model.matchupEntryVotes, x => x.matchupId);
    const matchupViews = this.matchupsInSelectedRound.sortBy('displayOrder').map(matchup => ({
      hasBye: pairedEntrants[matchup.id].length < 2,
      entrants: [...pairedEntrants[matchup.id].sortBy('voteCount').reverse()],
    }));

    // remove byes from list and add them back to the end
    return matchupViews.rejectBy('hasBye').concat(matchupViews.filterBy('hasBye'));
  }),
  firstFourMatchupsChartData: computed('matchupChartData', function () {
    return this.matchupChartData.slice(0, 4);
  }),
  voteTotalsForThisRound: computed(
    'model.matchupEntryVotes.@each.{iteration,voteCount}',
    'selectedRound.iteration',
    'mostRelevantRound.iteration',
    function () {
      return this.model.matchupEntryVotes
        .filterBy('iteration', this.selectedRound ? this.selectedRound.iteration : this.mostRelevantRound.iteration)
        .mapBy('voteCount');
    }
  ),

  sweepsCardWinnersPagingInfo: computed('currentSweepsWinnerPage', 'model.sweepstakesWinners.length', function () {
    const numSweepsWinners = this.model.sweepstakesWinners.length;
    return numSweepsWinners > 1 ? `(${this.currentSweepsWinnerPage} of ${numSweepsWinners})` : false;
  }),
  //endregion

  //region Methods
  /**
   * This returns chart properties for vote and nomination charts
   **/
  getChartProperties(loadedAndEmpty, cumulativeCategories, emptyTimeSeriesData) {
    return {
      data: loadedAndEmpty ? emptyTimeSeriesData : cumulativeCategories,
      color: loadedAndEmpty ? 'rgb(172,172,172)' : 'rgb(44, 121, 229)',
    };
  },
  //endregion

  //region Actions
  actions: {
    selectedRoundChanged(round) {
      set(this, 'selectedRound', round);
    },
    getEntrantPercentWidth(voteCount) {
      const highestVoteCount = Math.max(...this.voteTotalsForThisRound);
      return `${(voteCount / highestVoteCount) * 100}%`;
    },
  },
});
