/* eslint-disable ember/use-ember-data-rfc-395-imports */
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';
import ApplicationSerializer from 'partner/serializers/application';

export default ApplicationSerializer.extend({
  enums: service(),
  /**
   * Converts related_entity_type_id and related_entity_id into proper relationships if they exist
   */
  normalize(modelClass, resourceHash = {}) {
    if (
      Object.prototype.hasOwnProperty.call(resourceHash, 'related_entity_id') &&
      Object.prototype.hasOwnProperty.call(resourceHash, 'related_entity_type_id')
    ) {
      const entityTypeName = this.enums.findWhere(
        'ENTITY_TYPE',
        { id: resourceHash['related_entity_type_id'] },
        'name'
      );
      const key = `${underscore(entityTypeName)}_id`;
      resourceHash[key] = resourceHash.related_entity_id;
      delete resourceHash.related_entity_type_id;
      delete resourceHash.related_entity_id;
    }
    return this._super(...arguments);
  },
});
