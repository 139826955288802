export default ({ layout, mediaIcon, isCommunityGallery }) => {
  const cardGalleryImageContainer = `
    <div class="card-gallery__image-container" style="font-family: 'Open Sans'">
      <div class="card-gallery__placeholder">
        <i class="ssIcon card-gallery__placeholder-icon ${mediaIcon}" aria-label="image"></i>
      </div>
    </div>
    <div class="card-gallery__text">
      <div class="card-gallery__title">Title</div>
      <div class="card-gallery__caption">Caption</div>
    </div>`;
  return `
<div class="ssUGCGallery">
  <div class="ssUGCGallery">
    <div class="ssUserInfo template-color-primary-background-color">
      <div class="user-info-container">
      </div>
    </div>
    <div class="ssGalleryWrapper ">
      <div class="round-info">
        <span class="round-info__header">
          Enter until m/dd @ h:mm pm
        </span>
      </div>
      <div class="ssGalleryEnterContainer">
        <a class="ssButton ssButtonPrimary ssGalleryEnterButton ssGalleryEnterButton--preview template-color-primary-background-color" href="#" target="_self">
          <i aria-hidden="true" class="${mediaIcon}"></i> ${isCommunityGallery ? 'Post' : 'Enter'} Your Own!
        </a>
      </div>
      <ul class="ssThumbnailGrid">
      ${
        layout === 'Justified'
          ? `
        <div class="justified-gallery">
          <div class="justified-gallery__placeholder-container">
            <div class="justified-gallery__placeholder-preview justified-gallery__placeholder-preview--long">
             <i class="ssIcon justified-gallery__placeholder-preview-icon ${mediaIcon}" aria-label="image"></i>
            </div>
            <div class="justified-gallery__placeholder-preview">
              <i class="ssIcon justified-gallery__placeholder-preview-icon ${mediaIcon}" aria-label="image"></i>
            </div>
            <div class="justified-gallery__placeholder-preview">
              <i class="ssIcon justified-gallery__placeholder-preview-icon ${mediaIcon}" aria-label="image"></i>
            </div>
            <div class="justified-gallery__placeholder-preview justified-gallery__placeholder-preview--long">
              <i class="ssIcon justified-gallery__placeholder-preview-icon ${mediaIcon}" aria-label="image"></i>
            </div>
          </div>
        </div>
        `
          : layout === 'Card'
          ? `
        <div class="card-gallery">
          <a class="card-gallery__card entry-one" href="#" target="_self">
            ${cardGalleryImageContainer}
          </a>
          <a class="card-gallery__card entry-two" href="#" target="_self">
            ${cardGalleryImageContainer}
          </a>
          <a class="card-gallery__card entry-three" href="#" target="_self">
            ${cardGalleryImageContainer}
          </a>
          <a class="card-gallery__card entry-four" href="#" target="_self">
            ${cardGalleryImageContainer}
          </a>
          <a class="card-gallery__card entry-five" href="#" target="_self">
            ${cardGalleryImageContainer}
          </a>
          <a class="card-gallery__card entry-six" href="#" target="_self">
            ${cardGalleryImageContainer}
          </a>
        </div>
        `
          : `
        <li class="ssThumbnailGridItem entry-one">
          <a href="#" target="_self">
            <div class="ssThumbnailGridSquare entry">
              <span class="ssThumbnailPlaceholder"><i class="ssIcon ${mediaIcon}"></i></span>
            </div>
          </a>
        </li>
        <li class="ssThumbnailGridItem entry-two">
          <a href="#" target="_self">
            <div class="ssThumbnailGridSquare entry">
              <span class="ssThumbnailPlaceholder"><i class="ssIcon ${mediaIcon}"></i></span>
            </div>
          </a>
        </li>
        <li class="ssThumbnailGridItem entry-three">
          <a href="#" target="_self">
            <div class="ssThumbnailGridSquare entry">
              <span class="ssThumbnailPlaceholder"><i class="ssIcon ${mediaIcon}"></i></span>
            </div>
          </a>
        </li>
        <li class="ssThumbnailGridItem entry-four">
          <a href="#" target="_self">
            <div class="ssThumbnailGridSquare entry">
              <span class="ssThumbnailPlaceholder"><i class="ssIcon ${mediaIcon}"></i></span>
            </div>
          </a>
        </li>
        <li class="ssThumbnailGridItem entry-five">
          <a href="#" target="_self">
            <div class="ssThumbnailGridSquare entry">
              <span class="ssThumbnailPlaceholder"><i class="ssIcon ${mediaIcon}"></i></span>
            </div>
          </a>
        </li>
        <li class="ssThumbnailGridItem entry-six">
          <a href="#" target="_self">
            <div class="ssThumbnailGridSquare entry">
              <span class="ssThumbnailPlaceholder"><i class="ssIcon ${mediaIcon}"></i></span>
            </div>
          </a>
        </li>
        <li class="ssThumbnailGridItem entry-seven">
          <a href="#" target="_self">
            <div class="ssThumbnailGridSquare entry">
              <span class="ssThumbnailPlaceholder"><i class="ssIcon ${mediaIcon}"></i></span>
            </div>
          </a>
        </li>
        `
      }
      </ul>
    </div>
    <div class="ssUGCGalleryShare">
      <ul class="ssShareListCompact ssShareList">
        <li class="ssShareListItem ssShareListItemFacebook">
          <a class="ssShareLink ssShareLinkFacebook" data-token="facebook" href="#">
            <i class="ssIcon-facebook"></i>
            <span class="ssShareLinkText">Share</span>
          </a>
        </li>
        <li class="ssShareListItem ssShareListItemTwitter">
          <a class="ssShareLink ssShareLinkTwitter" data-token="twitter" href="#">
            <i class="ssIcon-twitter"></i>
            <span class="ssShareLinkText">Tweet</span>
          </a>
        </li>
        <li class="ssShareListItem ssShareListItemEmail">
          <a class="ssShareLink ssShareLinkEmail" data-token="email" href="#">
            <i class="ssIcon-envelope"></i>
            <span class="ssShareLinkText">Email</span>
          </a>
        </li>
        <li class="ssShareListItem ssShareListItemPinterest">
          <a class="ssShareLink ssShareLinkPinterest" data-token="pinterest" href="#">
            <i class="ssIcon-pinterest"></i>
            <span class="ssShareLinkText">Pin It</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
`;
};
