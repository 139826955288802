import { set, setProperties } from '@ember/object';
import { normalize, stripSymbols } from 'secondstreet-common/utils/english';

/**
 * @class CustomColumn
 * @property {string} header - The original string text of the column header in the uploaded file
 * @property {string} fieldType
 * @property {boolean} isAutomatch - Did the system automatically suggest a matched field?
 * @property {boolean} isManuallyOverridden - Did the user override the automatch?
 * @property {Field|undefined} field - The Field that has been matched to this column
 */
export default class CustomColumn {
  constructor(header, fieldType = 'Textarea', field = undefined) {
    if (!header || typeof header !== 'string') {
      throw new Error('Custom Columns must have a header');
    }
    this.header = header;
    this.fieldType = fieldType;
    this.isAutomatch = !!field;
    this.isManuallyOverridden = false;
    this.field = field;
  }

  /**
   * @param {Field} field
   */
  match(field) {
    setProperties(this, {
      field,
      isManuallyOverridden: field !== this.field && this.isAutomatch,
    });
  }

  changeType(fieldType = 'Textarea') {
    if (this.field) {
      throw new Error('You cannot change the field type of a field that exists.');
    }

    set(this, 'fieldType', fieldType);
  }
}

const maybeTrueFalseCheckbox = str => {
  const lower = str.toLowerCase();
  return lower === 'true' || lower === 'false';
};

const maybeOneZeroCheckbox = str => {
  const lower = str.toLowerCase();
  return lower === '1' || lower === '0';
};

const maybeTFCheckbox = str => {
  const lower = str.toLowerCase();
  return lower === 't' || lower === 'f';
};

const maybeYesNoCheckbox = str => {
  const lower = str.toLowerCase();
  return lower === 'yes' || lower === 'no';
};

const maybeYNCheckbox = str => {
  const lower = str.toLowerCase();
  return lower === 'y' || lower === 'n';
};

const maybeOnOffCheckbox = str => {
  const lower = str.toLowerCase();
  return lower === 'on' || lower === 'off';
};

const maybeDate = str => !isNaN(new Date(str).getTime());

// Matches numbers like 12345 or 12,345 or 12345.67 or 12,345.67
const maybeNumber = str => str.match(/^(\d+|\d{1,2}|\d{1,2},\d{3})(,\d{3})*(\.\d*)?$/);

const maybeTextbox = str => str.length <= 125;

const maybeSelect = (distinctSoFar, rowCount) => distinctSoFar <= 10 && distinctSoFar <= rowCount / 4;

/**
 * @param workbook
 * @param original
 * @param {Field[]} fields
 * @param {String[]} skip
 * @returns {CustomColumn[]}
 */
export const mapParsed = (workbook, original, fields, skip = []) => {
  const parsed = [...original];
  const headers = parsed.shift();
  const rowCount = parsed.length;
  return headers
    .filter(header => !skip.includes(stripSymbols(normalize(header))))
    .map(header => {
      const matched = fields.find(field => normalize(field.name) === normalize(header));
      if (matched) {
        return new CustomColumn(header, matched.fieldType, matched);
      }

      // Not using the index from map because that is post-filter, and parsed is pre-filter.
      const headerIndex = headers.indexOf(header);
      let couldBeTrueFalseCheckbox = true;
      let couldBeOneZeroCheckbox = true;
      let couldBeTFCheckbox = true;
      let couldBeYesNoCheckbox = true;
      let couldBeYNCheckbox = true;
      let couldBeOnOffCheckbox = true;
      let couldBeDate = true;
      let couldBeNumber = true;
      let couldBeSelect = true;
      let couldBeTextbox = true;
      let hasData = false;
      const distinctValues = [];

      // This algorithm only touches each cell once, rather than needing to
      // loop through once for each possible type with Array.prototype.every.
      for (let rowIndex = 0; rowIndex < rowCount; rowIndex += 1) {
        const cell = parsed[rowIndex][headerIndex];
        if (!cell) {
          continue;
        }
        hasData = true;
        couldBeTrueFalseCheckbox && (couldBeTrueFalseCheckbox = maybeTrueFalseCheckbox(cell));
        couldBeOneZeroCheckbox && (couldBeOneZeroCheckbox = maybeOneZeroCheckbox(cell));
        couldBeTFCheckbox && (couldBeTFCheckbox = maybeTFCheckbox(cell));
        couldBeYesNoCheckbox && (couldBeYesNoCheckbox = maybeYesNoCheckbox(cell));
        couldBeYNCheckbox && (couldBeYNCheckbox = maybeYNCheckbox(cell));
        couldBeOnOffCheckbox && (couldBeOnOffCheckbox = maybeOnOffCheckbox(cell));
        couldBeDate && (couldBeDate = maybeDate(cell));
        couldBeNumber && (couldBeNumber = maybeNumber(cell));
        if (couldBeSelect && distinctValues.indexOf(cell) === -1) {
          distinctValues.push(cell);
          couldBeSelect && (couldBeSelect = maybeSelect(distinctValues.length, rowCount));
        }
        couldBeTextbox && (couldBeTextbox = maybeTextbox(cell));
      }

      const type = couldBeTrueFalseCheckbox
        ? 'SingleCheckbox'
        : couldBeOneZeroCheckbox
        ? 'SingleCheckbox'
        : couldBeTFCheckbox
        ? 'SingleCheckbox'
        : couldBeYesNoCheckbox
        ? 'SingleCheckbox'
        : couldBeYNCheckbox
        ? 'SingleCheckbox'
        : couldBeOnOffCheckbox
        ? 'SingleCheckbox'
        : couldBeNumber
        ? 'NumberInput'
        : couldBeDate
        ? 'CustomDateInput'
        : couldBeSelect
        ? 'SelectSingle'
        : couldBeTextbox
        ? 'Textbox'
        : 'Textarea';

      return new CustomColumn(header, hasData ? type : 'null');
    });
};
