import { attr, hasMany } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class ProductModel extends BaseModel {
  @hasMany('promotion-product') promotionProduct;
  @attr('number') organizationId;
  @attr('number') productTypeId;
  @attr('number') fulfillmentTypeId;
  @attr('string') adminName;
  @attr('number') defaultPrice;
  @attr('number') defaultCurrencyId;
  @attr('number') cost;
  @attr('number') statusTypeId;
  @attr('string') displayName;
  @attr('string') description;
  @attr('boolean') isInherited;
}
