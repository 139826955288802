/* eslint-disable ember/no-classic-classes, ember/no-jquery */
import { set } from '@ember/object';
import { bool } from '@ember/object/computed';
import Evented, { on } from '@ember/object/evented';
import Service from '@ember/service';
import $ from 'jquery';
import Confetti from 'partner/utils/confetti';

/**
 * Shows confetti to the users. Because whimsy.
 * Warning: While the confetti is showing, the user CANNOT interact with the UI.
 *
 * @listens start - Start the confetti
 * @listen cancel - Cancel the confetti in-progress
 * @event Confetti#started - Triggers once the confetti has started showing
 * @event Confetti#finished - Triggers once the confetti is completely done showing
 *
 * @typedef {Ember.Service} Confetti
 * @mixes Ember.Evented
 */
export default Service.extend(Evented, {
  //region Computed Properties
  isStarted: bool('canvas'),
  //endregion

  //region Methods
  /**
   * @private
   */
  startConfetti() {
    this.setupDOM();
    const confetti = new Confetti.Context(this.canvas);
    confetti.stopped = () => this.confettiStopped();
    confetti.start();
    set(this, 'confetti', confetti);
  },
  /**
   * @private
   */
  setupDOM() {
    const canvas = document.createElement('canvas');
    document.body.appendChild(canvas);
    canvas.style.position = 'fixed';
    canvas.style.left = 0;
    canvas.style.top = 0;
    canvas.style.bottom = 0;
    canvas.style.right = 0;
    canvas.style.zIndex = 100000;
    const eventListener = () => {
      this.trigger('cancel');
    };
    $(canvas).on('click', eventListener);
    set(this, 'canvas', canvas);
    set(this, 'eventListener', eventListener);
  },
  /**
   * @private
   */
  teardownDOM() {
    const { canvas } = this;
    if (canvas) {
      const $canvas = $(canvas);
      $canvas.off('click', this.eventListener);
      $canvas.remove();
    }
    set(this, 'canvas', undefined);
  },
  /**
   * @private
   */
  stopConfetti() {
    const { confetti } = this;
    if (confetti) {
      confetti.stop();
    }
    this.teardownDOM();
  },
  /**
   * @private
   */
  confettiStopped() {
    this.teardownDOM();
    this.trigger('finished');
  },
  //endregion

  //region Events
  start: on('start', function () {
    if (!this.isStarted) {
      this.startConfetti();
      this.trigger('started');
    }
  }),
  cancel: on('cancel', function () {
    this.stopConfetti();
    this.trigger('finished');
  }),
  //endregion
});
