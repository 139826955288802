/* eslint-disable @typescript-eslint/restrict-template-expressions */
import isLocalhost from 'secondstreet-common/utils/is-localhost';

abstract class Embed {
  abstract get elements(): string;

  uid = Math.random().toString(36).substring(2, 8);

  get domain() {
    return window.location.hostname;
  }

  get embedCode() {
    return this.elements
      .split('\n')
      .map(line => line.trim())
      .filter(line => line)
      .join('\n');
  }
}

abstract class ReactAppEmbed extends Embed {
  get hostname() {
    if (this.domain === 'partners.secondstreet.com') {
      return 'embed.secondstreetapp.com';
    } else if (this.domain.includes('partners-qa-')) {
      return this.domain.replace('partners-qa', 'consumer-qa');
    } else if (this.domain === 'localhost') {
      return 'embeddev.secondstreetapp.com';
    }

    return this.domain;
  }

  get apiUrlAttr() {
    if (this.domain.includes('partners-qa-')) {
      return `data-api-url="https://${this.domain.replace('partners-qa', 'api-qa')}"`;
    } else if (this.domain === 'localhost') {
      return `data-api-url="https://api-qa-master.secondstreetapp.com"`;
    }

    return '';
  }
}

class WidgetEmbed extends ReactAppEmbed {
  designId: string;

  constructor(designId: string) {
    super();
    this.designId = designId;
  }

  get elements() {
    return `
      <div ${this.apiUrlAttr} data-ss-embed="optin" data-design-id="${this.designId}" data-script="${this.uid}"></div>
      <script src="https://${this.hostname}/Scripts/dist/optin.js" data-ss-script="${this.uid}"></script>
    `;
  }
}

class PreferenceCenterEmbed extends ReactAppEmbed {
  organizationId: string;

  constructor(organizationId: string) {
    super();
    this.organizationId = organizationId;
  }

  get elements() {
    return `
      <div ${this.apiUrlAttr} data-ss-embed="preferences" data-organization-id="${this.organizationId}" data-script="${this.uid}"></div>
      <script defer src="https://${this.hostname}/Scripts/dist/preferences.js" data-ss-script="${this.uid}"></script>
    `;
  }
}

class PromotionFeedEmbed extends Embed {
  organizationId: string;

  constructor(organizationId: string) {
    super();
    this.organizationId = organizationId;
  }

  get hostname() {
    if (this.domain === 'partners.secondstreet.com' || this.domain.startsWith('partners-qa-staging')) {
      return `o-${this.organizationId}.secondstreetapp.com`;
    }

    return `o-${this.organizationId}.dev.secondstreetapp.com`;
  }

  get elements() {
    return `
      <div data-ss-embed="feed" data-script="${this.uid}"></div>
      <script defer src="https://${this.hostname}/Scripts/dist/feed.js" data-ss-script="${this.uid}"></script>
    `;
  }
}

class PromotionEmbed extends Embed {
  constructor(protected organizationPromotionId: string, protected organizationPromotionGuid: string) {
    super();
  }

  // consumer-qa domains will work to retrieve embed.js, but they do not play well with Facebook, so we just embed-XXX/embeddev
  get hostname() {
    if (this.domain === 'partners.secondstreet.com' || this.domain.startsWith('partners-qa-staging')) {
      return `embed-${this.organizationPromotionId}.secondstreetapp.com`;
    } else if (this.domain.includes('partners-qa-')) {
      return this.domain.replace('partners-qa', 'consumer-qa');
    }

    return 'embeddev.secondstreetapp.com';
  }

  get elements() {
    return `
      <div data-ss-embed="promotion" data-opguid="${this.organizationPromotionGuid}" data-routing="hash" data-script="${this.uid}"></div>
      <script defer src="https://${this.hostname}/Scripts/dist/embed.js" data-ss-script="${this.uid}">/**/</script>
    `;
  }

  /*
    When running in localhost start consumer site server at a port such as 4300 and use localhost
  */
  get previewCode() {
    const src = isLocalhost() ? 'http://localhost:4300/embed.js' : `https://${this.hostname}/Scripts/dist/embed.js`;

    return `
    <div data-ss-embed="promotion" data-opguid="${this.organizationPromotionGuid}" data-hash-params="true" data-routing="hash" data-script="${this.uid}"></div>
    <script src=${src} data-ss-script="${this.uid}">/**/</script>
  `;
  }
}

class AdgagementEmbed extends PromotionEmbed {
  constructor(organizationPromotionId: string, organizationPromotionGuid: string, protected adSize: number) {
    super(organizationPromotionId, organizationPromotionGuid);
  }

  get elements() {
    return `
      <div data-ss-embed="promotion" data-adgagement="${this.adSize}" data-opguid="${this.organizationPromotionGuid}" data-routing="hash" data-script="${this.uid}"></div>
      <script defer data-ss-script="${this.uid}" src="https://${this.hostname}/Scripts/dist/embed.js">/**/</script>
    `;
  }
}

export { PromotionFeedEmbed, PromotionEmbed, AdgagementEmbed, WidgetEmbed, PreferenceCenterEmbed };
