/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { firstPresentBy } from 'secondstreet-common/utils/functional';

const PREFERRED_DOMAIN_ORDER = ['eblastengine.com', 'secondstreetmail.com'];
const PROBLEMS = {
  SPF: [1, 101],
  Feedback: [4, 104],
};

export default Component.extend({
  //region Attributes
  /**
   * The {@link SenderAccount} that you want the validation message to be about
   * @property {SenderAccount}
   */
  'sender-account': null,

  /**
   * All of the {@link SenderAccount} records that belong to the current organization.
   * @property {SenderAccount[]}
   */
  'sender-accounts': null,
  //endregion

  //region Ember Hooks
  tagName: '',
  //endregion

  //region Computed Properties
  /**
   * @param {Number} senderAccountValidationTypeId
   * @returns {Boolean}
   */
  isProblemFeedback: computed('sender-account.senderAccountValidationTypeId', function () {
    return PROBLEMS['Feedback'].includes(get(this, 'sender-account.senderAccountValidationTypeId'));
  }),
  /**
   * @param {SenderAccount[]} accounts
   * @returns {?SenderAccount}
   */
  forwardingAccount: computed('sender-accounts.@each.fromAccountDomain', function () {
    const accounts = this['sender-accounts'];
    return accounts
      ? accounts.reduce(
          firstPresentBy(PREFERRED_DOMAIN_ORDER, x => get(x, 'fromAccountDomain')),
          accounts[0]
        )
      : null;
  }),
  //endregion
});
