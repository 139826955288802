/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { computed } from '@ember/object';
import { debounce } from '@ember/runloop';
import Attribute from 'partner/components/template-builder/attribute-editor/attribute';
import getValidNumber from 'partner/utils/valid-number';

const LINE_HEIGHT_ATTRS = ['description-line-height', 'line-height'];

export default Attribute.extend({
  //region Attributes
  max: null,
  min: 0,
  //endregion

  //region Computed Properties
  /**
   * Pixel values get saved to template builder as a string with px. We remove that and instead show it outside the input.
   * @param value
   * @param unit
   */
  formattedValue: computed('value', 'unitString', function () {
    const { value } = this;
    const unit = this.unitString;
    if (!value) {
      return unit === '%' ? '' : 0;
    }
    return unit ? value.replace(unit, '') : value;
  }),

  unitString: computed('attr-type', function () {
    const attrType = this['attr-type'];
    const match = attrType.match(/^unit\((.+)\)/);
    return attrType === 'integer' ? '' : match ? match[1] : '';
  }),

  isLineHeight: computed('attr-name', function () {
    return LINE_HEIGHT_ATTRS.includes(this['attr-name']);
  }),
  //endregion

  //region Methods
  setAttribute(attr, event) {
    const validNumber = getValidNumber(event);
    //append with 'px' because template-builder is expecting this in the string
    this['set-attribute'](attr, event.target.value === '' ? undefined : `${validNumber || 0}${this.unitString}`);
  },
  //endregion

  //region Actions
  actions: {
    onInput(event) {
      debounce(this, this.setAttribute, this['attr-name'], event, 150);
    },
  },
  //endregion
});
