/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import enums from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';
import type AudienceModel from './audience';

@makeBooleanProperties('AUDIENCE_FILTER_TYPE')
export default class AudienceFilterModel extends BaseModel {
  @service declare enums: EnumsService;

  @attr() declare filterValue: any;
  @attr() declare filterValueUpper: any;
  @attr('number') declare audienceFilterTypeId: number;
  @attr('number', { defaultValue: 1 }) declare comparisonTypeId: number;
  @attr('string') declare entityId: string;
  @attr('number') declare audienceFilterUnitsTypeId: number;
  @attr('string') declare locationReferencePoint: string;
  @attr('boolean') declare isExcluded: boolean;
  @attr('number') declare precedingWindowDays: number | null;
  @attr('number') declare entityTypeId: number;

  @belongsTo('audience', { async: false }) declare audience: AudienceModel;

  @enums.computed('name', 'audienceFilterTypeId', 'audienceFilterTypeId') declare audienceFilterType: string;
  @enums.computed('name', 'entityTypeId', 'entityTypeId') declare entityType: string;
  @enums.computed('name', 'comparisonTypeId', 'comparisonTypeId') declare comparisonType: string;
  @enums.computed('name', 'audienceFilterUnitsTypeId', 'audienceFilterUnitsTypeId')
  declare audienceFilterUnitsType: string;

  get fieldOptionIds() {
    return this.isField ? this.filterValue.split(',') : [];
  }

  /**
   * The CSS class used to show invalid age filter values,
   * necessary to add multiple CSS classes to the inputs
   */
  get ageCssClass() {
    return `ssAudienceFilterAgeNumberInput${this.errors.get('length') ? ' ssIsInvalid' : ''}`;
  }

  /**
   * The CSS class used to show invalid location range filter values,
   * necessary to add multiple CSS classes to the inputs
   */
  get locationRangeCssClass() {
    return `ssAudienceFilterLocationRangeInput${this.errors.has('filterValue') ? ' ssIsInvalid' : ''}`;
  }

  /**
   * The CSS class used to show invalid location reference point filter values,
   * necessary to add multiple CSS classes to the inputs
   */
  get locationReferencePointCssClass() {
    return `ssAudienceFilterLocationReferencePointInput${
      this.errors.has('locationReferencePoint') ? ' ssIsInvalid' : ''
    }`;
  }

  /**
   * Returns the text representation of the gender filter
   * type: Male, Female, or Not Specified
   */
  get genderName() {
    const intValue = window.parseInt(this.filterValue, 10);
    return this.isGender ? this.enums.findWhere('GENDER_FILTER_TYPE', { id: intValue }, 'name') : '';
  }

  get locationChipText() {
    const intValue = parseInt(this.filterValue);
    return this.audienceFilterType === 'StateProvince'
      ? this.enums.findWhere('STATE_PROVINCE', { id: intValue }, 'name')
      : this.audienceFilterType === 'LocationDistancePostalCode'
      ? `Within ${this.filterValue} miles of ${this.locationReferencePoint}`
      : this.locationReferencePoint;
  }

  get ageChipText() {
    return `${this.filterValue} - ${this.filterValueUpper}`;
  }

  get usePurchaseWindow() {
    return !isEmpty(this.precedingWindowDays);
  }

  set usePurchaseWindow(value) {
    this.precedingWindowDays = value ? 60 : null;
  }

  // Props from @makeBooleanProperties('AUDIENCE_FILTER_TYPE')
  declare isGender: boolean;
  declare isField: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'audience-filter': AudienceFilterModel;
  }
}
