/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend({
  organizationId: DS.attr('number'),
  organizationPromotionId: DS.attr('number'),
  promotionId: DS.attr('number'),
  contestId: DS.attr('number'),
  organizationUserId: DS.attr('number'),
  browserName: DS.attr('string'),
  browserPlatform: DS.attr('string'),
  browserType: DS.attr('string'),
  browserVersion: DS.attr('string'),
  promotionType: DS.attr('string'),
  organizationPromotionURL: DS.attr('string'),
  subject: DS.attr('string'),
  description: DS.attr('string'),
  email: DS.attr('string'),
  caseNumber: DS.attr('string'),
  platform: DS.attr('string'),
});
