import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-inline-styles style-concatenation}}\n{{#each @entities as |entity|}}\n  <Ballot::Ads::SideNav::ListItem\n    @selected={{eq @selectedOwnerId entity.id}}\n    @entity={{entity}}\n    style={{this.style}}\n    {{on \"click\" (fn @onChange entity.id)}}\n  />\n  {{#if entity.isExpanded}}\n    <Ballot::Ads::SideNav::List\n      @selectedOwnerId={{@selectedOwnerId}}\n      @onChange={{@onChange}}\n      @entities={{entity.children}}\n      @index={{inc @index}}\n    />\n  {{/if}}\n{{/each}}", {"contents":"{{! template-lint-disable no-inline-styles style-concatenation}}\n{{#each @entities as |entity|}}\n  <Ballot::Ads::SideNav::ListItem\n    @selected={{eq @selectedOwnerId entity.id}}\n    @entity={{entity}}\n    style={{this.style}}\n    {{on \"click\" (fn @onChange entity.id)}}\n  />\n  {{#if entity.isExpanded}}\n    <Ballot::Ads::SideNav::List\n      @selectedOwnerId={{@selectedOwnerId}}\n      @onChange={{@onChange}}\n      @entities={{entity.children}}\n      @index={{inc @index}}\n    />\n  {{/if}}\n{{/each}}","moduleName":"partner/components/ballot/ads/side-nav/list.hbs","parseOptions":{"srcName":"partner/components/ballot/ads/side-nav/list.hbs"}});
import Component from '@glimmer/component';

interface SideNavListSignature {
  Element: HTMLDivElement;
  Args: {
    index: number;
  };
}

export default class SideNavListComponent extends Component<SideNavListSignature> {
  get style() {
    return `padding-left: ${16 + this.args.index * 32}px`;
  }
}
