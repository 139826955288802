/* eslint-disable ember/no-jquery */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

/**
 * Side nav component based on http://www.google.com/design/spec/patterns/navigation-drawer.html
 *
 * <SideNavToggle /> component is used to trigger a event that opens this component.
 */

export default class SideNavComponent extends Component {
  //region Dependencies
  @service screen;
  @service('local-storage') localStorage;
  //endregion

  @tracked
  hidden = this.localStorage.getItem('isSideNavOpen') === false;

  @action
  toggleHidden() {
    this.hidden = !this.hidden;
  }
}
