/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { gt } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
const TABS_PER_PAGE = 3;

/**
 * Component that will contain horizontal tabs.
 * Most likely used inside a {[app-bar}}
 */
export default Component.extend({
  //region Ember Hooks
  classNameBindings: [':ssTabBar', 'areArrowsUseful:ssTabBarShow'],
  //endregion

  //region Properties
  currentPage: 1,
  tabs: null,
  //endregion

  //region Computed Properties
  totalPages: computed('tabs.length', function () {
    return Math.ceil(get(this, 'tabs.length') / TABS_PER_PAGE);
  }),
  areArrowsUseful: gt('totalPages', 1),
  hasNextArrow: computed('totalPages', 'currentPage', function () {
    return this.currentPage < this.totalPages;
  }),
  hasPreviousArrow: gt('currentPage', 1),
  visibleTabs: computed('tabs.[]', 'currentPage', function () {
    const endIndex = this.currentPage * TABS_PER_PAGE;
    const startIndex = Math.max(0, endIndex - TABS_PER_PAGE);
    return this.tabs.slice(startIndex, endIndex);
  }),
  //endregion

  init() {
    this._super(...arguments);

    set(this, 'tabs', this.tabs || []);
  },

  //region Actions
  actions: {
    registerTab(tab) {
      if (isEmpty(this.tabs)) {
        set(this, 'tabs', []);
      }
      this.tabs.addObject(tab);
    },
    unregisterTab(tab) {
      this.tabs.removeObject(tab);
    },
    previousPage() {
      this.decrementProperty('currentPage');
    },
    nextPage() {
      this.incrementProperty('currentPage');
    },
  },
  //endregion
});
