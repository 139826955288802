import shareList from './share-list';
import ballotSideNav from './ballot-side-nav';
import ballotEntrantWide from './ballot-entrant-wide';
import featuredEntrantsWide from './ballot-featured-entrants-wide';

export default `
  <div class="ssUGCGallery font-['Open_Sans']">
    <div class="ssUserInfo template-color-primary-background-color">
      <div class="user-info-container"></div>
    </div>
    <div class="ssUGCGallery">
      ${ballotSideNav}
      <div class="ssGalleryWrapper bg-white !p-0 !pl-11 !pr-4 flex flex-col gap-4">
        <div class="round-info !mt-8">
          <div class="round-info__voting-status">
            <div class="round-info__header">
                Vote until m/dd @ h:mm pm
            </div>
          </div>
        </div>
        <div class="rounded border border-solid border-gray-400">
          <div class="text-center text-lg bg-gray-400 p-4 cursor-pointer">
            <span font-bold>Category Name</span>
            <i class="material-icons absolute text-2xl ml-1">keyboard_arrow_up</i>
          </div>
          <div class="!pt-4 !ml-2 !mr-4">
            ${featuredEntrantsWide}
          </div>
          <div class="max-w-full px-0.5 pb-7 mx-2">
            <div>
              <div class="ssTileExternalHeaderContainer !mb-2 !ml-1 text-gray-500">
                Entrants
              </div>
              <div class="grid sm:grid-cols-4 auto-rows-fr gap-x-4 gap-y-4">
                ${ballotEntrantWide}
                ${ballotEntrantWide}
                ${ballotEntrantWide}
                ${ballotEntrantWide}
              </div>
            </div>
          </div>
        </div>
      </div>
      ${shareList}
    </div>
  </div>
`;
