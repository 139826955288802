/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller, { inject as controller } from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { run, debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const sortDefaults = {
  emailAddress: 'asc',
  optinSource: 'asc',
  audienceJoinDate: 'desc',
};
const oppositeDirection = dir => (dir === 'asc' ? 'desc' : 'asc');

export default class OrganizationsOrganizationDataAudiencesAudienceMembersController extends Controller {
  //region Dependencies
  @controller('organizations.organization.data.audiences')
  audiencesController;
  @controller('organizations.organization.data.audiences.audience')
  audienceController;
  //endregion

  //region Ember Hooks
  @service
  permissions;
  @service
  snackbar;
  @service
  deliberateConfirmation;
  queryParams = [{ searchValue: 'search' }, { columnSort: 'sort' }, { pageIndex: 'page' }];
  //endregion

  //region Properties
  /**
   * @property {Array<string,string>} First string is the column, second is ASC or DESC
   */
  columnSort = 'audienceJoinDate:desc';
  pageIndex = 1;
  searchValue = '';
  /**
   * Is the report being generated/downloaded?
   * @type {Boolean}
   */
  isDownloadingReport = false;
  /**
   * The audience used for generating a report download
   * @type {Object?}
   */
  selectedReportAudience = null;

  @tracked
  isNewMemberModalOpen = false;
  //endregion

  //region Computed Properties
  @computed('columnSort')
  get sortColumn() {
    return this.columnSort.split(':')[0];
  }

  @computed('columnSort')
  get sortDirection() {
    return this.columnSort.split(':')[1];
  }

  @computed('audienceController.model.audience.isSubscriptionAudience')
  get isSubscriptionAudience() {
    return this.audienceController.model.audience.isSubscriptionAudience;
  }

  @computed('audienceController.model.audience.isThirdPartyAudience')
  get isThirdPartyAudience() {
    return this.audienceController.model.audience.isThirdPartyAudience;
  }

  async optOutMember(audienceMember, event) {
    event.stopPropagation();
    event.preventDefault();

    const index = this.model.audienceMembers.indexOf(audienceMember);
    run(() => this.model.audienceMembers.toArray().removeObject(audienceMember));

    try {
      await audienceMember.destroyRecord();
      this.send('refreshAudience');
    } catch (err) {
      this.snackbar.serverError();
      run(() => this.model.audienceMembers.splice(index, 0, audienceMember));
    }
  }

  debouncedSearch(value) {
    set(this, 'pageIndex', 1);
    set(this, 'searchValue', value.length >= 2 ? value : '');
  }
  //endregion

  //region Actions
  @action
  searchChanged(value) {
    debounce(this, this.debouncedSearch, value, 500);
  }

  @action
  changePage(pageIndex) {
    run(() => set(this, 'pageIndex', pageIndex));
  }

  @action
  sortBy(newColumn) {
    if (!sortDefaults[newColumn]) {
      throw new Error(`Unsupported sort column: ${newColumn}`);
    }

    const [oldColumn, direction] = this.columnSort.split(':');
    run(() =>
      set(
        this,
        'columnSort',
        `${newColumn}:${newColumn === oldColumn ? oppositeDirection(direction) : sortDefaults[newColumn]}`
      )
    );
  }

  @action
  optOut(audienceMember, event) {
    this.optOutMember(audienceMember, event);
  }

  @action
  async showConfirmOptOut(audienceMember, event) {
    event.stopPropagation();
    const { deliberateConfirmation } = this;
    const confirmed = await deliberateConfirmation.show({
      promptText: `Are sure you want to opt out ${audienceMember.emailAddress} from this audience?`,
      cancelButtonText: 'No, Cancel',
      confirmButtonText: 'Yes, Opt Out',
    });

    if (confirmed) {
      await this.optOutMember(audienceMember, event);
    }

    deliberateConfirmation.resetConfirmedStatus();
  }

  @action
  toggleNewMemberModal() {
    this.isNewMemberModalOpen = !this.isNewMemberModalOpen;
  }
  //endregion
}
