import { attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { safeUnload } from 'partner/utils/ember-data';
import BaseModel from 'secondstreet-common/models/base';

export default class EmailNotificationModel extends BaseModel {
  @service store;

  @attr('number', {
    defaultValue: 1,
  })
  emailNotificationTypeId;
  @attr('number') emailNotificationScheduleTypeId;
  @attr('number') organizationPromotionId;
  @attr('number', {
    defaultValue: 1,
  })
  statusTypeId;

  @attr('string') primaryUrl;

  @attr('date') emailNotificationScheduleDate;

  @belongsTo('design') design;

  @hasMany('email-notification-recipient', { async: false }) emailNotificationRecipients;

  saveRecord() {
    /*
      Here we unload the duplicate entries which are created while saving a entity that contains embedded children entities.
      This is a known issue in ember-data.
      https://discuss.emberjs.com/t/proposal-fix-saving-new-embedded-records-creates-duplicates/3677
    */
    const emailNotificationRecipients = this.emailNotificationRecipients.toArray();

    return this.save().then(() => {
      emailNotificationRecipients.forEach(record => {
        record.isLoaded && isEmpty(record.id) && safeUnload(record, this.store);
      });
    });
  }
}
