/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/use-brace-expansion */ // FIXME
import Controller from '@ember/controller';
import { computed, get, set } from '@ember/object';
import { empty, filterBy, not } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';

export default Controller.extend({
  store: service(),

  //region Computed Properties
  activeMessageCampaignCategories: filterBy('model.messageCampaignCategories', 'hasDeletedStatusType', false),
  sortedMessageCampaignCategories: computed('activeMessageCampaignCategories.@each.name', function () {
    return this.activeMessageCampaignCategories.sortBy('name');
  }),
  removeCategoryNoDetails: computed('categoryToBeDeleted', function () {
    const categoryToBeDeleted = get(this, 'categoryToBeDeleted.name');
    return `I do not want to remove ${categoryToBeDeleted}.`;
  }),
  removeCategoryYesDetails: computed('categoryToBeDeleted', function () {
    const categoryToBeDeleted = get(this, 'categoryToBeDeleted.name');
    return `I want to permanently remove ${categoryToBeDeleted}.`;
  }),
  showEmptyMessage: empty('activeMessageCampaignCategories'),
  canSave: computed('categoryToEdit.isSaving', 'categoryToEdit.hasDirtyAttributes', 'categoryToEdit.name', function () {
    return (
      !get(this, 'categoryToEdit.isSaving') &&
      get(this, 'categoryToEdit.hasDirtyAttributes') &&
      get(this, 'categoryToEdit.name') &&
      !isEmpty(get(this, 'categoryToEdit.name').trim())
    );
  }),
  cantSave: not('canSave'),
  //endregion

  actions: {
    setCategoryToEdit(category) {
      set(this, 'categoryToEdit', category);
    },
    setCategoryToBeDeleted(category) {
      set(this, 'categoryToBeDeleted', category);
    },
    deleteCategory() {
      const category = this.categoryToBeDeleted;
      set(category, 'statusType', 'Deleted');
      //Don't actually remove it from the store. It could still be in use by an MessageCampaign
      category.save().then(() => {
        set(this, 'categoryToBeDeleted', null);
      });
    },
    save() {
      this.categoryToEdit.save().then(category => {
        set(this, 'categoryToEdit', null);
        get(this, 'model.messageCampaignCategories').addObject(category);
      });
    },
    cancel(category) {
      if (get(category, 'hasDirtyAttributes')) {
        category.rollbackAttributes();
      }
      set(this, 'categoryToEdit', null);
    },
    newCategory() {
      set(
        this,
        'categoryToEdit',
        this.store.createRecord('message-campaign-category', {
          organizationId: get(this, 'model.organization.id'),
        })
      );
    },
  },
});
