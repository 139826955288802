/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { computed, get, set } from '@ember/object';

export default Controller.extend({
  settings: controller('organizations.organization.settings.index'),

  //region Computed Properties
  emptyName: computed('model.name', function () {
    return get(this, 'model.name') === '' || get(this, 'model.name') === undefined;
  }),
  //endregion

  actions: {
    save(model) {
      this.model.save().then(() => {
        if (get(this, 'settings.modal') === 'isCreatingCategory') {
          get(this, 'settings.model.messageCampaignCategories').addObject(model);
        }
        set(this, 'settings.modal', null);
      });
    },
    cancel() {
      this.model.rollbackAttributes();
      set(this, 'settings.modal', null);
    },
  },
});
