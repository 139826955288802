import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class extends Helper {
  @service
  snackbar;

  compute([snackBarContent, snackBarActionLabel, snackBarAction]) {
    return () => {
      this.snackbar.show(snackBarContent, snackBarActionLabel, 5000, null, null, '').then(() => {
        if (snackBarAction) {
          snackBarAction();
        }
      });
    };
  }
}
