import { computed, get } from '@ember/object';

/**
 * Generate a computed property to see if a path is any of a number of flags
 * @param {String} path - May be either a path to an object, or a path to an array of objects marked as `foo.@each`
 * @param {String[]} flags
 * @returns {Ember.ComputedProperty}
 * @example
 *     isFooHappy: isPathAnyOf('foo', ['isHappy', 'isJubilant', 'isEcstatic']),
 *     areBearsSad: isPathAnyOf('bears.@each', ['isSad', 'isDepressed', 'isBlue']),
 */
export const isPathAnyOf = (path, flags) =>
  computed(`${path}.{${flags.join(',')}}`, function () {
    const EACH_END_REGEX = /\.@each$/;

    if (path.match(EACH_END_REGEX)) {
      return flags.some(flag => get(this, path.replace(EACH_END_REGEX, '')).isAny(flag));
    }
    return flags.some(flag => !!get(this, `${path}.${flag}`));
  });

export default isPathAnyOf;
