import Component from '@glimmer/component';

export default class UiActionNeededAlertComponent extends Component {
  /**
   * Set the elevation level of the alert
   * @type {Number}
   */
  get z() {
    return this.args.z ?? 2;
  }
}
