/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, get } from '@ember/object';
import { and, match, notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

const { Errors } = DS;
const VALID_LENGTH_REGEX = /.{8}/;
const INVALID_LENGTH_MESSAGE = 'Minimum 8 characters';
const VALID_CAPITAL_REGEX = /[A-Z]/;
const INVALID_CAPITAL_MESSAGE = 'Must contain a capital letter';
const VALID_LOWERCASE_REGEX = /[a-z]/;
const INVALID_LOWERCASE_MESSAGE = 'Must contain a lowercase letter';
const VALID_NUMBER_REGEX = /[0-9]/;
const INVALID_NUMBER_MESSAGE = 'Must contain a number';
const VALID_SYMBOL_REGEX = /[^a-zA-Z\d\s]/;
const INVALID_SYMBOL_MESSAGE = 'Must contain a symbol';
const INVALID_NAME_MESSAGE = "Can't contain first or last name";

export default Controller.extend({
  //region Dependencies
  session: service(),
  //endregion

  //region Ember Hooks
  queryParams: ['isNewUser'],
  //endregion

  //region Properties
  password: '',
  passwordConfirmation: '',
  //endregion

  //region Computed Properties
  header: computed('isNewUser', 'session.data.authenticated.organization_users.{first_name,last_name}', function () {
    const { isNewUser } = this;
    const firstName = get(this, 'session.data.authenticated.organization_users.first_name');
    const lastName = get(this, 'session.data.authenticated.organization_users.last_name');

    return isNewUser ? `Welcome, ${firstName} ${lastName}!` : 'Reset Password';
  }).readOnly(),
  /**
   * @returns {Boolean}
   * @type {Ember.ComputedProperty}
   */
  passwordLengthValid: match('password', VALID_LENGTH_REGEX).readOnly(),

  /**
   * @returns {Boolean}
   * @type {Ember.ComputedProperty}
   */
  passwordContainsCapitalLetters: match('password', VALID_CAPITAL_REGEX).readOnly(),

  /**
   * @returns {Boolean}
   * @type {Ember.ComputedProperty}
   */
  passwordContainsLowercaseLetters: match('password', VALID_LOWERCASE_REGEX).readOnly(),

  /**
   * @returns {Boolean}
   * @type {Ember.ComputedProperty}
   */
  passwordContainsNumbers: match('password', VALID_NUMBER_REGEX).readOnly(),

  /**
   * @returns {Boolean}
   * @type {Ember.ComputedProperty}
   */
  passwordContainsSymbols: match('password', VALID_SYMBOL_REGEX).readOnly(),

  /**
   * @returns {Boolean}
   * @type {Ember.ComputedProperty}
   */
  passwordContainsFirstOrLastName: computed('password', 'model.{firstName,lastName}', function () {
    const { password } = this;
    if (password) {
      const firstName = get(this, 'model.firstName');
      const lastName = get(this, 'model.lastName');
      const lowerPassword = password.toLowerCase();
      const containsLowerFirstName = firstName ? lowerPassword.indexOf(firstName.toLowerCase()) > -1 : false;
      const containsLowerLastName = lastName ? lowerPassword.indexOf(lastName.toLowerCase()) > -1 : false;
      return containsLowerFirstName || containsLowerLastName;
    }
    return false;
  }).readOnly(),
  /**
   * @returns {Boolean}
   * @type {Ember.ComputedProperty}
   */
  confirmationMatches: computed('password', 'passwordConfirmation', function () {
    return this.password === this.passwordConfirmation;
  }),

  /**
   * @returns {Boolean}
   * @type {Ember.ComputedProperty}
   */
  passwordFilled: notEmpty('password').readOnly(),

  /**
   * @returns {Boolean}
   * @type {Ember.ComputedProperty}
   */
  confirmationFilled: notEmpty('passwordConfirmation').readOnly(),

  /**
   * @returns {Boolean}
   * @type {Ember.ComputedProperty}
   */
  canSubmit: and('validation.isEmpty', 'passwordFilled', 'confirmationFilled').readOnly(),

  /**
   * @returns {Boolean}
   * @type {Ember.ComputedProperty}
   */
  disableButton: computed('canSubmit', 'isSaving', function () {
    return !this.canSubmit || this.isSaving;
  }).readOnly(),

  /**
   * @returns {DS.Errors}
   * @type {Ember.ComputedProperty}
   */
  validation: computed(
    'confirmationFilled',
    'confirmationMatches',
    'passwordFilled',
    'passwordLengthValid',
    'passwordContainsCapitalLetters',
    'passwordContainsLowercaseLetters',
    'passwordContainsNumbers',
    'passwordContainsSymbols',
    'passwordContainsFirstOrLastName',
    function () {
      const { confirmationFilled } = this;
      const { confirmationMatches } = this;
      const { passwordFilled } = this;
      const { passwordLengthValid } = this;
      const { passwordContainsCapitalLetters } = this;
      const { passwordContainsLowercaseLetters } = this;
      const { passwordContainsNumbers } = this;
      const { passwordContainsSymbols } = this;
      const { passwordContainsFirstOrLastName } = this;
      const errors = Errors.create();

      if (confirmationFilled && !confirmationMatches) {
        errors.add('passwordConfirmation', 'This does not match the password you typed above.');
      }

      if (passwordFilled) {
        if (!passwordLengthValid) {
          errors.add('password', INVALID_LENGTH_MESSAGE);
        } else if (!passwordContainsCapitalLetters) {
          errors.add('password', INVALID_CAPITAL_MESSAGE);
        } else if (!passwordContainsLowercaseLetters) {
          errors.add('password', INVALID_LOWERCASE_MESSAGE);
        } else if (!passwordContainsNumbers) {
          errors.add('password', INVALID_NUMBER_MESSAGE);
        } else if (!passwordContainsSymbols) {
          errors.add('password', INVALID_SYMBOL_MESSAGE);
        } else if (passwordContainsFirstOrLastName) {
          errors.add('password', INVALID_NAME_MESSAGE);
        }
      }

      return errors;
    }
  ),
  //endregion
});
