import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import isEqual from 'lodash/isEqual';

export default class extends Controller {
  @service('current') current;
  @service('reports') reports;
  @service('sentry') sentry;
  @service('snackbar') snackbar;

  @tracked isShowingRejected = false;

  get promotion() {
    return this.current.organizationPromotion.promotion;
  }

  get masterMatchupEntryStatistics() {
    return this.model.matchupEntryStatistics.rejectBy('entry.masterEntryId');
  }

  get activeMatchupEntryStatistics() {
    return this.masterMatchupEntryStatistics.filter(
      matchupEntryStatistic => !['Hidden', 'Rejected'].includes(matchupEntryStatistic.get('entry.statusType'))
    );
  }

  get rejectedMatchupEntryStatistics() {
    return this.masterMatchupEntryStatistics.filterBy('entry.isRejected');
  }

  get chartData() {
    const chartData = this.activeMatchupEntryStatistics
      .map(matchupEntryStatistic => ({
        id: matchupEntryStatistic.entry.id,
        label: matchupEntryStatistic.entryName,
        value: matchupEntryStatistic.numEligibleVotes,
        fraudVotes: matchupEntryStatistic.numEligibleVotesPotentialFraud,
      }))
      .filter(({ value }) => value > 0)
      .sort((a, b) => b.value - a.value);

    // Keep a cached copy of the data to prevent re-rendering of the charts
    // when rejecting/unrejecting filtered out entries
    if (
      !isEqual(
        chartData.map(({ id }) => id),
        this._cachedChartData?.map(({ id }) => id)
      )
    ) {
      this._cachedChartData = chartData;
    }

    return this._cachedChartData;
  }

  rejectTask = task({ drop: true }, async matchupEntryStatistic => {
    try {
      await matchupEntryStatistic.entry.reject();

      this.snackbar
        .show('1 item was rejected', 'View Rejected')
        .then(() => this.toggleRejected())
        .catch(() => {});
    } catch (e) {
      this.sentry.captureException(e);
      this.snackbar.show('Something went wrong. Please try again later.');
    }
  });

  unrejectTask = task({ drop: true }, async matchupEntryStatistic => {
    try {
      await matchupEntryStatistic.entry.unreject();

      this.snackbar.show('1 item was added back');
    } catch (e) {
      this.sentry.captureException(e);
      this.snackbar.show('Something went wrong. Please try again later.');
    }
  });

  downloadReportTask = task({ drop: true }, async () => {
    try {
      await this.reports.download('EntryFraud', {
        params: { matchupId: this.model.matchup.id },
      });
    } catch (e) {
      this.sentry.captureException(e);
      this.snackbar.show('Something went wrong. Please try again later.');
    }
  });

  @action
  toggleRejected() {
    this.isShowingRejected = !this.isShowingRejected;

    // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
    scheduleOnce('afterRender', () => document.querySelector('#rejected')?.scrollIntoView({ behavior: 'smooth' }));
  }
}
