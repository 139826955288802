/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';

export default Controller.extend({
  //region Dependencies
  login: controller(),
  //endregion

  isSubmitting: false,

  //region Properties
  resetEmailSent: false,
  //endregion
});
