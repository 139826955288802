/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import PromotionTypeRoute from 'partner/mixins/promotion-type-route';
import RSVP from 'rsvp';

const isUGCSweepstakes = that =>
  get(
    that.modelFor('organizations.organization.organization-promotions.organization-promotion'),
    'organizationPromotion.promotion.promotionType'
  ) === 'UGCSweepstakes';

const isPaymentForm = that =>
  get(
    that.modelFor('organizations.organization.organization-promotions.organization-promotion'),
    'organizationPromotion.promotion.promotionType'
  ) === 'PaymentForm';

/**
 * Forms Index Route
 * /o/:organization_id/op/:organization_promotion_id/setup/forms
 * @type {Ember.Route}
 * @mixes PromotionTypeRoute
 */
export default class IndexRoute extends Route.extend(PromotionTypeRoute) {
  @service enums;
  @service router;
  @service store;

  model() {
    const forms = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion.setup.forms'
    );
    const hash = { forms };

    if (isUGCSweepstakes(this)) {
      set(
        hash,
        'isConsumerUploadEnabled',
        this.store
          .queryRecord('setting', { key: 'is_consumer_upload_enabled' })
          .then(setting => (setting ? get(setting, 'value') : true))
      );
    }

    return RSVP.hash(hash);
  }

  redirect(hash) {
    const formTypeId = this.enums.findWhere('FORM_TYPE', {
      name: 'Registration',
    });

    const registrationForm = get(get(hash, 'forms').filterBy('formTypeId', formTypeId), 'lastObject');
    const setupStep = isPaymentForm(this) ? 'contact' : 'form';
    const redirectRoute = `organizations.organization.organization-promotions.organization-promotion.setup.forms.${setupStep}`;

    if (!isUGCSweepstakes(this) || get(hash, 'isConsumerUploadEnabled')) {
      this.router.transitionTo(redirectRoute, get(registrationForm, 'id'));
    }
  }
}
