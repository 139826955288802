/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import enums from 'ember-cli-ss-enums/services/enums';
import AppContextRoute from 'partner/mixins/app-context-route';
import mostCurrentMatchup from 'partner/utils/most-current-matchup';
import RSVP from 'rsvp';

const DEFAULT_PAGE_SIZE = 24;
const PENDING_STATUS_TYPE_ID = enums.findWhere('STATUS_TYPE', { name: 'Submitted' }, 'id');
const APPROVED_STATUS_TYPE_ID = enums.findWhere('STATUS_TYPE', { name: 'Active' }, 'id');
const REJECTED_STATUS_TYPE_ID = enums.findWhere('STATUS_TYPE', { name: 'Rejected' }, 'id');

/**
 * Moderate Entries route. This is the "new" route to use for moderating.
 * We do moderating for sweeps in a different route, maybe that will change in future.
 * This route will be used for moderating any new promo types beyond basic sweeps (Example: Photo, Video, Quiz).
 *
 * /o/:organization_id/moderate-entries
 * @type {Ember.Route}
 * @mixes AppContextRoute
 */
export default Route.extend(AppContextRoute, {
  //region Dependencies
  router: service(),
  snackbar: service(),
  store: service(),
  settings: service(),
  //endregion

  //Ember Hooks
  init() {
    this._super(...arguments);

    set(this, 'matchupEntryParameters', this.matchupEntryParameters || {});
  },

  /**
   * Make ember reload the model when queryParams change.
   * http://emberjs.com/guides/routing/query-params/#toc_opting-into-a-full-transition
   * @type {Object}
   */
  queryParams: {
    statusTypeId: { refreshModel: true },
    matchupId: { refreshModel: true },
    pageIndex: { refreshModel: true },
    searchValue: { refreshModel: true },
    sortBy: { refreshModel: true },
    sortDirection: { refreshModel: true },
  },

  matchupEntryParameters: null,

  get organizationId() {
    return get(this.modelFor('organizations.organization'), 'organization.id');
  },

  get organizationPromotionId() {
    return get(
      this.modelFor('organizations.organization.organization-promotions.organization-promotion'),
      'organizationPromotion.id'
    );
  },

  async beforeModel(transition) {
    if (transition.to.queryParams.matchupId) return;

    const matchups = await this.fetchMatchups();
    const matchup = mostCurrentMatchup(matchups);

    this.router.transitionTo({ queryParams: { matchupId: matchup.id } });
  },

  /**
   * @param params {Object} Expected: { matchupId: #, statusTypeId: #, pageSize: #, pageIndex: # }
   * @returns {Object|Ember.RSVP.Promise}
   */
  async model(params) {
    this.matchupEntryParameters = {
      ...params,
      pageSize: +params.pageSize || DEFAULT_PAGE_SIZE,
      statusTypeId: +params.statusTypeId || PENDING_STATUS_TYPE_ID,
      pageIndex: +params.pageIndex || 1,
      organizationId: this.organizationId,
      organizationPromotionId: this.organizationPromotionId,
      sortColumn: params.sortBy,
      sortDirection: params.sortDirection,
    };

    const { matchups, matchupEntries } = await RSVP.hash({
      matchups: this.fetchMatchups(),
      matchupEntries: this.store.query('matchup-entry', this.matchupEntryParameters),
    });

    // Workaround weird Matchup names
    matchups.sortBy('startDate').forEach(matchup => {
      set(matchup, '_dynamicRoundName', `Round ${matchup.iteration}`);
    });

    // Get page sizes by requesting a single entry for each type
    const pageSizeFor = async statusTypeId => {
      const pageSizeMatchupEntries =
        params.statusTypeId === statusTypeId
          ? matchupEntries
          : await this.store.query('matchupEntry', {
              ...this.matchupEntryParameters,
              statusTypeId,
              pageSize: 1,
              pageIndex: 1,
            });

      return !isEmpty(pageSizeMatchupEntries) ? get(pageSizeMatchupEntries, 'meta.totalRecords') || 0 : 0;
    };

    return RSVP.hash({
      matchups: get(
        this.modelFor('organizations.organization.organization-promotions.organization-promotion'),
        'organizationPromotion.matchupsAsCategories'
      )
        ? matchups.sortBy('displayOrder')
        : matchups.sortBy('startDate'),
      _settings: this.settings.preload('dips_url'),
      approvedCount: pageSizeFor(APPROVED_STATUS_TYPE_ID),
      pendingCount: pageSizeFor(PENDING_STATUS_TYPE_ID),
      rejectedCount: pageSizeFor(REJECTED_STATUS_TYPE_ID),
      matchupEntries,
      entryForm: this.fetchEntryForm(),
    }).then(({ matchupEntries, ...rest }) => ({
      ...rest,
      matchupEntries: matchupEntries.toArray(),
      paging: matchupEntries.meta,
      dipsUrl: this.settings.getFor('dips_url'),
    }));
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'matchupId', null);
      set(controller, 'matchupEntryBeingEdited', null);
      set(controller, 'searchValue', '');

      // Reset the cached matchups for this promotion
      this.matchups = null;
    }
  },
  //endregion

  //region methods
  fetchEntryForm() {
    const formTypeId = enums.findWhere('FORM_TYPE', { name: 'EntrySubmission' });
    return this.store.queryRecord('form', { formTypeId });
  },

  /**
   * Saves the passed in matchupEntries and returns a single promise
   * @param matchupEntries
   * @returns {*}
   */
  saveMatchupEntries(matchupEntries) {
    const promises = matchupEntries.map(x => x.save());
    return Promise.all(promises);
  },

  revert(matchupEntries, statusTypeId) {
    matchupEntries.forEach(x => set(x, 'statusTypeId', statusTypeId));
    return this.saveMatchupEntries(matchupEntries);
  },

  pluralizedMessageText(count, actionName) {
    if (count > 1) {
      return `${count} Entries Have Been ${actionName}`;
    }

    return `${count} Entry Has Been ${actionName}`;
  },

  changeCount(statusTypeId, operation, count) {
    const change = countPropName => {
      if (operation === '+') {
        set(this, countPropName, get(this, countPropName) + count);
      } else {
        set(this, countPropName, get(this, countPropName) - count);
      }
    };

    statusTypeId = +statusTypeId;

    if (statusTypeId === PENDING_STATUS_TYPE_ID) {
      change('controller.model.pendingCount');
    } else if (statusTypeId === APPROVED_STATUS_TYPE_ID) {
      change('controller.model.approvedCount');
    } else {
      change('controller.model.rejectedCount');
    }
  },

  async appendNextMatchupEntry(pageIndex, pageSize) {
    const newMatchupEntries = await this.store.query('matchupEntry', {
      ...this.matchupEntryParameters,
      pageIndex,
      pageSize,
    });
    this.controller.model.matchupEntries.addObjects(newMatchupEntries);
  },

  async fetchMatchups() {
    if (!this.matchups) {
      this.matchups = await this.store.query('matchup', {
        organizationId: this.organizationId,
        organizationPromotionId: this.organizationPromotionId,
        excludeSecondaryMatchups: true,
      });
    }

    return this.matchups;
  },
  //endregion

  actions: {
    decreaseCount(statusTypeId) {
      this.changeCount(statusTypeId, '-', 1);
    },

    willTransition() {
      //Aborting the transition caused a bug that I could not solve, maybe something to do with the terrible way ember implemented query params.
      //Instead reverting any changes when a transition happens during editing.
      if (get(this, 'controller.matchupEntryBeingEdited')) {
        get(this, 'controller.matchupEntryBeingEdited.entry.entryFieldValues')
          .filterBy('hasDirtyAttributes', true)
          .forEach(x => x.rollbackAttributes());
        set(this, 'controller.matchupEntryBeingEdited', null);
      }
      return true;
    },

    changeStatus(matchupEntries, newStatusTypeId, onSuccess = null) {
      if (!Array.isArray(matchupEntries)) {
        matchupEntries = [matchupEntries];
      }
      // give time for the transition to run
      later(() => {
        const previousStatusTypeId = get(matchupEntries, 'firstObject.statusTypeId');
        const count = get(matchupEntries, 'length');
        const approvedStatusId = get(this, 'controller.approvedId');

        matchupEntries.forEach(matchupEntry => {
          set(matchupEntry, 'statusTypeId', newStatusTypeId);
          set(matchupEntry.entry, 'statusTypeId', newStatusTypeId);
        });

        this.saveMatchupEntries(matchupEntries).then(() => {
          const actionName = newStatusTypeId == approvedStatusId ? 'Approved' : 'Rejected';

          // as a requirement for the story we didn't want to refresh
          // the model so I had to change the counts manually
          this.changeCount(newStatusTypeId, '+', count);
          this.changeCount(previousStatusTypeId, '-', count);

          // alert the user and wait for result in case they want to undo the action
          this.snackbar
            .show(
              this.pluralizedMessageText(count, actionName),
              'Undo',
              5000,
              null,
              null,
              'blvd-snackbar--extra-margin-bottom'
            )
            .then(() => {
              this.changeCount(newStatusTypeId, '-', count);
              this.changeCount(previousStatusTypeId, '+', count);
              this.revert(matchupEntries, previousStatusTypeId);
            })
            .catch(() => {
              const pageIndex = get(this, 'controller.pageIndex');
              const currentPage = pageIndex * DEFAULT_PAGE_SIZE;
              const paging = get(this, 'controller.model.paging');
              const maxPageNumber = Math.ceil(paging.totalRecords / paging.pageSize);

              //Last page will not have further entries to add
              if (pageIndex < maxPageNumber) {
                this.appendNextMatchupEntry(currentPage, count);
              }
              if (get(this, 'controller.matchupEntriesCount') === 0) {
                this.refresh();
              }
            })
            .finally(() => onSuccess?.());
        });
      }, 600);
    },

    approveEntriesOnPage() {
      set(this, 'controller.approvingEntriesOnPage', true);

      this.send(
        'changeStatus',
        get(this, 'controller.model.matchupEntries').filter(
          x => `${get(x, 'statusTypeId')}` === get(this, 'controller.statusTypeId')
        ),
        APPROVED_STATUS_TYPE_ID,
        () => {
          set(this, 'controller.approvingEntriesOnPage', false);
        }
      );
    },

    toggleTabs() {
      this.controller.toggleProperty('tabsToggled');
    },

    loading(transition) {
      const controller = this.controllerFor(
        'organizations.organization.organization-promotions.organization-promotion.moderate-entries'
      );

      set(controller, 'loadingModel', true);

      transition.promise.finally(() => {
        set(controller, 'loadingModel', false);
      });
    },

    /**
     * @param {MatchupEntry} matchupEntry
     * @param {MediaItemTransform} [mediaItemTransform] - if present will be saved in order to modify the image for an entry
     * @returns {Promise}
     */
    async saveMatchupEntry(matchupEntry, mediaItemTransform = null) {
      set(this, 'controller.saving', true);

      await matchupEntry.saveWithEntryFieldValues(mediaItemTransform);

      set(this, 'controller.saving', false);
      set(this, 'controller.matchupEntryBeingEdited', null);
    },
  },
});
