/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import enums from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';

@makeBooleanProperties('MESSAGE_CAMPAIGN_TYPE')
export default class SentEmail extends BaseModel {
  @attr('string') name;
  @attr('date') sentDate;
  @attr('number') softBounces;
  @attr('number') hardBounces;
  @attr('number') clicks;
  @attr('number') opens;
  @attr('number') unsubscribes;
  @attr('number') complaints;
  @attr('number') organizationId;
  @attr('number') messageCampaignId;
  @attr('number') messageCampaignTypeId;
  @attr('number') messageId;
  @attr('number') messageVersionHistoryId;
  @attr('number') scheduleInstanceId;
  @attr('number') legacyMessageId;
  @attr('boolean') isLegacyInviteEmail;

  @computed('softBounces', 'hardBounces', 'clicks', 'opens', 'unsubscribes', 'complaints')
  get sentStatus() {
    switch (true) {
      case this.complaints > 0:
        return 'Marked as Spam';
      case this.hardBounces > 0:
        return 'Hard Bounce';
      case this.softBounces > 0:
        return 'Soft Bounce';
      case this.unsubscribes > 0:
        return 'Unsubscribed';
      case this.clicks > 0:
        return 'Opened and Clicked';
      case this.opens > 0:
        return 'Opened';
      default:
        return 'Delivered but Not Opened';
    }
  }

  @enums.computed('name', 'messageCampaignTypeId') messageCampaignType;
}
