/* eslint-disable ember/no-mixins, ember/no-computed-properties-in-native-classes */
import Controller, { inject as controller } from '@ember/controller';
import { computed, get } from '@ember/object';
import { alias, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import HasCategoricalCharts from 'partner/mixins/has-categorical-charts';
import { firstByProperty } from 'partner/utils/computed';
import { FORMATTED_MESSAGE_CAMPAIGN_TYPES } from 'partner/utils/performance-report';

const canAdminister = entities => permissions => !!permissions.getAccessLevel(entities).administer;

export default class OrganizationsOrganizationDashboardController extends Controller.extend(HasCategoricalCharts) {
  @service('permissions') permissions;
  @service('features') features;
  @service('session') session;

  @controller('application') applicationController;
  @controller('organizations.organization') organizationController;

  formattedMessageCampaignTypes = FORMATTED_MESSAGE_CAMPAIGN_TYPES;

  @alias('organizationController.model.organization')
  organization;

  @alias('applicationController.isAdminUser')
  isAdminUser;

  get canCreatePromotions() {
    return canAdminister('Promotion,OrganizationPromotion')(this.permissions);
  }

  get canCreateInteractiveContent() {
    return this.canCreatePromotions;
  }

  get canCreateEmails() {
    return canAdminister('MessageCampaign')(this.permissions);
  }

  get canCreateAudiences() {
    return canAdminister('Audience')(this.permissions);
  }

  get isDiscoverEnabled() {
    return this.features.get('HasDiscoverAccess') && get(this.session, 'data.authenticated.has_discover_access');
  }

  @readOnly('usersChart.categories.lastObject.aggregate')
  totalUsers;

  @firstByProperty('name', 'Top Tags', 'model.categoricalCharts')
  tagsChart;

  @computed('transformedPostalCodeData.@each.label')
  get topPostalCode() {
    return this.transformedPostalCodeData
      ? get(
          this.transformedPostalCodeData.filter(x => get(x, 'label') !== 'Unknown'),
          'firstObject.label'
        )
      : null;
  }

  @readOnly('postalCodesChart.reverseSortedCategories')
  transformedPostalCodeData;

  @firstByProperty('sourceTypeChartId', 43, 'model.categoricalCharts')
  postalCodesChart;
}
