/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { getOwner } from '@ember/application';
import Component from '@ember/component';
import { computed, get, observer, set, setProperties } from '@ember/object';
import { alias, empty, equal, or, sort } from '@ember/object/computed';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import flattenDeep from 'lodash/flattenDeep';
import PromotionTypeName from 'partner/mixins/promotion-type-name';
import { spreadIf } from 'secondstreet-common/utils/functional';
import { endpoint, queryString } from 'secondstreet-common/utils/url';

export default Component.extend(PromotionTypeName, {
  //region Dependencies
  session: service(),
  store: service(),
  screen: service(),
  enums: service(),
  //endregion

  //region Properties
  calculatingWinners: null,
  calculatingWinnersHasErrors: null,
  calculatingWinnersErrorMessage: null,
  recalculatingMessage: null,
  winnersSortingDesc: computed(() => ['points:desc']),
  selectedWinnerFilter: null,
  selectedWinner: null,
  //endregion

  //region Attributes
  matchupId: null,
  winners: null,
  'non-winners': null,
  'matchup-places': null,
  'is-winners-published': false,
  'updating-winners-publishing': false,
  showReportModal: false,
  confirmationModal: false,
  entireRePickWinner: false,
  entirePickWinner: false,
  preserveWinners: false,
  calculateWinnerType: 'Default',
  //endregion

  //region Hooks
  init() {
    this._super(...arguments);
    this.send('getMatchUpSettings');
    if (!this.winners) {
      set(this, 'winners', []);
    }
    if (!this['non-winners']) {
      this['non-winners'] = [];
    }
  },

  didUpdateAttrs() {
    this._super(...arguments);
    this.send('getMatchUpSettings');
  },
  //endregion

  //region Methods
  calculateWinnersColumns(winners) {
    return get(this, 'screen.width') <= 640 || get(this, 'model.organizationPromotion.promotion.isBallot')
      ? [winners]
      : this.splitToColumns(winners);
  },
  calculateNonWinnersColumns(nonWinners) {
    return get(this, 'screen.width') <= 640 ? [nonWinners] : this.splitToColumns(nonWinners);
  },
  splitToColumns(items) {
    const isEven = (item, index) => index % 2 === 0;
    return [items.filter(isEven), items.reject(isEven)].reject(column => isEmpty(column));
  },
  //endregion

  //region Computed Properties
  sortedNonWinners: sort('non-winners', 'winnersSortingDesc'),
  isVideo: or(
    'model.organizationPromotion.promotion.isUGCSweepstakesVideo',
    'model.organizationPromotion.promotion.isVideoVotingStandard'
  ),
  oneMatchup: equal('model.matchups.length', 1),
  noWinners: empty('model.matchupEntryRanks'),
  promotionTypeId: alias('model.organizationPromotion.promotions.promotionTypeId'),
  promotionSubTypeId: alias('model.organizationPromotion.promotions.promotionSubTypeId'),
  /**
   * registrationFormFields
   * This returns a list of form fields sorted by the order in which they appear on the registration form
   */
  registrationFormFields: computed('model.registrationForm', function () {
    return flattenDeep(
      get(this, 'model.registrationForm.formPages')
        .sortBy('displayOrder')
        .map(formPage => get(formPage, 'formFields').sortBy('displayOrder'))
    );
  }),

  /**
   * Fake Winners
   * This indicates the number of unfilled winner ranks. This amount is the difference between
   * potential winners and actual calculated winners.
   * @type {Ember.ComputedProperty}
   */
  fakeWinners: computed(
    'model.matchupPlace.{endRank,@each.endRank}',
    'winners.length',
    'organizationPromotion.promotion.isBallot',
    function () {
      const endRank = get(this, 'organizationPromotion.promotion.isBallot')
        ? get(this, 'model.matchupPlace.lastObject.endRank')
        : get(this, 'model.matchupPlace.endRank');
      const fakeWinners = [];
      const matchupEntryRanks = get(this, 'winners').mapBy('rank');

      for (let i = 1; i <= endRank; i++) {
        if (!matchupEntryRanks.includes(i)) {
          fakeWinners.push({ rank: i, fakeWinner: true });
        }
      }
      return fakeWinners;
    }
  ),
  /**
   * One Winner
   * This returns a boolean that says whether or not the promotion has specified only one potential winner.
   * @type {Ember.ComputedProperty}
   */
  oneWinner: computed('model.matchupPlace.endRank', function () {
    return get(this, 'model.matchupPlace.endRank') === 1;
  }),
  winnersColumns: computed('screen.width', 'winners.[]', function () {
    return this.calculateWinnersColumns(this.winners);
  }),
  nonWinnersColumns: computed('screen.width', 'sortedNonWinners.[]', function () {
    return this.calculateNonWinnersColumns(this.sortedNonWinners);
  }),
  /**
   * For Video promotions we do not want the winners to be re-arranged as the screen width changes. As such
   * this computed property is identical to WinnersColumns but without screen.width as a dependent key.
   */
  staticWinnersColumns: computed('winners.[]', function () {
    return this.calculateWinnersColumns(this.winners);
  }),
  staticNonWinnersColumns: computed('sortedNonWinners.[]', function () {
    return this.calculateNonWinnersColumns(this.sortedNonWinners);
  }),
  oneColumn: equal('winnersColumns.length', 1),
  //endregion

  //region Observers
  selectedMatchupChanged: observer('model.selectedMatchup.id', function () {
    if (get(this, 'model.selectedMatchup.id')) {
      set(this, 'matchupId', get(this, 'model.selectedMatchup.id'));
      set(this, 'calculatingWinnersHasErrors', false);
    }
  }),
  //endregion

  //region actions
  actions: {
    /**
     * Generate winners for the matchup.
     */
    calculateWinners(winner) {
      if (!this.calculatingWinners) {
        const { organizationPromotion } = this.model;
        const organizationPromotionId = organizationPromotion.id;
        const { promotionId } = organizationPromotion;

        const matchup = get(this, 'model.selectedMatchup');
        let matchupEntryId, rank, preserveWinners, disqualifyWinners;
        set(this, 'calculatingWinners', true);
        if (winner) {
          matchupEntryId = get(winner, 'matchupEntry.id');
          rank = get(winner, 'rank');
          set(this, 'repickedMatchupEntryId', matchupEntryId);
          set(this, 'repickedWinnerRank', rank);
        } else if (this.calculateWinnerType === 'Disqualify') {
          disqualifyWinners = true;
        } else if (this.preserveWinners) {
          preserveWinners = true;
        }

        const params = {
          matchupId: matchup.id,
          disqualifyMatchupEntryId: matchupEntryId ? matchupEntryId : '',
          disqualifyWinnersForMatchupId: matchupEntryId || this.noWinners ? '' : matchup.id,
          disqualifyRank: winner ? winner.rank : '',
          calculateIndividualWinner: this.calculateWinnerType === 'Individual',
          preserveExistingWinners: !!preserveWinners,
          onlyDisqualifyWinners: !!disqualifyWinners,
          ...spreadIf(organizationPromotion.hasSyndicates, { promotionId }, { organizationPromotionId }),
        };
        return this.session
          .request(`${endpoint('winners_calculation')}?${queryString(params)}`, {
            type: 'POST',
            data: '{"winners_calculation":[{}]}',
            headers: getOwner(this).lookup('adapter:application').headers,
            contentType: 'application/json',
          })
          .then(
            () => {
              this.refreshWinners();

              //continue the delay slightly after calculating in case it goes too quickly or takes a moment to refresh
              later(
                this,
                () => {
                  setProperties(this, {
                    calculatingWinners: false,
                    calculatingWinnersHasErrors: false,
                    repickedMatchupEntryId: null,
                  });
                },
                1000
              );
            },
            error => {
              setProperties(this, {
                repickedMatchupEntryId: null,
                calculatingWinners: false,
                calculatingWinnersHasErrors: true,
                calculatingWinnersErrorMessage: get(error, 'payload.errors.firstObject.message'),
              });
            }
          )
          .finally(() => {
            setProperties(this, {
              preserveWinners: false,
              calculateWinnerType: 'Default',
            });
          });
      }
    },

    getMatchUpSettings() {
      const setting = this.model.winnerSetting;
      set(this, 'selectedWinnerFilter', setting?.value ? 'Pick Individual Winners' : 'Pick Entire Round Winners');
    },

    async SaveMatchUpSettings(value) {
      const setting = this.model.winnerSetting;
      if (setting && setting?.ownerEntityId != 1) {
        setting.value = value;
        await setting.save();
      } else {
        const { organizationPromotion } = this.model;
        const { promotionId } = organizationPromotion;
        const matchup = get(this, 'model.selectedMatchup');
        const options = {
          key: 'pick_individual_winners',
          ownerEntityTypeId: this.enums.findWhere('ENTITY_TYPE', {
            name: 'Promotion',
          }),
          ownerEntityId: promotionId,
          targetEntityTypeId: this.enums.findWhere('ENTITY_TYPE', {
            name: 'Matchup',
          }),
          targetEntityId: matchup.id,
          value,
        };
        const newSetting = this.store.createRecord('setting', options);
        await newSetting.save();
        set(this, 'model.winnerSetting', newSetting);
      }
    },

    findWinnerBasedOnType(selectionType, winner) {
      if (winner && selectionType === 'Individual') {
        set(this, 'calculateWinnerType', selectionType);
        this.send('calculateWinners', winner);
      } else if (selectionType === 'PickWinners') {
        set(this, 'recalculatingMessage', 'Picking winners...');
        set(this, 'calculateWinnerType', selectionType);
        this.send('calculateWinners');
      }
    },

    recalculateWinners(winner) {
      if (!this.calculatingWinners) {
        if (winner) {
          set(this, 'recalculatingMessage', `Re-picking winner...`);
          set(this, 'confirmationModal', false);
          set(this, 'entireRePickWinner', false);
          this.send('calculateWinners', winner);
        } else {
          set(this, 'recalculatingMessage', 'Re-picking winners...');
          set(this, 'confirmationModal', false);
          set(this, 'entireRePickWinner', false);
          this.send('calculateWinners');
        }
      }
    },

    disqualifyWinners(type) {
      if (!this.calculatingWinners) {
        if (type) {
          setProperties(this, {
            recalculatingMessage: `Re-picking winners...`,
            confirmationModal: false,
            entireRePickWinner: false,
            calculateWinnerType: type,
          });
          this.send('calculateWinners');
          set(this, 'preserveWinners', true);
        }
      }
    },

    /**
     * Generates a new winner for the specified rank
     */
    repickWinner(winner) {
      if (!this.calculatingWinners) {
        if (get(this, 'model.organizationPromotion.promotions.promotionType') === 'UGCVoting') {
          if (window.confirm('Are you sure? This will discard this entry and recalculate winners.')) {
            set(this, 'recalculatingMessage', `Re-picking winner...`);
            this.send('calculateWinners', winner);
          }
        } else {
          setProperties(this, {
            calculateWinnerType: 'Individual',
            recalculatingMessage: `Re-picking winner...`,
            confirmationModal: false,
            entireRePickWinner: false,
          });
          this.send('calculateWinners', winner);
        }
      }
    },

    showConfirmationModal(winner) {
      if (winner) {
        setProperties(this, {
          selectedWinner: winner,
          confirmationModal: true,
        });
      } else {
        setProperties(this, {
          selectedWinner: null,
          entireRePickWinner: true,
          confirmationModal: true,
        });
      }
    },

    selectedWinnerMethod(selectionType) {
      set(this, 'selectedWinnerFilter', selectionType);

      if (selectionType === 'Pick Entire Round Winners') {
        this.send('SaveMatchUpSettings', false);
        setProperties(this, {
          entirePickWinner: true,
          confirmationModal: true,
        });
      } else {
        this.send('SaveMatchUpSettings', true);
        setProperties(this, {
          entirePickWinner: false,
          confirmationModal: false,
        });
      }
    },

    closeConfirmationModal() {
      setProperties(this, {
        entirePickWinner: false,
        entireRePickWinner: false,
        confirmationModal: false,
        preserveWinners: true,
      });
    },
  },
  //endregion
});
