import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { registerDestructor } from '@ember/destroyable';
import ENV from 'partner/config/environment';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';

type DirtyEntry = { flag: boolean; callback?: () => void };

const MESSAGE = 'Are you sure you want to leave without saving?';

export default class DirtyTrackerService extends Service {
  @service declare router: RouterService;

  @tracked entry: DirtyEntry = { flag: false };

  // For easier testing
  private isTesting = ENV.environment === 'test';
  _confirm?: (message: string) => boolean = this.isTesting ? () => true : undefined;

  constructor(props?: object) {
    super(props);

    this.router.on('routeWillChange', this.maybeConfirmTransition);
    if (!this.isTesting) window.addEventListener('beforeunload', this.maybePreventUnload);

    registerDestructor(this, () => {
      this.router.off('routeWillChange', this.maybeConfirmTransition);
      if (!this.isTesting) window.removeEventListener('beforeunload', this.maybePreventUnload);
    });
  }

  get isDirty() {
    return this.entry.flag;
  }

  confirm() {
    return !this.isDirty || this.confirmAndTriggerCallbacks();
  }

  track(entry: DirtyEntry) {
    this.entry = entry;
  }

  reset() {
    this.track({ flag: false });
  }

  private maybeConfirmTransition = (transition: Transition & { isAborted?: boolean }) => {
    if (
      transition.isAborted ||
      // Use this as a last resort to prevent the transition from being aborted
      // it is better to just call #reset() before the transition starts
      transition.to?.queryParams['ignoreDirtyTracking'] ||
      !this.isDirty ||
      this.confirmAndTriggerCallbacks()
    ) {
      return;
    }

    void transition.abort();
  };

  private maybePreventUnload = (event: BeforeUnloadEvent) => {
    if (!this.isDirty) return;

    event.preventDefault();
    event.returnValue = '';
  };

  private confirmAndTriggerCallbacks(): boolean {
    const result = this._confirm ? this._confirm(MESSAGE) : confirm(MESSAGE);

    if (result) {
      this.entry.callback?.();
      this.reset();
    }

    return result;
  }
}
