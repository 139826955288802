import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import { alphabeticalByProperty, simplyByProperty } from 'secondstreet-common/utils/sorting';

const sortedMessageCampaigns = messageCampaigns =>
  messageCampaigns.sort((a, b) => alphabeticalByProperty('messageCampaignType')(b, a) || simplyByProperty('id')(a, b));

export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionSetupTagsRoute extends Route {
  @service store;
  @service enums;
  @service router;

  queryParams = {
    id: {},
  };

  async beforeModel(transition) {
    if (transition.to.queryParams.id) return;

    const { messageVersions } = await this.getMessageVersions();

    if (messageVersions.firstObject) {
      transition.abort();
      this.router.transitionTo(this.fullRouteName, { queryParams: { id: messageVersions.firstObject.id } });
    }
  }

  async model() {
    return RSVP.hash({
      messageCampaigns: this.getMessageVersions().then(({ messageCampaigns }) => messageCampaigns),
    });
  }

  async afterModel() {
    this.send('checkChecklistStep', this.fullRouteName);
  }

  async getMessageVersions() {
    if (!this.versions) {
      this.messageCampaigns = sortedMessageCampaigns(
        this.modelFor('organizations.organization.organization-promotions.organization-promotion.setup')
          .displayedPromotionEmails
      );

      await RSVP.all(
        this.messageCampaigns.map(messageCampaign =>
          this.store.query('message', {
            messageCampaignId: messageCampaign.id,
            includeInteractionCount: true,
          })
        )
      );

      this.versions = this.messageCampaigns.map(mc => mc.messageVersions.firstObject);
    }

    return {
      messageVersions: this.versions,
      messageCampaigns: this.messageCampaigns,
    };
  }

  deactivate() {
    this.versions = null;
  }
}
