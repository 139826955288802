/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import AppContextRoute from 'partner/mixins/app-context-route';
import { UNSAVED_CHANGES_ALERT } from 'partner/utils/constants';
import { spreadIf } from 'secondstreet-common/utils/functional';
import { camelize } from '@ember/string';

export default Route.extend(AppContextRoute, {
  store: service(),

  //region Ember Hooks
  async model(params) {
    const currentDesignTemplate = this.store.peekRecord('design-template', params.design_template_id);

    const allDesignTemplatesOfType = this.modelFor('organizations.organization.legal.design-templates')[
      `${camelize(currentDesignTemplate.designTemplateType)}Templates`
    ];

    const locations = await this.store.query('location', { isPrimary: true });
    const legalDesignTokens = this.modelFor('organizations.organization.legal.design-templates')
      .designTokens.filterBy('isPageContentToken')
      .sortBy('name');
    const dynamicDesignTokens = this.modelFor('organizations.organization.legal.design-templates')
      .designTokens.filterBy('tokenType', 'Dynamic')
      .sortBy('name');

    return {
      legalDesignTokens,
      dynamicDesignTokens,
      currentDesignTemplate,
      otherDesignTemplatesOfType: allDesignTemplatesOfType.filter(template => template !== currentDesignTemplate),
      ...spreadIf(isPresent(locations), { organizationLocation: locations.firstObject }),
    };
  },
  resetController(controller) {
    setProperties(controller, {
      isEditing: false,
    });
  },
  //endregion

  //region Actions
  actions: {
    willTransition(transition) {
      const { currentDesignTemplate, otherDesignTemplatesOfType } = this.modelFor(this.routeName);

      if (currentDesignTemplate.hasDirtyAttributes) {
        const confirmed = window.confirm(UNSAVED_CHANGES_ALERT);
        if (confirmed) {
          [currentDesignTemplate, ...otherDesignTemplatesOfType].forEach(designTemplate => {
            designTemplate.rollbackAttributes();
          });
        } else {
          transition.abort();
        }
      }
    },
  },
  //endregion
});
