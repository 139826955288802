import { helper } from '@ember/component/helper';

/**
 * Subtract 1 from a number. Optionally pass a second argument to subtract a different value.
 * @param {Number} startNumber
 * @param {Number?} decrementValue
 * @returns {Number}
 */
export function decrement([startNumber, decrementValue = 1]) {
  return startNumber - decrementValue;
}

export default helper(decrement);
