import Store from '@ember-data/store';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { ModelFrom } from 'partner/utils/types';

export type Model = ModelFrom<TurnkeysCreateRoute>;

export default class TurnkeysCreateRoute extends Route {
  @service declare store: Store;

  model(params: { promotion_preset_id: number }) {
    // Records are loaded from in the parent route
    return this.store.peekRecord('promotion-preset', params.promotion_preset_id);
  }
}
