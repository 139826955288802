/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import WinnerDisplay from 'partner/mixins/winner-display';

export default Component.extend(WinnerDisplay, {
  //region Attributes
  'entry-is-tied': false,
  'publishing-winners': false,
  'hide-entry-rank': false,
  //endregion

  //region Properties
  expandedContactInfo: false,
  //endregion

  //region Actions
  actions: {
    toggleContactInfoDisplay() {
      this.toggleProperty('expandedContactInfo');
    },
  },
  //endregion
});
