/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { deprecate } from '@ember/debug';
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { run } from '@ember/runloop';
import PermittedComponent from 'partner/mixins/permitted-component';

/**
 * select component that sends a action when the value is changed
 */
export default Component.extend(PermittedComponent, {
  content: null,
  selectedValue: null,
  tagName: 'ss-select',
  'two-way': true,
  classNameBindings: ['hasErrors', 'material:ss-material-select'],
  optionValuePath: null,
  optionLabelPath: null,
  disabledValuePath: false,
  /**
   * allows user to select an already selected value i.e, subject and preheader
   * token selector in message version setup
   * @type {Boolean}
   */
  'select-same-value': false,
  /**
   * If you want to manually disable the component, pass this as true.
   */
  'is-disabled': false,

  //region Computed Properties
  /**
   * Don't overwrite this from the outside! Also don't overwrite `disabled`.
   * If you do those things, it will cause permissions issues.
   */
  isDisabled: computed('administer', 'disabled', 'is-disabled', function () {
    return !this.administer || this.disabled || this['is-disabled'];
  }),

  hasErrors: computed('errors.[]', 'attribute', function () {
    const { errors } = this;
    if (!errors) {
      return false;
    }
    return errors.has(this.attribute);
  }),
  //endregion

  init() {
    this._super(...arguments);
    const { content } = this;

    if (!content) {
      set(this, 'content', []);
    }
  },

  actions: {
    change() {
      const selectedEl = this.element.querySelector('select');
      const selectedIndex = selectedEl.selectedIndex - (this.prompt ? 1 : 0);
      const { content } = this;
      const selectedValue = content.objectAt(selectedIndex);

      if (this['two-way']) {
        set(this, 'selectedValue', selectedValue);
        deprecate('Please refrain from using {{ss-select}} without two-way=false in the future', false, {
          id: 'ss-select-two-way',
          until: 'Ember 4',
          for: 'ss-select',
          since: '0.0.0',
        });
      }

      run(() => this.changed(selectedValue));

      // clear away any errors from the select input
      if (this.errors) {
        this.errors.clear();
      }

      if (this['select-same-value']) {
        selectedEl.selectedIndex = 0;
      }
    },
  },
});
