/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { computed } from '@ember/object';
import Attribute from 'partner/components/template-builder/attribute-editor/attribute';

export default Attribute.extend({
  //region Computed Properties
  isBold: computed('component-attributes.@each.{value,defaultValue}', 'article-category', function () {
    const attrs = this['component-attributes'];
    const articleCategory = this['article-category'];
    const prefix = articleCategory ? `${articleCategory}-` : '';
    const fontWeight = attrs[`${prefix}font-weight`];
    return fontWeight.value === 'bold' || fontWeight.defaultValue === 'bold';
  }),

  isItalic: computed('component-attributes.@each.{value,defaultValue}', 'article-category', function () {
    const attrs = this['component-attributes'];
    const articleCategory = this['article-category'];
    const prefix = articleCategory ? `${articleCategory}-` : '';
    const fontStyle = attrs[`${prefix}font-style`];
    return fontStyle.value === 'italic' || fontStyle.defaultValue === 'italic';
  }),

  isUnderline: computed('component-attributes.@each.{value,defaultValue}', 'article-category', function () {
    const attrs = this['component-attributes'];
    const articleCategory = this['article-category'];
    const prefix = articleCategory ? `${articleCategory}-` : '';
    const textDecoration = attrs[`${prefix}text-decoration`];
    return textDecoration.value === 'underline' || textDecoration.defaultValue === 'underline';
  }),
  //endregion

  //region Actions
  actions: {
    toggleStyle(value) {
      let attr, val;
      const prefix = this['article-category'] ? `${this['article-category']}-` : '';
      switch (value) {
        case 'bold':
          attr = `${prefix}font-weight`;
          val = this.isBold ? '' : 'bold';
          break;
        case 'italic':
          attr = `${prefix}font-style`;
          val = this.isItalic ? '' : 'italic';
          break;
        case 'underline':
          attr = `${prefix}text-decoration`;
          val = this.isUnderline ? '' : 'underline';
          break;
        default:
          break;
      }
      this['set-attribute'](attr, val);
    },
  },
  //endregion
});
