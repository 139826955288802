import Service from '@ember/service';
import { inject as service } from '@ember/service';
import ENV from 'partner/config/environment';

export default class EmailNotificationService extends Service {
  @service store;

  getShareableDashboardLink(designGuid, organizationUniqueId) {
    let domainName;
    if (ENV.environment === 'production') {
      const hostname = window.location.host;
      if (hostname.includes('partners-qa-') && hostname.includes('.secondstreetapp.com')) {
        const [, qaEnvironment] = hostname.match(/(?:partners-qa-)(.*)(?=.secondstreetapp.com)/);
        domainName = `advertisers-qa-${qaEnvironment}.secondstreetapp.com`;
      } else {
        domainName = 'advertisers.secondstreet.com';
      }
    } else {
      domainName = ENV.APP.ADVERTISER_DOMAIN;
    }
    return `${
      ENV.APP.ADVERTISER_DOMAIN?.includes('localhost:') ? 'http' : 'https'
    }://${domainName}/#/op/${organizationUniqueId}/sd/${designGuid}`;
  }

  /**
   *
   * @param {Object} design - store object of type Design
   * @param {Object} organizationPromotion - store object of type OrganizationPromotion
   * @param {Object} originalDesign store object of type Design to copy existing recipients details
   * @returns
   */
  createDraftEmailNotification(design, organizationPromotion, originalDesign) {
    return this.store.createRecord('email-notification', {
      design,
      organizationPromotionId: organizationPromotion.id,
      primaryUrl: this.getShareableDashboardLink(design.designGuid, organizationPromotion.uniqueId),
      emailNotificationRecipients: originalDesign
        ? originalDesign.emailNotification.emailNotificationRecipients.map(({ emailAddress }) =>
            this.store.createRecord('email-notification-recipient', { emailAddress })
          )
        : [],
    });
  }
}
