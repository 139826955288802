import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class MoveCategoryModal extends Component {
  @tracked selectedCategory = null;
  @tracked isSaving = false;
  @tracked showErrorMessage = false;

  @service('session') session;

  get availableCategories() {
    const availableCategories = this.args.matchups.filter(matchup => matchup.id !== this.currentCategory.id);

    if (this.args.groups) {
      availableCategories.forEach(category => {
        category['groupName'] = this.args.groups.find(group => group.id === category.groupId).name;
      });
    }

    return availableCategories;
  }

  get currentCategory() {
    const matchupId = this.args.isUgc
      ? ({ id }) => id === this.args.item.matchup.id
      : ({ id }) => id === `${this.args.item.votingEntry.matchupId}`;

    return this.args.matchups.find(matchupId);
  }

  @action
  async onCategoryChange(category) {
    this.selectedCategory = category;
  }

  @action
  moveEntrant(e) {
    e.preventDefault();

    this.isSaving = true;
    this.args.confirmMove(this.selectedCategory.id);
  }
}
