/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { computed, set } from '@ember/object';
import { alias, filterBy, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { dasherize } from '@ember/string';

const MESSAGE_CAMPAIGN_ROUTE = 'organizations.organization.message-campaigns.message-campaign';
const SETUP_ROUTE = `${MESSAGE_CAMPAIGN_ROUTE}.setup`;
const CONFIRMATION_ROUTE = `${SETUP_ROUTE}.confirmation`;

export default Controller.extend({
  //region Ember Hooks
  checklist: service(),
  messageCampaignController: controller(MESSAGE_CAMPAIGN_ROUTE),
  setupController: controller(SETUP_ROUTE),
  saveSchedule: false,
  //endregion

  //region Computed Properties
  messageCampaign: alias('messageCampaignController.model.messageCampaign'),
  messageVersionFeeds: filterBy('setupController.model.messageVersionFeeds', 'isDisabled', false),
  isAnythingDirty: or('model.singleMessageCampaign.hasDirtyAttributes', 'messageCampaign.isAnythingDirty'),
  isScheduled: alias('messageCampaign.isScheduled'),
  scheduleComponent: computed('messageCampaign.messageCampaignType', function () {
    const type = this.messageCampaign.messageCampaignType;
    return `message-campaigns/${dasherize(type)}-schedule`;
  }),
  usingFeedDisplayOrder: computed('messageVersionFeeds.@each.useFeedDisplayOrder', function () {
    return this.messageVersionFeeds.isAny('useFeedDisplayOrder');
  }),
  isSaveButtonDisabled: computed('isAnythingDirty', 'isAnythingSaving', 'saveSchedule', function () {
    return this.messageCampaign.isNewsletter
      ? this.isAnythingSaving || !this.isAnythingDirty || this.saveSchedule
      : this.isAnythingSaving || !this.isAnythingDirty;
  }),
  //endregion

  //region Methods
  /**
   * This unconfirms the campaign and unchecks the completion step.
   */
  unconfirmCampaign() {
    set(this, 'confirmed', false);
    const { messageCampaign } = this.model;
    if (!messageCampaign.isWelcome && !messageCampaign.isInvite) {
      set(messageCampaign, 'isConfirmed', false);
      this.checklist.uncheckChecklistStep(CONFIRMATION_ROUTE);
    }
  },
  //endregion

  //region Actions
  actions: {
    checklistStepSatisfaction(bool) {
      set(this, 'isChecklistStepSatisfied', bool);
      const routeName = 'organizations.organization.message-campaigns.message-campaign.setup.schedule';
      this.checklist.setChecklistStepStatus(routeName, bool ? 100 : 0);
    },
    addMessageVersionB(messageVersionA) {
      set(this, 'isAnythingSaving', true);
      this.send('copyMessageVersion', messageVersionA);
    },
    checkNewsletterSchedule(value) {
      set(this, 'saveSchedule', value);
    },
  },
  //endregion
});
