/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set, get } from '@ember/object';
import { equal, filterBy, gte } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import TemplateDesigner from 'partner/mixins/template-designer';
import { generateRenderedContent } from 'partner/utils/designer-preview';
import { getDoc, replacePageTokens } from 'secondstreet-common/utils/replace-tokens';

export default Component.extend(TemplateDesigner, {
  //region Attributes
  minimumAgeSetting: null,
  tokens: null,
  minimumAgeEntryType: '',
  winnerRestrictionsSetting: null,
  setActiveDesign() {},
  saveCustomDesign() {},
  saveTemplateDesign() {},
  saveMinimumAgeSetting() {},
  saveWinnerRestrictionsSetting() {},
  //endregion

  //region Ember Hooks
  classNames: ['template-designer--legal'],
  //endregion

  //region Computed Properties
  isSaveDisabled: computed('is-anything-saving', 'design.{isApproved,hasDirtyAttributes}', function () {
    return this.design.isApproved && (!this.design.hasDirtyAttributes || this['is-anything-saving']);
  }),

  isLockedWithEditableAreas: computed('design.isLocked', function () {
    const tokenContents = get(this, 'design.tokenContents');
    return (
      get(this, 'design.template.isLocked') &&
      tokenContents.any(tokenContent => tokenContent.designToken.isPageContentToken)
    );
  }),

  renderedContent: computed('design.{renderedContent,tokenContents.@each.isDisabled}', function () {
    const tokenContents = (get(this, 'design.tokenContents') || []).toArray();
    return replacePageTokens(this.design.renderedContent, tokenContents);
  }),
  dynamicTokens: filterBy('tokens', 'tokenType', 'Dynamic'),
  sortedFilteredDynamicTokens: computed('dynamicTokens', 'organization-promotion.promotion.promotionType', function () {
    const excludedDynamicTokenKeys = [];

    if (this.organizationPromotion.promotion.isEventSignup) {
      excludedDynamicTokenKeys.push(
        'Promotion.EntryStartDate',
        'Promotion.EntryEndDate',
        'Promotion.SelectionStartDate',
        'Promotion.SelectionEndDate',
        'Promotion.EntryFrequency',
        'Promotion.SelectionFrequency',
        'Promotion.MinimumAge',
        'Promotion.NumberOfWinners'
      );
    } else {
      excludedDynamicTokenKeys.push(
        'Event.Name',
        'Event.SignupStartDate',
        'Event.SignupEndDate',
        'Event.StartDate',
        'Event.EndDate'
      );
    }

    const dynamicTokens = this.dynamicTokens.reject(token => excludedDynamicTokenKeys.includes(token.key));

    return dynamicTokens;
  }),
  immediately: equal('winnerRestrictionsSetting.value', 0),
  daysPassed: gte('winnerRestrictionsSetting.value', 1),
  //endregion

  //region Methods
  updateBody() {
    const tokens = this.isLockedWithEditableAreas
      ? this.tokens.filter(
          token => token.tokenContentTypeId != this.enums.findWhere('TOKEN_CONTENT_TYPE', { name: 'HtmlText' }, 'id')
        )
      : this.tokens;
    set(this, 'design.renderedContent', generateRenderedContent(this.design, tokens));
  },
  //endregion

  //region Actions
  actions: {
    save() {
      const dirtyDesignTokenContents = this.design.designTokenContents.filterBy('hasDirtyAttributes');

      if (this.minimumAgeSetting.hasDirtyAttributes) {
        this.saveMinimumAgeSetting();
      }

      if (this.winnerRestrictionsSetting.hasDirtyAttributes) {
        this.saveWinnerRestrictionsSetting();
      }

      if (isPresent(dirtyDesignTokenContents)) {
        this.saveTemplateDesign(dirtyDesignTokenContents);
      }
    },
    clickImmediately() {
      set(this, 'winnerRestrictionsSetting.value', 0);
    },
    clickDaysPassed() {
      set(this, 'winnerRestrictionsSetting.value', 30);
    },
    onTokenValueChange(property, htmlString) {
      let formattedHTMLString = '';
      if (
        this.isLockedWithEditableAreas &&
        this.activeTokenContent.token.tokenContentTypeId !=
          this.enums.findWhere('TOKEN_CONTENT_TYPE', { name: 'HtmlText' }, 'id')
      ) {
        try {
          const doc = getDoc(htmlString);
          const elements = doc.body.children;

          for (let i = 0; i < elements.length; i++) {
            formattedHTMLString += elements[i].innerHTML;
          }
        } catch (e) {
          formattedHTMLString = htmlString;
        }
      }

      this.send('updateTokenContentProperty', property, formattedHTMLString ? formattedHTMLString : htmlString);
    },
  },
  //endregion
});
