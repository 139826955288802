export default `
  <div class="ballot-showcase ballot-showcase--preview !p-0 ml-4 sm:mr-1">
    <div class="gallery-column flex flex-col max-w-[220px]">
      <div class="ssTileExternalHeaderContainer">Featured Entrants</div>
      <div class="individual-entry-view w-full max-w-[278px]">
        <div class="card">
          <div class="entry">
            <img class="max-w-full" width="600" src="https://media.secondstreetapp.com/250194?width=1170&height=1170&quality=60">
          </div>
          <div class="ballot-entry-description mx-3 !my-0">
            <span class="entry-title !p-0 !text-2xl">Entrant Name</span>
            <div class="entry-caption">
              Oat cake dessert sugar plum marzipan tart carrot cake donut lollipop. Wafer chocolate cake jujubes sweet. Halvah jelly fruitcake donut gingerbread. Cheesecake bonbon topping marshmallow tootsie roll oat cake powder oat cake. Tart sesame snaps jelly beans. Jujubes sweet roll chocolate cake. Soufflé soufflé cupcake.
            </div>
          </div>
          <div class="entry-actions">
            <div class="gallery-vote">
              <button class="ssButton ssButtonPrimary vote-button vote">Vote</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gallery-column flex flex-col max-w-[220px]">
      <div class="ssTileExternalHeaderContainer invisible">Featured Entrants</div>
      <div class="individual-entry-view w-full max-w-[278px]">
        <div class="card">
          <div class="entry">
            <img class="max-w-full" width="600" src="https://media.secondstreetapp.com/250194?width=1170&height=1170&quality=60">
          </div>
          <div class="ballot-entry-description mx-3 !my-0">
            <span class="entry-title !p-0 !text-2xl">Entrant Name</span>
            <div class="entry-caption">
              Sugar plum jujubes brownie cheesecake marshmallow. Toffee brownie cake candy canes bonbon gingerbread chupa chups. Gingerbread danish croissant gummies brownie icing ice cream.
            </div>
          </div>
          <div class="entry-actions">
            <div class="gallery-vote">
              <button class="ssButton ssButtonPrimary vote-button vote hover:brightness-110">Vote</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
`;
