import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type EnumsService from 'ember-cli-ss-enums/services/enums';

export default class PromotionTypeRoute extends Route {
  @service declare enums: EnumsService;

  get organizationPromotionModel(): any {
    return this.modelFor('organizations.organization.organization-promotions.organization-promotion');
  }

  get promotionTypeName() {
    const id = this.organizationPromotionModel.organizationPromotion.promotion.promotionTypeId;

    return this.enums.findWhere('PROMOTION_TYPE', { id }, 'name');
  }
}
