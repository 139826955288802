import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class extends Controller {
  @service router;

  saveTask = task({ drop: true }, async () => {
    try {
      await this.model.organization.save();

      this.router.transitionTo('organizations.organization.settings.info');
      // eslint-disable-next-line no-empty
    } catch (e) {}
  });
}
