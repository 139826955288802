/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import duration from 'secondstreet-common/utils/duration';
import uniq from 'lodash/uniq';
import { computed } from '@ember/object';
import { later } from '@ember/runloop';
import isEqual from 'lodash/isEqual';

const linkSubTypes = entityTypes =>
  entityTypes.map(([entityType, subTypes]) => {
    entityType.subTypes = subTypes.map(subType => {
      subType.entityType = entityType;

      return subType;
    });

    return entityType;
  });

const ENTITY_TYPES = linkSubTypes([
  [
    {
      id: 1,
      name: 'Contests',
      icon: 'emoji_events',
      background: 'bg-theme-sweepstakes',
      entityTypeId: 1,
    },
    [
      { id: 2, type: 'SweepstakesSimple', name: 'Sweepstakes' },
      { id: 3, type: 'SweepstakesCodeword', name: 'Codeword Sweeps' },
      { id: 21, type: 'UGCSweepstakesStandard', name: 'Photo Sweeps' },
      { id: 25, type: 'UGCSweepstakesVideo', name: 'Video Sweeps' },
      { id: 23, type: 'PhotoVotingStandard', name: 'Photo Contest' },
      { id: 26, type: 'VideoVotingStandard', name: 'Video Contest' },
    ],
  ],
  [
    {
      id: 2,
      name: 'Interactive Content',
      icon: 'quickreply',
      background: 'bg-theme-quizzes',
      entityTypeId: 1,
    },
    [
      { id: 4, type: 'QuizPersonality', name: 'Personality Quiz' },
      { id: 5, type: 'QuizTrivia', name: 'Trivia Quiz' },
      { id: 27, type: 'VotingBallot', name: 'Voting Ballot' },
      { id: 28, type: 'NominationAndVotingBallot', name: 'Nomination & Voting Ballot' },
      { id: 30, type: 'VotingBracket', name: 'Voting Bracket' },
      { id: 29, type: 'PollStandard', name: 'Polls' },
      { id: 18, type: 'Survey', name: 'Survey' },
      { id: 24, type: 'PhotoGallery', name: 'Community Gallery' },
    ],
  ],
  [
    {
      id: 3,
      name: 'Email',
      icon: 'email',
      background: 'bg-theme-messaging-email',
      entityTypeId: 3,
    },
    [
      { id: 1, type: 'SingleEmail', name: 'Single Email' },
      { id: 4, type: 'Newsletter', name: 'Newsletter' },
      { id: 5, type: 'Birthday', name: 'Birthday' },
      { id: 10, type: 'WeddingAnniversary', name: 'Wedding Anniversary' },
      { id: 3, type: 'DripCampaign', name: 'Drip Campaign' },
      { id: 7, type: 'Welcome', name: 'Welcome' },
      { id: 8, type: 'Invite', name: 'Invite' },
      { id: 9, type: 'ThankYou', name: 'Thank You' },
      { id: 12, type: 'Reminder', name: 'Reminder' },
    ],
  ],
  [
    {
      id: 2,
      name: 'Audiences',
      icon: 'group',
      background: 'bg-theme-data',
      entityTypeId: 2,
    },
    [
      { id: 2, type: 'SubscriptionAudience', name: 'Opt-in' },
      { id: 1, type: 'SegmentedAudience', name: 'Segmented Audience' },
    ],
  ],
  [
    {
      id: 3,
      name: 'Events',
      icon: 'event',
      background: 'bg-primary',
      entityTypeId: 1,
    },
    [{ id: 0, type: 'All', name: 'All' }],
  ],
  [
    {
      id: 4,
      name: 'Users',
      icon: 'perm_identity',
      background: 'bg-gray-800',
      entityTypeId: 4,
    },
    [{ id: 0, type: 'All', name: 'All' }],
  ],
]);

export default class SearchController extends Controller {
  @service notifications;
  @service enums;
  @service('screen') screen;

  entityTypes = ENTITY_TYPES;
  shouldCloseDropdown = true;

  @tracked searchValue = '';
  @tracked selectedSubTypes = [];
  @tracked selectedStartDate;
  @tracked selectedEndDate;
  @tracked includeArchived = false;

  @tracked _selectedSubTypes = [];
  @tracked _selectedStartDate;
  @tracked _selectedEndDate;
  @tracked _includeArchived = false;

  // Search related props
  @tracked organizationPromotions = null;
  @tracked audiences = null;
  @tracked messageCampaigns = null;
  @tracked groupedConsumerUsers = null;
  @tracked consumerUsersTotalRecords = null;
  @tracked isLoadingSearchResults = false;

  searchTask = task({ restartable: true }, async event => {
    await timeout(duration(250));
    this.searchValue = event.target.value;
    await this.updateSearch();
  });

  @action
  selectEntityType(entityType) {
    if (entityType.subTypes.every(subType => this.selectedSubTypes.includes(subType))) {
      this.selectedSubTypes = this.selectedSubTypes.filter(subType => !entityType.subTypes.includes(subType));
    } else {
      this.selectedSubTypes = [
        ...this.selectedSubTypes.filter(subType => !entityType.subTypes.includes(subType)),
        ...entityType.subTypes,
      ];
    }
  }

  @action
  selectSubType(subType) {
    if (this.selectedSubTypes.includes(subType)) {
      this.selectedSubTypes = this.selectedSubTypes.filter(type => type != subType);
    } else {
      this.selectedSubTypes = [...this.selectedSubTypes, subType];
    }
  }

  @action
  entityTypeCheckState(entityType) {
    return entityType.subTypes.every(subType => this.selectedSubTypes.includes(subType))
      ? true
      : entityType.subTypes.some(subType => this.selectedSubTypes.includes(subType))
      ? 'indeterminate'
      : false;
  }

  @computed(
    'selectedSubTypes',
    '_selectedSubType',
    'includeArchived',
    '_includeArchived',
    'selectedStartDate',
    '_selectedStartDate',
    'selectedEndDate',
    '_selectedEndDate'
  )
  get applyButtonDisabled() {
    return (this.selectedStartDate && !this.selectedEndDate) || this.selectedStartDate >= this.selectedEndDate
      ? true
      : isEqual(this.selectedSubTypes, this._selectedSubTypes) &&
          this.includeArchived == this._includeArchived &&
          this.selectedStartDate == this._selectedStartDate &&
          this.selectedEndDate == this._selectedEndDate;
  }

  @computed('selectedSubTypes', 'includeArchived', 'selectedStartDate', 'selectedEndDate')
  get filterCount() {
    return this.includeArchived && this.selectedStartDate && this.selectedEndDate
      ? this.selectedSubTypes.length + 2
      : this.includeArchived || (this.selectedStartDate && this.selectedEndDate)
      ? this.selectedSubTypes.length + 1
      : this.selectedSubTypes.length;
  }

  updateSearch() {
    if (!isEmpty(this.searchValue)) {
      this.isLoadingSearchResults = true;

      const selectedEntityTypes = this.entityTypes.filter(entityType =>
        entityType.subTypes.some(promotionType => this._selectedSubTypes.includes(promotionType))
      );

      const date = date => `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

      const mapIdsFor = (collection, filterKey, filterValue) =>
        uniq(collection.filter(item => get(item, filterKey) == filterValue))
          .mapBy('id')
          .join(',');

      this.send('getSearchResults', {
        searchValue: this.searchValue,
        searchEntityTypeIds: selectedEntityTypes.mapBy('entityTypeId').join(','),
        promotionSubTypeIds: mapIdsFor(this._selectedSubTypes, 'entityType.entityTypeId', 1),
        promotionTypeCategoryIds: mapIdsFor(selectedEntityTypes, 'entityTypeId', 1),
        messageCampaignTypeIds: mapIdsFor(this._selectedSubTypes, 'entityType.entityTypeId', 3),
        audienceTypeIds: mapIdsFor(this._selectedSubTypes, 'entityType.entityTypeId', 2),
        startDate: this.selectedStartDate ? date(this._selectedStartDate) : undefined,
        endDate: this.selectedEndDate ? date(this._selectedEndDate) : undefined,
        excludeArchieved: !this._includeArchived,
      });
    } else {
      this.organizationPromotions = null;
      this.audiences = null;
      this.messageCampaigns = null;
      this.groupedConsumerUsers = null;
      this.consumerUsersTotalRecords = null;
      this.isLoadingSearchResults = false;
    }
  }

  @action
  open(dropdown, entityType) {
    if (this.previousDropdown && this.previousDropdown.uniqueId != dropdown.uniqueId) {
      this.previousDropdown.actions.close();
    }

    this.previousDropdown = dropdown;

    if (!dropdown.isOpen && entityType.subTypes.length > 1) {
      dropdown.actions.open();
    }
  }

  @action
  prevent(e) {
    e.stopImmediatePropagation();
  }

  @action
  calculatePosition(trigger, content) {
    let style = {};

    const isMobile = this.screen.width <= 820;
    const { top, left, height, width } = trigger.getBoundingClientRect();
    const { height: contentHeight } = content.getBoundingClientRect();
    const leftOffset = isMobile ? 53 : width;
    const topOffset = isMobile ? 44 : 0;

    if (window.innerHeight < top + contentHeight && top + height / 2 > window.innerHeight / 2) {
      const bottom = top + (isMobile ? 0 : height);

      style = {
        top: bottom - Math.min(bottom, contentHeight) + window.scrollY,
        maxHeight: `${Math.min(bottom, 320)}px`,
      };
    } else {
      style = {
        top: top + window.scrollY + topOffset,
        maxHeight: `${Math.min(window.innerHeight - top, 320)}px`,
      };
    }

    return {
      style: {
        left: left + leftOffset,
        ...style,
      },
    };
  }

  @action
  calculateMainDropdownPosition(trigger) {
    const { left } = trigger.getBoundingClientRect();

    return { style: { left, top: window.scrollY + 56 } };
  }

  @action
  clearFilter(dropdown) {
    this.selectedSubTypes = [];
    this.selectedStartDate = undefined;
    this.selectedEndDate = undefined;
    this.includeArchived = false;

    this._selectedSubTypes = [];
    this._selectedStartDate = undefined;
    this._selectedEndDate = undefined;
    this._includeArchived = false;

    dropdown.actions.close();
    this.updateSearch();
  }

  @action
  applyFilters(dropdown) {
    this._selectedSubTypes = this.selectedSubTypes;
    this._selectedStartDate = this.selectedStartDate;
    this._selectedEndDate = this.selectedEndDate;
    this._includeArchived = this.includeArchived;
    dropdown.actions.close();
    this.updateSearch();
  }

  @action
  closeDropdown() {
    if (this.shouldCloseDropdown) {
      this.selectedSubTypes = this._selectedSubTypes;
      this.selectedStartDate = this._selectedStartDate;
      this.selectedEndDate = this._selectedEndDate;
      this.includeArchived = this._includeArchived;
    }

    return this.shouldCloseDropdown;
  }

  @action
  setLater(key, value) {
    later(this, () => set(this, key, value), 50);
  }
}
