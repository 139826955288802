/* eslint-disable ember/require-tagless-components, ember/no-classic-components, ember/no-computed-properties-in-native-classes */
import Component from '@ember/component';
import { computed, set, setProperties, action } from '@ember/object';
import difference from 'lodash/difference';
import { later } from '@ember/runloop';

const extractEmails = text =>
  text.match(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi
  ) || [];

export default class EditEmailAddresses extends Component {
  //region Attributes
  'email-addresses' = null;
  'deleted-email-addresses' = null;
  'recipient-placeholder' = 'Add one or more Email Addresses…';
  errors = null;
  'errors-attribute' = null;
  'is-editable' = true;

  newRecipient = '';
  noValidEmails = false;

  @computed('email-addresses')
  get allEmailAddresses() {
    const emailAddresses = this['email-addresses'];
    return emailAddresses ? emailAddresses.split(';') : [];
  }

  @computed('allEmailAddresses', 'deleted-email-addresses')
  get notDeletedEmailAddresses() {
    return difference(this.allEmailAddresses, this['deleted-email-addresses']);
  }

  @action
  addRecipients() {
    if (!this.newRecipient) return;
    set(this, 'showMustAddWarning', false);
    const recipients = extractEmails(this.newRecipient);

    if (!recipients.length) {
      set(this, 'noValidEmails', true);
      return;
    }

    const emailAddresses = this.notDeletedEmailAddresses.slice();
    const newRecipients = recipients.filter(email => emailAddresses.indexOf(email) === -1);

    if (newRecipients.length) {
      const updatedEmailAddresses = emailAddresses.addObjects(newRecipients).join(';');
      this['update-email-addresses'](updatedEmailAddresses);
    }

    setProperties(this, {
      newRecipient: '',
      noValidEmails: false,
    });
  }

  @action
  removeRecipient(item) {
    const emailAddresses = this.notDeletedEmailAddresses.slice();
    const updatedEmailAddresses = emailAddresses.removeObject(item).join(';');
    this['update-email-addresses'](updatedEmailAddresses);
  }

  @action
  onInputFocus() {
    set(this, 'noValidEmails', false);
    set(this, 'showMustAddWarning', false);
  }

  @action
  onInputFocusOut() {
    /*
      We wait for 100ms so this event do not interfere with click add event.
    */
    later(() => {
      if (!this.noValidEmails && this.newRecipient) {
        set(this, 'showMustAddWarning', true);
      }
    }, 100);
  }
}
