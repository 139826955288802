/**
 * Returns a string after replacing tabs, new lines and multiple spaces with single space
 *
 * @param {string} str
 * @returns {string}
 */
export default function (str) {
  return typeof str === 'string'
    ? str
        .trim()
        .replace(/[\t\n\r]/gm, ' ')
        .replace(/\s+/g, ' ')
    : '';
}
