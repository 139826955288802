/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo, hasMany } = DS;

export default BaseModel.extend({
  //region Ember Hooks
  checklistService: service('checklist'),
  //endregion

  //region Properties
  name: attr('string'),
  checklistStepTypeId: attr('number'),
  displayOrder: attr('number'),
  //endregion

  //region Computed Properties
  // Organization User will be used down the road, but for now there is one status per step
  isComplete: alias('checklistStepStatus.isComplete'),
  percentComplete: alias('checklistStepStatus.percentComplete'),
  checklistStepStatus: alias('checklistStepStatuses.firstObject'),
  checklistStepStatusPercentComplete: alias('checklistStepStatus.percentComplete'),
  destination: computed('checklistStepTypeId', function () {
    const typeId = window.parseInt(this.checklistStepTypeId, 10);
    return this.checklistService.getDestination(typeId);
  }),
  //endregion

  //region Relationships
  checklist: belongsTo('checklist', { async: false }),
  checklistStepStatuses: hasMany('checklist-step-status'),
  //endregion
});
