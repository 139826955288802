import type { Snapshot } from '@ember-data/store';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type ModelRegistry from 'ember-data/types/registries/model';
import ApplicationAdapter from './application';

export default class InterestTagAdapter extends ApplicationAdapter {
  private allLoaded = false;

  coalesceFindRequests = true;

  findAll(...args: any[]) {
    try {
      // @ts-ignore
      return super.findAll(...args);
    } finally {
      this.allLoaded = true;
    }
  }

  shouldReloadAll() {
    return !this.allLoaded;
  }

  urlForFindRecord<K extends keyof ModelRegistry>(id: string, modelName: K, snapshot: Snapshot<K>): string {
    const url = super.urlForFindRecord(id, modelName, snapshot);

    return this.excludeChildTags(url);
  }

  urlForFindMany<K extends keyof ModelRegistry>(ids: string[], modelName: K, snapshot: Snapshot<K>[]): string {
    const url = super.urlForFindMany(ids, modelName, snapshot);

    return this.excludeChildTags(url);
  }

  private excludeChildTags(url: string) {
    const urlObject = new URL(url, location.origin);

    if (!urlObject.searchParams.has('excludeChildTags')) {
      urlObject.searchParams.append('excludeChildTags', 'true');
    }

    return urlObject.toString();
  }
}
