import { later } from '@ember/runloop';
import environment from 'partner/config/environment';

const MINIMUM_POLLING_TIME = 5000;

const checkPolling = (audience, audienceId, context, stopPolling) => {
  if (environment.environment === 'test' || stopPolling) {
    return;
  }
  later(async () => {
    if (!context.isDestroyed) {
      const audience = await context.store.queryRecord('audience', {
        Id: audienceId,
      });
      if (audience.isProcessing || audience.isCalculating) {
        checkPolling(audience, audienceId, context, false);
      }
    }
  }, MINIMUM_POLLING_TIME);
};
export default checkPolling;
