/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { set } from '@ember/object';

export default Component.extend({
  //region Attributes
  width: 300,
  /**
   * The URL for the video
   */
  src: null,
  /**
   * Thumbnail image URL to be displayed if the video is broken
   */
  'alt-src': null,
  /**
   * The image URL representing the initial frame displayed for the video before clicking play
   */
  poster: null,
  //endregion

  didInsertElement() {
    this._super(...arguments);
    const video = this.element.querySelector('video source');
    if (this['alt-src']) {
      // if there is a problem with the video.src, the poster image will be replaced with the alt-src image
      video.addEventListener('error', () => {
        set(this, 'poster', this['alt-src']);
      });
    }
  },
});
