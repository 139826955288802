export const BACKSPACE = 8;
export const TAB = 9;
export const ENTER = 13;
export const SHIFT = 16;
export const CTRL = 17;
export const ALT = 18;
export const PAUSE_BREAK = 19;
export const CAPS_LOCK = 20;
export const ESC = 27;
export const SPACE = 32;
export const PAGE_UP = 33;
export const PAGE_DOWN = 34;
export const END = 35;
export const HOME = 36;
export const LEFT = 37;
export const UP = 38;
export const RIGHT = 39;
export const DOWN = 40;
export const INSERT = 45;
export const DELETE = 46;
export const COMMAND = 91;
export const LEFT_COMMAND = 91;
export const RIGHT_COMMAND = 93;
export const NUMPAD_ASTERISK = 106;
export const NUMPAD_PLUS = 107;
export const NUMPAB_MINUS = 109;
export const NUMPAD_DOT = 110;
export const NUMPAD_SLASH = 111;
export const NUM_LOCK = 144;
export const SCROLL_LOCK = 145;
export const MY_COMPUTER = 182;
export const MY_CALCULATOR = 183;
export const SEMICOLON = 186; // ;
export const EQUALS = 187; // =
export const COMMA = 188; // ,
export const MINUS = 189; // -
export const DOT = 190; // .
export const SLASH = 191; // /
export const GRAVE = 192; // `
export const LEFT_BRACKET = 219; // [
export const BACKSLASH = 220; // \
export const RIGHT_BRACKET = 221; // ]
export const QUOTE = 222; // '
