export default ({ message, confirmation: { receiptText, confirmationText, confirmationNumberText, overviewLink } }) => `
<div class="payment-card" data-token="thankYouMessage">
  <div class="payment-card__thanks">
    <div class="payment-card__dynamic-text">${message}</div>
    <p>${receiptText} <strong>youremail@example.com</strong></p>
    <p>${confirmationText}</p>
    <div class="callout-box">
      ${confirmationNumberText}:
      <div class="callout-box__code">
        ABCD-1234
      </div>
    </div>
    <span class="ember-view template-color-primary-color payment-card__link">
      ${overviewLink}
    </span>
  </div>
</div>
<div class="ssSharing">
  <ul class="ssShareListCompact ssShareList">
    <li class="ssShareListItem ssShareListItemFacebook">
      <a class="ssShareLink ssShareLinkFacebook" data-token="facebook" href="#" >
        <i class="ssIcon-facebook" aria-label="share on facebook"></i>
      </a>
    </li>
    <li class="ssShareListItem ssShareListItemTwitter">
      <a class="ssShareLink ssShareLinkTwitter" data-token="twitter" href="#">
        <i class="ssIcon-twitter" aria-label="share on twitter"></i>
      </a>
    </li>
    <li class="ssShareListItem ssShareListItemEmail">
      <a class="ssShareLink ssShareLinkEmail" data-token="email" href="#" >
        <i class="ssIcon-envelope" aria-label="share with email"></i>
      </a>
    </li>
    <li class="ssShareListItem ssShareListItemPinterest">
      <a class="ssShareLink ssShareLinkPinterest" data-token="pinterest" href="#" >
        <i class="ssIcon-pinterest" aria-label="share on pinterest"></i>
      </a>
    </li>
  </ul>
</div>
`;
