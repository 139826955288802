import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @disabled}}\n  {{#each @items as |item index|}}\n    {{yield item index}}\n  {{/each}}\n{{else}}\n  <div\n    {{did-insert this.handleMount}}\n    {{will-destroy this.handleDestroy}}\n    data-is-dragging={{this.isDragging}}\n    ...attributes\n  >\n    {{#each this.items as |item index|}}\n      {{yield (get @items index) index}}\n    {{/each}}\n  </div>\n{{/if}}\n", {"contents":"{{#if @disabled}}\n  {{#each @items as |item index|}}\n    {{yield item index}}\n  {{/each}}\n{{else}}\n  <div\n    {{did-insert this.handleMount}}\n    {{will-destroy this.handleDestroy}}\n    data-is-dragging={{this.isDragging}}\n    ...attributes\n  >\n    {{#each this.items as |item index|}}\n      {{yield (get @items index) index}}\n    {{/each}}\n  </div>\n{{/if}}\n","moduleName":"partner/components/ui/sortable.hbs","parseOptions":{"srcName":"partner/components/ui/sortable.hbs"}});
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Sortable, { type SortableEvent } from 'sortablejs';
import { bind, run } from '@ember/runloop';
import duration from 'secondstreet-common/utils/duration';

export interface UiSortableArgs<T = any> {
  items: T[];
  onChange: (items: T[], movedItem?: T) => void;
  group?: string;
  handle?: string;
  disabled?: boolean;
}

/**
 * Keep a copy of the original items from the starting group
 * so it can be used when moving an item from one group to another
 */
let startItems: any[] = [];

export default class UiSortableComponent<T = any> extends Component<UiSortableArgs<T>> {
  @tracked isDragging = false;

  // Used to force re-rendering when the user drags an item to the same position
  @tracked counter = 0;
  #previousCounter = 0;

  sortable: Sortable | null = null;

  #cachedItems: T[] = [];
  #mappedItems: { value: T }[] = [];

  /**
   * Convert the items to an array of objects with a `value` property
   * to force re-rendering when the items change
   */
  @cached
  get items() {
    const areEqual =
      this.args.items.length == this.#cachedItems.length &&
      this.args.items.every((item, index) => item == this.#cachedItems[index]);

    if (!areEqual || this.counter != this.#previousCounter) {
      // eslint-disable-next-line ember/no-side-effects
      this.#cachedItems = [...this.args.items];
      // eslint-disable-next-line ember/no-side-effects
      this.#mappedItems = this.#cachedItems.map(item => ({ value: item }));
      // eslint-disable-next-line ember/no-side-effects
      this.#previousCounter = this.counter;
    }

    return this.#mappedItems;
  }

  @action
  handleMount(element: HTMLElement) {
    this.sortable = Sortable.create(element, {
      animation: duration(150),
      group: this.args.group,
      handle: this.args.handle,
      disabled: this.args.disabled,

      onStart: bind(this, 'handleStart'),
      onEnd: bind(this, 'handleEnd'),
      onAdd: bind(this, 'handleAdd'),
      onRemove: bind(this, 'handleRemove'),
      onUpdate: bind(this, 'handleUpdate'),
    });
  }

  @action
  handleDestroy() {
    run(() => this.sortable?.destroy());
  }

  handleStart() {
    // Keep a copy of the original items
    startItems = [...this.args.items];
    this.isDragging = true;
  }

  handleEnd({ oldIndex, newIndex, item }: SortableEvent) {
    // When the item doesn't change position, remove it from the DOM and force re-render
    // as there will be a ghost element left behind
    if (oldIndex == newIndex) {
      run(() => item.remove()); // Remove the item from the DOM and let Ember re-render
      this.counter++;
    }

    this.isDragging = false;
  }

  /**
   * Called whenever an item is added to the list from another one
   */
  handleAdd({ oldIndex, newIndex, item }: SortableEvent) {
    if (oldIndex == undefined || newIndex == undefined) return;

    const movedItem = startItems[oldIndex] as T;

    this.#handleChange(
      item,
      [...this.args.items.slice(0, newIndex), movedItem, ...this.args.items.slice(newIndex)],
      movedItem
    );
  }

  handleRemove({ oldIndex, item }: SortableEvent) {
    if (!item || oldIndex == undefined) return;

    this.#handleChange(
      item,
      (startItems as T[]).filter((_, index) => index != oldIndex)
    );
  }

  handleUpdate({ oldIndex, newIndex, item }: SortableEvent) {
    if (oldIndex == undefined || newIndex == undefined) return;

    const items = [...this.args.items];
    const [movedItem] = items.splice(oldIndex, 1);
    if (movedItem) items.splice(newIndex, 0, movedItem);

    this.#handleChange(item, items, movedItem);
  }

  #handleChange(item: HTMLElement, items: T[], movedItem?: T) {
    run(() => item.remove()); // Remove the item from the DOM and let Ember re-render
    this.args.onChange(items, movedItem);
  }
}
