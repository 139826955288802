/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, observer, set } from '@ember/object';
import { run } from '@ember/runloop';
import { htmlSafe } from '@ember/template';
import PermittedComponent from 'partner/mixins/permitted-component';

// Via https://stackoverflow.com/a/3561711
const escapeStringForRegExp = s => s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); //eslint-disable-line

/**
 * {{ss-tokenized-input value=someProperty}}
 * @param [errors] {DS.Errors} - An instance of DS.Errors to display errors from. Using this makes `attribute` required.
 * @param [attribute] {String} - The string name of the property from DS.Errors this field is tied to.
 * @see Promotion.PermittedComponent
 * @type {Ember.TextField}
 */
export default Component.extend(PermittedComponent, {
  //region Ember Hooks
  tagName: 'ss-tokenized-input',
  classNames: ['tokenized-input'],
  classNameBindings: ['isExpanded'],

  getInput() {
    return this.element.querySelector('input');
  },

  init() {
    this._super(...arguments);

    if (!this.tokens) {
      set(this, 'tokens', []);
    }
  },

  didInsertElement() {
    this._super(...arguments);
    set(this, 'cursorPositionListener', () => {
      const left = this.element.querySelector('input').scrollLeft || 0;

      run(() => set(this, 'inputScrollLeft', htmlSafe(`${left}`)));
      // So we're not waiting for the run loop, let's eagerly set it in the DOM, too.
      const echo = this.element.querySelector('.tokenized-input__echo');
      if (echo) {
        // Echo only renders if there are tokens, so it may not be present.
        echo.style.left = `-${left}px`;
      }
    });
    // Catches new characters, click-and-drag highlight moves, and left/right arrow keys
    document.addEventListener('selectionchange', this.cursorPositionListener);
    // Catches backspacing
    this.element.addEventListener('keyup', this.cursorPositionListener);
  },

  willDestroyElement() {
    this._super(...arguments);
    document.removeEventListener('selectionchange', this.cursorPositionListener);
    this.element.removeEventListener('keyup', this.cursorPositionListener);
  },
  //endregion

  //region Properties
  /**
   * Listen for events that could possibly move the text cursor in the input, and
   * handles moving the echo to stay exactly under the input's text.
   *
   * Gets its actual contents set in `didInsertElement` for ease of removeEventListener.
   */
  cursorPositionListener() {},
  inputScrollLeft: htmlSafe('0'),
  /**
   * @property {DesignTokenModel[]}
   */
  tokens: null,
  isExpanded: false,
  type: 'text',
  checkForDynamicTokenFallback: () => {},
  //endregion

  //region Computed Properties
  // Put the longest tokens first, so we don't accidentally replace a substring of a longer token
  sortedTokens: computed('tokens.[]', function () {
    return [...(this.tokens || [])].sort((a, b) => b.name.length - a.name.length);
  }),

  /**
   * The value that actually gets bound to the <input> element.
   * @returns {String}
   */
  _visibleValue: null,
  visibleValue: computed('value', 'tokens.[]', '_visibleValue', {
    get() {
      if (this._visibleValue !== null) {
        return this._visibleValue;
      }
      let newValue = this.value || '';

      (this.tokens || []).forEach(token => {
        newValue = newValue.replace(new RegExp(`{{${escapeStringForRegExp(token.key)}}}`, 'g'), token.name);
      });
      return newValue;
    },
    set(_key, value) {
      this.set('_visibleValue', value);
      return value;
    },
  }),

  /**
   * The value that gets rendered behind the <input> element. Should have the same
   * textContent as visibleValue, but tokens are wrapped in <span> tags for CSS.
   * @returns {String}
   */
  echoValue: computed('visibleValue', 'sortedTokens.[]', function () {
    let newValue = this.visibleValue;

    this.sortedTokens.forEach(token => {
      // Make sure that the token is not highlighted already
      // This could have been done with a negative lookbehind, but Safari doesn't support that yet
      // newValue = newValue.replace(
      //   new RegExp(`(?<!">)(${get(token, 'name')})`, 'g'),
      //   '<span class="tokenized-input__token">$1</span>'
      // );
      const regex = new RegExp(`(">)?(${get(token, 'name')})`, 'g');
      let match;

      while ((match = regex.exec(newValue)) !== null) {
        if (!match[1]) {
          newValue = [
            newValue.substring(0, match.index),
            '<div class="tokenized-input__echo-highlight">',
            get(token, 'name'),
            '</div>',
            newValue.substring(regex.lastIndex),
          ].join('');
        }
      }
    });

    // Safe because token names only come from us, not users
    return htmlSafe(newValue);
  }),
  //endregion

  //region Observers
  refocusInputField: observer('isExpanded', function () {
    const { isExpanded } = this;
    if (isExpanded) {
      this.getInput().focus();
    }
  }),
  //endregion

  //region Methods
  insertTokenKey(tokenKey) {
    const textbox = this.getInput();
    const startSelPos = textbox.selectionStart;
    const endSelPos = textbox.selectionEnd;
    const newEndSelPos = startSelPos + tokenKey.length;

    textbox.selectionStart = newEndSelPos;
    textbox.selectionEnd = newEndSelPos;

    return textbox.value.substring(0, startSelPos) + tokenKey + textbox.value.substring(endSelPos);
  },

  replaceTokenNameWithTokenKey(value) {
    this.sortedTokens.forEach(token => {
      value = value.replace(new RegExp(escapeStringForRegExp(token.name), 'g'), `{{${token.key}}}`);
    });

    return value;
  },
  //endregion

  //region Events
  actions: {
    async setValueWithSelectMenu({ key, name }) {
      set(this, 'isExpanded', false);

      this.checkForDynamicTokenFallback({ key, name });
      this.setValue(this.insertTokenKey(`{{${key}}}`));
      this.getInput().focus();
    },

    setValueWithInputField({ target: { value } }) {
      this.setValue(this.replaceTokenNameWithTokenKey(value || ''));
    },

    setValueAfterFocusOut(value) {
      if (this.value !== value) {
        this.setValue(this.replaceTokenNameWithTokenKey(value || ''));
      }
    },
  },
  //endregion
});
