import type ThemeModel from 'secondstreet-common/models/theme';
import enums from 'ember-cli-ss-enums/services/enums';
import CurrentService from 'partner/services/current';

const promotionEntityTypeId = enums.findWhere('ENTITY_TYPE', {
  name: 'Promotion',
});

const organizationEntityTypeId = enums.findWhere('ENTITY_TYPE', {
  name: 'Organization',
});

export function findSuitableThemes(themes: ThemeModel[], current: CurrentService): ThemeModel[] {
  const promotionLevelThemes = themes.filter(
    theme => theme.ownerEntityTypeId === promotionEntityTypeId && theme.ownerEntityId === +current.promotion.id
  );

  if (promotionLevelThemes.length) {
    return promotionLevelThemes;
  }

  const orgLevelThemes = themes.filter(
    theme => theme.ownerEntityTypeId === organizationEntityTypeId && theme.ownerEntityId === +current.organization.id
  );

  if (orgLevelThemes.length) {
    return orgLevelThemes;
  }

  return themes;
}
