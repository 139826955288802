import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<html-preview class=\"iframe-wrapper {{if @fade 'iframe-wrapper--faded'}}\" ...attributes>\n  <iframe\n    id=\"html-preview\"\n    title=\"HTML Preview\"\n    sandbox={{this.sandbox}}\n    srcdoc={{this.srcdoc}}\n    style={{html-safe (concat \"height: \" this.height \"px\")}}\n    {{on \"load\" this.setup}}\n    {{did-update (perform this.updateTask) @html}}\n    {{will-destroy this.cleanup}}\n  >\n  </iframe>\n</html-preview>\n{{yield}}", {"contents":"<html-preview class=\"iframe-wrapper {{if @fade 'iframe-wrapper--faded'}}\" ...attributes>\n  <iframe\n    id=\"html-preview\"\n    title=\"HTML Preview\"\n    sandbox={{this.sandbox}}\n    srcdoc={{this.srcdoc}}\n    style={{html-safe (concat \"height: \" this.height \"px\")}}\n    {{on \"load\" this.setup}}\n    {{did-update (perform this.updateTask) @html}}\n    {{will-destroy this.cleanup}}\n  >\n  </iframe>\n</html-preview>\n{{yield}}","moduleName":"partner/components/html-preview.hbs","parseOptions":{"srcName":"partner/components/html-preview.hbs"}});
import { action } from '@ember/object';
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import duration from 'secondstreet-common/utils/duration';
import ENV from 'partner/config/environment';

interface HtmlPreviewSignature {
  Element: HTMLDivElement;
  Args: {
    html: string;
    sandbox?: string;
    promotionType?: string;
    isLegal?: boolean;
    fade?: boolean;
    onElementClick?: (data: { token: string; iteration: number; sectionIteration?: number }) => void;
  };
}

type IframeData =
  | { type: 'click'; value: { token: string; iteration: number; sectionIteration?: number } }
  | { type: 'resize'; value: { height: number } };

export default class HtmlPreviewComponent extends Component<HtmlPreviewSignature> {
  @tracked height = 480;

  srcdoc = getIframeSrcDocument({
    promotionType: this.args.promotionType,
    isLegal: this.args.isLegal,
  });
  private iframe?: HTMLIFrameElement;

  get sandbox() {
    return this.args.sandbox ?? ENV.environment == 'test' ? 'allow-scripts allow-same-origin' : 'allow-scripts';
  }

  @action
  setup(event: Event) {
    window.addEventListener('message', this.handleIframeMessage);
    this.iframe = event.target as HTMLIFrameElement;

    this.postMessage('html', this.args.html);
  }

  updateTask = task({ restartable: true }, async () => {
    await timeout(duration(150));

    this.postMessage('html', this.args.html);
  });

  @action
  cleanup() {
    window.removeEventListener('message', this.handleIframeMessage);
  }

  private handleIframeMessage = (event: MessageEvent) => {
    try {
      const data: IframeData = JSON.parse(event.data);

      switch (data.type) {
        case 'resize':
          this.height = data.value.height;
          break;

        case 'click':
          this.args.onElementClick?.(data.value);
          break;
      }
    } catch (e) {
      return;
    }
  };

  private postMessage(type: 'html', value: any = null) {
    if (!this.iframe) return;

    this.iframe.contentWindow?.postMessage(JSON.stringify({ type, value }), '*');
  }
}

function getIframeSrcDocument({ promotionType, isLegal }: { promotionType?: string; isLegal?: boolean }) {
  const html = String.raw;
  const styles = [];

  if (promotionType) styles.push(getStyleFor(`style-consumer-${dasherize(promotionType)}`));
  if (isLegal) styles.push(getStyleFor('style-partner-legal'));
  if (promotionType == 'VotingBracket') styles.push(getStyleFor('style-icons'));

  return html`
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="promotion-type" content="${promotionType}" />
        <title>Design Preview</title>

        ${styles.map(style => `<link rel="stylesheet" href="${style}" />`).join('\n')}
        <script src="https://unpkg.com/chroma-js@2.4.2/chroma.min.js"></script>
        <script src="https://unpkg.com/morphdom@2.7.0/dist/morphdom-umd.min.js"></script>
        <script src="https://cdn.tailwindcss.com"></script>
        <script>
          tailwind.config = {
            corePlugins: {
              preflight: false,
            },
          };
        </script>
        <link href="//fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

        <link
          href="//fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Aleo:ital,wght@0,100..900;1,100..900&family=Asap+Condensed:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=BioRhyme:wght@200..800&family=Bitter:ital,wght@0,100..900;1,100..900&family=Caladea:ital,wght@0,400;0,700;1,400;1,700&family=Caveat:wght@400..700&family=Chivo:ital,wght@0,100..900;1,100..900&family=Courgette&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Dancing+Script:wght@400..700&family=Enriqueta:wght@400;500;600;700&family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&family=Great+Vibes&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200..800&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oswald:wght@200..700&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Pacifico&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Proza+Libre:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rochester&family=Rokkitt:ital,wght@0,100..900;1,100..900&family=Sacramento&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
          rel="stylesheet"
        />
        <link href="//fonts.googleapis.com/css?family=Source Sans Pro" rel="stylesheet" />
        <link href="//fonts.googleapis.com/css?family=Source Serif Pro" rel="stylesheet" />
        <link href="//fonts.googleapis.com/css?family=Muli" rel="stylesheet" />

        <style>
          html {
            scrollbar-width: none;
          }

          body {
            margin: 0;
            padding: 0;
          }
        </style>
      </head>
      <body>
        <div id="preview-wrapper"></div>

        <script src="/assets/preview.js"></script>
      </body>
    </html>
  `;
}

function getStyleFor(name: string) {
  const meta = document.querySelector(`meta[name="${name}"]`);

  if (meta instanceof HTMLMetaElement) {
    return meta.content;
  }

  return '';
}
