const INTERVALS = {
  hours: 60 * 60 * 1000,
  days: 24 * 60 * 60 * 1000,
};

/**
 * Combines the given main and secondary categories,
 * filling up any missing data points with 0 values.
 *
 * @param {Object} data
 * @param {Array} data.mainCategories
 * @param {Array} data.secondaryCategories
 * @param {Boolean} data.aggregate - whether to aggregate the data or not
 * @param {('hours'|'days')} data.interval - the interval to use for the data
 *
 * @returns {Object} - the parsed data
 */
export function parseData({ mainCategories, secondaryCategories, aggregate = true, interval = 'hours' }) {
  const data = { main: [], secondary: [] };

  const mainCategoryValues = transformCategories(mainCategories);
  const secondaryCategoryValues = transformCategories(secondaryCategories);

  const keys = Object.keys(mainCategoryValues).sort();
  const date = new Date(keys[0]);
  const endDate = new Date(keys[keys.length - 1]);
  const transformDataCallback = aggregate ? generateAggregatedDataPoint : generateDataPoint;

  while (date <= endDate) {
    const currentKey = date.toISOString();

    data.main.push(transformDataCallback(data.main, date, mainCategoryValues[currentKey]));
    data.secondary.push(transformDataCallback(data.secondary, date, secondaryCategoryValues[currentKey]));

    date.setTime(date.getTime() + INTERVALS[interval]);
  }

  return data;
}

function transformCategories(categories) {
  return categories.reduce((acc, category) => {
    acc[category.time.toISOString()] = category.value;
    return acc;
  }, {});
}

function generateAggregatedDataPoint(data, date, value = 0) {
  return {
    time: new Date(date), // clone date to avoid mutating original
    value: value + (data[data.length - 1]?.value || 0),
  };
}

function generateDataPoint(data, date, value = 0) {
  return {
    time: new Date(date), // clone date to avoid mutating original
    value,
  };
}
