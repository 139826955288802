/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed, get, getProperties, set, setProperties } from '@ember/object';
import { alias, not } from '@ember/object/computed';
import { isEmpty, isPresent } from '@ember/utils';
import DS from 'ember-data';
import Copyable from 'partner/mixins/copyable-model';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

const genericProps = ['value', 'linkUrl', 'itemTitle'];
const itemImageProps = ['mediaItemId', 'altText', 'itemImageLinkUrl', 'itemImageExternalSrcUrl'];
const itemButtonProps = ['itemButtonLinkUrl', 'itemButtonText'];
const allModelProps = [...genericProps, ...itemImageProps, ...itemButtonProps];

/**
 * @typedef {DS.Model} MessageVersionTemplateTokenContent
 * @implements DesignTokenContentModel
 */
export default BaseModel.extend(Copyable, {
  //region Properties
  value: attr('string', { defaultValue: '' }),
  linkUrl: attr('string', { defaultValue: '' }),
  altText: attr('string', { defaultValue: '' }),
  mediaItemId: attr('number'),
  isDisabled: attr('boolean', { defaultValue: false }),
  itemTitle: attr('string', { defaultValue: '' }),
  itemImageExternalSrcUrl: attr('string', { defaultValue: '' }),
  itemImageLinkUrl: attr('string', { defaultValue: '' }),
  itemButtonLinkUrl: attr('string', { defaultValue: '' }),
  itemButtonText: attr('string', { defaultValue: '' }),
  displayOrder: attr('number'),
  itemPublishDate: attr('string'),
  messageVersionFeedId: attr('number'),
  //endregion

  //region Relationships
  messageVersion: belongsTo('message-version', { async: true }),
  messageVersionHistory: belongsTo('message-version-history', { async: true, inverse: null }),
  token: belongsTo('token', { async: false }),
  //endregion

  //region Computed Properties
  design: alias('messageVersion'),
  isIncomplete: computed('isPlaceholder', 'isDisabled', ...allModelProps, function () {
    const { isDisabled, isPlaceholder } = getProperties(this, 'isDisabled', 'isPlaceholder');
    const incomplete = !isDisabled && isPlaceholder;

    if (get(this, 'token.category') === 'Menu') {
      return incomplete || (isEmpty(this.linkUrl) && !isDisabled);
    }

    if (get(this, 'token.category') === 'Items') {
      const itemCategoryStatus = (itemCategoryProps, allOtherProps) =>
        itemCategoryProps.any(property => isPresent(get(this, property))) &&
        allOtherProps.every(property => isEmpty(get(this, property)));
      const allCategoriesAreDisabled = allModelProps.every(property => isEmpty(get(this, property)));
      const onlyImageEnabled = itemCategoryStatus(itemImageProps, [...genericProps, ...itemButtonProps]);
      const onlyButtonEnabled = itemCategoryStatus(itemButtonProps, [...genericProps, ...itemImageProps]);
      return !isDisabled && (allCategoriesAreDisabled || onlyImageEnabled || onlyButtonEnabled || isPlaceholder);
    }

    return incomplete;
  }),
  isComplete: not('isIncomplete'),
  isPlaceholder: computed(...allModelProps, 'token.{tokenContentType,placeholderTokenDefaultContent}', function () {
    // If there is a default value use it to determine if the value has changed
    const placeholderTokenDefaultContent = get(this, 'token.placeholderTokenDefaultContent');
    const tokenType = get(this, 'token.tokenContentType');
    const checkDefaults = propsArray =>
      propsArray.any(
        property =>
          typeof get(this, property) !== 'undefined' &&
          get(this, property) === get(placeholderTokenDefaultContent, property)
      );

    if (tokenType !== 'Color' && placeholderTokenDefaultContent) {
      return tokenType === 'Item'
        ? checkDefaults(allModelProps.reject(prop => prop === 'altText'))
        : checkDefaults(allModelProps);
    }
    return false;
  }),
  /**
   * Not to be confused with isPlaceholder, this simply checks to see if the token's value equals the default value that emails start out with, set
   * either by the user's organization or by Second Street's defaults.
   */
  isDefault: computed('value', 'token.tokenDefaultContent.value', function () {
    return this.value === get(this, 'token.tokenDefaultContent.value');
  }),
  /**
   * Whether or not this organization has a custom default that is different than what was set by Second Street
   * @type {Boolean}
   */
  hasCustomDefault: alias('token.hasCustomValue'),
  hasDefaultContent: computed(...allModelProps, 'token.placeholderTokenDefaultContent', function () {
    const tokenDefaultContent = get(this, 'token.placeholderTokenDefaultContent');
    const propsToCheck = allModelProps.reject(prop => prop === 'mediaItemId');
    return propsToCheck.every(prop => get(this, prop) === get(tokenDefaultContent, prop));
  }),
  tokenCategory: alias('token.category'),
  tokenId: alias('token.id'),
  //endregion

  //region Methods
  /**
   * Sets value/linkUrl/altText/mediaItem using Token.placeholderTokenDefaultContent or Token.tokenDefaultContent if it's found.
   * Token.tokenDefaultContent is Organization specific.
   * @param {Token} token
   */
  initializeValues(token, messageVersion) {
    const tokenDefaultContent = token.tokenDefaultContent || token.placeholderTokenDefaultContent;
    set(this, 'token', token);
    set(this, 'messageVersion', messageVersion);
    if (tokenDefaultContent) {
      setProperties(this, getProperties(tokenDefaultContent, ...allModelProps));
    }
    if (['tiktokProfileUrl', 'youtubeChannelUrl'].includes(this.token.key)) {
      set(this, 'isDisabled', true);
    }
  },
  //endregion
});
