import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import ImportFile from 'partner/models/import-file';
import MediaItem from 'partner/models/media-item';
import flattenDeep from 'lodash/flattenDeep';
import { prefixEndpoint } from 'secondstreet-common/utils/url';
import type SessionService from './-private/session';
import type Store from '@ember-data/store';
import type MediaItemModel from 'partner/models/media-item';

const getFileExtension = (fileName: string) => {
  if (fileName) {
    return fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
  }

  return null;
};

const getAcceptableImageFormats = () => ['.jpg', '.jpeg', '.gif', '.png'];

const getAcceptableVideoFormats = () => [
  '.3gp',
  '.asf',
  '.avi',
  '.divx',
  '.dv',
  '.flv',
  '.mkv',
  '.mov',
  '.mp4',
  '.mpg',
  '.mpeg',
  '.mpeg-ps',
  '.ts',
  '.mxf',
  '.ogg',
  '.ogv',
  '.wmv',
];

export default class UploaderService extends Service {
  @service declare session: SessionService;
  @service declare store: Store;

  getApiEndpoint(
    imagesOnly: boolean,
    videosOnly: boolean,
    paperEntries?: 'seededVotes' | 'nominationVotes' | 'nominations',
    isVotingBallot?: boolean,
    isNominationAndVotingBallot?: boolean
  ) {
    let apiEndpoint;

    if (paperEntries) {
      switch (paperEntries) {
        case 'seededVotes':
          apiEndpoint = 'ballot_seeded_external_votes_import_files';
          break;
        case 'nominationVotes':
          apiEndpoint = 'ballot_nomination_external_votes_import_files';
          break;
        case 'nominations':
          apiEndpoint = 'ballot_nomination_external_nominations_import_files';
          break;
      }
    } else {
      apiEndpoint = imagesOnly
        ? 'media_items'
        : videosOnly
        ? 'media_items?MediaType=3'
        : isVotingBallot
        ? 'ballot_seeded_import_files'
        : isNominationAndVotingBallot
        ? 'ballot_nomination_import_files'
        : 'audience_import_files';
    }
    return apiEndpoint;
  }

  async uploadFile(file: File, apiEndpoint?: string, queryParams?: string, options = {}) {
    const data = new FormData();
    data.append('file', file);

    if (!apiEndpoint) {
      apiEndpoint = this.getApiEndpoint(file.type.includes('image'), file.type.includes('video'));
    } else if (queryParams) {
      apiEndpoint = `${apiEndpoint}${queryParams}`;
    }

    try {
      const responseData = await this.session.request<{
        media_items?: { embedded_errors: any }[];
        import_files?: { embedded_errors: any }[];
      }>(prefixEndpoint(apiEndpoint) as string, {
        type: 'POST',
        contentType: false,
        processData: false,
        data,
        headers: (getOwner(this).lookup('adapter:application') as any).headers,
        ...options,
      });

      const [mediaItemJSON] = responseData.media_items || responseData.import_files || [];

      if (!mediaItemJSON) throw new Error('No media item returned');

      const mediaOnly = file.type.includes('image') || file.type.includes('video');
      const errors = mediaItemJSON.embedded_errors;
      const jsonApiDocument = (getOwner(this).lookup('serializer:application') as any).normalize(
        mediaOnly ? MediaItem : ImportFile,
        mediaItemJSON
      );
      jsonApiDocument.data.type = mediaOnly ? 'media-item' : 'import-file';

      const responseItem = this.store.push(jsonApiDocument) as MediaItemModel;

      return {
        responseItem,
        errors,
      };
    } catch (e: any) {
      return {
        hasErrors: true,
        errors: (e.payload?.errors as { key: string; message: string }[]) || e,
      };
    }
  }

  getValidFileTypes(accept: string) {
    switch (accept) {
      case 'image/*':
        return getAcceptableImageFormats().join(',');
      case 'video/mp4,video/*':
      case 'video/*':
        return getAcceptableVideoFormats().join(',');
      default:
        return accept;
    }
  }

  isValidFile(file: File, accept: string) {
    const fileExtension = getFileExtension(file.name);

    const acceptedItems = flattenDeep(
      accept.split(',').map(acceptedItem => {
        switch (acceptedItem.trim()) {
          case 'image/*':
            return getAcceptableImageFormats();
          case 'video/*':
            return getAcceptableVideoFormats();
          case 'video/mp4':
            return ['.mp4'];
          case 'application/vnd.ms-excel':
            return ['.xls'];
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return ['.xlsx'];
          default:
            return [acceptedItem];
        }
      })
    );

    return fileExtension && acceptedItems.includes(fileExtension);
  }
}
