import type Store from '@ember-data/store';
import Controller from '@ember/controller';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type { SetupQuizQuestionsIndexModel } from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/quiz/questions/index';

export default class SetupQuizQuestionsIndexController extends Controller {
  @service declare store: Store;

  declare model: SetupQuizQuestionsIndexModel;

  queryParams: readonly ['ai'] = ['ai'];
  @tracked ai = false;

  handleQuizGenerationTask = task({ drop: true }, async () => {
    this.ai = false;

    const [questions, outcomes] = await Promise.all([
      this.store.query('question', {}),
      this.store.query('outcome', {}),
    ]);

    set(this.model, 'questions', questions.toArray());
    set(this.model, 'outcomes', outcomes.toArray());
  });
}
