/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import PermittedComponent from 'partner/mixins/permitted-component';

/**
 * Simple Dialog that provides user with set of options.
 * @see http://www.google.com/design/spec/components/dialogs.html#dialogs-simple-menus
 */
export default Component.extend(PermittedComponent, {
  //region Ember Hooks
  attributeBindings: ['disabled'],
  classNameBindings: ['disabled:ssDisabled'],
  didRender() {
    this._super(...arguments);
    if (this['scroll-to-element']) {
      const modalParent = this.element.querySelector('.ssModalContent');
      const scrollToElement = this.element.querySelector(`${this['scroll-to-element-name']}`);
      if (modalParent?.scrollTop !== undefined && scrollToElement) {
        // if the element is out of view in the modal, scroll enough to see it
        if (scrollToElement.offsetTop > modalParent.offsetHeight) {
          // adds 10 to give element some extra space above the bottom
          modalParent.scrollTo(modalParent.scrollTop + (scrollToElement.offsetTop - modalParent.offsetHeight) + 10);
        }
      }
    }
  },
  //endregion

  //region Properties
  optionsVisible: false,
  tagName: 'simple-dialog',
  classNames: ['simpleDialog'],
  'two-way': true,
  'scroll-to-element': false,
  'scroll-to-element-name': '.option.selected',
  //endregion

  //region Computed Properties
  allowSelection: computed('content.length', 'disabled', function () {
    return get(this, 'content.length') > 1 && !this.disabled;
  }),
  //endregion

  //region Actions
  actions: {
    setSelectedOperator(operatorType) {
      this['item-selected-action'](operatorType);
    },
    toggleDialog() {
      if (!this.actionsDisabled) {
        if (!this.optionsVisible) {
          set(this, 'optionsVisible', true);
        } else if (!this.isDestroyed) {
          set(this, 'optionsVisible', false);
        }
      }
    },
  },
  //endregion
});
