import { SyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import BaseModel from 'secondstreet-common/models/base';
import type MediaItemModel from './media-item';
import type PromotionModel from './promotion';
import type QuestionOptionModel from './question-option';

export default class QuestionModel extends BaseModel {
  @attr('string', { defaultValue: '' }) declare name: string;
  @attr('number') declare displayOrder: number;
  @attr('number') declare statusTypeId: number;
  @attr('number') declare gameId: number;

  @belongsTo('media-item', { async: false }) declare mediaItem: MediaItemModel;
  @belongsTo('promotion', { async: false }) declare promotion: PromotionModel;
  @hasMany('question-option', { async: false }) declare questionOptions: SyncHasMany<QuestionOptionModel>;

  get isNewQuestionNoQuestionOptions() {
    // TODO: Ember Data 3.28+... or so I thought...
    // eslint-disable-next-line ember/no-get
    return get(this, 'isNew') && this.questionOptions.length < 1;
  }

  get hasPollBestPracticeQuestionOptionsCount() {
    return this.questionOptions.length >= 2;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    question: QuestionModel;
  }
}
