/* global Redactor */

/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
/* eslint-disable ember/use-brace-expansion, ember/no-side-effects */ // FIXME
import { computed, get, getProperties, set } from '@ember/object';
import { alias, not } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import { insertIf } from 'secondstreet-common/utils/functional';

const DASHBOARD_DATA_TOKEN_KEYS = [
  'Dashboard.OptIns',
  'Dashboard.Age',
  'Dashboard.Gender',
  'Dashboard.PostalCode',
  'Dashboard.CustomFormFields',
];

/**
 * @param {TokenContent[]} tokenContents
 * @param {Token[]} tokens
 * @param {Number} itemLimit
 * @param {(tokenContent: TokenContent) => number} position
 * @returns {{contentGroups: *[], hiddenContentGroups: Array}}
 */
const calculateContentGroups = (tokenContents, tokens, itemLimit, position = () => 0) => {
  const contentGroups = tokens
    .reject(token => DASHBOARD_DATA_TOKEN_KEYS.includes(token.key))
    .map(token => tokenContents.filterBy('token', token).sortBy('displayOrder').sortBy('messageVersionFeedId'))
    .reject(foundContents => isEmpty(foundContents))
    .sort((a, b) => position(a[0]) - position(b[0]));

  //checks for new item (not legacy item, which has more than one content group) and adjusts if there is a template item limit
  const isMaxAllowedItemsCategory =
    get(tokens, 'firstObject.tokenContentType') === 'Item' && contentGroups.length === 1 && itemLimit;
  const [allArticles] = contentGroups;

  return isMaxAllowedItemsCategory
    ? {
        contentGroups: [allArticles.slice(0, itemLimit)],
        hiddenContentGroups: allArticles.slice(itemLimit),
      }
    : { contentGroups, hiddenContentGroups: [] };
};

const isAllTokenContentsDisabled = tokenContents => tokenContents.isEvery('isDisabled');

const calculateDashboardSubCategories = (tokenContents, social, categoricalCharts) => {
  const validSourceTypeChartIds = categoricalCharts.mapBy('sourceTypeChartId');
  const socialTokenContents = social.filter(
    tc => tc.sourceTypeChartId && validSourceTypeChartIds.includes(tc.sourceTypeChartId)
  );
  const optinTokenContents = tokenContents
    .filter(tc => ['Dashboard.OptIns'].includes(tc.token.key))
    .filter(tc => tc.sourceTypeChartId && validSourceTypeChartIds.includes(tc.sourceTypeChartId));
  const customFormFieldTokenContents = tokenContents
    .filter(tc => ['Dashboard.CustomFormFields'].includes(tc.token.key))
    .reject(tc => socialTokenContents.mapBy('id').includes(tc.id))
    .filter(tc => tc.sourceTypeChartId && validSourceTypeChartIds.includes(tc.sourceTypeChartId));
  const demographicTokenContents = tokenContents
    .filter(tc => ['Dashboard.Age', 'Dashboard.Gender', 'Dashboard.PostalCode'].includes(tc.token.key))
    .filter(tc => tc.sourceTypeChartId && validSourceTypeChartIds.includes(tc.sourceTypeChartId));

  return [
    ...insertIf(socialTokenContents.length, {
      name: 'Social Fields',
      isSubcategory: true,
      isValid: true,
      tokenContents: socialTokenContents,
      token: {
        name: 'Social Fields',
        tokenContentTypeEditorComponent: 'token-content-editor-for-social-fields',
      },
      isDisabled: isAllTokenContentsDisabled(socialTokenContents),
    }),
    ...insertIf(optinTokenContents.length, {
      name: 'Opt-ins',
      isSubcategory: true,
      isValid: true,
      tokenContents: optinTokenContents,
      token: {
        name: 'Opt-ins',
        tokenContentTypeEditorComponent: 'token-content-editor-for-opt-ins',
      },
      isDisabled: isAllTokenContentsDisabled(optinTokenContents),
    }),
    ...insertIf(customFormFieldTokenContents.length, {
      name: 'Custom Form Fields',
      isSubcategory: true,
      isValid: true,
      tokenContents: customFormFieldTokenContents,
      token: {
        name: 'Custom Form Fields',
        tokenContentTypeEditorComponent: 'token-content-editor-for-custom-form-fields',
      },
      isDisabled: isAllTokenContentsDisabled(customFormFieldTokenContents),
    }),
    ...insertIf(demographicTokenContents.length, {
      name: 'Demographics',
      isSubcategory: true,
      isValid: true,
      tokenContents: [
        demographicTokenContents.findBy('token.name', 'Gender'),
        demographicTokenContents.findBy('token.name', 'Age'),
        demographicTokenContents.findBy('token.name', 'Postal Code'),
      ].compact(), // we need this to match the order for the dashboards
      token: {
        name: 'Demographics',
        tokenContentTypeEditorComponent: 'token-content-editor-for-demographics',
      },
      isDisabled: isAllTokenContentsDisabled(demographicTokenContents),
    }),
  ];
};

const DEFAULT_SUBJECT = 'Subject Line Text';
const DEFAULT_PREHEADER =
  'Preheader Text - Preheader Text - Preheader Text - Preheader Text - Preheader Text - Preheader Text - Preheader Text - Preheader Text - Preheader Text - Preheader Text';

export default Mixin.create({
  //region Ember Dependencies
  enums: service(),
  features: service(),
  classNames: ['template-designer'],
  classNameBindings: [
    'tokenFlyoutIsOpen:template-designer--token-flyout-open',
    'tokenCategoriesFlyoutIsOpen:template-designer--categories-flyout-open',
  ],
  //endregion

  //region Attributes
  /**
   * @property {Object}
   */
  design: null,
  /**
   * @property {Array}
   */
  designs: null,
  /**
   * @property {Boolean}
   */
  'is-anything-saving': null,
  /**
   * @property {Object}
   */
  dipsUrl: null,

  applyTokenFlyout: false,
  /**
   * @property {Function}
   */
  save() {},
  //endregion

  //region Properties
  activeTokenContent: null,
  customTokenContent: null,
  openCategory: null,
  isAddImageFlyoutOpen: false,
  isAddingImageFromLibrary: false,
  //endregion

  //region Computed Properties
  /**
   * @property {Ember.ComputedProperty<Boolean>}
   */
  tokenFlyoutIsOpen: computed('activeTokenContent', 'customTokenContent', 'applyTokenFlyout', function () {
    return isPresent(this.activeTokenContent) || isPresent(this.customTokenContent) || this.applyTokenFlyout;
  }),
  /**
   * @property {Ember.ComputedProperty<Boolean>}
   */
  tokenCategoriesFlyoutIsOpen: not('tokenFlyoutIsOpen'),
  /**
   * 1-based index
   * @property Ember.ComputedProperty<Number|null>
   */
  activeTokenContentIndex: computed('tokenCategories.[]', 'activeTokenContent.token.allowMultiple', function () {
    const { activeTokenContent } = this;
    if (!activeTokenContent || !get(activeTokenContent, 'token.allowMultiple')) {
      return null;
    }

    const { tokenCategories } = this;
    // Old style for loops for performance
    for (const category of tokenCategories) {
      for (const contentGroup of category.contentGroups) {
        for (const content of contentGroup) {
          if (content === activeTokenContent) {
            return contentGroup.indexOf(content) + 1;
          }
        }
      }
    }
    return null;
  }),
  tokenCategories: computed(
    'design.template.{maxItemsAllowed,templateContent,tokens.@each.token}',
    'design.tokenContents.@each.{isComplete,hasDirtyAttributes,isValid,sourceTypeChartId}',
    'categoricalCharts.@each.sourceTypeChartId',
    function () {
      const tokens = (get(this, 'design.template.tokens') || []).toArray();
      const templateContent = get(this, 'design.template.templateContent');
      const tokenContents = get(this, 'design.tokenContents');
      const position = tokenContent => templateContent.indexOf(get(tokenContent, 'token.key'));

      const result = values(groupBy(tokens, x => x.category))
        .map(tokens => {
          const [{ category }] = tokens;
          const tokenIds = tokens.map(token => token.id);
          const itemLimit = get(this, 'design.template.maxItemsAllowed');
          const subcategories =
            category === 'Dashboard Data'
              ? calculateDashboardSubCategories(tokenContents, this.socialShareTokenContents, this.categoricalCharts)
              : [];

          const { contentGroups, hiddenContentGroups } = calculateContentGroups(
            tokenContents,
            tokens,
            itemLimit,
            position
          );

          /**
           * @typedef {Object} TokenCategory
           * @property {String} name - User-visible name of the category
           * @property {String} icon - The dasherized icon name to show for the category
           * @property {Boolean} isSortable - Whether every token in the category is sortable
           * @property {Boolean} isComplete - Keeps track of whether or not the category has been completed
           * @property {Boolean} hasDirtyAttributes - Keeps track of whether or not any of the category's contents are dirty
           * @property {String} category - Database-stored name of the category
           * @property {DesignTokenContentModel[][]} contentGroups
           * @property {Array} hiddenContentGroups - array of template items that are hidden (e.g., due to template limitation)
           */
          return {
            name: this.enums.findWhere('TOKEN_CATEGORIES', { category }, 'displayName') || category,
            icon: this.enums.findWhere('TOKEN_CATEGORIES', { category }, 'icon') || 'certificate',
            contentGroups,
            category,
            isSortable: contentGroups.every(g => g.every(x => get(x, 'token.isSortable'))),
            isComplete: contentGroups.every(g => g.every(x => x.isComplete)),
            hasDirtyAttributes: contentGroups.some(g => g.some(x => x.hasDirtyAttributes)),
            isInvalid: contentGroups.some(g => g.some(x => !x.isDisabled && !x.isValid)),
            tokenIds,
            hiddenContentGroups,
            subcategories,
          };
        })
        .sort((a, b) => {
          const averagePosition = x => {
            const { contentGroups } = x;
            const total = contentGroups.reduce(
              (pValue, group) => pValue + group.reduce((pValue, content) => pValue + position(content), 0),
              0
            );
            const length = contentGroups.reduce((pValue, group) => pValue + group.length, 0);
            return total / length;
          };
          return averagePosition(a) - averagePosition(b);
        });
      if (result?.firstObject?.category == 'Editable Text Areas') {
        const refactoredResult = result?.firstObject?.contentGroups?.sort((a, b) =>
          String(a.firstObject.token.name).localeCompare(String(b.firstObject.token.name))
        );
        result.firstObject.contentGroups = refactoredResult;
      }
      return result;
    }
  ),
  allowDisable: computed(
    'audience.entityId',
    'activeTokenContent.{token.tokenContentType,token.allowDisable,fieldId,rssTokenName}',
    function () {
      if (get(this, 'activeTokenContent.token.tokenContentType') === 'Field') {
        return this.activeTokenContent.fieldId !== parseInt(this.audience.entityId, 10);
      }
      return get(this, 'activeTokenContent.token.allowDisable') || this.activeTokenContent.rssTokenName;
    }
  ),
  tokenContents: alias('design.tokenContents'),
  //endregion

  //region Methods
  clearActiveToken() {
    set(this, 'activeTokenContent', null);
    set(this, 'customTokenContent', null);
  },
  findCategoryByToken(token) {
    return this.tokenCategories.filter(category => get(category, 'tokenIds').includes(get(token, 'token.id')))[0];
  },
  clearSubjectAndPreheader() {
    set(this, 'design.subject', DEFAULT_SUBJECT);
    set(this, 'preheader-token-content.value', DEFAULT_PREHEADER);
  },
  checkForSingleToken(category, checkForDisabled = false) {
    //sales sheet categories use different models and have a simpler data structure
    if (this.isSalesSheetDesigner) {
      return category.elements.length === 1;
    }
    if (this.design.isDashboardShare) {
      return false;
    }

    const representativeTemplateToken = get(category, 'contentGroups.firstObject.firstObject');
    //safe-guard against empty categories, e.g. RSS Feeds when a message campaign is first created
    if (!representativeTemplateToken) {
      return false;
    }

    const containsOneContentGroup = category.contentGroups.length === 1;
    const containsOneRepeatableToken = category.contentGroups.firstObject.length === 1;
    const containsSingleToken = containsOneContentGroup && containsOneRepeatableToken;
    const hasRequestedStatus = checkForDisabled
      ? representativeTemplateToken.isDisabled
      : !representativeTemplateToken.isDisabled;
    const isMessageVersionFeed = representativeTemplateToken.rssTokenName;
    const notRepeatableToken = !get(representativeTemplateToken, 'token.allowMultiple') && !isMessageVersionFeed;
    const isRepeatableItem = get(representativeTemplateToken, 'token.tokenContentType') === 'Item';
    const hasRestrictedItems = isRepeatableItem && get(this, 'design.template.maxItemsAllowed') === 1;
    const hasRestrictedFeed = isMessageVersionFeed && !get(this, 'features.hasAdvancedNewsletterRSSOptions');
    const canSingleTokenBeDisabled = !(
      containsOneContentGroup && representativeTemplateToken?.designToken?.allowDisable
    );
    return (
      containsSingleToken &&
      canSingleTokenBeDisabled &&
      hasRequestedStatus &&
      (notRepeatableToken || hasRestrictedItems || hasRestrictedFeed)
    );
  },
  lastTokenContent(category) {
    const relevantCategory = this.tokenCategories.findBy('category', category);
    return relevantCategory ? relevantCategory.contentGroups.firstObject.lastObject : null;
  },
  getTokenName(tokenContent, index) {
    if (get(tokenContent, 'token')) {
      const { fieldId, itemTitle, isComplete, isDisabled, token } = getProperties(
        tokenContent,
        'fieldId',
        'itemTitle',
        'isComplete',
        'isDisabled',
        'token'
      );
      const { name, allowMultiple, tokenContentType } = getProperties(
        token,
        'name',
        'allowMultiple',
        'tokenContentType'
      );

      if (allowMultiple) {
        if (tokenContentType === 'Field' && fieldId) {
          const relevantAudience = this['subscription-audiences'].find(a => parseInt(a.entityId, 10) === fieldId);
          return relevantAudience ? relevantAudience.name : this.audience.name;
        }
        if (tokenContentType === 'Item' && itemTitle && isComplete && !isDisabled) {
          return itemTitle;
        }
        return `${name} ${index + 1}`;
      }

      return name;
    }

    if (tokenContent.rssTokenName) {
      if (get(this, 'features.hasAdvancedNewsletterRSSOptions')) {
        return `RSS Feed ${index + 1}`;
      }
      return 'Articles';
    }
  },
  //endregion

  //region Actions
  actions: {
    /**
     * Given a token, finds the token category it belongs to
     * @param token
     */
    findCategoryByToken(token) {
      return this.findCategoryByToken(token);
    },
    /**
     * @param {Object} tokenCategory
     */
    toggleCategory(tokenCategory) {
      //the Articles category is empty when a message campaign is created
      //for orgs that can only have one feed, we need to create a feed when they click on the category so that the flyout can be opened immediately
      const createFeedOnToggle =
        tokenCategory.category === 'Articles' &&
        !get(this, 'features.hasAdvancedNewsletterRSSOptions') &&
        !tokenCategory.contentGroups.firstObject.length;

      if (createFeedOnToggle) {
        this.send('createNewMessageVersionFeed');
      }

      if (this.openCategory === tokenCategory.category) {
        set(this, 'openCategory', null);
      } else if (this.checkForSingleToken(tokenCategory)) {
        this.send('editTokenContent', tokenCategory.contentGroups.firstObject.firstObject);
      } else {
        set(this, 'openCategory', tokenCategory.category);
      }

      if (this.isWidgetDesigner) {
        this.updateBody();
      }
    },
    toggleIsDisabled(content, category) {
      if (content.isSubcategory) {
        content.tokenContents.forEach(rtc => (rtc.isDisabled = !content.isDisabled));
      } else {
        content.toggleProperty('isDisabled');
      }
      const displayedItems = this.tokenCategories.filterBy('category', 'Items').contentGroups || [];
      if (content.isDisabled && flatten(displayedItems).indexOf(content) === 0) {
        this.clearSubjectAndPreheader();
      }
      if (content.isDisabled && (this.activeTokenContent || this.customTokenContent)) {
        if (this.checkForSingleToken(category, true)) {
          set(this, 'openCategory', null);
        }
        this.clearActiveToken();
      }

      if (content.constructor.modelName === 'message-version-feed' && this.messageVersionFeeds.isEvery('isDisabled')) {
        this.unconfirmMessageCampaign();
      }

      if (this.design && !this.design.isDashboardShare) {
        this.updateBody();
      }
      this.send('save');
    },
    /**
     * When design elements are clicked in the preview, we want to open that token in the fly out.
     * @param {Object | string} data
     */
    previewClicked(data) {
      this.send('save');

      if (data == 'SubjectAndPreheader' || data == 'From') {
        if (!this.checkForSingleToken(this.customTokenCategories.findBy('category', 'FromAndSubject'))) {
          set(this, 'openCategory', 'FromAndSubject');
        }
        this.send('editTokenContent', data);
      } else if (data.token) {
        this.send('editTokenKey', data.token, data.iteration, data.sectionIteration);
      }
    },

    /**
     * Find token content for clicked element
     * @param {String} tokenKey
     * @param {?Number} [i]
     * @param sectionIteration
     */
    editTokenKey(tokenKey, i = null, sectionIteration = null) {
      if (i === null) {
        return null;
      }

      if (tokenKey === 'signupForm') {
        this.send('editTokenContent', 'WidgetForm');
        return null;
      }

      let foundToken = get(this, 'design.tokenContents').filter(
        tokenContent => tokenKey === get(tokenContent, 'token.key') && !tokenContent.isDisabled
      );
      const messageVersionFeedIds = foundToken
        .uniqBy('messageVersionFeedId')
        .sortBy('messageVersionFeedId')
        .mapBy('messageVersionFeedId');
      if (sectionIteration > 0 && messageVersionFeedIds.length > 1) {
        const tokenMessageVersionFeedId = messageVersionFeedIds[sectionIteration];
        const indexOfFeedId = messageVersionFeedIds.indexOf(tokenMessageVersionFeedId);
        if (indexOfFeedId >= 1) {
          messageVersionFeedIds.slice(0, indexOfFeedId).forEach(feedId => {
            i += get(this, 'design.tokenContents').filterBy('messageVersionFeedId', feedId).length;
          });
        }
      }
      foundToken = foundToken.sortBy('displayOrder').objectAt(i);
      let relevantCategory;

      if (isPresent(foundToken)) {
        relevantCategory = this.findCategoryByToken(foundToken);
      } else {
        // Now check for an RSS match
        const feeds = this.enabledMessageVersionFeeds;
        if (feeds) {
          foundToken = feeds.rejectBy('isDisabled').objectAt(sectionIteration);
          relevantCategory = this.customTokenCategories.findBy('category', 'Articles');
        }
      }

      if (!this.checkForSingleToken(relevantCategory)) {
        set(this, 'openCategory', relevantCategory.category);
      }

      this.send('editTokenContent', foundToken);
    },
    /**
     * @param {DesignTokenContentModel|String} tokenContent
     */
    editTokenContent(tokenContent) {
      if (
        [
          'Dashboard.EntryCount',
          'Dashboard.PeopleCount',
          'Dashboard.VoteCount',
          'Dashboard.NominationCount',
          'Dashboard.QuizSubmissionCount',
          'Dashboard.PollSubmissionCount',
          'Dashboard.EventSignupCount',
          'Dashboard.SurveyResponseCount',
          'Promotion.Dates',
        ].includes(tokenContent.token?.key)
      ) {
        return;
      }

      if (!tokenContent.isDisabled) {
        if (get(tokenContent, 'token') || tokenContent.isSubcategory) {
          set(this, 'activeTokenContent', tokenContent);
          set(this, 'customTokenContent', null);
        } else {
          set(this, 'activeTokenContent', null);
          set(this, 'customTokenContent', tokenContent);
        }

        Redactor.instances.forEach(redactor => {
          // Only set the content of the instance that is currently visible
          if (redactor.editor.getRect().height > 0) {
            redactor.editor.setContent({ html: this.activeTokenContent.value });
            redactor.codemirror.cm.setValue(this.activeTokenContent.value);
          }
        });
      }

      if (tokenContent === 'EmbedCode' || tokenContent === 'ShortCode') {
        set(this, 'design.hasGrabbedCode', true);
      }
    },
    clearActiveTokenContent(tokenCategory) {
      if (tokenCategory && this.checkForSingleToken(tokenCategory)) {
        set(this, 'openCategory', null);
      }

      if (this.isAddImageFlyoutOpen) {
        set(this, 'isAddImageFlyoutOpen', false);
      }

      this.clearActiveToken();

      if (this.isWidgetDesigner) {
        this.updateBody();
      }

      this.send('save');
    },
    createTokenContent(token, category) {
      //will handle all token groups that use the repeatable Item token content type
      const previousTokenContent = category.isSortable ? this.lastTokenContent(category.category) : null;
      const tokenContent = this.addNewTokenContent(token, this.design, previousTokenContent);
      this.updateBody();
      this.send('editTokenContent', tokenContent);
    },
    tokenName(tokenContent, index) {
      return tokenContent.isSubcategory ? tokenContent.name : this.getTokenName(tokenContent, index);
    },
    allowDisable(tokenContent) {
      if (this.editingDisabled) {
        return false;
      }

      if (tokenContent.isSubcategory) {
        return true;
      }

      if (get(tokenContent, 'token.tokenContentType') === 'Field') {
        return tokenContent.fieldId !== parseInt(this.audience.entityId, 10);
      }
      return get(tokenContent, 'token.allowDisable') || tokenContent.rssTokenName;
    },
    addToken(category) {
      if (this.editingDisabled) {
        return false;
      }

      if (get(category, 'contentGroups.firstObject.firstObject.token')) {
        const tokenType = get(category, 'contentGroups.firstObject.firstObject.token.tokenContentType');

        if (tokenType === 'Field') {
          return this.availableSubscriptionAudiences.length;
        }

        if (tokenType === 'Item' && category.category === 'Items') {
          if (category.contentGroups.firstObject.isAny('isComplete')) {
            if (get(this, 'design.template.maxItemsAllowed')) {
              const itemContentGroups = this.tokenCategories.findBy('category', 'Items').contentGroups;
              return itemContentGroups.firstObject.length < get(this, 'design.template.maxItemsAllowed');
            }
            return true;
          }
          return false;
        }

        return get(category, 'contentGroups.firstObject.firstObject.token.allowMultiple');
      }

      if (category.category === 'Articles') {
        return get(this, 'features.hasAdvancedNewsletterRSSOptions');
      }
    },
    updateTokenContentProperty(property, value) {
      set(this, `activeTokenContent.${property}`, value);

      if (!this.design.isDashboardShare) {
        this.updateBody();
      }
    },
  },
  //endregion
});
