import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class BallotMatchupSummaryModel extends BaseModel {
  @attr('number') declare approvedCount: number;
  @attr('number') declare displayOrder: number;
  @attr('number') declare groupDisplayOrder: number;
  @attr('number', { defaultValue: -1 }) declare groupId: number;
  @attr('string') declare groupName: string;
  @attr('boolean') declare isWinnersPublished: boolean;
  @attr('string') declare name: string;
  @attr('number') declare pendingCount: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ballot-matchup-summary': BallotMatchupSummaryModel;
  }
}
