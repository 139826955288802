import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * Matchup Route
 * @type {Ember.Route}
 * @mixes Promotion.AuthenticatedRoute
 */
export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionMatchupsMatchupRoute extends Route {
  @service('store') store;

  model(params) {
    return this.store.find('matchup', params.matchup_id);
  }
}
