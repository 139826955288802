import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { hasDataProduct as hasDataProductUtil } from 'partner/utils/calculate';
import CurrentService from 'partner/services/current';

export default class HasDataProductHelper extends Helper {
  @service declare current: CurrentService;

  compute([organizationSsProducts]: [any]) {
    return hasDataProductUtil(organizationSsProducts || this.current.organization.organizationSsProducts);
  }
}
