export default `
  <div class="flex flex-col col-span-2 sm:last:odd:col-[2_/_span_2] sm:max-w-[427px] border border-solid border-gray-400 rounded">
    <div class="individual-entry-view grow min-h-20">
      <div class="material-list-tile entrant-list-item justify-between h-full !mx-4">
        <div class="flex-1 whitespace-nowrap w-[calc(100%-170px)] cursor-pointer">
          <div class="entrant-name block ml-1 text-lg text-ellipsis overflow-hidden">Entrant Name</div>
          <div class="entrant-details ml-2.5">
            <div class="address flex">
              <span class="material-icons contact-info-icon location text-base text-gray-500 relative top-0 mr-1">
                place
              </span>
              <span class="address-text block text-gray-500 text-ellipsis overflow-hidden">123 Example Road</span>
            </div>
          </div>
        </div>
        <div class="entrant-actions flex items-end ml-2 mb-2">
          <div class="entrant-action-buttons">
            <div class="entrant-action">
              <button class="voting-button ssButton ssButtonPrimary hover:brightness-110 vote-button vote">
                Vote
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
`;
