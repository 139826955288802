export default ({ message, name, eventDate, address, description }) => `
<div class="ssTile style="font-family: 'Open Sans'"">
  <div class="extra-chances extra-chances--primary template-color-primary-background-color">
    <div class="extra-chances__column extra-chances__column--primary-row extra-chances__column--full-width">
      <div class="extra-chances__title extra-chances__title--primary extra--chances__entry-received">Your sign up has been received!</div>
    </div>
  </div>
  <div class="extra-chances extra-chances--secondary">
    <div class="extra-chances__column extra-chances__column--secondary-row extra-chances__column--full-width extra-chances__custom-thanks-message">
      ${message}
    </div>
    <div class="extra-chances__column extra-chances__column--secondary-row extra-chances__column--full-width extra-chances__custom-thanks-message">
      <button class="!mt-5 ssButton ssButtonFlat !text-gray-500 !px-0 !mb-0 !w-auto hover:!bg-transparent">Log out</button>
    </div>
  </div>
<div class="event-calendar-card" data-token="thankYouMessage">
  <div class="event-calendar-card__header">${name}</div>
  <div class="event-calendar-card__subtitle">${eventDate}</div>
  <div class="event-calendar-card__subtitle">${address}</div>
  <div class="event-calendar-card__description">${description}</div>
</div>
<div class="event-calendar-card__calendars" data-token="thankYouMessage">
  <a class="event-calendar-card__calendar-button" href="#">Download Invite</a>
  <a class="event-calendar-card__calendar-button" href="#">Add to Google Calendar</a>
</div>
</div>
`;
