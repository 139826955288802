/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, and } from '@ember/object/computed';
import PermittedComponent from 'partner/mixins/permitted-component';

export default Component.extend(PermittedComponent, {
  tagName: 'simple-menu-trigger',
  classNameBindings: [':simple-menu-trigger', 'isActive'],
  attributeBindings: ['data-test'],
  menu: alias('parentView'),
  isActive: alias('menu.isExpanded'),

  click() {
    if (!this.disabled) {
      this.menu.toggleMenu();
    }

    return false;
  },

  //region Computed Properties
  disabled: computed('administer', 'permitViewOnly', {
    get() {
      return (!this.administer && !this.permitViewOnly) || this._disabled;
    },

    set(_key, value) {
      return (this._disabled = value);
    },
  }),
  permitViewOnly: and('menu.permit-view-only', 'view'),
  //endregion
});
