/* eslint-disable ember/no-mixins, ember/no-computed-properties-in-native-classes */
import { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import enums from 'ember-cli-ss-enums/services/enums';
import Copyable from 'partner/mixins/copyable-model';
import InheritableModel from 'partner/-base/inheritable-model';
import PromotionModel from './promotion';

const ModelWithMixins = InheritableModel.extend(Copyable) as unknown as typeof InheritableModel;

export default class SettingModel extends ModelWithMixins {
  @attr('number') declare targetEntityTypeId: number; // The id of the type of endpoint this setting belongs to. e.g. "prizes" or "form_field".
  @attr('number') declare targetEntityId: number; // The ID of what this setting belongs to, unique amongst entities from that endpoint.
  @attr('number') declare ownerEntityTypeId: number; // Keep in mind that Organization or Promotion level settings won't have a Target Entity.
  @attr('number') declare ownerEntityId: number; // That is only used for Settings that are meta data on Entities (like Matchup Name, Game Group Name, etc.).
  @attr('number') declare browserPlatformTypeId: number;
  @attr('number') declare domainId: number;
  @attr('number') declare promotionTypeId: number;
  @attr('number') declare valueDataTypeId: number;
  @attr('number') declare languageId: number;
  @attr('string') declare key: string; // The model/property it ties to. e.g. "Prize_Name"
  @attr() declare value: any; // The actual value it holds. e.g. "One Hundred Dollars"
  @attr('boolean') declare isInherited: boolean;

  @belongsTo('organization', { async: false }) declare organization: any;
  @belongsTo('organization-promotion', { async: false }) declare organizationPromotion: any;
  @belongsTo('promotion', { async: false }) declare promotion: PromotionModel;

  @enums.computed('code', 'languageId') declare languageCode: string;
  @enums.computed('name', 'languageId') declare languageName: string;

  @computed('isInherited')
  get status() {
    return this.isInherited ? 'incomplete' : 'bestPractice'; // 'complete' is always 'bestPractice' here.
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    setting: SettingModel;
  }
}
