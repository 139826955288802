import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from 'partner/serializers/application';

export default class VotingEntrySerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    nominationEntriesSummaries: { serialize: 'ids' },
    entryLocations: { serialize: false, deserialize: 'records' },
  };

  normalize(modelClass: any, resourceHash: any) {
    const resourceHashWithDefaults = {
      master_entry_id: null,
      facebook_url: null,
      full_address: null,
      instagram_handle: null,
      linkedin_profile_url: null,
      location_id: null,
      media_item_id: null,
      logo_media_item_id: null,
      phone: null,
      snapchat_handle: null,
      source_media_type: null,
      source_type: null,
      tiktok_handle: null,
      twitter_handle: null,
      vimeo_channel_url: null,
      website_url: null,
      youtube_channel_url: null,
      description: null,
      automatically_add_entrants: null,
      is_featured: null,
      selected_for_voting: null,
      ...resourceHash,
    };

    return super.normalize(modelClass, resourceHashWithDefaults);
  }
}
