/* eslint-disable ember/closure-actions, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { set } from '@ember/object';
import { alias, not } from '@ember/object/computed';

export default Component.extend({
  tagName: 'simple-menu-contents',
  classNameBindings: ['preventOffScreen:prevent-off-screen'],
  attributeBindings: ['isHidden:hidden'],

  didRender() {
    this._super();

    const menuBottom = this.element.getBoundingClientRect().bottom;
    const windowHeight = window.innerHeight;

    if (menuBottom > windowHeight) {
      set(this, 'preventOffScreen', true);
    }

    if (this.isHidden) {
      set(this, 'preventOffScreen', false);
    }
  },

  /**
   * Close the simple-menu when the menu is clicked
   * @returns {boolean}
   */
  click() {
    this.close();
  },

  'is-expanded': false,

  close() {
    return;
  },

  isHidden: not('is-expanded'),
  menu: alias('parentView'),

  preventOffScreen: false,
});
