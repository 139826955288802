/* eslint-disable ember/no-on-calls-in-components, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components */
import Component from '@ember/component';
import { computed, get, observer, set } from '@ember/object';
import { alias, lte } from '@ember/object/computed';
import { on } from '@ember/object/evented';
import { isEmpty } from '@ember/utils';
import moment from 'moment';

export default Component.extend({
  //region Computed Properties
  // TODO: [SMC Refactor] once singleMessageCampaign is removed,
  // We'll just pass messageCampaign in instead of the model.
  // We'll be able to remove this alias
  messageCampaign: alias('model.messageCampaign'),
  notEnoughMessageVersions: lte('messageCampaign.messageVersions.length', 1),
  abRecipientCount: computed('messageCampaign.messageTestPercentage', function () {
    if (!get(this, 'messageCampaign.hasRecipients')) {
      return '--';
    }
    const totalRecipients = get(this, 'messageCampaign.audienceMemberCount');
    const abPercentage = Number(get(this, 'messageCampaign.messageTestPercentage')) / 100;
    return get(this, 'messageCampaign.messageVersions.length') > 0
      ? Math.round((totalRecipients * abPercentage) / get(this, 'messageCampaign.messageVersions.length'))
      : 0;
  }),
  winnerRecipientCount: computed('messageCampaign.messageTestPercentage', function () {
    if (!get(this, 'messageCampaign.hasRecipients')) {
      return '--';
    }
    return this.abRecipientCount > 0
      ? get(this, 'messageCampaign.audienceMemberCount') -
          this.abRecipientCount * get(this, 'messageCampaign.messageVersions.length')
      : '';
  }),
  step2status: computed('messageCampaign.messageTestPercentage', function () {
    return !isEmpty(get(this, 'messageCampaign.messageTestPercentage')) &&
      get(this, 'messageCampaign.messageTestPercentage') > 0 &&
      !this.notEnoughMessageVersions
      ? 'bestPractice'
      : 'incomplete';
  }),
  step3status: computed('messageCampaign.{messageTestDurationOpenCount,messageTestDurationMinutes}', function () {
    return !isEmpty(get(this, 'messageCampaign.messageTestDurationOpenCount')) &&
      !isEmpty(get(this, 'messageCampaign.messageTestDurationMinutes')) &&
      get(this, 'messageCampaign.messageTestDurationOpenCount') > 0 &&
      get(this, 'messageCampaign.messageTestDurationMinutes') > 0 &&
      !this.notEnoughMessageVersions
      ? 'bestPractice'
      : 'incomplete';
  }),
  isStep3Dirty: computed(
    'messageCampaign.{isMessageTestDurationOpenCountDirty,isMessageTestDurationMinutesDirty}',
    function () {
      return (
        get(this, 'messageCampaign.isMessageTestDurationOpenCountDirty') ||
        get(this, 'messageCampaign.isMessageTestDurationMinutesDirty')
      );
    }
  ),
  step4status: computed('messageCampaign.messageTestWinningCriteria', function () {
    return !isEmpty(get(this, 'messageCampaign.messageTestWinningCriteria')) && !this.notEnoughMessageVersions
      ? 'bestPractice'
      : 'incomplete';
  }),
  isChecklistStepSatisfied: computed(
    'notEnoughMessageVersions',
    'messageCampaign.{scheduleStartDate,isScheduled,sendOnConfirm}',
    'step2status',
    'step3status',
    'step4status',
    function () {
      const startDate = get(this, 'messageCampaign.scheduleStartDate');
      const isScheduled = get(this, 'messageCampaign.isScheduled');
      const sendsOnConfirmation = get(this, 'messageCampaign.sendOnConfirm');
      const step2 = this.step2status;
      const step3 = this.step3status;
      const step4 = this.step4status;
      if (this.notEnoughMessageVersions) {
        return !!startDate || sendsOnConfirmation;
      }
      return (
        ((!isEmpty(startDate) && isScheduled) || sendsOnConfirmation) &&
        step2 === 'bestPractice' &&
        step3 === 'bestPractice' &&
        step4 === 'bestPractice'
      );
    }
  ),
  testEndHours: computed('messageCampaign.messageTestDurationMinutes', {
    get() {
      return get(this, 'messageCampaign.messageTestDurationMinutes') / 60;
    },
    set(key, newValue) {
      set(this, 'messageCampaign.messageTestDurationMinutes', Math.min(Number.parseInt(newValue, 10), 6) * 60);
      return newValue;
    },
  }),
  testOpenCount: computed('messageCampaign.messageTestDurationOpenCount', {
    get() {
      return get(this, 'messageCampaign.messageTestDurationOpenCount');
    },
    set(key, newValue) {
      if (isEmpty(newValue)) {
        set(this, 'messageCampaign.messageTestDurationOpenCount', 0);
        return 0;
      }
      set(this, 'messageCampaign.messageTestDurationOpenCount', newValue);
      return newValue;
    },
  }),
  showTimeSpan: computed('testEndHours', 'messageCampaign.isScheduled', function () {
    return this.testEndHours > 0 && get(this, 'messageCampaign.isScheduled');
  }),
  testTimeStart: computed('messageCampaign.scheduleStartDate', function () {
    const date = get(this, 'messageCampaign.scheduleStartDate')
      ? moment(get(this, 'messageCampaign.scheduleStartDate'))
      : moment('empty');
    return date.format('h:mma');
  }),
  testTimeEnd: computed('messageCampaign.{scheduleStartDate,messageTestDurationMinutes}', function () {
    const date = get(this, 'messageCampaign.scheduleStartDate')
      ? moment(get(this, 'messageCampaign.scheduleStartDate'))
      : moment('empty');
    return date.add(Number(get(this, 'messageCampaign.messageTestDurationMinutes')), 'm').format('h:mma');
  }),
  //endregion

  //region Observers
  scheduling: on(
    'init',
    observer('messageCampaign.{scheduleStartDate,sendOnConfirm}', function () {
      if (isEmpty(get(this, 'messageCampaign.scheduleStartDate')) && !get(this, 'messageCampaign.sendOnConfirm')) {
        set(this, 'messageCampaign.isScheduled', false);
      } else {
        set(this, 'messageCampaign.isScheduled', true);
      }
    })
  ),
  sendChecklistStepSatisfaction: observer(
    'messageCampaign.{scheduleStartDate,isScheduled,sendOnConfirm}',
    'step2status',
    'step3status',
    'step4status',
    function () {
      this['checklist-step-satisfied'](this.isChecklistStepSatisfied);
    }
  ),
  //endregion
});
