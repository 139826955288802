import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

const AUDIENCE_ROUTE = 'organizations.organization.data.audiences.audience';

export default class OrganizationsOrganizationDataAudiencesAudienceSetupIntegrationRoute extends Route {
  @service store;

  async model() {
    const { audience } = this.modelFor(AUDIENCE_ROUTE);
    const isAudienceArchived = audience.isArchived;
    const audienceId = audience.id;

    const audienceIntegration = await this.store.query('audience-integration', { audienceId });
    return {
      isAudienceArchived,
      audienceIntegration: audienceIntegration.firstObject,
    };
  }
}
