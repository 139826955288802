/* eslint-disable ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { equal, gt, readOnly } from '@ember/object/computed';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import PermittedComponent from 'partner/mixins/permitted-component';

export default Component.extend(PermittedComponent, {
  //region Dependencies
  features: service(),
  //endregion

  //region Attributes
  'disable-help-text': false,
  'dips-url': null,
  //endregion

  //region Ember Hooks
  tagName: 'form-designer-form-field',
  attributeBindings: ['data-guid'],
  classNameBindings: [
    'canAdminister:ssEditable',
    'canAdminister:ssClickable',
    'canAdminister:sortable',
    'hasErrors:has-errors',
    'disabled:ssDisabled',
  ],
  //when a form field gets resorted, sometimes Ember loses track of the form field and does not update the UI if you try to remove it
  willDestroy() {
    this._super(...arguments);
    if (!this.element) {
      document.querySelector(`#${guidFor(this)}`)?.remove();
    }
  },
  //endregion

  //region Properties
  showRemoveConfirmation: false,
  //endregion

  //region Computed Properties
  formField: readOnly('form-field'),
  'data-guid': computed('formField', function () {
    return guidFor(this.formField);
  }),

  isCopyEnabled: computed('form-field.{fieldType,field.isEditable}', function () {
    return (
      this?.formField?.field?.isEditable &&
      ['SelectSingle', 'RadioButtons', 'Checkboxes'].includes(this?.formField?.fieldType)
    );
  }),

  totalErrors: computed(
    'formField.errors.length',
    'formField.field.{errors.length,fieldOptions.@each.hasErrors,globalOptin.errors.length}',
    function () {
      return (
        (get(this, 'formField.errors.length') || 0) +
        (get(this, 'formField.field.errors.length') || 0) +
        (get(this, 'formField.field.globalOptin.errors.length') || 0) +
        (get(this, 'formField.field.fieldOptions') || []).reduce((sum, x) => sum + get(x, 'errors.length'), 0)
      );
    }
  ),

  hideRemoveFieldForParticipation: computed('formField', 'participationEnabled', function () {
    return this.participationEnabled.toArray()?.any(country => country.postalCodes?.length)
      ? this.formField.participationNonRemovableFieldsWithZipcode
      : this.formField.participationNonRemovableFieldsWithoutZipcode;
  }),
  hasErrors: gt('totalErrors', 0),
  canAdminister: equal('administer', true),
  hasSharingEnabled: computed('formField.optinNotificationHelpText', function () {
    return !!this.formField.optinNotificationHelpText;
  }),
  //endregion

  //region Actions
  actions: {
    editFormField() {
      if (this.canAdminister && this.editFormField && !this.disabled) {
        this.editFormField(this['form-field']);
      }
    },

    copyFormField() {
      this.copyFormField(this.formField);
    },

    removeOrShowConfirmation() {
      if (this.hasSharingEnabled) {
        set(this, 'showRemoveConfirmation', true);
      } else {
        this.removeFormField(this.formField);
      }
    },

    confirmRemove() {
      set(this, 'showRemoveConfirmation', false);
      this.removeFormField(this.formField);
    },

    cancelRemove() {
      set(this, 'showRemoveConfirmation', false);
    },
  },
  //endregion
});
