/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
/* eslint-disable ember/use-ember-get-and-set */ // FIXME
import { A } from '@ember/array';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

export default Mixin.create({
  store: service(),

  /**
   * Common code that we need to create a copy of an object,
   * but we don't want all the properties form the original.
   * Mainly used when we are creating a non-inherited copy of a
   * inherited object. Example of inherited object is {@link Promotion.Setting}
   * NOTE: Must be mixed into a {@link DS.Model}
   * @param excludedPropertyList {Array} these properties won't be included in the copy
   * @returns {DS.Model} new instance of model object that ember data is "aware of"
   */
  createCopy(excludedPropertyList = []) {
    const modelName = this.get('constructor.modelName');

    const propertyList = A([]);
    this.eachAttribute(name => {
      if (excludedPropertyList.indexOf(name) === -1) {
        propertyList.pushObject(name);
      }
    });

    const copy = this.store.createRecord(modelName, this.getProperties(propertyList));
    this.eachRelationship((name, relationship) => {
      if (excludedPropertyList.indexOf(name) === -1) {
        if (relationship.kind === 'hasMany') {
          copy.get(name).addObjects(this.get(name));
        } else if (relationship.kind === 'belongsTo') {
          copy.set(name, this.get(name));
        }
      }
    });

    return copy;
  },
});
