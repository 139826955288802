/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

const ORGANIZATION_PROMOTION_ROUTE = 'organizations.organization.organization-promotions.organization-promotion';
const ORGANIZATION_PROMOTION_SETUP_ROUTE =
  'organizations.organization.organization-promotions.organization-promotion.setup';

export default Route.extend({
  enums: service(),
  settings: service(),
  store: service(),

  async model() {
    const designTemplateTypeId = this.enums.findWhere('TEMPLATE_TYPE', { name: 'Promotion' });

    const { promotionDesign, designTemplates } = await RSVP.hash({
      promotionDesign: this.store.queryRecord('design', { designTemplateType: designTemplateTypeId }),
      designTemplates: this.store.query('design-template', { designTemplateTypeId }),
    });

    return RSVP.hash({
      organization: this.modelFor('organizations.organization').organization,
      organizationPromotion: this.modelFor(ORGANIZATION_PROMOTION_ROUTE).organizationPromotion,
      promotion: this.modelFor(ORGANIZATION_PROMOTION_ROUTE).promotion,
      matchups: this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE).matchups,
      promotionProduct: this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE).promotionProduct,
      _ads: this.store.query('sponsored-post', { BypassCache: true }).then(x => x.toArray()),
      design: promotionDesign,
      designTemplates,
      designTokens: this.store.query('design-token', {}).then(x => x.toArray()),
      designTokenContents: promotionDesign
        ? this.store.query('design-token-content', { designId: promotionDesign.id }).then(x => x.toArray())
        : [],

      languageSettings: this.store.query('setting', { category: 'UI_Text' }),
      _settings: this.settings.preload('dips_url'),
      useOldTemplateSetting: this.store.queryRecord('setting', { key: 'use_old_design_templates' }),
    });
  },

  async afterModel(model, transition) {
    const socialShareTokens1 = model.design.tokenContents.filter(tokenContent =>
      ['Facebook', 'Email'].includes(tokenContent.token.tokenContentType)
    );

    const socialShareTokens2 = model.design.tokenContents.filter(tokenContent =>
      ['Pinterest', 'Twitter'].includes(tokenContent.token.tokenContentType)
    );

    await RSVP.all([
      ...socialShareTokens1?.map(token => {
        if (!token.title) {
          set(token, 'title', model.organizationPromotion.promotion.name);
        }

        if (!token.value) {
          set(token, 'value', 'Check it out now!');
        }
        return token.save();
      }),
      ...socialShareTokens2?.map(token => {
        if (!token.value) {
          set(token, 'value', model.organizationPromotion.promotion.name);
        }
        return token.save();
      }),
    ]);

    transition.then(() => this.send('designChecklistStepLogic', model.design));
  },
  //endregion

  //region Methods
  async save(design) {
    set(this, 'controller.isAnythingSaving', true);
    const { organizationPromotion } = this.modelFor(this.routeName);
    const dirtyTokenContents = design.tokenContents.filterBy('hasDirtyAttributes');

    try {
      await Promise.all(dirtyTokenContents.map(dirtyTokenContent => dirtyTokenContent.save()));
      await (design.hasDirtyAttributes ? design.save() : Promise.resolve(null));
      await (organizationPromotion.hasDirtyAttributes ? organizationPromotion.save() : Promise.resolve(null));
    } finally {
      this.send('designChecklistStepLogic', design);
      set(this, 'controller.isAnythingSaving', false);
    }
  },
  //endregion

  //region Actions
  actions: {
    save() {
      if (!get(this, 'controller.isAnythingSaving')) {
        this.save(get(this, 'controller.model.design'));
      }
    },
  },
  //endregion
});
