/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';

export default Component.extend({
  //region Attributes
  updateTokenContentProperty() {},
  checkForDynamicTokenFallback() {},
  openImageLibrary() {},

  value: null,
  tokenId: null,
  isDisabled: false,
  errors: null,
  tokens: null,
  isMessageCampaign: false,
  libraryImage: null,
  wysiwygOverrideShiftEnter: true,
  wysiwygDisabledPlugins: null,
  //endregion

  //region Actions
  actions: {
    updateValue(value) {
      this.updateTokenContentProperty('value', value);
    },

    checkForDynamicTokenFallback(token) {
      this.checkForDynamicTokenFallback(token);
    },
  },
  //endregion
});
