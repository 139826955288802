/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { getOwner } from '@ember/application';
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import { endpoint } from 'secondstreet-common/utils/url';

export default Route.extend(AppContextRoute, {
  //region Dependencies
  session: service(),
  enums: service(),
  store: service(),
  //endregion

  //region Ember Hooks
  async model() {
    const { organization } = this.modelFor('organizations.organization');
    const organizationId = organization.id;
    const fields = await this.store.findAll('field');
    const orgForms = await this.store.query('form', { organizationId });
    const form = orgForms.find(
      form =>
        get(form, 'formTypeId') === this.enums.findWhere('FORM_TYPE', { name: 'EmailOptinPreferences' }) &&
        !get(form, 'isInherited')
    );

    //if the org has an Email Opt-in Preference form that is not inherited, use that one
    if (form) {
      return { form, fields, organization };
    }
    //Otherwise, create one by copying the default Email Opt-in Preference form, and then use the created form
    const defaultForm = orgForms.findBy(
      'formTypeId',
      this.enums.findWhere('FORM_TYPE', { name: 'EmailOptinPreferences' })
    );
    const newForm = await this.session
      .request(endpoint('copy_forms'), {
        type: 'POST',
        data: `{"copy_forms":[{"source_form_id": ${get(defaultForm, 'id')}}]}`,
        headers: get(getOwner(this).lookup('adapter:application'), 'headers'),
        contentType: 'application/json',
      })
      .then(responseData => this.store.find('form', get(responseData, 'copy_forms.firstObject.id')));
    return { form: newForm, fields, organization };
  },
  //endregion
});
