/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { get } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';

export default Mixin.create({
  //region Ember Hooks
  snackbar: service(),
  afterModel(model) {
    this._super(...arguments);
    const messageSendingStatusType = get(model, 'singleMessageCampaign.messageSendingStatusType');
    const syndicateCount = get(model, 'messageCampaign.syndicateCount');

    if (syndicateCount) {
      this.snackbar.show(
        `This email was copied to ${syndicateCount} other ${pluralize(syndicateCount, 'Organization', {
          withoutCount: true,
        })} and can no longer be edited.`,
        '',
        -1,
        null,
        true
      );
    } else if (get(model, 'singleMessageCampaign.isNewsletter')) {
      if (messageSendingStatusType === 'Sending') {
        this.snackbar.show('This message is sending and can no longer be edited.', '', -1, null, true);
      } else if (messageSendingStatusType === 'Sent') {
        this.snackbar.show('This message has been sent and can no longer be edited.', '', -1, null, true);
      }
    } else if (
      (get(model, 'singleMessageCampaign.isSingleEmail') || get(model, 'singleMessageCampaign.isInvite')) &&
      get(model, 'singleMessageCampaign.hasStarted')
    ) {
      this.snackbar.show('This message has been sent and can no longer be edited.', '', -1, null, true);
    }
  },
  //endregion
});
