/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */ // FIXME
import Component from '@ember/component';
import { observer } from '@ember/object';
import { debounce } from '@ember/runloop';
import FormDesignerFormFieldContentsMixin from 'partner/mixins/form-designer-form-field-contents';

export default Component.extend(FormDesignerFormFieldContentsMixin, {
  hashtagChanged: observer('form-field.field.twitterHashtag', function () {
    debounce(
      this,
      () => {
        if (!this.isDestroyed) {
          this.rerender();
        }
      },
      150
    );
  }),
});
