/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-mixins */
import { AsyncHasMany, attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, or } from '@ember/object/computed';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import enums from 'ember-cli-ss-enums/services/enums';
import PromotionTypeName from 'partner/mixins/promotion-type-name';
import BaseModel from 'secondstreet-common/models/base';
import type SettingModel from './setting';

const ModelWithMixins = BaseModel.extend(PromotionTypeName) as unknown as typeof BaseModel;

@makeBooleanProperties('PROMOTION_SUB_TYPE')
@makeBooleanProperties('PROMOTION_TYPE')
export default class PromotionModel extends ModelWithMixins {
  @attr('number') declare defaultLanguageId: number;
  @attr('string') declare name: string;
  @attr('number') declare promotionPresetId: number;
  @attr('number') declare promotionTypeId: number;
  @attr('number') declare promotionSubTypeId: number;
  @attr('number') declare promotionTypeCategoryId: number;
  @attr('number') declare copyOrganizationPromotionId: number; // Only used for POST when copying a promotion
  @attr('boolean') declare copyEntries: boolean; // Only used for POST when copying a ballot promotion
  @attr('number') declare organizationId: number;
  @attr('boolean') declare isInherited: boolean;
  @attr('boolean') declare isSyndicated: boolean;
  @attr('boolean') declare isCreatedFromTurnkey: boolean;

  @hasMany('organization-promotion', { async: true }) declare organizationPromotions: AsyncHasMany<any>;
  @hasMany('setting', { async: false }) declare settings: SyncHasMany<SettingModel>;
  @hasMany('outcome', { async: true }) declare outcomes: AsyncHasMany<any>;
  @belongsTo('sweepstakes', { async: true }) declare sweepstakes: AsyncHasMany<any>;

  // TODO: An algorithm to detect whether the promotion is fresh, hot, or stale.
  // The basic concept is that a fresh contest has been created, but hasn't really started getting the bulk of its entries.
  // If a time-insensitive contest is fresh, the user likely wants to go to setup. If a time-insensitive contest is
  // hot or stale, they likely want to go to setup. Similarly, on the promotion listing it could categorize based on it.
  // If a time-insensitive contest is fresh or hot, it should go in the "Current & Upcoming" list. If it is stale,
  // it should go into the "past" list.
  isFresh = true;

  isHot = false;
  isStale = false;

  @computed('promotionSubType')
  get areMatchupsCategories() {
    return [
      'VideoVotingStandard',
      'VotingBallot',
      'NominationAndVotingBallot',
      'PhotoGallery',
      'PhotoVotingStandard',
    ].includes(this.promotionSubType);
  }

  @computed('promotionSubType')
  get areMatchupsRounds() {
    return ['UGCSweepstakesVideo', 'UGCSweepstakesStandard', 'SweepstakesCodeword', 'SweepstakesSimple'].includes(
      this.promotionSubType
    );
  }

  @computed('promotionType')
  get isDraggableInTemplate() {
    return ['Sweepstakes', 'Survey', 'EventSignup'].includes(this.promotionType);
  }

  @enums.computed('name', 'promotionTypeId') declare promotionType: string;
  @enums.computed('name', 'promotionSubTypeId') declare promotionSubType: string;
  @enums.computed('name', 'promotionTypeCategoryId') declare promotionTypeCategory: string;
  @enums.computed('isTimeSensitive', 'promotionTypeId') declare isTimeSensitive: boolean;

  @or('isVotingBracket', 'isNominationAndVotingBallot', 'isBallot', 'isSweepstakesCodeword', 'isSweepstakesSimple')
  declare hasReminderEmails: boolean;
  @alias('isPaymentForm') declare hasReceiptEmails: boolean;
  @or('isUGCVoting', 'isUGCGallery', 'isUGCSweepstakes') declare isUgc: boolean;
  @or('isVideoVotingStandard', 'isUGCSweepstakesVideo') declare isVideo: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    promotion: PromotionModel;
  }
}
