/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { getOwner } from '@ember/application';
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';
import { endpoint } from 'secondstreet-common/utils/url';

export default Route.extend(AppContextRoute, {
  //region Dependencies
  router: service(),
  session: service(),
  store: service(),
  //endregion

  //region Ember Hooks
  model() {
    const audience = this.modelFor('organizations.organization.data.audiences.audience');
    return this.session
      .request(endpoint('copy_audiences'), {
        type: 'POST',
        data: `{"copy_audiences":[{"source_audience_id":${get(audience, 'audience.id')}}]}`,
        headers: get(getOwner(this).lookup('adapter:application'), 'headers'),
        contentType: 'application/json',
      })
      .then(responseData =>
        RSVP.hash({
          audience: this.store.find('audience', get(responseData, 'copy_audiences.firstObject.id')),
        })
      );
  },
  redirect(model) {
    set(model.audience, 'isJustCreated', true);
    if (get(model, 'audience.audienceType') === 'Segmented') {
      this.router.transitionTo(
        'organizations.organization.data.audiences.audience.filters',
        get(model, 'audience.id'),
        {
          queryParams: { isEditingName: true },
        }
      );
    } else {
      this.router.transitionTo('organizations.organization.data.audiences.audience.setup', get(model, 'audience.id'), {
        queryParams: { isEditingName: true },
      });
    }
  },
  //endregion
});
