/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set, setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty, isNone, isPresent } from '@ember/utils';
import flatten from 'lodash/flatten';
import MessageCampaignSnackbar from 'partner/mixins/message-campaign-snackbar';
import { ARTICLE_TOKENS } from 'partner/utils/constants';
import { setMessageIsConfirmed } from 'partner/utils/message-campaign-helpers';
import { createTokenContentsForMessageVersion, generateBodyAndSave } from 'partner/utils/message-version';
import convertRssItemToTokenContentItem from 'partner/utils/rss-item-to-token-content-item';
import ReadRss from 'partner/utils/rss-reader';
import RSVP from 'rsvp';

const DEFAULT_SUBJECT_TEXT = 'Subject Line Text';
const ARTICLE_TITLE_TOKEN = ARTICLE_TOKENS.findBy('name', 'Article 1 Headline').token;
const ARTICLE_DESCRIPTION_TOKEN = ARTICLE_TOKENS.findBy('name', 'Article 1 Description').token;

const MESSAGE_CAMPAIGN_ROUTE = 'organizations.organization.message-campaigns.message-campaign';
const SETUP_ROUTE = `${MESSAGE_CAMPAIGN_ROUTE}.setup`;
const MESSAGE_VERSIONS_ROUTE = `${SETUP_ROUTE}.message-versions`;

export default Route.extend(MessageCampaignSnackbar, {
  //region Ember Dependencies
  enums: service(),
  router: service(),
  snackbar: service(),
  store: service(),
  settings: service(),
  //endregion

  //region Ember Hooks
  async model() {
    const organizationId = this.modelFor('organizations.organization').organization.id;
    const { messageCampaign } = this.modelFor(MESSAGE_CAMPAIGN_ROUTE);
    const messageCampaignId = messageCampaign.id;

    const model = await RSVP.hash({
      dipsUrl: this.settings.preload('dips_url').then(() => this.settings.getFor('dips_url')),
      senderAccounts: this.store.query('sender-account', { organizationId }).then(x => x.toArray()),
      templates: this.store.query('message-body-template', { organizationId }).then(x => x.toArray()),
      locations: this.store.query('location', { isPrimary: true, organizationId }),
      tokenFallbackSettings: this.store.query('setting', { key: 'messaging_dynamic_token_fallback' }),
      ...this.maybeLoadOrgPromoSettings(),
    });

    if (messageCampaign.isNewsletter && messageCampaign.messageVersions.firstObject?.isTemplate) {
      set(
        model,
        'messageVersionHistory',
        (
          await this.store.query('message-version-history', {
            messageCampaignId,
            pageSize: 5,
            SendBody: true,
          })
        )
          .sortBy('dateCreated:desc')
          .findBy('isEditable')
      );
    }

    return model;
  },

  async maybeLoadOrgPromoSettings() {
    const { messageCampaign } = this.modelFor(MESSAGE_CAMPAIGN_ROUTE);
    if (!messageCampaign.isPromoEmail) {
      return {};
    }

    const organizationPromotion = await this.store.findRecord(
      'organization-promotion',
      messageCampaign.organizationPromotionId
    );

    if (!isPresent(organizationPromotion)) {
      return {};
    }

    if (!['Ballot', 'UGCVoting'].includes(organizationPromotion.promotion.promotionType)) {
      return { organizationPromotion };
    }

    const promoParams = {
      organizationPromotionId: organizationPromotion.id,
      promotionId: organizationPromotion.promotion.id,
    };

    return {
      organizationPromotion,
      voteIntervalTypeIdSetting: this.store.queryRecord('setting', {
        key: 'vote_allowed_interval',
        ...promoParams,
      }),
      votesAllowedNumberSetting: this.store.queryRecord('setting', {
        key: 'votes_allowed_number',
        ...promoParams,
      }),
      entryIntervalTypeIdSetting: this.store.queryRecord('setting', {
        key: 'entries_allowed_interval',
        ...promoParams,
      }),
      entriesAllowedNumberSetting: this.store.queryRecord('setting', {
        key: 'entries_allowed_number',
        ...promoParams,
      }),
    };
  },

  async afterModel(model, transition) {
    this._super(...arguments);

    const { messageCampaign } = this.modelFor(MESSAGE_CAMPAIGN_ROUTE);
    const { tokens, tokenContents } = this.modelFor(SETUP_ROUTE);
    const { messageVersionHistory } = model;

    if (isPresent(messageVersionHistory)) {
      const { messageVersionFeeds } = this.modelFor(SETUP_ROUTE);
      const messageVersion = await messageVersionHistory.messageVersion;
      const mvhTokenContents = await this.store
        .query('message-version-template-token-content', {
          messageVersionHistoryId: messageVersionHistory.id,
        })
        .then(x => x.toArray());

      if (isEmpty(mvhTokenContents)) {
        const newTokenContents = await this.createTokenContentsForMessageVersionHistory(
          messageVersion,
          messageVersionHistory
        );
        mvhTokenContents.push(...newTokenContents);
        tokenContents.push(...newTokenContents);
      }

      if (messageVersion.bodySourceType !== 'Scrape Url') {
        setProperties(messageVersionHistory, {
          tokenContents: mvhTokenContents,
          messageVersionFeeds: [],
        });
      }

      set(messageVersionHistory, 'messageVersionFeeds', messageVersionFeeds);
    }

    if (isEmpty(messageVersionHistory) && isEmpty(messageCampaign.messageVersions)) {
      await this.createMessageVersion(model, transition);
    }

    const designs = isPresent(messageVersionHistory) ? [messageVersionHistory] : messageCampaign.messageVersions;
    const templateDesigns = designs.filterBy('isTemplate');
    // in case the design uses a deleted custom template, which would not be returned in a GET to message body templates
    await Promise.all(templateDesigns.mapBy('messageBodyTemplate'));

    if (messageCampaign.isNewsletter) {
      const { messageVersionFeeds } = this.modelFor(SETUP_ROUTE);
      messageVersionFeeds.forEach((feed, index) => set(feed, 'rssTokenName', `RSS Feed ${index + 1}`));
    }

    if (messageCampaign.canCurrentlyEdit && !messageCampaign.isScheduledSentOrSending) {
      const templateMessageVersions = messageCampaign.messageVersions.filterBy('isTemplate');
      // run this on every route load in case new tokens were added to a custom template
      await this.createAndSaveTokenContents(
        templateMessageVersions,
        messageCampaign.isNewsletter,
        messageVersionHistory
      );
      // run this on every route load in case a custom template was edited
      if (templateDesigns.length) {
        generateBodyAndSave(templateDesigns, model.dipsUrl.value, tokens);
      }

      // save changes to message versions
      await RSVP.allSettled(templateDesigns.map(design => design.save()));
    }

    const { messageVersionHistoryId, messageVersionId } = transition.to.queryParams;
    const messageVersion = messageCampaign.messageVersions.firstObject;

    if (messageVersionHistoryId && messageVersionId) {
      transition.abort();
      this.router.replaceWith(this.fullRouteName, { queryParams: { messageVersionHistoryId: null, messageVersionId } });
    } else if (messageVersionHistoryId) {
      if (isEmpty(messageVersionHistory) || messageVersionHistoryId !== messageVersionHistory.id) {
        transition.abort();
        this.router.replaceWith(this.fullRouteName, {
          queryParams: {
            messageVersionHistoryId: isPresent(messageVersionHistory) ? messageVersionHistory.id : null,
          },
        });
      }
    } else if (messageVersionId) {
      if (!messageCampaign.messageVersions.findBy('id', messageVersionId)) {
        transition.abort();
        this.router.replaceWith(this.fullRouteName, {
          queryParams: { messageVersionId: isPresent(messageVersion) ? messageVersion.id : null },
        });
      }
    } else {
      transition.abort();
      if (isPresent(messageVersionHistory)) {
        this.router.replaceWith(this.fullRouteName, {
          queryParams: { messageVersionHistoryId: messageVersionHistory.id },
        });
      } else if (isPresent(messageVersion)) {
        this.router.replaceWith(this.fullRouteName, { queryParams: { messageVersionId: messageVersion.id } });
      } else {
        this.router.replaceWith(this.fullRouteName, {
          queryParams: { messageVersionId: null, messageVersionHistoryId: null },
        });
      }
    }
  },

  setupController() {
    this._super(...arguments);
    const { messageCampaign } = this.modelFor(MESSAGE_CAMPAIGN_ROUTE);
    this.messageChecklistStepLogic(messageCampaign.usesSingleMessageCampaign);
  },
  //endregion

  //region Methods
  async createItemTokenContentsFromRssFeed(messageVersionFeed, messageVersion, messageVersionHistory, itemToken) {
    const rssItems = await ReadRss(messageVersionFeed.feedUrl, messageVersionFeed.useFeedDisplayOrder);
    const filteredRssItems = rssItems.filter(rssItem =>
      (messageVersionFeed.items || []).findBy('linkUrl', rssItem.linkUrl)
    );
    return filteredRssItems.map(
      convertRssItemToTokenContentItem(messageVersionFeed, messageVersion, messageVersionHistory, itemToken)
    );
  },
  async createTokenContentsForMessageVersionHistory(messageVersion, messageVersionHistory) {
    const { messageVersionFeeds, tokens, tokenContents } = this.modelFor(SETUP_ROUTE);
    const itemToken = tokens.filterBy('allowMultiple').findBy('category', 'Items');
    const enabledMessageVersionFeeds = messageVersionFeeds.rejectBy('isDisabled');

    const itemTokenContentsByFeed = await Promise.all(
      enabledMessageVersionFeeds.map(messageVersionFeed =>
        this.createItemTokenContentsFromRssFeed(messageVersionFeed, messageVersion, messageVersionHistory, itemToken)
      )
    );

    const itemTokenContents = itemTokenContentsByFeed.flat().map((itemTokenContent, index) => {
      set(itemTokenContent, 'displayOrder', index + 1);
      return this.store.createRecord('message-version-template-token-content', itemTokenContent);
    });

    const tokenContentsForMessageVersionHistory = tokenContents.map(tokenContent => {
      const tokenContentCopy = tokenContent.createCopy(['id']);
      set(tokenContentCopy, 'messageVersionHistory', messageVersionHistory);
      if (tokenContentCopy.value === ARTICLE_DESCRIPTION_TOKEN) {
        set(tokenContentCopy, 'value', enabledMessageVersionFeeds.firstObject?.items.firstObject?.description);
      }
      return tokenContentCopy;
    });

    if (messageVersion.subject === ARTICLE_TITLE_TOKEN) {
      set(messageVersion, 'subject', enabledMessageVersionFeeds.firstObject?.items.firstObject?.title);
    }

    return Promise.all(
      [...itemTokenContents, ...tokenContentsForMessageVersionHistory].map(tokenContent => tokenContent.save())
    );
  },
  async createMessageVersion(model) {
    const messageVersionData = await this.createMessageVersionData(model);
    await this.persistMessageVersionData(messageVersionData, model);
  },
  initializePreheaderToken(preheaderTokenContent) {
    const { token } = preheaderTokenContent;
    const tokenDefaultContent = token.tokenDefaultContent || token.placeholderTokenDefaultContent;
    if (tokenDefaultContent?.isPlaceholder) {
      set(preheaderTokenContent, 'value', ARTICLE_DESCRIPTION_TOKEN);
    }
  },
  async persistMessageVersionData(data, model) {
    const { messageCampaign } = this.modelFor(MESSAGE_CAMPAIGN_ROUTE);
    try {
      if (data.message?.isNew) {
        await data.message.save();
      }
      const messageVersion = await data.messageVersion.save();
      const template = await messageVersion.messageBodyTemplate;
      if (messageCampaign.isNewsletter && isNone(model.messageVersionHistory)) {
        const messageVersionFeed = this.store.createRecord('message-version-feed', {
          messageVersion,
          articleQuantity: template.maxItemsAllowed,
          rssTokenName: 'RSS Feed 1',
        });
        await messageVersionFeed.save();
      }
      return messageVersion;
    } catch (err) {
      console.error(err);
      // delete the message and messageVersion if there was an error
      if (data.message) {
        data.message.deleteRecord();
      }
      data.messageVersion.deleteRecord();
    }
  },
  /**
   * @param model
   * @returns {RSVP.Promise} - Resolves with an object with a `messageVersion` property, and a nullable `message` property
   */
  async createMessageVersionData(model) {
    const { messageCampaign, singleMessageCampaign } = this.modelFor(MESSAGE_CAMPAIGN_ROUTE);
    const eligibleTemplates = messageCampaign.isNewsletter
      ? model.templates.filterBy('accommodatesMultipleItems')
      : model.templates;

    const [template] = eligibleTemplates.sortBy('name').filterBy('body');
    const bodySourceTypeId = this.enums.findWhere('BODY_SOURCE_TYPE', { name: 'Template' }, 'id');
    const senderAccounts = model.senderAccounts.filterBy('validationStatus', 'Verified');
    const primaryAccount = senderAccounts.findBy('isPrimary') || senderAccounts[0];
    const { replyTo, fromName } = primaryAccount;
    const { isNewsletter } = messageCampaign;
    const subject = isNewsletter ? ARTICLE_TITLE_TOKEN : DEFAULT_SUBJECT_TEXT;

    const message = messageCampaign.singleMessage || this.store.createRecord('message', { messageCampaign });
    const messageVersion = this.store.createRecord('message-version', {
      ...(messageCampaign.usesSingleMessageCampaign ? { singleMessageCampaign } : {}),
      message,
      bodySourceTypeId,
      template,
      subject,
      primaryAccount,
      replyTo,
      fromName,
    });

    return { message, messageVersion };
  },
  /**
   * Logic for determining if the "message" step should be checked.
   * @param isSingleMessageCampaign
   * @param relevantRoute
   */
  messageChecklistStepLogic(isSingleMessageCampaign) {
    const messageCampaignAttribute = isSingleMessageCampaign ? 'singleMessageCampaign' : 'messageCampaign';
    const messageCampaign = get(this.modelFor(MESSAGE_CAMPAIGN_ROUTE), messageCampaignAttribute);
    const messageVersions = isSingleMessageCampaign
      ? messageCampaign.messageVersions
      : messageCampaign.messages.map(x => x.messageVersions.firstObject).compact();
    const { messageVersionFeeds } = this.modelFor(SETUP_ROUTE);

    // message campaigns without checklist step logic
    if (messageCampaign.isWelcome || messageCampaign.isInvite) {
      if (!messageVersions.isEvery('isValid')) {
        this.unconfirmMessageCampaign(false);
      }
      return;
    }

    if (messageCampaign.messageVersions || messageCampaign.messages) {
      const isMessageComplete = isPresent(messageVersions) && messageVersions.isEvery('isComplete');
      let action;

      if (!messageVersions.isAny('hasDirtyAttributes')) {
        action = isMessageComplete ? 'checkChecklistStep' : 'uncheckChecklistStep';
      }

      if (
        !messageVersions.isEvery('isValid') ||
        (messageCampaign.isNewsletter && !messageVersionFeeds.isEvery('isComplete'))
      ) {
        this.unconfirmMessageCampaign();
        action = 'uncheckChecklistStep';
      }

      if (
        messageVersions.toArray().some(mv => mv.messageVersionTemplateTokenContents.toArray().some(tc => !tc.isValid))
      ) {
        action = 'uncheckChecklistStep';
      }

      if (action) this.send(action, MESSAGE_VERSIONS_ROUTE);
    }
  },

  async createAndSaveTokenContents(messageVersions, isNewsletter, messageVersionHistory) {
    const filterByKey = isNewsletter ? token => token.key !== 'item' : token => token;
    messageVersions.forEach(messageVersion => {
      // eslint-disable-next-line ember/use-ember-get-and-set
      const templateTokens = messageVersion.template.get('tokens').filter(filterByKey);
      const newTokenContents = createTokenContentsForMessageVersion(messageVersion, templateTokens, this.store);
      const newPreheaderTokenContent = newTokenContents.findBy('token.key', 'preheaderText');
      if (isNewsletter && isPresent(newPreheaderTokenContent)) {
        this.initializePreheaderToken(newPreheaderTokenContent);
      }
      if (messageVersionHistory) {
        newTokenContents.forEach(newTokenContent =>
          set(newTokenContent, 'messageVersionHistory', messageVersionHistory)
        );
      }
    });

    const allNewTokenContents = flatten(
      messageVersions.mapBy('tokenContents').map(tokenContents => tokenContents.toArray())
    ).filterBy('isNew');
    await Promise.all(allNewTokenContents.map(tokenContent => tokenContent.save()));
  },

  async unconfirmMessageCampaign(hasChecklist = true) {
    const { messageCampaign, singleMessageCampaign } = this.modelFor(MESSAGE_CAMPAIGN_ROUTE);

    if (messageCampaign.isConfirmed) {
      set(messageCampaign, 'isConfirmed', false);
      await messageCampaign.save();
    }

    setMessageIsConfirmed({
      usesSMC: messageCampaign.usesSingleMessageCampaign,
      singleMessageCampaign,
      messageCampaign,
      value: false,
      saveSMC: true,
    });

    if (hasChecklist) {
      this.send(
        'uncheckChecklistStep',
        'organizations.organization.message-campaigns.message-campaign.setup.confirmation'
      );
    }
  },
  //endregion

  //region Actions
  actions: {
    willTransition() {
      this._super(...arguments);
      this.snackbar.hide();
    },
    messageChecklistStepLogic(isSingleMessageCampaign) {
      this.messageChecklistStepLogic(isSingleMessageCampaign);
    },
    // Intercept the uncheck of any checklist item, and un-confirm the entire message.
    uncheckChecklistStep(route) {
      const confirm = 'organizations.organization.message-campaigns.message-campaign.setup.confirmation';
      if (route === confirm) {
        return true;
      }
      this.unconfirmMessageCampaign();
      return true;
    },
    unconfirmMessageCampaign() {
      this.unconfirmMessageCampaign();
    },
    /**
     * Logic for determining if the "schedule" step should be checked based on a MessageVersion being added or removed.
     * @param isSingleMessageCampaign
     */
    messageVersionsChangedChecklistStepLogic(isSingleMessageCampaign) {
      const messageCampaign = get(
        this.modelFor(MESSAGE_CAMPAIGN_ROUTE),
        isSingleMessageCampaign ? 'singleMessageCampaign' : 'messageCampaign'
      );
      const messageVersions = get(messageCampaign, isSingleMessageCampaign ? 'messageVersions' : 'messages');
      const scheduleRoute = 'organizations.organization.message-campaigns.message-campaign.setup.schedule';
      if (isSingleMessageCampaign) {
        if (
          messageVersions.length === 2 &&
          isEmpty(messageCampaign.messageTestDurationOpenCount) &&
          isEmpty(messageCampaign.messageTestDurationMinutes)
        ) {
          // Version B was just added and AB settings have not been set on schedule section
          this.send('uncheckChecklistStep', scheduleRoute);
        } else if (messageVersions.length === 1 && !isEmpty(messageCampaign.scheduleStartDate)) {
          // This handles the case when only version A remains which changes the "complete" requirements for the "schedule" step
          this.send('checkChecklistStep', scheduleRoute);
        }
      } else {
        const unscheduledDrip = messageVersions.rejectBy('schedule.delayDatePartType', 'EverySecond').find(
          message =>
            !message.schedule ||
            // if schedule.delayDatepartTypeId is 1, there will be no start time (sends immediately)
            isEmpty(message.schedule.delayValue) ||
            isEmpty(message.schedule.delayDatepartTypeId) ||
            (message.schedule.delayDatepartTypeId !== 1 && isEmpty(message.schedule.startTime))
        );
        const dripScheduled = isNone(unscheduledDrip);
        const checklistAction = dripScheduled ? 'checkChecklistStep' : 'uncheckChecklistStep';
        this.send(checklistAction, scheduleRoute);
      }
    },
  },
  //endregion
});
