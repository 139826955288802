import Service, { inject as service } from '@ember/service';
import type CurrentService from './current';

import ballotGroupPreview from 'partner/utils/consumer-previews-new/ballot-group';

import ballotEntrantPreview from 'partner/utils/consumer-previews-new/ballot-entrant';
import ballotPreview from 'partner/utils/consumer-previews-new/ballot';

import formPreview from 'partner/utils/consumer-previews-new/form';

import quizPreview, { quizForm, quizThanks } from 'partner/utils/consumer-previews-new/quiz';
import pollView from 'partner/utils/consumer-previews-new/poll';
import surveyPreview from 'partner/utils/consumer-previews-new/form';
import bracketMatchupPreview from 'partner/utils/consumer-previews-new/bracket-matchup';
import ugcPhotoPreview, { ugcFormPage, ugcEntrantPage } from 'partner/utils/consumer-previews-new/ugc-photo';

import eventPreview from 'partner/utils/consumer-previews-new/form';
import pollForm from 'partner/utils/consumer-previews-new/poll-form';

import sweepstakesThanks from 'partner/utils/consumer-previews-new/sweeps-thanks';

import surveyThanks from 'partner/utils/consumer-previews-new/survey-thanks';
import ugcThanks from 'partner/utils/consumer-previews-new/ugc-thanks';
import eventThanks from 'partner/utils/consumer-previews-new/event-thanks';

export type ParamsType = {
  layoutType?: string;
  isCodeword?: boolean;
  message?: string;
  promotionName?: string;
  promotionDates?: string;
  promotionAddress?: string;
  promotionDescription?: string;
  isSmall?: boolean;
  isVideo?: boolean;
  isCommunityGallery?: boolean;
  isPending?: boolean;
  hasThanksMessage?: boolean;
  isRegistrationOptional?: boolean;
  isPoll?: boolean;
  ballotLayoutType?: string;
  hasGroups?: boolean;
  hasCategories?: boolean;
  promotionColor?: string;
  promotionTextColor?: string;
  isQuizTrivia?: boolean;
  isMobile?: boolean;
};

type PromotionSubType =
  | 'SweepstakesSimple'
  | 'SweepstakesCodeword'
  | 'QuizTrivia'
  | 'QuizPersonality'
  | 'PollStandard'
  | 'Survey'
  | 'VotingBallot'
  | 'NominationAndVotingBallot'
  | 'VideoVotingStandard'
  | 'UGCSweepstakesVideo'
  | 'PhotoVotingStandard'
  | 'PhotoGallery'
  | 'UGCSweepstakesStandard'
  | 'VotingBracket'
  | 'EventSignup';

const promotionPreviews = {
  SweepstakesSimple: (params?: ParamsType) => {
    return formPreview(params);
  },
  SweepstakesCodeword: (params?: ParamsType) => {
    return formPreview({ ...params, isCodeword: true });
  },
  QuizTrivia: quizPreview,
  QuizPersonality: quizPreview,
  PollStandard: pollView,
  Survey: surveyPreview,
  VotingBallot: ballotPreview,
  NominationAndVotingBallot: ballotPreview,
  VideoVotingStandard: (params?: ParamsType) => {
    return ugcPhotoPreview({ ...params, isVideo: true });
  },
  UGCSweepstakesVideo: (params?: ParamsType) => {
    return ugcPhotoPreview({ ...params, isVideo: true });
  },
  PhotoVotingStandard: ugcPhotoPreview,
  PhotoGallery: (params?: ParamsType) => {
    return ugcPhotoPreview({ ...params, isCommunityGallery: true });
  },
  UGCSweepstakesStandard: ugcPhotoPreview,
  VotingBracket: bracketMatchupPreview,
  EventSignup: eventPreview,
};

const thanksPreviews = {
  SweepstakesSimple: sweepstakesThanks,
  SweepstakesCodeword: sweepstakesThanks,
  QuizTrivia: (params?: ParamsType) => {
    return quizThanks(params);
  },
  QuizPersonality: (params?: ParamsType) => {
    return quizThanks(params);
  },
  Survey: surveyThanks,
  VideoVotingStandard: (params?: ParamsType) => {
    return ugcThanks({ ...params, isVideo: true });
  },
  UGCSweepstakesVideo: (params?: ParamsType) => {
    return ugcThanks({ ...params, isVideo: true });
  },
  PhotoVotingStandard: (params?: ParamsType) => {
    return ugcThanks({ ...params, isVideo: false });
  },
  PhotoGallery: (params?: ParamsType) => {
    return ugcThanks({ ...params, isVideo: false });
  },
  UGCSweepstakesStandard: (params?: ParamsType) => {
    return ugcThanks({ ...params, isVideo: false });
  },
  EventSignup: eventThanks,
  PollStandard: eventThanks,
  VotingBallot: eventThanks,
  NominationAndVotingBallot: eventThanks,
  VotingBracket: eventThanks,
};

const entryFormPreviews = {
  SweepstakesSimple: (params?: ParamsType) => {
    return formPreview(params);
  },
  SweepstakesCodeword: (params?: ParamsType) => {
    return formPreview({ ...params, isCodeword: true });
  },
  QuizTrivia: (params?: ParamsType) => {
    return quizForm(params);
  },
  QuizPersonality: (params?: ParamsType) => {
    return quizForm(params);
  },
  Survey: surveyPreview,
  VideoVotingStandard: (params?: ParamsType) => {
    return ugcFormPage({ ...params, isVideo: true });
  },
  UGCSweepstakesVideo: (params?: ParamsType) => {
    return ugcFormPage({ ...params, isVideo: true });
  },
  PhotoVotingStandard: ugcFormPage,
  PhotoGallery: ugcFormPage,
  UGCSweepstakesStandard: ugcFormPage,
  EventSignup: eventPreview,
  PollStandard: (params?: ParamsType) => {
    return pollForm(params);
  },
  VotingBallot: ballotGroupPreview,
  NominationAndVotingBallot: ballotGroupPreview,
  VotingBracket: eventThanks,
};

const entrantPagePreviews = {
  SweepstakesSimple: sweepstakesThanks,
  SweepstakesCodeword: sweepstakesThanks,
  QuizTrivia: quizThanks,
  QuizPersonality: quizThanks,
  Survey: surveyThanks,
  VideoVotingStandard: (params?: ParamsType) => {
    return ugcEntrantPage({ ...params, isVideo: true });
  },
  UGCSweepstakesVideo: (params?: ParamsType) => {
    return ugcEntrantPage({ ...params, isVideo: true });
  },
  PhotoVotingStandard: ugcEntrantPage,
  PhotoGallery: ugcEntrantPage,
  UGCSweepstakesStandard: ugcEntrantPage,
  EventSignup: eventThanks,
  PollStandard: eventThanks,
  VotingBallot: ballotEntrantPreview,
  NominationAndVotingBallot: ballotEntrantPreview,
  VotingBracket: bracketMatchupPreview,
};

export default class PreviewService extends Service {
  @service declare current: CurrentService;

  getPromotionPreview(params?: ParamsType) {
    return promotionPreviews[this.current.promotion.promotionSubType as PromotionSubType](params);
  }

  getThanksPreview(params?: ParamsType) {
    return thanksPreviews[this.current.promotion.promotionSubType as PromotionSubType](params);
  }

  getEntryFormPreview(params?: ParamsType) {
    return entryFormPreviews[this.current.promotion.promotionSubType as PromotionSubType](params);
  }

  getEntrantPagePreview(params?: ParamsType) {
    return entrantPagePreviews[this.current.promotion.promotionSubType as PromotionSubType](params);
  }
}
