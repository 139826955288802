/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { get } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

/**
 * A Route that renders a promotion-specific template if one exists
 * @mixin
 * @deprecated Use the base route class instead - app/-base/promotion-type-route.ts
 */
export default Mixin.create({
  //region Ember hooks
  enums: service(),
  //endregion

  //region Computed Properties
  /**
   * This gets the name of the current promotion type
   */
  get promotionTypeName() {
    const id = get(
      this.modelFor('organizations.organization.organization-promotions.organization-promotion'),
      'organizationPromotion.promotion.promotionTypeId'
    );
    return this.enums.findWhere('PROMOTION_TYPE', { id }, 'name');
  },
  //endregion
});
