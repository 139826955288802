/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { and, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

const PAGE_SIZE = 10;

export default Component.extend({
  //region Attributes
  'message-campaign': null,
  'messaging-statistic': null,
  'single-message-campaign': null,
  'people-who-clicked-chart': null,
  'people-who-clicked-chart-categories-loaded-and-empty': null,
  'people-who-opened-chart': null,
  'people-who-opened-chart-categories-loaded-and-empty': null,
  'total-sends': null,
  'message-view': null,
  'campaign-view': null,
  'message-version-history-id': null,
  //endregion

  current: service(),

  //region Properties
  /**
   * @property {Number}
   */
  peopleWhoClickedPage: 1,

  messageVersionLinkDomain:
    window.location.hostname === 'partners.secondstreet.com' || window.location.hostname.includes('qa-staging')
      ? 'api'
      : 'apidev',
  //endregion

  //region Computed Properties
  get messageVersionLink() {
    return `https://${this.messageVersionLinkDomain}.secondstreetapp.com/message_contents/${this['message-version-history-id']}/${this['message-campaign'].organization.id}`;
  },

  peopleWhoClickedCategories: computed('people-who-clicked-chart.categories.[]', 'peopleWhoClickedPage', function () {
    const page = this.peopleWhoClickedPage;
    return get(this, 'people-who-clicked-chart.categories').slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
  }),

  peopleWhoClickedPaging: computed('peopleWhoClickedPage', 'people-who-clicked-chart.categories.length', function () {
    return {
      pageSize: PAGE_SIZE,
      pageIndex: this.peopleWhoClickedPage,
      totalRecords: get(this, 'people-who-clicked-chart.categories.length'),
    };
  }),

  birthdayMemberCount: readOnly('single-message-campaign.audienceMemberCount'),

  ongoingCampaignMemberCount: computed(
    'messaging-statistic{,.campaignIncompleteCount,.campaignCompletedCount,.campaignInProgressCount}',
    function () {
      const statistic = this['messaging-statistic'];
      return (
        statistic &&
        get(statistic, 'campaignIncompleteCount') +
          get(statistic, 'campaignCompletedCount') +
          get(statistic, 'campaignInProgressCount')
      );
    }
  ),

  useRollingStatistics: and('message-campaign.isNewsletter', 'campaign-view'),

  peopleSentToCount: computed(
    'useRollingStatistics',
    'messaging-statistic.{peopleSentToCount,roundedRollingPeopleSentToCount}',
    function () {
      return this.useRollingStatistics
        ? get(this, 'messaging-statistic.roundedRollingPeopleSentToCount')
        : get(this, 'messaging-statistic.peopleSentToCount');
    }
  ),

  peopleWhoReceivedCount: computed(
    'useRollingStatistics',
    'messaging-statistic.{peopleWhoReceivedCount,roundedRollingPeopleWhoReceivedCount}',
    function () {
      return this.useRollingStatistics
        ? get(this, 'messaging-statistic.roundedRollingPeopleWhoReceivedCount')
        : get(this, 'messaging-statistic.peopleWhoReceivedCount');
    }
  ),

  peopleWhoReceivedPercent: computed(
    'useRollingStatistics',
    'messaging-statistic.{peopleWhoReceivedPercent,rollingPeopleWhoReceivedPercent}',
    function () {
      return this.useRollingStatistics
        ? get(this, 'messaging-statistic.rollingPeopleWhoReceivedPercent')
        : get(this, 'messaging-statistic.peopleWhoReceivedPercent');
    }
  ),

  peopleWhoClickedCount: computed(
    'useRollingStatistics',
    'messaging-statistic.{peopleWhoClickedCount,roundedRollingPeopleWhoClickedCount}',
    function () {
      return this.useRollingStatistics
        ? get(this, 'messaging-statistic.roundedRollingPeopleWhoClickedCount')
        : get(this, 'messaging-statistic.peopleWhoClickedCount');
    }
  ),

  peopleWhoClickedPercent: computed(
    'useRollingStatistics',
    'messaging-statistic.{peopleWhoClickedPercent,rollingPeopleWhoClickedPercent}',
    function () {
      return this.useRollingStatistics
        ? get(this, 'messaging-statistic.rollingPeopleWhoClickedPercent')
        : get(this, 'messaging-statistic.peopleWhoClickedPercent');
    }
  ),

  peopleWhoOpenedCount: computed(
    'useRollingStatistics',
    'messaging-statistic.{peopleWhoOpenedCount,roundedRollingPeopleWhoOpenedCount}',
    function () {
      return this.useRollingStatistics
        ? get(this, 'messaging-statistic.roundedRollingPeopleWhoOpenedCount')
        : get(this, 'messaging-statistic.peopleWhoOpenedCount');
    }
  ),

  peopleWhoOpenedPercent: computed(
    'useRollingStatistics',
    'messaging-statistic.{peopleWhoOpenedPercent,rollingPeopleWhoOpenedPercent}',
    function () {
      return this.useRollingStatistics
        ? get(this, 'messaging-statistic.rollingPeopleWhoOpenedPercent')
        : get(this, 'messaging-statistic.peopleWhoOpenedPercent');
    }
  ),
  //endregion
});
