/* eslint-disable @typescript-eslint/restrict-template-expressions */

import Store from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import { Arc } from '@ss/arc/arc';
import { ArcBoxBlock, ArcHeadingBlock, ArcImageBlock, ArcTextBlock } from '@ss/arc/arc/blocks';
import { findBlocksByType } from '@ss/arc/utils/blocks';
import enums from 'ember-cli-ss-enums/services/enums';
import DesignTemplateModel from 'partner/models/design-template';
import RSVP from 'rsvp';
import CurrentService from './current';
import { getDoc } from 'secondstreet-common/utils/replace-tokens';

const defaultImageSrc =
  'https://images.unsplash.com/photo-1507525428034-b723cf961d3e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=1080';

const regex = /mediadev\.secondstreetapp\.com\/327|media\.secondstreetapp\.com\/1545/;

const promotionEntityTypeId = enums.findWhere('ENTITY_TYPE', {
  name: 'Promotion',
});

type Shadow = {
  name: string;
  tokens?: string[];
  background?: string;
  image?: string;
  backgroundToImage?: boolean;
  headerToCallToAction?: boolean;
  skipPrizeMessage?: boolean;
  prizeMessageElement?: string;
  imageToBackground?: boolean;
};

type TemplateMap = {
  [key: string]: Shadow;
};

const map: TemplateMap = {
  Antiqua: {
    name: 'Headliner',
    tokens: ['preheader', 'header', 'prizeMessage'],
    background: 'ssTemplatePreset',
    image: '',
  },
  Arrows: {
    name: 'Headliner',
    tokens: ['preheader', 'header', 'prizeMessage'],
    background: 'ssTemplatePreset',
    image: '',
  },
  Banner: {
    name: 'Callout',
    tokens: ['callToAction', 'header', 'prizeMessage'],
    background: 'ssTemplatePreset',
    image: '',
  },
  Blockhead: {
    name: 'Headliner',
    tokens: ['preheader', 'header', 'prizeMessage'],
    background: 'ssTemplateHeader',
    image: '',
  },
  Boxy: {
    name: 'Headliner',
    tokens: ['preheader', 'header', 'prizeMessage'],
    background: 'ssTemplatePreset',
    image: '',
  },
  Bubbles: {
    name: 'Headliner',
    tokens: ['preheader', 'header', 'prizeMessage'],
    background: 'ssTemplatePreset',
    image: '',
  },
  Chronicle: {
    name: 'Headliner',
    tokens: ['preheader', 'header', 'prizeMessage'],
    background: 'ssTemplatePreset',
    image: '',
  },
  Cutaway: {
    name: 'Callout',
    tokens: ['header', 'callToAction', 'prizeMessage'],
    background: 'ssImageSmall',
    image: '',
  },
  Fade: {
    name: 'Fade',
    tokens: ['header', 'prizeMessage'],
    background: 'ssBackgroundImage',
    image: '',
    backgroundToImage: true,
    headerToCallToAction: true,
  },
  'Graphic Header': {
    name: 'Graphic Header',
    tokens: ['callToAction'],
    background: '',
    image: 'ssTemplateImage',
    skipPrizeMessage: true,
  },
  Grunge: {
    name: 'Headliner',
    tokens: ['preheader', 'header', 'prizeMessage'],
    background: 'ssTemplatePreset',
    image: '',
    prizeMessageElement: 'span',
  },
  Headliner: {
    name: 'Headliner',
    tokens: ['preheader', 'header', 'prizeMessage'],
    background: 'ssTemplateTop',
    image: '',
  },
  'No Template': {
    name: 'No Template',
  },
  Panel: {
    name: 'Callout',
    tokens: ['header', 'prizeMessage', 'preheader'],
    background: '',
    image: 'ssTemplateImage',
    imageToBackground: true,
  },
  Polaroid: {
    name: 'Headliner',
    tokens: ['preheader', 'header', 'prizeMessage'],
    background: '',
    image: 'ssTemplateImage',
    imageToBackground: true,
  },
  Retro: {
    name: 'Callout',
    tokens: ['header', 'callToAction', 'prizeMessage'],
    background: 'ssTemplatePreset',
    image: '',
  },
  Ribbons: {
    name: 'Headliner',
    tokens: ['preheader', 'header', 'prizeMessage'],
    background: 'ssTemplateContents',
    image: '',
  },
  Simple: {
    name: 'Transparent',
    tokens: ['preheader', 'header', 'prizeMessage'],
    background: 'ssTemplatePreset',
    image: '',
  },
  Transparent: {
    name: 'Transparent',
    tokens: ['header'],
    background: 'ssTemplatePreset',
    image: '',
  },
};

const getShadow = (name: keyof typeof map, isQuiz: boolean) => {
  return isQuiz
    ? {
        ...map,
        Retro: {
          name: 'Headliner',
          tokens: ['header', 'preheader', 'prizeMessage'],
          background: 'ssTemplatePreset',
          image: '',
        },
      }[name]
    : map[name];
};

const findPrimaryColor = (doc: Document): string | undefined => {
  const styleTag = doc.querySelector('style');
  if (styleTag) {
    const styleContent = styleTag.textContent;
    if (styleContent) {
      const primaryColorMatch = styleContent.match(/--mdc-theme-primary:\s*([^;]+);/);
      if (primaryColorMatch && primaryColorMatch[1]) {
        return primaryColorMatch[1].trim();
      }
    }
  }
  return;
};

const findBackgroundImage = (doc: Document): string | undefined => {
  const styleTag = doc.querySelector('style');
  if (styleTag) {
    const styleContent = styleTag.textContent;
    if (styleContent) {
      const backgroundImageMatch = styleContent.match(/background-image:\s*url\(["']?([^)"']+)["']?\)/);
      if (backgroundImageMatch) {
        const [_, url] = backgroundImageMatch;
        if (url && regex.test(url)) {
          return defaultImageSrc;
        }
        return url;
      }
    }
  }
  return;
};

const findImageSrc = (doc: Document): string | undefined => {
  const img = doc.querySelector('.ssTemplateImage img') as HTMLImageElement;

  if (img && img.src && regex.test(img.src)) {
    return defaultImageSrc;
  }

  if (img && img.src) {
    return img.src;
  }
  return;
};

const findHeadingBlock = (arc: Arc) => {
  return findBlocksByType(arc.blocks, 'heading').find(block => block.data?.level == 1);
};

const findSubHeadingBlock = (arc: Arc) => {
  return findBlocksByType(arc.blocks, 'heading').find(block => block.data?.level == 3);
};

const findBoxWithBackground = (arc: Arc) => {
  return findBlocksByType(arc.blocks, 'box').find(block => block.styles?.background?.type == 'image');
};

const findCallToActionBlock = (arc: Arc) => {
  return findBlocksByType(arc.blocks, 'text').firstObject;
};

const findPrizeBlock = (arc: Arc) => {
  return findBlocksByType(arc.blocks, 'text').lastObject;
};

export default class TemplateDesignerService extends Service {
  @service declare store: Store;
  @service declare current: CurrentService;

  async createNewTurnkeyTemplateDesign() {
    const legacyDesignTemplateTypeId = enums.findWhere('TEMPLATE_TYPE', { name: 'Promotion' });
    const customDesignTemplateTypeId = enums.findWhere('TEMPLATE_TYPE', { name: 'Custom Template' });

    const { templates, legacyDesign } = await RSVP.hash({
      templates: this.store.query('design-template', { designTemplateTypeId: customDesignTemplateTypeId }),
      _oldTemplates: this.store.query('design-template', { designTemplateTypeId: legacyDesignTemplateTypeId }),
      legacyDesign: this.store.queryRecord('design', {
        designTemplateType: legacyDesignTemplateTypeId,
        BypassCache: true,
      }),
    });

    const oldTemplateName = legacyDesign?.designTemplate.get('name') as keyof typeof map;
    const primaryColor = findPrimaryColor(getDoc(legacyDesign.get('renderedContent')));

    const shadow = getShadow(oldTemplateName, this.current.promotion.isQuiz);

    if (!shadow) {
      return;
    }

    const newTemplate = templates.find(template => template.get('name') === shadow.name);

    //return;

    if (!newTemplate) {
      return;
    }

    const design = this.store.createRecord('design', {
      designTemplate: newTemplate,
      renderedContent: this.getContent(newTemplate, legacyDesign, shadow),
    });

    const theme =
      primaryColor && primaryColor !== '#00c3c3'
        ? this.store.createRecord('theme', {
            colorElement: primaryColor,
            colorBackground: '#ffffff',
            colorText: '#000000',
            colorButton: '#00c3c3',
            fontHeading: 'Open Sans',
            fontParagraph: 'Open Sans',
            ownerEntityTypeId: promotionEntityTypeId,
            ownerEntityId: this.current.promotion.id,
            name: 'Classic',
          })
        : null;

    await RSVP.all([design.save(), theme?.save()]);
    await this.setupDefaults(design);
    return design;
  }

  async setupDefaults(design: any) {
    await this.setupDefaultTokenValues(design);
    await this.setupSocialElement(design);
  }

  async setupDefaultTokenValues(design: any) {
    //load design token contents
    await this.store.query('design-token-content', { designId: design.id });
    if (design.tokenContents) {
      const title = `Check out ${this.current.promotion.name}`;
      const description = 'Check it out now!';

      const facebookToken = design.tokenContents.find(
        (tokenContent: any) => tokenContent.token.tokenContentType == 'Facebook'
      );
      facebookToken.title = title;
      facebookToken.value = description;

      const xToken = design.tokenContents.find((tokenContent: any) => tokenContent.token.tokenContentType == 'Twitter');
      xToken.title = title;
      xToken.value = description;

      const emailToken = design.tokenContents.find(
        (tokenContent: any) => tokenContent.token.tokenContentType == 'Email'
      );
      emailToken.title = title;
      emailToken.value = description;

      const galleryLayoutToken = design.tokenContents.find(
        ({ tokenCategory }: { tokenCategory: string }) => tokenCategory == 'Gallery Layout'
      );
      galleryLayoutToken.value = '1';

      const thanksToken = design.tokenContents.find(
        ({ tokenCategory }: { tokenCategory: string }) => tokenCategory == 'Thank You Message'
      );
      thanksToken.value = `Thanks for entering ${this.current.promotion.name}`;

      await Promise.all([
        facebookToken.save(),
        xToken.save(),
        emailToken.save(),
        galleryLayoutToken.save(),
        thanksToken.save(),
      ]);
    }
  }

  async setupSocialElement(design: any) {
    //load design token contents
    const arc = new Arc(JSON.parse(design.renderedContent as string));
    const { socialElement } = arc;

    if (!socialElement) {
      return;
    }
    await this.store.query('design-token-content', { designId: design.id });

    if (design.tokenContents) {
      const facebookToken = design.tokenContents.find(
        (tokenContent: any) => tokenContent.token.tokenContentType == 'Facebook'
      );

      socialElement.data.facebookTitle = facebookToken.title;
      socialElement.data.facebookMediaIemId = facebookToken.mediaItemId;

      const xToken = design.tokenContents.find((tokenContent: any) => tokenContent.token.tokenContentType == 'Twitter');
      socialElement.data.xDescription = xToken.title;

      const emailToken = design.tokenContents.find(
        (tokenContent: any) => tokenContent.token.tokenContentType == 'Email'
      );
      socialElement.data.emailSubject = emailToken.title;
      socialElement.data.emailDescription = emailToken.value;

      design.renderedContent = JSON.stringify(arc.content.toJSON());
      await design.save();
    }
  }

  private getContent(template: DesignTemplateModel, design: any, shadow: Shadow) {
    const arc = new Arc(JSON.parse(template.templateContent as string));
    const html = getDoc(design.get('renderedContent'));
    if (html) {
      const preheaderText = html.querySelector('[data-token="preheader"]')?.textContent;
      const headerText = html.querySelector('[data-token="header"]')?.textContent;
      const prizeMessageText = html.querySelector(
        shadow?.prizeMessageElement
          ? `[data-token="prizeMessage"] ${shadow.prizeMessageElement}`
          : '[data-token="prizeMessage"]'
      )?.innerHTML;
      const callToActionText = html.querySelector('[data-token="callToAction"]')?.innerHTML;
      const imageSrc = findImageSrc(html);
      const backgroundImageUrl = findBackgroundImage(html);

      const headerBlock = findHeadingBlock(arc) as ArcHeadingBlock;
      const preHeaderBlock = findSubHeadingBlock(arc) as ArcHeadingBlock;
      const prizeBlock = findPrizeBlock(arc) as ArcTextBlock;
      const callToActionBlock = findCallToActionBlock(arc) as ArcTextBlock;
      const imageBlock = findBlocksByType(arc.blocks, 'image').firstObject as ArcImageBlock;
      const boxBlock = findBoxWithBackground(arc) as ArcBoxBlock;

      if (preHeaderBlock) {
        preHeaderBlock.data.text = shadow.tokens?.includes('preheader') && preheaderText ? preheaderText : '';
      }

      if (headerBlock) {
        headerBlock.data.text = shadow.tokens?.includes('header') && headerText ? headerText : '';
      }

      if (callToActionBlock) {
        callToActionBlock.data.text =
          shadow.tokens?.includes('callToAction') && callToActionText ? callToActionText : '';

        if (shadow.headerToCallToAction && headerText) {
          callToActionBlock.data.text = headerText;
        }
      }

      if (!shadow.skipPrizeMessage && prizeBlock) {
        prizeBlock.data.text = shadow.tokens?.includes('prizeMessage') && prizeMessageText ? prizeMessageText : '';
      }

      if (shadow.image && imageSrc) {
        if (imageBlock) imageBlock.data.src = imageSrc;
        if (shadow.imageToBackground && boxBlock) boxBlock.styles.background.image = imageSrc;
      }

      if (shadow.background && backgroundImageUrl) {
        if (shadow.backgroundToImage) {
          if (imageBlock) {
            imageBlock.data.src = backgroundImageUrl;
          }
        } else {
          if (boxBlock) {
            boxBlock.styles.background.image = backgroundImageUrl;
          }
        }
      }
    }

    return JSON.stringify(arc.content.toJSON());
  }
}
