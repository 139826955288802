/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { computed, get, set, setProperties } from '@ember/object';
import { alias } from '@ember/object/computed';
import Route from '@ember/routing/route';
import { debounce, run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import EmailReportDownload from 'partner/mixins/email-report-download';
import RSVP from 'rsvp';

import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';

// This is pretty bad, but we are relying on two different enums. USER_MESSAGING_STATISTICS_TYPE & REPORT_TYPE
// To generate the downloadable reports we have to look up the equivalent report id
const REPORT_ENUM_MAPPING = {
  1: { name: 'click', id: 10 },
  2: { name: 'open', id: 11 },
  3: { name: 'unsubscribe', id: 12 },
  4: { name: 'issue', id: 13 },
  5: { name: 'recipient', id: 22 },
};
export default Route.extend(AppContextRoute, EmailReportDownload, {
  //region Ember Hooks
  enums: service(),
  store: service(),

  queryParams: {
    messageId: { refreshModel: true },
    messageVersionHistoryId: { refreshModel: true },
    scheduleInstanceId: { refreshModel: true },
  },

  async model(params) {
    const messageCampaignModel = this.modelFor('organizations.organization.message-campaigns.message-campaign');
    const messageCampaign = get(messageCampaignModel, 'messageCampaign');
    const messageCampaignId = get(messageCampaign, 'id');
    const userMessagingStatisticsTypeId = Number.parseInt(params.user_messaging_statistic_type_id, 10);
    const statisticTypes = get(this, 'enums.data')['USER_MESSAGING_STATISTICS_TYPE'];
    const reportTypeMapping = REPORT_ENUM_MAPPING[userMessagingStatisticsTypeId];
    const reportType = get(this, 'enums.data')['REPORT_TYPE'].findBy('id', reportTypeMapping.id);
    const userMessagingStatisticsType = statisticTypes.findBy('id', userMessagingStatisticsTypeId);
    const { messageId, messageVersionHistoryId, scheduleInstanceId } = params;
    const useMessageCampaignId = !get(messageCampaign, 'isDripCampaign') && !get(messageCampaign, 'isNewsletter');
    const records = this.store.query('user-messaging-statistic', {
      userMessagingStatisticsTypeId,
      messageCampaignId: useMessageCampaignId ? messageCampaignId : null,
      messageId: get(messageCampaign, 'isDripCampaign') ? messageId : null,
      messageVersionHistoryId: get(messageCampaign, 'isNewsletter') ? messageVersionHistoryId : null,
    });
    const messageCampaignAudiences = await this.store.query('message-campaign-audience', { messageCampaignId });

    return RSVP.hash({
      isThirdPartyAudience: (await messageCampaignAudiences.firstObject.audience).isThirdPartyAudience,
      messageCampaign,
      records,
      userMessagingStatisticsType,
      messageId,
      messageVersionHistoryId,
      scheduleInstanceId,
      reportType,
    });
  },
  afterModel(model) {
    model.meta = model.records.meta;
    model.records = model.records.toArray();
  },
  //endregion

  //region Computed properties
  messageCampaignId: alias('controller.model.messageCampaign.id'),
  messageId: alias('controller.model.messageId'),
  messageVersionHistoryId: alias('controller.model.messageVersionHistoryId'),

  messageCampaignParams: computed(
    'controller.model.messageCampaign',
    'messageCampaignId',
    'messageId',
    'messageVersionHistoryId',
    function () {
      const { messageCampaign } = this.controller.model;
      const isNewsletter = get(messageCampaign, 'isNewsletter');
      const isDripCampaign = get(messageCampaign, 'isDripCampaign');
      const useMessageCampaignId = !isDripCampaign && !isNewsletter;
      return {
        message_campaign_id: useMessageCampaignId ? this.messageCampaignId : null,
        message_id: isDripCampaign ? this.messageId : null,
        message_version_history_id: isNewsletter ? this.messageVersionHistoryId : null,
      };
    }
  ),

  //endregion

  //region Methods
  async queryRecords(searchValue = null, pageIndex = 1, pageSize = 100) {
    run(() => set(this.controller, 'isLoadingResults', true));
    const model = get(this.controller, 'model');
    const userMessagingStatisticsTypeId = get(model, 'userMessagingStatisticsType.id');
    const normalizedParams = mapKeys(this.messageCampaignParams, (_, k) => camelCase(k));
    const queriedRecords = await this.store.query('user-messaging-statistic', {
      ...normalizedParams,
      userMessagingStatisticsTypeId,
      searchValue,
      pageIndex,
      pageSize,
    });
    const records = queriedRecords.toArray();
    const { meta } = queriedRecords;
    run(() => setProperties(model, { records, meta }));
    run(() => set(this.controller, 'isLoadingResults', false));
  },
  //endregion

  actions: {
    changeEventPage(pageIndex) {
      this.queryRecords(null, pageIndex);
    },
    search(searchValue, pageIndex = 1, pageSize = 100) {
      debounce(this, this.queryRecords, searchValue, pageIndex, pageSize, 300);
    },
  },
});
