/**
 * @param {MessageCampaign[]} messageCampaigns
 * @param {Object} features
 */
const organizationEnabledCampaigns = (messageCampaigns, features) =>
  messageCampaigns.filter(
    messageCampaign =>
      features.get(`has${messageCampaign.messageCampaignType}Emails`) || messageCampaign.isAlwaysConfirmed
  );

/**
 * @param {MessageCampaign[]} messageCampaigns
 * @param {Object} promotion
 * @param {Object} registrationEnabledSetting
 */
const promotionEnabledCampaigns = (messageCampaigns, promotion, registrationEnabledSetting) =>
  messageCampaigns.filter(messageCampaign => {
    if (messageCampaign.isReminder) {
      return promotion.hasReminderEmails;
    }
    if (messageCampaign.isThankYou) {
      return registrationEnabledSetting.value;
    }
    return true;
  });

export { organizationEnabledCampaigns, promotionEnabledCampaigns };
