/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import urlParser from 'js-video-url-parser';

export default Component.extend({
  //region Attributes
  'set-video-details'() {},
  'close-import'() {},
  //endregion

  //region Ember Hooks
  tagName: 'youtube-video-import',
  classNames: 'youtube-video-import',
  //endregion

  //region Properties
  youTubeVideoUrl: '',
  //endregion

  //region Computed Properties
  youTubeVideoId: computed('youTubeVideoUrl', function () {
    const url = this.youTubeVideoUrl;
    if (!url) {
      return null;
    }

    const parsedUrl = urlParser.parse(url);
    if (parsedUrl && parsedUrl.provider === 'youtube' && parsedUrl.id) {
      return parsedUrl.id;
    }

    return null;
  }),
  hasErrors: computed('youTubeVideoUrl', 'youTubeVideoId', function () {
    return this.youTubeVideoUrl && !this.youTubeVideoId;
  }),
  //endregion
});
