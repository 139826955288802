/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

const SETUP_EMAIL_ROUTE = 'organizations.organization.data.audiences.audience.setup.email';

export default Route.extend(AppContextRoute, {
  //region Dependencies
  checklist: service(),
  enums: service(),
  router: service(),
  store: service(),
  //endregion

  //region Ember Hooks
  beforeModel() {
    this._super(...arguments);
    // clear out the checklist, side loaded checklistStep models, and side loaded checklistStepStatuses models. otherwise they would remain in store and
    // could show up for a different promotion
    this.store.unloadAll('checklist');
    this.store.unloadAll('checklistStep');
    this.store.unloadAll('checklistStepStatus');
  },
  async model() {
    this._super(...arguments);
    const audience = get(this.modelFor('organizations.organization.data.audiences.audience'), 'audience');
    const audienceId = get(audience, 'id');
    const checklistService = this.checklist;
    set(checklistService, 'audienceId', audienceId);
    set(checklistService, 'organizationPromotionId', null);
    set(checklistService, 'promotionTypeId', null);
    set(checklistService, 'messageCampaignId', null);
    const checklist = get(await this.store.query('checklist', { audienceId }), 'firstObject');

    return RSVP.hash({
      audience,
      checklist,
      hasData: get(this.modelFor('organizations.organization'), 'organization.hasData'),
      hasMessaging: get(this.modelFor('organizations.organization'), 'organization.hasMessaging'),
    });
  },
  afterModel(model) {
    this._super(...arguments);
    //Seed the checklist model in the checklist service
    const checklistService = this.checklist;
    set(checklistService, 'checklist', get(model, 'checklist'));
  },
  redirect() {
    const audienceType = get(
      this.modelFor('organizations.organization.data.audiences.audience'),
      'audience.audienceType'
    );
    if (audienceType !== 'Subscription') {
      this.router.replaceWith('organizations.organization.data.audiences.audience.filters');
    }
  },
  async setupController(controller, model) {
    this._super(...arguments);
    set(controller, 'model', model);
    set(controller, 'isEditingName', false);
    const messageCampaignTypeId = this.enums.findWhere('MESSAGE_CAMPAIGN_TYPE', { name: 'Welcome' }, 'id');
    const audienceId = get(model, 'audience.id');
    const hasMessaging = get(model, 'hasMessaging');
    const messageCampaigns = hasMessaging
      ? await this.store.query('message-campaign', { messageCampaignTypeId, audienceId })
      : [];

    if (isPresent(messageCampaigns)) {
      const messageCampaign = get(messageCampaigns, 'firstObject');
      const action = get(messageCampaign, 'isConfirmed') ? 'check' : 'uncheck';
      this.send(`${action}ChecklistStep`, SETUP_EMAIL_ROUTE);
    }
  },
  //endregion

  //region Actions
  actions: {
    toggleEditNameModal() {
      this.controller.toggleProperty('isEditingName');
    },
  },
  //endregion
});
