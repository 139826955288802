/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr, belongsTo, hasMany, type SyncHasMany } from '@ember-data/model';
import { computed, get, set } from '@ember/object';
import { alias, and, equal, not, or } from '@ember/object/computed';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import type { EntryFieldValueProperty } from 'partner/types';
import BaseModel from 'secondstreet-common/models/base';
import type EntryModel from './entry';
import type EntryFieldValueModel from './entry-field-value';
import type EntryLocationModel from './entry-location';
import type MatchupModel from './matchup';
import type MatchupEntryRankModel from './matchup-entry-rank';

const entryFieldValueFor = function (param: string) {
  return computed<EntryFieldValueModel | null>(
    'entry.entryFieldValues',
    `entry.entryFieldValues.@each.${param}`,
    function () {
      return !isEmpty(this.entry?.entryFieldValues) ? this.entry?.entryFieldValues.findBy(param, true) : null;
    }
  );
};

@makeBooleanProperties('STATUS_TYPE')
export default class MatchupEntryModel extends BaseModel {
  @service declare enums: EnumsService;

  @attr('number') declare statusTypeId: number;
  @attr('number') declare gameId: number;
  @attr('number') declare promotionId: number;
  @attr('number', { defaultValue: 0 }) declare displayOrder: number;
  @attr('number', { defaultValue: 1 }) declare displayColumn: number;
  @attr('number') declare organizationPromotionId: number;
  @attr('number') declare matchupId: number;
  @attr('boolean') declare isAutoApproved: boolean;

  @belongsTo('entry', { async: false, inverse: 'matchupEntry' }) declare entries: EntryModel;
  @alias('entries') declare entry: MatchupEntryModel['entries'];
  @belongsTo('matchup', { async: false }) declare matchup: MatchupModel;
  @belongsTo('matchup-entry-rank', { async: false }) declare matchupEntryRank: MatchupEntryRankModel;
  @belongsTo('promotion', { async: false }) declare promotion: any;
  @hasMany('entry-location', { async: false }) declare entryLocations: SyncHasMany<EntryLocationModel>;

  /**
   * Ember Data does not dirty the model if its belongsTo model was switched out, so we manually toggle it
   */
  @tracked dirtyMatchupRelationship = false;

  get guid() {
    return guidFor(this);
  }

  @entryFieldValueFor('isEntrySourceImageUrl') fullSizeImageUrl?: EntryFieldValueModel;
  @entryFieldValueFor('isEntrySourceVideoUrl') videoUrl?: EntryFieldValueModel;
  @entryFieldValueFor('isEntrySourceVideoLowResUrl') videoLowResUrl?: EntryFieldValueModel;
  @entryFieldValueFor('isEntrySourceVideoPreviewImageUrl') videoPreviewImageUrl?: EntryFieldValueModel;
  @entryFieldValueFor('isEntrySourceUsername') username?: EntryFieldValueModel;
  @entryFieldValueFor('isEntrySourcePostDateCreated') dateCreated?: EntryFieldValueModel;
  @entryFieldValueFor('isMediaCaption') mediaCaption?: EntryFieldValueModel;
  @entryFieldValueFor('isMediaTitle') mediaTitle?: EntryFieldValueModel;
  @entryFieldValueFor('isEntrySourceType') sourceType?: EntryFieldValueModel;
  @entryFieldValueFor('isEntrySourceMediaType') sourceMediaType?: EntryFieldValueModel;
  @entryFieldValueFor('isEntrySourcePostId') sourcePostId?: EntryFieldValueModel;
  @entryFieldValueFor('isEntrySourceImageThumbUrl') thumbnailImageUrl?: EntryFieldValueModel;
  @entryFieldValueFor('isEntrySourcePostUrl') sourcePostUrl?: EntryFieldValueModel;
  @entryFieldValueFor('isPhotoUpload') photoUpload?: EntryFieldValueModel;
  @entryFieldValueFor('isVideoEntryUpload') videoUpload?: EntryFieldValueModel;
  @entryFieldValueFor('isEntryPhoneNumber') phoneNumber?: EntryFieldValueModel;
  @entryFieldValueFor('isWebsiteUrl') websiteUrl?: EntryFieldValueModel;
  @entryFieldValueFor('isFacebookUrl') facebookUrl?: EntryFieldValueModel;
  @entryFieldValueFor('isTwitter') twitterUsername?: EntryFieldValueModel;
  @entryFieldValueFor('isYouTubeChannel') youtubeUrl?: EntryFieldValueModel;
  @entryFieldValueFor('isInstagramHandle') instagramUsername?: EntryFieldValueModel;
  @entryFieldValueFor('isTikTokHandle') tikTokHandle?: EntryFieldValueModel;
  @entryFieldValueFor('isSnapChatHandle') snapchatHandle?: EntryFieldValueModel;
  @entryFieldValueFor('isLinkedInProfileUrl') linkedInUrl?: EntryFieldValueModel;
  @entryFieldValueFor('isVimeoChannelUrl') vimeoChannelUrl?: EntryFieldValueModel;
  @entryFieldValueFor('isSeed') seed?: EntryFieldValueModel;
  @entryFieldValueFor('isFeaturedEntrant') featuredEntrant?: EntryFieldValueModel;
  @entryFieldValueFor('isLogo') logo?: EntryFieldValueModel;
  @entryFieldValueFor('isAutomaticallyAddEntrants') automaticallyAddEntrants?: EntryFieldValueModel;

  @alias('seed.value') declare seedValue: string;
  @equal('sourceType.value', 'YouTube') declare isFromYouTube: string;
  @equal('sourceType.value', 'Instagram') declare isFromInstagram: boolean;
  @equal('sourceMediaType.value', 'video') declare isVideo: boolean;
  @not('isVideo') declare isPhoto: boolean;
  @equal('sourceType.value', 'SecondStreet') declare isSecondStreetHosted: boolean;
  @and('isVideo', 'isSecondStreetHosted') declare isUploadedVideo: boolean;
  @and('isPhoto', 'isSecondStreetHosted') declare isUploadedPhoto: boolean;
  @or('hasDirtyAttributes', 'dirtyMatchupRelationship') declare hasDirtyMatchupOrAttributes: boolean;
  @alias('featuredEntrant.value') isFeatured?: boolean;

  @computed('entry.entryFieldValues.@each.isSocialField')
  get hasOnlyOneSocialField() {
    return this.entry.entryFieldValues.filterBy('isSocialField').length === 1;
  }

  @computed('hasDirtyAttributes', 'isDeleted', 'entry.{isDirty,hasDirtyEntryFieldValues}')
  get isDirty(): boolean {
    return !!this.hasDirtyAttributes || !!this.isDeleted || this.entry?.isDirty || this.entry?.hasDirtyEntryFieldValues;
  }

  get mediaItemValue(): { type: 'image' | 'video' | 'youtube' | 'external'; value: string; meta?: string } | null {
    if (this.isUploadedVideo && this.videoUpload?.value)
      return { type: 'video', value: this.videoUpload.value as string };

    if (this.isUploadedPhoto && this.photoUpload?.value)
      return { type: 'image', value: this.photoUpload.value as string };

    if (this.isFromYouTube && this.sourcePostId?.value)
      return { type: 'youtube', value: this.sourcePostId?.value as string };

    if (this.fullSizeImageUrl?.value) return { type: 'external', value: this.fullSizeImageUrl.value as string };

    return null;
  }

  get matchupIdAsString() {
    return `${this.matchupId}`;
  }

  get name() {
    return this.mediaTitle?.value as string;
  }

  get hasAds(): boolean {
    return isPresent(this.store.peekAll('sponsored-post').findBy('ownerEntityId', +this.id));
  }

  toggleDirtyMatchupState(bool: boolean) {
    set(this, 'dirtyMatchupRelationship', bool);
  }

  setDefaults() {
    this.displayOrder ||=
      (this.matchup?.matchupEntries.reduce((acc, { displayOrder }) => Math.max(acc, displayOrder), 0) || 0) + 1;

    if (this.mediaTitle?.value) {
      this.entry.name = this.mediaTitle?.value?.toString();
    }
  }

  rollbackWithEntryFieldValues() {
    this.entry.entryFieldValues
      .toArray()
      .forEach(entryFieldValue =>
        entryFieldValue.get('isNew') ? entryFieldValue.deleteRecord() : entryFieldValue.rollbackAttributes()
      );

    this.entry.rollbackAttributes();
    this.rollbackAttributes();
  }

  async cleanAndSave() {
    await this.destroyEmptyEntryFieldValues();
    this.setDefaults();
    await this.save();
  }

  async saveWithEntryFieldValues(mediaItemTransform?: any) {
    await this.destroyEmptyEntryFieldValues();

    // It seems like the backend cannot save multiple entry field values with the same field id
    // that's why we need to save them before saving the matchup entry
    await Promise.all(this.entry.entryFieldValues.filterBy('isNew').invoke('save'));

    await Promise.all([this.save(), mediaItemTransform?.isNew ? mediaItemTransform.save() : Promise.resolve(null)]);
  }

  save() {
    // eslint-disable-next-line ember/no-get
    if (get(this, 'isNew') && this.belongsTo('matchup' as any).value()) {
      this.matchup?.incrementMatchupEntriesCount();
    }

    return super.save();
  }

  destroyRecord() {
    if (this.belongsTo('matchup' as any).value()) {
      this.matchup?.decrementMatchupEntriesCount();
    }

    return super.destroyRecord();
  }

  destroyEmptyEntryFieldValues() {
    return Promise.all(
      this.entry.entryFieldValues
        .filter(entryFieldValue => entryFieldValue.isDeleted || isBlank(entryFieldValue.value))
        .map(entryFieldValue => entryFieldValue.destroyRecord())
    );
  }

  maybeCreateEntryFieldValue(property: EntryFieldValueProperty, entryFieldName: string, value: any = '') {
    return this[property] || this.createEntryFieldValue(entryFieldName, value);
  }

  overwriteEntryFieldValues(entryFieldValues: EntryFieldValueModel[]) {
    entryFieldValues.forEach(entryFieldValue => {
      const existing = this.entry.entryFieldValues.findBy('fieldId', entryFieldValue.fieldId);
      if (existing) {
        existing.value = entryFieldValue.value;
      } else {
        this.entry.entryFieldValues.addObject(
          this.createEntryFieldValue(entryFieldValue.fieldId, entryFieldValue.value)
        );
      }
    });
  }

  /**
   * Creates an entry field value for a given entry field name.
   * Options include: 'Photo Upload', 'Media Title', 'Media Caption', 'Entry Source Type'
   */
  private createEntryFieldValue(entryFieldNameOrId: string | number, value: any = '') {
    const fieldId =
      typeof entryFieldNameOrId === 'number'
        ? entryFieldNameOrId
        : this.enums.findWhere('ENTRY_FIELD', {
            name: entryFieldNameOrId,
          });

    return this.store.createRecord('entry-field-value', {
      entry: this.entry,
      matchup: this.matchup,
      fieldId,
      value,
    });
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'matchup-entry': MatchupEntryModel;
  }
}
