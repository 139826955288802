import { attr, hasMany, SyncHasMany } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';
import type PostalCodeModel from './postal-code';
import type StateProvinceModel from './state-province';
import { tracked } from '@glimmer/tracking';

export default class ParticipationAreaModel extends BaseModel {
  @attr('string') declare countryName: string;
  @attr('number') declare countryId: number;
  @attr('number') declare promotionId: number;

  @tracked isMarkedForDeletion = false;

  @hasMany('state-province', { async: false }) declare stateProvinces: SyncHasMany<StateProvinceModel>;
  @hasMany('postal-code', { async: false }) declare postalCodes: SyncHasMany<PostalCodeModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'participation-area': ParticipationAreaModel;
  }
}
