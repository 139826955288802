/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

//region utils
const fieldForType = name =>
  computed('fields.@each.id', function () {
    const fieldId = `${this.enums.findWhere('FIELD', { name }, 'id')}`;
    return this.fields.findBy('id', fieldId);
  });
//endregion

export default Component.extend({
  //region Dependencies
  enums: service(),
  //endregion

  //region Attributes
  /**
   * @type {string}
   */
  'default-title-form-field-title': null,
  /**
   * @type {string}
   */
  'default-title-form-field-label-text': null,
  /**
   * @type {string}
   */
  'default-caption-form-field-label-text': null,
  /**
   * @type {string}
   */
  'default-photo-entry-upload-form-field-label-text': null,
  /**
   * @type {string}
   */
  'media-release-form-field-label-text': null,
  /**
   * @type {FormField}
   */
  'title-form-field': null,
  /**
   * @type {FormField}
   */
  'caption-form-field': null,
  /**
   * @type {FormField}
   */
  'photo-entry-upload-form-field': null,
  /**
   * @type {FormField}
   */
  'media-release-form-field': null,
  /**
   * @type {bool}
   */
  'has-form-fields-changed': false,
  /**
   * @type {function}
   */
  'changed-form-field-value': x => x,
  /**
   * @type {function}
   */
  'create-form-field': x => x,
  /**
   * @type {function}
   */
  'delete-form-field': x => x,
  /**
   * @type {Field[]}
   */
  fields: null,
  //endregion

  //region Computed Properties
  titleField: fieldForType('PhotoTitle'),
  captionField: fieldForType('PhotoCaption'),
  mediaReleaseField: fieldForType('MediaReleaseConsent'),
  photoEntryUploadField: fieldForType('PhotoEntryUpload'),
  //endregion
});
