import RSVP from 'rsvp';

/**
 * @param {Number} ms - A number of milliseconds after which the Promise should resolve
 * @returns {Promise} Returns a promise that resolves after `ms` milliseconds (with `ms` as its value)
 */
export function timer(ms) {
  return new RSVP.Promise(resolve => {
    window.setTimeout(resolve, ms, ms);
  });
}
