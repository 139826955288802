/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal, notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import enums from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';

/**
 * @typedef {DS.Model} Token
 * @implements DesignTokenModel
 */
export default class TokenModel extends BaseModel {
  // region Attributes
  @attr('number') tokenTypeId;
  @attr('number') tokenContentTypeId;
  @attr('string') key;
  @attr('string') name;
  @attr('string') description;
  @attr('boolean', { defaultValue: false }) allowMultiple;
  @attr('string') category;
  @attr('string') placeholder;
  @attr('number') fieldId;
  //endregion

  //region Relationships
  @hasMany('token-default-content', { async: false, inverse: 'token' })
  tokenDefaultContents;
  //endregion

  //region Dependencies
  @service enums;
  //endregion

  //region Computed Properties
  get allowDisable() {
    return this.tokenContentType !== 'Color';
  }
  /**
   * Eventually, this property's definition will likely change
   * to include other repeatable tokens.
   * @property {Ember.ComputedProperty<Boolean>}
   */
  @equal('category', 'Items')
  isSortable;

  @computed('tokenDefaultContents.@each.{isPlaceholder,isNew}')
  get tokenDefaultContent() {
    return this.tokenDefaultContents.find(tdc => !tdc.isPlaceholder && !tdc.isNew);
  }

  @computed('tokenDefaultContents.@each.isPlaceholder')
  get placeholderTokenDefaultContent() {
    return this.tokenDefaultContents.findBy('isPlaceholder', true);
  }

  @notEmpty('tokenDefaultContent')
  hasCustomValue;

  @enums.computed('name', 'tokenTypeId', 'tokenTypeId')
  tokenType;

  @enums.computed('name', 'tokenContentTypeId', 'tokenContentTypeId')
  tokenContentType;

  @enums.computed('editorComponent', 'tokenContentTypeId', 'tokenContentTypeId')
  tokenContentTypeEditorComponent;

  get token() {
    if (
      this.tokenType === 'Template' &&
      (this.tokenContentType === 'HtmlText' || this.tokenContentType === 'RawHtml')
    ) {
      return `{{{${this.key}}}}`;
    }
    return `{{${this.key}}}`;
  }

  get dynamicTokenType() {
    if (this.tokenType === 'Template') {
      return undefined;
    }
    return this.enums.data.DYNAMIC_TOKEN.findBy('name', this.key)?.type || 'User';
  }

  get materialIconKey() {
    return this.enums.data.TOKEN_CONTENT_TYPE.findBy('name', this.tokenContentType)?.materialIconKey;
  }
  //endregion
}
