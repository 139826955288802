/**
 * Mapping of various ad sizes and dimensions that we offer for adgagements.
 */

export const AD_SIZES = [
  {
    className: 'square',
    displayName: 'Square',
    dimensions: '250x250',
  },
  {
    className: 'small-rect',
    displayName: 'Small Rectangle',
    dimensions: '300x250',
  },
  {
    className: 'large-rect',
    displayName: 'Large Rectangle',
    dimensions: '336x280',
  },
  {
    className: 'half-page',
    displayName: 'Half Page',
    dimensions: '300x600',
  },
];

export const AD_TYPE_BANNER = 'Banner Ad';
export const AD_TYPE_PROGRAMMATIC = 'Programmatic Ad';
export const AD_TYPES = [AD_TYPE_BANNER, AD_TYPE_PROGRAMMATIC];
