/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/no-computed-properties-in-native-classes */
/* eslint-disable ember/use-brace-expansion */ // FIXME
import { inject as controller } from '@ember/controller';
import FormController from 'partner/controllers/form-controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { equal, or } from '@ember/object/computed';
import { computed, get, set } from '@ember/object';

import type Store from '@ember-data/store';
import type SnackbarService from 'secondstreet-common/services/snackbar';
import type { RegistrationFormModel } from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/forms/form';

interface SourceOption {
  value: number;
  label: string;
  tooltip: string;
}

export default class OrganizationFormController extends FormController {
  @service declare store: Store;
  @service declare snackbar: SnackbarService;

  declare model: RegistrationFormModel;

  queryParams = ['optin-id'];

  sourceOptions: SourceOption[] = [
    { value: 0, label: 'Source promotion fields', tooltip: 'Only fields on this form will be used' },
    {
      value: 1,
      label: 'Child organization default form fields',
      tooltip:
        'All fields on this form except Email will be removed, and only fields on the child default form will be used',
    },
    {
      value: 2,
      label: 'Source promotion fields and local opt-in fields',
      tooltip: 'All fields on this form will be used and opt-in fields on the child default form will be used',
    },
  ];

  closeDropdownHandler: (() => void) | null = null;

  @tracked 'optin-id': string | null = null;
  @tracked formSettingsOpen = true;
  @tracked selectedSource: SourceOption | undefined = this.sourceOptions[0];

  // @ts-ignore
  @controller('organizations.organization.organization-promotions.organization-promotion.setup')
  declare setupController: any;

  @or(
    'model.form.hasDirtyAttributes',
    'model.organizationPromotion.hasDirtyAttributes',
    'model.organizationRegistrationFormSetting.isDirty',
    'setupController.hasDirtyAttributes',
    'didSomethingChange',
    'model.enabledSetting.hasDirtyAttributes'
  )
  isAnythingDirty!: boolean;

  @or('model.form.hasDirtyAttributes', 'didSomethingChange')
  isAnythingRelevantDirty!: boolean;

  @equal('model.enabledSetting.value', 0)
  enabledSettingDisabled!: boolean;

  @equal('model.enabledSetting.value', 1)
  enabledSettingRequired!: boolean;

  @equal('model.enabledSetting.value', 2)
  enabledSettingOptional!: boolean;

  @computed('model.sweepstakes.isEnabled', 'model.sweepstakes.endDate')
  get allowNoRegistration() {
    // @ts-ignore
    return get(this, 'model.sweepstakes.isEnabled') ? get(this, 'model.sweepstakes.endDate') < new Date() : true;
  }

  @action
  setup(dropdown: any) {
    this.closeDropdownHandler = this.closeSourceDropdown(dropdown);
    window.addEventListener('scroll', this.closeDropdownHandler);
  }

  @action
  cleanup() {
    if (this.closeDropdownHandler) {
      window.removeEventListener('scroll', this.closeDropdownHandler);
      this.closeDropdownHandler = null;
    }
  }

  @action
  closeSourceDropdown(dropdown: any) {
    return () => {
      dropdown.actions.close();
    };
  }

  @action
  changeEnabledSetting(value: number) {
    if (get(this, 'model.participationAreaEnabled.length')) {
      this.snackbar.show('Registration is required to enforce the participation area.');
      return;
    }
    // @ts-ignore
    set(this, 'model.enabledSetting.value', value);
  }

  @action
  changeFormSourceSetting(option: SourceOption) {
    this.selectedSource = option;

    // @ts-ignore
    set(this, 'model.organizationRegistrationFormSetting.value', option.value);
  }

  @action
  toggleEditingGlobalOptinId(globalOptinId: string | null) {
    set(this, 'optin-id', globalOptinId || null);
  }

  @action
  fetchForm() {
    this.model.form.reload();
  }
}
