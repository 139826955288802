/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { next } from '@ember/runloop';

export default Component.extend({
  //region Attributes
  register: x => x,
  unregister: x => x,
  'visible-tabs': null,
  //endregion

  //region Ember Hooks
  tagName: 'span',
  classNameBindings: ['isHiddenOnMobile:hideOnMobile'],
  willDestroy() {
    this._super(...arguments);
    this.unregister(this);
  },
  didInsertElement() {
    this._super(...arguments);
    this.register(this);
    next(() => this.notifyPropertyChange('is-tab-visible'));
  },
  //endregion

  //region Computed Properties
  isHiddenOnMobile: computed('visible-tabs.[]', function () {
    return !this['visible-tabs'].includes(this);
  }),
  //endregion

  init() {
    this._super(...arguments);

    set(this, 'visible-tabs', this['visible-tabs'] || []);
  },
});
