import { action, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class LoginTotpRoute extends Route {
  //region Dependencies
  @service session;
  @service router;
  //endregion

  //region Properties
  // Save this on the route in case
  // ember-simple-auth blows away the session
  // when we don't want it to, like when a user
  // tries a new 2fa code submission attempt
  @tracked currentSession = null;
  //endregion

  //region Ember Hooks
  redirect() {
    if (!this.session.isAuthenticated) {
      this.router.replaceWith('login');
    } else if (this.session.attemptedTransition?.targetName !== 'reset-password') {
      if (!this.session.isTwoFactorUser) {
        window.history.replaceState({}, '', '/');
        this.router.replaceWith('users.user', this.session.data?.authenticated?.organization_users.user_id);
      } else if (this.session.isTwoFactorAuthRequired && !this.session.isTwoFactorAuthDeviceSetUp) {
        this.router.replaceWith('setup.totp');
      }
    }
  }
  model() {
    return {
      hasRecoveryEmail: this.session.data?.authenticated?.user_two_factor_auth_recovery_email_address,
    };
  }
  afterModel() {
    set(this, 'currentSession', this.session.data?.authenticated);
  }
  //endregion

  //region Actions
  @action
  async submit2fa(code) {
    const token = this.currentSession?.access_token;
    const data = {
      token,
      id: this.currentSession?.id,
      access_code: code,
    };
    try {
      const { attemptedTransition } = this.session;
      await this.session.authenticate('authenticator:secondStreet', data);
      set(this, 'currentSession', null);
      if (attemptedTransition) {
        attemptedTransition.retry();
        set(this, 'session.attemptedTransition', null);
        return;
      }
      this.router.transitionTo('index');
    } catch ({ payload: { errors } }) {
      set(this, 'controller.errors', errors);
    }
  }
  //endregion
}
