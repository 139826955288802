/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

export default Route.extend(AppContextRoute, {
  store: service(),

  //region Ember Hooks
  model() {
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');

    return RSVP.hash({
      wrappers: this.store.query('wrapper', { organizationId }).then(x => x.toArray()),
    }).then(hash => {
      set(
        hash,
        'wrappers',
        hash.wrappers.map(x => (get(x, 'isInherited') ? x.createCopy(['id', 'isInherited']) : x))
      );
      return hash;
    });
  },
  //endregion

  //region Actions
  actions: {
    willTransition(transition) {
      if (get(this, 'controller.isAnythingDirty')) {
        const really = window.confirm(
          'Are you sure you want to leave? Required fields are missing or you have unsaved changes.'
        );
        if (!really) {
          transition.abort();
        }
      }
    },
    save() {
      get(this.modelFor('organizations.organization.wrapper'), 'wrappers').map(x => x.save());
    },
  },
  //endregion
});
