/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend({
  //region Relationships
  matchupPlace: DS.belongsTo('matchup-place', { async: true }),
  prize: DS.belongsTo('prize', { async: true }),
  //endregion
});
