/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Route from '@ember/routing/route';

export default Route.extend({
  async model() {
    const audienceModel = this.modelFor('organizations.organization.data.audiences.audience');
    const optinField = await audienceModel.audience.optinField;
    return { optinField };
  },
});
