/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import { alias, bool } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';
import dirtyProperty from 'partner/utils/dirty-property';

const { attr, hasMany } = DS;

/**
 * @typedef {DS.Model} DesignToken
 * @implements DesignTokenModel
 */
export default BaseModel.extend({
  tokenTypeId: attr('number', { defaultValue: 1 }),
  tokenContentTypeId: attr('number'),
  key: attr('string'),
  name: attr('string'),
  description: attr('string'),
  allowMultiple: attr('boolean', { defaultValue: false }),
  allowDisable: attr('boolean', { defaultValue: true }),
  category: attr('string'),
  isPageContentToken: attr('boolean'),
  isInherited: attr('boolean'),
  organizationId: attr('number'),

  //region Relationships
  defaultDesignTokenContents: hasMany('design-token-content', {
    async: false,
    inverse: null,
  }),
  // This one must be defined so that design-token-contents related to a design-token
  // don't go into the defaultDesignTokenContents relationship by default.
  designTokenContents: hasMany('design-token-content', {
    async: true,
    inverse: 'designToken',
  }),

  isNameDirty: dirtyProperty('name'),
  //endregion

  //region Computed Properties
  tokenDefaultContents: alias('defaultDesignTokenContents'),
  tokenType: enums.computed('name', 'tokenTypeId', 'tokenTypeId'),
  tokenContentType: enums.computed('name', 'tokenContentTypeId', 'tokenContentTypeId'),
  tokenContentTypeEditorComponent: enums.computed('editorComponent', 'tokenContentTypeId', 'tokenContentTypeId'),
  token: computed('key', function () {
    return `{{${this.key}}}`;
  }),
  inheritedDefaultContent: computed('defaultDesignTokenContents.@each.isInherited', function () {
    return this.defaultDesignTokenContents.findBy('isInherited');
  }),
  customDefaultContent: computed('defaultDesignTokenContents.@each.isInherited', function () {
    return this.defaultDesignTokenContents.findBy('isInherited', false);
  }),
  currentDefaultContent: computed('inheritedDefaultContent', 'customDefaultContent', 'name', 'id', function () {
    if (isPresent(this.defaultDesignTokenContents)) {
      return this.customDefaultContent || this.inheritedDefaultContent;
    }
    throw new Error(
      `Design token ${this.id}:${this.name} has not been assigned any default design token content records.`
    );
  }),
  currentDefaultContentIsEqualToInherited: computed(
    'customDefaultContent.value',
    'inheritedDefaultContent.value',
    function () {
      return (
        this.inheritedDefaultContent &&
        this.customDefaultContent &&
        this.customDefaultContent.value === this.inheritedDefaultContent.value
      );
    }
  ),
  currentDefaultIsDisabled: bool('currentDefaultContent.isDisabled'),
  currentDefaultValue: alias('currentDefaultContent.value'),
  //endregion
});
