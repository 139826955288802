/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import isAnyPath from 'partner/utils/is-any-path';

export default class OrganizationsOrganizationWrapperController extends Controller {
  @isAnyPath('hasDirtyAttributes', ['model.wrappers.[]'])
  isAnythingDirty;

  @alias('model.wrappers')
  wrappers;

  get smallDeviceWrapper() {
    return this.wrappers.filterBy('browserPlatformType', 'Mobile')[0];
  }

  get largeDeviceWrapper() {
    return this.wrappers.filterBy('browserPlatformType', 'Desktop')[0];
  }

  @action
  editSmallWrapperType(id) {
    this.smallDeviceWrapper.wrapperMethodTypeId = id;
  }

  @action
  editLargeWrapperType(id) {
    this.largeDeviceWrapper.wrapperMethodTypeId = id;
  }
}
