/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, getProperties, set, setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import flatten from 'lodash/flatten';
import { generateWidgetPreview } from 'partner/utils/designer-preview';
import * as ReactDOM from 'react-dom';
import RSVP from 'rsvp';
import { spreadIf } from 'secondstreet-common/utils/functional';

const doesNotHaveMessaging = context =>
  !get(context.modelFor('organizations.organization'), 'organization.hasMessaging');

export default Route.extend({
  //region Ember Dependencies
  enums: service(),
  router: service(),
  store: service(),
  settings: service(),
  //endregion

  //region Ember Hooks
  queryParams: {
    designId: {
      refreshModel: true,
    },
  },
  async model(params) {
    if (doesNotHaveMessaging(this)) {
      return;
    }

    const getTemplateTypeIdFor = name => this.enums.findWhere('TEMPLATE_TYPE', { name }, 'id');

    const audience = get(this.modelFor('organizations.organization.data.audiences.audience'), 'audience');
    const audienceId = get(this.modelFor('organizations.organization.data.audiences.audience'), 'audience.id');
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');

    const designsPromise = this.store.query('design', { audienceId, organizationId });

    const embeddedTemplatePromise = this.store.query('design-template', {
      organizationId,
      designTemplateTypeId: getTemplateTypeIdFor('Audience - Embedded'),
    });
    const exitIntentTemplatePromise = this.store.query('design-template', {
      organizationId,
      designTemplateTypeId: getTemplateTypeIdFor('Audience - Exit Intent'),
    });
    const designTokensPromise = this.store.query('design-token', { organizationId });
    const audiencesPromise = this.store.query('audience', { organizationId });
    const starredFieldsPromise = this.store.query('field', { isStarred: true });
    const widgetFormPromise = params.designId ? this.store.queryRecord('form', { designId: params.designId }) : null;

    const designs = (await designsPromise).toArray();

    const embeddedTemplates = (await embeddedTemplatePromise).toArray();
    const exitIntentTemplates = (await exitIntentTemplatePromise).toArray();

    const designTemplates = [...embeddedTemplates, ...exitIntentTemplates];

    const designTemplate = get(designTemplates, 'firstObject');

    if (isEmpty(designs)) {
      const newDesign = await this.createDesign(designTemplate, audienceId).save();
      designs.addObject(newDesign);
    }

    const designTokens = (await designTokensPromise).filter(token => get(designTemplate, 'tokens').includes(token));

    const foundTokenContents = await RSVP.all(
      designs.map(design => this.store.query('design-token-content', { designId: get(design, 'id') }))
    );

    const tokenContents = await RSVP.all(
      foundTokenContents.map(tokenContents =>
        get(designTemplate, 'tokens').map(
          token =>
            tokenContents.findBy('designToken', token) ||
            this.createTokenContent(token, get(designs, 'firstObject'), audience).save()
        )
      )
    );

    return RSVP.hash({
      designs,
      designTemplate,
      designTemplates,
      audience,
      designTokens,
      starredFields: await starredFieldsPromise,
      organization: get(this.modelFor('organizations.organization'), 'organization'),
      tokenContents: flatten(tokenContents),
      dipsUrl: this.settings.preload('dips_url').then(() => this.settings.getFor('dips_url')),
      widgetForm: await widgetFormPromise,
      subscriptionAudiences: (await audiencesPromise).filterBy('isSubscriptionAudience'),
    });
  },
  afterModel(model, transition) {
    if (doesNotHaveMessaging(this)) {
      return;
    }

    if (!transition.to.queryParams.designId) {
      transition.abort();
      this.router.replaceWith('organizations.organization.data.audiences.audience.setup.widget', {
        queryParams: { designId: model.designs.firstObject.id },
      });
    }
  },
  setupController(controller) {
    this._super(...arguments);
    if (doesNotHaveMessaging(this)) {
      set(controller, 'showSignupWidgetUpsell', true);
    }
  },
  //endregion

  //region Methods
  createDesign(designTemplate, audienceId) {
    const newDesign = this.store.createRecord('design');
    set(newDesign, 'designTemplate', designTemplate);
    set(newDesign, 'audienceId', audienceId);
    set(newDesign, 'renderedContent', '<!-- -->');
    return newDesign;
  },
  createTokenContent(designToken, design, audience) {
    const organization = get(this.modelFor('organizations.organization'), 'organization');

    const newTokenContent = this.store.createRecord('design-token-content');
    const defaultContent = get(designToken, 'tokenDefaultContents.firstObject');
    setProperties(newTokenContent, {
      organization,
      designToken,
      design,
      ...spreadIf(
        defaultContent,
        getProperties(defaultContent, 'isDisabled', 'value', 'linkUrl', 'altText', 'title', 'mediaItemId')
      ),
      ...spreadIf(get(designToken, 'key') === 'signupOptin', {
        fieldId: audience ? parseInt(get(audience, 'entityId'), 10) : null,
        value: audience ? get(audience, 'name') : null,
      }),
    });

    return newTokenContent;
  },
  maybeCheckChecklistStep(areAllContentsComplete, hasGrabbedCode) {
    if (areAllContentsComplete && hasGrabbedCode) {
      this.send('checkChecklistStep', 'organizations.organization.data.audiences.audience.setup.widget');
    }
  },
  async copyDesign(design, designTemplate) {
    const setSaveState = bool => run(() => set(this, 'controller.isAnythingSaving', bool));
    setSaveState(true);

    const newDesign = this.createDesign(designTemplate, get(this, 'controller.model.audience.id'));
    const newTokenContents = get(design, 'tokenContents')
      .filter(tokenContent => get(designTemplate, 'designTokens').includes(get(tokenContent, 'designToken')))
      .map(tokenContent => {
        const copy = tokenContent.createCopy(['id', 'design', 'organization']);
        set(copy, 'design', newDesign);
        return copy;
      });

    const signupOptinTokenContents = newTokenContents.filterBy('fieldId');
    const allOtherTokenContents = newTokenContents.rejectBy('fieldId');

    try {
      await newDesign.save();

      //for some reason signup optin design tokens are subject to a race condition
      //if we want to preserve their order from the first widget, we need to save them one at a time
      for (let i = 0; i < get(signupOptinTokenContents, 'length'); i++) {
        await signupOptinTokenContents.objectAt(i).save();
      }

      await RSVP.all(allOtherTokenContents.map(tc => tc.save()));
      return newDesign;
    } catch (err) {
      newDesign.destroyRecord();
      RSVP.all(newTokenContents.map(tc => tc.destroyRecord()));
    } finally {
      setSaveState(false);
    }
  },
  //endregion

  actions: {
    didTransition() {
      const widgetPreviewNode = document.querySelector('#widget-preview');

      if (widgetPreviewNode) {
        // need to unmount the widget component to ensure updated states
        ReactDOM.unmountComponentAtNode(widgetPreviewNode);

        const {
          activeDesign,
          model: { dipsUrl, widgetForm },
        } = this.controller;

        set(
          activeDesign,
          'renderedContent',
          ReactDOM.render(generateWidgetPreview(activeDesign, dipsUrl, widgetForm), widgetPreviewNode)
        );
      }
    },

    setActiveDesign(id) {
      set(this, 'controller.designId', id);
    },

    async save(design, areAllContentsComplete, hasGrabbedCode) {
      const setSaveState = bool => run(() => set(this, 'controller.isAnythingSaving', bool));
      setSaveState(true);

      try {
        await RSVP.all(
          get(design, 'tokenContents')
            .filterBy('hasDirtyAttributes', true)
            .map(x => x.save())
        );
        if (get(design, 'hasDirtyAttributes')) {
          await design.save();
        }
      } catch (e) {
        console.error(e);
      }
      setSaveState(false);
      run(() => this.maybeCheckChecklistStep(areAllContentsComplete, hasGrabbedCode));
    },
    createTokenContent(token, design) {
      return this.createTokenContent(token, design);
    },
    async copyDesign(design, designTemplate) {
      const newDesign = await this.copyDesign(design, designTemplate || get(design, 'designTemplate'));
      if (newDesign) {
        get(this.modelFor(this.routeName), 'designs').addObject(newDesign);
        this.send('setActiveDesign', get(newDesign, 'id'));
      }
    },
    async saveWidgetForm() {
      set(this, 'controller.isAnythingSaving', true);
      try {
        await get(this.modelFor(this.routeName), 'widgetForm').save();
      } catch (e) {
        console.error(e);
      } finally {
        set(this, 'controller.isAnythingSaving', false);
      }
    },
    async convertDesign(design, designTemplate) {
      set(design, 'designTemplate', designTemplate);
      await design.save();
    },
  },
});
