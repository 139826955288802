/* eslint-disable ember/no-mixins */ // FIXME
import { action, set, setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';

export default class ResetPasswordRoute extends Route.extend(AppContextRoute) {
  //region Dependencies
  @service router;
  @service snackbar;
  @service session;
  @service store;
  //endregion

  //region Hooks
  beforeModel(transition) {
    super.beforeModel(...arguments);

    if (this.session.isTwoFactorAuthIncomplete) {
      this.session.attemptedTransition = transition;
      this.router.replaceWith('login.totp');
    } else if (this.session.isTwoFactorAuthRequired && !this.session.isTwoFactorAuthDeviceSetUp) {
      this.session.attemptedTransition = transition;
      this.router.replaceWith('setup.totp');
    }
  }

  model(_model, transition) {
    //region HAX: need to refresh page for impersonation access token (see partner/app/routes/index.js)
    if (transition.to.queryParams.forceReload) {
      schedule('routerTransitions', this, () => window.location.reload());
      return;
    }
    //endregion
    return this.store.findAll('partner-organization-user').then(x => x.firstObject);
  }

  redirect(partnerOrganizationUser) {
    if (partnerOrganizationUser.migratedToUcm) {
      this.router.replaceWith('users.user', partnerOrganizationUser.userId);
    }
  }
  //endregion

  //region Actions
  @action
  async resetPassword() {
    const { controller } = this;
    const user = this.modelFor('reset-password');
    set(user, 'password', controller.password);
    set(controller, 'isSaving', true);

    await user.save();
    setProperties(controller, {
      isSaving: false,
      password: '',
      passwordConfirmation: '',
    });
    this.router.replaceWith('organizations.index');
    this.snackbar.show('Your password has been reset.', 'OK');
  }
  //endregion
}
