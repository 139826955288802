/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { equal, readOnly } from '@ember/object/computed';
import { dasherize } from '@ember/string';

const join = (a, b, joiner = '-') => (a && b ? `${a}${joiner}${b}` : `${a}${b}`);

export default Component.extend({
  //region Attributes
  /**
   * @type {String}
   */
  'entity-type': null,

  /**
   * @type {String}
   */
  type: null,

  /**
   * @type {?String}
   */
  size: 'medium',
  //endregion

  //region Ember Hooks
  classNames: ['type-icon'],

  classNameBindings: ['_typeClass', '_isMedium:type-icon--medium', '_isSmall:type-icon--small'],
  //endregion

  //region Computed Properties
  /**
   * @returns {Boolean}
   */
  _isSmall: equal('size', 'small'),

  /**
   * @returns {Boolean}
   * @param size
   */
  _isMedium: equal('size', 'medium'),

  /**
   * @private
   * @returns {String}
   */
  _svgSlug: computed('entity-type', 'type', function () {
    return join(dasherize(this['entity-type'] || ''), dasherize(this.type || ''));
  }),

  /**
   * @private
   * @returns {String}
   */
  _svgPath: readOnly('_svgSlug'),

  /**
   * @private
   * @returns {String}
   */
  _svgClass: computed('_svgSlug', function () {
    return `type-icon__svg type-icon__svg--${this._svgSlug}`;
  }),

  /**
   * @private
   * @returns {String}
   */
  _typeClass: computed('_svgSlug', function () {
    return `type-icon--${this._svgSlug}`;
  }),
  //endregion
});
