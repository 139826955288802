/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import InheritableModel from 'partner/-base/inheritable-model';

const { attr, belongsTo } = DS;

export default InheritableModel.extend({
  organization: belongsTo('organization', {
    async: false,
  }),
  organizationAttributeTypeId: attr('number'),
  value: attr(),
  attributeValueMap: attr(),
});
