/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { ParamsType } from 'partner/services/preview';
const photo = ({ id, width, height }: { id: string; width?: number; height?: number }) => {
  return {
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'image-2',
        uniqueTypeNumber: '2',
        deletable: true,
        type: 'promotion-image',
        blocks: [],
        data: {
          src: `https://media.secondstreetapp.com/${6550274}?width=107&height=80&cropmode=fit&anchor=center&quality=60&format=auto`,
          alt: '',
          url: '',
          target: '_blank',
          additionalCss: `.arc-promotion-promotion-0 .arc-image-image-${id} {\n   \n  }\n  \n  `,
        },
        styles: {
          size: {
            custom: true,
            width: '40',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-box-box-${id} {\n   \n  }\n  \n  `,
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
      },
      size: {
        custom: width || height,
        width,
        height,
      },
      border: {
        radius: '4',
      },
      background: {
        type: 'color',
        color: '#f1f3f3',
        opacity: 100,
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const questionText = (id: string) => {
  return {
    id: 'heading-3',
    uniqueTypeNumber: `${id}3`,
    deletable: true,
    type: 'promotion-heading',
    blocks: [],
    data: {
      level: 1,
      text: 'Answer #',
      additionalCss: `.arc-heading-heading-${id}3 {\n     \n  }\n  \n  `,
    },
    styles: {
      font: {
        color: '#000000',
        weight: 400,
        family: 'Open Sans',
        size: '16',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const questionText2 = (id: string, color?: string) => {
  return {
    id: 'heading-3',
    uniqueTypeNumber: `${id}3`,
    deletable: true,
    type: 'promotion-heading',
    blocks: [],
    data: {
      level: 1,
      text: 'Answer #',
      additionalCss: `.arc-heading-heading-${id}3 {\n     \n  }\n  \n  `,
    },
    styles: {
      font: {
        color,
        weight: 700,
        family: 'Open Sans',
        size: '20',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};
const card = (id: string, color?: string) => {
  return {
    id: 'box-4',
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-box',
    blocks: [questionText2(id, color)],
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-${id} {\n width: calc(50% - 8px);    \n  }\n  \n  `,
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        justify: 'center',
        align: 'center',
      },
      background: {
        type: 'color',
        color: '#f1f3f3',
        opacity: 100,
      },
      border: {
        style: 'solid',
        width: 1,
        color: '#e0e3e6',
        radius: '4',
      },
      size: {
        custom: true,
        height: '240',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const row = (id: string) => {
  return {
    id: 'box-4',
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'box-5',
        uniqueTypeNumber: '5',
        deletable: true,
        type: 'promotion-box',
        blocks: [],
        data: {
          additionalCss: '.arc-box-box-5 {\n     \n  }\n  \n  ',
        },
        styles: {
          layout: {
            display: 'flex',
            direction: 'column',
          },
          size: {
            custom: true,
            width: '15',
            height: '15',
          },
          border: {
            style: 'solid',
            width: 1,
            color: '#4b4c4d',
            radius: '20',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      questionText(id),
    ],
    data: {
      additionalCss: '.arc-box-box-4 {\n     \n  }\n  \n  ',
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'row',
        gapX: '16',
        gapY: '16',
        align: 'center',
      },
      spacing: {
        paddingBlock: '',
        paddingInline: '8',
      },
      size: {
        custom: true,
        width: '240',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const questionBlock = (params?: ParamsType, questionNumber?: string) => {
  return {
    id: 'box-2',
    uniqueTypeNumber: '2',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      ...(params?.isSmall ? [] : [photo({ id: '12', height: 250 })]),
      {
        id: 'heading-1',
        uniqueTypeNumber: '1',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: `Question ${questionNumber} of 2`,
          additionalCss:
            '.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-1 {\n  margin-top:16px;   \n  }\n  \n  ',
        },
        styles: {
          font: {
            color: params?.promotionColor,
            weight: 700,
            family: 'Open Sans',
            size: '16',
            height: '24',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      {
        id: 'heading-2',
        uniqueTypeNumber: '2',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: 'Example question?',
          additionalCss: '.arc-heading-heading-2 {\n     \n  }\n  \n  ',
        },
        styles: {
          font: {
            color: '#000000',
            weight: 600,
            family: 'Open Sans',
            size: '24',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      {
        id: 'box-3',
        uniqueTypeNumber: '3',
        deletable: true,
        type: 'promotion-box',
        blocks: [
          ...(params?.isSmall
            ? [row('51'), row('52')]
            : [card('51', params?.promotionColor), card('52', params?.promotionColor)]),
        ],
        data: {
          additionalCss:
            '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-3 {\n  margin-bottom: 0;   \n  }\n  \n  ',
        },
        styles: {
          layout: {
            display: 'flex',
            direction: params?.isSmall ? 'column' : 'row',
            gapX: params?.isSmall ? '' : '16',
            gapY: params?.isSmall ? '' : '16',
          },
          spacing: {
            marginBlock: '20',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-2 {\n max-width:632px ;   \n  }\n  \n  `,
    },
    styles: {
      border: {
        style: 'solid',
        width: 1,
        color: '#e0e3e6',
        radius: '4',
      },
      layout: {
        display: 'flex',
        direction: 'column',
      },
      spacing: {
        marginBlock: '16',
        marginInline: '',
        paddingBlock: '20',
        paddingInline: '20',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};
export default (params?: ParamsType) => [
  {
    type: 'promotion-box',
    uniqueTypeNumber: '101',
    blocks: [
      ...(params?.isSmall ? [questionBlock(params, '1')] : [questionBlock(params, '1'), questionBlock(params, '2')]),
    ],
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
      },
    },
  },
];
