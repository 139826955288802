import { helper } from '@ember/component/helper';

/**
 * Displays the number with a flexible number of decimal places. Often used to show pretty percentages.
 * Examples:
 *  {{flexible-number 40.555 0}} => 41
 *  {{flexible-number 0.555 0}} => 1
 *  {{flexible-number 40.555 1}} => 41
 *  {{flexible-number 0.555 1}} => 0.6
 *  {{flexible-number 40.555 2}} => 41
 *  {{flexible-number 0.555 2}} => 0.6
 *  {{flexible-number 0.055 2}} => 0.05
 * @param number - The number to be displayed
 * @param maxPlaces - The maximum number of decimal places to allow to be displayed
 * @returns {*}
 */
export function flexibleNumber([number, maxPlaces]) {
  let num = number;
  let i = 0;
  if (!maxPlaces) {
    maxPlaces = 0;
  }
  while (i < maxPlaces + 1) {
    const tens = 10 ** i; // eslint-disable-line
    num = Math.round(number * tens) / tens;
    if (number < 1 / tens) {
      i += 1;
    } else {
      break;
    }
  }

  let ret = `${num}`;

  if (num < 1 && num > 0) {
    ret = `.${ret.split('.')[1]}`;
  }

  return ret;
}

export default helper(flexibleNumber);
