/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { run, next } from '@ember/runloop';
import moment from 'moment';
import PermittedComponent from 'partner/mixins/permitted-component';
import Pikaday from 'pikaday-time';
import { inject as service } from '@ember/service';

/**
 * Date and time picker. Separates date and time into four fields: Date, hour, minute, am/pm.
 * @see https://github.com/dbushell/Pikaday
 * @type {Ember.Component}
 */
export default Component.extend(PermittedComponent, {
  defaultFormat: service('default-format'),

  momentDateFormat: alias('moment-date-format'),
  'moment-date-format': null,
  classNameBindings: ['ssDateTimePicker'],
  ssDateTimePicker: true,
  placeholder: null,
  isDisabledWhileSaving: false,
  'disable-past-dates': false,
  'disable-future-dates': false,
  'show-time': true,
  'show-icon': false,
  'date-range-start': new Date().getFullYear() - 1,
  'date-range-end': new Date().getFullYear() + 2,
  /**
   * Expects a Javascript Date object
   */
  'custom-max-date': null,
  /**
   * Expects a Javascript Date object
   */
  'custom-min-date': null,
  /**
   * You can pass in a closure action to override this.
   */
  'focus-in'() {
    return;
  },
  /**
   * You can pass in a closure action to override this.
   */
  'focus-out'() {
    return;
  },
  /**
   * Triggered when the date changed
   */
  onChange: null,
  /**
   * If you want to manually disable the component, pass this as true.
   */
  'is-disabled': false,
  /**
   * `classModifiers` should be passed in as an array. Will add
   * `ssDateTimePicker--myClassModifier` class names to the date input for each one
   */
  ssDateTimePickerModifiers: computed('classModifiers', function () {
    let modifiers = '';
    if (this.classModifiers) {
      this.classModifiers.forEach(modifier => (modifiers += `ssDateTimePicker--${modifier} `));
    }
    return modifiers.trim();
  }),
  /**
   * Don't overwrite this from the outside! Also don't overwrite `disabled`.
   * If you do those things, it will cause permissions issues.
   */
  isDisabled: computed('administer', 'disabled', 'is-disabled', {
    get() {
      return !this.administer || this.disabled || this['is-disabled'];
    },

    set(_key, value) {
      set(this, 'disabled', value);
      return value;
    },
  }),
  minDate: computed('disable-past-dates', 'custom-min-date', function () {
    const customMinDate = this['custom-min-date'];
    const startOfDay = moment().startOf('day').toDate();
    const disablePastDates = this['disable-past-dates'];

    if (customMinDate && disablePastDates) {
      return new Date(Math.max(customMinDate, startOfDay));
    }

    if (customMinDate && !disablePastDates) {
      return customMinDate;
    }

    if (!customMinDate && disablePastDates) {
      return startOfDay;
    }

    return undefined;
  }),
  maxDate: computed('disable-future-dates', 'custom-max-date', function () {
    const customMaxDate = this['custom-max-date'];
    const startOfDay = moment().startOf('day').toDate();
    const disableFutureDates = this['disable-future-dates'];

    if (customMaxDate && disableFutureDates) {
      return new Date(Math.min(customMaxDate, startOfDay));
    }

    if (customMaxDate && !disableFutureDates) {
      return customMaxDate;
    }

    if (!customMaxDate && disableFutureDates) {
      return startOfDay;
    }

    return undefined;
  }),
  _hasErrors: null,
  hasErrors: computed('errors.[]', 'attribute', '_hasErrors', {
    get() {
      if (this._hasErrors !== null) {
        return this._hasErrors;
      }
      const { errors } = this;
      if (!errors) {
        return false;
      }
      return errors.has(this.attribute);
    },
    set(_key, value) {
      this.set('_hasErrors', value);
      return value;
    },
  }),

  init() {
    this._super(...arguments);
    if (!this['moment-date-format']) {
      set(this, 'moment-date-format', `${this.defaultFormat.getDateFormat()} h:mm A`);
    }

    if (!this['placeholder']) {
      set(this, 'placeholder', this.defaultFormat.getDateFormat());
    }
  },
  /**
   * Turn <input type="text" class="ssDateTimePicker"> into a Pikaday picker.
   */
  didInsertElement() {
    this._super(...arguments);

    if (!this.isDisabled) {
      this.enablePikaday();
    }
  },

  willDestroyElement() {
    this._super(...arguments);

    run(() => this.picker?.destroy());
  },

  didUpdateAttrs() {
    this._super();
    next(() => this.dateChanged());

    if (this.picker && this.isDisabledWhileSaving) {
      set(this, 'disableAllDays', true);
      set(this, 'picker._o.disableDayFn', date => (date ? true : false));
      const pickerOptions = this.picker._o;
      this.picker.config(pickerOptions);
      const pickerSelectElements = this.picker.el.getElementsByTagName('select');
      for (let i = 0; i < pickerSelectElements.length; i++) {
        pickerSelectElements[i].disabled = true;
      }
    } else if (this.picker && !this.isDisabledWhileSaving && this.disableAllDays) {
      set(this, 'disableAllDays', false);
      set(this, 'picker._o.disableDayFn', null);
      const pickerOptions = this.picker._o;
      this.picker.config(pickerOptions);
      const pickerSelectElements = this.picker.el.getElementsByTagName('select');
      for (let i = 0; i < pickerSelectElements.length; i++) {
        pickerSelectElements[i].disabled = false;
      }
    }

    next(() => {
      if (this.minDate) this.picker?.setMinDate(this.minDate);
      if (this.maxDate) this.picker?.setMaxDate(this.maxDate);
    });

    // If we've been enabled since the didInsertElement hook, we need to enable now.
    if (!this.isDisabled && !this.picker) {
      this.enablePikaday();
    }
  },
  enablePikaday() {
    const { momentDateFormat } = this;
    const dateInput = this.getDateInput();

    this.picker = new Pikaday({
      field: dateInput,
      yearRange: [this['date-range-start'], this['date-range-end']],
      format: momentDateFormat,
      onSelect: date => (this.onChange ? this.onChange(date) : set(this, 'date', date)),
      use24hour: false,
      showTime: this['show-time'],
      onOpen: () => {
        this.onOpen?.();
      },
      onClose: () => {
        this.onClose?.();
      },
    });

    // Do this in the next run loop because we don't actually want it enforcing minDate upon initialization, just future changes.
    next(() => {
      this.picker?.setMinDate(this.minDate);
      this.picker?.setMaxDate(this.maxDate);
    });
  },
  /**
   * Keep Pikaday and Memory all in sync with each other
   */
  dateChanged() {
    const pikaday = this.picker;

    if (pikaday && !this.isDisabled && !this.isDisabledWhileSaving) {
      const pikadayDate = pikaday.getDate();
      const { date } = this;
      if (date) {
        if (!pikadayDate || pikadayDate.getTime() !== date.getTime()) {
          pikaday.setDate(date, true);
        }
      } else {
        pikaday.setDate(null, true);
        this.getDateInput().value = '';
      }
    }
  },

  /**
   * Gets the input that will contain the date.
   * @returns input DOM Element object
   */
  getDateInput() {
    return this.element.querySelector('input[type="text"]');
  },

  actions: {
    handleInputChange(event) {
      if (!event.target.value && !this.resetOnNull) {
        this.onChange ? this.onChange(null) : set(this, 'date', null);
      }
    },
  },
});
