import isDate from 'lodash/isDate';

/**
 * Date In Range
 *
 * @method dateInRange
 * @memberof Promotion.validate
 * @param date {Date} - The date to test
 * @param [rangeStart] {Date} - Test if date is after this, if present
 * @param [rangeEnd] {Date} - Test if date is before this, if present
 * @returns {boolean} - Is date after rangeStart and before rangeEnd, if they are present?
 */
export const dateInRange = function (date, rangeStart, rangeEnd) {
  if (!isDate(date)) {
    throw new Error('First argument must be a date');
  }
  if (rangeStart && !isDate(rangeStart)) {
    throw new Error('Start range must be a date');
  }
  if (rangeEnd && !isDate(rangeEnd)) {
    throw new Error('End range must be a date');
  }

  // If the dates to compare against are reversed, fix them for the comparison:
  if (rangeStart && rangeEnd && rangeStart > rangeEnd) {
    const pivot = rangeStart;
    rangeStart = rangeEnd;
    rangeEnd = pivot;
  }

  const afterStart = rangeStart ? rangeStart.getTime() <= date.getTime() : true;
  const beforeEnd = rangeEnd ? rangeEnd.getTime() >= date.getTime() : true;
  if (rangeStart && rangeEnd) {
    return afterStart && beforeEnd;
  } else if (rangeStart) {
    return afterStart;
  } else if (rangeEnd) {
    return beforeEnd;
  }
  return false;
};

export default {
  dateInRange,
};
