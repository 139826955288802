/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { inject as controller } from '@ember/controller';
import { computed } from '@ember/object';
import { alias, readOnly, sort } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

export default Mixin.create({
  //region Ember Hooks
  enums: service(),
  organizationController: controller('organizations.organization'),
  queryParams: ['statusTypeId', 'promotionTypeId'],
  //endregion

  //region Properties
  statusTypeId: null,
  promotionTypeId: null,
  organizationPromotionSorting: Object.freeze(['intId:desc']),
  visibleWhen: 'organizations.organization.organization-promotions.index',
  //endregion

  //region Computed Properties
  promotionTypeIdInt: computed('promotionTypeId', function () {
    return window.parseInt(this.promotionTypeId, 10);
  }),
  showPromotionCreationButton: readOnly('promotionTypeId'),
  organizationPromotions: sort('model.organizationPromotions', 'organizationPromotionSorting'),
  currentOrganizationPromotions: sort('model.currentOrganizationPromotions', 'organizationPromotionSorting'),
  expiredOrganizationPromotions: sort('model.expiredOrganizationPromotions', 'organizationPromotionSorting'),
  organization: alias('organizationController.model.organization'),
  /**
   * This will have to change when more than one promotionType is displayed on the page at a time
   */
  promotionCreationId: computed('promotionTypeId', function () {
    return this.enums.findWhere('PROMOTION_TYPE', { id: this.promotionTypeIdInt }, 'creationId');
  }),
  //endregion
});
