import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex flex-col gap-4\" {{did-insert (perform this.loadTemplatesTask)}} ...attributes>\n  <div class=\"text-black font-semibold text-lg\">\n    Which template would you like to use?\n  </div>\n  {{#if this.loadTemplatesTask.isRunning}}\n    <Ui::Spinner class=\"my-6 mx-auto\" />\n  {{/if}}\n  <div class=\"flex justify-center md:justify-normal flex-wrap gap-[22px]\">\n    {{#each this.templates as |template|}}\n      <SetupDesign::TemplateCard\n        @template={{template}}\n        @onPreview={{fn @onPreviewTemplate template}}\n        @onSelect={{fn @onSelectTemplate template}}\n        @onDelete={{this.onDeleteSuccess}}\n      />\n    {{/each}}\n  </div>\n</div>", {"contents":"<div class=\"flex flex-col gap-4\" {{did-insert (perform this.loadTemplatesTask)}} ...attributes>\n  <div class=\"text-black font-semibold text-lg\">\n    Which template would you like to use?\n  </div>\n  {{#if this.loadTemplatesTask.isRunning}}\n    <Ui::Spinner class=\"my-6 mx-auto\" />\n  {{/if}}\n  <div class=\"flex justify-center md:justify-normal flex-wrap gap-[22px]\">\n    {{#each this.templates as |template|}}\n      <SetupDesign::TemplateCard\n        @template={{template}}\n        @onPreview={{fn @onPreviewTemplate template}}\n        @onSelect={{fn @onSelectTemplate template}}\n        @onDelete={{this.onDeleteSuccess}}\n      />\n    {{/each}}\n  </div>\n</div>","moduleName":"partner/components/setup-design/template-selector.hbs","parseOptions":{"srcName":"partner/components/setup-design/template-selector.hbs"}});
import Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import DesignTemplateModel from 'partner/models/design-template';
import enums from 'ember-cli-ss-enums/services/enums';

export interface TemplateSelectorSignature {
  Element: HTMLDivElement;
}

export default class TemplateSelectorComponent extends Component<TemplateSelectorSignature> {
  @service declare store: Store;

  @tracked templates: DesignTemplateModel[] = [];

  loadTemplatesTask = task({ drop: true }, async () => {
    const designTemplateTypeId = enums.findWhere('TEMPLATE_TYPE', { name: 'Custom Template' });
    this.templates = (await this.store.query('design-template', { designTemplateTypeId, BypassCache: true }))
      .toArray()
      .reject(template => template.isInherited && !template.visibleToAll);
  });

  @action
  onDeleteSuccess() {
    this.templates = this.templates.rejectBy('isDeleted');
  }
}
