/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import { run } from '@ember/runloop';
import { readOnly } from '@ember/object/computed';
import { task } from 'ember-concurrency';

export default class extends Controller {
  @service('deliberateConfirmation') deliberateConfirmation;
  @service('store') store;
  @service('snackbar') snackbar;
  @service('router') router;
  @service('session') session;
  @service('enums') enums;
  @service('reports') reports;
  //endregion

  //region Computed Properties,
  @readOnly('model.consumerUser') consumerUser;
  @readOnly('consumerUser.hasDirtyAttributes') isAnythingDirty;

  /**
   * @private
   */
  get fullNameOrEmail() {
    const firstName = get(this, 'consumerUser.firstName');
    const lastName = get(this, 'consumerUser.lastName');
    return firstName && lastName ? get(this, 'consumerUser.fullName') : get(this, 'consumerUser.emailAddress');
  }

  /**
   * @private
   */
  get confirmDeleteUserText() {
    return {
      promptText: `Are you sure you want to delete ${this.fullNameOrEmail} from the database? This profile and all personally identifiable information will be erased and cannot be recovered.`,
      cancelButtonText: 'No, cancel',
      confirmButtonText: 'Yes, delete',
    };
  }

  anonymizeUser() {
    const organizationUserId = get(this, 'consumerUser.id');
    const params = { organizationUserId };
    return run(() => this.store.createRecord('user-anonymize-queue', params).save());
  }

  showConfirmDeleteUserTask = task({ drop: true }, async () => {
    const confirmed = await this.deliberateConfirmation.show(this.confirmDeleteUserText);

    if (confirmed) {
      await this.anonymizeUser();

      this.snackbar.show('Deleting profile. This may take a few minutes.', 'OK');
      this.router.transitionTo('organizations.organization.dashboard');
    }

    this.deliberateConfirmation.resetConfirmedStatus();
  });

  downloadReportTask = task({ drop: true }, async () => {
    const organizationUserId = get(this, 'consumerUser.id');
    try {
      await this.reports.download('UserProfile', {
        data: {
          organization_user_id: organizationUserId,
        },
      });
    } catch (e) {
      this.snackbar.show('Something went wrong. Please try again later.');
    }
  });
}
