/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend({
  //region Properties
  gameId: DS.attr('number'),
  name: DS.attr('string'),
  displayOrder: DS.attr('number'),
  statusTypeId: DS.attr('number'),
  isAlphabetized: DS.attr('boolean'),
  startDate: DS.attr('date'),
  endDate: DS.attr('date'),
  //endregion
});
