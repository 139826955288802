import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<EntryForm::Field::Wrapper @label={{@formField.labelText}} ...attributes>\n  <div>\n    {{#each @formField.field.sortedFieldOptions key=\"id\" as |fieldOption|}}\n      <label class=\"entry-form-field__radio-label\">\n        <input\n          type=\"radio\"\n          name=\"form-field-radio-{{@formField.id}}\"\n          value={{fieldOption.id}}\n          checked={{eq this.entryFieldValue.fieldOption fieldOption}}\n          disabled={{@disabled}}\n          {{on \"change\" this.handleChange}}\n        >\n\n        <span>{{fieldOption.text}}</span>\n      </label>\n    {{/each}}\n  </div>\n</EntryForm::Field::Wrapper>\n\n", {"contents":"<EntryForm::Field::Wrapper @label={{@formField.labelText}} ...attributes>\n  <div>\n    {{#each @formField.field.sortedFieldOptions key=\"id\" as |fieldOption|}}\n      <label class=\"entry-form-field__radio-label\">\n        <input\n          type=\"radio\"\n          name=\"form-field-radio-{{@formField.id}}\"\n          value={{fieldOption.id}}\n          checked={{eq this.entryFieldValue.fieldOption fieldOption}}\n          disabled={{@disabled}}\n          {{on \"change\" this.handleChange}}\n        >\n\n        <span>{{fieldOption.text}}</span>\n      </label>\n    {{/each}}\n  </div>\n</EntryForm::Field::Wrapper>\n\n","moduleName":"partner/components/entry-form/field/radio-buttons.hbs","parseOptions":{"srcName":"partner/components/entry-form/field/radio-buttons.hbs"}});
import { action } from '@ember/object';

import Base from './base';

export default class EntryFormFieldRadioButtonsComponent extends Base {
  @action
  handleChange(e) {
    const fieldOption = this.fieldOptions.findBy('id', e.target.value);

    this.args.onChange(this.entryFieldValue, {
      value: fieldOption.text,
      fieldId: this.fieldId,
      fieldOption,
    });
  }
}
