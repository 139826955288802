/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { getOwner } from '@ember/application';
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import flattenDeep from 'lodash/flattenDeep';
import copyForm from 'partner/utils/api/copy-form';
import RSVP from 'rsvp';

const ORGANIZATION_PROMOTION_ROUTE = 'organizations.organization.organization-promotions.organization-promotion';
const ORGANIZATION_PROMOTION_SETUP_ROUTE =
  'organizations.organization.organization-promotions.organization-promotion.setup';
const ORGANIZATION_PROMOTION_FORMS_ROUTE =
  'organizations.organization.organization-promotions.organization-promotion.setup.forms';

export default Route.extend({
  //region Dependencies
  enums: service(),
  router: service(),
  session: service(),
  snackbar: service(),
  store: service(),
  //endregion

  //region Ember Hooks
  async model(params) {
    const organization = get(this.modelFor('organizations.organization'), 'organization');
    const organizationId = get(organization, 'Id');
    const organizationPromotion = get(this.modelFor(ORGANIZATION_PROMOTION_ROUTE), 'organizationPromotion');
    const organizationPromotionId = get(organizationPromotion, 'id');

    const extraChancesFormTypeId = this.enums.findWhere('FORM_TYPE', { name: 'ExtraChances' });

    const form = await this.store.find('form', params.form_id);
    const extraChancesForm = await this.store.queryRecord('form', { formTypeId: extraChancesFormTypeId });
    const participationAreaEnabled = await this.store.query('participation-area', {}).then(result => result.toArray());

    return RSVP.hash({
      extraChancesForm,
      form,
      starredFields: this.store.query('field', {
        isStarred: true,
        organizationPromotionId,
      }),
      searchResults: [],
      organization,
      organizationPromotion,
      participationAreaEnabled,
      sweepstakes: this.store.queryRecord('sweepstakes', {
        organizationId,
        organizationPromotionId,
      }),
      enabledSetting: this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE).registrationEnabledSetting,
      organizationRegistrationFormSetting: this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE)
        .organizationRegistrationFormSetting,
    }).then(hash => {
      // Automatically copy inherited forms and redirect to their copies.
      if (get(hash.form, 'isInherited')) {
        return this.copyForm(hash.form).then(copiedForm =>
          this.router.replaceWith(
            `${ORGANIZATION_PROMOTION_FORMS_ROUTE}.${
              organizationPromotion.promotion.isPaymentForm ? 'contact' : 'form'
            }`,
            get(copiedForm, 'id')
          )
        );
      }
      return hash;
    });
  },
  //endregion

  //region Methods
  // TODO: Remove if we can figure out a better way to do this
  async copyForm(sourceForm) {
    const newForm = await copyForm(sourceForm, this.store, this.session, getOwner(this).lookup('adapter:application'));

    await newForm.reload();

    return newForm;
  },

  rollback() {
    [
      this.store.peekAll('globalOptin'),
      this.store.peekAll('fieldOption'),
      this.store.peekAll('field'),
      this.store.peekAll('formField'),
      this.store.peekAll('formPage'),
      this.store.peekAll('form'),
      get(this.modelFor(this.router.currentRouteName), 'form.deletedRecords'),
    ].forEach(records => records.slice().forEach(x => x.rollbackAttributes()));
  },

  async save() {
    set(this, 'controller.isAnythingSaving', true);
    const formModel = this.controller.model;

    try {
      await formModel.organizationPromotion.save();
      await formModel.form.save();

      if (formModel.organizationRegistrationFormSetting.isDirty) {
        const reload = formModel.organizationRegistrationFormSetting.isInherited;
        await formModel.organizationRegistrationFormSetting.save();

        if (reload) {
          const newSetting = await this.store.queryRecord('setting', { key: 'Organization_Registration_Form_Setting' });
          set(formModel, 'organizationRegistrationFormSetting', newSetting);
        }
      }

      const formFields = flattenDeep(
        formModel.form.formPages.mapBy('formFields').map(formFields => formFields.toArray())
      );

      await Promise.all(formFields.rejectBy('formPage').map(orphanFormField => orphanFormField.reload()));

      this.controller.send('checkChecklistStep');
      set(this, 'controller.didSomethingChange', false);
      set(formModel, 'form.deletedRecords', []);
      const enabledSetting = get(formModel, 'enabledSetting');

      if (get(enabledSetting, 'hasDirtyAttributes')) {
        const thankYouEmail = this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE).promotionEmails.findBy('isThankYou');

        if (this.isRegistrationEnabledChanged(enabledSetting.changedAttributes().value) && thankYouEmail?.isConfirmed) {
          this.snackbar.show(`Your thank you email has been ${enabledSetting.value ? 'enabled' : 'disabled'}.`, '', -1);
        }

        if (thankYouEmail) {
          if (enabledSetting.value) {
            this.send('updateDisplayedPromotionEmails', 'addObject', thankYouEmail);
          } else {
            this.send('updateDisplayedPromotionEmails', 'removeObject', thankYouEmail);
          }
        }

        this.send('emailChecklistStepLogic');

        /*
          if enabledSetting is inherited one, we create a new setting and replace this inherited setting
          with newly created setting after save
        */
        const reloadSetting = enabledSetting.isInherited;
        await enabledSetting.save();
        if (reloadSetting) {
          const setupRouteModel = this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE);
          const newSetting = await this.store.queryRecord('setting', { key: 'Registration_Type' });
          set(formModel, 'enabledSetting', newSetting);
          set(setupRouteModel, 'registrationEnabledSetting', newSetting);
        }
      }

      return RSVP.Promise.resolve();
    } finally {
      set(this, 'controller.isAnythingSaving', false);
    }
  },

  somethingChanged() {
    set(this, 'controller.didSomethingChange', true);
  },

  isRegistrationEnabledChanged([oldProperty, newProperty]) {
    const registrationWasDisabled = (oldProperty === 1 || oldProperty === 2) && newProperty === 0;
    const registrationWasEnabled = oldProperty === 0 && (newProperty === 1 || newProperty === 2);
    return registrationWasDisabled || registrationWasEnabled;
  },
  //endregion

  //region Actions
  actions: {
    async save() {
      await this.save();
    },
    async saveAndContinue() {
      try {
        await this.save();
        this.controller.send('checkChecklistStep');
        set(this, 'controller.didSomethingChange', false);
        set(this.modelFor(this.router.currentRouteName), 'form.deletedRecords', []);
        const enabledSetting = get(this.modelFor(this.router.currentRouteName), 'enabledSetting');
        if (get(enabledSetting, 'hasDirtyAttributes')) {
          await get(this.modelFor(this.router.currentRouteName), 'enabledSetting').save();
        }
        return RSVP.Promise.resolve();
      } catch (e) {
        /* no op */
      } finally {
        this.send('continue');
      }
    },
    fieldStarred(field) {
      const method = get(field, 'isStarred') ? 'addObject' : 'removeObject';
      get(this.modelFor(this.router.currentRouteName), 'starredFields')[method](field);
    },
    // Changing relationships doesn't dirty parent records so we manually set a property on the controller to track
    // Eventually used to determine whether or not to light up the save button
    somethingChanged() {
      this.somethingChanged();
    },
    willTransition(transition) {
      this._super();
      let confirmed = !get(this, 'controller.isAnythingRelevantDirty');
      confirmed = confirmed ? confirmed : window.confirm('Are you sure you want to leave without saving?');

      if (confirmed) {
        this.rollback();
        set(this, 'controller.didSomethingChange', false);
        set(this.modelFor(this.router.currentRouteName), 'form.deletedRecords', []);
      } else {
        transition.abort();
      }
    },
  },
  //endregion
});
