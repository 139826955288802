import { set } from '@ember/object';
import difference from 'lodash/difference';
import { generateRenderedContent } from 'partner/utils/designer-preview';
import textFromHtml from 'partner/utils/text-from-html';

const templateTokenCopyInstructions = [{ tokenKey: 'item', numberOfCopies: 3 }];

export function createTokenContent(messageVersion, token, store) {
  const tokenContent = store.createRecord('message-version-template-token-content');
  tokenContent.initializeValues(token, messageVersion);
  return tokenContent;
}

export async function copyMessageVersion(messageVersion) {
  const newMessageVersion = messageVersion.createCopy([
    'id',
    'senderAccount',
    'template',
    'messageVersionTemplateTokenContents',
  ]);
  const newTokenContents = messageVersion.tokenContents.rejectBy('isDisabled').map(tokenContent => {
    const copy = tokenContent.createCopy(['id', 'messageVersion']);
    set(copy, 'messageVersion', newMessageVersion);
    return copy;
  });
  const [senderAccount, message] = await Promise.all([messageVersion.senderAccount, messageVersion.message]);
  newMessageVersion.tokenContents.addObjects(newTokenContents);
  set(newMessageVersion, 'messageBodyTemplate', messageVersion.messageBodyTemplate);
  set(newMessageVersion, 'senderAccount', senderAccount);
  set(newMessageVersion, 'message', message);
  return newMessageVersion;
}

export function createTokenContentsForMessageVersion(messageVersion, tokens, store) {
  const existingTokens = messageVersion.tokenContents.mapBy('token');
  const missingTokens = difference(tokens.toArray(), existingTokens);
  const newTokenContents = [];
  missingTokens.forEach(templateToken => {
    if (templateToken.allowMultiple) {
      const copyInstructions = templateTokenCopyInstructions.findBy('tokenKey', templateToken.key);
      const numberOfCopies = copyInstructions ? copyInstructions.numberOfCopies : 1;
      for (let count = 1; count <= numberOfCopies; count++) {
        const newTokenContent = createTokenContent(messageVersion, templateToken, store);
        set(newTokenContent, 'displayOrder', count);
        newTokenContents.push(newTokenContent);
      }
    } else {
      newTokenContents.push(createTokenContent(messageVersion, templateToken, store));
    }
  });
  return newTokenContents;
}

export function generateBodyAndSave(messageVersions, dipsUrlValue, tokens) {
  return messageVersions.forEach(messageVersion => {
    const newBody = generateRenderedContent(messageVersion, tokens, dipsUrlValue, messageVersion.messageVersionFeeds);
    set(messageVersion, 'body', newBody);
    set(messageVersion, 'plainTextBody', textFromHtml(newBody));
  });
}
