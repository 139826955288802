/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { computed, set, setProperties } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { ARTICLE_TOKENS, EMAIL_DYNAMIC_TOKENS } from 'partner/utils/constants';
import { copyMessageVersion } from 'partner/utils/message-version';

const MESSAGE_CAMPAIGN_ROUTE = 'organizations.organization.message-campaigns.message-campaign';
const SETUP_ROUTE = `${MESSAGE_CAMPAIGN_ROUTE}.setup`;

export default Controller.extend({
  //region Dependencies
  enums: service(),
  router: service(),
  snackbar: service(),
  store: service(),
  //endregion

  //region Ember Hooks
  queryParams: ['messageVersionId', 'messageVersionHistoryId'],
  messageVersionId: null,
  messageVersionHistoryId: null,
  organizationController: controller('organizations.organization'),
  messageCampaignController: controller(MESSAGE_CAMPAIGN_ROUTE),
  setupController: controller(SETUP_ROUTE),
  //endregion

  //region Properties
  /**
   * @type {Boolean}
   */
  isAnythingSaving: false,
  /**
   * @type {Boolean}
   */
  isUpdatingRecipientsCount: false,
  //endregion

  //region Computed Properties
  messageCampaign: readOnly('messageCampaignController.model.messageCampaign'),
  singleMessageCampaign: readOnly('messageCampaignController.model.singleMessageCampaign'),
  audiences: readOnly('setupController.model.audiences'),
  tokens: readOnly('setupController.model.tokens'),
  messageCampaignAudiences: readOnly('setupController.model.messageCampaignAudiences'),
  messageVersionHistory: readOnly('model.messageVersionHistory'),
  messageVersions: readOnly('messageCampaign.messageVersions'),
  filteredMessageVersion: computed('messageVersionHistory', 'messageVersions.[]', 'messageVersionId', function () {
    return isPresent(this.messageVersionHistory)
      ? this.messageVersionHistory
      : this.messageVersions.findBy('id', this.messageVersionId);
  }),

  messageCampaignTypeViews: computed(
    'messageVersions.[]',
    'messageVersionHistory',
    'messageCampaign.isConfirmed',
    function () {
      const { messageCampaign, messageVersions, messageVersionHistory } = this;
      return [
        {
          messageCampaignViews: [messageCampaign].map(messageCampaign => ({
            messageCampaign,
            messageVersions: isPresent(messageVersionHistory) ? [messageVersionHistory] : messageVersions,
          })),
        },
      ];
    }
  ),

  get emailDynamicTokens() {
    const result = this.tokens
      .filterBy('dynamicTokenType')
      .filter(token => EMAIL_DYNAMIC_TOKENS.includes(token.dynamicTokenType));

    if (this.messageCampaign.isNewsletter && !this.messageVersionHistoryId && this.filteredMessageVersion.isTemplate) {
      result.push(...ARTICLE_TOKENS);
    }

    return result;
  },
  //endregion

  //region Methods
  async copyMessageVersionData(messageVersion) {
    const newMessageVersion = await copyMessageVersion(messageVersion);

    if (this.messageCampaign.isSingleEmail) {
      try {
        await newMessageVersion.save();
        await Promise.all(newMessageVersion.tokenContents.map(tokenContent => tokenContent.save()));
        return newMessageVersion;
      } catch (err) {
        console.error(err);
        newMessageVersion.destroyRecord();
        return;
      }
    }

    const message = await messageVersion.message;
    const newMessage = message.createCopy(['id', 'messageVersions', 'messageCampaign', 'schedule', 'schedules']);
    try {
      set(newMessage, 'messageCampaign', this.messageCampaign);
      await newMessage.save();
      set(newMessageVersion, 'message', newMessage);
      await newMessageVersion.save();
      await Promise.all(newMessageVersion.tokenContents.map(tokenContent => tokenContent.save()));
      return newMessageVersion;
    } catch (err) {
      console.error(err);
      newMessage.destroyRecord();
      newMessageVersion.destroyRecord();
      return;
    }
  },
  async saveChangesToDraftMessage(design, isSingleMessageCampaign, isEditingNewsletterInstance) {
    set(this, 'isAnythingSaving', true);
    try {
      if (isSingleMessageCampaign) {
        if (this.singleMessageCampaign.hasDirtyAttributes) {
          await this.singleMessageCampaign.save();
        }
      }

      const dirtyTokenContents = design.tokenContents.filterBy('hasDirtyAttributes');
      const unsavedMessageVersionFeeds = design.messageVersionFeeds.filterBy('hasDirtyAttributes');

      await Promise.all([
        ...dirtyTokenContents.map(x => x.save()),
        ...unsavedMessageVersionFeeds.map(feed => feed.save()),
      ]);

      if (design.hasDirtyAttributes && !design.isSaving) {
        await design.save();
      }
    } finally {
      run(() => set(this, 'isAnythingSaving', false));
      if (!isEditingNewsletterInstance) {
        this.send('messageChecklistStepLogic', isSingleMessageCampaign);
        this.send('messageVersionsChangedChecklistStepLogic', isSingleMessageCampaign);
      }
    }
  },
  async unschedule() {
    if (
      this.singleMessageCampaign.scheduleTypeId !==
      this.enums.findWhere('SCHEDULE_TYPE', { name: 'NewFeedItemEvent' }, 'id')
    ) {
      return;
    }
    setProperties(this.singleMessageCampaign, {
      isScheduled: false,
      scheduleDelayDatepartTypeId: null,
      scheduleStartDate: null,
      scheduleDelayValue: null,
      scheduleTypeId: this.enums.findWhere('SCHEDULE_TYPE', { name: 'Recurring' }, 'id'),
    });
    await this.singleMessageCampaign.save();
    this.send('uncheckChecklistStep', 'organizations.organization.message-campaigns.message-campaign.setup.schedule');
  },
  //endregion

  //region Actions
  actions: {
    save(design, usesSingleMessageCampaign, isEditingNewsletterInstance) {
      this.saveChangesToDraftMessage(design, usesSingleMessageCampaign, isEditingNewsletterInstance);
    },
    updateMessageChecklistStep(isSingleMessageCampaign) {
      this.send('messageChecklistStepLogic', isSingleMessageCampaign);
    },
    setActiveVersion(id) {
      set(this, 'messageVersionId', id);
    },
    removeVersion(messageVersionToBeDeleted, messageVersions) {
      if (this.messageVersionId === messageVersionToBeDeleted.id) {
        const [messageVersionToKeep] = messageVersions.rejectBy('id', this.messageVersionId);
        this.router.transitionTo({ queryParams: { messageVersionId: messageVersionToKeep.id } });
      }

      this.send('removeMessageVersion', messageVersionToBeDeleted);
    },
    async copyMessageVersion(messageVersion) {
      const newMessageVersion = await this.copyMessageVersionData(messageVersion);
      if (newMessageVersion) {
        this.router.transitionTo({
          queryParams: { messageVersionId: newMessageVersion.id },
        });
      }
    },
    unschedule() {
      this.unschedule();
    },
    /**
     * If the RSS newsletter option 'every time an article is published' is selected and the feed display order 'Order
     * in Feed' is selected, unschedule the message campaign.
     * @param feedDisplayOrder
     */
    unscheduleNewsletter(useFeedDisplayOrder) {
      if (!useFeedDisplayOrder) {
        return;
      }
      const { scheduleTypeId } = this.singleMessageCampaign;
      if (scheduleTypeId === this.enums.findWhere('SCHEDULE_TYPE', { name: 'NewFeedItemEvent' }, 'id')) {
        this.unschedule();
      }
    },
    async updateInviteRecipientsCount(id) {
      set(this, 'isUpdatingRecipientsCount', true);
      await this.store.query('message-campaign', { id, updateCount: true });
      set(this, 'isUpdatingRecipientsCount', false);
    },
    updateAudienceModel(updateAction, newAudience) {
      this.messageCampaignAudiences[updateAction](newAudience);
    },
  },
  //endregion
});
