/* eslint-disable */
/**
 * Crappy logic for a crappy problem of browser detection.
 * Taken straight from: http://stackoverflow.com/questions/5916900/detect-version-of-browser
 * @returns {String}
 */
export default function browserVersion() {
  var ua = navigator.userAgent,
    tem,
    M;

  M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return 'Opera ' + tem[1];
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return M.join(' ');
}
