export default ({ message }) => `
<div class="ssTile" data-token="thankYouMessage" style="font-family: 'Open Sans'">
  <div class="extra-chances extra-chances--primary template-color-primary-background-color">
    <div class="extra-chances__column extra-chances__column--primary-row extra-chances__column--full-width">
      <div class="extra-chances__title extra-chances__title--primary extra--chances__entry-received">Your entry has been received!</div>
    </div>
  </div>
  <div class="extra-chances extra-chances--secondary">
    <div class="extra-chances__column extra-chances__column--secondary-row extra-chances__column--full-width extra-chances__custom-thanks-message">
      ${message}
    </div>
    <div class="extra-chances__column extra-chances__column--secondary-row extra-chances__column--full-width extra-chances__custom-thanks-message">
      <button class="extra-chances__logout extra-chances__link-button template-color-primary-color">Log out</button>
    </div>
  </div>
</div>
`;
