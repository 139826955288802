/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';

/**
 * Use this component when you would like to allow sorting on multiple parameters for a
 * set of data. Only one parameter can be passed into a single component.
 *
 * Handles the display of the sorting indicator.
 *
 * @type {Ember.Component}
 */
export default Component.extend({
  //region Ember Hooks
  classNames: ['ss-sort'],
  //endregion

  //region Attributes
  /**
   * Tells the component which property the table is being currently being sorted by
   */
  'active-sort-property': null,
  /**
   * The sort property this sort option represents
   */
  'sort-property': null,
  /**
   * "asc" vs "desc" used to style sort indicator
   */
  'sort-direction': null,
  /**
   * The name to display on the component
   */
  label: null,
  /**
   * If true prevents the sort action from being fired
   */
  disabled: null,
  //endregion

  //region Hooks
  click() {
    if (!this.disabled) {
      // Fires a sorting function which you pass into the component upon using it
      if (this.action && typeof this.action === 'function') {
        this.action(this['sort-property']);
      }
    }
  },
  //endregion
});
