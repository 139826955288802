/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';

export default Component.extend({
  //region Ember Hooks
  tagName: 'expansion-panel',
  classNameBindings: ['expanded:expanded', 'expanded::collapsed'],
  //endregion

  //region Attributes
  expanded: false,
  //endregion
});
