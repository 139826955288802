import { animate, Promise, stop } from 'liquid-fire';

export default function designTemplateSlide(opts) {
  opts = opts || {};
  stop(this.oldElement);
  return Promise.all([
    animate(this.oldElement, { opacity: 0 }, opts),
    animate(this.newElement, { opacity: [opts.maxOpacity || 1, 0] }, { duration: 1000 }),
  ]);
}
