import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class OrganizationPromotionStatisticModel extends BaseModel {
  @attr('number') declare parentOrganizationId: number;
  @attr('number') declare entrants: number;
  @attr('number') declare newOptins: number;
  @attr('number') declare newUsers: number;
  @attr('number') declare promotions: number;
  @attr('number') declare users: number;
  @attr('number') declare votes: number;
  @attr('number') declare nominations: number;
  @attr('number') declare leads: number;

  @attr('string') declare organizationName: string;

  get organizationId() {
    return this.id;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'organization-promotion-statistic': OrganizationPromotionStatisticModel;
  }
}
