/* eslint-disable ember/no-mixins */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import PromotionTypeRoute from 'partner/mixins/promotion-type-route';

/**
 * Setup Index Route
 * /o/:organization_id/op/:organization_promotion_id/setup
 * @type {Ember.Route}
 * @mixes AuthenticatedRoute
 * @mixes PromotionTypeRoute
 */
export default class IndexRoute extends Route.extend(PromotionTypeRoute) {
  @service checklist;
  @service router;

  /**
   * Redirect the user to the appropriate step
   * @see http://emberjs.com/api/classes/Ember.Route.html#method_afterModel
   */
  afterModel() {
    // TODO: Redirect to either the first uncompleted step, or the dashboard.
    const { checklist } = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion.setup'
    );
    const firstStep = checklist.checklistSteps.sortBy('displayOrder').firstObject;
    const destination = this.checklist.getDestination(get(firstStep, 'checklistStepTypeId'));

    this.router.transitionTo(destination);
  }
}
