/* eslint-disable ember/no-jquery, ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import $ from 'jquery';
import InMemoryPaging from 'partner/mixins/in-memory-paging';
import isAnyPath from 'partner/utils/is-any-path';

export default Controller.extend(InMemoryPaging, {
  //region Ember Hooks
  session: service(),
  application: controller(),
  //endregion

  //region Computed Properties
  searchTerm: '',
  searchTermEmail: '',
  searchTermName: '',
  searchTermOrganization: '',
  searchTermCreateUsers: false,
  showAdvancedModal: false,
  advancedSearch: false,
  searchClickedToggle: true,
  defaultPlaceHolderText: true,
  noSearchResults: computed('pagedEntities', function () {
    return isBlank(this.pagedEntities);
  }),
  userIdString: computed('session.data.authenticated.organization_users.user_id', function () {
    return get(this, 'session.data.authenticated.organization_users.user_id').toString();
  }),
  isExpanded: false,
  paging: computed('model.partnerUsers', 'advancedSearch', 'searchTerm', function () {
    return this.createPagingObject(this.calculateEntities().length, this.pageSize);
  }),
  pagedEntities: computed('paging.pageIndex', 'model.partnerUsers', 'searchTerm', 'searchClickedToggle', function () {
    const { paging } = this;

    if (!this.searchClickedToggle.toString()) {
      return false;
    }

    const entities = this.calculateEntities();
    return this.getEntitiesByPage(paging, entities);
  }),
  canCreateNewUsers: isAnyPath('hasAdminUserCreationPermissions', ['model.editorPartnerUserOrganizationRoles.[]']),
  //endregion

  //region Methods
  calculateEntities() {
    const isAdvancedSearch = this.advancedSearch;
    const { searchTerm } = this;
    return get(this, 'model.partnerUsers').filter(user => {
      if (!isAdvancedSearch) {
        return this.searchBasic(user, searchTerm, [
          'firstName',
          'lastName',
          'fullName',
          'emailAddress',
          'organizationDetailsMeta',
        ]);
      } else if (isAdvancedSearch) {
        return this.searchAdvanced(
          user,
          this.searchTermName,
          this.searchTermEmail,
          this.searchTermOrganization,
          this.searchTermCreateUsers
        );
      }
      return false;
    });
  },

  searchProperties(user, searchTerm, properties) {
    return (
      properties.filter(property => {
        const value = get(user, property);
        if (property !== 'emailAddress' && property !== 'organizationDetailsMeta') {
          return value ? value.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0 : false;
        }
        return value ? value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 : false;
      }).length > 0
    );
  },

  searchBasic(user, searchTerm, properties) {
    return (
      this.searchProperties(user, searchTerm, properties) ||
      (searchTerm.indexOf(' ') >= 0 &&
        this.searchProperties(user, searchTerm.split(' ')[0], ['firstName']) &&
        this.searchProperties(user, searchTerm.split(' ')[1], ['lastName']))
    );
  },

  searchAdvanced(user, searchTermName, searchTermEmail, searchTermOrganization, searchTermCreateUsers) {
    return (
      this.searchBasic(user, searchTermName, ['firstName', 'lastName', 'fullName']) &&
      (searchTermCreateUsers ? get(user, 'hasAdminUserCreationRole') : true) &&
      get(user, 'emailAddress').toLowerCase().indexOf(searchTermEmail.toLowerCase()) !== -1 &&
      get(user, 'organizationDetailsMeta').toLowerCase().indexOf(searchTermOrganization.toLowerCase()) !== -1
    );
  },
  //endregion

  actions: {
    changePage(page) {
      set(this, 'paging.pageIndex', page);
    },
    checkSearchValidity(e) {
      set(this, 'searchTerm', e.target.value);
      if (this.searchTerm.length >= 2) {
        set(this, 'showAdvancedModal', false);
        set(this, 'advancedSearch', false);
        set(this, 'defaultPlaceHolderText', true);
      }
    },
    toggleAdvancedSearch() {
      if (this.isExpanded) {
        set(this, 'showAdvancedModal', false);
        if (!this.advancedSearch && this.searchTerm.length === 0) {
          set(this, 'defaultPlaceHolderText', true);
        }
        set(this, 'isExpanded', false);
      } else {
        set(this, 'defaultPlaceHolderText', false);
        set(this, 'showAdvancedModal', true);
        set(this, 'searchTerm', '');
        set(this, 'isExpanded', true);
      }
    },
    closeAdvancedSearch() {
      if (this.isExpanded) {
        set(this, 'showAdvancedModal', false);
        if (!this.advancedSearch && this.searchTerm.length === 0) {
          set(this, 'defaultPlaceHolderText', true);
        }
        set(this, 'isExpanded', false);
      }
    },
    searchAdvanced() {
      set(this, 'searchTerm', '');
      set(this, 'showAdvancedModal', false);
      this.toggleProperty('searchClickedToggle');
      set(this, 'advancedSearch', true);
      set(this, 'isExpanded', false);
    },
    setSearchInputFocus() {
      $('.ssUsersSearchInput').focus();
    },
    removeAdvancedPropertyChip(fieldName) {
      if (fieldName === 'searchTermCreateUsers') {
        set(this, fieldName, false);
      } else {
        set(this, fieldName, '');
      }
      if (
        !this.searchTermEmail &&
        !this.searchTermName &&
        !this.searchTermOrganization &&
        !this.searchTermCreateUsers
      ) {
        set(this, 'advancedSearch', false);
        set(this, 'searchTerm', '');
        set(this, 'defaultPlaceHolderText', true);
      } else {
        this.send('searchAdvanced');
      }
    },
  },
});
