import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<EntryForm::Field::Wrapper @label={{@formField.labelText}} ...attributes as |guid|>\n  <SsSelect\n    @selectId={{guid}}\n    @selectedValue={{this.entryFieldValue.fieldOption}}\n    @content={{@formField.field.sortedFieldOptions}}\n    @changed={{this.handleChange}}\n    @optionLabelPath=\"text\"\n    @optionValuePath=\"id\"\n    @prompt=\"Select an option...\"\n    @class=\"ss-material-select\"\n    @is-disabled={{@disabled}}\n    @two-way={{false}}\n  />\n</EntryForm::Field::Wrapper>\n", {"contents":"<EntryForm::Field::Wrapper @label={{@formField.labelText}} ...attributes as |guid|>\n  <SsSelect\n    @selectId={{guid}}\n    @selectedValue={{this.entryFieldValue.fieldOption}}\n    @content={{@formField.field.sortedFieldOptions}}\n    @changed={{this.handleChange}}\n    @optionLabelPath=\"text\"\n    @optionValuePath=\"id\"\n    @prompt=\"Select an option...\"\n    @class=\"ss-material-select\"\n    @is-disabled={{@disabled}}\n    @two-way={{false}}\n  />\n</EntryForm::Field::Wrapper>\n","moduleName":"partner/components/entry-form/field/select-single.hbs","parseOptions":{"srcName":"partner/components/entry-form/field/select-single.hbs"}});
import { action } from '@ember/object';

import Base from './base';

export default class EntryFormFieldSingleSelectSingleComponent extends Base {
  @action
  handleChange(fieldOption) {
    this.args.onChange(this.entryFieldValue, {
      value: fieldOption?.text,
      fieldId: this.fieldId,
      fieldOption,
    });
  }
}
