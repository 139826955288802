/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend({
  //region Properties
  name: DS.attr('string'),
  masterFormSubmissionId: DS.attr('number'),
  status: DS.attr('number'),
  organizationUserId: DS.attr('number'),
  dateCreated: DS.attr('date'),
  dateModified: DS.attr('date'),
  //endregion

  //region Relationships
  form: DS.belongsTo('form', { async: true }),
  entry: DS.belongsTo('entry', { async: true }),
  matchup: DS.belongsTo('matchup', { async: true }),
  //endregion
});
