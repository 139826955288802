import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

/**
 * Dashboard Quiz Route
 * /o/:organization_id/op/:organization_promotion_id/dashboard/quiz
 * @type {Ember.Route}
 */
export default class extends Route {
  @service('session') session;
  @service('store') store;
  @service('enums') enums;

  model() {
    const params = {
      organizationId: this.modelFor('organizations.organization').organization.id,
      organizationPromotionId: this.modelFor(
        'organizations.organization.organization-promotions.organization-promotion'
      ).organizationPromotion.id,
    };
    const fieldTypeId = this.enums.findWhere('FIELD_TYPE', { name: 'Optin' });

    return hash({
      categoricalCharts: this.store.query('categoricalChart', params),
      eventCharts: this.store.query('eventChart', params),
      registrationTypeSetting: this.store
        .query('setting', {
          key: 'Registration_Type',
          ...params,
        })
        .then(x => x.get('firstObject')),
      optinFields: this.store
        .query('field', {
          ...params,
          fieldTypeId,
          filterByOrganizationPromotion: true,
        })
        .then(x => x.filterBy('fieldType', 'Optin')),
    });
  }
}
