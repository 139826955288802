import Component from '@glimmer/component';

export default class MessageCampaignsDemographicsComponent extends Component {
  get transformedPostalCodeData() {
    return this.args.locationChart.reverseSortedCategories.filter(({ label }) => label !== 'Unknown');
  }

  get topPostalCode() {
    return this.transformedPostalCodeData?.firstObject?.label;
  }
}
