import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

const MODERATE_NOMINATIONS_ROUTE =
  'organizations.organization.organization-promotions.organization-promotion.moderate-nominations';

export default class extends Route {
  @service('store') store;

  async model() {
    const { matchupId } = this.paramsFor(MODERATE_NOMINATIONS_ROUTE);
    const { matchups } = this.modelFor(MODERATE_NOMINATIONS_ROUTE);
    const matchup = matchups.findBy('id', matchupId);

    return RSVP.hash({
      matchup,
      matchups,
      matchupEntryStatistics: this.store.query('matchup-entry-statistic', { matchupId }),
    });
  }

  @action
  loading(transition) {
    const controller = this.controllerFor(
      'organizations.organization.organization-promotions.organization-promotion.moderate-nominations'
    );

    controller.loadingModel = true;

    transition.promise.finally(() => {
      controller.loadingModel = false;
    });
  }
}
