import ApplicationAdapter from './application';

export default class OrganizationAdapter extends ApplicationAdapter {
  private allLoaded = false;

  findAll(...args: any[]) {
    try {
      // @ts-ignore
      return super.findAll(...args);
    } finally {
      this.allLoaded = true;
    }
  }

  shouldReloadAll(...args: any) {
    const force = args[1]?.adapterOptions?.force;
    return force ?? !this.allLoaded;
  }
}
