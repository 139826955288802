/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import { and, equal, not } from '@ember/object/computed';
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import Copyable from 'partner/mixins/copyable-model';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;

export default BaseModel.extend(Copyable, {
  //region Properties
  isOwnedByRoot: attr('boolean'),
  isInherited: attr('boolean'),
  browserPlatformTypeId: attr('number'),
  wrapperMethodTypeId: attr('number'),
  htmlContent: attr('string'),
  applicationTokenKey: attr('string'),
  scrapeUrl: attr('string'),
  //endregion

  //region Computed Properties
  browserPlatformType: enums.computed('name', 'browserPlatformTypeId'),
  wrapperMethodType: enums.computed('name', 'wrapperMethodTypeId'),
  isRootAndInherited: and('isOwnedByRoot', 'isInherited'),
  isUsingDefault: computed('isOwnedByRoot', 'isInherited', function () {
    return this.isInherited && !this.isOwnedByRoot;
  }),
  isOneTimeWrapper: not('isInherited'),
  isCustomNoWrapper: equal('wrapperMethodType', 'No Wrapper'),
  //endregion
});
