import { get } from '@ember/object';
import flatten from 'lodash/flatten';

/**
 * @param {Field[]} fields
 * @returns {Field[]}
 */
export default function (potentiallyDisplayedFields, formPage) {
  const allFields = flatten(
    get(formPage, 'form.formPages')
      .mapBy('formFields')
      .map(formFields => formFields.toArray())
  ).mapBy('fields');
  return potentiallyDisplayedFields.reject(field => {
    if (field.isFacebookLikeApi) {
      return allFields.some(existingField => {
        const fieldUrl = field.relatedUrl;
        const existingFieldUrl = existingField.relatedUrl;
        const fieldName = field.name;
        const existingFieldName = existingField.name;
        return fieldUrl === existingFieldUrl && fieldName === existingFieldName;
      });
    }
    return allFields.includes(field);
  });
}
