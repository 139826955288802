import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * Setup Dictionary Route - Allows the configuration of UI Text
 * /o/:organization_id/op/:organization_promotion_id/setup/dictionary
 * @type {Ember.Route}
 */
export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionSetupDictionaryRoute extends Route {
  @service store;

  model() {
    return this.store.query('setting', { category: 'UI_Text' });
  }

  @action
  save() {
    this.currentModel.filterBy('hasDirtyAttributes').forEach(setting => setting.save());
  }
}
