/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import endsWith from 'lodash/endsWith';
import trim from 'lodash/trim';
import BaseModel from 'secondstreet-common/models/base';

type Tier = 1 | 2 | 3 | 4 | 5;

export default class InterestTagModel extends BaseModel {
  @attr('string') declare name: string;
  @attr('string') declare fullName: string;
  @attr('number') declare tier: Tier;
  @attr('boolean') declare isFromSource: boolean;

  @belongsTo('interest-tag', { inverse: 'childInterestTags', async: false }) parentInterestTag?: InterestTagModel;
  @hasMany('interest-tag', { inverse: 'parentInterestTag', async: false })
  declare childInterestTags: SyncHasMany<InterestTagModel>;

  @equal('tier', 1) declare isTierOne: boolean;
  @equal('tier', 2) declare isTierTwo: boolean;
  @equal('tier', 3) declare isTierThree: boolean;
  @equal('tier', 4) declare isTierFour: boolean;
  @equal('tier', 5) declare isTierFive: boolean;

  get formattedFullName() {
    const formattedName = `<b>${this.name}</b>`;

    // replace last occurrence of name
    return this.fullName.slice(0, this.fullName.lastIndexOf(this.name)) + formattedName;
  }

  get fullNamePrefix() {
    return endsWith(this.fullName, this.name)
      ? trim(this.fullName.slice(0, this.fullName.lastIndexOf(this.name)), '> ')
      : this.fullName;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'interest-tag': InterestTagModel;
  }
}
