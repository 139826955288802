/* eslint-disable ember/no-observers */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { addObserver, removeObserver } from '@ember/object/observers';
import { registerDestructor } from '@ember/destroyable';

/**
 * Provides a div (or other element as specified by tagName) where contenteditable can be turned on or off.
 * The contenteditable property must be the STRING true, not the boolean true. Because web browsers.
 */
export default class SsContentEditable extends Component {
  cachedValue = '';

  constructor(...args) {
    super(...args);

    const callback = () => this.#updateValue(this.args.value);
    addObserver(this.args, 'value', callback);
    registerDestructor(this, () => removeObserver(this.args, 'value', callback));
  }

  @action
  handleMount(element) {
    this.element = element;
    this.#updateValue(this.args.value);

    if (!this.args.autofocus) return;

    this.element.focus();
    document.execCommand('selectAll', false, null);

    if (this.args.isNew) {
      const range = document.createRange(); //Create a range (a range is a like the selection but invisible)
      range.selectNodeContents(this.element); //Select the entire contents of the element with the range
      const selection = window.getSelection(); //get the selection object (allows you to change selection)
      selection.removeAllRanges(); //remove any selections already made
      selection.addRange(range); //make the range you have just created the visible selection
    }
  }

  @action
  handleInput(e) {
    this.cachedValue = e.target.innerText;
    this.args.onChange(e.target.innerText);
  }

  #updateValue(value) {
    if (value === this.cachedValue || !this.element) return;

    this.cachedValue = value;
    this.element.innerText = value;
  }
}
