/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;
/**
 * @typedef {DS.Model} RecentMessageCampaign
 */
export default BaseModel.extend({
  //region Attributes
  messageCampaignName: attr('string'),

  messageCampaignTypeId: attr('number'),

  sentDate: attr('date'),

  opens: attr('number'),

  clicks: attr('number'),

  isLegacyMessage: attr('boolean'),

  legacyMessageId: attr('string'),

  organizationId: attr('number'),
  //endregion

  //region Computed Properties
  messageCampaignType: enums.computed('name', 'messageCampaignTypeId'),
  //endregion
});
