/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { gt, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { SETTINGS_ROUTES } from 'partner/utils/constants';

export default Component.extend({
  //region Attributes
  /**
   * @type {?Organization}
   */
  organization: null,

  /**
   * @type {?String}
   */
  'organization-id': null,

  /**
   * @type {Boolean}
   */
  'has-promotions': false,

  /**
   * @type {Boolean}
   */
  'has-interactive-content': false,
  //endregion

  //region Properties
  _sideNavIsOpen: true,
  //endregion

  //region Dependencies
  features: service(),
  screen: service(),
  session: service(),
  navigation: service(),
  router: service(),
  localStorage: service('local-storage'),
  //endregion

  init() {
    this._super(...arguments);
    set(this, '_sideNavIsOpen', this.localStorage.getItem('isSideNavOpen') !== false);
  },

  //region Ember Hooks
  classNames: ['organization-page'],
  //endregion

  //region Computed Properties
  /**
   * @returns {String}
   */
  _organizationId: computed('organization.id', 'organization-id', function () {
    return get(this, 'organization.id') || this['organization-id'];
  }),
  /**
   * @returns {Boolean}
   */
  isDesktop: computed('screen.width', function () {
    return get(this, 'screen.width') > 920; // same as defined in organization-page.scss
  }),
  /**
   * @returns {Number}
   */
  userId: readOnly('session.data.authenticated.organization_users.user_id'),
  /**
   * @returns {String}
   */
  email: readOnly('session.data.authenticated.username'),
  /**
   * @returns {Boolean}
   */
  hasDiscoverAccess: computed('session.data.authenticated.has_discover_access,features.hasDiscoverAccess', function () {
    return get(this, 'features.HasDiscoverAccess') && get(this, 'session.data.authenticated.has_discover_access');
  }),
  /**
   * @returns {Boolean}
   */
  hasMultipleOrganizations: gt('session.data.authenticated.organizations_count', 1),
  /**
   * @returns {String}
   */
  username: computed('session.data.authenticated.organization_users.{first_name,last_name}', function () {
    const firstName = get(this, 'session.data.authenticated.organization_users.first_name');
    const lastName = get(this, 'session.data.authenticated.organization_users.last_name');
    return `${firstName} ${lastName}`;
  }),

  navigationClassName: computed(
    'navigation.entity.{promotion.promotionType,messageCampaignType,audienceType,type}',
    function () {
      const { entity } = this.navigation;
      return entity?.promotion?.promotionType || entity?.messageCampaignType || entity?.audienceType || entity?.type;
    }
  ),

  /**
   * Because Events, Contests, and Interactive Content are all architecturally nested in "op/", we have to
   * override their link-to active states to pretend they're nested in their appropriate promo type
   */
  isContestsLinkActive: computed(
    'navigation.entity.promotion.promotionTypeCategory',
    'router.currentRouteName',
    function () {
      return (
        this.navigation.entity?.promotion?.promotionTypeCategory === 'contest' ||
        this.router.currentRouteName.includes('organizations.organization.organization-promotions.index')
      );
    }
  ),

  isInteractiveContentLinkActive: computed(
    'navigation.entity.promotion.promotionTypeCategory',
    'router.currentRouteName',
    function () {
      return (
        this.navigation.entity?.promotion?.promotionTypeCategory === 'interactiveContent' ||
        this.router.currentRouteName.includes('organizations.organization.interactive-content.index')
      );
    }
  ),

  isEventsLinkActive: computed(
    'navigation.entity.promotion.promotionTypeCategory',
    'router.currentRouteName',
    function () {
      return (
        this.navigation.entity?.promotion?.promotionTypeCategory === 'event' ||
        this.router.currentRouteName.includes('organizations.organization.events.index')
      );
    }
  ),

  isAudiencesLinkActive: computed('router.currentRouteName', function () {
    return this.router.currentRouteName.includes('organizations.organization.data.audiences.index');
  }),

  isMessageCampaignsLinkActive: computed('router.currentRouteName', function () {
    return this.router.currentRouteName.includes('organizations.organization.message-campaigns.index');
  }),

  isSettingsActive: computed('router.currentRouteName', function () {
    return SETTINGS_ROUTES.includes(this.router.currentRouteName);
  }),

  sideNavIsOpen: computed('screen.width', '_sideNavIsOpen', function () {
    return this.screen.width >= 730 && this._sideNavIsOpen;
  }),
  //endregion

  //region Actions
  actions: {
    updateContent() {
      this.localStorage.setItem('isSideNavOpen', !this._sideNavIsOpen);
      set(this, '_sideNavIsOpen', !this._sideNavIsOpen);
    },
  },
  //endregion
});
