import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class NewRoute extends Route {
  @service router;

  model(params) {
    return params.design_template_type_id;
  }

  afterModel(designTemplateTypeId) {
    this.router.replaceWith('organizations.organization.legal.design-templates.new', designTemplateTypeId);
  }
}
