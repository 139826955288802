/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { alias, equal, readOnly } from '@ember/object/computed';
import { debounce } from '@ember/runloop';
import { isBlank } from '@ember/utils';
import $ from 'jquery';

/**
 * View All Tags View.
 * @type {Ember.View}
 */
export default Component.extend({
  //region Ember Hooks
  click() {
    return false;
  },
  didInsertElement() {
    this._super(...arguments);
    if (get(this, 'controller.showSearch')) {
      $('.ssViewAllItemsInput').focus();
    }
  },
  //endregion

  //region Properties
  itemSorting: computed(() => ['name']),
  isLoadingSearchResults: false,
  /**
   * Default page number
   * @type {Number}
   */
  page: 1,
  /**
   * Default number of items shown per page
   * @type {Number}
   */
  perPage: 100,
  searchInput: '',
  search: '',
  //endregion

  //region Computed Properties
  topLevelItems: computed('model.allItems.{[],length}', 'forceRecomputeTopLevelItems', function () {
    const items = get(this, 'model.allItems').filterBy('tier', 1);

    if (items.length > 0) {
      return items;
    }
    return get(this, 'model.allItems');
  }),
  /**
   * True if all available records are currently displayed in the list
   * @type {Ember.ComputedProperty}
   * @return {Boolean}
   */
  hideShowMore: computed('totalRecords', 'visibleSortedTopLevelItems', 'search', function () {
    if (this.search) {
      return this.visibleItems > get(this, 'visibleSortedTopLevelItems.length');
    }
    return this.totalRecords === get(this, 'visibleSortedTopLevelItems.length');
  }),
  sortedTopLevelItems: computed('topLevelItems.[]', function () {
    return this.topLevelItems.sortBy('name');
  }),
  /**
   * Returns true if the list is loaded with Interest Tags
   * @type {Ember.ComputedProperty}
   * @return {Boolean}
   */
  isTags: equal('model.itemType', 'interestTag'),
  /**
   * Returns true if the list is loaded with Global Optins
   * @type {Ember.ComputedProperty}
   * @return {Boolean}
   */
  isOptins: equal('model.itemType', 'globalOptin'),
  isFields: equal('model.itemType', 'field'),
  isPromotions: equal('model.itemType', 'organizationPromotion'),
  isMessages: equal('model.itemType', 'messageCampaign'),
  isAudiences: equal('model.itemType', 'audience'),
  /**
   *
   */
  visibleSortedTopLevelItems: computed(
    'model.useLoadMore',
    'visibleItems',
    'sortedTopLevelItems',
    'topLevelItems',
    'search',
    function () {
      const search = this.search.toLowerCase();
      const isSearchBlank = isBlank(search);
      const items = this.sortedTopLevelItems
        .map(item => (isSearchBlank ? item : this._isFilterMatch(search, item) ? item : null))
        .compact();

      if (get(this, 'model.useLoadMore')) {
        return items.slice(0, this.visibleItems);
      }
      return items;
    }
  ),
  /**
   * The total number of items in the collection displayed in the model
   */
  totalRecords: readOnly('topLevelItems.length'),
  /**
   * The total of number items currently visibleO
   */
  visibleItems: alias('perPage'),
  /**
   * The currently displayed modal title
   */
  title: computed('search', 'model', function () {
    if (isBlank(this.search)) {
      return get(this, 'model.header');
    }
    return get(this, 'model.searchingHeader') || 'Select Results To Filter By';
  }),
  //endregion

  //region Methods
  /**
   * If SearchStore is set on the view-all-items modal,
   * the search will be called to the store instead of to your loaded items.
   * @private
   */
  _updateSearch() {
    if (!this.isDestroyed) {
      if (get(this, 'model.searchFunction')) {
        set(this, 'isLoadingSearchResults', true);
        const searchFunction = get(this, 'model.searchFunction');
        searchFunction(this.searchInput).then(() => {
          set(this, 'isLoadingSearchResults', false);
        });
      } else {
        set(this, 'search', this.searchInput);
      }
    }
  },
  /**
   * Returns true if the name of the item matches the search input
   * @private
   */
  _isFilterMatch(search, item) {
    return get(item, 'name') && get(item, 'name').toLowerCase().indexOf(search) > -1;
  },
  //endregion

  //region Actions
  actions: {
    /**
     * Loads more items to display in the list of available items,
     * has a slight delay to simulate API latency
     */
    loadMoreItems() {
      set(this, 'visibleItems', this.visibleItems + this.perPage);
    },
    alternateActionClicked() {
      this.close();
      if (this['alternate-action'] && typeof this['alternate-action'] === 'function') {
        this['alternate-action']();
      }
    },
    updateSearch(value) {
      set(this, 'searchInput', value);
      // Debounce updating the search input used for filtering, since typing speeds can cause lag.
      debounce(this, this._updateSearch, 250);
    },
  },
  //endregion
});
