/* eslint-disable ember/no-mixins */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ResetScrollRoute from 'partner/mixins/reset-scroll-route';
import RSVP from 'rsvp';

const AUDIENCE_ROUTE = 'organizations.organization.data.audiences.audience';

export default class extends Route.extend(ResetScrollRoute) {
  @service('session') session;
  @service('store') store;

  eventChartValueProperty = 'aggregate';

  async model() {
    const { id: organizationId } = this.modelFor('organizations.organization').organization;
    const { id: audienceId } = this.modelFor(AUDIENCE_ROUTE).audience;

    return RSVP.hash({
      categoricalCharts: this.store.query('categoricalChart', { audienceId, organizationId }),
      eventCharts: this.store.query('eventChart', { organizationId }),
    });
  }
}
