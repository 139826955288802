import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class OrgsOrganizationRoute extends Route {
  @service router;
  @service session;
  @service store;

  async model(params) {
    return {
      organization: await this.store.findRecord('organization', params.organization_id),
    };
  }

  // Remove or refactor this once Rogers is back
  async redirect(model) {
    if (model.organization.statusTypeId === 7) {
      await this.session.invalidate();

      return this.router.replaceWith('index');
    }
  }
}
