/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import formatNumber from 'partner/utils/format-number';

export default Component.extend({
  //region Attributes
  /**
   * @type {String|Number}
   */
  kpi: null,
  /**
   * @type {String}
   */
  label: null,
  //endregion

  //region Ember Hooks
  classNames: ['kpi-end-detail'],
  //endregion

  //region Computed Properties
  _isKpiNumber: computed('kpi', function () {
    return typeof this.kpi === 'number';
  }),

  _kpi: computed('_isKpiNumber', 'kpi', function () {
    const { kpi } = this;
    return this._isKpiNumber ? formatNumber(kpi) : kpi;
  }),
  //endregion
});
