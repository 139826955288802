/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  //region Attributes
  'video-id': null,
  'auto-play': false,
  /**
   * Due to a change in YouTube's API, disabling this will only prevent user from being recommended related
   * videos from other channels. The user will still see related videos from the same channel this video belongs to.
   */
  'show-related-videos': true,
  //endregion

  //region Properties
  youtubeVideo: null,
  //endregion

  //region Computed Properties
  playerVars: computed('auto-play', 'show-related-videos', function () {
    return {
      autoplay: this['auto-play'] ? 1 : 0,
      enablejsapi: 1,
      rel: this['show-related-videos'] ? 1 : 0,
    };
  }),
  //endregion
});
