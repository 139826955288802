/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default Component.extend({
  enums: service(),
  store: service(),

  name: readOnly('entity.name'),

  isAnythingPurchasedTag: computed('audience-filter', function () {
    return (
      get(this, 'audience-filter.entityTypeId') ===
        this.enums.findWhere('ENTITY_TYPE', {
          name: 'Commerce Product',
        }) &&
      get(this, 'audience-filter.audienceFilterTypeId') ===
        this.enums.findWhere('AUDIENCE_FILTER_TYPE', {
          name: 'PurchaseBehavior',
        }) &&
      parseInt(get(this, 'audience-filter.entityId')) === 0
    );
  }),

  isInterestTag: computed('audience-filter.entityTypeId', function () {
    return get(this, 'audience-filter.entityTypeId') === this.enums.findWhere('ENTITY_TYPE', { name: 'Interest Tag' });
  }),
  entity: computed('isInterestTag', 'taggable-entities', function () {
    if (this.isInterestTag === true) {
      return this.store.find('interestTag', get(this, 'audience-filter.entityId'));
    } else if (this.isInterestTag === false) {
      //We can't do individual GETs for taggableEntites, so pass them in and find them that way
      return this['taggable-entities'].findBy('entityId', window.parseInt(get(this, 'audience-filter.entityId')));
    }
    return null;
  }),
  text: computed('entity.content', function () {
    let text = null;
    const entity = this.isInterestTag ? get(this, 'entity.content') : this.entity;
    const precedingWindowDays = get(this, 'audience-filter.precedingWindowDays');
    const isExcluded = get(this, 'audience-filter.isExcluded');
    if (entity || this.isAnythingPurchasedTag) {
      const entityName = entity ? `a ${get(entity, 'name')}` : 'any';
      if (isExcluded) {
        if (!isEmpty(precedingWindowDays)) {
          text = `Not purchased ${entityName} offer in the last ${precedingWindowDays} days`;
        } else {
          text = `Never purchased ${entityName} offer`;
        }
      } else {
        if (!isEmpty(precedingWindowDays)) {
          text = `Purchased ${entityName} offer in the last ${precedingWindowDays} days`;
        } else {
          text = `Purchased ${entityName} offer`;
        }
      }
    }
    return text;
  }),
});
