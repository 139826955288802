/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import EmberObject, { get } from '@ember/object';
import Mixin from '@ember/object/mixin';

/**
 * Common logic for in memory paging.
 */
export default Mixin.create({
  //region Properties
  pageSize: 50,
  //endregion

  //region Methods
  createPagingObject(totalCount, pageSize) {
    return EmberObject.create({
      pageIndex: 1, //default to first page
      pageSize,
      totalRecords: totalCount,
    });
  },
  /**
   * Common logic for getting a certain "page" of results from then entire entities array.
   * @param paging {Object}
   * @param entities {Array}
   * @returns {Array}
   */
  getEntitiesByPage(paging, entities) {
    const end = get(paging, 'pageSize') * get(paging, 'pageIndex');
    const start = end - get(paging, 'pageSize');
    return entities.slice(start, end);
  },
  //endregion
});
