import { inject as service } from '@ember/service';
import ApplicationAdapter from './application';
import type Store from '@ember-data/store';

export default class FieldAdapter extends ApplicationAdapter {
  @service declare store: Store;
  private allLoaded = false;

  findAll(...args: any[]) {
    try {
      // @ts-ignore
      return super.findAll(...args);
    } finally {
      this.allLoaded = true;
    }
  }

  shouldReloadAll(...args: any) {
    const force = args[1]?.adapterOptions?.force;
    return force ?? !this.allLoaded;
  }

  unloadAll() {
    this.store.unloadAll('field');
    this.allLoaded = false;
  }
}
