/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default Route.extend({
  store: service(),

  model() {
    return this.modelFor('organizations.organization.data.audiences.audience.setup');
  },

  afterModel(model) {
    const latestCountDate = get(model, 'audience.latestCountDate');
    const hoursPassed = moment().diff(moment(latestCountDate), 'hours');
    if (hoursPassed > 1 || !latestCountDate) {
      // Refresh audience count if more than an hour has passed since last count
      // or if there has never been a count
      this.updateAudienceCount();
    }
  },

  updateAudienceCount() {
    this.store.query('audience', {
      id: get(this.modelFor(this.fullRouteName), 'audience.id'),
      forceUpdateCount: true,
      organizationId: get(this.modelFor('organizations.organization.data.audiences'), 'organization.id'),
    });
  },

  actions: {
    updateAudienceCount() {
      this.updateAudienceCount();
    },
  },
});
