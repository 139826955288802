import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each this.sortedCharts key=\"id\" as |chart|}}\n  <Dashboards::FieldChart @chart={{chart}} @downloadReport={{@downloadReport}} @forceSkipLoading={{chart.isOptin}} />\n{{/each}}\n\n<div hidden {{did-insert this.fetchOptinCharts}}></div>\n", {"contents":"{{#each this.sortedCharts key=\"id\" as |chart|}}\n  <Dashboards::FieldChart @chart={{chart}} @downloadReport={{@downloadReport}} @forceSkipLoading={{chart.isOptin}} />\n{{/each}}\n\n<div hidden {{did-insert this.fetchOptinCharts}}></div>\n","moduleName":"partner/components/dashboards/field-charts.hbs","parseOptions":{"srcName":"partner/components/dashboards/field-charts.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class DashboardsFieldChartsComponent extends Component {
  get sortedCharts() {
    return this.args.charts.sort((a, b) => {
      if (a.isOptin && b.isOptin) {
        return b.optinAnsweredCount - a.optinAnsweredCount;
      }

      return a.isOptin ? -1 : 1;
    });
  }

  @action
  fetchOptinCharts() {
    this.args.charts.forEach(chart => {
      if (chart.isOptin) chart.fetchCategories();
    });
  }
}
