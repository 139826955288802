const isProduction =
  window.location.hostname == 'partners.secondstreet.com' || window.location.hostname.includes('partners-qa-staging');

const sanitizeWordpressShortcode = (script: string) => script.replace(/\s+/g, ' ').trim();

export const wordpressCodeForPromoFeeds = (orgId: string) =>
  sanitizeWordpressShortcode(`[ss-feed organization_id="${orgId}"${isProduction ? '' : ' dev="true"'}]`);

export const wordpressCodeForPromotions = (orgPromoId: string, guid: string) =>
  sanitizeWordpressShortcode(`
    [ss-promo
      op_id="${orgPromoId}"
      op_guid="${guid}"
      routing="hash"
      ${isProduction ? '' : 'dev="true"'}]`);

export const wordpressCodeForWidgets = (designId: string) =>
  sanitizeWordpressShortcode(`[ss-signup design_id="${designId}"]`);

export const wordpressCodeForPreferenceCenter = (orgId: string) =>
  sanitizeWordpressShortcode(`[ss-preferences organization_id="${orgId}"]`);
