/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default class OrganizationsOrganizationDataAudiencesAudienceSetupIntegrationController extends Controller {
  //region Ember Hooks
  @service
  enums;
  //endregion

  //region Computed Properties
  @computed('model.audienceIntegration.integrationExternalServiceId')
  get audienceIntegrationName() {
    return this.enums.findWhere(
      'AUDIENCE_INTEGRATION_TYPE',
      { id: this.model.audienceIntegration?.integrationExternalServiceId },
      'name'
    );
  }

  @computed('model.audienceIntegration.statusTypeId')
  get audienceIntegrationStatus() {
    return this.enums.findWhere('STATUS_TYPE', { id: this.model.audienceIntegration?.statusTypeId }, 'name');
  }

  @computed('audienceIntegrationStatus', 'model.isAudienceArchived')
  get hasIntegration() {
    return (
      !this.model.isAudienceArchived && ['Active', 'Submitted', 'Cancelled'].includes(this.audienceIntegrationStatus)
    );
  }

  @equal('audienceIntegrationStatus', 'Submitted')
  isPending;
}
