/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/* globals twttr */
import Component from '@ember/component';
import { computed } from '@ember/object';

/**
 * Display a Twitter tweet button
 */
export default Component.extend({
  //region Properties
  disableInteraction: false,
  classNames: ['ssTwitterTweet'],
  /**
   * This should be the hashtag without the # sign
   */
  hashtag: '',
  /**
   * This is the URL to twitter's tweet API
   */
  buttonUrl: computed('hashtag', function () {
    const { hashtag } = this;
    if (hashtag) {
      return `https://twitter.com/intent/tweet?button_hashtag=${hashtag}`;
    }
    return 'https://twitter.com/share';
  }),
  /**
   * This is the text displayed on the tweet button
   */
  buttonText: computed('hashtag', function () {
    const { hashtag } = this;
    if (hashtag) {
      return `Tweet #${hashtag}`;
    }
    return 'Tweet';
  }),
  /**
   * This determines the appropriate css class with which to render the button
   * @returns {string}
   */
  buttonCssClass: computed('hashtag', function () {
    const { hashtag } = this;
    if (hashtag) {
      return 'twitter-hashtag-button';
    }
    return 'twitter-share-button';
  }),
  //endregion

  //region Observers
  didInsertElement() {
    this._super(...arguments);
    twttr.widgets.load();
  },
  //endregion
});
