import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { endpoint } from 'secondstreet-common/utils/url';

export default class LoginUcmRoute extends Route {
  @service router;
  @service session;

  queryParams = {
    uniqueUri: { refreshModel: true, replace: true },
  };

  beforeModel(transition) {
    if (this.session.isAuthenticated) {
      this.router.replaceWith('index');
      return;
    }
    const { queryParams } = transition.to;
    if (queryParams.uniqueUri) {
      this.session
        .request(`${endpoint('ucm')}?uniqueUri=${queryParams.uniqueUri || ''}`, {
          contentType: 'text/javascript',
          dataType: 'json',
        })
        .then(
          response => {
            if (Array.isArray(response.redirectUrl) && isPresent(response.redirectUrl.firstObject)) {
              window.location = response.redirectUrl.firstObject;
              return;
            }
          },
          () => this.router.replaceWith('login.index')
        );
    } else {
      this.router.replaceWith('login.index');
    }
  }
}
