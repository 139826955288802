/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  //region Dependencies
  features: service(),
  //endregion

  //region Ember Hooks
  classNames: ['template-list'],
  //endregion

  //region Attributes
  /**
   * @property {Object}
   */
  'current-template': null,
  /**
   * @property {Array}
   */
  templates: null,
  /**
   * @property {String}
   */
  'dips-url': null,
  /**
   * @property {Function}
   */
  'set-body-template'() {},
  /**
   * @property {Boolean}
   */
  'editing-disabled': false,
  /**
   * @property {Boolean}
   */
  'show-email-upsell': false,
  /**
   * @property {Boolean}
   */
  'access-to-template-builder': false,
  /**
   * @property {String}
   */
  'message-version-id': null,
  /**
   * @property {String}
   */
  'message-campaign-id': null,
  /**
   * @property {String}
   */
  'organization-promotion-id': null,
  /**
   * @property {String}
   */
  'audience-id': null,
  /**
   * @property {String}
   */
  'message-campaign-type-id': null,
  //endregion

  //region Computed Properties
  showCustomTemplateOption: computed(
    'access-to-template-builder',
    'features.hasCustomTemplateBuilder',
    'show-email-upsell',
    'editing-disabled',
    function () {
      return (
        this['access-to-template-builder'] &&
        get(this, 'features.hasCustomTemplateBuilder') &&
        !this['show-email-upsell'] &&
        !this['editing-disabled']
      );
    }
  ),
  //endregion

  //region Actions
  actions: {
    selectTemplate(template) {
      if (this['editing-disabled']) {
        return;
      }
      this['set-body-template'](template);
    },
    thumbnailForMediaItemId(id) {
      return `//${this['dips-url']}/${id}?width=290`;
    },
  },
  //endregion
});
