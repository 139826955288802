import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"ssAudienceFilterOptions\" ...attributes>\n  <Tagging::Selector\n    @selected={{this.chosenInterestTags}}\n    @onChange={{this.update}}\n    @style=\"subtle\"\n    @placeholder=\"Add Filter...\"\n    @disabled={{@disabled}}\n  >\n    <Audience::FilterTags\n      @items={{this.chosenInterestTags}}\n      @operator={{@audience.interestTagOperatorTypeId}}\n      @onOperatorChange={{set @audience \"interestTagOperatorTypeId\"}}\n      as |interestTag|\n    >\n      <Tagging::Tag @interestTag={{interestTag}} @onRemove={{unless @disabled (fn this.remove interestTag)}} />\n    </Audience::FilterTags>\n  </Tagging::Selector>\n</div>\n", {"contents":"<div class=\"ssAudienceFilterOptions\" ...attributes>\n  <Tagging::Selector\n    @selected={{this.chosenInterestTags}}\n    @onChange={{this.update}}\n    @style=\"subtle\"\n    @placeholder=\"Add Filter...\"\n    @disabled={{@disabled}}\n  >\n    <Audience::FilterTags\n      @items={{this.chosenInterestTags}}\n      @operator={{@audience.interestTagOperatorTypeId}}\n      @onOperatorChange={{set @audience \"interestTagOperatorTypeId\"}}\n      as |interestTag|\n    >\n      <Tagging::Tag @interestTag={{interestTag}} @onRemove={{unless @disabled (fn this.remove interestTag)}} />\n    </Audience::FilterTags>\n  </Tagging::Selector>\n</div>\n","moduleName":"partner/components/audience/interest-tag-audience-filters.hbs","parseOptions":{"srcName":"partner/components/audience/interest-tag-audience-filters.hbs"}});
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import difference from 'lodash/difference';
import type AudienceModel from 'partner/models/audience';
import type AudienceFilterModel from 'partner/models/audience-filter';
import type InterestTagModel from 'partner/models/interest-tag';

interface InterestTagAudienceFiltersSignature {
  Element: HTMLDivElement;
  Args: {
    audience: AudienceModel;
    audienceFilters: AudienceFilterModel[];
    operatorChoices: string[];
  };
}

export default class InterestTagAudienceFilters extends Component<InterestTagAudienceFiltersSignature> {
  @service declare store: Store;

  get chosenInterestTags() {
    return this.args.audienceFilters
      .map(audienceFilter => this.store.peekRecord('interest-tag', audienceFilter.entityId))
      .filter(Boolean) as InterestTagModel[];
  }

  @action
  async update(interestTags: InterestTagModel[]) {
    const currentInterestTags = await Promise.all(this.chosenInterestTags);

    difference(currentInterestTags, interestTags).map(interestTag => this.remove(interestTag));
    difference(interestTags, currentInterestTags).map(interestTag => this.add(interestTag));
  }

  @action
  add(interestTag: InterestTagModel) {
    const newAudienceFilter = this.store.createRecord('audienceFilter', {
      audienceFilterType: 'InterestTag',
      comparisonType: 'GreaterThanOrEqualTo',
      filterValue: 1,
      entityId: interestTag.id,
      audience: this.args.audience,
    });

    this.args.audience.audienceFilters.then(x => x.addObject(newAudienceFilter));
  }

  @action
  remove(interestTag: InterestTagModel) {
    this.args.audienceFilters.findBy('entityId', interestTag.id)?.deleteRecord();
  }
}
