/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  //region Ember Hooks
  tagName: '',
  //endregion

  //region Attributes
  /**
   * @property {String}
   */
  designer: null,
  /**
   * @property {Number}
   */
  categories: null,
  //endregion

  //region Computed Properties
  tokenCategories: computed('categories', function () {
    return new Array(this.categories);
  }),
  //endregion
});
