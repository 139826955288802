/* eslint-disable ember/no-mixins, ember/no-get, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set, setProperties } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import PermittedComponent from 'partner/mixins/permitted-component';
import { getFieldTypeDefaultValue } from 'partner/utils/field-type-metadata';
import omit from 'lodash/omit';

export default Component.extend(PermittedComponent, {
  //region Ember Hooks
  init() {
    this._super(...arguments);
    set(this, 'field-type-blacklist', this['field-type-blacklist'] || []);
    set(this, 'unstarred-fields', this['unstarred-fields'] || []);
  },
  //endregion

  //region Ember Hooks
  tagName: 'form-designer',

  enums: service(),
  store: service(),

  classNames: ['form-designer'],
  classNameBindings: ['inline-page-editor:form-designer--inline-page-editor'],
  //endregion

  //region Attributes
  'field-type-blacklist': null,
  'is-anything-saving': false,
  /**
   * If enabled, this displays the form designer without its border and save bar styles.
   * @type {Boolean}
   */
  'inline-page-editor': false,
  /**
   * If enabled, this disables features on the form designer.
   * @type {Boolean}
   */
  disabled: false,
  'disable-help-text': false,
  /**
   * If enabled, hides the Add Page button and the page counter at the top of the form
   * @property {Boolean}
   */
  'single-page-form': false,
  /**
   * If disabled, disables clicking on a form field to open its editor modal (resorting and removing are preserved)
   * @property {Boolean}
   */
  'can-edit-form-fields': true,
  /**
   * @property {Boolean}
   */
  'can-create-custom-fields': true,
  /**
   * If set to true, the form designer-edit-form-field modal becomes hidden.
   * This is intended for when any modal on top of this one is rendered.
   * @property {Boolean}
   */
  isEditModalHidden: false,
  /**
   * @property {String}
   */
  'field-type': '',
  /**
   * @property {Array}
   */
  'unstarred-fields': null,
  /**
   * The function for saving the form/fields
   * @type {Function}
   */
  'save-form'() {},
  /**
   * The action to call when changes are complete (saved)
   * @type {Function}
   */
  'something-finished-changing'() {},
  /**
   * The action to call when a change has been made
   * @type {Function}
   */
  'something-changed'() {},
  'dips-url': null,
  isLastPageLocked: false,
  canAddFormPages: false,
  toggleEditingGlobalOptinId() {},
  //endregion

  //region Properties
  formPagesSorting: computed(() => ['pageNumber:asc']),
  //endregion

  //region Methods
  restoreFieldOptions(formField) {
    this.store.createRecord('fieldOption', {
      field: formField.field,
      fieldOptionTypeId: 1,
      text: formField.field.fieldOptions.firstObject.text === 'ios' ? 'android' : 'ios',
      displayOrder: formField.field.fieldOptions.firstObject.text === 'ios' ? 2 : 1,
    });
  },
  createFieldOption(fieldType, field) {
    if (fieldType === 'WebLink') {
      this.store.createRecord('fieldOption', {
        field,
        fieldOptionTypeId: 1,
        text: 'weblink',
        displayOrder: 1,
      });
    } else if (fieldType === 'MobileApp') {
      const apps = ['ios', 'android'];
      apps.forEach(app => {
        this.store.createRecord('fieldOption', {
          field,
          fieldOptionTypeId: 1,
          text: app,
          displayOrder: app === 'ios' ? 1 : 2,
        });
      });
    } else if (fieldType === 'SmartSpeakerSkill') {
      this.store.createRecord('fieldOption', {
        field,
        text: 'alexa',
        fieldOptionTypeId: 1,
        displayOrder: 1,
      });
    } else if (fieldType === 'Instagram') {
      this.store.createRecord('fieldOption', {
        field,
        text: 'instagram',
        fieldOptionTypeId: 1,
        displayOrder: 1,
      });
    } else if (fieldType === 'FacebookLink') {
      this.store.createRecord('fieldOption', {
        field,
        text: 'facebooklink',
        fieldOptionTypeId: 1,
        displayOrder: 1,
      });
    } else if (fieldType === 'XLink') {
      this.store.createRecord('fieldOption', {
        field,
        text: 'xlink',
        fieldOptionTypeId: 1,
        displayOrder: 1,
      });
    } else if (fieldType === 'TikTokLink') {
      this.store.createRecord('fieldOption', {
        field,
        text: 'tiktoklink',
        fieldOptionTypeId: 1,
        displayOrder: 1,
      });
    } else if (fieldType === 'YouTubeLink') {
      this.store.createRecord('fieldOption', {
        field,
        text: 'youtubelink',
        fieldOptionTypeId: 1,
        displayOrder: 1,
      });
    }
  },
  //endregion

  //region Computed Properties
  /**
   * Which FormField is currently being edited, if any.
   * @type {FormField?}
   */
  editingFormField: computed('currently-editing-global-optin-id', {
    get() {
      const editingFormField = this._editingFormField;
      const globalOptinId = this['currently-editing-global-optin-id'];
      const match = str => formField => get(formField, str) === globalOptinId;
      if (globalOptinId) {
        return get(this, 'form.formPages')
          .map(formPage => {
            const ff = get(formPage, 'formFields');
            // Fall back to Field IDs if there is no GlobalOptin with a matching ID.
            return ff.find(match('field.globalOptin.id')) || ff.find(match('field.id'));
          })
          .compact()[0];
      }
      return editingFormField;
    },
    set(key, value) {
      set(this, '_editingFormField', value);
      return value;
    },
  }),
  sortedFormPages: sort('form.formPages', 'formPagesSorting'),

  createFormField(formPage, field, attr = {}) {
    const newFormField = this.store.createRecord('form-field');
    const isExtraChancesForm = this.form.formType === 'ExtraChances';
    const maxExtraChances = field.fieldType === 'ExtraChances' ? -1 : 1;
    const defaultValue = getFieldTypeDefaultValue(field.fieldType);
    const noValue = defaultValue === true ? false : null;
    setProperties(newFormField, {
      displayOrder: formPage.formFields.length + 2, // Adding 1 to an already 1-based index.
      isRequired: isExtraChancesForm ? false : field.isRequired,
      labelText: field.labelText || get(field, 'globalOptin.labelText'),
      helpText: field.helpText,
      isYesnoFormat: field.isYesnoFormat,
      isLimitSelection: field.isLimitSelection,
      answerLimit: field.answerLimit,
      field,
      formPage,
      defaultValue: isExtraChancesForm ? noValue : defaultValue,
      isRemovable: true,
      isJustCreated: field.isNew,
      extraChances: isExtraChancesForm ? 1 : null,
      extraChancesMaximum: isExtraChancesForm ? maxExtraChances : null,
      ...attr,
    });
    formPage.formFields.addObject(newFormField);
    return newFormField;
  },

  editFormField(formField) {
    if (!this.disabled) {
      set(this, 'editingFormField', formField);
      if (get(formField, 'field.isOptin')) {
        // Fall back to Field ID if our Optin doesn't use a GlobalOptin
        this.toggleEditingGlobalOptinId(get(formField, 'field.globalOptin.id') || get(formField, 'field.id'));
      }
      // When opening a URL extra chance that has multiple field options,
      // the available field options need to be rebuilt
      if (formField.fieldType === 'MobileApp' && formField.field.fieldOptions.length === 1) {
        this.restoreFieldOptions(formField);
      }
    }
  },

  createField(fieldType, skipOptionCreation = false) {
    const newField = this.store.createRecord('field');
    setProperties(newField, {
      isEditable: true,
      fieldTypeId: this.enums.findWhere('FIELD_TYPE', { name: fieldType }),
      fieldOptionTypeId: this.enums.findWhere('FIELD_TYPE', { name: fieldType }, 'defaultFieldOptionTypeId'),
      fieldValueDestinationTypeId: this.enums.findWhere('FIELD_VALUE_DESTINATION_TYPE', { name: 'Registration' }),
    });
    if (!skipOptionCreation && ['SelectSingle', 'RadioButtons', 'Checkboxes'].includes(fieldType)) {
      newField.fieldOptions.addObject(this.store.createRecord('field-option', { displayOrder: 1 }));
    }
    // URL-based extra chance fields should have fieldOptions created from the start
    if (
      [
        'WebLink',
        'MobileApp',
        'SmartSpeakerSkill',
        'Instagram',
        'FacebookLink',
        'XLink',
        'TikTokLink',
        'YouTubeLink',
      ].includes(fieldType)
    ) {
      this.createFieldOption(fieldType, newField);
    }

    if (fieldType === 'Optin' && this.organization.isAffiliate) {
      const newGlobalOptin = this.store.createRecord('global-optin');
      setProperties(newGlobalOptin, {
        statusTypeId: this.enums.findWhere('STATUS_TYPE', { name: 'Active' }),
        organization: this.organization,
      });
      set(newField, 'globalOptin', newGlobalOptin);
    }

    return newField;
  },
  //endregion

  //region Actions
  actions: {
    editFormField(...args) {
      this.editFormField(...args);
    },

    copyFormField(formField) {
      const { field, formPage } = formField;
      const formFieldsToCopy = omit(formField.toJSON(), ['displayOrder', 'defaultValue', 'fields', 'formPage']);

      const labelText = `${field.labelText} (Copy)`;
      const fieldCopy = this.createField(field.fieldType, true);
      setProperties(fieldCopy, {
        isStarred: field.isStarred,
        name: field.isStarred ? field.name : null,
      });

      field.fieldOptions.forEach(obj => {
        fieldCopy.fieldOptions.addObject(
          this.store.createRecord('field-option', {
            displayOrder: obj.displayOrder,
            displayIndex: obj.displayIndex,
            text: obj.text,
          })
        );
      });

      const formFieldCopy = this.createFormField(formPage, fieldCopy, {
        ...formFieldsToCopy,
        isJustCreated: true,
        labelText,
      });

      this.editFormField(formFieldCopy);
    },

    stopEditingFormField(somethingChanged = true) {
      set(this, 'editingFormField', null);
      this.toggleEditingGlobalOptinId();
      if (somethingChanged) {
        this['something-changed']();
      }
    },
    addFormPage() {
      const { form } = this;
      const pagesSortedByNumber = get(form, 'formPages').sortBy('pageNumber');
      const lastPage = get(pagesSortedByNumber, 'lastObject');
      const nextPageNumber = lastPage ? get(lastPage, 'pageNumber') + 1 : 1;
      const newPage = this.store.createRecord('formPage', {
        displayOrder: nextPageNumber,
        name: `Page ${nextPageNumber}`,
        pageNumber: nextPageNumber,
        form,
      });
      get(form, 'formPages').pushObject(newPage);
    },

    removeFormPage(page) {
      this.form.deletedRecords.addObject(page);
      page.deleteRecord();
      this.form.formPages.removeObject(page);

      // Fix the display order and the page number
      this.form.formPages.sortBy('pageNumber').forEach((formPage, i) => {
        const currentValue = i + 1;

        set(formPage, 'displayOrder', currentValue);
        set(formPage, 'pageNumber', currentValue);
      });
    },

    createField(...args) {
      return this.createField(...args);
    },

    createFormField(...args) {
      return this.createFormField(...args);
    },

    toggleEditModal(isViewingAll) {
      set(this, 'isEditModalHidden', isViewingAll);
    },
  },
  //endregion
});
