/* eslint-disable ember/use-brace-expansion, ember/no-jquery, ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, get, observer, set, setProperties } from '@ember/object';
import { equal, gt, or } from '@ember/object/computed';
import { debounce, next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import enums from 'ember-cli-ss-enums/services/enums';
import NeedsOrganizationPromotionController from 'partner/mixins/needs-organization-promotion-controller';
import ToolbarNavigation from 'partner/mixins/toolbar-navigation';

const PENDING_STATUS_TYPE_ID = enums.findWhere('STATUS_TYPE', { name: 'Submitted' }, 'id');

export default Controller.extend(NeedsOrganizationPromotionController, ToolbarNavigation, {
  //region Dependencies
  enums: service(),
  screen: service(),
  store: service(),
  snackbar: service(),
  //endregion

  //region Ember Hooks
  queryParams: ['statusTypeId', 'matchupId', 'pageIndex', 'searchValue', 'sortBy', 'sortDirection'],

  // Query Params
  matchupId: null,
  pageIndex: 1,
  searchValue: '', // empty values in inputs are empty strings
  statusTypeId: `${PENDING_STATUS_TYPE_ID}`, // query params are always strings
  //endregion

  //region Properties
  allStatusName: 'All',
  approvingEntriesOnPage: false,
  loadingModel: false,
  isAddingImageFromLibrary: false,

  /**
   * The matchupEntry object being edited, if one is being edited. Null otherwise.
   * @type {MatchupEntry}
   */
  matchupEntryBeingEdited: null,
  saving: null,
  isCategorySideNavExpanded: true,
  sortBy: 'DateCreated',
  sortDirection: 'desc',
  //endregion

  //region Methods
  calculateMatchupEntries() {
    const matchupEntries = get(this, 'model.matchupEntries');
    const isStatusTypeIdMatch = x => `${get(x, 'statusTypeId')}` === this.statusTypeId;

    if (get(this, 'screen.width') <= 940) {
      return [matchupEntries.filter(isStatusTypeIdMatch)];
    }

    const isEven = (_item, index) => index % 2 === 0;

    return [
      matchupEntries.filter(isEven).filter(isStatusTypeIdMatch),
      matchupEntries.reject(isEven).filter(isStatusTypeIdMatch),
    ].reject(column => isEmpty(column));
  },
  //endregion

  //region Computed Properties
  pendingId: computed(function () {
    return `${this.enums.findWhere('STATUS_TYPE', { name: 'Submitted' }, 'id')}`;
  }),
  approvedId: computed(function () {
    return `${this.enums.findWhere('STATUS_TYPE', { name: 'Active' }, 'id')}`;
  }),
  rejectedId: computed(function () {
    return `${this.enums.findWhere('STATUS_TYPE', { name: 'Rejected' }, 'id')}`;
  }),
  currentPageName: computed('statusTypeId', 'pendingId', 'approvedId', function () {
    const { statusTypeId } = this;
    if (statusTypeId === `${this.pendingId}`) {
      return 'Pending';
    } else if (statusTypeId === `${this.approvedId}`) {
      return 'Approved';
    }
    return 'Rejected';
  }),
  oneMatchup: equal('model.matchups.length', 1),
  hasMultipleMatchups: gt('model.matchups.length', 1),
  oneColumn: equal('matchupEntries.length', 1),
  statusName: computed('statusTypeId', function () {
    const { statusTypeId } = this;

    if (/\d+/.test(statusTypeId)) {
      return this.enums.findWhere('STATUS_TYPE', { id: +statusTypeId }, 'name');
    }

    return this.allStatusName;
  }),

  isLoading: or('loadingModel', 'approvingEntriesOnPage'),

  loadingMessage: computed('approvingEntriesOnPage', 'loadingModel', function () {
    return this.approvingEntriesOnPage ? 'Approving Entries' : 'Loading Entries';
  }),

  moreEntriesOnNextPage: computed('model.paging.{pageSize,totalRecords}', function () {
    return get(this, 'model.paging.pageSize') < get(this, 'model.paging.totalRecords');
  }),
  matchupEntries: computed(
    'screen.width',
    'model.matchupEntries',
    'model.matchupEntries.[]',
    'model.matchupEntries.@each.statusTypeId',
    'pendingId',
    'statusTypeId',
    function () {
      return this.calculateMatchupEntries();
    }
  ),
  /**
   * For Video promotions we do not want the matchup entries to be re-arranged as the screen width changes. As such
   * this computed property is identical to MatchupEntries but without screen.width as a dependent key.
   */
  staticMatchupEntries: computed(
    'model.matchupEntries',
    'model.matchupEntries.[]',
    'model.matchupEntries.@each.statusTypeId',
    'pendingId',
    'statusTypeId',
    function () {
      return this.calculateMatchupEntries();
    }
  ),
  matchupEntriesCount: computed('matchupEntries', function () {
    let length = 0;
    this.matchupEntries.forEach(x => {
      length += x.length;
    });
    return length;
  }),
  showApprovalAction: computed('statusTypeId', 'pendingId', 'matchupEntriesCount', function () {
    return this.statusTypeId === `${this.pendingId}` && this.matchupEntriesCount > 1;
  }),
  isVideo: or(
    'organizationPromotion.promotion.isUGCSweepstakesVideo',
    'organizationPromotion.promotion.isVideoVotingStandard'
  ),

  sortByNewestFirst: equal('sortDirection', 'desc'),
  //endregion

  //region Observers
  selectedMatchupChanged: observer('model.selectedMatchup.id', function () {
    if (get(this, 'model.selectedMatchup.id')) {
      set(this, 'matchupId', get(this, 'model.selectedMatchup.id'));
    }
  }),
  setSelectedMatchup: observer('matchupId', 'model.matchups.[]', function () {
    if (this.matchupId && !isEmpty(get(this, 'model.matchups'))) {
      set(this, 'model.selectedMatchup', get(this, 'model.matchups').findBy('id', this.matchupId));
    }
  }),
  checkForPageOutOfRange: observer('model.paging', function () {
    const paging = get(this, 'model.paging');
    if (paging) {
      const pageNumber = window.parseInt(get(paging, 'pageIndex'), 10);
      const totalNumber = window.parseInt(get(paging, 'totalRecords'), 10);
      const pageSizeNumber = window.parseInt(get(paging, 'pageSize'), 10);
      const maxPageNumber = Math.ceil(totalNumber / pageSizeNumber);
      if (maxPageNumber !== 0 && pageNumber > maxPageNumber) {
        next(this, function () {
          //this will trigger the model to refresh which will retrieve the records for the last "real" page
          //doing this in a run.next because for some reason the queryParam change was not causing the model to refresh
          set(this, 'pageIndex', maxPageNumber);
        });
      }
    }
  }),
  resetPageIndex: observer('matchupId', 'statusTypeId', function () {
    set(this, 'pageIndex', 1);
  }),
  //endregion

  actions: {
    changePage(page) {
      set(this, 'pageIndex', page);
    },

    editMatchupEntry(matchupEntry) {
      set(this, 'matchupEntryBeingEdited', matchupEntry);
    },

    toggleCategorySideNav() {
      this.toggleProperty('isCategorySideNavExpanded');
    },

    async moveEntrant(matchupId) {
      const matchupEntry = await this.store.findRecord('matchup-entry', this.selectedMoveItem.entry.matchupEntry.id);
      const targetMatchup = this.store.peekRecord('matchup', matchupId);

      set(
        this,
        'model.matchupEntries',
        get(this, 'model.matchupEntries').filter(entry => entry.id !== this.selectedMoveItem.id)
      );
      set(matchupEntry, 'matchup', targetMatchup);
      set(this, 'selectedMoveItem', null);

      try {
        await matchupEntry.save();
        this.send('decreaseCount', matchupEntry.statusTypeId);
        this.snackbar.show('The entrant has been moved to the category', matchupEntry.matchup.name).then(() => {
          set(this, 'matchupId', matchupId);
        });
      } catch (e) {
        this.snackbar.show('There was a problem moving the entrant category', 'Dismiss', -1, 'error');
      }
    },

    setSearchValue(value) {
      debounce(this, 'set', 'searchValue', value, 250);
    },

    sortEntrants() {
      setProperties(this, {
        pageIndex: 1,
        sortBy: 'DateCreated',
        sortDirection: this.sortByNewestFirst ? 'asc' : 'desc',
      });
    },
  },
});
