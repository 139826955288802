/* eslint-disable ember/no-get, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { computed, set, get } from '@ember/object';
import { alias, filterBy, readOnly, reads } from '@ember/object/computed';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import areAttrsChanged from 'partner/utils/are-attrs-changed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Controller.extend({
  features: service(),
  router: service(),
  store: service(),

  taggableEntity: null,
  //region Ember Hooks
  loadTaggableEntitiesTask: task(function* (taggableEntityId, messageCampaignId) {
    const taggableEntity = (yield this.store.query('taggable-entity', {
      tier: 1,
      taggableEntityId,
      messageCampaignId,
    }))?.firstObject;
    set(this, 'taggableEntity', taggableEntity);
  }).restartable(),

  organizationController: controller('organizations.organization'),
  queryParams: ['is-editing-basics'],
  //endregion

  //region Properties
  'is-editing-basics': false,
  needsRedirect: false,
  //endregion

  //region Computed Properties
  mc: computed('model.{messageCampaign,messageCampaign.usesSingleMessageCampaign,singleMessageCampaign}', function () {
    return this.model.messageCampaign.usesSingleMessageCampaign
      ? this.model.singleMessageCampaign
      : this.model.messageCampaign;
  }),
  messageCampaign: readOnly('model.messageCampaign'),
  errors: reads('mc.errors'),
  name: computed('mc.name', {
    get() {
      return this.mc.name;
    },
    set(key, value) {
      set(this.model.messageCampaign, 'name', value);
      // TODO: [SMC Refactor] Remove when SMC is removed
      if (this.model.singleMessageCampaign) {
        set(this.model.singleMessageCampaign, 'name', value);
      }
      return value;
    },
  }),
  externalId: computed('mc.externalId', {
    get() {
      return this.mc.externalId;
    },
    set(key, value) {
      set(this.mc, 'externalId', value);
      set(this.model.messageCampaign, 'externalId', value);
      return value;
    },
  }),
  isNameActuallyDirty: areAttrsChanged({
    'model.singleMessageCampaign': ['name'],
    'model.messageCampaign': ['name'],
  }),
  isExternalIdDirty: areAttrsChanged({
    'model.singleMessageCampaign': ['externalId'],
    'model.messageCampaign': ['externalId'],
  }),
  isNameDirty: computed('isNameActuallyDirty', 'nameStatus', function () {
    // Fake like it's not dirty if it's dirty but incomplete.
    return this.isNameActuallyDirty ? this.nameStatus !== 'incomplete' : false;
  }),
  nameStatus: computed('mc.name', function () {
    return isBlank(this.mc.name) ? 'incomplete' : 'bestPractice';
  }),
  externalIdStatus: computed('mc.externalId', function () {
    return isEmpty(this.mc.externalId) ? 'incomplete' : 'bestPractice';
  }),
  hasDataProduct: readOnly('organizationController.hasDataProduct'),
  categoryStatus: computed('messageCampaignCategory', function () {
    return isEmpty(this.messageCampaignCategory) ? 'incomplete' : 'bestPractice';
  }),
  messageCampaignCategory: computed('mc.messageCampaignCategory', {
    get() {
      return this.mc.messageCampaignCategory;
    },
    set(key, value) {
      set(this.mc, 'messageCampaignCategory', value);
      return value;
    },
  }),
  messageCampaignCategories: filterBy('model.messageCampaignCategories', 'hasDeletedStatusType', false),

  messageCampaignCategoriesForEditBasics: computed('messageCampaignCategory.{[],hasDeletedStatusType}', function () {
    const category = this.messageCampaignCategory;
    const categories = this.messageCampaignCategories.slice();
    if (category && category.hasDeletedStatusType && isPresent(categories)) {
      //This campaign is associated to a category that has been deleted. Add it to the array of active categories
      //so it will show up in the select
      categories.addObject(category);
      return categories.sortBy('name');
    }
    return categories;
  }),

  creatorName: readOnly('model.messageCampaign.messageCampaignCreatorFullName'),
  creatorEmail: readOnly('model.messageCampaign.createdByOrganizationUser.emailAddress'),
  dateCreated: readOnly('model.messageCampaign.dateCreated'),
  displayedCreatorEmail: computed('creatorName', 'creatorEmail', function () {
    const email = this.creatorEmail;
    return email ? (this.creatorName ? `(${email})` : email) : '';
  }),

  isSaveEnabled: computed(
    'nameStatus',
    'categoryStatus',
    'chosenInterestTags.length',
    'features.{hasRequiredPromotionMessagingTagging,hasMessageCategories}',
    function () {
      const hasName = this.nameStatus === 'bestPractice';
      const hasCategory = this.categoryStatus === 'bestPractice';

      if (get(this, 'features.hasRequiredPromotionMessagingTagging')) {
        const hasNameAndTags = hasName && isPresent(this.chosenInterestTags);
        return get(this, 'features.hasMessageCategories') ? hasNameAndTags && hasCategory : hasNameAndTags;
      }

      return get(this, 'features.hasMessageCategories') ? hasName && hasCategory : hasName;
    }
  ),

  messageCampaignType: reads('mc.messageCampaignType'),
  messageCampaignTitle: computed(
    'is-editing-basics',
    'model.messageCampaign.{usesSingleMessageCampaign,name}',
    'model.singleMessageCampaign.name',
    function () {
      const messageCampaignName = this.model.messageCampaign.name;

      if (this.model.messageCampaign.usesSingleMessageCampaign) {
        return this.model.singleMessageCampaign.name || (this['is-editing-basics'] ? '' : messageCampaignName);
      }

      return messageCampaignName;
    }
  ),
  taggingStatus: computed('taggableEntity.entityInterestTags.[]', function () {
    return isEmpty(this.taggableEntity?.entityInterestTags) ? 'incomplete' : 'bestPractice';
  }),

  chosenInterestTags: alias('taggableEntity.interestTags'),
  //endregion

  //region Actions
  actions: {
    saveBasics() {
      this.mc
        .save()
        .then(
          () => {
            set(this, 'is-editing-basics', false);
            this.send('saveHistory', this.model.messageCampaign);
          },
          reason => {
            if (isEmpty(this.errors.errorsFor('name')) && isEmpty(this.errors.errorsFor('messageCampaignCategory'))) {
              set(this, 'is-editing-basics', false);
            }
            throw reason;
          }
        )
        .finally(() => {
          set(this, 'model.messageCampaign.isJustCreated', false);
          if (this.model.messageCampaign.usesSingleMessageCampaign) {
            this.model.messageCampaign.save();
          }
          if (this.needsRedirect) {
            this.router.transitionTo(
              'organizations.organization.message-campaigns.message-campaign.setup.message-versions'
            );
            set(this, 'needsRedirect', false);
          }
        });
    },
    messageCampaignCategorySelected(category) {
      set(this.mc, 'messageCampaignCategory', category);
      if (this.model.messageCampaign.usesSingleMessageCampaign) {
        set(this.model.messageCampaign, 'messageCampaignCategory', category);
      }
    },
  },
  //endregion
});
