/* eslint-disable ember/no-mixins */
import { get, set, action } from '@ember/object';
import Route from '@ember/routing/route';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

/**
 * Organization Settings Index Route
 * /o/:organization_id/settings
 */
export default class extends Route.extend(AppContextRoute) {
  @service store;

  async model() {
    const { organization } = this.modelFor('organizations.organization');
    const organizationId = organization.id;

    const result = await RSVP.hash({
      organization,
      organizationAttributes: this.store.query('organizationAttribute', { organizationId }),
      optoutWrapper: this.store.query('optoutWrapper', { organizationId }),
      locations: this.store.query('location', { isPrimary: true, organizationId }),
      domains: this.store.query('domain', { includeInherited: true, organizationId }),
      isSecondStreetAdmin: get(this.controllerFor('application'), 'isAdminUser'),
      promotionFeedTitleSetting: this.store.query('setting', { key: 'promotion_feed_title' }),
      organizationPaymentProcessors: this.store.query('organization-payment-processor', { organizationId }),
    });

    result.primaryLocation =
      result.locations.firstObject || this.store.createRecord('location', { organization, isPrimary: true });
    result.promotionFeedTitleSetting = result.promotionFeedTitleSetting.firstObject;

    return result;
  }

  setupController(controller) {
    super.setupController(...arguments);

    if (controller.domains) {
      scheduleOnce('afterRender', this.scrollToDomains);
    }
  }

  resetController(controller) {
    set(controller, 'domains', null);
  }

  scrollToDomains() {
    window.scrollTo(0, document.querySelector('.ss-domain-tile-header').offsetTop - 20);
  }

  @action
  getUpdatedDomains() {
    this.refresh();
  }
}
