/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { readOnly } from '@ember/object/computed';

/**
 * External Apps New Controller
 * @type {Ember.Controller}
 */
export default Controller.extend({
  //region Computed Properties
  isAnythingSaving: readOnly('model.isSaving'),
  isAnythingDirty: readOnly('model.hasDirtyAttributes'),
  //endregion
});
