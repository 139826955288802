export default () => `
<div class="questions-page poll-question poll-question--big font-['Open_Sans']">  
  <div class="border border-solid border-gray-100 rounded p-4 flex gap-4 flex-col">
    <div class="text-primary text-base font-bold template-color-primary-color">Question 1 of 2</div>
    
      <div class="text-3xl text-black font-semibold">
        Example Question One
      </div>
    
    <div class="poll-question__grid-answers">
      <div role="button" class="question-option question-option--big !bg-gray-50 border border-solid border-gray-100">
        <div class="question-option__text question-option__text--big template-color-primary-color template-color-primary-color text-xl">Answer One</div>
      </div>
      <div class="question-option question-option--big !bg-gray-50 border border-solid border-gray-100 hover:border-primary">  
        <div class="question-option__text question-option__text--big template-color-primary-color template-color-primary-color text-xl">Answer Two</div>
      </div>
    </div>
  </div> 

    <div class="border border-solid border-gray-100 rounded p-4 flex gap-4 flex-col m-4">
    <div class="text-primary text-base font-bold template-color-primary-color">Question 2 of 2</div>
    
      <div class="text-3xl text-black font-semibold">
        Example Question Two
      </div>
    
    <div class="poll-question__grid-answers">
      <div role="button" class="question-option question-option--big !bg-gray-50 border border-solid border-gray-100">
        <div class="question-option__text question-option__text--big template-color-primary-color template-color-primary-color text-xl">Answer One</div>
      </div>
      <div class="question-option question-option--big !bg-gray-50 border border-solid border-gray-100 hover:border-primary">  
        <div class="question-option__text question-option__text--big template-color-primary-color template-color-primary-color text-xl">Answer Two</div>
      </div>
    </div>
  </div> 

  
 
  
  <div class="questions-page__share-container questions-page__share-container--big">
    <ul class="ssShareListCompact ssShareList">      
      <li class="ssShareListItem ssShareListItemFacebook">
        <a class="ssShareLink ssShareLinkFacebook" data-token="facebook" href="#">
          <i class="ssIcon-facebook" aria-label="Share on Facebook"></i>
        </a>
      </li>
      <li class="ssShareListItem ssShareListItemTwitter">
        <a class="ssShareLink ssShareLinkTwitter" data-token="twitter" href="#" >
          <i class="ssIcon-twitter" aria-label="Share on Twitter"></i>
        </a>
      </li>
      <li class="ssShareListItem ssShareListItemEmail">
        <a class="ssShareLink ssShareLinkEmail" data-token="email" href="#" >
          <i class="ssIcon-envelope" aria-label="Share on Email"></i>
        </a>
      </li>
      <li class="ssShareListItem ssShareListItemPinterest">
        <a class="ssShareLink ssShareLinkPinterest" data-token="pinterest" href="#" >
          <i class="ssIcon-pinterest" aria-label="Share on Pinterest"></i>
        </a>
      </li>
    </ul>
  </div>
</div>
`;
