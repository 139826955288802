import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';
import type CurrentService from 'partner/services/current';
import type MatchupModel from 'partner/models/matchup';
import type MatchupGroupModel from 'partner/models/matchup-group';
import type { BallotStyle } from 'partner/types';
import type { Model } from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/ballot';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import type Store from '@ember-data/store';
import type ScreenService from 'ember-screen/services/screen';
import nextDisplayOrder from 'partner/utils/next-display-order';

export default class SetupBallotController extends Controller {
  @service declare current: CurrentService;
  @service declare enums: EnumsService;
  @service declare screen: ScreenService;
  @service declare store: Store;

  declare model: Model;

  queryParams = ['matchupGroupId', 'matchupId'];

  @tracked matchupGroupId?: string;
  @tracked matchupId?: string;
  @tracked selectedBallotStyle: BallotStyle | null = null;

  get matchupGroups() {
    return this.model.matchupGroups;
  }

  get matchups() {
    return this.model.matchups;
  }

  get isSingleColumnLayout() {
    return this.screen.width < 1024;
  }

  get hasGroups() {
    return this.selectedBallotStyle == 'groups' || this.matchupGroups.length > 0;
  }

  get hasCategories() {
    return (
      this.hasGroups ||
      this.selectedBallotStyle == 'categories' ||
      this.matchups.filter(matchup => !matchup.isBallotDefaultMatchup).length > 0
    );
  }

  get hasEntrants() {
    return (
      this.hasGroups ||
      this.hasCategories ||
      this.selectedBallotStyle == 'simple-seeded' ||
      (this.selectedBallotStyle == 'simple' && !this.current.promotion.isNominationAndVotingBallot) ||
      (this.matchups.length === 1 && this.model.firstMatchupEntries.length > 0)
    );
  }

  get simpleBallotStyleSelected() {
    return this.matchups.length === 1 && this.matchups.firstObject?.name == 'SimpleBallot';
  }

  get currentMatchupGroup() {
    return this.matchupGroupId ? this.matchupGroups.findBy('id', this.matchupGroupId) : undefined;
  }

  get currentMatchups(): Model['matchups'] {
    return this.matchupGroupId
      ? this.matchups.filterBy('matchupGroupId', this.matchupGroupId)
      : !this.hasGroups
      ? this.matchups.filter(matchup => !matchup.isBallotDefaultMatchup)
      : [];
  }

  get currentMatchup() {
    return this.matchupId ? this.matchups.findBy('id', this.matchupId) : undefined;
  }

  @action
  selectBallotStyle(style: BallotStyle) {
    this.selectedBallotStyle = style;

    const defaultMatchup = this.matchups.firstObject;

    if (!defaultMatchup) return;

    defaultMatchup.name = ['simple', 'simple-seeded'].includes(style) ? 'SimpleBallot' : 'Category 1';

    if (defaultMatchup.get('hasDirtyAttributes')) {
      defaultMatchup.save();
      this.toggleChecklistStep();
    }
  }

  @action
  handleRemoveMatchupGroup(matchupGroup: MatchupGroupModel) {
    if (this.matchupGroupId === matchupGroup.id) {
      this.matchupGroupId = undefined;
      this.matchupId = undefined;
    }

    const groupMatchups = matchupGroup.matchups.toArray();

    this.model = {
      ...this.model,
      matchupGroups: this.matchupGroups.filter(({ id }) => id != matchupGroup.id),
      matchups: this.matchups.filter(matchup => !groupMatchups.includes(matchup)),
    };

    groupMatchups.forEach(matchup => matchup.unloadRecord());
    void this.maybeCreateDefaultMatchup(groupMatchups[0]);
  }

  @action
  createMatchup(
    { matchup, matchupGroup }: { matchup?: MatchupModel; matchupGroup?: MatchupGroupModel } = {},
    overwrites: Partial<Pick<MatchupModel, 'name' | 'displayOrder' | 'matchupGroup' | 'matchupGroupId'>> = {}
  ) {
    matchup = matchup || this.matchups.firstObject;
    matchupGroup = matchupGroup || this.currentMatchupGroup;

    const [firstDefaultMatchup] = this.matchups.filter(matchup => matchup.isBallotDefaultMatchup);

    // We always have a default matchup, so we can just update it
    if (firstDefaultMatchup) {
      firstDefaultMatchup.setProperties({
        matchupClassificationTypeId: this.enums.data.MATCHUP_CLASSIFICATION_TYPE[0]?.id,
        displayOrder: 1,
        name: '',
        matchupGroup,
        matchupGroupId: matchupGroup?.id,
        wasDefaultMatchup: true,
      });

      return firstDefaultMatchup;
    }

    const newMatchup = this.store.createRecord('matchup', {
      displayOrder: nextDisplayOrder(this.currentMatchups),
      iteration: matchup?.iteration || 1,
      matchupType: matchup?.matchupType || 1,
      entryIntervalTypeId: matchup?.entryIntervalTypeId || 1,
      entriesAllowedNumber: matchup?.entriesAllowedNumber || 1,
      matchupGroup,
      matchupGroupId: matchupGroup?.id,
      // defaults to first category's type (in groups, the first category of that group)
      matchupClassificationTypeId:
        this.currentMatchups.lastObject?.matchupClassificationTypeId || matchup?.matchupClassificationTypeId || 1,
      startDate: matchup?.startDate || this.fallbackDates.startDate,
      endDate: matchup?.endDate || this.fallbackDates.endDate,
      voteIntervalTypeId: matchup?.voteIntervalTypeId || 1,
      votesAllowedNumber: matchup?.votesAllowedNumber || 1,
      selectionEndDate: matchup?.selectionEndDate || this.fallbackDates.selectionEndDate,
      selectionStartDate: matchup?.selectionStartDate || this.fallbackDates.selectionStartDate,
      matchupEntriesCount: 0,
      ...overwrites,
    });

    this.model = {
      ...this.model,
      matchups: [...this.matchups, newMatchup],
    };

    this.selectedBallotStyle = null;

    return newMatchup;
  }

  @action
  handleRemoveMatchup(matchup: MatchupModel) {
    this.model = {
      ...this.model,
      matchups: this.matchups.filter(matchupItem => matchupItem != matchup),
    };

    void this.maybeCreateDefaultMatchup(matchup);

    if (this.matchupId === matchup.id) this.matchupId = undefined;
  }

  @action
  importSuccessful() {
    this.send('reload');
  }

  @action
  toggleChecklistStep() {
    this.send('verifyAndToggleBallotChecklistStep', {
      matchups: this.matchups,
      matchupGroups: this.model.matchupGroups,
      route: 'organizations.organization.organization-promotions.organization-promotion.setup.ballot',
    });
  }

  private async maybeCreateDefaultMatchup(matchup?: MatchupModel) {
    if (this.matchups.length > 0 || !matchup) return;

    const defaultMatchup = this.createMatchup(
      { matchup },
      { name: 'Category 1', displayOrder: 0, matchupGroup: undefined, matchupGroupId: undefined }
    );

    await defaultMatchup?.save();
  }

  private get fallbackDates() {
    const startDate = new Date();
    const endDate = moment(startDate).add(2, 'weeks').toDate();
    let selectionStartDate = moment(endDate).add(1, 'minute').toDate();
    let selectionEndDate = moment(selectionStartDate).add(2, 'weeks').toDate();

    // Voting Ballots only have one phase so both start dates and both end dates are the same
    if (this.current.promotion?.isVotingBallot) {
      selectionStartDate = startDate;
      selectionEndDate = endDate;
    }

    return { startDate, endDate, selectionStartDate, selectionEndDate };
  }
}

declare module '@ember/controller' {
  interface Registry {
    'organizations.organization.organization-promotions.organization-promotion.setup.ballot': SetupBallotController;
  }
}
