/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, set } from '@ember/object';
import { filterBy, or } from '@ember/object/computed';
import { inject } from '@ember/service';
import ENUMS from 'ember-cli-ss-enums/utils/enum-data';
import { PreferenceCenterEmbed } from 'partner/utils/embed-scripts';
import { wordpressCodeForPreferenceCenter } from 'partner/utils/wordpress-shortcodes';

export default Controller.extend({
  //region Dependencies
  enums: inject(),
  snackbar: inject(),
  //endregion

  //region Properties
  didSomethingChange: false,
  isAnythingSaving: false,
  //endregion

  //region Computed Properties
  isAnythingDirty: or('model.form.hasDirtyAttributes', 'didSomethingChange'),

  fieldTypeBlacklist: computed(() => ENUMS.FIELD_TYPE.mapBy('name').reject(field => field === 'Optin')),

  starredFields: filterBy('model.fields', 'isStarred'),

  unstarredFields: filterBy('model.fields', 'isStarred', false),

  embedCode: computed('model.organization.id', function () {
    return new PreferenceCenterEmbed(this.model.organization.id).embedCode;
  }),
  shortCode: computed('model.organization.id', function () {
    return wordpressCodeForPreferenceCenter(this.model.organization.id);
  }),
  //endregion

  //region Actions
  actions: {
    somethingChanged() {
      set(this, 'didSomethingChange', true);
    },
    async save() {
      set(this, 'isAnythingSaving', true);
      try {
        await this.model.form.save();
        set(this, 'didSomethingChange', false);
      } finally {
        set(this, 'isAnythingSaving', false);
      }
    },
    saveToClipboard() {
      //`doc.execCommand('copy')` Copies the current selected text on the screen, so we have to pick the text to copy
      const inputArea = document.querySelector('.ssModal textarea');
      inputArea.select();
      document.execCommand('copy');
      this.snackbar.show('Copied to clipboard!');
    },
  },
  //endregion
});
