/* eslint-disable ember/require-tagless-components, ember/no-classic-components, ember/no-computed-properties-in-native-classes */
import Component from '@ember/component';
import { set, action, computed } from '@ember/object';
import { readOnly, or } from '@ember/object/computed';
import { run } from '@ember/runloop';
import { ENTER } from 'partner/utils/keycodes';

export default class Toolbar extends Component {
  classNames = ['merge-nominations-toolbar'];

  //region Attributes
  'selected-count' = 0;

  'suggested-count' = 0;
  'are-any-of-suggestions-all-pending' = false;
  'are-any-pending' = false;
  'are-selected-all-pending' = false;
  'is-saving-merge' = false;
  'is-saving-approval' = false;
  'split-button-choice' = null;
  'is-writeins' = false;
  'merge-selected'() {}
  'merge-and-approve-selected'() {}
  'merge-suggested'() {}
  'merge-and-approve-suggested'() {}
  'approve-selected'() {}
  'approve-all'() {}
  'preferred-name' = '';

  //endregion

  //region Properties
  _name = '';

  //endregion

  //region Computed Properties
  @readOnly('is-saving-merge')
  isSavingMerge;

  @readOnly('is-saving-approval')
  isSavingApproval;

  @computed('selected-count', 'suggested-count', 'are-any-pending')
  get isApproveAll() {
    return this['selected-count'] < 1 && this['suggested-count'] < 1 && this['are-any-pending'];
  }

  @computed('selected-count', 'suggested-count', 'are-any-pending')
  get isSelect() {
    return this['selected-count'] < 1 && this['suggested-count'] < 1 && !this['are-any-pending'];
  }

  @computed('selected-count', 'are-selected-all-pending')
  get isApproveSelected() {
    return this['selected-count'] === 1 && this['are-selected-all-pending'];
  }

  @computed('selected-count', 'are-selected-all-pending')
  get isSelectAnother() {
    return this['selected-count'] === 1 && !this['are-selected-all-pending'];
  }

  @computed('selected-count', 'suggested-count', 'are-any-of-suggestions-all-pending')
  get isMergeAllSuggested() {
    return this['selected-count'] < 1 && this['suggested-count'] >= 1 && !this['are-any-of-suggestions-all-pending'];
  }

  @computed(
    'selected-count',
    'suggested-count',
    'are-any-of-suggestions-all-pending',
    'split-button-choice',
    'is-writeins'
  )
  get isMergeAllSuggestedSplit() {
    const choice = this['split-button-choice'];
    return (
      this['selected-count'] < 1 &&
      this['suggested-count'] >= 1 &&
      this['are-any-of-suggestions-all-pending'] &&
      (choice ? choice === 'merge' : !this['is-writeins'])
    );
  }

  @computed(
    'selected-count',
    'suggested-count',
    'are-any-of-suggestions-all-pending',
    'split-button-choice',
    'is-writeins'
  )
  get isMergeAndApproveAllSuggestedSplit() {
    const choice = this['split-button-choice'];
    return (
      this['selected-count'] < 1 &&
      this['suggested-count'] >= 1 &&
      this['are-any-of-suggestions-all-pending'] &&
      (choice ? choice === 'mergeAndApprove' || choice === 'approve' : !!this['is-writeins'])
    );
  }

  @(or('isMergeAllSuggestedSplit', 'isMergeAndApproveAllSuggestedSplit').readOnly())
  isSuggestedSplitButton;

  @computed('isMergeAllSuggestedSplit', 'isMergeAndApproveAllSuggestedSplit')
  get suggestedSplitButtonSelection() {
    return this.isMergeAllSuggestedSplit ? 'merge' : this.isMergeAndApproveAllSuggestedSplit ? 'mergeAndApprove' : null;
  }

  @computed('selected-count', 'are-selected-all-pending')
  get isMergeSelected() {
    return this['selected-count'] > 1 && !this['are-selected-all-pending'];
  }

  @computed('selected-count', 'are-selected-all-pending', 'split-button-choice', 'is-writeins')
  get isMergeSelectedSplit() {
    const choice = this['split-button-choice'];
    return (
      this['selected-count'] > 1 &&
      this['are-selected-all-pending'] &&
      (choice ? choice === 'merge' : !this['is-writeins'])
    );
  }

  @computed('selected-count', 'are-selected-all-pending', 'split-button-choice', 'is-writeins')
  get isMergeAndApproveSelectedSplit() {
    const choice = this['split-button-choice'];
    return (
      this['selected-count'] > 1 &&
      this['are-selected-all-pending'] &&
      (choice ? choice === 'mergeAndApprove' : !!this['is-writeins'])
    );
  }

  @computed('selected-count', 'split-button-choice', 'are-selected-all-pending')
  get isApproveSelectedSplit() {
    return this['selected-count'] > 1 && this['are-selected-all-pending'] && this['split-button-choice'] === 'approve';
  }

  @(or('isMergeSelectedSplit', 'isMergeAndApproveSelectedSplit', 'isApproveSelectedSplit').readOnly())
  isSelectedSplitButton;

  @computed('isMergeSelectedSplit', 'isMergeAndApproveSelectedSplit', 'isApproveSelectedSplit')
  get selectedSplitButtonSelection() {
    return this.isMergeSelectedSplit
      ? 'merge'
      : this.isMergeAndApproveSelectedSplit
      ? 'mergeAndApprove'
      : this.isApproveSelectedSplit
      ? 'approve'
      : null;
  }

  @(or('isSavingMerge', 'isSavingApproval', 'isSelect', 'isSelectAnother', 'mergeDisabled').readOnly())
  isDisabled;

  @computed('isNameEditable')
  get mergeDisabled() {
    return this.isNameEditable && !this.name.trim();
  }

  @computed('preferred-name', '_name')
  get name() {
    return this._name || this['preferred-name'];
  }

  set name(value) {
    set(this, '_name', value);
    return value;
  }

  @computed('isMergeSelected', 'isSelectedSplitButton', 'split-button-choice')
  get isNameEditable() {
    return this.isMergeSelected || (this.isSelectedSplitButton && this['split-button-choice'] !== 'approve');
  }

  // I'm sorry about the tedium of reading the following computed property...
  @computed(
    'isMergeSelected',
    'isMergeSelectedSplit',
    'isMergeAndApproveSelectedSplit',
    'isMergeAllSuggested',
    'isMergeAllSuggestedSplit',
    'isMergeAndApproveAllSuggestedSplit',
    'isApproveSelected',
    'isApproveSelectedSplit',
    'isApproveAll',
    'merge-selected',
    'merge-and-approve-selected',
    'merge-suggested',
    'merge-and-approve-suggested',
    'approve-selected',
    'approve-all'
  )
  get chosenAction() {
    //store in variable for the sake of readability
    const { isMergeSelected } = this;
    const { isMergeSelectedSplit } = this;
    const { isMergeAndApproveSelectedSplit } = this;
    const { isMergeAllSuggested } = this;
    const { isMergeAllSuggestedSplit } = this;
    const { isMergeAndApproveAllSuggestedSplit } = this;
    const { isApproveSelected } = this;
    const { isApproveSelectedSplit } = this;
    const { isApproveAll } = this;
    const mergeSelected = this['merge-selected'];
    const mergeAndApproveSelected = this['merge-and-approve-selected'];
    const mergeSuggested = this['merge-suggested'];
    const mergeAndApproveSuggested = this['merge-and-approve-suggested'];
    const approveSelected = this['approve-selected'];
    const approveAll = this['approve-all'];

    return isMergeSelected || isMergeSelectedSplit
      ? mergeSelected
      : isMergeAndApproveSelectedSplit
      ? mergeAndApproveSelected
      : isMergeAllSuggested || isMergeAllSuggestedSplit
      ? mergeSuggested
      : isMergeAndApproveAllSuggestedSplit
      ? mergeAndApproveSuggested
      : isApproveSelected || isApproveSelectedSplit //prettier:wrap
      ? approveSelected
      : isApproveAll //prettier:wrap
      ? approveAll
      : null;
  }

  @action
  nameKeydown(event) {
    if (event.keyCode !== ENTER) {
      return;
    }

    event.preventDefault();
    event.target.blur();
    return false;
  }

  @action
  clearName() {
    run(() => {
      if (this && !this.isDestroyed) {
        set(this, '_name', '');
      }
    });
  }
}
