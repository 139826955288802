export default ({ message }) => `
<div class="ssSurveyPage ssTile" data-token="thankYouMessage" style="font-family: 'Open Sans'">
  <div class="extra-chances extra-chances--primary template-color-primary-background-color">
    <div class="extra-chances__column extra-chances__column--primary-row extra-chances__column--full-width">
      <div class="extra-chances__title extra-chances__title--primary extra--chances__entry-received">Your response has been received!</div>
    </div>
  </div>
  <div class="extra-chances extra-chances--secondary">
    <div class="extra-chances__column extra-chances__column--secondary-row extra-chances__column--full-width extra-chances__custom-thanks-message">
      ${message}
    </div>
  </div>
	<div class="ssSharing mx-4">
		<ul class="ssShareListCompact ssShareList">
			<li class="ssShareListItem ssShareListItemFacebook">
				<a class="ssShareLink ssShareLinkFacebook" data-token="facebook" href="#" >
					<i class="ssIcon-facebook"></i>
				</a>
			</li>
			<li class="ssShareListItem ssShareListItemTwitter">
				<a class="ssShareLink ssShareLinkTwitter" data-token="twitter" href="#">
					<i class="ssIcon-twitter"></i>
				</a>
			</li>
			<li class="ssShareListItem ssShareListItemEmail">
				<a class="ssShareLink ssShareLinkEmail" data-token="email" href="#" >
					<i class="ssIcon-envelope"></i>
				</a>
			</li>
			<li class="ssShareListItem ssShareListItemPinterest">
				<a class="ssShareLink ssShareLinkPinterest" data-token="pinterest" href="#" >
					<i class="ssIcon-pinterest"></i>
				</a>
			</li>
		</ul>
	</div>
</div>
`;
