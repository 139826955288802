/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/avoid-leaking-state-in-ember-objects */ // FIXME
import Controller, { inject as controller } from '@ember/controller';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';

const TAGGABLE_CATEGORY_TYPE_ID_OFFERS = '4';

export default Controller.extend({
  dataController: controller('organizations.organization.data'),

  //region Properties
  sortProperties: ['id'],
  //endregion

  //region Computed Properties
  arrangedContent: sort('model', 'sortProperties'),
  //TODO once we add Ecommerce, stop filtering and go back to using arranged content in the handlebars
  filteredArrangedContent: computed('arrangedContent.@each.id', function () {
    return this.arrangedContent.rejectBy('id', TAGGABLE_CATEGORY_TYPE_ID_OFFERS);
  }),
  //endregion
});
