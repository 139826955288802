/* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes, ember/no-observers, ember/no-mixins, ember/require-tagless-components, ember/no-component-lifecycle-hooks, ember/no-jquery, ember/no-incorrect-calls-with-inline-anonymous-functions, ember/closure-actions */

import AudienceCommonController from 'partner/mixins/audience-common-controller';

export default class ArchivedController extends AudienceCommonController {
  isDownloadingReport = false;

  selectedReportAudience = null;

  statusTypeId = 2;
}
