/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * Matchups Route
 * No URL - Anything under /o/:organization_id/op/:organization_promotion_id/dashboard/matchups
 * @type {Ember.Route}
 */
export default Route.extend({
  store: service(),

  //region Ember Hooks
  model() {
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');
    const organizationPromotionId = get(
      this.modelFor('organizations.organization.organization-promotions.organization-promotion'),
      'organizationPromotion.id'
    );
    return this.store.query('matchup', {
      organizationId,
      organizationPromotionId,
      excludeSecondaryMatchups: true,
    });
  },
  //endregion
});
