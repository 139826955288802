/* eslint-disable ember/no-observers, ember/no-classic-classes, ember/avoid-leaking-state-in-ember-objects */ // FIXME
import Helper from '@ember/component/helper';
import { observer, set } from '@ember/object';

/**
 * Returns whether errors exist for a given attribute.
 * Sample usage: (has-errors matchup.errors "startDate")
 */
export default Helper.extend({
  /**
   * @private
   */
  _errors: [],
  /**
   * Gotta observe the .[] because changing items won't recompute the helper
   */
  arrayChanged: observer('_errors.[]', function () {
    this.recompute();
  }),
  /**
   * @returns {Boolean}
   */
  compute([errors, attribute]) {
    if (!errors) {
      return false;
    }

    if (errors) {
      set(this, '_errors', errors);
    }
    const { _errors } = this;
    return _errors ? _errors.has(attribute) : false;
  },
});
