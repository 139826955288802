/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';

export default Component.extend({
  //region Properties
  simulationData: null,
  isLoaded: true,
  //endregion

  //region Computed Properties
  sortedData: computed('simulationData', function () {
    if (this.simulationData) {
      return this.simulationData
        .sortBy('probabilityPercent')
        .reverse()
        .map(outcome => ({
          label: get(outcome, 'name'),
          value: get(outcome, 'probabilityPercent'),
        }));
    }
    return;
  }),
  simulationChartHeight: computed('sortedData.length', function () {
    return get(this, 'sortedData.length') * 40 || 320; // 40px per row Looks nice.
  }),
  //endregion

  //region Actions
  actions: {
    closeBalance() {
      if (this.action && typeof this.action === 'function') {
        this.action();
      }
    },
  },
  //endregion
});
