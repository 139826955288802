/**
 * This function takes the url from the
 * partners consumer site. Checks if the current
 * url is on dev or localhost and returns the
 * appropriate url
 *
 * @param {String} - the link to the partner's consumer site
 * @param {String} - the current url of the page
 * @returns {String} - the appropriate consumer site link depending on if the user is on dev or live
 */
export default function (partnersLink, pageUrl, isOldPromotion) {
  const [, , currentDomain] = pageUrl.split('/');
  const partnersLinkArr = partnersLink.split('/');
  // apparently if there is a '/' at the end of the url
  // (on the old platform) it gets redirected to the index
  if (partnersLinkArr.lastObject === '') {
    partnersLinkArr.pop();
  }
  let [, , domainName] = partnersLinkArr;

  // if this function gets called before the links gets
  // computed and passed in
  if ((isOldPromotion || currentDomain.split(':')[0] === 'localhost') && domainName.split('.')[1] !== 'dev') {
    domainName = domainName.split('.');
    domainName.splice(1, 0, 'dev');
    domainName = domainName.join('.');
  } else if (currentDomain.split('-')[1] === 'qa') {
    domainName = currentDomain.split('-');
    domainName[0] = 'consumer';
    domainName = domainName.join('-');
  }
  partnersLinkArr[2] = domainName;
  return partnersLinkArr.join('/');
}
