/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { fullName } from 'partner/utils/computed';
import BaseModel from 'secondstreet-common/models/base';

export default class ConsumerUserModel extends BaseModel {
  @service('enums') enums;
  @service('store') store;

  @attr('number') organizationId;
  @attr('string') organizationName;
  @attr('string') emailAddress;
  @attr('string') firstName;
  @attr('string') middleName;
  @attr('string') lastName;
  @attr('string') phone;
  @attr('date-only') birthDate;
  @attr('number') genderId;
  @attr('string') address1;
  @attr('string') address2;
  @attr('string') city;
  @attr('number') stateProvinceId;
  @attr('string') postalCode;

  // Only returned when retrieved via /consumer_users/:id
  @attr('boolean') isEditable;
  @attr('boolean') isSendable;
  @attr('boolean') isOptedOut;
  @attr('string') sendingStatus;
  @attr('string') sendingStatusDetail;
  @attr('date') lastActivityDate;

  @hasMany('interest-tag', { async: true }) interestTags;
  @hasMany('organization-user-attribute', { async: false }) organizationUserAttributes;
  @hasMany('message-campaign-category-opt-out', { async: false }) messageCampaignCategoryOptOuts;

  @fullName() fullName;

  @computed('genderId')
  get genderName() {
    return this.enums.findWhere('GENDER_FILTER_TYPE', { id: this.genderId }, 'name');
  }

  @computed('stateProvinceId')
  get stateProvinceName() {
    return this.enums.findWhere('STATE_PROVINCE', { id: this.stateProvinceId }, 'name');
  }

  get interestTagsCount() {
    return this.hasMany('interestTags').ids().length;
  }
}
