/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;

/**
 * @typedef {DS.Model} PromotionApprover
 */
export default BaseModel.extend({
  //region Properties
  emailAddress: attr('string'),
  firstName: attr('string'),
  lastName: attr('string'),
  //endregion

  //region Computed Properties
  displayName: computed('firstName', 'lastName', 'emailAddress', function () {
    const firstName = isPresent(this.firstName) ? this.firstName : '';
    const lastName = isPresent(this.lastName) ? this.lastName : '';
    const email = this.emailAddress;
    return `${firstName} ${lastName} <${email}>`;
  }),
  //endregion
});
