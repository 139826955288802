/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import RSVP from 'rsvp';

export default Controller.extend({
  router: service(),

  actions: {
    save() {
      const template = this.model;
      const isModelNew = get(this, 'model.isNew');
      return RSVP.Promise.resolve()
        .then(() => {
          if (isBlank(get(template, 'templateContent'))) {
            //passing in null so the API knows that we are doing the initial save.
            set(template, 'templateContent', null);
          }
        })
        .then(() => template.save())
        .then(() => {
          if (isModelNew) {
            this.router.transitionTo(
              'organizations.organization.email.message-body-templates.message-body-template',
              get(template, 'id')
            );
          }
          this.send('stopEditingName');
        });
    },
    stopEditing() {
      this.send('stopEditing');
    },
  },
});
