/* eslint-disable ember/no-computed-properties-in-native-classes */
import { belongsTo, hasMany, type SyncHasMany } from '@ember-data/model';
import { alias } from '@ember/object/computed';
import BaseModel from 'secondstreet-common/models/base';
import type EntityLinkModel from './entity-link';
import type MatchupEntryModel from './matchup-entry';

export default class EntrantProfileModel extends BaseModel {
  @belongsTo('matchup-entry', { async: false }) declare matchupEntries: MatchupEntryModel;
  @alias('matchupEntries') declare matchupEntry: EntrantProfileModel['matchupEntries'];
  @hasMany('entity-link', { async: false }) declare entityLinks: SyncHasMany<EntityLinkModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'entrant-profile': EntrantProfileModel;
  }
}
