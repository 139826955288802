import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type SettingsService from 'partner/services/settings';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import type CurrentService from 'partner/services/current';
import PerformanceReportFixedHeaderController from 'partner/-base/performance-report-fixed-header-controller';
import { SortParamsType } from 'partner/-base/promotion-ads-controller';
import { isEmpty } from '@ember/utils';
import ReportsService from 'partner/services/reports';
import SnackbarService from 'secondstreet-common/services/snackbar';
import camelizeKeys from 'partner/utils/camelize-keys';

type FilterType = {
  name: string;
  id: number | string;
};

export default class OrganizationPromotionSyndicationMetricsController extends PerformanceReportFixedHeaderController {
  @service declare settings: SettingsService;
  @service declare current: CurrentService;
  @service declare reports: ReportsService;
  @service declare snackbar: SnackbarService;

  queryParams = ['page', 'sortColumn', 'organizationIds', 'sortDirection'];

  @tracked pageIndex = 1;
  @tracked sortColumn = 'users';
  @tracked sortDirection = 'desc';
  @tracked organizationIds = '';
  @tracked params = {};
  @tracked meta?: any;

  allOption: FilterType = {
    name: 'All',
    id: 0,
  };

  get childrenOrganizationsOptions() {
    return [this.allOption, this.current.organization, ...this.current.organization.descendants];
  }

  get selectedOrgs() {
    const selectedOrgIds = this.organizationIds ? this.organizationIds.split(',') : [];
    if (isEmpty(selectedOrgIds)) return [this.allOption];
    return [this.current.organization, ...this.current.organization.descendants].filter((org: FilterType) =>
      selectedOrgIds.includes(`${org.id}`)
    );
  }

  get stats() {
    return this?.meta?.performanceStatisticsTotalCounts
      ? {
          totalRecords: this.meta.totalRecords,
          ...camelizeKeys(this.meta.performanceStatisticsTotalCounts),
        }
      : [];
  }

  get summaryStats() {
    return [
      {
        header: 'Organizations',
        primary: 'totalRecords',
      },
      {
        header: 'People',
        primary: 'totalUserCount',
      },
      {
        header: 'New People',
        primary: 'totalNewUserCount',
      },
      {
        header: 'Entries',
        primary: 'totalEntrantCount',
      },
      {
        header: 'New Opt Ins',
        primary: 'totalOptInCount',
      },
      {
        header: 'Leads',
        primary: 'totalLeadsCount',
      },
    ];
  }

  @action
  onSortChange(sortParams: SortParamsType) {
    this.sortColumn = sortParams.column;
    this.sortDirection = sortParams.direction;
    this.pageIndex = 1;
    this.updateParams();
  }

  @action
  changePage(page: number) {
    this.pageIndex = page;
    this.updateParams();
  }

  @action
  setTempSelectedOrgs(items: FilterType[]) {
    const lastSelectedId = items.lastObject?.id;
    this.organizationIds = lastSelectedId == 0 ? '' : items?.mapBy('id')?.join(',');
  }

  @action
  updateParams() {
    this.params = {
      allowSorting: true,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      pageIndex: this.pageIndex,
      organizationIds: this.organizationIds,
      pageSize: 25,
    };
  }

  downloadReportTask = task({ drop: true }, async () => {
    const { organizationIds, sortColumn, sortDirection } = this;
    try {
      await this.reports.download('PromotionPerformance', {
        params: {
          sortColumn: sortColumn || '',
          sortDirection: sortDirection || '',
        },
        data: {
          organization_ids: organizationIds,
          only_syndicates: true,
        },
      });
    } catch (e) {
      this.snackbar.exception(e);
    }
  });
}
