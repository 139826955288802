/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */ // FIXME
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { filterBy } from '@ember/object/computed';
import { run, scheduleOnce, debounce } from '@ember/runloop';
import { isPresent } from '@ember/utils';
import ReadRss from 'partner/utils/rss-reader';

export default Component.extend({
  //region Attributes
  selectMultiple: false,
  /**
   * @type {?Number}
   */
  articleLimit: null,
  /**
   * @type {Function}
   */
  cancelImport() {},
  //endregion

  //region Properties
  isLoadingRssArticles: false,
  rssArticles: null,
  //endregion

  //region Ember hooks
  didInsertElement() {
    this._super(...arguments);
    scheduleOnce('afterRender', this, function () {
      this.element.querySelector('.ss-material-input').focus();
    });
  },
  //endregion
  //region Computed Properties
  selectedRssArticles: filterBy('rssArticles', 'isSelected'),
  articleLimitReached: computed('selectedRssArticles.length', 'articleLimit', function () {
    return this.articleLimit <= this.selectedRssArticles.length;
  }),
  containsFullArticles: computed('rssArticles.{length,@each.content}', function () {
    return isPresent(this.rssArticles) && this.rssArticles.isEvery('content');
  }),
  //endregion

  //region methods
  async updateRssArticles(rssFeedUrl) {
    run(() => set(this, 'isLoadingRssArticles', true));
    const articles = await ReadRss(rssFeedUrl);
    this.rssArticles.clear();
    this.rssArticles.addObjects(articles);
    run(() => set(this, 'isLoadingRssArticles', false));
  },
  //end region

  init() {
    this._super(...arguments);

    set(this, 'rssArticles', this.rssArticles || []);
  },

  //region Actions
  actions: {
    importArticles(importFullArticles = false) {
      const selectedArticles = this.rssArticles.filterBy('isSelected');
      selectedArticles.forEach(article => {
        // strip the HTML from the article's description value
        const sanitizedDescription = new DOMParser().parseFromString(article.description, 'text/html');
        set(article, 'description', sanitizedDescription.body.textContent || '');
      });
      this.importArticles(selectedArticles, importFullArticles);
    },
    debounceFeedUrl(event) {
      const feedUrl = event.target.value;
      debounce(this, this.updateRssArticles, feedUrl, 1500);
    },
    selectOnly(rssArticle) {
      this.rssArticles.filterBy('isSelected').forEach(rssArticle => set(rssArticle, 'isSelected', false));
      set(rssArticle, 'isSelected', true);
    },
  },
  //endregion
});
