/* eslint-disable ember/no-get, ember/no-classic-classes, ember/no-actions-hash */
import { A } from '@ember/array';
import { computed, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty } from '@ember/utils';
import flattenDeep from 'lodash/flattenDeep';
import union from 'lodash/union';
import { UNSAVED_CHANGES_ALERT } from 'partner/utils/constants';
import RSVP from 'rsvp';

export default Route.extend({
  router: service(),

  /**
   * @private
   */
  isQuestionChanged() {
    const { question } = this.currentModel;
    const isNew = question && question.isNew;

    let isChanged = !isNew && question.hasDirtyAttributes;
    if (!isChanged && isNew) {
      const properties = A([question.name, question.mediaItem]);
      isChanged = properties.filter(prop => !isEmpty(prop)).length;
    }

    return isChanged;
  },

  validate() {
    const { question } = this.currentModel;
    const errors = [];
    if (question.questionOptions.length < 1) {
      errors.push({
        name: 'Options',
        message: 'You must have at least one answer.',
      });
    } else if (
      this.modelFor('organizations.organization.organization-promotions.organization-promotion').organizationPromotion
        .promotion.promotionSubType === 'QuizTrivia' &&
      question.questionOptions.filterBy('isCorrect', true).length < 1
    ) {
      errors.push({
        name: 'Correct Options',
        message: 'You must have at least one correct answer.',
      });
    }
    if (errors.length) {
      question.forceDirty();
      errors.forEach(err => question.errors.add(err.name, err.message));
      return RSVP.Promise.reject();
    }
    return RSVP.Promise.resolve();
  },

  /**
   * Save the questions and question options
   * @returns {Ember.RSVP.Promise} Resolves with an object with `question`, `questionOptions`, and
   * `questionOptionOutcomeWeights`, or rejects with an error if there were problems saving.
   */
  save() {
    const { question } = this.currentModel;
    if (question.name) {
      //trim name before saving
      set(question, 'name', question.name.trim());
    }

    return this.validate()
      .then(() =>
        // 1. Save the questions
        question.hasDirtyAttributes ? question.save() : question
      )
      .then(question =>
        // 2. Save the question options
        RSVP.hash({
          question,
          questionOptions: RSVP.all(
            union(
              question.questionOptions.map(option => {
                if (option.name && !option.isDeleted) {
                  //trim name before saving
                  set(option, 'name', option.name.trim());
                }
                return option.hasDirtyAttributes ? option.save() : option;
              }),
              this.controller.deletedQuestionOptions.map(option => option.save())
            )
          ),
        })
      )
      .then(hash =>
        // 3. Save the question option outcome weights
        RSVP.hash({
          question: hash.question,
          questionOptions: hash.questionOptions,
          questionOptionOutcomeWeights: RSVP.all(
            flattenDeep(
              hash.question.questionOptions.map(option =>
                option.questionOptionOutcomeWeights.map(weight =>
                  weight.hasDirtyAttributes && !weight.isJunkData ? weight.save() : weight
                )
              )
            )
          ),
        })
      )
      .then(hash => {
        this.modelFor(
          'organizations.organization.organization-promotions.organization-promotion.setup.quiz'
        ).questions.addObject(hash.question);
        // this forces the quiz check status to be recalculated
        this.send('updateCheckboxStatuses');
        return hash;
      })
      .catch(err => {
        throw err;
      });
  },
  /**
   * Makes sure the outcome at least has a name
   */
  isQuestionValid: computed('currentModel.question.name', function () {
    const { question } = this.currentModel;
    return question && !isBlank(question.name);
  }),

  actions: {
    // TODO: Abstract this along with OutcomeRouteMixin, but note the difference in which properties are monitored
    willTransition(transition) {
      this._super();
      const { question } = this.currentModel;
      let safeToTransition = false;

      if (
        this.isQuestionChanged() ||
        this.controller.isQuestionOptionsDirty ||
        this.controller.isQuestionOptionOutcomeWeightsDirty
      ) {
        const userHasConfirmedLeaving = window.confirm(UNSAVED_CHANGES_ALERT);
        if (userHasConfirmedLeaving) {
          if (question.isNew) {
            question.destroyRecord();
          } else {
            question.rollbackAttributes();
          }
          safeToTransition = true;
        }
      } else {
        if (question.isNew) {
          question.destroyRecord();
        }
        safeToTransition = true;
      }

      if (!safeToTransition) {
        transition.abort();
      }

      set(this, 'controller.showNoQuestionOptionError', false);

      return true;
    },
    save() {
      this.save().then(hash =>
        this.router.replaceWith(
          'organizations.organization.organization-promotions.organization-promotion.setup.quiz.questions.question',
          hash.question.id
        )
      );
    },
    saveAndNew() {
      this.save()
        .then(() => this.newQuestion())
        .catch(e => {
          console.error(e);
        });
    },
    new() {
      this.newQuestion();
    },
    remove() {
      const result = window.confirm('Are you sure you want to remove this question?');
      if (result) {
        const { questionOptions } = this.currentModel.question;
        RSVP.all(questionOptions.map(x => x).map(item => item.destroyRecord()))
          .then(() => {
            this.modelFor(
              'organizations.organization.organization-promotions.organization-promotion.setup.quiz'
            ).questions.removeObject(this.currentModel.question);
            return this.currentModel.question.destroyRecord();
          })
          .then(() => this.send('compactDisplayOrders'))
          .then(() => {
            // this forces the quiz check status to be recalculated
            this.send('updateCheckboxStatuses');
            this.router.replaceWith(
              'organizations.organization.organization-promotions.organization-promotion.setup.quiz.questions.index'
            );
          });
      }
    },
    removeImage() {
      set(this, 'currentModel.question.mediaItem', null);
      this.currentModel.question.forceDirty();
    },
    setMediaItem(mediaItem) {
      set(this, 'controller.isAddImageFlyoutOpen', false);
      set(this, 'currentModel.question.mediaItem', mediaItem);
      this.currentModel.question.forceDirty();
    },
    saveAndRunSimulation() {
      set(this, 'controller.balance', false);
      this.save().then(
        () => set(this, 'controller.balance', true),
        () => {
          /* no op */
        }
      );
    },
    closeSimulation() {
      set(this, 'controller.balance', false);
    },
  },
});
