/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { or } from '@ember/object/computed';
import Component from '@ember/component';
import { set, action } from '@ember/object';
import { next } from '@ember/runloop';
import $ from 'jquery';
import { MAX_TOKEN_CONTENT_URL_LENGTH } from 'partner/utils/constants';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class UploadMediaFlyout extends Component {
  @service uploader;
  //region Attributes

  classNames = ['upload-media-flyout'];

  'force-open' = false;
  'is-open' = false;
  'is-disabled' = false;
  'small-flyout' = false;
  'character-limit-reached' = false;
  'currently-uploading-image' = false;
  'currently-uploading-video' = false;
  'disable-add-url' = false;
  'adjust-position-value' = false;
  'can-add-by-url' = true;
  'can-add-by-library' = true;
  'can-add-image' = true;
  'can-add-video' = false;
  'can-add-youtube' = true;
  'media-model' = null;
  'image-url' = '';
  'button-text' = 'New Image';
  tokens = null;
  'add-media-item'() {}
  'add-video-media-item'() {}
  'upload-started'() {}
  'upload-ended'() {}
  'open-image-library'() {}
  'sync-image-url'() {}
  'open-youtube-uploader'() {}

  allowDragAndDrop = true;
  failedUploading = false;
  showError = true;

  shouldNotClose = false;
  isAddingByUrl = false;
  shiftFlyoutUpwards = false;
  maxTokenContentUrlLength = MAX_TOKEN_CONTENT_URL_LENGTH;

  @tracked
  isUploading = false;

  @or('is-open', 'force-open') show;

  @tracked
  file = null;

  @tracked
  fileDragged = false;

  didRender() {
    super.didRender(...arguments);
    if (this.show) {
      this.handleBodyClicks();
    }
    const getNearestParentWithOverflowHidden = el => {
      if (el === document.documentElement) {
        return el;
      }
      return ['auto', 'hidden', 'scroll'].includes(window.getComputedStyle(el.parentElement).overflowY)
        ? el.parentElement
        : getNearestParentWithOverflowHidden(el.parentElement);
    };
    if (this.show) {
      const topOfFlyout = document.querySelector('.pop-up-view').getBoundingClientRect().top;
      const nearestParentWithOverflowHidden = getNearestParentWithOverflowHidden(this.element);
      const bottomOfParent = nearestParentWithOverflowHidden.getBoundingClientRect().bottom;
      // 50 pixels is the minimum amount of the menu that must be seen in order to be considered "visible enough", determined by product
      if (nearestParentWithOverflowHidden && topOfFlyout + 50 > bottomOfParent) {
        set(this, 'shiftFlyoutUpwards', true);
      }
    } else {
      set(this, 'shiftFlyoutUpwards', false);
    }
  }

  click(ev) {
    const isChild = this.element?.querySelector('.new-image-button')?.contains(ev.target);
    const bubble = true;
    const capture = false;
    if (ev.target.matches('input[type=file]')) {
      set(this, 'shouldNotClose', true);
      return bubble;
    } else if (this['is-open']) {
      return isChild ? capture : bubble;
    }
    return bubble;
  }

  handleBodyClicks() {
    $('body')
      .off('click.uploadImageFlyout')
      .one('click.uploadImageFlyout', () => {
        // Bypass the flyout-close, then create a new event handler because this one has already been triggered.
        if (this.shouldNotClose) {
          set(this, 'shouldNotClose', false);
          this.handleBodyClicks();
        } else if (!this.isDestroyed) {
          this.open(false);
        }
      });
  }

  @action
  async onDropFile(event) {
    //If user drop File on DropArea
    event.preventDefault();
    this.fileDragged = false;
    [this.file] = event.dataTransfer.files;
    let type = null;
    if (this.file.type.includes('image')) {
      type = 'Image';
    } else if (this.file.type.includes('video')) {
      type = 'Video';
    }

    if ((type === 'Image' && !this['can-add-image']) || (type === 'Video' && !this['can-add-video'])) {
      return;
    }

    set(this, 'isUploading', true);
    set(this, 'failedUploading', false);

    this.uploadStarted(type);
    const response = await this.uploader.uploadFile(this.file);
    if (response.hasErrors) {
      this.displayError();
    }
    this.uploadEnded(type);
    set(this, 'isUploading', false);

    if (type === 'Image') {
      this['add-media-item'](response.responseItem);
    } else {
      this['add-video-media-item'](response.responseItem);
    }
  }

  @action displayError() {
    set(this, 'failedUploading', true);
  }

  @action
  dragLeaveFile(event) {
    //If user leave dragged File from DropArea
    event.preventDefault();
    this.fileDragged = false;
  }

  @action
  onDragOver(event) {
    //If user Drag File Over DropArea
    event.preventDefault(); //preventing from default behaviour
    this.fileDragged = true;
  }

  @action handleTrigger() {
    set(this, 'isAddingByUrl', false);
    next(() => (this['is-disabled'] ? null : this.open(!this['is-open']))); // Do this after
    // eslint-disable-next-line no-undef
    //this.click();
  }

  @action uploadStarted(type) {
    set(this, 'failedUploading', false);
    if (this['upload-started']) {
      this['upload-started'](type);
    }
  }

  @action uploadEnded(type) {
    if (this['upload-ended']) {
      this['upload-ended'](type);
    }
  }
}
