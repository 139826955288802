/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { ATTR_MAPPING, LIMIT_MAPPING } from 'partner/utils/template-builder/attribute-mapping';

export default Component.extend({
  //region Attributes
  attributes: null,
  'component-tag': '',
  'article-category': null,
  'is-articles': false,
  'primary-color': null,
  'secondary-color': null,
  'component-id': null,
  'set-attribute'() {},
  //endregion

  //region Ember Hooks
  classNames: ['attribute-editor'],
  //endregion

  //region Computed Properties
  attributeGroups: computed('attributes', 'is-articles', 'article-category', function () {
    const attrs = this.attributes;
    const isArticles = this['is-articles'];
    const articleCategory = this['article-category'];

    return ATTR_MAPPING.map(groupAttrs =>
      isArticles
        ? groupAttrs.filterBy('articleCategory', articleCategory).filter(attr => attrs[attr.attrName])
        : groupAttrs.filter(attr => attrs[attr.attrName] && !attr.articleCategory)
    ).filter(groupAttrs => groupAttrs.length > 0);
  }),

  /**
   * Need to store this attribute at a parent level because border-color needs to be aware of this updated value.
   */
  borderColorDisabled: computed('attributes.@each.value', 'article-category', function () {
    const { attributes } = this;
    const articleCategory = this['article-category'];
    const prefix = articleCategory ? `${articleCategory}-` : '';
    const borderWidth = attributes[`${prefix}border-width`]
      ? attributes[`${prefix}border-width`].value || attributes[`${prefix}border-width`].defaultValue
      : null;
    return !borderWidth || borderWidth === '0px';
  }),

  isHtmlEditor: computed('component-tag', function () {
    return this['component-tag'] === 'ss-static-html' || this['component-tag'] === 'ss-editable-html';
  }),
  //endregion

  //region Actions
  actions: {
    setAttribute(attr, value) {
      this['set-attribute'](attr, value);
    },
    findAttrType(attr) {
      return this.attributes[attr].attrType;
    },
    findDividerAttrName(attr) {
      const dividerAttrs = {
        'border-color': 'Color',
        'border-width': 'Height',
      };
      return dividerAttrs[attr.attrName] || attr.displayName;
    },
    findMinMax(limit, attrName, componentTag) {
      const obj = LIMIT_MAPPING.find(x => attrName === x.attrName && componentTag === x.componentTag);
      return obj ? obj[limit] : limit === 'min' ? 0 : null;
    },
  },
  //endregion
});
