/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { set, action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isNotItemToken } from 'partner/utils/tokens';
import { getOwner } from '@ember/application';

const SUBJECT_TOKENS = [
  'User.FirstName',
  'User.LastName',
  'User.Birthdate',
  'User.City',
  'User.State',
  'User.PostalCode',
  'User.Gender',
  'User.EmailAddress',
  'Organization.Name',
];

export default class TokensController extends Controller {
  @service('enums') enums;

  queryParams = ['tokenId'];

  @tracked tokenId = null;

  @computed('model.tokens.@each.tokenType')
  get displayedTokens() {
    return this.model.tokens.filterBy('tokenType', 'Template').filter(isNotItemToken);
  }

  get textInputFieldTokenFilter() {
    return dynamicToken =>
      dynamicToken.key === 'MessageVersion.Id' ||
      (dynamicToken.dynamicTokenType === 'System' && dynamicToken.key.includes('Random'));
  }

  get socialHeaderTextTokenFilter() {
    return dynamicToken => SUBJECT_TOKENS.includes(dynamicToken.key);
  }

  @computed('model.tokens', 'tokenId')
  get activeToken() {
    return this.model.tokens.findBy('id', this.tokenId);
  }

  get route() {
    return getOwner(this).lookup('route:organizations.organization.email.tokens');
  }

  get dynamicTokens() {
    const dynamicTokens = this.model.tokens.filterBy('tokenType', 'Dynamic');
    return dynamicTokens.reject(token => ['Organization.TimezoneInfoId'].includes(token.key));
  }

  @action
  async setTokenId(tokenId = null) {
    set(this, 'tokenId', tokenId);

    if (!tokenId) {
      set(this.model, 'tokens', await this.route.fetchTokens());
    }
  }
}
