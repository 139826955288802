/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */ // FIXME
import { get, set } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import Base from 'partner/components/template-builder/flyout/base';

export default Base.extend({
  //region Attributes
  'second-street-templates': null,
  'dips-url-value': null,
  'set-template-content'() {},
  'save-template'() {},
  //endregion

  //region Properties
  selectedTemplate: null,
  //endregion

  //region Ember Hooks
  didInsertElement() {
    this._super(...arguments);
    scheduleOnce('afterRender', () => {
      const [initialTemplate] = this['second-street-templates'].sortBy('name');
      if (initialTemplate) {
        this.send('setTemplate', initialTemplate);
      }
    });
  },
  //endregion

  //region Actions
  actions: {
    setTemplate(template) {
      set(this, 'selectedTemplate', template);
      this['set-template-content'](JSON.parse(get(template, 'mjmlJson')));
    },
  },
  //endregion
});
