/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { Checkbox } from '@ember/legacy-built-in-components';
import PermittedComponent from 'partner/mixins/permitted-component';

/**
 * @deprecated Use {{ss-material-checkbox}} instead.
 * checkbox that reacts to permission status.
 * {{ss-checkbox checked=someProperty}}
 * @see Promotion.PermittedComponent
 * @type {Ember.Checkbox}
 */
export default Checkbox.extend(PermittedComponent, {
  attributeBindings: ['disabled'],
  classNameBindings: ['disabled:ssDisabled'],
});
