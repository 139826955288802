/* eslint-disable ember/no-mixins */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

export default class extends Route.extend(AppContextRoute) {
  @service('session') session;
  @service('store') store;

  model() {
    const { messageCampaign } = this.modelFor('organizations.organization.message-campaigns.message-campaign');
    const { scheduleInstanceId } = this.modelFor(
      'organizations.organization.message-campaigns.message-campaign.schedule-instances.schedule-instance'
    );

    return RSVP.hash({
      scheduleInstanceId,
      messageCampaign,
      categoricalCharts: this.store.query('categorical-chart', { scheduleInstanceId }),
      messagingStatistic: this.store.query('messaging-statistic', { scheduleInstanceId }).then(x => x.firstObject),
    });
  }
}
