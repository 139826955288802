import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type CurrentService from 'partner/services/current';
import type { ModelFrom } from 'partner/utils/types';
import type { Model as SetupBallotRouteModel } from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/ballot';
import type EnumsService from 'ember-cli-ss-enums/services/enums';

export type Model = ModelFrom<SetupBallotMatchupEntriesNewRoute>;

export default class SetupBallotMatchupEntriesNewRoute extends Route {
  @service declare current: CurrentService;
  @service declare enums: EnumsService;
  @service declare store: Store;

  model() {
    const matchup = this.getMatchup();
    const { promotion } = this.current.organizationPromotion;

    if (!matchup) throw new Error('No matchup found');

    const matchupEntry = this.store.createRecord('matchup-entry', {
      matchup,
      promotion,
      statusTypeId: this.enums.findWhere('STATUS_TYPE', { name: 'Active' }),
      entry: this.store.createRecord('entry', {
        matchup,
        promotion,
        statusTypeId: this.enums.findWhere('STATUS_TYPE', { name: 'Active' }),
        entryFieldValues: [
          this.store.createRecord('entry-field-value', {
            fieldId: this.enums.findWhere('ENTRY_FIELD', { name: 'Entry Source Type' }),
            matchup,
            value: 'SecondStreet',
          }),
        ],
      }),
    });

    return { matchupEntry };
  }

  private getMatchup() {
    const { matchupId } = this.paramsFor(
      'organizations.organization.organization-promotions.organization-promotion.setup.ballot'
    ) as { matchupId?: string };

    return (
      (matchupId && this.store.peekRecord('matchup', matchupId)) ||
      // Fallback for simple ballots
      (
        this.modelFor(
          'organizations.organization.organization-promotions.organization-promotion.setup.ballot'
        ) as SetupBallotRouteModel
      ).matchups.firstObject
    );
  }
}
