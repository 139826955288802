/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;

export default BaseModel.extend({
  //region Ember Hooks
  enums: service(),
  //endregion

  // region properties
  organizationId: attr('number'),
  paymentProcessorId: attr('number'),
  accountId: attr('string'),
  paymentProcessorStatusTypeId: attr('number'),
  paymentProcessorStatusDetails: attr('string'),
  paymentProcessorApiKey: attr('string'),
  paymentProcessorPublishApiKey: attr('string'),
  defaultCurrencyId: attr('number'),
  statusTypeId: attr('number'),
  dateCreated: attr('date'),
  dateModified: attr('date'),
  createdByOrganizationUserId: attr('number'),
  isValid: attr('boolean'),
  // endregion

  // region computed properties
  paymentProcessorName: computed('paymentProcessorId', function () {
    return this.enums.findWhere('PAYMENT_PROCESSOR_TYPE', { id: this.paymentProcessorId }, 'name');
  }),
  paymentProcessorStatus: computed('paymentProcessorStatusTypeId', function () {
    return this.enums.findWhere('PAYMENT_PROCESSOR_STATUS_TYPE', { id: this.paymentProcessorStatusTypeId }, 'name');
  }),
  //end region
});
