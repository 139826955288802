/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';

/**
 * Used for importing or processing files inside of a modal.
 */
export default Component.extend({
  //region Ember Hooks
  classNames: ['import-modal'],
  //endregion

  //region Attributes
  /**
   * Pass in an action that will close the modal
   * @type Function
   */
  'close-modal': x => x,
  /**
   * Displays the progress bar in an indeterminate state if the import is processing
   * @type Boolean
   */
  'is-importing': false,
  //endregion
});
