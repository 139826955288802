import Route from '@ember/routing/route';
import type { ModelFrom } from 'partner/utils/types';

export type Model = ModelFrom<SetupBallotMatchupEntriesEditRoute>;

export default class SetupBallotMatchupEntriesEditRoute extends Route {
  model({ matchup_entry_id }: { matchup_entry_id: string }) {
    return { matchupEntryId: matchup_entry_id };
  }
}
