import enums from 'ember-cli-ss-enums/services/enums';
import moment from 'moment';

/**
 * Class representing a bracket
 * Contains matchup properties common to all matchups in a bracket
 */
class Bracket {
  matchupType = 1;
  entryIntervalTypeId = enums.findWhere('ENTRY_INTERVAL_TYPE', { name: 'None Specified' }, 'id');
  entriesAllowedNumber = 0;
  voteIntervalTypeId = enums.findWhere(
    'UGC_VOTING_ENTRY_OPTIONS',
    { nameWithCategories: 'Once per Category' },
    'entryIntervalTypeId'
  );
  votesAllowedNumber = enums.findWhere(
    'UGC_VOTING_ENTRY_OPTIONS',
    { nameWithCategories: 'Once per Category' },
    'entriesAllowedNumber'
  );
}
/**
 * Class representing an iteration
 * Contains matchup properties common to all matchups in a bracket iteration (round)
 */
class Iteration extends Bracket {
  constructor({ iteration, startDate, selectionStartDate, endDate, selectionEndDate }) {
    super();
    this.iteration = iteration;
    this.startDate = startDate;
    this.selectionStartDate = selectionStartDate;
    this.endDate = endDate;
    this.selectionEndDate = selectionEndDate;
  }
  /**
   * @param {Object} matchup
   */
  static getDatesFor = matchup => ({
    startDate: matchup.startDate,
    selectionStartDate: matchup.selectionStartDate,
    endDate: matchup.endDate,
    selectionEndDate: matchup.selectionEndDate,
  });
  /**
   * By default, new iterations should start one minute after the previous iteration ends and end one day after that
   * They should also end a minute before to preserve ending at 00 by default (example start = 9:01; end = 5:00;)
   * @param {Object} matchup
   */
  static createDatesFrom = matchup => {
    const previousStartDate = moment(matchup.startDate);
    const previousEndDate = moment(matchup.endDate);
    const previousDuration = previousEndDate.diff(previousStartDate);
    const previousRoundIsFirstRound = matchup.iteration === 1;
    const calculateEndDates = momentDate => (previousRoundIsFirstRound ? momentDate.subtract(1, 'minute') : momentDate);

    const startDate = moment(matchup.endDate).add(1, 'minutes').toDate();
    const selectionStartDate = moment(matchup.selectionEndDate).add(1, 'minutes').toDate();

    return {
      startDate,
      selectionStartDate,
      endDate: calculateEndDates(moment(startDate).add(previousDuration)).toDate(),
      selectionEndDate: calculateEndDates(moment(selectionStartDate).add(previousDuration)).toDate(),
    };
  };
  /**
   * By default, brackets should start one week from creation and end one day after that
   */
  static createNewDates = () => {
    const now = moment();
    return {
      startDate: moment(now).startOf('hour').add(1, 'weeks').toDate(),
      selectionStartDate: moment(now).startOf('hour').add(1, 'weeks').toDate(),
      endDate: moment(now).startOf('hour').add(8, 'days').toDate(),
      selectionEndDate: moment(now).startOf('hour').add(8, 'days').toDate(),
    };
  };
}
/**
 * Class representing a matchup
 * Contains matchup properties unique to each matchup
 */
class Matchup extends Iteration {
  constructor(displayOrder, iteration) {
    super(iteration);
    this.displayOrder = displayOrder;
    this.name = `Round ${iteration.iteration} - Matchup ${displayOrder}`;
  }
}

export { Iteration, Matchup };
