/* eslint-disable @typescript-eslint/restrict-template-expressions */
import Service, { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import duration from 'secondstreet-common/utils/duration';
import { simplyByProperty } from 'secondstreet-common/utils/sorting';

import type CurrentService from './current';
import type SponsoredPostsStatisticModel from 'secondstreet-common/models/sponsored-posts-statistic';
import type Store from '@ember-data/store';

export default class AdMetricsService extends Service {
  @service declare current: CurrentService;
  @service declare store: Store;

  async fetchAdMetrics() {
    return (
      await this.store.query('sponsored-posts-statistic', {
        BypassCache: true,
      })
    )
      .toArray()
      .filterBy('isBannerAd')
      .sort((a, b) => simplyByProperty('impressions')(b, a));
  }

  async fetchAndDownloadAdMetricsCSV() {
    await this.downloadAdMetricsCSV(await this.fetchAdMetrics());
  }

  async downloadAdMetricsCSV(adMetrics: SponsoredPostsStatisticModel[]) {
    await timeout(duration(100));
    const data = adMetrics.map(
      ({ adId, adImages, adName, ballotArea, adType, ownerEntityName, impressions, reach, clicks, ctr }) => ({
        AdId: adId,
        AdImageUrl: adImages.map(adImage => adImage.src).join(','),
        Advertisers: adName,
        Type: adType,
        'Ballot Area': ballotArea,
        Placement: ownerEntityName || '',
        Impressions: impressions,
        Reach: reach,
        Clicks: clicks,
        CTR: `${ctr}%`,
      })
    );

    const XLSX = await import('xlsx');

    const worksheet = XLSX.utils.json_to_sheet(data);
    const csv = XLSX.utils.sheet_to_csv(worksheet);

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${this.current.organizationPromotion.name.replace(/ /g, '')}_Ads.csv`;

    document.body.appendChild(link);
    link.click();
  }
}
