/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { get } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

/**
 * This is a mixin that will prevent non administer orgs from visiting any route that mixes this in.
 */
export default Mixin.create({
  router: service(),

  afterModel() {
    if (!get(this.modelFor('organizations.organization'), 'organization.isAdministrator')) {
      this.router.transitionTo('organizations.organization.settings');
    }
  },
});
