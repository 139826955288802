/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

export default BaseModel.extend({
  //region Attributes
  name: attr('string'),
  primaryContactFirstName: attr('string'),
  primaryContactLastName: attr('string'),
  emailAddress: attr('string'),
  ccEmailAddresses: attr('string'),
  phoneNumber: attr('string'),
  billingAddressLine_1: attr('string'),
  billingAddressLine_2: attr('string'),
  billingAddressLine_3: attr('string'),
  billingAddressCity: attr('string'),
  billingAddressPostalCode: attr('string'),
  billingAddressCountrySubdivision: attr('string'),
  billingAddressCountry: attr('string'),
  billingAddressAttention: attr('string'),
  invoiceMemo: attr('string'),
  collapseLineItems: attr('boolean'),
  //endregion

  //region Relationships
  organization: belongsTo('organization'),
  //endregion
});
