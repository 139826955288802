import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

/**
 * Dashboard Payment Form Route
 * /o/:organization_id/op/:organization_promotion_id/dashboard/payment-form
 * @type {Ember.Route}
 */
export default class extends Route {
  @service('session') session;
  @service('store') enums;
  @service('enums') store;

  model() {
    const { id: organizationId } = this.modelFor('organizations.organization').organization;
    const { organizationPromotion } = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion'
    );
    const { id: organizationPromotionId, hasSyndicates, promotion } = organizationPromotion;
    const promotionId = promotion.id;

    const fieldTypeId = this.enums.findWhere('FIELD_TYPE', {
      name: 'Optin',
    });

    return hash({
      organizationPromotion,
      organizationPaymentProcessors: this.store.query('organization-payment-processor', {
        organizationId,
      }),
      categoricalCharts: this.store.query('categoricalChart', {
        organizationId,
        ...(hasSyndicates ? { promotionId } : { organizationPromotionId }),
      }),
      eventCharts: this.store.query('eventChart', {
        organizationId,
        ...(hasSyndicates ? { promotionId } : { organizationPromotionId }),
      }),
      optinFields: this.store
        .query('field', {
          organizationId,
          organizationPromotionId,
          fieldTypeId,
          filterByOrganizationPromotion: true,
        })
        .then(x => x.filterBy('fieldType', 'Optin')),
    }).then(hash => {
      hash.stripePaymentProcessor = hash.organizationPaymentProcessors.findBy('paymentProcessorName', 'Stripe');

      return hash;
    });
  }
}
