/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { readOnly, sort } from '@ember/object/computed';

export default Component.extend({
  // region ember hooks
  classNameBindings: ['unknown-winner-info'],
  didRender() {
    this._super(...arguments);
    this.preLoadBeforeAfterImages(1);
  },
  //endregion

  // region Properties
  currentPageIndex: 1,
  currentTransition: 'toLeft',
  winnerSorting: computed(() => ['entry.matchup.displayOrder', 'rank']),
  //endregion

  // region Computed Properties
  winnersPaging: computed('numWinners', 'currentPageIndex', function () {
    return {
      pageIndex: this.currentPageIndex,
      pageSize: 1,
      totalRecords: this.numWinners,
    };
  }),
  numWinners: readOnly('sortedWinners.length'),
  sortedWinners: sort('winners', 'winnerSorting'),
  //endregion

  // region functions
  preLoadBeforeAfterImages(page) {
    const { sortedWinners } = this;
    const { numWinners } = this;
    this.preLoadImage(sortedWinners.objectAt(page === numWinners ? 0 : page));
    this.preLoadImage(sortedWinners.objectAt(page === 1 ? numWinners - 1 : page - 2));
  },
  preLoadImage(winner) {
    if (!winner) {
      return;
    }
    const matchupEntry = get(winner, 'entry.matchupEntry');
    if (!matchupEntry) {
      return;
    }
    const fullSizeImageUrl = get(matchupEntry, 'fullSizeImageUrl.value');
    if (get(matchupEntry, 'isUploadedPhoto')) {
      new Image().src = `//${get(this, 'dips-url.value')}/${get(
        matchupEntry,
        'photoUpload.value'
      )}?width=675&height=500&cropmode=Fill&anchor=center`;
    } else if (fullSizeImageUrl) {
      new Image().src = fullSizeImageUrl;
    }
  },
  //endregion

  // region actions
  changePage(page) {
    this.preLoadBeforeAfterImages(page);
    if (page > this.currentPageIndex) {
      set(this, 'currentTransition', 'toLeft');
      this['current-transition']('toLeft');
    } else {
      set(this, 'currentTransition', 'toRight');
      this['current-transition']('toRight');
    }
    set(this, 'currentPageIndex', page);
    this['current-page-changed'](page);
  },
  //endregion
});
