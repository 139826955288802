/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { get, set } from '@ember/object';
import { and, filterBy, mapBy, or, lte } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default Controller.extend({
  //region Dependencies
  person: controller('organizations.organization.people.person'),
  organization: controller('organizations.organization'),
  store: service(),
  features: service(),
  screen: service(),
  //endregion

  //region Properties
  showUnsubscribeModal: false,
  optingInUser: false,
  //endregion

  //region Computed Properties
  segmentedMemberships: filterBy('person.model.consumerUserAudiences', 'isSegmentedAudience'),

  optinSubscriptions: filterBy('person.model.consumerUserAudiences', 'isSubscriptionAudience'),

  hasEmailOrAudienceInsights: or('organization.model.organization.{hasMessaging,hasData}'),

  linkToOptinAvailable: and('hasEmailOrAudienceInsights', 'features.hasSubscriptionLists'),

  optinAudiences: filterBy('person.model.audiences', 'isSubscriptionAudience'),

  optinSubscriptionIds: mapBy('optinSubscriptions', 'audienceId'),

  isMobile: lte('screen.width', 940),
  //endregion

  //region Methods
  toggleUserSubscription(bool) {
    set(this, 'person.model.consumerUser.isOptedOut', bool);
    this.send('save', get(this, 'person.model.consumerUser'));
  },
  removeAllOptins() {
    RSVP.all(
      this.optinSubscriptions.map(membership => {
        get(this, 'person.model.consumerUserAudiences').removeObject(membership);
        return membership.destroyRecord();
      })
    );
  },
  //endregion

  //region Actions
  actions: {
    findNonSubscribedOptins(audiences) {
      return audiences
        .rejectBy('isSegmentedAudience')
        .reject(audience => this.optinSubscriptionIds.includes(get(audience, 'id')));
    },
    cancelUnsubscribe() {
      set(this, 'showUnsubscribeModal', false);
    },
    confirmUnsubscribe() {
      this.toggleUserSubscription(true);
      this.removeAllOptins();
    },
    unsubscribeFromAudience(membership, event) {
      event.preventDefault();
      get(this, 'person.model.consumerUserAudiences').removeObject(membership);
      membership.destroyRecord();
    },
    async subscribeToOptin(audience) {
      // if a user had been opted out of all audiences, opt them back in
      if (get(this, 'person.model.consumerUser.isOptedOut')) {
        this.toggleUserSubscription(false);
      }

      const newMembership = await this.store
        .createRecord('consumer-user-audience', {
          consumerUserId: get(this, 'person.model.consumerUser.id'),
          audienceId: get(audience, 'id'),
          name: get(audience, 'name'),
          audienceTypeId: get(audience, 'audienceTypeId'),
        })
        .save();

      //need to add the SAVED audience, otherwise it briefly renders an incorrectly-sorted date-less audience
      get(this, 'person.model.consumerUserAudiences').addObject(newMembership);
    },
  },
  //endregion
});
