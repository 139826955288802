/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import PromotionTypeRoute from 'partner/mixins/promotion-type-route';

/**
 * Route for viewing entries per matchup.
 * @type {Ember.Route}
 * @mixes {PromotionTypeRoute}
 */
export default Route.extend(PromotionTypeRoute, {
  //region Ember Hooks
  model() {
    return this.modelFor('organizations.organization.organization-promotions.organization-promotion.matchups.matchup');
  },
  setupController(controller, model) {
    set(controller, 'model', model);
    set(controller, 'selectedMatchup', model);

    const matchupsModel = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion.matchups'
    );
    if (matchupsModel) {
      const matchups = matchupsModel.sortBy('startDate');
      matchups.forEach((m, index) => set(m, '_dynamicRoundName', `Round ${index + 1}`));
      set(controller, 'matchups', matchups);
    }

    set(controller, 'searchTerm', '');
    set(controller, 'searching', false);

    controller.send('search');
  },
  //endregion
});
