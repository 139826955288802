/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { inject as controller } from '@ember/controller';
import { computed, setProperties } from '@ember/object';
import { alias, readOnly } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

/**
 * Organization Promotion List Controller Mixin
 * @type {Ember.Mixin}
 */
export default Mixin.create({
  //region Properties
  statusTypeId: null,
  promotionTypeId: null,
  isSettingStatus: false,
  //endregion

  //region Ember Hooks
  enums: service(),
  applicationController: controller('application'),
  organizationController: controller('organizations.organization'),
  queryParams: [
    'statusTypeId',
    'promotionTypeId',
    'promotionTypeCategoryId',
    'pageIndex',
    'sortColumn',
    'sortDirection',
    'isCurrent',
    'searchValue',
    'promotionSubTypeId',
  ],
  //endregion

  //region Computed Properties
  promotionTypeIdInt: computed('promotionTypeId', function () {
    return window.parseInt(this.promotionTypeId, 10);
  }),
  showPromotionCreationButton: readOnly('promotionTypeId'),
  organization: alias('organizationController.model.organization'),
  /**
   * This will have to change when more than one promotionType is displayed on the page at a time
   */
  promotionCreationId: computed('promotionTypeId', function () {
    return this.enums.findWhere('PROMOTION_TYPE', { id: this.promotionTypeIdInt }, 'creationId');
  }),
  isAdminUser: alias('applicationController.isAdminUser'),
  pageIndex: 1,
  sortColumn: null,
  isCurrent: true,
  sortDirection: null,
  searchValue: null,
  promotionSubTypeId: null,
  //endregion

  actions: {
    pageChanged(page) {
      set(this, 'pageIndex', page);
    },

    onApplyFilters({ sortColumn, sortDirection, searchValue, selectedTypeId }) {
      setProperties(this, {
        sortColumn,
        sortDirection,
        searchValue,
        pageIndex: 1,
        promotionSubTypeId: selectedTypeId,
      });
    },
  },
});
