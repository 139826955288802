/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { computed, get, set } from '@ember/object';
import { equal } from '@ember/object/computed';
import { debounce, next } from '@ember/runloop';
import Base from 'partner/components/template-builder/flyout/base';
import { insertIf } from 'secondstreet-common/utils/functional';

const TAGS_WITH_LAYOUT = ['ss-social-icons', 'ss-menu-links', 'ss-section'];
const TAGS_WITH_CONTENT_BLOCK = ['ss-image', 'ss-text', 'ss-button', 'ss-editable-html'];
const TAGS_WITH_ACCORDIONS = ['ss-social-icons', 'ss-menu-links', 'ss-section', 'ss-articles'];

export default Base.extend({
  //region Attributes
  'selected-component': null,
  'template-tokens': null,
  'section-layouts': null,
  'primary-color': null,
  'secondary-color': null,
  'set-attribute'() {},
  'remove-component'() {},
  'duplicate-component'() {},
  'clear-selected-component'() {},
  'add-new-token-to-template'() {},
  //endregion

  //region Ember Hooks
  didReceiveAttrs() {
    this._super();
    const tag = get(this, 'selected-component.tag');
    if (!this.isSettingAttribute && TAGS_WITH_LAYOUT.includes(tag)) {
      set(this, 'activeEditorDrawer', tag === 'ss-section' ? 'styles' : 'layout');
    }
  },
  //endregion

  //region Properties
  activeEditorDrawer: null,
  isSettingAttribute: false,
  //endregion

  //region Computed Properties
  articleLayouts: computed('selected-component.attrsInfo.layout-style.attrType', function () {
    const type = get(this, 'selected-component.attrsInfo.layout-style.attrType');
    const allowedLayouts = type.match(/^enum\((.+)\)/)[1].split(',');
    return [
      ...insertIf(allowedLayouts.includes('image-top'), {
        value: 'image-top',
        image: 'Articles_One_Column',
      }),
      ...insertIf(allowedLayouts.includes('grid'), {
        value: 'grid',
        image: 'Articles_Two_Column',
      }),
      ...insertIf(allowedLayouts.includes('image-left'), {
        value: 'image-left',
        image: 'Articles_Images_Left',
      }),
      ...insertIf(allowedLayouts.includes('image-right'), {
        value: 'image-right',
        image: 'Articles_Images_Right',
      }),
    ];
  }),

  usesContentBlock: computed('selected-component.tag', function () {
    return TAGS_WITH_CONTENT_BLOCK.includes(get(this, 'selected-component.tag'));
  }),

  isEditingSocial: equal('selected-component.tag', 'ss-social-icons'),

  isEditingArticles: equal('selected-component.tag', 'ss-articles'),

  isEditingSection: equal('selected-component.tag', 'ss-section'),

  isEditingMenuLinks: equal('selected-component.tag', 'ss-menu-links'),

  /**
   * Some components have their attributes organized into different accordions/drawers.
   * @param tag
   */
  showAccordions: computed('selected-component.tag', function () {
    return TAGS_WITH_ACCORDIONS.includes(get(this, 'selected-component.tag'));
  }),
  //endregion

  //region Actions
  actions: {
    updateContentBlockSearchResults({ target: { value } }) {
      debounce(this, this.updateContentBlockSearchResults, value, 750);
    },
    setActiveEditorDrawer(drawer) {
      if (this.activeEditorDrawer === drawer) {
        set(this, 'activeEditorDrawer', null);
      } else {
        set(this, 'activeEditorDrawer', drawer);
      }
    },
    setAttribute(attrName, value) {
      set(this, 'isSettingAttribute', true);
      this['set-attribute'](attrName, value);
      next(() => {
        set(this, 'isSettingAttribute', false);
      });
    },
  },
  //endregion
});
