/* eslint-disable ember/no-mixins */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * Setup Entries Route
 * /o/:organization_id/op/:organization_promotion_id/setup/entries
 * @type {Ember.Route}
 */
export default class OrganizationsOrganizationOrganizationPromitionsOrganizationPromitionSetupEntriesRoute extends Route {
  //region Dependencies
  @service enums;
  @service store;
  //endregion

  //region Ember Hooks
  async model() {
    const { organization } = this.modelFor('organizations.organization');
    const { organizationPromotion } = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion'
    );

    const pendingEntriesEmailRecipientsSetting = await this.fetchOrCreatePendingEntriesEmailRecipientsSetting(
      organization.id,
      organizationPromotion.id
    );
    const entryForm = await this.fetchEntryForm();

    return {
      entryForm,
      organization,
      organizationPromotion,
      pendingEntriesEmailRecipientsSetting,
    };
  }
  //endregion

  afterModel() {
    this.send('checkChecklistStep', this.fullRouteName);
  }

  //region Methods
  async fetchOrCreatePendingEntriesEmailRecipientsSetting(organizationId, organizationPromotionId) {
    const options = {
      key: 'pending_entries_email_recipients',
      organizationId,
      organizationPromotionId,
    };

    return (
      (await this.store.queryRecord('setting', options)) ||
      this.store.createRecord('setting', { ...options, value: '', languageId: 0 }).save()
    );
  }

  fetchEntryForm() {
    const formTypeId = this.enums.findWhere('FORM_TYPE', { name: 'EntrySubmission' });
    return this.store.queryRecord('form', { formTypeId });
  }
  //region
}
