/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { gt, lte } from '@ember/object/computed';
import moment from 'moment';
import TickTock from 'partner/mixins/tick-tock';

export default Component.extend(TickTock, {
  countdown: computed('isOver', 'remainingMS', function () {
    const duration = moment.duration(this.remainingMS);
    const { isOver } = this;
    const remaining = timespan => (isOver ? 0 : duration[timespan]());
    return {
      years: remaining('years'),
      months: remaining('months'),
      days: remaining('days'),
      hours: remaining('hours'),
      minutes: remaining('minutes'),
      seconds: remaining('seconds'),
    };
  }),
  end: computed(() => new Date()), // Should be passed into the component.
  remainingMS: computed('end', 'tock', function () {
    return this.end - this.tock;
  }),
  isOver: lte('remainingMS', 0),
  hasYears: gt('countdown.years', 0),
  hasMonths: gt('countdown.months', 0),
});
