/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class LoginTotp extends Component {
  //region Properties
  @tracked isHelpInfoShowing = false;
  //endregion

  //region Actions
  @action
  async formChanged(code) {
    await this.args.formSubmitted(code);
  }

  @action
  showHelpInfo() {
    this.isHelpInfoShowing = true;
  }
}
