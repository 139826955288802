/* eslint-disable ember/no-on-calls-in-components, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components */
import Component from '@ember/component';
import { computed, get, observer, set } from '@ember/object';
import { on } from '@ember/object/evented';
import { inject as service } from '@ember/service';
import { all, Promise as EmberPromise } from 'rsvp';

export default Component.extend({
  store: service(),

  /**
   * Sets fieldOptionNames and fieldOptionsTitle which is used for chip text for Field type AudienceFilters
   */
  fieldOptionsObserver: on(
    'init',
    observer('fieldOptions.[]', 'field.isFulfilled', function () {
      this.field.then(field => {
        if (get(field, 'isSingleCheckbox')) {
          const text = `${get(field, 'name')}: Yes`;
          set(this, 'fieldOptionNames', text);
          set(this, 'fieldOptionsTitle', text);
        } else {
          this.fieldOptions.then(fieldOptions => {
            let text = '';
            fieldOptions.forEach((fieldOption, index) => {
              if (index !== 0) {
                text = `${text}, `;
              }
              text = text + get(fieldOption, 'name');
            });
            set(this, 'fieldOptionNames', text);
            set(this, 'fieldOptionsTitle', `${get(field, 'name')}: ${text}`);
          });
        }
      });
    })
  ),

  field: computed('audience-filter.entityId', function () {
    const entityId = get(this, 'audience-filter.entityId');
    return entityId ? this.store.find('field', entityId) : EmberPromise.resolve();
  }),

  fieldOptions: computed('audience-filter.fieldOptionIds', function () {
    return all(get(this, 'audience-filter.fieldOptionIds').map(id => this.store.find('fieldOption', id)));
  }),
});
