/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { and } from '@ember/object/computed';
import EditableSingleObject from 'partner/mixins/editable-single-object';
import { dasherize } from '@ember/string';

export default Component.extend(EditableSingleObject, {
  //region Ember Hooks
  tagName: '',
  //endregion

  //region Computed Properties
  /**
   * @type {Ember.ComputedProperty}
   * @returns {Boolean}
   */
  expanded: computed('model.entityTypeName', {
    get() {
      let result;
      if (this._expanded !== undefined) {
        //_expanded has been previously set so use that instead of calculating default value
        result = this._expanded;
      } else {
        //some types are collapsed by default
        result = !get(this, 'model.isMatchup') && !get(this, 'model.isGameGroup');
      }
      return result;
    },
    set(key, value) {
      set(this, '_expanded', value);
      return value;
    },
  }),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {Boolean}
   */
  isExpanded: and('expanded', 'model.isExpandable'),
  dasherizedDisplayName: computed('model.displayName', function () {
    return dasherize(get(this, 'model.displayName'));
  }),
  //endregion

  //region Actions
  actions: {
    toggleExpansion() {
      this.toggleProperty('expanded');
    },
  },
  //endregion
});
