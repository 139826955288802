/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { computed } from '@ember/object';
import { debounce } from '@ember/runloop';
import { isBlank } from '@ember/utils';
import Attribute from 'partner/components/template-builder/attribute-editor/attribute';
import getValidNumber from 'partner/utils/valid-number';

export default Attribute.extend({
  //region Attributes
  min: 0,
  max: 200,
  //endregion

  //region Ember Hooks
  tagName: 'tbody',
  //endregion

  //region Computed Properties
  /**
   * Pixel values get saved to template builder as a string with px. We remove that and instead show it outside the input.
   * @param value
   */
  formattedVerticalValue: computed('value', function () {
    const { value } = this;
    if (!value) {
      return 0;
    }
    const paddingValues = value.split(' ');
    const [verticalValue] = paddingValues;

    return verticalValue.replace('px', '');
  }),

  formattedHorizontalValue: computed('value', function () {
    const { value } = this;
    if (!value) {
      return 0;
    }
    const paddingValues = value.split(' ');
    const horizontalValue = paddingValues.length === 1 ? paddingValues[0] : paddingValues[1];

    return horizontalValue.replace('px', '');
  }),
  //endregion

  //region Methods
  setVerticalPadding(event) {
    this['set-attribute'](
      this['attr-name'],
      `${isBlank(event.target.value) ? 0 : getValidNumber(event)}px ${this.formattedHorizontalValue}px`
    );
  },

  setHorizontalPadding(event) {
    this['set-attribute'](
      this['attr-name'],
      `${this.formattedVerticalValue}px ${isBlank(event.target.value) ? 0 : getValidNumber(event)}px`
    );
  },
  //endregion

  //region Actions
  actions: {
    setVerticalPadding(event) {
      debounce(this, this.setVerticalPadding, event, 150);
    },
    setHorizontalPadding(event) {
      debounce(this, this.setHorizontalPadding, event, 150);
    },
  },
  //endregion
});
