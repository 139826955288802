/* eslint-disable ember/no-mixins */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import AppContextRoute from 'partner/mixins/app-context-route';

const CONTESTS_ID = 1;
const INTERACTIVE_CONTENT_ID = 2;

/**
 * Organization Dashboard Route
 * /o/:organization_id/data/dashboard
 * @type {Ember.Route}
 */
export default class OrganizationsOrganizationDashboardRoute extends Route.extend(AppContextRoute) {
  @service('session') session;
  @service('store') store;

  eventChartValueProperty = 'count';

  beforeModel() {
    // This is a quick fix to work around a (bug?) where the deactivate hook fires _after_ the model hook of the route you're going to
    // The OrganizationPromotionRoute#deactivate should be unsetting these, but going directly from there to here fires this model hook first.
    this.session.removeCustomHeader('X-Organization-Promotion-Id');
    this.session.removeCustomHeader('X-Promotion-Id');
  }

  async model() {
    const { id: organizationId } = this.modelFor('organizations.organization').organization;
    const organizationUserId = this.session.data.authenticated.organization_users.user_id;
    const localStorageKey = `SS_${organizationUserId}_${organizationId}_history`;

    const data = {
      userHistory: (JSON.parse(localStorage.getItem(localStorageKey)) || []).sortBy('date').reverse(),
    };

    data.categoricalCharts = this.store.query('categorical-chart', { organizationId });
    data.eventCharts = this.store.query('event-chart', { organizationId });
    data.audiences = this.store.query('audience', { organizationId });

    data.recentPromotions = this.store.query('organization-promotion', {
      promotionTypeCategoryId: CONTESTS_ID,
      pageSize: 1000,
      recentActivityOnly: true,
      organizationId,
    });

    data.recentInteractiveContent = this.store.query('organization-promotion', {
      promotionTypeCategoryId: INTERACTIVE_CONTENT_ID,
      recentActivityOnly: true,
      pageSize: 1000,
      organizationId,
    });

    data.recentMessageCampaigns = this.store.query('recent-message-campaign', {
      pageSize: 1000,
      organizationId,
    });
    return RSVP.hash(data);
  }

  resetController(controller) {
    controller.setProperties({
      _isCreatingPromotion: false,
      _isCreatingInteractiveContent: false,
      _isCreatingEmail: false,
    });
  }
}
