/* eslint-disable ember/no-on-calls-in-components, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { deprecate } from '@ember/debug';
import Component from '@ember/component';
import { get, observer, set } from '@ember/object';
import { on } from '@ember/object/evented';
import { next, later } from '@ember/runloop';
import { resolve } from 'rsvp';

export default Component.extend({
  //region Ember Hooks
  classNameBindings: ['isAnimatingOut', 'isAnimatedIn'],
  didInsertElement() {
    this._super(...arguments);
    // Clear out the selected options if nothing has been chosen
    this._resetOptions();
    this._setValue(this.currentValue);
    set(this, 'isAnimatingOut', false);
    set(this, 'isAnimatedIn', false);
    next(() => set(this, 'isAnimatedIn', true));
  },
  //endregion

  //region Properties
  classNames: ['simpleModal'],
  currentValue: null,
  'two-way': true,
  //endregion

  //region Observers
  /**
   * If content is a promise array then unwrap it.
   */
  unwrapContent: observer('content.isFulfilled', function () {
    if (get(this, 'content.isFulfilled') === true) {
      resolve(this.content).then(content => {
        set(this, 'content', content.content);
      });
    }
  }),
  /**
   * "mark" the currentValue as the selected option
   */
  setCurrentValueSelected: on(
    'init',
    observer('currentValue', function () {
      if (this.currentValue) {
        set(this.currentValue, '_selectedOption', true);
      }
    })
  ),
  //endregion

  //region Methods
  /**
   *
   * @private
   */
  _setSelectedOption() {
    const { currentValue } = this;
    this.content.forEach(item => {
      set(item, '_selectedOption', currentValue === item);
    });
  },
  _resetOptions() {
    this.content.forEach(item => {
      set(item, '_selectedOption', false);
    });
  },
  _setValue(item) {
    if (item) {
      set(this, 'currentValue', item);
      set(item, '_selectedOption', true);
      this._setSelectedOption();
    }
  },
  //endregion

  //region Actions
  actions: {
    changeValue(item) {
      if (!this.actionsDisabled) {
        if (this['two-way']) {
          deprecate('Please stop using two-way binding on simple-modal.', true, {
            id: 'component.simple-modal.two-way',
            until: 'Ember 4',
            for: 'simple-modal',
            since: '0.0.0',
          });
          this._setValue(item);
        }
        this.send('toggleDialog');
        const itemSelectedAction = this['item-selected-action'];
        if (itemSelectedAction) {
          itemSelectedAction(item);
        }
      }
    },
    toggleDialog() {
      const CSS_ANIMATION_DURATION_MS = 800;
      set(this, 'isAnimatingOut', true);
      set(this, 'isAnimatedIn', false);
      later(() => {
        if (!this.isDestroyed) {
          this['toggleDialog']();
        }
      }, CSS_ANIMATION_DURATION_MS);
    },
  },
  //endregion
});
