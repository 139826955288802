/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { inject as controller } from '@ember/controller';
import { alias } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';

/**
 * Convenience mixin that makes it easier to get access to the OrganizationPromotion object
 * from the organizationPromotion controller.
 */
export default Mixin.create({
  organizationPromotionController: controller(
    'organizations.organization.organization-promotions.organization-promotion'
  ),

  organizationPromotion: alias('organizationPromotionController.model.organizationPromotion'),
});
