/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-observers */
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { observes } from '@ember-decorators/object';
import { computed, set } from '@ember/object';
import { alias, equal, not, sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import enums from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';

const regex = /\{\{Organization\.Name\}\}/g;
@makeBooleanProperties('FIELD_TYPE')
export default class Field extends BaseModel {
  @service store;
  @service current;

  @attr('boolean', { defaultValue: false }) isRequired;
  @attr('boolean', { defaultValue: true }) isEditable;
  @attr('number') fieldTypeId;
  @attr('string', { defaultValue: '' }) name;
  @attr('number') fieldOptionTypeId;
  @attr('string') relatedUrl;
  @attr('number') relevance;
  @attr('string') requiredText;
  @attr('number') maximumLengthOrValue;
  @attr('string', { defaultValue: '' }) labelText;
  @attr('boolean') isStarred;
  @attr('number') fieldValueDestinationTypeId;
  @attr('string') helpText;
  @attr('number') userAttributeTypeId;
  @attr('number') membersOrganizationId;
  @attr('number') organizationId;
  @attr('boolean') isInherited;
  @attr('boolean') hasOptinNotifications;
  @attr('boolean') isYesnoFormat;
  @attr('boolean', { defaultValue: false }) isLimitSelection;
  @attr('number', { defaultValue: null }) answerLimit;

  @hasMany('field-option', { async: false }) fieldOptions;
  @belongsTo('global-optin', { async: false }) globalOptins;
  @hasMany('global-optin-user-outgoing-mapping', { async: false }) globalOptinUserOutgoingMappings;
  @belongsTo('media-item', { async: false }) mediaItems;
  @alias('mediaItems') mediaItem;
  @hasMany('optin-notification') optinNotifications;
  @hasMany('optin-notification-shared-data-field', { inverse: 'optinField' }) optinNotificationSharedDataFields;

  @observes('fieldType')
  setDefaultName() {
    if (isBlank(this.name) && !this.isDeleted) {
      let name;
      switch (this.fieldType) {
        case 'TwitterTweetApi':
          name = 'Tweet';
          break;
        default:
          name = '';
          break;
      }
      set(this, 'name', name);
    }
  }

  @computed('name', 'labelText')
  get hasCustomName() {
    return this.name !== this.labelText;
  }

  @alias('globalOptins') globalOptin;
  @equal('name', 'Gender') isGender;

  @computed('globalOptin.{isFulfilled,id}')
  get hasGlobalOptin() {
    return !!this.globalOptin;
  }

  @not('isEditable') isReadonly;

  @computed('relatedUrl')
  set twitterHandle(value) {
    value = value ? value.replace('@', '').replace(/\s/g, '') : '';
    set(this, 'relatedUrl', value);
    set(this, 'name', `Follow @${value}`);
    return value;
  }

  get twitterHandle() {
    return this.relatedUrl;
  }

  get formattedName() {
    return this.name?.replace(regex, this.current.organization.name);
  }

  get formattedLabelText() {
    return this.labelText?.replace(regex, this.current.organization.name);
  }

  /**
   * For TwitterTweetApi fields, use this as a user-friendly way to edit the requiredText.
   * @returns {String}
   * @type {Ember.ComputedProperty}
   */
  @computed('requiredText')
  get twitterHashtag() {
    return this.requiredText ? this.requiredText.replace(/^#/, '') : '';
  }

  set twitterHashtag(value) {
    // If the user typed their own hashtag, strip it.
    value = value ? value.replace('#', '').replace(/\s/g, '') : '';

    // Set a version of the hashtag to both requiredText and name
    set(this, 'requiredText', value ? `#${value}` : '');
    set(this, 'name', `Post${value ? ' #' : ''}${value}`);

    return value;
  }

  /**
   * Used on audience route to fake a fieldOption for SingleCheckbox fields
   */
  @computed('id', 'filedType')
  get fakeFieldOption() {
    if (!this.isSingleCheckbox) {
      return null;
    }

    const id = `${this.id}-fake-field-option`;
    return this.store.createRecord('fieldOption', {
      id,
      name: 'Yes',
    });
  }

  @enums.computed('name', 'fieldOptionTypeId') fieldOptionType;
  @enums.computed('name', 'fieldTypeId') fieldType;
  @enums.computed('iconKey', 'fieldTypeId', 'FIELD_TYPE') fieldTypeIconKey;
  @sort('fieldOptions', (a, b) => a.displayOrder - b.displayOrder) sortedFieldOptions;
}
