/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend({
  promotionTypeId: DS.attr('number'),
  ssProducts: DS.hasMany('ss-product', {
    async: false,
  }),
});
