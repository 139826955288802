/* eslint-disable ember/no-get, ember/no-classic-classes, ember/no-actions-hash */
import { A } from '@ember/array';
import { computed, get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty } from '@ember/utils';

export default Route.extend({
  router: service(),

  /**
   * Save the current outcome
   */
  save() {
    return this.currentModel
      .save()
      .then(outcome => {
        get(
          this.modelFor('organizations.organization.organization-promotions.organization-promotion.setup.quiz'),
          'outcomes'
        ).addObject(outcome);
        this.send('updateCheckboxStatuses');
        this.router.replaceWith(
          'organizations.organization.organization-promotions.organization-promotion.setup.quiz.outcomes.outcome',
          outcome
        );
        return outcome;
      })
      .catch(e => {
        console.error(e);
      });
  },
  /**
   * Transition to a page for creating a new outcome
   */
  newOutcome() {
    if (this.isOutcomeValid === true) {
      set(this, 'currentModel', null);
      this.router.transitionTo(
        'organizations.organization.organization-promotions.organization-promotion.setup.quiz.outcomes.new'
      );
    }
  },
  /**
   * Makes sure the outcome at least has a name
   */
  isOutcomeValid: computed('currentModel.name', function () {
    const outcome = this.currentModel;
    return outcome && !isBlank(get(outcome, 'name'));
  }),

  actions: {
    willTransition(transition) {
      this._super();
      let changeHasBeenMade = false;
      let canTransition = false;
      const outcome = this.currentModel;
      const isNew = outcome && get(outcome, 'isNew');

      if (!isNew && get(outcome, 'hasDirtyAttributes')) {
        changeHasBeenMade = true;
      } else if (isNew) {
        const properties = A([get(outcome, 'name'), get(outcome, 'description'), get(outcome, 'mediaItem')]);

        changeHasBeenMade = properties.filter(prop => !isEmpty(prop)).length;
      }

      if (changeHasBeenMade) {
        const ignoreChanges = window.confirm(
          'Are you sure you want to leave this outcome? Your unsaved changes will be lost.'
        );
        if (ignoreChanges) {
          if (isNew) {
            outcome.destroyRecord();
          } else {
            outcome.rollbackAttributes();
          }
          canTransition = true;
        }
      } else {
        if (isNew) {
          outcome.destroyRecord();
        }
        canTransition = true;
      }

      if (!canTransition) {
        transition.abort();
      }

      return true;
    },
    save() {
      this.save();
    },
    saveAndNew() {
      this.save()
        .then(() => this.newOutcome())
        .catch(e => {
          console.error(e);
        });
    },
    new() {
      this.newOutcome();
    },
    remove() {
      const result = window.confirm('Are you sure you want to remove this outcome?');
      if (result) {
        this.currentModel.destroyRecord().then(() => {
          this.modelFor(
            'organizations.organization.organization-promotions.organization-promotion.setup.quiz.outcomes'
          ).removeObject(this.currentModel);
          // this forces the quiz check status to be recalculated
          this.send('updateCheckboxStatuses');
          this.router.replaceWith(
            'organizations.organization.organization-promotions.organization-promotion.setup.quiz.outcomes.index'
          );
        });
      }
    },
    removeImage() {
      set(this.currentModel, 'mediaItem', null);
      this.currentModel.forceDirty();
    },
    setMediaItem(mediaItem) {
      set(this, 'controller.isAddImageFlyoutOpen', false);
      set(this.currentModel, 'mediaItem', mediaItem);
      this.currentModel.forceDirty();
    },
  },
});
