/* eslint-disable ember/no-get */
import { A } from '@ember/array';
import { computed, get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import flattenDeep from 'lodash/flattenDeep';
import isDate from 'lodash/isDate';
import { dateInRange } from 'partner/utils/validate';

/**
 * First by Property
 * This generates a function that creates a computed property to get the first of an array by a property
 * @method firstByProperty
 * @memberOf Promotion.computed
 * @param {String} property - property name to filter by
 * @param {String} value - property value to filter by
 * @param {String} array - The array holding objects to observe and compute from
 * @returns {Function} - A function that can be used as a computed property in an Ember model or controller
 */
export const firstByProperty = function (property, value, array) {
  return computed(array, function () {
    return get(A(get(this, array)).filterBy(property, value), 'firstObject');
  });
};
/**
 * ContainsAny
 * This generates a function that creates a computed property to find whether any of the passed values exists
 * inside the passed array.
 * @method contains
 * @param {String} values - property values to search for
 * @param {String} array - the array holding the objects or values you want to search
 * @returns {Function} - A function that can be used as a computed property in an Ember model or controller
 */
export const containsAny = function (array, ...values) {
  return computed(array, function () {
    return values.any(value => get(this, array).includes(value));
  });
};
/**
 * Collect grandchildren as if using nested @each. Because you can't do computed.mapBy('formPages.@each.formFields.@each').
 * Note that this will probably break if a Grandchild can be related through more than one Child.
 *
 * @param children {String} - The modelName of the direct Children through which your Grandchildren are related
 * @param grandchildren {String} - The modelName of the Grandchildren you're trying to collect
 * @returns {[]} - Returns an array of Grandchild entities
 * @deprecated - Doesn't work in newer versions of Ember.
 */
export const collectGrandchildren = function (children, grandchildren) {
  return computed(`${children}.@each.${grandchildren}`, function () {
    if (get(this, children)) {
      return flattenDeep(get(this, children).map(child => (get(child, grandchildren) || []).toArray()));
    }
    return;
  });
};

export const fullName = () =>
  computed('firstName', 'middleName', 'lastName', function () {
    const { firstName } = this;
    const { middleName } = this;
    const { lastName } = this;
    const name = `${firstName} ${middleName ? `${middleName} ` : ''}${lastName}`;

    return name.trim();
  });

/**
 * Higher-order function to create a computed property, which...
 * Returns the Matchup which dates fit one of these rules. Check in this order.
 * 1) The current time is between its startDate and its endDate
 * 2) or, if any Matchup's startDate is before today, choose the one that started most recently
 * 3) or, choose the one that will start soonest
 */
export const currentMatchup = () =>
  computed('model.matchups.@each.{startDate,endDate}', 'tock', function () {
    const matchups = get(this, 'model.matchups');
    const now = this.tock;
    if (matchups) {
      // check if now is in between a matchup
      const currentMatchups = matchups.filter(matchup =>
        dateInRange(now, get(matchup, 'startDate'), get(matchup, 'endDate'))
      );
      if (!isEmpty(currentMatchups)) {
        return get(currentMatchups, 'firstObject');
      }

      // check for any close matchups just before now
      const priorMatchups = matchups.filter(matchup => {
        const startDate = get(matchup, 'startDate');
        return isDate(startDate) ? startDate.getTime() <= now.getTime() : false;
      });
      if (!isEmpty(priorMatchups)) {
        return get(priorMatchups, 'lastObject');
      }

      // check for any matchups past now
      const postMatchups = matchups.filter(matchup => {
        const startDate = get(matchup, 'startDate');
        return isDate(startDate) ? startDate.getTime() >= now.getTime() : false;
      });
      if (!isEmpty(postMatchups)) {
        return get(postMatchups, 'firstObject');
      }
    }
    return null;
  });

export default {
  firstByProperty,
  containsAny,
  collectGrandchildren,
  fullName,
  currentMatchup,
};
