/* global Redactor */

Redactor.add('plugin', 'fontsize', {
  translations: {
    en: {
      fontsize: {
        title: 'Font Size',
        cancel: 'Cancel',
        setSize: 'Set',
      },
    },
  },
  start() {
    const button = {
      title: '## fontsize.title ##',
      icon: '<i class="material-icons text-lg">text_fields</i>',
      command: 'fontsize.popup',
      position: {
        after: 'italic',
      },
      blocks: {
        all: true,
        except: ['image', 'line', 'noneditable'],
      },
    };

    this.app.toolbar.add('fontsize', button);
    this.app.context.add('fontfamily', button);
  },
  popup(_e, button) {
    this.app.context.close();

    const stack = this.app.create('stack');

    stack.create('fontsize', {
      title: '## fontsize.title ##',
      width: '200px',
      form: {
        size: { type: 'number', min: 8, max: 84, style: 'min-width: 156px' },
      },
      footer: {
        cancel: { title: '## fontsize.cancel ##', command: 'modal.close' },
        save: { title: '## fontsize.setSize ##', command: 'fontsize.save', type: 'primary' },
      },
    });

    const $removeButton = this.createRemoveButton();
    const selection = this.app.create('selection');
    const $inline = selection.getInline();
    const instance = this.app.block.get();

    if (instance) {
      const $block = instance.getBlock();
      this.app.editor.save($block);
    }

    this.app.modal.open({ name: 'fontsize', stack, focus: 'text', button });

    const $input = stack.$form.find('input').get();

    $input.style.minWidth = '156px';
    $input.min = 8;
    $input.max = 84;
    $input.value = 16;

    if (instance) {
      const style = instance.getStyle();

      if (selection.isCollapsed() && style && style['font-size']) {
        $input.value = style['font-size'].slice(0, -2);
      }

      if ($inline.style && $inline.style.fontSize !== '') {
        $input.value = $inline.style.fontSize.slice(0, -2);
      }
    } else {
      $input.value = '';
    }

    stack.$stack.get().appendChild($removeButton);
  },
  createRemoveButton() {
    const $button = document.createElement('div');

    $button.style.margin = '0 18px 18px 18px';
    $button.style.width = 'fit-content';
    $button.style.cursor = 'pointer';
    $button.style.color = 'black';
    $button.textContent = 'Remove Font Size';
    $button.onclick = () => this.remove();

    return $button;
  },
  save(stack) {
    const $input = stack.$form.find('input').get();

    this.app.modal.close();
    this.app.editor.restore();

    const params = { tag: 'span', style: { 'font-size': `${$input.value}px` } };
    const selection = this.app.create('selection');
    const $block = this.app.block.get();

    if (selection.isCollapsed()) {
      $block.setStyle({ 'font-size': `${$input.value}px` });
      return;
    }

    this.app.inline.set(params);
  },
  remove() {
    this.app.modal.close();

    const params = { style: 'font-size' };
    const selection = this.app.create('selection');
    const $block = this.app.block.get();

    if (selection.isCollapsed()) {
      $block.setStyle({ 'font-size': '' });
      return;
    }

    this.app.inline.remove(params);
  },
});
