/**
 * Find the right matchup placing criteria ID based on the promotion type
 */
export default function (promotionType: string) {
  switch (promotionType) {
    case 'Sweepstakes':
      return 3;
    case 'UGCSweepstakes':
      return 3;
    case 'UGCVoting':
      return 2;
    case 'Ballot':
    case 'VotingBracket':
      return 2;
    default:
      return 3;
  }
}
