import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex justify-center items-center gap-1 text-xs font-semibold\" ...attributes test-count>\n  <span>\n    {{@votingEntry.count}}\n  </span>\n  <span class=\"truncate max-w-full\">\n    {{inflect @votingEntry.count this.title}}\n  </span>\n</div>", {"contents":"<div class=\"flex justify-center items-center gap-1 text-xs font-semibold\" ...attributes test-count>\n  <span>\n    {{@votingEntry.count}}\n  </span>\n  <span class=\"truncate max-w-full\">\n    {{inflect @votingEntry.count this.title}}\n  </span>\n</div>","moduleName":"partner/components/ballot/setup/voting-entry/vote-count.hbs","parseOptions":{"srcName":"partner/components/ballot/setup/voting-entry/vote-count.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type CurrentService from 'partner/services/current';
import type VotingEntryModel from 'partner/models/voting-entry';

export interface BallotSetupVotingEntryVoteCountSignature {
  Element: HTMLDivElement;
  Args: {
    votingEntry: VotingEntryModel;
  };
}

export default class BallotSetupVotingEntryVoteCountComponent extends Component<BallotSetupVotingEntryVoteCountSignature> {
  @service declare current: CurrentService;

  get hasWriteIns(): boolean {
    return this.current.promotion.isVotingBallot;
  }

  get title() {
    if (this.hasWriteIns) {
      if (this.args.votingEntry.nominationEntriesSummaries.length && this.args.votingEntry.statusType === 'Submitted') {
        return 'Write-in Vote';
      }

      return 'Vote';
    }

    return 'Nomination';
  }
}
