import ApplicationSerializer from 'partner/serializers/application';

export default class EntryLocationSerializer extends ApplicationSerializer {
  normalize(modelClass: any, resourceHash: any) {
    const resourceHashWithDefaults = {
      google_place_id: null,
      ...resourceHash,
    };

    return super.normalize(modelClass, resourceHashWithDefaults);
  }
}
