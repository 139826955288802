/* eslint-disable ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash */
import Component from '@ember/component';
import { computed, get, observer, set } from '@ember/object';
import { alias, readOnly, sort } from '@ember/object/computed';
import { debounce } from '@ember/runloop';
import { isBlank } from '@ember/utils';

export default Component.extend({
  //region Properties
  itemSorting: computed(() => ['name']),
  /**
   * Default page number
   * @type {Number}
   */
  page: 1,
  /**
   * Default number of items shown per page
   * @type {Number}
   */
  perPage: 100,
  searchInput: '',
  search: '',
  //endregion

  //region Computed Properties
  topLevelItems: computed('all-items.[]', function () {
    const items = this['all-items'].filterBy('tier', 1);

    if (items.length > 0) {
      return items;
    }
    return this['all-items'];
  }),
  /**
   * True if all available records are currently displayed in the list
   * @type {Ember.ComputedProperty}
   * @return {Boolean}
   */
  hideShowMore: computed('totalRecords', 'visibleSortedTopLevelItems.length', 'visibleItems', 'search', function () {
    if (this.search) {
      return this.visibleItems > get(this, 'visibleSortedTopLevelItems.length');
    }
    return this.totalRecords === get(this, 'visibleSortedTopLevelItems.length');
  }),
  sortedTopLevelItems: sort('topLevelItems', 'itemSorting'),
  /**
   *
   */
  visibleSortedTopLevelItems: computed('useLoadMore', 'visibleItems', 'sortedTopLevelItems', 'search', function () {
    const search = this.search.toLowerCase();
    let items;

    if (isBlank(search)) {
      items = this.sortedTopLevelItems;
    } else {
      items = this.sortedTopLevelItems
        .map(function (item) {
          return this._isFilterMatch(search, item) ? item : undefined;
        }, this)
        .compact();
    }

    if (this.useLoadMore) {
      return items.slice(0, this.visibleItems);
    }
    return items;
  }),
  /**
   * The total number of items in the collection displayed in the model
   */
  totalRecords: readOnly('topLevelItems.length'),
  /**
   * The total of number items currently visible
   */
  visibleItems: alias('perPage'),
  /**
   * The currently displayed modal title
   */
  title: computed('search', 'searchingHeader', 'header', function () {
    if (isBlank(this.search)) {
      return this.header;
    }
    return this.searchingHeader || 'Select Results To Filter By';
  }),
  //endregion

  //region Observers
  /**
   * Debounce updating the search input used for filtering,
   * since typing speeds can cause lag
   * @type {Ember.Observer}
   */
  searchInputChanged: observer('searchInput', function () {
    debounce(this, this._updateSearch, 250);
  }),
  /**
   * Clear the search input when the component unloads all items
   */
  clearSearchInput: observer('all-items', function () {
    set(this, 'searchInput', '');
    set(this, 'search', '');
  }),
  //endregion

  //region Methods
  /**
   * @private
   */
  _updateSearch() {
    if (!this.isDestroyed) {
      set(this, 'search', this.searchInput);
    }
  },
  /**
   * Returns true if the name of the item matches the search input
   * @private
   */
  _isFilterMatch(search, item) {
    return get(item, 'name') && get(item, 'name').toLowerCase().indexOf(search) > -1;
  },
  //endregion

  //region Actions
  actions: {
    itemSelected(item) {
      this['selected-action'](item);
    },
  },
});
