/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import flattenDeep from 'lodash/flattenDeep';
import take from 'lodash/take';
import times from 'lodash/times';
import { Promise } from 'rsvp';

const NUM_TURNKEYS = 3;

export default Component.extend({
  //region Dependencies
  store: service(),
  features: service(),
  settings: service(),
  //endregion

  //region Attributes
  organization: null,

  closed() {},

  isAdminUser: false,
  //endregion

  //region Ember Hooks
  didReceiveAttrs() {
    this._super(...arguments);
    this.loadTurnkeyCategories();
  },
  //endregion

  //region Properties

  _isLoading: false,

  _dipsUrl: null,

  _skeletonTurnkeys: times(NUM_TURNKEYS),

  _isTurnkeyError: false,

  _promotionPresets: null,

  _presetsSorting: ['isTrending:desc', 'isFeatured:desc', 'dateModified:desc'], // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
  //endregion

  //region Computed Properties
  _sortedPresets: sort('_promotionPresets', '_presetsSorting'),

  _filteredPresets: computed('_sortedPresets', 'type', function () {
    return this._sortedPresets.filterBy(this.type);
  }),

  _turnkeys: computed('organization.isChain', '_filteredPresets', function () {
    const presets = this._filteredPresets;
    return take(
      get(this, 'organization.isChain') ? presets.reject(x => get(x, 'legacyContestId')) : presets,
      NUM_TURNKEYS
    );
  }),

  contestCreationDisabled: computed('organization.hasContests', 'isAdminUser', function () {
    return !this.organization.hasContests && !this.isAdminUser;
  }),
  //endregion

  //region Methods
  async loadTurnkeyCategories() {
    if (isEmpty(this._promotionPresets)) {
      set(this, '_isLoading', true);
      try {
        const turnkeyCategoriesPromise = this.store.query('turnkey-category', {
          organizationId: get(this, 'organization.id'),
          isDefault: false,
        });

        const promotionPresets = await Promise.all(
          (await turnkeyCategoriesPromise).map(cat => get(cat, 'promotionPresets'))
        );
        this.settings.preload('dips_url').then(() => set(this, '_dipsUrl', this.settings.getValueFor('dips_url')));
        set(this, '_promotionPresets', flattenDeep(promotionPresets.map(x => x.toArray())).uniq());
      } catch (err) {
        set(this, '_isTurnkeyError', true);
      }
      set(this, '_isLoading', false);
    }
  },
  //endregion
});
