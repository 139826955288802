/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

/**
 * @typedef {DS.Model} MessageCampaignAudience
 */
export default BaseModel.extend({
  //region Properties
  /**
   * @type {Boolean}
   */
  isExcluded: attr('boolean', { defaultValue: false }),
  //endregion

  //region Relationships
  /**
   * @type {MessageCampaign}
   */
  messageCampaign: belongsTo('message-campaign', {
    async: false,
  }),
  /**
   * @type {Audience}
   */
  audience: belongsTo('audience', {
    async: true,
  }),
  //endregion
});
