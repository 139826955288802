import { ParamsType } from 'partner/services/preview';
import { header } from './common';
const text = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`;
export const photo = ({ id, width, height }: { id: string; width?: number; height?: number; isVideo?: boolean }) => {
  return {
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'image-2',
        uniqueTypeNumber: '2',
        deletable: true,
        type: 'promotion-image',
        blocks: [],
        data: {
          src: `https://media.secondstreetapp.com/${6542562}?width=107&height=80&cropmode=fit&anchor=center&quality=60&format=auto`,
          alt: '',
          url: '',
          target: '_blank',
          additionalCss: '.arc-promotion-promotion-0 .arc-image-image-2 {\n     \n  }\n  \n  ',
        },
        styles: {
          size: {
            custom: true,
            width: '40',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-${id} {\n  ${
        width ? 'aspect-ratio: 1/1; margin: -72px auto;' : 'aspect-ratio: 16/9;'
      } \n  }\n  \n  `,
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
      },
      size: {
        custom: width || height,
        width,
        height,
      },
      border: {
        radius: '4',
        style: width ? 'solid' : 'none',
        color: '#bfc6ce',
        width: 1,
      },
      background: {
        type: 'color',
        color: width ? '#ffffff' : '#f1f3f3',
        opacity: 100,
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

export default (params?: ParamsType) => [
  header({
    title: 'Already Voted?',
    promotionColor: params?.promotionColor,
    promotionTextColor: params?.promotionTextColor,
  }),
  photo({ id: '1' }),
  photo({ id: '2', width: 152, height: 152 }),
  {
    uniqueTypeNumber: '3',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        uniqueTypeNumber: '1',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: 'Entrant Name',
          additionalCss: ``,
        },
        styles: {
          font: {
            color: '#000000',
            weight: 700,
            family: 'Open Sans',
            size: '48',
          },
          textShadow: {
            x: 0,
            y: 0,
            blur: 6,
            color: '#212121',
            hasShadow: true,
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      {
        uniqueTypeNumber: '6',
        deletable: true,
        type: 'promotion-box',
        blocks: [
          {
            id: 'button-2',
            uniqueTypeNumber: '2',
            deletable: true,
            type: 'promotion-button',
            blocks: [],
            data: {
              href: '',
              text: `<i class="material-icons text-lg">done</i> VOTE`,
              target: '_blank',
              url: '',
              additionalCss:
                '.arc-promotion-promotion-0 .arc-promotion-button-promotion-button-2 span {\n  display: flex;\n     align-items: center;\n     gap: 4px;\n  }\n  \n  ',
            },
            styles: {
              border: {
                radius: 4,
              },
              spacing: {
                paddingBlock: 10,
                paddingInline: 32,
              },
              background: {
                type: 'color',
                color: '#4caf50',
              },
              font: {
                color: '#ffffff',
                align: 'center',
                family: 'Open Sans',
                weight: 400,
                size: '14',
                height: '20',
                spacing: '1.25',
              },
              size: {
                custom: true,
                height: '36',
                width: '',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
          {
            id: 'button-1',
            uniqueTypeNumber: '1',
            deletable: true,
            type: 'promotion-button',
            blocks: [],
            data: {
              href: '',
              text: `<i class="material-icons text-lg">share</i> SHARE`,
              target: '_blank',
              url: '',
              additionalCss:
                '.arc-promotion-promotion-0 .arc-promotion-button-promotion-button-1 span {\n  display: flex;\n     align-items: center;\n     gap: 4px;\n  }\n  \n  ',
            },
            styles: {
              border: {
                radius: 4,
                style: 'solid',
                width: 1,
                color: '#4caf50',
              },
              spacing: {
                paddingBlock: 10,
                paddingInline: 32,
              },
              background: {},
              font: {
                color: '#4caf50',
                align: 'center',
                family: 'Open Sans',
                weight: 400,
                size: '14',
                height: '20',
                spacing: '1.25',
              },
              size: {
                custom: true,
                height: '36',
                width: '',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
        ],
        data: {
          additionalCss: '.arc-promotion-promotion-0 .arc-box-box-6 {\n   \n  }\n  \n  ',
        },
        styles: {
          layout: {
            display: 'flex',
            direction: 'row',
            align: 'center',
            justify: 'center',
            gapX: 20,
            gapY: 20,
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss:
        '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-3 {\n padding: 80px 16px 32px 16px;   \n  }\n  \n  ',
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
        gapX: 16,
        gapY: 16,
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  },
  {
    uniqueTypeNumber: '4',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        uniqueTypeNumber: '11',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: 'Description',
          additionalCss: ``,
        },
        styles: {
          font: {
            color: '#000000',
            weight: 700,
            family: 'Open Sans',
            size: '14',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      {
        uniqueTypeNumber: '12',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text,
          additionalCss: ``,
        },
        styles: {
          font: {
            color: '#6b7786',
            weight: 400,
            family: 'Open Sans',
            size: '14',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss:
        '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-4 {\n width:auto; padding: 20px 0; margin: 0 16px; border-top: 1px solid #bfc6ce; border-bottom: 1px solid #bfc6ce;   \n  }\n  \n  ',
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        gapX: 4,
        gapY: 4,
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  },
];
