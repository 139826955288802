import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import findSuggestedMerges from 'partner/utils/find-suggested-merges';
import { preferredNominationMergeItemOrder } from 'partner/utils/merge-items';
import { isPresent } from '@ember/utils';
import VotingEntryModel from 'partner/models/voting-entry';

type Suggestion = {
  name?: string;
  totalCount?: number;
  votingEntries: VotingEntryModel[];
};

const getCount = (votingEntries: VotingEntryModel[]): number => {
  return votingEntries.reduce((partialSum: number, { count }: { count: number }) => {
    return count + partialSum;
  }, 0);
};

export default class MergeSuggestionsService extends Service {
  @tracked _suggestions: Suggestion[] = [];

  generateSuggestions(votingEntries: VotingEntryModel[]) {
    const result = findSuggestedMerges(
      votingEntries.rejectBy('isRejected').map((votingEntry: VotingEntryModel) => [votingEntry, votingEntry.name])
    ) as VotingEntryModel[][];

    this._suggestions = result.map((group: VotingEntryModel[]) => {
      const match: VotingEntryModel = preferredNominationMergeItemOrder(group).firstObject;

      return {
        name: match.name,
        totalCount: getCount(group),
        votingEntries: group.map((votingEntry: VotingEntryModel) => votingEntry),
      };
    });
  }

  get suggestions(): Suggestion[] {
    return this._suggestions;
  }

  excludeSuggestion(votingEntry: VotingEntryModel) {
    this._suggestions = this._suggestions
      .map((group: Suggestion) => ({
        ...group,
        votingEntries: group.votingEntries.rejectBy('id', votingEntry.id),
        totalCount: getCount(group.votingEntries.rejectBy('id', votingEntry.id)),
      }))
      .reject((group: Suggestion) => group.votingEntries.length === 1);
  }

  modifyName(suggestionGroup: Suggestion, name: string) {
    this._suggestions = this._suggestions.map((group: Suggestion) => ({
      ...group,
      name: suggestionGroup == group ? name : group.name,
    }));
  }

  get areAnyOfSuggestionsAllPending(): boolean {
    return isPresent(
      (this._suggestions || []).filter((itemGroup: Suggestion) =>
        itemGroup.votingEntries.every((item: VotingEntryModel) => item.isSubmitted)
      )
    );
  }

  resetSuggestions(): void {
    this._suggestions = [];
  }
}
