import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import type { default as SettingsService, SettingsKey } from 'partner/services/settings';

export default class SettingsValueForHelper extends Helper {
  @service declare settings: SettingsService;

  compute([key]: [SettingsKey]) {
    return this.settings.getValueFor(key);
  }
}
