import Helper from '@ember/component/helper';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CurrentHelper extends Helper {
  @service('current') current;

  compute([key]) {
    return get(this.current, key);
  }
}
