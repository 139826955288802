import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<MdcTextfield\n  @id=\"2fa-code-input\"\n  @class=\"two-factor-code-input mdc-up-text-field\"\n  @label=\"Enter Code\"\n  @type=\"text\"\n  @inputmode=\"numeric\"\n  @pattern=\"[0-9]*\"\n  @autocomplete=\"one-time-code\"\n  @required={{true}}\n  @value={{this.code}}\n  @onchange={{this.formChanged}}\n  @helptext={{this.codeValidation}}\n  @helptext-persistent={{this.isCodeInvalid}}\n  @helptext-validation-msg={{this.isCodeInvalid}}\n  @valid={{not this.isCodeInvalid}}\n  @disabled={{this.isSubmitting}}\n  {{did-insert this.focusField}}\n/>\n{{#if this.isSubmitting}}\n  <i class=\"ssIcon ssIcon-spinner ssIcon-spin\"></i>\n  <span class=\"help-text\">Verifying&hellip;</span>\n  <br>\n{{/if}}\n", {"contents":"<MdcTextfield\n  @id=\"2fa-code-input\"\n  @class=\"two-factor-code-input mdc-up-text-field\"\n  @label=\"Enter Code\"\n  @type=\"text\"\n  @inputmode=\"numeric\"\n  @pattern=\"[0-9]*\"\n  @autocomplete=\"one-time-code\"\n  @required={{true}}\n  @value={{this.code}}\n  @onchange={{this.formChanged}}\n  @helptext={{this.codeValidation}}\n  @helptext-persistent={{this.isCodeInvalid}}\n  @helptext-validation-msg={{this.isCodeInvalid}}\n  @valid={{not this.isCodeInvalid}}\n  @disabled={{this.isSubmitting}}\n  {{did-insert this.focusField}}\n/>\n{{#if this.isSubmitting}}\n  <i class=\"ssIcon ssIcon-spinner ssIcon-spin\"></i>\n  <span class=\"help-text\">Verifying&hellip;</span>\n  <br>\n{{/if}}\n","moduleName":"partner/components/two-factor-code-input.hbs","parseOptions":{"srcName":"partner/components/two-factor-code-input.hbs"}});
/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class TwoFactorCodeInputComponent extends Component {
  //region Properties
  @tracked _code = '';
  @tracked submittedCode = '';
  @tracked isSubmitting = false;
  @tracked twoFaInputEl = '';
  //endregion

  //region Computed Properties
  get code() {
    return this._code;
  }
  get isCodeInvalid() {
    return this.code === this.submittedCode && isPresent(this.args.errors);
  }
  get codeValidation() {
    return this.isCodeInvalid ? this.args.errors?.firstObject?.message : null;
  }
  //endregion

  //region Actions
  @action
  async formChanged(input) {
    this._code = input;
    // clear input cache
    if (input.length === 6) {
      this.isSubmitting = true;
      await this.args.formChanged(this.code);
      this._code = '';
      this.twoFaInputEl.focus();
      this.isSubmitting = false;
    }
  }
  @action
  focusField() {
    this.twoFaInputEl = document.getElementById('2fa-code-input').querySelector('input');
    this.twoFaInputEl.focus();
  }
  //endregion
}
