import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import { isEmpty } from '@ember/utils';
import PromotionTypeRoute from 'partner/-base/promotion-type-route';
import type CurrentService from 'partner/services/current';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import type MatchupGroupModel from 'partner/models/matchup-group';
import type SettingsService from 'partner/services/settings';
import type SetupBallotController from 'partner/controllers/organizations/organization/organization-promotions/organization-promotion/setup/ballot';
import type Store from '@ember-data/store';
import type { ModelFrom } from 'partner/utils/types';

export type Model = ModelFrom<BallotSetupRoute>;

export default class BallotSetupRoute extends PromotionTypeRoute {
  @service declare current: CurrentService;
  @service declare enums: EnumsService;
  @service declare router: RouterService;
  @service declare settings: SettingsService;
  @service declare store: Store;

  declare controller: SetupBallotController;

  queryParams = {
    matchupGroupId: { refreshModel: false },
    matchupId: { refreshModel: false },
  };

  async model() {
    const { matchups, matchupGroups } = await RSVP.hash({
      matchups: this.store.query('matchup', { excludeSecondaryMatchups: true }),
      matchupGroups: this.store.query('matchup-group', {}),
      _dipsUrl: this.settings.preload('dips_url'),
      _categorySortCriteria: this.settings.preload('category_sort_criteria', { force: true }),
    });

    return RSVP.hash({
      matchups: matchups.toArray(),
      matchupGroups: matchupGroups.toArray(),
      firstMatchupEntries:
        // Load only the matchup entries for the first matchup, to check if this is a simple ballot
        matchups.length == 1 && isEmpty(matchupGroups)
          ? this.store.query('matchup-entry', {
              matchupId: matchups.firstObject?.id,
              statusTypeId: this.enums.findWhere('STATUS_TYPE', { name: 'Active' }),
            })
          : [],
    });
  }

  afterModel(model: Model) {
    void this.preloadMatchupEntriesCount(model.matchups);
  }

  redirect() {
    // It is still technically possible to URL hack to this route for non-ballot promotions. We should
    // bounce the user back to the dashboard if this ever happens
    if (!this.current.organizationPromotion.promotion.isBallot) {
      void this.router.replaceWith(
        'organizations.organization.organization-promotions.organization-promotion.dashboard'
      );
    }
  }

  resetController(controller: SetupBallotController, isExiting: boolean) {
    if (isExiting) {
      controller.selectedBallotStyle = null;
    }
  }

  @action
  reload() {
    this.refresh();
  }

  @action
  handleMatchupGroupCreation(matchupGroup: MatchupGroupModel) {
    this.controller.model = {
      ...this.controller.model,
      matchupGroups: [...this.controller.model.matchupGroups, matchupGroup],
    };
    this.controller.selectedBallotStyle = null;
  }

  @action
  handleMatchupEntryCreation() {
    this.controller.selectedBallotStyle = null;
  }

  private async preloadMatchupEntriesCount(matchups: Model['matchups']) {
    await this.store.query('ballot-matchup-summary', {});

    matchups.forEach(matchup => {
      matchup.matchupEntriesCount = this.store.peekRecord('ballot-matchup-summary', matchup.id)?.approvedCount || 0;
    });
  }
}
