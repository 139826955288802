/* eslint-disable ember/no-mixins, ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import MediaItemRelationshipSerializer from 'partner/mixins/media-item-relationship-serializer';
import ApplicationSerializer from 'partner/serializers/application';

export default ApplicationSerializer.extend(MediaItemRelationshipSerializer, DS.EmbeddedRecordsMixin, {
  attrs: {
    mediaItems: { embedded: 'always' },
  },
});
