import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import enums from 'ember-cli-ss-enums/services/enums';
import RSVP from 'rsvp';

const ADESTRA_ORGANIZATION_ATTRIBUTE_TYPE_ID = enums.findWhere(
  'ORGANIZATION_ATTRIBUTE_TYPE',
  { name: 'HasAdestraIntegration' },
  'id'
);

const errorMessages = Object.freeze({
  deleted: 'This field no longer exists in Adestra and the data sync is failing. Please update your field mapping.',
});

const SsFields = [
  { name: 'Email', ssField: 'EmailAddress' },
  { name: 'First Name', ssField: 'FirstName' },
  { name: 'Last Name', ssField: 'LastName' },
  { name: 'Address 1', ssField: 'Address1' },
  { name: 'Address 2', ssField: 'Address2' },
  { name: 'City', ssField: 'City' },
  { name: 'State', ssField: 'State' },
  { name: 'Postal Code', ssField: 'PostalCode' },
  { name: 'Gender', ssField: 'Gender' },
  { name: 'Birthdate', ssField: 'BirthDate' },
  { name: 'Phone', ssField: 'Phone' },
];

class FieldMap {
  @tracked adestraName;
  constructor(name, ssName, adestraName, errorCode) {
    // Display Name
    this.name = name;
    this.ssName = ssName;
    this.adestraName = adestraName;
    this.originalAdestraName = adestraName;
    this.errorMsg = errorMessages[errorCode];
  }

  get isDirty() {
    return this.originalAdestraName !== this.adestraName;
  }
}

export default class OrganizationsOrganizationAdestraConfigureFieldsRoute extends Route {
  @service('store') store;

  async model() {
    const { organization_id: organizationId } = this.paramsFor('organizations.organization');
    const { adestraFields, organizationAttribute } = await RSVP.hash({
      adestraFields: this.store.findAll('adestraCoretableColumn'),
      organizationAttribute: this.store
        .query('organizationAttribute', {
          organizationId,
          organizationAttributeTypeId: ADESTRA_ORGANIZATION_ATTRIBUTE_TYPE_ID,
        })
        .then(data => data.firstObject),
    });
    return {
      organizationAttribute,
      fieldsMap: organizationAttribute && this.getFieldsMap(organizationAttribute.attributeValueMap),
      adestraFields,
    };
  }

  getFieldsMap(attributeValueMap) {
    const fieldsMap = attributeValueMap.fields_map;
    return SsFields.map(({ name, ssField }) => {
      const mappedField = fieldsMap?.find(field => field.internal_system_field_name === ssField);
      return new FieldMap(name, ssField, mappedField?.external_service_field_name, mappedField?.error_code);
    });
  }
}
