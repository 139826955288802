/* eslint-disable @typescript-eslint/restrict-template-expressions */
import Service, { inject as service } from '@ember/service';
import SessionService from './-private/session';
import CurrentService from './current';
import { loadScript } from 'secondstreet-common/utils/load-script';
import ENV from 'partner/config/environment';

const EXCLUDED_ORGANIZATION_IDS = [
  95191, // DoorCountyDailyNews.com
  2083148, // The Villages Daily Sun
];

export default class AppcuesService extends Service {
  @service declare current: CurrentService;
  @service declare session: SessionService;

  _mock: typeof window.Appcues;

  async setup() {
    try {
      await this.attachAppcues();
      await this.identify(this.session.data?.authenticated?.organization_users);
    } catch (e) {
      console.error(e);
    }
  }

  track(event: string, properties?: any) {
    return this.withAppcues(appcues => appcues.track(event, properties));
  }

  reset() {
    return this.withAppcues(appcues => appcues.reset());
  }

  identify(user: any, additionalProperties = {}) {
    if (!user) return;

    try {
      const name =
        !user.first_name && !user.last_name
          ? 'Unknown'
          : `${user.first_name ?? '******'} ${user.last_name ?? '*********'}`;

      if (this.shouldNotBeTracked(user.organization_id)) return;

      return this.withAppcues(appcues => {
        appcues.identify(`SS-${user.id}`, {
          name,
          email_address: user.email_address,
          organization: `SS-${user.organization_id}`,
          user_created: new Date(user.date_created).valueOf(),
          last_activity_date: new Date(user.last_activity_date).valueOf(),
          ...additionalProperties,
        });
      });
    } catch (e) {
      console.error(e);
    }

    return Promise.resolve();
  }

  private withAppcues(callback: (appcues: NonNullable<typeof window.Appcues>) => void) {
    if (this.shouldNotBeTracked(this.session.data?.authenticated?.organization_id)) return;
    if (this.shouldNotBeTracked(this.current.organization?.id)) return;

    if (ENV.environment != 'production') {
      if (this._mock) callback(this._mock);
      return;
    }

    if (!window.Appcues) return;

    try {
      callback(window.Appcues);
    } catch (e) {
      console.error(e);
    }
  }

  private shouldNotBeTracked(organizationId?: number | string) {
    if (!organizationId) return false;

    return EXCLUDED_ORGANIZATION_IDS.includes(Number(organizationId));
  }

  private attachAppcues() {
    if (ENV.environment != 'production') return;

    window.AppcuesSettings = {
      enableURLDetection: true,
    };

    return loadScript('https://fast.appcues.com/121185.js');
  }
}
