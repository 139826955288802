/**
 * @param {MessageVersionFeed} messageVersionFeed
 * @param {MessageVersion} messageVersion
 * @param {MessageVersionHistory} messageVersionHistory
 * @param {Token} token
 * @returns {Function} - passed into a function mapping over RSS items
 */
export default (messageVersionFeed, messageVersion, messageVersionHistory, token) =>
  /**
   * @param {Object} rssItem - an item pulled in from the source RSS feed with description, linkUrl, title, imageUrl, and (sometimes) content properties
   * @returns {Object} - an object with properties that an Item-type Message Version Template Token Content is expected to have
   */
  rssItem => ({
    messageVersion,
    messageVersionHistory,
    token,
    messageVersionFeedId: messageVersionFeed.id,
    isDisabled: false,
    value: messageVersionFeed.isDescriptionEnabled
      ? messageVersionFeed.articleContentType === 'Article Preview'
        ? rssItem.description
        : messageVersionFeed.articleContentType === 'Full Article'
        ? rssItem.content
        : null
      : null,
    itemButtonLinkUrl: messageVersionFeed.isButtonEnabled ? rssItem.linkUrl : null,
    itemButtonText: messageVersionFeed.isButtonEnabled ? messageVersionFeed.buttonText : null,
    itemTitle: messageVersionFeed.isHeadlineEnabled ? rssItem.title : null,
    linkUrl: messageVersionFeed.isHeadlineEnabled ? rssItem.linkUrl : null,
    itemImageLinkUrl: messageVersionFeed.isImageEnabled ? rssItem.linkUrl : null,
    altText: messageVersionFeed.isImageEnabled ? rssItem.title : null,
    itemImageExternalSrcUrl: messageVersionFeed.isImageEnabled ? rssItem.imageUrl : null,
    itemPublishDate: messageVersionFeed.isPublishDateEnabled ? rssItem.publishDate : null,
    mediaItemId: null,
  });
