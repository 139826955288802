import Application from '@ember/application';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';
import * as Sentry from '@sentry/ember';

// Helps TypeScript find the module.
// https://github.com/ember-polyfills/ember-cached-decorator-polyfill#typescript-usage
import 'ember-cached-decorator-polyfill';

import config from './config/environment';

if (config.SENTRY_DSN) {
  try {
    const environment =
      window.location.host == 'partners.secondstreet.com'
        ? 'production'
        : window.location.host.includes('partners-qa')
        ? 'staging'
        : 'development';

    Sentry.init({
      dsn: config.SENTRY_DSN,
      environment,
      release: `partner@${config.SENTRY_RELEASE}`,
      tracesSampleRate: environment == 'production' ? 0.05 : 0.5,

      beforeBreadcrumb(breadcrumb) {
        if (breadcrumb.category == 'xhr' && breadcrumb.data.url?.includes('fullstory.com')) {
          return null;
        }

        return breadcrumb;
      },
    });
  } catch (e) {
    console.error(e);
  }
}

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
