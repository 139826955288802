import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  {{did-insert (perform this.fetchTask @matchupId @filters)}}\n  {{did-update (perform this.fetchTask @matchupId @filters)}}\n  ...attributes\n>\n  {{yield this.matchupEntries this.tasks}}\n</div>\n", {"contents":"<div\n  {{did-insert (perform this.fetchTask @matchupId @filters)}}\n  {{did-update (perform this.fetchTask @matchupId @filters)}}\n  ...attributes\n>\n  {{yield this.matchupEntries this.tasks}}\n</div>\n","moduleName":"partner/components/ballot/matchup-entry-manager.hbs","parseOptions":{"srcName":"partner/components/ballot/matchup-entry-manager.hbs"}});
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import isEqual from 'lodash/isEqual';
import type Store from '@ember-data/store';
import type DeliberateConfirmationService from 'partner/services/deliberate-confirmation';
import type SnackbarService from 'secondstreet-common/services/snackbar';
import type MatchupEntryModel from 'partner/models/matchup-entry';
import type { TaskForAsyncTaskFunction } from 'ember-concurrency';

type Tasks = {
  fetch: TaskForAsyncTaskFunction<{ restartable: boolean }, (matchupId: string, filters?: Filters) => Promise<void>>;
  reorder: TaskForAsyncTaskFunction<{ drop: boolean }, (matchupEntries: MatchupEntryModel[]) => Promise<void>>;
  remove: TaskForAsyncTaskFunction<{ drop: boolean }, (matchupEntry: MatchupEntryModel) => Promise<void>>;
};

type Filters = Pick<Partial<MatchupEntryModel>, 'statusTypeId'>;

interface BallotMatchupEntryManagerSignature {
  Element: HTMLDivElement;
  Args: {
    matchupId: number | string;
    filters?: Filters;
    skipFetch?: boolean;
  };
  Blocks: {
    default: [matchupEntries: MatchupEntryModel[], tasks: Tasks];
  };
}

export default class BallotMatchupEntryManagerComponent extends Component<BallotMatchupEntryManagerSignature> {
  @service declare deliberateConfirmation: DeliberateConfirmationService;
  @service declare snackbar: SnackbarService;
  @service declare store: Store;

  private previousQueryParams?: Record<string, unknown>;

  @cached
  get matchupEntries(): MatchupEntryModel[] {
    const filters = this.args.filters || {};
    const filterKeys = Object.keys(filters) as Array<keyof Filters>;

    return this.store
      .peekAll('matchup-entry')
      .filter(
        (matchupEntry: MatchupEntryModel) =>
          matchupEntry.matchupId == this.args.matchupId &&
          !matchupEntry.isNew &&
          filterKeys.every(key => filters[key] == matchupEntry[key])
      );
  }

  get tasks(): Tasks {
    return {
      fetch: this.fetchTask,
      reorder: this.reorderTask,
      remove: this.removeTask,
    };
  }

  fetchTask = task({ keepLatest: true }, async (matchupId: string, filters: Filters) => {
    const queryParams = { matchupId, ...filters };

    if (this.args.skipFetch || isEqual(this.previousQueryParams, queryParams)) return;

    await this.store.query('matchup-entry', queryParams);

    this.previousQueryParams = queryParams;
  });

  reorderTask = task({ keepLatest: true }, async (matchupEntries: MatchupEntryModel[]) => {
    matchupEntries.forEach((matchupEntry, index) => {
      matchupEntry.displayOrder = index + 1;
    });

    try {
      await Promise.all(matchupEntries.filterBy('hasDirtyAttributes').map(matchupEntry => matchupEntry.save()));
    } catch (e) {
      this.snackbar.exception(e, { ignoreInvalidError: false });
    }
  });

  removeTask = task({ drop: true }, async (matchupEntry: MatchupEntryModel) => {
    const message = `Are you sure you want to remove ${
      matchupEntry.mediaTitle?.value as string
    }? This will delete the entrant and any associated ads.`;
    if (!(await this.deliberateConfirmation.show(message))) return;

    try {
      await matchupEntry.destroyRecord();
    } catch (e) {
      this.snackbar.exception(e);
    }
  });
}
