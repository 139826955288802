import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"entry-form-field\" ...attributes>\n  {{yield this.guid}}\n\n  {{#if @label}}\n    <label for={{this.guid}} class=\"ss-material-label\" data-test-form-field-label>\n      {{@label}}\n    </label>\n  {{/if}}\n</div>\n", {"contents":"<div class=\"entry-form-field\" ...attributes>\n  {{yield this.guid}}\n\n  {{#if @label}}\n    <label for={{this.guid}} class=\"ss-material-label\" data-test-form-field-label>\n      {{@label}}\n    </label>\n  {{/if}}\n</div>\n","moduleName":"partner/components/entry-form/field/wrapper.hbs","parseOptions":{"srcName":"partner/components/entry-form/field/wrapper.hbs"}});
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

export default class EntryFormFieldWrapperComponent extends Component {
  get guid() {
    return guidFor(this);
  }
}
