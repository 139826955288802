/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/use-ember-get-and-set */ // FIXME
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import InMemoryPaging from 'partner/mixins/in-memory-paging';

export default Controller.extend(InMemoryPaging, {
  //region Properties
  showTagged: false,
  //endregion

  //region Computed Properties
  showUnTaggedPaging: computed('untaggedEntities.length', function () {
    return this.pageSize < this.get('untaggedEntities.length');
  }),
  untaggedPaging: computed('untaggedEntities', function () {
    return this.createPagingObject(this.get('untaggedEntities.length'), this.pageSize);
  }),
  showTaggedPaging: computed('taggedEntities.length', function () {
    return this.pageSize < this.get('taggedEntities.length');
  }),
  taggedPaging: computed('taggedEntities', function () {
    return this.createPagingObject(this.get('taggedEntities.length'), this.pageSize);
  }),
  pagedUntaggedEntities: computed('untaggedPaging.pageIndex', function () {
    const paging = this.untaggedPaging;
    const entities = this.untaggedEntities;
    return this.getEntitiesByPage(paging, entities);
  }),
  /**
   * Don't recompute this because it would be jarring to move them as tags were added
   */
  untaggedEntities: computed('model', function () {
    const taggableEntities = this.get('model.taggableEntities');
    return taggableEntities
      .filter(taggableEntity => !taggableEntity.get('entityInterestTags.length'))
      .sort(this.sortTaggableEntities);
  }),
  pagedTaggedEntities: computed('taggedPaging.pageIndex', function () {
    const paging = this.taggedPaging;
    const entities = this.taggedEntities;
    return this.getEntitiesByPage(paging, entities);
  }),
  /**
   * Don't recompute this because it would be jarring to move them as tags were added
   */
  taggedEntities: computed('model', function () {
    const taggableEntities = this.get('model.taggableEntities');
    return taggableEntities
      .filter(taggableEntity => taggableEntity.get('entityInterestTags.length'))
      .sort(this.sortTaggableEntities);
  }),
  //endregion

  //region Methods
  /**
   * Note for this sort we want the higher count to be higher in the list and the later date
   * to be higher in the list.
   * @returns {number}
   */
  sortTaggableEntities(a, b) {
    const countDifference = b.get('interactionsCount') - a.get('interactionsCount');
    if (countDifference !== 0) {
      return countDifference;
    }
    return b.get('dateCreated').getTime() - a.get('dateCreated').getTime();
  },
  //endregion

  //region Actions
  actions: {
    toggleShowTagged() {
      this.toggleProperty('showTagged');
    },
    toggleExpansion(item) {
      item.toggleProperty('isExpanded');
    },
    changeUntaggedPage(page) {
      this.untaggedPaging.set('pageIndex', page);
    },
    changeTaggedPage(page) {
      this.taggedPaging.set('pageIndex', page);
    },
  },
  //endregion
});
