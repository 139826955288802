import { get, set } from '@ember/object';

/**
 * Loop over items and update displayOrder based on removedDisplayOrder
 * @param {Object[]} items
 * @param {Number} removedDisplayOrder
 * @param {String} [displayOrderPropertyName]
 * @returns {Object[]} updatedItems
 */
export default function updateDisplayOrder(items, removedDisplayOrder, displayOrderPropertyName = 'displayOrder') {
  //make a copy via slice() to avoid issues of looping while changing a sorting related property
  return items
    .slice()
    .map(item => {
      if (get(item, displayOrderPropertyName) > removedDisplayOrder) {
        set(item, displayOrderPropertyName, get(item, displayOrderPropertyName) - 1);
        return item;
      }
      return null;
    })
    .compact();
}
