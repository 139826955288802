/* eslint-disable ember/no-mixins, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components */
import Component from '@ember/component';
import { isNone } from '@ember/utils';
import PermittedComponent from 'partner/mixins/permitted-component';

/**
 * Contains common logic for add and remove components that need to be aware of permission status.
 * Will likely not ever be used by itself.
 *
 * Mixing in Promotion.PermittedComponent exposes the permission status of the nearest permission block.
 *
 * @see Promotion.PermittedComponent
 * @type {Ember.Component}
 */
export default Component.extend(PermittedComponent, {
  attributeBindings: ['disabled', 'title'],
  classNameBindings: ['disabled:ssDisabled'],
  tagName: 'a',
  param: null,
  param2: null,

  click() {
    if (!this.action) {
      return;
    }
    if (typeof this.action === 'string') {
      console.error(
        `You passed "${this.action}" to <PermittedAction /> as @action rather than passing a function. Try using the {{action}} helper, like {{action "${this.action}"}}.`
      );
      throw new Error();
    }
    if (typeof this.action !== 'function') {
      console.error(
        `You passed a ${typeof this
          .action} to <PermittedAction /> as @action rather than passing a function. Try using the {{action}} helper."`
      );
      throw new Error();
    }
    if (!isNone(this.param) && !isNone(this.param2)) {
      this.action(this.param, this.param2);
    } else if (!isNone(this.param)) {
      this.action(this.param);
    } else {
      this.action();
    }
  },
});
