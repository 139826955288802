/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/* globals jstz */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { next } from '@ember/runloop';
import { inject } from '@ember/service';
import areAttrsChanged from 'partner/utils/are-attrs-changed';

export default Component.extend({
  //region Dependencies
  enums: inject(),
  //endregion

  //region Attributes
  save() {},
  //endregion

  //region Ember Hooks
  /**
   * Don't use a wrapping element so that the {{ss-step}} in the handlebars can be the outermost element.
   */
  tagName: '',
  //endregion

  //region Properties
  timeZone: jstz.determine().name(),
  //endregion

  //region Computed Properties
  isStepComplete: computed('message-campaign.{scheduleStartDate,sendOnConfirm}', function () {
    return !!get(this, 'message-campaign.scheduleStartDate') || !!get(this, 'message-campaign.sendOnConfirm');
  }),
  isStepDirty: areAttrsChanged({
    'message-campaign': ['scheduleStartDate'],
  }),
  //endregion

  //region Methods
  unschedule() {
    set(this, 'message-campaign.scheduleStartDate', null);
    set(this, 'message-campaign.isConfirmed', false);
    set(this, 'message-campaign.singleSchedule.startDate', null);

    // If the message was set to send when a new article is published, reset the scheduleTypeId so it passes API validation
    const recurring = this.enums.findWhere('SCHEDULE_TYPE', { name: 'Recurring' });
    const newFeedItemEvent = this.enums.findWhere('SCHEDULE_TYPE', { name: 'NewFeedItemEvent' });
    if (get(this, 'message-campaign.scheduleTypeId') === newFeedItemEvent) {
      set(this, 'message-campaign.scheduleTypeId', recurring);
    }
  },
  //endregion

  //region Actions
  actions: {
    unschedule() {
      this.unschedule();
    },
    setSendOnConfirm(sendOnConfirm) {
      set(this, 'message-campaign.sendOnConfirm', sendOnConfirm);
      if (sendOnConfirm && get(this, 'message-campaign.scheduleStartDate')) {
        this.unschedule();
      }
      if (!sendOnConfirm) {
        next(() => document.querySelector('.ssDateTimePicker').focus());
      }
    },
    async save() {
      await this.save();
    },
  },
  //endregion
});
