export default `
  <div class="ssUGCGalleryShare !pl-12 !pr-4">
    <ul class="ssShareListCompact ssShareList">
      <li class="ssShareListItem ssShareListItemFacebook">
        <a class="ssShareLink ssShareLinkFacebook" data-token="facebook" href="#">
          <i class="ssIcon-facebook"></i>
          <span class="ssShareLinkText">Share</span>
        </a>
      </li>
      <li class="ssShareListItem ssShareListItemTwitter">
        <a class="ssShareLink ssShareLinkTwitter" data-token="twitter" href="#">
          <i class="ssIcon-twitter"></i>
          <span class="ssShareLinkText">Tweet</span>
        </a>
      </li>
      <li class="ssShareListItem ssShareListItemEmail">
        <a class="ssShareLink ssShareLinkEmail" data-token="email" href="#">
          <i class="ssIcon-envelope"></i>
          <span class="ssShareLinkText">Email</span>
        </a>
      </li>
      <li class="ssShareListItem ssShareListItemPinterest">
        <a class="ssShareLink ssShareLinkPinterest" data-token="pinterest" href="#">
          <i class="ssIcon-pinterest"></i>
          <span class="ssShareLinkText">Pin It</span>
        </a>
      </li>
    </ul>
  </div>
`;
