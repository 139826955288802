/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend({
  targetPageId: DS.attr('number'),
  organizationPromotionId: DS.attr('number'),
  legacyContestId: DS.attr('number'),
  legacyMessageId: DS.attr('number'),
  url: DS.attr('string'),
});
