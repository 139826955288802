import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and this.isLoading (not @forceSkipLoading))}}\n  {{! template-lint-disable no-inline-styles style-concatenation }}\n  <div\n    class=\"flex flex-col rounded bg-white p-4 mdc-elevation-transition mdc-elevation--z2\"\n    style=\"height: {{or @height '320px'}};\"\n    {{on-intersect (perform this.fetchTask) rootMargin=\"32px\" once=true}}\n    ...attributes\n  >\n    {{#if @title}}\n      <div class=\"flex items-center gap-2 text-gray-500\" test-chart-loader-title>\n        {{#if @icon}}\n          <Ui::Icon @name={{@icon}} />\n        {{/if}}\n        <span class=\"text-2xl\">\n          {{@title}}\n        </span>\n      </div>\n    {{/if}}\n\n    <div class=\"flex-auto flex items-center justify-center gap-2 text-gray-500 text-sm p-4\">\n      <Ui::Spinner />\n      Loading...\n    </div>\n  </div>\n{{else}}\n  {{yield}}\n{{/if}}\n", {"contents":"{{#if (and this.isLoading (not @forceSkipLoading))}}\n  {{! template-lint-disable no-inline-styles style-concatenation }}\n  <div\n    class=\"flex flex-col rounded bg-white p-4 mdc-elevation-transition mdc-elevation--z2\"\n    style=\"height: {{or @height '320px'}};\"\n    {{on-intersect (perform this.fetchTask) rootMargin=\"32px\" once=true}}\n    ...attributes\n  >\n    {{#if @title}}\n      <div class=\"flex items-center gap-2 text-gray-500\" test-chart-loader-title>\n        {{#if @icon}}\n          <Ui::Icon @name={{@icon}} />\n        {{/if}}\n        <span class=\"text-2xl\">\n          {{@title}}\n        </span>\n      </div>\n    {{/if}}\n\n    <div class=\"flex-auto flex items-center justify-center gap-2 text-gray-500 text-sm p-4\">\n      <Ui::Spinner />\n      Loading...\n    </div>\n  </div>\n{{else}}\n  {{yield}}\n{{/if}}\n","moduleName":"partner/components/chart/loader.hbs","parseOptions":{"srcName":"partner/components/chart/loader.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import RSVP from 'rsvp';

export default class ChartLoaderComponent extends Component {
  @tracked isLoading = true;

  get charts() {
    return [this.args.chart, this.args.charts].flat().filter(Boolean);
  }

  fetchTask = task({ drop: true }, async () => {
    if (!this.isLoading) return;

    await RSVP.all(this.charts.map(chart => chart.fetchCategories(this.args.params)));

    this.isLoading = false;
  });
}
