/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { isBlank, isPresent } from '@ember/utils';

export default Component.extend({
  //region Attributes
  /**
   * @type {Function}
   * @param {Object<username>} params
   */
  'form-submitted': x => x,

  /**
   * @type {Function}
   */
  'clear-errors': x => x,

  /**
   * Has a reset email been sent to the user?
   * @type {Boolean}
   */
  'reset-email-sent': false,

  /**
   * @type {?APIError[]}
   */
  errors: null,

  /**
   * The username we should _start_ with
   * @type {?String}
   */
  username: null,
  //endregion

  //region Ember Hooks
  classNames: ['login-forgot-page'],

  didReceiveAttrs() {
    this._super();
    if (isBlank(this._username)) {
      set(this, '_username', this.username); // `username` is just a suggestion. Here we take its advice
    }
    set(this, 'hasTextChanged', false); // New attrs mean we might be getting new errors!
  },
  //endregion

  //region Properties
  /**
   * The username _as typed by the user_
   * @type {?String}
   * @private
   */
  _username: null,

  /**
   * @type {Boolean}
   */
  hasTextChanged: false,
  //endregion

  //region Computed Properties
  /**
   * @returns {Boolean}
   */
  isSubmitDisabled: computed('_username', 'errors.[]', 'reset-email-sent', 'hasTextChanged', function () {
    return isBlank(this._username) || (isPresent(this.errors) && !this.hasTextChanged) || this['reset-email-sent'];
  }),
  /**
   * @returns {Boolean}
   */
  isUsernameInvalid: computed('errors.@each.detail', 'hasTextChanged', function () {
    return this.hasTextChanged ? false : isPresent(this.errors);
  }),
  //endregion

  //region Actions
  actions: {
    usernameChanged(value) {
      set(this, '_username', value);
      set(this, 'hasTextChanged', true);
      if (this.errors) {
        this['clear-errors']();
      }
    },

    formSubmitted() {
      return this['form-submitted']({ username: this._username });
    },
  },
  //endregion
});
