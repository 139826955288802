/* eslint-disable prefer-destructuring, ember/jquery-ember-run, ember/no-jquery */
import $ from 'jquery';
import Papa from 'papaparse';

export const CsvToHtmlTable = (options, successCallback = null, noDataCallback = null, pagingCallback = null) => {
  options = options || {};
  const csv_path = options.csv_path || '';
  const el = options.element || 'table-container';

  $.when($.get(csv_path)).then(async data => {
    if (!data) {
      return noDataCallback();
    }

    // Optin Reports include a header row that throws off the entire table generation
    const reportData = options.is_optin_report ? data.replace(/^.*?\r?\n/, '') : data;

    const csvDataObjects = [];
    let csvDataHeaders;

    await Papa.parse(reportData, {
      header: true,
      worker: true,
      skipEmptyLines: true,
      step: results => {
        let allFieldsEmpty = true;
        for (const property in results.data) {
          if (results.data[property].length > 0) {
            allFieldsEmpty = false;
            break;
          }
        }
        if (!allFieldsEmpty) {
          csvDataObjects.addObject(results.data);
          if (!csvDataHeaders) {
            csvDataHeaders = results.meta.fields;
          }
        }
      },
      complete: async () => {
        if (!csvDataObjects.length) {
          return noDataCallback();
        }
        const columnObjects = csvDataHeaders.map(column => ({
          title: column,
          data: column.replace(/\./g, '\\.'), // DataTable breaks unless we escape dots in column names
          render: $.fn.dataTable.render.text(),
          type: column.match(/(?:^|\W)date(?:$|\W)/gi) ? 'date' : 'string',
        }));

        const $table = $(`<table class='ss-table' id='${el}-table'></table>`);
        const $containerElement = $(`#${el}`);
        $containerElement.empty().append($table);

        await $table.DataTable({
          autoWidth: false,
          data: csvDataObjects,
          lengthChange: options.length_change,
          createdRow: row => {
            row.querySelectorAll('td').forEach(td => {
              td.setAttribute('title', td.innerText);
            });
          },
          pageLength: options.page_length,
          pagingType: 'simple',
          columns: columnObjects,
          order: [],
          deferRender: true,
        });

        const updateTableCount = () => {
          successCallback($table.DataTable().page.info().recordsDisplay);
        };

        $table.on('page.dt', () => {
          pagingCallback();
        });

        $table.on('search.dt', () => {
          updateTableCount();
        });

        document.querySelectorAll('#table-container-table th').forEach(th => {
          th.setAttribute('title', th.innerText);
        });

        successCallback(csvDataObjects.length);
      },
    });
  });
};
