/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import Mixin from '@ember/object/mixin';

/**
 * Our API was not returned {mediaItemId: null} when we removed a mediaItem from certain models.
 * This serializer mixin will add that to the payload if the API doesn't include it.
 * Not including the null mediaItemId cause model._data to be out of sync which caused incorrect dirty status.
 */
export default Mixin.create({
  normalize(type, hash, prop) {
    const ret = this._super(type, hash, prop);
    ret.mediaItem = ret.mediaItem || undefined;
    return ret;
  },
});
