import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.mediaItemId}}\n  <img\n    src={{dips-url-for this.mediaItemId width=this.width height=this.height anchor=@anchor cropmode=@cropmode}}\n    width={{this.width}}\n    height={{this.height}}\n    test-dips-image\n    ...attributes\n  />\n{{else if this.externalSrc}}\n  <div style={{html-safe this.sizeStyle}} class=\"overflow-hidden\" test-external-image ...attributes>\n    <img src={{this.externalSrc}} class=\"w-full h-full object-cover\" />\n  </div>\n{{else if this.logoMediaItemId}}\n  <Ballot::MatchupEntryLogo\n    @matchupEntry={{@matchupEntry}}\n    @width={{this.width}}\n    @height={{this.height}}\n    @anchor={{@anchor}}\n    @cropmode={{@cropmode}}\n    ...attributes\n  />\n{{else}}\n  <Ui::EmptyImage style={{html-safe this.sizeStyle}} test-empty-image />\n{{/if}}", {"contents":"{{#if this.mediaItemId}}\n  <img\n    src={{dips-url-for this.mediaItemId width=this.width height=this.height anchor=@anchor cropmode=@cropmode}}\n    width={{this.width}}\n    height={{this.height}}\n    test-dips-image\n    ...attributes\n  />\n{{else if this.externalSrc}}\n  <div style={{html-safe this.sizeStyle}} class=\"overflow-hidden\" test-external-image ...attributes>\n    <img src={{this.externalSrc}} class=\"w-full h-full object-cover\" />\n  </div>\n{{else if this.logoMediaItemId}}\n  <Ballot::MatchupEntryLogo\n    @matchupEntry={{@matchupEntry}}\n    @width={{this.width}}\n    @height={{this.height}}\n    @anchor={{@anchor}}\n    @cropmode={{@cropmode}}\n    ...attributes\n  />\n{{else}}\n  <Ui::EmptyImage style={{html-safe this.sizeStyle}} test-empty-image />\n{{/if}}","moduleName":"partner/components/ballot/matchup-entry-image.hbs","parseOptions":{"srcName":"partner/components/ballot/matchup-entry-image.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type MatchupEntryModel from 'partner/models/matchup-entry';
import type SettingsService from 'partner/services/settings';

export interface BallotMatchupEntryImageSignature {
  Element: HTMLDivElement | HTMLImageElement;
  Args: {
    matchupEntry: MatchupEntryModel;
    width?: number;
    height?: number;
    cropmode?: string;
    anchor?: string;
  };
}

export default class BallotMatchupEntryImageComponent extends Component<BallotMatchupEntryImageSignature> {
  @service declare settings: SettingsService;

  get mediaItemId() {
    if (this.args.matchupEntry.isUploadedVideo && this.args.matchupEntry.videoUpload?.value) {
      return this.args.matchupEntry.videoUpload.value;
    }

    if (this.args.matchupEntry.isUploadedPhoto && this.args.matchupEntry.photoUpload?.value) {
      return this.args.matchupEntry.photoUpload.value;
    }

    return '';
  }

  get logoMediaItemId() {
    return this.args.matchupEntry.logo?.value;
  }

  get externalSrc(): string {
    if (this.args.matchupEntry.isFromYouTube && this.args.matchupEntry.sourcePostId?.value) {
      return `http://img.youtube.com/vi/${this.args.matchupEntry.sourcePostId.value as string}/0.jpg`;
    }

    if (this.args.matchupEntry.fullSizeImageUrl?.value) {
      return this.args.matchupEntry.fullSizeImageUrl?.value as string;
    }

    return '';
  }

  get sizeStyle() {
    return `width: ${this.width}px; height: ${this.height}px;`;
  }

  private get width(): number {
    return this.args.width || 1170;
  }

  private get height(): number {
    return this.args.height || 1170;
  }
}
