import { action } from '@ember/object';
import Route from '@ember/routing/route';
export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionSetupPaymentRoute extends Route {
  async model() {
    const setupModel = this.modelFor('organizations.organization.organization-promotions.organization-promotion.setup');
    return {
      promotionProduct: setupModel.promotionProduct,
    };
  }
  @action
  willTransition(transition) {
    let confirmed = !this.controller.model.promotionProduct.hasDirtyAttributes;
    confirmed = confirmed ? confirmed : window.confirm('Are you sure you want to leave without saving?');

    if (confirmed) {
      this.controller.model.promotionProduct.rollbackAttributes();
      return true;
    }
    transition.abort();
  }
}
