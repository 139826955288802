/* eslint-disable @typescript-eslint/restrict-template-expressions, ember/no-get */
import { get, setProperties } from '@ember/object';
import { capitalize } from '@ember/string';
import { pluralize, singularize } from 'ember-inflector';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import formatNumber from 'secondstreet-common/utils/format-number';
import type MessagingStatisticsEmailModel from 'partner/models/messaging-statistics-email';
import type MessagingStatisticsOrganizationModel from 'partner/models/messaging-statistics-organization';
import type OrganizationPerformanceStatisticModel from 'partner/models/organization-performance-statistic';
import type PromotionPerformanceStatisticModel from 'partner/models/promotion-performance-statistic';
import type OrganizationPromotionStatisticModel from 'partner/models/organization-promotion-statistic';

export type PromotionType = {
  id: string | number | null;
  name: string;
  type?: string;
};

export type SortDirectionType = 'asc' | 'desc' | null;

type StatisticsModel =
  | MessagingStatisticsOrganizationModel
  | OrganizationPerformanceStatisticModel
  | OrganizationPromotionStatisticModel;

export type SummaryTypeModel = StatisticsModel & { children: SummaryTypeModel[]; showChildren: boolean };

type EmailColumnNameType =
  | 'type'
  | 'emailName'
  | 'organizationName'
  | 'sentAt'
  | 'category'
  | 'sentTo'
  | 'delivered'
  | 'opened'
  | 'clicked'
  | 'unsubscribed'
  | 'organizationName'
  | 'emailCount'
  | 'sentCount'
  | 'opened';

export type ColumnType = {
  property?: string;
  name?: string;
  label?: string;
  icon?: boolean;
  sortDefault?: string;
  sortable?: string;
  breakable?: boolean;
  width?: string;
  chainOnly?: boolean;
  text?: (row: StatisticsModel) => void;
  middleText?: (row: StatisticsModel) => void;
  bottomText?: (row: StatisticsModel) => void;
  tooltip?: string;
  featureFlag?: string;
  wrappable?: boolean;
  bottomTextHideWhenCollapsed?: boolean;
};

const inflect = (count: number, word: string) => (Number(count) === 1 ? singularize(word) : pluralize(word));

export const DATE_RANGE_30_DAYS = 'Past 30 Days';
export const DATE_RANGE_THIS_MONTH = 'This Month';
export const DATE_RANGE_LAST_MONTH = 'Last Month';
export const DATE_RANGE_THIS_YEAR = 'This Year';
export const DATE_RANGE_LAST_YEAR = 'Last Year';
export const DATE_RANGE_CUSTOM = 'Custom';

export type DateRangeType = 'Past 30 Days' | 'This Month' | 'Last Month' | 'This Year' | 'Last Year' | 'Custom';

export const DATE_RANGES: DateRangeType[] = [
  DATE_RANGE_30_DAYS,
  DATE_RANGE_THIS_MONTH,
  DATE_RANGE_LAST_MONTH,
  DATE_RANGE_THIS_YEAR,
  DATE_RANGE_LAST_YEAR,
  DATE_RANGE_CUSTOM,
];

export const FORMATTED_MESSAGE_CAMPAIGN_TYPES: PromotionType[] = [
  {
    id: null,
    name: 'All',
  },
  {
    id: '1',
    name: 'Single Email',
    type: 'SingleEmail',
  },
  {
    id: '3',
    name: 'Drip Campaign',
    type: 'DripCampaign',
  },
  {
    id: '4',
    name: 'Newsletter',
    type: 'Newsletter',
  },
  {
    id: '5',
    name: 'Birthday',
    type: 'Birthday',
  },
  {
    id: '10',
    name: 'Wedding Anniversary',
    type: 'WeddingAnniversary',
  },
  {
    id: '7',
    name: 'Welcome',
    type: 'Welcome',
  },
  {
    id: '8',
    name: 'Invite',
    type: 'Invite',
  },
  {
    id: '9',
    name: 'Thank You',
    type: 'ThankYou',
  },
  {
    id: '12',
    name: 'Reminder',
    type: 'Reminder',
  },
];

export const FORMATTED_PROMOTION_TYPES: PromotionType[] = [
  { type: 'SweepstakesSimple', name: 'Sweepstakes', id: 2 },
  { type: 'SweepstakesCodeword', name: 'Codeword Sweeps', id: 3 },
  { type: 'UGCSweepstakesStandard', name: 'Photo Sweeps', id: 21 },
  { type: 'UGCSweepstakesVideo', name: 'Video Sweeps', id: 25 },
  { type: 'PhotoVotingStandard', name: 'Photo Contest', id: 23 },
  { type: 'VideoVotingStandard', name: 'Video Contest', id: 26 },
  { type: 'QuizPersonality', name: 'Personality Quiz', id: 4 },
  { type: 'QuizTrivia', name: 'Trivia Quiz', id: 5 },
  { type: 'VotingBallot', name: 'Voting Ballot', id: 27 },
  {
    type: 'NominationAndVotingBallot',
    name: 'Nomination & Voting Ballot',
    id: 28,
  },
  { type: 'VotingBracket', name: 'Voting Bracket', id: 30 },
  { type: 'PollStandard', name: 'Polls', id: 29 },
  { type: 'Survey', name: 'Survey', id: 18 },
  { type: 'PhotoGallery', name: 'Community Gallery', id: 24 },

  { type: 'EventSignup', name: 'Event Sign-Up', id: 31 },
];

/**
 * Helper function to determine the dates for a given range.
 *
 * @param dateRange A value from DATE_RANGES above.
 * @param customStart The start date for DATE_RANGE_CUSTOM.
 * @param customEnd The end date for DATE_RANGE_CUSTOM.
 * @returns {startDate : string; endDate : string} moment-generated date object
 */
export function determineDateRange(dateRange: DateRangeType, customStart?: string, customEnd?: string) {
  switch (dateRange) {
    case DATE_RANGE_30_DAYS:
      return {
        startDate: moment().subtract(30, 'days').startOf('day'),
        endDate: moment().endOf('day'),
      };
    case DATE_RANGE_THIS_MONTH:
      return {
        startDate: moment().startOf('month'),
        endDate: moment().endOf('day'),
      };
    case DATE_RANGE_LAST_MONTH:
      return {
        startDate: moment().subtract(1, 'month').startOf('month'),
        endDate: moment().subtract(1, 'month').endOf('month'),
      };
    case DATE_RANGE_THIS_YEAR:
      return {
        startDate: moment().startOf('year'),
        endDate: moment().endOf('day'),
      };
    case DATE_RANGE_LAST_YEAR:
      return {
        startDate: moment().subtract(1, 'year').startOf('year'),
        endDate: moment().subtract(1, 'year').endOf('year'),
      };
    case DATE_RANGE_CUSTOM:
      return {
        startDate: moment(customStart),
        endDate: moment(customEnd),
      };
    default:
      throw new Error(`Unsupported date range: ${dateRange}`);
  }
}

/**
 * Descriptions of each column in the email performance report. See
 * components/messaging-statistics-cell.hbs for the rendering.
 * Implemented column description fields:
 *
 *   property: Name of the field on the row object for the data.
 *   name: The column header.
 *   featureFlag: The name of a feature flag required for this column to appear.
 *   chainOnly: Whether the column should only be shown for summary rows.
 *   sortDefault: Either 'asc' or 'desc'.
 *   text: A function to compute the primary "top" text of the cell row[property] will be used as
 *         a default if this is omitted.
 *   middleText: A function to compute the "middle" text of the cell. Middle text appears between
 *               top and bottom text, and disappears in the collapsed view.
 *   bottomText: A function to compute the secondary "bottom" text of the cell.
 *   bottomTextHideWhenCollapsed: If true, the bottom text is hidden in the collapsed view.
 *   breakable: If true, allows words to be broken if necessary when wrapping lines.
 *   wrappable: If true, lets the middle and bottom text sit beside the top text when there's space.
 */
const EMAIL_COLUMNS = {
  type: {
    property: 'Type',
    name: 'Type',
    label: 'Type',
    icon: true,
    width: 'max-content',
    chainOnly: false,
    featureFlag: false,
    sortable: false,
  },
  emailName: {
    property: 'messageCampaignName',
    name: 'Email Name',
    label: 'Email Name',
    sortDefault: 'asc',
    sortable: 'messageCampaignName',
    breakable: true,
    width: 'minmax(min-content, 1fr)',
    chainOnly: false,
    featureFlag: false,
  },
  organizationName: {
    property: 'organizationName',
    name: 'Organization',
    label: 'Organization',
    sortable: 'organizationName',
    chainOnly: true,
    sortDefault: 'asc',
    featureFlag: false,
  },
  sentAt: {
    property: 'scheduleInstanceDate',
    name: 'Sent',
    label: 'Sent',
    sortDefault: 'desc',
    sortable: 'scheduleInstanceDate',
    text: (row: MessagingStatisticsEmailModel) => {
      const date = row.scheduleInstanceDate;
      return date ? moment(date).format() : 'Ongoing';
    },
    chainOnly: false,
    middleText: (row: MessagingStatisticsEmailModel) => (row.scheduleInstanceDate ? 'at' : ''),
    bottomText: (row: MessagingStatisticsEmailModel) => {
      const date = row.scheduleInstanceDate;
      return date ? moment(date).format('h:mma') : '';
    },
    wrappable: true,
    featureFlag: false,
  },
  category: {
    property: 'messageCampaignCategoryName',
    name: 'Category',
    label: 'Category',
    featureFlag: 'hasMessageCategories',
    sortable: 'messageCampaignCategoryName',
    sortDefault: 'asc',
    chainOnly: false,
  },
  emailCount: {
    property: 'scheduleInstanceCount',
    name: 'Emails',
    label: 'Emails',
    sortDefault: 'desc',
    sortable: 'scheduleInstanceCount',
    text: (row: MessagingStatisticsOrganizationModel) => formatNumber(row.scheduleInstanceCount),
    chainOnly: false,
    featureFlag: false,
  },
  sentCount: {
    property: 'sentCount',
    name: 'Sent to',
    label: 'Sent to',
    sortDefault: 'desc',
    sortable: 'sentCount',
    text: (row: MessagingStatisticsOrganizationModel | MessagingStatisticsEmailModel) => formatNumber(row.sentCount),
    chainOnly: false,
    featureFlag: false,
  },
  sentTo: {
    property: 'sentCount',
    name: 'Sent to',
    label: 'Sent to',
    sortDefault: 'desc',
    sortable: 'sentCount',
    text: (row: MessagingStatisticsOrganizationModel | MessagingStatisticsEmailModel) => formatNumber(row.sentCount),
    bottomText: (row: MessagingStatisticsOrganizationModel | MessagingStatisticsEmailModel) =>
      capitalize(inflect(row.sentCount, 'person')),
    chainOnly: false,
    wrappable: true,
    featureFlag: false,
  },
  delivered: {
    property: 'receivedPercentage',
    name: 'Delivered',
    label: 'Delivered',
    sortDefault: 'desc',
    sortable: 'receivedPercentage',
    text: (row: MessagingStatisticsOrganizationModel | MessagingStatisticsEmailModel) => `${row.receivedPercentage}%`,
    bottomText: (row: MessagingStatisticsOrganizationModel | MessagingStatisticsEmailModel) =>
      formatNumber(row.receivedCount),
    bottomTextHideWhenCollapsed: true,
    chainOnly: false,
    featureFlag: false,
  },
  opened: {
    property: 'openedPercentage',
    name: 'Opened',
    label: 'Opened',
    tooltip: 'Total unique opens',
    sortDefault: 'desc',
    sortable: 'openedPercentage',
    text: (row: MessagingStatisticsOrganizationModel | MessagingStatisticsEmailModel) => `${row.openedPercentage}%`,
    bottomText: (row: MessagingStatisticsEmailModel) => formatNumber(row.openedCount),
    bottomTextHideWhenCollapsed: true,
    chainOnly: false,
    featureFlag: false,
  },
  clicked: {
    property: 'clickedPercentage',
    name: 'Clicked',
    label: 'Clicked',
    tooltip: 'Total unique clicks',
    sortDefault: 'desc',
    sortable: 'clickedPercentage',
    text: (row: MessagingStatisticsOrganizationModel | MessagingStatisticsEmailModel) => `${row.clickedPercentage}%`,
    bottomText: (row: MessagingStatisticsOrganizationModel | MessagingStatisticsEmailModel) =>
      formatNumber(row.clickedCount),
    bottomTextHideWhenCollapsed: true,
    chainOnly: false,
    featureFlag: false,
  },
  unsubscribed: {
    property: 'optedOutPercentage',
    name: 'Unsubscribed',
    label: 'Unsubscribed',
    sortDefault: 'desc',
    sortable: 'optedOutPercentage',
    text: (row: MessagingStatisticsOrganizationModel | MessagingStatisticsEmailModel) => `${row.optedOutPercentage}%`,
    bottomText: (row: MessagingStatisticsOrganizationModel | MessagingStatisticsEmailModel) =>
      formatNumber(row.optedOutCount),
    bottomTextHideWhenCollapsed: true,
    chainOnly: false,
    featureFlag: false,
  },
};

const ORGANIZATION_COLUMNS = [
  {
    property: 'organizationName',
    label: 'Organization Name',
    sortable: 'organizationName',
    width: 'minmax(min-content, 1fr)',
  },
  {
    property: 'promotions',
    label: 'Promotions Run',
    tooltip: 'Promotions that include 10 or more people',
    sortable: 'promotions',
    text: (row: OrganizationPerformanceStatisticModel) => formatNumber(row.promotions),
  },
  {
    property: 'users',
    label: 'People',
    tooltip: 'Total new and returning users',
    sortable: 'users',
    text: (row: OrganizationPerformanceStatisticModel) => formatNumber(row.users),
  },
  {
    property: 'newUsers',
    label: 'New People',
    tooltip: 'Total users who are new to the database',
    sortable: 'newUsers',
    text: (row: OrganizationPerformanceStatisticModel) => formatNumber(row.newUsers),
  },
  {
    property: 'newOptins',
    label: 'New Opt Ins',
    tooltip: 'Total new opt ins that came from any source',
    sortable: 'newOptins',
    text: (row: OrganizationPerformanceStatisticModel) => formatNumber(row.newOptins),
  },
  {
    property: 'emails',
    label: 'Emails Sent',
    sortable: 'emails',
    text: (row: OrganizationPerformanceStatisticModel) => formatNumber(row.emails),
  },
];

const PROMOTION_COLUMNS = [
  {
    property: 'promotionName',
    label: 'Promotion Name',
    sortable: 'promotionName',
    width: 'minmax(min-content, 1fr)',
    showIcon: true,
  },
  {
    property: 'organizationName',
    label: 'Organization Name',
    sortable: 'organizationName',
    width: 'minmax(min-content, 1fr)',
  },
  {
    property: 'users',
    label: 'People',
    sortable: 'users',
    tooltip: 'Total new and returning users',
    text: (row: PromotionPerformanceStatisticModel) => formatNumber(row.users),
  },
  {
    property: 'newUsers',
    label: 'New People',
    sortable: 'newUsers',
    tooltip: 'Total users who are new to the database',
    text: (row: PromotionPerformanceStatisticModel) => formatNumber(row.newUsers),
  },
  {
    property: 'entrants',
    label: 'Entries',
    sortable: 'entrants',
    text: (row: PromotionPerformanceStatisticModel) => formatNumber(row.entrants),
  },
  {
    property: 'nominations',
    label: 'Nominations',
    sortable: 'nominations',
    text: (row: PromotionPerformanceStatisticModel) => formatNumber(row.nominations),
  },
  {
    property: 'votes',
    label: 'Votes',
    sortable: 'votes',
    text: (row: PromotionPerformanceStatisticModel) => formatNumber(row.votes),
  },
  {
    property: 'newOptins',
    label: 'New Opt Ins',
    sortable: 'newOptins',
    tooltip: 'Total new opt ins that came from promotions',
    text: (row: PromotionPerformanceStatisticModel) => formatNumber(row.newOptins),
  },
  {
    property: 'leads',
    label: 'Leads',
    sortable: 'leads',
    tooltip: 'Total opt ins shared with advertisers',
    text: (row: PromotionPerformanceStatisticModel) => formatNumber(row.leads),
  },
];

const PROMOTION_SUMMARY_COLUMNS = [
  {
    property: 'organizationName',
    label: 'Organization Name',
    sortable: 'organizationName',
    width: 'minmax(min-content, 1fr)',
  },
  {
    property: 'promotions',
    label: 'Promotions',
    sortable: 'promotions',
    text: (row: OrganizationPromotionStatisticModel) => formatNumber(row.promotions),
  },
  {
    property: 'users',
    label: 'People',
    sortable: 'users',
    tooltip: 'Total new and returning users',
    text: (row: OrganizationPromotionStatisticModel) => formatNumber(row.users),
  },
  {
    property: 'newUsers',
    label: 'New People',
    sortable: 'newUsers',
    tooltip: 'Total users who are new to the database',
    text: (row: OrganizationPromotionStatisticModel) => formatNumber(row.newUsers),
  },
  {
    property: 'entrants',
    label: 'Entries',
    sortable: 'entrants',
    text: (row: OrganizationPromotionStatisticModel) => formatNumber(row.entrants),
  },
  {
    property: 'nominations',
    label: 'Nominations',
    sortable: 'nominations',
    text: (row: OrganizationPromotionStatisticModel) => formatNumber(row.nominations),
  },
  {
    property: 'votes',
    label: 'Votes',
    sortable: 'votes',
    text: (row: OrganizationPromotionStatisticModel) => formatNumber(row.votes),
  },
  {
    property: 'newOptins',
    label: 'New Opt Ins',
    sortable: 'newOptins',
    tooltip: 'Total new opt ins that came from promotions',
    text: (row: OrganizationPromotionStatisticModel) => formatNumber(row.newOptins),
  },
  {
    property: 'leads',
    label: 'Leads',
    sortable: 'leads',
    tooltip: 'Total opt ins shared with advertisers',
    text: (row: OrganizationPromotionStatisticModel) => formatNumber(row.leads),
  },
];

const getColumns = (columnIds: EmailColumnNameType[]) => (isChain: boolean, features: { featureFlag: string }) =>
  columnIds
    .map((columnId: EmailColumnNameType) => EMAIL_COLUMNS[columnId])
    .filter(
      column => (isChain || !column?.chainOnly) && (!column.featureFlag || get(features, 'hasMessageCategories'))
    );

export const getEmailColumns = getColumns([
  'type',
  'emailName',
  'organizationName',
  'sentAt',
  'category',
  'sentTo',
  'delivered',
  'opened',
  'clicked',
  'unsubscribed',
]);

export const getSummaryColumns = getColumns([
  'organizationName',
  'emailCount',
  'sentCount',
  'delivered',
  'opened',
  'clicked',
  'unsubscribed',
]);

export const getOrganizationColumns = () => [...ORGANIZATION_COLUMNS];

export const getPromotionColumns = (isChain: boolean) => {
  if (!isChain) {
    return PROMOTION_COLUMNS.rejectBy('property', 'organizationName');
  }
  return [...PROMOTION_COLUMNS];
};

export const getPromotionSummaryColumns = () => [...PROMOTION_SUMMARY_COLUMNS];

/**
 * Calculates the percentage form of x/y, displaying two decimals of precision
 * if < 1% or two decimals otherwise. Trailing zeroes are not rendered.
 *
 * This doesn't actually convert to a string with the % tacked on for
 * compatibility with the format returned from the server for non-aggregated
 * orgs. That is also the reason it's not exported, though perhaps it would
 * make more sense for the client to simply recompute all percentages using
 * this function.
 *
 * @param {Number} x
 * @param {Number} y
 */
function percentage(x: number, y: number) {
  if (y === 0) {
    return 0;
  }
  const percent = (x / y) * 100;
  if (percent < 1) {
    // Two decimals instead of one.
    return Math.floor(percent * 100) / 100;
  }
  return Math.floor(percent * 10) / 10;
}

// Merge o2 counts into o1.
function mergeOrganizationCounts(o1: any, o2: any) {
  setProperties(o1, {
    scheduleInstanceCount: o1.scheduleInstanceCount + o2.scheduleInstanceCount,
    sentCount: o1.sentCount + o2.sentCount,
    clickedCount: o1.clickedCount + o2.clickedCount,
    openedCount: o1.openedCount + o2.openedCount,
    optedOutCount: o1.optedOutCount + o2.optedOutCount,
    receivedCount: o1.receivedCount + o2.receivedCount,
    newUsers: o1.newUsers + o2.newUsers,
    newOptins: o1.newOptins + o2.newOptins,
    emails: o1.emails + o2.emails,
    promotions: o1.promotions + o2.promotions,
    users: o1.users + o2.users,
    entrants: o1.entrants + o2.entrants,
    votes: o1.votes + o2.votes,
    nominations: o1.nominations + o2.nominations,
    leads: o1.leads + o2.leads,
  });
  return o1;
}

function recalculatePercentages(org: any) {
  const { sentCount } = org;
  setProperties(org, {
    clickedPercentage: percentage(org.clickedCount, sentCount),
    openedPercentage: percentage(org.openedCount, sentCount),
    optedOutPercentage: percentage(org.optedOutCount, sentCount),
    receivedPercentage: percentage(org.receivedCount, sentCount),
  });
}

// Creates an organization stats-like object (same interface) with aggregated
// statistics from the organization and all its children.
function createAggregateOrganization(
  org: SummaryTypeModel,
  orgToChildren: Record<string | number, SummaryTypeModel[]>,
  sortChildren: (a: (SummaryTypeModel | null)[]) => (SummaryTypeModel | null)[]
): SummaryTypeModel | null {
  if (!org) {
    return null;
  }
  const children = org.organizationId ? orgToChildren[org.organizationId] : [];
  if (children?.length === 0) {
    return org;
  }
  // Recursively aggregate children.
  const aggregatedChildren = children
    ? children?.map(c => createAggregateOrganization(c, orgToChildren, sortChildren))
    : [];
  // Combine own stats with the aggregated children. Should aggOrg be an Ember object?
  const aggOrg = aggregatedChildren?.reduce(
    mergeOrganizationCounts,
    org && org.toJSON
      ? {
          ...org.toJSON(),
          organizationId: org.organizationId,
        }
      : {}
  );
  // Fill in the percentages for the new aggregated stats.
  recalculatePercentages(aggOrg);
  if (aggOrg) {
    aggOrg.children = sortChildren([org, ...aggregatedChildren]);
    aggOrg.showChildren = false;
  }
  return aggOrg || null;
}

export function createOrganizationTree(
  orgId: string | number,
  orgs: SummaryTypeModel[],
  sortColumn: string,
  sortDirection: SortDirectionType
) {
  if (!orgs) {
    return null;
  }
  const orgToChildren: Record<string | number, SummaryTypeModel[]> = {};
  orgs.forEach(org => {
    if (org && org.organizationId) {
      orgToChildren[org.organizationId] = [];
    }
  });
  orgs.forEach(org => {
    const parentId = org.parentOrganizationId;
    if (parentId && orgToChildren[parentId]) {
      orgToChildren[parentId]?.push(org);
    }
  });
  const sortChildren =
    sortDirection === 'asc'
      ? (children: (SummaryTypeModel | null)[]) =>
          sortBy(children, (child: any) => get(child, sortColumn)?.toLowerCase?.() || get(child, sortColumn))
      : (children: (SummaryTypeModel | null)[]) =>
          sortBy(children, (child: any) => get(child, sortColumn)?.toLowerCase?.() || get(child, sortColumn)).reverse();
  const thisOrg = orgs.find(({ organizationId }) => organizationId == orgId);
  if (thisOrg) {
    return createAggregateOrganization(thisOrg, orgToChildren, sortChildren);
  }
  return null;
}

export function getSort(sort: string, isSummary: boolean) {
  if (!sort) {
    return isSummary ? 'sentCount:desc' : 'openedPercentage:desc';
  }
  return sort;
}
