/**
 * @interface DesignTemplateModel
 * @extends DS.Model
 * @property {String} name
 * @property {String} templateContent
 * @property {Boolean} isInherited
 * @property {Number} designTemplateTypeId
 * @property {Number} statusTypeId
 * @property {Promise<Organization>} organization
 * @property {Promise<MediaItem>} thumbnailMediaItem
 * @property {String} [thumbnailUrl] - If undefined, use this URL. Otherwise, use the thumbnailMediaItemId.
 * @property {Promise<DesignTokenModel[]>} tokens
 * @property {Ember.ComputedProperty<String>} designTemplateType
 * @property {Ember.ComputedProperty<String>} statusType
 */
