/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { map } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';
import RSVP from 'rsvp';

const repositionModal = () => {
  const modal = document.querySelector('.ssModal');
  if (modal) modal.style.top = '90px';
};

export default Component.extend({
  //region Ember Hooks
  snackbar: service(),
  store: service(),
  enums: service(),

  didInsertElement() {
    this._super(...arguments);
    this.store.unloadAll('checklist');
    this.store.unloadAll('checklistStep');
    this.store.unloadAll('checklistStepStatus');
    this.openCopyToOrganizationsModal();
  },

  //endregion

  //region Attributes
  /**
   * @property {Function}
   */
  'cancel-copy-to-organizations': null,
  'use-syndicate-default-token-content': true,
  'is-anything-saving': false,
  organizations: null,
  //region Attributes

  //region Properties
  /**
   * @property {Array}
   */
  messageCampaignSyndicates: null,
  /**
   * @property {Number}
   */
  showCopyToOrganizationsPage: null,
  /**
   * @property {Boolean}
   */
  isAnythingLoading: false,
  //endregion

  //region Computed Properties
  syndicationList: null,

  messageChecklistStep: computed('checklistSteps.@each.checklistStepTypeId', 'enums', function () {
    const stepTypeId = this.enums.findWhere('CHECKLIST_STEP_TYPE', { name: 'Messaging Message' }, 'id');
    return get(this, 'checklistSteps.checklistSteps').findBy('checklistStepTypeId', stepTypeId);
  }),

  scheduleChecklistStep: computed('checklistSteps.@each.checklistStepTypeId', 'enums', function () {
    const stepTypeId = this.enums.findWhere('CHECKLIST_STEP_TYPE', { name: 'Schedule' }, 'id');
    return get(this, 'checklistSteps.checklistSteps').findBy('checklistStepTypeId', stepTypeId);
  }),

  hasABVersions: computed('singleMessageCampaign.{noABTesting,messageVersions.length}', function () {
    return (
      !get(this, 'singleMessageCampaign.noABTesting') && get(this, 'singleMessageCampaign.messageVersions.length') > 1
    );
  }),

  currentOrgTree: computed('organizations', 'syndicated-message-campaign.organization.id', function () {
    const { organizations } = this;
    return organizations ? organizations.filterBy('id', get(this, 'syndicated-message-campaign.organization.id')) : [];
  }),

  syndicatesByOrganizationId: map('messageCampaignSyndicates', messageCampaignSyndicate =>
    get(messageCampaignSyndicate, 'syndicateOrganizationId').toString()
  ),

  whiteList: computed('syndicatesByOrganizationId', 'organizations', function () {
    const { organizations } = this;
    return organizations
      ? organizations.filter(organization => !this.syndicatesByOrganizationId.includes(get(organization, 'id')))
      : [];
  }),

  syndicationListExcludingCurrentOrg: computed(
    'syndicationList.[]',
    'syndicated-message-campaign.organization.id',
    function () {
      return this.syndicationList.filter(
        organization => get(this, 'syndicated-message-campaign.organization.id') !== get(organization, 'id')
      );
    }
  ),

  unremoveableOrganizations: computed('syndicatesByOrganizationId', 'organizations', function () {
    const { organizations } = this;
    return organizations
      ? organizations.filter(organization => this.syndicatesByOrganizationId.includes(get(organization, 'id')))
      : [];
  }),

  newlySyndicatedOrganizations: computed('unremoveableOrganizations', 'syndicationList.[]', function () {
    return this.syndicationList.filter(
      organization => !this.unremoveableOrganizations.mapBy('id').includes(get(organization, 'id'))
    );
  }),

  newSyndicationExcludingCurrentOrg: computed(
    'newlySyndicatedOrganizations',
    'syndicated-message-campaign.organization.id',
    function () {
      return this.newlySyndicatedOrganizations.filter(
        organization => get(this, 'syndicated-message-campaign.organization.id') !== get(organization, 'id')
      );
    }
  ),

  copyToOrgText: computed('newSyndicationExcludingCurrentOrg.length', 'unremoveableOrganizations', function () {
    const count = get(this, 'newSyndicationExcludingCurrentOrg.length');
    const { unremoveableOrganizations } = this;

    return `Copy to ${
      count ? count + (unremoveableOrganizations.length ? ' more' : '') : ''
    } ${pluralize(count, 'Organization', { withoutCount: true })}`;
  }),

  async openCopyToOrganizationsModal() {
    //to avoid having the modal appear offscreen and requiring the user to scroll up this modal
    repositionModal();

    const syndicatedMessageCampaign = this['syndicated-message-campaign'];
    set(this, 'isAnythingLoading', true);
    set(this, 'showCopyToOrganizations', true);

    const messageCampaignId = get(syndicatedMessageCampaign, 'id');
    const organizationId = get(this, 'syndicated-message-campaign.organization.id');

    const checklistSteps = this.store.query('checklist', { messageCampaignId });
    const [singleMessageCampaign, messageCampaignSyndicates] = await Promise.all([
      this.store.findRecord('singleMessageCampaign', messageCampaignId),
      this.store.query('messageCampaignSyndicate', {
        masterMessageCampaignId: messageCampaignId,
      }),
      this.store.query('token', { organizationId }),
    ]);
    await this.store.query('messageVersionTemplateTokenContent', { messageCampaignId });
    const syndicateOrganizationsById = messageCampaignSyndicates.map(messageCampaignSyndicate =>
      get(messageCampaignSyndicate, 'syndicateOrganizationId').toString()
    );
    if (this.organizations) {
      this.organizations.forEach(organization => {
        if (syndicateOrganizationsById.includes(get(organization, 'id'))) {
          this.syndicationList.addObject(organization);
        }
      });
    }

    set(this, 'checklistSteps', get(checklistSteps, 'firstObject'));
    set(this, 'showCopyToOrganizationsPage', 1);
    set(this, 'singleMessageCampaign', singleMessageCampaign);
    set(this, 'isAnythingLoading', false);
    set(this, 'messageCampaignSyndicates', messageCampaignSyndicates);
  },
  //endregion

  init() {
    this._super(...arguments);

    set(this, 'syndicationList', this.syndicationList || []);
  },

  //region Actions
  actions: {
    cancelCopyToOrganizations() {
      this['cancel-copy-to-organizations']();
    },
    addOrganization(organization) {
      this.syndicationList.addObject(organization);
    },
    removeOrganization(organization, ignoreTierOne) {
      if (get(organization, 'tier') === 1 && !ignoreTierOne) {
        this.newlySyndicatedOrganizations.forEach(selectedOrganization =>
          this.syndicationList.removeObject(selectedOrganization)
        );
      } else {
        this.syndicationList.removeObject(organization);
      }
    },
    closeOrganizationSelection() {
      set(this, 'showCopyToOrganizationsPage', 2);
    },
    finalizeSyndication() {
      set(this, 'showCopyToOrganizationsPage', 4);
    },
    async makeCopies() {
      set(this, 'is-anything-saving', true);

      const { newSyndicationExcludingCurrentOrg } = this;
      await RSVP.all(
        newSyndicationExcludingCurrentOrg.map(organization => {
          const messageCampaignSyndicate = this.store.createRecord('messageCampaignSyndicate', {
            masterMessageCampaignId: get(this, 'singleMessageCampaign.id'),
            syndicateOrganizationId: get(organization, 'id'),
            useSyndicateDefaultTokenContent: this['use-syndicate-default-token-content'],
          });
          return messageCampaignSyndicate.save();
        })
      );
      const messageCampaignTypeId = this.enums.findWhere('MESSAGE_CAMPAIGN_TYPE', { name: 'SingleEmail' }, 'id');
      await this.store.query('message-campaign', { messageCampaignTypeId });
      set(this, 'is-anything-saving', false);
      const numberOfSyndication = get(this, 'newSyndicationExcludingCurrentOrg.length');
      this.snackbar.show(
        `Draft of ${get(this, 'singleMessageCampaign.name')} copied to ${pluralize(
          numberOfSyndication,
          'Organization'
        )}.`,
        'OK'
      );
      this['cancel-copy-to-organizations']();
    },
  },
  //endregion
});
