import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class OrganizationsOrganizationAdestraConfigureFieldsController extends Controller {
  @service router;

  @tracked showMappingModal = false;
  @tracked selectedField;
  @tracked searchValue = '';

  get adestraFields() {
    return this.model.adestraFields.filter(({ name }) =>
      name.toLowerCase().includes(this.searchValue.trim().toLowerCase())
    );
  }

  get fieldsMap() {
    return this.model.fieldsMap;
  }

  get emailField() {
    return this.model.fieldsMap.find(({ name }) => name === 'Email');
  }

  get canBeSubmitted() {
    return this.model.fieldsMap?.some(({ isDirty }) => isDirty) && this.emailField.adestraName;
  }

  @action
  mapField(mappingValue) {
    this.selectedField.adestraName = mappingValue.name;
    this.closeModal();
  }

  @action
  removeFieldFromIntegration(field) {
    field.originalAdestraName = undefined;
    field.adestraName = undefined;
  }

  @action
  startMappingField(field) {
    this.showMappingModal = true;
    this.selectedField = field;
  }

  @action
  updateAdestraFieldsSearch(event) {
    this.searchValue = event.target.value;
  }

  @action
  closeModal() {
    this.showMappingModal = false;
    this.searchValue = '';
  }

  prepareFieldsMap() {
    const result = [];
    this.fieldsMap.forEach(field => {
      if (field.adestraName) {
        result.push({ external_service_field_name: field.adestraName, internal_system_field_name: field.ssName });
      }
    });
    return result;
  }

  finishMapping = task({ drop: true }, async () => {
    const { organizationAttribute } = this.model;
    const { attributeValueMap } = organizationAttribute;

    organizationAttribute.attributeValueMap = { ...attributeValueMap, fields_map: this.prepareFieldsMap() };

    await organizationAttribute.save();

    this.router.transitionTo('organizations.organization.adestra');
  });
}
