import { helper } from '@ember/component/helper';
import { ordinalize } from 'secondstreet-common/utils/english';

/**
 * @param num
 */
export function nTh([num]) {
  return ordinalize(num);
}

export default helper(nTh);
