/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import MessageCampaignListController from 'partner/mixins/message-campaign-list-controller';

/**
 * Campaigns Index Controller
 * /o/:organization_id/message-campaigns
 * @type {Ember.Controller}
 * @mixes MessageCampaignListController
 */
export default Controller.extend(MessageCampaignListController, {
  //region Properties
  queryParams: ['isCreating'],
  isCreating: false,
  visibleWhen: 'organizations.organization.message-campaigns.index',
  archived: false,
  statusTypeId: 1,
  showCalendar() {},

  emptyMessage: computed('getCurrent', 'isComplete', 'searchValue', 'messageCampaignTypeId', function () {
    if (this.searchValue || this.messageCampaignTypeId) {
      return {
        header: 'No Results Found.',
        subheader: 'Try changing your search criteria.',
      };
    }

    if (this.getCurrent && !this.isComplete) {
      return {
        header: "You haven't created any emails yet.",
        subheader: 'Click the plus button to create one!',
      };
    }
    if (this.getCurrent && this.isComplete) {
      return {
        header: 'You do not have any ongoing or upcoming emails yet.',
        subheader: 'Once some are scheduled and confirmed, they will show up here.',
      };
    }

    return {
      header: "You haven't sent any emails yet.",
      subheader: 'Once you begin sending, they will show up here.',
    };
  }),
});
