/* eslint-disable ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, observer, set } from '@ember/object';
import { oneWay } from '@ember/object/computed';
import difference from 'lodash/difference';
import PermittedComponent from 'partner/mixins/permitted-component';
import { inject as service } from '@ember/service';

/**
 * @typedef {Ember.Component} AutocompleteChips
 */
export default Component.extend(PermittedComponent, {
  screen: service(),

  //region Ember Hooks
  init() {
    this._super(...arguments);
    this.updateChosenItemsCopy();
    set(this, 'available-items', this['available-items'] || []);
    set(this, 'chosen-items', this['chosen-items'] || []);
  },

  didInsertElement() {
    this._super(...arguments);
    this.onDocumentClicked = event => {
      if (!this.showInputAlways && !this.element.querySelector('.user-input')?.contains(event.target)) {
        set(this, 'showModalButton', false);
      }
    };
    document.addEventListener('click', this.onDocumentClicked);
  },

  willDestroyElement() {
    this._super(...arguments);
    document.removeEventListener('click', this.onDocumentClicked);
  },

  //endregion

  //region Attributes

  /**
   * The placeholder text to display after the chips
   * @type {String}
   */
  placeholder: 'Add...',
  /**
   * The header text to display in the View All modal
   * @type {String}
   */
  'view-all-header': 'Choose Items',
  /**
   * An array of available items to allow the user to choose from
   * @type {Array}
   */
  'available-items': null,
  /**
   * An array of items that the user has chosen.
   * NOTE: This array is NOT modified by this component, it is expected that you pass in
   *       the chosen items in their canonical state. If you want to see when an item is
   *       added or removed, listen to the item-added and itemRemoved actions.
   * @type {Array}
   */
  'chosen-items': null,
  /**
   * The property of each item that should be matched when the user searches
   * @type {String}
   */
  'search-path': 'id',
  /**
   * The property of each item by which the items in the suggestion dropdown should be sorted
   * @type {String}
   */
  'sort-path': 'id',
  /**
   * The property of each item to display to the user in the suggestion dropdown and the chips
   * @type {String}
   */
  'display-path': 'id',
  /**
   * The property of each item to display to the user in the suggestion dropdown as secondary text, if present
   * @type {String?}
   */
  'secondary-text-path': null,
  /**
   * Whether the View All modal should allow the user to search
   * @type {Boolean}
   */
  'view-all-searchable': true,
  /**
   * If true, includes the modal to search or create a new audience
   * @property {Boolean}
   */
  'with-modal': true,
  //endregion

  //region Ember Hooks
  tagName: 'autocomplete-chips',

  showInputAlways: true,
  showModalButton: oneWay('showInputAlways'),
  //endregion

  //region Properties
  /**
   * Will contain a copy of the `chosen-items` array after the user opens the View All modal.
   * This is here so that when the `chosen-items` array changes, we can update the _contents_ of
   * this array, because it is passed by reference (not bound) to the ViewAllItemsController.
   * Since it isn't bound, when `chosen-items` changes to a whole new array the modal would not
   * get the new array, and would not show the new state properly.
   * @type {Array?}
   */
  chosenItemsCopy: null,
  isViewingAll: false,
  //endregion

  //region Computed Properties
  unavailableItems: computed('unavailable-items.[]', 'chosen-items.[]', function () {
    return this['unavailable-items'] || this['chosen-items'].slice();
  }),
  notDeletedItems: computed('{chosen-items,deleted-items}.[]', function () {
    const chosenItems = this['chosen-items'].toArray();
    const deletedItems = this['deleted-items'] ? this['deleted-items'].toArray() : [];
    return difference(chosenItems, deletedItems);
  }),
  viewAllItemsModel: computed(
    'available-items',
    'chosenItemsCopy',
    'disabled-items',
    'view-all-header',
    'secondary-text-path',
    'alternateActionText',
    'alternateAction',
    function () {
      return {
        allItems: this['available-items'],
        chosenItems: this.chosenItemsCopy,
        disabledItems: this['disabled-items'],
        header: this['view-all-header'],
        showSearch: this['view-all-searchable'],
        removeAction: item => this.send('removeItem', item),
        addAction: item => this.send('addItem', item),
        secondaryTextPath: this['secondary-text-path'],
        alternateActionText: this.alternateActionText,
        alternateAction: this.alternateAction,
        itemType: this['item-type'],
      };
    }
  ),
  get persistData() {
    return this.save || (() => {});
  },
  //endregion

  //region Observers
  chosenItemsChanged: observer('notDeletedItems.[]', function () {
    this.updateChosenItemsCopy();
  }),
  //endregion

  //region Methods
  /**
   * Updates the chosenItemsCopy array whenever chosen-items changes. See chosenItemsCopy's comment.
   */
  updateChosenItemsCopy() {
    if (this.chosenItemsCopy === null) {
      set(this, 'chosenItemsCopy', []);
    }
    const { chosenItemsCopy } = this;
    chosenItemsCopy.removeObjects(chosenItemsCopy.slice());
    this.notDeletedItems.forEach(x => chosenItemsCopy.addObject(x));
  },
  //endregion

  //region Actions
  actions: {
    // these actions are invoked by the view all items modal; data is not persisted until the modal is closed
    removeItem(item) {
      this.itemRemoved(item);
    },
    addItem(item) {
      this.itemAdded(item);
    },

    // these actions are invoked by individual item actions; data is persisted with every action
    //   - adding one item at a time from the autocomplete dropdown
    //   - removing one item at a time by removing each chip
    async removeItemChip(item) {
      this.itemRemoved(item);
      await this.persistData();
    },
    async addItemFromDropdown(item) {
      this.itemAdded(item);
      await this.persistData();
    },

    viewAll() {
      set(this, 'isViewingAll', true);
      if (this.toggleEditModal) {
        this.toggleEditModal(this.isViewingAll);
      }
    },
    async stopViewingAll() {
      await this.persistData();
      set(this, 'isViewingAll', false);
      if (this.toggleEditModal) {
        this.toggleEditModal(this.isViewingAll);
      }
    },
  },
  //endregion
});
