/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;

export default BaseModel.extend({
  mediaItemId: attr('number'),
  /**
   * Amount to rotate the mediaItem.
   * Supported Values: [90, 180, 270]
   */
  rotate: attr('number'),
});
