import BaseModel from 'secondstreet-common/models/base';
import { attr, belongsTo } from '@ember-data/model';

export default class OptinNotificationModel extends BaseModel {
  @attr('string') declare emailAddress: string;
  @attr('number') declare optinNotificationFrequencyTypeId: number;
  @attr('number') declare defaultOptinNotificationFrequencyTypeId: number;

  @attr('date') declare scheduledDate: string;
  @attr('date') declare lastNotificationDate: string;

  @belongsTo('organization', { async: false }) declare organization: any;
  @belongsTo('organization-user', { async: false }) declare organizationUser: any;
  @belongsTo('organization-promotion', { async: false }) declare organizationPromotion: any;
  @belongsTo('field', { async: false }) declare optinField: any;
}
