/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { inject as controller } from '@ember/controller';
import Controller from '@ember/controller';
import { computed, get, set } from '@ember/object';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

// Salesforce Opportunity URL with 2 potential formats:
//  https://na138.lightning.force.com/lightning/r/Opportunity/0063A000016RSmSQAW/view
//  https://na45.salesforce.com/0063A000016RSmS
const salesForceUrlRegex =
  /https:\/\/[a-z0-9]+.(?:salesforce.com|lightning.force.com\/lightning\/r\/Opportunity)\/([^/]+)\/*\S*/i;

const bloggerPackages = ['Email - By Audience Size', 'Blogger Email and Audience Insights'];

const emailPackages = [
  'Second Street Engagement Platform',
  'Contests, Interactive Content, and Email',
  'Contests, Email, and Audience Insights',
  'Interactive Content, Email, and Audience Insights',
  'Interactive Content and Email',
  'Email and Audience Insights',
  'Contests and Email',
  'Email',
];

export default Controller.extend({
  //region Services
  enums: service(),
  licenseController: controller('organizations.organization.licenses.license'),
  //endregion

  //region Fields
  organization: null,
  organizations: x => x,
  /**
   * The invoiceRecipient being displayed in modal
   * @type InvoiceReceipient
   */
  invoiceRecipient: null,
  //end region

  //region Computed Properties
  title: computed('upgrading', 'downgrading', 'editing', function () {
    if (this.upgrading) {
      return 'Upgrade';
    }
    if (this.downgrading) {
      return 'Downgrade';
    }
    if (this.editing) {
      return 'Edit License';
    }
    return 'New License Setup';
  }).readOnly(),
  /**
   * @returns {Array}
   */
  cancellationReasonTypes: computed(function () {
    return get(this, 'enums.data.CANCELLATION_REASON_TYPE');
  }).readOnly(),

  downgrading: computed('model.previousOrganizationSalesPackage', 'licenseController.downgrading', function () {
    return (
      isPresent(get(this, 'model.previousOrganizationSalesPackage')) &&
      get(this, 'licenseController.downgrading') === 'true'
    );
  }).readOnly(),

  editing: equal('licenseController.editing', 'true').readOnly(),

  upgrading: computed('model.previousOrganizationSalesPackage', 'downgrading', 'editing', function () {
    return isPresent(get(this, 'model.previousOrganizationSalesPackage')) && !this.downgrading && !this.editing;
  }).readOnly(),

  /**
   * Builds the sales force link, and will set it up on the model if the value changes
   * @returns {String}
   */
  salesforceUrl: computed('model.salesforceId', {
    get() {
      const salesforceId = get(this, 'model.salesforceId');
      return salesforceId ? `https://na45.salesforce.com/${salesforceId}` : '';
    },
    set(_key, value) {
      const [, opportunityId] = salesForceUrlRegex.exec(value) || []; // Anything after the first element is the parenthesized substring matches, if any
      set(this, 'model.salesforceId', isPresent(opportunityId) ? opportunityId : '');
      return value;
    },
  }),
  /**
   * @returns {Array}
   */
  billingFrequencies: computed(function () {
    return get(this, 'enums.data.BILLING_FREQUENCY_TYPE');
  }).readOnly(),
  /**
   * @returns {Array}
   */
  billingTypes: computed(function () {
    return get(this, 'enums.data.BILLING_TYPE');
  }).readOnly(),
  /**
   * @returns {Array}
   */
  salesPackages: computed(function () {
    return get(this, 'enums.data.SALES_PACKAGES');
  }).readOnly(),
  /**
   * @returns {Array}
   */
  salesPeople: computed(function () {
    return get(this, 'enums.data.SALES_PEOPLE');
  }).readOnly(),
  /**
   * Does the selected sales package include email
   * @returns {Boolean}
   */
  salesPackageIncludesEmail: computed('model.salesPackage', function () {
    return Boolean(
      this.salesPackages
        .filter(salesPackage => emailPackages.includes(salesPackage.name))
        .findBy('id', this.model.salesPackage?.id || -1)
    );
  }),
  /**
   * Is the selected sales package for myCapture Publisher
   * @returns {Boolean}
   */
  licenseForMyCapturePublisher: computed('model.salesPackage', function () {
    const salesPackage = get(this, 'model.salesPackage');
    const myCapturePublisherSalesPackage = this.salesPackages.findBy('name', 'myCapture Publisher');
    const salesPackageId = isPresent(salesPackage) ? get(salesPackage, 'id') : -1;
    return get(myCapturePublisherSalesPackage, 'id') === salesPackageId;
  }),
  /**
   * Is the model valid yet to save?
   * @returns {Boolean}
   */
  isValid: computed(
    'salesPackageIncludesEmail',
    'licenseForMyCapturePublisher',
    'downgrading',
    'model.{recurringFee,billingFrequencyTypeId,numberOfIncludedEmails,setupFee,cancellationReasonTypeId,salesforceId,salesPackage.name,invoiceRecipient.id,salesPackageId,billingStartDate}',
    function () {
      const { salesPackageIncludesEmail } = this;
      const emailOverageRate = get(this, 'model.numberOfIncludedEmails');
      const { licenseForMyCapturePublisher } = this;
      const setupFee = get(this, 'model.setupFee');
      const { downgrading } = this;
      const cancellationReasonTypeId = get(this, 'model.cancellationReasonTypeId');
      const salesforceId = get(this, 'model.salesforceId');
      const salesPackageName = get(this, 'model.salesPackage.name');
      const recurringFee = get(this, 'model.recurringFee');
      const billingFrequencyTypeId = get(this, 'model.billingFrequencyTypeId');

      const invoiceRecipientId = get(this, 'model.invoiceRecipient.id');
      const salesPackageId = get(this, 'model.salesPackageId');
      const billingStartDate = get(this, 'model.billingStartDate');
      const baseFields = [invoiceRecipientId, salesPackageId, billingStartDate];

      const emailOverageRateValid = !salesPackageIncludesEmail || isPresent(emailOverageRate);
      const setupFeeValid = !licenseForMyCapturePublisher || isPresent(setupFee);
      const downgradeReasonValid = !downgrading || isPresent(cancellationReasonTypeId);
      const salesforceIdValid = isPresent(salesforceId) || downgrading;
      const optionalFields = [recurringFee, billingFrequencyTypeId];
      const requiredFields = bloggerPackages.includes(salesPackageName)
        ? baseFields
        : [...optionalFields, ...baseFields];

      return (
        requiredFields.every(isPresent) &&
        emailOverageRateValid &&
        setupFeeValid &&
        downgradeReasonValid &&
        salesforceIdValid
      );
    }
  ),
  /**
   * @returns {Array}
   */
  setupFeeErrors: computed('model.errors{,.content.[]}', function () {
    const errors = get(this, 'model.errors');
    return errors.errorsFor('setupFee').mapBy('message');
  }),
  /**
   * @returns {Array}
   */
  salesforceIdErrors: computed('model.errors{,.content.[]}', function () {
    const errors = get(this, 'model.errors');
    return errors.errorsFor('salesforceId').mapBy('message');
  }),
  /**
   * @returns {Array}
   */
  recurringFeeErrors: computed('model.errors{,.content.[]}', function () {
    const errors = get(this, 'model.errors');
    return errors.errorsFor('recurringFee').mapBy('message');
  }),
  /**
   * @returns {Array}
   */
  numberOfIncludedEmailsErrors: computed('model.errors{,.content.[]}', function () {
    const errors = get(this, 'model.errors');
    return errors.errorsFor('numberOfIncludedEmails').mapBy('message');
  }),
  /**
   * @returns {Array}
   */
  emailOverageRateErrors: computed('model.errors{,.content.[]}', function () {
    const errors = get(this, 'model.errors');
    return errors.errorsFor('emailOverageRate').mapBy('message');
  }),

  showBillingType: computed(
    'model.{invoiceRecipient,invoiceRecipient.organization.id,organization.id}',
    'salesPackageIncludesEmail',
    function () {
      const invoiceRecipient = get(this, 'model.invoiceRecipient');
      const invoiceRecipientOrganizationId = get(this, 'model.invoiceRecipient.organization.id');
      const organizationId = get(this, 'model.organization.id');
      const { salesPackageIncludesEmail } = this;

      return (
        salesPackageIncludesEmail &&
        isPresent(invoiceRecipient) &&
        isPresent(invoiceRecipientOrganizationId) &&
        invoiceRecipientOrganizationId !== organizationId
      );
    }
  ),
  /**
   * @returns {Boolean}
   */
  noRecurringFee: computed('model.salesPackage.name', function () {
    return bloggerPackages.includes(get(this, 'model.salesPackage.name'));
  }),
  //end region

  //region Methods
  async saveChanges() {
    const { model } = this;
    if (this.organization && !get(model, 'organization')) {
      set(model, 'organization', this.organization);
    }
    if (get(model, 'hasDirtyAttributes')) {
      await model.save();
      const transition = this.replaceRoute('organizations.organization.licenses.license', get(model, 'id'));
      return transition.promise;
    }
  },
  //end region

  actions: {
    getByPath(path, object) {
      return get(object || {}, path);
    },
    save() {
      this.saveChanges();
    },
    saveOnEnter({ key }) {
      if (key !== 'Enter') {
        return;
      }
      this.saveChanges();
    },
  },
});
