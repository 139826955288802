/* eslint-disable ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { PROMO_TYPE_CATEGORY_ROUTE_MAP } from 'partner/utils/constants';

export default Controller.extend({
  permissions: service(),
  enums: service(),
  features: service(),
  session: service(),
  appcues: service(),
  store: service(),

  queryParams: ['isEditingName'],
  isEditingName: false,

  preserveScrollPosition: false,
  siteNamePermissionEntities: 'OrganizationPromotion',
  /**
   * Is the user report being prepared for download?
   * @type {Boolean}
   */
  isPreparingRegisteredUsersReport: false,
  /**
   * Is the sweeps entry report being prepared for download?
   * @type {Boolean}
   */
  isPreparingSweepstakesEntriesReport: false,
  /**
   * Is the survey report being prepared for download?
   * @type {Boolean}
   */
  isPreparingSurveyResponsesReport: false,
  /**
   * Is the quiz entry picks report being prepared for download?
   * @type {Boolean}
   */
  isPreparingQuizEntryPicksReport: false,

  lastPage: computed('promotion.promotionTypeCategory', function () {
    const category = this.promotion.promotionTypeCategory;
    return `organizations.organization.${
      category ? PROMO_TYPE_CATEGORY_ROUTE_MAP[this.promotion.promotionTypeCategory] : 'organization-promotions'
    }`;
  }),

  organizationPromotion: alias('model.organizationPromotion'),
  promotion: alias('organizationPromotion.promotion'),

  isLoggedInUserOnAllowedList: computed('session', 'model.allowedPartnerUsers.@each.emailAddress', function () {
    return !!this.model.allowedPartnerUsers.findBy(
      'emailAddress',
      this.session.data.authenticated.organization_users.email_address
    );
  }),

  ballotPermissionDenied: computed(
    'features.HasAdminPromotionWhitelist',
    'promotion.isBallot',
    'model.allowedPartnerUsers.@each.emailAddress',
    'session',
    function () {
      if (!this.features.get('HasAdminPromotionWhitelist')) return;

      const targetEmailAddress = this.session.data.authenticated.organization_users.email_address.toLowerCase();
      const isLoggedInUserOnAllowedList = this.model.allowedPartnerUsers
        .map(partnerUser => partnerUser.emailAddress.toLowerCase())
        .includes(targetEmailAddress);
      const loggedInOrganizationUserTypeId = this.session.data.authenticated.organization_users.user_type_id;
      const adminUserTypeIds = [
        this.enums.findWhere('USER_TYPE', { name: 'SecondStreetAdmin' }, 'id'),
        this.enums.findWhere('USER_TYPE', { name: 'SuperAdmin' }, 'id'),
      ];
      const isLoggedInUserAdmin = adminUserTypeIds.includes(loggedInOrganizationUserTypeId);

      return (
        this.promotion.isBallot &&
        this.model.allowedPartnerUsers.length &&
        !isLoggedInUserAdmin &&
        !isLoggedInUserOnAllowedList
      );
    }
  ),

  actions: {
    editName() {
      set(this, 'preserveScrollPosition', true);
      set(this, 'isEditingName', true);
    },

    handleSave() {
      set(this, 'isEditingName', false);
      this.send('saveHistory', this.model.organizationPromotion);
    },
  },
});
