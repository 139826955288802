import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import PromotionTypeRoute from 'partner/-base/promotion-type-route';
import type { ModelFrom } from 'partner/utils/types';
import type { SetupQuizModel } from '../../quiz';

export type SetupQuizQuestionsIndexModel = ModelFrom<SetupQuizQuestionsIndexRoute>;

export default class SetupQuizQuestionsIndexRoute extends PromotionTypeRoute {
  @service declare router: RouterService;

  queryParams = {
    ai: { refreshModel: false },
  };

  model() {
    return this.setupQuizModel;
  }

  private get setupQuizModel() {
    return this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion.setup.quiz'
    ) as SetupQuizModel;
  }
}
