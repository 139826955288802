/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed, get } from '@ember/object';
import { sum } from '@ember/object/computed';
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import moment from 'moment';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

const percentOfSent = key =>
  computed('peopleSentToCount', key, function () {
    const count = get(this, key);
    const sent = this.peopleSentToCount;
    return sent > 0 ? (count / sent) * 100 : 0;
  });
const percentOfMessageInstancesSent = key =>
  computed('messageInstancesSentCount', key, function () {
    const count = get(this, key);
    const sent = this.messageInstancesSentCount;
    return sent > 0 ? (count / sent) * 100 : 0;
  });
const percentOfRollingSent = key =>
  computed('rollingPeopleSentToCount', key, function () {
    const count = Math.round(get(this, key));
    const sent = Math.round(this.rollingPeopleSentToCount);
    return sent > 0 ? (count / sent) * 100 : 0;
  });

export default BaseModel.extend({
  //region Properties
  peopleSentToCount: attr('number'),
  peopleWhoOptedOutCount: attr('number'),
  peopleWhoOpenedCount: attr('number'),
  peopleWhoClickedCount: attr('number'),
  peopleWhoReceivedCount: attr('number'),
  peopleWhoSoftBouncedCount: attr('number'),
  peopleWhoHardBouncedCount: attr('number'),
  peopleWhoComplainedCount: attr('number'),
  genderFemaleSentToCount: attr('number'),
  genderMaleSentToCount: attr('number'),
  genderNotKnownSentToCount: attr('number'),
  messagingStatisticsLevelTypeId: attr('number'),
  scheduleInstanceDate: attr('date'),
  scheduleInstanceId: attr('number'),
  testWinnerMessageVersionId: attr('number'),
  testWinnerDate: attr('date'),
  testWinnerMessageVersionOrder: attr('number'),
  testPeopleSentToCount: attr('number'),
  testMessageInstancesSentCount: attr('number'),

  messageInstancesSentCount: attr('number'),
  messageInstancesReceivedCount: attr('number'),
  messageInstancesOpenedCount: attr('number'),
  messageInstancesClickedCount: attr('number'),
  messageInstancesComplainedCount: attr('number'),
  messageInstancesSoftBouncedCount: attr('number'),
  messageInstancesHardBouncedCount: attr('number'),
  messageInstancesOptedOutCount: attr('number'),

  campaignCompletedCount: attr('number'),
  campaignInProgressCount: attr('number'),
  campaignIncompleteCount: attr('number'),
  messageId: attr('number'),
  messageVersionHistoryId: attr('number'),

  rollingPeopleSentToCount: attr('number'),

  rollingPeopleWhoOptedOutCount: attr('number'),
  rollingPeopleWhoOpenedCount: attr('number'),
  rollingPeopleWhoReceivedCount: attr('number'),
  rollingPeopleWhoSoftBouncedCount: attr('number'),
  rollingPeopleWhoHardBouncedCount: attr('number'),
  rollingPeopleWhoComplainedCount: attr('number'),
  rollingPeopleWhoClickedCount: attr('number'),
  rollingMessageInstancesSentCount: attr('number'),
  rollingMessageInstancesReceivedCount: attr('number'),
  rollingMessageInstancesOpenedCount: attr('number'),
  rollingMessageInstancesClickedCount: attr('number'),
  rollingMessageInstancesComplainedCount: attr('number'),
  rollingMessageInstancesSoftBouncedCount: attr('number'),
  rollingMessageInstancesHardBouncedCount: attr('number'),
  rollingMessageInstancesOptedOutCount: attr('number'),
  //endregion

  //region Computed Properties
  messagingStatisticsLevelType: enums.computed(
    'name',
    'messagingStatisticsLevelTypeId',
    'messagingStatisticsLevelTypeId'
  ),
  issues: computed('peopleWhoSoftBouncedCount', 'peopleWhoHardBouncedCount', function () {
    return [this.peopleWhoSoftBouncedCount, this.peopleWhoHardBouncedCount];
  }),
  messageInstancesIssues: computed(
    'messageInstancesComplainedCount',
    'messageInstancesSoftBouncedCount',
    'messageInstancesHardBouncedCount',
    function () {
      return [
        this.messageInstancesComplainedCount,
        this.messageInstancesSoftBouncedCount,
        this.messageInstancesHardBouncedCount,
      ];
    }
  ),
  issuesCount: sum('issues'),
  messageInstancesIssuesCount: sum('messageInstancesIssues'),
  peopleWhoReceivedPercent: percentOfSent('peopleWhoReceivedCount'),
  issuesPercent: percentOfSent('issuesCount'),
  peopleWhoClickedPercent: percentOfSent('peopleWhoClickedCount'),
  peopleWhoOpenedPercent: percentOfSent('peopleWhoOpenedCount'),
  peopleWhoOptedOutPercent: percentOfSent('peopleWhoOptedOutCount'),
  messageInstancesReceivedPercent: percentOfMessageInstancesSent('messageInstancesReceivedCount'),
  messageInstancesOpenedPercent: percentOfMessageInstancesSent('messageInstancesOpenedCount'),
  messageInstancesClickedPercent: percentOfMessageInstancesSent('messageInstancesClickedCount'),
  messageInstancesOptedOutPercent: percentOfMessageInstancesSent('messageInstancesOptedOutCount'),
  messageInstancesIssuesPercent: percentOfMessageInstancesSent('messageInstancesIssuesCount'),

  rollingIssues: computed('rollingPeopleWhoSoftBouncedCount', 'rollingPeopleWhoHardBouncedCount', function () {
    return [this.rollingPeopleWhoSoftBouncedCount, this.rollingPeopleWhoHardBouncedCount];
  }),
  rollingIssuesCount: sum('rollingIssues'),
  rollingPeopleWhoReceivedPercent: percentOfRollingSent('rollingPeopleWhoReceivedCount'),
  rollingIssuesPercent: percentOfRollingSent('rollingIssuesCount'),
  rollingPeopleWhoOpenedPercent: percentOfRollingSent('rollingPeopleWhoOpenedCount'),
  rollingPeopleWhoClickedPercent: percentOfRollingSent('rollingPeopleWhoClickedCount'),
  rollingOptOutPercent: percentOfRollingSent('rollingPeopleWhoOptedOutCount'),

  roundedRollingPeopleSentToCount: computed('rollingPeopleSentToCount', function () {
    return Math.round(this.rollingPeopleSentToCount);
  }),
  roundedRollingPeopleWhoOptedOutCount: computed('rollingPeopleWhoOptedOutCount', function () {
    return Math.round(this.rollingPeopleWhoOptedOutCount);
  }),
  roundedRollingPeopleWhoSoftBouncedCount: computed('rollingPeopleWhoSoftBounced', function () {
    return Math.round(this.rollingPeopleWhoSoftBouncedCount);
  }),
  roundedRollingPeopleWhoHardBouncedCount: computed('rollingPeopleWhoHardBounced', function () {
    return Math.round(this.rollingPeopleWhoHardBouncedCount);
  }),
  roundedRollingPeopleWhoOpenedCount: computed('rollingPeopleWhoOpenedCount', function () {
    return Math.round(this.rollingPeopleWhoOpenedCount);
  }),
  roundedRollingPeopleWhoReceivedCount: computed('rollingPeopleWhoReceivedCount', function () {
    return Math.round(this.rollingPeopleWhoReceivedCount);
  }),
  roundedRollingIssuesCount: computed('rollingIssuesCount', function () {
    return Math.round(this.rollingIssuesCount);
  }),
  roundedRollingPeopleWhoClickedCount: computed('rollingPeopleWhoClickedCount', function () {
    return Math.round(this.rollingPeopleWhoClickedCount);
  }),

  issuesChartData: computed('{peopleWhoSoftBouncedCount,peopleWhoHardBouncedCount}', function () {
    return [
      {
        label: 'Soft Bounce',
        value: this.peopleWhoSoftBouncedCount,
      },
      {
        label: 'Hard Bounce',
        value: this.peopleWhoHardBouncedCount,
      },
    ];
  }),
  rollingIssuesChartData: computed('{rollingPeopleWhoSoftBouncedCount,rollingPeopleWhoHardBouncedCount}', function () {
    return [
      {
        label: 'Soft Bounce',
        value: this.roundedRollingPeopleWhoSoftBouncedCount,
      },
      {
        label: 'Hard Bounce',
        value: this.roundedRollingPeopleWhoHardBouncedCount,
      },
    ];
  }),
  formattedTestWinnerDateTime: computed('testWinnerDate', function () {
    if (this.testWinnerDate) {
      const date = moment(this.testWinnerDate);
      return date.format('hh:mma');
    }
    return;
  }),
  //endregion

  //region Relationships
  messageCampaign: belongsTo('message-campaign', { async: true }),
  message: belongsTo('message', { async: true }),
  messageVersionHistory: belongsTo('message-version-history', { async: true }),
  messageVersion: belongsTo('message-version', { async: true }),
  //endregion
});
