import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"design-element-content\">\n  <h3 class=\"design-element-name\">\n    {{#if (eq @customTokenContent \"EmbedCode\")}}\n      Copy and paste this code into your site:\n    {{else}}\n      Install <a href=\"https://wordpress.org/plugins/second-street-promotion/\" target=\"_blank\" rel=\"noopener noreferrer\">our Wordpress plugin</a> and use the shortcode below:\n    {{/if}}\n  </h3>\n</div>\n<div class=\"design-element-content\">\n  <textarea\n    class=\"embed-code\"\n    readonly\n    {{on \"click\" this.copyToClipboard}}\n    {{on \"mouseenter\" this.highlight}}\n    {{on \"mouseleave\" this.unhighlight}}\n  >{{this.codeType}}</textarea>\n</div>\n", {"contents":"<div class=\"design-element-content\">\n  <h3 class=\"design-element-name\">\n    {{#if (eq @customTokenContent \"EmbedCode\")}}\n      Copy and paste this code into your site:\n    {{else}}\n      Install <a href=\"https://wordpress.org/plugins/second-street-promotion/\" target=\"_blank\" rel=\"noopener noreferrer\">our Wordpress plugin</a> and use the shortcode below:\n    {{/if}}\n  </h3>\n</div>\n<div class=\"design-element-content\">\n  <textarea\n    class=\"embed-code\"\n    readonly\n    {{on \"click\" this.copyToClipboard}}\n    {{on \"mouseenter\" this.highlight}}\n    {{on \"mouseleave\" this.unhighlight}}\n  >{{this.codeType}}</textarea>\n</div>\n","moduleName":"partner/components/token-content-editor-for-embed-code.hbs","parseOptions":{"srcName":"partner/components/token-content-editor-for-embed-code.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { clipboardCopy } from 'secondstreet-common/helpers/clipboard-copy';

export default class TokenContentEditorForEmbedCode extends Component {
  //region Dependencies
  @service
  snackbar;
  //endregion

  //region Computed Properties
  get codeType() {
    return this.args.customTokenContent === 'EmbedCode' ? this.args.embedCode : this.args.shortCode;
  }
  //endregion

  //region Actions
  @action
  copyToClipboard(event) {
    event.stopPropagation();
    clipboardCopy([this.codeType])();
    this.snackbar.show('Copied to clipboard!');
  }

  @action
  highlight(event) {
    event.target.select();
  }

  @action
  unhighlight(event) {
    event.target.setSelectionRange(0, 0);
  }
  //endregion
}
