import { get } from '@ember/object';
import mapValues from 'lodash/mapValues';

const letters = {
  o: { key: 'organizationId', fn: context => get(context.modelFor('organizations.organization'), 'organization.id') },
  op: {
    key: 'organizationPromotionId',
    fn: context =>
      get(
        context.modelFor('organizations.organization.organization-promotions.organization-promotion'),
        'organizationPromotion.id'
      ),
  },
  p: {
    key: 'promotionId',
    fn: context =>
      get(
        context.modelFor('organizations.organization.organization-promotions.organization-promotion'),
        'organizationPromotion.promotion.id'
      ),
  },
  mc: {
    key: 'messageCampaignId',
    fn: context =>
      get(context.modelFor('organizations.organization.message-campaigns.message-campaign'), 'messageCampaign.id'),
  },
};

const factory = (...choices) =>
  Object.defineProperties(
    {},
    {
      ...mapValues(letters, (_, letter) => ({
        get: () => factory(letter, ...choices),
      })),
      for: {
        /**
         * Terminates the chain, returning a query param object
         * @param {Ember.Route} route
         * @returns {Object}
         */
        value: route =>
          Object.assign(
            {},
            ...choices.map(choice => ({
              [letters[choice].key]: letters[choice].fn(route),
            }))
          ),
      },
    }
  );

export default factory();
