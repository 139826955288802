/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import DS from 'ember-data';
import InheritableModel from 'partner/-base/inheritable-model';
import Copyable from 'partner/mixins/copyable-model';

export default InheritableModel.extend(Copyable, {
  //region Properties
  pageTypeId: DS.attr('number'),
  name: DS.attr('string'),
  url: DS.attr('string'),
  isLive: DS.attr('boolean'),
  masterPageTemplateId: DS.attr('number'),
  isInherited: DS.attr('boolean'),
  pageTemplatePresetId: DS.attr('number'),
  promotionTypeId: DS.attr('number'),
  languageId: DS.attr('number'),
  domainId: DS.attr('number'),
  templateTokens: DS.hasMany('template-token', {
    async: false,
  }),
  audienceId: DS.attr('number'),
  //endregion

  //region Computed Properties
  status: computed('isInherited', function () {
    return this.isInherited ? 'incomplete' : 'bestPractice'; // 'complete' is always 'bestPractice' here.
  }),
  //endregion
});
