/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { and } from '@ember/object/computed';
import PermittedComponent from 'partner/mixins/permitted-component';

export default Component.extend(PermittedComponent, {
  tagName: 'simple-menu-item',
  classNameBindings: ['disabled:disabled'],

  //region Attributes
  /**
   * By defaut, we disable some simple menu functionality if user doesn't have certain administer permissions.
   * By setting this to true, we will remove those restrictions for users with view permissions.
   */
  'permit-view-only': false,
  //endregion

  //region Computed Properties
  _disabled: null,
  disabled: computed('administer', 'permitViewOnly', '_disabled', {
    get() {
      if (this._disabled !== null) {
        return this._disabled;
      }
      return !this.administer && !this.permitViewOnly;
    },
    set(_key, value) {
      this.set('_disabled', value);
      return value;
    },
  }),
  permitViewOnly: and('permit-view-only', 'view'),
  //endregion
});
