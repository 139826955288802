import { action } from '@ember/object';
import Component from '@glimmer/component';
import { later } from '@ember/runloop';

export default class UiListFilter extends Component {
  @action
  mdcInput(event) {
    this.args.oninput(event.target.value, event);
  }

  @action
  focusSearch(element) {
    if (this.args.autofocus) {
      //using runloop/later, just to make sure this executes in the end of javascript event loop execution
      //as we have faced some issue with autofocus not letting css animation to work seamlessly
      later(() => {
        element.querySelector('input').focus();
      }, 0);
    }
  }
}
