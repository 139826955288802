/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, set } from '@ember/object';
import { on } from '@ember/object/evented';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import flattenDeep from 'lodash/flattenDeep';
import TickTock from 'partner/mixins/tick-tock';
import { setMessageIsConfirmed } from 'partner/utils/message-campaign-helpers';
import RSVP from 'rsvp';

const nonConfirmationRoutes = [
  {
    route: 'organizations.organization.message-campaigns.message-campaign.setup.recipients',
    name: 'Recipients',
  },
  {
    route: 'organizations.organization.message-campaigns.message-campaign.setup.message-versions',
    name: 'Message',
  },
  {
    route: 'organizations.organization.message-campaigns.message-campaign.setup.schedule',
    name: 'Schedule',
  },
];

export default Controller.extend(TickTock, {
  //region Ember Hooks
  checklist: service(),
  store: service(),
  router: service(),
  //endregion

  //region Computed Properties
  /**
   * Which non-confirmation checklist steps are not yet complete?
   * @type {Ember.ComputedProperty}
   * @returns {Object[]} Array of objects with `route` and `name`
   */
  incomplete: computed('tock', 'model.checklist.checklistSteps.@each.checklistStepStatusPercentComplete', function () {
    // "Tickle" the computed property dependent keys
    this.tock;

    // Actually compute the value
    return nonConfirmationRoutes.filter(x => !this.checklist?.isChecklistStepChecked(x.route));
  }),

  currentSetupStep: computed('router.currentRouteName', function () {
    const index = this.router.currentRouteName.lastIndexOf('.');
    return this.router.currentRouteName.substring(index + 1);
  }),

  /**
   * Has the user confirmed they want the message campaign to be sent?
   * @type {Ember.ComputedProperty}
   * @returns {Boolean}
   */
  confirmed: computed(
    'model.messageCampaign.{usesSingleMessageCampaign,messages.@each.sendingStatusType,isConfirmed}',
    'model.singleMessageCampaign.{messageSendingStatusType,isConfirmed}',
    {
      get() {
        return this.model.messageCampaign.usesSingleMessageCampaign
          ? this.model.singleMessageCampaign.isConfirmed
          : this.model.messageCampaign.isConfirmed;
      },

      set(key, value) {
        setMessageIsConfirmed({
          usesSMC: this.model.messageCampaign.usesSingleMessageCampaign,
          singleMessageCampaign: this.model.singleMessageCampaign,
          messageCampaign: this.model.messageCampaign,
          value,
        });

        return value;
      },
    }
  ),
  //endregion

  //region Observers
  /**
   * Unset confirmation if any other checklist items uncheck.
   */
  updateConfirmation: on('init', function () {
    const originatingChecklist = this.checklist.checklist;
    this.checklist.on('stepUnchecked', checklistFromEvent => {
      if (!isEmpty(this.incomplete) && originatingChecklist === checklistFromEvent) {
        set(this, 'confirmed', false);
        this.saveConfirmation();
      }
    });
  }),
  //endregion

  //region Methods
  saveConfirmation() {
    this.confirmed;
    let hasErrorSavingConfirmation = false;

    return RSVP.all(
      flattenDeep([this.model.singleMessageCampaign, (this.model.messageCampaign.messages || []).toArray()])
        .compact()
        .filterBy('hasDirtyAttributes', true)
        .map(x =>
          x.save().catch(() => {
            hasErrorSavingConfirmation = true;
          })
        )
    ).then(async () => {
      if (hasErrorSavingConfirmation) {
        await this.store.query('checklist', { messageCampaignId: this.model.singleMessageCampaign.id });
      }
      const action = this.confirmed ? 'check' : 'uncheck';
      return this.checklist[`${action}ChecklistStep`](
        'organizations.organization.message-campaigns.message-campaign.setup.confirmation'
      );
    });
  },
  //endregion
});
