/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';

function makeCounter(num) {
  return computed('question-option-outcome-weight.selectionWeight', function () {
    return get(this, 'question-option-outcome-weight.selectionWeight') === num;
  });
}

export default Component.extend({
  //region Computed Properties
  isZero: makeCounter(0),
  isOne: makeCounter(1),
  isTwo: makeCounter(2),
  isThree: makeCounter(3),
  isFour: makeCounter(4),
  isFive: makeCounter(5),
  isTen: makeCounter(10),
  //endregion

  //region Actions
  actions: {
    setWeight(weight) {
      set(this, 'question-option-outcome-weight.selectionWeight', weight);
    },
  },
  //endregion
});
