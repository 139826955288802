/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-mixins */
import Controller, { inject as controller } from '@ember/controller';
import { computed } from '@ember/object';
import { alias, equal, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import HasCategoricalCharts from 'partner/mixins/has-categorical-charts';
import HasEventCharts from 'partner/mixins/has-event-charts';
import NeedsOrganizationPromotionController from 'partner/mixins/needs-organization-promotion-controller';
import { firstByProperty } from 'partner/utils/computed';

export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionDashboardQuizController extends Controller.extend(
  HasCategoricalCharts,
  HasEventCharts,
  NeedsOrganizationPromotionController
) {
  @service('enums') enums;
  @service('permissions') permissions;

  @alias('model.matchups.firstObject.startDate')
  startDate;

  @alias('model.matchups.firstObject.endDate')
  endDate;

  @controller('organizations.organization.organization-promotions.organization-promotion')
  organizationPromotionController;

  currentSweepsWinnerPage = 1;
  winnersCardTransition = 'toLeft';

  @readOnly('organizationPromotion.kpi')
  totalQuizSubmissions;

  @readOnly('model.matchupEntries.meta.totalRecords')
  totalEntries;

  @readOnly('organizationPromotion.numEligibleUsers')
  numEligibleUsers;

  @equal('model.registrationTypeSetting.value', 2)
  isRegistrationOptional;

  @computed('model.registrationTypeSetting')
  get isRegistrationEnabled() {
    return !!this.model.registrationTypeSetting.value;
  }

  @computed('model.categoricalCharts.@each.sourceTypeId')
  get questionCharts() {
    return this.model.categoricalCharts.filterBy('sourceTypeId', 3).sortBy('sourceTypeDisplayOrder');
  }

  @computed('questionCharts.length', 'model.sweepstakes.isEnabled')
  get hasOrphanedQuestionChart() {
    return this.model.sweepstakes.isEnabled && this.questionCharts.length % 2 === 1;
  }

  @computed('model.promotion.isQuiz')
  get reportCountTitle() {
    return this.model.promotion.isQuiz ? 'Quiz Submissions' : 'Poll Responses';
  }

  @computed('currentSweepsWinnerPage', 'model.sweepstakesWinners.length')
  get sweepsCardWinnersPagingInfo() {
    const numSweepsWinners = this.model.sweepstakesWinners.length;
    return numSweepsWinners > 1 ? `(${this.currentSweepsWinnerPage} of ${numSweepsWinners})` : false;
  }

  @firstByProperty('name', 'Entry', 'model.eventCharts')
  entriesChart;

  @firstByProperty('sourceTypeId', 2, 'model.categoricalCharts')
  outcomeChart;
}
