/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type { ModelFrom } from 'partner/utils/types';
import type SetupBallotController from 'partner/controllers/organizations/organization/organization-promotions/organization-promotion/setup/ballot';
import type SetupBallotMatchupsNewController from 'partner/controllers/organizations/organization/organization-promotions/organization-promotion/setup/ballot/matchups/new';

export type Model = ModelFrom<SetupBallotMatchupsNewRoute>;

export default class SetupBallotMatchupsNewRoute extends Route {
  @service declare store: Store;

  setupController(controller: SetupBallotMatchupsNewController): void {
    const setupBallotController = this.controllerFor(
      'organizations.organization.organization-promotions.organization-promotion.setup.ballot'
    ) as SetupBallotController;
    controller.matchup = setupBallotController.createMatchup();
  }
}
