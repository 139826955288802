import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DesignTemplateRoute extends Route {
  @service router;

  model(params) {
    return params.design_template_id;
  }

  afterModel(designTemplateId) {
    this.router.replaceWith('organizations.organization.legal.design-templates.design-template', designTemplateId);
  }
}
