import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * Legacy Route - only exists to redirect to what has replaced it.
 */
export default class DesignsIndexRoute extends Route {
  @service router;

  redirect() {
    const targetRoute = 'organizations.organization.organization-promotions.organization-promotion.setup.designs';
    if (this.router.isActive(targetRoute)) {
      this.router.refresh(targetRoute);
    } else {
      this.router.transitionTo(targetRoute);
    }
  }
}
