import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from 'partner/serializers/application';

export default ApplicationSerializer.extend(EmbeddedRecordsMixin, {
  attrs: {
    organizationPromotions: {
      serialize: false,
      deserialize: 'records',
    },
  },
});
