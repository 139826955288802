/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';

export default Controller.extend({
  //region Properties
  /**
   * @type {?String}
   */
  username: null,
  //endregion
});
