/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed, get } from '@ember/object';
import { equal, not, notEmpty, sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty } from '@ember/utils';
import { makeBooleanProperties, productType } from 'ember-cli-ss-enums/enums-decorator';
import flattenTree from 'partner/utils/flatten-tree';
import BaseModel from 'secondstreet-common/models/base';

const HAS_PROMOTIONS = [
  'hasContests',
  'hasOffers',
  'hasCustomSingleContest',
  'hasNationalContest:AutoRacing',
  'hasNationalContest:Golf',
  'hasNationalContest:CollegeBasketball',
  'hasNationalContest:ProFootball',
  'hasNationalContest:ProFootballKnockout',
  'hasNationalContest:CollegeFootball',
];
const HAS_INTERACTIVE_CONTENT = ['hasSurveys', 'hasQuizzes', 'hasBallots', 'hasCommunityGalleries'];

@productType
@makeBooleanProperties('ORGANIZATION_TYPE', 'name', 'organizationTypeId')
export default class Organization extends BaseModel {
  @service enums;

  @attr('string') affiliateId;
  @attr('string') eblastApiGuid;
  @attr('number') organizationTypeId;
  @attr('string') name;
  @attr('number') statusTypeId;
  @attr('string') organizationGuid;
  @attr('number') promotionsCount;
  @attr('number') interactionsCount;
  @attr('string') rssLink;
  @attr('string') xmlLink;
  @attr('number') timeZoneId;
  @attr('number') tier;
  @attr('number') parentOrganizationId;
  @attr('string') salesForceId;
  @attr('number') level;

  childOrganizationSorting = ['name:asc'];

  @computed('timeZoneId')
  get timeZone() {
    return this.enums.findWhere('TIME_ZONES', { id: this.timeZoneId }, {});
  }

  @equal('organizationTypeId', 1) isAdministrator; // TODO: Enums

  @computed('childOrganizations.[]')
  get hasChildren() {
    return !isEmpty(this.childOrganizations);
  }

  @notEmpty('parentOrganizationId') hasParent;
  @not('hasParent') missingParent;
  @sort('childOrganizations', 'childOrganizationSorting') sortedChildOrganizations;

  @computed('parentOrganization')
  get ancestorNames() {
    const parent = this.parentOrganization;
    if (!parent) {
      return 'No Parent';
    }
    let names = '';
    const getNames = org => {
      if (org) {
        names = isEmpty(names) ? get(org, 'name') : get(org, 'name') ? `${get(org, 'name')} > ${names}` : `${names}`;
        getNames(get(org, 'parentOrganization'));
      }
    };
    getNames(parent);
    return names;
  }

  @equal('tier', 1) isTierOne;
  @equal('tier', 2) isTierTwo;
  @equal('tier', 3) isTierThree;
  @equal('tier', 4) isTierFour;
  @equal('tier', 5) isTierFive;

  @computed(...HAS_PROMOTIONS)
  get hasPromotions() {
    return HAS_PROMOTIONS.some(promotion => get(this, promotion));
  }

  @computed(...HAS_INTERACTIVE_CONTENT)
  get hasInteractiveContent() {
    return HAS_INTERACTIVE_CONTENT.some(ic => get(this, ic));
  }

  // For now, we've deferred the decision on how to properly
  // detect this, so this heuristic will have to do.
  @computed('hasPromotions', 'hasMessaging', 'hasSurveys', ...HAS_INTERACTIVE_CONTENT.filter(x => x !== 'hasSurveys'))
  get isOnlyBlogger() {
    const { hasPromotions } = this;
    const { hasMessaging } = this;
    const { hasSurveys } = this;
    const nonSurveyIC = HAS_INTERACTIVE_CONTENT.filter(x => x !== 'hasSurveys');

    return !hasPromotions && hasMessaging && hasSurveys && nonSurveyIC.every(ic => !get(this, ic));
  }

  @computed('hasInteractiveContent', 'hasContests', 'hasMessaging', 'hasData')
  get onlyHasInteractiveContentOrContests() {
    return (this.hasInteractiveContent || this.hasContests) && !this.hasMessaging && !this.hasData;
  }

  get descendants() {
    return flattenTree(this.sortedChildOrganizations, 'sortedChildOrganizations');
  }

  //endregion

  //region Relationships
  @belongsTo('organization', { inverse: 'childOrganizations', async: true }) parentOrganization;
  @hasMany('organization', { inverse: 'parentOrganization', async: false }) childOrganizations;
  @hasMany('invoice-recipient', { async: true }) invoiceRecipients;
  @hasMany('organization-promotion', { async: true }) organizationPromotions;
  @hasMany('organization-sales-package', { async: false, inverse: 'organization' }) organizationSalesPackages;
  @belongsTo('organization-statistic', { async: false }) organizationStatistics;
  @hasMany('organization-ss-product', { async: false, inverse: 'organization' }) organizationSsProducts;
  @hasMany('setting', { async: false }) settings;
  @hasMany('organization-attribute', { async: true }) organizationAttributes;
  @belongsTo('location', { async: false }) location;

  async getParentOrganizations() {
    const parent = await this.parentOrganization;
    const getAncestor = async org => {
      if (isBlank(org) || get(org, 'name') === 'Second Street Admin') {
        return [];
      }
      const parentOrganization = await get(org, 'parentOrganization');
      const parentOrganizations = await getAncestor(parentOrganization);
      return [org].concat(parentOrganizations);
    };
    return getAncestor(parent);
  }
}
