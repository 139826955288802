/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { alias, gt } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import PermittedComponent from 'partner/mixins/permitted-component';

/**
 * App Bar that shows up at the top of partner tool pages.
 */
export default Component.extend(PermittedComponent, {
  appContext: service('app-context'),
  router: service(),

  /**
   * Visible When
   * A space-separated list of route names that the app bar should be visible on.
   * @type {String}
   */
  'visible-when': '',
  isLoading: false,

  visibleWhen: alias('visible-when'),

  /**
   * If org has the data product we show tag icon next to promo name.
   * This property indicates if there are > 1 tags.
   */
  multipleTags: gt('tagsCount', 1),

  _isHidden: null,
  isHidden: computed('visibleWhen', 'appContext.currentAppContext', '_isHidden', {
    get() {
      const { _isHidden, visibleWhen } = this;

      if (_isHidden !== null) {
        return _isHidden;
      }

      if (!visibleWhen) {
        return true;
      }

      return !visibleWhen.split(' ').includes(get(this, 'appContext.currentAppContext'));
    },

    set(_key, value) {
      this.set('_isHidden', value);

      return value;
    },
  }),

  disableEditTitle: computed('isLoading', 'router.currentRouteName', function () {
    return this.router.currentRouteName.includes('loading') || this.isLoading;
  }),

  actions: {
    editTitle() {
      if (!this.disabled) {
        this.editTitle();
      }
    },

    back() {
      if (window.history.length <= 2) {
        this.router.transitionTo(this.lastPage);
      } else {
        window.history.back();
      }
    },
  },
});
