import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';
import type PartnerUserModel from './partner-user';

export default class PartnerUserSecurityDetailModel extends BaseModel {
  @attr('number') declare partnerUserId: number;
  @attr('string') declare partnerTwoFactorAuthRecoveryEmailAddress: string;
  @attr('boolean') declare isPartnerTwoFactorAuthEnabled: boolean;
  @attr('boolean') declare resetTwoFactorAuth: boolean;
  @attr('string') declare partnerTwoFactorAuthSecretKey: string;
  @attr('boolean') declare isPartnerTwoFactorAuthDeviceSetupComplete: boolean;

  @belongsTo('partner-user') declare partnerUser: AsyncBelongsTo<PartnerUserModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'partner-user-security-detail': PartnerUserSecurityDetailModel;
  }
}
