/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';

export default Component.extend({
  //region Ember Hooks
  classNames: ['token-flyout'],
  classNameBindings: ['add-tokens:token-flyout--add-token'],
  //endregion

  //region Attributes
  /**
   * @type {Function}
   */
  closeFlyout() {},
  /**
   * @type {Boolean}
   */
  'is-complete': false,
  /**
   * @type {Boolean}
   */
  'is-dirty': false,
  /**
   * @type {Boolean}
   */
  'is-valid': false,
  /**
   * @type {Boolean}
   */
  'is-loading': false,
  /**
   * @type {String}
   */
  label: null,
  /**
   * @type {Boolean}
   */
  'add-tokens': false,
  /**
   * @type {Object}
   */
  errors: null,
  /**
   * @type {Boolean}
   */
  editingEnabled: true,
  /**
   * @type {Boolean}
   */
  'show-article-import': true,
  /**
   * @type {Object}
   * @property {Boolean} isPresent
   * @property {Function} action
   * @property {String} text
   * @property {Boolean} disabled
   * @property {String} alert - text that shows up after the action is triggered
   */
  secondaryAction: null,
  /**
   * @type {Object}
   * @property {Boolean} isPresent
   * @property {action} action
   * @property {String} text
   * @property {String} icon
   * @property {String} classModifier
   */
  tertiaryAction: null,
  //endregion
});
