import Component from '@glimmer/component';
import { dasherize } from '@ember/string';

const join = (a, b, joiner = '-') => (a && b ? `${a}${joiner}${b}` : `${a}${b}`);

export default class ItemComponent extends Component {
  get typeClass() {
    return join(
      'stat-card__list-item',
      join(dasherize(this.args['entity-type'] || ''), dasherize(this.args.type || '')),
      '--'
    );
  }
}
