/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { isPresent } from '@ember/utils';

export default Component.extend({
  //region Attributes
  'inner-text': null,
  'is-readonly': false,
  'modal-header': null,
  'modal-subheader': null,
  'primary-button-text': 'Done',
  'primary-button-action': null,
  'secondary-button-text': 'Copy',
  'secondary-button-action': null,
  //endregion

  //region Actions
  actions: {
    primaryAction() {
      const action = this['primary-button-action'];
      if (isPresent(action)) {
        action();
      }
    },
    secondaryAction() {
      const action = this['secondary-button-action'];
      if (isPresent(action)) {
        action();
      }
    },
  },
  //endregion
});
