/* eslint-disable ember/no-mixins, ember/no-get */
import { action, get, set, setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isBlank, isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import PromotionTypeRoute from 'partner/mixins/promotion-type-route';
import RSVP from 'rsvp';
import { clipboardCopy } from 'secondstreet-common/helpers/clipboard-copy';
import { endpoint } from 'secondstreet-common/utils/url';

/**
 * Setup Publish Route
 * /o/:organization_id/op/:organization_promotion_id/setup/publish
 * @type {Ember.Route}
 * @mixes PromotionTypeRoute
 */
export default class SetupPublishRoute extends Route.extend(PromotionTypeRoute) {
  @service features;
  @service session;
  @service settings;
  @service snackbar;
  @service store;

  async model() {
    const organizationId = this.modelFor('organizations.organization').organization.id;
    const orgPromoModel = this.modelFor('organizations.organization.organization-promotions.organization-promotion');
    const organizationPromotionId = orgPromoModel.organizationPromotion.id;

    const hash = await RSVP.hash({
      wrappers: this.store.query('wrapper', { organizationPromotionId }),
      orgDefaultWrappers: this.store.query('wrapper', { organizationId }),
      domains: this.store.query('domain', { organizationId, organizationPromotionId }),
      organizationPromotionDomains: this.store.query('organizationPromotionDomain', {
        organizationId,
        organizationPromotionId,
      }),
      domainRedirection: this.store.query('domainRedirection', { organizationId, organizationPromotionId }),
      matchups: this.store.query('matchup', {
        organizationId,
        organizationPromotionId,
        excludeSecondaryMatchups: true,
      }),
      promotionApprovers: get(this, 'features.hasRequiredPromotionEmbedApproval')
        ? this.store.query('promotionApprover', { organizationId })
        : null,
      promotionApprovalSetting: get(this, 'features.hasRequiredPromotionEmbedApproval')
        ? this.store.query('setting', { key: 'is_promotion_embed_approved' })
        : null,
      settings: this.settings.preload(['use_static_iframe', 'top_offset_iframe', 'bottom_offset_iframe'], {
        force: true,
      }),
    });

    await Promise.all(hash.organizationPromotionDomains.mapBy('domain'));

    return {
      organizationPromotion: orgPromoModel.organizationPromotion,
      organizationId,
      wrappers: hash.wrappers.toArray(),
      domains: hash.domains.toArray(),
      orgDefaultWrappers: hash.orgDefaultWrappers.toArray(),
      organizationPromotionDomains: hash.organizationPromotionDomains.toArray(),
      domainRedirection: hash.domainRedirection.firstObject,
      matchups: hash.matchups,
      promotionApprovers: hash.promotionApprovers,
      promotionApprovalSetting: hash.promotionApprovalSetting ? hash.promotionApprovalSetting.firstObject : null,
      availableDomains: hash.domains
        .filterBy('domainType', 'Web')
        .filterBy('statusType', 'Active')
        .reject(domain => domain.hostname.indexOf('upickem.net') > 0),
      organizationPromotionDomain: hash.organizationPromotionDomains.findBy('domain.domainType', 'Web'),
      trustedDomains: hash.domains.filterBy('domainTypeId', 3),
      facebookOrganizationPromotionDomains: hash.organizationPromotionDomains.filterBy(
        'domain.domainType',
        'FacebookPage'
      ),
      settings: {
        useStaticIframe: this.settings.getFor('use_static_iframe'),
        topOffsetIframe: this.settings.getFor('top_offset_iframe'),
        bottomOffsetIframe: this.settings.getFor('bottom_offset_iframe'),
      },
    };
  }

  afterModel(model) {
    if (isBlank(get(model, 'promotionApprovalSetting')) && get(this, 'features.hasRequiredPromotionEmbedApproval')) {
      const newPublishApprovalSetting = this.store.createRecord('setting', {
        key: 'is_promotion_embed_approved',
        value: false,
      });
      set(model, 'promotionApprovalSetting', newPublishApprovalSetting);
    }
  }

  resetController(controller) {
    setProperties(controller, {
      isViewingFacebookInstructions: false,
      relevantPage: null,
      embedFrameDimensions: '250x250',
      isAdgagementComplete: false,
      isGettingAdgagementCode: false,
      isShowingTrustedDomains: false,
    });
  }

  saveTask = task({ enqueue: true }, async continueAfterSave => {
    const model = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion.setup.publish'
    );
    set(this, 'controller.isAnythingSaving', true);
    const domainRedirection = get(model, 'domainRedirection');
    const orgPromoDomain = get(model, 'organizationPromotionDomain');
    const publishApprovalSetting = get(model, 'promotionApprovalSetting');
    const facebookOrgPromoDomains = get(model, 'facebookOrganizationPromotionDomains');
    const wrappers = get(this, 'controller.organizationPromotionLevelWrappers');
    const { orgDefaultWrappers } = this.controller;

    if (domainRedirection && get(domainRedirection, 'statusType') === 'InActive') {
      domainRedirection.deleteRecord();
    }

    await RSVP.all(
      get(model, 'domains')
        .filterBy('hasDirtyAttributes')
        .map(x => x.save())
    );

    // notify all approval requesters of any change to the publish approval setting
    if (publishApprovalSetting && get(publishApprovalSetting, 'hasDirtyAttributes')) {
      await this.session.request(endpoint('promotion_approval_requests'), {
        type: 'PUT',
        data: {
          promotion_approval_requests: [
            { requested_organization_user_id: null, is_approved: publishApprovalSetting.value },
          ],
        },
      });
    }

    try {
      await RSVP.all([
        publishApprovalSetting && get(publishApprovalSetting, 'hasDirtyAttributes')
          ? publishApprovalSetting.save()
          : null,

        RSVP.all(
          Object.entries(model.settings).map(async ([key, setting]) => {
            if (!setting.get('hasDirtyAttributes')) return;

            const { isInherited } = setting;

            await setting.save();

            // We need to reload the inherited settings as a new one is created after saving
            if (isInherited) {
              await this.settings.preload(setting.key, { force: true });
              set(model.settings, key, this.settings.getFor(setting.key));
            }
          })
        ),

        get(orgPromoDomain, 'hasDirtyAttributes') ? orgPromoDomain.save() : null,
        domainRedirection?.isDirty ? domainRedirection.save() : null,
        RSVP.all(wrappers.filterBy('hasDirtyAttributes').map(x => x.save())).then(() => {
          set(this, 'controller.isEditingCustomWrapper', false);
        }),
        RSVP.all(orgDefaultWrappers.filterBy('hasDirtyAttributes').map(x => x.save())).then(() => {
          set(this, 'controller.isEditingDefaultWrapper', false);
          set(this, 'controller.isUsingDefaultWrapperState', true);
        }),
        RSVP.all(facebookOrgPromoDomains.filterBy('hasDirtyAttributes').map(x => x.save())).then(() => {
          const deletedFacebookOrgPromoDomains = facebookOrgPromoDomains.filterBy('isDeleted', true).slice();

          if (deletedFacebookOrgPromoDomains) {
            deletedFacebookOrgPromoDomains.forEach(x => {
              facebookOrgPromoDomains.removeObject(x);
            });
          }
        }),
      ]);
    } catch (e) {
      console.error(e);
    }

    set(this, 'controller.isAnythingSaving', false);
    if (this.controller.isPreparingToCopy && !this.controller.domainRedirectionHasErrors) {
      this.codeCopy(this.controller.isPreparingToCopy);
    }

    if (domainRedirection?.isDeleted) {
      set(model, 'domainRedirection', null);
    }

    set(this, 'controller.isPreparingToCopy', false);

    const hasErrors = [publishApprovalSetting, orgPromoDomain, domainRedirection, Object.values(model.settings)].some(
      model => isPresent(model?.errors)
    );
    const wrapperErrors = wrappers && wrappers.isAny('errors.length');
    const facebookOrgPromoDomainErrors = facebookOrgPromoDomains && facebookOrgPromoDomains.isAny('errors.length');

    if (continueAfterSave && !hasErrors && !wrapperErrors && !facebookOrgPromoDomainErrors) {
      this.send('continue');
    }
  });

  @action
  willTransition(transition) {
    set(this, 'controller.isEditingCustomWrapper', false);
    set(this, 'controller.isEditingDefaultWrapper', false);

    const isSyndicationEnabled =
      this.controller.model.organizationPromotion.hasSyndicates && this.features.get('hasPromotionSyndication');

    let confirmed = isSyndicationEnabled
      ? !this.controller.isSelectedOrganizationsDirty
      : !this.controller.isAnythingDirty;
    confirmed = confirmed ? confirmed : window.confirm('Are you sure you want to leave without saving?');

    if (confirmed) return true;

    transition.abort();
  }

  @action
  didTransition() {
    this.send(
      'checkChecklistStep',
      'organizations.organization.organization-promotions.organization-promotion.setup.publish'
    );
  }

  @action
  save(continueAfterSave) {
    this.controller.saveTaskInstance = this.saveTask.perform(continueAfterSave);
  }

  @action
  saveAndContinue() {
    this.save(true);
  }

  @action
  saveWrapperSettings() {
    this.save();
  }

  @action
  codeCopy(type) {
    const code = type === 'Embed' ? this.controller.embedCode : this.controller.wordpressCode;
    clipboardCopy([code])();

    if (type === 'Embed') {
      this.snackbar.show('Copied to clipboard! Paste the code into your website or CMS.');
    } else {
      this.snackbar
        .show(
          'Copied to clipboard! Before pasting, make sure you have our WordPress plugin installed.',
          'Get WordPress Plugin'
        )
        .then(() => {
          window.open('https://wordpress.org/plugins/second-street-promotion/', '_blank', 'noopener noreferrer');
        });
    }
  }

  @action
  prepareCodeCopy(type) {
    set(this, 'controller.isPreparingToCopy', type);
  }
}
