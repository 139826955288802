/* global Redactor */

Redactor.add('plugin', 'snippets', {
  translations: {
    en: {
      snippets: {
        snippets: 'Snippets',
        add: 'Add Snippet',
      },
    },
  },
  defaults: {
    snippets: [],
  },

  subscribe: {
    'snippets.addSnippets'(snippet) {
      const $token = this.dom(`<span contenteditable=false data-token=${snippet.params.key}></span>`);
      $token.html(`{{${snippet.params.key}.value}}`);
      this.app.editor.insertContent({ html: $token });
    },
  },
  start() {
    if (this.opts.get('snippets.snippets').length && this.opts.get('snippets.designTemplateLocked')) {
      const button = {
        title: '## snippets.snippets ##',
        icon: '<i class="material-icons-outlined text-lg text-black leading-none">data_object</i>',
        command: 'snippets.popup',
        position: {
          after: 'fontspacing',
        },
      };
      this.app.toolbar.add('snippets', button);
      this.app.context.add('snippets', button);
    }
  },
  popup(e, button) {
    const items = this.opts
      .get('snippets.snippets')
      .filter(token => ('key' in token && token.key !== '') || 'templateTypeId' in token)
      .map(token => ({
        title: token.name,
        command: 'snippets.select',
        params: {
          name: token.name,
          token: token.token,
          key: token.key,
        },
      }));

    const addSnippetButton = {
      title: `<span class="text-primary">${this.lang.get('snippets.add')}</span>`,
      icon: '<i class="material-icons-outlined text-lg text-primary leading-none text-center">add</i>',
      command: 'snippets.add',
      position: 'first',
    };

    items.push(addSnippetButton);
    const dropdown = this.app.create('dropdown', 'snippets', {
      width: '240px',
      items,
    });

    this.app.dropdown.open(e, button, dropdown);
  },
  select(params) {
    const $token = this.dom(`<span contenteditable=false data-token=${params.key}></span>`);
    $token.html(`{{${params.key}.value}}`);
    this.app.editor.insertContent({ html: $token });
    this.app.dropdown.close();
  },
  add() {
    this.app.dropdown.close();
    this.app.broadcast('snippets.createNewSnippet');
  },
});
