import { get } from '@ember/object';
import { endpoint } from 'secondstreet-common/utils/url';

export default async (sourceForm, store, session, adapter) =>
  store.find(
    'form',
    get(
      await session.request(endpoint('copy_forms'), {
        type: 'POST',
        data: `{"copy_forms":[{"source_form_id":${sourceForm.id}}]}`,
        headers: adapter.headers,
        contentType: 'application/json',
      }),
      'copy_forms.firstObject.id'
    )
  );
