import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';
import InterestTagModel from './interest-tag';

export default class EntityInterestTagModel extends BaseModel {
  @attr('number') declare entityId: number;
  @attr('number') declare entityTypeId: number;
  @attr('boolean') declare isInherited: boolean;
  @attr('number') declare interestTagId: number;

  @belongsTo('interest-tag', { async: true }) declare interestTag: AsyncBelongsTo<InterestTagModel>;

  get isEditable() {
    return !this.isInherited;
  }
}
