/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-mixins */
import { attr, belongsTo, hasMany, type SyncHasMany } from '@ember-data/model';
import type Store from '@ember-data/store';
import { computed } from '@ember/object';
import { equal, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import enums from 'ember-cli-ss-enums/services/enums';
import difference from 'lodash/difference';
import PromotionTypeName from 'partner/mixins/promotion-type-name';
import { all } from 'rsvp';
import BaseModel from 'secondstreet-common/models/base';
import type EntityInterestTagModel from './entity-interest-tag';
import type InterestTagModel from './interest-tag';

const taggingSpecificNames: Record<string, string> = {
  'Game Group': 'Category',
  Matchup: 'Subcategory',
  'Organization Link': 'Link',
  'Messaging Message': 'Message',
};

@makeBooleanProperties('ENTITY_TYPE')
export default class TaggableEntityModel extends BaseModel.extend(PromotionTypeName) {
  @service declare store: Store;

  @attr('string') declare name: string;
  @attr('number') declare interactionsCount: number;
  @attr('date') declare dateCreated: Date;
  @attr('number') declare entityTypeId: number;
  @attr('string') declare viewSiteLink: string;
  @attr('number') declare promotionSubTypeId: number;
  @attr('number') declare messageCampaignTypeId: number;
  @attr('number') declare entityId: number;
  @attr('number') declare tier: number;
  @attr('boolean') declare isEditable: boolean;
  @attr('number') declare displayOrder: number;
  @attr('string') declare details: string;
  @attr('boolean') declare skipTier: boolean;

  @hasMany('entity-interest-tag', { async: false }) declare entityInterestTags: SyncHasMany<EntityInterestTagModel>;
  @belongsTo('taggable-entity', { inverse: 'taggableEntities', async: false })
  declare parentTaggableEntity: TaggableEntityModel;
  @hasMany('taggable-entity', { inverse: 'parentTaggableEntity', async: false })
  declare taggableEntities: SyncHasMany<TaggableEntityModel>;

  @equal('tier', 1) declare isTierOne: boolean;
  @equal('tier', 2) declare isTierTwo: boolean;
  @equal('tier', 3) declare isTierThree: boolean;
  @equal('tier', 4) declare isTierFour: boolean;

  @computed('isQuestion', 'isGameGroup', 'isMatchup', 'isMessagingMessage', 'taggableEntities.length')
  get isExpandable() {
    if (this.isMatchup || this.isMessagingMessage) {
      return this.taggableEntities.length > 0;
    }

    return this.isQuestion || this.isGameGroup;
  }

  @enums.computed('name', 'entityTypeId') declare entityTypeName: string;

  @computed('entityTypeName')
  get displayName() {
    return taggingSpecificNames[this.entityTypeName] || this.entityTypeName;
  }

  @enums.computed('displayOrder', 'entityTypeId') declare entityTypeDisplayOrder: number;
  @enums.computed('name', 'messageCampaignTypeId', 'messageCampaignTypeId', 'MESSAGE_CAMPAIGN_TYPE')
  declare messageCampaignTypeName: string;
  @or('promotionClassName', 'messageCampaignTypeName') declare entityTypeClassName: string;
  @enums.computed('multiTierTaggingEnabled', 'promotionSubTypeId', 'id', 'PROMOTION_SUB_TYPE')
  declare multiTierTaggingEnabled: boolean;
  @equal('entityTypeName', 'Message Campaign') declare isEmail: boolean;

  get interestTags() {
    return this.entityInterestTags.mapBy('interestTag.content') as InterestTagModel[];
  }

  set interestTags(interestTags: InterestTagModel[]) {
    void this.replaceInterestTags(interestTags);
  }

  //endregion

  //region Methods
  /**
   * Delete EntityInterestTag when the associated InterestTag is removed from this.interestTags
   */
  async removeInterestTag(removedInterestTag: InterestTagModel) {
    return Promise.all(
      this.entityInterestTags.map(entityInterestTag =>
        entityInterestTag.interestTag.then(interestTag => {
          return removedInterestTag == interestTag ? entityInterestTag.destroyRecord() : null;
        })
      )
    );
  }

  /**
   * Save EntityInterestTag when a InterestTag is added to this.interestTags
   */
  addInterestTag(interestTag: InterestTagModel) {
    const entityInterestTag = this.store.createRecord('entityInterestTag', {
      interestTag,
      entityId: this.entityId,
      entityTypeId: this.entityTypeId,
      isInherited: false,
    });

    this.entityInterestTags.addObject(entityInterestTag);

    return entityInterestTag.save();
  }

  loadChildTaggableEntities() {
    const promise = this.store.query('taggable-entity', {
      parentTaggableEntityId: this.id,
      tier: 2,
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    promise.then(taggableEntities => {
      const childTaggableEntities = this.taggableEntities;
      childTaggableEntities.addObjects(taggableEntities);
    });

    return promise;
  }

  fetchInterestTags() {
    return all(this.entityInterestTags.map(entityInterestTag => entityInterestTag.get('interestTag')));
  }

  async replaceInterestTags(nextInterestTags: InterestTagModel[]) {
    const currentInterestTags = await this.fetchInterestTags();

    await all([
      ...difference(currentInterestTags, nextInterestTags).map(interestTag => this.removeInterestTag(interestTag)),
      ...difference(nextInterestTags, currentInterestTags).map(interestTag => this.addInterestTag(interestTag)),
    ]);
  }

  // props from makeBooleanProperties
  declare isGameGroup: boolean;
  declare isMatchup: boolean;
  declare isMessagingMessage: boolean;
  declare isQuestion: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'taggable-entity': TaggableEntityModel;
  }
}
