/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, observer, set } from '@ember/object';
import { empty, sort } from '@ember/object/computed';
import { debounce } from '@ember/runloop';

export default Component.extend({
  //region Attributes
  /**
   * @type {Array?}
   */
  'sent-emails': null,
  /**
   * Paging meta data
   * @type {Object?}
   */
  paging: null,
  /**
   * Set when `sentEmails` are being loaded/finished loading
   * @type {Boolean}
   */
  'is-loading': false,
  /**
   * Function for searching through sent emails
   * @type {Function}
   */
  'search-value-changed': null,
  //endregion

  //region Properties
  /**
   * The current page of `sent-emails`
   * @type {Array?}
   */
  page: null,
  /**
   * The value for searching `sent-emails`
   * @type {String}
   */
  searchValue: '',
  //endregion

  //region Computed Properties
  sortedSentEmails: sort('sent-emails', (a, b) => get(b, 'sentDate') - get(a, 'sentDate')).readOnly(),

  noSentEmails: empty('sent-emails'),

  showPaging: computed('paging.{pageSize,totalRecords}', function () {
    return get(this, 'paging.pageSize') < get(this, 'paging.totalRecords');
  }),
  //endregion

  //region Observers
  pageIndexChanged: observer('paging.{pageIndex,refresh}', function () {
    const newPage = get(this, 'paging.pageIndex') || 1;
    if (get(this, 'paging.refresh')) {
      set(this, 'paging.refresh', false);
      this['page-changed-action'](newPage);
    }
    const previousPage = parseInt(this.page || 1);
    // Update the querystring if needed:
    if (newPage !== previousPage) {
      set(this, 'page', newPage === 1 ? null : newPage);
      this['page-changed-action'](newPage);
    }
  }),
  //endregion

  //region Actions
  actions: {
    changePage(page) {
      set(this, 'paging.pageIndex', page);
    },
    searchValueChanged(query) {
      debounce(this['search-value-changed'], query, 250);
    },
  },
  //endregion
});
