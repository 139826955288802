import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import enums from 'ember-cli-ss-enums/services/enums';
import RSVP from 'rsvp';

const STATUS_TYPE_IDS = [
  enums.findWhere('STATUS_TYPE', { name: 'Active' }, 'id'),
  enums.findWhere('STATUS_TYPE', { name: 'InActive' }, 'id'),
  enums.findWhere('STATUS_TYPE', { name: 'Pending' }, 'id'),
];

export default class extends Route {
  @service('enums') enums;
  @service('store') store;
  @service('router') router;

  queryParams = {
    filters: { refreshModel: true },
    ipAddress: { refreshModel: true },
    searchValue: { refreshModel: true },
    statusTypeId: { refreshModel: true },
    pageIndex: { refreshModel: true },
    sortColumn: { refreshModel: true },
    sortDirection: { refreshModel: true },
  };

  get organization() {
    return this.modelFor('organizations.organization').organization;
  }

  get organizationPromotion() {
    return this.modelFor('organizations.organization.organization-promotions.organization-promotion')
      .organizationPromotion;
  }

  async beforeModel(transition) {
    if (this.controller && transition.from?.name == transition.to?.name) {
      this.controller.isLoading = true;
    }

    if (transition.to.queryParams.statusTypeId) return;

    this.router.replaceWith({ queryParams: { statusTypeId: STATUS_TYPE_IDS[0], pageIndex: 1 } });
  }

  async model({ searchValue, filters, ipAddress, ...params }) {
    const searchValues = [ipAddress, filters, searchValue].filter(Boolean).join(',');
    const usersQueryParams = {
      ...params,
      ...(searchValues ? { searchValue: searchValues } : {}),
    };

    return RSVP.hash({
      organizationPromotion: this.organizationPromotion,
      summaries: this.#fetchOrCreateSummaries(),
      users: this.store.query('organization-promotion-user', usersQueryParams),
      usersQueryParams,
    });
  }

  setupController(controller, model, ...args) {
    super.setupController(controller, model, ...args);

    controller.isLoading = false;
    controller.selectedUsers = controller.selectedUsers.filter(user => model.users.includes(user));
  }

  resetController(controller, isExiting) {
    if (isExiting) controller.resetParams();
  }

  @action
  loading() {
    // Prevent the loading template from being rendered
    return !this.controller?.isLoading;
  }

  async #fetchOrCreateSummaries() {
    const summaries = await this.store.query('organization-promotion-user-status-type-summary', {
      organizationId: this.organization.id,
    });

    return STATUS_TYPE_IDS.map(
      statusTypeId =>
        summaries.findBy('statusTypeId', statusTypeId) ||
        this.store.createRecord('organization-promotion-user-status-type-summary', {
          statusTypeId,
          userCount: 0,
        })
    ).sortBy('statusTypeId');
  }
}
