/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/* globals twttr */
import Component from '@ember/component';

/**
 * Display a Twitter follow button
 */
export default Component.extend({
  disableInteraction: false,
  classNames: ['ssTwitterFollowButton'],
  handle: '',
  didInsertElement() {
    this._super(...arguments);
    twttr.widgets.load();
  },
});
