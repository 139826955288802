import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from 'partner/serializers/application';

export default class EntrySerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    entryFieldValues: { embedded: 'always' },
    organizationUsers: { embedded: 'always' },
    organizationPromotionUsers: { embedded: 'always' },
  };
}
