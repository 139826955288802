/**
 * This determines if a DOM element is inside the viewport.
 * @param el DOM Object or jQuery Object
 *
 * @see http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433
 */
export const elementVisible = el => {
  //special bonus for those using jQuery
  if (typeof window.jQuery === 'function' && el instanceof window.jQuery) {
    [el] = el;
  }

  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /*or $(window).height() */ &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
  );
};

export default {
  elementVisible,
};
