import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from 'partner/serializers/application';

export default class OrganizationPromotionSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    promotions: { embedded: 'always' },
  };

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload.organization_promotions) {
      [payload.organization_promotions].flat().forEach(element => {
        if (!('end_date' in element)) {
          element['end_date'] = null;
        }
      });
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
