/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import PermittedComponent from 'partner/mixins/permitted-component';

/**
 * Input that only works in the "data down. actions up" style.
 * Utilizes the oninput event to know when the value changed and sends an action up accordingly.
 * Some functionality was copied from {{ss-input}} (works in old style of using two way binding)
 */
export default Component.extend(PermittedComponent, {
  //region Properties
  'is-just-created': false, // Pass this in to bind it, when using focus-just-created
  unset: true, // Pass unset=false if you don't want to unset is-just-created, when using focus-just-created
  //endregion

  //region Ember Hooks
  tagName: 'one-way-input',
  /**
   * Autofocus the input as it's inserted if the attribute auto-focus-on-insert is set to true
   */
  didInsertElement() {
    this._super(...arguments);
    const element = this.element.querySelector('input');
    if (this['auto-focus-on-insert']) {
      element.focus();
    } else if (this['focus-just-created'] && this['is-just-created']) {
      element.focus();
      element.setSelectionRange(element.value.length, element.value.length);
    }
  },
  //endregion

  //region Attributes
  'auto-focus-on-insert': false,
  /**
   * If you want to manually disable the component, pass this as true.
   */
  'is-disabled': false,
  //endregion

  //region Computed Properties
  /**
   * Don't overwrite this from the outside! Also don't overwrite `disabled`.
   * If you do those things, it will cause permissions issues.
   */
  isDisabled: computed('administer', 'disabled', 'is-disabled', {
    get() {
      return !this.administer || this.disabled || this['is-disabled'];
    },

    set(_key, value) {
      set(this, 'disabled', value);
      return value;
    },
  }),
  hasErrors: computed('errors.[]', 'attribute', function () {
    const { errors } = this;
    if (!errors) {
      return false;
    }
    return errors.has(this.attribute);
  }),
  //endregion

  //region Events
  focusIn() {
    if (this['focus-in']) {
      this['focus-in']();
    }
  },
  /**
   *  Do this on blur or else it'll re-render a non-focused field on top.
   */
  focusOut() {
    if (this['focus-just-created'] && this.unset) {
      set(this, 'is-just-created', undefined);
    }
  },
  //endregion
  actions: {
    valueChanged(event) {
      this['changed'](event.target.value, event);
    },
  },
});
