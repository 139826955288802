/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import { isNotItemToken } from 'partner/utils/tokens';

export default Component.extend({
  //region Ember Hooks
  enums: service(),
  tagName: 'add-token',
  //endregion

  //region Properties
  template: null,
  inputFieldClass: null, // Optional class of input field to inject the selected token into. Otherwise the token is appended to the body of the template.
  inputField: null, // Optional input field to inject the selected token into. Otherwise the token is appended to the body of the template.
  tokens: null,
  existingListTitle: 'Existing Tokens',
  closeModal() {},
  icon: null, // Optional name of an icon. Example: 'ssIcon-cogs'
  //endregion

  //region Computed Properties
  /**
   * @type {Ember.ComputedProperty}
   * @returns {Object[]}
   */
  tokenKeyCombinations: computed('tokens', function () {
    const tokenKeyArrays = this.tokens.filter(isNotItemToken).map(token => {
      const isTemplateToken = get(token, 'tokenType') === 'Template';
      const mayContainHandlebars =
        isTemplateToken &&
        (token.tokenContentType === 'HtmlText' ||
          token.tokenContentType === 'Code' ||
          token.tokenContentType === 'RawHtml');
      const tokenPrefix = mayContainHandlebars ? '{{{' : '{{';
      const tokenSuffix = mayContainHandlebars ? '}}}' : '}}';

      if (isTemplateToken) {
        const tokenKeys = this.enums.findWhere(
          'TOKEN_CONTENT_TYPE_KEY_SUB_TYPE',
          { tokenContentTypeId: parseInt(get(token, 'tokenContentTypeId')) },
          'keys'
        );
        if (tokenKeys !== null) {
          return tokenKeys.map(key => ({
            id: get(token, 'id'),
            token: `${tokenPrefix + get(token, 'key')}.${key}${tokenSuffix}`,
            tokenType: get(token, 'tokenType'),
            tokenContentType: get(token, 'tokenContentType'),
            key: `${get(token, 'key')}.${key}`,
            showIcon: true,
          }));
        }
        return [
          {
            id: get(token, 'id'),
            token: tokenPrefix + get(token, 'key') + tokenSuffix,
            tokenType: get(token, 'tokenType'),
            tokenContentType: get(token, 'tokenContentType'),
            key: get(token, 'key'),
            showIcon: true,
          },
        ];
      }
      return [
        {
          id: get(token, 'id'),
          token: `{{${get(token, 'key')}}}`,
          tokenType: get(token, 'tokenType'),
          tokenContentType: get(token, 'tokenContentType'),
          key: get(token, 'key'),
          showIcon: false,
        },
      ];
    });
    const tokenKeys = [];
    return tokenKeys.concat(...tokenKeyArrays).sortBy('key');
  }),
  //endregion

  //region Methods
  insertToken(tokenValue) {
    const body = get(this, 'template.templateContent');
    set(this, 'template.templateContent', tokenValue + body);
  },
  replaceSelectedText(tokenValue) {
    this.inputField.focus();
    const [inputField] = this.inputField;
    const startSelPos = inputField.selectionStart;
    const endSelPos = inputField.selectionEnd;
    const newEndSelPos = startSelPos + tokenValue.length;
    inputField.value = inputField.value.substring(0, startSelPos) + tokenValue + inputField.value.substring(endSelPos);
    inputField.selectionStart = newEndSelPos;
    inputField.selectionEnd = newEndSelPos;
    // Temporarily remove focus so Ember will dirty the model:
    this.inputField.blur().focus();
  },
  //endregion

  //region Actions
  actions: {
    cancel() {
      this.closeModal();
    },
    existingTokenSelected(tokenValue) {
      if (this.inputFieldClass) {
        set(this, 'inputField', $(this.inputFieldClass));
      }
      const { inputField } = this;
      if (inputField !== null && typeof inputField !== 'undefined') {
        this.replaceSelectedText(tokenValue);
      } else {
        this.insertToken(tokenValue);
      }
      this.closeModal();
    },
  },
  //endregion
});
