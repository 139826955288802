import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class OrganizationAttributeTypeModel extends BaseModel {
  //region Attributes
  @attr('string') name;
  @attr('number') valueDataTypeId;
  @attr('string', { defaultValue: 'False' }) defaultValue;
  @attr('boolean') isApplicationFeature;
  @attr('number') excludedOrganizationsCount;
  //endregion

  //region Properties
  get isOn() {
    return this.defaultValue === 'True';
  }
  //endregion
}
