import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::Modal::Title @title=\"Global Entrant Settings\" @isLoading={{false}} />\n\n<div class=\"flex flex-col px-6 mb-4 -mt-2 {{if false 'pointer-events-none'}}\">\n  <Ui::Alert class=\"mb-2\">\n    This configuration is applied to all the entrants across this ballot\n  </Ui::Alert>\n\n  <Ui::Tabs @tabs={{this.tabs}} @current={{this.currentTab}} @onChange={{this.changeTab}} />\n</div>\n\n{{#if (eq this.currentTab \"sortAndLinks\")}}\n  <Ballot::Setup::MatchupEntrySettings::SortAndLinks @onClose={{this.close}} />\n{{/if}}\n\n{{#if (eq this.currentTab \"preApprovedList\")}}\n  <Ballot::Setup::MatchupEntrySettings::PreApprovedList @onClose={{this.close}} />\n{{/if}}\n\n{{#if (eq this.currentTab \"entrantLimit\")}}\n  <Ballot::Setup::MatchupEntrySettings::EntrantLimit @onClose={{this.close}} />\n{{/if}}", {"contents":"<Ui::Modal::Title @title=\"Global Entrant Settings\" @isLoading={{false}} />\n\n<div class=\"flex flex-col px-6 mb-4 -mt-2 {{if false 'pointer-events-none'}}\">\n  <Ui::Alert class=\"mb-2\">\n    This configuration is applied to all the entrants across this ballot\n  </Ui::Alert>\n\n  <Ui::Tabs @tabs={{this.tabs}} @current={{this.currentTab}} @onChange={{this.changeTab}} />\n</div>\n\n{{#if (eq this.currentTab \"sortAndLinks\")}}\n  <Ballot::Setup::MatchupEntrySettings::SortAndLinks @onClose={{this.close}} />\n{{/if}}\n\n{{#if (eq this.currentTab \"preApprovedList\")}}\n  <Ballot::Setup::MatchupEntrySettings::PreApprovedList @onClose={{this.close}} />\n{{/if}}\n\n{{#if (eq this.currentTab \"entrantLimit\")}}\n  <Ballot::Setup::MatchupEntrySettings::EntrantLimit @onClose={{this.close}} />\n{{/if}}","moduleName":"partner/components/ballot/setup/matchup-entry-settings.hbs","parseOptions":{"srcName":"partner/components/ballot/setup/matchup-entry-settings.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type DirtyTrackerService from 'partner/services/dirty-tracker';
import type CurrentService from 'partner/services/current';

interface BallotSetupMatchupEntrySettingsSignature {
  Element: HTMLDivElement;
  Args: {
    onClose: () => void;
  };
}

type Tab = 'sortAndLinks' | 'preApprovedList' | 'entrantLimit';

export default class BallotSetupMatchupEntrySettingsComponent extends Component<BallotSetupMatchupEntrySettingsSignature> {
  @service declare dirtyTracker: DirtyTrackerService;
  @service declare current: CurrentService;

  @tracked currentTab: Tab = 'sortAndLinks';

  get tabs() {
    const tabs: Partial<Record<Tab, string>> = {
      sortAndLinks: 'Sort and Links',
      preApprovedList: 'Pre-approved list',
      ...(this.current.promotion.isNominationAndVotingBallot ? { entrantLimit: 'Entrant Limit' } : {}),
    };

    return Object.entries(tabs).map(([key, label]) => ({ key, label }));
  }

  @action
  changeTab(tab: Tab) {
    if (this.dirtyTracker.confirm()) {
      this.currentTab = tab;
    }
  }

  @action
  close() {
    this.dirtyTracker.reset();
    this.args.onClose();
  }
}
