/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { computed } from '@ember/object';
import Attribute from 'partner/components/template-builder/attribute-editor/attribute';

export default Attribute.extend({
  //region Computed Properties
  value: computed('component-attributes.@each.{value,defaultValue}', 'attr-name', function () {
    const componentAttrs = this['component-attributes'];
    const attrName = this['attr-name'];
    return typeof componentAttrs[attrName].value === 'boolean'
      ? componentAttrs[attrName].value
      : componentAttrs[attrName].defaultValue;
  }),
  //endregion
});
