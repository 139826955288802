/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import PromotionTypeRoute from 'partner/mixins/promotion-type-route';
import { unloadEmptyRecords } from 'partner/utils/store-unload';

/**
 * Forms Route
 * No URL - Anything under /o/:organization_id/op/:organization_promotion_id/setup/forms
 * @type {Ember.Route}
 * @mixes Promotion.AuthenticatedRoute
 * @mixes Promotion.PromotionTypeRoute
 */
export default Route.extend(PromotionTypeRoute, {
  store: service(),

  beforeModel(...args) {
    this._super(...args);
    unloadEmptyRecords(this.store, 'field');
  },

  //region Ember Hooks
  model() {
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');
    const organizationPromotionId = get(
      this.modelFor('organizations.organization.organization-promotions.organization-promotion'),
      'organizationPromotion.id'
    );

    return this.store.query('form', {
      organizationId,
      organizationPromotionId,
    });
  },

  afterModel() {
    this.send('checkChecklistStep', this.fullRouteName);
  },
  //endregion
});
