/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { set } from '@ember/object';
import SsInputComponent from 'partner/components/ss-input';

/**
 * Focus Just-Created Input Component
 * Used to focus an element and then unset "isJustCreated" on it.
 * @type {*}
 */
export default SsInputComponent.extend({
  //region Attributes
  isJustCreated: false, // Pass this in to bind it.
  unset: true, // Pass unset=false if you don't want to unset isJustCreated
  //endregion

  //region Events
  didInsertElement() {
    this._super(...arguments);
    if (this.isJustCreated && (this['auto-focus-on-insert'] || this['focus-just-created'])) {
      this.element.focus();
      this.element.setSelectionRange(this.element.value.length, this.element.value.length);
    }
  },
  // Do this on blur or else it'll re-render a non-focused field on top.
  focusOut() {
    this._super();
    if (this.unset) {
      set(this, 'isJustCreated', undefined);
    }
  },
  //endregion
});
