import { action } from '@ember/object';
import Component from '@glimmer/component';
import enums from 'ember-cli-ss-enums/services/enums';

export default class WrapperSettingsComponent extends Component {
  @action
  editWrapperType(value) {
    this.args.editWrapperType(enums.findWhere('WRAPPER_METHOD_TYPE', { name: value }, 'id'));
  }
}
