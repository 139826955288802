import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.values}}\n  <div class=\"flex items-center flex-wrap gap-2 mt-2\" ...attributes>\n    {{#each this.values key=\"icon\" as |value|}}\n      <div class=\"relative flex items-center justify-center w-4.5 leading-none\" test-value>\n        {{#if (starts-with value.icon \"ssIcon\")}}\n          <div class=\"ssIcon {{value.icon}} !text-lg !leading-none text-gray-500\"></div>\n        {{else}}\n          <Ui::Icon @name={{value.icon}} class=\"text-lg text-gray-500\" />\n        {{/if}}\n\n        {{#if (gt value.count 1)}}\n          <span\n            class=\"absolute -bottom-0.5 -right-1 py-0.5 px-[3px] min-w-[12px] bg-theme text-white text-[8px] text-center rounded-full\"\n          >\n            {{value.count}}\n          </span>\n        {{/if}}\n\n        <Ui::Tooltip @text={{value.value}} />\n      </div>\n    {{/each}}\n  </div>\n{{/if}}\n", {"contents":"{{#if this.values}}\n  <div class=\"flex items-center flex-wrap gap-2 mt-2\" ...attributes>\n    {{#each this.values key=\"icon\" as |value|}}\n      <div class=\"relative flex items-center justify-center w-4.5 leading-none\" test-value>\n        {{#if (starts-with value.icon \"ssIcon\")}}\n          <div class=\"ssIcon {{value.icon}} !text-lg !leading-none text-gray-500\"></div>\n        {{else}}\n          <Ui::Icon @name={{value.icon}} class=\"text-lg text-gray-500\" />\n        {{/if}}\n\n        {{#if (gt value.count 1)}}\n          <span\n            class=\"absolute -bottom-0.5 -right-1 py-0.5 px-[3px] min-w-[12px] bg-theme text-white text-[8px] text-center rounded-full\"\n          >\n            {{value.count}}\n          </span>\n        {{/if}}\n\n        <Ui::Tooltip @text={{value.value}} />\n      </div>\n    {{/each}}\n  </div>\n{{/if}}\n","moduleName":"partner/components/ballot/setup/field-value-icons.hbs","parseOptions":{"srcName":"partner/components/ballot/setup/field-value-icons.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type CurrentService from 'partner/services/current';
import MatchupEntryModel from 'partner/models/matchup-entry';
import type VotingEntryModel from 'partner/models/voting-entry';

type Value = {
  icon: string;
  value: string;
  count?: number;
};

interface BallotSetupFieldValueIconsSignature {
  Element?: HTMLDivElement;
  Args: {
    entry: MatchupEntryModel | VotingEntryModel;
  };
}

export default class BallotSetupFieldValueIconsComponent extends Component<BallotSetupFieldValueIconsSignature> {
  @service declare current: CurrentService;

  get entry() {
    return this.args.entry instanceof MatchupEntryModel
      ? new MatchupEntryAdapter(this.args.entry)
      : new VotingEntryAdapter(this.args.entry);
  }

  get values(): Value[] {
    return [
      {
        icon: 'place',
        value: [
          this.entry.entryLocations.firstObject?.fullAddress,
          this.entry.locationsCount > 1
            ? `and ${this.entry.locationsCount - 1} more location${this.entry.locationsCount != 2 ? 's' : ''}`
            : '',
        ]
          .filter(Boolean)
          .join(' '),
        count: this.entry.locationsCount,
      },
      { icon: 'phone', value: this.entry.phoneNumber },
      { icon: 'language', value: this.entry.websiteUrl },
      {
        icon: 'ssIcon-facebook',
        value: this.current.organizationPromotion?.enableFacebookLikeUrl && this.entry.facebookUrl,
      },
      {
        icon: 'ssIcon-twitter',
        value: this.current.organizationPromotion?.enableTwitterHandle && this.entry.twitterUsername,
      },
      {
        icon: 'ssIcon-instagram-alt',
        value: this.current.organizationPromotion?.enableInstagramHandle && this.entry.instagramUsername,
      },
      {
        icon: 'ssIcon-youtube-play',
        value: this.current.organizationPromotion?.enableYoutubeChannelUrl && this.entry.youtubeUrl,
      },
      {
        icon: 'ssIcon-tiktok',
        value: this.current.organizationPromotion?.enableTiktokHandle && this.entry.tikTokHandle,
      },
      {
        icon: 'ssIcon-snapchat',
        value: this.current.organizationPromotion?.enableSnapchatHandle && this.entry.snapchatHandle,
      },
      {
        icon: 'ssIcon-linkedin-sign',
        value: this.current.organizationPromotion?.enableLinkedinProfileUrl && this.entry.linkedInUrl,
      },
      {
        icon: 'ssIcon-vimeo',
        value: this.current.organizationPromotion?.enableVimeoChannelUrl && this.entry.vimeoChannelUrl,
      },
    ].filter(({ value }) => value);
  }
}

interface EntryAdapter {
  phoneNumber: string | number | boolean | undefined;
  websiteUrl: string | number | boolean | undefined;
  facebookUrl: string | number | boolean | undefined;
  twitterUsername: string | number | boolean | undefined;
  instagramUsername: string | number | boolean | undefined;
  youtubeUrl: string | number | boolean | undefined;
  tikTokHandle: string | number | boolean | undefined;
  linkedInUrl: string | number | boolean | undefined;
  vimeoChannelUrl: string | number | boolean | undefined;
  locationsCount: number;
}

class MatchupEntryAdapter implements EntryAdapter {
  constructor(protected record: MatchupEntryModel) {}

  get phoneNumber() {
    return this.record.phoneNumber?.value;
  }

  get websiteUrl() {
    return this.record.websiteUrl?.value;
  }

  get facebookUrl() {
    return this.record.facebookUrl?.value;
  }

  get twitterUsername() {
    return this.record.twitterUsername?.value;
  }

  get instagramUsername() {
    return this.record.instagramUsername?.value;
  }

  get youtubeUrl() {
    return this.record.youtubeUrl?.value;
  }

  get tikTokHandle() {
    return this.record.tikTokHandle?.value;
  }

  get snapchatHandle() {
    return this.record.snapchatHandle?.value;
  }

  get linkedInUrl() {
    return this.record.linkedInUrl?.value;
  }

  get vimeoChannelUrl() {
    return this.record.vimeoChannelUrl?.value;
  }

  get locationsCount() {
    return this.record.entryLocations.get('length');
  }

  get entryLocations() {
    return this.record.entryLocations;
  }
}

class VotingEntryAdapter implements EntryAdapter {
  constructor(protected record: VotingEntryModel) {}

  get phoneNumber() {
    return this.record.phone;
  }

  get websiteUrl() {
    return this.record.websiteUrl;
  }

  get facebookUrl() {
    return this.record.facebookUrl;
  }

  get twitterUsername() {
    return this.record.twitterHandle;
  }

  get instagramUsername() {
    return this.record.instagramHandle;
  }

  get youtubeUrl() {
    return this.record.youtubeChannelUrl;
  }

  get tikTokHandle() {
    return this.record.tiktokHandle;
  }

  get snapchatHandle() {
    return this.record.snapchatHandle;
  }

  get linkedInUrl() {
    return this.record.linkedinProfileUrl;
  }

  get vimeoChannelUrl() {
    return this.record.vimeoChannelUrl;
  }

  get locationsCount() {
    return this.record.entryLocations.get('length');
  }

  get entryLocations() {
    return this.record.entryLocations;
  }
}
