import { isEmpty } from '@ember/utils';
import moment from 'moment';

export const days = moment.weekdaysShort().map(x => x.toLowerCase());

export const months = moment.monthsShort().map(x => x.toLowerCase());

export const DAYS_IN_WEEK = 7;

const up = x => x.toString().toUpperCase();

/**
 *
 * @param pattern
 * @returns {ParsedWeeklyPattern}
 */
export function parseWeekly(pattern) {
  /**
   * @typedef {Object} ParsedWeeklyPattern
   * @param {Boolean} isWeekly
   * @param {Boolean} mon
   * @param {Boolean} tue
   * @param {Boolean} wed
   * @param {Boolean} thu
   * @param {Boolean} fri
   * @param {Boolean} sat
   * @param {Boolean} sun
   */
  const ret = {
    isWeekly: true,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  };

  if (!isEmpty(pattern)) {
    pattern
      .split(',')
      .map(x => x.toLowerCase())
      .forEach(day => (ret[day] = true));
  }

  return ret;
}

/**
 * @param pattern
 * @returns {ParsedMonthlyDatePattern}
 */
export function parseMonthlyDate(pattern) {
  /**
   * @typedef {Object} ParsedMonthlyDatePattern
   * @param {Boolean} isMonthly
   * @param {Boolean} isDateBased
   * @param {Number} date
   */
  return {
    isMonthly: true,
    isDateBased: true,
    number: window.parseInt(pattern, 10),
  };
}

/**
 * @param pattern
 * @returns {ParsedMonthlyWeekdayPattern}
 */
export function parseMonthlyWeekday(pattern) {
  const weekday = up(days.find(day => pattern.indexOf(up(day)) > -1));
  const [occurrence] = pattern.split(up(weekday));
  const weekdayIndex = moment.weekdaysShort().map(up).indexOf(up(weekday));
  /**
   * @typedef {Object} ParsedMonthlyWeekdayPattern
   * @param {Boolean} isMonthly
   * @param {Boolean} isWeekdayBased
   * @param {Number} occurrence
   * @param {String} weekday - a 3-letter code such as MON
   * @param {Number} weekdayIndex - An index where 0 is Sunday, through 6 is Saturday.
   */
  return {
    isMonthly: true,
    isWeekdayBased: true,
    occurrence,
    weekdayIndex,
    weekday,
  };
}

/**
 *
 * @param pattern
 * @returns {ParsedMonthlyDatePattern|ParsedMonthlyWeekdayPattern}
 */
export function parseMonthly(pattern) {
  if (days.any(day => pattern.indexOf(up(day)) > -1)) {
    return parseMonthlyWeekday(pattern);
  }
  return parseMonthlyDate(pattern);
}

/**
 * @param pattern
 * @returns {ParsedYearlyDatePattern}
 */
export function parseYearly(pattern) {
  const month = up(months.find(month => pattern.indexOf(up(month)) > -1));
  const number = parseInt(pattern.split(up(month))[1], 10);
  const monthIndex = moment.monthsShort().map(up).indexOf(up(month));
  /**
   * @typedef {Object} ParsedYearlyDatePattern
   * @param {Boolean} isYearly
   * @param {Number} number
   * @param {String} month - a 3-letter code such as JAN
   * @param {Number} monthIndex - An index where 0 is January, through 11 is December.
   */
  return {
    isYearly: true,
    number,
    month,
    monthIndex,
  };
}

export default {
  parseWeekly,
  parseYearly,
  parseMonthly,
};
