import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AudienceCommonController extends Controller {
  queryParams = ['statusTypeId', 'pageIndex', 'sortColumn', 'sortDirection', 'isSegmentedAudience', 'searchValue'];

  @tracked pageIndex = 1;
  @tracked sortColumn = null;
  @tracked isSegmentedAudience = false;
  @tracked searchValue = null;
  @tracked sortDirection = null;
  @tracked showFilters = false;

  @action
  onApplyFilters({ sortColumn, sortDirection, searchValue }) {
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;
    this.searchValue = searchValue;
    this.pageIndex = 1;
  }

  @action
  onPageChange(page) {
    this.pageIndex = page;
  }
}
