/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { computed } from '@ember/object';
import Attribute from 'partner/components/template-builder/attribute-editor/attribute';
import { insertIf } from 'secondstreet-common/utils/functional';

const ATTRS_WITH_TRANSPARENT = [
  'background-color',
  'button-background-color',
  'layout-background-color',
  'layout-background-color-alternating',
];

export default Attribute.extend({
  //region Attributes
  disabled: false,
  'primary-color': null,
  'secondary-color': null,
  //endregion

  //region Computed Properties
  colorOptions: computed('attr-name', function () {
    const attrName = this['attr-name'];
    return [
      ...insertIf(
        ATTRS_WITH_TRANSPARENT.includes(attrName) || (attrName === 'button-color' && !this['article-category']),
        {
          label: 'None',
          value: 'transparent',
        }
      ),
      ...insertIf(attrName === 'icon-color', {
        label: 'Brand Colors',
        value: 'BRAND_COLORS',
      }),
      {
        label: 'Primary',
        value: '{{colorCodePrimary}}',
      },
      {
        label: 'Secondary',
        value: '{{colorCodeSecondary}}',
      },
      {
        label: 'Custom',
        value: 'custom',
      },
    ];
  }),

  selectedColorOption: computed('value', function () {
    const { value } = this;
    if (value === undefined || value === 'transparent') {
      return 'transparent';
    }
    if (value === '{{colorCodePrimary}}' || value === '{{colorCodeSecondary}}' || value === 'BRAND_COLORS') {
      return value;
    }
    return 'custom';
  }),

  /**
   * Search the attribute name for string "border-color" to confirm if it is border;
   * takes into account the fact that some attributes have a prefix. e.g. "image-border-color"
   * @param attrName
   */
  isBorderColor: computed('attr-name', function () {
    return /border-color/.test(this['attr-name']);
  }),
  //endregion

  //region Actions
  actions: {
    selectedColorOptionChanged(option) {
      if (option.value === 'custom') {
        const hex = {
          '{{colorCodePrimary}}': this['primary-color'],
          '{{colorCodeSecondary}}': this['secondary-color'],
          BRAND_COLORS: this['primary-color'],
        };
        this['set-attribute'](this['attr-name'], hex[this.value] || '#ffffff');
      } else {
        this['set-attribute'](this['attr-name'], option.value);
      }
    },
    setColor(hex) {
      this['set-attribute'](this['attr-name'], hex);
    },
  },
  //endregion
});
