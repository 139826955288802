import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import ApplicationSerializer from 'partner/serializers/application';

export default class MatchupSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  normalize(modelClass: any, resourceHash: any) {
    const resourceHashWithDefaults = {
      help_text: '',
      ...resourceHash,
    };

    return super.normalize(modelClass, resourceHashWithDefaults);
  }
}
