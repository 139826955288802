/* eslint-disable ember/no-computed-properties-in-native-classes */
import { AsyncBelongsTo, attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import BaseModel from 'secondstreet-common/models/base';
import type EntryFieldValueModel from './entry-field-value';
import type MatchupModel from './matchup';
import type MatchupEntryModel from './matchup-entry';

@makeBooleanProperties('STATUS_TYPE')
export default class EntryModel extends BaseModel {
  @attr('string') declare name: string;
  @attr('number') declare statusTypeId: number;
  @attr('string') declare fullAddress: string;
  @attr() declare location: any;

  // Relationships //latest usage on route organizationPromotion.matchup-entries
  @belongsTo('organization-user', { async: false }) declare organizationUsers: any;
  @alias('organizationUsers') declare organizationUser: EntryModel['organizationUsers'];

  @belongsTo('organization-promotion-user', { async: false }) declare organizationPromotionUsers: any;
  @alias('organizationPromotionUsers') declare organizationPromotionUser: EntryModel['organizationPromotionUsers'];

  @hasMany('entry-field-value', { async: false, inverse: 'entry' })
  declare entryFieldValues: SyncHasMany<EntryFieldValueModel>;

  //region Relationships   //previous usage, for changing qualification on route matchup.entries
  @belongsTo('promotion', { async: true }) declare promotion: AsyncBelongsTo<any>;
  @belongsTo('matchup', { async: true }) declare matchup: AsyncBelongsTo<MatchupModel>;
  @belongsTo('matchup-entry', { async: false, inverse: 'entries' }) declare matchupEntry: MatchupEntryModel;

  @computed('entryFieldValues.@each.isDirty')
  get hasDirtyEntryFieldValues() {
    return this.entryFieldValues.toArray().some(entryFieldValue => entryFieldValue.isDirty);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    entry: EntryModel;
  }
}
