/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { set } from '@ember/object';
import PermittedComponent from 'partner/mixins/permitted-component';

export default Component.extend(PermittedComponent, {
  //region Ember Hooks
  tagName: 'expansion-panel-list',
  init() {
    this._super(...arguments);
    const defaultActiveItem = this['default-active-item'];
    if (defaultActiveItem) {
      set(this, 'activeItem', defaultActiveItem);
      this['panel-opened'](defaultActiveItem);
    }
  },
  //endregion

  //region Properties
  activeItem: null,
  //endregion

  //region Attributes
  'actions-disabled': null,
  'default-active-item': null,
  'panel-closed'() {},
  'panel-opened'() {},
  //endregion

  actions: {
    toggleActiveItem(item) {
      if (!this['actions-disabled']) {
        if (item === this.activeItem) {
          //Item already active, so clear it out in order to close the expanded item
          set(this, 'activeItem', null);
          this['panel-closed']();
        } else {
          set(this, 'activeItem', item);
          this['panel-opened'](item);
        }
      }
    },
  },
});
