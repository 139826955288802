import { modifier } from 'ember-modifier';
import scrollIntoView, { type StandardBehaviorOptions } from 'scroll-into-view-if-needed';

export default modifier(
  (
    element,
    [target]: [string],
    { options = { scrollMode: 'if-needed', behavior: 'smooth' } }: { options: StandardBehaviorOptions }
  ) => {
    const targetElement = document.querySelector(target);

    if (targetElement) scrollIntoView(targetElement, options);
  },
  { eager: false }
);
