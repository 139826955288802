import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"grid grid-cols-[1fr_auto_1fr] items-center\" ...attributes>\n  <div role=\"presentation\"></div>\n  <TemplateDesigner::Controls::ButtonGroup\n    @items={{this.deviceTypes}}\n    @selectedKey={{@selectedDeviceType}}\n    @materialIcon={{true}}\n  />\n  <span class=\"text-gray-500 font-semibold mx-2 min-w-[106px]\">\n    {{this.displayDeviceWidth}}\n  </span>\n</div>", {"contents":"<div class=\"grid grid-cols-[1fr_auto_1fr] items-center\" ...attributes>\n  <div role=\"presentation\"></div>\n  <TemplateDesigner::Controls::ButtonGroup\n    @items={{this.deviceTypes}}\n    @selectedKey={{@selectedDeviceType}}\n    @materialIcon={{true}}\n  />\n  <span class=\"text-gray-500 font-semibold mx-2 min-w-[106px]\">\n    {{this.displayDeviceWidth}}\n  </span>\n</div>","moduleName":"partner/components/template-designer/device-selector.hbs","parseOptions":{"srcName":"partner/components/template-designer/device-selector.hbs"}});
import Component from '@glimmer/component';
import { DeviceType } from '@ss/arc/arc/types';

export interface DeviceSelectorSignature {
  Element: HTMLDivElement;
  Args: {
    previewMode: boolean;
    selectedDeviceType: DeviceType;
    onDeviceChange: (deviceType: DeviceType) => void;
  };
}

export default class DeviceSelectorComponent extends Component<DeviceSelectorSignature> {
  get deviceTypes() {
    const inEditDeviceTypes = [
      { icon: 'desktop_mac', key: 'desktop', onSelect: () => this.args.onDeviceChange('desktop') },
      { icon: 'tablet_mac', key: 'tablet', onSelect: () => this.args.onDeviceChange('tablet') },
      { icon: 'phone_iphone', key: 'mobile', onSelect: () => this.args.onDeviceChange('mobile') },
    ];

    return this.args.previewMode
      ? [
          ...inEditDeviceTypes,
          {
            icon: 'phone_iphone',
            key: 'mobile_landscape',
            onSelect: () => this.args.onDeviceChange('mobile_landscape'),
            customClass: '[&_i]:-rotate-90',
          },
        ]
      : inEditDeviceTypes;
  }

  get displayDeviceWidth() {
    return this.args.selectedDeviceType == 'desktop'
      ? '960px'
      : this.args.selectedDeviceType == 'tablet'
      ? '768px x 1024px'
      : this.args.selectedDeviceType == 'mobile'
      ? '390px x 844px'
      : '844px x 390px';
  }
}
