import Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action, setProperties } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import DesignTemplateModel from 'partner/models/design-template';
import SettingModel from 'partner/models/setting';
import { Model } from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/designs';
import type TemplateDesignerService from 'partner/services/template-designer';
import type SnackbarService from 'secondstreet-common/services/snackbar';

export default class SetupDesignsController extends Controller {
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare snackbar: SnackbarService;
  @service declare permissions: any;
  @service declare templateDesigner: TemplateDesignerService;

  declare model: Model;

  @tracked showTemplateSwitcher = false;
  @tracked templateForPreview?: DesignTemplateModel;

  useOldTemplateSetting?: SettingModel;

  get canAdminister() {
    return this.permissions.getAccessLevel('MessageBodyTemplate,MessageBodyTemplateToken,Token').administer;
  }

  saveTemplateSettingTask = task({ drop: true }, async bool => {
    if (this.useOldTemplateSetting) {
      this.useOldTemplateSetting.value = bool;
      await this.useOldTemplateSetting.save();
      if (bool) {
        await this.router.transitionTo(
          'organizations.organization.organization-promotions.organization-promotion.setup.designs.legacy'
        );
      }
    }
  });

  selectTemplateTask = task({ drop: true }, async (template: DesignTemplateModel) => {
    this.templateForPreview = undefined;
    const newDesign = this.store.createRecord('design');
    setProperties(newDesign, {
      designTemplate: template,
      renderedContent: template.templateContent,
    });
    try {
      await newDesign.save();
      await this.templateDesigner.setupDefaults(newDesign);

      void this.router.refresh();
    } catch (e) {
      this.snackbar.exception(e);
    }
  });

  @action
  onPreviewTemplate(template: DesignTemplateModel) {
    this.templateForPreview = template;
  }

  @action
  onClosePreview() {
    this.templateForPreview = undefined;
  }
}
