import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type { Model } from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/ballot/matchup-entries/new';
import type MatchupEntryModel from 'partner/models/matchup-entry';

export default class SetupBallotMatchupEntriesNewController extends Controller {
  @service declare router: RouterService;

  declare model: Model;

  @action
  handleSave(matchupEntry: MatchupEntryModel) {
    this.send('handleMatchupEntryCreation', matchupEntry);
  }

  @action
  handleClose() {
    if (this.model.matchupEntry.get('isNew')) {
      this.model.matchupEntry.destroyRecord();
    }

    void this.router.transitionTo(
      'organizations.organization.organization-promotions.organization-promotion.setup.ballot'
    );
  }
}
