import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SetupTotpSuccessRoute extends Route {
  @service session;
  @service router;

  redirect() {
    if (!this.session.isAuthenticated) {
      this.router.replaceWith('login');
    } else if (this.session.attemptedTransition?.targetName !== 'reset-password') {
      if (!this.session.isTwoFactorUser) {
        this.router.replaceWith('index');
      } else if (!this.session.isTwoFactorAuthRequired) {
        this.router.replaceWith('users.user', this.session.data?.authenticated?.organization_users.user_id);
      } else if (!this.session.isTwoFactorAuthDeviceSetUp) {
        this.router.replaceWith('setup.totp');
      } else if (this.session.isTwoFactorAuthIncomplete) {
        this.router.replaceWith('login.totp');
      }
    }
  }
}
