/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default Route.extend({
  //region Dependencies
  session: service(),
  store: service(),
  enums: service(),
  //endregion

  //region Ember Hooks
  model() {
    const messageCampaign = get(
      this.modelFor('organizations.organization.message-campaigns.message-campaign'),
      'messageCampaign'
    );
    const messageCampaignId = get(messageCampaign, 'id');
    return RSVP.hash({
      messageCampaign,
      messages: this.store.query('message', { messageCampaignId }),
      singleMessageCampaign: get(
        this.modelFor('organizations.organization.message-campaigns.message-campaign'),
        'singleMessageCampaign'
      ),
      categoricalCharts: this.store.query('categorical-chart', { messageCampaignId }),
      messagingStatistics: this.store.query('messaging-statistic', { messageCampaignId }),
      checklist: this.store.query('checklist', { messageCampaignId }).then(results => get(results, 'firstObject')),
    }).then(hash => {
      set(hash, 'messagingStatistic', get(get(hash, 'messagingStatistics').sortBy('id'), 'lastObject'));
      hash.categoricalCharts.forEach(chart => set(chart, 'rawCategories', undefined));
      hash.sortedMessages = hash.messages.sortBy('id');
      if (get(hash.messageCampaign, 'isNewsletter')) {
        const scheduleInstances = this.loadScheduleInstances(
          this.generateScheduleInstanceParameters('ScheduleInstance')
        );
        hash.scheduleInstances = scheduleInstances.then(scheduleInstances => scheduleInstances.scheduleInstances);
        hash.meta = scheduleInstances.then(scheduleInstances => scheduleInstances.meta);
      }
      if (get(hash.messageCampaign, 'isWelcome') || get(hash.messageCampaign, 'isDripCampaign')) {
        const messages = this.loadScheduleInstances(this.generateScheduleInstanceParameters('Message'));
        hash.messageLevelMessagingStatistics = messages.then(messages => {
          // Each messageLevelMessagingStatistic should have a rank, which is based on its parent message's ranking
          // within the collection of messages
          const { sortedMessages } = hash;
          const { scheduleInstances } = messages;
          sortedMessages.forEach((message, index) => {
            set(message, 'rank', index + 1);
          });
          scheduleInstances.forEach(instance => {
            const parentMessage = sortedMessages.findBy('id', get(instance, 'messageId').toString());
            if (parentMessage) {
              set(instance, 'rank', get(parentMessage, 'rank'));
            }
          });
          return messages.scheduleInstances;
        });
        hash.meta = messages.then(messages => messages.meta);
      }

      return RSVP.hash(hash);
    });
  },
  async afterModel(model) {
    const hasSent = get(model, 'messages').any(
      message =>
        get(message, 'isSent') ||
        get(message, 'isSending') ||
        get(message, 'isSentAndScheduled') ||
        get(message, 'isTestSend')
    );

    if (get(model, 'messageCampaign.isWelcome') || hasSent) {
      const statistics = get(model, 'messagingStatistic');

      if (get(model, 'messageCampaign.usesSingleMessageCampaign') && get(statistics, 'peopleSentToCount') >= 1) {
        //If there is only one version, testWinnerMessageVersionId will return 0 and it will fall back to messageVersion
        const messageVersionId =
          get(statistics, 'testWinnerMessageVersionId') || parseInt(get(statistics, 'messageVersion.id'), 10);
        const messageVersionHistory = await this.store.query('message-version-history', { messageVersionId });
        set(model, 'messageVersionHistory', messageVersionHistory);
      }
    }
  },
  //endregion

  //region Methods
  /**
   * Return a hash containing a list of schedule instances and its associated paging meta.
   */
  loadScheduleInstances(scheduleInstanceParams) {
    return this.store.query('messaging-statistic', scheduleInstanceParams).then(scheduleInstances => {
      const meta = get(scheduleInstances, 'meta');
      return { scheduleInstances, meta };
    });
  },

  generateScheduleInstanceParameters(statisticsLevelType) {
    // Populate a request object with shared request parameters
    const messageCampaignModel = this.modelFor('organizations.organization.message-campaigns.message-campaign');
    const messageCampaign = get(messageCampaignModel, 'messageCampaign');
    const messageCampaignId = get(messageCampaign, 'id');
    const messagingStatisticsLevelTypeId = this.enums.findWhere('MESSAGING_STATISTICS_LEVEL_TYPE', {
      name: statisticsLevelType,
    });
    const sortDirection = get(messageCampaign, 'isDripCampaign') ? 'asc' : 'desc';
    const sortColumn = get(messageCampaign, 'isNewsletter') ? 'scheduleInstanceDate' : 'id';
    const messageId = get(messageCampaign, 'isWelcome')
      ? { messageId: get(messageCampaignModel, 'messages.firstObject.id') }
      : {};
    return {
      ...messageId,
      messageCampaignId,
      messagingStatisticsLevelTypeId,
      pageIndex: 1,
      pageSize: 10,
      sortDirection,
      sortColumn,
    };
  },
  //endregion

  //region Actions
  actions: {
    updateScheduleInstancePage(page, statisticsLevelType, sortParameter, direction) {
      const params = this.generateScheduleInstanceParameters(statisticsLevelType);
      set(this, 'controller.isLoadingSchedule', true);
      set(params, 'pageIndex', page);
      if (sortParameter && direction) {
        set(params, 'sortColumn', sortParameter);
        set(params, 'sortDirection', direction);
      }
      return this.loadScheduleInstances(params).then(sourceData => {
        const { scheduleInstances } = sourceData;
        const model = get(this, 'controller.model');
        if (get(model, 'messageCampaign.isDripCampaign') || get(model, 'messageCampaign.isWelcome')) {
          // Assign numeric rank to the message instance based on its parent message
          const sortedMessages = get(this, 'controller.model.sortedMessages');
          scheduleInstances.forEach(instance => {
            const parentMessage = sortedMessages.findBy('id', get(instance, 'messageId').toString());
            set(instance, 'rank', get(parentMessage, 'rank'));
          });
          set(model, 'messageLevelMessagingStatistics', scheduleInstances);
        } else {
          set(model, 'scheduleInstances', scheduleInstances);
        }
        set(model, 'meta', sourceData.meta);
        set(this, 'controller.isLoadingSchedule', false);
      });
    },
    refreshStats() {
      const messageCampaign = get(
        this.modelFor('organizations.organization.message-campaigns.message-campaign'),
        'messageCampaign'
      );
      const messageCampaignId = get(messageCampaign, 'id');
      this.store.query('messaging-statistic', { messageCampaignId });
    },
  },
  //end Region
});
