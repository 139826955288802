import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div hidden {{did-insert this.track}} {{did-update this.track @flag @callback}} {{will-destroy this.reset}}></div>\n", {"contents":"<div hidden {{did-insert this.track}} {{did-update this.track @flag @callback}} {{will-destroy this.reset}}></div>\n","moduleName":"partner/components/dirty-tracker.hbs","parseOptions":{"srcName":"partner/components/dirty-tracker.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type DirtyTrackerService from 'partner/services/dirty-tracker';

interface DirtyTrackerSignature {
  Element: HTMLDivElement;
  Args: {
    flag: boolean;
    callback?: () => void;
  };
}

export default class DirtyTrackerComponent extends Component<DirtyTrackerSignature> {
  @service declare dirtyTracker: DirtyTrackerService;

  @action
  track() {
    this.dirtyTracker.track({ flag: this.args.flag, callback: this.args.callback });
  }

  @action
  reset() {
    this.dirtyTracker.reset();
  }
}
