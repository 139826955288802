/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty } from '@ember/utils';
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo, hasMany } = DS;

/**
 * @typedef {DS.Model} MessageBodyTemplate
 * @implements DesignTemplateModel
 */
export default BaseModel.extend({
  //region Properties
  body: attr('string'),
  isInherited: attr('boolean'),
  statusTypeId: attr('number'),
  name: attr('string'),
  thumbnail: attr('number'),
  maxItemsAllowed: attr('number'),
  mjmlJson: attr('string'),
  isFreeTemplateForPromotions: attr('boolean'),
  //endregion

  //region Relationships
  tokens: hasMany('token', {
    async: false,
  }),
  organization: belongsTo('organization', {
    async: false,
  }),
  thumbnailMediaItem: belongsTo('media-item'),
  //endregion

  //region Dependencies
  enums: service(),
  //endregion

  //region Computed Properties
  statusType: enums.computed('name', 'statusTypeId', 'statusTypeId', 'STATUS_TYPE'),
  templateContent: alias('body'),
  thumbnailUrl: computed('name', function () {
    const thumbnailMediaItemId = get(this, 'thumbnailMediaItem.id');

    if (thumbnailMediaItemId) {
      return undefined;
    }

    let url;
    switch (this.name) {
      case 'Bands':
        url = 'https://media.secondstreetapp.com/78102?width=290&height=472';
        break;
      case 'Boxes':
        url = 'https://media.secondstreetapp.com/89051?width=290&height=472';
        break;
      case 'Brief':
        url = 'https://media.secondstreetapp.com/145838?width=290&height=472';
        break;
      case 'Frames':
        url = 'https://media.secondstreetapp.com/89049?width=290&height=472';
        break;
      case 'Gallery':
        url = 'https://media.secondstreetapp.com/78100?width=290&height=472';
        break;
      case 'Memo':
        url = 'https://media.secondstreetapp.com/78091?width=290&height=472';
        break;
      case 'Note':
        url = 'https://media.secondstreetapp.com/133797?width=290&height=472';
        break;
      case 'Points':
        url = 'https://media.secondstreetapp.com/89055?width=290&height=472';
        break;
      case 'Sidebar':
        url = 'https://media.secondstreetapp.com/78101?width=290&height=472';
        break;
      case 'Simple':
        url = 'https://media.secondstreetapp.com/89053?width=290&height=472';
        break;
      case 'Spotlight':
        url = 'https://media.secondstreetapp.com/78103?width=290&height=472';
        break;
      case 'Stacked':
        url = 'https://media.secondstreetapp.com/89052?width=290&height=472';
        break;
      default:
        url = 'https://media.secondstreetapp.com/78198?width=290&height=472';
        break;
    }

    return url;
  }),
  isNameDirty: computed('name', function () {
    // Force it to pretend not to be dirty if the name is blank.
    return isBlank(this.name) ? false : !isEmpty(this.changedAttributes().name);
  }),
  nameStatus: computed('name', function () {
    return isBlank(this.name) ? 'incomplete' : 'bestPractice';
  }),
  isSaveEnabled: computed('nameStatus', function () {
    return this.nameStatus !== 'incomplete';
  }),
  accommodatesMultipleItems: computed('tokens.@each.tokenContentTypeId', 'maxItemsAllowed', function () {
    const itemTokenContentTypeId = this.enums.findWhere('TOKEN_CONTENT_TYPE', { name: 'Item' }, 'id');
    return this.tokens.isAny('tokenContentTypeId', itemTokenContentTypeId) && this.maxItemsAllowed !== 1;
  }),
  //endregion
});
