/* global Redactor */

Redactor.add('plugin', 'clearformat', {
  defaults: {
    disallowedTags: ['b', 'i', 'u', 'pre', 'del', 'sub', 'sup', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
  },
  start() {
    this.app.hotkeys.add('ctrl+shift+m, meta+shift+m]', {
      title: 'Clean Format',
      name: 'meta+shift+m',
      command: 'clearformat.clean',
    });
  },
  clean() {
    this.app.dropdown.close();

    if (this.app.blocks.is()) {
      this.cleanMultipleBlocks(this.app.blocks.get({ selected: true, instances: true }));

      return;
    }

    this.cleanBlock(this.app.block.get(), true);
  },
  cleanMultipleBlocks(instances) {
    instances.forEach(instance => {
      this.cleanBlock(instance, true);
    });
  },
  cleanBlock(instance, isRoot = false) {
    const $block = isRoot ? instance.getBlock() : instance;

    $block.contents().forEach($child => {
      this.cleanBlock(this.app.dom($child));
    });

    const $element = $block.get();

    if ($element.className || $element.style) {
      $element.removeAttribute('style');
      $element.removeAttribute('data-style');
      $element.removeAttribute('data-rx-style-cache');
      $element.removeAttribute('class');
    }

    if ($element.tagName === 'A') $element.className = 'underline';

    if (this.opts.get('clearformat.disallowedTags').includes($element.tagName?.toLowerCase())) {
      if (isRoot) {
        const $newBlock = this.app.create('block.text');
        $newBlock.setContent($element.innerHTML);
        instance.change($newBlock);

        return;
      }

      $element.outerHTML = $element.innerHTML;
    }
  },
});
