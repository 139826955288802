/* eslint-disable ember/no-classic-classes, ember/no-observers, */
/* globals FB */
import { getOwner } from '@ember/application';
import { A } from '@ember/array';
import ArrayProxy from '@ember/array/proxy';
import { set } from '@ember/object';
import { computed, observer, setProperties } from '@ember/object';
import { equal, gt } from '@ember/object/computed';
import { assign } from '@ember/polyfills';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import FacebookPage from 'partner/models/facebook-page';
import RSVP from 'rsvp';
import { endpoint } from 'secondstreet-common/utils/url';

function getUserData(response) {
  // get user data
  setProperties(this, {
    user: response.name,
    picture: response.picture?.data?.url,
    userID: response.id,
  });

  return this.session.request(endpoint('third_party_sessions'), {
    type: 'POST',
    data: `{"third_party_sessions":[{"social_media_type_id":"1","social_media_user_id":"${this.userID}","request_user_access_token":"${this.accessToken}"}]}`,
    headers: getOwner(this).lookup('adapter:application')?.headers,
    contentType: 'application/json',
  });
}

function retrieveFacebookPages(response, limit) {
  // use long lived token to retrieve fb pages
  const longLived = response.external_authentication_records?.firstObject?.response_user_access_token;
  const loadFacebookPages = (offset = 0) =>
    this.api(`me/accounts?access_token=${longLived}&limit=${limit}&offset=${offset}`).then(response => {
      response?.data?.forEach(datum => {
        const { pages } = this;
        if (!pages.isAny('id', datum.id)) {
          // If no page with this ID is already there.
          pages.addObject(FacebookPage.create(assign({ facebook: this }, datum)));
        }
      });
      if (response.paging && !isEmpty(response.paging.next)) {
        // there are more fb pages, recursively call this function to load them
        loadFacebookPages(offset + limit);
      }
    });
  loadFacebookPages();
}

function retrieveInstagramPages(response, limit) {
  const longLived = response.external_authentication_records?.firstObject?.response_user_access_token;
  const loadPages = async (offset = 0) => {
    const response = await new Promise((res, rej) =>
      this.api(
        `me/accounts?fields=id,instagram_business_account{id,username},name,access_token&access_token=${longLived}&limit=${limit}&offset=${offset}`
      )
        .then(response => res(response))
        .catch(error => rej(error))
    );

    response?.data?.forEach(datum => {
      const { instagramEnabledPages } = this;
      datum.longLivedToken = longLived;
      if (!instagramEnabledPages.isAny('id', datum.id)) {
        instagramEnabledPages.addObject(datum);
      } else {
        const instagramEnabledPage = instagramEnabledPages.find(page => page.id === datum.id);
        if (datum.instagram_business_account && !instagramEnabledPage.instagramEnabledPage) {
          set(instagramEnabledPage, 'instagram_business_account', datum.instagram_business_account);
        }
      }
    });
    if (response.paging && !isEmpty(response.paging.next)) {
      loadPages(offset + limit);
    }
  };
  loadPages();
}

/**
 * Contains logic for observing and reacting to fb events.
 *
 * status is set in response to FB login attempt.
 * @see partner/app/index.html
 *
 * @typedef {Ember.Service} FacebookService
 */
export default Service.extend({
  //region Dependencies
  session: service(),
  //endregion

  //region Properties
  isInitialized: false,
  status: 'unknown',
  //Partner tool login status
  isAuthenticated: false,
  //FB auth status
  isAuthorized: equal('status', 'connected'),
  // Times the current token has been overriden
  timesCurrentTokenOverriden: 0,
  user: '',
  userID: '',
  picture: '',
  pages: null,
  instagramEnabledPages: null,
  instagramPages: computed('instagramEnabledPages.@each.{id,instagram_business_account}', function () {
    const pagesWithInstagramData = this.instagramEnabledPages.filter(page => page.instagram_business_account);
    return pagesWithInstagramData.map(page => ({
      id: page.instagram_business_account.id,
      username: page.instagram_business_account.username,
      pageId: page.id,
      pageAccessToken: page.access_token,
      userAccessToken: page.longLivedToken,
    }));
  }),
  accessToken: '',
  //endregion

  //region Hooks
  init() {
    this._super(...arguments);
    if (!this.pages) {
      this.pages = ArrayProxy.create({ content: A([]) });
    }
    if (!this.instagramEnabledPages) {
      set(this, 'instagramEnabledPages', []);
    }
  },
  //endregion

  //region Methods
  api(url) {
    return new RSVP.Promise((fulfill, reject) => {
      try {
        FB.api(url, fulfill);
      } catch (error) {
        reject(error);
      }
    });
  },
  //endregion

  //region Computed Properties
  isAuthorizedWithInstagramPermissions: gt('instagramPages.length', 0),
  //endregion

  //region Observers
  /**
   * Steps that need to happen anytime user fb auth changes.
   * End goal is to have the user's fb pages with long lived access tokens.
   * 1) get user data
   * 2) get short term access token
   * 3) exchange short term token for long lived token must be done on server side because it use app secret
   * 4) finally update the list of fb pages so that they are updated in ember with long lived tokens
   *
   * @see https://developers.facebook.com/docs/facebook-login/access-tokens#extending
   */
  isAuthorizedChanged: observer(
    'isAuthorized',
    'isInitialized',
    'isAuthenticated',
    'timesCurrentTokenOverriden',
    function () {
      const limit = 20;
      if (!this.isAuthorized) {
        setProperties(this, {
          status: 'unknown',
          user: '',
          userID: '',
          picture: '',
          pages: ArrayProxy.create({ content: A([]) }),
          accessToken: '',
        });
      }
      if (this.isAuthorized && this.isAuthenticated && this.isInitialized) {
        this.api('/me?fields=picture,name')
          .then(response => getUserData.call(this, response))
          .then(response => {
            retrieveFacebookPages.call(this, response, limit);
            retrieveInstagramPages.call(this, response, limit);
          })
          .catch(error => console.error(`Facebook Graph fetch error: ${error}`));
      }
    }
  ),
});
