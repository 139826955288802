/* global Redactor */

import '@melloware/coloris/dist/coloris.css';
import Coloris from '@melloware/coloris';

Redactor.add('plugin', 'fontcolor', {
  translations: {
    en: {
      fontcolor: {
        title: 'Font Color',
        cancel: 'Cancel',
        set: 'Set',
      },
    },
  },
  defaults: {
    tabSelectClass: 'bg-gray-100',
  },
  previousColors: {
    backgroundColor: null,
    color: null,
  },
  start() {
    const button = {
      title: '## fontcolor.title ##',
      icon: '<i class="material-icons text-lg">format_color_text</i>',
      command: 'fontcolor.popup',
      position: {
        after: 'italic',
      },
      blocks: {
        all: true,
        except: ['image', 'line', 'noneditable'],
      },
    };

    this.app.toolbar.add('fontcolor', button);
  },
  popup(_e, button) {
    const stack = this.app.create('stack');

    stack.create('fontcolor', {
      width: '200px',
    });

    const $body = stack.getBody();
    const $tabSelector = this.dom('<div class="flex"></div>');

    this.previousColors = {
      backgroundColor: null,
      color: null,
    };

    this.hsl = this.opts.get('fontcolor.hsl');
    this.hsv = this.opts.get('fontcolor.hsv');

    this.$highlightTab = this._createTab($tabSelector, 'Highlight');
    this.$textTab = this._createTab($tabSelector, 'Text');
    this.$textTab.addClass(this.opts.get('fontcolor.tabSelectClass'));

    this.$colorSelectorText = this._createColorSelector('text');
    this.$activeSelector = this.$colorSelectorText;
    this.$colorSelectorHighlight = this._createColorSelector('highlight');
    this.$colorSelectorHighlight.hide();

    $body.append($tabSelector);
    $body.append(this.$colorSelectorText);
    $body.append(this.$colorSelectorHighlight);

    this._initTabEvents();

    this.app.modal.open({ name: 'fontcolor', stack, button });

    this.inputFocused = false;

    this.app.editor.save();
    this.selection = this.app.editor.savedSelection;

    this._initColorSelector('text');
  },
  _createTab($selector, name) {
    const $tab = this.dom('<button class="grow text-center cursor-pointer hover:bg-gray-100 p-2 w-10"></button>');

    $tab.html(name);
    $selector.append($tab);

    return $tab;
  },
  _initTabEvents() {
    this.$textTab.on('click', e => {
      e.preventDefault();

      this.$highlightTab.removeClass(this.opts.get('fontcolor.tabSelectClass'));

      this.$colorSelectorHighlight.hide();
      this.$colorSelectorText.show();
      this.$activeSelector = this.$colorSelectorText;

      this._initColorSelector('text');

      this.$textTab.addClass(this.opts.get('fontcolor.tabSelectClass'));
    });

    this.$highlightTab.on('click', e => {
      e.preventDefault();

      this.$textTab.removeClass(this.opts.get('fontcolor.tabSelectClass'));

      this.$colorSelectorText.hide();
      this.$colorSelectorHighlight.show();
      this.$activeSelector = this.$colorSelectorHighlight;

      this._initColorSelector('highlight');

      this.$highlightTab.addClass(this.opts.get('fontcolor.tabSelectClass'));
    });
  },
  _initColorSelector(tab) {
    const selection = this.app.create('selection');
    const existingNode = selection.getNodes({ type: 'inline' }).firstObject;
    let defaultColor = tab === 'text' ? '#000' : '#fff';

    if (existingNode) {
      if (tab === 'text') {
        defaultColor = existingNode.style.color || '#000';
      } else {
        defaultColor = existingNode.style.backgroundColor || '#fff';
      }
    }

    Coloris({
      parent: `#coloris-${tab}`,
      alpha: false,
      formatToggle: true,
      inline: true,
      defaultColor,
      onChange: color => {
        this._setColor(color, tab);
      },
    });

    const $colorInput = this.app.modal.$modal.find('.clr-color').get();

    $colorInput.addEventListener('focus', () => (this.inputFocused = true));
    $colorInput.addEventListener('blur', () => (this.inputFocused = false));
  },
  _createColorSelector(tab) {
    // If a container already exists, Coloris will attach to the wrong element.
    this.dom(`#coloris-${tab}`)?.remove();

    const $container = this.dom(`
      <div class="relative overflow-hidden" id="coloris-${tab}"></div>
    `);

    return $container;
  },
  _setColor(color, tab) {
    if (this.inputFocused) {
      this.app.editor.$editor.get().focus();
      this.app.editor.savedSelection = this.selection;
      this.app.editor.restore();
    }

    const params = { tag: 'span', style: {} };

    if (tab === 'highlight') {
      if (color === this.previousColors.backgroundColor) return;
      params.style['background-color'] = color;
      params.style['color'] = this.previousColors.color;
      this.previousColors.backgroundColor = color;
    } else {
      if (color === this.previousColors.color) return;
      params.style['color'] = color;
      params.style['background-color'] = this.previousColors.backgroundColor;
      this.previousColors.color = color;
    }

    this.app.inline.set(params);
  },
});
