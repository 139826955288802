export const flattenTree = (array, property) => {
  const result = [];

  const collectChildren = tag => {
    result.addObject(tag);
    tag[property].forEach(collectChildren);
  };
  array.forEach(collectChildren);
  return result;
};

export default flattenTree;
