/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { computed, get, set, setProperties } from '@ember/object';
import { alias } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { isNone, isPresent } from '@ember/utils';

export default Mixin.create({
  store: service(),

  //region Computed Properties
  extraChancesForm: alias('model.extraChancesForm'),
  extraChancesFormPage: alias('extraChancesForm.formPages.firstObject'),
  activeExtraChancesFormFields: computed('extraChancesFormPage.formFields.{[],@each.extraChances}', function () {
    const formFields = get(this, 'extraChancesFormPage.formFields') || [];
    return formFields.filter(formField => Number.parseInt(get(formField, 'extraChances'), 10) > 0);
  }),
  isExtraChanceRuleDirty: computed(
    'extraChancesFormPage.formFields.[]',
    'extraChancesFormPage.formFields.@each.{isNew,hasDirtyAttributes,extraChances,extraChancesMaximum}',
    'model.extraChancesForm.deletedRecords',
    function () {
      const formFields = get(this, 'extraChancesFormPage.formFields') || [];
      if (formFields.isAny('isNew') || formFields.isAny('hasDirtyAttributes')) {
        //not new and has dirty attributes
        return true;
      }
      return (
        isPresent(formFields) && formFields.isEvery('extraChances', 0) && formFields.isEvery('extraChancesMaximum', -1)
      );
    }
  ),
  extraChanceFields: computed('extraChancesFormPage.formFields.@each.field', function () {
    const fields = get(this, 'extraChancesFormPage.formFields');
    return fields ? fields.mapBy('field') : null;
  }),
  areExtraChanceFieldsDirty: computed('extraChanceFields.@each.hasDirtyAttributes', function () {
    const fields = this.extraChanceFields;
    return fields ? fields.isAny('hasDirtyAttributes') : false;
  }),
  //endregion
  actions: {
    async addExtraChances(fieldId = 539) {
      const formPage = this.extraChancesFormPage;
      const field = await this.store.findRecord('field', fieldId);
      let formField = get(formPage, 'formFields').findBy('field.id', field.id);
      if (isNone(formField)) {
        formField = this.store.createRecord('form-field');
        setProperties(formField, {
          displayOrder: get(formPage, 'formFields.length') + 1,
          isRequired: get(field, 'isRequired'),
          labelText: get(field, 'labelText') || get(field, 'globalOptin.labelText'),
          helpText: get(field, 'helpText'),
          field,
          formPage: this['form-page'],
          isRemovable: true,
          isJustCreated: get(field, 'isNew'),
          extraChances: 1,
          extraChancesMaximum: -1,
        });
        get(formPage, 'formFields').addObject(formField);
      } else if ((get(formField, 'extraChances') || 0) < 1) {
        set(formField, 'extraChances', 1);
      }
    },
    removeFormField(formField) {
      const formPage = this.extraChancesFormPage;
      get(formPage, 'formFields').removeObject(formField);
      if (get(formField, 'isNew')) {
        formField.rollbackAttributes();
      } else {
        formField.destroyRecord();
      }
    },
  },
});
