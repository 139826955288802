import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import type { UploaderValue } from './form/uploader';

export interface TokenContentEditorForImageWithoutAltTextSignature {
  Args: {
    value?: string;
    mediaItemId?: string;
    errors?: string[]; // Not sure about this
    canAddImageViaUrl?: boolean;
    isDisabled?: boolean;
    randomNumberTokens?: any[]; // TODO: Update once models/token is typed
    updateTokenContentProperty: (key: string, value: string) => void;
  };
}

export default class TokenContentEditorForImageWithoutAltTextComponent<
  T extends TokenContentEditorForImageWithoutAltTextSignature
> extends Component<T> {
  get uploaderValue() {
    if (this.args.mediaItemId) return { type: 'image', value: this.args.mediaItemId };
    if (this.imgPreviewUrl) return { type: 'external', value: this.imgPreviewUrl };

    return null;
  }

  get imgPreviewUrl() {
    let previewURL = this.args.value || '';

    const foundTokens = (this.args.randomNumberTokens || []).filter(
      token => previewURL.indexOf(`{{${token.key as string}}}`) > -1
    );

    if (isPresent(foundTokens)) {
      foundTokens.forEach(token => {
        previewURL = previewURL.replace(`{{${token.key as string}}}`, token.id);
      });
    }

    return previewURL;
  }

  get accepts() {
    return this.args.canAddImageViaUrl ?? true ? ['image', 'external'] : ['image'];
  }

  handleImageChange = ({ type, value }: UploaderValue) => {
    this.args.updateTokenContentProperty('value', type == 'external' ? value : '');
    this.args.updateTokenContentProperty('mediaItemId', type == 'image' ? value : '');
  };
}
