import { get, setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class OrganizationsOrganizationPeoplePersonPromotionHistoryRoute extends Route {
  @service store;
  @service settings;

  //region Ember Hooks
  async model() {
    const participatingOrganizationUserId = get(
      this.modelFor('organizations.organization.people.person'),
      'consumerUser.id'
    );

    const organizationPromotions = this.store.query('organization-promotion', { participatingOrganizationUserId });
    const dipsUrl = this.settings.preload('dips_url').then(() => this.settings.getFor('dips_url'));

    return hash({ organizationPromotions, dipsUrl });
  }

  resetController(controller) {
    setProperties(controller, {
      // These requests are made per-user, so clear them out.
      matchupEntriesByOrganizationPromotion: {},
      // We are NOT clearing out OPDomains because those aren't user-specific.
    });
  }
  //endregion
}
