import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class TransitionToHelper extends Helper {
  @service('router') router;

  compute(params) {
    return maybeEvent => {
      if (maybeEvent !== undefined && typeof maybeEvent.preventDefault === 'function') {
        maybeEvent.preventDefault();
      }

      params = params.map(param => {
        if (param != null && typeof param == 'object') {
          return { queryParams: param };
        }

        return param;
      });

      return this.router.transitionTo(...params);
    };
  }
}
