/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ParamsType } from 'partner/services/preview';
import { statusText } from './ballot-group';
import { allEntrants, featuredEntrants, mainLayout } from './ballot-wide-scroll';
import { header } from './common';

const textCategory = (id: string) => {
  return {
    uniqueTypeNumber: '4',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        uniqueTypeNumber: id,
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: 'Category <i class="material-icons ml-1">keyboard_arrow_up</i>',
          additionalCss: `.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-${id} {\n display:flex; align-items: center; \n  }\n  \n  `,
        },
        styles: {
          font: {
            color: '#000000',
            weight: 700,
            family: 'Open Sans',
            size: '18',
            align: 'center',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
      },
      spacing: {
        paddingBlock: 24,
      },
      background: {
        type: 'color',
        color: '#9fa9b7',
      },
    },
  };
};

export default (params?: ParamsType) => [
  header({
    title: 'Already Voted?',
    promotionColor: params?.promotionColor,
    promotionTextColor: params?.promotionTextColor,
  }),
  mainLayout([
    {
      uniqueTypeNumber: '25',
      deletable: true,
      type: 'promotion-box',
      blocks: [
        statusText({
          marginTop: 16,
        }),
        {
          uniqueTypeNumber: '26',
          deletable: true,
          type: 'promotion-box',
          blocks: [textCategory('21'), featuredEntrants(), allEntrants()],
          data: {
            additionalCss: '.arc-promotion-promotion-0 .arc-box-box-26 {\n \n  }\n  \n  ',
          },
          styles: {
            layout: {
              display: 'flex',
              direction: 'column',
              align: 'center',
              justify: 'center',
              gapX: 20,
              gapY: 20,
            },
            border: {
              style: 'solid',
              radius: 4,
              width: 1,
              color: '#bfc6ce',
            },
            spacing: {},
          },
          dataMobile: {},
          stylesMobile: {},
          dataTablet: {},
          stylesTablet: {},
        },
      ],
      data: {
        additionalCss: '.arc-promotion-promotion-0 .arc-box-box-25 {\n  \n  }\n  \n  ',
      },
      styles: {
        layout: {
          display: 'flex',
          direction: 'column',
          align: 'center',
          justify: 'center',
          gapX: 20,
          gapY: 20,
        },
        spacing: {
          paddingInline: 16,
        },
      },
      dataMobile: {},
      stylesMobile: {},
      dataTablet: {},
      stylesTablet: {},
    },
  ]),
];
