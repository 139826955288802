/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { computed, get } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import moment from 'moment';
import { inject as service } from '@ember/service';

import WinnersCard from './winners-card';

export default WinnersCard.extend({
  defaultFormat: service('default-format'),

  // region Ember hooks
  classNames: ['sweeps-winners-card'],
  //endregion

  // region Computed properties
  winningEntryOrganizationUser: readOnly('winner.entry.organizationUser'),
  winnerCardEmptyState: computed(() => ({
    rank: 1,
    username: 'Email Address',
    firstName: 'Name',
    addressSummary: 'Address',
    phone: 'Phone Number',
  })),
  winner: computed('currentPageIndex', 'numWinners', 'winnerCardEmptyState', 'sortedWinners', function () {
    return this.numWinners ? this.sortedWinners.objectAt(this.currentPageIndex - 1) : this.winnerCardEmptyState;
  }),
  ageText: computed('winningEntryOrganizationUser.{age,birthDate}', function () {
    const age = get(this, 'winningEntryOrganizationUser.age');
    const birthDate = get(this, 'winningEntryOrganizationUser.birthDate');
    return age ? `${age} Years Old (${moment(birthDate).format(this.defaultFormat.getDateFormat())})` : null;
  }),
  nameText: computed(
    'winningEntryOrganizationUser.{username,name}',
    'winner.entry.matchupEntry.isUploadedPhoto',
    function () {
      const name = get(this, 'winningEntryOrganizationUser.name');
      const username = get(this, 'winningEntryOrganizationUser.username');
      if (get(this, 'winner.entry.matchupEntry.isUploadedPhoto')) {
        return name || null;
      }
      return name || username || null;
    }
  ),
  //endregion
});
