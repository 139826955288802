export const emptyTimeSeriesData = count => () => {
  const categories = [
    {
      value: 0,
      time: new Date(),
    },
  ];
  for (let i = 1; i < count + 1; i++) {
    categories.push({
      value: 0,
      time: new Date(categories[i - 1].time.getTime() + 24 * 60 * 60 * 1000),
    });
  }
  return categories;
};
