/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr, hasMany, InvalidError } from '@ember-data/model';
import { computed } from '@ember/object';
import { mapBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import enums from 'ember-cli-ss-enums/services/enums';
import dirtyProperty from 'partner/utils/dirty-property';
import { nestedRelationship, safeUnload } from 'partner/utils/ember-data';
import BaseModel from 'secondstreet-common/models/base';

@makeBooleanProperties('FORM_TYPE')
export default class Form extends BaseModel {
  @service store;
  @service enums;

  @attr('string') name;
  @attr('number') formTypeId;
  @attr('number') entryTypeId;
  @attr('boolean') isInherited;
  @attr('number') entrySchemaId;
  @attr('boolean') disableVideoUpload;
  @attr('boolean') disablePhotoUpload;

  deletedRecords = []; // Used when unloading or rolling back records. Manually populated.

  @hasMany('form-pages', { async: false }) formPages;

  @enums.computed('name', 'formTypeId') formType;
  @dirtyProperty('isDefault') isIsDefaultDirty;

  @computed('formPages.@each.formFields')
  get allFormFields() {
    return nestedRelationship(this.formPages.toArray(), 'formFields');
  }

  @mapBy('allFormFields', 'field') allFields;

  get formDefaultSettings() {
    return this.store.peekAll('form-default-setting').filterBy('defaultFormId', +this.id);
  }

  get defaultPromotionNames() {
    return this.formDefaultSettings.map(setting =>
      this.enums.findWhere('PROMOTION_TYPE', { id: setting.promotionTypeId }, 'publicName')
    );
  }

  save() {
    const formPages = this.formPages.toArray();
    const formFields = nestedRelationship(formPages, 'formFields');
    const fields = formFields.mapBy('field');
    const fieldOptions = nestedRelationship(fields, 'fieldOptions');
    const globalOptins = fieldOptions.mapBy('globalOptin').compact();
    const relatedRecords = []
      .concat(formPages)
      .concat(formFields)
      .concat(fields)
      .concat(fieldOptions)
      .concat(globalOptins);

    relatedRecords.forEach(record => record.errors.clear());
    return super.save().then(() => {
      const isAnythingInvalid = relatedRecords.any(record => !isEmpty(record.embeddedErrors));
      /**
       * Unloads all related records for which the function you pass returns `true`.
       * @param {Function} test - Receives a record as an argument, and if it returns true that record will be unloaded.
       */
      const unloadIfPasses = test => {
        relatedRecords.forEach(record => {
          if (test(record)) {
            //region HAX
            // When saving new form fields, the code sometimes creates a copy of them in "root.deleted.uncommitted" state from the API response
            // instead of just updating the existing record with its new id. Before we unload the old record, make sure the correct one isn't marked as deleted.
            // To recreate: Delete at least two formfields, save, add them back, save again.
            const actualRecord = this.formPages.firstObject.formFields.find(
              x =>
                x.currentState.stateName === 'root.deleted.uncommitted' &&
                x.field.id === record.field.id &&
                x.formPage.id === record.formPage.id &&
                isEmpty(record.id) &&
                !isEmpty(x.id)
            );
            if (actualRecord) {
              actualRecord.rollbackAttributes();
            }
            //endregion
            this.deletedRecords.removeObject(record);
            safeUnload(record, this.store);
          }
        });
      };

      // Unload all records that don't have an ID, as they've been obsoleted by records the API created.
      unloadIfPasses(record => record.isLoaded && isEmpty(record.id));

      // Stop tracking deleted objects
      this.deletedRecords.clear();

      // If anything within these relationships or the form is invalid, throw an error to cause the promise to reject
      if (isAnythingInvalid) {
        throw new InvalidError();
      } else if (!isEmpty(this.embeddedErrors)) {
        throw new InvalidError();
      }

      return this;
    });
  }
}
