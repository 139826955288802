/* eslint-disable ember/no-mixins, ember/no-new-mixins */
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  selectedMatchup: alias('model.selectedMatchup'),
  previousMatchup: computed('model.{matchups.[],selectedMatchup}', function () {
    const currentMatchupIndex = this.model.matchups.indexOf(this.model.selectedMatchup);
    const prevMatchupIndex = currentMatchupIndex - 1;
    return currentMatchupIndex === 0 ? this.model.matchups.lastObject : this.model.matchups.objectAt(prevMatchupIndex);
  }),
  nextMatchup: computed('model.{matchups.[],selectedMatchup}', function () {
    const currentMatchupIndex = this.model.matchups.indexOf(this.model.selectedMatchup);
    const nextMatchupIndex = currentMatchupIndex + 1;
    return this.model.matchups.objectAt(nextMatchupIndex === this.model.matchups.length ? 0 : nextMatchupIndex);
  }),
  previousMatchupView: computed('flattenedMatchups.@each.id', 'selectedMatchup.id', function () {
    if (!this.flattenedMatchups) {
      return;
    }
    const currentMatchupIndex = this.flattenedMatchups.mapBy('id').indexOf(this.selectedMatchup.id);
    const prevMatchupIndex = currentMatchupIndex - 1;
    const previousMatchupId =
      currentMatchupIndex === 0
        ? this.flattenedMatchups.lastObject.id
        : this.flattenedMatchups.objectAt(prevMatchupIndex).id;
    return this.flattenedMatchups.findBy('id', previousMatchupId);
  }),
  nextMatchupView: computed('flattenedMatchups.@each.id', 'selectedMatchup.id', function () {
    if (!this.flattenedMatchups) {
      return;
    }
    const currentMatchupIndex = this.flattenedMatchups.mapBy('id').indexOf(this.selectedMatchup.id);
    const nextMatchupIndex = currentMatchupIndex + 1;
    const nextMatchupId = this.flattenedMatchups.objectAt(
      nextMatchupIndex === this.flattenedMatchups.length ? 0 : nextMatchupIndex
    ).id;
    return this.flattenedMatchups.findBy('id', nextMatchupId);
  }),
});
