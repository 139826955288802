/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-actions-hash, ember/no-computed-properties-in-native-classes */
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';
import PerformanceReportCommonController from 'partner/-base/performance-report-controller';
import { get, setProperties, action, computed } from '@ember/object';
import camelizeKeys from 'partner/utils/camelize-keys';
import {
  createOrganizationTree,
  FORMATTED_MESSAGE_CAMPAIGN_TYPES,
  getEmailColumns,
  getSummaryColumns,
  type PromotionType,
  SortDirectionType,
  type SummaryTypeModel,
} from 'partner/utils/performance-report';
import type { Model } from 'partner/routes/organizations/organization/message-campaigns/performance-report';
import type CurrentService from 'partner/services/current';
import type ReportsService from 'partner/services/reports';
import type { ReportType } from 'partner/services/reports';
import { tracked } from '@glimmer/tracking';

const VIEW_TYPES = [
  {
    id: 'summary',
    name: 'Summary',
  },
  {
    id: 'emails',
    name: 'Emails',
  },
];

export default class PerformanceReportController extends PerformanceReportCommonController {
  //region Ember Dependencies

  @service declare reports: ReportsService;
  @service declare current: CurrentService;
  @service declare features: any;

  declare model: Model;

  @tracked sortColumn = 'sentCount';
  @tracked sortDirection: SortDirectionType = 'desc';

  viewTypes = VIEW_TYPES;

  @controller('organizations.organization.message-campaigns')
  declare messageCampaignsController: any;

  //endregion

  get messageCampaignTypes() {
    return this.current.organization.hasMessaging
      ? [...FORMATTED_MESSAGE_CAMPAIGN_TYPES]
      : ([FORMATTED_MESSAGE_CAMPAIGN_TYPES[0], ...FORMATTED_MESSAGE_CAMPAIGN_TYPES.slice(-4)] as PromotionType[]);
  }

  @computed('isSummary', 'model.{messagingStatisticsOrganizations,messagingStatisticsEmails}.meta')
  get meta() {
    const { isSummary } = this;
    const { meta: organizationsMeta } = this.model.messagingStatisticsOrganizations as unknown as {
      meta: { messageStatisticsTotalCounts: object; totalRecords: number };
    };
    const { meta: emailsMeta } = this.model.messagingStatisticsEmails as unknown as {
      meta: { messageStatisticsTotalCounts: object; totalRecords: number };
    };

    return isSummary ? organizationsMeta : emailsMeta;
  }

  @computed('meta.messageStatisticsTotalCounts')
  get stats() {
    return this?.meta?.messageStatisticsTotalCounts ? camelizeKeys(get(this, 'meta.messageStatisticsTotalCounts')) : [];
  }

  @computed('isChain', 'features')
  private get emailColumns() {
    return getEmailColumns(this.isChain, this.features);
  }

  @computed('features', 'isChain', 'rows')
  private get summaryColumns() {
    const expandableColumn = this.rows && this.rows.any((row: any) => row.children);
    const columns = getSummaryColumns(this.isChain, this.features);
    return expandableColumn
      ? [
          {
            label: '',
            width: 'max-content',
          },
          ...columns,
        ]
      : columns;
  }

  @computed('isSummary', 'summaryColumns', 'emailColumns')
  get columns() {
    return this.isSummary ? this.summaryColumns : this.emailColumns;
  }

  @computed('model.messagingStatisticsOrganizations', 'orgId', 'sortColumn', 'sortDirection')
  get organizationTree() {
    return createOrganizationTree(
      parseInt(this.orgId),
      this.model.messagingStatisticsOrganizations.toArray() as SummaryTypeModel[],
      this.sortColumn || 'organizationName',
      this.sortDirection || 'asc'
    );
  }

  @computed('isSummary', 'organizationTree.children', 'model.messagingStatisticsEmails')
  get rows() {
    return this.isSummary ? this.organizationTree?.children : this.model.messagingStatisticsEmails.toArray();
  }

  get summaryStats() {
    return [
      {
        header: 'Emails',
        value: this?.meta?.totalRecords,
      },
      {
        header: 'Sent',
        value: this?.stats?.totalSentCount,
      },
      {
        header: 'Delivered',
        primary: 'totalReceivedPercentage',
        secondary: 'totalReceivedCount',
        showPercentage: true,
      },
      {
        header: 'Opened',
        primary: 'totalOpenedPercentage',
        secondary: 'totalOpenedCount',
        showPercentage: true,
      },
      {
        header: 'Clicked',
        primary: 'totalClickedPercentage',
        secondary: 'totalClickedCount',
        showPercentage: true,
      },
      {
        header: 'Unsubscribed',
        primary: 'totalOptedOutPercentage',
        secondary: 'totalOptedOutCount',
        showPercentage: true,
      },
    ];
  }

  @computed('viewTypes', 'view')
  get selectedViewType() {
    return this.viewTypes.findBy('id', this.view);
  }

  @computed('messageCampaignTypes', 'type')
  get selectedMessageCampaignType() {
    return this.messageCampaignTypes.findBy('id', this.type);
  }

  @computed('messageCampaignsController.model.messageCampaignCategories')
  get messageCampaignCategories() {
    const categories = this.messageCampaignsController.model.messageCampaignCategories;
    return [{ id: null, name: 'All' }, ...categories.toArray()];
  }

  @computed('messageCampaignCategories', 'category')
  get selectedMessageCampaignCategory() {
    return this.messageCampaignCategories.findBy('id', this.category) || 'All';
  }

  get reportType(): ReportType {
    return this.isSummary ? 'MessagingStatisticsOrganizations' : 'MessagingStatisticsEmails';
  }

  @action
  changeView({ id }: { id: string }) {
    const type = this.selectedMessageCampaignType;
    setProperties(this, {
      page: 1,
      view: id,
      organizationIds: '',
      sortColumn: 'sentCount',
      sortDirection: 'desc',
      name: '',
      type: !type ? null : (type.id as string),
    });
  }

  @action
  filterByType({ id }: { id: string }) {
    setProperties(this, {
      page: 1,
      type: id,
    });
  }

  @action
  filterByCategory({ id }: { id: string }) {
    setProperties(this, {
      page: 1,
      category: id,
    });
  }
}

declare module '@ember/controller' {
  interface Registry {
    'organizations.organization.message-campaigns.performance-report': PerformanceReportController;
  }
}
