/* eslint-disable ember/use-ember-data-rfc-395-imports */
import ApplicationSerializer from 'partner/serializers/application';

export default ApplicationSerializer.extend({
  /**
   * Normalize EntriesOrganizationUser. Doesn't come back from server with id property.
   * 1. Copies entry_id value to a id property
   * 2. Maps underscore_names to camelCaseNames
   * @see http://emberjs.com/api/data/classes/DS.RESTSerializer.html#method_normalize
   */
  normalize(modelClass, hash = {}) {
    if (hash['entry_id']) {
      hash['id'] = hash['entry_id'];
    }

    return this._super(...arguments);
  },
});
