import { ParamsType } from 'partner/services/preview';
import { header } from './common';

export const statusText = ({ marginTop }: { marginTop: number }) => {
  return {
    uniqueTypeNumber: '12',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        uniqueTypeNumber: '2',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: 'Vote until m/dd @ h:mm pm',
          additionalCss: ``,
        },
        styles: {
          font: {
            color: '#000000',
            weight: 400,
            family: 'Open Sans',
            size: '16',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-12 {\n ${
        marginTop ? `margin-top:${marginTop}px;` : ''
      } \n  }\n  \n  `,
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const groupBox = () => {
  return {
    uniqueTypeNumber: '11',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        uniqueTypeNumber: '1',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: 'Group Name',
          additionalCss: ``,
        },
        styles: {
          font: {
            color: '#ffffff',
            weight: 700,
            family: 'Open Sans',
            size: '24',
          },
          textShadow: {
            x: 0,
            y: 0,
            blur: 6,
            color: '#212121',
            hasShadow: true,
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss:
        '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-11 {\n  max-width: 320px   \n  }\n  \n  ',
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
      },
      border: {
        radius: 5,
      },
      background: {
        type: 'color',
        color: '#9e9e9e',
      },
      size: {
        custom: true,
        height: 180,
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};
export default (params?: ParamsType) => [
  header({
    title: 'Already Voted?',
    promotionColor: params?.promotionColor,
    promotionTextColor: params?.promotionTextColor,
  }),
  statusText({
    marginTop: 60,
  }),
  {
    uniqueTypeNumber: '1',
    deletable: true,
    type: 'promotion-grid',
    blocks: [groupBox(), groupBox(), groupBox(), groupBox(), groupBox(), groupBox()],
    data: {
      columns: '1:1',
      additionalCss:
        '.arc-promotion-promotion-0 .arc-promotion-grid-promotion-grid-1 {\n   justify-items: center; margin: 16px auto; max-width: 672px;  \n  }\n  \n  ',
    },
    styles: {
      layout: {
        display: 'grid',
        direction: 'row',
        gapX: '20',
        gapY: '20',
      },
      size: {},
      spacing: {},
    },
    dataMobile: {
      columns: '1',
    },
    stylesMobile: {},
    dataTablet: {
      columns: '1:1',
    },
    stylesTablet: {},
  },
];
