/**
 * Array of duration option objects.
 * There is some funkyness with the month duration (which in use actually should be + 1 month - 1 day -> end of day -> start of minute)
 * @constant
 * @type {Array<durationOption>}
 */
const durationOptions = [
  {
    name: '1 Hour',
    duration: 3600000,
    momentValue: 1,
    momentInterval: 'h',
  },
  {
    name: '24 Hours',
    duration: 86400000,
    momentValue: 24,
    momentInterval: 'h',
  },
  {
    name: '2 Days',
    duration: 172800000,
    momentValue: 2,
    momentInterval: 'd',
  },
  {
    name: '3 Days',
    duration: 259200000,
    momentValue: 3,
    momentInterval: 'd',
  },
  {
    name: '1 Week',
    duration: 604800000,
    momentValue: 1,
    momentInterval: 'w',
  },
  {
    name: '2 Weeks',
    momentValue: 2,
    momentInterval: 'w',
  },
  {
    name: '1 Month',
    momentValue: 1,
    momentInterval: 'M',
  },
  {
    name: 'Set Manually',
    momentValue: null,
    momentInterval: null,
  },
];

/**
 * Returns full option objects based on an array of option names. Will order them as passed.
 * @function options
 * @param {Options[]} options - array of strings that are array option names found in durationOptions
 * @returns {Options[]} - array of option objects from durationOptions
 */
export const options = selectedOptions =>
  durationOptions.filter(option => selectedOptions.find(name => name === option.name));

/**
 * Transforms matchups data to two-dimensional array.
 * For use as an input in ui/dates-rounds.
 * @function transformToDatesRounds
 * @param {Ember.array} matchups - first layer of the matchups Ember array
 * @returns {Array<Object>} - returns array of objects that contain startDate, endDate, and hasError
 */
export const transformToDatesRounds = matchups =>
  matchups.map((matchup, index) => ({
    startDate: matchup.startDate,
    endDate: matchup.endDate,
    round: index + 1,
  }));

export default {
  options,
  transformToDatesRounds,
};
