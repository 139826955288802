import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<EntryForm::Field::Wrapper @label={{@formField.labelText}} ...attributes as |guid|>\n  <input\n    id={{guid}}\n    class=\"ss-material-input\"\n    type=\"text\"\n    value={{this.entryFieldValue.value}}\n    disabled={{@disabled}}\n    {{on \"input\" this.handleInput}}\n  >\n</EntryForm::Field::Wrapper>\n", {"contents":"<EntryForm::Field::Wrapper @label={{@formField.labelText}} ...attributes as |guid|>\n  <input\n    id={{guid}}\n    class=\"ss-material-input\"\n    type=\"text\"\n    value={{this.entryFieldValue.value}}\n    disabled={{@disabled}}\n    {{on \"input\" this.handleInput}}\n  >\n</EntryForm::Field::Wrapper>\n","moduleName":"partner/components/entry-form/field/textbox.hbs","parseOptions":{"srcName":"partner/components/entry-form/field/textbox.hbs"}});
import { action } from '@ember/object';

import Base from './base';

export default class EntryFormFieldTextboxComponent extends Base {
  @action
  handleInput(e) {
    this.args.onChange(this.entryFieldValue, {
      value: e.target.value,
      fieldId: this.fieldId,
    });
  }
}
