import { attr, belongsTo } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class OrganizationPromotionLinkModel extends BaseModel {
  @attr('number') organizationPromotionId;
  @attr('number') linkedOrganizationPromotionId;
  @attr('string') linkedPromotionName;
  @attr('number') numActiveVotingEntries;

  //Backend suggested this to be an enum. In case it will be used in future to differentiate between different
  //use-cases. Foreg. 1 referes to Auto Approving entrants
  @attr('number', { defaultValue: 1 }) organizationPromotionLinkTypeId;

  @belongsTo('organization-promotion') organizationPromotion;
}
