import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"w-[90px] h-[90px] rounded p-2 flex flex-col gap-2 border border-solid border-gray-200 items-center cursor-grab\n    {{if this.disabled 'pointer-events-none opacity-40'}}\n    \"\n  {{on \"dragstart\" (fn this.handleDragStart @blockUnit.type)}}\n  {{on \"dragend\" this.handleDragEnd}}\n  draggable=\"true\"\n>\n  <Ui::Symbol @name={{@blockUnit.iconName}} @style=\"outlined\" class=\"h-18 w-12 flex justify-center items-center\" />\n  <span class=\"font-sans font-normal text-xs mt-auto\">\n    {{@blockUnit.name}}\n  </span>\n</div>", {"contents":"<div\n  class=\"w-[90px] h-[90px] rounded p-2 flex flex-col gap-2 border border-solid border-gray-200 items-center cursor-grab\n    {{if this.disabled 'pointer-events-none opacity-40'}}\n    \"\n  {{on \"dragstart\" (fn this.handleDragStart @blockUnit.type)}}\n  {{on \"dragend\" this.handleDragEnd}}\n  draggable=\"true\"\n>\n  <Ui::Symbol @name={{@blockUnit.iconName}} @style=\"outlined\" class=\"h-18 w-12 flex justify-center items-center\" />\n  <span class=\"font-sans font-normal text-xs mt-auto\">\n    {{@blockUnit.name}}\n  </span>\n</div>","moduleName":"partner/components/template-designer/draggable-block.hbs","parseOptions":{"srcName":"partner/components/template-designer/draggable-block.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import Arc from '@ss/arc/arc/arc';

import type { ArcBlock, ArcBlockType } from '@ss/arc/arc/types';
import { getGhostElement } from '@ss/arc/arc/utils';

interface SsArcDraggableBlockSignature {
  Element: HTMLDivElement;
  Args: {
    arc: Arc;
    blockUnit: ArcBlock;
  };
}

export default class TemplateDesignerLibraryElementsComponent extends Component<SsArcDraggableBlockSignature> {
  get disabled() {
    return (
      (this.args.blockUnit.type == 'social' && this.args.arc.socialElement) ||
      (this.args.blockUnit.type == 'countdown' && this.args.arc.countdownElement)
    );
  }

  @action
  handleDragStart(type: ArcBlockType, e: DragEvent) {
    e.stopPropagation();
    const block = this.args.arc.createBlock({
      type,
    });

    if (!block) return;

    this.args.arc.dragging = block;
    const ghost = getGhostElement(block.name, block.iconName);
    document.querySelector('.ssOrganizationPromotion')?.appendChild(ghost);

    e.dataTransfer?.setDragImage(ghost, 0, 0);
  }

  @action
  handleDragEnd() {
    this.resetDraggingState();
  }

  private resetDraggingState() {
    this.args.arc.dragging = undefined;
    document.querySelector('#ghost')?.remove();
  }
}
