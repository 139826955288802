/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

/**
 * Audience Filters Route
 * /o/:organization_id/data/audiences/:audience_id/filters
 */
export default Route.extend({
  router: service(),
  store: service(),

  //region Ember Hooks
  //region HAX
  // For some reason, refreshModel must be true for transitionTo to properly pass the QP.
  // This will become a problem if we ever do a find call in this model.
  // TODO: Find out why, and tell front-end dev team.
  queryParams: {
    showUploadModal: {
      refreshModel: true,
    },
  },
  //endregion
  async model() {
    const isChain = get(this.modelFor('organizations.organization'), 'organization.isChain');
    const audience = get(this.modelFor('organizations.organization.data.audiences.audience'), 'audience');

    if (isChain) {
      await this.store.findAll('organization'); // Because of Ember Data async: false relationship
    }

    const { organization } = this.modelFor('organizations.organization');

    const filterData = {
      messageCampaigns: organization.hasMessaging
        ? this.store.query('messageCampaign', {
            organizationId: organization.id,
            pageIndex: 1,
            pageSize: 1000,
            sortColumn: 'scheduleStartDate',
            sortDirection: 'desc',
          })
        : [],
    };

    return RSVP.hash({
      audience,
      filterData,
      _interestTags: this.store.findAll('interest-tag'), // Tags need to be preloaded here
    });
  },
  redirect() {
    const audienceType = get(
      this.modelFor('organizations.organization.data.audiences.audience'),
      'audience.audienceType'
    );
    if (audienceType === 'Subscription') {
      this.router.replaceWith('organizations.organization.data.audiences.audience.setup');
    }
  },
  resetController() {
    set(this, 'controller.showUploadModal', false);
  },
  //endregion

  //region Actions
  actions: {
    willTransition(transition) {
      const { audience } = this.modelFor('organizations.organization.data.audiences.audience');
      let confirmed = !audience.hasDirtyAttributes && !audience.audienceFilters.isAny('hasDirtyAttributes');
      confirmed = confirmed ? confirmed : window.confirm('Are you sure you want to leave without saving?');
      if (confirmed) {
        get(this, 'currentModel.audience').reload();
      } else {
        transition.abort();
      }
    },
  },
  //endregion
});
