import { attr, hasMany } from '@ember-data/model';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import enums from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';

/**
 * This is an enum table that is exposed via the API, which is very different than other places.
 * Represents a product sold/licensed by SecondStreet.
 * (Contests, Data, Quizzes...)
 */
@makeBooleanProperties('SS_PRODUCT_TYPE')
export default class SsProduct extends BaseModel {
  @attr('string') description;
  @attr('boolean') isPartnerNotificationTargetable;
  @attr('number') ssProductTypeId;

  @hasMany('ss-product-promotion-type', { async: false }) ssProductPromotionTypes;
  @hasMany('organization-ss-product', { async: false }) organizationSsProducts;

  // For stability, don't rely on the name from the API. Instead, treat it like an enum.
  @enums.computed('name', 'ssProductTypeId')
  name;
}
