import Component from '@glimmer/component';
import { task } from 'ember-concurrency';

export default class WysiwygTemplateEditorComponent extends Component {
  get isAnythingDirty() {
    return this.args.designTemplate?.hasDirtyAttributes;
  }

  get isSaveDisabled() {
    return !this.isAnythingDirty || this.saveTask.isRunning;
  }

  saveTask = task({ drop: true }, async () => {
    await this.args.designTemplate?.save();
  });
}
