/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionDashboardReportController extends Controller {
  @service('enums') enums;

  @computed('model.reportType', 'reportTypeOverride')
  get reportKpi() {
    return this.reportTypeOverride
      ? this.reportTypeOverride.replace(/([A-Z])/g, ' $1')
      : this.enums.findWhere(
          'REPORT_TYPE',
          {
            name: this.model.reportType,
          },
          'public'
        );
  }

  @computed('reportKpi')
  get reportName() {
    return ['EntriesAllCopies', 'RegisteredUsersAllCopies'].includes(this.model.reportType)
      ? `${this.reportKpi} (all copies)`
      : this.reportKpi;
  }

  @computed('model.{organizationPromotion.name,optinField.name}', 'reportName')
  get reportTitle() {
    return `${this.model.organizationPromotion.name} - ${this.model.optinField?.name || this.reportName}`;
  }
}
