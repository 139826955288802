/* eslint-disable ember/no-mixins, ember/no-classic-classes */
import { inject as service } from '@ember/service';
import BaseRoute from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/quiz/outcomes/base';

/**
 * Outcome Route
 * /o/:organization_id/op/:organization_promotion_id/setup/quiz/outcomes/:outcome_id
 * @type {Ember.Route}
 */
export default BaseRoute.extend({
  store: service(),

  //region Ember Hooks
  model(params) {
    return this.store.find('outcome', params.outcome_id);
  },
  //endregion
});
