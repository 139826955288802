/* eslint-disable ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import BaseRoute from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/quiz/outcomes/base';

/**
 * Outcomes New Route
 * /o/:organization_id/op/:organization_promotion_id/setup/quiz/outcomes/new
 * @type {Ember.Route}
 */
export default BaseRoute.extend({
  store: service(),

  //region Ember Hooks
  model() {
    const promotion = get(
      this.modelFor('organizations.organization.organization-promotions.organization-promotion'),
      'organizationPromotion.promotion'
    );
    let outcomeTypeId;

    switch (get(promotion, 'promotionSubType')) {
      case 'QuizTrivia':
        outcomeTypeId = 2; // Spectrum // TODO: Use an enum.
        break;
      case 'QuizPersonality':
        outcomeTypeId = 1; // Categorical // TODO: Use an enum.
        break;
    }

    return this.store.createRecord('outcome', {
      description: '',
      promotion,
      outcomeTypeId,
    });
  },
  //endregion
});
