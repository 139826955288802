import Controller, { inject as controller } from '@ember/controller';

export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionWinnersLoadingController extends Controller {
  @controller('organizations.organization.organization-promotions.organization-promotion')
  organizationPromotionController;

  get organizationPromotion() {
    return this.organizationPromotionController.model.organizationPromotion;
  }
}
