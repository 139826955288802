/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

/**
 * Represents the types of template elements.
 * Rules for the types are in {Promotion.ENUMS.TEMPLATE_ELEMENT_TYPE}
 * @see {Promotion.ENUMS.TEMPLATE_ELEMENT_TYPE}
 * @see {Promotion.TemplateElement}
 * @type {DS.Model}
 */
export default BaseModel.extend({
  //region Ember Hooks
  enums: service(),
  //endregion

  //region Properties
  name: DS.attr('string'),
  token: DS.attr('string'),
  description: DS.attr('string'),
  category: DS.attr('string'),
  //endregion

  //region Computed Properties
  /**
   * Indicates if this element type could have HTML content
   * @returns {boolean}
   */
  isHtml: computed('name', function () {
    return this.hasTrait('html');
  }),
  /**
   * Indicates if this element type can be edited by the user
   * @returns {boolean}
   */
  editableByUser: computed('name', function () {
    return this.hasTrait('editableByUser');
  }),
  /**
   * Indicates if this element type has "normal" content or uses a MediaItemId
   * @returns {boolean}
   */
  usesMediaItemId: computed('name', function () {
    return this.hasTrait('usesMediaItemId');
  }),
  /**
   * Indicates if this element type uses file upload
   * @returns {boolean}
   */
  usesFileUpload: computed('name', function () {
    return this.hasTrait('usesFileUpload');
  }),
  /**
   * Indicates if this element type uses JSON
   * @returns {boolean}
   */
  usesJson: computed('name', function () {
    return this.hasTrait('usesJson');
  }),
  /**
   * Indicates if this element type uses the color picker
   * @returns {boolean}
   */
  usesColorPicker: computed('id', function () {
    return this.hasTrait('colorPicker');
  }),
  usesPlainTextArea: computed('id', function () {
    return this.hasTrait('plainTextArea');
  }),
  sortOrder: computed('id', function () {
    return this.enums.findWhere('TEMPLATE_ELEMENT_TYPE', { id: parseInt(this.id, 10) }, 'sortOrder');
  }),
  helpText: computed('id', function () {
    return this.enums.findWhere('TEMPLATE_ELEMENT_TYPE', { id: parseInt(this.id, 10) }, 'helpText');
  }),
  //endregion

  //region Methods
  /**
   * Uses {Promotion.ENUMS} to see if this TemplateElementType as a certain trait/ability.
   * @param traitName
   * @returns {boolean} true if object has the "trait" turned on by configuration
   */
  hasTrait(traitName) {
    const searchObject = { id: parseInt(this.id, 10) };
    searchObject[traitName] = true;
    return !isEmpty(this.enums.findWhere('TEMPLATE_ELEMENT_TYPE', searchObject));
  },
  //endregion
});
