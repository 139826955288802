/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Route from '@ember/routing/route';

export default Route.extend({
  model(params) {
    return {
      organizationId: params.organization_id,
    };
  },
});
