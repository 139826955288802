/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed, get, set, action } from '@ember/object';
import { task } from 'ember-concurrency';
import ENUMS from 'ember-cli-ss-enums/utils/enum-data';

const ALL_TIME_ZONES = ENUMS.TIME_ZONES.sortBy('offset').filter(zone =>
  [
    'Hawaii',
    'Alaska',
    'Pacific',
    'Arizona',
    'Mountain',
    'Central Time',
    'Guam',
    'Saskatchewan',
    'Eastern Time',
    'Indiana',
    'Atlantic Time',
    'Newfoundland',
    'Dublin',
    'Cairo',
    'E. Europe',
    'Adelaide',
    'Perth',
    'Brisbane',
    'Melbourne',
  ].any(supported => zone.name.match(supported))
);

const AVAILABLE_TIME_ZONES = ALL_TIME_ZONES.map(zone => ({
  id: zone.id,
  name: zone.name.replace(/^\(.+?\)/, ''),
}));

export default class extends Controller {
  @service router;

  get allTimeZones() {
    return ALL_TIME_ZONES;
  }

  get availableTimeZone() {
    return AVAILABLE_TIME_ZONES;
  }

  @computed('model.organization.timeZoneId', 'availableTimeZone.@each.id')
  get selectedTimeZone() {
    // Find a displayed time zone because creating a new one fails equality checks in the dropdown.
    return this.availableTimeZone.findBy('id', get(this, 'model.organization.timeZoneId'));
  }

  @action
  timeZoneChanged(timeZone) {
    set(this.model.organization, 'timeZoneId', timeZone.id);
  }

  saveTask = task({ drop: true }, async () => {
    try {
      await this.model.organization.save();

      this.router.transitionTo('organizations.organization.settings.info');
      // eslint-disable-next-line no-empty
    } catch (e) {}
  });
}
