import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type PartnerNotificationModel from 'partner/models/partner-notification';

export default class NotificationsService extends Service {
  @service declare store: Store;

  @tracked notifications: PartnerNotificationModel[] = [];

  get unreadNotifications() {
    return this.notifications.filter(notification => !notification.hasChild);
  }

  get readNotifications() {
    return this.notifications.filter(notification => notification.hasChild);
  }

  get unreadNotificationsCount() {
    return this.unreadNotifications.length;
  }

  async getNotifications() {
    const result = await this.store.query('partner-notification', { update: true });
    this.notifications = result.toArray();
  }
}
