import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';
import type EntryModel from './entry';
import type MatchupModel from './matchup';
import type MatchupEntryModel from './matchup-entry';

export default class WinnerModel extends BaseModel {
  @attr('string') declare matchupPlaceName: string;
  @attr('string') declare matchupEntryId: string;
  @attr('number') declare rank: number;
  @attr('string') declare firstName: string;
  @attr('string') declare lastName: string;
  @attr('string') declare emailAddress: string;
  @attr('string') declare phone: string;
  @attr('number') declare birthDay: number;
  @attr('number') declare birthMonth: number;
  @attr('number') declare birthYear: number;
  @attr('date') declare dateCreated: Date;
  @attr('date') declare dateModified: Date;
  @attr('number') declare classEntityType: number;
  @attr('number') declare organizationTypeId: number;
  // TODO: Write a serializer for OwnerEntityType/Id once we know what types it could belong to.
  @attr('number') declare ownerEntityType: number;
  @attr('number') declare ownerEntityId: number;

  @belongsTo('matchup', { async: true }) declare matchup: AsyncBelongsTo<MatchupModel>;
  @belongsTo('matchup-entry', { inverse: null, async: true }) declare matchupEntry: AsyncBelongsTo<MatchupEntryModel>;
  @belongsTo('matchup-place', { inverse: null, async: false }) declare matchupPlace: any;
  @belongsTo('entry', { inverse: null, async: true }) declare entry: AsyncBelongsTo<EntryModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    winner: WinnerModel;
  }
}
