/* globals FS */
import { getOwner } from '@ember/application';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Base from 'ember-simple-auth/authenticators/base';
import ENV from 'partner/config/environment';
import RSVP from 'rsvp';
import { endpoint } from 'secondstreet-common/utils/url';

/**
 * `Authenticator` implementation for ember-cli-simple-auth.
 * Logic for creating a session using the second street API.
 * @see http://ember-simple-auth.com/ember-simple-auth-api-docs.html#SimpleAuth-Authenticators-Base-authenticate
 * @see /partner/authorizers/second-street.js
 */
export default Base.extend({
  //region Dependencies
  ajax: service(),
  appcues: service(),
  session: service(),
  //endregion

  /**
   * Called when app loads and there is already data in sessions.get('secure')
   * @param data
   * @returns {Ember.RSVP.Promise}
   */
  async restore(data) {
    return this.validateExistingSession({
      type: 'GET',
      headers: {
        'X-Api-Key': ENV.APP.API_KEY,
        Authorization: data['access_token'],
      },
      contentType: 'application/json',
    });
  },

  /**
   * Attempt to log the user in. Logic used is determined by options passed.
   * @param options - login credentials needed to login via secondstreet api
   * @returns {Ember.RSVP.Promise} - if the promise resolves that data it resolves with will be stored in sessions.get('secure')
   */
  async authenticate(options) {
    let response;

    if (options.access_code && options.id && options.token) {
      response = await this._authenticate2fa(options);
    } else if (options.token) {
      response = await this._authenticateToken(options.token);
    } else if (options.code) {
      const { code, state, uniqueUri } = options;
      const ucmResponse = await this.session.request(
        `${endpoint('ucm_authorize')}?code=${code}&state=${state || ''}&uniqueUri=${uniqueUri || ''}`
      );
      const accessToken = ucmResponse.sessions[0].access_token;
      response = await this._authenticateToken(accessToken);
    } else {
      response = await this._authenticateUsernamePassword(options);
    }

    this.appcues.identify(response.organization_users, {
      completed_joyride: response.is_new_platform_joyride_complete,
    });
    this.appcues.track('Logged In');

    return response;
  },

  async _authenticateToken(token) {
    return this.validateExistingSession({
      type: 'GET',
      headers: {
        'X-Api-Key': ENV.APP.API_KEY,
        Authorization: token,
      },
      contentType: 'application/json',
    });
  },

  /**
   * Creates a session on the server with a POST to sessions.
   * @param {} options - username and password
   * @returns {Ember.RSVP.Promise}
   * @private
   */
  async _authenticateUsernamePassword(options) {
    return this.validateExistingSession({
      type: 'POST',
      data: JSON.stringify({ sessions: [options] }),
      headers: {
        'X-Api-Key': ENV.APP.API_KEY,
      },
      contentType: 'application/json',
    });
  },

  /**
   * Validates a session with 2fa on the server with a PUT to sessions.
   * @param {} options - One-time code, session id, and access token
   * @returns {Ember.RSVP.Promise}
   * @private
   */
  async _authenticate2fa(options) {
    const data = {
      sessions: [
        {
          access_code: options.access_code,
          id: options.id,
        },
      ],
    };

    return this.validateExistingSession({
      type: 'PUT',
      data: JSON.stringify(data),
      headers: {
        'X-Api-Key': ENV.APP.API_KEY,
        Authorization: options.token,
      },
      contentType: 'application/json',
    });
  },

  async invalidate() {
    return this.session.request(endpoint('sessions'), {
      type: 'DELETE',
      headers: {
        'X-Api-Key': ENV.APP.API_KEY,
      },
    });
  },

  /**
   * Validates the session and sets Fullstory/Facebook stuff
   * @param {Object} options
   * @returns {Ember.RSVP.Promise}
   */
  async validateExistingSession(options) {
    return new RSVP.Promise(async (resolve, reject) => {
      try {
        const responseData = await this.ajax.request(endpoint('sessions'), options);

        this._setFacebookServiceLoginStatus(true);
        this.identifyFullStoryUser(responseData.sessions[0]);

        //resolving this promise logs the user in (or keeps them logged in)
        //the data passed to resolve will be stored in sessions.get('secure')
        resolve(responseData.sessions[0]);
      } catch (error) {
        this._setFacebookServiceLoginStatus(false);
        reject(error);
      }
    });
  },

  /**
   * @private
   */
  identifyFullStoryUser(data) {
    try {
      const user = key => get(data, `organization_users.${key}`);

      if (ENV.environment === 'production' && FS && FS.identify && user('id')) {
        FS.identify(`${user('id')}`, {
          displayName: `${user('first_name')} ${user('last_name')}`,
          email: user('username'),
          organizationId_int: user('organization_id'),
        });
      }
    } catch (e) {
      console.error(e);
    }
  },

  /**
   * @private
   */
  _setFacebookServiceLoginStatus(value) {
    set(getOwner(this).lookup('service:facebook'), 'isAuthenticated', value);
  },
});
