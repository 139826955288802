/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { notEmpty } from '@ember/object/computed';

export default Component.extend({
  //region Attributes
  /**
   * @property {String}
   */
  value: null,
  /**
   * @property {String}
   */
  'field-id': null,
  /**
   * The subscription audience currently being set up
   * @property {Object}
   */
  audience: null,
  /**
   * All subscription audiences in the organization
   * @property {Audience[]}
   */
  'subscription-audiences': null,
  /**
   * All subscription audiences that do not have a corresponding signup optin
   * @property {Audience[]}
   */
  'available-subscription-audiences': null,
  updateTokenContentProperty() {},
  //endregion

  //region Computed Properties
  chosenAudience: computed('subscription-audiences', 'field-id', function () {
    const fieldId = this['field-id'];
    return fieldId ? [this['subscription-audiences'].find(a => parseInt(get(a, 'entityId'), 10) === fieldId)] : [];
  }),

  hasChosen: notEmpty('field-id'),

  isCurrentAudience: computed('field-id', 'audience.entityId', function () {
    return this['field-id'] === parseInt(get(this, 'audience.entityId'), 10);
  }),
  //endregion

  //region Actions
  actions: {
    audienceAdded(audience) {
      this.updateTokenContentProperty('fieldId', parseInt(audience.entityId, 10));
      this.updateTokenContentProperty('value', audience.name);
    },
    audienceRemoved() {
      this.updateTokenContentProperty('fieldId', null);
      this.updateTokenContentProperty('value', null);
    },
    setValue(value) {
      this.updateTokenContentProperty('value', value);
    },
  },
  //endregion
});
