/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

export default Mixin.create({
  router: service(),

  redirect() {
    this.router.transitionTo('organizations.organization.organization-promotions.organization-promotion.setup.designs');
  },
});
