import { attr } from '@ember-data/model';
import enums from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';

export default class MessagingStatisticsEmailModel extends BaseModel {
  @attr('number') declare clickedCount: number;
  @attr('number') declare clickedPercentage: number;
  @attr('number') declare messageCampaignCategoryId: number;
  @attr('number') declare messageCampaignId: number;
  @attr('number') declare messageCampaignTypeId: number;
  @attr('number') declare messageId: number;
  @attr('number') declare messageVersionHistoryId: number;
  @attr('number') declare messageVersionId: number;
  @attr('number') declare openedCount: number;
  @attr('number') declare openedPercentage: number;
  @attr('number') declare optedOutCount: number;
  @attr('number') declare optedOutPercentage: number;
  @attr('number') declare organizationId: number;
  @attr('number') declare receivedCount: number;
  @attr('number') declare receivedPercentage: number;
  @attr('number') declare scheduleInstanceId: number;
  @attr('number') declare sentCount: number;

  @attr('string') declare messageCampaignCategoryName: string;
  @attr('string') declare messageCampaignName: string;
  @attr('string') declare organizationName: string;
  @attr('string') declare messageCampaignTypeName: string;

  @attr('string') declare scheduleInstanceDate: string;

  @enums.computed('name', 'messageCampaignTypeId')
  declare messageCampaignType: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'messaging-statistics-email': MessagingStatisticsEmailModel;
  }
}
