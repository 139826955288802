import Controller from '@ember/controller';
import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import enums from 'ember-cli-ss-enums/services/enums';
import RSVP from 'rsvp';
import { endpoint } from 'secondstreet-common/utils/url';
import duration from 'secondstreet-common/utils/duration';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';

const DEFAULT_PARAMS = {
  allUsersSelected: false,
  filters: '',
  ipAddress: '',
  searchValue: '',
  statusTypeId: '',
  pageIndex: 1,
  pageSize: 100,
  sortColumn: 'fraudFlags',
  sortDirection: 'desc',
};

export default class extends Controller {
  @service('logger') logger;
  @service('reports') reports;
  @service('router') router;
  @service('session') session;
  @service('snackbar') snackbar;
  @service('deliberate-confirmation') deliberateConfirmation;

  queryParams = [
    // Filtering
    'filters',
    'ipAddress',
    'searchValue',
    'statusTypeId',
    // Pagination
    'pageIndex',
    'pageSize', // This is being used in the acceptance tests
    // Sorting
    'sortColumn',
    'sortDirection',
  ];

  // Query params
  @tracked filters = DEFAULT_PARAMS.filters;
  @tracked ipAddress = DEFAULT_PARAMS.ipAddress;
  @tracked searchValue = DEFAULT_PARAMS.searchValue;
  @tracked pageIndex = DEFAULT_PARAMS.pageIndex;
  @tracked pageSize = DEFAULT_PARAMS.pageSize;
  @tracked sortColumn = DEFAULT_PARAMS.sortColumn;
  @tracked sortDirection = DEFAULT_PARAMS.sortDirection;

  @tracked allUsersSelected = DEFAULT_PARAMS.allUsersSelected;
  @tracked isLoading = false;
  @tracked selectedUsers = [];
  @tracked isGeneratingMagicLinkFor = null;

  activeStatusTypeId = enums.findWhere('STATUS_TYPE', { name: 'Active' }, 'id');
  inactiveStatusTypeId = enums.findWhere('STATUS_TYPE', { name: 'InActive' }, 'id');

  get promotion() {
    return this.model.organizationPromotion.promotion;
  }

  get currentSummary() {
    return this.model.summaries.find(({ statusTypeId }) => `${statusTypeId}` === this.statusTypeId);
  }

  get nominationsColumnLabel() {
    if (
      this.promotion.isSweepstakes ||
      this.promotion.isVideoVotingStandard ||
      this.promotion.isPhotoVotingStandard ||
      this.promotion.isEventSignup ||
      this.promotion.isUGCGallery
    ) {
      return 'Entries';
    } else if (this.promotion.isNominationAndVotingBallot) {
      return 'Noms';
    } else if (this.promotion.isQuiz || this.promotion.isPoll) {
      return 'Submissions';
    }
    return null;
  }

  get columns() {
    return [
      { label: 'Email', sortable: 'email', width: 'minmax(min-content, 1fr)' },
      { label: 'Name', sortable: 'name', width: 'minmax(min-content, 1fr)' },
      ...(this.nominationsColumnLabel ? [{ label: this.nominationsColumnLabel, sortable: 'numEligibleEntries' }] : []),
      ...(this.promotion.isSurvey ? [{ label: 'Responses', sortable: 'numEligibleFormSubmissions' }] : []),
      ...(this.promotion.isBallot || this.promotion.isPhotoVotingStandard || this.promotion.isVideoVotingStandard
        ? [{ label: 'Votes', sortable: 'numVotes', width: 'max-content' }]
        : []),
      { label: 'IP Address', sortable: 'ipAddress', width: 'max-content' },
      { label: 'IP Usage', sortable: 'ipUsage', width: 'max-content' },
      { label: 'IP City/Country', sortable: 'ipCityCountry' },
      { label: 'Postal Code', sortable: 'postalCode' },

      ...(this.currentSummary.isPending
        ? []
        : [{ label: 'Fraud Flags', sortable: 'fraudFlags', width: 'max-content' }]),
      ...(!this.promotion.isEventSignup && !this.currentSummary.isInActive
        ? [{ label: 'Login Link', width: 'max-content' }]
        : []),
      { label: '', width: 'max-content' },
    ];
  }

  get filtersAsArray() {
    return this.filters.split(',').filter(Boolean);
  }

  get selectedUsersCount() {
    return this.allUsersSelected ? this.model.users.meta.totalRecords : this.selectedUsers.length;
  }

  resetParams() {
    Object.keys(DEFAULT_PARAMS).forEach(key => {
      this[key] = DEFAULT_PARAMS[key];
    });
  }

  refreshModel() {
    return getOwner(this)
      .lookup('route:organizations.organization.organization-promotions.organization-promotion.moderate-people.manual')
      .refresh();
  }

  refreshDetectedFraud() {
    return getOwner(this)
      .lookup('route:organizations.organization.organization-promotions.organization-promotion.moderate-people')
      .refreshDetectedFraud();
  }

  @action
  handleSortChange({ column, direction }) {
    this.sortColumn = column;
    this.sortDirection = direction;
    this.resetPageIndex();
  }

  @action
  handleSearchSubmit(e) {
    e.preventDefault();

    this.appendToFilters(this.searchValue);
    this.searchValue = '';

    return false;
  }

  @action
  handleUserSelectionChange(selectedUsers) {
    this.selectedUsers = selectedUsers;
    this.allUsersSelected = false;
  }

  @action
  appendToFilters(value) {
    this.filters = uniq([...this.filtersAsArray, value])
      .filter(Boolean)
      .join(',');
    this.resetPageIndex();
  }

  @action
  removeFilter(value) {
    this.filters = this.filtersAsArray.filter(v => v !== value).join(',');
    this.resetPageIndex();
  }

  @action
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  @action
  resetPageIndex() {
    this.pageIndex = 1;
  }

  setSearchValueTask = task({ restartable: true }, async value => {
    this.isLoading = true;

    await timeout(duration(250));

    this.searchValue = value;
    this.resetPageIndex();
  });

  changeStatusTypeTask = task({ drop: true }, async () => {
    const label = this.currentSummary.isActive ? 'disqualified' : 'made active';
    const toastMessage =
      this.selectedUsersCount > 1 ? `${this.selectedUsersCount} people were ${label}` : `1 person was ${label}`;

    try {
      if (this.allUsersSelected) {
        await this.#activateAllUsers();
      } else {
        await this.#changeStatusOfSelectedUsers();
      }

      this.snackbar.show(toastMessage);

      this.#refreshData();
    } catch (e) {
      this.logger.error(e);
      const message = e.errors?.firstObject?.detail;
      if (message) {
        this.snackbar.show(message);
      } else {
        this.snackbar.show('Something went wrong. Please try again later.');
      }
      this.#refreshData();
      this.selectedUsers = [];
    }
  });

  removeTask = task({ drop: true }, async user => {
    const toastMessage = 'This person has been removed from this promotion';

    try {
      const isConfirmed = await this.deliberateConfirmation.show({
        promptText:
          'Are you sure you want to remove this person? This will permanently remove all data and activity by this person from the promotion, including charts and reports.',
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
      });
      if (!isConfirmed) {
        return;
      }
      await this.#changeStatusTypeOfUser(user, 3);

      this.snackbar.show(toastMessage);

      this.#refreshData();
    } catch (e) {
      this.logger.error(e);
      this.snackbar.show('Something went wrong. Please try again later.');
    }
  });

  changePageTask = task({ restartable: true }, async pageIndex => {
    this.pageIndex = pageIndex;

    // Give the user some time before scrolling to the top
    // so they can change the page again if they want
    await timeout(duration(500));

    this.scrollToTop();
  });

  generateMagicLoginLinkTask = task({ drop: true }, async user => {
    this.isGeneratingMagicLinkFor = user;

    const email_address = user.emailAddress;
    const { isVotingBracket, isSweepstakes } = this.model.organizationPromotion.promotion;
    const redirect_url = isVotingBracket ? '/bracket' : isSweepstakes ? '/-SS-' : '/gallery';
    const data = { organization_promotion_user_login_links: [{ email_address, redirect_url }] };

    try {
      const {
        organization_promotion_user_login_links: [response],
      } = await this.session.request(endpoint('organization_promotion_user_login_links'), { type: 'POST', data });

      return response.login_link;
    } catch (e) {
      this.logger.error(e);
      this.snackbar.show('Something went wrong. Please try again later.');
    } finally {
      this.isGeneratingMagicLinkFor = null;
    }
  });

  downloadReportTask = task({ drop: true }, async () => {
    const params = omit(
      {
        ...this.model.usersQueryParams,
        statusType: this.model.usersQueryParams.statusTypeId,
      },
      ['pageIndex', 'pageSize', 'statusTypeId']
    );
    try {
      await this.reports.download('PeopleFraud', {
        params,
      });
    } catch (e) {
      this.logger.error(e);
      this.snackbar.show('Something went wrong. Please try again later.');
    }
  });

  async #activateAllUsers() {
    await this.session.request(
      endpoint('organization_promotion_users_bulk', {
        OrganizationPromotionId: this.model.organizationPromotion.id,
        ...(this.model.usersQueryParams.searchValue ? { searchValue: this.model.usersQueryParams.searchValue } : {}),
      }),
      {
        type: 'PUT',
        data: {
          organization_promotion_users: [
            {
              status_type_id: this.activeStatusTypeId,
            },
          ],
        },
      }
    );

    this.allUsersSelected = false;
  }

  #refreshData() {
    this.refreshModel();
    this.refreshDetectedFraud();
  }

  async #changeStatusOfSelectedUsers() {
    const statusTypeId =
      this.currentSummary.statusTypeId === this.activeStatusTypeId
        ? this.inactiveStatusTypeId
        : this.activeStatusTypeId;

    await RSVP.all(this.selectedUsers.map(user => this.#changeStatusTypeOfUser(user, statusTypeId)));

    this.selectedUsers = [];
  }

  async #changeStatusTypeOfUser(user, statusTypeId) {
    user.statusTypeId = statusTypeId;

    await user.save();
  }
}
