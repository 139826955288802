/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';

export default Component.extend({
  //region Ember Hooks
  tagName: 'ul',
  classNames: ['starred-items-modal__list'],
  //endregion

  //region Attribute
  /**
   * @property {Array}
   */
  items: null,
  /**
   * The property on which to base sorting and displaying fetched data
   * @property {String}
   */
  'property-to-use': '',
  /**
   * @property {Function}
   */
  'select-item'() {},
  /**
   * @property {Function}
   */
  'close-modal'() {},
  /**
   * @property {Boolean}
   */
  'show-icons': true,
  //endregion
});
