/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, get } from '@ember/object';
import { gt, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { hasDataProduct } from 'partner/utils/calculate';
import { endpoint } from 'secondstreet-common/utils/url';

export default Controller.extend({
  //region Dependencies
  session: service(),
  features: service(),
  snackbar: service(),
  //endregion

  //region Ember Hooks
  visibleWhen:
    'organizations.organization organizations.organization.loading organizations.organization.organization-promotions.loading organizations.organization.data organizations.organization.message-campaigns.loading organizations.organization.dashboard organizations.organization.message-campaigns.index organizations.organization.interactive-content.index organizations.organization.interactive-content.loading',
  //endregion

  //region Computed Properties
  hasPromotions: readOnly('model.organization.hasPromotions'),

  hasInteractiveContent: readOnly('model.organization.hasInteractiveContent'),

  hasEvents: readOnly('features.hasEvents'),

  hasEcommerce: readOnly('features.hasEcommerce'),

  organization: readOnly('model.organization'),

  organizationSsProducts: readOnly('model.organizationSsProducts'),

  organizationFeatureFlags: readOnly('model.organizationFeatureFlags'),

  permissions: readOnly('model.permissions'),

  organizationAttributeTypes: readOnly('model.organizationAttributeTypes'),

  email: readOnly('session.data.authenticated.username'),

  hasDataProduct: computed('organizationSsProducts', function () {
    return hasDataProduct(this.organizationSsProducts);
  }),

  username: computed('session.data.authenticated.organization_users.{first_name,last_name}', function () {
    const firstName = get(this, 'session.data.authenticated.organization_users.first_name');
    const lastName = get(this, 'session.data.authenticated.organization_users.last_name');
    return `${firstName} ${lastName}`;
  }).readOnly(),

  userId: readOnly('session.data.authenticated.organization_users.user_id'),

  hasMultipleOrganizations: gt('session.data.authenticated.organizations_count', 1).readOnly(),
  //endregion

  actions: {
    async redirectToQlik() {
      const emailAddress = get(this.session, 'data.authenticated.username');

      try {
        const response = await this.session.request(`${endpoint('qlik_ticket_create')}?emailAddress=${emailAddress}`, {
          method: 'POST',
          headers: {
            ...this.session.headers,
          },
        });

        const redirectUri = response.ticket.firstObject.TargetUri;

        window.open(redirectUri);
      } catch (e) {
        this.snackbar.exception(e);
      }
    },
  },
});
