/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import Copyable from 'partner/mixins/copyable-model';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend(Copyable, {
  //region Properties
  isCurrentVersion: DS.attr('boolean'),
  promotionTypeId: DS.attr('number'),
  languageId: DS.attr('number'),
  isInherited: DS.attr('boolean'),
  templateContent: DS.attr('string'),
  //endregion

  //region Relationships
  pageTemplate: DS.belongsTo('page-template', { async: true }),
  //endregion
});
