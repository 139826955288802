/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

export default Route.extend(AppContextRoute, {
  //region Ember Hooks
  enums: service(),
  navigation: service(),
  store: service(),

  async model(params) {
    const messageCampaignId = params.message_campaign_id;
    const organizationId = params.organization_id;

    const [messageCampaign] = await Promise.all([
      this.store.find('messageCampaign', messageCampaignId),
      // Messages must be loaded manually but they are only accessed via the messageCampaign relationship.
      this.store.query('message', { messageCampaignId }),
    ]);

    const hash = {
      messageCampaign,
      messageCampaignCategories: this.store.query('messageCampaignCategory', { organizationId }),
    };

    // TODO: [SMC Refactor] Remove once singleMessageCampaign is removed
    if (messageCampaign.usesSingleMessageCampaign) {
      hash.singleMessageCampaign = await messageCampaign.singleMessageCampaign;
    }

    const { hasDataProduct } = this.controllerFor('organizations.organization');

    if (hasDataProduct) {
      const messageCampaignEntityTypeId = this.enums.findWhere('ENTITY_TYPE', {
        name: 'Message Campaign',
      });
      const taggableEntityId = `${messageCampaignEntityTypeId}-${messageCampaignId}`;
      this.controllerFor(this.routeName).loadTaggableEntitiesTask.perform(taggableEntityId, messageCampaignId);
    }

    return RSVP.hash(hash);
  },
  afterModel(model) {
    // tell the outer navigation wrapper we're in a message campaign
    this.navigation.setEntity(model.messageCampaign);
  },
  setupController(controller, model) {
    this._super(...arguments);

    const { messageCampaign } = model;

    // Handle newly-created Message Campaigns
    if (messageCampaign.isJustCreated) {
      // TODO: [SMC Refactor] Once singleMessageCampaign is removed, remove this
      if (messageCampaign.usesSingleMessageCampaign) {
        set(messageCampaign.singleMessageCampaign, 'name', '');
      } else {
        set(messageCampaign, 'name', '');
      }
      set(controller, 'is-editing-basics', true);
    }
  },
  resetController(controller) {
    set(controller, 'is-editing-basics', false);
    set(controller, 'needsRedirect', false);
  },
  serialize(model) {
    return {
      message_campaign_id: model.messageCampaign?.id,
      organization_id: model.organization?.id,
    };
  },
  activate() {
    this._super(...arguments);
    const { messageCampaign } = this.modelFor(this.routeName);
    this.send('saveHistory', messageCampaign);
  },
  deactivate() {
    // tell the outer navigation wrapper we're no longer in a message campaign
    this.navigation.setEntity(null);
  },
  //endregion

  //region Actions
  actions: {
    editBasics() {
      set(this, 'controller.is-editing-basics', true);
    },
  },
  //endregion
});
