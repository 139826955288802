import enums from 'ember-cli-ss-enums/services/enums';
import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class DomainRedirectionModel extends BaseModel {
  // Matches to OrganizationPromotionDomain.domain.id
  @attr('number') declare domainId: number;
  @attr('string') declare name: string;
  // Matches to OrganizationPromotionDomain.rootFolder
  @attr('string') declare path: string;
  @attr('number') declare redirectOrganizationPromotionId: number;
  @attr('string') declare redirectUrl: string;
  @attr('boolean') declare includePathAndQuery: boolean;
  @attr('number') declare statusTypeId: number;
  @attr('boolean') declare convertPathToHash: boolean;
  @attr('boolean') declare isEmbedRedirect: boolean;

  @enums.computed('name', 'statusTypeId', 'statusTypeId', 'STATUS_TYPE') declare statusType: string;
}
