/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr, belongsTo } from '@ember-data/model';
import { alias, and, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';

import BaseModel from 'secondstreet-common/models/base';
import enums from 'ember-cli-ss-enums/services/enums';

const fieldIdForState = enums.findWhere('FIELD', { name: 'State' }, 'id');
const fieldIdForPostalCode = enums.findWhere('FIELD', { name: 'PostalCode' }, 'id');
export default class FormFieldModel extends BaseModel {
  @service current;
  //region Properties
  @attr('number') displayOrder;
  @attr('boolean', { defaultValue: false }) isRequired;
  @attr('string', { defaultValue: '' }) labelText;
  @attr('string') optinNotificationHelpText;
  @attr('string') placeholderText;
  @attr('string', { defaultValue: '' }) helpText;
  @attr() defaultValue;
  @attr('string') requiredText;
  @attr('boolean') isRemovable;
  @attr('boolean') isEditable;
  @attr('number') extraChances;
  @attr('number') extraChancesMaximum;
  @attr('boolean', { defaultValue: false }) isHiddenOnConsumerSite;
  @attr('boolean') isYesnoFormat;
  @attr('boolean', { defaultValue: false }) isLimitSelection;
  @attr('number', { defaultValue: null }) answerLimit;
  @attr('boolean', { defaultValue: false }) allowVideoUpload;
  @attr('boolean', { defaultValue: false }) allowPhotoUpload;
  //endregion

  //region Relationships
  @belongsTo('form-page', { async: false }) formPage;
  @belongsTo('field', { async: false }) fields;
  //endregion

  //region Computed Properties
  // TODO: When API changes this to be singular, get rid of this
  @alias('fields') field;
  @alias('field.isStarred') isStarred;
  @alias('field.name') starredNickname;
  @alias('field.fieldType') fieldType;
  @alias('field.isEditable') isEditable;

  @equal('fieldType', 'RadioButtons') isRadioButtons;
  @equal('fieldType', 'Checkboxes') isCheckboxes;
  @equal('fieldType', 'SingleCheckbox') isCheckbox;
  @equal('fieldType', 'SelectSingle') isDropdown;
  @equal('fieldType', 'Optin') isOptin;
  @equal('fieldType', 'DisplayText') isDescriptionText;
  @equal('fieldType', 'TwitterTweetApi') isTwitterTweet;
  @equal('fieldType', 'TwitterFollowApi') isTwitterFollow;
  @equal('fieldType', 'FacebookLikeApi') isFacebookLike;
  @and('isRoot', 'isCheckbox') isMediaReleaseConsent;

  get formattedLabelText() {
    const regex = /\{\{Organization\.Name\}\}/g;
    return this.labelText?.replace(regex, this.current.organization.name);
  }

  get participationNonRemovableFieldsWithZipcode() {
    return this.field.id == fieldIdForState || this.field.id == fieldIdForPostalCode;
  }

  get participationNonRemovableFieldsWithoutZipcode() {
    return this.field.id == fieldIdForState;
  }

  /**
   * The path to the Handlebars partial to view the formField
   * @type {string}
   */
  get viewComponent() {
    return this.fieldType ? `form-designer-${underscore(this.fieldType)}` : null;
  }

  get isRoot() {
    return this.field.organizationId === 1;
  }

  //endregion
}
