import { attr, hasMany, SyncHasMany } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';
import PromotionPresetModel from './promotion-preset';

export default class TurnkeyCategoryModel extends BaseModel {
  @attr('string') declare name: string;
  @attr('number') declare displayOrder: number;
  @attr('number') declare mediaItemId: number;

  @hasMany('promotion-preset', { async: false }) declare promotionPresets: SyncHasMany<PromotionPresetModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'turnkey-category': TurnkeyCategoryModel;
  }
}
