import { addValues } from 'secondstreet-common/utils/functional';

const createMatchupView = ballotMatchupSummary => ({
  id: ballotMatchupSummary.id,
  groupId: ballotMatchupSummary.groupId,
  name: ballotMatchupSummary.name,
  isWinnersPublished: ballotMatchupSummary.isWinnersPublished,
  hasNoEntries: ballotMatchupSummary.pendingCount === 0 && ballotMatchupSummary.approvedCount === 0,
  displayOrder: ballotMatchupSummary.displayOrder,
});

const createStatusCountView = ballotMatchupSummary => ({
  id: ballotMatchupSummary.id,
  groupId: ballotMatchupSummary.groupId,
  pendingCount: ballotMatchupSummary.pendingCount,
  approvedCount: ballotMatchupSummary.approvedCount,
  pendingCountLength: ballotMatchupSummary.pendingCount.toString().length,
  // we do not count a Ballot Matchup as 'approved' unless it has at least 2 approved entries
  approvedCountLength:
    ballotMatchupSummary.approvedCount > 1 ? ballotMatchupSummary.approvedCount.toString().length : 0,
});

export const createMatchupGroupViews = function (ballotMatchupSummaries, currentMatchupId) {
  const matchupGroupViewsById = {};
  for (const matchupSummary of ballotMatchupSummaries) {
    const foundMatchupGroupView = matchupGroupViewsById[matchupSummary.groupId];
    if (foundMatchupGroupView) {
      foundMatchupGroupView.matchups.addObject(createMatchupView(matchupSummary));
      continue;
    }
    matchupGroupViewsById[matchupSummary.groupId] = {
      name: matchupSummary.groupName,
      id: matchupSummary.groupId,
      displayOrder: matchupSummary.groupDisplayOrder,
      matchups: [createMatchupView(matchupSummary)],
      isExpanded: matchupSummary.groupId === ballotMatchupSummaries.findBy('id', currentMatchupId)?.groupId,
    };
  }

  return Object.values(matchupGroupViewsById);
};

export const createMatchupStatusCounts = function (ballotMatchupSummaries) {
  const matchupGroupViewsById = {};
  for (const matchupSummary of ballotMatchupSummaries) {
    const foundMatchupGroupView = matchupGroupViewsById[matchupSummary.groupId];
    if (foundMatchupGroupView) {
      foundMatchupGroupView.matchups.addObject(createStatusCountView(matchupSummary));
      continue;
    }
    matchupGroupViewsById[matchupSummary.groupId] = {
      id: matchupSummary.groupId,
      matchups: [createStatusCountView(matchupSummary)],
    };
  }

  Object.values(matchupGroupViewsById).forEach(group => {
    group.totalPending = group.matchups.reduce(addValues('pendingCount'), 0);
  });
  return Object.values(matchupGroupViewsById);
};

export default {
  createMatchupGroupViews,
  createMatchupStatusCounts,
};
