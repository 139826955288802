/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { and } from '@ember/object/computed';

export default Component.extend({
  //region Attributes
  'message-campaign': null,
  'messaging-statistic': null,
  'unsubscribed-chart': null,
  'unsubscribed-chart-categories-loaded-and-empty': null,
  'message-id': null,
  'message-view': null,
  'campaign-view': null,
  //endregion

  //region Computed Properties
  useRollingStatistics: and('message-campaign.isNewsletter', 'campaign-view'),

  peopleSentToCount: computed(
    'useRollingStatistics',
    'messaging-statistic.{peopleSentToCount,roundedRollingPeopleSentToCount}',
    function () {
      return this.useRollingStatistics
        ? get(this, 'messaging-statistic.roundedRollingPeopleSentToCount')
        : get(this, 'messaging-statistic.peopleSentToCount');
    }
  ),

  unsubscribedCount: computed(
    'useRollingStatistics',
    'messaging-statistic.{peopleWhoOptedOutCount,peopleWhoComplainedCount,roundedRollingPeopleWhoOptedOutCount,rollingPeopleWhoComplainedCount}',
    function () {
      const roundedRollingPeopleWhoOptedOutCount = get(
        this,
        'messaging-statistic.roundedRollingPeopleWhoOptedOutCount'
      );
      const rollingPeopleWhoComplainedCount = get(this, 'messaging-statistic.rollingPeopleWhoComplainedCount');
      const peopleWhoOptedOutCount = get(this, 'messaging-statistic.peopleWhoOptedOutCount');
      const peopleWhoComplainedCount = get(this, 'messaging-statistic.peopleWhoComplainedCount');

      return this.useRollingStatistics
        ? roundedRollingPeopleWhoOptedOutCount + rollingPeopleWhoComplainedCount
        : peopleWhoOptedOutCount + peopleWhoComplainedCount;
    }
  ),

  unsubscribedPercent: computed(
    'useRollingStatistics',
    'messaging-statistic.{peopleWhoOptedOutPercent,rollingOptOutPercent}',
    function () {
      return this.useRollingStatistics
        ? get(this, 'messaging-statistic.rollingOptOutPercent')
        : get(this, 'messaging-statistic.peopleWhoOptedOutPercent');
    }
  ),

  issuesCount: computed(
    'useRollingStatistics',
    'messaging-statistic.{issuesCount,roundedRollingIssuesCount}',
    function () {
      return this.useRollingStatistics
        ? get(this, 'messaging-statistic.roundedRollingIssuesCount')
        : get(this, 'messaging-statistic.issuesCount');
    }
  ),

  issuesPercent: computed(
    'useRollingStatistics',
    'messaging-statistic.{issuesPercent,rollingIssuesPercent}',
    function () {
      return this.useRollingStatistics
        ? get(this, 'messaging-statistic.rollingIssuesPercent')
        : get(this, 'messaging-statistic.issuesPercent');
    }
  ),

  issuesChartData: computed(
    'useRollingStatistics',
    'messaging-statistic.{issuesChartData,rollingIssuesChartData}',
    function () {
      return this.useRollingStatistics
        ? get(this, 'messaging-statistic.rollingIssuesChartData')
        : get(this, 'messaging-statistic.issuesChartData');
    }
  ),
  //endregion
});
