/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { computed, get, set, action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class extends Controller {
  @service current;
  @service enums;
  @service features;
  @service store;

  queryParams = ['domains'];

  @tracked showEditLocationModal = false;

  @alias('model.primaryLocation') primaryLocation;

  @computed('model.organization.timeZoneId')
  get selectedTimeZoneName() {
    return this.enums.findWhere('TIME_ZONES', { id: get(this, 'model.organization.timeZoneId') }, 'name');
  }

  @computed('model.organizationAttributes')
  get eblastApiGuid() {
    const id = this.enums.findWhere('ORGANIZATION_ATTRIBUTE_TYPE', { name: 'isEblastApiGuid' }, 'id');
    const attribute = get(this, 'model.organizationAttributes').content.findBy(
      'organizationAttributeType.id',
      id.toString()
    );
    return attribute ? get(attribute, 'value') : null;
  }

  @computed('model.organizationAttributes')
  get hasAdestraIntegration() {
    const id = this.enums.findWhere('ORGANIZATION_ATTRIBUTE_TYPE', { name: 'HasAdestraIntegration' }, 'id');
    const attribute = this.model.organizationAttributes.findBy('organizationAttributeTypeId', id);

    return !!attribute;
  }

  @computed('model.organizationPaymentProcessors')
  get hasStripeIntegration() {
    const result = this.model.organizationPaymentProcessors.any(
      processor => processor.paymentProcessorName === 'Stripe'
    );

    return result;
  }

  @computed('model.organizationPaymentProcessors')
  get isStripeIntegrationErrored() {
    return this.model.organizationPaymentProcessors.any(
      processor => processor.paymentProcessorId === 1 && !processor.isValid
    );
  }

  @action
  toggleEditLocationModal() {
    this.showEditLocationModal = !this.showEditLocationModal;
  }

  @action
  setCategoryToBeDeleted(categoryToBeDeleted) {
    set(this, 'categoryToBeDeleted', categoryToBeDeleted);
  }

  @action
  updateDomains() {
    this.send('getUpdatedDomains');
  }
}
