/**
 * Library of various template builder attributes and their relevant information such as label and which component to
 * render.
 */

export const ATTR_MAPPING = [
  // GROUPING 1
  [
    {
      attrName: 'background-color',
      displayName: 'Background Color',
      childComponent: 'color',
    },
    {
      attrName: 'button-color',
      displayName: 'Button Color',
      childComponent: 'color',
    },
    {
      attrName: 'border-radius',
      displayName: 'Radius',
      childComponent: 'unit-input',
    },
    {
      attrName: 'border-width',
      displayName: 'Border',
      childComponent: 'unit-input',
    },
    {
      attrName: 'border-color',
      displayName: 'Border Color',
      childComponent: 'color',
    },
    {
      attrName: 'border-style',
      displayName: 'Style',
      childComponent: 'dropdown',
    },
    {
      attrName: 'height',
      displayName: 'Height',
      childComponent: 'unit-input',
    },
    {
      attrName: 'icon-style',
      displayName: 'Icon Style',
      childComponent: 'icon-style',
    },
    {
      attrName: 'icon-color',
      displayName: 'Icon Color',
      childComponent: 'color',
    },
    {
      attrName: 'button-background-color',
      displayName: 'Button Color',
      childComponent: 'color',
      articleCategory: 'button',
    },
    {
      attrName: 'button-border-radius',
      displayName: 'Radius',
      childComponent: 'unit-input',
      articleCategory: 'button',
    },
    {
      attrName: 'button-border-width',
      displayName: 'Border',
      childComponent: 'unit-input',
      articleCategory: 'button',
    },
    {
      attrName: 'button-border-color',
      displayName: 'Border Color',
      childComponent: 'color',
      articleCategory: 'button',
    },
    {
      attrName: 'content',
      displayName: 'Content',
      childComponent: 'content',
    },
  ],
  // GROUPING 2
  [
    {
      attrName: 'font-family',
      displayName: 'Font',
      childComponent: 'dropdown',
    },
    {
      attrName: 'font-size',
      displayName: 'Font Size',
      childComponent: 'unit-input',
    },
    {
      attrName: 'color',
      displayName: 'Font Color',
      childComponent: 'color',
    },
    {
      attrName: 'font-style',
      displayName: 'Font Style',
      childComponent: 'font-style',
    },
    {
      attrName: 'text-transform',
      displayName: 'Font Case',
      childComponent: 'dropdown',
    },
    {
      attrName: 'headline-font-family',
      displayName: 'Font',
      childComponent: 'dropdown',
      articleCategory: 'headline',
    },
    {
      attrName: 'headline-font-size',
      displayName: 'Font Size',
      childComponent: 'unit-input',
      articleCategory: 'headline',
    },
    {
      attrName: 'headline-color',
      displayName: 'Font Color',
      childComponent: 'color',
      articleCategory: 'headline',
    },
    {
      attrName: 'headline-font-style',
      displayName: 'Font Style',
      childComponent: 'font-style',
      articleCategory: 'headline',
    },
    {
      attrName: 'headline-text-transform',
      displayName: 'Font Case',
      childComponent: 'dropdown',
      articleCategory: 'headline',
    },
    {
      attrName: 'headline-align',
      displayName: 'Alignment',
      childComponent: 'alignment',
      articleCategory: 'headline',
    },
    {
      attrName: 'date-font-family',
      displayName: 'Font',
      childComponent: 'dropdown',
      articleCategory: 'date',
    },
    {
      attrName: 'date-font-size',
      displayName: 'Font Size',
      childComponent: 'unit-input',
      articleCategory: 'date',
    },
    {
      attrName: 'date-color',
      displayName: 'Font Color',
      childComponent: 'color',
      articleCategory: 'date',
    },
    {
      attrName: 'date-font-style',
      displayName: 'Font Style',
      childComponent: 'font-style',
      articleCategory: 'date',
    },
    {
      attrName: 'date-text-transform',
      displayName: 'Font Case',
      childComponent: 'dropdown',
      articleCategory: 'date',
    },
    {
      attrName: 'date-align',
      displayName: 'Alignment',
      childComponent: 'alignment',
      articleCategory: 'date',
    },
    {
      attrName: 'image-border-radius',
      displayName: 'Radius',
      childComponent: 'unit-input',
      articleCategory: 'image',
    },
    {
      attrName: 'image-border-width',
      displayName: 'Border',
      childComponent: 'unit-input',
      articleCategory: 'image',
    },
    {
      attrName: 'image-border-color',
      displayName: 'Border Color',
      childComponent: 'color',
      articleCategory: 'image',
    },
    {
      attrName: 'description-font-family',
      displayName: 'Font',
      childComponent: 'dropdown',
      articleCategory: 'description',
    },
    {
      attrName: 'description-font-size',
      displayName: 'Font Size',
      childComponent: 'unit-input',
      articleCategory: 'description',
    },
    {
      attrName: 'description-color',
      displayName: 'Font Color',
      childComponent: 'color',
      articleCategory: 'description',
    },
    {
      attrName: 'description-font-style',
      displayName: 'Font Style',
      childComponent: 'font-style',
      articleCategory: 'description',
    },
    {
      attrName: 'description-text-transform',
      displayName: 'Font Case',
      childComponent: 'dropdown',
      articleCategory: 'description',
    },
    {
      attrName: 'description-align',
      displayName: 'Alignment',
      childComponent: 'alignment',
      articleCategory: 'description',
    },
    {
      attrName: 'description-line-height',
      displayName: 'Line Spacing',
      childComponent: 'unit-input',
      articleCategory: 'description',
    },
    {
      attrName: 'button-font-family',
      displayName: 'Font',
      childComponent: 'dropdown',
      articleCategory: 'button',
    },
    {
      attrName: 'button-font-size',
      displayName: 'Font Size',
      childComponent: 'unit-input',
      articleCategory: 'button',
    },
    {
      attrName: 'button-color',
      displayName: 'Font Color',
      childComponent: 'color',
      articleCategory: 'button',
    },
    {
      attrName: 'button-font-style',
      displayName: 'Font Style',
      childComponent: 'font-style',
      articleCategory: 'button',
    },
    {
      attrName: 'button-text-transform',
      displayName: 'Font Case',
      childComponent: 'dropdown',
      articleCategory: 'button',
    },
    {
      attrName: 'button-align',
      displayName: 'Alignment',
      childComponent: 'alignment',
      articleCategory: 'button',
    },
    {
      attrName: 'button-box-shadow',
      displayName: 'Drop Shadow',
      childComponent: 'toggle',
      articleCategory: 'button',
    },
  ],
  // GROUPING 3
  [
    {
      attrName: 'icon-size',
      displayName: 'Height',
      childComponent: 'unit-input',
    },
    {
      attrName: 'align',
      displayName: 'Alignment',
      childComponent: 'alignment',
    },
    {
      attrName: 'icon-spacing',
      displayName: 'Spacing',
      childComponent: 'unit-input',
    },
    {
      attrName: 'padding',
      displayName: 'Padding',
      childComponent: 'padding',
    },
    {
      attrName: 'width',
      displayName: 'Width',
      childComponent: 'unit-input',
    },
    {
      attrName: 'line-height',
      displayName: 'Line Spacing',
      childComponent: 'unit-input',
    },
    {
      attrName: 'box-shadow',
      displayName: 'Drop Shadow',
      childComponent: 'toggle',
    },
    {
      attrName: 'full-width',
      displayName: 'Full Width',
      childComponent: 'toggle',
    },
  ],
];

export const LIMIT_MAPPING = [
  {
    attrName: 'icon-size',
    componentTag: 'ss-social-icons',
    max: 90,
    min: 30,
  },
  {
    attrName: 'icon-spacing',
    componentTag: 'ss-social-icons',
    max: 90,
    min: 0,
  },
  {
    attrName: 'font-size',
    componentTag: 'ss-text',
    max: 200,
    min: 0,
  },
  {
    attrName: 'font-size',
    componentTag: 'ss-menu-links',
    max: 24,
    min: 12,
  },
  {
    attrName: 'border-width',
    componentTag: 'ss-button',
    max: 80,
    min: 0,
  },
  {
    attrName: 'font-size',
    componentTag: 'ss-button',
    max: 100,
    min: 0,
  },
  {
    attrName: 'width',
    componentTag: 'ss-button',
    max: 100,
    min: 15,
  },
  {
    attrName: 'line-height',
    componentTag: 'ss-text',
    max: 10,
    min: 1,
  },
  {
    attrName: 'description-line-height',
    componentTag: 'ss-articles',
    max: 10,
    min: 1,
  },
  {
    attrName: 'headline-font-size',
    componentTag: 'ss-articles',
    max: 100,
    min: 0,
  },
  {
    attrName: 'image-border-width',
    componentTag: 'ss-articles',
    max: 80,
    min: 0,
  },
  {
    attrName: 'description-font-size',
    componentTag: 'ss-articles',
    max: 100,
    min: 0,
  },
  {
    attrName: 'date-font-size',
    componentTag: 'ss-articles',
    max: 100,
    min: 0,
  },
  {
    attrName: 'border-width',
    componentTag: 'ss-section',
    max: 80,
    min: 0,
  },
  {
    attrName: 'width',
    componentTag: 'ss-image',
    max: 100,
    min: 0,
  },
  {
    attrName: 'width',
    componentTag: 'ss-text',
    max: 100,
    min: 0,
  },

  {
    attrName: 'width',
    componentTag: 'ss-divider',
    max: 100,
    min: 0,
  },
];
