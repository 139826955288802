/* eslint-disable ember/no-classic-classes */
/* globals FS */
import Service from '@ember/service';
import ENV from 'partner/config/environment';

/**
 * Contains logic for interacting with the FullStory API
 */
export default Service.extend({
  //region Methods
  logCustomEvent(eventName, options) {
    try {
      if (FS && FS.identify && ENV.environment === 'production') {
        FS.event(eventName, options);
      }
    } catch (e) {
      console.error(e);
    }
  },
  //endregion
});
