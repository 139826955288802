/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend({
  //region Properties
  matchupEntryId: DS.attr('number'),
  matchupId: DS.attr('number'),
  organizationUserId: DS.attr('number'),
  voteWeight: DS.attr('number'),
  dateCreated: DS.attr('date'),
  dateModified: DS.attr('date'),
  currentCompletionPercentage: DS.attr('number'),
  previousCompletionPercentage: DS.attr('number'),
  //endregion

  //region Relationships
  organizationPromotion: DS.belongsTo('organization-promotion', { async: true }),
  //endregion
});
