/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import MessageCampaignListRoute from 'partner/mixins/message-campaign-list-route';

/**
 * Campaigns Index Route
 * /o/:organization_id/message-campaigns
 * @type {Ember.Route}
 * @mixes MessageCampaignListRoute
 */
export default Route.extend(MessageCampaignListRoute, {
  resetController(controller) {
    set(controller, 'isCalendarVisible', false);
  },
});
