/* eslint-disable ember/no-get, ember/no-mixins */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import PromotionTypeRoute from 'partner/mixins/promotion-type-route';

/**
 * Dashboard Index Route
 * /o/:organization_id/op/:organization_promotion_id/dashboard
 * @type {Ember.Route}
 * @mixes PromotionTypeRoute
 */
export default class IndexRoute extends Route.extend(PromotionTypeRoute) {
  @service router;

  /**
   * Redirect the user to the appropriate dashboard path
   * @see http://emberjs.com/api/classes/Ember.Route.html#method_afterModel
   */
  redirect() {
    let destination;

    switch (
      get(
        this.modelFor('organizations.organization.organization-promotions.organization-promotion'),
        'organizationPromotion.promotion.promotionTypeName'
      )
    ) {
      case 'Survey':
        destination = 'organizations.organization.organization-promotions.organization-promotion.dashboard.survey';
        break;
      case 'Quiz':
        destination = 'organizations.organization.organization-promotions.organization-promotion.dashboard.quiz';
        break;
      case 'Poll':
        destination = 'organizations.organization.organization-promotions.organization-promotion.dashboard.quiz';
        break;
      case 'UGCSweepstakes':
        destination =
          'organizations.organization.organization-promotions.organization-promotion.dashboard.ugc-sweepstakes';
        break;
      case 'UGCVoting':
        destination = 'organizations.organization.organization-promotions.organization-promotion.dashboard.ugc-voting';
        break;
      case 'UGCGallery':
        destination = 'organizations.organization.organization-promotions.organization-promotion.dashboard.ugc-gallery';
        break;
      case 'Ballot':
        destination = 'organizations.organization.organization-promotions.organization-promotion.dashboard.ballot';
        break;
      case 'VotingBracket':
        destination = 'organizations.organization.organization-promotions.organization-promotion.dashboard.bracket';
        break;
      case 'EventSignup':
        destination = 'organizations.organization.organization-promotions.organization-promotion.dashboard.event';
        break;
      case 'PaymentForm':
        destination =
          'organizations.organization.organization-promotions.organization-promotion.dashboard.payment-form';
        break;
      default:
        destination = 'organizations.organization.organization-promotions.organization-promotion.dashboard.sweepstakes';
        break;
    }

    this.router.transitionTo(destination);
  }
}
