/* eslint-disable ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, get, set } from '@ember/object';
import { not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import areAttrsChanged from 'partner/utils/are-attrs-changed';

export default Controller.extend({
  router: service(),
  //region Computed Properties
  isStepOneDirty: areAttrsChanged({
    model: ['isScrape'],
  }),
  isHtmlDirty: areAttrsChanged({
    model: ['content'],
  }),
  isScrapeUrlDirty: areAttrsChanged({
    model: ['contentUrl'],
  }),
  stepOneStatus: 'bestPractice',
  htmlStatus: computed('model.content', function () {
    return get(this, 'model.content') ? 'bestPractice' : 'incomplete';
  }),
  scrapeUrlStatus: computed('model.contentUrl', function () {
    return get(this, 'model.contentUrl') ? 'bestPractice' : 'incomplete';
  }),
  isHtml: not('model.isScrape'),
  wrapperMethod: computed('model.isScrape', {
    get() {
      if (get(this, 'model.isScrape')) {
        return 'Scrape';
      }
      return 'Html';
    },
    set(key, value) {
      switch (value) {
        case 'Html':
          set(this, 'model.isScrape', false);
          set(this, 'isHtml', true);
          break;
        case 'Scrape':
          set(this, 'model.isScrape', true);
          set(this, 'isHtml', false);
          break;
      }
      return value;
    },
  }),
  //endregion

  actions: {
    save() {
      if (!get(this, 'model.isScrape')) {
        set(this, 'model.contentUrl', null);
      }
      this.model
        .save()
        .then(() => {
          this.router.transitionTo('organizations.organization.settings.info');
        })
        //region HAX: we can't get errors to show when using "content" as the attribute; use "contentHtml" instead
        .catch(ex => {
          if (ex.errors && ex.errors.length) {
            get(this, 'model.errors').add(
              this.model.isScrape ? 'contentUrl' : 'contentHtml',
              ex.errors.firstObject.detail
            );
          }
        });
      //endregion
    },
  },
});
