/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set, setProperties } from '@ember/object';
import { and, equal, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import clone from 'lodash/clone';
import union from 'lodash/union';
import partition from 'lodash/partition';

import { AbortedByUser } from 'secondstreet-common/utils/errors';
import enums from 'ember-cli-ss-enums/services/enums';

const FREQUENCY_ID_TYPE_HOURLY = enums.findWhere('OPTIN_NOTIFICATION_FREQUENCY_TYPE', { name: 'Hourly' });

const PERMISSION_ENTITY = 'OptinNotification';
const CONFIRM_MSG = 'Are you sure you want to remove the following recipients?';
const isDirty = model => model.isDirty;
const isShareableField = field => field && [1, 2, 3, 4, 5, 6, 7, 21, 22, 23].includes(field.fieldTypeId);

const modelArrayAdd = (array, attr, value, create) => {
  const existing = array.findBy(attr, value);

  if (!existing) {
    array.addObject(create());
    return;
  }

  if (existing.isDeleted) {
    existing.rollbackAttributes();
  }
};

const modelArrayRemove = (array, attr, value) => {
  const existing = array.findBy(attr, value);

  if (!existing) {
    return;
  }

  if (existing.isNew) {
    array.removeObject(existing);
  }

  existing.deleteRecord();
};

/**
 * @callback confirmSaveCallback
 */

/**
 * @callback saveCallback
 * @returns {Promise} Resolves when saving is complete.
 */

/**
 * @callback cancelCallback
 */

/**
 * @callback emailCallback
 * @param {string} emailAddress Email address to add or remove.
 */

/**
 * @callback fieldCallback
 * @param {Field} sharedField Field to add or remove.
 */

/**
 * @typedef {Object} OptinNotificationContext
 * @property {OptinNotification[]} optinNotifications
 * @property {OptinNotificationSharedDataField[]} optinNotificationSharedDataFields
 * @property {Field[]} shareableFields
 * @property {boolean} isViewable
 * @property {boolean} isEditable
 * @property {boolean} isLoading
 * @property {boolean} isSaving
 * @property {boolean} isSharing
 * @property {boolean} hasDirtyAttributes
 * @property {emailCallback} addEmailAddress
 * @property {emailCallback} removeEmailAddress
 * @property {fieldCallback} addSharedField
 * @property {fieldCallback} removeSharedField
 * @property {confirmSaveCallback} confirmSave
 * @property {saveCallback} save
 * @property {cancelCallback} cancel
 */

export default Component.extend({
  //region Dependencies
  enums: service(),
  features: service(),
  permissions: service(),
  store: service(),
  //endregion

  //region Attributes
  field: null,
  form: null,
  //endregion

  //region Ember Hooks
  async didReceiveAttrs() {
    this._super(...arguments);
    this.resetComponent();

    if (!this.isViewable) {
      return;
    }

    if (!this.field.isNew) {
      await this.loadData();
    }

    this.ensureDefaultFields();
  },
  //endregion

  //region Properties
  /** @property {Field[]} */
  associatedFields: null,
  /** @property {OptinNotification[]} */
  optinNotifications: null,
  postMessageNotifications: null,
  /** @property {OptinNotificationSharedDataField[]} */
  optinNotificationSharedDataFields: null,
  /** @property {boolean} */
  isLoading: false,
  /** @property {boolean} */
  isSaving: false,
  /** @property {string} */
  cachedFieldName: '',
  optedForPostMessageNotifications: false,
  //endregion

  //region Computed Properties
  _isSharing: computed('{optinNotifications,postMessageNotifications}.@each.isDeleted', function () {
    return !isEmpty([...this.optinNotifications, ...this.postMessageNotifications]);
  }).readOnly(),
  isSharing: computed('_isSharing', {
    get() {
      return this._isSharing;
    },
    set(_, value) {
      if (value) {
        this.send('cancel');
      } else {
        // We run the loop off of a cloned instance so that we're not modifying the array
        // we're looping, which can cause side effects
        for (const optinNotification of clone(this.optinNotifications)) {
          this.send('removeEmailAddress', optinNotification.emailAddress);
        }

        for (const postMessageNotification of clone(this.postMessageNotifications)) {
          this.send('removePostMessageEmailAddress', postMessageNotification.emailAddress);
        }

        for (const optinNotificationSharedDataField of clone(this.optinNotificationSharedDataFields)) {
          this.send('removeSharedField', optinNotificationSharedDataField.sharedDataField);
        }
      }
      return value;
    },
  }),
  hasViewablePermission: computed('permissions.permissions.@each.permissionTypeId', function () {
    return this.permissions.getAccessLevel(PERMISSION_ENTITY).view;
  }).readOnly(),
  hasEditablePermission: computed('permissions.permissions.@each.permissionTypeId', function () {
    return this.permissions.getAccessLevel(PERMISSION_ENTITY).administer;
  }).readOnly(),
  isNotDefaultForm: not('form.isDefault').readOnly(),
  isNotExtraChancesForm: not('form.isExtraChances').readOnly(),
  isNotOrganizationLevelForm: not('isOrganizationLevelForm').readOnly(),
  isOptinField: equal('field.fieldType', 'Optin').readOnly(),
  isViewable: and(
    'isOptinField',
    'hasViewablePermission',
    'features.hasHotLeads',
    'isNotDefaultForm',
    'isNotExtraChancesForm',
    'isNotOrganizationLevelForm'
  ).readOnly(),
  isEditable: and('isViewable', 'hasEditablePermission').readOnly(),
  optinNotificationFrequencyTypeId: computed(function () {
    return this.enums.findWhere('OPTIN_NOTIFICATION_FREQUENCY_TYPE', { name: 'Hourly' }, 'id');
  }).readOnly(),
  fieldsFromForm: computed('form.allFields', function () {
    return this.form ? this.form.allFields : [];
  }).readOnly(),
  fieldsFromExtraChancesForm: computed('extraChancesForm', function () {
    return this.extraChancesForm ? this.extraChancesForm.allFields : [];
  }).readOnly(),
  shareableFields: computed('{fieldsFromForm,associatedFields,fieldsFromExtraChancesForm}.[]', function () {
    return union(this.fieldsFromForm, this.associatedFields, this.fieldsFromExtraChancesForm).filter(isShareableField);
  }).readOnly(),
  isDirty: computed(
    '{optinNotifications,optinNotificationSharedDataFields,postMessageNotifications}.@each.isDirty',
    'optedForPostMessageNotifications',
    function () {
      return (
        this.optinNotifications.any(isDirty) ||
        this.optinNotificationSharedDataFields.any(isDirty) ||
        this.postMessageNotifications.any(isDirty)
      );
    }
  ).readOnly(),
  isSaveDisabled: computed('isSharing', 'optinNotifications.[]', 'postMessageNotifications.[]', function () {
    return this.isSharing && isEmpty(this.optinNotifications) && isEmpty(this.postMessageNotifications);
  }),

  selectedFrequencyTypeId: computed('optinNotifications.[]', function () {
    const id = this.optinNotifications.find(
      optinNotification => !isNaN(optinNotification.defaultOptinNotificationFrequencyTypeId)
    )?.defaultOptinNotificationFrequencyTypeId;
    return isNaN(id) ? FREQUENCY_ID_TYPE_HOURLY : id;
  }),
  //endregion

  //region Methods
  resetComponent() {
    setProperties(this, {
      associatedFields: [],
      optinNotifications: [],
      postMessageNotifications: [],
      optinNotificationSharedDataFields: [],
      isLoading: false,
      isSaving: false,
      optedForPostMessageNotifications: false,
    });
  },
  async loadData() {
    const optinFieldId = this.field.id;
    set(this, 'isLoading', true);
    try {
      const [associatedFields, allOptinNotifications, optinNotificationSharedDataFields] = await Promise.all([
        this.store.query('field', { associatedWithFieldId: optinFieldId }),
        this.store.query('optinNotification', { optinFieldId }),
        this.store.query('optinNotificationSharedDataField', { optinFieldId }),
      ]);

      const [postMessageNotifications, optinNotifications] = partition(
        allOptinNotifications.toArray(),
        ({ optinNotificationFrequencyTypeId }) =>
          optinNotificationFrequencyTypeId == 5 || optinNotificationFrequencyTypeId == 6
      );

      setProperties(this, {
        associatedFields: associatedFields.toArray(),
        optinNotifications,
        postMessageNotifications,
        optinNotificationSharedDataFields: optinNotificationSharedDataFields.toArray(),
        optedForPostMessageNotifications: postMessageNotifications.length ? true : false,
      });
    } finally {
      if (!this.isDestroyed) {
        set(this, 'isLoading', false);
      }
    }
  },
  ensureDefaultFields() {
    if (!this.isEditable) {
      return;
    }

    if (isEmpty(this.optinNotificationSharedDataFields)) {
      this.shareableFields.forEach(field => this.send('addSharedField', field));
    } else if (this.optinNotificationSharedDataFields.findBy('sharedDataField.name', 'Email')) {
      const emailField = this.shareableFields.findBy('name', 'Email');
      if (emailField) {
        this.send('addSharedField', emailField);
      }
    }
  },
  // The optinField is destroyed by Form.save() if it was new, so all the
  // models need to be updated to the one in the store that actually contains
  // an ID.
  ensureFieldId() {
    const newOptinField = this.store.peekAll('field').findBy('name', this.cachedFieldName);
    this.optinNotifications.forEach(x => set(x, 'optinField', newOptinField));
    this.postMessageNotifications.forEach(x => set(x, 'optinField', newOptinField));
    this.optinNotificationSharedDataFields.forEach(x => set(x, 'optinField', newOptinField));
  },
  cleanUp() {
    setProperties(this, {
      optinNotifications: this.optinNotifications.rejectBy('isDeleted'),
      postMessageNotifications: this.postMessageNotifications.rejectBy('isDeleted'),
      optinNotificationSharedDataFields: this.optinNotificationSharedDataFields.rejectBy('isDeleted'),
    });
  },

  updatePostMessageOptins(options) {
    isPresent(this.postMessageNotifications) &&
      set(
        this,
        'postMessageNotifications',
        this.postMessageNotifications.map(optin => {
          setProperties(optin, options);
          return optin;
        })
      );
  },

  //endregion

  //region Actions
  actions: {
    toggleSharing() {
      set(this, 'isSharing', !this.isSharing);
    },
    addEmailAddress(emailAddress) {
      if (!this.isEditable || !emailAddress) {
        return;
      }

      modelArrayAdd(this.optinNotifications, 'emailAddress', emailAddress, () =>
        this.store.createRecord('optin-notification', {
          optinField: this.field,
          optinNotificationFrequencyTypeId: this.selectedFrequencyTypeId,
          defaultOptinNotificationFrequencyTypeId: this.selectedFrequencyTypeId,
          emailAddress,
        })
      );
    },

    removeEmailAddress(emailAddress) {
      if (!this.isEditable) {
        return;
      }

      modelArrayRemove(this.optinNotifications, 'emailAddress', emailAddress);
    },

    addPostNotificationEmailAddress(emailAddress, frequencyTypeId, scheduledDate) {
      if (!this.isEditable || !emailAddress) {
        return;
      }

      modelArrayAdd(this.postMessageNotifications, 'emailAddress', emailAddress, () =>
        this.store.createRecord('optin-notification', {
          optinField: this.field,
          optinNotificationFrequencyTypeId: frequencyTypeId,
          scheduledDate,
          emailAddress,
        })
      );
    },

    removePostMessageEmailAddress(emailAddress) {
      if (!this.isEditable) {
        return;
      }

      modelArrayRemove(this.postMessageNotifications, 'emailAddress', emailAddress);
    },

    updatePostMessageOptins() {
      this.updatePostMessageOptins(...arguments);
    },

    setOptedForPostMessageNotifications(value) {
      if (value) {
        this.postMessageNotifications.forEach(x => x.rollbackAttributes());
        if (isEmpty(this.postMessageNotifications)) {
          this.optinNotifications.rejectBy('isDeleted').forEach(optin => {
            this.send('addPostNotificationEmailAddress', optin.emailAddress, 5, null);
          });
        }
      } else {
        for (const postMessageNotification of clone(this.postMessageNotifications)) {
          this.send('removePostMessageEmailAddress', postMessageNotification.emailAddress);
        }
      }
      set(this, 'optedForPostMessageNotifications', value);
    },

    addSharedField(field) {
      if (!this.isEditable) {
        return;
      }

      modelArrayAdd(this.optinNotificationSharedDataFields, 'sharedDataField', field, () =>
        this.store.createRecord('optin-notification-shared-data-field', {
          optinField: this.field,
          sharedDataField: field,
        })
      );
    },
    removeSharedField(field) {
      if (!this.isEditable) {
        return;
      }

      modelArrayRemove(this.optinNotificationSharedDataFields, 'sharedDataField', field);
    },
    confirmSave() {
      if (!this.isEditable) {
        return;
      }

      const deletedEmails = [...this.optinNotifications, ...this.postMessageNotifications]
        .filter(x => x.isDeleted && !x.isNew)
        .mapBy('emailAddress');
      if (!isEmpty(deletedEmails)) {
        const PREFIX = '\n    - ';
        if (!window.confirm(`${CONFIRM_MSG}\n${PREFIX}${deletedEmails.join(PREFIX)}`)) {
          throw new AbortedByUser('During deleting optin notification check.');
        }
      }

      // If the field is new, its object will be destroyed by Form.save(). The
      // name is stored here so that the new field object that contains an ID
      // can be found.
      set(this, 'cachedFieldName', this.field.name);
    },
    async save() {
      if (!this.isEditable) {
        return;
      }

      this.ensureFieldId();

      set(this, 'isSaving', true);
      try {
        await Promise.all([
          ...this.optinNotifications.filter(isDirty).map(x => x.save()),
          ...this.postMessageNotifications.filter(isDirty).map(x => x.save()),
          ...this.optinNotificationSharedDataFields.filter(isDirty).map(x => x.save()),
        ]);
      } finally {
        set(this, 'isSaving', false);
      }

      this.cleanUp();
    },
    cancel() {
      if (!this.isEditable) {
        return;
      }

      this.optinNotifications.forEach(x => x.rollbackAttributes());
      this.postMessageNotifications.forEach(x => x.rollbackAttributes());
      this.optinNotificationSharedDataFields.forEach(x => x.rollbackAttributes());

      this.cleanUp();
      this.ensureDefaultFields();
    },

    setFormFieldNotificationFrequency(frequency) {
      this.optinNotifications.filterBy('isNew').forEach(x =>
        setProperties(x, {
          optinNotificationFrequencyTypeId: frequency.id,
          defaultOptinNotificationFrequencyTypeId: frequency.id,
        })
      );

      this.optinNotifications.rejectBy('isNew').forEach(x => {
        if (x.optinNotificationFrequencyTypeId == x.defaultOptinNotificationFrequencyTypeId) {
          setProperties(x, {
            optinNotificationFrequencyTypeId: frequency.id,
            defaultOptinNotificationFrequencyTypeId: frequency.id,
          });
        } else {
          setProperties(x, {
            defaultOptinNotificationFrequencyTypeId: frequency.id,
          });
        }
      });
    },
  },
  //endregion
});
