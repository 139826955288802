import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import enums from 'ember-cli-ss-enums/services/enums';
import { sortBy } from 'ember-composable-helpers/helpers/sort-by';
import RSVP from 'rsvp';

const ADESTRA_ORGANIZATION_ATTRIBUTE_TYPE_ID = enums.findWhere(
  'ORGANIZATION_ATTRIBUTE_TYPE',
  { name: 'HasAdestraIntegration' },
  'id'
);
const ADESTRA_INTEGRATION_TYPE_ID = enums.findWhere('AUDIENCE_INTEGRATION_TYPE', { name: 'Adestra' }, 'id');
const ACTIVE_STATUS_TYPE_ID = enums.findWhere('STATUS_TYPE', { name: 'Active' }, 'id');

export default class OrganizationsOrganizationDataAudiencesAdestraRoute extends Route {
  @service router;
  @service store;

  hasAdestraIntegration = false;

  get organization() {
    return this.modelFor('organizations.organization').organization;
  }

  async model() {
    const { organization_id: organizationId } = this.paramsFor('organizations.organization');
    const { audiences, attrs } = await RSVP.hash({
      audiences: this.store
        .query('audience', { organizationId, IncludeIntegration: true, ForceUpdateCount: true })
        .then(data => data.filter(audience => audience.isSubscriptionAudience))
        .then(this.selectRecommendedAudiences.bind(this)),
      attrs: this.store.query('organizationAttribute', {
        organizationId,
        organizationAttributeTypeId: ADESTRA_ORGANIZATION_ATTRIBUTE_TYPE_ID,
      }),
    });
    this.hasErrorGuard(attrs.firstObject);
    this.hasAdestraIntegration = attrs.length > 0;
    return { audiences: this.sortAudiences(audiences), organization: this.organization };
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.authorized = this.hasAdestraIntegration;
    controller.createAdestraIntegration = this.createAdestraIntegration;
    controller.addExitHandler();
  }

  hasErrorGuard(organizationAttribute) {
    const hasError = organizationAttribute?.attributeValueMap?.fields_map?.some(fieldMap => fieldMap?.error_code);
    if (hasError) {
      this.router.transitionTo('organizations.organization.adestra.configure-fields');
    }
  }

  resetController(controller) {
    controller.confirmedLeave = false;
    controller.searchValue = '';
    controller.audiences.forEach(
      audience => audience.adestraIntegration?.hasDirtyAttributes && audience.adestraIntegration.rollbackAttributes()
    );
  }

  hasAnyIntegratedAudience(audiences) {
    return audiences.some(audience => audience.adestraIntegration?.statusTypeId);
  }

  selectRecommendedAudiences(audiences) {
    if (!this.hasAnyIntegratedAudience(audiences)) {
      for (const audience of audiences) {
        if (audience.isRecommendedForIntegration)
          this.createAdestraIntegration(audience).statusTypeId = ACTIVE_STATUS_TYPE_ID;
      }
    }
    return audiences;
  }

  createAdestraIntegration(audience) {
    return this.store.createRecord('audienceIntegration', {
      audience,
      organization: this.model.organization,
      integrationExternalServiceId: ADESTRA_INTEGRATION_TYPE_ID,
      syncOptOuts: true,
    });
  }

  sortAudiences(data) {
    const a = sortBy([
      'dateCreated:desc',
      data.filter(({ isLegacyAudience, isRecentlyCreated }) => !isLegacyAudience && isRecentlyCreated),
    ]);
    const b = sortBy([
      'lastSentDate:desc',
      data.filter(
        ({ isLegacyAudience, isRecentlyCreated, isSent }) => !isLegacyAudience && !isRecentlyCreated && isSent
      ),
    ]);
    const c = sortBy([
      'latestCount:desc',
      data.filter(
        ({ isLegacyAudience, isRecentlyCreated, isSent }) => !isLegacyAudience && !isRecentlyCreated && !isSent
      ),
    ]);
    const d = sortBy(['name', data.filter(({ isLegacyAudience }) => isLegacyAudience)]);

    // Group ones with already existing integration at the bottom
    const hasAlreadyExistingIntegration = audience => !audience.adestraIntegration && audience.hasExistingIntegration;
    const sortByHasAlreadyExistingIntegration = (a, b) =>
      hasAlreadyExistingIntegration(a) - hasAlreadyExistingIntegration(b);
    return sortBy([
      'adestraIntegration.isActive:desc',
      sortByHasAlreadyExistingIntegration,
      'isStarred:desc',
      a.concat(b, c, d),
    ]);
  }

  deactivate() {
    this.controller.removeExitHandler();
  }
}
