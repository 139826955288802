/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { alias } from '@ember/object/computed';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

/**
 * Relationship between a Organization and SsProduct.
 * @typedef {DS.Model} OrganizationSsProduct
 */
export default BaseModel.extend({
  organization: DS.belongsTo('organization', { async: false, inverse: 'organizationSsProducts' }),
  ssProducts: DS.belongsTo('ss-product', { async: false }),

  //alias because the api always sends back plural
  ssProduct: alias('ssProducts'),
  ssProductName: alias('ssProduct.name'),
});
