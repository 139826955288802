/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default Route.extend({
  store: service(),

  //region Ember Hooks
  async model() {
    const consumerUserId = get(this.modelFor('organizations.organization.people.person'), 'consumerUser.id');
    const sentEmails = await this.store.query('sentEmail', {
      consumerUserId,
      pageSize: 25,
      pageIndex: 1,
    });
    return RSVP.hash({
      sentEmails,
      currentMeta: get(sentEmails, 'meta'),
      isLoadingSentEmails: false,
    });
  },
  //endregion

  //region Methods
  async pageChanged(page) {
    const consumerUserId = get(this.modelFor('organizations.organization.people.person'), 'consumerUser.id');
    const params = {
      consumerUserId,
      pageSize: 25,
      pageIndex: page,
    };
    set(this, 'controller.isLoadingSentEmails', true);
    const sentEmails = await RSVP.hash({
      sentEmails: this.store.query('sentEmail', params),
    });
    this.finishLoadingSentEmails(sentEmails.sentEmails);
  },

  /**
   * Set sentEmails and corresonding meta deta on the model
   * @param {Promise} sentEmails Promise of all sentEmail records returned
   */
  finishLoadingSentEmails(sentEmails) {
    set(this, 'controller.model.sentEmails', sentEmails.toArray());
    set(this, 'controller.model.currentMeta', sentEmails.meta);
    set(this, 'controller.isLoadingSentEmails', false);
  },
  //endregion

  //region Actions
  actions: {
    pageChanged(page) {
      this.pageChanged(page);
    },
    async searchValueChanged(searchValue) {
      // Don't search on one single character
      if (searchValue.length === 1) {
        return;
      }
      set(this, 'controller.isLoadingSentEmails', true);
      const consumerUserId = get(this.modelFor('organizations.organization.people.person'), 'consumerUser.id');
      const params = {
        consumerUserId,
        pageSize: 25,
        pageIndex: 1,
      };
      if (searchValue.length >= 2) {
        set(params, 'searchValue', searchValue);
      }
      const sentEmails = await RSVP.hash({
        sentEmails: this.store.query('sentEmail', params),
      });
      this.finishLoadingSentEmails(sentEmails.sentEmails);
    },
  },
  //endregion
});
