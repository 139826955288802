/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';

export default Route.extend(AppContextRoute, {
  store: service(),

  async model(params) {
    const organizationSalesPackages = this.modelFor('organizations.organization.licenses');
    return organizationSalesPackages.findBy('id', params.license_id);
  },

  serialize(model = { id: 'new' }) {
    return { license_id: get(model, 'id') };
  },

  async setupController(controller) {
    this._super(...arguments);
    const { organization } = this.modelFor('organizations.organization');
    const organizationParams = { organizationId: organization.id };
    const invoiceRecipients = await this.store.query('invoice-recipient', organizationParams);
    run(() => {
      set(controller, 'invoiceRecipients', invoiceRecipients.toArray());
    });
  },
});
