/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, get } from '@ember/object';
import HasCategoricalCharts from 'partner/mixins/has-categorical-charts';

export default Controller.extend(HasCategoricalCharts, {
  areChartsAllLoaded: computed(
    'postalCodeChart.categories',
    'postalCodeChartCategoriesLoadedAndEmpty',
    'genderChart.categories',
    'genderChartCategoriesLoadedAndEmpty',
    'birthdateChart.categories',
    'birthdateChartCategoriesLoadedAndEmpty',
    'topTagsChart.categories',
    'topTagsChartCategoriesLoadedAndEmpty',
    function () {
      const postalCategories = get(this, 'postalCodeChart.categories');
      const postalEmpty = this.postalCodeChartCategoriesLoadedAndEmpty;
      const genderCategories = get(this, 'genderChart.categories');
      const genderEmpty = this.genderChartCategoriesLoadedAndEmpty;
      const birthdateCategories = get(this, 'birthdateChart.categories');
      const birthdateEmpty = this.birthdateChartCategoriesLoadedAndEmpty;
      const tagsCategories = get(this, 'topTagsChart.categories');
      const tagsEmpty = this.topTagsChartCategoriesLoadedAndEmpty;

      return Boolean(
        (postalCategories.length > 0 || postalEmpty) &&
          (genderCategories.length > 0 || genderEmpty) &&
          (birthdateCategories.length > 0 || birthdateEmpty) &&
          (tagsCategories.length > 0 || tagsEmpty)
      );
    }
  ),
});
