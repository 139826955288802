import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';

export default class extends Controller {
  @controller('organizations.organization.organization-promotions.organization-promotion.moderate-nominations')
  moderateNominations;

  @action
  openMatchup(matchupId) {
    this.moderateNominations.set('matchupId', matchupId);
  }
}
