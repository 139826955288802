import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex flex-col gap-4 px-4 text-center pb-4\">\n  {{#each this.layoutOptions as |option|}}\n    <button\n      role=\"button\"\n      type=\"button\"\n      class=\"rounded border border-solid border-gray-200 p-4 bg-transparent\"\n      {{on \"click\" (fn this.onLayoutChange option)}}\n    >\n      <div class=\"flex gap-4 items-center h-10\">\n        <SsRadio\n          @value={{option.value}}\n          @selection={{this.layoutOptionToken.value}}\n          name=\"ballot-sort-order\"\n          class=\"related-radio-input\"\n        />\n        <span class=\"text-sm text-black font-normal\">\n          {{option.name}}\n        </span>\n      </div>\n      <img src={{option.src}} width=\"138\" />\n    </button>\n  {{/each}}\n</div>", {"contents":"<div class=\"flex flex-col gap-4 px-4 text-center pb-4\">\n  {{#each this.layoutOptions as |option|}}\n    <button\n      role=\"button\"\n      type=\"button\"\n      class=\"rounded border border-solid border-gray-200 p-4 bg-transparent\"\n      {{on \"click\" (fn this.onLayoutChange option)}}\n    >\n      <div class=\"flex gap-4 items-center h-10\">\n        <SsRadio\n          @value={{option.value}}\n          @selection={{this.layoutOptionToken.value}}\n          name=\"ballot-sort-order\"\n          class=\"related-radio-input\"\n        />\n        <span class=\"text-sm text-black font-normal\">\n          {{option.name}}\n        </span>\n      </div>\n      <img src={{option.src}} width=\"138\" />\n    </button>\n  {{/each}}\n</div>","moduleName":"partner/components/template-designer/library/attributes/layout-options.hbs","parseOptions":{"srcName":"partner/components/template-designer/library/attributes/layout-options.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type { SsArcAttributeSignature } from '@ss/arc/arc/types';
import EnumsService from 'ember-cli-ss-enums/services/enums';
import type CurrentService from 'partner/services/current';

type Layout = {
  id: number;
  name: string;
  value: string;
  src: string;
};

export default class TemplateDesignerLayoutOptionsComponent extends Component<SsArcAttributeSignature> {
  @service declare current: CurrentService;
  @service declare enums: EnumsService;

  get layoutOptionToken() {
    return this.args.design.tokenContents.find(
      ({ tokenCategory }: { tokenCategory: string }) => tokenCategory == 'Gallery Layout'
    );
  }

  get layoutOptions(): Layout[] {
    switch (this.current.promotion.promotionTypeName) {
      case 'UGCSweepstakes':
      case 'UGCVoting':
      case 'UGCGallery':
        return this.enums.data.GALLERY_LAYOUTS;
      case 'Ballot':
        return this.enums.data.BALLOT_LAYOUTS;
      case 'Poll':
        return this.enums.data.POLL_LAYOUTS;
      default:
        return [];
    }
  }

  @action
  onLayoutChange(layout: Layout) {
    this.layoutOptionToken.value = layout.value;
    this.args.changePromotionView();
    this.args.save();
  }
}
