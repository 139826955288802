import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class DeliberateConfirmationSimplifedComponent extends Component {
  //region Dependencies
  @service deliberateConfirmation;
  //endregion

  //region Actions
  @action
  cancel() {
    this.deliberateConfirmation.cancel();
  }

  @action
  confirm() {
    this.deliberateConfirmation.confirm();
  }

  @action
  keyDown(event) {
    if (event.keyCode === 27) {
      this.cancel();
    }
  }

  @action
  attachKeyDownHandler() {
    document.addEventListener('keydown', this.keyDown);
  }

  @action
  detachKeyDownHandler() {
    document.removeEventListener('keydown', this.keyDown);
  }

  @action
  focus(element) {
    element.focus();
  }
  //endregion
}
