import { helper } from '@ember/component/helper';

/**
 *  {{integer-to-character 0}} will return "a", 1 will return "b", ...
 *  {{integer-to-character 0 upperCase=true}} will return "A", 1 will return "B", ...
 */
export function integerToCharacter([integer], hash) {
  let result = '';
  if (integer === undefined) {
    integer = hash.number;
  }
  try {
    result = String.fromCharCode(97 + integer);
    if (hash.upperCase) {
      return result.toUpperCase();
    }
  } catch (e) {
    console.error(e);
  }

  return result;
}

export default helper(integerToCharacter);
