/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { set, setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import MessageCampaignSnackbar from 'partner/mixins/message-campaign-snackbar';

const SEND_ON_CONFIRM_OFFSET = 15;

export default Route.extend(MessageCampaignSnackbar, {
  //region Ember Hooks
  snackbar: service(),
  enums: service(),
  deliberateConfirmation: service('deliberate-confirmation'),
  //endregion

  maybeSaveTask: task(function* () {
    if (this.controller.messageCampaign?.sendOnConfirm && this.controller.setupController?.confirmed) {
      yield this.doubleConfirm();
    } else {
      yield this.save();
    }
  }).drop(),

  //region Methods
  async doubleConfirm() {
    const confirmed = await this.deliberateConfirmation.show(this.controller.deliberateConfirmationText);

    if (confirmed) {
      setProperties(this.controller.singleMessageCampaign, {
        sendNowOffset: SEND_ON_CONFIRM_OFFSET,
        sendNow: true,
        isScheduled: true,
        undoPhase: true,
      });

      await this.save();

      this.showUndo();
    }

    set(this, 'controller.setupController.confirmed', confirmed);
    this.deliberateConfirmation.resetConfirmedStatus();
  },

  showUndo() {
    const undo = () => {
      setProperties(this.controller.singleMessageCampaign, {
        scheduleStartDate: null,
        isScheduled: false,
        undoPhase: false,
      });

      // Resets both MC and SMC is_confirmed to false
      set(this, 'controller.setupController.confirmed', false);

      this.save();
    };

    const timeout = () => {
      this.snackbar.show('This message was sent and can no longer be edited.', '', -1, null, true);
    };

    this.snackbar.show('Congrats! Your message was sent', 'Undo', 10000, null, true).then(undo).catch(timeout);
  },

  save() {
    set(this, 'controller.isAnythingSaving', true);

    return this.controller.setupController.saveConfirmation().then(() => {
      // Refresh the messageCampaign so that messageCampaign.sendingStatusTypeId is accurate (it is updated by the backend based upon related messages)
      set(this, 'controller.isAnythingSaving', false);
    });
  },
  //endregion

  //region Actions
  actions: {
    willTransition() {
      this._super();
      this.snackbar.hide();
    },

    save() {
      next(() => this.maybeSaveTask.perform());
    },

    saveAndContinue() {
      this.save().then(() => this.send('continue'));
    },
  },
  //endregion
});
