const ballotEntrant = `
  <expansion-panel class="collapsed">
    <div class="individual-entry-view">
      <div class="material-list-tile entrant-list-item">
        <span class="entrant-name ml-1">Entrant Name</span>
        <div class="entrant-details">
          <div class="address">
            <span class="material-icons contact-info-icon !text-base">
              place
            </span>
            <span class="address-text">123 Example Road</span>
          </div>
        </div>
        <div class="entrant-actions">
          <div class="entrant-action-buttons">
            <div class="entrant-action">
              <button class="voting-button ssButton ssButtonPrimary vote-button vote">
                Vote
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </expansion-panel>
`;

const navCategory = `
  <span class="active nested-menu-children--link">
    <div class="nested-menu-child category-navigation__category ssClickable category-navigation__category--active">
      <span>
        Category
      </span>
    </div>
  </span>
`;

export default `
  <div class="ssUGCGallery font-['Open_Sans']">
    <div class="ssUserInfo template-color-primary-background-color">
      <div class="user-info-container"></div>
    </div>
    <div class="ssUGCGallery">
      <div class="gallery-app-content has-navigation flex bg-[#EEEEEE]">
        <div class="group-navigation ember-view sticky hidden sm:!flex sm:flex-col sm:!mr-0 mt-4 w-44">
          <span class="category-navigation__top-section flex-shrink-0 sm:ml-4 text-black">
            <i class="material-icons category-navigation__home">
              home
            </i>
            All Groups
          </span>
          <div class="category-navigation__bottom-section flex-auto overflow-y-auto sm:!pr-4 sm:pb-4">
            <div class="nested-menu category-navigation__group category-navigation__group--active">
              <div class="nested-menu-parent ssClickable">
                <i class="material-icons">keyboard_arrow_down</i>
                <span class="active nested-menu-parent-text">Group</span>
              </div>
              <div class="nested-menu-children">
                ${navCategory}
                ${navCategory}
                ${navCategory}
                ${navCategory}
                ${navCategory}
                ${navCategory}
              </div>
            </div>
            <div class="nested-menu category-navigation__group">
              <div class="nested-menu-parent hover:text-blue-400 ssClickable">
                <i class="material-icons">keyboard_arrow_right</i>
                <span class="nested-menu-parent-text">Group</span>
              </div>
            </div>
            <div class="nested-menu category-navigation__group">
              <div class="nested-menu-parent hover:text-blue-400 ssClickable">
                <i class="material-icons">keyboard_arrow_right</i>
                <span class="nested-menu-parent-text">Group</span>
              </div>
            </div>
          </div>
        </div>
        <div class="ssGalleryWrapper with-navigation !p-0 flex flex-col gap-4 mx-3 sm:mx-0 mr-3 mb-4">
          <div class="round-info !mb-0 !mt-8">
            <div class="round-info__voting-status">
              <div class="round-info__header">
                  Vote until m/dd @ h:mm pm
              </div>
            </div>
          </div>
          <div class="gallery-display expanded gallery-display--ballot">
            <div class="category-header__name-bar">
              <div class="category-name !ml-0">
                <span>Category</span>
              </div>
            </div>
            <div class="ballot-showcase ballot-showcase--preview !p-0 ml-4 sm:mr-1">
              <div class="gallery-column flex flex-col max-w-[220px]">
                <div class="ssTileExternalHeaderContainer">Featured Entrants</div>
                <div class="individual-entry-view w-full max-w-[278px]">
                  <div class="card">
                    <div class="entry">
                      <img width="600" src="https://media.secondstreetapp.com/250194?width=1170&height=1170&quality=60">
                    </div>
                    <div class="ballot-entry-description mx-3 !my-0">
                      <span class="entry-title !p-0 !text-2xl">Entrant Name</span>
                      <div class="entry-caption">
                        Oat cake dessert sugar plum marzipan tart carrot cake donut lollipop. Wafer chocolate cake jujubes sweet. Halvah jelly fruitcake donut gingerbread. Cheesecake bonbon topping marshmallow tootsie roll oat cake powder oat cake. Tart sesame snaps jelly beans. Jujubes sweet roll chocolate cake. Soufflé soufflé cupcake.
                      </div>
                    </div>
                    <div class="entry-actions">
                      <div class="gallery-vote">
                        <button class="ssButton ssButtonPrimary vote-button vote">Vote</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gallery-column flex flex-col max-w-[220px]">
                <div class="ssTileExternalHeaderContainer invisible">Featured Entrants</div>
                <div class="individual-entry-view w-full max-w-[278px]">
                  <div class="card">
                    <div class="entry">
                      <img width="600" src="https://media.secondstreetapp.com/250194?width=1170&height=1170&quality=60">
                    </div>
                    <div class="ballot-entry-description mx-3 !my-0">
                      <span class="entry-title !p-0 !text-2xl">Entrant Name</span>
                      <div class="entry-caption">
                        Sugar plum jujubes brownie cheesecake marshmallow. Toffee brownie cake candy canes bonbon gingerbread chupa chups. Gingerbread danish croissant gummies brownie icing ice cream.
                      </div>
                    </div>
                    <div class="entry-actions">
                      <div class="gallery-vote">
                        <button class="ssButton ssButtonPrimary vote-button vote">Vote</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ballot-showcase !p-0">
              <div class="all-entrants">
                <div class="ballots-entrants-list-display">
                  <div class="ssTileExternalHeaderContainer !ml-4">
                    Entrants
                  </div>
                  <expansion-panel-list class="material-list show-borders">
                    ${ballotEntrant}
                    ${ballotEntrant}
                  </expansion-panel-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="ssUGCGalleryShare">
        <ul class="ssShareListCompact ssShareList">
          <li class="ssShareListItem ssShareListItemFacebook">
            <a class="ssShareLink ssShareLinkFacebook" data-token="facebook" href="#">
              <i class="ssIcon-facebook"></i>
              <span class="ssShareLinkText">Share</span>
            </a>
          </li>
          <li class="ssShareListItem ssShareListItemTwitter">
            <a class="ssShareLink ssShareLinkTwitter" data-token="twitter" href="#">
              <i class="ssIcon-twitter"></i>
              <span class="ssShareLinkText">Tweet</span>
            </a>
          </li>
          <li class="ssShareListItem ssShareListItemEmail">
            <a class="ssShareLink ssShareLinkEmail" data-token="email" href="#">
              <i class="ssIcon-envelope"></i>
              <span class="ssShareLinkText">Email</span>
            </a>
          </li>
          <li class="ssShareListItem ssShareListItemPinterest">
            <a class="ssShareLink ssShareLinkPinterest" data-token="pinterest" href="#">
              <i class="ssIcon-pinterest"></i>
              <span class="ssShareLinkText">Pin It</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
`;
