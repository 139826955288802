/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import PromotionTypeRoute from 'partner/mixins/promotion-type-route';
import { organizationEnabledCampaigns, promotionEnabledCampaigns } from 'partner/utils/displayed-promotion-emails';
import RSVP from 'rsvp';

const SETUP_EMAILS_ROUTE = 'organizations.organization.organization-promotions.organization-promotion.setup.emails';

/**
 * A class for ballot checklist step validation, contains a method for each pairing of
 * promotion subtype (NominationAndVoting/Voting) and ballot style (simple/categories/groups).
 * Each method returns true if the checklist step should be checked, false if not.
 * @type {Object}
 */
export class BallotChecklistValidator {
  constructor({ promotion, matchups, matchupGroups }) {
    this.promotion = promotion;
    this.matchups = matchups;
    this.matchupGroups = matchupGroups;
  }

  shouldCheck() {
    const subtype = this.promotion.promotionSubType.replace('Ballot', '');

    return this.validationRules(`${this.ballotStyle()}${subtype}`);
  }

  validationRules(validationString) {
    switch (validationString) {
      case 'simpleNominationAndVoting':
      case 'simpleVoting':
        return this.matchups.length === 1 && get(this.matchups, 'firstObject.name') === 'SimpleBallot';

      case 'categoriesNominationAndVoting':
      case 'categoriesVoting':
        return this.matchups.length >= 2;

      case 'groupsNominationAndVoting':
      case 'groupsVoting':
        return (
          this.matchupGroups.length >= 2 && this.matchupGroups.every(matchupGroup => matchupGroup.categoryCount >= 1)
        );

      default:
        return false;
    }
  }

  ballotStyle() {
    if (!isEmpty(this.matchupGroups)) {
      return 'groups';
    } else if (this.matchups.length >= 1 && get(this.matchups, 'firstObject.name') !== 'SimpleBallot') {
      return 'categories';
    } else if (
      this.promotion.isNominationAndVotingBallot &&
      this.matchups.length === 1 &&
      get(this.matchups, 'firstObject.name') === 'SimpleBallot'
    ) {
      // nomination and voting simple
      return 'simple';
    } else if (this.promotion.isVotingBallot && !isEmpty(this.matchups)) {
      // voting simple
      return 'simple';
    }

    return '';
  }
}

/**
 * Setup Route
 * No URL - anything under /o/:organization_id/op/:organization_promotion_id/setup
 * @type {Ember.Route}
 * @mixes PromotionTypeRoute
 */
export default Route.extend(PromotionTypeRoute, {
  checklist: service(),
  enums: service(),
  features: service(),
  snackbar: service(),
  store: service(),

  _matchups: null,

  beforeModel() {
    // clear out the checklist, side loaded checklistStep models, and side loaded checklistStepStatuses models. otherwise they would remain in store and
    // could show up for a different promotion
    this.store.unloadAll('checklist');
    this.store.unloadAll('checklistStep');
    this.store.unloadAll('checklistStepStatus');
    this._matchups = null;
  },

  async model() {
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');
    const orgPromoModel = this.modelFor('organizations.organization.organization-promotions.organization-promotion');
    const organizationPromotionId = get(orgPromoModel, 'organizationPromotion.id');
    const organizationPromotion = get(orgPromoModel, 'organizationPromotion');

    set(this.checklist, 'organizationPromotionId', organizationPromotionId);
    set(this.checklist, 'promotionTypeId', get(orgPromoModel, 'organizationPromotion.promotion.promotionTypeId'));
    set(this.checklist, 'messageCampaignId', null);
    set(this.checklist, 'audienceId', null);

    const [checklist, registrationEnabledSetting, organizationRegistrationFormSetting, promotionEmails] =
      await Promise.all([
        this.store.query('checklist', {}),
        this.store.queryRecord('setting', { key: 'Registration_Type' }),
        this.store.queryRecord('setting', { key: 'Organization_Registration_Form_Setting' }),
        this.store
          .query('message-campaign', {
            targetOrganizationPromotionId: organizationPromotionId,
            statusTypeId: this.enums.findWhere('STATUS_TYPE', { name: 'Active' }),
          })
          .then(x => x.toArray()),
      ]);

    const displayedPromotionEmails = promotionEnabledCampaigns(
      organizationEnabledCampaigns(promotionEmails, this.features),
      organizationPromotion.promotion,
      registrationEnabledSetting
    );

    const hash = {
      promotion: organizationPromotion.promotion,
      checklist: get(checklist, 'firstObject'),
      games: this.store.query('game', { organizationId, organizationPromotionId }),
      registrationEnabledSetting,
      organizationRegistrationFormSetting,
      promotionEmails,
      displayedPromotionEmails,
    };

    if (
      organizationPromotion.promotion.isVotingBracket ||
      organizationPromotion.promotion.isEventSignup ||
      organizationPromotion.promotion.isSweepstakesSimple ||
      organizationPromotion.promotion.isSweepstakesCodeword
    ) {
      const matchups = await this.store.query('matchup', {
        organizationId,
        organizationPromotionId,
        excludeSecondaryMatchups: true,
      });
      set(hash, 'matchups', matchups.toArray());
    }

    if (organizationPromotion.promotion.isPaymentForm) {
      const promotionProducts = await this.store.query('promotion-product', {
        organizationId,
        organizationPromotionId,
      });
      set(hash, 'promotionProduct', promotionProducts.firstObject);
    }

    return RSVP.hash(hash);
  },

  afterModel(model) {
    //Seed the checklist model in the checklist service
    set(this.checklist, 'checklist', get(model, 'checklist'));
  },
  //endregion

  //region Properties
  isPromotionDesignComplete: false,
  //endregion

  //region Methods
  async _verifyAndToggleBallotChecklistStep({ matchups, matchupGroups, viaWriteIns, route } = {}) {
    // There is one route that uses this without sending matchups
    if (!matchups) {
      this._matchups = this._matchups || (await this.store.queryRecord('matchup', { excludeSecondaryMatchups: true }));

      matchups = this._matchups;
    }

    const shouldCheck = viaWriteIns
      ? matchups.every(matchup => matchup.hasMatchupEntries)
      : new BallotChecklistValidator({
          promotion: this.controller.model.promotion,
          matchups,
          matchupGroups,
        }).shouldCheck();

    this.send(shouldCheck ? 'checkChecklistStep' : 'uncheckChecklistStep', route);
  },
  //endregion

  //region Actions
  actions: {
    /**
     * Logic for determining if the "design" step should be checked.
     * @param isSingleMessageCampaign
     */
    designChecklistStepLogic(design) {
      const tokenContents = get(design, 'tokenContents');
      const templateTokens = get(design, 'template.tokens');
      let isPromotionDesignComplete = true;
      isPromotionDesignComplete = templateTokens.every(token =>
        tokenContents.any(tokenContent => {
          if (get(token, 'designTokenContents.lastObject.id') === get(tokenContent, 'id')) {
            return get(tokenContent, 'isComplete');
          }
        })
      );

      this.send(
        isPromotionDesignComplete ? 'checkChecklistStep' : 'uncheckChecklistStep',
        'organizations.organization.organization-promotions.organization-promotion.setup.design'
      );

      set(this, 'isPromotionDesignComplete', isPromotionDesignComplete);
    },

    // Ain't that a bitch
    emailChecklistStepLogic() {
      if (this.controller.model.displayedPromotionEmails.isEvery('isConfirmed')) {
        this.send('checkChecklistStep', SETUP_EMAILS_ROUTE);
      } else {
        this.send('uncheckChecklistStep', SETUP_EMAILS_ROUTE);
      }
    },

    updatePromotionEmails(action, messageCampaign) {
      this.controller.model.promotionEmails[action](messageCampaign);
    },

    updateDisplayedPromotionEmails(action, messageCampaign) {
      this.controller.model.displayedPromotionEmails[action](messageCampaign);
    },
    // Ain't it

    /**
     * Determines whether the ballot checklist step should be checked/unchecked
     * @param route - what route to check
     * @param viaWriteins - if the ballot checklist step is being evaluated from enabling/disabling write-ins
     */
    verifyAndToggleBallotChecklistStep(options) {
      this._verifyAndToggleBallotChecklistStep(options);
    },

    replaceRegistrationEnabledSetting(setting) {
      set(this.controller.model, 'registrationEnabledSetting', setting);
    },
  },
  //endregion
});
