/* eslint-disable ember/no-on-calls-in-components, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash */
/* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */ // FIXME
import Component from '@ember/component';
import { computed, get, observer, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { on } from '@ember/object/evented';
import { run, once, next } from '@ember/runloop';
import { isEmpty, isPresent } from '@ember/utils';
import areAttrsChanged from 'partner/utils/are-attrs-changed';
import { Promise } from 'rsvp';
import moment from 'moment';
import sumBy from 'lodash/sumBy';
import { inject as service } from '@ember/service';

const DEFAULT_RECURRENCES = 2;
const DEFAULT_CUSTOM_FREQUENCY = 2;
const DEFAULT_SEND_TIME = { name: 'At', default: 0, multiplier: 0 };

export default Component.extend({
  defaultFormat: service('default-format'),
  //region Properties
  recurringPresets: computed(() => [{ name: 'Custom' }]),
  approvalOptions: computed(() => [
    { name: 'Off', value: false },
    { name: 'On', value: true },
  ]),
  approvalSendingOptions: computed(() => [
    DEFAULT_SEND_TIME,
    { name: 'Hours before', default: 12, max: 23, multiplier: 60 },
    { name: 'Minutes before', default: 90, max: 1380, multiplier: 1 },
  ]),
  sendTime: DEFAULT_SEND_TIME,
  /**
   * When you DS.Model#save, any dates become new Date()s, causing observers to fire.
   * Instead, we'll compare a string representation.
   * @type {String}
   */
  startDateString: '',
  'schedule-check': () => {},
  /**
   * Whether it's safe to start listening for changes in `baseDateString`
   * @type {Boolean}
   */
  hasInitializedStartDateString: false,
  //endregion

  //region Computed Properties
  messageCampaign: alias('model.messageCampaign'),
  /**
   * Whether the user wants the newsletter to never end
   * @returns {Boolean}
   * @type {Ember.ComputedProperty}
   */
  endNever: computed('messageCampaign.{scheduleEndDate,scheduleNumberOfRecurrences}', {
    get() {
      const noEndDate = isEmpty(get(this, 'messageCampaign.scheduleEndDate'));
      const noRecurrences = isEmpty(get(this, 'messageCampaign.scheduleNumberOfRecurrences'));
      return noEndDate && noRecurrences;
    },
    set(key, value) {
      if (value) {
        set(this, 'messageCampaign.scheduleEndDate', null);
        set(this, 'messageCampaign.scheduleNumberOfRecurrences', null);
      }
      return value;
    },
  }),

  /**
   * Whether the user wants the newsletter to end after X occurrences
   * @returns {Boolean}
   * @type {Ember.ComputedProperty}
   */
  endOnOccurrences: computed('messageCampaign.{scheduleEndDate,scheduleNumberOfRecurrences}', {
    get() {
      const noEndDate = isEmpty(get(this, 'messageCampaign.scheduleEndDate'));
      const hasRecurrences = !isEmpty(get(this, 'messageCampaign.scheduleNumberOfRecurrences'));
      return noEndDate && hasRecurrences;
    },
    set(key, value) {
      if (value) {
        this.sentEmailCount
          ? set(this, 'recurrences', DEFAULT_RECURRENCES + this.sentEmailCount)
          : set(this, 'recurrences', DEFAULT_RECURRENCES);
      }
      return value;
    },
  }),
  minDate: computed('messageCampaign.scheduleStartDate', function () {
    const startDate = get(this, 'messageCampaign.scheduleStartDate');
    const date = isPresent(startDate) ? startDate : new Date();

    return moment(date, this.defaultFormat.getDateFormat()).add(2, 'days');
  }),
  /**
   * Whether the user wants the newsletter to end on X date
   * @returns {Boolean}
   * @type {Ember.ComputedProperty}
   */
  endOnDate: computed('messageCampaign.{scheduleEndDate,scheduleNumberOfRecurrences}', {
    get() {
      const hasEndDate = !isEmpty(get(this, 'messageCampaign.scheduleEndDate'));
      const noRecurrences = isEmpty(get(this, 'messageCampaign.scheduleNumberOfRecurrences'));
      return hasEndDate && noRecurrences;
    },
    set(key, value) {
      if (value) {
        set(this, 'messageCampaign.scheduleEndDate', this.minDate);
        set(this, 'messageCampaign.scheduleNumberOfRecurrences', null);
      }
      return value;
    },
  }),
  /**
   * Recurrences
   * Its setter will unset the Schedule End Date.
   * @type {Ember.ComputedProperty}
   */
  messageVersionDetails: computed('messageCampaign.messages.@each.messageVersions', function () {
    return this.messageCampaign?.messages?.toArray().flatMap(message => message.messageVersions?.toArray());
  }),
  sentEmailCount: computed('messageVersionDetails.@each.sentCount', function () {
    return sumBy(this.messageVersionDetails, 'sentCount');
  }),
  recurrences: computed('messageCampaign.scheduleNumberOfRecurrences', {
    get() {
      const value = parseInt(get(this, 'messageCampaign.scheduleNumberOfRecurrences'), 10);
      return Number.isNaN(value) ? '' : value;
    },
    set(key, value) {
      set(this, 'messageCampaign.scheduleEndDate', null);
      if (value) {
        const newValue = window.parseInt(value, 10);
        set(this, 'messageCampaign.scheduleNumberOfRecurrences', newValue);
        return newValue;
      }
      const newValue = null;
      set(value, newValue);
      return newValue;
    },
  }),
  recurrenceInvalid: computed('recurrences', 'sentEmailCount', function () {
    return this.recurrences ? this.sentEmailCount + 1 > this.recurrences : false;
  }),
  minimumValue: computed('recurrences', 'sentEmailCount', function () {
    return this.sentEmailCount && this.sentEmailCount > 0 ? this.sentEmailCount : 1;
  }),
  /**
   * End Date
   * Its setter will unset the Schedule Number of Recurrences.
   * @type {Ember.ComputedProperty}
   */
  endDate: computed('messageCampaign.scheduleEndDate', {
    get() {
      return get(this, 'messageCampaign.scheduleEndDate');
    },
    set(key, value) {
      set(this, 'messageCampaign.scheduleNumberOfRecurrences', null);
      set(this, 'messageCampaign.scheduleEndDate', value);
      return value;
    },
  }),
  step2status: computed('messageCampaign.singleSchedule.{delayDatepartTypeId,delayValue}', function () {
    const singleSchedule = get(this, 'messageCampaign.singleSchedule') || {};
    const { delayValue, scheduleTypeId } = singleSchedule;
    const validScheduleDelay = delayValue || (delayValue === 0 && (scheduleTypeId === 2 || scheduleTypeId === 7));
    return get(singleSchedule, 'delayDatepartTypeId') && validScheduleDelay ? 'bestPractice' : 'incomplete';
  }),
  step3status: computed(
    'messageCampaign.singleMessage.{isApprovalRequired,approvalMinutesBeforeSend,approvalRecipients}',
    function () {
      const singleMessage = get(this, 'messageCampaign.singleMessage') || {};
      if (!get(singleMessage, 'isApprovalRequired')) {
        return 'bestPractice';
      }
      const minutes = get(singleMessage, 'approvalMinutesBeforeSend') | 0;
      return isPresent(get(singleMessage, 'approvalRecipients')) && minutes >= 0 && minutes <= 1380
        ? 'bestPractice'
        : 'incomplete';
    }
  ),
  step4status: computed('endNever', 'recurrences', 'endOnDate', function () {
    return this.endNever || this.recurrences || this.endOnDate ? 'bestPractice' : 'incomplete';
  }),
  isStep2Dirty: areAttrsChanged({
    messageCampaign: [
      'singleSchedule.delayDatepartTypeId',
      'singleSchedule.delayDatepartType',
      'singleSchedule.recurringPattern',
    ],
  }),
  isStep3Dirty: areAttrsChanged({
    messageCampaign: ['scheduleEndDate', 'scheduleNumberOfRecurrences'],
  }),
  isStep4Dirty: areAttrsChanged({
    messageCampaign: ['scheduleEndDate', 'scheduleNumberOfRecurrences'],
  }),
  isChecklistStepSatisfied: computed(
    'messageCampaign.scheduleStartDate',
    'step2status',
    'step3status',
    'step4status',
    function () {
      return (
        get(this, 'messageCampaign.scheduleStartDate') &&
        this.step2status === 'bestPractice' &&
        this.step3status === 'bestPractice' &&
        this.step4status === 'bestPractice'
      );
    }
  ),
  minutesBeforeSend: computed('sendTime{,.value}', function () {
    const { sendTime } = this;
    return sendTime.value * sendTime.multiplier;
  }),
  //endregion

  //region Observers
  scheduling: on(
    'init',
    observer('messageCampaign.singleSchedule.{startDate,delayDatepartTypeId,delayValue}', function () {
      if (!isEmpty(this.isChecklistStepSatisfied)) {
        set(this, 'messageCampaign.isScheduled', this.isChecklistStepSatisfied);
      }
    })
  ),
  sendChecklistStepSatisfaction: observer('messageCampaign.isScheduled', 'isChecklistStepSatisfied', function () {
    once(() => next(() => this['checklist-step-satisfied'](this.isChecklistStepSatisfied)));
  }),
  startDateChanged: on(
    'init',
    observer('messageCampaign.scheduleStartDate', function () {
      const startDate = get(this, 'messageCampaign.scheduleStartDate');
      set(this, 'startDateString', startDate ? startDate.toISOString() : '');
      if (this.startDateString) {
        set(this, 'hasInitializedStartDateString', true);
      }
    })
  ),
  /**
   * When you DS.Model#save, any dates become new Date()s, causing observers to fire.
   * This observes a cached string version to compare instead.
   */
  startDateStringChanged: observer({
    dependentKeys: ['startDateString'],
    fn() {
      if (this.hasInitializedStartDateString) {
        set(this, 'messageCampaign.singleSchedule.delayDatepartTypeId', undefined);
        set(this, 'messageCampaign.singleSchedule.delayValue', undefined);
        set(this, 'messageCampaign.singleSchedule.recurringPattern', '');
      }
    },
    sync: true,
  }),
  //endregion

  //region Events
  /**
   * This is not an observer because choosing a preset as they fiddle with its UI would be very disconcerting
   */
  init() {
    this._super(...arguments);
    const { messageCampaign } = this;
    if (isEmpty(messageCampaign)) {
      return;
    }
    const recurringType = get(messageCampaign, 'singleSchedule.delayDatepartTypeId');
    const recurringFrequency = get(messageCampaign, 'singleSchedule.delayDatepartType');
    if (recurringType && recurringFrequency) {
      set(this, 'recurringPreset', this.recurringPresets.findBy('name', 'Custom'));
      // TODO: Select some of the OTHER best presets for them.
    }
    const minutesPrior = get(messageCampaign, 'messageApprovalMinutesBeforeSend');
    if (get(messageCampaign, 'messageIsApprovalRequired') && minutesPrior > 0) {
      const [, hours, minutes] = this.approvalSendingOptions;
      minutes.value = minutesPrior;
      hours.value = minutesPrior / 60;
      const sendTime = !(minutesPrior % 60) ? hours : minutes;
      set(this, 'sendTime', sendTime);
    }
  },
  //endregion

  //region Actions
  actions: {
    recurringPresetChanged(recurringPreset) {
      set(this, 'recurringPreset', recurringPreset);
      const frequency = 'messageCampaign.singleSchedule.delayDatepartType';
      if (get(recurringPreset, 'name') === 'Custom') {
        set(this, frequency, DEFAULT_CUSTOM_FREQUENCY);
      }
    },
    approvalRequired(value) {
      set(this, 'approvalRequired', value);
    },
    updateMinutesPrior() {
      const { sendTime } = this;
      const model = this.messageCampaign;
      return run(() => set(model, 'messageApprovalMinutesBeforeSend', sendTime.value * sendTime.multiplier));
    },

    canSave() {
      return this.isChecklistStepSatisfied && !this.recurrenceInvalid ? Promise.resolve() : Promise.reject();
    },

    checkRecurrence() {
      this['schedule-check'](this.recurrenceInvalid);
    },
  },
  //endregion
});
