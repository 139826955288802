/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

/**
 * @typedef {DS.Model} ImportFile
 */
export default BaseModel.extend({
  //region Properties
  importFileTypeId: DS.attr('number'),
  fileName: DS.attr('string'),
  originalFileName: DS.attr('string'),
  size: DS.attr('number'),
  contentType: DS.attr('string'),
  createdRecordCount: DS.attr('number'),
  recordCount: DS.attr('number'),
  //endregion
});
