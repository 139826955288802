/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import EmberObject, { computed, get, observer, set } from '@ember/object';
import { alias, sort } from '@ember/object/computed';
import { next } from '@ember/runloop';
import $ from 'jquery';
import EditableSingleObject from 'partner/mixins/editable-single-object';
import { elementVisible } from 'partner/utils/dom';

export default Component.extend(EditableSingleObject, {
  //region Ember Hooks
  classNames: ['material-list-tile', 'with-tagging-input', 'material-list-tile__centered-icons'],
  classNameBindings: [
    'addEditableStyle:ssEditable',
    'multiTierTaggingEnabled:multiTierTagging',
    'isExpanded:is-expanded',
    'isTierOne:ssTierOne',
    'promotionClassName',
    'entityTypeClassName',
    'withMenu:with-menu',
  ],
  click() {
    const $body = $('body');
    $body.trigger('click.interestTags'); //close any open tag search boxes
    $body.trigger('close.all.simpleMenu'); //close any open overflow menus

    //swallow click event because we don't want the inner clickable parts of this view to collapse it
    return false;
  },
  //endregion

  //region Attributes
  /**
   * @type {Boolean}
   */
  multiTierTaggingEnabled: false,
  /**
   * @type {TaggableEntity}
   */
  model: null,
  /**
   * @type {Boolean}
   */
  expandedToggle: false,
  //endregion

  //region Properties
  expanded: false,
  entityTypeSortOrder: computed(() => ['displayOrder']),
  /**
   * @type {Promise?}
   */
  taggableEntitiesPromise: null,
  //endregion

  //region Computed Properties
  addEditableStyle: computed('multiTierTaggingEnabled', 'isExpanded', function () {
    return this.multiTierTaggingEnabled && !this.isExpanded;
  }),
  isExpanded: alias('expanded'),
  isTierOne: alias('model.isTierOne'),
  promotionClassName: alias('model.promotionClassName'),
  taggableEntity: alias('model'),
  withMenu: alias('model.viewSiteLink'),
  /**
   * Builds an array of composite objects that group taggableEntities by entityType
   * Example: [
   *            {entityTypeName: 'Question', taggableEntities: [<Question:1>, <Question:2>...]},
   *            {entityTypeName: 'Outcome', taggableEntities: [<Outcome:43>, <Outcome:24>...]}
   *          ]
   *  @returns {CompositeEntityType[]}
   */
  entityTypes: computed(
    'taggableEntity.taggableEntities.@each.{entityTypeName,entityTypeDisplayOrder,isQuestion}',
    function () {
      const taggableEntities = get(this, 'taggableEntity.taggableEntities');
      return taggableEntities
        .map(x => get(x, 'entityTypeName'))
        .compact()
        .uniq()
        .map(entityTypeName => {
          const categoryTaggableEntities = taggableEntities.filterBy('entityTypeName', entityTypeName);
          /**
           * @property {String} entityTypeName
           * @property {Number} displayOrder
           * @property {TaggableEntity[]} taggableEntities
           * @typedef {Ember.Object} CompositeEntityType
           */
          return EmberObject.create({
            entityTypeName,
            displayOrder: get(categoryTaggableEntities, 'firstObject.entityTypeDisplayOrder'),
            taggableEntities: categoryTaggableEntities.sortBy(
              get(categoryTaggableEntities, 'firstObject.isQuestion') ? 'displayOrder' : 'name'
            ),
          });
        });
    }
  ),
  sortedEntityTypes: sort('entityTypes', 'entityTypeSortOrder'),

  entityTypeClassName: computed('model.entityTypeName', {
    get() {
      if (this._entityTypeClassName) return this._entityTypeClassName;

      return `ss${get(this, 'model.entityTypeName').replace(' ', '')}`;
    },
    set(key, value) {
      this._entityTypeClassName = value;
      return value;
    },
  }),
  //endregion

  //region Observers
  expandedObserver: observer({
    dependentKeys: ['expanded'],
    fn() {
      if (this.expanded) {
        //wrapping the in viewport check inside a run.next to insure that any previously expanded sections
        //have been closed and all related rendering has completed
        next(this, function () {
          if (!elementVisible(this.element)) {
            this.element.scrollIntoView();
          }
        });
      }
    },
    sync: true,
  }),
  /**
   * Observes a property on the form controller that gets changed when another entity is opened.
   */
  expandedToggleChanged: observer({
    dependentKeys: ['expandedToggle'],
    fn() {
      set(this, 'expanded', false);
    },
    sync: true,
  }),
  //endregion

  //region Actions
  actions: {
    toggleExpansion() {
      //only allow expansion for categories that have multi tier tagging
      if (this.multiTierTaggingEnabled === true) {
        if (this.expanded === true) {
          set(this, 'expanded', false);
        } else {
          this.toggleProperty('expandedToggle');
          set(this, 'expanded', true);
          const taggableEntitiesPromise = this.model.loadChildTaggableEntities();
          set(this, 'taggableEntitiesPromise', taggableEntitiesPromise);
        }
      }
    },
    openLink(url) {
      this.openLink(url);
    },
  },
  //endregion
});
