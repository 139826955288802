import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type { ModelFrom } from 'partner/utils/types';

export type Model = ModelFrom<SetupBallotMatchupsEditRoute>;

export default class SetupBallotMatchupsEditRoute extends Route {
  @service declare store: Store;

  async model({ matchup_id }: { matchup_id: string }) {
    const matchup = await this.store.findRecord('matchup', matchup_id, { reload: true });

    return { matchup };
  }
}
