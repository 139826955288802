/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, get, set } from '@ember/object';
import { debounce } from '@ember/runloop';

const validAutoSaveFormTypes = ['Registration', 'ExtraChances'];

export default Controller.extend({
  //region Computed Properties
  shouldUseAutoSave: computed(function () {
    return (
      validAutoSaveFormTypes.includes(get(this, 'model.form.formType')) ||
      validAutoSaveFormTypes.includes(get(this, 'model.extraChancesForm.formType'))
    );
  }),
  //endregion

  //region Methods
  handleAutoSave() {
    debounce(this, this.autoSaveForm, 100);
  },
  somethingChanged() {
    set(this, 'didSomethingChange', true);
    if (this.shouldUseAutoSave) {
      this.handleAutoSave();
    }
  },
  somethingFinishedChanging() {
    set(this, 'didSomethingChange', false);
  },
  autoSaveForm() {
    if (this.save) {
      this.save();
    } else {
      this.target.send('save');
    }
  },
  //endregion

  actions: {
    somethingChanged() {
      this.somethingChanged();
    },
    somethingFinishedChanging() {
      this.somethingFinishedChanging();
    },
  },
});
