/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

/**
 * Used to define a permissions "block" which sets a permission state that is used to determine what the
 * current user can do.
 * Example usage:
 *   {{#ss-permissions-block entities='MatchupPlace,MatchupLogic' as |administer view|}}
 *      {{ss-input value='foo.bar'}}  //ss-input will lookup the administer value because it uses the PermittedComponent mixin.
 *      {{#if administer}}
 *        //something only admins can do
 *      {{/if}}
 *   {{/ss-permission-block}}
 *
 * Alternate usage (override-admin):
 *   {{#ss-permissions-block override-admin="foo.isEditable" as |administer canView|}}
 *      //Use override-admin to set administer state inside block (short circuits permission based logic)
 *   {{/ss-permission-block}}
 *
 * @see Promotion.PermissionService
 * @see Promotion.PermittedComponent
 */
export default Component.extend({
  permissions: service(),
  /**
   * Alternate way of setting the administer state of a block. Used when the "locked" state of block
   * is not determined by permissions. Short circuits the permission based logic (ignores it).
   */
  'override-admin': null,
  /**
   * Alternate way of setting the view state of a block. Used when the "locked" state of block
   * is not determined by permissions. Short circuits the permission based logic (ignores it).
   */
  'override-view': null,

  /**
   * used to check if any entity in the entities string has permissions
   */
  'is-any-entity': false,

  canView: computed(
    'entities',
    'permissions.permissions.@each.permissionTypeId',
    'override-view',
    'is-any-entity',
    function () {
      const overrideView = this['override-view'];

      if (isPresent(overrideView)) {
        return overrideView;
      }

      const { entities } = this;
      const isAnyEntity = this['is-any-entity'];

      return isPresent(entities) ? this.permissions.getAccessLevel(entities, isAnyEntity).view : false;
    }
  ),
  administer: computed(
    'entities',
    'permissions.permissions.@each.permissionTypeId',
    'override-admin',
    'is-any-entity',
    function () {
      const overrideAdmin = this['override-admin'];
      if (isPresent(overrideAdmin)) {
        return overrideAdmin;
      }
      const { entities } = this;
      const isAnyEntity = this['is-any-entity'];

      return isPresent(entities) ? this.permissions.getAccessLevel(entities, isAnyEntity).administer : false;
    }
  ),
  tagName: 'div',
  /**
   * Enables or disables the "view only" warning that
   * is seen if the user has only view access.
   * @type {Boolean}
   */
  showViewOnlyWarning: true,
  /**
   * Enables or disables the "no permission" warning that
   * is seen if the user has NO access to the entities.
   * @type {Boolean}
   */
  showNoAccessWarning: true,
});
