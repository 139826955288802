/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import AppContextRoute from 'partner/mixins/app-context-route';
import checkPolling from 'partner/utils/check-polling';
import { UNSAVED_CHANGES_ALERT } from 'partner/utils/constants';
import RSVP from 'rsvp';

/**
 * Audience Route
 * /o/:organization_id/data/audiences/:audience_id
 * @type {Ember.Route}
 * @mixes AppContextRoute
 */
export default Route.extend(AppContextRoute, {
  //region Ember Hooks
  notifications: service(),
  navigation: service(),
  store: service(),

  async model(params) {
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');

    const audience = await this.store.queryRecord('audience', {
      id: params.audience_id,
      organizationId,
      updateCount: true,
    });

    checkPolling(audience, get(audience, 'id'), this, get(audience, 'isCalculating'));
    const organizationFields = get(this.modelFor('organizations.organization.data.audiences'), 'fields');
    const fields = get(audience, 'isThirdPartyAudience')
      ? await this.store.query('field', { membersOrganizationId: get(audience, 'membersOrganizationId') })
      : organizationFields;

    return RSVP.hash({
      //  when route loads request audience in a special way that forces count to be recalculated
      copy: params.isEditingName,
      audience,
      hasData: get(this.modelFor('organizations.organization'), 'organization.hasData'),
      fields,
    });
  },
  afterModel(model) {
    if (get(model, 'copy')) {
      if (!isEmpty(get(model, 'audience.isJustCreated'))) {
        set(model, 'audience.name', '');
      }
    }
    // tell the outer navigation wrapper we're in an audience
    this.navigation.setEntity(model.audience);
  },
  activate() {
    this._super(...arguments);
    this.send('saveHistory', get(this.modelFor('organizations.organization.data.audiences.audience'), 'audience'));
    set(this, 'isAudienceRouteActive', true);
  },
  deactivate() {
    this._super(...arguments);
    // tell the outer navigation wrapper we're no longer in an audience
    this.navigation.setEntity(null);
    set(this, 'isAudienceRouteActive', false);
  },
  //endregion

  //region Properties
  /**
   * @type {Boolean?}
   */
  isEditingName: false,
  downloadInProcess: false,
  isAudienceRouteActive: true,
  //endRegion

  //region Actions
  actions: {
    willTransition(transition) {
      if (get(this, 'controller.model.audience.hasDirtyAttributes')) {
        const confirm = window.confirm(UNSAVED_CHANGES_ALERT);
        if (!confirm) {
          transition.abort();
        } else {
          get(this, 'controller.model.audience').rollbackAttributes();
        }
      }
    },
    toggleEditNameModal() {
      this.controller.toggleProperty('isEditingName');
    },
  },
  //endregion
});
