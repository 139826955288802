/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr, belongsTo, hasMany, type SyncHasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import enums from 'ember-cli-ss-enums/services/enums';
import { inject as service } from '@ember/service';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';
import Organization from './organization';
import MediaItemModel from './media-item';
import { DesignToken } from './design-token';
import type DesignTemplatePromotionTypeModel from './design-template-promotion-type';
import dirtyProperty from 'partner/utils/dirty-property';

import { joinStringArray } from '@ss/helpers/helpers/join-string-array';
import { isEmpty } from '@ember/utils';

export default class DesignTemplateModel extends BaseModel {
  @service declare enums: EnumsService;

  @attr('string') declare name: string;
  @attr('string') declare templateContent: string;
  @attr('boolean', { defaultValue: false }) declare isInherited: boolean;
  @attr('number') declare designTemplateTypeId: number;
  @attr('number') declare statusTypeId: number;
  @attr('number') declare designMethodTypeId: number;
  @attr('number') declare sourceDesignTemplateId: number;
  @attr() declare promotionTypeIds: number[];
  @attr('boolean') declare useDefaultContent: boolean;
  @attr('number') declare organizationId: number;
  @attr('boolean') declare isLocked: boolean;
  @attr('boolean') declare visibleToAll: boolean;
  @attr('string') declare redirectLink: string;
  @attr('boolean', { defaultValue: false }) declare isCustomUrl: boolean;

  @dirtyProperty('isCustomUrl') declare isCustomUrlDirty: boolean;
  @dirtyProperty('redirectLink') declare isRedirectLinkDirty: boolean;

  @belongsTo('organization') declare organization: Organization;
  @belongsTo('media-item') declare thumbnailMediaItem: MediaItemModel;
  @hasMany('design-token') declare designTokens: SyncHasMany<DesignToken>;
  @hasMany('design-template-promotion-type')
  declare designTemplatePromotionTypes: SyncHasMany<DesignTemplatePromotionTypeModel>;

  get tokens() {
    return this.designTokens;
  }

  @enums.computed('name', 'designTemplateTypeId', 'designTemplateTypeId', 'TEMPLATE_TYPE')
  declare designTemplateType: string;

  @enums.computed('name', 'designMethodTypeId', 'designMethodTypeId', 'DESIGN_METHOD_TYPE')
  declare designMethodType: string;

  @enums.computed('name', 'statusTypeId', 'statusTypeId', 'STATUS_TYPE') declare statusType: string;

  @dirtyProperty('name') declare isNameDirty: boolean;
  @dirtyProperty('isLocked') declare isLockedDirty: boolean;

  @computed('defaultPromotionTypeIds')
  get displayedPromotionTypeIds() {
    return this.defaultPromotionTypeIds.slice().uniq();
  }

  @computed('displayedPromotionTypeIds.[]', 'enums')
  get displayedPromotionList() {
    const displayedPromotions = this.displayedPromotionTypeIds.map(id =>
      this.enums.findWhere('PROMOTION_TYPE', { id }, 'publicName')
    );
    return displayedPromotions.length ? joinStringArray([displayedPromotions, ', ', ' and ']) : '';
  }

  @computed('designTemplatePromotionTypes.@each.{isDeleted,promotionTypeId}')
  get defaultPromotionTypeIds() {
    return this.designTemplatePromotionTypes
      .toArray()
      .filter(promotionType => !promotionType.isDeleted)
      .mapBy('promotionTypeId');
  }

  async saveRecord() {
    /*
        Here we unload the duplicate entries which are created while saving a entity that contains embedded children entities.
        This is a known issue in ember-data.
        https://discuss.emberjs.com/t/proposal-fix-saving-new-embedded-records-creates-duplicates/3677
      */

    await this.save();
    const designTemplatePromotionTypes = this.designTemplatePromotionTypes.toArray();
    designTemplatePromotionTypes.forEach(record => {
      if (record.get('isLoaded') && (isEmpty(record.id) || record.get('isDeleted'))) {
        this.designTemplatePromotionTypes.removeObject(record);
        this.store.unloadRecord(record);
      }
    });

    return this;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'design-template': DesignTemplateModel;
  }
}
