/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-mixins */
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { alias, readOnly } from '@ember/object/computed';
import HasCategoricalCharts from 'partner/mixins/has-categorical-charts';
import HasEventCharts from 'partner/mixins/has-event-charts';
import NeedsOrganizationPromotionController from 'partner/mixins/needs-organization-promotion-controller';
import TickTock from 'partner/mixins/tick-tock';
import { currentMatchup, firstByProperty } from 'partner/utils/computed';
import { inject as service } from '@ember/service';

/**
 * Dashboard Sweepstakes Controller
 * /o/:organization_id/op/:organization_promotion_id/dashboard/sweepstakes
 * @type {Ember.Controller}
 */
export default class extends Controller.extend(
  HasCategoricalCharts,
  HasEventCharts,
  TickTock,
  NeedsOrganizationPromotionController
) {
  @service('store') store;
  @service('current') current;

  tickDuration = 1000 * 60; // One minute

  @readOnly('current.organizationPromotion.kpi')
  totalEntries;

  @readOnly('current.organizationPromotion.numEligibleUsers')
  numEligibleUsers;

  @firstByProperty('name', 'Entry', 'model.eventCharts')
  entriesChart;

  @alias('model.matchups.firstObject.startDate')
  contestStartDate;

  @alias('model.matchups.lastObject.endDate')
  contestEndDate;

  @currentMatchup()
  currentMatchup;

  @alias('model.games.firstObject.entriesPerMatchupMax')
  maxAllowedEntriesPerRound;

  @tracked matchupRounds = [];
  @tracked selectedRoundId = null;
  @tracked showImportEntriesModal = false;
  @tracked selectedRoundName = null;
  sampleImportFileName = 'Second Street Sweeps Entries Import Template.xlsx';
  sampleImportFileLink = '/media/final/resources/importfiles/SecondStreet_SweepsEntries_ImportTemplate.xlsx';

  get oneRound() {
    const { matchups } = this.model;
    return matchups.length === 1;
  }

  @computed('maxAllowedEntriesPerRound', 'model.matchups.[]')
  get maxAllowedEntries() {
    return this.maxAllowedEntriesPerRound * this.model.matchups.length;
  }

  get queryParams() {
    return `?matchupId=${this.selectedRoundId}`;
  }

  get formattedMatches() {
    return this.matchupRounds.map(match => match.roundName);
  }

  @action
  selectRoundMethod(selectedValue) {
    const filteredMatch = this.matchupRounds.find(match => match.roundName === selectedValue);

    if (filteredMatch) {
      this.selectedRoundId = filteredMatch.id;
    }

    this.selectedRoundName = selectedValue;
  }

  @action
  onClose() {
    this.showImportEntriesModal = false;
    if (!this.oneRound) {
      this.selectedRoundId = null;
      this.selectedRoundName = null;
    }
  }
}
