/* eslint-disable ember/named-functions-in-promises */
import { get, setProperties } from '@ember/object';
import { isEmpty } from '@ember/utils';
import mostCurrentMatchup from 'partner/utils/most-current-matchup';
import RSVP from 'rsvp';

/*
 * In situations where we cannot just pass a dynamic segment into a route and let its model hook run,
 * we can put the logic for the model hook in a function here, so that we may use it in multiple places.
 * Context must have the enums service injected.
 */

// model hook for user.user
export const user = (params, context) => {
  const { store } = context;

  return RSVP.hash({
    isAnythingSaving: false,
    partnerUser: store.findRecord('partner-user', params.user_id, {
      reload: true,
    }),
    passwordResetRequest: store.createRecord('passwordResetRequest'),
    roles: store.findAll('role'),
    organizations: store.findAll('organization').then(x => x.toArray()),
    partnerUserSecurityDetail: store
      .findRecord('partner-user-security-detail', params.user_id, {
        reload: true,
      })
      .catch(e => console.error(e)),
  }).then(hash => {
    setProperties(hash, {
      partnerUserOrganizationRoles: store
        .query('partner-user-organization-role', {
          partnerUserId: params.user_id,
        })
        .then(x => x.toArray()),
      editorPartnerUserOrganizationRoles: store
        .query('partner-user-organization-role', {
          partnerUserId: get(context, 'session.data.authenticated.organization_users.user_id'),
          includeImplicitRoles: true,
        })
        .then(x => x.toArray()),
    });
    return RSVP.hash(hash);
  });
};

/***
 * Returns a model hook for the corresponding promotionType
 * @param promotionType The promotion type for the dashboard being displayed
 * @returns {Function}
 */
export const dashboard = promotionType =>
  function () {
    const { enums } = this;
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');
    const { organizationPromotion } = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion'
    );
    const organizationPromotionId = organizationPromotion.id;
    const fieldTypeId = enums.findWhere('FIELD_TYPE', { name: 'Optin' });
    const pendingEntriesStatusTypeId = enums.findWhere('STATUS_TYPE', { name: 'Submitted' }, 'id');
    const approvedEntriesStatusTypeId = enums.findWhere('STATUS_TYPE', { name: 'Active' }, 'id');
    const rejectedEntriesStatusTypeId = enums.findWhere('STATUS_TYPE', { name: 'Rejected' }, 'id');
    const promotionParams = { organizationId, organizationPromotionId };

    return RSVP.all([
      this.store.query('matchup', {
        excludeSecondaryMatchups: true,
      }),
      this.settings.preload('dips_url'),
    ]).then(([matchups]) => {
      // Get page sizes by requesting a single entry for each type
      const pageSizeFor = statusTypeId =>
        this.store
          .query('matchupEntry', {
            matchupId:
              promotionType === 'UGCGallery' ||
              promotionType === 'UGCVoting' ||
              promotionType === 'Ballot' ||
              promotionType === 'VotingBracket'
                ? undefined
                : mostCurrentMatchup(matchups).id,
            pageSize: 1,
            pageIndex: 1,
            statusTypeId,
            organizationId,
            organizationPromotionId,
            filterByOrganizationPromotion:
              promotionType === 'UGCGallery' ||
              promotionType === 'UGCVoting' ||
              promotionType === 'Ballot' ||
              promotionType === 'VotingBracket',
          })
          .then(matchupEntries => (!isEmpty(matchupEntries) ? get(matchupEntries, 'meta.totalRecords') || 0 : 0));

      const hash = {
        matchups: organizationPromotion.matchupsAsCategories
          ? matchups.sortBy('displayOrder')
          : matchups.sortBy('endDate'),
        categoricalCharts: this.store.query('categoricalChart', promotionParams),
        eventCharts: this.store.query('eventChart', promotionParams),
        optinFields: this.store
          .query('field', {
            organizationId,
            organizationPromotionId,
            fieldTypeId,
            filterByOrganizationPromotion: true,
          })
          .then(x => x.filterBy('fieldType', 'Optin')),
        winners:
          promotionType === 'UGCSweepstakes' || promotionType === 'UGCVoting' || promotionType === 'VotingBracket'
            ? this.store.query('winner', promotionParams)
            : [],
        pendingEntriesCount: pageSizeFor(pendingEntriesStatusTypeId),
        approvedEntriesCount: pageSizeFor(approvedEntriesStatusTypeId),
        rejectedEntriesCount: pageSizeFor(rejectedEntriesStatusTypeId),
        dipsUrl: this.settings.getFor('dips_url'),
        matchupGroups: this.store.query('matchup-group', promotionParams).then(x => x.toArray().sortBy('displayOrder')),
      };

      if (
        promotionType === 'UGCSweepstakes' ||
        promotionType === 'UGCVoting' ||
        promotionType === 'Ballot' ||
        promotionType === 'VotingBracket'
      ) {
        hash.organizationPromotion = this.store.findRecord('organization-promotion', organizationPromotionId, {
          reload: true,
        });
      }

      if (
        promotionType === 'UGCGallery' ||
        promotionType === 'UGCVoting' ||
        promotionType === 'Ballot' ||
        promotionType === 'VotingBracket'
      ) {
        hash.sweepstakes = this.store.queryRecord('sweepstakes', promotionParams);
      }

      if (promotionType === 'Ballot') {
        hash._settings = this.settings.preload('category_sort_criteria');
      }

      return RSVP.hash(hash).then(hash => {
        if (get(hash, 'sweepstakes.isEnabled')) {
          hash.sweepstakesWinners = this.store.query('winner', {
            matchupId: get(hash, 'sweepstakes.matchupId'),
          });
        }

        return RSVP.hash(hash);
      });
    });
  };

export default {
  user,
  dashboard,
};
