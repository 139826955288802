/* eslint-disable ember/no-classic-components, ember/require-tagless-components, ember/no-actions-hash, ember/no-observers, ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-classic-classes, ember/no-get */
import Component from '@ember/component';
import { computed, get, observer, set } from '@ember/object';
import { alias, equal, filterBy, not, setDiff } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import DS from 'ember-data';
import PermittedComponent from 'partner/mixins/permitted-component';
import checkPolling from 'partner/utils/check-polling';
import RSVP from 'rsvp';

export default Component.extend(PermittedComponent, {
  init() {
    this._super(...arguments);
    this.updateChecklistStep();
  },

  store: service('store'),
  features: service(),
  addRecipientFocused: 'Add an Email Address...',

  //region Attributes
  'save-audiences': x => x,
  //endregion

  //region Properties
  showExclusionList: false,
  //endregion

  //region Computed Properties
  /**
   * Returns an array of the messageCampaignAudiences selected for this message campaign, and then extracts
   * the associated audience from each one. DS.PromiseArray prevents us from having to grab ".content" off
   * each audience promise.
   * @type {Ember.ComputedProperty}
   * @returns {Audience[]}
   */
  chosenAudiences: computed('model.messageCampaignAudiences.@each.audience', function () {
    return DS.PromiseArray.create({
      promise: RSVP.all(get(this, 'model.messageCampaignAudiences').mapBy('audience')),
    });
  }),
  includedMessageCampaignAudiences: filterBy('model.messageCampaignAudiences', 'isExcluded', false),
  excludedMessageCampaignAudiences: filterBy('model.messageCampaignAudiences', 'isExcluded', true),
  /**
   * Filters the messageCampaignAudiences into an array of included records, and then extracts the associated
   * audience from each one. DS.PromiseArray prevents us from having to grab ".content" off
   * each audience promise.
   * @type {Ember.ComputedProperty}
   * @returns {Audience[]}
   */
  includedAudiences: computed('includedMessageCampaignAudiences.@each.audience', function () {
    return DS.PromiseArray.create({
      promise: RSVP.all(this.includedMessageCampaignAudiences.mapBy('audience')),
    });
  }),
  /**
   * Filters the messageCampaignAudiences into an array of excluded records, and then extracts the associated
   * audience from each one. DS.PromiseArray prevents us from having to grab ".content" off
   * each audience promise.
   * @type {Ember.ComputedProperty}
   * @returns {Audience[]}
   */
  excludedAudiences: computed('excludedMessageCampaignAudiences.@each.audience', function () {
    return DS.PromiseArray.create({
      promise: RSVP.all(this.excludedMessageCampaignAudiences.mapBy('audience')),
    });
  }),
  /**
   * Subtracts excludedAudiences from the full list of audiences to return an array of audiences that
   * are eligible to be included.
   * @type {Ember.ComputedProperty}
   * @returns {Audience[]}
   */
  audiencesAvailableToInclude: setDiff('model.audiences', 'excludedAudiences'),
  /**
   * If audiences have been added, exclude third-party audiences from list of suggested results
   * @type {Ember.ComputedProperty}
   * @returns {Audience[]}
   */
  unavailableAudiences: computed(
    'chosenAudiences.[]',
    'includedAudiences.[]',
    'model.audiences.@each.isThirdPartyAudience',
    function () {
      if (isPresent(this.includedAudiences)) {
        return this.chosenAudiences.toArray().concat(get(this, 'model.audiences').filterBy('isThirdPartyAudience'));
      }
      return this.chosenAudiences;
    }
  ),
  /**
   * If a third-party audience was added, disable all other audiences
   * If a first-party audience was added, disable all other third-party audiences
   * @type {Ember.ComputedProperty}
   * @returns {Audience[]}
   */
  disabledAudiences: computed(
    'audiencesAvailableToInclude.@each.isThirdPartyAudience',
    'includedAudiences.[]',
    function () {
      if (isEmpty(this.includedAudiences)) {
        return [];
      }
      const firstIncludedAudience = get(this, 'includedAudiences.firstObject');
      const availableAudiences = this.audiencesAvailableToInclude;
      //you can only add one third-party audience, so we only need to check the first one
      if (get(firstIncludedAudience, 'isThirdPartyAudience')) {
        return availableAudiences.filter(audience => audience !== firstIncludedAudience);
      }
      return availableAudiences.filterBy('isThirdPartyAudience');
    }
  ),
  /**
   * Subtracts includedAudiences from the full list of first party audiences to return an array of audiences that
   * are eligible to be excluded.
   * @type {Ember.ComputedProperty}
   * @returns {Audience[]}
   */
  audiencesAvailableToExclude: setDiff('firstPartyAudiences', 'includedAudiences'),
  firstPartyAudiences: filterBy('model.audiences', 'isThirdPartyAudience', false),
  additionalRecipientsStatus: computed('model.singleMessageCampaign.additionalRecipients.length', function () {
    return isEmpty(get(this, 'model.singleMessageCampaign.additionalRecipients')) ? 'incomplete' : 'bestPractice';
  }),
  includedAudiencesStatus: computed('includedAudiences.length', function () {
    return isEmpty(this.includedAudiences) ? 'incomplete' : 'bestPractice';
  }),
  excludedAudiencesStatus: computed('excludedAudiences.length', function () {
    return isEmpty(this.excludedAudiences) ? 'incomplete' : 'bestPractice';
  }),
  messageIsApprovalRequiredStatus: computed(
    'model.singleMessageCampaign.messageIsApprovalRequired',
    'messageIsApprovalRequiredIsDirty',
    function () {
      return !this.messageIsApprovalRequiredIsDirty ? 'bestPractice' : 'incomplete';
    }
  ),
  /**
   * The step status for question 2 in a drip campaign
   * @type {Ember.ComputedProperty}
   * @returns {String}
   */
  sendToExistingAudienceMembersStatus: computed(
    'model.messageCampaign.isSendToExistingAudienceMembersDirty',
    function () {
      return get(this, 'model.messageCampaign.isSendToExistingAudienceMembersDirty') ? 'incomplete' : 'bestPractice';
    }
  ),
  messageIsApprovalNotRequired: not('model.singleMessageCampaign.messageIsApprovalRequired'),
  messageIsApprovalRequiredIsDirty: computed(
    'model.singleMessageCampaign.{hasDirtyAttributes,messageIsApprovalRequired}',
    function () {
      return (
        get(this, 'model.singleMessageCampaign.hasDirtyAttributes') &&
        get(this, 'model.singleMessageCampaign.messageIsApprovalRequired') !==
          get(this, 'model.initialMessageIsApprovalRequired')
      );
    }
  ),
  additionalRecipientsRequired: alias('model.singleMessageCampaign.messageIsApprovalRequired'),
  includedThirdPartyAudiences: filterBy('includedAudiences', 'isThirdPartyAudience'),

  isChecklistStepSatisfied: equal('includedAudiencesStatus', 'bestPractice'),
  //endregion

  //region Observers
  showingAudienceBasicsModalChanged: observer('showing-audience-basics-modal', function () {
    //Handles a complex case related to hitting back button which cases a query param to be added back to the url
    if (this['showing-audience-basics-modal']) {
      this.send('audienceFiltersCancelled');
    }
  }),
  //endregion

  //region Methods
  updateChecklistStep() {
    // includedAudiences is a PromiseArray and once it resolves we want to send the action since
    // isChecklistStepSatisfied depends on includedAudiencesStatus which depends on includedAudiences
    this.includedAudiences.then(() => this.checklistStepSatisfaction(this.isChecklistStepSatisfied));
  },
  //endregion

  //region Actions
  actions: {
    closeCreateAudienceModal() {
      set(this, 'showCreateAudienceModal', false);
    },
    audienceFiltersCancelled(audience) {
      set(this, 'showAudienceFiltersModal', false);
      set(this, 'showCreateAudienceModal', false);
      if (audience) {
        this.removeMessageCampaignAudience(audience);
      }
    },
    addExistingAudience(isExcluded, audience) {
      this.addMessageCampaignAudience(audience, isExcluded);
    },
    removeExistingAudience(audience) {
      this.removeMessageCampaignAudience(audience);
    },
    audienceBasicsSaved(audience, showUploadModal) {
      const { organizationId } = this;
      //POSTing an audience doesn't calculate the count. Must do GET with query params to force that
      this.store
        .query('audience', {
          id: get(audience, 'id'),
          updateCount: true,
          organizationId,
        })
        .then(audiences => {
          set(this, 'audience', get(audiences, 'firstObject'));
          checkPolling(audience, get(audience, 'id'), this, false);
        });
      get(this, 'model.audiences').addObject(audience);
      set(this, 'showUploadModal', showUploadModal);
      set(this, 'showAudienceFiltersModal', true);
      set(this, 'showCreateAudienceModal', false);
    },
    audienceFiltersSaved(audience, saveAndContinue) {
      const { isExcluded } = this;
      const shouldAdd = isExcluded
        ? !this.excludedAudiences.includes(audience)
        : !this.includedAudiences.includes(audience);
      if (shouldAdd) {
        this.addMessageCampaignAudience(audience, isExcluded);
        this['save-audiences']();
      }
      if (saveAndContinue) {
        set(this, 'showAudienceFiltersModal', false);
        set(this, 'showCreateAudienceModal', false);
      }
    },
    createNewAudience(isExcluded) {
      //Indicates if the audience is going to be used for Inclusion or Exclusion
      set(this, 'isExcluded', isExcluded);

      set(this, 'audience', this.store.createRecord('audience'));
      this.toggleProperty('showCreateAudienceModal');
      this.loadFiltersData();
    },
  },
  //endregion
});
