/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { computed, set } from '@ember/object';
import { notEmpty, or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { dasherize } from '@ember/string';
import { isPresent } from '@ember/utils';
import areAttrsChanged from 'partner/utils/are-attrs-changed';

export default Controller.extend({
  //region Ember Hooks
  checklist: service(),
  //endregion
  //region Properties
  'calculating-boost-count': false,
  modifyingAudienceFailed: false,
  messageCampaignController: controller('organizations.organization.message-campaigns.message-campaign'),
  //endregion

  //region Computed Properties
  showingAudienceBasicsModal: readOnly('messageCampaignController.is-editing-basics'),
  isAnythingDirty: or(
    'areIncludedAudiencesDirty',
    'areExcludedAudiencesDirty',
    'additionalRecipientsDirty',
    'model.messageCampaign.hasDirtyAttributes',
    'messageIsApprovalRequiredIsDirty'
  ),
  showDirtyPeopleCount: or(
    'areIncludedAudiencesDirty',
    'areExcludedAudiencesDirty',
    'model.messageCampaign.hasDirtyAttributes'
  ),
  isAdditionalRecipientsDirty: areAttrsChanged({
    'model.singleMessageCampaign': ['additionalRecipients', 'messageIsApprovalRequired'],
  }),
  recipientComponent: computed('model.messageCampaign.messageCampaignType', function () {
    const type = this.model.messageCampaign.messageCampaignType;
    return `message-campaigns/${dasherize(type)}-recipients`;
  }),
  areIncludedAudiencesDirty: computed(
    'model.messageCampaignAudiences.@each.{isExcluded,hasDirtyAttributes}',
    function () {
      const includedAudiences = this.model.messageCampaignAudiences.filter(
        audience => !audience.isExcluded && audience.hasDirtyAttributes
      );

      return isPresent(includedAudiences);
    }
  ),
  areExcludedAudiencesDirty: computed(
    'model.messageCampaignAudiences.@each.{isExcluded,hasDirtyAttributes}',
    function () {
      const excludedAudiences = this.model.messageCampaignAudiences.filter(
        audience => audience.isExcluded && audience.hasDirtyAttributes
      );

      return isPresent(excludedAudiences);
    }
  ),
  additionalRecipientsDirty: computed(
    'model.singleMessageCampaign.{hasDirtyAttributes,additionalRecipientsArray.[]}',
    function () {
      // This is gross, what's a better way?
      if (this.model.messageCampaign.usesSingleMessageCampaign) {
        const initialArray = this.model.initialAdditionalRecipientsArray.sort();
        const currentArray = this.model.singleMessageCampaign.additionalRecipientsArray.sort();

        return this.model.singleMessageCampaign.hasDirtyAttributes && initialArray.join() !== currentArray.join();
      }
      return false;
    }
  ),
  messageIsApprovalRequiredIsDirty: computed(
    'model.singleMessageCampaign.{hasDirtyAttributes,messageIsApprovalRequired}',
    function () {
      return (
        this.model.singleMessageCampaign?.hasDirtyAttributes &&
        this.model.singleMessageCampaign?.messageIsApprovalRequired !== this.model.initialMessageIsApprovalRequired
      );
    }
  ),
  isIncludedAudiencesEmpty: computed(
    'model.messageCampaignAudiences.@each.{isExcluded,hasDirtyAttributes}',
    function () {
      const includedAudiences = this.model.messageCampaignAudiences.filter(audience => !audience.isExcluded);

      return includedAudiences.length === 0;
    }
  ),
  isExcludedAudiencesEmpty: computed(
    'model.messageCampaignAudiences.@each.{isExcluded,hasDirtyAttributes}',
    function () {
      const excludedAudiences = this.model.messageCampaignAudiences.filter(audience => audience.isExcluded);

      return excludedAudiences.length === 0;
    }
  ),
  areAudiencesEmptyAndNotDirty: computed(
    'isIncludedAudiencesEmpty',
    'isExcludedAudiencesEmpty',
    'isAnythingDirty',
    function () {
      return this.isIncludedAudiencesEmpty && this.isExcludedAudiencesEmpty && !this.isAnythingDirty;
    }
  ),
  isMessageCampaignLocked: notEmpty('model.messageCampaign.dateSent'),
  //endregion

  //region Actions
  actions: {
    checklistStepSatisfaction(bool) {
      const routeName = 'organizations.organization.message-campaigns.message-campaign.setup.recipients';
      this.checklist.setChecklistStepStatus(routeName, bool ? 100 : 0);
    },
    toggleDeliverabilityBoost(bool) {
      set(this, 'model.singleMessageCampaign.sendToEngagedOnly', bool);
      this.send('save');
    },
  },
  //endregion
});
