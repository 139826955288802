/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { computed, set } from '@ember/object';
import { empty, equal, notEmpty, or, readOnly } from '@ember/object/computed';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import ENUMS from 'ember-cli-ss-enums/utils/enum-data';
import { inflect } from 'partner/helpers/inflect';
import ABTestSummary from 'partner/mixins/ab-test-summary';
import { ARTICLE_TOKENS } from 'partner/utils/constants';
import { insertIf } from 'secondstreet-common/utils/functional';

const ARTICLE_TITLE_TOKEN = ARTICLE_TOKENS.findBy('name', 'Article 1 Headline').token;
const ARTICLE_DESCRIPTION_TOKEN = ARTICLE_TOKENS.findBy('name', 'Article 1 Description').token;
const ARTICLE_LENGTH_TOKEN = ARTICLE_TOKENS.findBy('name', 'Article Count').token;

const MESSAGE_CAMPAIGN_ROUTE = 'organizations.organization.message-campaigns.message-campaign';
const SETUP_ROUTE = `${MESSAGE_CAMPAIGN_ROUTE}.setup`;
const SCHEDULE_ROUTE = `${SETUP_ROUTE}.schedule`;
const CONFIRMATION_ROUTE = `${SETUP_ROUTE}.confirmation`;

export default Controller.extend(ABTestSummary, {
  //region Ember Hooks
  checklist: service(),
  router: service(),
  messageCampaignController: controller(MESSAGE_CAMPAIGN_ROUTE),
  setupController: controller(SETUP_ROUTE),

  maybeUnconfirm() {
    if (!this.setupController.isAnythingSaving && this.setupController.confirmed && !this.isSetupComplete) {
      this.checklist.uncheckChecklistStep(CONFIRMATION_ROUTE);
      set(this, 'messageCampaign.isConfirmed', false);
      this.send('confirmationChanged', false);
    }
    next(() => {
      if (
        !this.setupController.isAnythingSaving &&
        this.singleMessageCampaign?.isConfirmed &&
        !this.checklist.isChecklistStepChecked(CONFIRMATION_ROUTE)
      ) {
        this.checklist.checkChecklistStep(CONFIRMATION_ROUTE);
        this.send('confirmationChanged', true);
      }
    });
  },
  maybeUncheckSchedule() {
    if (
      this.messageCampaign?.isWelcome ||
      this.messageCampaign?.isDripCampaign ||
      this.messageCampaign?.isAnnualMessageCampaign ||
      (this.setupController.confirmed && !this.isAnythingDirty)
    ) {
      return;
    }
    if (
      !this.setupController.isAnythingSaving &&
      this.checklistSteps &&
      this.scheduleChecklistStep.isComplete &&
      !this.singleMessageCampaign?.sendOnConfirm &&
      this.singleMessageCampaign?.isSingleEmail &&
      this.messageCampaign.startDate < new Date()
    ) {
      this.checklist.uncheckChecklistStep(SCHEDULE_ROUTE);
    }
  },
  //endregion

  //region Properties
  isAnythingSaving: false,
  //endregion

  //region Computed Properties
  messageCampaign: readOnly('messageCampaignController.model.messageCampaign'),
  singleMessageCampaign: readOnly('messageCampaignController.model.singleMessageCampaign'),
  usesSingleMessageCampaign: readOnly('messageCampaign.usesSingleMessageCampaign'),
  messageVersions: readOnly('messageCampaign.messageVersions'),
  schedules: readOnly('messageCampaign.schedules'),
  dateFields: readOnly('setupController.model.dateFields'),
  hasAudience: notEmpty('setupController.model.messageCampaignAudiences'),
  // returns a string of the CC'd emails separated by a new line character if more than one email
  // was available
  additionalRecipientsString: computed('messageCampaign.additionalRecipients', function () {
    return this.messageCampaign.additionalRecipients?.length
      ? htmlSafe(this.messageCampaign.additionalRecipients.join('\n'))
      : '';
  }),
  checklistSteps: readOnly('checklist.checklist.checklistSteps'),
  hasABVersions: computed(
    'usesSingleMessageCampaign',
    'singleMessageCampaign.noABTesting',
    'messageVersions.[]',
    function () {
      const smc = this.singleMessageCampaign;
      return this.usesSingleMessageCampaign && !smc.noABTesting && this.messageVersions.length > 1;
    }
  ),
  recipientsChecklistStep: computed('checklistSteps.@each.checklistStepTypeId', function () {
    const stepType = ENUMS.CHECKLIST_STEP_TYPE.findBy('name', 'Recipients');
    return this.checklistSteps.findBy('checklistStepTypeId', stepType.id);
  }),
  messageChecklistStep: computed('checklistSteps.@each.checklistStepTypeId', function () {
    const stepType = ENUMS.CHECKLIST_STEP_TYPE.findBy('name', 'Messaging Message');
    return this.checklistSteps.findBy('checklistStepTypeId', stepType.id);
  }),
  scheduleChecklistStep: computed('checklistSteps.@each.checklistStepTypeId', function () {
    const stepType = ENUMS.CHECKLIST_STEP_TYPE.findBy('name', 'Schedule');
    return this.checklistSteps.findBy('checklistStepTypeId', stepType.id);
  }),
  finalConfirmationChecklistStep: computed('checklistSteps.@each.checklistStepTypeId', function () {
    const stepType = ENUMS.CHECKLIST_STEP_TYPE.findBy('name', 'Messaging Confirmation');
    return this.checklistSteps.findBy('checklistStepTypeId', stepType.id);
  }),
  incompleteChecklistSteps: computed(
    'recipientsChecklistStep.isComplete',
    'messageChecklistStep.isComplete',
    'scheduleChecklistStep.isComplete',
    function () {
      return [
        ...insertIf(!this.recipientsChecklistStep.isComplete, 'Recipients'),
        ...insertIf(!this.messageChecklistStep.isComplete, 'Message'),
        ...insertIf(!this.scheduleChecklistStep.isComplete, 'Schedule'),
      ];
    }
  ),
  isAnythingDirty: or('singleMessageCampaign.hasDirtyAttributes', 'messageCampaign.isAnythingDirty'),
  isSetupComplete: equal('setupController.incomplete.length', 0),
  isEnabled: computed(
    'tock',
    'isSetupComplete',
    'isLockedDown',
    'messageCampaign.syndicateCount',
    'isSentAndScheduledButNotConfirmed',
    function () {
      return (
        this.isSetupComplete &&
        (!this.isLockedDown || this.isSentAndScheduledButNotConfirmed) &&
        !this.messageCampaign.syndicateCount
      );
    }
  ),
  /**
   * Describes a message campaign that has begun sending recurring emails, but has had its confirmation put on hold.
   * Typically this happens when the campaign is unscheduled after it has already begun sending.
   */
  isSentAndScheduledButNotConfirmed: computed('messageCampaign.{isConfirmed,isSentAndScheduled}', function () {
    return !this.messageCampaign.isConfirmed && this.messageCampaign.isSentAndScheduled;
  }),
  isLockedDown: computed('singleMessageCampaign.messageSendingStatusType', 'usesSingleMessageCampaign', function () {
    return this.usesSingleMessageCampaign
      ? this.singleMessageCampaign.messageSendingStatusType === 'Sent' ||
          this.singleMessageCampaign.messageSendingStatusType === 'Sending'
      : false;
  }),
  noMessageVersions: empty('messageVersions'),
  newMembersOnly: computed('messageCampaign.{isDripCampaign,sendToExistingAudienceMembers}', function () {
    return this.messageCampaign.isDripCampaign && !this.messageCampaign.sendToExistingAudienceMembers;
  }),
  dateField: computed('schedules.firstObject.relatedEventEntityId', 'dateFields', function () {
    const dateFieldId = this.schedules?.firstObject.relatedEventEntityId;
    return this.dateFields?.findBy('id', dateFieldId?.toString());
  }),
  isPersonalizedDate: equal('schedules.firstObject.scheduleTypeId', 6),
  deliberateConfirmationText: computed('messageCampaign.estimatedRecipientsCount', function () {
    const count = this.messageCampaign.estimatedRecipientsCount;
    return {
      promptText: `Are you sure you want to send this message to ${count} ${inflect([count, 'person'])} right now?`,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, Send Now',
    };
  }),
  testEndHours: computed('singleMessageCampaign.messageTestDurationMinutes', function () {
    return this.singleMessageCampaign?.messageTestDurationMinutes / 60;
  }),
  /**
   * The email subject with RSS related tokens turned into chips
   * @property {Ember.ComputedProperty}
   * @returns {String}
   */
  chipifiedSubject: computed('messageVersions.firstObject.subject', function () {
    return this.messageVersions.firstObject.subject
      .replace(
        ARTICLE_TITLE_TOKEN,
        '<span class="ui-tag ui-tag--light inline-flex items-center truncate">Article 1 Headline</span>'
      )
      .replace(
        ARTICLE_DESCRIPTION_TOKEN,
        '<span class="ui-tag ui-tag--light inline-flex items-center truncate">Article 1 Description</span>'
      )
      .replace(
        ARTICLE_LENGTH_TOKEN,
        '<span class="ui-tag ui-tag--light inline-flex items-center truncate">Article Count</span>'
      );
  }),
  //endregion

  //region Actions
  actions: {
    confirmationChanged(newValue) {
      set(this, 'setupController.confirmed', newValue);

      try {
        this.send('save');
      } catch (e) {
        console.error(e);
      }
    },
    triggerConfirmationAndScheduleCheck() {
      if (this.router.isActive(SETUP_ROUTE)) {
        this.maybeUnconfirm();
        this.maybeUncheckSchedule();
      }
    },
  },
  //endregion
});
