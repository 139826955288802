import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<EntryForm::Field::Wrapper @label={{@formField.labelText}} ...attributes>\n  <div>\n    {{#each @formField.field.sortedFieldOptions key=\"id\" as |fieldOption|}}\n      <label class=\"entry-form-field__checkbox-label\" data-test-checkboxes-field-option={{fieldOption.id}}>\n        <input\n          type=\"checkbox\"\n          checked={{includes fieldOption this.selectedFieldOptions}}\n          disabled={{@disabled}}\n          {{on \"change\" (fn this.handleChange fieldOption)}}\n        />\n\n        <span>\n          {{fieldOption.text}}\n        </span>\n      </label>\n    {{/each}}\n  </div>\n</EntryForm::Field::Wrapper>\n", {"contents":"<EntryForm::Field::Wrapper @label={{@formField.labelText}} ...attributes>\n  <div>\n    {{#each @formField.field.sortedFieldOptions key=\"id\" as |fieldOption|}}\n      <label class=\"entry-form-field__checkbox-label\" data-test-checkboxes-field-option={{fieldOption.id}}>\n        <input\n          type=\"checkbox\"\n          checked={{includes fieldOption this.selectedFieldOptions}}\n          disabled={{@disabled}}\n          {{on \"change\" (fn this.handleChange fieldOption)}}\n        />\n\n        <span>\n          {{fieldOption.text}}\n        </span>\n      </label>\n    {{/each}}\n  </div>\n</EntryForm::Field::Wrapper>\n","moduleName":"partner/components/entry-form/field/checkboxes.hbs","parseOptions":{"srcName":"partner/components/entry-form/field/checkboxes.hbs"}});
import { action } from '@ember/object';

import Base from './base';

export default class EntryFormFieldCheckboxesComponent extends Base {
  get selectedFieldOptions() {
    return this.args.entryFieldValues.map(({ fieldOption }) => fieldOption);
  }

  @action
  handleChange(fieldOption, e) {
    const entryFieldValue = this.args.entryFieldValues.findBy('fieldOption', fieldOption);

    this.args.onChange(entryFieldValue, {
      value: e.target.checked ? fieldOption.text : null,
      fieldId: this.fieldId,
      fieldOption,
    });
  }
}
