/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';

export default Route.extend(AppContextRoute, {
  store: service(),

  //region Ember Hooks
  async model() {
    const designTokens = await this.store.findAll('design-token');
    const { organization } = this.modelFor('organizations.organization');
    return {
      organization,
      legalDesignTokens: designTokens.filterBy('isPageContentToken').sortBy('name'),
      dynamicDesignTokens: designTokens.filterBy('tokenType', 'Dynamic').sortBy('name'),
    };
  },
  //endregion
});
