/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';
import map from 'lodash/map';

/**
 * Display a Facebook like box
 */
export default Component.extend({
  disableInteraction: false,
  src: computed(
    'href',
    'width',
    'height',
    'colorScheme',
    'showFaces',
    'header',
    'stream',
    'showBorder',
    'appId',
    function () {
      const { href } = this;
      if (href) {
        const baseUrl = '//www.facebook.com/plugins/page.php';
        const params = {
          href: encodeURI(href),
          width: this.width,
          height: this.height,
          colorscheme: this.colorScheme,
          show_faces: this.showFaces,
          header: this.header,
          hide_cover: true,
          small_header: false,
          stream: this.stream,
          show_border: this.showBorder,
          appId: this.appId,
        };
        return `${baseUrl}?${map(params, (value, key) => {
          let part = key.toString();
          part += value === null ? '' : `=${value.toString()}`;
          return part;
        }).join('&')}`;
      }
      return null;
    }
  ),
  scrolling: 'no',
  frameBorder: '0',
  style: 'border:none; overflow:hidden;',
  allowTransparency: 'true',
  href: '',
  width: null,
  height: null,
  colorScheme: 'light',
  showFaces: false,
  header: false,
  stream: false,
  showBorder: false,
  appId: null,
  safeStyle: computed('style', function () {
    return htmlSafe(this.style);
  }),
});
