/* global Redactor */

Redactor.add('plugin', 'imagealign', {
  translations: {
    en: {
      imagealign: {
        alignment: 'Alignment',
        left: 'Align Left',
        center: 'Align Center',
        right: 'Align Right',
      },
    },
  },
  defaults: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.58579 4.58579C8.96086 4.21071 9.46957 4 10 4H14C14.5304 4 15.0391 4.21071 15.4142 4.58579C15.7893 4.96086 16 5.46957 16 6V10C16 10.5304 15.7893 11.0391 15.4142 11.4142C15.0391 11.7893 14.5304 12 14 12H10C9.46957 12 8.96086 11.7893 8.58579 11.4142C8.21071 11.0391 8 10.5304 8 10V6C8 5.46957 8.21071 4.96086 8.58579 4.58579ZM14 6L10 6V10H14V6ZM3 7C3 6.44772 3.44772 6 4 6H5C5.55228 6 6 6.44772 6 7C6 7.55228 5.55228 8 5 8H4C3.44772 8 3 7.55228 3 7ZM18 7C18 6.44772 18.4477 6 19 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H19C18.4477 8 18 7.55228 18 7ZM3 11C3 10.4477 3.44772 10 4 10H5C5.55228 10 6 10.4477 6 11C6 11.5523 5.55228 12 5 12H4C3.44772 12 3 11.5523 3 11ZM18 11C18 10.4477 18.4477 10 19 10H20C20.5523 10 21 10.4477 21 11C21 11.5523 20.5523 12 20 12H19C18.4477 12 18 11.5523 18 11ZM3 15C3 14.4477 3.44772 14 4 14H20C20.5523 14 21 14.4477 21 15C21 15.5523 20.5523 16 20 16H4C3.44772 16 3 15.5523 3 15ZM3 19C3 18.4477 3.44772 18 4 18H20C20.5523 18 21 18.4477 21 19C21 19.5523 20.5523 20 20 20H4C3.44772 20 3 19.5523 3 19Z"/></svg>',
  },
  start() {
    const controlButton = {
      icon: this.opts.get('imagealign.icon'),
      title: '## imagealign.alignment ##',
      observer: 'imagealign.observe',
      command: 'imagealign.popup',
      position: { after: 'add' },
      blocks: {
        types: ['image'],
      },
    };

    const toolbarButton = {
      icon: this.opts.get('imagealign.icon'),
      title: '## imagealign.alignment ##',
      observer: 'imagealign.observe',
      command: 'imagealign.popup',
      position: { after: 'image' },
      blocks: {
        types: ['image'],
      },
    };

    this.app.control.add('imagealign', controlButton);
    this.app.toolbar.add('imagealign', toolbarButton);
  },
  popup(e, button) {
    const instance = this.app.block.get();
    const css = instance.getStyle();
    const buttons = {
      left: { title: '## imagealign.left ##', command: 'imagealign.set' },
      center: { title: '## imagealign.center ##', command: 'imagealign.set' },
      right: { title: '## imagealign.right ##', command: 'imagealign.set' },
    };

    if (css && css['text-align']) {
      buttons[css['text-align']].active = true;
    }

    const dropdown = this.app.create('dropdown', 'imagealign', { items: buttons });

    this.app.dropdown.open(e, button, dropdown);
  },
  observe(obj) {
    const instance = this.app.block.get();

    if (instance && instance.isType('image')) {
      return obj;
    }

    return;
  },
  set(_params, _button, name) {
    this.app.dropdown.close();

    const instance = this.app.block.get();
    instance.setStyle({ 'text-align': name });

    this.app.broadcast('image.position');
  },
});
