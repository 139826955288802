/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

export default Controller.extend({
  //region Ember Hooks
  queryParams: [
    'messageVersionId',
    'messageCampaignId',
    'organizationPromotionId',
    'audienceId',
    'messageCampaignTypeId',
  ],
  //endregion

  //region Dependencies
  features: service(),
  //endregion

  //region Properties
  templateMethod: 'secondStreetTemplate',
  messageVersionId: null,
  messageCampaignId: null,
  organizationPromotionId: null,
  audienceId: null,
  messageCampaignTypeId: null,
  //endregion

  //region Computed Properties
  isNewEmailTemplateNameEmpty: computed('model.template.name', function () {
    return isBlank(get(this, 'model.template.name'));
  }),
  //endregion
});
