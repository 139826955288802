/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import flatten from 'lodash/flatten';
import { UNSAVED_CHANGES_ALERT } from 'partner/utils/constants';
import RSVP from 'rsvp';
import { insertIf } from 'secondstreet-common/utils/functional';

import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type CurrentService from 'partner/services/current';
import type SettingsService from 'partner/services/settings';
import type { ModelFrom } from 'partner/utils/types';
import type SetupLegalController from 'partner/controllers/organizations/organization/organization-promotions/organization-promotion/setup/legal';

const promotionTypesWithRulesAlwaysEnabled = ['UGCVoting', 'Sweepstakes', 'UGCSweepstakes', 'Ballot'];
const ROUTE_NAME = 'organizations.organization.organization-promotions.organization-promotion.setup.legal';

export type Model = ModelFrom<OrganizationSetupLegalRoute>;

export default class OrganizationSetupLegalRoute extends Route {
  @service declare current: CurrentService;
  @service declare router: RouterService;
  @service declare settings: SettingsService;
  @service declare store: Store;

  declare controller: SetupLegalController;

  async model() {
    const organizationId = this.current.organization.id;
    const organizationPromotionModel = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion'
    ) as any;
    const { id: organizationPromotionId } = organizationPromotionModel.organizationPromotion;
    const { promotionType } = organizationPromotionModel.organizationPromotion.promotion;

    const {
      allDesigns,
      sweepstakes,
      promotionMinimumAgeSetting,
      promotionRulesSourceSetting,
      promotionPrivacySourceSetting,
      promotionTermsSourceSetting,
      promotionWinnerRestrictionsSetting,
      designTokens,
    } = await RSVP.hash({
      allDesigns: this.store.query('design', { organizationPromotionId }),
      sweepstakes: this.store.queryRecord('sweepstakes', {
        organizationId,
        organizationPromotionId,
      }),
      promotionMinimumAgeSetting: this.store.queryRecord('setting', {
        key: 'Promotion_Minimum_Age',
        organizationId,
        organizationPromotionId,
      }),
      promotionWinnerRestrictionsSetting: this.store.queryRecord('setting', {
        key: 'Random_Drawing_Winner_Ineligibility_Days',
        organizationId,
        organizationPromotionId,
      }),
      promotionRulesSourceSetting: this.store.queryRecord('setting', {
        key: 'Use_Organization_Default_Rules',
        organizationId,
        organizationPromotionId,
      }),
      promotionTermsSourceSetting: this.store.queryRecord('setting', {
        key: 'Use_Organization_Default_Terms_Of_Service',
        organizationId,
        organizationPromotionId,
      }),
      promotionPrivacySourceSetting: this.store.queryRecord('setting', {
        key: 'Use_Organization_Default_Privacy_Policy',
        organizationId,
        organizationPromotionId,
      }),
      // @ts-ignore
      _settings: this.settings.preload(['rule_is_locked', 'terms_of_service_is_locked', 'private_policy_is_locked'], {
        force: true,
      }),
      designTokens: this.store.findAll('design-token'),
    });
    // we need to get design templates in order for a design to know which type it is
    await RSVP.all(allDesigns.mapBy('designTemplate'));

    const includeRules = sweepstakes.isEnabled || promotionTypesWithRulesAlwaysEnabled.includes(promotionType);
    const relevantDesigns = [
      ...insertIf(includeRules, allDesigns.findBy('isRules')),
      allDesigns.findBy('isTermsOfService'),
      allDesigns.findBy('isPrivacyPolicy'),
    ];

    const tokenContents = await RSVP.all(
      relevantDesigns.map(design => this.store.query('design-token-content', { designId: design.id }))
    );

    return {
      organizationPromotion: organizationPromotionModel.organizationPromotion,
      designs: relevantDesigns,
      promotionMinimumAgeSetting,
      designTokens,
      tokenContents: flatten(tokenContents.map(tokenContents => tokenContents.toArray())),
      promotionWinnerRestrictionsSetting,
      promotionRulesSourceSetting,
      promotionTermsSourceSetting,
      promotionPrivacySourceSetting,
      sweepstakes,
    };
  }

  resetController(controller: SetupLegalController) {
    controller.designId = null;
  }

  setupController(controller: SetupLegalController, model: Model, transition: any) {
    super.setupController(controller, model, transition);

    this.router.on('routeWillChange', transition => {
      const {
        promotionMinimumAgeSetting,
        promotionWinnerRestrictionsSetting,
        promotionRulesSourceSetting,
        promotionTermsSourceSetting,
        promotionPrivacySourceSetting,
      } = model;

      if (
        promotionMinimumAgeSetting.get('hasDirtyAttributes') ||
        promotionWinnerRestrictionsSetting.get('hasDirtyAttributes') ||
        promotionRulesSourceSetting.get('hasDirtyAttributes') ||
        promotionTermsSourceSetting.get('hasDirtyAttributes') ||
        promotionPrivacySourceSetting.get('hasDirtyAttributes')
      ) {
        const confirmed = window.confirm(UNSAVED_CHANGES_ALERT);
        if (confirmed) {
          [
            promotionMinimumAgeSetting,
            promotionWinnerRestrictionsSetting,
            promotionRulesSourceSetting,
            promotionRulesSourceSetting,
            promotionPrivacySourceSetting,
          ].forEach(setting => {
            setting.rollbackAttributes();
          });
        } else {
          void transition.abort();
        }
      }
    });

    this.router.on('routeDidChange', transition => {
      if (transition.to.name === ROUTE_NAME) {
        const designId = this.controller.model.designs.firstObject.id;

        if (!this.controller.designId) {
          void this.router.replaceWith(ROUTE_NAME, {
            queryParams: { designId },
          });
        }
      }
    });
  }
}
