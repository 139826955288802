import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class extends BaseModel {
  //region Properties
  @attr()
  coreTables;

  @attr()
  workspaces;
  //endregion
}
