import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class EmailTemplateRoute extends Route {
  @service router;

  model(params) {
    return params.email_template_id;
  }

  afterModel(messageBodyTemplateId) {
    this.router.replaceWith(
      'organizations.organization.email.message-body-templates.message-body-template',
      messageBodyTemplateId
    );
  }
}
