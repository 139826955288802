import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"fixed side-panel-left right-0 bg-white border-t border-b border-gray-200 z-5 overflow-gradient-y xs:overflow-gradient-none\"\n  ...attributes\n>\n  <div\n    class=\"flex flex-row items-stretch h-15 md:h-20 px-1 xs:px-0 overflow-y-auto xs:overflow-y-visible\"\n    {{did-insert this.attachEventHandlers}}\n    {{will-destroy this.detachEventHandlers}}\n  >\n    {{#each @collection as |item|}}\n      {{yield item}}\n\n      <div role=\"presentation\" class=\"pointer-events-none relative w-0 self-center md:mb-6 last:hidden\">\n        <div class=\"h-px w-2 sm:w-4 md:w-6 lg:w-8 xl:w-10 bg-gray-200 -translate-x-1/2\"></div>\n      </div>\n    {{/each}}\n  </div>\n</div>\n\n{{! Spacer }}\n<div\n  role=\"presentation\"\n  class=\"h-15 md:h-20 mt-0.5\n    {{if (not (includes @currentItem (array 'ballot' 'tags' 'ads' 'current'))) 'mb-4 md:mb-6'}}\"\n></div>", {"contents":"<div\n  class=\"fixed side-panel-left right-0 bg-white border-t border-b border-gray-200 z-5 overflow-gradient-y xs:overflow-gradient-none\"\n  ...attributes\n>\n  <div\n    class=\"flex flex-row items-stretch h-15 md:h-20 px-1 xs:px-0 overflow-y-auto xs:overflow-y-visible\"\n    {{did-insert this.attachEventHandlers}}\n    {{will-destroy this.detachEventHandlers}}\n  >\n    {{#each @collection as |item|}}\n      {{yield item}}\n\n      <div role=\"presentation\" class=\"pointer-events-none relative w-0 self-center md:mb-6 last:hidden\">\n        <div class=\"h-px w-2 sm:w-4 md:w-6 lg:w-8 xl:w-10 bg-gray-200 -translate-x-1/2\"></div>\n      </div>\n    {{/each}}\n  </div>\n</div>\n\n{{! Spacer }}\n<div\n  role=\"presentation\"\n  class=\"h-15 md:h-20 mt-0.5\n    {{if (not (includes @currentItem (array 'ballot' 'tags' 'ads' 'current'))) 'mb-4 md:mb-6'}}\"\n></div>","moduleName":"partner/components/ui/layout/setup-steps-wrapper.hbs","parseOptions":{"srcName":"partner/components/ui/layout/setup-steps-wrapper.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class UiLayoutSetupStepsWrapperComponent extends Component {
  wrapper?: HTMLElement;

  handleOverflow = () => {
    if (!this.wrapper) return;

    if (this.wrapper.offsetWidth < this.wrapper.scrollWidth) {
      this.wrapper.classList.remove('justify-center');
    } else {
      this.wrapper.classList.add('justify-center');
    }
  };

  @action
  attachEventHandlers(element: HTMLElement) {
    this.wrapper = element;

    this.handleOverflow();
    window.addEventListener('resize', this.handleOverflow);
  }

  @action
  detachEventHandlers() {
    window.removeEventListener('resize', this.handleOverflow);
  }
}
