/* eslint-disable ember/no-mixins */
import { setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import ResetScrollRoute from 'partner/mixins/reset-scroll-route';

export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionDashboardReportRoute extends Route.extend(
  AppContextRoute,
  ResetScrollRoute
) {
  @service('store') store;

  //region Ember Hooks
  queryParams = {
    reportType: { refreshModel: true },
    matchupId: { refreshModel: true },
    fieldId: { refreshModel: true },
    reportTypeOverride: { refreshModel: true },
  };

  async model(params) {
    const organizationId = this.modelFor('organizations.organization').organization.id;
    let optinField = null;

    if (params.reportType === 'SubscribedUsers' && params.fieldId) {
      const optinFields = await this.store.query('categorical-chart', {
        organizationId,
      });

      optinField = optinFields.findBy('sourceTypeChartId', parseInt(params.fieldId, 10));
    }

    return {
      reportType: params.reportType,
      matchupId: params.matchupId,
      fieldId: params.fieldId,
      optinField,
      organizationPromotion: this.modelFor('organizations.organization.organization-promotions.organization-promotion')
        .organizationPromotion,
      organizationId,
    };
  }

  resetController(controller) {
    setProperties(controller, {
      reportType: null,
      matchupId: null,
      fieldId: null,
      reportTypeOverride: null,
    });
  }
  //endregion
}
