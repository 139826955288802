/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set, setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { run } from '@ember/runloop';
import AppContextRoute from 'partner/mixins/app-context-route';

export default Route.extend(AppContextRoute, {
  model() {
    return this.modelFor('organizations.organization.licenses.license');
  },
  async setupController(controller, model) {
    this._super(...arguments);
    const organization = await get(model, 'organization');
    const currentOrganization = get(this.modelFor('organizations.organization'), 'organization');
    const parentOrganizations = await currentOrganization.getParentOrganizations();
    const organizations = [currentOrganization].concat(parentOrganizations).uniqBy('id').sortBy('id');
    run(() => setProperties(controller, { organization, organizations }));
  },
  resetController(controller) {
    set(controller, 'showInvoiceModal', false);
    set(controller, 'invoiceRecipient', null);
  },
  actions: {
    async saveInvoiceRecipient(invoiceRecipient) {
      await invoiceRecipient.save();
      set(this, 'controller.model.invoiceRecipient', invoiceRecipient);
    },
  },
});
