/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default Route.extend({
  //region Dependencies
  router: service(),
  store: service(),
  session: service(),
  //endregion

  //region Ember Hooks
  redirect() {
    if (this.session.isAuthenticated) {
      this.router.replaceWith('index');
    }
  },
  //endregion

  //region Actions
  actions: {
    async formSubmitted({ username }) {
      set(this, 'controller.isSubmitting', true);
      const record = this.store.createRecord('password-reset-request', { sendTo: username });

      try {
        await record.save();
        run(() => set(this, 'controller.resetEmailSent', true));
      } catch ({ errors }) {
        if (errors.length === 1 && errors[0].detail === "We don't have an account for that email address.") {
          run(() => set(this, 'controller.resetEmailSent', true));
        } else {
          run(() => set(this, 'controller.errors', errors));
        }
      } finally {
        set(this, 'controller.isSubmitting', false);
      }
    },
    clearErrors() {
      set(this, 'controller.errors', null);
    },
  },
  //endregion
});
