/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/use-ember-get-and-set */ // FIXME
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';

export default Route.extend(AppContextRoute, {
  enums: service(),
  store: service(),

  redirect(params) {
    const redirectId = this.enums.findWhere('UPICKEM_REDIRECTS', { page: params.page }, 'id');
    const redirect = this.store.createRecord('upickemAuthenticationRedirect', {
      targetPageId: redirectId,
      organizationPromotionId: params.organizationPromotionId,
      legacyContestId: params.legacyContestId,
      legacyMessageId: params.legacyMessageId,
    });
    redirect.save().then(upickemRedirect => {
      const historyItem = get(upickemRedirect, 'organizationPromotionId')
        ? this.store.peekRecord('organizationPromotion', get(upickemRedirect, 'organizationPromotionId'))
        : this.store
            .peekAll('messageCampaign')
            .findBy('legacyMessageId', parseInt(get(upickemRedirect, 'legacyMessageId')));
      if (historyItem) {
        this.send('saveHistory', historyItem);
      }
      window.location.replace(upickemRedirect.get('url'));
    });
  },
});
