import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

/**
 * Allows internal promotions and message-campaigns to inform the outer navigation component
 * what entity is being viewed, to inform various colors and app bar titles
 */
export default class NavigationService extends Service {
  /**
   * @type {OrganizationPromotion/MessageCampaign}
   */
  @tracked
  entity = null;

  //region Methods
  setEntity(entity) {
    this.entity = entity;
  }
  //endregion
}
