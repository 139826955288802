/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { set } from '@ember/object';
import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default Component.extend({
  //region Dependencies
  enums: service(),

  features: service(),

  store: service(),

  router: service(),
  //endregion

  //region Attributes
  closed() {},
  'has-data': false,
  //endregion

  //region Ember Hooks
  classNameBindings: ['_isCreating:ss-not-interactive'],
  //endregion

  //region Properties
  _isLegacyVisible: false,

  _isCreating: false,
  //endregion

  //region Actions
  actions: {
    async create(name) {
      set(this, '_isCreating', true);
      const messageCampaignPresetId = this.enums.findWhere('MESSAGE_CAMPAIGN_PRESETS', { name }, 'id');
      try {
        const messageCampaign = this.store.createRecord('message-campaign', { messageCampaignPresetId });
        await messageCampaign.save();
        run(() => set(messageCampaign, 'isJustCreated', true));
        this.router.transitionTo(
          'organizations.organization.message-campaigns.message-campaign',
          messageCampaign.organization.id,
          messageCampaign.id
        );
      } catch (err) {
        run(() => set(this, '_isCreateError', true));
      }
      run(() => set(this, '_isCreating', false));
    },
  },
  //endregion
});
