import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class TrackAppcuesEventHelper extends Helper {
  @service('appcues') appcues;

  compute(params) {
    return () => this.appcues.track(...params);
  }
}
