import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<EmberPopover\n  @animationDuration={{0}}\n  @effect=\"none\"\n  @popperContainer={{or @popperContainer this.popoverContainer}}\n  @side={{or @side \"bottom\"}}\n  @spacing={{4}}\n  @tooltipClass=\"ui-popover {{@tooltipClass}}\"\n  @targetElement={{@targetElement}}\n  ...attributes\n>\n  {{yield}}\n</EmberPopover>\n", {"contents":"<EmberPopover\n  @animationDuration={{0}}\n  @effect=\"none\"\n  @popperContainer={{or @popperContainer this.popoverContainer}}\n  @side={{or @side \"bottom\"}}\n  @spacing={{4}}\n  @tooltipClass=\"ui-popover {{@tooltipClass}}\"\n  @targetElement={{@targetElement}}\n  ...attributes\n>\n  {{yield}}\n</EmberPopover>\n","moduleName":"partner/components/ui/popover.hbs","parseOptions":{"srcName":"partner/components/ui/popover.hbs"}});
import Component from '@glimmer/component';
import { getOwner } from '@ember/application';

export default class UiPopoverComponent extends Component {
  get popoverContainer() {
    const config = getOwner(this).resolveRegistration('config:environment');

    return config.environment === 'test' ? '#ember-testing' : 'body';
  }
}
