/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { mapBy, not, or } from '@ember/object/computed';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import chunk from 'lodash/chunk';
import { POWERS } from 'partner/utils/bracket/constants';
import { createBracketMatchups, distributeSeededEntries, removeBracketMatchups } from 'partner/utils/bracket/setup';
import { elementVisible } from 'partner/utils/dom';

const oppositeOf = displayOrder => (displayOrder === 1 ? 2 : 1);

const getMatchupEntryElement = el => {
  if (el.dataset.matchupEntryId) {
    return el;
  } else if (el.parentNode.tagName === 'HTML') {
    return null;
  }
  return getMatchupEntryElement(el.parentNode);
};

// all things that should be removed when removing a media item
const MEDIA_ENTRY_FIELD_NAMES = ['Entry Source Type', 'Entry Source Media Type', 'Photo Upload', 'Video Entry Upload'];

// baseline properties for video and image media items
const ENTRY_FIELD_VALUE_FIELD_NAME_MAP = {
  sourceType: 'Entry Source Type',
  sourceMediaType: 'Entry Source Media Type',
};

const VOTING_BRACKET_DATES_ROUTE =
  'organizations.organization.organization-promotions.organization-promotion.setup.dates-prizes';
/**
 * @param {String} mediaType - 'Photo' or 'Video'
 */
const entryFieldValueFieldNameMap = mediaType => {
  if (mediaType === 'Photo') {
    return {
      ...ENTRY_FIELD_VALUE_FIELD_NAME_MAP,
      photoUpload: 'Photo Upload',
    };
  }
  if (mediaType === 'Video') {
    return {
      ...ENTRY_FIELD_VALUE_FIELD_NAME_MAP,
      videoUpload: 'Video Entry Upload',
    };
  }
  throw new Error('Unrecognized media type');
};

export default Component.extend({
  //region Ember Dependencies
  store: service(),
  enums: service(),
  //endregion

  //region Attributes
  matchups: null,
  matchupEntries: null,
  promotion: null,
  continue() {},
  updateModel() {},
  dipsUrl: null,
  uncheckChecklistStep() {},
  administer: null,
  //endregion

  //region Properties
  showDisableMessage: true,
  isAddingNewBracketRound: false,
  isRemovingBracketRound: false,
  isMediaFlyoutOpen: false,
  isCurrentlyUploadingImage: false,
  isDragging: false,
  isSavingMatchupEntry: false,
  matchupEntryBeingEdited: null,
  //endregion

  //region Computed Properties
  entries: mapBy('matchupEntries', 'entries'),
  entryFieldValuesForSeed: mapBy('matchupEntries', 'seed'),
  entryFieldValuesForEntryName: mapBy('matchupEntries', 'mediaTitle'),
  allEntriesHaveNames: computed('entryFieldValuesForEntryName.@each.value', function () {
    return this.entryFieldValuesForEntryName.every(entryNameField => isPresent(entryNameField?.value.trim()));
  }),
  matchupViews: computed(
    'matchups.@each.{iteration,displayOrder}',
    'matchupEntries.@each.{matchup,displayOrder}',
    function () {
      return chunk(
        this.matchups
          .filterBy('iteration', 1)
          .sortBy('displayOrder')
          .map(matchup => {
            const matchupEntriesCopy = [...this.matchupEntries.filterBy('matchup', matchup)];

            if (matchupEntriesCopy.length === 1) {
              matchupEntriesCopy.push({
                isBye: true,
                mediaTitle: { value: 'No Opponent' },
                displayOrder: oppositeOf(matchupEntriesCopy[0].displayOrder),
              });
            }

            return { matchup, matchupEntries: matchupEntriesCopy.sortBy('displayOrder') };
          }),
        2
      );
    }
  ),
  matchupThresholdReached: computed('matchupEntries.length', function () {
    return (
      isEmpty(this.matchupEntries) ||
      (this.matchupEntries.length !== 1 && Math.log2(this.matchupEntries.length) % 1 === 0)
    );
  }),
  bracketLoadingText: computed('isAddingNewBracketRound', 'isRemovingBracketRound', function () {
    if (this.isAddingNewBracketRound) {
      return 'Creating New Bracket Round...';
    } else if (this.isRemovingBracketRound) {
      return 'Removing Bracket Round...';
    }
    return;
  }),
  placeholderText: computed('matchupEntries.length', function () {
    return this.matchupEntries.length === 1 ? 'Add Entrant Most Likely to Win' : 'Add Entrant Next Most Likely to Win';
  }),
  numberOfByes: computed('matchupEntries.length', function () {
    return POWERS.find(power => power >= this.matchupEntries.length) - this.matchupEntries.length;
  }),
  isEditingDisabled: computed('matchups.@each.{iteration,startDate}', {
    get() {
      if (!this.administer) {
        return true;
      }
      return isPresent(this.matchups) ? new Date() >= this.matchups.findBy('iteration', 1).startDate : false;
    },
    set(key, value) {
      return value;
    },
  }),
  isEditingEnabled: not('isEditingDisabled'),
  isAnythingSaving: or('isSavingMatchupEntry', 'isRemovingBracketRound', 'isAddingNewBracketRound'),
  //endregion

  //region Methods
  createMatchupEntry() {
    return this.store.createRecord('matchup-entry', {
      promotionId: this.promotion.id,
      statusTypeId: this.enums.findWhere('STATUS_TYPE', { name: 'Active' }),
      entry: this.store.createRecord('entry', {
        promotion: this.promotion,
        statusTypeId: this.enums.findWhere('STATUS_TYPE', { name: 'Active' }),
        name: '',
        entryFieldValues: [
          this.store.createRecord('entry-field-value', {
            fieldId: this.enums.findWhere('ENTRY_FIELD', { name: 'Seed' }),
            value: this.matchupEntries.length + 1,
          }),
          this.store.createRecord('entry-field-value', {
            fieldId: this.enums.findWhere('ENTRY_FIELD', { name: 'Media Title' }),
            value: '',
          }),
        ],
      }),
    });
  },
  createEntryFieldValuesForMedia(matchupEntry, mediaType) {
    return Object.keys(entryFieldValueFieldNameMap(mediaType))
      .map(entryFieldValue =>
        matchupEntry[entryFieldValue]
          ? null
          : this.store.createRecord('entry-field-value', {
              entry: matchupEntry.entry,
              fieldId: this.enums.findWhere('ENTRY_FIELD', {
                name: entryFieldValueFieldNameMap(mediaType)[entryFieldValue],
              }),
            })
      )
      .filter(Boolean);
  },
  disableInterface() {
    const isStartDatePassed = isPresent(this.matchups)
      ? new Date() >= this.matchups.findBy('iteration', 1).startDate
      : false;
    set(this, 'isEditingDisabled', isStartDatePassed);
    return isStartDatePassed;
  },
  //endregion

  //region Actions
  actions: {
    setEntryName(matchupEntry, { target: { value } }) {
      matchupEntry.errors.remove('entries.name');
      set(matchupEntry.entry, 'name', value);
      set(matchupEntry.mediaTitle, 'value', value);
    },
    // Creating/Editing/Saving Media Items
    async addImageMediaItem(matchupEntry, mediaItem) {
      set(this, 'isMediaFlyoutOpen', false);

      const newEntryFieldValues = this.createEntryFieldValuesForMedia(matchupEntry, 'Photo');

      set(matchupEntry.photoUpload, 'value', mediaItem?.id);
      set(matchupEntry.sourceMediaType, 'value', 'image');
      set(matchupEntry.sourceType, 'value', 'SecondStreet');

      await Promise.all(newEntryFieldValues.map(entryFieldValueForImage => entryFieldValueForImage.save()));
    },
    startUpload(type) {
      set(this, `isCurrentlyUploading${type}`, true);
    },
    endUpload(type) {
      set(this, `isCurrentlyUploading${type}`, false);
    },
    async removeMediaItem(matchupEntry) {
      const entryFieldValuesToDelete = matchupEntry.entry.entryFieldValues.filter(entryFieldValue =>
        MEDIA_ENTRY_FIELD_NAMES.includes(this.enums.findWhere('ENTRY_FIELD', { id: entryFieldValue.fieldId }, 'name'))
      );
      return Promise.all(
        entryFieldValuesToDelete.map(entryFieldValueToDelete => entryFieldValueToDelete.destroyRecord())
      );
    },
    // END Creating/Editing/Saving Media Items

    // Creating/Editing/Saving Description
    createBracketEntryDescription(matchupEntry) {
      this.store.createRecord('entry-field-value', {
        fieldId: this.enums.findWhere('ENTRY_FIELD', { name: 'Media Caption' }),
        entry: matchupEntry.entry,
        value: '',
      });
    },
    saveBracketEntryDescription(mediaCaptionEntryField) {
      if (isEmpty(mediaCaptionEntryField.value.trim())) {
        mediaCaptionEntryField.destroyRecord();
      } else if (mediaCaptionEntryField.hasDirtyAttributes) {
        mediaCaptionEntryField.save();
      }
    },
    setBracketEntryDescription(mediaCaptionEntryField, content) {
      set(mediaCaptionEntryField, 'value', content);
    },
    // END Creating/Editing/Saving Description
    continue() {
      this.continue();
    },
    async removeEntry(matchupEntry) {
      if (this.disableInterface()) {
        return;
      }

      set(this, 'isSavingMatchupEntry', true);
      await this.updateModel('matchupEntries', 'removeObject', matchupEntry);

      let matchupsToRemove = [];
      if (this.matchupThresholdReached) {
        set(this, 'isRemovingBracketRound', true);
        matchupsToRemove = removeBracketMatchups(this.matchups);
        await this.updateModel('matchups', 'removeObjects', matchupsToRemove);
      }

      this.matchupEntries.sortBy('seedValue').forEach((matchupEntry, index) => {
        set(matchupEntry, 'seed.value', index + 1);
      });

      distributeSeededEntries(this.matchupEntries, this.matchups);
      const matchupEntriesToSave = this.matchupEntries.rejectBy('isNew').filterBy('hasDirtyMatchupOrAttributes');

      await Promise.all([
        matchupEntry.destroyRecord(),
        ...matchupEntriesToSave.map(matchupEntry => matchupEntry.save()),
        ...matchupsToRemove.map(matchup => matchup.destroyRecord()),
      ]);

      matchupEntriesToSave.forEach(matchupEntry => {
        matchupEntry.toggleDirtyMatchupState(false);
      });

      this.updateChecklistStep();
      if (isEmpty(this.matchups)) {
        this.uncheckChecklistStep(VOTING_BRACKET_DATES_ROUTE);
      }
      this.updateEndDateForGames(this.matchups);
      set(this, 'isRemovingBracketRound', false);
      set(this, 'isSavingMatchupEntry', false);
    },
    async addMatchupEntry() {
      if (this.disableInterface()) {
        return;
      }

      if (this.matchupThresholdReached) {
        set(this, 'isAddingNewBracketRound', true);
        const newMatchupObjects = createBracketMatchups(this.matchups);
        const newMatchups = newMatchupObjects.map(newMatchupObject =>
          this.store.createRecord('matchup', { ...newMatchupObject })
        );

        await Promise.all(newMatchups.map(matchup => matchup.save()));
        await this.updateModel('matchups', 'addObjects', newMatchups);

        this.uncheckChecklistStep(VOTING_BRACKET_DATES_ROUTE);
      }
      const newMatchupEntry = this.createMatchupEntry();
      distributeSeededEntries([newMatchupEntry, ...this.matchupEntries], this.matchups);
      await this.updateModel('matchupEntries', 'addObject', newMatchupEntry);
      this.updateEndDateForGames(this.matchups);
      set(this, 'isAddingNewBracketRound', false);
    },
    async saveMatchupEntry(matchupEntry) {
      if (isPresent(matchupEntry.mediaTitle.value.trim()) && matchupEntry.mediaTitle.hasDirtyAttributes) {
        set(this, 'isSavingMatchupEntry', true);
        if (matchupEntry.isNew) {
          const matchupEntriesToSave = this.matchupEntries.filterBy('hasDirtyMatchupOrAttributes').rejectBy('isSaving');
          await Promise.all([...matchupEntriesToSave.map(matchupEntry => matchupEntry.save())]);
          matchupEntriesToSave.forEach(matchupEntry => {
            matchupEntry.toggleDirtyMatchupState(false);
          });
        } else {
          await matchupEntry.save();
        }
      } else {
        if (!matchupEntry.isNew) {
          matchupEntry.mediaTitle.rollbackAttributes();
        }
      }
      this.updateChecklistStep();
      set(this, 'isSavingMatchupEntry', false);
    },
    async reassignEntrySeeds(resortedMatchupEntries) {
      if (!this.allEntriesHaveNames || this.disableInterface()) {
        return;
      }
      set(this, 'isSavingMatchupEntry', true);
      resortedMatchupEntries.forEach((matchupEntry, index) => {
        set(matchupEntry, 'seed.value', index + 1);
      });
      distributeSeededEntries(this.matchupEntries, this.matchups);
      await Promise.all(
        this.matchupEntries.filterBy('hasDirtyMatchupOrAttributes').map(matchupEntry => matchupEntry.save())
      );
      this.matchupEntries.forEach(matchupEntry => {
        matchupEntry.toggleDirtyMatchupState(false);
      });
      set(this, 'isSavingMatchupEntry', false);
    },
    // Dragging and Swapping Entries
    onDragStart(event) {
      set(this, 'isDragging', true);
      event.dataTransfer.setData('text/plain', event.target.dataset.matchupEntryId);
      event.dataTransfer.effectAllowed = 'move';
    },
    onDrag(event) {
      if (!event.clientY) {
        // Bail for Firefox because it doesn't set values correctly on drag events:
        // https://bugzilla.mozilla.org/show_bug.cgi?id=505521
        return;
      }

      // 150 pixels to make an allowance for the app bar
      if (event.clientY < 150) {
        window.scrollBy(0, -10);
      }

      if (window.innerHeight - event.clientY < 50) {
        window.scrollBy(0, 10);
      }
    },
    onDragEnd() {
      set(this, 'isDragging', false);
    },
    onDragOver(event) {
      event.preventDefault();
      const matchupEntryElement = getMatchupEntryElement(event.currentTarget);
      if (matchupEntryElement) {
        matchupEntryElement.classList.add('bracket-matchups__entrant--hover-zone');
      }
    },
    onDragLeave(event) {
      event.preventDefault();
      const matchupEntryElement = getMatchupEntryElement(event.currentTarget);
      if (matchupEntryElement) {
        matchupEntryElement.classList.remove('bracket-matchups__entrant--hover-zone');
      }
    },
    async onDrop(event) {
      event.preventDefault();

      if (!this.allEntriesHaveNames || this.disableInterface()) {
        return;
      }

      set(this, 'isSavingMatchupEntry', true);

      const draggedMatchupEntry = this.matchupEntries.findBy('id', event.dataTransfer.getData('text'));
      const targetMatchupEntry = this.matchupEntries.findBy(
        'id',
        getMatchupEntryElement(event.target).dataset.matchupEntryId
      );

      const draggedSeedValue = draggedMatchupEntry.seedValue;
      const targetSeedValue = targetMatchupEntry.seedValue;

      set(draggedMatchupEntry, 'seed.value', targetSeedValue);
      set(targetMatchupEntry, 'seed.value', draggedSeedValue);

      distributeSeededEntries(this.matchupEntries, this.matchups);
      await Promise.all(
        this.matchupEntries.filterBy('hasDirtyMatchupOrAttributes').map(matchupEntry => matchupEntry.save())
      );

      this.matchupEntries.forEach(matchupEntry => {
        matchupEntry.toggleDirtyMatchupState(false);
      });
      set(this, 'isSavingMatchupEntry', false);
    },
    // END Dragging and Swapping Entries
    editMatchupEntry(entry) {
      set(this, 'matchupEntryBeingEdited', entry);
    },
    scrollToPreview() {
      next(() => {
        const bracketPreview = document.querySelector('.bracket__preview-label');
        if (!elementVisible(bracketPreview)) {
          window.scrollTo(0, bracketPreview.getBoundingClientRect().top);
        }
      });
    },
  },
  //endregion
});
