export default ({ message }) => `
<div data-token="thankYouMessage" style="font-family: 'Open Sans'">
	<div class="ssUGCGallery">
		<div class="liquid-container">
			<div class="liquid-child" style="visibility: visible; top: 0px; left: 0px; transform: translateX(0px);">
				<div class="liquid-container">
					<div class="liquid-child" style="top: 0px; left: 0px;">
						<div style="max-height: 500px">
							<div class="ssUserInfo template-color-primary-background-color">
                <div class="user-info-container">
                </div>
              </div>
              <div class="ssGalleryHeader individual-entry template-color-primary-background-color">
                <div class="ssGalleryHeaderContent">
                  <a href="#" class="back-to-gallery active">
                    <span>Gallery</span>
                  </a>
                </div>
              </div>
							<div class="ssEntryWrapper entry-registration-form">
								<div class="individual-entry-view liquid-container">
									<div class="liquid-child" style="top: 0px; left: 0px;">
										<div class="entry-container">
											<div class="uploaded-image ">
												<img width="600" src="//media.secondstreetapp.com/249311?width=600">
												<div class="voted-statusliquid-container">
												</div>
												<div class="image-caption-container">
													<div class="image-caption">Pending Approval</div>
												</div>
											</div>
											<div class="entry-information">
												<div class="vote-button-container">
												</div>
												<span class="entry-title">Title</span>
												<span class="entry-caption">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nisi ante, porttitor a magna in, viverra condimentum eros. Aliquam scelerisque, ante in rhoncus lacinia, ligula ex semper ante, blandit maximus elit diam eget eros. Sed pharetra eu felis iaculis gravida. In sed diam non odio eleifend placerat at laoreet lorem. </span>
												<ul class="ssShareListCompact ssShareListConsumer ssShareList">
													<li class="ssShareListItem">
														<a class="ssShareLink ssShareLinkFacebook" href="#">
															<i class="ssIcon-facebook"></i>
															<span class="ssShareLinkText">Share</span>
														</a>
													</li>
													<li class="ssShareListItem">
														<a target="_blank" class="ssShareLink ssShareLinkTwitter" href="#">
															<i class="ssIcon-twitter"></i>
															<span class="ssShareLinkText">Tweet</span>
														</a>
													</li>
													<!---->
													<li class="ssShareListItem">
														<a target="_parent" class="ssShareLink ssShareLinkEmail" href="#">
															<i class="ssIcon-envelope"></i>
															<span class="ssShareLinkText">Email</span>
														</a>
													</li>
													<li class="ssShareListItem">
														<a target="_blank" class="ssShareLink ssShareLinkPinterest" href="#">
															<i class="ssIcon-pinterest"></i>
															<span class="ssShareLinkText">Pin It</span>
														</a>
													</li>
												</ul>
											</div>

										</div>
									</div>
								</div>
							</div>
							<div class="ssGalleryHeader gallery-footer template-color-primary-background-color">
								<div class="ssGalleryHeaderContent">
									<div class="ssUserInfo">
										<div class="current-vote liquid-container">
										</div>
										<div class="thumbnail">
											<a href="#" class="thumbnail-go active">
												<img width="76" height="76" src="//media.secondstreetapp.com/249311?width=152&height=152&cropmode=Fill&anchor=Center" cropmode="Fill" anchor="Center" class="thumbnail-primary">
												<span class="thumbnail-overlay pending">Pending</span>
											</a>
										</div>
										<div class="logout-container">
											<div class="logout-text last-action"> Thanks For Entering, Jane!
											</div>
											<div class="logout-text" title="jane.doe@example.com">jane.doe@example.com</div>
											<div class="logout-voting-button">
												Log out
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="matchup-entry-modal">
							<div class="ssModal ssModalShow ssModalComponent">
								<div class="ssModalContent ">
									<div class="ssModalClose">
										<button type="button" class="ssButton ssButtonSmall ssModalCloseButton ssPullRight ssButtonFlat">close</button>
									</div>
									<div class="ssModalInnerContent">${message}</div>

								</div>
							</div>
							<div class="ssModalOverlay"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
`;
