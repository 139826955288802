/* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes, ember/no-observers, ember/no-mixins, ember/require-tagless-components, ember/no-component-lifecycle-hooks, ember/no-jquery, ember/no-incorrect-calls-with-inline-anonymous-functions, ember/closure-actions */
import Component from '@ember/component';
import { action, computed, get, set, setProperties } from '@ember/object';
import { bool, intersect, setDiff } from '@ember/object/computed';
import { addObserver } from '@ember/object/observers';
import { debounce, next, run, scheduleOnce } from '@ember/runloop';
import $ from 'jquery';
import PermittedComponent from 'partner/mixins/permitted-component';

const positions = (context, suggestion) => {
  const ul = context.element.querySelector('ul.autocomplete-input-suggestions');
  const li = ul.querySelector(`li[data-id='${suggestion.id}']`);

  const liPositionTop = li.offsetTop;
  const liHeight = li.offsetHeight;
  return { ul, liPositionTop, liHeight };
};

/**
 * Autocomplete Input. Shows a input for search value and relevant results when value entered.
 * @typedef {Ember.Component} AutocompleteInput
 */
export default class AutocompleteInputComponent extends Component.extend(PermittedComponent) {
  //region Ember Hooks
  constructor() {
    super(...arguments);
    addObserver(this, 'searchInput', this, this.searchInputChanged);
    addObserver(this, 'search', this, this.searchChanged);
  }
  tagName = 'autocomplete-input';
  classNames = ['autocomplete-input'];
  classNameBindings = ['hasSearch', 'isFocused'];
  //endregion Ember Hooks

  //region Properties
  search = '';
  searchInput = '';
  'chosen-tags' = [];
  'available-tags' = [];
  'unavailable-tags' = [];
  'hide-chosen-tags' = true;
  'search-path' = 'id';
  'sort-path' = 'id';
  'display-path' = 'id';
  'secondary-text-path' = null;
  'clear-on-choice' = true;
  'safe-string' = false;
  /**
   * Leaving this false in future uses of this component should be considered deprecated.
   * If true, the following is the behavior of the component:
   *    - chosen-tags must be sent in, and is NOT modified by the component.
   *    - An action called "tag-added" is sent with the item when one is chosen
   * If false, the following is the behavior of the component:
   *    - chosen-tags WILL BE modified by the component
   *    - No actions will be sent up.
   * @type {Boolean}
   */
  'data-down-actions-up' = false;
  isFocused = false;
  /**
   * The list of matched available tags based on the user's search terms
   */
  suggestions = [];
  //endregion Properties

  //region Ember Hooks
  didInsertElement() {
    super.didInsertElement(...arguments);
    // Add body click event to reset search
    $('body').on('click.interestTags', () => {
      if (!this.isDestroyed) {
        set(this, 'searchInput', '');
      }
    });

    $('body').on('close.all.autocompleteTagResults', (event, elementId) => {
      run(() => this._hideSuggestions(elementId));
    });
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    $('body').off('click.interestTags');
    $('body').off('close.all.autocompleteTagResults');
  }
  /**
   * @private
   */
  keyDown(event) {
    let currentItem, nextItemIndex, sortedNotChosen, suggestion;

    // down arrow
    if (event.keyCode === 40 && this.hasSearch) {
      sortedNotChosen = this.sortedNotChosenSuggestions;
      const highlightedSuggestion = this.suggestions.filterBy('__autocompleteTagsHighlighted__', true);
      currentItem = highlightedSuggestion.firstObject;
      nextItemIndex = sortedNotChosen.indexOf(currentItem) + 1;
      // if these are the same, we're on the last item in the array
      if (nextItemIndex === sortedNotChosen.length) {
        suggestion = sortedNotChosen.objectAt(0);
      } else {
        suggestion = sortedNotChosen.objectAt(nextItemIndex);
      }
      this._highlight(suggestion);
      this._scrollDownIfNeeded(suggestion);
      event.preventDefault();
    } else if (event.keyCode === 38 && this.hasSearch) {
      // up arrow
      sortedNotChosen = this.sortedNotChosenSuggestions;
      const highlightedSuggestion = this.suggestions.filterBy('__autocompleteTagsHighlighted__', true);
      currentItem = highlightedSuggestion.firstObject;
      nextItemIndex = sortedNotChosen.indexOf(currentItem) - 1;
      if (nextItemIndex === -1) {
        suggestion = sortedNotChosen.objectAt(sortedNotChosen.length - 1);
      } else {
        suggestion = sortedNotChosen.objectAt(nextItemIndex);
      }
      this._highlight(suggestion);
      this._scrollUpIfNeeded(suggestion);
      event.preventDefault();
    } else if (event.keyCode === 13 && this.hasSearch) {
      // enter
      const highlightedSuggestion = this.suggestions.filterBy('__autocompleteTagsHighlighted__', true);
      currentItem = highlightedSuggestion.firstObject;
      if (currentItem) {
        this._chooseItem(currentItem);
      }
      event.preventDefault();
    } else if (event.keyCode === 9 && this.hasSearch) {
      set(this, 'searchInput', '');
    }
  }
  //endregion

  //region Computed Properties
  @bool('search')
  hasSearch;

  @setDiff('available-tags', 'unavailableTags')
  notChosenTags;

  @intersect('suggestions', 'notChosenTags')
  notChosenSuggestions;

  @computed('notChosenSuggestions.[]')
  get sortedNotChosenSuggestions() {
    //returning a copy of the array because if you don't ember doesn't notice only index changes
    const sortedSuggestions = this.sortSuggestions(this.notChosenSuggestions).slice();

    // highlight the first item in the sorted list of suggestions
    if (sortedSuggestions && sortedSuggestions.length > 0) {
      const [firstSuggestion] = sortedSuggestions;
      if (!firstSuggestion.__autocompleteTagsHighlighted__) {
        this._highlight(firstSuggestion);
      }
    }

    return sortedSuggestions;
  }
  //endregion

  //region Methods
  _hideSuggestions(elementId) {
    if (!this.isDestroyed && (elementId ? this.elementId !== elementId : true)) {
      set(this, 'searchInput', '');
    }
  }
  /**
   * Default sort logic for sorting suggestions. Uses property 'sort-path'.
   * This sort logic can be overridden by extending this component and implementing a sortSuggestions function.
   * @param suggestions
   * @returns {Array} sorted array
   */
  sortSuggestions(suggestions) {
    return suggestions.sortBy(this['sort-path']);
  }
  /**
   * Default logic for determining if tag should be a suggestion. Uses property 'search-path'.
   * This can be overridden by extending this component and implementing a isTagRelevant function.
   * @param {string} search
   * @param {Object} tag
   * @returns {boolean} true if relevant to search term
   */
  isTagRelevant(search, tag) {
    return get(tag, this['search-path'])
      .split(' ')
      .any(word => word.toLowerCase().indexOf(search) === 0);
  }
  /**
   * @private
   */
  _updateSearch() {
    if (!this.isDestroyed) {
      set(this, 'search', this.searchInput);
    }
  }
  /**
   * @private
   */
  _focusSearch() {
    scheduleOnce('afterRender', this, function () {
      this.element.querySelector('input.autocomplete-input-search-input').focus();
    });
  }
  /**
   * @private
   */
  _reset() {
    setProperties(this, { search: '', searchInput: '' });
  }
  /**
   * @private
   */
  _highlight(suggestion) {
    if (suggestion) {
      this['available-tags'].filterBy('__autocompleteTagsHighlighted__', true).forEach(tag => {
        set(tag, '__autocompleteTagsHighlighted__', false);
      });
      set(suggestion, '__autocompleteTagsHighlighted__', true);
    }
  }
  /**
   *
   */
  _chooseItem(item) {
    if (this['data-down-actions-up']) {
      this['tag-added'](item);
    } else {
      this['chosen-tags'].addObject(item);
    }
    if (this['clear-on-choice']) {
      this._reset();
      this._focusSearch();
    }
  }
  _scrollDownIfNeeded(suggestion) {
    if (!suggestion) {
      return;
    }
    const { ul, liPositionTop, liHeight } = positions(this, suggestion);
    if (liPositionTop + liHeight > ul.offsetHeight) {
      //li needs to be scrolled into view
      ul.scrollTop = ul.scrollTop + liHeight;
      // going off the bottom! go back to the top...
    } else if (liPositionTop < 0) {
      ul.scrollTop = 0;
    }
  }
  _scrollUpIfNeeded(suggestion) {
    if (!suggestion) {
      return;
    }
    const { ul, liPositionTop, liHeight } = positions(this, suggestion);
    if (liPositionTop < 0) {
      //li needs to be scrolled into view
      ul.scrollTop = ul.scrollTop - liHeight;
      // going off the top! go back to the bottom...
    } else if (liPositionTop > ul.offsetHeight) {
      ul.scrollTop = liPositionTop;
    }
  }
  /**
   * @private
   */
  _updateSuggestions() {
    const search = this.search.toLowerCase();
    const suggestions = this['available-tags']
      .map(function (tag) {
        return this.isTagRelevant(search, tag) ? tag : undefined;
      }, this)
      .compact();

    set(this, 'suggestions', suggestions);
  }
  /**
   * Debounce updating the search input used for filtering, since typing speeds can cause lag
   * @type {Ember.Observer}
   */
  searchInputChanged() {
    debounce(this, this._updateSearch, 250);
  }
  searchChanged() {
    if (this.search) {
      next(this, this._updateSuggestions);
    }
  }
  //endregion

  //region Actions
  @action
  chooseItem(item) {
    this._chooseItem(item);
  }

  @action
  highlightItem(suggestion) {
    this._highlight(suggestion);
  }

  @action
  showSuggestions() {
    set(this, 'isFocused', true);

    if (this.searchInput !== '') {
      set(this, 'search', this.searchInput);
    }
    //close all other open AutocompleteTagsComponent's (id will be used to exclude itself from event)
    $('body').trigger('close.all.autocompleteTagResults', [this.elementId]);
  }

  @action
  hideSuggestions() {
    set(this, 'isFocused', false);
    this._hideSuggestions();
  }
  //endregion
}
