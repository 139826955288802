import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<EntryForm::Field::Wrapper @label={{@formField.labelText}} ...attributes as |guid|>\n  <SsWysiwyg\n    @content={{to-string (markdown-to-html @entryFieldValues.firstObject.value)}}\n    @changed={{this.handleInput}}\n    @placeholder={{@placeholder}}\n    @simple={{true}}\n    @guid={{guid}}\n    disabled={{@disabled}}\n    id={{guid}}\n    test-redactor\n  />\n</EntryForm::Field::Wrapper>\n", {"contents":"<EntryForm::Field::Wrapper @label={{@formField.labelText}} ...attributes as |guid|>\n  <SsWysiwyg\n    @content={{to-string (markdown-to-html @entryFieldValues.firstObject.value)}}\n    @changed={{this.handleInput}}\n    @placeholder={{@placeholder}}\n    @simple={{true}}\n    @guid={{guid}}\n    disabled={{@disabled}}\n    id={{guid}}\n    test-redactor\n  />\n</EntryForm::Field::Wrapper>\n","moduleName":"partner/components/entry-form/field/textarea.hbs","parseOptions":{"srcName":"partner/components/entry-form/field/textarea.hbs"}});
import { action } from '@ember/object';
import Base from './base';

export default class EntryFormFieldTextareaComponent extends Base {
  @action
  handleInput(e) {
    this.args.onChange(this.entryFieldValue, {
      value: e,
      fieldId: this.fieldId,
    });
  }
}
