import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"h-full\" {{did-insert (perform this.renderMapTask)}} {{will-destroy this.cleanup}}>\n  {{yield}}\n</div>\n", {"contents":"<div class=\"h-full\" {{did-insert (perform this.renderMapTask)}} {{will-destroy this.cleanup}}>\n  {{yield}}\n</div>\n","moduleName":"partner/components/google-map.hbs","parseOptions":{"srcName":"partner/components/google-map.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { Cluster, ClusterStats, MarkerClusterer } from '@googlemaps/markerclusterer';
import type GoogleMapsService from 'partner/services/google-maps';
import { task } from 'ember-concurrency';
import maxBy from 'lodash/maxBy';
import meanBy from 'lodash/meanBy';
import { action } from '@ember/object';

interface GoogleMapSignature {
  Element: HTMLElement;
  Args: {
    data: { latitude: number; longitude: number; label: string }[];
  };
}

export default class GoogleMapsComponent extends Component<GoogleMapSignature> {
  @service declare googleMaps: GoogleMapsService;

  private map?: google.maps.Map;
  private markers: google.maps.Marker[] = [];
  private markerCluster?: CustomMarkerClusterer;
  private applyCenter = true;

  renderMapTask = task({ drop: true }, async (element: HTMLDivElement) => {
    const { Map } = await this.googleMaps.loader.importLibrary('maps');
    const { Marker } = await this.googleMaps.loader.importLibrary('marker');
    const { Size } = await this.googleMaps.loader.importLibrary('core');
    const { LatLngBounds } = await this.googleMaps.loader.importLibrary('core');
    const bounds = new LatLngBounds();

    this.markers = this.args.data
      .filter(({ latitude, longitude }) => latitude && longitude)
      .map(({ latitude, longitude }) => {
        const position = { lat: latitude, lng: longitude };

        bounds.extend(position);

        return new Marker({ position });
      });

    this.map = new Map(element, {
      zoom: 1,
      mapTypeControl: false,
      streetViewControl: false,
    });

    this.map.fitBounds(bounds);

    this.markerCluster = new CustomMarkerClusterer({
      map: this.map,
      markers: [],
      renderer: {
        render({ count, position }: Cluster, stats: ClusterStats) {
          const { icon, size } = getClusterIcon(count, stats.clusters.markers.max);

          return new Marker({
            position,
            icon: {
              url: icon,
              size: new Size(size, size),
            },
            label: {
              text: String(count),
              color: 'rgba(255, 255, 255, 0.9)',
              fontSize: '11px',
            },
            zIndex: Number(Marker.MAX_ZINDEX) + count,
          });
        },
      },
    });

    this.map.addListener('idle', this.onIdleEvent);
  });

  @action
  cleanup() {
    this.markerCluster?.clearMarkers();
    this.markers.forEach(marker => marker.setMap(null));
  }

  private onIdleEvent = () => {
    if (this.map) {
      const bounds = this.map.getBounds();

      this.markerCluster?.clearMarkers();

      this.markerCluster?.addMarkers(this.markers.filter(marker => bounds?.contains(marker.getPosition() as any)));
      if (this.applyCenter) {
        this.centerOnBiggestCluster();
        this.applyCenter = false;
      }
    }
  };

  private centerOnBiggestCluster() {
    if (!this.markerCluster || !this.map) return;

    const clusters = this.markerCluster.getClusters();
    const biggestCluster = maxBy(clusters, 'count');
    const mean = meanBy(clusters, 'count');

    if (biggestCluster && biggestCluster.count > 10 && biggestCluster.count > mean) {
      this.map.panTo(biggestCluster.position);
      this.map.setZoom(3);
    }
  }
}

class CustomMarkerClusterer extends MarkerClusterer {
  getClusters() {
    return this.clusters;
  }
}

const CLUSTER_ICONS = [
  { icon: buildClusterSvg('#0e74fe', 53), size: 53 },
  { icon: buildClusterSvg('#feb30b', 56), size: 56 },
  { icon: buildClusterSvg('#fc0008', 66), size: 66 },
  { icon: buildClusterSvg('#fc00e8', 78), size: 78 },
  { icon: buildClusterSvg('#8900fe', 90), size: 90 },
] as const;

/**
 * Returns the icon and size for a cluster based on the number of markers it contains.
 * It returns different sizes depending on the biggest cluster in the set
 */
function getClusterIcon(count: number, max: number) {
  const multiplier = max < 10 ? 1 : max < 50 ? 2 : max < 100 ? 3 : max < 250 ? 4 : 5;

  return CLUSTER_ICONS[Math.ceil((count / max) * multiplier) - 1] ?? CLUSTER_ICONS[0];
}

function buildClusterSvg(color: string, size: number) {
  const svg = `
    <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="${size}" height="${size}">
      <circle cx="120" cy="120" opacity=".8" r="70" />
      <circle cx="120" cy="120" opacity=".4" r="90" />
      <circle cx="120" cy="120" opacity=".2" r="110" />
    </svg>
  `;

  return `data:image/svg+xml;base64,${btoa(svg)}`;
}
