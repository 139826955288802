/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

export default BaseModel.extend({
  //region Properties
  type: attr('string'),
  name: attr('string'),
  value: attr('string'),
  //endregion

  //region Relationships
  senderAccountSetup: belongsTo('sender-account-setup', { async: false }),
  //endregion
});
