/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';

export default Component.extend({
  //region Ember Hooks
  attributeBindings: ['style'],

  didRender() {
    this._super(...arguments);

    if (this.element) {
      set(this, 'drawerHeight', this.element.querySelector('.ssTurnkeyCategoryDrawer')?.offsetHeight || 0);
    }
  },
  //endregion

  //region Properties
  tagName: 'li',
  //endregion

  //region Attributes
  'custom-promotion-presets': null,
  //endregion

  //region Computed Properties
  style: computed('drawerHeight', 'isExpanded', function () {
    return this.isExpanded ? `margin-bottom: calc(7em + ${this.drawerHeight}px)` : '';
  }),
  isExpanded: computed('category', 'activeCategory', function () {
    return this.category === this.activeCategory;
  }),
  //endregion
});
