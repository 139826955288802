/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Route from '@ember/routing/route';
import OrganizationPromotionListRoute from 'partner/mixins/organization-promotion-list-route';

/**
 * OrganizationPromotions Archived Route
 * /o/:organization_id/interactive-content/archived
 * @type {Ember.Route}
 * @mixes OrganizationPromotionListRoute
 */
export default Route.extend(OrganizationPromotionListRoute, {
  promotionTypeCategory: 'interactiveContent',
});
