import { computed, get } from '@ember/object';
import BaseComponent from 'partner/components/winners/base-component';

export default BaseComponent.extend({
  //region Methods
  calculateWinnersColumns() {
    const winners = this.winners.concat(this.fakeWinners);
    return get(this, 'screen.width') <= 640 ? [winners] : this.splitToColumns(winners);
  },

  firstEligibleToBePicked: computed('winnersColumns', function () {
    return this.winnersColumns
      .flat()
      .sort((a, b) => a.rank - b.rank)
      .findBy('fakeWinner', true);
  }),
  //endregion
});
