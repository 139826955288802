/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/named-functions-in-promises */ // FIXME
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import PromotionTypeRoute from 'partner/mixins/promotion-type-route';
import { FRIEND_REFERRAL_FIELD_ID } from 'partner/utils/constants';
import findMatchupPlacingCriteria from 'partner/utils/find-matchup-placing-criteria';
import RSVP from 'rsvp';

const VOTING_BRACKET_DATES_ROUTE =
  'organizations.organization.organization-promotions.organization-promotion.setup.dates-prizes';
const ORGANIZATION_PROMOTION_SETUP_ROUTE =
  'organizations.organization.organization-promotions.organization-promotion.setup';

/**
 * Setup Dates & Prizes Route
 * /o/:organization_id/op/:organization_promotion_id/setup/dates-prizes
 * @type {Ember.Route}
 * @mixes PromotionTypeRoute
 */
export default Route.extend(PromotionTypeRoute, {
  //region Dependencies
  facebook: service(),
  enums: service(),
  store: service(),
  settings: service(),
  //endregion

  //region Ember Hooks
  model() {
    const { organization } = this.modelFor('organizations.organization');
    const { organizationPromotion } = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion'
    );
    const { games } = this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE);
    const organizationId = organization.id;
    const organizationPromotionId = organizationPromotion.id;
    const { promotion } = organizationPromotion;
    const extraChancesFormTypeId = this.enums.findWhere('FORM_TYPE', { name: 'ExtraChances' });
    const isSweepstakes = get(promotion, 'isSweepstakes');
    const { registrationEnabledSetting } = this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE);

    const hash = {
      matchups: this.store.query('matchup', { excludeSecondaryMatchups: true }).then(x => x.toArray()),
      sweepstakes: this.store.queryRecord('sweepstakes', {
        organizationId,
        organizationPromotionId,
      }),
      matchupPlacingCriteria: this.store.findRecord(
        'matchupPlacingCriteria',
        findMatchupPlacingCriteria(get(promotion, 'promotionTypeName'))
      ),
      extraChancesField: isSweepstakes ? this.store.findRecord('field', FRIEND_REFERRAL_FIELD_ID) : null, // The API doesn't include this with starred fields
      starredFields: this.store.query('field', { isStarred: true }),
      extraChancesForm: isSweepstakes ? this.store.queryRecord('form', { formTypeId: extraChancesFormTypeId }) : null,
      _settings: this.settings.preload('dips_url'),
      games,
      organization,
      organizationPromotion,
      participationCountries: this.store.query('participation-area', {}).then(result => result.toArray()),
      registrationForm: this.store
        .query('form', {
          organizationId,
          organizationPromotionId,
          formTypeId: this.enums.findWhere('FORM_TYPE', { name: 'Registration' }),
        })
        .then(result => result.toArray().lastObject),
      registrationEnabledSetting,
    };

    const usesForms =
      this.promotionTypeName &&
      ['UGCVoting', 'UGCGallery', 'UGCSweepstakes', 'Ballot'].includes(this.promotionTypeName);

    if (usesForms) {
      const formTypeId = this.enums.findWhere('FORM_TYPE', {
        name: 'EntrySubmission',
      });
      set(hash, 'entryForm', this.store.queryRecord('form', { formTypeId }));

      const fieldValueDestinationTypeId = `${this.enums.findWhere(
        'FIELD_VALUE_DESTINATION_TYPE',
        { name: 'Entry' },
        'id'
      )}`;
      set(hash, 'fields', this.store.query('field', { fieldValueDestinationTypeId }));
    }

    if (['isBallot', 'isUGCVoting', 'isUGCGallery', 'isVotingBracket'].some(is => get(promotion, is))) {
      hash.voteIntervalTypeIdSetting = this.store.queryRecord('setting', { key: 'vote_allowed_interval' });
      hash.votesAllowedNumberSetting = this.store.queryRecord('setting', { key: 'votes_allowed_number' });
      hash.entryIntervalTypeIdSetting = this.store.queryRecord('setting', { key: 'entries_allowed_interval' });
      hash.entriesAllowedNumberSetting = this.store.queryRecord('setting', { key: 'entries_allowed_number' });
    }

    if (promotion.isSurvey) {
      hash.surveyFrequencySetting = this.store.queryRecord('setting', { key: 'has_single_signup_limit' });
    }

    if (promotion.isVotingBallot) {
      hash.allowWriteInsSetting = this.store.queryRecord('setting', {
        key: 'Allow_WriteIns',
        organizationId,
        organizationPromotionId,
        promotionId: promotion.id,
      });
    }

    if (promotion.isSweepstakesCodeword) {
      hash.codewordRequiredSetting = this.store.queryRecord('setting', {
        key: 'Codeword_Required',
        organizationId,
        organizationPromotionId,
      });
    }

    if (promotion.isBallot) {
      hash.approvedEntrantDisplaySetting = this.store.queryRecord('setting', {
        key: 'hide_approved_entrants',
        organizationId,
        organizationPromotionId,
      });
    }

    return RSVP.hash(hash)
      .then(hash => {
        const mpc = get(hash, 'matchupPlacingCriteria');
        set(
          hash,
          'matchupPlaces',
          this.store.query('matchupPlace', {
            matchupPlacingCriteriaId: get(mpc, 'id'),
            organizationId,
            organizationPromotionId,
          })
        );

        return RSVP.hash({
          ...hash,
          dipsUrl: this.settings.getFor('dips_url'),
        });
      })
      .then(hash => {
        if (!get(promotion, 'isUGCGallery')) {
          const matchupPlaces = get(hash, 'matchupPlaces').sortBy('startRank');
          const firstPlace = get(matchupPlaces.filterBy('startRank', 1), 'firstObject');
          set(hash, 'matchupPlaces', matchupPlaces);
          set(hash, 'firstPlace', firstPlace);
        }

        return hash;
      });
  },

  afterModel(model) {
    if (model.organizationPromotion.promotion.isVotingBracket) {
      const checkStatus = isEmpty(model.games.findBy('isPrimary').matchups)
        ? 'uncheckChecklistStep'
        : 'checkChecklistStep';

      this.send(checkStatus, VOTING_BRACKET_DATES_ROUTE);
    }
  },
  /**
   * Load relevant records
   * TODO: Move all of this into the model hook
   * @see http://emberjs.com/api/classes/Ember.Route.html#method_setupController
   */
  setupController(controller, model) {
    this._super(...arguments);
    const relevantMatchupCodewords = get(controller, 'relevantMatchupCodewords');

    const today = new Date();
    let mostRelevantMatchup = null;

    const matchups = get(model, 'matchups');
    matchups.forEach(matchup => {
      // logic used to determine the most relevant start date
      if (today >= get(matchup, 'startDate')) {
        mostRelevantMatchup = matchup;
      }

      relevantMatchupCodewords.addObjects(get(matchup, 'matchupCodewords'));
    });

    if (!mostRelevantMatchup && !isEmpty(matchups)) {
      mostRelevantMatchup = get(matchups.sortBy('startDate'), 'firstObject');
    }

    if (mostRelevantMatchup) {
      set(mostRelevantMatchup, 'isCurrentMatchup', true);
    }
  },
  resetController(controller) {
    set(controller, '_openedMatchup', undefined);
  },
  //endregion

  //region Actions
  actions: {
    willTransition(transition) {
      let confirmed = !get(this, 'controller.isAnythingRelevantDirty');
      confirmed = confirmed ? confirmed : window.confirm('Are you sure you want to leave without saving?');

      if (confirmed) {
        this.controller.send('rollback');
        this._super(transition);
      } else {
        transition.abort();
      }
    },
  },
  //endregion
});
