/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { run, later } from '@ember/runloop';
import { ensureProtocol } from 'secondstreet-common/utils/url';

export default Component.extend({
  //region Attributes
  /**
   * @property {String}
   */
  value: null,
  /**
   * @property {String}
   */
  linkUrl: null,
  /**
   * @property {String}
   */
  title: null,
  updateTokenContentProperty() {},
  //endregion

  //region Ember Hooks
  init() {
    this._super(...arguments);
    if (this.buttonHasValues) {
      set(this, 'buttonEnabled', true);
    }
  },
  //endregion

  //region Properties
  /**
   * @property {Boolean}
   */
  buttonEnabled: false,
  //endregion

  //region Computed Properties
  buttonHasValues: computed('title', 'linkUrl', function () {
    return !!(this.title || this.linkUrl);
  }),
  //endregion

  //region Methods
  focusInputField(index) {
    later('afterRender', () => {
      const input = this.element.querySelectorAll('input[type="text"]')[index];
      input && input !== document.activeElement && input.focus();
    });
  },
  //endregion

  //region Actions
  actions: {
    toggleButton() {
      this.toggleProperty('buttonEnabled');
      if (this.buttonEnabled) {
        //zero-indexed: focus in on the second input in the flyout (first input of the button)
        this.focusInputField(1);
      } else {
        this.updateTokenContentProperty('title', null);
        this.updateTokenContentProperty('linkUrl', null);
      }
    },
    ensureProtocol() {
      run(() => this.updateTokenContentProperty('linkUrl', ensureProtocol(this.linkUrl)));
    },
    setValue(value) {
      this.updateTokenContentProperty('value', value);
    },
    setTitle(value) {
      this.updateTokenContentProperty('title', value);
    },
    setLinkUrl(value) {
      this.updateTokenContentProperty('linkUrl', value);
    },
  },
  //endregion
});
