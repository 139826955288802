/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { inject as controller } from '@ember/controller';
import { computed, set, setProperties, get } from '@ember/object';
import { alias, readOnly } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

/**
 * Message Campaign List Controller Mixin
 * @type {Ember.Mixin}
 */
export default Mixin.create({
  snackbar: service(),

  //region Ember Hooks
  organizationController: controller('organizations.organization'),
  queryParams: [
    'statusTypeId',
    'pageIndex',
    'sortColumn',
    'sortDirection',
    'getCurrent',
    'isComplete',
    'searchValue',
    'messageCampaignTypeId',
  ],
  //endregion

  //region Properties
  statusTypeId: null,
  isSettingStatus: false,
  //endregion

  //region Computed Properties
  messageStatusChangedMessage: computed('archived', function () {
    return this.archived
      ? 'The message campaign has been made active.'
      : 'Message campaign archived. Phew, it always feels nice to tidy up a bit.';
  }),
  hasMessaging: readOnly('organizationController.model.organization.hasMessaging'),
  organization: alias('organizationController.model.organization'),
  pageIndex: 1,
  sortColumn: null,
  sortDirection: null,
  getCurrent: true,
  isComplete: false,
  searchValue: null,
  messageCampaignTypeId: null,

  actions: {
    onPageChange(page) {
      set(this, 'pageIndex', page);
    },

    onApplyFilters({ sortColumn, sortDirection, searchValue, selectedTypeId }) {
      setProperties(this, {
        sortColumn,
        sortDirection,
        searchValue,
        messageCampaignTypeId: selectedTypeId,
        pageIndex: 1,
      });
    },

    async setMessageStatus(messageCampaign, statusTypeId) {
      const previousStatusTypeId = get(messageCampaign, 'statusTypeId');
      set(this, 'isSettingStatus', true);
      set(messageCampaign, 'statusTypeId', statusTypeId);
      await messageCampaign.save();
      set(this, 'isSettingStatus', false);
      this.snackbar.show(get(this, 'messageStatusChangedMessage'), 'Undo').then(async () => {
        set(this, 'isSettingStatus', true);
        set(messageCampaign, 'statusTypeId', previousStatusTypeId);
        await messageCampaign.save();
        set(this, 'isSettingStatus', false);
      });
    },
  },
  //endregion
});
