/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import MessageCampaignListController from 'partner/mixins/message-campaign-list-controller';
import { computed } from '@ember/object';

/**
 * Campaigns Archived Controller
 * /o/:organization_id/message-campaigns/archived
 * @type {Ember.Controller}
 * @mixes MessageCampaignListController
 */
export default Controller.extend(MessageCampaignListController, {
  //region Properties
  visibleWhen: 'organizations.organization.message-campaigns.archived',
  archived: true,
  statusTypeId: 2,
  getCurrent: null,
  isComplete: null,

  emptyMessage: computed('getCurrent', 'isComplete', 'searchValue', 'messageCampaignTypeId', function () {
    if (this.searchValue || this.messageCampaignTypeId) {
      return {
        header: 'No Results Found.',
        subheader: 'Try changing your search criteria',
      };
    }

    return {
      header: "You haven't archived any emails yet.",
    };
  }),
  //endregion
});
