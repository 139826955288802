import { AsyncBelongsTo, attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';
import type PartnerUserSecurityDetailModel from './partner-user-security-detail';

export default class PartnerUserModel extends BaseModel {
  @attr('string') declare emailAddress: string;
  @attr('string') declare firstName: string;
  @attr('string') declare lastName: string;
  @attr('string') declare businessPhone: string;
  @attr('string') declare primaryRole: string;
  @attr('string') declare organizationDetailsSummary: string;
  @attr('string') declare organizationDetailsMeta: string;
  @attr('boolean') declare isEditable: boolean;
  @attr('boolean') declare hasAdminUserCreationRole: boolean;
  @attr('boolean') declare isSecondStreetAdmin: boolean;
  @attr('boolean') declare hasDiscoverAccess: boolean;
  @attr('boolean') declare migratedToUcm: boolean;

  @belongsTo('partner-user-security-detail')
  declare partnerUserSecurityDetail: AsyncBelongsTo<PartnerUserSecurityDetailModel>;
  @hasMany('organization', { async: false }) declare organizations: SyncHasMany<any>;

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get autocompleteSearch() {
    return `${this.firstName} ${this.lastName} ${this.emailAddress}`;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'partner-user': PartnerUserModel;
  }
}
