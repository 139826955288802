import BaseRoute from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/forms/base';
import type Form from 'partner/models/form';

export interface RegistrationFormModel {
  form: Form;
  organizationRegistrationFormSetting?: {
    value: number;
  };
}

export default class RegistrationFormRoute extends BaseRoute {
  setupController(controller: any, model: RegistrationFormModel, transition: any) {
    super.setupController(controller, model, transition);

    controller.selectedSource =
      model.organizationRegistrationFormSetting !== undefined
        ? controller.sourceOptions[model.organizationRegistrationFormSetting.value]
        : controller.sourceOptions[0];
  }
}

declare module '@ember/routing/route' {
  interface Registry {
    'organizations.organization.organization-promotions.organization-promotion.setup.forms.form': RegistrationFormRoute;
  }
}
