/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed, set, setProperties } from '@ember/object';
import { filterBy, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';

const AUDIENCE_EMAIL_ROUTE = 'organizations.organization.data.audiences.audience.setup.email';
const PROMOTION_EMAILS_ROUTE = 'organizations.organization.organization-promotions.organization-promotion.setup.emails';
const MESSAGE_CAMPAIGN_DESIGN_ROUTE =
  'organizations.organization.message-campaigns.message-campaign.setup.message-versions';

export default Controller.extend({
  //region Dependencies
  router: service(),
  store: service(),
  enums: service(),
  //endregion

  //region Ember Hooks
  queryParams: [
    'isSelectingTemplate',
    'isNewTemplate',
    'messageVersionId',
    'messageCampaignId',
    'organizationPromotionId',
    'audienceId',
    'messageCampaignTypeId',
  ],
  //endregion

  //region Properties
  isSavingTemplate: false,

  // Used to force using Template Builder with empty mjmlJson.
  isNewTemplate: false,
  isSelectingTemplate: false,
  messageVersionId: null,
  messageCampaignId: null,
  organizationPromotionId: null,
  audienceId: null,
  messageCampaignTypeId: null,
  //endregion

  //region Computed Properties
  useTemplateBuilder: or('model.template.mjmlJson', 'isNewTemplate'),

  /** Filters out templates not for use with Template Builder. */
  secondStreetTemplates: filterBy('model.secondStreetTemplates', 'mjmlJson'),

  dynamicTokenExclusionList: computed(() => [
    'System.BatchQueueID',
    'System.ReadTracking',
    'System.Stop',
    'Promotion.Name',
    'Promotion.NameEncoded',
    'Promotion.Url',
    'Promotion.UrlEncoded',
  ]),
  //endregion

  //region Methods
  transitionData() {
    const { audienceId } = this;
    const orgPromoId = this.organizationPromotionId;
    const { messageCampaignId } = this;
    const queryParams = { messageVersionId: this.messageVersionId };
    const result = { queryParams };

    if (audienceId) {
      result.link = AUDIENCE_EMAIL_ROUTE;
      result.dynamicSegment = audienceId;
    } else if (orgPromoId) {
      result.link = PROMOTION_EMAILS_ROUTE;
      result.dynamicSegment = orgPromoId;
    } else {
      result.link = MESSAGE_CAMPAIGN_DESIGN_ROUTE;
      result.dynamicSegment = messageCampaignId;
    }
    return result;
  },
  async saveTemplate(mjmlJson, hbs) {
    const { template } = this.model;
    setProperties(template, {
      mjmlJson,
      body: hbs,
    });

    set(this, 'isNewTemplate', false);
    set(this, 'isSavingTemplate', true);
    try {
      await template.save();
    } finally {
      set(this, 'isSavingTemplate', false);
    }
  },
  //enderegion

  //region Actions
  actions: {
    updateAndSaveTemplate(mjmlJson, hbs) {
      this.saveTemplate(JSON.stringify(mjmlJson), hbs);
    },
    async doneSelectingTemplate(mjmlJson, hbs) {
      await this.saveTemplate(mjmlJson, hbs);
      set(this, 'isSelectingTemplate', false);
    },
    addNewToken(token) {
      this.model.tokens.addObject(token);
    },
    async saveToMessageVersionAndTransition(hasArticles) {
      const { link, dynamicSegment, queryParams } = this.transitionData();
      const { messageCampaignTypeId } = this;
      const messageCampaignType = this.enums.findWhere(
        'MESSAGE_CAMPAIGN_TYPE',
        { id: Number(messageCampaignTypeId) },
        'name'
      );

      // we do not want to save the new template to the message version you were editing if the template is not eligible for that campaign type
      // for example, if the new template does not have any place to put RSS Feeds, and you are coming from a Newsletter that requires its templates to accommodate RSS Feeds
      if (messageCampaignType !== 'Newsletter' || hasArticles) {
        const messageVersion = await this.store.findRecord('message-version', this.messageVersionId);
        set(messageVersion, 'template', this.model.template);
        await messageVersion.save();
      }

      this.router.transitionTo(link, dynamicSegment, { queryParams });
    },
  },
  //endregion
});
