/*
 * Constant (or "Magic") Values.
 * If you're adding much more than a single export to this file, you might be
 * better off creating/editing an enum value instead.
 */

export const NO_TEMPLATE_NAME = 'No Template';
export const UNSAVED_CHANGES_ALERT = 'Are you sure you want to leave? Your unsaved changes will be lost.';
export const FRIEND_REFERRAL_FIELD_ID = 539;
export const MAX_TOKEN_CONTENT_URL_LENGTH = 800;

// These should stay in-sync with the pixel values set in the @media-medium and @media-large SCSS mixins.
export const BREAKPOINTS = Object.freeze({
  MEDIUM: 640,
  LARGE: 960,
});

export const PROMO_TYPE_CATEGORY_ID_MAP = {
  contest: 1,
  interactiveContent: 2,
  event: 3,
  ecommerce: 4,
};

export const PROMO_TYPE_CATEGORY_ROUTE_MAP = {
  contest: 'organization-promotions',
  interactiveContent: 'interactive-content',
  event: 'events',
  ecommerce: 'ecommerce',
};

// An array of Dynamic Token-like objects used by Newsletters
// These are replaced by the template renderer with content from RSS Items
const ARTICLE_TITLE_TOKEN = 'sections.contents.[0].items.contents.[0].itemTitle';
const ARTICLE_DESCRIPTION_TOKEN = 'sections.contents.[0].items.contents.[0].value';
const ARTICLE_LENGTH_TOKEN = 'sections.contents.[0].items.contents.length';

export const ARTICLE_TOKENS = [
  {
    key: `{${ARTICLE_TITLE_TOKEN}}`,
    name: 'Article 1 Headline',
    token: `{{{${ARTICLE_TITLE_TOKEN}}}}`,
    id: -1,
    dynamicTokenType: 'Article',
  },
  {
    key: `{${ARTICLE_DESCRIPTION_TOKEN}}`,
    name: 'Article 1 Description',
    token: `{{{${ARTICLE_DESCRIPTION_TOKEN}}}}`,
    id: -2,
    dynamicTokenType: 'Article',
  },
  {
    key: ARTICLE_LENGTH_TOKEN,
    name: 'Article Count',
    token: `{{${ARTICLE_LENGTH_TOKEN}}}`,
    id: -3,
    dynamicTokenType: 'Article',
  },
];

export const EMAIL_DYNAMIC_TOKENS = ['MessageVersion', 'Organization', 'System', 'User'];
export const SETTINGS_ROUTES = [
  'organizations.organization.legal.design-templates.index',
  'organizations.organization.legal.design-tokens',
  'organizations.organization.legal.design-templates.design-template',
  'organizations.organization.forms.index',
  'organizations.organization.settings.index',
  'organizations.organization.forms.form',
  'organizations.organization.wrapper',
  'organizations.organization.email.index',
  'organizations.organization.email.loading',
  'organizations.organization.email.message-body-templates.index',
  'organizations.organization.email.tokens',
  'organizations.organization.email.token-fallbacks',
  'organizations.organization.promotions-feed.promotions',
  'organizations.organization.promotions-feed.configure',
  'organizations.organization.opt-out-preference-center',
  'organizations.organization.sender-accounts',
  'organizations.organization.feature-flags',
  'organizations.organization.notifications',
  'organizations.organization.licenses.license.cancel',
];
