import { ParamsType } from 'partner/services/preview';
export default (params?: ParamsType) => [
  {
    id: 'box-2',
    uniqueTypeNumber: '2',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'box-3',
        uniqueTypeNumber: '3',
        deletable: true,
        type: 'promotion-box',
        blocks: [
          ...(params?.isCodeword
            ? [
                {
                  id: 'box-4',
                  uniqueTypeNumber: '4',
                  deletable: true,
                  type: 'promotion-box',
                  blocks: [
                    {
                      id: 'box-5',
                      uniqueTypeNumber: '5',
                      deletable: true,
                      type: 'promotion-box',
                      blocks: [
                        {
                          id: 'heading-1',
                          uniqueTypeNumber: '1',
                          deletable: true,
                          type: 'promotion-heading',
                          blocks: [],
                          data: {
                            level: 5,
                            text: 'Codeword',
                            additionalCss:
                              '.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-1 {\n  color: var(--mdc-color-system-text, black);   \n  }\n  \n  ',
                          },
                          styles: {
                            font: {
                              color: '#000000',
                              weight: 600,
                              family: 'Open Sans',
                              size: '14',
                              height: '24',
                            },
                            size: {
                              custom: true,
                              height: '24',
                            },
                          },
                          dataMobile: {},
                          stylesMobile: {},
                          dataTablet: {},
                          stylesTablet: {},
                        },
                        {
                          id: 'heading-2',
                          uniqueTypeNumber: '2',
                          deletable: true,
                          type: 'promotion-heading',
                          blocks: [],
                          data: {
                            level: 5,
                            text: 'Required',
                            additionalCss:
                              '.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-2 {\n    margin-left: auto;\n color: var(--mdc-color-system-text, black);  }\n  \n  ',
                          },
                          styles: {
                            font: {
                              color: '#252b31',
                              weight: '',
                              family: 'Open Sans',
                              size: '12',
                              height: '16',
                              align: 'start',
                            },
                            size: {
                              custom: true,
                              height: '16',
                            },
                          },
                          dataMobile: {},
                          stylesMobile: {},
                          dataTablet: {},
                          stylesTablet: {},
                        },
                      ],
                      data: {
                        additionalCss:
                          '.arc-promotion-promotion-0 .arc-box-box-5 {\n    min-height: unset;\n  }\n  \n  ',
                      },
                      styles: {
                        layout: {
                          display: 'flex',
                          direction: 'row',
                          align: 'center',
                        },
                      },
                      dataMobile: {},
                      stylesMobile: {},
                      dataTablet: {},
                      stylesTablet: {},
                    },
                    {
                      id: 'box-6',
                      uniqueTypeNumber: '6',
                      deletable: true,
                      type: 'promotion-box',
                      blocks: [],
                      data: {
                        additionalCss: '.arc-promotion-promotion-0 .arc-box-box-6 {\n  flex-shrink: 0;   \n  }\n  \n  ',
                      },
                      styles: {
                        layout: {
                          display: 'flex',
                          direction: 'column',
                        },
                        background: {
                          type: 'color',
                          color: '#ffffff',
                          opacity: 100,
                        },
                        border: {
                          style: 'solid',
                          width: 1,
                          color: '#6b7786',
                          radius: '4',
                        },
                        size: {
                          custom: true,
                          height: '40',
                        },
                      },
                      dataMobile: {},
                      stylesMobile: {},
                      dataTablet: {},
                      stylesTablet: {},
                    },
                  ],
                  data: {
                    additionalCss: '.arc-promotion-promotion-0 .arc-box-box-4 {\n     \n  }\n  \n  ',
                  },
                  styles: {
                    layout: {
                      display: 'flex',
                      direction: 'column',
                      gapX: '4',
                      gapY: '4',
                    },
                  },
                  dataMobile: {},
                  stylesMobile: {},
                  dataTablet: {},
                  stylesTablet: {},
                },
              ]
            : []),
          {
            id: 'box-7',
            uniqueTypeNumber: '7',
            deletable: true,
            type: 'promotion-box',
            blocks: [
              {
                id: 'box-8',
                uniqueTypeNumber: '8',
                deletable: true,
                type: 'promotion-box',
                blocks: [
                  {
                    id: 'heading-3',
                    uniqueTypeNumber: '3',
                    deletable: true,
                    type: 'promotion-heading',
                    blocks: [],
                    data: {
                      level: 5,
                      text: 'Email',
                      additionalCss:
                        '.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-3 {\n  color: var(--mdc-color-system-text, black);   \n  }\n  \n  ',
                    },
                    styles: {
                      font: {
                        color: '#000000',
                        weight: 600,
                        family: 'Open Sans',
                        size: '14',
                        height: '24',
                      },
                      size: {
                        custom: true,
                        height: '24',
                      },
                    },
                    dataMobile: {},
                    stylesMobile: {},
                    dataTablet: {},
                    stylesTablet: {},
                  },
                  {
                    id: 'heading-4',
                    uniqueTypeNumber: '4',
                    deletable: true,
                    type: 'promotion-heading',
                    blocks: [],
                    data: {
                      level: 5,
                      text: 'Required',
                      additionalCss:
                        '.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-4 {\n    margin-left: auto; color: var(--mdc-color-system-text, black); \n  }\n  \n  \n  ',
                    },
                    styles: {
                      font: {
                        color: '#252b31',
                        weight: '',
                        family: 'Open Sans',
                        size: '12',
                        height: '16',
                        align: 'start',
                      },
                      size: {
                        custom: true,
                        height: '16',
                      },
                    },
                    dataMobile: {},
                    stylesMobile: {},
                    dataTablet: {},
                    stylesTablet: {},
                  },
                ],
                data: {
                  additionalCss: '.arc-promotion-promotion-0 .arc-box-box-8 {\n    min-height: unset;\n  }\n  \n  ',
                },
                styles: {
                  layout: {
                    display: 'flex',
                    direction: 'row',
                    align: 'center',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
              {
                id: 'box-9',
                uniqueTypeNumber: '9',
                deletable: true,
                type: 'promotion-box',
                blocks: [],
                data: {
                  additionalCss: '.arc-promotion-promotion-0 .arc-box-box-9 {\n  flex-shrink: 0;   \n  }\n  \n  ',
                },
                styles: {
                  layout: {
                    display: 'flex',
                    direction: 'column',
                  },
                  background: {
                    type: 'color',
                    color: '#ffffff',
                    opacity: 100,
                  },
                  border: {
                    style: 'solid',
                    width: 1,
                    color: '#6b7786',
                    radius: '4',
                  },
                  size: {
                    custom: true,
                    height: '40',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
            ],
            data: {
              additionalCss: '.arc-promotion-promotion-0 .arc-box-box-4 {\n     \n  }\n  \n  ',
            },
            styles: {
              layout: {
                display: 'flex',
                direction: 'column',
                gapX: '4',
                gapY: '4',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
        ],
        data: {
          additionalCss: '.arc-promotion-promotion-0 .arc-box-box-3 {\n     \n  }\n  \n  ',
        },
        styles: {
          layout: {
            display: 'flex',
            direction: 'column',
            gapX: '16',
            gapY: '16',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      {
        id: 'text-1',
        uniqueTypeNumber: '1',
        deletable: true,
        type: 'promotion-text',
        blocks: [],
        data: {
          text: '<p>By clicking the submit button below, I agree to the <u>Terms of Service</u> and <u>Privacy Policy</u></p>',
          additionalCss:
            '.arc-promotion-promotion-0 .arc-promotion-text-promotion-text-1 {\n  color: var(--mdc-color-system-text, black);   \n  }\n  \n  ',
        },
        styles: {
          font: {
            color: '#000000',
            weight: 400,
            family: 'Open Sans',
          },
          spacing: {
            marginBlock: '24',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      {
        id: 'button-1',
        uniqueTypeNumber: '1',
        deletable: true,
        type: 'promotion-button',
        blocks: [],
        classNames: 'template-color-primary-background-color',
        data: {
          href: '',
          text: 'CONTINUE<span class="material-icons">chevron_right</span>',
          target: '_blank',
          url: '',
          additionalCss: `.arc-promotion-button-promotion-button-1 span {\n     display: flex;\n     align-items: center;  }\n 
              .arc-promotion-button-promotion-button-1 span .material-icons { margin-left: -4px; }\n
            \n  `,
        },
        styles: {
          border: {
            radius: 5,
          },
          spacing: {
            paddingBlock: 10,
            paddingInline: 10,
          },
          background: {
            type: 'color',
            color: params?.promotionColor,
          },
          font: {
            color: params?.promotionTextColor,
            align: 'center',
            family: 'Open Sans',
            weight: 600,
            size: '14',
            height: '20',
            spacing: '1.25',
          },
          size: {
            custom: true,
            height: '40',
            width: '',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {},
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
      },
      spacing: {},
      border: {},
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  },
];
