import PermittedActionComponent from 'partner/components/permitted-action';

/**
 * To be used inside of a @see Promotion.PermissionsBlock.  This component will only be visible
 * if the block component has administer rights.
 *
 * Example:
 *  <SsPermissionsViewOnly>
 *    //html for users with view only access (non admins)
 *  </SsPermissionsViewOnly>
 *
 *  <SsPermissionsAdminister>
 *    //html only for admins
 *  </SsPermissionsAdminister>
 *
 * @see Promotion.PermittedComponent
 * @see Promotion.PermittedActionComponent
 * @type {Ember.Component}
 */
export default class SsPermissionsAdminister extends PermittedActionComponent {}
