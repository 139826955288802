/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { user } from 'partner/utils/model-hooks';
import RSVP from 'rsvp';
import { DeveloperError } from 'secondstreet-common/utils/errors';
import { endpoint } from 'secondstreet-common/utils/url';

/**
 * Placeholder route that uses AuthenticatedRoute mixin to add authentication to all child routes
 */
export default Route.extend({
  //region Dependencies
  router: service(),
  snackbar: service(),
  session: service(),
  store: service(),
  //endregion

  //region Ember Hooks
  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');
  },

  model() {
    return RSVP.hash({
      passwordResetRequest: this.store.createRecord('passwordResetRequest'),
    });
  },
  //endregion

  //region Actions
  actions: {
    /**
     * Add one or more {@link Role}s to an {@link Organization} by creating a {@link PartnerUserOrganizationRole} and
     * removing all other existing {@link PartnerUserOrganizationRole}s for the given {@link Organization}.
     * @param {Organization} organization
     * @param {Role[]} roles
     */
    addOrganizationPartnerUserRoles(organization, roles, partnerUser, puors) {
      let puor;
      get(partnerUser, 'organizations').addObject(organization);
      partnerUser.forceDirty();

      puor = puors.findBy('organization', organization);
      if (!puor) {
        puor = this.store.createRecord('partner-user-organization-role', {
          partnerUser,
          organization,
          roles,
        });
        puors.addObject(puor);
      } else {
        get(puor, 'roles').clear();
        get(puor, 'roles').addObjects(roles);
        puor.forceDirty();
      }

      //remove all PUORs for all organization.childOrganizations
      if (get(organization, 'childOrganizations')) {
        get(organization, 'childOrganizations').forEach(x => this.send('removeOrganizationRoles', x));
      }
    },
    /**
     * Remove one or more {@link Role}s to an {@link Organization} by removing the matching {@link PartnerUserOrganizationRole}
     * @param {Organization} organization
     * @param {PartnerUserOrganizationRole[]} puors
     */
    removeOrganizationPartnerUserRoles(organization, puors) {
      const matchingPuor = puors.findBy('organization', organization);
      if (matchingPuor) {
        //Clear out all roles so that it becomes part of the unneededPartnerUserOrganizationRoles when saving
        get(matchingPuor, 'roles').clear();
        get(matchingPuor, 'partnerUser').then(pu => void pu.forceDirty());
      }
    },
    /**
     * Add one or more {@link Role}s to a {@link PartnerUserOrganizationRole}
     * @param partnerUserOrganizationRole
     * @param partnerUser
     * @param {...roles} roles
     */
    addPartnerUserRoles(partnerUserOrganizationRole, partnerUser, ...roles) {
      get(partnerUserOrganizationRole, 'roles').then(puorRoles => {
        puorRoles.addObjects(roles);
      });
      get(partnerUser, 'organizations').addObject(get(partnerUserOrganizationRole, 'organization'));
      partnerUserOrganizationRole.forceDirty();
      partnerUser.forceDirty();
    },
    /**
     * Remove one or more {@link Role}s from a {@link PartnerUserOrganizationRole}
     * @param partnerUserOrganizationRole
     * @param {...roles} roles
     */
    removePartnerUserRoles(partnerUserOrganizationRole, ...roles) {
      get(partnerUserOrganizationRole, 'roles').removeObjects(roles);
      partnerUserOrganizationRole.forceDirty();
    },
    /**
     * Sets the sendTo property of the existing passwordResetRequest record and saves it,
     * then creates a new record and displays a toast if the save is successful or shows
     * the error in a toast if not
     * @param partnerUser - The partner user record who's password is being reset
     * @param model - The model that contains the passwordResetRequest we want
     */
    resetPassword(partnerUser, model) {
      set(this.modelFor(model), 'passwordResetRequest', this.store.createRecord('passwordResetRequest'));
      const passwordResetRequest = get(this.modelFor(model), 'passwordResetRequest');
      // if the partnerUser record has a dirty and unsaved change to the email address, use the last saved value
      const sendTo = isEmpty(partnerUser.changedAttributes()['emailAddress'])
        ? get(partnerUser, 'emailAddress')
        : partnerUser.changedAttributes()['emailAddress'][0];
      // are we already saving? then don't save again
      if (!get(passwordResetRequest, 'isSaving')) {
        set(passwordResetRequest, 'sendTo', sendTo);
        passwordResetRequest
          .save()
          .then(() => {
            set(this.modelFor(model), 'passwordResetRequest', this.store.createRecord('passwordResetRequest'));
            this.snackbar.show(`Password reset email sent to ${sendTo}`);
          })
          .catch(error => {
            // show the error related to the specific property we're using, sendTo
            this.snackbar.show(error.errors['sendTo'], '', 5000, 'error');
          });
      }
    },
    showError() {
      throw new DeveloperError('Not implemented');
    },
    async impersonate(partnerUser, target = 'index') {
      const userImpersonation = await this.store.createRecord('user-impersonation', { partnerUser }).save();
      const accessToken = get(userImpersonation, 'accessToken');
      if (!userImpersonation || isEmpty(accessToken)) {
        this.showError();
      } else {
        try {
          await this.session.authenticate('authenticator:second-street', { token: accessToken });
          //region HAX
          // We used to `store.unloadAll(); this.transitionTo('organizations.index');` here,
          // but an internal bug in Ember Data 2.12 was giving us trouble.
          this.router.transitionTo(target, { queryParams: { forceReload: true } });
          //endregion
        } catch (err) {
          this.showError();
        }
      }
    },
    async onChangePasswordForMigratedUser(partnerUser) {
      if (partnerUser.migratedToUcm) {
        try {
          const { emailAddress } = partnerUser;
          await this.session.request(
            endpoint('ucm_password_reset', {
              emailAddress: encodeURIComponent(emailAddress),
            }),
            {
              type: 'POST',
            }
          );
          this.snackbar.show(`Password reset email sent to ${emailAddress}`);
        } catch (e) {
          this.snackbar.exception(e);
        }
      }
    },
    savePartnerUser(model) {
      set(model, 'isAnythingSaving', true);
      const partnerUser = get(model, 'partnerUser');
      const partnerUserInvite = get(model, 'partnerUserInvite');

      // To remove all roles for an Organization, `PUT` to `/partner_users/:id` with fewer Organizations, then
      // unload those PartnerUserOrganizationRoles from the store.
      const unneededPartnerUserOrganizationRoles = get(model, 'partnerUserOrganizationRoles').filterBy(
        'roles.length',
        0
      );
      unneededPartnerUserOrganizationRoles.forEach(partnerUserOrganizationRole => {
        get(partnerUser, 'organizations').removeObject(get(partnerUserOrganizationRole, 'organization'));
        partnerUser.forceDirty();
      });

      (partnerUser.isDirty ? partnerUser.save() : RSVP.resolve(partnerUser))
        .then(
          partnerUser => {
            unneededPartnerUserOrganizationRoles.forEach(x => this.store.unloadRecord(x));
            get(model, 'partnerUserOrganizationRoles').removeObjects(unneededPartnerUserOrganizationRoles);
            return partnerUser;
          },
          err => {
            if (err.errors.any(x => x.source.pointer === '/data/attributes/organizations')) {
              if (get(partnerUser, 'isNew')) {
                this.snackbar.show('Active admin users must have at least one role.', null, 5000, 'error');
              } else {
                this.snackbar
                  .show('Active admin users must have at least one role.', 'Delete User', 5000, 'error')
                  .then(() => {
                    this.controller.send('tryDeleteUser');
                  });
              }
            }
            throw err;
          }
        )
        .then(() =>
          RSVP.all(
            get(model, 'partnerUserOrganizationRoles')
              .filterBy('isDirty')
              .map(x => x.save())
          )
        )
        .then(() => {
          if (partnerUserInvite) {
            set(partnerUserInvite, 'sendTo', get(partnerUser, 'emailAddress'));
            partnerUserInvite
              .save()
              .then(() => {
                set(model, 'partnerUserInvite', this.store.createRecord('partnerUserInvite'));
              })
              .then(() => user({ user_id: get(model, 'partnerUser.id') }, this))
              .then(model => {
                // to show the snackbar before the transition to avoid it being laggy
                this.snackbar.show('An invitation email is on its way!', 'ADD ANOTHER USER').then(() => {
                  this.router.transitionTo('users.new');
                });
                // after that transition to the user.user route
                // to avoid the model in routes user.user we pass in the model
                this.router.transitionTo('users.user', model);
              })
              .catch(error => {
                // show the error related to the specific property we're using, sendTo
                this.snackbar.show(error.errors['sendTo'], '', 5000, 'error');
              });
          }
        })
        .finally(() => set(model, 'isAnythingSaving', false));
    },
  },
  //endregion
});
