/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { computed, get } from '@ember/object';
import { alias, filter } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default Mixin.create({
  //region Ember Hooks
  enums: service(),
  defaultFormat: service('default-format'),

  classNames: ['winner-card'],
  //endregion

  //region Attributes
  winner: null,
  //endregion

  //region Computed Properties
  rank: computed('winner.rank', function () {
    if (this.winner) {
      return get(this, 'winner.rank');
    }
    return;
  }),
  organizationUserAttributeFieldValues: computed('winner.organizationUser.organizationUserAttributes', function () {
    const organizationUserAttributes = get(this, 'winner.organizationUser.organizationUserAttributes');
    if (organizationUserAttributes) {
      return get(this, 'winner.organizationUser.organizationUserAttributes')
        .map(organizationUserAttribute => {
          const fieldId = window.parseInt(get(organizationUserAttribute, 'userAttributeType.fieldId'), 10);
          if (fieldId) {
            return {
              value: get(organizationUserAttribute, 'value'),
              fieldId,
            };
          }
          return { value: null, fieldId: null };
        })
        .compact();
    }
    return [];
  }),
  customFormFields: filter('registration-form-fields', formField => {
    const fieldName = get(formField, 'field.name');
    return (
      !get(formField, 'field.isEmailTextbox') &&
      !get(formField, 'field.isDisplayText') &&
      !get(formField, 'field.isDisplayNumber') &&
      !get(formField, 'field.isDisplayMoney') &&
      !get(formField, 'field.isDisplayDate') &&
      !get(formField, 'field.isDisplayBoolean') &&
      !get(formField, 'field.isPassword') &&
      !get(formField, 'field.isFacebookLikeApi') &&
      !get(formField, 'field.isTwitterFollowApi') &&
      !get(formField, 'field.isTwitterTweetApi') &&
      fieldName !== 'Full Name' &&
      fieldName !== 'First Name' &&
      fieldName !== 'Middle Name' &&
      fieldName !== 'Middle Initial' &&
      fieldName !== 'Last Name' &&
      fieldName !== 'Full Address' &&
      fieldName !== 'Address 1' &&
      fieldName !== 'Address 2' &&
      fieldName !== 'City' &&
      fieldName !== 'State' &&
      fieldName !== 'Postal Code' &&
      fieldName !== 'Country' &&
      fieldName !== 'Birthdate' &&
      fieldName !== 'Birthdate Day' &&
      fieldName !== 'Birthdate Month' &&
      fieldName !== 'Birthdate Year' &&
      fieldName !== 'Phone' &&
      fieldName !== 'Gender'
    );
  }),
  customFormFieldValues: computed(
    'customFormFields',
    'organizationUserAttributeFieldValues',
    'winner.matchupEntry.entry.entryFieldValues',
    function () {
      return this.customFormFields.map(formField => {
        const fieldId = parseInt(get(formField, 'field.id'), 10);
        const labelText = get(formField, 'labelText');
        const fieldType = get(formField, 'field.fieldType');
        const fieldOptionType = get(formField, 'field.fieldOptionType');
        const orgUserFieldValue = this.organizationUserAttributeFieldValues.findBy('fieldId', fieldId);
        let value = orgUserFieldValue ? get(orgUserFieldValue, 'value') : null;
        if (fieldOptionType === 'CustomFieldOption') {
          const fieldOption = get(formField, 'field.fieldOptions').findBy('id', value);
          value = fieldOption ? get(fieldOption, 'name') : value;
        } else if (fieldOptionType === 'NoOptions' && !value) {
          const entryFieldValues = get(this, 'winner.matchupEntry.entry.entryFieldValues') || [];
          const entryFieldValue = entryFieldValues.findBy('fieldId', fieldId);
          value = entryFieldValue ? get(entryFieldValue, 'value') : value;
        }
        return { fieldId, labelText, fieldType, fieldOptionType, value };
      });
    }
  ),
  username: alias('winner.organizationUser.username'),
  emailAddress: alias('winner.organizationUser.emailAddress'),
  fullName: alias('winner.organizationUser.name'),
  address: alias('winner.organizationUser.addressSummary'),
  ageText: computed('winner.organizationUser.{age,birthDate}', function () {
    const age = get(this, 'winner.organizationUser.age');
    const birthDate = get(this, 'winner.organizationUser.birthDate');
    if (age) {
      return `${age} Years Old (${moment(birthDate).format(this.defaultFormat.getDateFormat())})`;
    }
    return null;
  }),
  phone: computed('organizationUserAttributeFieldValues', function () {
    const fieldId = this.enums.findWhere('FIELD', { name: 'Phone' });
    const orgUserFieldValue = this.organizationUserAttributeFieldValues.findBy('fieldId', fieldId);
    return orgUserFieldValue ? get(orgUserFieldValue, 'value') : null;
  }),
  //endregion
});
