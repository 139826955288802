/* eslint-disable ember/use-ember-data-rfc-395-imports */
import { A } from '@ember/array';
import { get } from '@ember/object';
import DS from 'ember-data';
import ApplicationSerializer from 'partner/serializers/application';

export default ApplicationSerializer.extend(DS.EmbeddedRecordsMixin, {
  // This is taken straight from DS.EmbeddedRecordsMixin, except that one line is changed to reject empty records.
  _generateSerializedHasMany(snapshot, relationship) {
    const hasMany = snapshot.hasMany(relationship.key);
    const manyArray = A(hasMany).reject(x => get(x, 'record.isEmpty')); // THIS LINE WAS CHANGED
    const ret = new Array(manyArray.length);

    for (let i = 0; i < manyArray.length; i++) {
      const embeddedSnapshot = manyArray[i];
      const embeddedJson = embeddedSnapshot.serialize({ includeId: true });
      this.removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, embeddedJson);
      ret[i] = embeddedJson;
    }

    return ret;
  },
  attrs: {
    formFields: { embedded: 'always' },
  },
});
