import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import type MatchupModel from 'partner/models/matchup';

export default class SetupBallotMatchupsNewController extends Controller {
  @tracked matchup?: MatchupModel;
}

declare module '@ember/controller' {
  interface Registry {
    'organizations.organization.organization-promotions.organization-promotion.setup.ballot.matchups.new': SetupBallotMatchupsNewController;
  }
}
