import { action } from '@ember/object';
import { inject as service } from '@ember/service';
// ModerateEntryCard is being used just for this. Remove if this gets deleted
import ModerateEntryCard from './moderate-entry-card';
import { tracked } from '@glimmer/tracking';

export default class ModerateUgcEntryCardComponent extends ModerateEntryCard {
  @service current;
  @service store;

  @tracked showUploadImage = false;

  //region Ember Hooks
  classNames = ['moderate-ugc-entry-card', 'group'];
  //endregion

  //region Properties
  isMatchupEntryCard = false;

  get isPending() {
    return !this['matchup-entry'].isActive && !this['matchup-entry'].isRejected;
  }

  get downloadLink() {
    return `//${this['dips-url']?.value}/${this['matchup-entry']?.photoUpload?.value}?download=true`;
  }

  @action
  handleImageChange({ mediaItem }) {
    this.showUploadImage = false;
    this.addMediaItem(mediaItem);
  }

  //region Actions
  @action
  async viewOnSite() {
    window
      .open(`${this.current.organizationPromotion?.consumerUrl}#/gallery/${this['matchup-entry'].id}`, '_blank')
      .focus();
  }

  @action
  setMatchupEntryName({ target: { value } }) {
    this['matchup-entry'].mediaTitle.value = value;
  }

  @action
  cancel() {
    this.mediaItemTransform?.rollbackAttributes();
    this['matchup-entry'].entries.entryFieldValues.map(x => x.rollbackAttributes());
  }

  //region Actions
  @action
  handleEntryFieldValueChange(entryFieldValue, { value, fieldOption, ...rest }) {
    if (!entryFieldValue) {
      return this.createEntryFieldValueRecord({ value, fieldOption, ...rest });
    } else if (fieldOption && entryFieldValue.fieldOption !== fieldOption) {
      // Just another quirk in the backend
      // It looks like you cannot update the field option of the value
      // that's why we need to delete it and create a new one
      entryFieldValue.value = null;

      return this.createEntryFieldValueRecord({ value, fieldOption, ...rest });
    }

    entryFieldValue.value = value;
  }
  //endregion

  //region Methods
  createEntryFieldValueRecord(attrs) {
    return this.store.createRecord('entry-field-value', {
      entry: this['matchup-entry'].entry,
      matchup: this['matchup-entry'].matchup,
      ...attrs,
    });
  }
  //endregion
}
