/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import isAnyPath from 'partner/utils/is-any-path';

export default class OrganizationsOrganizationFeatureFlagsController extends Controller {
  @service store;

  //region Properties
  @tracked editedOrganizationAttributes = [];
  @tracked searchInput = '';
  @tracked searchTerm = '';
  @tracked selectedOrganizationAttributeType = null;
  @tracked newOrganizationAttributeType = null;
  //endregion

  //region Computed Properties
  @isAnyPath('hasDirtyAttributes', ['editedOrganizationAttributes.[]', 'newOrganizationAttributeType'])
  isAnythingDirty;

  @isAnyPath('isSaving', ['editedOrganizationAttributes.[]', 'newOrganizationAttributeType'])
  isAnythingSaving;

  @computed('model.organizations.@each.name', 'searchTerm')
  get filteredOrganizations() {
    if (this.searchTerm && this.searchTerm.length >= 2) {
      return this.model.organizations
        .filter(org => org.name.toLowerCase().indexOf(this.searchTerm.trim().toLocaleLowerCase()) >= 0)
        .sortBy('name');
    }
    return null;
  }

  @computed('selectedOrganizationAttributeType', 'model.organizationAttributes.@each.value')
  get excludedOrganizations() {
    const selectedOrganizationAttributeTypeId = parseInt(this.selectedOrganizationAttributeType.id, 10);
    const defaultValue = this.selectedOrganizationAttributeType.defaultValue === 'True';
    return this.model.organizationAttributes
      .filter(x => x.organizationAttributeTypeId === selectedOrganizationAttributeTypeId && x.value !== defaultValue)
      .map(x => x.organization);
  }
  //endregion

  //region Methods
  updateSearch() {
    this.searchTerm = this.searchInput;
  }
  resetValues() {
    this.searchInput = null;
    this.searchTerm = null;
    this.selectedOrganizationAttributeType = null;
    this.newOrganizationAttributeType = null;
    if (isPresent(this.editedOrganizationAttributes)) {
      this.editedOrganizationAttributes.forEach(att => {
        att.rollbackAttributes();
      });
    }
    this.editedOrganizationAttributes = [];
  }
  //endregion

  //region Actions
  @action
  createNewType() {
    this.newOrganizationAttributeType = this.store.createRecord('organizationAttributeType', {
      valueDataTypeId: 6,
      isApplicationFeature: true,
    });
  }

  @action
  editExceptions(organizationAttributeType) {
    this.selectedOrganizationAttributeType = organizationAttributeType;
  }

  @action
  async save() {
    if (this.newOrganizationAttributeType) {
      await this.newOrganizationAttributeType.save();
      await this.store
        .createRecord('organizationAttribute', {
          organization: this.model.rootOrganization,
          organizationAttributeTypeId: this.newOrganizationAttributeType.id,
          value: this.newOrganizationAttributeType.defaultValue,
        })
        .save();
    } else {
      await Promise.all(this.editedOrganizationAttributes.filterBy('hasDirtyAttributes').map(att => att.save()));
    }
    this.resetValues();
    this.send('refreshModel');
  }

  @action
  cancel() {
    this.resetValues();
  }

  @action
  toggleExcluded(organization) {
    //find the matching orgAtt
    const selectedOrganizationAttributeTypeId = parseInt(this.selectedOrganizationAttributeType.id, 10);
    let attribute = organization.organizationAttributes.findBy(
      'organizationAttributeTypeId',
      selectedOrganizationAttributeTypeId
    );

    //if not found create one and set value to opposite of default
    if (!attribute) {
      attribute = this.store.createRecord('organizationAttribute', {
        organization,
        organizationAttributeTypeId: selectedOrganizationAttributeTypeId,
        value: this.selectedOrganizationAttributeType.defaultValue === 'False',
      });
      this.model.organizationAttributes.addObject(attribute);
    } else {
      set(attribute, 'value', !attribute.value);
    }

    this.editedOrganizationAttributes.addObject(attribute);
  }

  @action
  updateSearchInput({ target: { value } }) {
    this.searchInput = value;
    debounce(this, this.updateSearch, 250);
  }
  //endregion
}
