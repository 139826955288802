/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';

/**
 * QuestionOption Details Component
 * @type {Ember.View}
 */
export default Component.extend({
  //region Ember Hooks
  didRender() {
    this._super(...arguments);
    //region HAX
    // Should not muck about with parent styles
    this.element.parentElement.style.marginBottom = `calc(4em + ${this.element.offsetHeight}px)`;
    //endregion
  },
  willDestroyElement() {
    this._super(...arguments);
    this.element.parentElement.style.marginBottom = `3%`;
  },
  //endregion
});
