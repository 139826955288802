import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class MessageCampaignsAbTestStaticsComponent extends Component {
  @service('enums') enums;

  get showOpenRateChart() {
    return (
      this.enums.findWhere(
        'MESSAGE_TEST_WINNING_CRITERIA',
        { id: this.args.messageCampaign.messageTestWinningCriteriaId },
        'name'
      ) === 'OpenRate'
    );
  }
}
