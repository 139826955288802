import { underscore } from '@ember/string';
import { pluralize } from 'ember-inflector';

import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
  //region Ember Hooks
  pathForType(type) {
    return `audience_${pluralize(underscore(type))}`;
  },
  //end region
});
