/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';

const idsByPeriodsOfTime = {
  'hours before': 10,
  'on the day before': 11,
  'on the day of': 12,
};

const periodsOfTimeByIds = {
  10: 'hours before',
  11: 'on the day before',
  12: 'on the day of',
};

const messageVersionFeedPropertyMap = {
  altText: 'sectionImageAltText',
  mediaItemId: 'sectionMediaItemId',
  linkUrl: 'sectionImageLinkUrl',
  value: 'sectionImageExternalSrcUrl',
};

const DEFAULT_RSS_IMAGE_SRC = 'http://media.secondstreetapp.com/77028?width=1200';
const DEFAULT_RSS_IMAGE_LINK = '';
const DEFAULT_RSS_IMAGE_ALT_TEXT = '';
const DEFAULT_RSS_BUTTON_TEXT = 'Read more';

export default Component.extend({
  //region Ember Hooks
  classNames: 'article-token-contents',
  enums: service(),
  features: service(),
  didReceiveAttrs() {
    this._super(...arguments);
    set(this, 'numberOfArticles', this.articleQuantity || 5);
    set(this, 'numberOfHours', this.timeQuantity || 24);
    set(this, 'selectedPeriod', periodsOfTimeByIds[this.quantityTypeId] || 'hours before');
  },
  // endregion

  //region Attributes
  /**
   * RSS Feed URL - Required
   * @type {String}
   */
  feedUrl: null,
  /**
   * @type {Number}
   */
  quantityTypeId: null,
  /**
   * @type {?Number}
   */
  articleQuantity: null,
  /**
   * @type {?Number}
   */
  timeQuantity: null,
  /**
   * @type {Boolean}
   */
  isHeadlineEnabled: false,
  /**
   * @type {Boolean}
   */
  isImageEnabled: false,
  /**
   * @type {Boolean}
   */
  isDescriptionEnabled: false,
  /**
   * @type {Boolean}
   */
  isPublishDateEnabled: false,
  /**
   * @type {Boolean}
   */
  isButtonEnabled: false,
  /**
   * Required if isButtonEnabled is true
   * @type {?String}
   */
  buttonText: null,
  /**
   * @type {?String}
   */
  sectionHeaderText: null,
  /**
   * @type {?Number}
   */
  sectionMediaItemId: null,
  /**
   * @type {?String}
   */
  sectionImageExternalSrcUrl: null,
  /**
   * @type {?String}
   */
  sectionImageLinkUrl: null,
  /**
   * @type {?String}
   */
  sectionImageAltText: null,
  /**
   * @type {DS.Errors<Object>}
   */
  errors: null,
  /**
   * Currently expanded category
   * @type {?String}
   */
  activeFeedCategory: null,
  /**
   * @type {Boolean}
   */
  isSectionHeaderEnabled: false,
  /**
   * Value in the number input field in the second messaging option
   * Value will be passed on to articleQuantity only if second option is selected
   * @type {Number}
   */
  numberOfArticles: null,
  /**
   * Value in the number input field in the third messaging option
   * Value will be passed on to timeQuantity only if third option is selected
   * @type {Number}
   */
  numberOfHours: null,
  /**
   * Value in the selection menu in the third messaging option
   * Corresponding ID will be passed on to quantityTypeId only if third option is selected
   * @property {String}
   */
  selectedPeriod: null,
  /**
   * @type {String}
   */
  dipsUrl: null,
  /**
   * @type {Function}
   */
  openImageLibrary() {},
  /**
   * @property {Number}
   */
  articleLimit: null,
  /**
   * @property {String}
   */
  articleContentType: null,
  /**
   * @property {Boolean}
   */
  editingDisabled: null,
  /**
   * @property {Boolean}
   */
  useFeedDisplayOrder: false,
  /**
   * Potentially unschedules the Newsletter if the feed item display order is changed
   * @property {Function}
   */
  unscheduleNewsletter() {},
  /**
   * @property {Function}
   */
  updateMessageVersionFeedProperty() {},
  /**
   * @property {Function}
   */
  saveMessageVersionFeed() {},
  /**
   * @property {Function}
   */
  saveMessageVersionFeedUrl() {},
  // endregion

  //region Computed Properties
  /**
   * An array of options from the enum for the description
   * @property {Ember.ComputedProperty<String[]>}
   */
  descriptionOptions: computed('enums.data', function () {
    return this.enums.data.ARTICLE_CONTENT_TYPE.sortBy('id').mapBy('name');
  }),
  /**
   * An array of attributes with validation errors
   * @param errors
   * @property {Ember.ComputedProperty<Boolean>}
   */
  attributesWithErrors: computed('errors.content.[]', function () {
    return this.errors && this.errors.content ? this.errors.content.mapBy('attribute') : [];
  }),
  /**
   * True if the feed url is missing
   * @param errors
   * @property {Ember.ComputedProperty<Boolean>}
   */
  contentHasErrors: computed('attributesWithErrors', function () {
    return this.attributesWithErrors ? this.attributesWithErrors.includes('feedUrl') : false;
  }),
  /**
   * True if the button element is enabled, but there is no button text
   * @param errors
   * @property {Ember.ComputedProperty<Boolean>}
   */
  layoutHasErrors: computed('attributesWithErrors', function () {
    return this.attributesWithErrors ? this.attributesWithErrors.includes('buttonText') : false;
  }),
  /**
   * Hardcoded values in the selection menu
   * @property {Ember.ComputedProperty<Array>}
   */
  timePeriodValues: computed(() => Object.keys(idsByPeriodsOfTime)),
  /**
   * True if the option to send articles within a span of time (hours before, day of, or day before) is selected
   * @param id
   * @property {Ember.ComputedProperty<Boolean>}
   */
  periodOfTimeBeforeSendIsSet: computed('quantityTypeId', function () {
    return [10, 11, 12].includes(this.quantityTypeId);
  }),
  /**
   * True if the footer image is enabled
   * @param altText
   * @param link
   * @param source
   * @param mediaItemId
   * @property {Ember.ComputedProperty<Boolean>}
   */
  footerHasValues: computed(
    'sectionImageAltText',
    'sectionImageLinkUrl',
    'sectionImageExternalSrcUrl',
    'sectionMediaItemId',
    function () {
      return Boolean(
        this.sectionImageAltText ||
          this.sectionImageLinkUrl ||
          this.sectionImageExternalSrcUrl ||
          this.sectionMediaItemId
      );
    }
  ),
  /**
   * @returns {Number|null}
   * @param hours
   * @param timePeriod
   */
  currentTimeQuantity: computed('numberOfHours', 'selectedPeriod', function () {
    return this.selectedPeriod === 'hours before' ? this.numberOfHours : null;
  }),
  /**
   * @returns {Number}
   * @param timePeriod
   */
  timePeriodId: computed('selectedPeriod', function () {
    return idsByPeriodsOfTime[this.selectedPeriod];
  }),
  //endregion

  // region Actions
  actions: {
    setActiveCategory(category) {
      if (this.activeFeedCategory === category) {
        set(this, 'activeFeedCategory', null);
      } else {
        set(this, 'activeFeedCategory', category);
      }
    },
    toggleFeedArticleElement(element, currentStatus) {
      const desiredStatus = !currentStatus;
      this.updateMessageVersionFeedProperty(`is${element}Enabled`, desiredStatus);

      if (element === 'Button') {
        this.updateMessageVersionFeedProperty('buttonText', desiredStatus ? DEFAULT_RSS_BUTTON_TEXT : null);
      }
    },
    toggleFooter() {
      if (this.footerHasValues) {
        this.updateMessageVersionFeedProperty('sectionImageExternalSrcUrl', null, false);
        this.updateMessageVersionFeedProperty('sectionImageLinkUrl', null, false);
        this.updateMessageVersionFeedProperty('sectionImageAltText', null, false);
        this.updateMessageVersionFeedProperty('sectionMediaItemId', null);
        set(this, 'activeFeedCategory', null);
      } else {
        this.updateMessageVersionFeedProperty('sectionImageExternalSrcUrl', DEFAULT_RSS_IMAGE_SRC, false);
        this.updateMessageVersionFeedProperty('sectionImageLinkUrl', DEFAULT_RSS_IMAGE_LINK, false);
        this.updateMessageVersionFeedProperty('sectionImageAltText', DEFAULT_RSS_IMAGE_ALT_TEXT);
        set(this, 'activeFeedCategory', 'footer');
      }
    },
    selectFeedType(id, articles, hours) {
      this.updateMessageVersionFeedProperty('messageVersionFeedArticleQuantityTypeId', id);
      this.updateMessageVersionFeedProperty('articleQuantity', articles);
      this.updateMessageVersionFeedProperty('timeQuantity', hours);
      this.saveMessageVersionFeed();
    },
    toggleSectionHeader() {
      if (this.isSectionHeaderEnabled) {
        this.updateMessageVersionFeedProperty('sectionHeaderText', null);
        set(this, 'isSectionHeaderEnabled', false);
      } else {
        set(this, 'isSectionHeaderEnabled', true);
      }
    },
    // Set the articleQuantity property directly, if the option to send a certain amount of articles is selected
    changeArticleQuantity() {
      const numberOfArticles = Math.min(this.articleLimit || Number.MAX_SAFE_INTEGER, this.numberOfArticles);
      this.updateMessageVersionFeedProperty('articleQuantity', numberOfArticles);
      this.saveMessageVersionFeed();
    },
    // Set the time-quantity property directly, if the option to send articles within a period of time is selected
    changeTimeQuantity() {
      this.updateMessageVersionFeedProperty('timeQuantity', this.currentTimeQuantity);
      this.saveMessageVersionFeed();
    },
    // Set the quantityTypeId directly, if the option to send articles within a period of time is selected
    changeTimePeriod(value) {
      set(this, 'selectedPeriod', value);
      this.updateMessageVersionFeedProperty(
        'messageVersionFeedArticleQuantityTypeId',
        idsByPeriodsOfTime[this.selectedPeriod]
      );
      this.updateMessageVersionFeedProperty('timeQuantity', this.currentTimeQuantity);
      this.saveMessageVersionFeed();
    },
    async feedOrderSelected({ target }) {
      const isOrderInFeed = target.children[target.selectedIndex].value === 'true';
      this.updateMessageVersionFeedProperty('useFeedDisplayOrder', isOrderInFeed);
      await this.unscheduleNewsletter(isOrderInFeed);
      if (isOrderInFeed) {
        this.updateMessageVersionFeedProperty('messageVersionFeedArticleQuantityTypeId', 2);
        this.updateMessageVersionFeedProperty('articleQuantity', this.numberOfArticles);
        this.updateMessageVersionFeedProperty('timeQuantity', null);
      }
      this.saveMessageVersionFeed();
    },
    updateMessageVersionFeedProperty(property, value) {
      this.updateMessageVersionFeedProperty(messageVersionFeedPropertyMap[property], value);
    },
    setFeedUrl(value) {
      this.updateMessageVersionFeedProperty('feedUrl', value);
      this.saveMessageVersionFeedUrl();
    },
    setFeedDescription(value) {
      this.updateMessageVersionFeedProperty('articleContentType', value);

      if (value === 'Full Article') {
        this.updateMessageVersionFeedProperty('isImageEnabled', false);
      }

      if (value === 'Off') {
        this.updateMessageVersionFeedProperty('isDescriptionEnabled', false);
      } else {
        this.updateMessageVersionFeedProperty('isDescriptionEnabled', true);
      }

      this.saveMessageVersionFeed();
    },
  },
  // endregion
});
