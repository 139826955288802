/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { computed, get } from '@ember/object';
import BaseComponent from 'partner/components/winners/base-component';
import TickTock from 'partner/mixins/tick-tock';
import mostCurrentMatchup from 'partner/utils/most-current-matchup';

export default BaseComponent.extend(TickTock, {
  //region Properties
  isShowingNonWinningEntrants: false,
  //endregion

  // region Computed Properties
  votingHasEnded: computed('model.matchups.@each.{endDate,selectionEndDate}', 'tock', function () {
    const matchup = mostCurrentMatchup(get(this, 'model.matchups'));
    return get(matchup, 'selectionEndDate') < this.tock;
  }),

  tiedWinners: computed('winners.@each.points', function () {
    const { winners } = this;
    return winners.filter(x => {
      const instances = winners.filterBy('points', get(x, 'points'));
      return instances.length > 1;
    });
  }),
  // endregion
});
