/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller, { inject as controller } from '@ember/controller';
import { alias } from '@ember/object/computed';
import SetupQuizController from '../quiz';

export default class SetupQuizIndexController extends Controller {
  @controller('organizations.organization.organization-promotions.organization-promotion.setup.quiz')
  declare quiz: SetupQuizController;

  @alias('quiz.outcomesStatus') declare outcomesStatus: SetupQuizController['outcomesStatus'];
  @alias('quiz.questionsStatus') declare questionsStatus: SetupQuizController['questionsStatus'];
}
