import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class LoginChooseOrganization extends Route {
  @service router;
  @service session;
  @service store;

  beforeModel() {
    if (get(this, 'session.data.authenticated.organizations_count') === 1) {
      this.router.replaceWith('organizations.organization', get(this, 'session.data.authenticated.organization_id'));
    } else {
      this.session.removeCustomHeader('X-Organization-Id');
    }
  }

  async model() {
    const flatOrganizations = await this.store.findAll('organization', {
      adapterOptions: {
        force: true,
      },
    });

    return {
      organizations: flatOrganizations.filterBy('tier', 1),
      userName: `${get(this, 'session.data.authenticated.organization_users.first_name')} ${get(
        this,
        'session.data.authenticated.organization_users.last_name'
      )}`,
    };
  }
}
