export default ({ promotionAd }) => `
<div class="ssBracket font-['Open_Sans']">
  <div class="liquid-container ember-view">
    <div class="liquid-child ember-view" style="top: 0px; left: 0px;">
      <div class="liquid-container ember-view">
        <div class="liquid-child ember-view" style="top: 0px; left: 0px;">
          <div class="bracket-header template-color-primary-background-color template-color-primary-background-color--darken">

            <div class="bracket-header__nav-link">
              <a href="/embed/9eb8098d-f8d1-4ef9-a3cc-af2390931c2d/bracket" id="ember133" class="active ember-view"><i aria-hidden="true" class="ssIcon ssIcon-arrow-left"></i>&nbsp;Bracket</a>
            </div>
          </div>
          <div class="matchup-view" style="touch-action: pan-y; user-select: none;">
            <div class="matchup-menu__menu-contents ember-view matchup-menu__menu-contents--side-nav">
              <div role="button" class="matchup-menu__matchup-label matchup-menu__matchup-label--selected">
                <div>
                  <div class="matchup-menu__menu-entrant ">
                    <div class="matchup-menu__menu-entrant-label" title="1">Entrant Name 1</div>
                  </div>
                  <div class="matchup-menu__menu-entrant ">
                    <div class="matchup-menu__menu-entrant-label" title="8">Entrant Name 2</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="matchup-view__entrants-wrapper">
            ${
              promotionAd
                ? `
              <div class="matchup-view__ad-bar" data-token="bracketAd">
                <div class="matchup-view__ad-label">Sponsored by</div>
                <div class="matchup-view__iteration-ads">
                  ${
                    promotionAd.isBannerAd
                      ? `
                    <div class="bracket-header__iteration-ad">
                      ${promotionAd.content}
                    </div>
                  `
                      : ''
                  }
                  ${
                    promotionAd.isProgrammaticAd
                      ? `
                  <div class="bracket-header__html-tags">
                    <div class="iframe-wrapper">
                      <iframe srcdoc='${promotionAd.content}' style="display: block; max-width: 95%; " scrolling="no" width="84" height="70"></iframe>
                    </div>
                  </div>
                `
                      : ''
                  }
                </div>
              </div>
            `
                : ''
            }
              <div class="matchup-view__liquid-thanks liquid-container ember-view">
                <div class="liquid-child ember-view" style="top: 0px; left: 0px;">
                  <div class="matchup-view__navigation">
                    <button class="matchup-view__navigation-button template-color-primary-color font-['Open_Sans']">
                      <i class="material-icons">keyboard_arrow_left</i>
                      Previous
                    </button>
                    <button class="matchup-view__navigation-button template-color-primary-color font-['Open_Sans']">
                      Next
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>
                  </div>
                  <div class="matchup-view__progress-container ">
                    <div class="matchup-view__matchup-count">Matchup 1 / 1</div>
                  </div>
                  <div class="matchup-view__round-status">
                  Vote starting mm/dd/yyyy @ h:mmpm
                  </div>
                  <div class="matchup-view__liquid-container liquid-container ember-view">
                    <div class="liquid-child ember-view" style="top: 0px; left: 0px;">
                      <div class="matchup-entrants">
                        <div class="matchup-entrants__image-container matchup-entrants__image-container-1">
                          <img src="https://media.secondstreetapp.com/250194?width=1170&amp;height=1170&amp;quality=60" alt="" class="square matchup-entrants__image" width="80">
                        </div>
                        <div class="matchup-entrants__name matchup-entrants__name-1">Entrant Name 1</div>
                        <div class="matchup-entrants__caption matchup-entrants__caption-1">Entrant description text will go here. Anything over 200 characters will truncate.</div>
                        <div class="matchup-entrants__vote matchup-entrants__vote-1">
                          <div class="matchup-entrants__vote-button-container">
                            <div class="vote-button">Vote</div>
                          </div>
                        </div>
                        <div class="matchup-entrants__image-container matchup-entrants__image-container-2">
                          <img src="https://media.secondstreetapp.com/250194?width=1170&amp;height=1170&amp;quality=60" alt="" class="square matchup-entrants__image" width="80">
                          <div class="matchup-entrants__image-overlay"></div>
                          <div class="matchup-entrants__vs-container">
                            <div class="matchup-entrants__vs template-color-primary-color">VS</div>
                          </div>
                        </div>
                        <div class="matchup-entrants__name matchup-entrants__name-2">Entrant Name 2</div>
                        <div class="matchup-entrants__caption matchup-entrants__caption-2">Entrant description text will go here. Anything over 200 characters will truncate.</div>
                        <div class="matchup-entrants__vote matchup-entrants__vote-2">
                          <div class="matchup-entrants__vote-button-container">
                            <div class="vote-button">Vote</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ssSharing">
    <ul class="ssShareListCompact ssShareList">
        <li class="ssShareListItem ssShareListItemFacebook">
          <a class="ssShareLink ssShareLinkFacebook" data-token="facebook" href="#">
            <i class="ssIcon-facebook"></i>
            <span class="ssShareLinkText">Share</span>
          </a>
        </li>
        <li class="ssShareListItem ssShareListItemTwitter">
          <a class="ssShareLink ssShareLinkTwitter" data-token="twitter" href="#">
            <i class="ssIcon-twitter"></i>
            <span class="ssShareLinkText">Tweet</span>
          </a>
        </li>
        <li class="ssShareListItem ssShareListItemEmail">
          <a class="ssShareLink ssShareLinkEmail" data-token="email" href="#">
            <i class="ssIcon-envelope"></i>
            <span class="ssShareLinkText">Email</span>
          </a>
        </li>
        <li class="ssShareListItem ssShareListItemPinterest">
          <a class="ssShareLink ssShareLinkPinterest" data-token="pinterest" href="#">
            <i class="ssIcon-pinterest"></i>
            <span class="ssShareLinkText">Pin It</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
`;
