import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type Store from '@ember-data/store';
import type CurrentService from 'partner/services/current';
import type { ModelFrom } from 'partner/utils/types';
import type OrganizationPromotionPaymentModel from 'partner/models/organization-promotion-payment';

export type Model = ModelFrom<PromotionSetupECommerceRoute>;

export default class PromotionSetupECommerceRoute extends Route {
  @service declare store: Store;
  @service declare current: CurrentService;

  async model() {
    const organizationPaymentProcessors = await this.store.query('organization-payment-processor', {});
    const organizationPromotionPayment: OrganizationPromotionPaymentModel = await this.store.queryRecord(
      'organization-promotion-payment',
      {}
    );
    const sweepstakes = await this.store.queryRecord('sweepstakes', {
      organizationId: this.current.organization.id,
      organizationPromotionId: this.current.organizationPromotion.id,
    });

    return {
      organizationPaymentProcessors,
      organizationPromotionPayment,
      sweepstakes,
    };
  }
}

declare module '@ember/routing/route' {
  interface Registry {
    'organizations.organization.organization-promotions.organization-promotion.setup.ecommerce': PromotionSetupECommerceRoute;
  }
}
