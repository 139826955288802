/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';

import WinnersCard from './winners-card';

export default WinnersCard.extend({
  // region Ember hooks
  classNames: ['voting-winners-card'],
  //endregion

  // region Computed Properties
  winner: computed('sortedWinners', 'currentPageIndex', function () {
    return this.sortedWinners.objectAt(this.currentPageIndex - 1);
  }),
  winningEntry: readOnly('winner.entry.matchupEntry'),
  //endregion
});
