/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import BaseModel from 'secondstreet-common/models/base';
import type MatchupModel from './matchup';
import { isPresent } from '@ember/utils';

export default class MatchupGroupModel extends BaseModel {
  @attr('string', { defaultValue: '' }) declare name: string;
  @attr('number') declare displayOrder: number;
  @attr('number') declare statusTypeId: number;
  @attr('number') declare gameId: number;

  @belongsTo('media-item', { async: false }) declare mediaItems: any;
  @hasMany('matchup', { async: false, inverse: 'matchupGroup' }) declare matchups: SyncHasMany<MatchupModel>;

  @alias('mediaItems') mediaItem: MatchupGroupModel['mediaItems'];
  @alias('matchups.length') matchupsCount?: number;

  @computed('matchups.[]')
  get categoryCount() {
    return this.matchups.toArray()?.length || 0;
  }

  get hasAds(): boolean {
    return isPresent(this.store.peekAll('sponsored-post').findBy('ownerEntityId', +this.id));
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'matchup-group': MatchupGroupModel;
  }
}
