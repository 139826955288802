/* eslint-disable ember/no-jquery */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import $ from 'jquery';

export default class QuestionOptionComponent extends Component {
  //region Ember Hooks
  @service store;
  //endregion

  get dataGuid() {
    return guidFor(this.args.questionOption);
  }

  get hasErrors() {
    return !this.args.questionOption.errors.isEmpty;
  }

  get isEditing() {
    return this.args.activeQuestionOption === this;
  }
  //endregion

  //region Methods
  updateWeights() {
    const weights = this.args.questionOption.questionOptionOutcomeWeights;
    const { outcomes } = this.args;

    // Get rid of any existing weights if their outcomes no longer exist.
    weights.filterBy('outcome', null).forEach(weight => weight.destroyRecord());
    weights.filterBy('outcome.isDeleted', true).forEach(weight => weight.destroyRecord());

    // Create new weights for any outcomes we don't already have weights for.
    weights.addObjects(
      (outcomes || [])
        .map(outcome => {
          const outcomeHasNoWeight = isEmpty(weights.filterBy('outcome.id', outcome.id));
          if (outcomeHasNoWeight) {
            return this.store.createRecord('questionOptionOutcomeWeight', {
              outcome,
              questionOption: this.args.questionOption,
            });
          }
          return null;
        })
        .compact()
    );
  }
  //endregion

  //region Actions
  @action
  addImage({ mediaItem }) {
    this.isAddImageFlyoutOpen = false;
    this.args.questionOption.mediaItem = mediaItem;
    this.args.questionOption.forceDirty();
  }

  @action
  removeImage() {
    this.args.questionOption.forceDirty();
    this.args.questionOption.mediaItem = null;
  }

  @action
  closeEditor() {
    this.toggleEditing();
  }

  @action
  toggleEditing() {
    this.args.questionOptionIsEditing(!this.isEditing, this);
  }

  @action
  editQuestionOption(event) {
    const square = $(event.target).parents('.ssThumbnailGridSquare');
    if (!this.isEditing && square.length) {
      this.toggleEditing();
    }
  }

  @action
  createdQuestionOption() {
    if (this.args.questionOption.isJustCreated) {
      this.toggleEditing();
      this.args.questionOption.isJustCreated = undefined;
    }
    this.updateWeights();
  }

  @action
  setIsCorrect(value) {
    this.args.questionOption.isCorrect = value;
  }
  //endregion
}
