/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
/* eslint-disable ember/no-test-import-export */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;

/**
 * @typedef {DS.Model} MessageVersionTest
 */
export default BaseModel.extend({
  //region Attributes
  messageVersionId: attr('number'),
  messageTo: attr('string'),
  //endregion
});
