import { attr } from '@ember-data/model';
import enums from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';

export default class MatchupPlacingCriteriaModel extends BaseModel {
  @attr('string') declare name: string;
  @attr('number') declare ownerEntityType: number;
  @attr('number') declare ownerEntityId: number;
  @attr('number') declare tieBreakLogicTypeId: number;
  @attr('number') declare winnerMethodTypeId: number;
  @attr('string') declare tieBreakingLogicValue: string;
  @attr('boolean') declare isInherited: boolean;

  @enums.computed('name', 'winnerMethodTypeId') declare winnerMethodType: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'matchup-placing-criteria': MatchupPlacingCriteriaModel;
  }
}
