/* eslint-disable ember/no-mixins */
import Route from '@ember/routing/route';
import AppContextRoute from 'partner/mixins/app-context-route';

/**
 * New Organization Promotion  Route
 * /o/:organization_id/op/new
 * @type {Ember.Route}
 * @mixes AppContextRoute
 */
export default Route.extend(AppContextRoute);
