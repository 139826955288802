import { helper } from '@ember/component/helper';

/**
 * Do math! Supports addition, subtraction, division, multiplication, and exponents.
 * If you try to divide by zero, it'll just return zero.
 * @param {Number} first
 * @param {String} operator
 * @param {Number} second
 * @returns {Number}
 */
export function math([first, operator, second]) {
  switch (operator) {
    case '+':
      return first + second;
    case '-':
      return first - second;
    case '/':
      return second === 0 ? 0 : first / second;
    case '*':
      return first * second;
    case '%':
      return first % second;
    case '**':
      return first ** second; //jshint ignore:line
  }
}

export default helper(math);
