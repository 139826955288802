import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.dipsSrc}}\n  <img\n    src={{dips-url-for this.dipsSrc width=this.width height=this.height}}\n    width={{this.width}}\n    height={{this.height}}\n    test-dips-image\n    ...attributes\n  />\n{{else if this.externalSrc}}\n  <div style={{html-safe this.sizeStyle}} class=\"overflow-hidden\" ...attributes>\n    <img src={{this.externalSrc}} class=\"w-full h-full object-cover\" test-external-image />\n  </div>\n{{else if this.logoMediaItemId}}\n  <img\n    src={{dips-url-for this.logoMediaItemId width=this.width height=this.height}}\n    width={{this.width}}\n    height={{this.height}}\n    class=\"w-full h-full object-contain\"\n    test-dips-logo\n    ...attributes\n  />\n{{else}}\n  {{! template-lint-disable no-inline-styles }}\n  <Ui::EmptyImage style={{html-safe this.sizeStyle}} test-empty-image />\n{{/if}}", {"contents":"{{#if this.dipsSrc}}\n  <img\n    src={{dips-url-for this.dipsSrc width=this.width height=this.height}}\n    width={{this.width}}\n    height={{this.height}}\n    test-dips-image\n    ...attributes\n  />\n{{else if this.externalSrc}}\n  <div style={{html-safe this.sizeStyle}} class=\"overflow-hidden\" ...attributes>\n    <img src={{this.externalSrc}} class=\"w-full h-full object-cover\" test-external-image />\n  </div>\n{{else if this.logoMediaItemId}}\n  <img\n    src={{dips-url-for this.logoMediaItemId width=this.width height=this.height}}\n    width={{this.width}}\n    height={{this.height}}\n    class=\"w-full h-full object-contain\"\n    test-dips-logo\n    ...attributes\n  />\n{{else}}\n  {{! template-lint-disable no-inline-styles }}\n  <Ui::EmptyImage style={{html-safe this.sizeStyle}} test-empty-image />\n{{/if}}","moduleName":"partner/components/ballot/setup/voting-entry/image.hbs","parseOptions":{"srcName":"partner/components/ballot/setup/voting-entry/image.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type SettingsService from 'partner/services/settings';
import type VotingEntryModel from 'partner/models/voting-entry';

export interface BallotSetupVotingEntryImageSignature {
  Element: HTMLDivElement | HTMLImageElement;
  Args: {
    votingEntry: VotingEntryModel;
    width?: number;
    height?: number;
  };
}

export default class BallotSetupVotingEntryImageComponent extends Component<BallotSetupVotingEntryImageSignature> {
  @service declare settings: SettingsService;

  get dipsSrc(): number {
    if (
      !this.args.votingEntry.isFromYouTube &&
      (this.args.votingEntry.isVideoEntry || this.args.votingEntry.isPhotoEntry)
    ) {
      return this.args.votingEntry.mediaItemId;
    }

    return 0;
  }

  get externalSrc(): string {
    if (this.args.votingEntry.isFromYouTube && this.args.votingEntry.sourcePostId) {
      return `http://img.youtube.com/vi/${this.args.votingEntry.sourcePostId as string}/0.jpg`;
    }

    return '';
  }

  get sizeStyle() {
    return `width: ${this.width}px; height: ${this.height}px;`;
  }

  get logoMediaItemId() {
    return this.args.votingEntry.logoMediaItemId;
  }

  private get width(): number {
    return this.args.width || 1170;
  }

  private get height(): number {
    return this.args.height || 1170;
  }
}
