import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Boulevard::ModalDialog\n  @isCloseable={{false}}\n  class=\"ssPromotionBasics {{@organizationPromotion.promotion.promotionClassName}}\"\n>\n  <MdcLinearProgress @indeterminate={{or this.setupTask.isRunning this.saveTask.isRunning}} />\n\n  {{#if this.setupTask.isRunning}}\n    <div class=\"ssModalInnerContent\">\n      <Loading::Title class=\"ssModalTitle\" />\n      <Loading::Steps />\n    </div>\n  {{else}}\n    <OrganizationPromotion::BasicsForm\n      @organizationPromotion={{@organizationPromotion}}\n      @allowedPartnerUsers={{this.allowedPartnerUsers}}\n      @interestTags={{this.taggableEntity.interestTags}}\n      @onSubmit={{perform this.saveTask}}\n      @onCancel={{@onCancel}}\n    />\n  {{/if}}\n</Boulevard::ModalDialog>\n", {"contents":"<Boulevard::ModalDialog\n  @isCloseable={{false}}\n  class=\"ssPromotionBasics {{@organizationPromotion.promotion.promotionClassName}}\"\n>\n  <MdcLinearProgress @indeterminate={{or this.setupTask.isRunning this.saveTask.isRunning}} />\n\n  {{#if this.setupTask.isRunning}}\n    <div class=\"ssModalInnerContent\">\n      <Loading::Title class=\"ssModalTitle\" />\n      <Loading::Steps />\n    </div>\n  {{else}}\n    <OrganizationPromotion::BasicsForm\n      @organizationPromotion={{@organizationPromotion}}\n      @allowedPartnerUsers={{this.allowedPartnerUsers}}\n      @interestTags={{this.taggableEntity.interestTags}}\n      @onSubmit={{perform this.saveTask}}\n      @onCancel={{@onCancel}}\n    />\n  {{/if}}\n</Boulevard::ModalDialog>\n","moduleName":"partner/components/organization-promotion/edit-form.hbs","parseOptions":{"srcName":"partner/components/organization-promotion/edit-form.hbs"}});
import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import enums from 'ember-cli-ss-enums/services/enums';
import { task } from 'ember-concurrency';
import type FeaturesService from 'ember-feature-flags/services/features';
import difference from 'lodash/difference';
import type OrganizationPromotionPartnerUserAllowedListModel from 'partner/models/organization-promotion-partner-user-allowed-list';
import type TaggableEntityModel from 'partner/models/taggable-entity';
import type SessionService from 'partner/services/-private/session';
import type CurrentService from 'partner/services/current';
import { hasDataProduct } from 'partner/utils/calculate';
import type SnackbarService from 'secondstreet-common/services/snackbar';
import type { Model } from './basics-form';
import type OrganizationPromotionManagerService from 'partner/services/organization-promotion-manager';

interface OrganizationPromotionEditFormSignature {
  Element: HTMLDivElement;
  Args: {
    organizationPromotion: any; // OrganizationPromotion model
    onSave: () => void;
    onCancel: () => void;
  };
}

export default class OrganizationPromotionEditFormComponent extends Component<OrganizationPromotionEditFormSignature> {
  @service declare current: CurrentService;
  @service declare features: FeaturesService;
  @service declare store: Store;
  @service declare snackbar: SnackbarService;
  @service declare session: SessionService;
  @service declare organizationPromotionManager: OrganizationPromotionManagerService;

  @tracked allowedPartnerUsers: OrganizationPromotionPartnerUserAllowedListModel[] = [];
  @tracked taggableEntity: TaggableEntityModel | null = null;

  get creatorEmail() {
    return (
      this.args.organizationPromotion.get('createdByOrganizationUser.emailAddress') ||
      this.session.data.authenticated?.organization_users.email_address
    );
  }

  get hasDataProduct() {
    return hasDataProduct(this.current.organization.organizationSsProducts);
  }

  constructor(owner: unknown, args: OrganizationPromotionEditFormSignature['Args']) {
    super(owner, args);

    void this.setupTask.perform();
  }

  setupTask = task({ drop: true }, async () => {
    await Promise.all([this.maybeFetchAllowedPartnerUsers(), this.maybeFetchTags()]);
  });

  saveTask = task({ drop: true }, async (model: Model) => {
    try {
      await Promise.all([
        this.saveOrganizationPromotion(model),
        this.saveInterestTags(model.interestTags),
        this.saveAllowedPartnerUsers(model.partnerUsers),
        this.organizationPromotionManager.setupDomain(this.args.organizationPromotion),
      ]);

      this.args.onSave();
    } catch (e) {
      this.snackbar.exception(e);
    }
  });

  private async maybeFetchAllowedPartnerUsers() {
    if (!this.args.organizationPromotion.promotion.isBallot || !this.features.get('hasAdminPromotionWhitelist')) {
      return;
    }

    const allowedPartnerUsers = await this.store.query('organization-promotion-partner-user-allowed-list', {});
    this.allowedPartnerUsers = allowedPartnerUsers.toArray();
  }

  private async maybeFetchTags() {
    if (!this.hasDataProduct) return;

    const entityTypeId = enums.findWhere('ENTITY_TYPE', { name: 'OrganizationPromotion' });

    this.taggableEntity = await this.store.queryRecord('taggable-entity', {
      tier: 1,
      taggableEntityId: `${entityTypeId}-${String(this.args.organizationPromotion.id)}`,
    });
  }

  private async saveOrganizationPromotion(model: Model) {
    this.args.organizationPromotion.setProperties({
      name: model.name,
      defaultLanguageId: model.defaultLanguageId,
    });

    if (this.args.organizationPromotion.isDirty) {
      await this.args.organizationPromotion.save();
    }
  }

  private async saveInterestTags(interestTags: Model['interestTags']) {
    if (!this.taggableEntity) return;

    await this.taggableEntity.replaceInterestTags(interestTags);
  }

  private async saveAllowedPartnerUsers(nextPartnerUsers: Model['partnerUsers']) {
    const currentPartnerUsers = await Promise.all(this.allowedPartnerUsers.mapBy('partnerUser'));

    // Zero length means that all users have access, that's why we need to clean the rules
    if (nextPartnerUsers.length === 0) {
      await Promise.all(
        this.allowedPartnerUsers
          .filter(allowedPartnerUser => allowedPartnerUser.emailAddress != this.creatorEmail)
          .map(allowedPartnerUser => allowedPartnerUser.destroyRecord())
      );

      // The creator can be remove only when they are the last one
      await this.allowedPartnerUsers.findBy('emailAddress', this.creatorEmail)?.destroyRecord();
      return;
    }

    const deleted = difference(currentPartnerUsers, nextPartnerUsers).map(partnerUser =>
      this.allowedPartnerUsers
        .find(allowedPartnerUser => allowedPartnerUser.partnerUserId == partnerUser.id)
        ?.destroyRecord()
    );

    const created = difference(nextPartnerUsers, currentPartnerUsers).map(partnerUser =>
      this.store
        .createRecord('organization-promotion-partner-user-allowed-list', {
          partnerUser,
          emailAddresses: partnerUser.emailAddress,
        })
        .save()
    );

    await Promise.all([...created, ...deleted]);
  }
}
