/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import flatten from 'lodash/flatten';
import { repairBracketMatchupEntries, repairBracketMatchups } from 'partner/utils/bracket/repair';
import RSVP from 'rsvp';

const ORGANIZATION_ROUTE = 'organizations.organization';
const ORGANIZATION_PROMOTION_ROUTE = 'organizations.organization.organization-promotions.organization-promotion';
const ORGANIZATION_PROMOTION_SETUP_ROUTE =
  'organizations.organization.organization-promotions.organization-promotion.setup';

export default Route.extend({
  //region Dependencies
  enums: service(),
  store: service(),
  settings: service(),
  //endregion

  //region Ember Hooks
  async model() {
    const organizationId = this.modelFor(ORGANIZATION_ROUTE).organization.id;
    const organizationPromotionId = this.modelFor(ORGANIZATION_PROMOTION_ROUTE).organizationPromotion.id;
    const statusTypeId = this.enums.findWhere('STATUS_TYPE', { name: 'Active' }, 'id');
    const { matchups, games } = this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE);
    const { promotion } = this.modelFor(ORGANIZATION_PROMOTION_ROUTE).organizationPromotion;

    const { matchupEntriesByMatchup, sweepstakes } = await RSVP.hash({
      matchupEntriesByMatchup: Promise.all(
        matchups.filterBy('iteration', 1).map(matchup =>
          this.store.query('matchup-entry', {
            organizationId,
            organizationPromotionId,
            matchupId: matchup.id,
            statusTypeId,
          })
        )
      ),
      _settings: this.settings.preload('dips_url'),
      sweepstakes: this.store.queryRecord('sweepstakes', {
        organizationId,
        organizationPromotionId,
      }),
    });
    const dipsUrl = this.settings.getFor('dips_url');
    const matchupEntries = flatten(matchupEntriesByMatchup.map(matchupEntries => matchupEntries.toArray()));

    return { matchups, matchupEntries, promotion, dipsUrl, games, sweepstakes };
  },
  async afterModel(model) {
    await this.checkAndRepairBracket(model);
  },
  //endregion

  //region Method
  /**
   * Because there is occasional in-the-background bulk saving when setting up brackets,
   * and a lot of the data that is saved is not reflected in the UI at all (eg future matchups),
   * it may be possible to get a bracket in a bad state without the user knowing anything went wrong.
   * In that (hopefully unlikely) event, this will check and attempt to repair the bracket.
   * @param model
   */
  async checkAndRepairBracket(model) {
    const { matchupsToRemove, matchupsToFix, matchupsToAdd } = repairBracketMatchups(
      model.matchupEntries.length,
      model.matchups
    );
    const newMatchups = matchupsToAdd.map(newMatchupObject =>
      this.store.createRecord('matchup', { ...newMatchupObject })
    );

    model.matchups.addObjects(newMatchups);
    model.matchups.removeObjects(matchupsToRemove);
    await Promise.all([
      ...matchupsToRemove.map(matchup => matchup.destroyRecord()),
      ...matchupsToFix.map(matchup => matchup.save()),
      ...newMatchups.map(matchup => matchup.save()),
    ]);
    const matchupEntriesToFix = repairBracketMatchupEntries(model.matchupEntries, model.matchups);
    await Promise.all(matchupEntriesToFix.map(matchupEntry => matchupEntry.save()));

    // update primary game and sweepstakes end dates in case they are out of sync
    this.updateEndDateForGames(model.matchups);
  },
  /**
   * When adding or removing an entrant causes the # of rounds to change, the overall end date of the bracket gets
   * changed, so we need to update the end date of the bracket's primary game and the sweepstakes game if it exists.
   * @param matchups
   */
  async updateEndDateForGames(matchups) {
    const { sweepstakes, games } = this.modelFor(this.routeName);
    const newEndDate = matchups.sortBy('iteration').lastObject?.endDate;
    const primaryGame = games.findBy('isPrimary');
    set(primaryGame, 'endDate', newEndDate);
    await primaryGame.save();

    if (sweepstakes.isEnabled) {
      set(sweepstakes, 'endDate', newEndDate);
      await sweepstakes.save();
    }
  },
  //endregion

  //region Actions
  actions: {
    saveAndContinue() {
      this.send('continue');
    },
    updateModel(modelToUpdate, action, modelInstance) {
      this.controller.model[modelToUpdate][action](modelInstance);
    },
    updateChecklistStep() {
      if (this.controller.model.matchupEntries.length > 1) {
        this.send('checkChecklistStep', this.routeName);
      } else {
        this.send('uncheckChecklistStep', this.routeName);
      }
    },
    updateEndDateForGames(matchups) {
      this.updateEndDateForGames(matchups);
    },
  },
  //endregion
});
