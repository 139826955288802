import Service from '@ember/service';
import Ember from 'ember';

class InMemoryStorage {
  inMemoryData = {};

  removeItem() {
    this.inMemoryData = {};
  }

  getItem(key) {
    return this.inMemoryData[key];
  }

  setItem(key, value) {
    this.inMemoryData[key] = value;
  }
}

export default class LocalStorageService extends Service {
  key = 'second-street-storage';
  storage = null;
  data = {};

  constructor(...args) {
    super(...args);
    this.storage = Ember.testing ? new InMemoryStorage() : localStorage;
    this.restore();
  }

  clear() {
    this.storage.removeItem(this.key);
    this.data = {};
  }

  getItem(key) {
    return this.data[key];
  }

  setItem(key, value) {
    this.data[key] = value;
    this.persist();
  }

  restore() {
    try {
      this.data = JSON.parse(this.storage.getItem(this.key) || '{}');
    } catch (e) {
      this.data = {};
    }
  }

  persist() {
    this.storage.setItem(this.key, JSON.stringify(this.data));
  }
}
