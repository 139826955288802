/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { computed } from '@ember/object';
import Attribute from 'partner/components/template-builder/attribute-editor/attribute';

export default Attribute.extend({
  //region Computed Properties
  tokenId: computed('selected-component.id', function () {
    //extract the tokenName by removing the curly braces from the value ({{foo}} --> foo to find the token id
    const tokenName = this['selected-component'].attrsInfo.content.value.slice(2, -2);
    const { id } = this['template-tokens'].findBy('key', tokenName);
    return id;
  }),
  //endregion

  //region Actions
  actions: {
    setAttribute({ target: { value } }) {
      this['set-attribute'](this['attr-name'], value);
    },
  },
  //endregion
});
