/* eslint-disable ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import ApplicationSerializer from 'partner/serializers/application';

export default ApplicationSerializer.extend(DS.EmbeddedRecordsMixin, {
  attrs: {
    // embedded: 'always' means { serialize: 'records', deserialize: 'records' },
    // for this case we don't want the relationships to be serialized
    // More info: http://emberjs.com/api/data/classes/DS.EmbeddedRecordsMixin.html
    organizationStatistics: { deserialize: 'records', serialize: false },
    childOrganizations: { deserialize: 'records', serialize: false },
  },
});
