/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';

export default Component.extend({
  //region Attributes
  'can-expand': false,
  //endregion

  //region Properties
  _isExpanded: false,
  //endregion

  //region Ember Hooks
  classNames: ['stat-card__list'],

  classNameBindings: ['_isExpanded:stat-card__list--expanded'],
  //endregion
});
