import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * Legacy Route - only exists to redirect to what has replaced it.
 */
export default class DesignRoute extends Route {
  @service router;

  redirect() {
    this.router.transitionTo('organizations.organization.organization-promotions.organization-promotion.setup.designs');
  }
}
