/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

export default Controller.extend({
  //region Ember Hooks
  application: controller(),
  session: service(),
  //endregion

  //region Properties
  showDeleteConfirmation: false,
  //endregion

  //region Computed Properties
  deleteConfirmationMessage: computed('model.partnerUser.{fullName,emailAddress}', function () {
    const fullName = get(this, 'model.partnerUser.fullName');
    const name = isBlank(fullName) ? get(this, 'model.partnerUser.emailAddress') : fullName;
    return `Are you sure you want to delete ${name}?`;
  }),
  isEditingYourself: computed(
    'model.partnerUser.id',
    'session.data.authenticated.organization_users.user_id',
    function () {
      return this.session.data.authenticated.organization_users.user_id === parseInt(get(this, 'model.partnerUser.id'));
    }
  ),
  //endregion

  //region Actions
  actions: {
    tryDeleteUser() {
      set(this, 'showDeleteConfirmation', true);
    },
    confirmDeleteUser() {
      this.target.send('deleteUser');
      this.send('dontDeleteUser');
    },
    dontDeleteUser() {
      set(this, 'showDeleteConfirmation', false);
    },
    removeTwoFactorDevice() {
      this.model.partnerUserSecurityDetail.resetTwoFactorAuth = true;
      this.model.partnerUserSecurityDetail.save().then(() => {
        if (this.isEditingYourself) {
          this.session.invalidate();
        }
      });
    },
    removeRecoveryEmail() {
      this.model.partnerUserSecurityDetail.partnerTwoFactorAuthRecoveryEmailAddress = null;
      this.model.partnerUserSecurityDetail.save();
    },
  },
  //endregion
});
