/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import flattenDeep from 'lodash/flattenDeep';
import RSVP from 'rsvp';
import { categoricalChartCategories } from 'secondstreet-common/utils/data-manipulation';

/**
 * Audience Filters Route
 * /o/:organization_id/data/audiences/:audience_id/sales-sheet
 */
export default Route.extend({
  session: service('session'),
  store: service('store'),
  settings: service('settings'),

  //region Ember Hooks
  async model() {
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');
    const audienceModel = this.modelFor('organizations.organization.data.audiences.audience');
    const audienceId = get(audienceModel, 'audience.id');

    const model = await RSVP.hash({
      pageTemplatePresets: this.store.query('pageTemplatePreset', {
        pageTypeId: 23,
        audienceId,
        organizationId,
      }),
      pageTemplates: this.store.query('pageTemplate', {
        pageTypeId: 23,
        audienceId,
        organizationId,
      }),
      audience: get(audienceModel, 'audience'),
      _settings: this.settings.preload('dips_url'),
      categoricalCharts: this.store.query('categoricalChart', {
        audienceId,
        organizationId,
      }),
    });
    model.dipsUrl = this.settings.getFor('dips_url');
    model.pageTemplatePresets = model.pageTemplatePresets
      .toArray()
      .filterBy('id', `${get(model.pageTemplates, 'firstObject.pageTemplatePresetId')}`);
    return model;
  },
  afterModel(model) {
    const audienceId = get(this.modelFor('organizations.organization.data.audiences.audience'), 'audience.id');
    const charts = get(model, 'categoricalCharts');
    // Don't await or return these, as we don't want to pause the router to load this data.
    charts.forEach(chart => categoricalChartCategories(chart, this, { audienceId }));
  },
  //endregion

  //region Methods
  async save() {
    const setSaveState = status => run(() => set(this, 'controller.isAnythingSaving', status));
    setSaveState(true);

    await RSVP.all(
      flattenDeep(
        get(this, 'currentModel.pageTemplatePresets').map(pageTemplatePreset =>
          get(pageTemplatePreset, 'templateElements').map(templateElement => {
            if (get(templateElement, 'hasDirtyAttributes')) {
              set(
                templateElement,
                'audienceId',
                parseInt(get(this.modelFor('organizations.organization.data.audiences.audience'), 'audience.id'), 10)
              );
              return templateElement.save();
            }
            return templateElement;
          })
        )
      )
    );
    setSaveState(false);
  },
  //endregion

  //region Actions
  actions: {
    save() {
      if (!get(this, 'controller.isAnythingSaving')) {
        this.save();
      }
    },
  },
  //endregion
});
