/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/* eslint-disable ember/no-side-effects */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { pluralize } from 'ember-inflector';
import HasCategoricalCharts from 'partner/mixins/has-categorical-charts';
import TickTock from 'partner/mixins/tick-tock';
import { emptyTimeSeriesData } from 'partner/utils/charts';
import { firstByProperty } from 'partner/utils/computed';

export default Component.extend(HasCategoricalCharts, TickTock, {
  //region Ember Hooks
  enums: service(),
  screen: service(),
  permissions: service(),
  //endregion

  //region Properties
  tickDuration: 1000 * 60, // One minute
  locationRankingsTableHeight: 465,
  showMultipleRoundsWinnersModal: false,
  currentSweepsWinnerPage: 1,
  currentVotingWinnerPage: 1,
  winnersCardTransition: 'toLeft',
  //endregion

  //region Attributes
  'is-ugc-voting-promotion': false,
  'is-ugc-gallery-promotion': false,
  'is-ugc-sweepstakes-promotion': false,
  //endregion

  //region Computed Properties
  onMobile: computed('screen.width', function () {
    return get(this, 'screen.width') <= 640;
  }),
  totalEntriesCount: computed('model.{pendingEntriesCount,approvedEntriesCount,rejectedEntriesCount}', function () {
    return (
      get(this, 'model.pendingEntriesCount') +
      get(this, 'model.approvedEntriesCount') +
      get(this, 'model.rejectedEntriesCount')
    );
  }),
  entriesCountKpi: readOnly('organizationPromotion.kpi'),
  isWinnersPublished: readOnly('model.matchups.firstObject.isWinnersPublished'),
  numEligibleUsers: readOnly('organizationPromotion.numEligibleUsers'),
  numEligibleVotes: readOnly('organizationPromotion.numEligibleVotes'),
  entriesChart: firstByProperty('name', 'Entry', 'model.eventCharts'),
  votesChart: firstByProperty('name', 'Vote', 'model.eventCharts'),
  contestStartDate: readOnly('model.matchups.firstObject.startDate'),
  contestEndDate: readOnly('model.matchups.lastObject.endDate'),
  contestSelectionStartDate: readOnly('model.matchups.firstObject.selectionStartDate'),
  contestSelectionEndDate: readOnly('model.matchups.lastObject.selectionEndDate'),
  numWinners: readOnly('model.winners.length'),
  numSweepsWinners: readOnly('sweepsCardWinners.length'),
  isVideoDashboard: or(
    'organizationPromotion.promotion.isVideoVotingStandard',
    'organizationPromotion.promotion.isUGCSweepstakesVideo'
  ),
  mediaType: computed('isVideoDashboard', function () {
    return this.isVideoDashboard ? 'Video' : 'Photo';
  }),
  showEntriesCardEmptyState: computed('contestStartDate', 'entriesCountKpi', function () {
    const count = this.entriesCountKpi;
    return this.contestStartDate > new Date() && count ? false : !count;
  }),
  entriesCardEmptyStateText: computed('tock', 'contestStartDate', 'totalEntriesCount', 'mediaType', function () {
    if (this.contestStartDate > this.tock && !this.totalEntriesCount) {
      return 'Waiting for Contest to Start';
    }
    const nameForEntries = this['is-ugc-sweepstakes-promotion'] ? 'Entries' : pluralize(this.mediaType);
    return `Waiting for ${nameForEntries}`;
  }),
  winnersCardEmptyStateMessage: computed('votingHasStarted', 'votingHasEnded', 'numWinners', function () {
    if (this.votingHasEnded) {
      return this.numWinners ? null : '';
    }

    return this.votingHasStarted ? 'Voting is in progress.' : 'Voting has not started yet.';
  }),
  votesEmptyStateText: computed('votingHasStarted', function () {
    return this.votingHasStarted ? 'Waiting for Votes' : 'Waiting for Voting to Start';
  }),
  votingHasStarted: computed('contestSelectionStartDate', 'tock', function () {
    return this.contestSelectionStartDate < this.tock;
  }),
  votingHasEnded: computed('contentSelectionEndDate', 'tock', function () {
    return this.contestSelectionEndDate < this.tock;
  }),
  oddFieldChartsCount: computed('fieldCharts.length', function () {
    return get(this, 'fieldCharts.length') % 2;
  }),
  emptyTimeSeriesData: computed(emptyTimeSeriesData(10)),
  entriesChartProperties: computed(
    'entriesChart',
    'entriesChart.{categories,areCategoriesLoadedAndEmpty}',
    'emptyTimeSeriesData',
    function () {
      const loadedAndEmpty = get(this, 'entriesChart.areCategoriesLoadedAndEmpty');
      return {
        data: loadedAndEmpty ? this.emptyTimeSeriesData : get(this, 'entriesChart.cumulativeCategories'),
        color: loadedAndEmpty ? 'rgb(172,172,172)' : 'rgb(44, 121, 229)',
      };
    }
  ),
  votesChartProperties: computed(
    'votesChart',
    'votesChart.{categories,areCategoriesLoadedAndEmpty}',
    'emptyTimeSeriesData',
    function () {
      const loadedAndEmpty = get(this, 'votesChart.areCategoriesLoadedAndEmpty');
      return {
        data: loadedAndEmpty ? this.emptyTimeSeriesData : get(this, 'votesChart.cumulativeCategories'),
        color: loadedAndEmpty ? 'rgb(172,172,172)' : 'rgb(44, 121, 229)',
      };
    }
  ),
  transformedPostalCodeData: computed('postalCodeChart.reverseSortedCategories', function () {
    return get(this, 'postalCodeChart.reverseSortedCategories').filter(x => get(x, 'label') !== 'Unknown');
  }),
  topPostalCodeName: readOnly('transformedPostalCodeData.firstObject.label'),
  matchupsForReportModal: computed('model.matchups.[]', function () {
    return get(this, 'model.matchups')
      .sortBy('displayOrder')
      .map(item => ({
        id: get(item, 'id'),
        name: get(item, 'name'),
        isPreparingDownload: false,
      }));
  }),
  winnersCardSubheader: computed(
    'numWinners',
    'numSweepsWinners',
    'is-ugc-voting-promotion',
    'votingHasEnded',
    'organizationPromotion.promotion.promotionSubType',
    function () {
      const { numSweepsWinners } = this;
      if (this['is-ugc-voting-promotion']) {
        const votingWinnersCount = this.numWinners;
        const votingWinnersText = `After all the votes were tallied, ${votingWinnersCount}${this.personWasInflector(
          votingWinnersCount
        )} victorious`;
        const sweepsWinnersText = `${
          numSweepsWinners + this.personWasInflector(numSweepsWinners)
        } randomly selected as ${numSweepsWinners === 1 ? 'the sweepstakes winner' : 'sweepstakes winners'}`;
        const { votingHasEnded } = this;
        if (numSweepsWinners && votingHasEnded) {
          return `${votingWinnersText}, and ${sweepsWinnersText}.`;
        } else if (numSweepsWinners) {
          return `Votes have not yet been tallied, but ${sweepsWinnersText}.`;
        } else if (votingHasEnded) {
          return `${votingWinnersText}.`;
        }
      } else if (numSweepsWinners) {
        const numWinners = this.numSweepsWinners;
        const mediaType =
          get(this, 'organizationPromotion.promotion.promotionSubType') === 'UGCSweepstakesVideo' ? 'videos' : 'photos';
        return `After all the ${mediaType} were collected, ${numWinners}${this.personWasInflector(
          numWinners
        )} randomly selected as ${numWinners === 1 ? 'the winner' : 'winners'}.`;
      }
      return null;
    }
  ),
  personWasInflector: count => (count === 1 ? ' person was' : ' people were'),
  sweepsCardWinners: computed(
    'model.{winners,sweepstakes.isEnabled,sweepstakesWinners}',
    'is-ugc-sweepstakes-promotion',
    'is-ugc-gallery-promotion',
    'is-ugc-voting-promotion',
    function () {
      if (this['is-ugc-sweepstakes-promotion']) {
        return get(this, 'model.winners');
      } else if (
        (this['is-ugc-voting-promotion'] && get(this, 'model.sweepstakes.isEnabled')) ||
        this['is-ugc-gallery-promotion']
      ) {
        return get(this, 'model.sweepstakesWinners');
      }
      return [];
    }
  ),
  votingCardWinners: computed('model.winners', function () {
    const winners = get(this, 'model.winners').sortBy('entry.matchupEntry.displayOrder');
    let rank = 1;
    winners.forEach(winner => set(winner, 'rank', rank++));
    return winners.sortBy('rank');
  }),
  sweepsCardWinnersPagingInfo: computed('currentSweepsWinnerPage', 'numSweepsWinners', function () {
    const { numSweepsWinners } = this;
    if (numSweepsWinners > 1) {
      return `(${this.currentSweepsWinnerPage} of ${numSweepsWinners})`;
    }
    return '';
  }),
  votingCardWinnersPagingInfo: computed('currentVotingWinnerPage', 'votingHasEnded', 'numWinners', function () {
    if (this.numWinners > 1 && this.votingHasEnded) {
      return `(${this.currentVotingWinnerPage} of ${this.numWinners})`;
    }

    return '';
  }),
  currentSweepsWinner: computed('numSweepsWinners', 'currentSweepsWinnerPage', 'sweepsCardWinners', function () {
    return this.numSweepsWinners > 0 ? this.sweepsCardWinners.objectAt(this.currentSweepsWinnerPage - 1) : null;
  }),
  currentVotingWinner: computed(
    'currentVotingWinnerPage',
    'numWinners',
    'votingCardWinners',
    'votingHasEnded',
    function () {
      return this.numWinners > 0 && this.votingHasEnded
        ? this.votingCardWinners.objectAt(this.currentVotingWinnerPage - 1)
        : null;
    }
  ),
  //endregion

  //region Actions
  actions: {
    toggleShowMultipleRoundWinnersModal() {
      this.toggleProperty('showMultipleRoundsWinnersModal');
    },
  },
  //endregion
});
