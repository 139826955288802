/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;

export default BaseModel.extend({
  //region Properties
  emailAddress: attr('string'),
  birthday: attr('date-only'),
  birthMonth: attr('string'),
  birthYear: attr('string'),
  firstName: attr('string'),
  gender: attr('string'),
  lastActivityDate: attr('date'),
  lastName: attr('string'),
  organizationId: attr('number'),
  statusTypeId: attr('number'),
  userId: attr('number'),
  userTypeId: attr('number'),
  username: attr('string'),
  password: attr('string'),
  migratedToUcm: attr('boolean'),
  //endregion
});
