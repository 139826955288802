import { helper } from '@ember/component/helper';
import { assert } from '@ember/debug';

/**
 * Helper to determine the count(1 based index) for items in a one or two column layout.
 * Example layouts:
 * //two column
 * 1 2
 * 3 4
 * 5 6
 *
 * //one column
 * 1
 * 2
 * 3
 *
 * @param {Array} params - Not used by this helper
 * @param {ColumnIndexParams} namedParams - see type def below
 *
 * @returns {Number} - ONE BASED index for display
 */
export function columnIndex(params, { columnCount, innerIndex, outerIndex }) {
  /**
   * @typedef {Object} ColumnIndexParams
   * @property {Number} columnCount - count of columns (1,2)
   * @property {Number} innerIndex - the index inside the column, zero based
   * @property {Number} outerIndex - which column is being display, the index of the column itself, zero based
   */

  assert('{{column-index columnCount}} only supports 1 or 2 columns', columnCount === 1 || columnCount === 2);

  if (columnCount === 1) {
    return innerIndex + 1;
  }
  return outerIndex === 1 ? (innerIndex + 1) * 2 : (innerIndex + 1) * 2 - 1;
}

export default helper(columnIndex);
