/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  //region Attributes
  value: null,
  errors: null,
  //endregion

  //region Computed Properties
  twitterValue: computed('value', 'organizationPromotion.promotion.name', 'organization.name', function () {
    return this.value
      ?.replace('{{Promotion.Name}}', this.organizationPromotion.promotion.name)
      ?.replace('{{Organization.Name}}', this.organization.name)
      ?.concat(' xxxxxxxxxxxxxxxxxxxxxx'); // Approximate length of a shortened Twitter URL
  }),
  //endregion

  //region Actions
  actions: {
    updateValue({ target: { value } }) {
      this.updateTokenContentProperty('value', value);
    },
  },
  //endregion
});
