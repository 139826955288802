import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';
import type RouterService from '@ember/routing/router-service';
import type { Model } from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/ballot/matchup-groups/new';
import type SetupBallotController from 'partner/controllers/organizations/organization/organization-promotions/organization-promotion/setup/ballot';
import type MatchupGroupModel from 'partner/models/matchup-group';

export default class SetupBallotMatchupGroupsNewController extends Controller {
  @service declare router: RouterService;

  @controller('organizations.organization.organization-promotions.organization-promotion.setup.ballot')
  declare setupBallotController: SetupBallotController;

  declare model: Model;

  @action
  handleSave(matchupGroup: MatchupGroupModel) {
    this.send('handleMatchupGroupCreation', matchupGroup);

    void this.router.transitionTo(
      'organizations.organization.organization-promotions.organization-promotion.setup.ballot',
      { queryParams: { matchupGroupId: matchupGroup.id, matchupId: null } }
    );
  }

  @action
  handleClose() {
    if (this.model.matchupGroup.get('isNew')) {
      this.model.matchupGroup.destroyRecord();
    }

    void this.router.transitionTo(
      'organizations.organization.organization-promotions.organization-promotion.setup.ballot'
    );
  }
}
