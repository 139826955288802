/**
 * Allows you to properly retrieve the value of a number input based on minimums and maximums you have set
 * By default the browser handles min and max with the input arrows, but the user can still bypass this by
 * just typing into the field. This util returns the minimum if you input a number too small, and returns the
 * maximum if you input a number too large.
 * @param target - expects the target object of the input element
 * @returns {*}
 */

export default function getValidNumber({ target }) {
  const { min } = target;
  if (target.validity.valid) {
    return parseFloat(target.value);
  }
  if (target.validity.rangeOverflow) {
    const { max } = target;
    target.value = max;
    return max;
  }
  if (target.validity.rangeUnderflow) {
    target.value = min;
    return min;
  }
  target.value = min;
  return min;
}
