import { helper } from '@ember/component/helper';
import moment from 'moment';
/**
 * Exposes Moment.js methods to your dates.
 * The first argument is passed to moment to create a Moment.
 * The second argument is the method to call on that Moment.
 * The third and following arguments are passed as params to that method.
 *
 * Examples:
 *      {{moment myDate 'fromNow'}} => moment(myDate).fromNow()
 *      {{moment myDate 'format' 'ddd, hA'}} => moment(myDate).format('ddd, ha')
 *      {{moment myDate 'from' myOtherDate true}} => moment(myDate).from(myOtherDate, true)
 */
export function momentHelper(params) {
  const [date, method, ...args] = params;
  return moment(date)[method](...args);
}

export default helper(momentHelper);
