/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { equal } from '@ember/object/computed';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;

/**
 * @typedef {DS.Model} Role
 */
export default BaseModel.extend({
  name: attr('string'),
  description: attr('string'),

  isAdminUserCreation: equal('id', '3'),
});
