import { attr, belongsTo } from '@ember-data/model';
import { get } from '@ember/object';
import BaseModel from 'secondstreet-common/models/base';
import type OutcomeModel from './outcome';
import type QuestionOptionModel from './question-option';

export default class QuestionOptionOutcomeWeightModel extends BaseModel {
  @attr('number', { defaultValue: 0 }) declare selectionWeight: number;

  @belongsTo('question-option', { async: false }) declare questionOption: QuestionOptionModel;
  @belongsTo('outcome', { async: false }) declare outcome: OutcomeModel;

  /**
   * Implies that this model shouldn't be saved to the API, because it only represents a default
   */
  get isJunkData() {
    // TODO: Change when Ember Data types property unwrap computed props
    // eslint-disable-next-line ember/no-get
    return get(this, 'isNew') && this.selectionWeight === 0;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'question-option-outcome-weight': QuestionOptionOutcomeWeightModel;
  }
}
