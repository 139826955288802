import Component from '@glimmer/component';

export default class ExpandableChecklistComponent extends Component {
  get addToken() {
    return this.args['add-token'] || (() => {});
  }

  get offset() {
    return this.args.offset || 0;
  }
}
