import { attr, belongsTo } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';
import type PartnerNotificationModel from './partner-notification';

export default class PartnerNotificationUserReadModel extends BaseModel {
  @attr('date') declare dateCreated: Date;
  @attr('date') declare dateModified: Date;
  @attr('number') declare userId: number;

  @belongsTo('partner-notification', { async: false }) declare partnerNotification: PartnerNotificationModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'partner-notification-user-read': PartnerNotificationUserReadModel;
  }
}
