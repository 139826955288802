/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { deprecate } from '@ember/debug';
import { TextField } from '@ember/legacy-built-in-components';
import { computed, set } from '@ember/object';
import PermittedComponent from 'partner/mixins/permitted-component';
import { ensureProtocol } from 'secondstreet-common/utils/url';

/**
 * {{ss-input value=someProperty}}
 * @param [errors] {DS.Errors} - An instance of DS.Errors to display errors from. Using this makes `attribute` required.
 * @param [attribute] {String} - The string name of the property from DS.Errors this field is tied to.
 * @see Promotion.PermittedComponent
 * @type {Ember.TextField}
 */
export default TextField.extend(PermittedComponent, {
  //region Ember Hooks
  attributeBindings: ['isDisabled:disabled', 'oninput', 'onchange'],
  classNameBindings: ['isDisabled:ssDisabled', 'hasErrors'],

  /**
   * Autofocus the input as it's inserted if the attribute auto-focus-on-insert is set to true
   */
  didInsertElement() {
    this._super(...arguments);
    if (this['auto-focus-on-insert']) {
      this.element.focus();
    }
  },
  //endregion

  //region Attributes
  'auto-focus-on-insert': false,
  /**
   * Removes the @ if included by the user.
   * @type {Boolean}
   */
  'is-social-handle': false,
  /**
   * If you want to manually disable the component, pass this as true.
   */
  'is-disabled': false,
  /**
   * If true then the click event will not be sent beyond this input.
   * @type {Boolean}
   */
  'prevent-click-bubble': false,
  /**
   * If true, pressing enter will call the action passed in via
   * the enter-action attribute
   * @type {Boolean}
   */
  'action-on-enter': false,
  /**
   * The action to be called if the attribute action-on-enter is true
   * @type {Function}
   */
  'enter-action': null,
  /**
   * @type {Boolean}
   */
  'two-way': true,
  /**
   * @type {Function}
   */
  onblur() {},
  //endregion

  //region Computed Properties
  /**
   * Don't overwrite this from the outside! Also don't overwrite `disabled`.
   * If you do those things, it will cause permissions issues.
   */
  isDisabled: computed('administer', 'disabled', 'is-disabled', {
    get() {
      return !this.administer || this.disabled || this['is-disabled'];
    },

    set(_key, value) {
      set(this, 'disabled', value);
      return value;
    },
  }),
  _hasErrors: null,
  hasErrors: computed('errors.[]', 'attribute', '_hasErrors', {
    get() {
      const { _hasErrors, errors } = this;
      if (_hasErrors !== null) {
        return _hasErrors;
      }
      if (!errors) {
        return false;
      }
      return errors.has(this.attribute);
    },
    set(_key, value) {
      this.set('_hasErrors', value);
      return value;
    },
  }),
  //endregion

  //region Events
  click() {
    if (this['prevent-click-bubble']) {
      return false;
    }
  },
  /**
   * If it's being used as a number input, only allow numbers to be typed
   * @private
   */
  keyDown(event) {
    if (this.type === 'number') {
      // number keys
      const allowedKeyCodes = [
        8, 9, 33, 34, 35, 36, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102,
        103, 104, 105,
      ];
      if (allowedKeyCodes.indexOf(event.keyCode) === -1) {
        event.preventDefault();
      }
    }
    if (event.key === 'Enter' && this['action-on-enter']) {
      event.preventDefault();
      this['enter-action']();
    }
  },
  focusOut() {
    if (this.resetOnNull && !this.value) {
      set(this, 'value', this.storedValue);
    }
    if (this['focus-out']) {
      this['focus-out']();
    } else {
      const { value: oldValue, min, max } = this;
      let newValue = oldValue;
      if (this.type === 'url') {
        newValue = ensureProtocol(oldValue);
      } else if (this.type === 'number') {
        if (min && (!oldValue || parseFloat(oldValue) < parseFloat(min))) {
          newValue = min;
        } else if (max && (!oldValue || parseFloat(oldValue) > parseFloat(max))) {
          newValue = max;
        } else {
          newValue = parseFloat(oldValue);
        }
      } else if (this['is-social-handle'] && oldValue) {
        // If the user typed their own hashtag, strip it.
        newValue = oldValue.replace('@', '').replace(/\s/g, '');
      }

      if (this['two-way']) {
        set(this, 'value', newValue);
        deprecate('Please use {{mdc-textfield}}', false, {
          id: 'ss-input-two-way',
          until: 'Ember 4',
          for: 'ss-input',
          since: '0.0.0',
        });
      } else {
        this.onblur(newValue);
      }
    }
  },
  focusIn() {
    if (this['focus-in']) {
      this['focus-in']();
    }
  },
  //endregion
});
