import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';

export default class extends Route<Record<string, never>> {
  @service declare router: RouterService;

  get organization() {
    return (this.modelFor('organizations.organization') as any).organization;
  }

  beforeModel() {
    void this.router.transitionTo('organizations.organization.email.message-body-templates');
  }
}
