import Route from '@ember/routing/route';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default class IndexRoute extends Route {
  @service('router') router;

  redirect(model, transition) {
    if (transition.to.queryParams.forceReload) {
      next(() => window.location.reload(true));

      return;
    }

    this.router.replaceWith('organizations');
  }
}
