/* eslint-disable ember/no-mixins, ember/no-computed-properties-in-native-classes */
import Controller, { inject as controller } from '@ember/controller';
import { computed, get } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import HasCategoricalCharts from 'partner/mixins/has-categorical-charts';

export default class extends Controller.extend(HasCategoricalCharts) {
  @controller('organizations.organization.data.audiences.audience') audienceController;
  @controller('organizations.organization.data.audiences') audiencesController;

  /**
   * Is the report being generated/downloaded?
   * @type {Boolean}
   */
  @tracked isDownloadingReport = false;

  /**
   * The audience used for generating a report download
   * @type {Object?}
   */
  @tracked selectedReportAudience = null;

  //region Computed Properties
  @readOnly('audiencesController.downloadInProcess')
  disableDownload;

  @readOnly('audienceController.model.audience')
  audience;

  @readOnly('audience.latestCount')
  latestCount;

  @readOnly('audience.latestCountDate')
  latestCountDate;

  @readOnly('audience.isCalculating')
  isCalculating;

  @computed('postalCodeChart.reverseSortedCategories.@each.label')
  get transformedPostalCodeData() {
    return this.postalCodeChart.reverseSortedCategories.filter(x => get(x, 'label') !== 'Unknown');
  }

  @computed('transformedPostalCodeData.@each.label')
  get topPostalCode() {
    return this.transformedPostalCodeData?.firstObject?.label;
  }

  @computed('audience.id')
  get categoricalChartParams() {
    return { audienceId: this.audience.id };
  }

  @computed('audience.{id,latestCountDate,dateCreated}')
  get eventChartParams() {
    return {
      audienceId: this.audience.id,
      endDate: this.audience.latestCountDate.toISOString(),
      startDate: this.audience.dateCreated.toISOString(),
    };
  }
}
