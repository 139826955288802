/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { set } from '@ember/object';
import { gte } from '@ember/object/computed';

/**
 * Used for displayiing individual errors that get passed in.
 */
export default Component.extend({
  //region Ember Hooks
  classNames: ['error-display'],
  //endregion

  //region Attributes
  /**
   * Pass in the errors to be displayed
   * @type Errors []
   */
  errors: null,
  //endregion

  //region Properties
  showAllErrors: false,
  //endregion

  //region Computed Properties
  hasMultipleErrors: gte('errors.length', 2).readOnly(),
  //endregion

  //region Actions
  actions: {
    showAllErrors() {
      set(this, 'showAllErrors', true);
    },
  },
  //endregion
});
