/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import MessageCampaignSnackbar from 'partner/mixins/message-campaign-snackbar';
import RSVP from 'rsvp';

const MESSAGE_CAMPAIGN_ROUTE = 'organizations.organization.message-campaigns.message-campaign';
const SETUP_ROUTE = `${MESSAGE_CAMPAIGN_ROUTE}.setup`;

export default Route.extend(MessageCampaignSnackbar, {
  //region Ember Hooks
  enums: service(),
  snackbar: service(),
  store: service(),

  model() {
    // TODO: messagecampaign vs singleMessageCampaign causes issues such as DripCampaign vs SingleMessageCampaign
    //       Drip is not a single message campaign
    const { messageCampaign, singleMessageCampaign } = this.modelFor(MESSAGE_CAMPAIGN_ROUTE);
    const { dateFields } = this.modelFor(SETUP_ROUTE);
    return {
      messageCampaign,
      //region HAX
      // This and things that rely upon it should be refactored so the model accurately reflects that this is a
      // MessageCampaign and not a SingleMessageCampaign, but it's been like this for three years and just putting
      // it back is a hotfix to fix a production bug.
      singleMessageCampaign: singleMessageCampaign || messageCampaign,
      //endregion
      messages: messageCampaign.messages,
      dateFields,
    };
  },
  afterModel(model) {
    this._super(...arguments);
    if (model.messageCampaign.isDripCampaign && model.messageCampaign.isConfirmed) {
      this.snackbar.show('The campaign is sending and this step can no longer be edited.', '', -1, null, true);
    }
    const { messageCampaign } = this.modelFor(MESSAGE_CAMPAIGN_ROUTE);
    switch (messageCampaign.messageCampaignType) {
      case 'DripCampaign': {
        messageCampaign.messages.forEach((message, index) => {
          if (!message.schedule?.targetEntityTypeId) {
            const newSchedule = this.store.createRecord('schedule', {
              delayValue: index === 0 ? 0 : 1,
              delayDatepartTypeId:
                index === 0
                  ? this.enums.findWhere('DATEPART_TYPE', { name: 'EverySecond' })
                  : this.enums.findWhere('DATEPART_TYPE', { name: 'Daily' }),
              targetEntity: message,
              targetEntityTypeId: this.enums.findWhere('ENTITY_TYPE', {
                name: 'Messaging Message',
              }),
              scheduleTypeId:
                index === 0
                  ? this.enums.findWhere('SCHEDULE_TYPE', {
                      name: 'JoinedAudienceEvent',
                    })
                  : this.enums.findWhere('SCHEDULE_TYPE', {
                      name: 'RelatedMessageSent',
                    }),
              relatedEventEntityId: index === 0 ? null : messageCampaign.messages.objectAt(index - 1).id,
            });
            set(message, 'schedule', newSchedule);
            newSchedule.save();
          }
        });
        break;
      }
      default: {
        // this is where we're setting default values
        if (
          model.messageCampaign.messageVersions.length > 1 &&
          model.messageCampaign.messageTestWinningCriteriaId === 0 &&
          model.messageCampaign.messageTestPercentage === 0
        ) {
          set(model, 'messageCampaign.messageTestWinningCriteriaId', 1);
          set(model, 'messageCampaign.messageTestPercentage', 10);
          set(model, 'messageCampaign.messageTestDurationOpenCount', 200);
          set(model, 'messageCampaign.messageTestDurationMinutes', 240);
        }
        break;
      }
    }
  },
  //#endregion

  //region Methods
  _save() {
    const { messageCampaign } = this.modelFor(MESSAGE_CAMPAIGN_ROUTE);

    //if campaign was changed to sendOnConfirm, uncheck the Confirmation box and the Confirmation step
    const sendOnConfirmWasSet = messageCampaign.changedAttributes()['sendOnConfirm'];
    if (sendOnConfirmWasSet && sendOnConfirmWasSet[1]) {
      this.controller.unconfirmCampaign();
    }

    if (messageCampaign.usesSingleMessageCampaign) {
      set(this, 'controller.isAnythingSaving', true);

      // If a birthday or wedding anniversary campaign is scheduled and a start date is applied, uncheck the Confirmation box and the Confirmation step
      const isAnnualCampaignType = messageCampaign.isBirthday || messageCampaign.isWeddingAnniversary;
      const messageCampaignIsScheduled = messageCampaign.messageSendingStatusType === 'Scheduled';

      const scheduleStartDateWasSet = messageCampaign.singleSchedule.changedAttributes()['scheduleStartDate'];
      // checks that the start date has been changed, that it did not have an old value, and that it now has a new value
      const scheduleStartDateIsNew =
        scheduleStartDateWasSet && !scheduleStartDateWasSet[0] && !!scheduleStartDateWasSet[1];

      if (isAnnualCampaignType && messageCampaignIsScheduled && scheduleStartDateIsNew) {
        this.controller.unconfirmCampaign();
      }

      // messageCampaign.saveAll() saves the
      // dirty messages and schedules as well
      return messageCampaign
        .saveAll()
        .then(() => {
          if (this.controller.isChecklistStepSatisfied) {
            this.send('checkChecklistStep');
          } else {
            this.send('uncheckChecklistStep');
          }
          return RSVP.resolve(true);
        })
        .catch(() => {
          if (!messageCampaign.usesSingleMessage) {
            const saveError = messageCampaign.errors.firstObject;

            const errorAttribute = saveError.attribute;
            const errorDetails = saveError.message;
            //triggers the recurringType to null out
            this._rollback();
            //puts the recurringType back to saved state
            this._rollback();
            messageCampaign.forceDirty();
            messageCampaign.errors.add(errorAttribute, errorDetails);
          }
        })
        .then(() => set(this, 'controller.isAnythingSaving', false));
    }
    set(this, 'controller.isAnythingSaving', true);

    return RSVP.all(
      messageCampaign.schedules.map(schedule => {
        if (schedule.hasDirtyAttributes) {
          return schedule.save();
        }
        return null;
      })
    )
      .then(() => {
        if (this.controller.isChecklistStepSatisfied) {
          this.send('checkChecklistStep');
        } else {
          this.send('uncheckChecklistStep');
        }
        return RSVP.resolve(true);
      })
      .finally(() => {
        set(this, 'controller.isAnythingSaving', false);
      });
  },
  _rollback() {
    const { messageCampaign } = this.modelFor(this.routeName);
    messageCampaign.rollBackAll();
  },
  //endregion

  //region Actions
  actions: {
    save() {
      return this.controller.isAnythingDirty ? this._save() : null;
    },
    saveAndContinue() {
      this._save()
        .then(() => {
          if (!this.controller.isAnythingDirty) {
            this.send('continue');
          }
        })
        .catch(e => {
          console.error(e);
        });
    },
    willTransition(transition) {
      let confirmed = !this.controller.isAnythingDirty;
      confirmed = confirmed ? confirmed : window.confirm('Are you sure you want to leave without saving?');

      if (confirmed) {
        this._rollback();
        this._super(transition);
        set(this, 'controller.isAnythingSaving', false);
        this.snackbar.hide();
      } else {
        transition.abort();
      }
    },
    unscheduleDrip() {
      const { messageCampaign } = this.modelFor(MESSAGE_CAMPAIGN_ROUTE);
      messageCampaign.messages.forEach((message, index) => {
        const delayValue = index === 0 ? 0 : 1;
        const delayDatepartTypeId = this.enums.findWhere('DATEPART_TYPE', { name: 'Daily' });
        set(message, 'schedule.delayValue', delayValue);
        set(message, 'schedule.delayDatepartTypeId', delayDatepartTypeId);
        set(message, 'schedule.startTime', undefined);
        set(message, 'schedule.updatedTime', undefined);
      });
      return this.controller.isAnythingDirty ? this._save() : null;
    },
    removeMessage(messageToDelete) {
      this.send('removeMessageVersion', messageToDelete.messageVersions.firstObject);
    },
  },
  //endregion
});
