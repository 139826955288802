/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { set } from '@ember/object';
import Evented, { on } from '@ember/object/evented';
import Mixin from '@ember/object/mixin';
import { run } from '@ember/runloop';

/**
 * Tick Tock Mixin
 *
 * Adds a tick method that fires every second, updating a tock property that holds a new Date().
 * You can use tock as a computed property dependent key in order to recompute every second.
 * @mixin
 */
export default Mixin.create(Evented, {
  tickTockTeardown: on('willDestroy', function () {
    clearTimeout(this.tickTimeout);
  }),
  tickTimeout: null,
  tickDuration: 1000,
  tick: on('init', function () {
    run(() => {
      if (!this.isDestroyed) {
        const now = new Date();
        this.trigger('tock', now);
        set(this, 'tock', now);
        set(
          this,
          'tickTimeout',
          setTimeout(() => this.tick(), this.tickDuration)
        );
      }
    });
  }),
  tock: Object.freeze(new Date()),
});
