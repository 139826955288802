/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import enums from 'ember-cli-ss-enums/services/enums';
import moment from 'moment';
import PermittedComponent from 'partner/mixins/permitted-component';
import { makePresets } from 'partner/utils/recurring-delay-presets';
import { DAYS_IN_WEEK, parseMonthly } from 'partner/utils/recurring-pattern';

export default Component.extend(PermittedComponent, {
  //region Ember Hooks
  enums: service(),
  tagName: 'recurring-delay-custom',
  //endregion

  //region Attributes
  /**
   * @type {Number?}
   */
  delay: null,
  /**
   * @type {Number?}
   */
  'type-id': null,
  /**
   * @type {Date?}
   */
  'base-date': null,
  /**
   * @type {String}
   */
  pattern: '',
  /**
   * @type {Boolean}
   */
  disabled: false,
  //endregion

  //region Computed Properties
  /**
   * The disabled property is inherited from PermittedComponent
   * Indicates if the inputs in this component should be disabled
   */
  selectedOption: computed('type-id', 'pattern', {
    get() {
      if (!this['type-id']) {
        return null;
      }
      const type = this.enums.findWhere('DATEPART_TYPE', { id: this['type-id'] }, 'name');
      if (!type) {
        return null;
      }
      if (type === 'Monthly') {
        return get(parseMonthly(this.pattern), 'isWeekdayBased') ? 'MonthlyWeekday' : 'MonthlyDate';
      }
      return type;
    },
    set(key, value) {
      const preset = makePresets(this['base-date'])[value];
      set(this, 'type-id', get(preset, 'type-id'));
      set(this, 'pattern', get(preset, 'pattern'));
      return value;
    },
  }),
  delayInput: computed('delay', {
    get() {
      return `${this.delay}`;
    },
    set(key, value) {
      set(this, 'delay', window.parseInt(value, 10));
      return `${value}`;
    },
  }),
  baseDateWeekday: computed('base-date', function () {
    return moment.weekdays()[moment(this['base-date']).day()];
  }),
  baseDateOccurrence: computed('base-date', function () {
    return Math.ceil(moment(this['base-date']).date() / DAYS_IN_WEEK);
  }),
  isWeekly: equal('type-id', enums.findWhere('DATEPART_TYPE', { name: 'Weekly' })),
  //endregion

  //region Actions
  actions: {
    recurringTypeChanged(recurringType) {
      set(this, 'type-id', get(recurringType, 'id'));
    },
    optionSelected() {
      const selectElement = this.element.querySelector('select');
      set(this, 'selectedOption', selectElement.value);
    },
  },
  //endregion
});
