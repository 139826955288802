/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import moment from 'moment';

export default Component.extend({
  material: false,
  time: computed(() => new Date()),
  'is-first-disabled': false,
  'is-second-disabled': false,
  classNameBindings: ['datePickerClasses'],
  datePickerClasses: 'ssField ssDoubleDatePicker',
  'disable-past-dates': true,
  timeZone: computed('time', function () {
    return `All times are in your local time (GMT${moment(this.time).format('Z')})`;
  }),
});
