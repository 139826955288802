import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.sortedChecklistSteps}}\n  <Ui::Layout::SetupStepsWrapper\n    @collection={{this.sortedChecklistSteps}}\n    @currentItem={{@currentChecklistStep}}\n    test-checklist-steps\n    ...attributes\n    as |step|\n  >\n    <Ui::Layout::SetupStep\n      @step={{step}}\n      @promotionSubTypeId={{@promotionSubTypeId}}\n      @promotionSubType={{@promotionSubType}}\n      test-checklist-step\n    />\n  </Ui::Layout::SetupStepsWrapper>\n{{else}}\n  <div role=\"presentation\" class=\"h-5\"></div>\n{{/if}}\n", {"contents":"{{#if this.sortedChecklistSteps}}\n  <Ui::Layout::SetupStepsWrapper\n    @collection={{this.sortedChecklistSteps}}\n    @currentItem={{@currentChecklistStep}}\n    test-checklist-steps\n    ...attributes\n    as |step|\n  >\n    <Ui::Layout::SetupStep\n      @step={{step}}\n      @promotionSubTypeId={{@promotionSubTypeId}}\n      @promotionSubType={{@promotionSubType}}\n      test-checklist-step\n    />\n  </Ui::Layout::SetupStepsWrapper>\n{{else}}\n  <div role=\"presentation\" class=\"h-5\"></div>\n{{/if}}\n","moduleName":"partner/components/ui/layout/setup-steps.hbs","parseOptions":{"srcName":"partner/components/ui/layout/setup-steps.hbs"}});
/* eslint-disable ember/no-computed-properties-in-native-classes */
import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default class UiLayoutSetupStepsComponent extends Component {
  @service('router') router;
  @service('checklist') checklistService;

  stepSorting = ['displayOrder'];

  @sort('knownChecklistSteps', 'stepSorting')
  sortedChecklistSteps;

  get checklistSteps() {
    return this.args.checklist?.checklistSteps || [];
  }

  @computed('checklistSteps.@each.{checklistStepTypeId,destination}')
  get knownChecklistSteps() {
    return this.checklistSteps.filter(
      checklistStep =>
        !!this.checklistService.getChecklistStepEnum(checklistStep.checklistStepTypeId) &&
        this.router._router.hasRoute(checklistStep.destination)
    );
  }
}
