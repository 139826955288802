/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { filter } from '@ember/object/computed';

const percentOf = (num, denom) => (num ? (num / denom) * 100 : 0);
const percentReducer = (acc, { percent }) => acc + percent;
const indexBefore = outerIndex => (item, innerIndex) => innerIndex <= outerIndex;

const type = li => get(li, 'promotion.promotionType') || get(li, 'messageCampaignType') || get(li, 'audienceType');
const isTypeIn = types => li => types.includes(type(li));
const stackPercentages = (bar, i, bars) => ({
  className: bar.className,
  percent: bars.filter(indexBefore(i)).reduce(percentReducer, 0),
});

const CLASS_NAME_MAP = {
  Sweepstakes: ['Sweepstakes', 'UGCSweepstakes'],
  PhotoVotingStandard: ['UGCVoting'],
  PickemStandard: ['Pickem', 'LegacyBracket'],
  Survey: ['Survey', 'Poll'],
  BallotVotersChoice: ['VotersChoice', 'Ballot', 'VotingBracket'],
  QuizPersonality: ['Quiz'],
  PhotoGallery: ['UGCGallery'],
  SingleEmail: ['SingleEmail', 'SingleSMS', 'Invite'],
  Newsletter: ['Newsletter'],
  DripCampaign: ['DripCampaign', 'Birthday', 'Welcome', 'ThankYou', 'WeddingAnniversary', 'Reminder', 'Receipt'],
  SubscriptionAudience: ['Subscription'],
  SegmentedAudience: ['Segmented', 'Advertiser'],
  EventSignup: ['EventSignup'],
  PaymentForm: ['PaymentForm'],
};

export default Component.extend({
  // //region Attributes
  /**
   * @type {Array<OrganizationPromotion|MessageCampaign>}
   */
  'list-items': null,
  //endregion

  //region Computed Properties
  _nonOfferListItems: filter('list-items', li => get(li, 'promotion.promotionType') !== 'Offers'),

  _colorBars: computed('list-items.[]', '_nonOfferListItems.length', function () {
    const items = this['list-items'];

    if (!items) {
      return [];
    }
    return Object.entries(CLASS_NAME_MAP)
      .map(([className, types]) => ({
        className,
        percent: percentOf(items.filter(isTypeIn(types)).length, get(this, '_nonOfferListItems.length')),
      }))
      .rejectBy('percent', 0)
      .sortBy('percent')
      .map(stackPercentages)
      .reverse();
  }),
  //endregion
});
