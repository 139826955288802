/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr, belongsTo } from '@ember-data/model';
import { alias, and, equal, not } from '@ember/object/computed';
import BaseModel from 'secondstreet-common/models/base';
import MatchupModel from './matchup';
import MatchupEntryModel from './matchup-entry';

type MatchupEntryFieldValue<T extends keyof MatchupEntryModel> = NonNullable<MatchupEntryModel[T]>['value'];

export default class MatchupEntryRankModel extends BaseModel {
  @attr('number') declare rank: number;
  @attr('number') declare points: number;
  @attr('date') declare nextEligibilityDate: Date;

  @belongsTo('matchup', { async: false }) declare matchup: MatchupModel;
  @belongsTo('matchup-entry', { async: false }) declare matchupEntries: MatchupEntryModel;
  @alias('matchupEntries') declare matchupEntry: MatchupEntryRankModel['matchupEntries'];

  // TODO: Check if those are needed
  @alias('matchupEntry.entry.organizationUser') organizationUser?: MatchupEntryModel['entry']['organizationUser'];
  @alias('matchupEntry.twitterUsername.value') twitterHandle?: MatchupEntryFieldValue<'twitterUsername'>;
  @alias('matchupEntry.username.value') username?: MatchupEntryFieldValue<'username'>;
  @alias('matchupEntry.dateCreated') dateCreated?: MatchupEntryModel['dateCreated'];
  @alias('matchupEntry.sourceType.value') sourceType?: MatchupEntryFieldValue<'sourceType'>;
  @alias('matchupEntry.sourceMediaType.value') sourceMediaType?: MatchupEntryFieldValue<'sourceMediaType'>;
  @alias('matchupEntry.sourcePostUrl.value') sourcePostUrl?: MatchupEntryFieldValue<'sourcePostUrl'>;
  @alias('matchupEntry.sourcePostId.value') sourcePostId?: MatchupEntryFieldValue<'sourcePostId'>;
  @alias('matchupEntry.mediaCaption.value') mediaCaption?: MatchupEntryFieldValue<'mediaCaption'>;
  @alias('matchupEntry.mediaTitle.value') mediaTitle?: MatchupEntryFieldValue<'mediaTitle'>;
  @alias('matchupEntry.photoUpload.value') photoUpload?: MatchupEntryFieldValue<'photoUpload'>;
  @alias('matchupEntry.videoUpload.value') videoUpload?: MatchupEntryFieldValue<'videoUpload'>;
  @alias('matchupEntry.fullSizeImageUrl.value') fullSizeImageUrl?: MatchupEntryFieldValue<'fullSizeImageUrl'>;
  @alias('matchupEntry.videoUrl.value') videoUrl?: MatchupEntryFieldValue<'videoUrl'>;
  @alias('matchupEntry.videoLowResUrl.value') videoLowResUrl?: MatchupEntryFieldValue<'videoLowResUrl'>;
  @alias('matchupEntry.videoPreviewImageUrl.value')
  videoPreviewImageUrl?: MatchupEntryFieldValue<'videoPreviewImageUrl'>;

  @equal('sourceMediaType', 'video') declare isVideo: boolean;
  @not('isVideo') declare isPhoto: boolean;
  @equal('sourceType', 'YouTube') declare isFromYouTube: boolean;
  @equal('sourceType', 'SecondStreet') declare isSecondStreetHosted: boolean;
  @equal('sourceType', 'Instagram') declare isFromInstagram: boolean;
  @and('isVideo', 'isSecondStreetHosted') declare isUploadedVideo: boolean;
  @and('isPhoto', 'isSecondStreetHosted') declare isUploadedPhoto: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'matchup-entry-rank': MatchupEntryRankModel;
  }
}
