/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import ResetScrollRoute from 'partner/mixins/reset-scroll-route';

/**
 * Audiences New Route
 * /o/:organization_id/data/audiences/new
 * @type {Ember.Route}
 * @mixes AppContextRoute
 * @mixes ResetScrollRoute
 */
export default Route.extend(AppContextRoute, ResetScrollRoute, {
  router: service(),
  store: service(),

  //region Ember Hooks
  async model(params) {
    const audienceTypeId = parseInt(params.audience_type_id, 10);

    return {
      audience: this.store.createRecord('audience', { audienceTypeId }),
      hasData: get(this.modelFor('organizations.organization'), 'organization.hasData'),
      fields: get(this.modelFor('organizations.organization.data.audiences'), 'fields'),
    };
  },

  setupController(controller, model) {
    set(controller, 'model', model);
    set(controller, 'visibleWhen', 'organizations.organization.data.audiences.new');
  },
  //endregion

  //region Actions
  actions: {
    audienceSaved(audience, showUploadModal) {
      const audiencesModel = this.modelFor('organizations.organization.data.audiences');
      const subRoute = get(audience, 'isSubscriptionAudience') ? 'setup' : 'filters';

      set(audiencesModel, 'audiences', audiencesModel.audiences.slice().addObject(audience).sortBy('name'));
      this.router.transitionTo(`organizations.organization.data.audiences.audience.${subRoute}`, get(audience, 'id'), {
        queryParams: { showUploadModal },
      });
    },
    stopAdding() {
      this.router.transitionTo('organizations.organization.data.audiences.index');
    },
    toggleEditNameModal() {
      this.controller.toggleProperty('isEditingName');
    },
  },
  //endregion
});
