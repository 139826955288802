import { attr, belongsTo } from '@ember-data/model';
import enums from 'ember-cli-ss-enums/services/enums';
import type { UploaderValue } from 'partner/components/form/uploader';
import BaseModel from 'secondstreet-common/models/base';

export default class MediaItemModel extends BaseModel {
  @attr('number') declare mediaTypeId: number;
  @attr('number') declare mediaHostTypeId: number;
  @attr('string') declare dipsUrl: string;
  @attr('string') declare fileName: string;
  @attr('number') declare size: number;
  @attr('string') declare contentType: string;
  @attr('string') declare externalSourceUrl: string;
  @attr('number') declare currentVersionNumber: number;
  @attr('number') declare height: number;
  @attr('number') declare width: number;

  @belongsTo('field', { async: false }) field: any;

  @enums.computed('name', 'mediaHostTypeId', 'mediaHostTypeId', 'MEDIA_HOST_TYPE') mediaHostType?: string;

  get croppedPopunderRetinaFacesUrl() {
    return `${this.dipsUrl}?cropmode=fill&anchor=faces&width=1440&height=600&quality=60`;
  }

  get croppedRetinaFacesUrl() {
    return `${this.dipsUrl}?cropmode=fill&anchor=faces&width=1170&height=1170&quality=60`;
  }

  get croppedRetinaUrl() {
    return `${this.dipsUrl}?width=1170&height=1170&quality=60&format=auto`;
  }

  get isYouTube() {
    return this.mediaHostType == 'YouTube';
  }

  get asUploaderValue(): UploaderValue {
    if (this.isYouTube) return { type: 'youtube', value: this.fileName };
    if (this.externalSourceUrl) return { type: 'external', value: this.externalSourceUrl };

    return {
      type: this.contentType?.startsWith('video') ? 'video' : 'image',
      value: this.id,
    };
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'media-item': MediaItemModel;
  }
}
