/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';

export default Controller.extend({
  //region Properties
  /**
   * The sender account being edited/created in the
   * {{sender-account-form}} modal
   * @type {SenderAccount?}
   */
  currentSenderAccount: null,
  /**
   * Toggle for showing/hiding {{sender-account-form}}
   * @type {Boolean}
   */
  showSenderAccountForm: false,
  //endregion
});
