/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

export default BaseModel.extend({
  enums: service(),

  //region Properties
  address1: attr('string'),
  address2: attr('string'),
  address3: attr('string'),
  city: attr('string'),
  county: attr('string'),
  stateProvinceId: attr('number'),
  countryId: attr('number'),
  postalCode: attr('string'),
  isPrimary: attr('boolean'),
  //endregion

  //region Computed Properties
  stateProvinceName: computed('stateProvinceId', function () {
    return this.enums.findWhere('STATE_PROVINCE', { id: this.stateProvinceId }, 'name');
  }),
  stateProvinceCountryId: computed('stateProvinceId', function () {
    return this.enums.findWhere('STATE_PROVINCE', { id: this.stateProvinceId }, 'countryId');
  }),
  countryName: computed('countryId', function () {
    return this.enums.findWhere('COUNTRY', { id: this.countryId }, 'name');
  }),
  displayedOrganizationName: computed(
    'address1',
    'address2',
    'address3',
    'city',
    'stateProvinceId',
    'postalCode',
    'stateProvinceName',
    function () {
      return [this.address1, this.address2, this.address3, this.city, this.stateProvinceName, this.postalCode]
        .filter(Boolean)
        .join(', ');
    }
  ),
  //endregion

  //region Relationships
  organization: belongsTo('organization', {
    async: false,
  }),
  //endregion
});
