/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/* eslint-disable ember/no-side-effects, ember/no-deeply-nested-dependent-keys-with-each */ // FIXME
import Component from '@ember/component';
import EmberObject, { computed, get, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import { isNone } from '@ember/utils';
import $ from 'jquery';
import flattenDeep from 'lodash/flattenDeep';
import TemplateDesigner from 'partner/mixins/template-designer';
import { collectGrandchildren } from 'partner/utils/computed';

const GOOGLE_MAP_API_KEY = 'AIzaSyA1xYP1aDhEXMjK_vUO2H481tkrT2gFBIM';

export default Component.extend(TemplateDesigner, {
  enums: service(),

  //region Ember Hooks
  classNames: ['template-designer--sales-sheet'],
  classNameBindings: ['audienceType'],
  attributeBindings: ['style'],

  init() {
    this._super(...arguments);
    // NVD3 is dumb and requires direct script imports rather than enabling installation via NPM
    $.getScript('/assets/salessheets/material/d3.min.js', () => {
      $.getScript('/assets/salessheets/material/nv.d3.min.js', () => {
        $.getScript('/assets/salessheets/material/markerclusterer_compiled.js', () => {
          this.runSalesSheetScripts();
        });
      });
    });

    if (window.google === undefined || window.google.maps === undefined) {
      $.ajax({
        url: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&sensor=false`,
        dataType: 'script',
        cache: true,
      });
    }
  },

  didInsertElement() {
    this._super(...arguments);
    //If the element is not empty, we should indicate that it "is adding"
    //This prevents the element from disabling when you clear text
    if (get(this, 'customTokenContent.mediaItemId') || get(this, 'customTokenContent.elementContent')) {
      set(this, 'isAdding', true);
    }
  },
  willDestroyElement() {
    this._super(...arguments);
    // Because sales-sheets use NVD3 which require an old version of D3, we need to clear out the global variable
    // before navigating to other pages which potentially need C3 to take over that variable for an updated D3
    window.d3 = undefined;
  },
  //endregion

  //region Attributes
  'is-loading': false,
  //endregion

  //region Properties
  isAdding: false,
  previewHeight: null,
  isSalesSheetDesigner: true,
  isAddingImageFromLibrary: false,
  //endregion

  //region Computed Properties
  audienceType: alias('audience.audienceType'),
  style: computed('previewHeight', function () {
    return `min-height: ${this.previewHeight}`;
  }),
  uniqueTemplateElements: collectGrandchildren('page-template-presets', 'templateElements'),
  selectedTemplatePreset: computed('page-template-presets.pageTemplatePresetId', function () {
    return this['page-template-presets'].findBy('id', get(this, 'page-template.pageTemplatePresetId').toString());
  }),
  /**
   * Unique Template Elements, used mainly for isAnythingDirty
   * @returns {TemplateElement[]} - Array of unique TemplateElements that belong to the PageTemplateVersions
   */
  uniqueElements: computed('page-template-presets.@each.templateElements', function () {
    return flattenDeep(this['page-template-presets'].map(preset => get(preset, 'templateElements').toArray())).uniq();
  }),
  /**
   * The categories of TemplateElements to display to the user, properly sorted
   *
   * @returns {Array} - An array of objects representing a category. Structure:
   *     { name: (string), icon: (string), sortOrder: (number), elements: (TemplateElement[]) }
   */
  templateElementCategories: computed(
    'uniqueTemplateElements.@each.{templateElementCategory,templateElementSortOrder}',
    function () {
      const templateElements = this.uniqueTemplateElements;
      const categories = templateElements
        .map(x => get(x, 'templateElementCategory'))
        .compact()
        .uniq()
        .map(name =>
          EmberObject.create({
            category: name,
            icon: this.enums.findWhere('TEMPLATE_ELEMENT_TYPE_CATEGORY', { name }, 'icon'),
            sortOrder: this.enums.findWhere('TEMPLATE_ELEMENT_TYPE_CATEGORY', { name }, 'sortOrder'),
            elements: [],
          })
        )
        .sortBy('sortOrder');
      templateElements.forEach(templateElement => {
        // Re-use an existing category object, adding the template element to it.
        const existingCategory = get(
          categories.filterBy('category', get(templateElement, 'templateElementCategory')),
          'firstObject'
        );
        if (existingCategory) {
          // So that we don't create a copy of the array during sorting, insert the new
          // template element directly into the proper index.
          let desiredIndex = 0;
          get(existingCategory, 'elements').forEach((existingTemplateElement, i) => {
            if (
              get(existingTemplateElement, 'templateElementSortOrder') <
              get(templateElement, 'templateElementSortOrder')
            ) {
              desiredIndex = i + 1;
            }
          });
          get(existingCategory, 'elements').insertAt(desiredIndex, templateElement);
        }
      });
      return categories;
    }
  ),
  parsedTemplate: computed(
    'selectedTemplatePreset',
    'uniqueElements.@each.{elementContent,mediaItemId}',
    'audience',
    'categorical-charts{,.@each.categories}',
    function () {
      const elements = this.uniqueElements;
      if (elements) {
        const template = get(this, 'selectedTemplatePreset.compiledTemplateContent');
        return template(this.getTokenContext());
      }
      return null;
    }
  ),
  //endregion

  //region Methods
  /* prettier-ignore */
  runSalesSheetScripts() {
    // region HAX
    // This code is copied from sales-sheets and should not be changed without also changing the same code inside of
    // consumer-salessheets/material/index.hbs
    /* eslint-disable */
    const iframeElement = this.element.querySelector('iframe');
    const salesSheet = iframeElement?.contentDocument;
    if(!salesSheet){
      return;
    }
    //While testing check if this change returns contentDocument

    let chartData = {};
    const tokenContext = this.getTokenContext();
    chartData.tagChart = JSON.parse(tokenContext.topTagsChartData);
    chartData.postalCodeChart = JSON.parse(tokenContext.postalCodeChartData);
    chartData.birthdateChart = JSON.parse(tokenContext.birthdateChartData);
    chartData.genderChart = JSON.parse(tokenContext.genderChartData);
    chartData.audienceChart = JSON.parse(tokenContext.audienceCountData);
    chartData.color = tokenContext.salesSheetColor;

    // Parse JSON
    chartData.tagChart = chartData.tagChart ? chartData.tagChart : null;
    chartData.postalCodeChart = chartData.postalCodeChart ? chartData.postalCodeChart : null;
    chartData.birthdateChart = chartData.birthdateChart ? chartData.birthdateChart : null;
    chartData.genderChart = chartData.genderChart ? chartData.genderChart : null;

    const configData = {
      audienceChart: JSON.parse(tokenContext.audienceCountConfiguration),
      tagChart: JSON.parse(tokenContext.topTagsChartConfiguration),
      postalCodeChart: JSON.parse(tokenContext.postalCodeChartConfiguration),
      birthdateChart: JSON.parse(tokenContext.birthdateChartConfiguration),
      genderChart: JSON.parse(tokenContext.genderChartConfiguration)
    };

    renderLabelsFromConfig(configData);

    const nullMessage = 'There is no data.';
    if(salesSheet.getElementById('tags-placeholder')){
      salesSheet.getElementById('tags-placeholder').innerHTML = chartData.tagChart ? renderTopTagsTemplate(chartData.tagChart, 5) : nullMessage;
    }
    if(salesSheet.getElementById('gender-chart')) {
      salesSheet.getElementById('gender-chart').innerHTML = chartData.genderChart ? renderGenderTemplate(chartData.genderChart) : nullMessage;
    }

    if(salesSheet.getElementById('audience-count-data')) {
      salesSheet.getElementById('audience-count-data').innerHTML = chartData.audienceChart ? formatInteger(chartData.audienceChart) : nullMessage;
    }

    if (chartData.postalCodeChart) {
      renderGoogleMap(chartData.postalCodeChart, tokenContext.salesSheetColor);
    }else if(salesSheet.getElementById('location-google-map')){
      salesSheet.getElementById('location-google-map').innerHTML = nullMessage;
    }

    if (chartData.birthdateChart && configData.birthdateChart.isVisible) {
      renderAgeChart(chartData.birthdateChart, chartData.color);
    }else if(salesSheet.getElementById('age-chart-placeholder')){
      salesSheet.getElementById('age-chart-placeholder').innerHTML = nullMessage;
    }

    hideElementsFromConfig(configData);

    function renderLabelsFromConfig(data) {
      [
        ['audience-count-label', 'audienceChart'],
        ['filtered-tags-label', 'tagChart'],
        ['location-label', 'postalCodeChart'],
        ['age-label', 'birthdateChart'],
        ['gender-label', 'genderChart']
      ].forEach(function(pair) {
        var id = pair[0], key = pair[1];
        if (id && salesSheet.getElementById(id)) {
          salesSheet.getElementById(id).innerHTML = data[key].label;
        }
      });
    }

    function hideElementsFromConfig(data) {
      [
        ['audience-size', 'audienceChart'],
        ['filtered-tags', 'tagChart'],
        ['location', 'postalCodeChart'],
        ['age', 'birthdateChart'],
        ['gender', 'genderChart']
      ].forEach(function(pair) {
        if (!data[pair[1]].isVisible) {
          var parent = salesSheet.getElementById(pair[0]).parentElement;
          parent.removeChild(salesSheet.getElementById(pair[0]));
        }
      });
    }

    function formatInteger(number) {
      // Simplified version of accounting#format from accounting.js
      // https://github.com/openexchangerates/accounting.js/blob/master/accounting.js#L232-L263
      var base = number + "", mod = base.length > 3 ? base.length % 3 : 0;
      return (mod ? base.substr(0, mod) + ',' : "") + base.substr(mod).replace(/(\d{3})(?=\d)/g, "$1" + ',') + "";
    }

    function renderTopTagsTemplate(tagTableData, numToDisplay) {
      var chartCategories, categories, html, numToActuallyDisplay;
      chartCategories = tagTableData.categorical_charts[0].categorical_chart_categories;
      categories = chartCategories
          .filter(function(category) {
            return category.name !== "Unknown";
          })
          .sort(function(a, b) {
            return b.count - a.count;
          })
      ;

      // Render top tags
      html = '<table><tbody>';
      numToActuallyDisplay = numToDisplay > chartCategories.length ? chartCategories.length : numToDisplay;
      for (var x = 0; x < numToActuallyDisplay; x++) {
        html += '<tr>';
        html += '<td class="tag"><i class="icon icon-tag"></i> ' + categories[x].name + '</td>';
        html += '<td class="users">' + formatInteger(categories[x].count) + ' People</td>';
        html += '</tr>';
      }
      html += '</tbody>';

      // Count remaining categories
      if (numToActuallyDisplay < categories.length) {
        var remainingCategoryCount = categories.length - numToActuallyDisplay;
        html += '<tfoot><tr><td colspan="2">... And ' + remainingCategoryCount + ' more</td></tr></tfoot>';
      }

      html+= '</table>';

      return html;
    }

    function colorToRGBA(color, alpha) {
      var rgba;
      if (color.substring(0, 1) == '#') {
        rgba = [hexToR(color), hexToG(color), hexToB(color)];
      }
      else if (color.substring(0, 3).toLowerCase() == 'rgb') {
        rgba = /\((\d+),\s?(\d+),\s?(\d+).*\)/g.exec(color).slice(1,4);
      }
      if (alpha) {
        rgba.push(alpha);
        return 'rgba(' + rgba.join() + ')';
      }
      else {
        return 'rgb(' + rgba.join() + ')';
      }
    }
    function hexToR(h) {return parseInt((cutHex(h)).substring(0,2),16)}
    function hexToG(h) {return parseInt((cutHex(h)).substring(2,4),16)}
    function hexToB(h) {return parseInt((cutHex(h)).substring(4,6),16)}
    function cutHex(h) {return (h.charAt(0)=="#") ? h.substring(1,7):h}

    function getMapMarkers(mapChartData) {
      var categories = mapChartData.categorical_charts[0].categorical_chart_categories
          .filter(function(category) {
                return !!category.latitude &&
                    !!category.longitude &&
                    category.name !== "Unknown" &&
                    (category.latitude != 0 && category.longitude != 0);
              }
          );

      return categories.map(function(category) {
        var latLng = new google.maps.LatLng(category.latitude, category.longitude);
        return new google.maps.Marker({ position: latLng, count: category.count, title: category.name });
      });
    }

    function renderGoogleMap(data, color) {
      if (!salesSheet.getElementById('location-google-map')) return;

      // Load map markers
      var mapMarkers = getMapMarkers(data);

      // Generate map object
      var mapOptions = {
        center: new google.maps.LatLng(39.833333, -98.583333),
        zoom: 11,
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        draggable: false,
        scrollwheel: false,

        // How you would like to style the map.
        // This is where you would paste any style found on Snazzy Maps.
        styles: [{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#d3d3d3"}]},{"featureType":"transit","stylers":[{"color":"#808080"},{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"visibility":"on"},{"color":"#b3b3b3"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"weight":1.8}]},{"featureType":"road.local","elementType":"geometry.stroke","stylers":[{"color":"#d7d7d7"}]},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ebebeb"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"color":"#a7a7a7"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#efefef"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#696969"}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"visibility":"on"},{"color":"#737373"}]},{"featureType":"poi","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#d6d6d6"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"color":"#dadada"}]}]
      };
      var map = new google.maps.Map(salesSheet.getElementById('location-google-map'), mapOptions);
      var salesSheetColorRGBA = colorToRGBA(color,.7);

      // Inject clustered map markers
      var markerCluster = new MarkerClusterer(map, mapMarkers, {
        zoomOnClick: false,
        minimumClusterSize: 1,
        styles: [
          {
            fontFamily: 'Roboto, sans-serif',
            height: 24,
            textColor: '#fff; background: ' + salesSheetColorRGBA + '; border-radius: 50%',
            textSize: 12,
            width: 24
          },
          {
            fontFamily: 'Roboto, sans-serif',
            height: 32,
            textColor: '#fff; background: ' + salesSheetColorRGBA + '; border-radius: 50%',
            textSize: 12,
            width: 32
          },
          {
            fontFamily: 'Roboto, sans-serif',
            height: 48,
            textColor: '#fff; background: ' + salesSheetColorRGBA + '; border-radius: 50%',
            textSize: 12,
            width: 48
          },
          {
            fontFamily: 'Roboto, sans-serif',
            height: 64,
            textColor: '#fff; background: ' + salesSheetColorRGBA + '; border-radius: 50%',
            textSize: 12,
            width: 64
          },
          {
            fontFamily: 'Roboto, sans-serif',
            height: 72,
            textColor: `#fff; background: ${color}; border-radius: 50%`,
            textSize: 12,
            width: 72
          }
        ]
      });

      markerCluster.setCalculator(function(markers, numStyles) {
        var totalUsers, index, firstTitle, isOneTitle, title, dv;
        totalUsers = markers.reduce(function(pValue, marker) {
          return pValue + marker.count;
        }, 0);
        index = 0;
        dv = totalUsers;
        firstTitle = markers[0].title;
        isOneTitle = markers.every(function(marker) { return marker.title === firstTitle; });
        while (dv !== 0) { // Increase hotness in powers of ten.
          dv = parseInt(dv / 10, 10);
          index += 1;
        }
        index = Math.min(index, numStyles); // Don't use a hotness we don't have
        title = isOneTitle ? '' + firstTitle : '';
        return {
          text: totalUsers.toString(),
          index: index,
          title: title
        };
      });

      //Recenter the map on the most common latitude and longitude from our data
      var mostCommonDataPoint = data.categorical_charts[0].categorical_chart_categories.reduce(function(prev, current){
        if(current.name === "Unknown") {
          return prev;
        }
        return current.count > prev.count ? current : prev;
      });
      var mostCommonLatLng = new google.maps.LatLng(mostCommonDataPoint.latitude, mostCommonDataPoint.longitude);
      map.setCenter(mostCommonLatLng);
    }

    /**
     * Round Largest Remainder
     * @param array {Number[]} - An array of numbers that should be rounded
     * @param total {Number} - A number to which the rounded numbers should total
     * @returns {Number[]}
     * @see http://en.wikipedia.org/wiki/Largest_remainder_method
     * @see http://stackoverflow.com/a/13483710
     */
    function roundLargestRemainder(array, total) {
      // Create a map so that when we sort by remainder, we can return in the original order
      var map = {};
      array.forEach(function(x) { map[x] = map[Math.floor(x)]; });

      // Calculate the remaining number of integers to distribute after flooring everything
      var remainder = total - array.reduce(function(value, num) { return Math.floor(num) + value}, 0);

      // Don't sort in-place, make a copy
      array.slice()
      // Sort by the decimal remainder
          .sort(function(a, b) { return (a - Math.floor(a)) > (b - Math.floor(b)) ? -1 : 1; })
          // Distribute 1 to each until there are no more to distribute
          .forEach(function(num, i) { map[num] = Math.floor(num) + (i < remainder ? 1 : 0); })
      ;

      // Return everything in the right order
      return array.map(function(num) { return map[num]; });
    }

    function getAgeChartValues(data) {
      var categories = data.categorical_charts[0].categorical_chart_categories
          .filter(function(object) {
            return object.name !== 'Unknown';
          })
      ;

      var knownCount = 0;
      categories.forEach(function(category) {
        knownCount += category.count;
      });

      categories = categories
          .map(function(category) {
            return {
              'label': category.name.replace(' to ', '-'),
              'value': category.count / knownCount
            };
          })
      ;
      var roundedValues = roundLargestRemainder(categories.map(function(x) { return x.value * 100; }), 100);
      categories = categories
          .map(function(object, i) {
            object.value = roundedValues[i] / 100;
            return object;
          })
      ;
      if (categories.filter(function(x) { return x.value !== 0; }).length > 1) {
        return categories.filter(function(x) { return x.value !== 0; });
      }
      else {
        return categories;
      }
    }

    function renderAgeChart(data, color) {
      var historicalBarChart = [
        {
          key: 'Cumulative Return',
          values: getAgeChartValues(data)
        }
      ];

      nv.addGraph(function() {

        var chart = nv.models.discreteBarChart()
            .x(function(d) { return d.label })
            .y(function(d) { return d.value })
            .color([color])
            .showYAxis(false)
            .staggerLabels(false)
            .tooltips(false)
            .showValues(true)
            .duration(0)
            .valueFormat(d3.format('%'))
        ;
        const svgElement = document.getElementById("html-preview").contentWindow.document.getElementById('age-chart-size-fix').getElementsByTagName('svg')[0];
        d3.select(svgElement)
            .datum(historicalBarChart)
            .call(chart);

        nv.utils.windowResize(chart.update);

        return chart;
      });
    }

    function getGenderChartValues(data) {
      var categories = data.categorical_charts[0].categorical_chart_categories;
      var maleCount = 0;
      var femaleCount = 0;
      categories.forEach(function(category) {
        if (category.name === 'Male') {
          maleCount += category.count;
        }
        else if (category.name === 'Female') {
          femaleCount += category.count;
        }
      });

      return { maleCount: maleCount, femaleCount: femaleCount };
    }

    function renderGenderTemplate(data) {
      var genderCounts, totalCount, isFemaleHighlighted, isMaleHighlighted, femalePercent, malePercent, html;
      genderCounts = getGenderChartValues(data);
      totalCount = genderCounts.femaleCount + genderCounts.maleCount;
      isFemaleHighlighted = genderCounts.femaleCount >= genderCounts.maleCount;
      isMaleHighlighted = !isFemaleHighlighted;
      femalePercent = 0;
      malePercent = 0;
      if (totalCount > 0) {
        var percents = roundLargestRemainder([
          genderCounts.femaleCount / totalCount * 100,
          genderCounts.maleCount / totalCount * 100
        ], 100);
        femalePercent = percents[0];
        malePercent = percents[1];
      }

      // Render gender
      html = '<div id="gender-female"' + (isFemaleHighlighted ? ' class="greater"' : '') + '>';
      html += '<span class="percent">' + femalePercent + '%</span>';
      html += '<i class="icon icon-female"></i>';
      html += '</div>';
      html += '<div id="gender-male"' + (isMaleHighlighted ? ' class="greater"' : '') + '>';
      html += '<span class="percent">' + malePercent + '%</span>';
      html += '<i class="icon icon-male"></i>';
      html += '</div>';
      html += '<div id="gender-unknown">';
      html += '</div>';

      return html;
    }
    /*eslint-enable */
    //endregion HAX
  },

  getTokenContext() {
    const tokenContext = {};
    const elements = this.uniqueElements;
    if (elements) {
      // build context object that contains a key/value for each element
      elements.forEach(element => {
        const { mediaItemId } = element;
        const usesMediaItem = get(element, 'templateElementType.usesMediaItemId');
        tokenContext[element.templateElementType?.token] = !usesMediaItem ? element.elementContent : mediaItemId;
      });
      //Now do the other tokens we have to replace
      tokenContext.audienceCountData = this.audience.latestCount;
      this['categorical-charts'].forEach(chart => {
        const categoricalChartCategories = (chart.categories || []).map(ccc => ({
          count: ccc.value,
          name: ccc.label,
          latitude: ccc.latitude || 0,
          longitude: ccc.longitude || 0,
        }));
        const chartObj = {
          categorical_charts: [
            {
              categorical_chart_categories: categoricalChartCategories,
            },
          ],
        };
        tokenContext[`${camelize(chart.name)}ChartData`] = JSON.stringify(chartObj);
      });

      // Fix any missing data
      ['topTagsChartData', 'postalCodeChartData', 'birthdateChartData', 'genderChartData', 'audienceChartData'].forEach(
        key => {
          if (isNone(get(tokenContext, key))) {
            set(
              tokenContext,
              key,
              JSON.stringify({
                categorical_charts: [
                  {
                    categorical_chart_categories: [],
                  },
                ],
              })
            );
          }
        }
      );
    }
    return tokenContext;
  },
  //endregion

  //region Actions
  /* eslint-disable ember/no-actions-hash */
  actions: {
    save() {
      this.save();
    },
    addMediaItem(newMediaItem) {
      run(() => {
        set(this, 'isAddImageFlyoutOpen', false);
        set(this, 'customTokenContent.mediaItemId', newMediaItem.id);
      });
    },
    findCategoryByDesignElement(element) {
      return this.templateElementCategories.find(category => category.elements.mapBy('id').includes(element.id));
    },
    launchPreview() {
      window.open(this.audience.salesSheetUrl);
    },
    setColor(hex) {
      set(this, 'customTokenContent.elementContent', hex);
    },
    runSalesSheetScripts() {
      if (window.d3) {
        this.runSalesSheetScripts();
      }
    },

    attachMessageListener() {
      this.windowMessageListener = e => {
        try {
          const data = JSON.parse(e.data);

          if (data.type === 'updated') this.send('runSalesSheetScripts');
        } catch (e) {
          console.error(e);
        }
      };

      window.addEventListener('message', this.windowMessageListener);
    },

    removeMessageListener() {
      window.removeEventListener('message', this.windowMessageListener);
    },
  },
  //endregion
});
