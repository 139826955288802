/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import moment from 'moment';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

export default Route.extend(AppContextRoute, {
  //region Dependencies
  enums: service(),
  store: service(),
  navigation: service(),
  //endregion

  //region Ember Hooks
  async model(params) {
    const consumerUserId = get(params, 'consumer_user_id');
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');
    //We need to be sure we reload the consumerUser from the API
    //The ConsumerUser returned on search results is missing isEditable

    const consumerUserAudiences = await this.store.query('consumer-user-audience', { consumerUserId });

    return this.store
      .queryRecord('consumerUser', { id: consumerUserId, organizationId })
      .then(person => person.reload())
      .then(person => {
        const hash = {
          consumerUser: person,
          // both starred and unstarred fields excluding the follow types: optin, facebook like, tweet, twitter follow, and description text
          profileViewFields: this.store.query('field', {
            isProfileView: true,
          }),
          consumerUserAudiences: consumerUserAudiences.toArray(),
        };

        return RSVP.hash(hash);
      });
  },
  afterModel() {
    // tell the outer navigation wrapper we're in a consumer-user
    this.navigation.setEntity({ type: 'ConsumerUser' });
  },
  deactivate() {
    // tell the outer navigation wrapper we're no longer in a consumer-user
    this.navigation.setEntity(null);
  },
  //endregion

  //region Methods
  save(consumerUser) {
    get(consumerUser, 'organizationUserAttributes')
      .filterBy('hasDirtyAttributes')
      .forEach(x => x.save());
    if (get(consumerUser, 'hasDirtyAttributes')) {
      consumerUser.save();
    }
  },
  //endregion

  //region Actions
  actions: {
    save(consumerUser) {
      this.save(consumerUser);
    },
    async createNewOrganizationUserAttribute(field, fieldOptionId = null) {
      const consumerUser = get(this.modelFor('organizations.organization.people.person'), 'consumerUser');

      let startingValue = '';
      switch (get(field, 'fieldType')) {
        case 'NumberInput':
          startingValue = 0;
          break;
        case 'SingleCheckbox':
          startingValue = false;
          break;
        case 'CustomDateInput':
          startingValue = moment(new Date()).format();
          break;
        case 'Checkboxes':
          startingValue = fieldOptionId;
          break;
        default:
          startingValue = '';
          break;
      }

      const newOrgUserAttr = this.store.createRecord('organizationUserAttribute', {
        consumerUser,
        userAttributeTypeId: get(field, 'userAttributeTypeId'),
        value: startingValue,
      });

      await newOrgUserAttr.save();

      get(consumerUser, 'organizationUserAttributes').addObject(newOrgUserAttr);
      consumerUser.save();
    },
  },
  //endregion
});
