import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface AudienceUploadANewListSignature {
  Element: HTMLDivElement;
  Args: {
    toggleViewOnlyFields?: () => void;
    importFileChosen?: () => void;
    cancel?: () => void;
  };
}

export default class AudienceUploadANewListComponent extends Component<AudienceUploadANewListSignature> {
  @tracked canUploadThirdPartyAudience = false;
}
