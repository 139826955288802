import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-action no-curly-component-invocation }}\n<div class=\"postal-code-chart\">\n  {{#ss-permissions-block entities=\"UserReport\" showViewOnlyWarning=false class=\"ssFullSize\"}}\n    {{#if this.postalCodeChart.categories}}\n      {{ss-chart\n        type=this.postalCodeChart.chartType\n        data=this.postalCodeChart.categories\n        selectedSeedColor=\"rgb(44, 121, 229)\"\n        outerHeight=460\n        horizontalMargin=0\n        verticalMargin=0\n        minSlicePercent=0\n        cluster-minimum-cluster-size=1\n      }}\n    {{else}}\n      {{#if this.postalCodeChartCategoriesLoadedAndEmpty}}\n        <div class=\"ssTileContent\">\n          <p>You have not collected any location data yet.</p>\n        </div>\n      {{else}}\n        <div class=\"skeleton-image\"></div>\n      {{/if}}\n    {{/if}}\n  {{/ss-permissions-block}}\n</div>\n", {"contents":"{{! template-lint-disable no-action no-curly-component-invocation }}\n<div class=\"postal-code-chart\">\n  {{#ss-permissions-block entities=\"UserReport\" showViewOnlyWarning=false class=\"ssFullSize\"}}\n    {{#if this.postalCodeChart.categories}}\n      {{ss-chart\n        type=this.postalCodeChart.chartType\n        data=this.postalCodeChart.categories\n        selectedSeedColor=\"rgb(44, 121, 229)\"\n        outerHeight=460\n        horizontalMargin=0\n        verticalMargin=0\n        minSlicePercent=0\n        cluster-minimum-cluster-size=1\n      }}\n    {{else}}\n      {{#if this.postalCodeChartCategoriesLoadedAndEmpty}}\n        <div class=\"ssTileContent\">\n          <p>You have not collected any location data yet.</p>\n        </div>\n      {{else}}\n        <div class=\"skeleton-image\"></div>\n      {{/if}}\n    {{/if}}\n  {{/ss-permissions-block}}\n</div>\n","moduleName":"partner/components/dashboards/postal-code-chart.hbs","parseOptions":{"srcName":"partner/components/dashboards/postal-code-chart.hbs"}});
/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';

export default Component.extend({
  //region Computed Properties
  tagName: '',
  //endregion
});
