/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
/* eslint-disable ember/use-ember-get-and-set */ // FIXME
import { and, not, readOnly } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';

/**
 * A Controller whose model is a single instance of a {@link DS.Model}
 * @mixin
 * @deprecated
 */
export default Mixin.create({
  //region Properties
  isEditing: false,
  //endregion

  //region Computed Properties
  /**
   * Represents that the model or models are in a state that is allowed to be saved.
   * For example if a model is inValid you can NOT call save on it
   */
  canSave: and('isAnythingDirty', 'model.isValid'),
  canCancel: not('model.isValid'),
  isAnythingDirty: readOnly('model.hasDirtyAttributes'),
  isAnythingSaving: readOnly('model.isSaving'),
  //endregion

  //region Actions
  actions: {
    edit() {
      this.set('isEditing', true);
    },
    save() {
      if (this.get('model.isValid') && this.get('model.hasDirtyAttributes')) {
        this.model
          .save() /* this is intentional, error must be consumed */
          .catch(() => {});
      }
      this.set('isEditing', false);
    },
    rollback() {
      this.model.rollbackAttributes();
      this.set('isEditing', false);
    },
  },
  //endregion
});
