/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-mixins */
import { attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import enums from 'ember-cli-ss-enums/services/enums';
import TickTock from 'partner/mixins/tick-tock';
import BaseModel from 'secondstreet-common/models/base';
import type PartnerNotificationUserReadModel from './partner-notification-user-read';

@makeBooleanProperties('PARTNER_NOTIFICATION_TYPE')
export default class PartnerNotificationModel extends BaseModel.extend(TickTock) {
  @attr('string') declare text: string;
  @attr('string') declare url: string;
  @attr('date') declare dateCreated: Date;
  @attr('date') declare dateModified: Date;
  @attr('date') declare endDate: Date;
  @attr('number') declare partnerNotificationTypeId: number;

  tickDuration = 60000;

  @belongsTo('partner-notification-user-read', { async: false })
  declare partnerNotificationUserRead: PartnerNotificationUserReadModel;
  @hasMany('ss-product', { async: false }) declare ssProducts: SyncHasMany<any>;

  @enums.computed('name', 'partnerNotificationTypeId', 'partnerNotificationTypeId', 'PARTNER_NOTIFICATION_TYPE')
  declare partnerNotificationType: string;

  @notEmpty('partnerNotificationUserRead') declare hasChild: boolean;
  @notEmpty('url') declare hasLink: boolean;

  @computed('endDate', 'tock')
  get isPast() {
    return this.endDate <= new Date();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'partner-notification': PartnerNotificationModel;
  }
}
