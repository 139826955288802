import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { Model } from 'partner/routes/organizations/organization/forms';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import type CurrentService from 'partner/services/current';
import type Form from 'partner/models/form';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type Store from '@ember-data/store';
import type SnackbarService from 'secondstreet-common/services/snackbar';

export default class OrganizationsOrganizationFormsIndexController extends Controller {
  declare model: Model;

  @service declare enums: EnumsService;
  @service declare current: CurrentService;
  @service declare snackbar: SnackbarService;

  @service declare store: Store;

  @tracked formToDelete?: Form;
  @tracked selectedFormToReplaceDefault?: Form;

  private async updatePromotion(promotionName: string) {
    const promotionTypeId = this.enums.findWhere('PROMOTION_TYPE', { publicName: promotionName }, 'id');
    const defaultSettingForPromotion = await this.model.formDefaultSettings.find(
      setting => setting.promotionTypeId == promotionTypeId
    );
    if (defaultSettingForPromotion && this.selectedFormToReplaceDefault) {
      defaultSettingForPromotion.defaultFormId = +this.selectedFormToReplaceDefault?.id;
      defaultSettingForPromotion.organizationId = this.current.organization.id;
      await defaultSettingForPromotion?.save();
    }

    return Promise.resolve();
  }

  deleteTask = task({ drop: true }, async () => {
    if (!this.formToDelete) return;
    const { defaultPromotionNames } = this.formToDelete;
    try {
      if (defaultPromotionNames.length && this.selectedFormToReplaceDefault) {
        for (const promotionName of defaultPromotionNames) {
          await this.updatePromotion(promotionName);
        }
      }
      await this.formToDelete?.destroyRecord();
      this.model.forms.removeObject(this.formToDelete);
    } catch (e) {
      this.snackbar.exception(e);
    } finally {
      this.formToDelete = undefined;
      this.selectedFormToReplaceDefault = undefined;
    }
  });
}
