import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class OrganizationPerformanceStatisticModel extends BaseModel {
  @attr('number') declare parentOrganizationId: number;
  @attr('number') declare newOptins: number;
  @attr('number') declare newUsers: number;
  @attr('number') declare emails: number;
  @attr('number') declare promotions: number;
  @attr('number') declare users: number;

  @attr('string') declare organizationName: string;

  get organizationId() {
    return this.id;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'organization-performance-statistic': OrganizationPerformanceStatisticModel;
  }
}
