import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import BaseModel from 'secondstreet-common/models/base';
import type EntryModel from './entry';
import type MatchupEntryModel from './matchup-entry';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';

export default class EntryLocationModel extends BaseModel {
  @attr('string', { defaultValue: '' }) declare businessPhone: string;
  @attr('string', { defaultValue: '' }) declare fullAddress: string;
  @attr('number') declare displayOrder: number;
  @attr('number') declare entryId: number;
  @attr('number') declare locationId: number;
  @attr('string') declare googlePlaceId: string | null;
  @attr('number') latitude?: number;
  @attr('number') longitude?: number;

  @belongsTo('entry', { async: true }) declare entry: AsyncBelongsTo<EntryModel>;
  @belongsTo('matchup-entry', { async: false }) declare matchupEntry: MatchupEntryModel;

  @tracked isMarkedForDeletion = false;

  get isDirty(): boolean {
    return super.isDirty || this.isMarkedForDeletion;
  }

  rollback() {
    this.rollbackAttributes();
    this.isMarkedForDeletion = false;
  }

  toJSON() {
    return mapKeys(this.serialize(), (v, k) => camelCase(k));
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'entry-location': EntryLocationModel;
  }
}
