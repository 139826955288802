/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { computed, get } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import getAvailableGenders from 'partner/utils/organization-gender-types';

export default Controller.extend({
  //region Dependencies
  enums: service(),
  features: service(),

  person: controller('organizations.organization.people.person'),
  //endregion

  //region Computed Properties
  genderOptions: computed('features.hasExpandedGenderOptions', function () {
    return getAvailableGenders(get(this, 'features.hasExpandedGenderOptions'));
  }),
  stateProvinceOptions: readOnly('enums.data.STATE_PROVINCE'),
  consumerUser: readOnly('person.model.consumerUser'),

  //region Properties
  addingCustomFields: false,
  //endregion

  //region Actions
  actions: {
    findFieldsNotOnForm(fields) {
      return fields.reject(field =>
        get(this, 'consumerUser.organizationUserAttributes')
          .mapBy('userAttributeType.fieldId')
          .includes(Number.parseInt(get(field, 'id')))
      );
    },
  },
  //endregion
});
