export default `
  <div class="ballot-showcase ballot-showcase--preview max-w-full gap-x-4 !p-0 sm:!flex-nowrap">
    <div class="gallery-column max-w-[182px]">
      <div class="ml-2 mb-2 text-gray-500">Featured Entrants</div>
      <div class="individual-entry-view">
        <div class="w-full mb-6 mx-1 border border-solid border-gray-400 rounded">
          <div class="entry">
            <img width="180" src="https://media.secondstreetapp.com/250194?width=1170&height=1170&quality=60">
          </div>
          <div class="mx-4 my-1">
            <span class="entry-title !pb-0">Entrant Name</span>
            <div class="entry-caption">
              Oat cake dessert sugar plum marzipan tart carrot cake donut lollipop. Wafer chocolate cake.
            </div>
          </div>
          <div class="entry-actions">
            <div class="gallery-vote">
              <button class="ssButton ssButtonPrimary vote-button vote hover:brightness-110">Vote</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gallery-column max-w-[182px]">
      <div class="ml-2 mb-2 text-gray-500 invisible">Featured Entrants</div>
      <div class="individual-entry-view">
        <div class="w-full mb-6 mx-1 border border-solid border-gray-400 rounded">
          <div class="entry">
            <img width="180" src="https://media.secondstreetapp.com/250194?width=1170&height=1170&quality=60">
          </div>
          <div class="mx-4 my-1">
            <span class="entry-title !pb-0">Entrant Name</span>
            <div class="entry-caption">
              Sugar plum jujubes brownie cheesecake marshmallow. Toffee brownie cake candy canes bonbon.
            </div>
          </div>
          <div class="entry-actions">
            <div class="gallery-vote">
              <button class="ssButton ssButtonPrimary vote-button vote hover:brightness-110">Vote</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gallery-column max-w-[182px]">
      <div class="ml-2 mb-2 text-gray-500 invisible">Featured Entrants</div>
      <div class="individual-entry-view">
        <div class="w-full mb-6 mx-1 border border-solid border-gray-400 rounded">
          <div class="entry">
            <img width="180" src="https://media.secondstreetapp.com/250194?width=1170&height=1170&quality=60">
          </div>
          <div class="mx-4 my-1">
            <span class="entry-title !pb-0">Entrant Name</span>
            <div class="entry-caption">
              Caramel fudge tart macarons lollipop toffee. Cupcake jelly beans pie chocolate wafer candy.
            </div>
          </div>
          <div class="entry-actions">
            <div class="gallery-vote">
              <button class="ssButton ssButtonPrimary vote-button vote hover:brightness-110">Vote</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
`;
