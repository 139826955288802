import { ParamsType } from 'partner/services/preview';

export default (params?: ParamsType) => [
  {
    id: 'box-13',
    uniqueTypeNumber: '13',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'box-14',
        uniqueTypeNumber: '14',
        deletable: true,
        type: 'promotion-box',
        blocks: [
          {
            id: 'heading-6',
            uniqueTypeNumber: '6',
            deletable: true,
            type: 'promotion-heading',
            blocks: [],
            data: {
              level: 4,
              text: 'Your entry has been received!',
              additionalCss: '.arc-promotion-promotion-0 .arc-heading-heading-6 {\n     \n  }\n  \n  ',
            },
            styles: {
              font: {
                color: params?.promotionTextColor,
                weight: 700,
                family: 'Open Sans',
              },
              spacing: {
                paddingBlock: '16',
                paddingInline: '16',
                marginBlock: '',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
        ],
        data: {
          additionalCss: '.arc-promotion-promotion-0 .arc-box-box-14 {\n     \n  }\n  \n  ',
        },
        styles: {
          layout: {
            display: 'flex',
            direction: 'column',
          },
          background: {
            type: 'color',
            color: params?.promotionColor,
            opacity: 100,
          },
          spacing: {
            marginBlock: '',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      {
        id: 'text-2',
        uniqueTypeNumber: '2',
        deletable: true,
        type: 'promotion-text',
        blocks: [],
        data: {
          text: params?.message ?? '',
          additionalCss: '.arc-promotion-promotion-0 .arc-text-text-2 {\n     \n  }\n  \n  ',
        },
        styles: {
          font: {
            color: '#000000',
            weight: 400,
            family: 'Open Sans',
          },
          spacing: {
            paddingBlock: '16',
            paddingInline: '16',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      {
        id: 'heading-7',
        uniqueTypeNumber: '7',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 5,
          text: 'LOG OUT',
          additionalCss: '.arc-promotion-promotion-0 .arc-heading-heading-7 {\n     \n  }\n  \n  ',
        },
        styles: {
          font: {
            color: '#6b7786',
            weight: 600,
            family: 'Open Sans',
            size: '14',
          },
          spacing: {
            paddingBlock: '16',
            paddingInline: '16',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss: '.arc-promotion-promotion-0 .arc-box-box-13 {\n }\n  \n  ',
    },
    styles: {
      background: {
        type: 'color',
        color: '#ffffff',
        opacity: 100,
      },
      layout: {
        display: 'flex',
        direction: 'column',
      },
      spacing: {
        paddingBlock: '',
        paddingInline: '',
        marginBlock: '',
        marginInline: '',
      },
      border: {
        style: 'solid',
        width: 1,
        color: '#929599',
        radius: '4',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  },
];
