/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend({
  audiences: DS.hasMany('audience', {
    async: false,
  }),
  messageCampaigns: DS.hasMany('message-campaign', {
    async: false,
  }),
  organizationPromotions: DS.hasMany('organization-promotion', {
    async: false,
  }),
  consumerUsers: DS.hasMany('consumer-user', {
    async: false,
  }),
});
