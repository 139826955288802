import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default class extends Controller {
  @service settings;
  @service snackbar;

  toggleEmailValidationFlag = task({ keepLatest: true }, async () => {
    try {
      const emailValidationSetting = this.settings.getFor('use_email_validation');
      emailValidationSetting.value = !emailValidationSetting.value;
      await emailValidationSetting.save();
      this.snackbar.show(`Email validation has been turned ${emailValidationSetting.value ? 'on' : 'off'}.`);
    } catch (e) {
      this.snackbar.exception(e);
    }
  });
}
