import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<EntryForm::Field::Wrapper ...attributes>\n  <label class=\"entry-form-field__checkbox-label\">\n    <input\n      type=\"checkbox\"\n      checked={{this.entryFieldValue.value}}\n      disabled={{@disabled}}\n      {{on \"change\" this.handleChange}}\n    >\n    <span>{{@formField.labelText}}</span>\n  </label>\n</EntryForm::Field::Wrapper>\n", {"contents":"<EntryForm::Field::Wrapper ...attributes>\n  <label class=\"entry-form-field__checkbox-label\">\n    <input\n      type=\"checkbox\"\n      checked={{this.entryFieldValue.value}}\n      disabled={{@disabled}}\n      {{on \"change\" this.handleChange}}\n    >\n    <span>{{@formField.labelText}}</span>\n  </label>\n</EntryForm::Field::Wrapper>\n","moduleName":"partner/components/entry-form/field/single-checkbox.hbs","parseOptions":{"srcName":"partner/components/entry-form/field/single-checkbox.hbs"}});
import { action } from '@ember/object';

import Base from './base';

export default class EntryFormFieldSingleCheckboxComponent extends Base {
  @action
  handleChange(e) {
    this.args.onChange(this.entryFieldValue, {
      value: e.target.checked,
      fieldId: this.fieldId,
    });
  }
}
