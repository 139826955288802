/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import EmberObject, { computed } from '@ember/object';
import { proxy } from 'secondstreet-common/utils/promise-proxy';

export default EmberObject.extend({
  /**
   * You _must_ pass in the Facebook Service manually when you create a FacebookPage.
   * We can't just use Ember.inject.service because FacebookPages aren't associated to the Ember container.
   * @type {FacebookService}
   */
  facebook: null,
  /**
   * @returns {PromiseProxy} Resolves with an object from the Facebook API.
   */
  picture: computed('id', function () {
    return proxy(this.facebook.api(`/${this.id}/picture/`).then(response => response.data));
  }),
});
