export default () => `
<ss-form class="form" style="font-family: 'Open Sans'">
  <ss-form-page>
    <form action="javascript:void(0);">
      <ss-form-field>
        <div class="ssFormPart ssHasEmailBox ssRequired">
          <label class="ssField ssRegistrationFormField">
            <span class="ssFormFieldText ssRegistrationFormFieldLabelText">
              <span class="ssLabelText">Email</span>
              <span class="ssRequiredLabel">Required</span>
            </span>
            <input type="email" class="ssRegistrationField ssEmailTextboxField">
          </label>
          </div>
      </ss-form-field>
      <ss-form-field>
        <div class="ssFormPart ssHasDisplayText">
          <span class="ssFormFieldText">
            <span class="ssLabelText">By clicking the button below, I agree to the <a href="#">Terms of Service</a>, <a href="#">Privacy Policy</a>, and <a href="#">Rules</a>
            </span>
          </span>
        </div>
      </ss-form-field>
      <button type="submit" class="ssButton ssFormButton ssButtonContinue ssButtonPrimary template-color-primary-background-color template-color-primary-color-contrast">
        Continue
        <i class="ssIcon-chevron-right"></i>
      </button>
    </form>
  </ss-form-page>
</ss-form>
<div class="ssSharing">
  <ul class="ssShareListCompact ssShareList">
    <li class="ssShareListItem ssShareListItemFacebook">
      <a class="ssShareLink ssShareLinkFacebook" data-token="facebook" href="#" >
        <i class="ssIcon-facebook"></i>
      </a>
    </li>
    <li class="ssShareListItem ssShareListItemTwitter">
      <a class="ssShareLink ssShareLinkTwitter" data-token="twitter" href="#">
        <i class="ssIcon-twitter"></i>
      </a>
    </li>
    <li class="ssShareListItem ssShareListItemEmail">
      <a class="ssShareLink ssShareLinkEmail" data-token="email" href="#" >
        <i class="ssIcon-envelope"></i>
      </a>
    </li>
    <li class="ssShareListItem ssShareListItemPinterest">
      <a class="ssShareLink ssShareLinkPinterest" data-token="pinterest" href="#" >
        <i class="ssIcon-pinterest"></i>
      </a>
    </li>
  </ul>
</div>
`;
