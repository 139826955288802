import { attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';
import type MediaItemModel from './media-item';
import type QuestionModel from './question';
import type QuestionOptionOutcomeWeightModel from './question-option-outcome-weight';

export default class QuestionOptionModel extends BaseModel {
  @attr('string') declare name: string;
  @attr('number') declare displayOrder: number;
  @attr('boolean', { defaultValue: false }) declare isCorrect: boolean;
  @attr('number') declare promotionId: number;
  @attr('number') declare statusTypeId: number;
  @attr('string') declare nextQuestionId: string;

  @belongsTo('media-item', { async: false }) declare mediaItem: MediaItemModel;
  @belongsTo('question', { async: false }) declare question: QuestionModel;
  @hasMany('question-option-outcome-weight', { async: false })
  declare questionOptionOutcomeWeights: SyncHasMany<QuestionOptionOutcomeWeightModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'question-option': QuestionOptionModel;
  }
}
