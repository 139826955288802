/* eslint-disable ember/no-classic-classes */
import { computed } from '@ember/object';
import Service from '@ember/service';

/**
 * Permission service. Requires that a call /permissions has been made
 * after the user's access token has been set and that the results from that
 * have been set. get(this, 'permissions').set('permissions', permissionsArray);
 *
 * @see Promotion.Permission
 * @see Promotion.ApplicationController
 */
export default Service.extend({
  /**
   * Edge case for users that CAN'T see or edit promotions, but can access nimble or old deals
   */
  hasCommerceProductButNotPromotionPermissions: computed('permissions.[]', function () {
    return (
      !this.hasViewOrAdminAccess('Promotion,OrganizationPromotion') && this.hasViewOrAdminAccess('CommerceProduct')
    );
  }),

  hasEitherDownloadOrViewDataPermissions: computed('permissions.[]', function () {
    return this.hasViewOrAdminAccess('UserReport') || this.hasViewOrAdminAccess('OrganizationUser');
  }),
  /**
   * For the given entity name(s) return the highest level of common access for each entity.
   *
   * Example: [{name: 'Foo', view: true, administer: false}, {name: 'Bar', view: true, administer: true}]
   * Result: {view: true, administer: false} //false because both didn't have administer: true
   *
   * @param {string} entityTypeNames - single name or comma delimited list of entity names
   * @returns {{view: boolean, administer: boolean}}
   */
  getAccessLevel(entityTypeNames, isAnyEntity) {
    const entityAccessLevels = [];
    const results = { view: false, administer: false };
    const { permissions } = this;
    if (permissions) {
      //find access level for each entity
      entityTypeNames.split(',').forEach(name => {
        const level = { entityType: name, view: false, administer: false };
        permissions.filterBy('entityType', name).forEach(permission => {
          if (permission.isView) {
            level.view = true;
          } else if (permission.isAdminister) {
            level.administer = true;
          }
        });
        entityAccessLevels.pushObject(level);
      });

      //check count of view and administer rights compared to entity total
      const entityCount = entityAccessLevels.length;
      const entityViewCount = entityAccessLevels.filterBy('view').length;
      const entityAdministerCount = entityAccessLevels.filterBy('administer').length;

      results.view = isAnyEntity ? entityViewCount > 0 : entityViewCount === entityCount;
      results.administer = isAnyEntity ? entityAdministerCount > 0 : entityAdministerCount === entityCount;
    }
    return results;
  },

  hasViewOrAdminAccess(entityTypeNames) {
    const { view, administer } = this.getAccessLevel(entityTypeNames);
    return view || administer;
  },

  noAdministerAccess(entityTypeNames = '') {
    const accessLevel = this.getAccessLevel(entityTypeNames);
    return !accessLevel.administer;
  },
  noViewAccess(entityTypeNames = '') {
    const accessLevel = this.getAccessLevel(entityTypeNames);
    return !accessLevel.view;
  },
});
