import { A } from '@ember/array';
import { isEmpty } from '@ember/utils';

export const delimitedTransformFactory = (
  { split = ',', deserializeMap = null } = { split: ',', deserializeMap: null }
) => ({
  serialize(array) {
    return Array.isArray(array) ? array.join(split) : null;
  },
  deserialize(string) {
    if (isEmpty(string)) {
      return A([]);
    }
    const arr = string.split(split);
    return A(deserializeMap ? arr.map(deserializeMap) : arr);
  },
});
