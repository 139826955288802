import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type { ModelFrom } from 'partner/utils/types';
import RSVP from 'rsvp';
import type { SetupQuizModel } from '../quiz';

export type SetupQuizQuestionsModel = ModelFrom<SetupQuizQuestionsRoute>;

export default class SetupQuizQuestionsRoute extends Route {
  declare currentModel?: SetupQuizQuestionsModel;

  get questions() {
    const { questions } = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion.setup.quiz'
    ) as SetupQuizModel;

    return questions;
  }

  model() {
    return this.questions;
  }

  @action
  compactDisplayOrders() {
    this.questions.forEach((question, index) => {
      question.displayOrder = index + 1;
    });

    return RSVP.all(
      (this.currentModel || [])
        .filterBy('hasDirtyAttributes')
        .rejectBy('isNew')
        .map(x => x.save())
    );
  }
}
