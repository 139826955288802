/* eslint-disable ember/no-mixins */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import AppContextRoute from 'partner/mixins/app-context-route';
import { endpoint } from 'secondstreet-common/utils/url';
import RSVP from 'rsvp';

export default class extends Route.extend(AppContextRoute) {
  @service('current') current;
  @service('session') session;
  @service('settings') settings;

  async model() {
    return RSVP.hash({
      detectedFraud: this.#fetchDetectedFraud(),
      _settings: this.settings.preload('use_email_validation', { force: true }),
    });
  }

  async refreshDetectedFraud() {
    set(this.controller.model, 'detectedFraud', await this.#fetchDetectedFraud());
  }

  async #fetchDetectedFraud() {
    const {
      organization_promotion_users_fraud_count: [detectedFraud],
    } = await this.session.request(endpoint('organization_promotion_users_fraud_count'));

    return detectedFraud;
  }
}
