import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import DesignTemplateModel from 'partner/models/design-template';
import type CurrentService from 'partner/services/current';
import type DeliberateConfirmationService from 'partner/services/deliberate-confirmation';
import SnackbarService from 'secondstreet-common/services/snackbar';

export interface TemplateCardSignature {
  Element: HTMLDivElement;
  Args: {
    template: DesignTemplateModel;
    onDelete: () => void;
  };
}

export default class TemplateBaseComponent extends Component<TemplateCardSignature> {
  @service declare current: CurrentService;
  @service declare deliberateConfirmation: DeliberateConfirmationService;
  @service declare snackbar: SnackbarService;

  type = 'Template';

  get canDelete() {
    return this.args.template.organizationId === +this.current.organization.id;
  }

  deleteTemplateTask = task({ drop: true }, async () => {
    if (!this.canDelete) return;

    const confirmed = await this.deliberateConfirmation.show({
      promptText: `Deleting this ${this.type.toLowerCase()} is permanent and cannot be undone.`,
      cancelButtonText: 'No, Cancel',
      confirmButtonText: 'Yes, Delete',
    });

    if (!confirmed) return;

    await this.args.template.destroyRecord();
    this.snackbar.show(`${this.type} deleted successfully.`);
    this.args.onDelete();
  });
}
