/* eslint-disable ember/no-mixins, ember/no-computed-properties-in-native-classes */
import { inject as service } from '@ember/service';
import { gt, notEmpty, filter, mapBy } from '@ember/object/computed';
import Controller from '@ember/controller';
import { get, computed, action } from '@ember/object';
import NeedsOrganizationPromotionController from 'partner/mixins/needs-organization-promotion-controller';
import ToolbarNavigation from 'partner/mixins/toolbar-navigation';
import { mergeItems } from 'partner/utils/merge-items';
import { tracked } from '@glimmer/tracking';
import { setProperties } from '@ember/object';
import { alphabeticalByProperty } from 'secondstreet-common/utils/sorting';

export default class ModerateNominationsController extends Controller.extend(
  NeedsOrganizationPromotionController,
  ToolbarNavigation
) {
  @service('router') router;
  @service store;
  @service current;
  @service settings;

  //region Ember Hooks
  queryParams = ['matchupId', 'openModal', 'editMatchupEntryId', 'showSettings', 'preserveScrollPosition'];

  //endregion

  //region Properties
  matchupId = null;

  @tracked editMatchupEntryId = null;
  @tracked showSettings = false;

  /**
   * @type {VotingEntry}
   */
  votingEntryBeingEdited = null;

  showModal = false;
  allMatchupGroupViews = null;
  matchupStatusCounts = null;
  pendingMatchupGroupViews = null;
  isCategorySideNavExpanded = window.innerWidth >= 768;
  @tracked loadingModel = false;

  //endregion

  //region Computed Properties
  @filter('model.votingEntries', x => get(x, 'hasDirtyAttributes') && !get(x, 'isNew'))
  dirtyVotingEntries;

  @filter('model.votingEntries', x => get(x, 'hasDirtyAttributes') || get(x, 'isNew'))
  unsavedVotingEntries;

  @computed('model.votingEntries.@each.{id,masterEntryId,isActive}')
  get approvedVotingEntries() {
    return this.model.votingEntries.reject(ve => ve.masterEntryId).filterBy('isActive');
  }

  @mapBy('model.votingEntries', 'nominationEntriesSummaries')
  votingEntryNominationEntriesSummaries;

  @computed(
    'model.votingEntries.@each.{id,masterEntryId,statusTypeId}',
    'votingEntryNominationEntriesSummaries.@each.length',
    'current.promotion.isVotingBallot'
  )
  get pendingNominationMergeItems() {
    return mergeItems(get(this, 'model.votingEntries'), this.current?.promotion?.isVotingBallot).filterBy('isPending');
  }

  @notEmpty('dirtyVotingEntries')
  isAnythingDirty;

  @computed('allMatchupGroupViews.@each.{matchups,displayOrder}')
  get flattenedMatchups() {
    return this.allMatchupGroupViews
      .sortBy('displayOrder')
      .map(mgv =>
        this.settings.getValueFor('category_sort_criteria') == '1'
          ? mgv.matchups.sort(alphabeticalByProperty('name'))
          : mgv.matchups.sortBy('displayOrder')
      )
      .flat();
  }

  @computed('matchupStatusCounts.@each.matchups')
  get flattenedMatchupStatusCounts() {
    return this.matchupStatusCounts.mapBy('matchups').flat();
  }

  @computed('matchupId', 'model.matchups.@each.id')
  get selectedMatchup() {
    return get(this, 'model.matchups').findBy('id', `${this.matchupId}`);
  }

  @action
  triggerUpdateMatchUpStatusCounts() {
    if (this.matchupStatusCounts) {
      this.send('updateMatchupStatusCounts');
    }
  }

  @gt('model.matchups.length', 1)
  hasMultipleMatchups;

  @computed('hasMultipleMatchups', 'router.currentRouteName')
  get hasFixedToolbar() {
    return (
      this.hasMultipleMatchups ||
      this.router.currentRouteName ===
        'organizations.organization.organization-promotions.organization-promotion.moderate-nominations.merge'
    );
  }

  @action
  async refreshVotingEntry(matchupEntry) {
    await this.store.findRecord('voting-entry', matchupEntry?.entry?.id, {
      reload: true,
    });

    setProperties(this, {
      editMatchupEntryId: null,
      preserveScrollPosition: false,
    });
  }
}
