import { computed, get } from '@ember/object';
import { isEmpty } from '@ember/utils';

/**
 * Returns true if the object or anything in it is currently being saved.
 * @param flag {String} - The Ember Data Model Flag to check if anything is
 * @param paths {String[]} - The paths to all your models. (e.g. ['model.filter', 'model.audiences.[]'])
 * @returns {Ember.ComputedProperty}
 */
export default function (flag, paths) {
  const COLLECTION_REGEX = /\.?\[\]/;

  return computed(...paths.map(path => `${path.replace(COLLECTION_REGEX, '.@each')}.${flag}`), function () {
    return paths.any(function (path) {
      // Not using => expression because we want `this` to be bound to the computed property, not lexical.
      if (path.match(COLLECTION_REGEX)) {
        const collection = get(this, path.replace(COLLECTION_REGEX, ''));

        return isEmpty(collection) ? false : collection.any(item => (item.isDestroyed ? false : !!get(item, flag)));
      }

      return !!get(this, `${path}.${flag}`);
    }, this);
  });
}
