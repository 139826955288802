/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { isArray } from '@ember/array';
import { computed, get } from '@ember/object';
import { not, notEmpty } from '@ember/object/computed';
import { isEmpty, isPresent } from '@ember/utils';
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import Copyable from 'partner/mixins/copyable-model';
import MessageVersionProperties from 'partner/mixins/message-version-properties';
import { containsAny } from 'partner/utils/computed';
import dirtyProperty from 'partner/utils/dirty-property';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo, hasMany } = DS;

/**
 * @typedef {DS.Model} MessageVersion
 * @implements DesignModel
 */
export default BaseModel.extend(MessageVersionProperties, Copyable, {
  //region Attributes
  bodySourceTypeId: attr('number'),
  bodyScrapeUrl: attr('string'),
  statusTypeId: attr('number'),
  sentCount: attr('number'),
  //endregion

  //region Relationships
  singleMessageCampaign: belongsTo('single-message-campaign', { async: true }),
  messageVersionTemplateTokenContents: hasMany('message-version-template-token-contents', { async: false }),
  messageVersionFeeds: hasMany('message-version-feed'),
  messageVersionHistories: hasMany('message-version-history'),
  interactionCount: attr('number'),
  //endregion

  //region Properties
  /**
   * Toggled to true by the email designer to (temporarily) display a completed state for Test Message flyout
   * @property {Boolean}
   */
  testMessageWasSent: false,
  //endregion

  //region Computed Properties
  /**
   * Returns true if the criteria for being a newer template is not met
   * @type {Ember.Computed}
   * @returns {Boolean}
   */
  hasLegacyTemplate: computed('messageBodyTemplateTokens.[]', function () {
    const templateTokens = this.messageBodyTemplateTokens || [];
    const itemTokens = templateTokens.filterBy('key', 'item');
    return itemTokens.length ? false : !isEmpty(templateTokens.filterBy('key', 'itemHeadline1'));
  }),

  isScrapeUrlDirty: dirtyProperty('bodyScrapeUrl'),

  isScrapeUrlComplete: notEmpty('bodyScrapeUrl'),

  scrapeUrlError: containsAny('errorAttributes', 'bodyScrapeUrl'),

  scrapeUrlIsValid: not('scrapeUrlError'),

  isBodyHtmlComplete: computed('renderedContent', 'isScrapeUrl', 'isScrapeUrlComplete', 'isCustom', function () {
    return this.isCustom ? isPresent(this.renderedContent) : this.isScrapeUrl ? this.isScrapeUrlComplete : true;
  }),

  bodySourceType: enums.computed('name', 'bodySourceTypeId', 'bodySourceTypeId'),
  isComplete: computed(
    'isValid',
    'replyTo',
    'subject',
    'fromName',
    'bodySourceType',
    'body',
    'bodyScrapeUrl',
    'singleMessageCampaign.isNewsletter',
    'messageBodyTemplateTokens.@each.id',
    'tokenContents.@each.{tokenId,isIncomplete}',
    'messageVersionFeeds.@each.{isDisabled,isComplete}',
    function () {
      // TODO: Don't hard-code a test for 'Subject Line Text' here.
      if (
        !this.isValid ||
        isEmpty(this.replyTo) ||
        isEmpty(this.subject) ||
        isEmpty(this.fromName) ||
        this.subject === 'Subject Line Text'
      ) {
        return false;
      }

      switch (this.bodySourceType) {
        case 'Custom':
          if (isEmpty(this.body)) {
            return false;
          }
          break;
        case 'Scrape Url':
          if (isEmpty(this.bodyScrapeUrl)) {
            return false;
          }
          break;
        case 'Template': {
          const templateTokens = isArray(this.messageBodyTemplateTokens)
            ? get(this, 'message.messageCampaign.isDoubleOptinConfirmation')
              ? this.messageBodyTemplateTokens.rejectBy('category', 'Social & Footer')
              : this.messageBodyTemplateTokens
            : [];
          const templateIssues = templateTokens.filter(content =>
            this.tokenContents.any(x => {
              if (get(x, 'tokenId') === get(content, 'id')) {
                return get(x, 'isIncomplete');
              }
              return false;
            })
          );

          if (!isEmpty(templateIssues)) return false;
          if (!this.messageVersionFeeds.rejectBy('isDisabled').isEvery('isComplete')) return false;

          break;
        }
      }

      return true;
    }
  ),
  //endregion
});
