/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

/**
 * Notifies the appContext service of a new app context. This will hide other app bars.
 * The reason we do it in both activate() and didTransition() is because didTransition() happens every
 * time, but activate() is the only hook that seems to work in loading routes.
 *
 * @mixin
 */
export default Mixin.create({
  //region Ember Hooks
  notifications: service(),
  appContext: service('app-context'),
  //endregion

  activate() {
    this._super(...arguments);
    this.appContext.setAndNotify(this.routeName);
  },

  setupController() {
    this._super(...arguments);
  },

  //region Actions
  actions: {
    didTransition() {
      this._super(...arguments);
      this.appContext.setAndNotify(this.routeName);
    },
  },
  //endregion
});
