/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Controller.extend({
  router: service(),
  enums: service(),
  organizationController: controller('organizations.organization'),

  returnRoute: computed('model.newDesignTemplate', function () {
    let route;
    switch (+this.model.newDesignTemplate.designTemplateTypeId) {
      case this.enums.findWhere('TEMPLATE_TYPE', { name: 'Privacy Policy' }, 'id'):
        route = 'privacy-policy';
        break;
      case this.enums.findWhere('TEMPLATE_TYPE', { name: 'Rules' }, 'id'):
        route = 'rules';
        break;
      case this.enums.findWhere('TEMPLATE_TYPE', { name: 'Terms of Service' }, 'id'):
        route = 'terms-of-service';
        break;
      default:
        route = '';
        break;
    }
    return `organizations.organization.legal.design-templates.${route}`;
  }),

  actions: {
    returnToListingPage() {
      this.router.transitionTo(this.returnRoute);
    },

    addToModelAndTransition() {
      this.send('addTemplate', this.model.newDesignTemplate);
      this.router.transitionTo(
        'organizations.organization.legal.design-templates.design-template',
        this.model.newDesignTemplate.id
      );
    },
  },
});
