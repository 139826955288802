/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { alias, not } from '@ember/object/computed';
import PermittedComponent from 'partner/mixins/permitted-component';

/**
 * Deliberate confirmation dialog. Functionally close to a simple dialog, but with more features.
 * This is something we came up with, not a material design feature.
 * Reuses some styles/html from simpleDialog module/component
 */
export default Component.extend(PermittedComponent, {
  classNameBindings: [':simpleDialog', ':deliberate-confirmation', ':is-animated-in'],

  confirmationValue: false,
  yesSelected: alias('confirmationValue'),
  noSelected: not('confirmationValue'),
  buttonText: computed('noSelected', 'yes-button-text', function () {
    return this.noSelected ? 'OK' : this['yes-button-text'] || 'OK, REMOVE';
  }),

  actions: {
    setConfirmationValue(value) {
      set(this, 'confirmationValue', value);
    },
    buttonClicked() {
      if (this.noSelected) {
        this.cancelAction();
      } else {
        this.confirmAction();
      }
    },
  },
});
