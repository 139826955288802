/* eslint-disable ember/require-tagless-components, ember/no-classic-components, ember/no-computed-properties-in-native-classes*/
import Component from '@ember/component';
import { computed, get, set, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import groupBy from 'lodash/groupBy';
import isAnyPath from 'partner/utils/is-any-path';
import { task } from 'ember-concurrency';

export default class ConsumerUserComponent extends Component {
  @service('snackbar') snackbar;

  tagName = 'consumer-user';
  'consumer-user' = null;
  'gender-options' = null;
  'state-province-options' = null;
  'save-user'() {}
  'begin-adding-custom-fields'() {}
  'custom-fields' = [];
  'show-confirm-delete-user'() {}
  'create-org-attribute'() {}

  @computed('gender-options.@each.id', 'consumer-user.genderId')
  get selectedGenderOption() {
    return this['gender-options'].findBy('id', get(this, 'consumer-user.genderId'));
  }

  @computed('state-province-options.@each.id', 'consumer-user.stateProvinceId')
  get selectedStateProvinceOption() {
    return this['state-province-options'].findBy('id', get(this, 'consumer-user.stateProvinceId'));
  }

  /**
   * Filters the consumer user's organization user attributes by which ones have fields returned from the API
   * as part of the "profile view" parameter
   */
  @computed('consumer-user.organizationUserAttributes.@each.userAttributeTypeFieldId', 'custom-fields.@each.id')
  get relevantOrganizationUserAttributes() {
    return get(this, 'consumer-user.organizationUserAttributes')
      .sortBy('id')
      .filter(attr => !isEmpty(this['custom-fields'].findBy('id', `${get(attr, 'userAttributeTypeFieldId')}`)));
  }

  @computed('relevantOrganizationUserAttributes.@each.userAttributeTypeId')
  get uniqueRelevantOrganizationUserAttributes() {
    return this.relevantOrganizationUserAttributes.uniqBy('userAttributeTypeId');
  }

  @isAnyPath('isSaving', ['consumer-user.organizationUserAttributes.[]'])
  areAnyOrgUserAttributesSaving;

  @computed('custom-fields.@each.id', 'consumer-user.organizationUserAttributes.@each.userAttributeTypeFieldId')
  get customFieldsNotOnForm() {
    return this['custom-fields'].reject(field =>
      get(this, 'consumer-user.organizationUserAttributes')
        .mapBy('userAttributeTypeFieldId')
        .includes(Number.parseInt(get(field, 'id')))
    );
  }

  /**
   * Collects all the org user attributes and groups them by shared userAttributeType.
   * For every field type except for Checkboxes, the grouping will contain a single
   * org user attribute.
   */
  @computed('relevantOrganizationUserAttributes.@each.userAttributeTypeId')
  get bundledOrgUserAttributes() {
    return groupBy(this.relevantOrganizationUserAttributes, x => get(x, 'userAttributeTypeId'));
  }

  deleteOptOutTask = task({ drop: true }, async categoryOptOut => {
    try {
      await categoryOptOut.destroyRecord();

      this.snackbar.show(`${categoryOptOut.name} opt out has been cleared.`);
    } catch (e) {
      this.snackbar.show(`Something went wrong. Please try again later.`);
    }
  });

  @action
  saveOnEnter({ key }) {
    if (key === 'Enter') {
      this['save-user']();
    }
  }

  @action
  changeGenderOption(value) {
    set(this, 'consumer-user.genderId', get(value, 'id'));
  }

  @action
  changeStateProvinceOption(value) {
    set(this, 'consumer-user.stateProvinceId', get(value, 'id'));
  }

  @action
  deleteOrgUserAttribute(orgUserAttribute) {
    orgUserAttribute.destroyRecord();
  }
}
