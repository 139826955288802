/* eslint-disable ember/no-observers, ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { A } from '@ember/array';
import Controller from '@ember/controller';
import { observer } from '@ember/object';
import { alias } from '@ember/object/computed';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import IsSecondStreetAdminMixin from 'partner/mixins/is-second-street-admin';

export default Controller.extend(IsSecondStreetAdminMixin, {
  //region Ember Hooks
  session: service(),
  enums: service(),
  snackbar: service(),
  router: service(),
  //endregion

  queryParams: ['accessToken'],
  accessToken: null,

  //region Observers
  currentRouteChanged: observer('router.currentRouteName', function () {
    this._updateAnalytics();
  }),
  //endregion

  //region Methods
  _updateAnalytics() {
    // Google Analytics
    // window.location gets updated later in the current run loop, so we will
    // wait until the next run loop to inspect its value and make the call
    // to track the page view
    next(() => {
      // Track the page in Google Analytics
      if (isPresent(window.ga)) {
        // Assume that if there is a hash component to the url then we are using
        // the hash location strategy. Otherwise, we'll assume the history
        // strategy.
        let pathArray = window.location.hash.split('/');
        const pageTitle = pathArray[pathArray.length - 1].toString();
        pathArray = A(pathArray).slice(5);
        pathArray = pathArray.toString().replace(',', '/');

        // You need your Google Analytics code already loaded for _ga to be initialized
        window.ga('send', 'pageview', {
          page: pathArray,
          title: pageTitle,
        });
      }
    });
  },
  //endregion

  // Login
  token: alias('session.data.authenticated.access_token'),
  organizationUserGuid: alias('session.data.authenticated.organization_user_guid'),
  organizationId: alias('session.data.authenticated.organization_id'),
});
