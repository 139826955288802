/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
/* eslint-disable ember/avoid-leaking-state-in-ember-objects */ // FIXME
import Controller from '@ember/controller';
import EditableSingleObject from 'partner/mixins/editable-single-object';

/**
 * Matchup Controller
 * No URL - To be used with {{render}}
 * @mixes {EditableSingleObject}
 */
export default Controller.extend(EditableSingleObject, {
  //region Properties
  isEditing: true,
  entryFrequencies: ['Per Round', 'Per Hour', 'Per Week', 'Per Month'],
  //endregion
});
