/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import ENV from 'partner/config/environment';
import { UnauthorizedError } from 'secondstreet-common/utils/errors';

export default Route.extend({
  //region Dependencies
  appcues: service(),
  checklist: service(),
  router: service(),
  sentry: service(),
  session: service(),
  snackbar: service(),
  //endregion

  //region Ember Hooks
  async beforeModel() {
    await this.session.setup();

    this.appcues.setup();
    this.sentry.setupUser();
  },

  model({ accessToken }, transition) {
    //Authenticate with accessToken
    if (accessToken) {
      // Set transition so it can be used in ApplicationRouteMixin.sessionAuthenticationSucceeded
      set(this.session, 'attemptedTransition', transition);

      return this.session.authenticate('authenticator:secondStreet', { token: accessToken }).catch(e => {
        console.error(e);
      });
    }
  },

  setupController(controller, model) {
    set(controller, 'model', model);
    //region HAX
    // This removes the accessToken query param (we couldn't figure out a better way to to do this. Any ideas?)
    if (ENV.environment !== 'test') {
      const intervalId = window.setInterval(() => {
        set(controller, 'accessToken', null);
        if (window.location.href.indexOf('accessToken=') === -1) {
          window.clearInterval(intervalId);
        }
      }, 500);
    }
  },
  //endregion

  //region Methods
  continue() {
    // TODO: Extract into the checklist service?
    const checklistStepTypeId = this.checklist.getChecklistStepId(this.router.currentRouteName);
    const nextStep = this.checklist.getNextStep(checklistStepTypeId);
    if (nextStep) {
      this.router.transitionTo(nextStep);
    }
  },

  createHistoryItem(historyItem, entityType) {
    const isPromotion = !isEmpty(get(historyItem, 'id'));

    return {
      isPromotion,
      name: get(historyItem, 'name'),
      isOldPlatform: get(historyItem, 'isOldPlatform'),
      messageCampaignTypeId: get(historyItem, 'messageCampaignTypeId'),
      promotionSubTypeId: get(historyItem, 'promotion.promotionSubTypeId'),
      promotionTypeId: get(historyItem, 'promotion.promotionTypeId'),
      isLegacyMessage: get(historyItem, 'isLegacyMessage'),
      legacyMessageId: get(historyItem, 'legacyMessageId'),
      audienceTypeId: get(historyItem, 'audienceTypeId'),
      entityType,
      id: get(historyItem, 'id'),
      date: Date.now(),
    };
  },
  //endregion

  //region Actions
  actions: {
    saveHistory(historyItem) {
      let newHistory;
      const orgModel = this.modelFor('organizations.organization');
      const organizationId = get(orgModel, 'organization.id');
      const organizationUserId = get(this, 'session.data.authenticated.organization_users').user_id;
      const localStorageKey = `SS_${organizationUserId}_${organizationId}_history`;
      const userHistory = JSON.parse(localStorage.getItem(localStorageKey)) || [];
      const entityType =
        get(historyItem, 'entityType') || get(historyItem, 'promotion.promotionTypeId')
          ? 'organizationPromotion'
          : get(historyItem, 'messageCampaignTypeId')
          ? 'messageCampaign'
          : 'audience';

      if (isEmpty(userHistory.findBy('entityType', entityType))) {
        userHistory.push(this.createHistoryItem(historyItem, entityType));
        newHistory = userHistory.sortBy('date').reverse().slice(0, 6);
      } else {
        if (isEmpty(userHistory.findBy('id', get(historyItem, 'id')))) {
          userHistory.push(this.createHistoryItem(historyItem, entityType));
          newHistory = userHistory.sortBy('date').reverse().slice(0, 6);
        } else {
          userHistory.findBy('id', historyItem.id).date = Date.now();
          userHistory.findBy('id', historyItem.id).name = historyItem.name;
          newHistory = userHistory;
        }
      }

      localStorage.setItem(localStorageKey, JSON.stringify(newHistory));
    },
    actionTransitionTo(route, model) {
      if (model) {
        this.router.transitionTo(route, model);
      } else {
        this.router.transitionTo(route);
      }
    },
    actionReplaceWith(route, model) {
      this.router.replaceWith(route, model);
    },

    error(error) {
      if (error instanceof UnauthorizedError) {
        this.router.transitionTo('login');
      } else {
        // Let the error propagate
        return true;
      }
    },

    logout() {
      this.controllerFor('login').send('logout');
    },
    noOp() {
      // this space left intentionally blank
    },
    openLink(siteLink) {
      window.open(siteLink);
    },
    /**
     * Action that acts a medium between the SnackbarService and the {{snack-bar}} component
     * @param actionClicked
     */
    hideSnackbar(actionClicked) {
      this.snackbar.hide(actionClicked);
    },

    /**
     * Continue to next checklist step
     */
    continue() {
      this.continue();
    },
    checkChecklistStep(routeName) {
      if (routeName) {
        this.checklist.checkChecklistStep(routeName);
      } else {
        this.checklist.checkChecklistStep(this.router.currentRouteName);
      }
    },
    uncheckChecklistStep(routeName) {
      if (routeName) {
        this.checklist.uncheckChecklistStep(routeName);
      } else {
        this.checklist.uncheckChecklistStep(this.router.currentRouteName);
      }
    },
  },
  //endregion
});
