/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins */
import Mixin from '@ember/object/mixin';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

export default Mixin.create({
  enums: service(),
  reports: service(),
  session: service(),

  //region Ember Hooks
  actions: {
    async downloadEmailReports(reportType = null) {
      if (isBlank(reportType)) return;

      set(this, `isPreparingEmailReports`, true);

      try {
        await this.reports.download(reportType, {
          data: this.messageCampaignParams
            ? this.messageCampaignParams
            : {
                message_campaign_id: this.messageCampaignId,
                message_id: this.messageId,
                message_version_history_id: this.messageVersionHistoryId,
              },
        });
      } catch (error) {
        console.error(`Error downloading Email Reports.`, error);
        throw new Error(`downloadEmailReports`);
      } finally {
        set(this, `isPreparingEmailReports`, false);
      }
    },
  },
  //endregion
});
