/**
 * @interface DesignTokenModel
 * @extends DS.Model
 * @property {String} key
 * @property {String} name
 * @property {String} description
 * @property {String} category
 * @property {String} title
 * @property {Boolean} allowMultiple
 * @property {Boolean} allowDisable
 * @property {Boolean} isDisabled
 * @property {Number} tokenTypeId
 * @property {String} tokenType
 * @property {Number} tokenContentTypeId
 * @property {String} tokenContentType
 * @property {DesignTokenContentModel[]} tokenDefaultContents
 * @property {DesignTokenModel} token
 * @property {String} tokenContentTypeEditorComponent
 */
// TODO: Figure out which computed properties from partner/app/models/message-version-template-token-content.js need to be part of the shared interface.
