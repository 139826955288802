/* eslint-disable ember/no-classic-classes, ember/use-ember-get-and-set */ // FIXME
import Service from '@ember/service';

/**
 * Service that is used to hold the currentAppContext.
 * CurrentAppContext is the routeName the app bar is visible for.
 */
export default Service.extend({
  /**
   * Set the value of the current route and then toggle a property that all {{app-bar}} components watch.
   * @param routeName
   */
  setAndNotify(routeName) {
    this.set('currentAppContext', routeName);
    this.toggleProperty('contextChanged');
  },
});
