/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed, set } from '@ember/object';
import { gt } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import enums from 'ember-cli-ss-enums/services/enums';
import DS from 'ember-data';
import moment from 'moment';
import {
  computedDripOffsetDate,
  computedFormattedTime,
  computedRecurringWords,
  computedSchedulePrefix,
} from 'partner/utils/computed-schedule-date-summaries';
import dirtyProperty from 'partner/utils/dirty-property';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

export default BaseModel.extend({
  //region Ember Hooks
  enums: service(),
  //endregion

  //region Properties
  recurringTypeId: attr('number'),
  recurringFrequency: attr('number'),
  recurringPattern: attr('string'),
  startDate: attr('date'),
  endDate: attr('local-date'),
  isScheduled: attr('boolean'),
  numberOfRecurrences: attr('number'),
  targetEntityTypeId: attr('number'),
  scheduleTypeId: attr('number'),
  delayDatepartTypeId: attr('number'),
  delayValue: attr('number'),
  relatedEventEntityId: attr('number'),
  startTime: attr('string'),
  updatedTime: attr('string'),
  sendNow: attr('boolean'),
  sendNowOffset: attr('number'),
  //endregion

  //region Relationships
  targetEntity: belongsTo('message', { async: false }),
  messageCampaign: belongsTo('message-campaign', {
    async: false,
  }),
  //endregion

  //region Computed Properties
  isDelayed: gt('delayValue', 0),
  formattedStartTime: computedFormattedTime('startTime'),
  schedulePrefix: computedSchedulePrefix('messageCampaign.messageCampaignType', 'targetEntity.isSent'),
  dripOffsetDate: computedDripOffsetDate('startTime', 'delayValue', 'delayDatepartTypeId'),
  scheduleTypeName: enums.computed('name', 'scheduleTypeId', 'scheduleTypeId', 'SCHEDULE_TYPE'),
  delayDatepartType: enums.computed('name', 'delayDatepartTypeId', 'delayDatepartTypeId', 'DATEPART_TYPE'),
  dripDelayValue: computed('delayValue', {
    get() {
      return Math.abs(this.delayValue);
    },
    set(key, value) {
      set(this, 'delayValue', value * (this.dripDelayDatepartValue >= 0 ? 1 : -1));
      return Math.abs(value);
    },
  }),
  dripDelayDatepartValue: computed('delayDatepartTypeId', 'delayValue', {
    get() {
      return this.delayDatepartTypeId * (this.delayValue >= 0 ? 1 : -1);
    },
    set(key, value) {
      if (this.delayValue === 0 && value < 0) {
        set(this, 'delayValue', -1);
      } else if (value < 0) {
        set(this, 'delayValue', this.dripDelayValue * -1);
      } else {
        set(this, 'delayValue', this.dripDelayValue);
      }
      set(this, 'delayDatepartTypeId', Math.abs(value));
      return value;
    },
  }),
  isStartDateDirty: dirtyProperty('startDate'),
  isStartTimeDirty: dirtyProperty('startTime'),
  isDelayValueDirty: dirtyProperty('delayValue'),
  isDelayDatepartTypeDirty: dirtyProperty('delayDatepartTypeId'),
  isScheduleTypeIdDirty: dirtyProperty('scheduleTypeId'),
  hasStarted: computed('isStartDateDirty', function () {
    const now = new Date();
    return now > this.startDate && !this.isStartDateDirty;
  }),
  formattedStartDate: computed('startDate', function () {
    if (this.startDate) {
      const date = moment(this.startDate);
      return `${date.format('dddd, MMMM Do YYYY')} at ${date.format('hh:mma')}`;
    }
    return;
  }),
  hasStartDateErrors: computed('errors.length', function () {
    return this.errors.mapBy('attribute').includes('startDate');
  }),
  recurringWords: computedRecurringWords('recurringPattern', 'startDate', 'delayDatepartTypeId', 'delayValue'),
  //endregion
});
