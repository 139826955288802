/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
/* eslint-disable ember/use-ember-get-and-set */ // FIXME
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';
import isUndefined from 'lodash/isUndefined';
import Copyable from 'partner/mixins/copyable-model';
import dirtyProperty from 'partner/utils/dirty-property';
import BaseModel from 'secondstreet-common/models/base';

/**
 * Represents the template contents used for a message.
 * @mixes {partner/mixins/copyable-model}
 * @type {DS.Model}
 */
export default BaseModel.extend(Copyable, {
  //region Properties
  isCurrentVersion: DS.attr('boolean'),
  isInherited: DS.attr('boolean'),
  bodyHtml: DS.attr('string'),
  bodyText: DS.attr('string'),
  replyTo: DS.attr('string'),
  subject: DS.attr('string'),
  //endregion

  //region Relationships
  messageTemplate: DS.belongsTo('message-template', { async: true }),
  //endregion

  //region Computed Properties
  isSubjectDirty: dirtyProperty('subject'),
  subjectStatus: computed('isSubjectDirty', function () {
    return !isEmpty(this.subject) ? 'bestPractice' : 'incomplete';
  }),
  isReplyToDirty: computed('replyTo', function () {
    const old = this.get('_data.replyTo');
    const current = this.replyTo;
    if (typeof old === 'undefined') {
      return !(isEmpty(current) || isUndefined(current));
    }
    return !((isEmpty(current) && isEmpty(old)) || current === old);
  }),
  replyToStatus: computed('isReplyToDirty', function () {
    return !isEmpty(this.replyTo) ? 'bestPractice' : 'incomplete';
  }),
  isBodyHtmlDirty: computed('bodyHtml', function () {
    const old = this.get('_data.bodyHtml');
    const current = this.bodyHtml;
    if (typeof old === 'undefined') {
      return !(isEmpty(current) || isUndefined(current));
    }
    return !((isEmpty(current) && isEmpty(old)) || current === old);
  }),
  bodyHtmlStatus: computed('isBodyHtmlDirty', function () {
    return !isEmpty(this.bodyHtml) ? 'bestPractice' : 'incomplete';
  }),
  //endregion
});
