/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  //region Attributes
  'template-components': null,
  'section-layouts': null,
  //endregion

  //region Computed Properties
  components: computed('template-components', function () {
    return this['template-components'].filterBy('isDraggable', true).rejectBy('tag', 'ss-section');
  }),

  section: computed('template-components', function () {
    return this['template-components'].findBy('tag', 'ss-section');
  }),

  sectionLayouts: computed('section-layouts', function () {
    return this['section-layouts'].map(({ value, image }) => ({
      image,
      attrs: JSON.stringify({ layout: value }),
    }));
  }),
  //endregion
});
