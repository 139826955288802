/* eslint-disable ember/no-computed-properties-in-native-classes */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { isBlank } from '@ember/utils';

export default class QuizQuestionOptionsComponent extends Component {
  @tracked isAddingImageFromLibrary = false;
  @tracked isAddImageFlyoutOpen = false;

  get questionOptions() {
    return this.args['question-options'] || [];
  }

  @computed('questionOptions.@each.displayOrder')
  get sortedQuestionOptions() {
    return this.questionOptions.sortBy('displayOrder');
  }

  @computed('args.question-option-being-edited.name')
  get isSaveDisabled() {
    return isBlank(this.args['question-option-being-edited']?.name);
  }

  @action
  setMediaItem(questionOption, mediaItem) {
    questionOption.forceDirty();

    this.isAddImageFlyoutOpen = false;
    questionOption.mediaItem = mediaItem;
  }

  @action
  reorderQuestionOptions(questionOptions) {
    questionOptions.forEach((questionOption, index) => {
      questionOption.displayOrder = index + 1;

      if (questionOption.hasDirtyAttributes) {
        questionOption.save();
      }
    });
  }
}
