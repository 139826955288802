/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Route from '@ember/routing/route';
import AppContextRoute from 'partner/mixins/app-context-route';

export default Route.extend(AppContextRoute, {
  //region Ember Hooks
  beforeModel() {
    // Until the old platform stops linking here, just redirect to the new support URL.
    window.location.replace('https://help.secondstreet.com/kb-tickets/new');
  },
  //endregion
});
