/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;

export default BaseModel.extend({
  organizationUserId: attr('number'),
  address1: attr('string'),
  address2: attr('string'),
  birthDate: attr('date'),
  clickTime: attr('date'),
  emailAddress: attr('string'),
  firstName: attr('string'),
  lastName: attr('string'),
  gender: attr('string'),
  linkUrl: attr('string'),
  linkText: attr('string'),
  openDevice: attr('string'),
  openTime: attr('date'),
  phoneNumber: attr('string'),
  postalCode: attr('string'),
  state: attr('string'),
  unsubscribeReason: attr('string'),
  unsubscribeTime: attr('date'),
  issueType: attr('string'),
  issueTime: attr('date'),
  isRemoved: attr('boolean'),
  sendTime: attr('date'),
});
