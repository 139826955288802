/* eslint-disable ember/no-mixins */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

export default class extends Route.extend(AppContextRoute) {
  @service('session') session;
  @service('store') store;
  @service('enums') enums;

  model() {
    const { messageCampaign } = this.modelFor('organizations.organization.message-campaigns.message-campaign');
    const { id: messageCampaignId } = messageCampaign;
    const { messageId } = this.modelFor(
      'organizations.organization.message-campaigns.message-campaign.messages.message'
    );

    return RSVP.hash({
      messageCampaign,
      messages: this.store.query('message', { messageCampaignId }),
      categoricalCharts: this.store.query('categorical-chart', {
        messageId,
        messageCampaignId,
      }),
      messagingStatistic: this.store.query('messaging-statistic', { messageId }).then(x => x.firstObject),
    }).then(hash => {
      hash.message = hash.messages.filterBy('id', messageId);

      if (hash.messageCampaign.isWelcome || hash.messageCampaign.isDripCampaign) {
        const messageLevelTypeId = this.enums.findWhere('MESSAGING_STATISTICS_LEVEL_TYPE', { name: 'Message' });

        hash.messageLevelMessagingStatistics = this.store.query('messaging-statistic', {
          messageCampaignId,
          messagingStatisticsLevelTypeId: messageLevelTypeId,
        });
      }

      return hash;
    });
  }
}
