import Component from '@glimmer/component';

export default class UploadAlmostComplete extends Component {
  fields = {
    SingleCheckbox: 'Checkbox',
    NumberInput: 'Number',
    CustomDateInput: 'Date',
    SelectSingle: 'Drop Down',
    Textbox: 'Text',
    Textarea: 'Text (Long Response)',
  };
}
