/**
 * CONTEXTUAL
 * These are contextual help and lab articles.s
 * The right side help content will NOT show up unless you define these per ROUTE.
 */
const CONTEXT = {
  'thanks-sharing.sweepstakes': {
    lab: [
      {
        image: 'http://secondstreetlab.com/wp-content/uploads/userphoto/4.thumbnail.jpg',
        link: 'http://secondstreetlab.com/2012/11/ideas-for-sweepstakes-prizes/',
        text: 'Best Sweepstakes Prizes',
      },
      {
        image: 'http://secondstreetlab.com/wp-content/uploads/userphoto/10.thumbnail.jpg',
        link: 'http://secondstreetlab.com/2012/12/billings-gazette-brings-in-70000-with-big-giveaway/',
        text: 'Case Study: $70k in revenue',
      },
    ],
  },
  'organizations.organization.organization-promotions.organization-promotion.setup.dates-prizes': {
    lab: [
      {
        image: 'http://secondstreetlab.com/wp-content/uploads/userphoto/4.thumbnail.jpg',
        link: 'http://secondstreetlab.com/2012/11/ideas-for-sweepstakes-prizes/',
        text: 'Best Sweepstakes Prizes',
      },
      {
        image: 'http://secondstreetlab.com/wp-content/uploads/userphoto/10.thumbnail.jpg',
        link: 'http://secondstreetlab.com/2012/12/billings-gazette-brings-in-70000-with-big-giveaway/',
        text: 'Case Study: $70k in revenue',
      },
    ],
  },
  'organizations.organization.organization-promotions.organization-promotion.setup.legal': {
    lab: [
      {
        image: 'http://secondstreetlab.com/wp-content/uploads/userphoto/9.thumbnail.jpg',
        link: 'http://secondstreetlab.com/2013/04/watch-out-for-trademark-infringement/',
        text: 'Watch Out for Trademarks!',
      },
    ],
  },
  'organizations.organization.organization-promotions.organization-promotion.setup.messages.message': {
    lab: [
      {
        image: 'http://secondstreetlab.com/wp-content/uploads/userphoto/9.thumbnail.jpg',
        link: 'http://secondstreetlab.com/2013/04/watch-out-for-trademark-infringement/',
        text: 'Watch Out for Trademarks!',
      },
    ],
  },
  'organizations.organization.organization-promotions.organization-promotion.setup.messages.index': {
    lab: [
      {
        image: 'http://secondstreetlab.com/wp-content/uploads/userphoto/9.thumbnail.jpg',
        link: 'http://secondstreetlab.com/2013/04/watch-out-for-trademark-infringement/',
        text: 'Watch Out for Trademarks!',
      },
    ],
  },
  page: {
    lab: [
      {
        image: 'http://secondstreetlab.com/wp-content/uploads/userphoto/9.thumbnail.jpg',
        link: 'http://secondstreetlab.com/2013/04/watch-out-for-trademark-infringement/',
        text: 'Watch Out for Trademarks!',
      },
    ],
  },
  'organizations.organization.organization-promotions.organization-promotion.setup.quiz.index': {
    lab: [
      {
        image: 'http://secondstreetlab.com/wp-content/uploads/userphoto/13.thumbnail.jpg',
        link: 'http://secondstreetlab.com/category/big-pages/quizzes/',
        text: 'Best practices for Quizzes',
      },
    ],
  },
};

export default CONTEXT;
