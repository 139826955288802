/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-mixins */
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import HasCategoricalCharts from 'partner/mixins/has-categorical-charts';
import HasEventCharts from 'partner/mixins/has-event-charts';
import NeedsOrganizationPromotionController from 'partner/mixins/needs-organization-promotion-controller';
import { firstByProperty } from 'partner/utils/computed';

export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionDashboardQuizController extends Controller.extend(
  HasCategoricalCharts,
  HasEventCharts,
  NeedsOrganizationPromotionController
) {
  currentSweepsWinnerPage = 1;
  winnersCardTransition = 'toLeft';

  @readOnly('organizationPromotion.kpi')
  totalEntries;

  @readOnly('organizationPromotion.numEligibleUsers')
  numEligibleUsers;

  @readOnly('model.matchups.firstObject.startDate')
  contestStartDate;

  @readOnly('model.matchups.firstObject.endDate')
  contestEndDate;

  @firstByProperty('name', 'Entry', 'model.eventCharts')
  entriesChart;

  @computed('currentSweepsWinnerPage', 'model.sweepstakesWinners.length')
  get sweepsCardWinnersPagingInfo() {
    const numSweepsWinners = this.model.sweepstakesWinners.length;
    return numSweepsWinners > 1 ? `(${this.currentSweepsWinnerPage} of ${numSweepsWinners})` : false;
  }
}
