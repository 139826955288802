/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { computed, get } from '@ember/object';
import Mixin from '@ember/object/mixin';
import moment from 'moment';
import { inject as service } from '@ember/service';

export default Mixin.create({
  defaultFormat: service('default-format'),

  //region Computed Properties
  nonABRecipients: computed('testRecipients', 'messageCampaign.estimatedRecipientsCount', function () {
    const { testRecipients } = this;
    const estimatedRecipientsCount = get(this, 'messageCampaign.estimatedRecipientsCount');
    return testRecipients &&
      estimatedRecipientsCount &&
      !isNaN(parseInt(testRecipients, 10)) &&
      !isNaN(parseInt(estimatedRecipientsCount, 10))
      ? estimatedRecipientsCount - testRecipients
      : 'NA';
  }),

  testRecipients: computed(
    'singleMessageCampaign.messageTestPercentage',
    'messageCampaign.estimatedRecipientsCount',
    function () {
      const messageTestPercentage = get(this, 'singleMessageCampaign.messageTestPercentage');
      const estimatedRecipientsCount = get(this, 'messageCampaign.estimatedRecipientsCount');
      return messageTestPercentage && estimatedRecipientsCount
        ? Math.round(estimatedRecipientsCount * (messageTestPercentage / 100))
        : 'NA';
    }
  ),

  // @deprecated - lives on MessageCampaign model now
  formattedScheduleStartDate: computed('singleMessageCampaign.scheduleStartDate', function () {
    const scheduleStartDate = get(this, 'singleMessageCampaign.scheduleStartDate');
    return scheduleStartDate ? this.formatMomentDate(moment(scheduleStartDate)) : null;
  }),

  // @deprecated - lives on MessageCampaign model now
  formattedEndOfABTestingDate: computed('singleMessageCampaign.endOfABTestingDate', function () {
    const endOfABTestingDate = get(this, 'singleMessageCampaign.endOfABTestingDate');
    return endOfABTestingDate ? this.formatMomentDate(moment(endOfABTestingDate)) : null;
  }),
  //endregiom

  //region Methods
  formatMomentDate(momentDate) {
    return `${momentDate.format(this.defaultFormat.getDateFormat({ full: true }))}`;
  },
  //endregion
});
