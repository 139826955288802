/* eslint-disable ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import ApplicationSerializer from 'partner/serializers/application';

export default ApplicationSerializer.extend(DS.EmbeddedRecordsMixin, {
  attrs: {
    fieldOptions: { embedded: 'always' },
    globalOptins: { embedded: 'always' },
    globalOptinUserOutgoingMappings: { embedded: 'always' },
    mediaItems: { embedded: 'always' },
  },
});
