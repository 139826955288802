/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import AudienceFiltersLoadDataRoute from 'partner/mixins/audience-filters-load-data-route';
import MessageCampaignSnackbar from 'partner/mixins/message-campaign-snackbar';
import { safeUnload } from 'partner/utils/ember-data';
import { timer } from 'partner/utils/timer';
import RSVP from 'rsvp';

const MESSAGE_CAMPAIGN_ROUTE = 'organizations.organization.message-campaigns.message-campaign';
const SETUP_ROUTE = `${MESSAGE_CAMPAIGN_ROUTE}.setup`;

export default Route.extend(AudienceFiltersLoadDataRoute, MessageCampaignSnackbar, {
  //region Ember Hooks
  snackbar: service(),
  store: service(),

  model() {
    const { organization } = this.modelFor('organizations.organization');
    const { messageCampaign, singleMessageCampaign } = this.modelFor(MESSAGE_CAMPAIGN_ROUTE);
    const { audiences, messageCampaignAudiences } = this.modelFor(SETUP_ROUTE);
    const organizationId = organization.id;

    return RSVP.hash({
      organizationId,
      audiences,
      fields: this.store.findAll('field'),
      messageCampaignAudiences,
      messageCampaign,
      singleMessageCampaign,
    });
  },

  async afterModel(model) {
    this._super(...arguments);
    const currentDate = new Date();
    const messageCampaignDate = new Date(model.messageCampaign.audienceMemberCountDate);
    const hoursPassed = (currentDate - messageCampaignDate) / 1000 / 60 / 60;

    if (hoursPassed > 1) {
      await this.innerRefreshRecipientCount();
    }
    if (model.messageCampaign.usesSingleMessageCampaign) {
      set(model, 'initialAdditionalRecipientsArray', model.singleMessageCampaign.additionalRecipientsArray.slice(0));
      set(model, 'initialMessageIsApprovalRequired', model.singleMessageCampaign.messageIsApprovalRequired);
    }
    if (model.messageCampaign.isDripCampaign && model.messageCampaign.sendingStatusTypeId >= 2) {
      set(model, 'disabledAudiences', model.messageCampaignAudiences.mapBy('audience'));
    }
    if (model.messageCampaign.isDripCampaign && model.messageCampaign.isConfirmed) {
      this.snackbar.show('The campaign is sending and this step can no longer be edited.', '', -1, null, true);
    }
  },
  //endregion

  //region Methods
  async save() {
    const model = this.modelFor(this.routeName);
    const smc = model.singleMessageCampaign;

    set(this, 'controller.error', null);
    set(this, 'controller.isAnythingSaving', true);
    set(this, 'controller.modifyingAudienceFailed', false);
    this.snackbar.hide();

    try {
      await Promise.all(model.messageCampaignAudiences.filterBy('hasDirtyAttributes').map(x => x.save()));
    } catch (error) {
      set(this, 'controller.modifyingAudienceFailed', true);
      model.messageCampaignAudiences.filterBy('isNew').forEach(messageCampaignAudience => {
        model.messageCampaignAudiences.removeObject(messageCampaignAudience);
        if (messageCampaignAudience) {
          safeUnload(messageCampaignAudience, this.store);
        }
      });
    }

    try {
      if (model.messageCampaign.usesSingleMessageCampaign) {
        if (smc.hasDirtyAttributes) {
          await smc.save();
        }
      }

      if (model.messageCampaign.isDripCampaign && model.messageCampaign.hasDirtyAttributes) {
        await model.messageCampaign.save();
      }

      if (model.messageCampaign.isDripCampaign && model.messageCampaign.sendingStatusTypeId >= 2) {
        set(model, 'disabledAudiences', model.messageCampaignAudiences.mapBy('audience'));
      }

      if (model.messageCampaign.usesSingleMessageCampaign) {
        set(model, 'initialAdditionalRecipientsArray', model.singleMessageCampaign.additionalRecipientsArray.slice(0));
        set(model, 'initialMessageIsApprovalRequired', model.singleMessageCampaign.messageIsApprovalRequired);
      }
    } catch (error) {
      set(this, 'controller.error', error);
      this.snackbar.show('Something went wrong. Please try again later.', '', -1);
    }

    if (isPresent(model.messageCampaignAudiences.rejectBy('isExcluded').mapBy('audience'))) {
      this.send('checkChecklistStep');
    } else {
      this.send('uncheckChecklistStep');
    }

    this.refreshRecipientCount();
    set(this, 'controller.isAnythingSaving', false);
  },

  async innerRefreshRecipientCount() {
    const { messageCampaign } = this.modelFor(this.routeName);

    await this.store.query('messageCampaign', {
      Id: messageCampaign.id,
      UpdateCount: true,
    });

    if (messageCampaign.usesSingleMessageCampaign) {
      await this.store.query('single-message-campaign', {
        Id: messageCampaign.id,
      });
    }
  },

  /**
   * Fetches the messageCampaign record w/ an updated totalRecipientCount
   */
  async refreshRecipientCount() {
    set(this, 'controller.calculating-boost-count', true);
    try {
      await Promise.all([timer(1000), this.innerRefreshRecipientCount()]);
    } finally {
      set(this, 'controller.calculating-boost-count', false);
    }
  },

  //endregion

  //region Actions
  actions: {
    loadFiltersData() {
      const loadAudiences = false;
      this.loadAudienceFilterData(loadAudiences).then(hash => {
        hash.audiences = this.modelFor(this.routeName).audiences;
        set(this, 'controller.audienceFiltersHash', hash);
      });
    },
    addMessageCampaignAudience(audience, isExcluded) {
      const { messageCampaign, messageCampaignAudiences } = this.modelFor(this.routeName);
      const messageCampaignAudience = this.store.createRecord('message-campaign-audience', {
        messageCampaign,
        audience,
        isExcluded,
      });
      messageCampaignAudiences.addObject(messageCampaignAudience);
    },
    removeMessageCampaignAudience(audience) {
      const { messageCampaignAudiences } = this.modelFor(this.routeName);
      const messageCampaignAudience = messageCampaignAudiences.findBy('audience.content', audience);
      messageCampaignAudiences.removeObject(messageCampaignAudience);
      if (messageCampaignAudience) {
        messageCampaignAudience.destroyRecord();
      }
      set(this, 'controller.error', null);
    },
    save() {
      set(this.controller, 'showDuplicateAdditionalEmailRecipientWarning', false);
      return this.save();
    },
    saveAndContinue() {
      return this.save().then(() => {
        if (!this.controller.isAnythingDirty) {
          this.send('continue');
        }
      });
    },
    willTransition(transition) {
      this._super();
      let confirmed = !this.controller.isAnythingDirty;
      confirmed = confirmed ? confirmed : window.confirm('Are you sure you want to leave without saving?');

      if (confirmed) {
        this.snackbar.hide();
        set(this, 'controller.error', null);
        set(this, 'controller.modifyingAudienceFailed', false);
        this.modelFor(this.routeName).messageCampaign.rollbackAttributes();
      } else {
        transition.abort();
      }
    },
  },
  //endregion
});
