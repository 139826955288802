/* global Redactor */
/* eslint-disable ember/no-side-effects, ember/use-ember-data-rfc-395-imports, ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, getProperties, set, setProperties } from '@ember/object';
import { alias, bool, filterBy, gt, not, setDiff } from '@ember/object/computed';
import { run, debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import DS from 'ember-data';
import Renderer from 'isomorphic-template-renderer';
import $ from 'jquery';
import flatten from 'lodash/flatten';
import moment from 'moment';
import TemplateDesigner from 'partner/mixins/template-designer';
import TickTock from 'partner/mixins/tick-tock';
import { generateRenderedContent, templateRendererData } from 'partner/utils/designer-preview';
import isAnyPath from 'partner/utils/is-any-path';
import isValidUrl from 'partner/utils/is-valid-url';
import { createTokenContent, createTokenContentsForMessageVersion } from 'partner/utils/message-version';
import replaceTokensInPreview from 'partner/utils/replace-tokens-in-preview';
import convertRssItemToTokenContentItem from 'partner/utils/rss-item-to-token-content-item';
import textFromHtml from 'partner/utils/text-from-html';
import { isItemToken } from 'partner/utils/tokens';
import RSVP from 'rsvp';
import { formatMoney } from 'secondstreet-common/utils/format-money';
import { insertIf } from 'secondstreet-common/utils/functional';
import { alphabeticalByProperty, keepSorting, simplyByProperty } from 'secondstreet-common/utils/sorting';

const TOKEN_KEYS = ['bodyText', 'mainButton', 'mainHeadline', 'mainImage'];
const dynamicTokenKeys = [
  'User First Name',
  'User Last Name',
  'User Birthdate',
  'User Gender',
  'User City',
  'User State',
  'User Postal Code',
];
const filterTokensByKey = token => TOKEN_KEYS.includes(get(token, 'key'));
const EXCLUDED_DYNAMIC_TOKEN_KEYS = [
  'System.ReadTracking',
  'System.BatchQueueId',
  'User.FullName',
  'Promotion.UrlEncoded',
  'Promotion.NameEncoded',
  'Organization.TimezoneInfoId',
];

const replaceTokens = replaceTokensInPreview(
  /{{System\.OptOutText}}|{{Organization\.(Name|Address|Timezone)}}|{{Event\.(Name|SignupStartDate|SignupEndDate|StartDate|EndDate)}}|{{Promotion\.(Name|EntryStartDate|EntryEndDate|SelectionStartDate|SelectionEndDate|EntryFrequency|SelectionFrequency|UserReferralUrl|SupportInfo)}}|{{Order\.(ConfirmationCode|PurchaseDate|TotalPrice|LineItems|PaymentMethod)}}|{{Product\.(Name|Price)}}/g
);

function messageHeaderText(headerType, shouldSanitize) {
  return computed(headerType, 'isEditingNewsletterInstance', 'tokensToReplace', function () {
    const header = get(this, headerType);
    try {
      const renderedHeader = Renderer.render(
        ...templateRendererData('', header, this.design.tokenContents, this.tokens, this.design.messageVersionFeeds)
      );
      if (this.isEditingNewsletterInstance) {
        set(this, headerType, renderedHeader);
      }
      const sanitizedRPT = new DOMParser().parseFromString(renderedHeader, 'text/html');
      return replaceTokens(shouldSanitize ? sanitizedRPT.body.textContent : renderedHeader, this.tokensToReplace);
    } catch (e) {
      console.error(e);
      return 'error';
    }
  });
}

const sortByUpsellThenName = templates =>
  templates
    .slice()
    .sort((a, b) =>
      keepSorting(simplyByProperty('isFreeTemplateForPromotions')(b, a), alphabeticalByProperty('name')(a, b))
    );

const sortByName = templates => templates.sortBy('name');

const sortByNameAndNumber = (a, b) => a.name.localeCompare(b.name, 'en', { numeric: true, sensitivity: 'base' });

export default Component.extend(TickTock, TemplateDesigner, {
  //region Dependencies
  enums: service(),
  store: service(),
  session: service(),
  snackbar: service(),
  features: service(),
  permissions: service(),
  deliberateConfirmation: service('deliberate-confirmation'),
  //endregion

  //region Attributes
  /**
   * @property {Object}
   */
  'message-campaign': null,
  /**
   * @property {Array}
   */
  'sender-accounts': null,
  /**
   * @property {Array}
   */
  tokens: null,
  /**
   * @property {Array}
   */
  templates: null,
  /**
   * @property {Number}
   */
  'message-version-history-id': null,
  /**
   * @property {Function}
   */
  'remove-version'() {},
  /**
   * @property {Function}
   */
  unschedule() {},
  /**
   * @property {Function}
   */
  'set-active-version'() {},
  /**
   * Creates additional message versions based on the first message version within the same message campaign
   * @property {Function}
   */
  copyMessageVersion() {},
  /**
   * @property {Object}
   */
  'single-message-campaign': null,
  /**
   * @property {Boolean}
   */
  'show-email-upsell': false,
  /**
   * A collection of allowed message campaign types, each with their corresponding message campaigns and message versions
   * This allows for a designer empty state with a create button if a certain campaign type does not have any message versions
   * @property {Array}
   */
  'message-campaign-type-views': null,
  /**
   * Creates additional message campaigns based on the first message campaign of the same type
   * @property {Function}
   */
  'copy-message-campaign'() {},
  /**
   * Associated with the create button for a message campaign type that does not have any message campaigns and is in an empty state
   * Only creates the first of its type; any additional types, if allowed, are handled by copy-message-campaign
   * @property {Function}
   */
  'add-new-message-campaign-type'() {},
  /**
   * @property {Array}
   */
  locations: null,
  /**
   * @property {Object}
   */
  organization: null,
  /**
   * All audiences available to the organization
   * @property {Array}
   */
  audiences: null,
  /**
   * Recipients of the message campaign
   * @property {Array}
   */
  messageCampaignAudiences: null,
  /**
   * @property {Function}
   */
  'set-active-campaign'() {},
  /**
   * @property {Function}
   */
  removeCampaign() {},
  /**
   * Contextual based on location in app, not message campaign type
   * @property {Boolean}
   */
  'display-message-campaign-headers': false,
  /**
   * Contextual based on location in app, not message campaign type
   * @property {Boolean}
   */
  'display-message-version-headers': false,
  /**
   * Action to trigger external changes when the feed item display order is changed
   * @property {Function}
   */
  unscheduleNewsletter() {},
  /**
   * @property {Object}
   */
  'organization-promotion': null,
  /**
   * @property {Object}
   */
  promotionProduct: null,
  /**
   * @property {Object}
   */
  'vote-interval': null,
  /**
   * @property {Object}
   */
  'votes-allowed': null,
  /**
   * @property {Object}
   */
  'entry-interval': null,
  /**
   * @property {Object}
   */
  'entries-allowed': null,
  /**
   * @property {Function}
   */
  'update-invite-recipients-count'() {},
  /**
   * @property {Boolean}
   */
  'is-updating-recipients-count': false,
  /**
   * A function that is called every time a message has been confirmed or unconfirmed
   * @property {Function}
   */
  'update-checklist-step'() {},
  /**
   * @property {Object}
   */
  audience: null,
  /**
   * @property {Function}
   */
  updateAudienceModel() {},
  /**
   * @property {Function}
   */
  updateMessageChecklistStep() {},
  settingTokenFallback: false,
  settingTokenFallbackTokenDisplayName: '',
  settingTokenFallbackTokenKey: '',
  currentTokenFallbackSetting: null,
  settingTokenFallbackValue: '',
  tokenFallbackSettings: null,
  //endregion

  //region Properties
  isSavingEarlyApproval: false,
  /**
   * The toggle property for showing/hiding the
   * from address menu in the "From" token flyout
   * @property {Boolean}
   */
  showFromAddressMenu: false,
  /**
   * The {SenderAccount} being created in the
   * From Address creation modal
   * @property {SenderAccount?}
   */
  newSenderAccount: null,
  /**
   * @property {Object}
   */
  messageCampaignToBeDeleted: null,
  /**
   * @property {Object}
   */
  messageVersionToBeDeleted: null,
  /**
   * @property {Object}
   */
  messageCampaignViewToBeModified: null,
  /**
   * @property {Boolean}
   */
  showRemoveMessageCampaignConfirmation: false,
  /**
   * @property {Boolean}
   */
  showRemoveMessageVersionConfirmation: false,
  /**
   * @property {Boolean}
   */
  methodChooserVisible: false,
  /**
   * @type {Boolean}
   */
  savingDefaultToken: false,
  /**
   * @type {Object}
   */
  newTokenDefaultContent: null,
  /**
   * @property {Boolean}
   */
  showSubjectInfo: false,
  /**
   * @property {Boolean}
   */
  showPreheaderInfo: false,
  /**
   * @property {String}
   */
  messageTo: '',
  /**
   * Whether the Info and Organization Settings link is currently being shown in From Address
   * @type {Boolean}
   */
  showFromAddressInfo: false,
  /**
   * @property {Boolean}
   */
  addingToken: false,
  /**
   * @property {String}
   */
  plainTextSetMessage: null,
  /**
   * @property {Boolean}
   */
  isRefreshingArticles: false,
  //endregion

  //region Computed Properties
  message: alias('design.message'),
  schedule: alias('message.schedule'),
  canSetPlainText: computed('design.{plainTextBody,body}', function () {
    return isPresent(this.design.body) && this.design.plainTextBody !== textFromHtml(this.design.body);
  }),
  hasDefaultSettingPermissions: computed('permissions.permissions.@each.permissionTypeId', function () {
    return this.permissions.getAccessLevel(
      'MessageBodyTemplate,Token,MessageBodyTemplateToken,OrganizationSettings'
    ).administer;
  }).readOnly(),
  defaultSavedMessage: computed('newTokenDefaultContent.errors', 'savingDefaultToken', function () {
    return this.newTokenDefaultContent &&
      isEmpty(get(this, 'newTokenDefaultContent.errors')) &&
      !this.savingDefaultToken
      ? 'Default Saved!'
      : null;
  }),
  setDefaultButtonText: computed('newTokenDefaultContent.errors', 'savingDefaultToken', function () {
    return this.savingDefaultToken && isEmpty(get(this, 'newTokenDefaultContent.errors'))
      ? 'Saving...'
      : 'Set as Default';
  }),
  canSetTokenAsDefault: computed(
    'message-campaign.isPromoEmail',
    'activeTokenContent.{value,isPlaceholder,isDefault}',
    'activeTokenContent.token.{hasCustomValue,tokenContentType,placeholderTokenDefaultContent.value,key}',
    'hasDefaultSettingPermissions',
    function () {
      if (!this.hasDefaultSettingPermissions || !this.activeTokenContent) {
        return false;
      }
      const { token } = this.activeTokenContent;
      if (isItemToken(token)) {
        return false;
      }
      const { isPromoEmail } = this['message-campaign'];
      const isPlaceholderValue =
        token.tokenContentType === 'Color'
          ? this.activeTokenContent.value === token.placeholderTokenDefaultContent.value
          : this.activeTokenContent.isPlaceholder;

      return !(isPromoEmail || isPlaceholderValue || token.hasCustomValue || this.activeTokenContent.isDefault);
    }
  ),
  /**
   * @returns {Array}
   */
  messageVersionFeeds: alias('design.messageVersionFeeds'),
  /**
   * @returns {String}
   */
  subjectLine: messageHeaderText('design.subject', false),
  /**
   * @returns {String}
   */
  preheaderText: messageHeaderText('design.preheaderTokenContent.value', true),
  /**
   * @returns {String}
   */
  displayedFromName: computed('design.fromName', 'tokensToReplace', function () {
    return replaceTokens(get(this, 'design.fromName'), this.tokensToReplace);
  }),
  /**
   * @returns {String}
   */
  fromNameFirstLetter: computed('displayedFromName', function () {
    return this.displayedFromName[0];
  }),
  /**
   * @returns {Boolean}
   */
  isNewsletter: bool('message-campaign.isNewsletter'),
  methodOptions: computed('isNewsletter', function () {
    const { isNewsletter } = this;
    return [
      {
        primaryLabel: isNewsletter ? 'RSS Feed' : 'Template',
        secondaryLabel: isNewsletter
          ? 'Import your RSS Feed into one of our pre-made templates and then customize it to create beautiful responsive emails without knowing any code.'
          : 'Customize pre-made templates to create beautiful responsive emails without knowing any code.',
        bodySourceType: 'Template',
      },
      {
        primaryLabel: 'Scrape Page',
        secondaryLabel:
          'Use the HTML from an external web page for the body of the message. The HTML will automatically be imported right before the message is sent.',
        bodySourceType: 'Scrape Url',
      },
      ...insertIf(!isNewsletter, {
        primaryLabel: 'HTML',
        secondaryLabel: 'Specify the HTML for the body of the message directly in our code editor.',
        bodySourceType: 'Custom',
      }),
    ];
  }),
  /**
   * @returns {Boolean}
   */
  _isEditingNewsletterInstance: null,
  isEditingNewsletterInstance: computed('message-version-history-id', '_isEditingNewsletterInstance', {
    get() {
      if (this._isEditingNewsletterInstance !== null) {
        return this._isEditingNewsletterInstance;
      }
      return !!this['message-version-history-id'];
    },
    set(_key, value) {
      this.set('_isEditingNewsletterInstance', value);
      return value;
    },
  }),
  isEditingNewsletterCampaign: computed('isEditingNewsletterInstance', 'isNewsletter', function () {
    return this.isNewsletter && !this.isEditingNewsletterInstance;
  }),
  editingDisabled: computed(
    'message-campaign.cannotCurrentlyEdit',
    'isEditingNewsletterInstance',
    'design.{isSent,isApproved}',
    function () {
      if (this.isEditingNewsletterInstance) {
        return get(this, 'design.isSent') || get(this, 'design.isApproved');
      }
      return get(this, 'message-campaign.cannotCurrentlyEdit');
    }
  ),
  editingEnabled: not('editingDisabled'),
  /**
   * Partially mirrors data structure of tokenCategories rendered by the designer
   * Intended to simplify logic for expanding token categories vs immediately opening token flyout
   * @returns {Array}
   */
  customTokenCategories: computed(
    'design.{isCustom,isScrapeUrl,isFromComplete,isSubjectComplete,isBodyHtmlComplete}',
    'isNewsletter',
    'recipientsAndScheduleIsComplete',
    function () {
      const articles = get(this, 'messageVersionFeedCategories.firstObject');
      const fromAndSubject = {
        category: 'FromAndSubject',
        contentGroups: [['From'], ['SubjectAndPreheader']],
        name: 'From & Subject',
        isComplete: this.design.isFromComplete && this.design.isSubjectComplete && !this.design.fromError,
      };
      const bodyContent = {
        category: 'BodyContent',
        contentGroups: [
          ...insertIf(get(this, 'design.isCustom'), ['BodyHtml']),
          ...insertIf(get(this, 'design.isScrapeUrl'), ['ScrapeUrl']),
          ...insertIf(!this.isNewsletter, ['PlainText']),
        ],
        name: 'Body',
        isComplete: get(this, 'design.isBodyHtmlComplete'),
      };
      const recipientsAndSchedule = {
        category: 'RecipientsAndSchedule',
        contentGroups: [['Recipients'], ['Schedule']],
        name: 'Recipients & Schedule',
        isComplete: this.recipientsAndScheduleIsComplete,
      };

      return [articles, fromAndSubject, bodyContent, recipientsAndSchedule];
    }
  ),
  recipientsAndScheduleIsComplete: computed(
    'messageCampaignAudiences.[]',
    'isScheduleStepComplete',
    'message-campaign.scheduleAndRecipientsInDesignStep',
    function () {
      return get(this, 'message-campaign.scheduleAndRecipientsInDesignStep')
        ? isPresent(this.messageCampaignAudiences) && this.isScheduleStepComplete
        : true;
    }
  ),
  messageVersionFeedCategories: computed(
    'messageVersionFeeds',
    'messageVersionFeedsWithErrors.[]',
    'dirtyMessageVersionFeeds.[]',
    'messageVersionFeedsAreComplete',
    function () {
      return [
        {
          category: 'Articles',
          name: 'Articles',
          isInvalid: isPresent(this.messageVersionFeedsWithErrors),
          isComplete: this.messageVersionFeedsAreComplete,
          hasDirtyAttributes: isPresent(this.dirtyMessageVersionFeeds),
          contentGroups: [this.messageVersionFeeds],
        },
      ];
    }
  ),
  /**
   * @returns {Array}
   */
  messageVersionFeedsWithErrors: filterBy('enabledMessageVersionFeeds', 'isValid', false),
  /**
   * @returns {Array}
   */
  enabledMessageVersionFeeds: filterBy('messageVersionFeeds', 'isDisabled', false),
  /**
   * @returns {Boolean}
   */
  messageVersionFeedsAreComplete: computed('enabledMessageVersionFeeds.{[],@each.isComplete}', function () {
    return this.enabledMessageVersionFeeds.isEvery('isComplete');
  }),
  /**
   * @returns {Array}
   */
  dirtyMessageVersionFeeds: filterBy('enabledMessageVersionFeeds', 'hasDirtyAttributes', true),
  /**
   * @returns {SenderAccount[]}
   */
  validSenderAccounts: filterBy('sender-accounts', 'validationStatus', 'Verified'),
  /**
   * Tokens displayed in Subject, Preheader, and socialHeaderText tokens
   * @returns {Token[]}
   */

  subjectTokens: computed('message-campaign.messageCampaignType', function () {
    return [
      ...this.dynamicTokens.filter(token =>
        [
          'User.FirstName',
          'User.LastName',
          'User.Birthdate',
          'User.City',
          'User.State',
          'User.PostalCode',
          'User.Gender',
          'User.EmailAddress',
          'Organization.Name',
          'MessageVersion.SendDate',
        ].includes(token.key)
      ),
      ...insertIf(
        this['message-campaign'].isReceipt,
        ...this.dynamicTokens.filter(token =>
          ['Order.TotalPrice', 'Order.PurchaseDate', 'Order.ConfirmationCode'].includes(token.key)
        )
      ),
      ...this.dynamicTokens.filterBy('dynamicTokenType', 'Article'),
      ...this.dynamicTokens.filterBy('key', 'Promotion.Name'),
    ].sortBy('name');
  }),
  /**
   * Tokens displayed in the WYSIWYG and Add Token modal
   * @returns {Token[]}
   */
  get sortedFilteredDynamicTokens() {
    return [...this.dynamicTokens.reject(token => EXCLUDED_DYNAMIC_TOKEN_KEYS.includes(token.key))].sort(
      sortByNameAndNumber
    );
  },
  /**
   * Tokens displayed in text fields (except Subject, Preheader, and socialHeaderText tokens)
   * @returns {Token[]}
   */
  get randomNumberTokens() {
    const keys = [
      'MessageVersion.Id',
      'MessageVersionInstance.Id',
      'Organization.Id',
      'Promotion.Url',
      'User.EmailAddress',
      'User.EmailAddress.MD5',
      'User.EmailAddress.SHA1',
      'User.EmailAddress.SHA256',
      'User.Id',
    ];

    return [
      ...this.dynamicTokens.filter(token => keys.includes(token.key)).sort(sortByNameAndNumber),
      // Put the random number tokens at the end of the list
      ...this.dynamicTokens
        .filter(token => token.dynamicTokenType === 'System' && token.key.includes('Random'))
        .sort(sortByNameAndNumber),
    ];
  },
  /**
   * Whether to show the info section for the subject
   * @type {Boolean}
   */
  infoTexts: computed(() => ({
    preheaderInfo:
      'Preheader text appears in the preview following the subject line when you are looking at a list of all of your emails in your inbox. It can be the difference between someone opening or archiving your message.',
    subjectInfo:
      'You can set the subject dynamically for each send by adding the token "{{#subject}}Subject here{{/subject}}" to your scrape page.',
  })),
  /**
   * @returns {Array}
   */
  templateTokens: computed('templates.@each.tokens', function () {
    return flatten(this.templates.map(template => get(template, 'tokens').toArray()));
  }),
  sortedMessageBodyTemplates: computed(
    'show-email-upsell',
    'message-campaign.{isNewsletter,isReceipt}',
    'templates.@each.{accommodatesMultipleItems,isFreeTemplateForPromotions,name}',
    function () {
      let relevantTemplates;
      if (this['message-campaign'].isNewsletter) {
        relevantTemplates = this.templates.filterBy('accommodatesMultipleItems');
      } else if (this['message-campaign'].isReceipt) {
        relevantTemplates = this.templates.filterBy('name', 'Payment Form Receipt Email');
      } else {
        relevantTemplates = this.templates;
      }

      const sortedTemplates = this['show-email-upsell']
        ? sortByUpsellThenName(relevantTemplates)
        : sortByName(relevantTemplates);
      return sortedTemplates.filterBy('body');
    }
  ),
  /**
   * @property {Function}
   * @param x
   */
  tokenCategoryFilter: computed('isEditingNewsletterCampaign', function () {
    const filterFunction =
      (...args) =>
      x =>
        !['From & Subject', ...args].includes(get(x, 'category'));
    return get(this, 'message-campaign.isDoubleOptinConfirmation')
      ? filterFunction('Social & Footer')
      : this.isEditingNewsletterCampaign
      ? filterFunction('Items')
      : filterFunction();
  }),
  /**
   * @property {Ember.ComputedProperty<Boolean>}
   */
  isPreviewVisible: computed('design.{isCustom,isScrapeUrl,renderedContent,bodyScrapeUrl}', function () {
    if (get(this, 'design.isCustom')) {
      return !!get(this, 'design.renderedContent');
    } else if (get(this, 'design.isScrapeUrl')) {
      return !!get(this, 'design.bodyScrapeUrl');
    }
    return true;
  }),
  /**
   * @returns {Boolean}
   */
  isAnythingDirty: isAnyPath('hasDirtyAttributes', [
    'design',
    'messageVersionFeeds.[]',
    'design.tokenContents.[]',
    'message',
    'schedule',
  ]),
  /**
   * @property {Ember.ComputedProperty<TokenCategory[]>}
   */
  filteredTokenCategories: computed('tokenCategories.[]', 'tokenCategoryFilter', function () {
    return this.tokenCategories.filter(this.tokenCategoryFilter);
  }),
  /**
   * Offsets the index of the token categories
   */
  tokenCategoryIndexOffset: computed('isNewsletter', 'isEditingNewsletterInstance', function () {
    return !this.isNewsletter || this.isEditingNewsletterInstance ? 3 : 4;
  }),
  earlyApprovalEnabled: computed(
    'single-message-campaign.{messageIsApprovalRequired,hasDirtyAttributes,isSaving}',
    function () {
      const smc = this['single-message-campaign'];
      return get(smc, 'messageIsApprovalRequired') && !get(smc, 'hasDirtyAttributes') && !get(smc, 'isSaving');
    }
  ),
  earlyApprovalSendTime: computed('single-message-campaign.messageApprovalMinutesBeforeSend', function () {
    const minutesPrior = get(this, 'single-message-campaign.messageApprovalMinutesBeforeSend');
    if (minutesPrior > 0) {
      return !(minutesPrior % 60) ? `${minutesPrior / 60} hours before` : `${minutesPrior} minutes before`;
    }
    return 'when';
  }),
  testEmailCategoryIndex: computed(
    'design.isTemplate',
    'isNewsletter',
    'message-campaign.scheduleAndRecipientsInDesignStep',
    'filteredTokenCategories.length',
    'hasMultipleTemplates',
    function () {
      const templateTokenCategories = get(this, 'filteredTokenCategories.length');
      const scheduleAndRecipientsCategoryCounter = get(this, 'message-campaign.scheduleAndRecipientsInDesignStep')
        ? 1
        : 0;
      const articlesCategoryCounter = this.isNewsletter ? 1 : 0;

      //From & Subject Category + (Templates Category OR Body Category) + (Test Email Category OR Test & Confirm Category)
      const customTokenCategories = scheduleAndRecipientsCategoryCounter + 3;

      return this.design.isTemplate && this.hasMultipleTemplates
        ? articlesCategoryCounter + templateTokenCategories + customTokenCategories
        : customTokenCategories;
    }
  ),

  hasMultipleTemplates: gt('sortedMessageBodyTemplates.length', 1),

  showArticleImport: computed(
    'design.{isTemplate,hasLegacyTemplate}',
    'message-campaign.{messageCampaignType,usesArticleImportFeature}',
    function () {
      return (
        get(this, 'design.isTemplate') &&
        !get(this, 'design.hasLegacyTemplate') &&
        get(this, 'message-campaign.usesArticleImportFeature')
      );
    }
  ),
  /**
   * Returns an array of new message-campaign-audiences, which is used to display a dirty state
   * @return {Array}
   */
  newMessageCampaignAudiences: filterBy('messageCampaignAudiences', 'isNew'),
  /**
   * Returns an array of the messageCampaignAudiences selected for this message campaign, and then extracts
   * the associated audience from each one. DS.PromiseArray prevents us from having to grab ".content" off
   * each audience promise.
   * @type {Ember.ComputedProperty}
   * @returns {Audience[]}
   */
  chosenAudiences: computed('messageCampaignAudiences.@each.audience', function () {
    return DS.PromiseArray.create({
      promise: RSVP.all(this.messageCampaignAudiences.mapBy('audience')),
    });
  }),
  /**
   * Subtracts already-included audiences from the full list of audiences to return an array of audiences that are eligible to be included.
   * @type {Ember.ComputedProperty}
   * @returns {Audience[]}
   */
  unchosenAudiences: setDiff('audiences', 'chosenAudiences'),
  /**
   * Subtracts Third-Party Audiences from the full list of audiences to return an array of audiences that are eligible to be included.
   * @type {Ember.ComputedProperty}
   * @returns {Audience[]}
   */
  availableAudiences: computed('chosenAudiences.[]', 'unchosenAudiences.{[],@each.isThirdPartyAudience}', function () {
    if (isPresent(this.chosenAudiences)) {
      return this.unchosenAudiences.rejectBy('isThirdPartyAudience');
    }
    return this.unchosenAudiences;
  }),
  chosenThirdPartyAudiences: filterBy('chosenAudiences', 'isThirdPartyAudience'),
  designIsComplete: computed(
    'message-campaign.scheduleAndRecipientsInDesignStep',
    'design.isComplete',
    'isScheduleStepComplete',
    'messageCampaignAudiences',
    function () {
      if (get(this, 'message-campaign.scheduleAndRecipientsInDesignStep')) {
        return this.design.isComplete && this.isScheduleStepComplete && isPresent(this.messageCampaignAudiences);
      }
      return this.design.isComplete;
    }
  ),
  incompleteCategories: computed(
    'design.isTemplate',
    'filteredTokenCategories.@each.{isComplete,name}',
    'customTokenCategories.@each.{isComplete,name}',
    function () {
      const incompleteCustomTokenCategories = this.customTokenCategories.rejectBy('isComplete').mapBy('name');

      if (get(this, 'design.isTemplate')) {
        return [
          ...insertIf(incompleteCustomTokenCategories.includes('From & Subject'), 'From & Subject'),
          ...this.filteredTokenCategories.rejectBy('isComplete').mapBy('name'),
          ...insertIf(incompleteCustomTokenCategories.includes('Recipients & Schedule'), 'Recipients & Schedule'),
        ];
      }

      return incompleteCustomTokenCategories;
    }
  ),
  scheduleStartDate: computed('schedule.startDate', {
    get() {
      return this.schedule.startDate;
    },
    set(key, value) {
      set(this.schedule, 'startDate', value);
      return value;
    },
  }),
  organizationAddress: computed('locations.firstObject.displayedOrganizationName', function () {
    return isPresent(this.locations) ? this.locations.firstObject.displayedOrganizationName : '';
  }),
  tokensToReplace: computed(
    'organization.{name,timeZoneId}',
    'organizationAddress',
    'entry-interval',
    'entries-allowed',
    'vote-interval',
    'votes-allowed',
    'organization-promotion.{name,orgTimeZoneSubmissionStartDate,orgTimeZoneSubmissionEndDate,orgTimeZoneSelectionStartDate,orgTimeZoneSelectionEndDate}',
    'promotionProduct.price',
    function () {
      const findInterval = (entryIntervalTypeId, entriesAllowedNumber) => {
        //we ignore entry interval type id of 1 because it sounds weird to specify a one-time-only frequency
        if (!entryIntervalTypeId || !entriesAllowedNumber || entryIntervalTypeId === 1) {
          return '';
        }
        return this.enums
          .findWhere('UGC_VOTING_ENTRY_OPTIONS', { entryIntervalTypeId, entriesAllowedNumber }, 'name')
          .toLowerCase();
      };

      return {
        '{{System.OptOutText}}':
          '<a style="color: #199cd0" href="#">Click here to unsubscribe and manage your email subscriptions.</a>',
        '{{Organization.Name}}': get(this, 'organization.name'),
        '{{Organization.Address}}': this.organizationAddress,
        '{{Organization.Timezone}}': this.enums.findWhere(
          'TIME_ZONES',
          { id: get(this, 'organization.timeZoneId') },
          'displayName'
        ),
        '{{Promotion.Name}}': get(this, 'organization-promotion.name'),
        '{{Promotion.EntryStartDate}}': get(this, 'organization-promotion.orgTimeZoneSubmissionStartDate'),
        '{{Promotion.EntryEndDate}}': get(this, 'organization-promotion.orgTimeZoneSubmissionEndDate'),
        '{{Promotion.SelectionStartDate}}': get(this, 'organization-promotion.orgTimeZoneSelectionStartDate'),
        '{{Promotion.SelectionEndDate}}': get(this, 'organization-promotion.orgTimeZoneSelectionEndDate'),
        '{{Promotion.EntryFrequency}}': findInterval(
          get(this, 'entry-interval.value'),
          get(this, 'entries-allowed.value')
        ),
        '{{Promotion.SelectionFrequency}}': findInterval(
          get(this, 'vote-interval.value'),
          get(this, 'votes-allowed.value')
        ),
        '{{Promotion.UserReferralUrl}}': 'https://your-promotion-url.com/contest/referral/1234-abcd-4567-efgh-8910',
        '{{Event.Name}}': get(this, 'matchups.firstObject.name'),
        '{{Event.SignupStartDate}}': get(this, 'organization-promotion.orgTimeZoneSubmissionStartDate'),
        '{{Event.SignupEndDate}}': get(this, 'organization-promotion.orgTimeZoneSubmissionEndDate'),
        '{{Event.StartDate}}': get(this, 'organization-promotion.orgTimeZoneSelectionStartDate'),
        '{{Event.EndDate}}': get(this, 'organization-promotion.orgTimeZoneSelectionEndDate'),
        '{{Order.TotalPrice}}': `$${this.promotionProduct?.price ? formatMoney(this.promotionProduct.price) : ''}`,
        '{{Product.Name}}': this.productName,
        '{{Product.Price}}': `$${this.promotionProduct?.price ? formatMoney(this.promotionProduct.price) : ''}`,
        '{{Order.PurchaseDate}}': new Date().toLocaleDateString(),
        '{{Order.PaymentMethod}}': 'Visa ending in ****',
        '{{Order.ConfirmationCode}}': '1234-ABCD',
        '{{Promotion.SupportInfo}}': this.supportInfo,
      };
    }
  ),
  htmlPreview: computed('design.renderedContent', 'tokensToReplace', function () {
    return replaceTokens(get(this, 'design.renderedContent'), this.tokensToReplace);
  }),
  showEarlyScheduleWarning: computed('scheduleStartDate', 'organization-promotion.startDate', function () {
    if (this.scheduleStartDate && get(this, 'organization-promotion.startDate')) {
      return this.scheduleStartDate < get(this, 'organization-promotion.startDate');
    }
    return false;
  }),
  isScheduleStepComplete: computed('tock', 'message-campaign.isConfirmed', 'schedule.startDate', function () {
    return (
      this.schedule.startDate &&
      (get(this, 'message-campaign.isConfirmed') || moment(this.schedule.startDate).add(5, 'm').toDate() > new Date())
    );
  }),
  messageCampaignConfirmationRemovalMessageYes: computed('messageCampaignToBeDeleted', function () {
    return `I want to permanently remove this ${this.messageCampaignToBeDeleted.displayName}.`;
  }),
  messageCampaignConfirmationRemovalMessageNo: computed('messageCampaignToBeDeleted', function () {
    return `I do not want to remove this ${this.messageCampaignToBeDeleted.displayName}.`;
  }),

  disableTokenizedInput: computed('message-campaign', 'activeTokenContent', function () {
    return (
      get(this, 'message-campaign.isDoubleOptinConfirmation') &&
      this.activeTokenContent.linkUrl?.includes('{{EmailVerificationLink}}')
    );
  }),

  preventReminderFromFrequency: computed('matchups', function () {
    return (
      (get(this, 'organization-promotion.promotion.isSweepstakesCodeword') ||
        get(this, 'organization-promotion.promotion.isSweepstakesSimple')) &&
      (this.matchups?.any(matchup => matchup.entriesAllowedNumber === 999) ||
        (this.matchups?.length == 1 && this.matchups?.firstObject?.entryIntervalTypeId == 1))
    );
  }),
  //endregion

  //region Hooks
  init() {
    this._super(...arguments);
    if (!this.audiences) {
      set(this, 'audiences', []);
    }
    if (!this.messageCampaignAudiences) {
      set(this, 'messageCampaignAudiences', []);
    }
    if (!this.tokenFallbackSettings) {
      this.tokenFallbackSettings = [];
    }
    if (!this.currentTokenFallbackSetting) {
      this.currentTokenFallbackSetting = {};
    }
  },
  //endregion

  //region Methods
  updateBody() {
    set(this, 'design.body', generateRenderedContent(this.design, this.tokens, this.dipsUrl.value));
  },
  /**
   * This toggles the message version type id based on selected options
   * @param feed
   * @param messageVersionFeedArticleQuantityTypeId
   * @param articles
   * @param hours
   */
  setFeedTypeProperties(feed, messageVersionFeedArticleQuantityTypeId, articles, hours) {
    setProperties(feed, {
      messageVersionFeedArticleQuantityTypeId,
      articleQuantity: articles,
      timeQuantity: hours,
    });
  },
  async createTokenContentsFor(template) {
    const messageVersion = this.isEditingNewsletterInstance ? await this.design.messageVersion : this.design;
    messageVersion.tokenContents.filterBy('isNew').map(unusedTokenContent => unusedTokenContent.deleteRecord());
    const newTemplateTokens = this.isEditingNewsletterCampaign
      ? template.tokens.rejectBy('key', 'item')
      : template.tokens;

    const newTokenContents = createTokenContentsForMessageVersion(messageVersion, newTemplateTokens, this.store);

    if (this.isEditingNewsletterInstance) {
      newTokenContents.forEach(newTokenContent => set(newTokenContent, 'messageVersionHistory', this.design));
    }
  },
  updateToken(tokenContent, rssItem, useFullArticle) {
    const defaultContent = get(tokenContent, 'token.placeholderTokenDefaultContent');
    const hasDefaultButtonText = defaultContent.itemButtonText === tokenContent.itemButtonText;
    run(() =>
      setProperties(tokenContent, {
        value: useFullArticle ? rssItem.content : rssItem.description,
        linkUrl: rssItem.linkUrl,
        altText: rssItem.imageUrl && !useFullArticle ? rssItem.title : '',
        itemTitle: rssItem.title,
        itemImageLinkUrl: rssItem.imageUrl && !useFullArticle ? rssItem.linkUrl : '',
        itemButtonLinkUrl: tokenContent.itemButtonText ? rssItem.linkUrl : '',
        itemImageExternalSrcUrl: !useFullArticle ? rssItem.imageUrl : '',
        itemButtonText: hasDefaultButtonText ? 'Read More' : tokenContent.itemButtonText,
        itemPublishDate: null,
        isDisabled: false,
        mediaItemId: null,
      })
    );
    return tokenContent;
  },
  updateSubjectAndPreheader(article) {
    if (isPresent(article.title)) {
      set(this, 'design.subject', article.title);
    }
    if (isPresent(article.description)) {
      set(this, 'design.preheaderTokenContent.value', article.description);
    }
  },
  deleteTemplateTokens(tokenCategory, startIndex) {
    const relevantCategory = this.tokenCategories.findBy('category', tokenCategory);
    if (!relevantCategory) {
      return;
    }
    const excessTokens = get(relevantCategory, 'contentGroups.firstObject').slice(startIndex);
    if (excessTokens.isEvery('hasDefaultContent')) {
      excessTokens.forEach(token => {
        token.destroyRecord();
      });
    }
  },
  async setSenderAccount(senderAccount) {
    const usesSingleMessageCampaign =
      get(this, 'message-campaign.usesSingleMessageCampaign') ||
      get(this, 'design.message.messageCampaign.usesSingleMessageCampaign');
    set(this, 'design.senderAccount', senderAccount);
    const { replyTo, fromName } = getProperties(senderAccount, 'replyTo', 'fromName');
    if (replyTo) {
      set(this, 'design.replyTo', replyTo);
    }
    if (fromName) {
      set(this, 'design.fromName', fromName);
    }
    await this.design.save();
    this.updateMessageChecklistStep(usesSingleMessageCampaign);
  },
  addNewTokenContent(designToken, relevantMessageVersion, previousItemContents) {
    const properties = [
      'isDisabled',
      'value',
      'linkUrl',
      'title',
      'altText',
      'mediaItemId',
      'itemTitle',
      'itemImageExternalSrcUrl',
      'itemImageLinkUrl',
      'itemButtonLinkUrl',
      'itemButtonText',
      'displayOrder',
    ];
    // Load default attribute values:
    const token = this.tokens.findBy('key', 'item');
    const defaultTokenContents = get(token, 'tokenDefaultContents.firstObject');
    const defaultAttrs = getProperties(defaultTokenContents, properties);
    // Get previous item's attribute values:
    const previousAttrs = getProperties(previousItemContents, properties);
    const newAttrs = {
      token: designToken,
      messageVersion: relevantMessageVersion,
      displayOrder: get(designToken, 'isSortable')
        ? get(
            get(relevantMessageVersion, 'messageVersionTemplateTokenContents')
              .filterBy('token.isSortable')
              .filterBy('token.key', get(designToken, 'key')),
            'length'
          ) + 1
        : null,
    };
    // For properties not explicitly set, copy from the previous item (or the default item as a fallback)
    for (const property in previousAttrs) {
      if (
        Object.prototype.hasOwnProperty.call(previousAttrs, property) &&
        Object.prototype.hasOwnProperty.call(defaultAttrs, property) &&
        !Object.prototype.hasOwnProperty.call(newAttrs, property)
      ) {
        newAttrs[property] = previousAttrs[property] ? defaultAttrs[property] : previousAttrs[property];
      }
    }
    // Return new template token content:
    return this.store.createRecord('message-version-template-token-content', newAttrs);
  },
  unconfirmMessage() {
    set(this, 'message-campaign.isConfirmed', false);
    this['message-campaign'].save();
    this['update-checklist-step']();
    this.snackbar.show('This email will not send until you finalize your design and confirm it.');
  },
  /**
   * Determines whether the subject and/or preheader should be updated after
   * items are resorted, then updates and saves
   * @param  {MessageVersionTemplateTokenContent} oldArticle The first article before resorting
   * @param  {MessageVersionTemplateTokenContent} newArticle The first article after resorting
   */
  replaceSubjectAndPreheader(oldArticle, newArticle) {
    if (get(this, 'design.subject') === get(oldArticle, 'itemTitle')) {
      set(this, 'design.subject', get(newArticle, 'itemTitle'));
    }

    const oldSanitizedDescription = new DOMParser().parseFromString(get(oldArticle, 'value'), 'text/html');
    if (this.preheaderText === oldSanitizedDescription.body.textContent) {
      const newSanitizedDescription = new DOMParser().parseFromString(get(newArticle, 'value'), 'text/html');
      set(this, 'design.preheaderTokenContent.value', newSanitizedDescription.body.textContent);
    }
    this.send('save');
  },
  async alterFeedUrl(feed, rssFeedUrl, prevError) {
    let suffix;
    if (prevError && prevError.errors[0].detail.includes('Url provided did not have data in an expected RSS format')) {
      if (!(rssFeedUrl.endsWith('/feed') || rssFeedUrl.endsWith('/feed/'))) {
        suffix = rssFeedUrl.endsWith('/') ? 'feed/' : '/feed/';
        set(feed, 'feedUrl', `${rssFeedUrl}${suffix}`);
        try {
          $('.rss-url input').attr('disabled', 'disabled');
          await feed.save();
        } catch (error) {
          set(feed, 'feedUrl', rssFeedUrl);
          get(feed, 'errors').add('feedUrl', prevError.errors[0].detail);
        }
        $('.rss-url input').removeAttr('disabled');
      }
    }
  },
  async saveFeedUrl() {
    const unsavedMessageVersionFeeds = this.design.messageVersionFeeds.filterBy('hasDirtyAttributes');
    await RSVP.all(
      unsavedMessageVersionFeeds.map(async feed => {
        let rssFeedUrl = get(feed, 'feedUrl');
        if (rssFeedUrl.length >= 4) {
          set(feed, 'feedUrl', rssFeedUrl.startsWith('http') ? rssFeedUrl : `http://${rssFeedUrl}`);
        }
        rssFeedUrl = get(feed, 'feedUrl');
        if (isValidUrl(rssFeedUrl) || isEmpty(rssFeedUrl)) {
          await feed.save().catch(error => {
            get(feed, 'errors').remove('feedUrl');
            this.alterFeedUrl(feed, rssFeedUrl, error);
          });
          this.updateBody();
          this.design.save();
        }
        return true;
      })
    );
  },
  // handles non-Template emails that were created via the API and were then switched to Template in the interface
  async assignTemplate() {
    const template = await get(this, 'design.messageBodyTemplate');
    if (isEmpty(template)) {
      const firstTemplate = this.sortedMessageBodyTemplates.firstObject;
      set(this, 'design.messageBodyTemplate', firstTemplate);
      this.createTokenContentsFor(firstTemplate);
    }
  },
  adjustItems(maxItemsAllowed) {
    this.design.tokenContents
      .filterBy('token.category', 'Items')
      .sortBy('displayOrder')
      .slice(maxItemsAllowed)
      .forEach(item => {
        set(item, 'isDisabled', true);
      });
  },
  adjustMessageVersionFeeds(id, articles, hours) {
    this.messageVersionFeeds.forEach(feed => {
      const maxArticlesAllowed = get(this, 'design.template.maxItemsAllowed');
      const newArticleQuantity = Math.min(feed.articleQuantity || articles, maxArticlesAllowed);
      this.setFeedTypeProperties(feed, id, newArticleQuantity, hours);
    });
  },
  async syncArticleContent(rssItem, useFullArticle) {
    const { tokenContents } = this.design;
    const currentTokens = tokenContents.mapBy('token').filter(filterTokensByKey).mapBy('key');
    const tokens = this.tokens.filter(filterTokensByKey);
    const missingTokens = tokens.reject(token => currentTokens.includes(get(token, 'key')));
    missingTokens.forEach(token => createTokenContent(this.design, token, this.store));
    const bodyContent = tokenContents.findBy('token.key', 'bodyText');
    const mainImageContent = tokenContents.findBy('token.key', 'mainImage');
    const mainHeadlineContent = tokenContents.findBy('token.key', 'mainHeadline');
    const mainButtonContent = tokenContents.findBy('token.key', 'mainButton');
    set(bodyContent, 'value', useFullArticle ? rssItem.content : rssItem.description);
    set(mainButtonContent, 'linkUrl', rssItem.linkUrl);
    set(mainButtonContent, 'value', 'Read More');

    if (rssItem.imageUrl) {
      // don't try to set a token content's value to undefined
      // also don't set link URL and alt text if no image
      set(mainImageContent, 'value', rssItem.imageUrl);
      set(mainImageContent, 'linkUrl', rssItem.linkUrl);
      set(mainImageContent, 'altText', rssItem.title);
    }

    set(mainHeadlineContent, 'value', rssItem.title);
    this.updateSubjectAndPreheader(rssItem);
    return Promise.all(
      [bodyContent, mainImageContent, mainHeadlineContent, mainButtonContent, this.design.preheaderTokenContent].map(
        tokenContent => tokenContent.save()
      )
    );
  },
  async refreshArticles() {
    set(this, 'isRefreshingArticles', true);

    try {
      await this.store.query('message-version-feed', { messageCampaignId: get(this, 'message-campaign.id') });
    } catch (error) {
      this.snackbar.show(error.message, 'dismiss', -1, 'error', true);
      return set(this, 'isRefreshingArticles', false);
    }

    const enabledMessageVersionFeeds = this.messageVersionFeeds.rejectBy('isDisabled');

    // show a toast and bail if no items come back on any feeds
    if (isEmpty(enabledMessageVersionFeeds.mapBy('items').flat())) {
      this.snackbar.show('The RSS feeds used by this Newsletter have no articles.');
      return set(this, 'isRefreshingArticles', false);
    }

    const itemTokenContentTypeId = this.enums.findWhere('TOKEN_CONTENT_TYPE', { name: 'Item' }, 'id');
    const existingItemTokenContents = this.design.messageVersionTemplateTokenContents.filterBy(
      'token.tokenContentTypeId',
      itemTokenContentTypeId
    );
    const itemToken = this.tokens.findBy('tokenContentTypeId', itemTokenContentTypeId);

    // convert RSS Items into Item Token Content-like objects
    const newItemTokenContentProps = enabledMessageVersionFeeds
      .map(messageVersionFeed =>
        messageVersionFeed.items.map(
          convertRssItemToTokenContentItem(messageVersionFeed, this.design.messageVersion, this.design, itemToken)
        )
      )
      .flat();

    // update the subject and preheader with data from the first RSS item
    this.updateSubjectAndPreheader(enabledMessageVersionFeeds.firstObject.items.firstObject);

    // if we have more RSS Items than we have existing Item Token Contents, make up that difference
    const newItemTokenContents = [];
    for (let i = existingItemTokenContents.length; i < newItemTokenContentProps.length; i++) {
      newItemTokenContents.push(this.store.createRecord('message-version-template-token-content'));
    }

    // if we have more Item Token Contents than we have RSS Items, apportion the ones we can delete
    const excessItemTokenContents = existingItemTokenContents.slice(newItemTokenContentProps.length);

    // populate existing Item Token Contents with properties from the Item Token Content-like objects
    const refreshedItemTokenContents = [...existingItemTokenContents, ...newItemTokenContents].map(
      (itemTokenContent, index) => {
        setProperties(itemTokenContent, {
          displayOrder: index + 1,
          ...newItemTokenContentProps.objectAt(index),
        });
        return itemTokenContent;
      }
    );

    await Promise.all([
      ...refreshedItemTokenContents.map(itemTokenContent => itemTokenContent.save()),
      ...excessItemTokenContents.map(itemTokenContent => itemTokenContent.destroyRecord()),
    ]);

    this.design.messageVersionTemplateTokenContents.addObjects(newItemTokenContents);
    this.design.messageVersionTemplateTokenContents.removeObjects(excessItemTokenContents);

    return set(this, 'isRefreshingArticles', false);
  },
  getDynamicTokenSettingWithTargetEntityId(key) {
    const token = this.tokens.findBy('key', key);
    return {
      setting: this.tokenFallbackSettings.findBy('targetEntityId', token.fieldId) || null,
      targetEntityId: token.fieldId || null,
    };
  },
  //endregion

  //region Actions
  actions: {
    showDeleteMessageCampaignConfirmation(messageCampaignView) {
      setProperties(this, {
        showRemoveMessageCampaignConfirmation: true,
        messageCampaignToBeDeleted: messageCampaignView.messageCampaign,
      });
    },
    removeCampaign() {
      const { messageCampaignToBeDeleted } = this;
      set(this, 'showRemoveMessageCampaignConfirmation', false);
      this.removeCampaign(messageCampaignToBeDeleted);
    },
    cancelCampaignRemoval() {
      setProperties(this, {
        showRemoveMessageCampaignConfirmation: false,
        messageCampaignToBeDeleted: null,
      });
    },
    showDeleteMessageVersionConfirmation(messageCampaignView, messageVersion) {
      setProperties(this, {
        showRemoveMessageVersionConfirmation: true,
        messageVersionToBeDeleted: messageVersion,
        messageCampaignViewToBeModified: messageCampaignView,
      });
    },
    removeVersion() {
      const { messageVersionToBeDeleted } = this;
      const { messageCampaignViewToBeModified } = this;
      const messageVersions = get(messageCampaignViewToBeModified, 'messageVersions');
      set(this, 'showRemoveMessageVersionConfirmation', false);

      this['remove-version'](messageVersionToBeDeleted, messageVersions);
    },
    cancelVersionRemoval() {
      setProperties(this, {
        showRemoveMessageVersionConfirmation: false,
        messageVersionToBeDeleted: null,
        messageCampaignViewToBeModified: null,
      });
    },
    showChooseMethodModal() {
      this.toggleProperty('methodChooserVisible');
    },
    toggleTestSend() {
      set(this, 'customTokenContent', 'Test');
      if (isBlank(this.messageTo)) {
        set(this, 'messageTo', get(this, 'session.data.authenticated.username'));
      }
    },
    toggleTestAndConfirm() {
      set(this, 'customTokenContent', 'TestAndConfirm');
      if (isBlank(this.messageTo)) {
        set(this, 'messageTo', get(this, 'session.data.authenticated.username'));
      }
    },
    async toggleConfirmation() {
      this.toggleProperty('message-campaign.isConfirmed');
      this['update-checklist-step']();
      this['message-campaign'].save();
    },
    toggleInfo(token) {
      this.toggleProperty(`show${token}Info`);
    },
    async toggleMessageCampaignProperty(property) {
      this.toggleProperty(`message-campaign.${property}`);
      await this['message-campaign'].save();
      await this['update-invite-recipients-count'](get(this, 'message-campaign.id'));
    },
    setSenderAccount(senderAccount) {
      this.setSenderAccount(senderAccount);
    },
    updateMessageVersionFeedProperty(property, value, updateBody = true) {
      set(this, `customTokenContent.${property}`, value);
      if (updateBody) {
        this.updateBody();
      }
    },
    async saveMessageVersionFeed() {
      await this.customTokenContent.save();
      this.updateBody();
      this.design.save();
    },
    saveMessageVersionFeedUrl() {
      debounce(this, this.saveFeedUrl, 3000);
    },
    updateMessageVersionProperty(property, value) {
      set(this, `design.${property}`, value);
    },
    updatePreheaderTokenContent(value) {
      set(this, 'design.preheaderTokenContent.value', value);
      this.updateBody();
    },
    async save() {
      if (!this.isAnythingDirty || this.editingDisabled) {
        return;
      }
      const { design } = this;
      const usesSingleMessageCampaign =
        get(this, 'message-campaign.usesSingleMessageCampaign') ||
        get(design, 'message.messageCampaign.usesSingleMessageCampaign');
      const newMessageVersionFeed = get(design, 'messageVersionFeeds').findBy('isNew');

      if (isPresent(design.body) && design.isTemplate) {
        set(design, 'plainTextBody', textFromHtml(design.body));
      }

      //we need to do some additional work if the Confirmation step is inside the designer
      if (get(this, 'message-campaign.quickConfirmation')) {
        if (!this.designIsComplete && get(this, 'message-campaign.isConfirmed')) {
          this.unconfirmMessage();
        }
      }

      if (this.schedule?.isStartDateDirty) {
        this.schedule.save();
        if (get(this, 'message-campaign.isConfirmed')) {
          this.unconfirmMessage();
        }
      }

      if (isPresent(newMessageVersionFeed)) {
        await newMessageVersionFeed.save();
        this.updateBody();
      }

      this.save(design, usesSingleMessageCampaign, this.isEditingNewsletterInstance);
    },
    createNewMessageVersionFeed() {
      const messageVersion = this.design;
      const articleLimit = get(this, 'design.template.maxItemsAllowed');
      const newFeed = this.store.createRecord('message-version-feed', { messageVersion });
      set(newFeed, 'rssTokenName', `RSS Feed ${this.messageVersionFeeds.length}`);
      this.setFeedTypeProperties(newFeed, articleLimit ? 2 : 1, articleLimit ? Math.min(articleLimit, 5) : null, null);
      this.send('editTokenContent', newFeed);
    },
    async sendTestMessage() {
      const messageVersionTest = this.store.createRecord('messageVersionTest', {
        messageVersionId: get(this, 'design.messageVersionId') || get(this, 'design.id'),
        messageTo: this.messageTo,
      });
      await messageVersionTest.save();
      this.snackbar.show('Test email successfully sent');
      set(this, 'design.testMessageWasSent', true);
    },
    async resendApprovalMessage() {
      const messageVersionHistoryId = this['message-version-history-id'];
      await this.store
        .createRecord('message-approval', { resend: true, isApproved: false, messageVersionHistoryId })
        .save();
      this.snackbar.show('A new approval email was sent to you!');
    },
    async replaceArticles(rssItems, useFullArticles) {
      // importing an article for a specific item
      const firstRssItem = rssItems.firstObject;
      if (isPresent(this.activeTokenContent)) {
        // replace the active token contents
        await this.updateToken(this.activeTokenContent, firstRssItem, useFullArticles).save();
        if (this.activeTokenContentIndex === 1) {
          await this.syncArticleContent(firstRssItem, useFullArticles);
        }
      } else {
        const articlesCategory = this.tokenCategories.findBy('category', 'Items');
        if (articlesCategory) {
          //replace all of message-version-template-token-content with theses articles
          const contentGroup = get(articlesCategory, 'contentGroups.firstObject');
          const token = get(contentGroup, 'firstObject.token');

          for (let i = contentGroup.length; i < rssItems.length; i++) {
            const tokenContent = this.addNewTokenContent(token, this.design, this.lastTokenContent('Items'));
            contentGroup.addObject(tokenContent);
          }

          await Promise.all(
            rssItems.map((rssItem, index) => this.updateToken(contentGroup[index], rssItem, useFullArticles).save())
          );
        } else {
          // no article category = no items = template that doesn't support items (coin, simple, note)
          const itemTokenContent = get(this, 'design.tokenContents').findBy('token.key', 'item');
          await this.updateToken(itemTokenContent, firstRssItem, useFullArticles).save();
        }

        await this.syncArticleContent(firstRssItem, useFullArticles);

        if (this.design.template.maxItemsAllowed !== 1) {
          await this.deleteTemplateTokens('Items', rssItems.length);
        }
      }

      this.updateBody();
      await this.design.save();
      set(this, 'showImportArticlesModal', false);
    },
    async enableEarlyApproval() {
      const singleMessageCampaign = this['single-message-campaign'];
      const messageApprovalRecipients = get(singleMessageCampaign, 'messageApprovalRecipients');

      setProperties(singleMessageCampaign, {
        messageIsApprovalRequired: true,
        messageApprovalRecipients: isEmpty(messageApprovalRecipients)
          ? get(this, 'session.data.authenticated.username')
          : messageApprovalRecipients,
        messageApprovalMinutesBeforeSend: 720,
      });
      await singleMessageCampaign.save();
    },
    async createFromAddress() {
      const newSenderAccount = await this.store.createRecord('sender-account');
      set(this, 'newSenderAccount', newSenderAccount);
      set(this, 'showCreateFromAddressModal', true);
    },
    closeCreateFromModal(setSender = true) {
      set(this, 'showCreateFromAddressModal', false);
      if (setSender) {
        this.setSenderAccount(this.newSenderAccount);
      }
    },
    toggleFromAddressMenu() {
      if (this.editingEnabled) {
        this.toggleProperty('showFromAddressMenu');
      }
    },
    async createAndSaveNewTokenContentDefaultContent() {
      set(this, 'savingDefaultToken', true);
      const tokenContent = this.activeTokenContent;
      const tdc = this.store.createRecord('token-default-content', {
        token: get(tokenContent, 'token'),
        value: get(tokenContent, 'value'),
        altText: get(tokenContent, 'altText'),
        mediaItemId: get(tokenContent, 'mediaItemId'),
        linkUrl: get(tokenContent, 'linkUrl'),
      });
      if (get(tokenContent, 'token.key') === 'item') {
        setProperties(tdc, {
          itemButtonLinkUrl: get(tokenContent, 'itemButtonLinkUrl'),
          itemButtonText: get(tokenContent, 'itemButtonText'),
          itemImageExternalSrcUrl: get(tokenContent, 'itemImageExternalSrcUrl'),
          itemImageLinkUrl: get(tokenContent, 'itemImageLinkUrl'),
          itemTitle: get(tokenContent, 'itemTitle'),
        });
      }
      set(this, 'newTokenDefaultContent', tdc);
      try {
        await tdc.save();
      } finally {
        set(this, 'savingDefaultToken', false);
      }
    },
    async addMessageCampaignAudience(audience) {
      const newMessageCampaignAudience = this.store.createRecord('message-campaign-audience', {
        messageCampaign: this['message-campaign'],
        audience,
        isExcluded: false,
      });
      this.updateAudienceModel('addObject', newMessageCampaignAudience);

      await newMessageCampaignAudience.save();
      await this['update-invite-recipients-count'](get(this, 'message-campaign.id'));
    },
    async removeMessageCampaignAudience(audience) {
      //To keep from grabbing .content off of message-campaign-audience, we need to resolve its relationship with audience
      //We then get the message-campaign-audience that was mapped with its now-resolved audience
      const messageCampaignAudiencesResolved = await RSVP.all(
        this.messageCampaignAudiences.map(messageCampaignAudience => ({
          messageCampaignAudience,
          audience: get(messageCampaignAudience, 'audience'),
        }))
      );
      const messageCampaignAudienceToRemove = get(
        messageCampaignAudiencesResolved.findBy('audience.id', get(audience, 'id')),
        'messageCampaignAudience'
      );
      this.updateAudienceModel('removeObject', messageCampaignAudienceToRemove);

      await messageCampaignAudienceToRemove.destroyRecord();
      await this['update-invite-recipients-count'](get(this, 'message-campaign.id'));

      if (isEmpty(this.messageCampaignAudiences) && get(this, 'message-campaign.isConfirmed')) {
        this.unconfirmMessage();
      }
    },
    async changeBodySourceType(methodOption) {
      set(this, 'design.bodySourceType', methodOption.bodySourceType);
      if (methodOption.bodySourceType === 'Template') {
        await this.assignTemplate();
        this.updateBody();
      }

      if (this.isNewsletter) {
        await this.unschedule();
      }

      this.send('save');
    },
    async updateImage(isDips, value) {
      const { customTokenContent, activeTokenContent } = this;

      //set image for WYSIWYG token sections
      if (isPresent(activeTokenContent) && activeTokenContent.token.tokenContentType === 'HtmlText') {
        await set(activeTokenContent, 'libraryImage', value);
        set(activeTokenContent, 'libraryImage', null);
      }

      //set image for RSS token sections
      if (isPresent(customTokenContent) && customTokenContent.rssTokenName) {
        setProperties(customTokenContent, {
          sectionImageExternalSrcUrl: isDips ? '' : value,
          sectionMediaItemId: isDips ? value.id : '',
        });
      }

      //set image for all other token types
      if (isPresent(activeTokenContent)) {
        if (activeTokenContent.token.key === 'item') {
          if (Redactor.instances.length) {
            await set(activeTokenContent, 'libraryImage', value);
            set(activeTokenContent, 'libraryImage', null);
          } else {
            setProperties(activeTokenContent, {
              itemImageExternalSrcUrl: isDips ? '' : value,
              mediaItemId: isDips ? value.id : '',
            });
          }
        } else {
          setProperties(activeTokenContent, {
            value: isDips ? '' : value,
            mediaItemId: isDips ? value.id : '',
          });
        }
      }
      this.updateBody();
    },

    async reorderItems(itemModels, draggedModel) {
      itemModels.forEach((item, index) => set(item, 'displayOrder', index + 1));

      this.updateBody();
      this.replaceSubjectAndPreheader(draggedModel, itemModels.findBy('displayOrder', 1));
    },

    generatePlainTextBody() {
      set(this, 'design.plainTextBody', textFromHtml(this.design.body));
      set(this, 'plainTextSetMessage', 'Plain Text Generated!');
    },
    setMessageBodyTemplate(template) {
      set(this, 'design.messageBodyTemplate', template);

      this.createTokenContentsFor(template);

      if (template.maxItemsAllowed) {
        this.adjustItems(template.maxItemsAllowed);
        //our default for articleQuantity is 5; pass that through if it doesn't exceed the max articles allowed
        this.adjustMessageVersionFeeds(2, Math.min(template.maxItemsAllowed, 5), null);
      }

      this.updateBody();
    },
    async confirmRefresh() {
      const confirmed = await this.deliberateConfirmation.show({
        promptText:
          'Are you sure you want to refresh your articles? This will overwrite any manual edits you have made to your Items, Subject Line, and Preheader.',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Yes, Refresh Articles',
      });

      if (confirmed) {
        await this.refreshArticles();
        this.updateBody();
        await this.send('save');
      }

      this.deliberateConfirmation.resetConfirmedStatus();
    },
    async checkForDynamicTokenFallback({ key, name }) {
      if (!this.hasDefaultSettingPermissions) {
        return;
      }

      if (!dynamicTokenKeys.includes(name)) {
        return;
      }

      const { setting, targetEntityId } = this.getDynamicTokenSettingWithTargetEntityId(key);

      if (setting && isPresent(setting.value)) {
        return;
      }

      const tokenTitle = name.replace('User ', '');
      this.snackbar
        .show(
          `Set up a token fallback in case we don't know the recipient's ${tokenTitle}`,
          'set up',
          7500,
          null,
          true,
          ''
        )
        .then(async () => {
          // Show dialog for adding default
          set(this, 'settingTokenFallback', true);
          set(this, 'settingTokenFallbackTokenKey', key);
          set(this, 'settingTokenFallbackTokenDisplayName', tokenTitle);
          if (!setting) {
            const newSetting = this.store.createRecord('setting', {
              key: 'messaging_dynamic_token_fallback',
              value: '',
              targetEntityId,
              targetEntityTypeId: 14,
            });

            await newSetting.save();

            set(this, 'currentTokenFallbackSetting', newSetting);
          } else {
            set(this, 'currentTokenFallbackSetting', setting);
          }
        });
    },
    async saveTokenFallbackSetting() {
      set(this, 'currentTokenFallbackSetting.value', this.settingTokenFallbackValue);
      await this.currentTokenFallbackSetting.save();

      // Reset
      setProperties(this, {
        settingTokenFallback: false,
        settingTokenFallbackTokenDisplayName: '',
        settingTokenFallbackTokenKey: '',
        currentTokenFallbackSetting: {},
        settingTokenFallbackValue: '',
      });
    },
  },
  //endregion
});
