/* eslint-disable ember/no-jquery, ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Route from '@ember/routing/route';
import $ from 'jquery';

/**
 * Loading Route
 * No URL - Shown whenever data is loading.
 * @type {Ember.Route}
 */
export default Route.extend({
  //region Ember Hooks
  /**
   * Supplementing private method to animate the ball logo.
   * @see http://emberjs.com/api/classes/Ember.Route.html#method_enter
   */
  enter() {
    this._super();
    $('.ember-application').addClass('ssLoading');
  },
  /**
   * Supplementing private method to stop animating the ball logo.
   * @see http://emberjs.com/api/classes/Ember.Route.html#method_exit
   */
  exit() {
    this._super();
    $('.ember-application').removeClass('ssLoading');
  },
  //endregion
});
