import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class EntityLinkModel extends BaseModel {
  @attr('string') declare entityId: string;
  @attr('number', { defaultValue: 1 }) declare linkTypeId: number;
  @attr('number') declare ownerEntityId: number;
  @attr('number') declare matchupId: number;
  @attr('number') declare displayOrder: number;
  @attr('string') declare entityName: string;
  @attr('boolean', { defaultValue: false }) declare isAutoAdded: boolean;
  @attr('string') declare entityCategoryName: string;
  @attr('string') declare entitySourceUrl: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'entity-link': EntityLinkModel;
  }
}
