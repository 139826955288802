/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

/**
 * Organization Route
 * No URL - anything under /o/:organization_id
 * @type {Ember.Route}
 * @mixes AppContextRoute
 */
export default Route.extend(AppContextRoute, {
  //region Ember Hooks
  current: service(),
  enums: service(),
  features: service(),
  permissions: service(),
  router: service(),
  session: service(),
  snackbar: service(),
  store: service(),
  defaultFormat: service('default-format'),

  beforeModel() {
    this._super(...arguments);

    //we reset allLoaded flag for 'field' model and unload all records, so they are loaded again for current org.
    this.store.adapterFor('field').unloadAll();
  },

  /**
   * Grab this Organization and OrganizationSsProducts
   * @see http://emberjs.com/api/classes/Ember.Route.html#method_model
   */
  async model(params) {
    const organizationId = params.organization_id;
    const organizationParams = { organizationId };

    this.session.addCustomHeaders({ 'X-Organization-Id': organizationId });

    const organization = await this.store.findRecord('organization', organizationId, { reload: true });

    return RSVP.hash({
      organization,
      organizationSsProducts: this.store.query('organization-ss-product', organizationParams),
      organizationFeatureFlags: this.store.query('organization-attribute', organizationParams),
      organizationSalesPackages: this.store.query('organization-sales-package', organizationParams),
      permissions: this.store.query('permission', organizationParams),
      organizationAttributeTypes: this.store.query('organization-attribute-type', organizationParams),
      _location: this.store.query('location', { isPrimary: true, organizationId }),
    });
  },

  //region HAX
  // Remove or refactor this once Rogers is back
  async redirect(model) {
    if (model.organization.statusTypeId === 7) {
      await this.session.invalidate();
      return this.router.replaceWith('index');
    }
  },
  //endregion

  async afterModel(model) {
    if (!model) {
      //region HAX
      // model was skipped because of incomplete transition that was stopped due to invalid or missing session
      // TODO: figure out why this happens and remove this hack
      window.location.reload();
      //endregion
    }

    //Setup the permissions service with the user's permissions
    const permissionsService = this.permissions;
    set(permissionsService, 'permissions', model.permissions);

    // Create a feature flag for each organization attribute
    const featureFlagTypes = get(model, 'organizationAttributeTypes');

    model.organizationFeatureFlags.forEach(organizationAttribute => {
      if (featureFlagTypes.findBy('id', get(organizationAttribute, 'organizationAttributeTypeId').toString())) {
        this.store
          .find('organizationAttributeType', get(organizationAttribute, 'organizationAttributeTypeId'))
          .then(type => {
            const name = get(type, 'name');
            if (name) {
              if (get(organizationAttribute, 'value')) {
                this.features.enable(name);
              } else {
                this.features.disable(name);
              }
            } else {
              throw new Error('OrganizationAttribute name missing');
            }
          });
      }
    });

    // Set properties on Organization that indicate which products the org has. (Example: organization.hasData)
    model.organizationSsProducts.forEach(osp => {
      const ssProductPromotionTypes = get(osp, 'ssProduct.ssProductPromotionTypes');

      if (ssProductPromotionTypes) {
        ssProductPromotionTypes.forEach(promotionType => {
          const promotionTypeName = this.enums.findWhere(
            'PROMOTION_TYPE',
            { id: get(promotionType, 'promotionTypeId') },
            'name'
          );

          //TODO: can this be moved to the features service?
          set(model.organization, `has${promotionTypeName}`, true);
        });
      }
    });

    this.current.setOrganization(model.organization);
    this.defaultFormat.resetDateFormat();
  },

  deactivate() {
    this.current.setOrganization(null);
  },
  //endregion

  //region Actions
  actions: {
    showRetiredVotersChoiceMessage() {
      this.snackbar
        .show(
          "Voter's Choice has been retired. If you need data from this promotion, our support team can assist you.",
          'Contact Support',
          7500
        )
        .then(() => {
          document.querySelector('body').dispatchEvent(new Event('helpIconClicked'));
        });
    },
  },
  //endregion
});
