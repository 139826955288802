/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, hasMany } = DS;

export default BaseModel.extend({
  //region Properties
  oldSpfText: attr('string'),
  newSpfText: attr('string'),
  newSpfDomainName: attr('string'),
  //endregion

  //region Relationships
  dnsUpdateRecords: hasMany('dns-update-record', { async: false }),
  //endregion
});
