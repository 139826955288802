/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import { gte } from '@ember/object/computed';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr } = DS;

export default BaseModel.extend({
  ipAddress: attr('string'),
  userCount: attr('number'),

  //region Computed Properties
  isMajorIssue: gte('userCount', 50),
  isMinorIssue: computed('userCount', 'isMajorIssue', function () {
    return !this.isMajorIssue && this.userCount >= 5;
  }),
  //endregion
});
