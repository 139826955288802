/* eslint-disable ember/no-computed-properties-in-native-classes, @typescript-eslint/restrict-template-expressions */
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type SponsoredPostModel from 'secondstreet-common/models/sponsored-post';
import { AD_TYPE_BANNER, AD_TYPE_PROGRAMMATIC } from 'partner/utils/adgagement';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type SnackbarService from 'secondstreet-common/services/snackbar';
import { type Model } from 'partner/-base/promotion-ads-route';
import type DeliberateConfirmationService from 'partner/services/deliberate-confirmation';
import { task } from 'ember-concurrency';
import type RouterService from '@ember/routing/router-service';
import type CurrentService from 'partner/services/current';
import { alphabeticalByProperty, simplyByProperty } from 'secondstreet-common/utils/sorting';

export type SortParamsType = {
  column: string;
  direction: string;
};

const getSortingByColumnType = (column: string) => {
  return ['impressions', 'reach', 'clicks', 'ctr'].includes(column)
    ? simplyByProperty(column)
    : alphabeticalByProperty(column);
};

export default class PromotionAdsController extends Controller {
  @service declare store: Store;
  @service declare snackbar: SnackbarService;
  @service declare deliberateConfirmation: DeliberateConfirmationService;
  @service declare checklist: any;
  @service declare router: RouterService;
  @service declare current: CurrentService;

  declare model: Model;

  categories = [
    { id: 0, name: 'All' },
    { id: 1, name: AD_TYPE_BANNER },
    { id: 2, name: AD_TYPE_PROGRAMMATIC },
  ];

  queryParams = ['new', 'edit', 'duplicate'];

  baseColumns = [
    {
      label: 'Preview',
      width: 'minmax(min-content, 1fr)',
      tooltip: 'Hover over the preview to see all ad images for each placement. Ads with a carousel will rotate.',
    },
    { label: 'Advertiser(s)', sortable: 'name', width: 'minmax(min-content, 1fr)' },
    { label: 'Type', sortable: 'adType', width: 'minmax(min-content, 1fr)' },
  ];

  @tracked selectedCategory = this.categories[0];
  @tracked searchTerm = '';
  @tracked currentlyEditing: SponsoredPostModel | undefined;
  @tracked sideNavSelection = '';
  @tracked sortParams: SortParamsType = {
    column: '',
    direction: '',
  };

  @tracked new = false;
  @tracked edit: string | number = 0;
  @tracked duplicate: string | number = 0;
  @tracked showImportDialog = false;

  @computed(
    'model.ads.@each.{isDeleted,isNew}',
    'searchTerm',
    'selectedCategory.id',
    'sideNavSelection',
    'sortParams.{column,direction}'
  )
  get filteredAds() {
    const searchTerm = this.searchTerm.trim().toLowerCase();
    const result = this.model.ads
      .rejectBy('isNew')
      .rejectBy('isDeleted')
      .filter((ad: SponsoredPostModel) => {
        return (
          (!this.sideNavSelection ||
            ad.ballotArea == this.sideNavSelection ||
            ad.ownerEntityId == +this.sideNavSelection) &&
          (!this.selectedCategory?.id || ad.adTypeId == this.selectedCategory.id) &&
          (!searchTerm ||
            ad.name?.toLowerCase()?.includes(searchTerm) ||
            ad.targetEntity?.name?.toLowerCase()?.includes(searchTerm))
        );
      })
      .sort(
        this.sortParams.direction === 'desc'
          ? (a, b) => getSortingByColumnType(this.sortParams.column)(b, a)
          : (a, b) => getSortingByColumnType(this.sortParams.column)(a, b)
      );
    return result;
  }

  removeTask = task(async (ad: SponsoredPostModel) => {
    const promptText = `You will not be able to access this ad or its information. Are you sure you want to delete this ad?`;
    if (
      !(await this.deliberateConfirmation.show({
        promptText,
        confirmButtonText: 'Yes, Delete',
        cancelButtonText: 'Cancel',
      }))
    ) {
      return;
    }

    try {
      await ad.destroyRecord();
      if (this.model.ads.rejectBy('isDeleted').length < 1) {
        this.checklist.uncheckChecklistStep(this.router.currentRouteName);
      }
      this.snackbar.show(`Ad deleted successfully`);
    } catch (e) {
      this.snackbar.exception(e);
    }
  });

  @action
  onClickDuplicate(ad: SponsoredPostModel) {
    this.currentlyEditing = this.store.createRecord('sponsored-post', {
      ...ad.toJSON(),
    });
    void this.router.transitionTo(this.router.currentRouteName, {
      queryParams: {
        edit: 0,
        duplicate: ad.id,
        new: false,
      },
    });
  }

  @action
  onClickEdit(ad: SponsoredPostModel) {
    this.currentlyEditing = ad;
    void this.router.transitionTo(this.router.currentRouteName, {
      queryParams: {
        edit: ad.id,
        duplicate: 0,
        new: false,
      },
    });
  }

  @action
  closeEditModal(currentlyEditing: SponsoredPostModel) {
    this.new = false;
    this.edit = 0;
    this.duplicate = 0;
    if (!currentlyEditing) return;

    if (currentlyEditing?.isDirty) {
      currentlyEditing.id ? currentlyEditing.rollbackAttributes() : currentlyEditing?.destroyRecord();
    } else if (!this.model.ads.includes(currentlyEditing)) {
      this.model.ads.addObject(currentlyEditing);
    }
    this.currentlyEditing = undefined;
    if (this.model.ads.rejectBy('isDeleted').length >= 1) {
      this.checklist.checkChecklistStep(this.router.currentRouteName);
    }
  }
}
