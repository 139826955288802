/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
/* eslint-disable ember/no-side-effects */ // FIXME
import { isArray } from '@ember/array';
import { computed, get, set } from '@ember/object';
import { filter, setDiff } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import flattenDeep from 'lodash/flattenDeep';
import { firstByProperty } from 'partner/utils/computed';

const SOCIAL_CHART_FIELD_TYPES = ['Instagram', 'FacebookLink', 'XLink', 'TikTokLink', 'YouTubeLink'];

function loadedAndEmpty(key) {
  return computed(key, `${key}.length`, function () {
    const array = get(this, key);
    return isArray(array) && !array.any(x => get(x, 'isAnswered'));
  });
}

export const transformFieldCharts = ({ optinFields, fieldCharts }) => {
  const optinFieldTypeIds = optinFields.map(x => x.id);
  const filteredFieldCharts = fieldCharts.filter(chart => !SOCIAL_CHART_FIELD_TYPES.includes(chart.fieldType));

  if (filteredFieldCharts) {
    filteredFieldCharts.forEach(fieldChart => {
      const isOptin = optinFieldTypeIds.indexOf(`${fieldChart.sourceTypeChartId}`) > -1;
      set(fieldChart, 'isOptin', isOptin);

      if (isOptin) {
        const categories = fieldChart.categories.filter(x => x.label === 'New');
        const count = categories.firstObject ? categories.firstObject.value : 0;
        set(fieldChart, 'optinAnsweredCount', count);
        set(fieldChart, 'isDownloading', false);
      }

      set(
        fieldChart,
        'transformedCategories',
        fieldChart.categories.filter(x => x.label !== 'Unknown')
      );
    });
  }

  return filteredFieldCharts.sort((a, b) => {
    if (a.isOptin && b.isOptin) {
      return b.optinAnsweredCount - a.optinAnsweredCount;
    }

    return a.isOptin ? -1 : 1;
  });
};

/**
 * Common logic for Controllers that have CategoricalCharts
 * @mixin
 */
export default Mixin.create({
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   * @deprecated - The API needs to send this as "Location" instead.
   */
  postalCodeChart: firstByProperty('sourceTypeChartId', 43, 'model.categoricalCharts'),
  postalCodeChartCategoriesLoadedAndEmpty: loadedAndEmpty('postalCodeChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   * @deprecated - The API needs to send this as "Age" instead.
   */
  birthdateChart: firstByProperty('sourceTypeChartId', 44, 'model.categoricalCharts'),
  birthdateChartCategoriesLoadedAndEmpty: loadedAndEmpty('birthdateChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  locationChart: firstByProperty('name', 'Location', 'model.categoricalCharts'),
  locationChartCategoriesLoadedAndEmpty: loadedAndEmpty('locationChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  ageChart: firstByProperty('name', 'Age', 'model.categoricalCharts'),
  ageChartCategoriesLoadedAndEmpty: loadedAndEmpty('ageChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  genderChart: firstByProperty('sourceTypeChartId', 37, 'model.categoricalCharts'),
  genderChartCategoriesLoadedAndEmpty: loadedAndEmpty('genderChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  topTagsChart: firstByProperty('name', 'Top Tags', 'model.categoricalCharts'),
  topTagsChartCategoriesLoadedAndEmpty: loadedAndEmpty('topTagsChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  topAudiencesChart: firstByProperty('name', 'Top Audiences', 'model.categoricalCharts'),
  topAudiencesChartCategoriesLoadedAndEmpty: loadedAndEmpty('topAudiencesChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  unsubscribedChart: firstByProperty('name', 'Unsubscribed', 'model.categoricalCharts'),
  unsubscribedChartCategoriesLoadedAndEmpty: loadedAndEmpty('unsubscribedChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  messageVersionLinksChart: firstByProperty('name', 'Top Links Clicked', 'model.categoricalCharts'),
  messageVersionLinksChartCategoriesLoadedAndEmpty: loadedAndEmpty('messageVersionLinksChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  peopleWhoOptedOutChart: firstByProperty('name', 'Unsubscribed', 'model.categoricalCharts'),
  peopleWhoOptedOutChartCategoriesLoadedAndEmpty: loadedAndEmpty('peopleWhoOptedOutChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  openedTimesChart: firstByProperty('name', 'Opened Times', 'model.categoricalCharts'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {Boolean}
   */
  openedTimesChartCategoriesLoadedAndEmpty: loadedAndEmpty('openedTimesChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  topDomainsChart: firstByProperty('name', 'Top Domains', 'model.categoricalCharts'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {Boolean}
   */
  topDomainsChartCategoriesLoadedAndEmpty: loadedAndEmpty('topDomainsChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  browserDeviceCategoriesChart: firstByProperty('name', 'Platform Breakdown', 'model.categoricalCharts'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {Boolean}
   */
  browserDeviceCategoriesChartCategoriesLoadedAndEmpty: loadedAndEmpty('browserDeviceCategoriesChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  abTestVersionsSentChart: firstByProperty('name', 'Test Versions Sent', 'model.categoricalCharts'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {Boolean}
   */
  abTestVersionsSentLoadedAndEmpty: loadedAndEmpty('abTestVersionsSentChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  abOpenRateChart: firstByProperty('name', 'Open Rate', 'model.categoricalCharts'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {Boolean}
   */
  abOpenRateLoadedAndEmpty: loadedAndEmpty('abOpenRateChart.rawCategories'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  abClickRateChart: firstByProperty('name', 'Click Rate', 'model.categoricalCharts'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns {Boolean}
   */
  abClickRateLoadedAndEmpty: loadedAndEmpty('abClickRateChart.rawCategories'),
  /**
   * This is only used to compute what _are_ fieldCharts.
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  nonFieldCharts: computed(
    'locationChart',
    'ageChart',
    'postalCodeChart',
    'birthdateChart',
    'genderChart',
    'topTagsChart',
    'topAudiencesChart',
    'outcomeChart',
    'questionCharts',
    function () {
      return flattenDeep(
        [
          'locationChart',
          'ageChart',
          'postalCodeChart',
          'birthdateChart',
          'genderChart',
          'topTagsChart',
          'topAudiencesChart',
          'outcomeChart',
          'questionCharts',
        ].map(x => get(this, x))
      );
    }
  ),
  /**
   * All the data for all the charts representing custom fields
   * @type {Ember.ComputedProperty}
   * @returns {@link partner/models/categorical-chart}
   */
  fieldCharts: setDiff('model.categoricalCharts', 'nonFieldCharts'),
  /**
   * @type {Ember.ComputedProperty}
   * @returns EventChart
   */
  usersChart: firstByProperty('name', 'Organization User', 'model.eventCharts'),
  socialCharts: filter('fieldCharts', chart => SOCIAL_CHART_FIELD_TYPES.includes(chart.fieldType)),

  noneSocialFieldCharts: computed('model.optinFields.@each.id', 'fieldCharts.@each.fieldType', function () {
    const optinFieldIds = this.model.optinFields.mapBy('id');

    return this.fieldCharts
      .filter(chart => !SOCIAL_CHART_FIELD_TYPES.includes(chart.fieldType))
      .map(chart => {
        chart.isOptin = optinFieldIds.includes(`${chart.sourceTypeChartId}`);

        return chart;
      });
  }),

  transformedFieldCharts: computed(
    'fieldCharts.@each.{optinAnsweredCount,categories,sourceTypeChartId}',
    'model.optinFields.@each.id',
    function () {
      return transformFieldCharts({
        optinFields: this.model.optinFields,
        fieldCharts: this.fieldCharts,
      });
    }
  ),
});
