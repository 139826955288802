import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import difference from 'lodash/difference';

export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionDashboardShareController extends Controller {
  @service deliberateConfirmation;
  @service store;
  @service('email-notification') emailNotificationService;

  queryParams = ['designId'];
  @tracked designId = null;

  get filteredDesign() {
    const { designs } = this.model;
    return this.designId ? designs.findBy('id', this.designId) : designs[0];
  }

  get filteredEmailNotification() {
    return this.filteredDesign.emailNotification;
  }

  get emailAddresses() {
    return this.filteredEmailNotification?.emailNotificationRecipients?.mapBy('emailAddress');
  }

  async saveDesign(design) {
    await Promise.all(
      design.designTokenContents.filterBy('hasDirtyAttributes').map(dirtyTokenContent => dirtyTokenContent.save())
    );

    await (design.hasDirtyAttributes ? design.save() : Promise.resolve(null));
  }

  saveTask = task({ restartable: true }, async () => {
    await timeout(250); // otherwise high typing speeds will lag

    try {
      await Promise.all(
        this.model.designs
          .filter(design => design.hasDirtyAttributes || design.designTokenContents.isAny('hasDirtyAttributes'))
          .map(design => this.saveDesign(design))
      );
    } catch (e) {
      console.error(e);
    }
  });

  saveEmailNotificationTask = task({ restartable: true }, async () => {
    try {
      await this.filteredEmailNotification.saveRecord();
    } catch (e) {
      console.error(e);
    }
  });

  @action
  async newDesign() {
    const newDesign = this.store.createRecord('design');
    const [originalDesign] = this.model.designs;
    newDesign.designTemplate = originalDesign.designTemplate;
    try {
      await newDesign.save();
      await this.store.query('design-token-content', { designId: newDesign.id });
      const emailNotification = this.emailNotificationService.createDraftEmailNotification(
        newDesign,
        this.model.organizationPromotion,
        originalDesign
      );
      await emailNotification.saveRecord();

      this.model.designs.addObject(newDesign);
      this.model.emailNotifications.addObject(emailNotification);
      this.designId = newDesign.id;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  setActiveDesign(id) {
    this.designId = id;
  }

  @action
  async removeDesign(design) {
    const { designs } = this.model;
    const isConfirmed = await this.deliberateConfirmation.show({
      promptText: 'Are you sure you want to delete this dashboard?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
    });

    if (!isConfirmed) return;

    await design.destroyRecord();

    designs.removeObject(design);
    this.designId = designs[0].id;
  }

  @action
  parseEmailNotificationEmailAddresses(emailAddressesString) {
    const emailAddresses = emailAddressesString ? emailAddressesString.split(';') : [];
    const recipients = this.filteredEmailNotification.emailNotificationRecipients;
    difference(emailAddresses, this.emailAddresses).forEach(email => {
      recipients.addObject(
        this.store.createRecord('email-notification-recipient', {
          emailAddress: email,
        })
      );
    });
    difference(this.emailAddresses, emailAddresses).forEach(email => {
      recipients.removeObject(recipients.findBy('emailAddress', email));
    });
  }
}
