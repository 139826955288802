import Transform from '@ember-data/serializer/transform';
import moment from 'moment';

export default class LocalDateTransform extends Transform {
  deserialize(value) {
    if (value && value.indexOf('Z') > -1) {
      return new Date(value.replace('Z', ''));
    }

    return value;
  }

  serialize(value) {
    return value ? `${moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS')}Z` : null;
  }
}
