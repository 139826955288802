/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { ParamsType } from 'partner/services/preview';

export const header = (params?: {
  email?: string;
  title?: string;
  promotionColor?: string;
  promotionTextColor?: string;
}) => {
  return {
    type: 'promotion-box',
    uniqueTypeNumber: '141',
    blocks: [
      {
        id: 'heading-41',
        uniqueTypeNumber: '41',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: params?.email || params?.title || 'Already Entered?',
          additionalCss:
            '.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-41 {\n  width: fit-content;   \n  }\n  \n  ',
        },
        styles: {
          font: {
            color: params?.promotionTextColor,
            weight: 400,
            family: 'Open Sans',
            size: '13',
            height: '17',
            align: 'start',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      ...(params?.email
        ? [
            {
              id: 'image-31',
              uniqueTypeNumber: '31',
              deletable: true,
              type: 'promotion-image',
              blocks: [],
              data: {
                src: 'https://media.secondstreetapp.com/6548579?width=107&height=80&cropmode=fit&anchor=center&quality=60&format=auto',
                alt: '',
                url: '',
                target: '_blank',
                additionalCss:
                  '.arc-promotion-promotion-0 .arc-promotion-image-promotion-image-31 {\n     margin-top: 4px;\n  }\n  \n  ',
              },
              styles: {
                size: {
                  custom: true,
                  width: '18',
                  height: '',
                },
                spacing: {
                  marginBlock: '',
                },
              },
              dataMobile: {},
              stylesMobile: {},
              dataTablet: {},
              stylesTablet: {},
            },
          ]
        : []),
    ],

    styles: {
      size: {
        custom: true,
        height: '40',
      },
      spacing: {
        paddingBlock: '10',
        paddingInline: '8',
      },
      background: {
        type: 'color',
        color: params?.promotionColor,
        opacity: 100,
      },
      layout: {
        display: 'flex',
        direction: 'row',
        align: 'center',
      },
    },
  };
};

export const subHeader = (id: string, title: string, promotionColor?: string, promotionTextColor?: string) => {
  return {
    type: 'promotion-box',
    uniqueTypeNumber: id,
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-${id} {\n  position: relative;   \n  }\n  \n  `,
    },
    blocks: [
      {
        type: 'promotion-box',
        uniqueTypeNumber: `${id}1`,
        data: {
          additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-${id}1 {\n  position: absolute;   \n  }\n  \n  `,
        },
        styles: {
          background: {
            type: 'color',
            color: '#000000',
            opacity: 20,
          },
        },
        blocks: [],
      },
      {
        id: 'heading-1',
        uniqueTypeNumber: id,
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: `<span style="font-size:18px;" class="material-icons">arrow_back</span> ${title}`,
        },
        styles: {
          layout: {
            display: 'flex',
            direction: 'row',
            align: 'center',
            gapX: '4',
            gapY: '4',
          },
          spacing: {
            paddingBlock: '10',
            paddingInline: '8',
          },
          background: {
            type: 'color',
            color: promotionColor,
            opacity: 100,
          },
          font: {
            color: promotionTextColor,
            weight: 600,
            family: 'Open Sans',
            size: '16',
            align: 'start',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
  };
};

export const photo = ({
  id,
  width,
  height,
  isVideo,
}: {
  id: string;
  width?: number;
  height?: number;
  isVideo?: boolean;
}) => {
  return {
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'image-2',
        uniqueTypeNumber: '2',
        deletable: true,
        type: 'promotion-image',
        blocks: [],
        data: {
          src: `https://media.secondstreetapp.com/${
            isVideo ? 6548740 : 6542562
          }?width=107&height=80&cropmode=fit&anchor=center&quality=60&format=auto`,
          alt: '',
          url: '',
          target: '_blank',
          additionalCss: '.arc-promotion-promotion-0 .arc-image-image-2 {\n     \n  }\n  \n  ',
        },
        styles: {
          size: {
            custom: true,
            width: '40',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-${id} {\n  max-height: 400px;   \n  }\n  \n  `,
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
      },
      size: {
        custom: width || height,
        width,
        height,
      },
      border: {
        radius: '4',
      },
      background: {
        type: 'color',
        color: '#f1f3f3',
        opacity: 100,
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const getButton = (id: string) => {
  return {
    id: 'box-1212',
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'heading-48',
        uniqueTypeNumber: `${id}11`,
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: 'VOTE',
          additionalCss: '.arc-heading-heading-48 {\n     \n  }\n  \n  ',
        },
        styles: {
          font: {
            color: '#ffffff',
            weight: 600,
            family: 'Open Sans',
            align: 'end',
            size: '16',
            spacing: '1',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
    ],
    data: {
      additionalCss: '.arc-box-box-1212 {\n     \n  }\n  \n  ',
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        align: 'center',
        justify: 'center',
      },
      border: {
        style: 'none',
        radius: '4',
      },
      background: {
        type: 'color',
        color: '#4caf50',
        opacity: 100,
      },
      size: {
        custom: true,
        height: 36,
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

export const card = ({
  id,
  width,
  height,
  isVideo,
  hasButton,
  title,
  description,
  titleColor,
  descriptionColor,
  titleSize,
  descriptionSize,
  titleWeight,
  descriptionWeight,
  hasBoxShadow,
}: {
  id: string;
  fitContent?: boolean;
  width?: number;
  height?: number;
  isVideo?: boolean;
  hasButton?: boolean;
  title?: string;
  description?: string;
  titleColor?: string;
  descriptionColor?: string;
  titleSize?: string;
  descriptionSize?: string;
  titleWeight?: string;
  descriptionWeight?: string;
  hasBoxShadow?: boolean;
}) => {
  return {
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-box',
    blocks: [
      photo({ id: `${id}1`, width, height, isVideo }),
      {
        id: 'heading-4',
        uniqueTypeNumber: '4',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: title || 'Title',
          additionalCss: '.arc-promotion-promotion-0 .arc-heading-heading-4 {\n     \n  }\n  \n  ',
        },
        styles: {
          font: {
            color: titleColor || '#000000',
            weight: titleWeight || 600,
            family: 'Open Sans',
            size: titleSize || '14',
            height: titleSize ? '' : '24',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      {
        id: 'heading-5',
        uniqueTypeNumber: `${id}1`,
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: description || 'Caption',
          additionalCss: `.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-${id}1 {\n  max-width: ${width}px;   \n  }\n  \n  `,
        },
        styles: {
          font: {
            color: descriptionColor || '#000000',
            weight: descriptionWeight || 400,
            family: 'Open Sans',
            size: descriptionSize || '14',
            height: descriptionSize ? '' : '20',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      ...(hasButton ? [getButton(`${id}9`)] : []),
    ],
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-${id} {\n position: relative;
      ${
        hasBoxShadow
          ? 'box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);'
          : ''
      }
      \n  }\n  \n  `,
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        gapX: '8',
        gapY: '8',
      },
      size: {
        custom: true,
        width: (width || 200) + 32,
      },
      spacing: {
        paddingBlock: '16',
        paddingInline: '16',
      },
      border: {
        radius: '6',
        style: 'solid',
        width: 1,
        color: '#e0e3e6',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

export const registrationForm = (params?: ParamsType) => {
  return {
    id: 'box-2',
    uniqueTypeNumber: '2',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      {
        id: 'heading-2',
        uniqueTypeNumber: '2',
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 5,
          text: 'In order to see your result, please fill out this form.',
          additionalCss: '.arc-promotion-promotion-0 .arc-heading-heading-2 {\n  }\n  \n  ',
        },
        styles: {
          font: {
            color: '#000000',
            weight: '600',
            family: 'Open Sans',
            size: '14',
            height: '24',
            align: 'start',
          },
          size: {
            custom: true,
            height: '16',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      {
        id: 'box-3',
        uniqueTypeNumber: '3',
        deletable: true,
        type: 'promotion-box',
        blocks: [
          {
            id: 'box-7',
            uniqueTypeNumber: '7',
            deletable: true,
            type: 'promotion-box',
            blocks: [
              {
                id: 'box-8',
                uniqueTypeNumber: '8',
                deletable: true,
                type: 'promotion-box',
                blocks: [
                  {
                    id: 'heading-3',
                    uniqueTypeNumber: '3',
                    deletable: true,
                    type: 'promotion-heading',
                    blocks: [],
                    data: {
                      level: 5,
                      text: 'Email',
                      additionalCss:
                        '.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-3 {\n  color: var(--mdc-color-system-text, black);   \n  }\n  \n  ',
                    },
                    styles: {
                      font: {
                        color: '#000000',
                        weight: 600,
                        family: 'Open Sans',
                        size: '14',
                        height: '24',
                      },
                      size: {
                        custom: true,
                        height: '24',
                      },
                    },
                    dataMobile: {},
                    stylesMobile: {},
                    dataTablet: {},
                    stylesTablet: {},
                  },
                  {
                    id: 'heading-4',
                    uniqueTypeNumber: '4',
                    deletable: true,
                    type: 'promotion-heading',
                    blocks: [],
                    data: {
                      level: 5,
                      text: 'Required',
                      additionalCss:
                        '.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-4 {\n    margin-left: auto; color: var(--mdc-color-system-text, black); \n  }\n  \n  \n  ',
                    },
                    styles: {
                      font: {
                        color: '#252b31',
                        weight: '',
                        family: 'Open Sans',
                        size: '12',
                        height: '16',
                        align: 'start',
                      },
                      size: {
                        custom: true,
                        height: '16',
                      },
                    },
                    dataMobile: {},
                    stylesMobile: {},
                    dataTablet: {},
                    stylesTablet: {},
                  },
                ],
                data: {
                  additionalCss:
                    '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-8 {\n    min-height: unset;\n  }\n  \n  ',
                },
                styles: {
                  layout: {
                    display: 'flex',
                    direction: 'row',
                    align: 'center',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
              {
                id: 'box-9',
                uniqueTypeNumber: '9',
                deletable: true,
                type: 'promotion-box',
                blocks: [],
                data: {
                  additionalCss:
                    '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-9 {\n  flex-shrink: 0;   \n  }\n  \n  ',
                },
                styles: {
                  layout: {
                    display: 'flex',
                    direction: 'column',
                  },
                  background: {
                    type: 'color',
                    color: '#ffffff',
                    opacity: 100,
                  },
                  border: {
                    style: 'solid',
                    width: 1,
                    color: '#6b7786',
                    radius: '4',
                  },
                  size: {
                    custom: true,
                    height: '40',
                  },
                },
                dataMobile: {},
                stylesMobile: {},
                dataTablet: {},
                stylesTablet: {},
              },
            ],
            data: {
              additionalCss: '.arc-promotion-promotion-0 .arc-box-box-4 {\n     \n  }\n  \n  ',
            },
            styles: {
              layout: {
                display: 'flex',
                direction: 'column',
                gapX: '4',
                gapY: '4',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
        ],
        data: {
          additionalCss: '.arc-promotion-promotion-0 .arc-box-box-3 {\n     \n  }\n  \n  ',
        },
        styles: {
          layout: {
            display: 'flex',
            direction: 'column',
            gapX: '16',
            gapY: '16',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      {
        type: 'promotion-box',
        uniqueTypeNumber: '21',
        blocks: [
          {
            id: 'button-1',
            uniqueTypeNumber: '1',
            deletable: true,
            type: 'promotion-button',
            blocks: [],
            classNames: 'template-color-primary-background-color',
            data: {
              href: '',
              text: 'SUBMIT',
              target: '_blank',
              url: '',
              additionalCss:
                '.arc-promotion-promotion-0 .arc-promotion-button-promotion-button-1 span {\n  border: 1px solid rgba(255, 255, 255, 0.05);   display: flex;\n     align-items: center;\n     gap: 4px;\n  }\n  \n  ',
            },
            styles: {
              border: {
                radius: 5,
              },
              spacing: {
                paddingBlock: 10,
                paddingInline: 10,
              },
              background: {
                type: 'color',
                color: params?.promotionColor,
                opacity: 100,
              },
              font: {
                color: params?.promotionTextColor,
                align: 'center',
                family: 'Open Sans',
                weight: 600,
                size: '14',
                height: '20',
                spacing: '1.25',
              },
              size: {
                custom: true,
                height: '40',
                width: '',
              },
            },
            dataMobile: {},
            stylesMobile: {},
            dataTablet: {},
            stylesTablet: {},
          },
          ...(params?.isRegistrationOptional
            ? [
                {
                  id: 'button-1',
                  uniqueTypeNumber: '5',
                  deletable: true,
                  type: 'promotion-button',
                  blocks: [],
                  data: {
                    href: '',
                    text: 'SKIP',
                    target: '_blank',
                    url: '',
                    additionalCss:
                      '.arc-promotion-promotion-0 .arc-promotion-button-promotion-button-5 {\n  border: 1px solid #ffffff0d; box-shadow: 0px 1px 2px 1px rgba(33,33,33,0.35);  }\n  \n  ',
                  },
                  styles: {
                    border: {
                      radius: 5,
                    },
                    spacing: {
                      paddingBlock: 10,
                      paddingInline: 10,
                    },
                    background: {},
                    font: {
                      color: '#252b31',
                      align: 'center',
                      family: 'Open Sans',
                      weight: 600,
                      size: '14',
                      height: '20',
                      spacing: '1.25',
                    },
                    size: {
                      custom: true,
                      height: '40',
                      width: '',
                    },
                  },
                  dataMobile: {},
                  stylesMobile: {},
                  dataTablet: {},
                  stylesTablet: {},
                },
              ]
            : []),
        ],
        styles: {
          layout: {
            display: 'flex',
            direction: 'row',
            gapX: '16',
            gapY: '16',
          },
        },
      },
    ],
    data: {
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-2 {\n max-width:632px; margin-left: auto; margin-right: auto;

      ${
        params?.isPoll
          ? ''
          : 'box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);'
      }
      
      \n  }\n  \n  `,
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'column',
        gapX: '16',
        gapY: '16',
      },
      border: {
        style: 'solid',
        width: params?.isPoll ? '1' : '0',
        color: '#e0e3e6',
        radius: '4',
      },

      spacing: {
        marginBlock: '16',
        marginInline: '16',
        paddingBlock: '20',
        paddingInline: '20',
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};
