import { attr } from '@ember-data/model';
import enums from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';

export const FRAUD_FLAG_LABELS = {
  email_fraud_score: 'Email Fraud Score',
  email_spam_trap_score: 'Email Spam Trap Score',
  ip_abuse_velocity: 'IP Abuse Velocity',
  ip_connection_type: 'IP Connection Type',
  country_code: 'IP Country',
  ip_fraud_score: 'IP Fraud Score',
  ip_is_suspected_bot: 'IP SuspectedBot',
  ip_is_suspected_proxy: 'IP SuspectedProxy',
};

@makeBooleanProperties('STATUS_TYPE')
export default class OrganizationPromotionUser extends BaseModel {
  @attr('string') emailAddress;
  @attr('string') name;
  @attr('date') dateCreated;
  @attr('string') ipAddress;
  @attr('number') ipUsage;
  @attr('string') ipCity;
  @attr('string') ipCountryName;
  @attr('string') lastVotedFor;
  @attr('number') numVotes;
  @attr('number') numEligibleEntries;
  @attr('number') statusTypeId;
  @attr('boolean') canUserLogin;
  @attr('string') fraudFlagsText;
  @attr('number') numEligibleFormSubmissions;
  @attr('string') postalCode;

  @enums.computed('name', 'statusTypeId')
  statusType;

  /**
   * Returns the fraud flags as an array of strings.
   * @type {string[]}
   */
  get fraudFlags() {
    return (this.fraudFlagsText || '')
      .split(',')
      .map(flag => flag.trim())
      .filter(Boolean);
  }

  /**
   * Returns the fraud flags as an array of human friendly strings.
   * @type {string[]}
   */
  get fraudFlagLabels() {
    return this.fraudFlags
      .map(flag => FRAUD_FLAG_LABELS[flag])
      .filter(Boolean)
      .sort();
  }
}
