/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  store: service(),

  //region Ember Hooks
  model() {
    const organizationId = this.modelFor('organizations.organization').organization.id;
    return this.store
      .query('message-body-template', { organizationId })
      .then(templates => templates.filterBy('isInherited', false));
  },
  //endregion
});
