import { set } from '@ember/object';

export const setMessageIsConfirmed = async ({
  usesSMC,
  singleMessageCampaign,
  messageCampaign,
  value,
  saveSMC = false,
}) => {
  // Message campaigns that have sendOnConfirm ask for confirmation via `deliberate-confirmation`
  // @see app/routes/organizations/organization/message-campaigns/message-campaign/setup/confirmation.js:19
  const shouldSave = !messageCampaign.sendOnConfirm;

  if (usesSMC) {
    set(singleMessageCampaign, 'isConfirmed', value);
    if (saveSMC && shouldSave) singleMessageCampaign.save();
  }

  set(messageCampaign, 'isConfirmed', value);
  if (shouldSave) messageCampaign.save();
};
