/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { copyMessageVersion } from 'partner/utils/message-version';
import RSVP from 'rsvp';

const MESSAGE_CAMPAIGN_ROUTE = 'organizations.organization.message-campaigns.message-campaign';

/**
 * Route for: /organizationPromotion.messageCampaigns.messageCampaign.setup
 */
export default Route.extend({
  //region Ember Hooks
  enums: service(),
  router: service(),
  snackbar: service(),
  store: service(),
  checklist: service(),

  beforeModel() {
    // clear out the checklist, side loaded checklistStep models, and side loaded checklistStepStatuses models. otherwise they would remain in store and
    // could show up for a different message campaign
    this.store.unloadAll('checklist');
    this.store.unloadAll('checklistStep');
    this.store.unloadAll('checklistStepStatus');
  },

  model() {
    const { organization } = this.modelFor('organizations.organization');
    const { messageCampaign, singleMessageCampaign } = this.modelFor(MESSAGE_CAMPAIGN_ROUTE);
    const organizationId = organization.id;
    const messageCampaignId = messageCampaign.id;
    const fieldTypeId = this.enums.findWhere('FIELD_TYPE', { name: 'CustomDateInput' });

    // seed checklist service with messageCampaignId
    set(this.checklist, 'messageCampaignId', messageCampaignId);
    set(this.checklist, 'organizationPromotionId', null);
    set(this.checklist, 'messageCampaignTypeId', messageCampaign.messageCampaignTypeId);
    set(this.checklist, 'promotionTypeId', null);
    set(this.checklist, 'audienceId', null);

    return RSVP.hash({
      messageCampaign,
      singleMessageCampaign,
      checklist: this.store.query('checklist', { messageCampaignId }).then(x => x.firstObject),
      audiences: this.store.query('audience', { organizationId }).then(x => x.toArray()),
      messageCampaignAudiences: this.store
        .query('message-campaign-audience', { messageCampaignId })
        .then(x => x.toArray()),
      messageVersionFeeds: this.store.query('message-version-feed', { messageCampaignId }),
      dateFields: this.store
        .query('field', { organizationId, fieldTypeId })
        .then(x => x.filterBy('fieldType', 'CustomDateInput')),
      tokens: this.store.query('token', { organizationId }).then(x => x.toArray()),
      tokenContents: this.store
        .query('message-version-template-token-content', { messageCampaignId })
        .then(x => x.toArray()),
    });
  },

  afterModel(model) {
    this._super(...arguments);
    //Seed the checklist model in the checklist service
    set(this.checklist, 'checklist', model.checklist);
  },
  //endregion

  //region Methods
  async copyMessageVersionData(messageVersion) {
    const newMessageVersion = await copyMessageVersion(messageVersion);
    try {
      await newMessageVersion.save();
      await Promise.all(newMessageVersion.tokenContents.map(tokenContent => tokenContent.save()));
      return newMessageVersion;
    } catch (err) {
      console.error(err);
      newMessageVersion.deleteRecord();
    }
  },
  //endregion

  //region Actions
  actions: {
    confirm() {
      set(this, 'controller.confirmed', true);
      this.controller.saveConfirmation();
    },

    unconfirm() {
      set(this, 'controller.confirmed', false);
      this.controller.saveConfirmation();
    },

    /**
     * Delete a message-version and it's related
     * message and schedule records
     * @param messageVersionToBeDeleted {Object} the message version to be deleted
     */
    async removeMessageVersion(messageVersionToBeDeleted) {
      const messageToBeDeleted = await messageVersionToBeDeleted.message;
      const messageCampaign = await messageToBeDeleted.messageCampaign;
      const scheduleToBeDeleted = messageToBeDeleted.schedule;
      const { usesSingleMessageCampaign } = messageCampaign;

      if (usesSingleMessageCampaign) {
        await messageVersionToBeDeleted.destroyRecord();
      } else {
        const nextSchedule = messageCampaign.messages.find(
          message => Number(message.schedule?.relatedEventEntityId) === Number(messageToBeDeleted.id)
        )?.schedule;

        if (isPresent(nextSchedule)) {
          set(nextSchedule, 'scheduleTypeName', scheduleToBeDeleted.scheduleTypeName);
          set(nextSchedule, 'relatedEventEntityId', scheduleToBeDeleted.relatedEventEntityId);
        }

        await Promise.all([
          nextSchedule?.save(),
          scheduleToBeDeleted?.destroyRecord(),
          messageVersionToBeDeleted.destroyRecord(),
          messageToBeDeleted.destroyRecord(),
        ]);
      }

      this.send('messageChecklistStepLogic', usesSingleMessageCampaign);
      this.send('messageVersionsChangedChecklistStepLogic', usesSingleMessageCampaign);
    },

    async copyMessageVersion(messageVersion) {
      const newMessageVersion = await this.copyMessageVersionData(messageVersion);
      if (newMessageVersion) {
        this.router.transitionTo(
          'organizations.organization.message-campaigns.message-campaign.setup.message-versions',
          {
            queryParams: { messageVersionId: newMessageVersion.id },
          }
        );
      }
    },
  },
  //endregion
});
