import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class DesignTemplatePromotionTypeModel extends BaseModel {
  @attr('number') declare promotionTypeId: number;
  @attr('number') declare designTemplateId: number;
  @attr('number') declare organizationId: number;
  @attr('boolean', { defaultValue: false }) declare isEnforced: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'design-template-promotion-type': DesignTemplatePromotionTypeModel;
  }
}
