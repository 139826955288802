import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type { ModelFrom } from 'partner/utils/types';
import { type Model as SetupBallotModel } from '../../ballot';
import nextDisplayOrder from 'partner/utils/next-display-order';

export type Model = ModelFrom<SetupBallotMatchupGroupsNewRoute>;

export default class SetupBallotMatchupGroupsNewRoute extends Route {
  @service declare store: Store;

  model() {
    const matchupGroup = this.store.createRecord('matchup-group', {
      displayOrder: this.getNextDisplayOrder(),
      statusTypeId: 1,
    });

    return { matchupGroup };
  }

  private getNextDisplayOrder() {
    const { matchupGroups } = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion.setup.ballot'
    ) as SetupBallotModel;

    return nextDisplayOrder(matchupGroups);
  }
}
