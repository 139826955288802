/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import PermittedComponent from 'partner/mixins/permitted-component';
import { parseWeekly } from 'partner/utils/recurring-pattern';

const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const computeDay = function (day) {
  return computed('parsedPattern', {
    get() {
      return get(this, `parsedPattern.${day}`);
    },
    set(_, value) {
      const method = value ? 'addObject' : 'removeObject';
      const arr = this.pattern.split(',');
      const fn = arr[method].bind(arr);
      const pattern = fn(day.toUpperCase())
        .reject(x => isEmpty(x))
        .sort((a, b) => days.indexOf(a.toLowerCase()) - days.indexOf(b.toLowerCase()))
        .join(',');
      set(this, 'pattern', pattern);
      return value;
    },
  });
};

const RecurringPatternWeeklyComponent = Component.extend(PermittedComponent, {
  //region Ember Hooks
  tagName: 'recurring-pattern-weekly',
  //endregion

  //region Attributes
  /**
   * @type {String}
   */
  pattern: '',
  //endregion

  //region Properties
  days,
  //endregion

  sun: computeDay('sun'),
  mon: computeDay('mon'),
  tue: computeDay('tue'),
  wed: computeDay('wed'),
  thu: computeDay('thu'),
  fri: computeDay('fri'),
  sat: computeDay('sat'),

  //region Computed Properties
  parsedPattern: computed('pattern', function () {
    return parseWeekly(this.pattern);
  }),
  //endregion

  //region Actions
  actions: {
    toggle(day) {
      this.toggleProperty(day);
    },
  },
  //endregion
});

export default RecurringPatternWeeklyComponent;
