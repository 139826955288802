import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
import xss from 'xss';

export function sanitize(value: string) {
  return htmlSafe(xss(value));
}

export default helper(function sanitizeHelper([value]: [string]) {
  return sanitize(value);
});
