/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import OrganizationPromotionListController from 'partner/mixins/organization-promotion-list-controller';

/**
 * OrganizationPromotions Archived Controller
 * /o/:organization_id/op/archived
 * @type {Ember.Controller}
 * @mixes OrganizationPromotionListController
 */
export default Controller.extend(OrganizationPromotionListController, {
  promotionTypeCategoryId: 1,
});
