import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<a\n  href=\"#\"\n  title=\"Help\"\n  class=\"global-actions__icon-toggle material-icons\"\n  {{on \"click\" (prevent-default (perform this.toggleTask (not this.isOpened)))}}\n  {{did-insert this.setup}}\n  {{will-destroy this.cleanup}}\n  ...attributes\n>\n  <Ui::Icon @name=\"contact_support\" />\n</a>\n", {"contents":"<a\n  href=\"#\"\n  title=\"Help\"\n  class=\"global-actions__icon-toggle material-icons\"\n  {{on \"click\" (prevent-default (perform this.toggleTask (not this.isOpened)))}}\n  {{did-insert this.setup}}\n  {{will-destroy this.cleanup}}\n  ...attributes\n>\n  <Ui::Icon @name=\"contact_support\" />\n</a>\n","moduleName":"partner/components/ss-support-toggle.hbs","parseOptions":{"srcName":"partner/components/ss-support-toggle.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type SessionService from 'partner/services/-private/session';
import { loadScript } from 'secondstreet-common/utils/load-script';

interface SsSupportToggleSignature {
  HTMLElement: HTMLDivElement;
}

export default class SsSupportToggle extends Component<SsSupportToggleSignature> {
  @service declare appcues: any;
  @service declare session: SessionService;

  @tracked isOpened = false;

  private isAssistInitialized = false;

  @action
  setup() {
    document.body.addEventListener('closeSupportPanel', this.closeSupportPanel);
  }

  @action
  cleanup() {
    document.body.removeEventListener('closeSupportPanel', this.closeSupportPanel);

    try {
      window.UES?.destroy();
    } catch (e) {
      console.warn(e);
    }
  }

  toggleTask = task({ drop: true }, async () => {
    try {
      await this.maybeInitializeUplandAssist();

      if (this.isOpened) {
        window.UES?.hide();
      } else {
        window.UES?.show({});
        this.appcues.track('Clicked Upland Assist');
      }
    } catch (e) {
      console.warn(e);
    }
  });

  private async maybeInitializeUplandAssist() {
    if (this.isAssistInitialized) return;

    await loadScript('https://assist.uplandsoftware.com/js/entry.js');

    window.UES?.init({
      token: this.session.data.authenticated?.upland_assist_jwt_token,
      disableMinimize: true,
      container: '#assist-panel',
      width: 320,
    });

    window.UES?.on('revealed', (value: { value: boolean }) => {
      this.isOpened = value.value;
      this.resizeAppContainer();
    });

    this.isAssistInitialized = true;
  }

  private resizeAppContainer() {
    const { body } = document;
    const supportPanel = document.querySelector('#assist-panel');
    const app = document.querySelector('.ssApp');

    if (this.isOpened) {
      const notifications = document.querySelector('.ssNotificationsOverlay');

      if (notifications instanceof HTMLDivElement) notifications.click();
      if (supportPanel) supportPanel.classList.add('ss-support-panel--open');
      if (app && body.scrollWidth > 420) app.classList.add('ss-support-panel-is-open');
    } else {
      if (supportPanel) supportPanel.classList.remove('ss-support-panel--open');
      if (app && body.scrollWidth > 420) app.classList.remove('ss-support-panel-is-open');
    }

    // C3 Charts resize - wait for class to be applied
    setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
  }

  private closeSupportPanel = () => {
    if (this.isOpened) void this.toggleTask.perform();
  };
}
