import enums from 'ember-cli-ss-enums/services/enums';
import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class EntriesOrganizationUserModel extends BaseModel {
  @attr('number') declare birthDay: number;
  @attr('number') declare birthMonth: number;
  @attr('number') declare birthYear: number;
  @attr('string') declare emailAddress: string;
  @attr('number') declare entryId: number;
  @attr('string') declare entryName: string;
  @attr('string') declare firstName: string;
  @attr('string') declare lastName: string;
  @attr('number') declare organizationUserId: number;
  @attr('number') declare statusTypeId: number;
  @attr('date') declare dateCreated: number;

  get disqualified() {
    return enums.findWhere('STATUS_TYPE', { name: 'InActive' }) === this.statusTypeId;
  }

  get birthDate() {
    return new Date(this.birthYear, this.birthMonth - 1, this.birthDay);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'entries-organization-user': EntriesOrganizationUserModel;
  }
}
