/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import SettingModel from 'partner/models/setting';

export default class SetupDesignsLegacyController extends Controller {
  @service declare router: RouterService;

  declare model: any;

  switchToNewDesignerTask = task({ drop: true }, async () => {
    const setting = this.model.useOldTemplateSetting as SettingModel;

    setting.value = false;
    setting.forceDirty();
    await setting.save();
    await this.router.refresh();
  });
}

declare module '@ember/controller' {
  interface Registry {
    'organizations.organization.organization-promotions.organization-promotion.setup.designs.legacy': SetupDesignsLegacyController;
  }
}
