import Controller from '@ember/controller';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class TurnkeysCreateController extends Controller {
  @service declare router: RouterService;

  handleCreate = (organizationPromotion: any) => {
    void this.router.transitionTo(
      'organizations.organization.organization-promotions.organization-promotion',
      organizationPromotion.id
    );
  };
}
