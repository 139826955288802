/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-mixins */
import Controller from '@ember/controller';
import { alias, readOnly } from '@ember/object/computed';
import HasCategoricalCharts from 'partner/mixins/has-categorical-charts';
import HasEventCharts from 'partner/mixins/has-event-charts';
import NeedsOrganizationPromotionController from 'partner/mixins/needs-organization-promotion-controller';
import { firstByProperty } from 'partner/utils/computed';

export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionDashboardSurveyController extends Controller.extend(
  HasCategoricalCharts,
  HasEventCharts,
  NeedsOrganizationPromotionController
) {
  @readOnly('organizationPromotion.kpi')
  totalResponses;

  @readOnly('organizationPromotion.numEligibleUsers')
  numEligibleUsers;

  @firstByProperty('name', 'Form Submission', 'model.eventCharts')
  entriesChart;

  @alias('model.matchups.firstObject.startDate')
  surveyStartDate;

  @alias('model.matchups.lastObject.endDate')
  surveyEndDate;
}
