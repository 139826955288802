import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

const MEMBERS_PER_PAGE = 50;

export default class OrganizationsOrganizationDataAudiencesAudienceMembersRoute extends Route {
  @service('store') store;

  //region Ember Hooks
  queryParams = {
    searchValue: {
      refreshModel: true,
      replace: true,
    },
    columnSort: {
      refreshModel: true,
      replace: true,
    },
    pageIndex: {
      refreshModel: true,
      replace: true,
    },
  };

  async model({ searchValue, columnSort, pageIndex = 1 }) {
    const { id: audienceId, entityId: fieldId } = this.modelFor(
      'organizations.organization.data.audiences.audience'
    ).audience;
    const [sortColumn, sortDirection] = columnSort.split(':');

    return RSVP.hash({
      fieldId,
      audienceMembers: await this.store.query('audience-member', {
        audienceId,
        pageSize: MEMBERS_PER_PAGE,
        pageIndex,
        searchValue,
        sortColumn,
        sortDirection,
      }),
    });
  }
  //endregion

  //region Actions
  @action
  loading(transition) {
    return !transition.queryParamsOnly;
  }

  @action
  refreshAudience() {
    this.store.queryRecord('audience', {
      id: this.modelFor('organizations.organization.data.audiences.audience').audience.id,
      organizationId: this.modelFor('organizations.organization').organization.id,
      updateCount: true,
    });

    this.refresh();
  }
  //endregion
}
