/* eslint-disable ember/no-get, ember/no-classic-classes, ember/avoid-leaking-state-in-ember-objects, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { computed, get, set } from '@ember/object';
import { alias, or } from '@ember/object/computed';
import { collectGrandchildren } from 'partner/utils/computed';

export default Controller.extend({
  //region Dependencies
  organizationPromotionController: controller(
    'organizations.organization.organization-promotions.organization-promotion'
  ),
  //endregion

  //region Properties
  deletedQuestionOptions: [],
  showNoQuestionOptionError: false,
  isSimulationRunning: false,
  isSimulationComplete: false,
  simulationData: null,
  //endregion

  //region Computed Properties
  organizationPromotion: alias('organizationPromotionController.model.organizationPromotion'),
  /**
   * QuestionOptionOutcomeWeights that are related to this Question via QuestionOptions
   * @type {Ember.ComputedProperty}
   * @returns {Ember.Array}
   */
  questionOptionOutcomeWeights: collectGrandchildren('model.question.questionOptions', 'questionOptionOutcomeWeights'),
  anyQuestionOptionsSaving: computed(
    'model.question.questionOptions.@each.isSaving',
    'deletedQuestionOptions.@each.isSaving',
    function () {
      const questionOptions = get(this, 'model.question.questionOptions') || [];
      const deletedOptions = this.deletedQuestionOptions || [];
      return (
        questionOptions.filterBy('isSaving', true).length > 0 || deletedOptions.filterBy('isSaving', true).length > 0
      );
    }
  ),
  anyQuestionOptionOutcomeWeightsSaving: computed('questionOptionOutcomeWeights.@each.isSaving', function () {
    const outcomeWeights = this.questionOptionOutcomeWeights || [];
    return outcomeWeights.filterBy('isSaving', true).length > 0;
  }),
  isQuestionOptionsDirty: computed(
    'model.question.questionOptions.@each.isDirty',
    'deletedQuestionOptions.@each.isDirty',
    function () {
      const questionOptions = get(this, 'model.question.questionOptions') || [];
      const anyDirty = function (pValue, option) {
        return pValue ? true : option.get('isDirty');
      };
      return questionOptions.reduce(anyDirty, false) || this.deletedQuestionOptions.reduce(anyDirty, false);
    }
  ),
  isQuestionOptionOutcomeWeightsDirty: computed('questionOptionOutcomeWeights.@each.{isJunkData,isDirty}', function () {
    if (this.questionOptionOutcomeWeights) {
      return this.questionOptionOutcomeWeights.reduce(
        (pValue, weight) => (pValue ? true : weight.get('isDirty') && !weight.get('isJunkData')),
        false
      );
    }
    return;
  }),
  isAnythingSaving: or('model.question.isSaving', 'anyQuestionOptionsSaving', 'anyQuestionOptionOutcomeWeightsSaving'),
  isAnythingDirty: or(
    'model.question.hasDirtyAttributes',
    'isQuestionOptionsDirty',
    'isQuestionOptionOutcomeWeightsDirty'
  ),
  isSimulationCompleteOrRunning: or('isSimulationRunning', 'isSimulationComplete'),
  isQuizPersonality: alias('organizationPromotion.promotions.isQuizPersonality'),
  //endregion

  //region Actions
  actions: {
    deleteQuestionOption(questionOption) {
      if (!get(this, 'model.question.isNew')) {
        this.deletedQuestionOptions.addObject(questionOption);
      }
      questionOption.deleteRecord();
    },
    questionBranchingSelected(sourceQuestionOption, targetQuestionId) {
      set(sourceQuestionOption, 'nextQuestionId', targetQuestionId);
    },
  },
  //endregion
});
