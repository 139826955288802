/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import WinnerDisplay from 'partner/mixins/winner-display';

/**
 * This is designed to display winners on /o/:id/op/:id/winners
 */
export default Component.extend(WinnerDisplay, {
  //region Attributes
  'is-video-sweeps': false,
  winners: null,
  'repicked-matchup-entry-id': null,
  'calculating-winners': null,
  'registration-form-fields': null,
  //endregion

  //region Computed Properties
  beingRepicked: computed('repicked-matchup-entry-id', 'calculating-winners', function () {
    const calculatingWinners = this['calculating-winners'];
    const repickedMatchupEntryId = this['repicked-matchup-entry-id'];
    const winner = get(this, 'winner');
    const repickedWinnerRank = this['repicked-winner-rank'];

    return (
      calculatingWinners &&
      (winner.fakeWinner ? repickedWinnerRank === winner.rank : repickedMatchupEntryId === winner.matchupEntry?.id)
    );
  }),
  //endregion
});
