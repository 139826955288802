import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class OrganizationsOrganizationNotificationsController extends Controller {
  @service('notifications') notifications;
  @service('store') store;

  /**
   * The PartnerNotification we're currently editing, if any.
   * @type {PartnerNotification?}
   */
  @tracked editing = null;

  /**
   * Whether the user is editing their notification targeting
   * @type {Boolean}
   */
  @tracked isEditingTargeting = false;

  get isEditingDisabled() {
    if (this.editing && this.editing.isNew) return false;
    if (this.editing && !this.editing.isPast) return false;

    return true;
  }

  saveTask = task({ drop: true }, async () => {
    await this.editing.save();
    this.stopEditing();
  });

  @action
  create(type, e) {
    e.preventDefault();

    const notification = this.store.createRecord('partner-notification', { partnerNotificationType: type });

    this.model.partnerNotifications.addObject(notification);
    this.editing = notification;
  }

  @action
  remove() {
    this.editing.destroyRecord();
    this.model.partnerNotifications.removeObject(this.editing);
    this.stopEditing();
  }

  @action
  edit(notification) {
    this.editing = notification;
  }

  @action
  stopEditing() {
    this.isEditingTargeting = false;
    this.editing = null;
  }

  @action
  toggleTargeting() {
    this.isEditingTargeting = !this.isEditingTargeting;
  }

  @action
  makeTargeted(product, isTargeted) {
    if (isTargeted) {
      this.editing.ssProducts.addObject(product);
    } else {
      this.editing.ssProducts.removeObject(product);
    }

    // Because adding to a hasMany doesn't dirty the object.
    this.editing.forceDirty();
  }
}
