import { action, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { endpoint } from 'secondstreet-common/utils/url';
import ENV from 'partner/config/environment';

export default class LoginIndexRoute extends Route {
  @service router;
  @service session;

  queryParams = {
    code: { refreshModel: true, replace: true },
    state: { refreshModel: true, replace: true },
    uniqueUri: { refreshModel: true, replace: true },
  };

  redirect() {
    if (this.session.isTwoFactorAuthIncomplete) {
      this.router.replaceWith('login.totp');
      return;
    }

    if (this.session.isAuthenticated) {
      this.router.replaceWith('index');
      return;
    }
  }

  async beforeModel(transition) {
    const { queryParams } = transition.to;
    try {
      if (!this.session.isAuthenticated && queryParams.code) {
        await this.session.authenticate('authenticator:second-street', queryParams);
      }
    } catch (e) {
      this.router.replaceWith('login.index');
    }
  }

  @action
  async formSubmitted({ username, password }) {
    this.controller.isLoggingIn = true;

    try {
      await this.session.authenticate('authenticator:second-street', { username, password });
    } catch ({ payload: { errors } }) {
      set(this, 'controller.errors', errors);
    } finally {
      this.controller.isLoggingIn = false;
    }
  }

  @action
  async checkEmail({ username }) {
    set(this, 'controller.errors', []);
    this.controller.isLoggingIn = true;
    try {
      const response = await this.session.request(
        `${endpoint('ucm')}?emailAddress=${encodeURIComponent(username)}&uniqueUri=${this.controller.uniqueUri || ''}`,
        {
          contentType: 'text/javascript',
          dataType: 'json',
        }
      );
      if (Array.isArray(response.redirectUrl) && isPresent(response.redirectUrl.firstObject)) {
        if (ENV.environment != 'test') {
          window.location = response.redirectUrl.firstObject;
        }
        return;
      }
      this.controller.showPasswordField = true;
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    } finally {
      this.controller.isLoggingIn = false;
    }
  }
}
