/* eslint-disable ember/no-mixins */

import AppContextRoute from 'partner/mixins/app-context-route';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Store from '@ember-data/store';
import OrganizationPromotionSyndicationMetricsController from 'partner/controllers/organizations/organization/organization-promotions/organization-promotion/syndication-metrics';
import Transition from '@ember/routing/transition';

export default class OrganizationPromotionSyndicationMetricsRoute extends Route.extend(AppContextRoute) {
  @service declare store: Store;

  queryParams = {
    pageIndex: {
      refreshModel: false,
      replace: true,
    },
    sortColumn: {
      refreshModel: false,
      replace: true,
    },
    sortDirection: {
      refreshModel: false,
      replace: true,
    },
    organizationIds: {
      refreshModel: false,
      replace: true,
    },
  };

  async model() {
    const orgs = await this.store.findAll('organization');
    return orgs;
  }

  setupController(
    controller: OrganizationPromotionSyndicationMetricsController,
    model: any,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    controller.updateParams();
  }
}
