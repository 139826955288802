import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex flex-col h-full bg-white\" ...attributes>\n  <div\n    class={{tw-merge\n      \"sticky top-0 flex-shrink-0 flex items-center pr-2 py-1 min-h-[51px] bg-white border-b border-gray-200\"\n      @headerClassNames\n    }}\n  >\n    <div>\n      {{yield to=\"prefix\"}}\n    </div>\n\n    <span class=\"flex-auto text-base text-gray-800 font-semibold truncate flex-shrink-0\" test-title>\n      {{@title}}\n    </span>\n\n    {{#if (has-block \"actions\")}}\n      <div class=\"pl-1\" test-actions>\n        {{yield to=\"actions\"}}\n      </div>\n    {{/if}}\n  </div>\n\n  {{! template-lint-disable no-potential-path-strings }}\n  <div class={{tw-merge \"@container\" @contentClassNames}}>\n    {{yield to=\"content\"}}\n  </div>\n</div>", {"contents":"<div class=\"flex flex-col h-full bg-white\" ...attributes>\n  <div\n    class={{tw-merge\n      \"sticky top-0 flex-shrink-0 flex items-center pr-2 py-1 min-h-[51px] bg-white border-b border-gray-200\"\n      @headerClassNames\n    }}\n  >\n    <div>\n      {{yield to=\"prefix\"}}\n    </div>\n\n    <span class=\"flex-auto text-base text-gray-800 font-semibold truncate flex-shrink-0\" test-title>\n      {{@title}}\n    </span>\n\n    {{#if (has-block \"actions\")}}\n      <div class=\"pl-1\" test-actions>\n        {{yield to=\"actions\"}}\n      </div>\n    {{/if}}\n  </div>\n\n  {{! template-lint-disable no-potential-path-strings }}\n  <div class={{tw-merge \"@container\" @contentClassNames}}>\n    {{yield to=\"content\"}}\n  </div>\n</div>","moduleName":"partner/components/ballot/setup/section.hbs","parseOptions":{"srcName":"partner/components/ballot/setup/section.hbs"}});
import Component from '@glimmer/component';

interface BallotSetupSectionSignature {
  Element: HTMLDivElement;
  Args: {
    title: string;
    headerClassNames?: string;
  };
  Blocks: {
    actions: [];
    content: [];
  };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class BallotSetupSectionComponent extends Component<BallotSetupSectionSignature> {}
