/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { lte, not } from '@ember/object/computed';
import moment from 'moment';
import PermittedComponent from 'partner/mixins/permitted-component';

export default Component.extend(PermittedComponent, {
  //region Properties
  tagName: 'li',
  //endregion

  //region Computed Properties
  daysSinceCreated: computed('turnkey.dateCreated', function () {
    return moment().diff(moment(get(this, 'turnkey.dateCreated')), 'days');
  }),
  isRecentlyAdded: lte('daysSinceCreated', 30),
  isDisabled: not('administer'),
  //endregion
});
