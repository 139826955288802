/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
/* eslint-disable ember/use-ember-get-and-set */ // FIXME
import Mixin from '@ember/object/mixin';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import ResetScrollRoute from 'partner/mixins/reset-scroll-route';
import RSVP from 'rsvp';

/**
 * Message Campaign List Route Mixin
 * @type {Ember.Mixin}
 * @mixes ResetScrollRoute
 * @mixes AppContextRoute
 */
export default Mixin.create(ResetScrollRoute, AppContextRoute, {
  store: service(),

  queryParams: {
    statusTypeId: { refreshModel: true },
    pageIndex: { refreshModel: true },
    sortColumn: { refreshModel: true },
    sortDirection: { refreshModel: true },
    getCurrent: { refreshModel: true },
    isComplete: { refreshModel: true },
    searchValue: { refreshModel: true },
    messageCampaignTypeId: { refreshModel: true },
  },

  model(params) {
    const hasData = get(this.modelFor('organizations.organization'), 'organization.hasData');
    const organization = get(this.modelFor('organizations.organization'), 'organization');
    return this.store
      .query('messageCampaign', {
        statusTypeId: 1,
        pageSize: 25,
        ...params,
      })
      .then(messageCampaigns =>
        RSVP.hash({
          organizations: this.store.findAll('organization').then(x => x.toArray()),
          messageCampaigns: messageCampaigns.toArray(),
          currentMeta: messageCampaigns.meta,
          hasData,
          organization,
        })
      );
  },

  setupController(controller) {
    this._super(...arguments);
    set(
      controller,
      'showFilters',
      controller.searchValue || controller.messageCampaignTypeId || controller.sortColumn || controller.sortDirection
    );
  },

  actions: {
    willTransition() {
      this.controller.set('pageIndex', 1);
      this.controller.set('isCreating', false);
    },
  },
});
