/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { computed } from '@ember/object';
import Attribute from 'partner/components/template-builder/attribute-editor/attribute';

const FONT_FAMILY_OPTIONS = [
  {
    label: 'Arial',
    value: 'Arial,sans-serif',
  },
  {
    label: 'Helvetica',
    value: 'Helvetica,sans-serif',
  },
  {
    label: 'Times New Roman',
    value: 'Times New Roman,serif',
  },
  {
    label: 'Verdana',
    value: 'Verdana,sans-serif',
  },
  {
    label: 'Courier New',
    value: 'Courier New,serif',
  },
  {
    label: 'Tahoma',
    value: 'Tahoma,sans-serif',
  },
  {
    label: 'Georgia',
    value: 'Georgia,serif',
  },
  {
    label: 'Trebuchet MS',
    value: 'Trebuchet MS,sans-serif',
  },
  {
    label: 'Geneva',
    value: 'Geneva,sans-serif',
  },
  {
    label: 'Lucida Sans Unicode',
    value: 'Lucida Sans Unicode,Lucida Grande,sans-serif',
  },
  {
    label: 'PT Serif',
    value: 'PT Serif,Georgia,sans-serif',
  },
  {
    label: 'Arial Black',
    value: 'Arial Black,Gadget,sans-serif',
  },
  {
    label: 'Lato',
    value: 'Lato,Gadget,Helvetica,Arial,sans-serif',
  },
];

const TEXT_TRANSFORM_OPTIONS = [
  {
    label: 'UPPERCASE',
    value: 'uppercase',
  },
  {
    label: 'Normal Case',
    value: 'none',
  },
  {
    label: 'Capitalize',
    value: 'capitalize',
  },
];

const BORDER_OPTIONS = [
  {
    label: 'Solid',
    value: 'solid',
  },
  {
    label: 'Dashed',
    value: 'dashed',
  },
  {
    label: 'Double Line',
    value: 'double',
  },
  {
    label: 'Dots',
    value: 'dotted',
  },
];

const FONT_FAMILY_ATTRS = [
  'font-family',
  'button-font-family',
  'headline-font-family',
  'date-font-family',
  'description-font-family',
];

export default Attribute.extend({
  //region Computed Properties
  options: computed('attr-name', function () {
    const attr = this['attr-name'];
    return FONT_FAMILY_ATTRS.includes(attr)
      ? FONT_FAMILY_OPTIONS
      : attr === 'border-style'
      ? BORDER_OPTIONS
      : TEXT_TRANSFORM_OPTIONS;
  }),

  isFontDropdown: computed('attr-name', function () {
    return FONT_FAMILY_ATTRS.includes(this['attr-name']);
  }),
  //endregion

  //region Actions
  actions: {
    setAttribute(option) {
      this['set-attribute'](this['attr-name'], option.value);
    },
  },
  //endregion
});
