/**
 * @interface DesignTokenContentModel
 * @extends DS.Model
 * @property {Boolean} isPlaceholder
 * @property {String} value
 * @property {String} linkUrl
 * @property {String} altText
 * @property {Number} mediaItemId
 * @property {DesignModel} design
 * @property {DesignTokenModel} token
 * @property {Boolean} isComplete
 */
