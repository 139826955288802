/* eslint-disable @typescript-eslint/restrict-template-expressions */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type SettingsService from 'partner/services/settings';
import RSVP from 'rsvp';
import type { ModelFrom } from 'partner/utils/types';
import CurrentService from 'partner/services/current';
import enums from 'ember-cli-ss-enums/services/enums';
import { alphabeticalByProperty } from 'secondstreet-common/utils/sorting';
import { sortBy } from 'secondstreet-common/helpers/extended-sort-by';
import type SetupAdsController from 'partner/-base/promotion-ads-controller';
import type MatchupEntryModel from 'partner/models/matchup-entry';
import { action } from '@ember/object';

const statusTypeIdActive = enums.findWhere('STATUS_TYPE', { name: 'Active' });

const matchupEntityTypeId = enums.findWhere('ENTITY_TYPE', {
  name: 'Matchup',
});
const matchupGroupEntityTypeId = enums.findWhere('ENTITY_TYPE', {
  name: 'Game Group',
});

const matchupEntryEntityTypeId = enums.findWhere('ENTITY_TYPE', {
  name: 'Matchup Entry',
});

export type Model = ModelFrom<PromotionAdsRoute>;
export default class PromotionAdsRoute extends Route {
  @service declare store: Store;
  @service declare settings: SettingsService;
  @service declare current: CurrentService;
  @service declare checklist: any;

  getStats = false;

  async model() {
    const hash = await RSVP.hash({
      _settings: this.settings.preload(['dips_url', 'category_sort_criteria']),
      matchups: this.store.query('matchup', { excludeSecondaryMatchups: true }).then(x => x.toArray()),
      matchupGroups: this.store.query('matchup-group', {}).then(x => x.sortBy('displayOrder')),
      ads: this.store
        .query(this.getStats ? 'sponsored-posts-statistic' : 'sponsored-post', { BypassCache: true })
        .then(x => x.toArray()),
      matchupEntries: this.store
        .query('matchup-entry', {
          organizationPromotionId: this.current.organizationPromotion.id,
          filterByOrganizationPromotion: true,
          statusTypeId: statusTypeIdActive,
          pageSize: 10000,
          pageIndex: 1,
        })
        .then(x => x.toArray()),
    });

    const matchupSortCriteria = this.settings.getValueFor('category_sort_criteria');
    const entriesSortCriteria = hash.matchups.firstObject?.entriesSortType;
    hash.ads = hash.ads.filter(
      ({ ownerEntityTypeId }) =>
        ownerEntityTypeId == matchupEntityTypeId ||
        ownerEntityTypeId == matchupEntryEntityTypeId ||
        ownerEntityTypeId == matchupGroupEntityTypeId
    );

    return {
      ...hash,
      ads: hash.ads.reject(
        ({ targetEntity, ballotArea }) =>
          ballotArea === 'Entrant Page' && !hash.matchupEntries.includes(targetEntity as MatchupEntryModel)
      ),
      matchupEntries:
        entriesSortCriteria == 'Manual'
          ? hash.matchupEntries.sortBy('displayOrder')
          : sortBy('mediaTitle.value:alpha', 'mediaTitle.value', hash.matchupEntries),
      matchups:
        +matchupSortCriteria == 1
          ? hash.matchups.sort(alphabeticalByProperty('name'))
          : hash.matchups.sortBy('displayOrder'),
    };
  }

  afterModel(model: Model) {
    if (model.ads?.length >= 1) {
      this.checklist.checkChecklistStep(this.routeName);
    } else {
      this.checklist.uncheckChecklistStep(this.routeName);
    }
  }

  setupController(controller: SetupAdsController, model: Model, transition: any) {
    super.setupController(controller, model, transition);
    if (controller.new) {
      controller.edit = 0;
      controller.duplicate = 0;
    } else if (controller.edit) {
      const targetAd = model.ads.findBy('id', `${controller.edit}`);
      controller.new = false;
      controller.duplicate = 0;
      if (targetAd) {
        controller.currentlyEditing = targetAd;
      } else {
        controller.edit = 0;
      }
    } else if (controller.duplicate) {
      const targetAd = model.ads.findBy('id', `${controller.edit}`);
      controller.new = false;
      controller.edit = 0;
      if (targetAd) {
        controller.currentlyEditing = this.store.createRecord('sponsored-post', {
          ...targetAd.toJSON(),
        });
      } else {
        controller.duplicate = 0;
      }
    }
  }

  resetController(controller: SetupAdsController, isExiting: boolean) {
    if (isExiting) {
      controller.currentlyEditing = undefined;
      [controller.selectedCategory] = controller.categories;
      controller.searchTerm = '';
      controller.sideNavSelection = '';
      controller.sortParams = {
        column: '',
        direction: '',
      };
    }
  }

  @action
  reload() {
    void this.refresh();
  }
}
