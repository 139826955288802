/* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes, ember/require-tagless-components, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { action, computed, get } from '@ember/object';
import { run, debounce } from '@ember/runloop';
import { isBlank } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import memoize from 'lodash/memoize';
import { normalize } from 'secondstreet-common/utils/english';

// If memory leaks ever become an issue thanks to all the memoizing going on,
// consider using the `willDestroyElement` hook to clear caches on the memoized functions.
// This would require overriding lodash.memoize.Cache as told here: https://github.com/lodash/lodash/issues/1269

const resolver = (org, str) => `${str}${org}`;
const matches = (obj, prop, str) =>
  (get(obj, prop) || '')
    .toString()
    .toLowerCase()
    .trim()
    .includes((str || '').toLowerCase().trim());
const organizationMatches = memoize(
  (org, str) =>
    isBlank(str) || matches(org, 'name', str) || matches(org, 'affiliateId', str) || matches(org, 'id', str),
  resolver
);

const recurseUpwards = memoize((org, str) => {
  const parent = get(org, 'parentOrganization');
  return parent ? organizationMatches(parent, str) || recurseUpwards(parent, str) : false;
}, resolver);
const organizationAncestorsMatch = memoize((org, str) => isBlank(str) || recurseUpwards(org, str), resolver);

const recurseDownwards = memoize(
  (org, str) =>
    (org.childOrganizations || [])
      .toArray()
      .some(childOrg => organizationMatches(childOrg, str) || recurseDownwards(childOrg, str)),
  resolver
);
const organizationDescendantsMatch = memoize((org, str) => isBlank(str) || recurseDownwards(org, str), resolver);

const orgViewSorting = (a, b) => {
  const aHasChildren = get(a, 'children.length') > 0;
  const bHasChildren = get(b, 'children.length') > 0;
  if (aHasChildren === bHasChildren) {
    const aName = normalize(a.name);
    const bName = normalize(b.name);

    if (aName < bName) {
      return -1;
    }
    if (bName < aName) {
      return 1;
    }
    return 0;
  }
  if (aHasChildren && !bHasChildren) {
    return -1;
  }
  return 1;
};

export default class ChooseOrganizationPage extends Component {
  //region Ember Hooks
  constructor() {
    super(...arguments);
    this._collapsedOrgIds = [];
    this._isInitializing = true;
  }

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
    if (this._isInitializing) {
      this._isInitializing = false;
      this._collapseChildOrganizations();
    }
  }

  classNames = ['choose-organization-page'];
  //endregion

  //region Properties
  /**
   * @type {String}
   * @private
   */
  @tracked
  _filterValue = '';

  /**
   * @type {Array}
   * @private
   */
  @tracked
  _collapsedOrgIds = null;

  /**
   * @type {Boolean}
   */
  @tracked
  _isInitializing = false;
  //endregion

  //region Computed Properties
  @computed('_filterValue')
  get _isFiltering() {
    return !isBlank(this._filterValue);
  }
  /**
   * @param {Organization[]} organizations
   * @param {String} filter
   * @returns {ChooseOrganizationView[]}
   * @private
   */
  @computed(
    'organizations.@each.{name,affiliateId,id,parentOrganization}',
    '_isFiltering',
    '_filterValue',
    '_collapsedOrgIds.[]'
  )
  get _organizationViews() {
    const { organizations } = this;
    const isFiltering = this._isFiltering;
    const filter = this._filterValue;
    const collapsedOrgs = this._collapsedOrgIds;

    const matchesAnywhere = organization =>
      organizationMatches(organization, filter) ||
      organizationAncestorsMatch(organization, filter) ||
      organizationDescendantsMatch(organization, filter);
    const recurse = orgs =>
      orgs
        .map(organization => {
          const isParentCollapsed = collapsedOrgs.includes(get(organization, 'parentOrganization.id'));

          /**
           * @typedef {ChooseOrganizationView}
           * @property {String} name
           * @property {String|Number} id
           * @property {Number} usersCount
           * @property {Boolean} isVisible
           * @property {Boolean} isCollapsed
           * @property {ChooseOrganizationView[]} children
           */
          return {
            name: organization.name,
            tier: organization.tier,
            id: organization.id,
            usersCount: get(organization, 'organizationStatistics.usersCount') || 0,
            isVisible: isFiltering ? matchesAnywhere(organization) : !isParentCollapsed,
            isCollapsed: collapsedOrgs.includes(organization.id),
            children: recurse(organization.childOrganizations || []).sort(orgViewSorting),
          };
        })
        .compact();
    return recurse(organizations || []);
  }
  /**
   * Because we know that a child organization can't be visible without its entire ancestor tree being visible,
   * we can check only the first layer here for performance
   *
   * @param {ChooseOrganizationView[]} organizationViews
   * @returns {Boolean}
   * @private
   */
  @computed('_organizationViews.@each.isVisible')
  get _isAnyOrganizationVisible() {
    return this._organizationViews.some(view => view.isVisible);
  }
  //endregion

  //region Methods
  _updateFilterValue(value) {
    run(() => {
      if (!this.isDestroyed) {
        this._filterValue = value;
      }
    });
  }

  _collapseChildOrganizations() {
    const collapsed = this._collapsedOrgIds;
    collapsed.clear();
    const collapseChildren = org =>
      (org.childOrganizations || []).forEach(child => {
        collapsed.addObject(child.id);
        collapseChildren(child);
      });
    (this.organizations || []).forEach(org => collapseChildren(org));
  }
  //endregion

  //region Actions
  @action
  updateFilterValue(value) {
    debounce(this, this._updateFilterValue, value, 750, false);
  }

  @action
  toggleCollapsed(id) {
    const collapsedOrgIds = this._collapsedOrgIds;
    return collapsedOrgIds.includes(id) ? collapsedOrgIds.removeObject(id) : collapsedOrgIds.addObject(id);
  }
  //endregion
}
