import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class OrganizationPromotionPaymentModel extends BaseModel {
  @attr('number') declare submissionFees: number;
  @attr('number') declare voteFees: number;
  @attr('string') declare paymentMessage: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'organization-promotion-payment': OrganizationPromotionPaymentModel;
  }
}
