/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

export default BaseModel.extend({
  //region Attributes
  firstName: attr('string'),
  lastName: attr('string'),
  emailAddress: attr('string'),
  optinSource: attr('string'),
  audienceJoinDate: attr('date'),
  organizationUserId: attr('string'),
  organizationId: attr('string'),
  //endregion

  //region Relationships
  audience: belongsTo('audience'),
  //endregion
});
