/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import PermittedComponent from 'partner/mixins/permitted-component';

export default Component.extend(PermittedComponent, {
  //region Attributes
  tagName: 'quiz-questions',
  classNames: ['quiz-questions'],
  /**
   * @type Question[]
   */
  questions: null,
  /**
   * @type Question
   */
  'just-created-question': null,
  /**
   * Action to add a new question option
   * @type {Function}
   */
  'add-question-option': () => {},
  /**
   * Action to edit a question option
   * @type {Function}
   */
  'edit-edit-question-option': () => {},
  /**
   * Action to save a question option
   * @type {Function}
   */
  'save-question-option': () => {},
  'dips-url': null,
  /**
   * @type Boolean
   */
  'currently-saving': null,
  /**
   * The QuestionOption that is currently being edited
   * @type QuestionOption
   */
  'question-option-being-edited': null,
  /**
   * Action to add a new question
   * @type {Function}
   */
  add: () => {},
  /**
   * Action to save a question
   * @type {Function}
   */
  save: () => {},
  /**
   * Send out action any time question toggle happens
   */
  'question-toggled': () => {},
  //endregion

  //region Properties
  isAddingImageFromLibrary: false,
  isAddImageFlyoutOpen: false,
  //endregion

  //region Computed Properties
  sortedQuestions: computed('questions.@each.displayOrder', function () {
    //using sortBy because computed.sort puts 2 before 1, for this we need 1,2,3...
    return this.questions.sortBy('displayOrder');
  }),

  allQuestionsHaveNames: computed('questions.@each.name', function () {
    return this.questions.every(q => get(q, 'name'));
  }),
  //endregion

  //region Methods
  async saveQuestion(question) {
    const name = get(question, 'name') || '';
    if (!isEmpty(name.trim()) && get(question, 'hasDirtyAttributes')) {
      await this.save(question);
    }
  },
  //endregion

  actions: {
    editImage(question) {
      set(this, 'questionEditedInModal', question);
    },
    saveQuestion(question) {
      this.saveQuestion(question);
    },
    async setQuestionMediaItemAndSave(question, mediaItem) {
      question.forceDirty();

      set(question, 'mediaItem', mediaItem);
      set(this, 'isAddImageFlyoutOpen', false);
      this.saveQuestion(question);
    },
    confirmRemoveQuestion(question) {
      this.remove(question);
    },
    showRemoveQuestionConfirmation(question) {
      set(this, 'questionBeingRemoved', question);
    },
    cancelRemoveQuestion() {
      set(this, 'questionBeingRemoved', null);
    },
    noAction() {
      //placeholder action when a different action should not happen. is there a better way?
    },
  },
});
