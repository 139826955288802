const option = ({ isTransparent, text }) => `
              <li class="ssThumbnailGridItem ssClickable ssThumbnailGridItemTextOnly ${
                isTransparent ? '' : '!border !border-solid !border-gray-100 !rounded-sm'
              }">
                <a class="ssThumbnailGridSquare">
                  <div class="ssVerticalCenterTableContainer">
                    <div class="ssVerticalCenterTable">
                      <div class="ssVerticalCenterTableRow">
                        <div class="ssVerticalCenterTableData">${text}</div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
`;

export default ({ logoContainer, headerContainer, isTransparent, textColor }) => `
${headerContainer}
<div class="ssQuizContainer preview">
  <div class="ssQuiz font-['Open_Sans']">
    <div class="ssQuestions">

      ${
        isTransparent
          ? ''
          : `<div class="min-h-[44px] template-color-primary-background-color flex flex-col items-center justify-center gap-2 text-primary-text">
         <span class="text-xs font-semibold" style="color: ${textColor}">
          Question 1 of 3
        </span>
         <div class="w-44 h-1 rounded-full bg-[rgba(0,0,0,0.2)]">
          <span class="h-full text-primary-background-color block rounded-full"  style="width: 33%; background-color:${textColor}"></span>
        </div>
      </div>`
      }
  
      <div>
        <div class="ssQuestion ssMainContent">
          ${logoContainer}
          ${
            isTransparent
              ? `<div class="ssQuestionHeader">
            <div class="ssVerticalCenterTable">
              <h2 class="ssVerticalCenterTableRow ssQuestionTitle">
                <span class="ssVerticalCenterTableData ssQuestionNumber">#1</span>
                <span class="ssVerticalCenterTableData ssQuestionName">Example Question</span>
              </h2>
            </div>
          </div>`
              : `<div class="flex flex-col gap-4 max-w-[720px] mx-auto mt-16 px-5">
            <div class="flex item gap-3">
              <span class="template-color-primary-color text-[28px] font-bold flex-shrink-0">
                1.
              </span>
              <span class="text-black text-[26px]">
                Example Question
              </span>
            </div>
          </div>`
          }
  
          <div class="ssQuestionOptions ${isTransparent ? '' : 'mt-4 px-5'}">
            <ul class="ssThumbnailGrid">
              ${option({ isTransparent, text: 'Answer One' })}
              ${option({ isTransparent, text: 'Answer Two' })}
              ${option({ isTransparent, text: 'Answer Three' })}
              ${option({ isTransparent, text: 'Answer Four' })}
            </ul>
          </div>
        </div>
      </div>
  
      <div class="ssQuestionProgressCallout ssQuestionProgressCalloutSecondary" style="display:none;">
        <div class="ssProgressBar ssProgressBarCanadian ssProgressBarMuted">
          <span class="ssProgressBarState" style="width: 33%"></span>
          <span class="ssProgressBarText">Question 1 of 3</span>
        </div>
      </div>
  
      <div class="ssSharing">
        <ul class="ssShareListCompact ssShareList">
          <li class="ssShareListItem ssShareListItemFacebook">
            <a class="ssShareLink ssShareLinkFacebook" data-token="facebook" href="#" >
              <i class="ssIcon-facebook"></i>
            </a>
          </li>
          <li class="ssShareListItem ssShareListItemTwitter">
            <a class="ssShareLink ssShareLinkTwitter" data-token="twitter" href="#">
              <i class="ssIcon-twitter"></i>
            </a>
          </li>
          <li class="ssShareListItem ssShareListItemEmail">
            <a class="ssShareLink ssShareLinkEmail" data-token="email" href="#" >
              <i class="ssIcon-envelope"></i>
            </a>
          </li>
          <li class="ssShareListItem ssShareListItemPinterest">
            <a class="ssShareLink ssShareLinkPinterest" data-token="pinterest" href="#" >
              <i class="ssIcon-pinterest"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
`;
