/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import DS from 'ember-data';
import isNumber from 'lodash/isNumber';
import Copyable from 'partner/mixins/copyable-model';
import dirtyProperty from 'partner/utils/dirty-property';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend(Copyable, {
  // Properties
  ownerEntityType: DS.attr('number'),
  ownerEntityId: DS.attr('number'),
  logicActionTypeId: DS.attr('number'),
  statusTypeId: DS.attr('number'),
  pointsPerMatch: DS.attr('number'),
  maximumMatches: DS.attr('number'),
  dateCreated: DS.attr('date'),
  dateModified: DS.attr('date'),
  isInherited: DS.attr('boolean'),

  // Computed Properties
  /**
   * Exists because for some instances we want to ignore the dirty state of a newly created MatchupLogic.
   * Because we create a MatchupLogic in memory and don't do anything with it unless the user edits certain properties.
   */
  isPointsPerMatchDirty: dirtyProperty('pointsPerMatch'),
  /**
   * Exists because for some instances we want to ignore the dirty state of a newly created MatchupLogic
   * Because we create a MatchupLogic in memory and don't do anything with it unless the user edits certain properties.
   */
  isMaximumMatchesDirty: dirtyProperty('maximumMatches'),
  pointsPerMatchStatus: computed('pointsPerMatch', function () {
    //complete, incomplete, bestPractice
    const value = this.pointsPerMatch;
    return isNumber(value) && value > 0 ? 'bestPractice' : 'incomplete'; //complete = bestPractice
  }),
  maximumMatchesStatus: computed('maximumMatches', function () {
    //-1 = unlimited
    const value = this.maximumMatches;
    return value && (value >= 0 || value === -1) ? 'bestPractice' : 'incomplete'; //complete = bestPractice
  }),
  isComplete: computed('pointsPerMatchStatus', 'maximumMatchesStatus', function () {
    const ppmStatus = this.pointsPerMatchStatus;
    const mmStatus = this.maximumMatchesStatus;
    return ppmStatus !== 'incomplete' && mmStatus !== 'incomplete';
  }),

  //region Relationships
  matchupPlacingCriteria: DS.belongsTo('matchup-placing-criteria', {
    async: true,
  }),
  matchupLogicConditions: DS.hasMany('matchup-logic-condition', {
    async: false,
  }),
  //endregion
});
