import duration from 'secondstreet-common/utils/duration';

export default function () {
  /* App bar transitions */
  this.transition(
    this.hasClass('liquid-archived'),
    this.includingInitialRender(),
    this.use('fade', { duration: duration(600) })
  );

  /* Miscellaneous */
  this.transition(
    this.hasClass('advanced-users-search'),
    this.toValue(true),
    this.use('toDown', { duration: duration(300) }),
    this.reverse('toUp', { duration: duration(300) })
  );

  this.transition(
    this.hasClass('login-area'),

    this.toValue(true),
    this.use('toLeft', { duration: duration(200) }),

    this.reverse('toRight', { duration: duration(200) })
  );
}
