/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/* eslint-disable ember/no-deeply-nested-dependent-keys-with-each */ // FIXME
import Component from '@ember/component';
import { computed, get, set, setProperties } from '@ember/object';
import { equal, mapBy } from '@ember/object/computed';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import flatten from 'lodash/flatten';
import TemplateDesigner from 'partner/mixins/template-designer';
import { generateWidgetPreview } from 'partner/utils/designer-preview';
import { WidgetEmbed } from 'partner/utils/embed-scripts';
import { wordpressCodeForWidgets } from 'partner/utils/wordpress-shortcodes';
import * as ReactDOM from 'react-dom';

const findToken = node => {
  const token = node.getAttribute('data-token');

  if (token) {
    return {
      token,
      iteration: Number(node.getAttribute('data-token-iteration') || 0),
      sectionIteration: Number(node.getAttribute('data-section-iteration') || 0),
    };
  } else if (node.parentNode.id == 'widget-preview') {
    return { token: null, iteration: 0 };
  }

  return findToken(node.parentNode);
};

export default Component.extend(TemplateDesigner, {
  //region Dependencies
  screen: service(),
  //endregion

  //region Ember Hooks
  classNames: ['template-designer--signup-widget'],
  didInsertElement() {
    this._super(...arguments);
    this.updateBody();
  },
  //endregion

  //region Attributes
  /**
   * @property {DesignTemplateModel[]}
   */
  'design-templates': null,
  /**
   * @property {Object}
   */
  audience: null,
  /**
   * @property {Object}
   */
  organization: null,
  /**
   * @property {Object}
   */
  widgetForm: null,
  /**
   * @property {Array}
   */
  'subscription-audiences': null,
  /**
   * @property {Array}
   */
  'starred-fields': null,
  'load-widget-form'() {},
  'save-widget-form'() {},
  'is-loading-widget-form': false,
  'create-token-content'() {},
  //endregion

  //region Properties
  isWidgetDesigner: true,
  //endregion

  //region Computed Properties
  /**
   * Partially mirrors data structure of tokenCategories rendered by the designer
   * Intended to simplify logic for expanding token categories vs immediately opening token flyout
   * @returns {Array}
   */
  customTokenCategories: computed(() => [
    {
      category: 'Embed',
      contentGroups: [['EmbedCode'], ['ShortCode']],
    },
  ]),

  embedCode: computed('design.id', function () {
    return new WidgetEmbed(this.design.id).embedCode;
  }),

  shortCode: computed('design.id', function () {
    return wordpressCodeForWidgets(this.design.id);
  }),

  // 1-based index. [1, 2, 3] instead of [0, 1, 2]
  // also includes the Form category which is not part of tokenContents
  embedCodeIndex: computed('design.tokenContents.@each.tokenCategory', function () {
    const tokenContents = get(this, 'design.tokenContents');
    return tokenContents ? tokenContents.uniqBy('tokenCategory').length + 2 : null;
  }),

  areAllContentsComplete: computed('designs.@each.isComplete', function () {
    return this.designs.isEvery('isComplete');
  }),

  fieldIds: mapBy('design.tokenContents', 'fieldId'),

  availableSubscriptionAudiences: computed('subscription-audiences.@each.entityId', 'fieldIds', function () {
    return this['subscription-audiences'].reject(audience =>
      this.fieldIds.includes(parseInt(get(audience, 'entityId'), 10))
    );
  }),

  designTemplates: computed('template', function () {
    return [this.template];
  }),

  subscriptionCategoryOpen: equal('openCategory', 'Subscriptions'),

  currentDesignIndex: computed('designs', 'design', function () {
    return this.designs.indexOf(this.design) + 1;
  }),

  embeddedDesignTemplate: computed('design-templates', function () {
    return get(
      this['design-templates'].filterBy('designTemplateType', 'Audience - Embedded').sortBy('id'),
      'firstObject'
    );
  }),

  exitIntentDesignTemplate: computed('design-templates', function () {
    return get(
      this['design-templates'].filterBy('designTemplateType', 'Audience - Exit Intent').sortBy('id'),
      'firstObject'
    );
  }),

  anyGrabbedCode: computed('designs.@each.hasGrabbedCode', function () {
    return this.designs.isAny('hasGrabbedCode');
  }),

  formFieldFields: mapBy('widgetForm.formPages.firstObject.formFields', 'field'),

  formFieldFieldOptions: computed('formFieldFields.@each.fieldOptions', function () {
    return flatten((this.formFieldFields || []).mapBy('fieldOptions'));
  }),

  formFieldFieldErrorObjects: computed('formFieldFields.@each.errors', function () {
    return (this.formFieldFields || []).mapBy('errors');
  }),

  hasFormFieldErrors: computed(
    'formFieldFieldErrorObjects.@each.length',
    'formFieldFieldOptions.@each.hasErrors',
    function () {
      const fieldErrorObjects = this.formFieldFieldErrorObjects;
      const fieldOptions = this.formFieldFieldOptions;
      const fieldsWithErrors = fieldErrorObjects ? fieldErrorObjects.filterBy('length') : [];
      const fieldOptionsWithErrors = fieldOptions ? fieldOptions.filterBy('errors.length') : [];
      return isPresent(fieldsWithErrors) || isPresent(fieldOptionsWithErrors);
    }
  ),

  hasDirtyWidgetFormFields: computed(
    'widgetForm.formPages.firstObject.formFields.@each.{isNew,hasDirtyAttributes}',
    function () {
      const { formFields } = this.widgetForm.formPages.firstObject;
      return formFields && (formFields.isAny('isNew') || formFields.isAny('hasDirtyAttributes'));
    }
  ),
  //endregion

  //region Methods
  updateBody() {
    const widgetPreviewNode = document.querySelector('#widget-preview');

    if (widgetPreviewNode) {
      const showThanks = this.subscriptionCategoryOpen || this.activeTokenContent?.token.key === 'signupThanksMessage';
      // need to unmount the widget component to ensure updated states
      ReactDOM.unmountComponentAtNode(document.querySelector('#widget-preview'));

      set(
        this,
        'design.renderedContent',
        ReactDOM.render(
          generateWidgetPreview(this.design, this.dipsUrl, this.widgetForm, showThanks),
          document.querySelector('#widget-preview')
        )
      );
    }
  },
  addNewTokenContent(token, design) {
    return this['create-token-content'](token, design);
  },
  //endregion

  //region Actions
  actions: {
    save() {
      this['save-widget-form']();
      this.save(this.design, this.areAllContentsComplete, this.anyGrabbedCode);
    },
    updateWidgetPreview() {
      this.updateBody();
    },
    convertDesign(design) {
      const newTemplate = get(this, get(design, 'isEmbedded') ? 'exitIntentDesignTemplate' : 'embeddedDesignTemplate');
      this['convert-design'](design, newTemplate);
    },
    scrollDown() {
      next(() => {
        //if the width is large enough that the widget preview is present, we need to target the flyout and scroll it down
        if (get(this, 'screen.width') > 700) {
          const templateEditor = document.querySelector('.template-editor');
          templateEditor.scrollTop = templateEditor.scrollHeight;
        } else {
          window.scrollTo(0, document.body.scrollHeight);
        }
      });
    },
    toggleFormEditor() {
      set(this, 'openCategory', null);
      this.updateBody();
      set(this, 'customTokenContent', 'WidgetForm');
    },
    updateImage(isDips, value) {
      setProperties(this.activeTokenContent, {
        value: isDips ? '' : value,
        mediaItemId: isDips ? value.id : '',
      });
      this.updateBody();
    },

    handleWidgetPreviewClick(e) {
      this.send('previewClicked', findToken(e.target));

      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();

      return false;
    },
  },
  //endregion
});
