import type Store from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import enums from 'ember-cli-ss-enums/services/enums';
import type InterestTagModel from 'partner/models/interest-tag';
import type PartnerUserModel from 'partner/models/partner-user';
import { generateRenderedContent } from 'partner/utils/designer-preview';
import type SessionService from './-private/session';
import type SettingsService from './settings';

const TOKENS_WITH_DEFAULT_VALUES = ['email', 'facebook', 'twitter', 'pinterest', 'thankYouMessage', 'productName'];

export default class OrganizationPromotionManagerService extends Service {
  @service declare session: SessionService;
  @service declare store: Store;
  @service declare settings: SettingsService;

  async setup(
    organizationPromotion: any,
    { interestTags, partnerUsers }: { interestTags: InterestTagModel[]; partnerUsers: PartnerUserModel[] }
  ) {
    try {
      // Sadly, some of the requests don't work without these headers
      this.session.addCustomHeaders({
        'X-Promotion-Id': organizationPromotion.promotion.id,
        'X-Organization-Promotion-Id': organizationPromotion.id,
      });

      await Promise.all([
        this.saveInterestTags(organizationPromotion, interestTags),
        this.savePartnerUsers(partnerUsers),
        this.setupDomain(organizationPromotion),
        this.setupEmails(organizationPromotion),
        this.setupMatchups(organizationPromotion),
        this.setupTemplates(organizationPromotion),
      ]);
    } finally {
      this.session.removeCustomHeader('X-Promotion-Id');
      this.session.removeCustomHeader('X-Organization-Promotion-Id');
    }
  }

  private async saveInterestTags(organizationPromotion: any, interestTags: InterestTagModel[]) {
    if (interestTags.length == 0) return;

    const entityTypeId = enums.findWhere('ENTITY_TYPE', { name: 'OrganizationPromotion' });
    const taggableEntity = await this.store.queryRecord('taggable-entity', {
      tier: 1,
      taggableEntityId: `${entityTypeId}-${String(organizationPromotion.id)}`,
    });

    return taggableEntity.replaceInterestTags(interestTags);
  }

  private async savePartnerUsers(partnerUsers: PartnerUserModel[]) {
    return Promise.all(
      partnerUsers.map(partnerUser =>
        this.store
          .createRecord('organization-promotion-partner-user-allowed-list', {
            partnerUser,
            emailAddress: partnerUser.emailAddress,
          })
          .save()
      )
    );
  }

  async setupDomain(organizationPromotion: any) {
    const domains = await this.store.query('organization-promotion-domain', {});
    const primaryDomains = domains.filterBy('isPrimary');

    // Load domains as they are async
    await Promise.all(primaryDomains.map(d => d.get('domain')));

    const primaryDomain = primaryDomains.findBy('domainType', 'Web');

    if (!primaryDomain) return;

    primaryDomain.rootFolder = organizationPromotion.name.replace(/[\s/]+/g, '-').replace(/[^a-zA-Z0-9\-_]+/g, '');

    return primaryDomain.save();
  }

  private async setupEmails(organizationPromotion: any) {
    const emails = await this.store.query('message-campaign', {
      targetOrganizationPromotionId: organizationPromotion.id,
      statusTypeId: enums.findWhere('STATUS_TYPE', { name: 'Active' }),
    });

    return Promise.all(
      emails.sortBy('messageCampaignType').map((email, index) => {
        const emailName = `${email.messageCampaignType.split(/(?=[A-Z])/).join(' ') as string} Email`;
        email.name = `${organizationPromotion.name as string} ${
          email.messageCampaignType === 'Invite' ? `${emailName} ${index + 1}` : emailName
        }`;

        return email.save();
      })
    );
  }

  private async setupMatchups(organizationPromotion: any) {
    if (!organizationPromotion.promotion.isEventSignup) return;

    const matchup = await this.store.queryRecord('matchup', {
      excludeSecondaryMatchups: true,
    });
    matchup.name = organizationPromotion.name;

    return matchup.save();
  }

  private async setupTemplates(organizationPromotion: any) {
    const designTemplateTypeId = enums.findWhere('TEMPLATE_TYPE', { name: 'Promotion' });
    const [promotionDesign] = await Promise.all([
      this.store.queryRecord('design', { designTemplateType: designTemplateTypeId }),
      this.store.query('design-template', {
        designTemplateTypeId,
      }),
      this.store.query('design-token', {}),
      this.settings.preload('dips_url'),
    ]);

    const designTokenContents = promotionDesign
      ? await this.store.query('design-token-content', { designId: promotionDesign.id })
      : [];

    const tokens = designTokenContents
      .filter(tokenContent => {
        const defaultTokenContent = tokenContent.get('designToken.defaultDesignTokenContents.firstObject');

        return (
          TOKENS_WITH_DEFAULT_VALUES.includes(tokenContent.get('designToken.key')) &&
          tokenContent.title == defaultTokenContent.title &&
          tokenContent.value == defaultTokenContent.value
        );
      })
      .map(token => this.setTokenContent(organizationPromotion, token))
      .filterBy('hasDirtyAttributes');

    if (isPresent(tokens) && promotionDesign) {
      promotionDesign.renderedContent = generateRenderedContent(
        promotionDesign,
        [{ tokenType: 'Dynamic', key: 'application' }],
        this.settings.getValueFor('dips_url')
      ).replace('{{application}}', '{{{application}}}');

      await Promise.all([...tokens.map(token => token.save()), promotionDesign.save()]);
    }
  }

  /**
   * Why is this happening on the frontend is beyond me.
   */
  private setTokenContent(organizationPromotion: any, tokenContent: any) {
    const attrs: { title?: string; value?: string } = {};
    const name = organizationPromotion.name as string;
    const key = tokenContent.get('designToken.key');

    if (['email', 'facebook'].includes(key)) {
      attrs.title = name;
      attrs.value = 'Check it out now!';
    }

    if (['twitter', 'pinterest', 'productName'].includes(key)) {
      attrs.value = name;
    }

    if (key == 'thankYouMessage') {
      if (organizationPromotion.promotion.isEventSignup) {
        attrs.value = `You have signed up! Thanks for signing up for ${name}!`;
      } else if (organizationPromotion.promotion.isPaymentForm) {
        attrs.value = `<strong>Thanks for your payment.<br><br></strong>Your payment has successfully processed.`;
      } else {
        attrs.value = `Thanks for entering ${name}!`;
      }
    }

    return tokenContent.setProperties(attrs);
  }
}
