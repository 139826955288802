/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { or } from '@ember/object/computed';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

export default BaseModel.extend({
  loginLink: DS.attr('string'),
  activeCount: DS.attr('number'),
  activeSoldCount: DS.attr('number'),
  pastCount: DS.attr('number'),
  pastSoldCount: DS.attr('number'),
  organization: DS.belongsTo('organization', {
    async: false,
  }),

  hasActiveData: or('activeCount', 'activeSoldCount'),
  hasPastData: or('pastCount', 'pastSoldCount'),
});
