/* eslint-disable ember/no-computed-properties-in-native-classes */
import { AsyncBelongsTo, AsyncHasMany, belongsTo, hasMany } from '@ember-data/model';
import { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import BaseModel from 'secondstreet-common/models/base';
import type PartnerUserModel from './partner-user';

const ADMIN_USER_CREATION_ROLE_ID = '3';

export default class PartnerUserOrganizationRoleModel extends BaseModel {
  @belongsTo('partner-user') declare partnerUser: AsyncBelongsTo<PartnerUserModel>;
  @belongsTo('organization', { async: false }) declare organization: any;
  @hasMany('role', { async: true }) declare roles: AsyncHasMany<any>;

  @alias('roles.length') declare rolesLength: number;

  // eslint-disable-next-line ember/use-brace-expansion
  @computed('roles.@each.id', 'roles.isFulfilled')
  get hasAdminUserCreationPermissions() {
    // eslint-disable-next-line ember/no-get
    const isFulfilled = get(this, 'roles.isFulfilled');
    return isEmpty(this.roles) || !isFulfilled ? false : this.roles.mapBy('id').includes(ADMIN_USER_CREATION_ROLE_ID);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'partner-user-organization-role': PartnerUserOrganizationRoleModel;
  }
}
