import { attr, belongsTo } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class PromotionProductModel extends BaseModel {
  @belongsTo('product') product;
  @attr('number') promotionId;
  @attr('number') productId;
  @attr('number') organizationId;
  @attr('number') statusTypeId;
  @attr('number') displayOrder;
  @attr('number') maximumQuantity;
  @attr('number') price;
  @attr('number') currencyId;
  @attr('date') purchaseStartDate;
  @attr('date') purchaseEndDate;
}
