import { helper } from '@ember/component/helper';

// A super naive implementation of tailwind-merge
// We should switch to it once we upgrade to ember-auto-import@2 or not
const twMerge = (baseClassNames: string, additionalClassNames: string) => {
  const baseClassNamesArray = baseClassNames.split(' ');
  const additionalClassNamesArray = additionalClassNames.split(' ');
  const additionalClassNamesPrefixes = additionalClassNamesArray.map(prefix);

  return baseClassNamesArray
    .filter(className => !additionalClassNamesPrefixes.includes(prefix(className)))
    .concat(additionalClassNamesArray)
    .join(' ');
};

const prefix = (className: string) => className.split('-').slice(0, -1).join('-');

export function twMergeHelper([baseClassNames, additionalClassNames]: [string, string?]) {
  return additionalClassNames ? twMerge(baseClassNames, additionalClassNames) : baseClassNames;
}

export default helper(twMergeHelper);
