/* eslint-disable ember/no-get */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * Organization Index Route
 * /o/:organization_id
 * @type {Ember.Route}
 */
export default class IndexRoute extends Route {
  @service router;

  /**
   * Redirect to {@link partner/routes/organizations/organization.dashboard} unless the user doesn't have access to
   * either promotions or interactive content. Otherwise, redirect to
   * {@link partner/routes/organizations/organization/data/audiences/index}
   * @see http://emberjs.com/api/classes/Ember.Route.html#method_beforeModel
   */
  redirect() {
    const organization = get(this.modelFor('organizations.organization'), 'organization');

    this.router.transitionTo(
      get(organization, 'isOnlyBlogger')
        ? 'organizations.organization.data.audiences.index'
        : 'organizations.organization.dashboard'
    );
  }
}
