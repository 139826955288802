/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { alias } from '@ember/object/computed';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, belongsTo } = DS;

export default BaseModel.extend({
  //region Properties
  value: attr('date-from-string'),
  // Yes, this is a separate property from the API as compared to the userAttributeType relationship
  userAttributeTypeId: attr('number'),
  valueName: attr('string'),
  //endregion

  //region Relationships
  organizationUser: belongsTo('organization-user', { async: false }),
  userAttributeType: belongsTo('user-attribute-type', { async: false }),
  consumerUser: belongsTo('consumer-user', { async: false }),
  //endregion

  //region ComputedProperties
  userAttributeTypeName: alias('userAttributeType.name'),
  userAttributeTypeFieldId: alias('userAttributeType.fieldId'),
  //endregion
});
