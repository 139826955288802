import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Ui::DataTable\n  @columns={{this.columns}}\n  @selectable={{false}}\n  @collection={{@ads}}\n  @sort={{this.sortParams}}\n  @onSortChange={{this.onSortChange}}\n  @isLoading={{@isLoading}}\n  class=\"overflow-y-hidden\"\n  ...attributes\n  as |Cell row|\n>\n  <Cell @label=\"Preview\" test-preview>\n    <Ballot::Ads::AdPreviewColumn @ad={{row}} />\n  </Cell>\n\n  <Cell @label=\"Advertiser(s)\" @value={{row.adName}} test-advertiser-name />\n  <Cell @label=\"Type\" @value={{row.ballotArea}} test-ad-type />\n\n  <Cell @label=\"Placement\" @value={{row.ownerEntityName}} test-placement />\n\n  <Cell @label=\"Impressions\" @value={{row.impressions}} test-ad-type />\n  <Cell @label=\"Reach\" @value={{row.reach}} test-ad-type />\n  <Cell @label=\"Clicks\" @value={{row.clicks}} test-ad-type />\n  <Cell @label=\"CTR\" @value={{concat row.ctr \"%\"}} test-ad-type />\n</Ui::DataTable>", {"contents":"<Ui::DataTable\n  @columns={{this.columns}}\n  @selectable={{false}}\n  @collection={{@ads}}\n  @sort={{this.sortParams}}\n  @onSortChange={{this.onSortChange}}\n  @isLoading={{@isLoading}}\n  class=\"overflow-y-hidden\"\n  ...attributes\n  as |Cell row|\n>\n  <Cell @label=\"Preview\" test-preview>\n    <Ballot::Ads::AdPreviewColumn @ad={{row}} />\n  </Cell>\n\n  <Cell @label=\"Advertiser(s)\" @value={{row.adName}} test-advertiser-name />\n  <Cell @label=\"Type\" @value={{row.ballotArea}} test-ad-type />\n\n  <Cell @label=\"Placement\" @value={{row.ownerEntityName}} test-placement />\n\n  <Cell @label=\"Impressions\" @value={{row.impressions}} test-ad-type />\n  <Cell @label=\"Reach\" @value={{row.reach}} test-ad-type />\n  <Cell @label=\"Clicks\" @value={{row.clicks}} test-ad-type />\n  <Cell @label=\"CTR\" @value={{concat row.ctr \"%\"}} test-ad-type />\n</Ui::DataTable>","moduleName":"partner/components/organization-promotion/ad-metrics-table.hbs","parseOptions":{"srcName":"partner/components/organization-promotion/ad-metrics-table.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import type Store from '@ember-data/store';
import type SponsoredPostModel from 'secondstreet-common/models/sponsored-post';
import { action } from '@ember/object';
import { SortParamsType } from 'partner/-base/promotion-ads-controller';

interface OrganizationPromotionAdMetricsTableSignature {
  Element: HTMLDivElement;
  Args: {
    ads: SponsoredPostModel[];
    hash: {
      allowSorting: boolean;
      sortColumn: string;
      sortDirection: 'asc' | 'desc';
      pageSize: number;
    };
    onSortChange: (params: SortParamsType) => void;
  };
}

export default class OrganizationPromotionAdMetricsTableComponent extends Component<OrganizationPromotionAdMetricsTableSignature> {
  @service declare store: Store;

  columns = [
    {
      label: 'Preview',
      width: 'minmax(min-content, 1fr)',
      tooltip: 'Hover over the preview to see all ad images for each placement. Ads with a carousel will rotate.',
    },
    {
      label: 'Advertiser(s)',
      sortable: this.args.hash.allowSorting ? 'adName' : '',
      width: 'minmax(min-content, 1fr)',
    },
    {
      label: 'Ballot Area',
      sortable: this.args.hash.allowSorting ? 'ballotArea' : '',
      width: 'minmax(min-content, 1fr)',
    },
    {
      label: 'Placement',
      sortable: this.args.hash.allowSorting ? 'ownerEntityName' : '',
      width: 'minmax(min-content, 1fr)',
    },
    {
      label: 'Impressions',
      tooltip: 'The number of times the ad was displayed',
      sortable: this.args.hash.allowSorting ? 'impressions' : '',
      width: 'minmax(min-content, 1fr)',
    },
    {
      label: 'Reach',
      tooltip: 'The number of unique known people that were reached by the ad',
      sortable: this.args.hash.allowSorting ? 'reach' : '',
      width: 'minmax(min-content, 1fr)',
    },
    {
      label: 'Clicks',
      tooltip: 'The number of clicks on the ad unit',
      sortable: this.args.hash.allowSorting ? 'clicks' : '',
      width: 'minmax(min-content, 1fr)',
    },
    {
      label: 'CTR',
      tooltip: 'The number of clicks divided by total impressions',
      sortable: this.args.hash.allowSorting ? 'ctr' : '',
      width: 'minmax(min-content, 1fr)',
    },
  ];

  get sortParams() {
    return {
      column: this.args.hash.sortColumn,
      direction: this.args.hash.sortDirection,
    };
  }

  @action
  onSortChange({ column, direction }: SortParamsType) {
    this.args.onSortChange({ column, direction });
  }
}
