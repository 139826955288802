/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import $ from 'jquery';
/**
 * Renders a table for individual message sends (Drip Campaigns) or individual instance sends (Newsletters)
 */
export default Component.extend({
  //region Attributes
  'message-campaign': null,
  'campaign-in-progress-count': null,
  'campaign-completed-count': null,
  'campaign-incomplete-count': null,
  'update-schedule-instance-page': null,
  'meta-data': null,
  'individual-sends': null,
  'individual-send-link': '',
  'schedule-start-date': null,
  'sort-object-type': '',
  //endregion

  //region Computed Properties
  showIndividualSendPaging: computed('meta-data.{pageSize,totalRecords}', function () {
    return get(this, 'meta-data.pageSize') < get(this, 'meta-data.totalRecords');
  }),

  selectedSortParameterObject: computed('sortParameterObjects', 'individualSendSortingProperty', function () {
    return this.sortParameterObjects.findBy('property', this.individualSendSortingProperty);
  }),

  sortParameterObjects: computed('message-campaign.isDripCampaign', function () {
    const dripFirstColumn = { name: 'Message #', property: 'id' };
    const newsletterFirstColumn = { name: 'Date', property: 'scheduleInstanceDate' };

    return [
      get(this, 'message-campaign.isDripCampaign') ? dripFirstColumn : newsletterFirstColumn,
      { name: 'Sent', property: 'peopleSentToCount' },
      { name: 'Received', property: 'peopleWhoReceivedCount' },
      { name: 'Opened', property: 'peopleWhoOpenedCount' },
      { name: 'Clicked', property: 'peopleWhoClickedCount' },
      { name: 'Unsubscribed', property: 'peopleWhoOptedOutCount' },
    ];
  }),

  individualSendSortingDirection: computed('message-campaign.isDripCampaign', {
    get() {
      return get(this, 'message-campaign.isDripCampaign') ? 'asc' : 'desc';
    },
    set(key, value) {
      return value;
    },
  }),

  individualSendSortingProperty: computed('message-campaign.isDripCampaign', {
    get() {
      return get(this, 'message-campaign.isDripCampaign') ? 'id' : 'scheduleInstanceDate';
    },
    set(key, value) {
      return value;
    },
  }),
  //endregion

  //region Methods
  scrollScheduleToTop() {
    const scrollTop = $(window).scrollTop();
    const offset = $('.ssMaterialTable').offset().top;
    const distance = offset - scrollTop;

    if (distance < 0) {
      window.scrollTo(0, offset);
    }
  },
  //endregion

  //region Actions
  actions: {
    linkParam(send) {
      if (get(this, 'message-campaign.isDripCampaign')) {
        return get(send, 'messageId');
      }
      return get(send, 'scheduleInstanceId');
    },
    changeScheduleInstancePage(page) {
      this['update-schedule-instance-page'](
        page,
        this['sort-object-type'],
        this.individualSendSortingProperty,
        this.individualSendSortingDirection
      );
      this.scrollScheduleToTop();
    },
    sort(property) {
      let direction;
      if (this.individualSendSortingProperty === property) {
        direction = this.individualSendSortingDirection === 'asc' ? 'desc' : 'asc';
      } else {
        direction = 'desc';
      }
      set(this, 'individualSendSortingProperty', property);
      set(this, 'individualSendSortingDirection', direction);
      this['update-schedule-instance-page'](1, this['sort-object-type'], property, direction);
    },
    getSortProperty(object) {
      // extract the property value from the passed sorting object
      this.send('sort', get(object, 'property'));
    },
  },
  //endregion
});
