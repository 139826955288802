/* eslint-disable ember/no-get */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * Message Campaign Index Route
 * /o/:organization_id/message-campaigns/:message_campaign_id/
 * @type {Ember.Route}
 */
export default class IndexRoute extends Route {
  @service router;

  redirect() {
    const { messageCampaign } = this.modelFor('organizations.organization.message-campaigns.message-campaign');
    const messageCampaignSendingStatus = get(messageCampaign, 'sendingStatusType');
    const hasSentWelcomeEmail = get(messageCampaign, 'isWelcome') && get(messageCampaign, 'peopleSentToCount') > 0;
    if (hasSentWelcomeEmail || ['Sent', 'SentAndScheduled'].includes(messageCampaignSendingStatus)) {
      this.router.transitionTo('organizations.organization.message-campaigns.message-campaign.dashboard');
    } else {
      this.router.transitionTo('organizations.organization.message-campaigns.message-campaign.setup', {
        queryParams: { 'is-editing-basics': messageCampaign.isJustCreated },
      });
    }
  }
}
