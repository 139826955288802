/* eslint-disable ember/no-get */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * Setup Index Route
 * /o/:organization_id/op/:organization_promotion_id/setup
 * @type {Ember.Route}
 * @mixes PromotionTypeRoute
 */
export default class IndexRoute extends Route {
  @service checklist;
  @service router;

  afterModel() {
    // TODO: Redirect to either the first uncompleted step, or the dashboard.
    const checklist = get(this.modelFor('organizations.organization.data.audiences.audience.setup'), 'checklist');
    const firstStep = get(checklist, 'checklistSteps').findBy('displayOrder', 1);
    const destination = this.checklist.getDestination(get(firstStep, 'checklistStepTypeId'));

    this.router.transitionTo(destination);
  }
}
