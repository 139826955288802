/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { set } from '@ember/object';
import Route from '@ember/routing/route';

export default Route.extend({
  // Region Properties
  isAnythingDirty: false,
  // endRegion

  // Region Hooks
  async model() {
    const currentOrganization = this.modelFor('organizations.organization', 'organization');
    const promotionFeedTitleSetting = this.modelFor('organizations.organization.promotions-feed').titleSetting;

    return {
      promotionFeedTitleSetting: promotionFeedTitleSetting.firstObject,
      promotionFeedLink: this.modelFor('organizations.organization.promotions-feed').promotionFeedLink,
      organization: currentOrganization.organization,
    };
  },
  // endRegion

  // region Actions
  actions: {
    willTransition(transition) {
      if (this.isAnythingDirty) {
        const isSure = window.confirm(`Are you sure you want to leave? You have unsaved changes.`);
        if (!isSure) {
          transition.abort();
        }
      }
    },
    updateIsAnythingDirty(value) {
      set(this, 'isAnythingDirty', value);
    },
    savePromotionsFeedConfiguration() {
      const { promotionFeedTitleSetting } = this.controller.model;
      return promotionFeedTitleSetting.save();
    },
  },
  // endregion
});
