/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';

/**
 * External App Route
 * /o/:organization_id/settings/external_apps/new
 */
export default Route.extend(AppContextRoute, {
  router: service(),
  store: service(),

  //region Ember Hooks
  model() {
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');
    return this.store.createRecord('external-app', {
      organizationId,
      externalAppTypeId: 4, //TODO: Convert to enum
      name: `${get(this.modelFor('organizations.organization'), 'organization.name')} Instagram App`,
    });
  },
  //endregion

  //region Methods
  save() {
    return this.currentModel.save();
  },
  //endregion

  //region Actions
  actions: {
    //If you leave the page without saving, destroy the record
    willTransition() {
      if (get(this, 'currentModel.isNew')) {
        this.currentModel.destroyRecord();
      }
    },
    save() {
      this.save()
        .then(externalApp => {
          //redirect to externalapp route
          this.router.replaceWith('organizations.organization.settings.exeternal-app', get(externalApp, 'id'));
        })
        .catch(e => {
          console.error(e);
        });
    },
  },
  //endregion
});
