/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import updateDisplayOrder from 'partner/utils/update-display-order';
import RSVP from 'rsvp';

export default Route.extend({
  store: service(),
  settings: service(),

  //region Ember Hooks
  async model() {
    const hash = await RSVP.hash({
      questions: get(
        this.modelFor('organizations.organization.organization-promotions.organization-promotion.setup.quiz'),
        'questions'
      ),
      promotion: get(
        this.modelFor('organizations.organization.organization-promotions.organization-promotion'),
        'organizationPromotion.promotion'
      ),
      _settings: this.settings.preload('dips_url'),
    });

    return {
      ...hash,
      dipsUrl: this.settings.getFor('dips_url'),
    };
  },
  //endregion

  //region Methods
  editQuestionOption(questionOption) {
    set(this, 'controller.questionOptionBeingEdited', questionOption);
  },
  isAnythingDirty() {
    return !isEmpty(
      get(this, 'controller.model.questions').filter(
        question => !!get(question, 'questionOptions').findBy('hasDirtyAttributes')
      )
    );
  },
  rollback() {
    get(this, 'controller.model.questions').forEach(question => {
      get(question, 'questionOptions')
        .filterBy('hasDirtyAttributes')
        .forEach(x => x.rollbackAttributes());
    });
  },
  //endregion

  //region Actions
  actions: {
    addQuestion() {
      const questions = get(this, 'controller.model.questions');
      const question = this.store.createRecord('question', {
        promotion: get(this, 'controller.model.promotion'),
        displayOrder: get(questions, 'length') + 1,
      });
      questions.addObject(question);
      set(this, 'controller.justCreatedQuestion', question);
    },
    addQuestionOption(question) {
      const questionOptions = get(question, 'questionOptions');
      const option = this.store.createRecord('question-option', {
        question,
        displayOrder: get(questionOptions, 'length') + 1,
      });
      questionOptions.addObject(option);
      set(this, 'controller.questionOptionBeingEdited', option);
    },
    editQuestionOption(questionOption) {
      this.editQuestionOption(questionOption);
    },
    async saveQuestionOption(questionOption) {
      set(this, 'controller.currentlySaving', true);
      try {
        (await get(questionOption, 'hasDirtyAttributes')) ? questionOption.save() : RSVP.resolve(questionOption);
        set(this, 'controller.questionOptionBeingEdited', null);
        this.send('updateCheckboxStatuses');
      } finally {
        set(this, 'controller.currentlySaving', false);
      }
    },
    async saveQuestion(question) {
      set(this, 'controller.currentlySaving', true);
      try {
        (await get(question, 'hasDirtyAttributes')) ? question.save() : RSVP.resolve(question);
        this.send('updateCheckboxStatuses');
      } finally {
        set(this, 'controller.currentlySaving', false);
      }
    },
    async removeQuestion(question) {
      const removedDisplayOrder = get(question, 'displayOrder');
      await RSVP.all(
        updateDisplayOrder(get(this, 'controller.model.questions'), removedDisplayOrder).map(x => x.save())
      );

      get(this, 'controller.model.questions').removeObject(question);
      if (get(question, 'questionOptions')) {
        await RSVP.all(get(question, 'questionOptions').map(x => x.destroyRecord()));
        await question.destroyRecord();
      } else {
        await question.destroyRecord();
      }

      this.send('updateCheckboxStatuses');
    },
    async removeQuestionOption(question, questionOption) {
      this.editQuestionOption(null);
      get(question, 'questionOptions').removeObject(questionOption);
      const removedDisplayOrder = get(questionOption, 'displayOrder');
      await RSVP.all(updateDisplayOrder(get(question, 'questionOptions'), removedDisplayOrder).map(x => x.save()));
      await questionOption.destroyRecord();
      this.send('updateCheckboxStatuses');
    },
    reorderQuestions(questions) {
      questions.forEach((question, index) => {
        set(question, 'displayOrder', index + 1);
        if (get(question, 'hasDirtyAttributes')) {
          question.save();
        }
      });
    },
    /**
     * Clear out properties when question toggle happens
     */
    questionToggled() {
      set(this, 'controller.questionOptionBeingEdited', null);
    },
    willTransition(transition) {
      const confirmed = !this.isAnythingDirty()
        ? true
        : window.confirm('Are you sure you want to leave without saving?');
      if (confirmed) {
        this.rollback();
        this._super(transition);
      } else {
        transition.abort();
      }
    },
  },
  //endregion
});
