import { get } from '@ember/object';
import enums from 'ember-cli-ss-enums/services/enums';
import moment from 'moment';
import { DAYS_IN_WEEK } from 'partner/utils/recurring-pattern';

export const makePresets = baseDate => {
  const typeId = name => enums.findWhere('DATEPART_TYPE', { name });
  const base = moment(baseDate);
  const baseDayCode = moment.weekdaysShort()[base.day()].toUpperCase();
  return {
    Daily: {
      'type-id': typeId('Daily'),
      pattern: '',
      delay: 1,
    },
    Weekly: {
      'type-id': typeId('Weekly'),
      pattern: baseDayCode,
      delay: 1,
    },
    EveryMinute: {
      'type-id': typeId('EveryMinute'),
      pattern: '',
      delay: 30,
    },
    WeeklyDays: {
      'type-id': typeId('Weekly'),
      pattern: baseDayCode,
      'pattern-match': /^[A-Z]{3}(,[A-Z]{3})+$/,
      delay: 1,
    },
    MonthlyDate: {
      'type-id': typeId('Monthly'),
      pattern: `${base.date()}`,
      delay: 1,
    },
    MonthlyWeekday: {
      'type-id': typeId('Monthly'),
      pattern: `${Math.ceil(base.date() / DAYS_IN_WEEK)}${baseDayCode}`,
      delay: 1,
    },
    Yearly: {
      'type-id': typeId('Yearly'),
      pattern: `${moment.monthsShort()[base.month()].toUpperCase()}${base.date()}`,
      delay: 1,
    },
  };
};

export const findPreset = (presets, typeId, pattern, delay) =>
  Object.keys(presets).find(key => {
    const patternMatch = get(presets, `${key}.pattern-match`);
    return (
      get(presets, `${key}.type-id`) === typeId &&
      (typeId === 2 || get(presets, `${key}.delay`) === delay) &&
      (patternMatch ? pattern.match(patternMatch) : get(presets, `${key}.pattern`) === pattern)
    );
  });

export default {
  makePresets,
  findPreset,
};
