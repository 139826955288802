/* eslint-disable ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import moment from 'moment';

const { Transform } = DS;

export default Transform.extend({
  /**
   * @param {String} str
   * @returns {Date}
   */
  deserialize: str => (str ? moment(str).local().toDate() : undefined),

  /**
   * @param {Date} date
   * @returns {String}
   */
  serialize: date => (date ? moment(date).local().format('YYYY-MM-DD') : undefined),
});
