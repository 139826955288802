/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import type { Model } from 'partner/routes/organizations/organization/legal/design-templates/design-tokens';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';

export default class LegalDesignTokensController extends Controller {
  @service declare enums: EnumsService;

  @tracked isCreatingSnippet = false;
  @tracked activeToken?: any;

  declare model: Model;

  @computed('enums', 'model.designTokens.[]')
  get designTokens() {
    return this.model.designTokens.filter(
      (token: { isPageContentToken: boolean; tokenType: string; statusTypeId: number }) =>
        token.isPageContentToken && token.tokenType == 'Template'
    );
  }

  get dynamicDesignTokens() {
    return this.model.designTokens.filterBy('tokenType', 'Dynamic').sortBy('name');
  }

  @action
  reset() {
    this.isCreatingSnippet = false;
    this.activeToken = undefined;
  }

  @action
  addToken(designToken: any) {
    this.model.designTokens.addObject(designToken);
  }
}
