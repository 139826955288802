/**
 * Determines the correct matchup to show the user based on the current state of the contest.
 * Prefer the current matchup. If we're between rounds, prefer the soonest to begin. If we're after the contest, simply show the last.
 * @param matchups {Matchup[]}
 * @returns {Matchup}
 */
export default function mostCurrentMatchup(matchups) {
  const today = new Date();
  const sortedMatchups = matchups.sortBy('startDate');
  const currentMatchup = sortedMatchups.find(matchup => today >= matchup.startDate && today <= matchup.endDate);
  if (currentMatchup) {
    return currentMatchup;
  }
  const nextMatchup = sortedMatchups.find(matchup => today < matchup.startDate);
  if (nextMatchup) {
    return nextMatchup;
  }
  return sortedMatchups.reverse().lastObject;
}
