/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
/* eslint-disable ember/use-ember-get-and-set */ // FIXME
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import SsPermissionsBlockComponent from 'partner/components/ss-permissions-block';

/**
 * Base class for custom Ember.View classes, or subclasses of View like Ember.Components,
 * that need to be aware of the current "permission block" status.
 *
 * IMPORTANT! If a PermittedComponent is used OUTSIDE of a PermissionsBlockComponent then both
 * administer and view will be set to true. This allows any component that uses this mixin to still work
 * independently of a permissions block.
 *
 * Example usage:
 *   {{#ss-permissions-block entities='MatchupPlace,MatchupLogic'}}
 *      //ss-input will respect permissions from the parent block
 *      {{ss-input value='foo.bar'}}
 *      //ss-remove will only show up if user has administer permission
 *      {{ss-remove action=this.someAction text='Remove Thing'}}
 *   {{/ss-permission-block}}
 *
 * @see Promotion.PermissionService
 * @see Promotion.SsPermissionsBlockComponent
 * @type {Ember.Mixin}
 */
export default Mixin.create({
  ancestorPermissionsBlockComponent: null,
  /**
   * TODO: rename this to disabled-by-permissions so that it doesn't conflict with other ways a component could be disabled
   */
  disabled: null,
  //region Computed Properties
  _administer: null,
  administer: computed('ancestorPermissionsBlockComponent.administer', '_administer', {
    get() {
      if (this._administer !== null) {
        return this._administer;
      }
      return !this.ancestorPermissionsBlockComponent || !!this.get('ancestorPermissionsBlockComponent.administer');
    },
    set(_key, value) {
      this.set('_administer', value);
      return value;
    },
  }),
  _view: null,
  view: computed('ancestorPermissionsBlockComponent.canView', '_view', {
    get() {
      if (this._view !== null) {
        return this._view;
      }
      return !this.ancestorPermissionsBlockComponent || !!this.get('ancestorPermissionsBlockComponent.canView');
    },
    set(_key, value) {
      this.set('_view', value);
      return value;
    },
  }),
  //endregion

  //region Hooks
  init() {
    this._super(...arguments);
    this.set('ancestorPermissionsBlockComponent', this.findAncestorPermissionComponent(this));
    if (this.disabled === null) {
      this.disabled = !this.administer;
    }
  },
  //endregion

  //region Methods
  /**
   * Find the closest ancestor that is an instance of Promotion.SsPermissionsBlockComponent,
   * which contains the permission state for the current "permission block"

   * @param {Ember.Component|Ember.View|Promotion.PermittedComponent} view (or subclass of any of those)
   * @returns {Promotion.SsPermissionsBlockComponent|boolean} false if certain ancestor not found
   */
  findAncestorPermissionComponent(view) {
    const parent = view.get('parentView');
    if (!parent) {
      return false;
    }
    if (parent instanceof SsPermissionsBlockComponent) {
      return parent;
    }
    return this.findAncestorPermissionComponent(parent);
  },
  //endregion
});
