/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

export default Mixin.create({
  features: service(),
  classNames: 'form-designer-form-field-contents',

  //region Attributes
  'dips-url': null,
  //endregion
});
