export default ({ message, isQuizTrivia, primaryColor }) => `
	<div class="bg-white font-['Open_Sans']">
		<div class="min-h-[44px] template-color-primary-background-color text-primary-text text-2xl flex flex-col items-center justify-center gap-2">
			Result
		</div>
		<div class="mx-2">
			<div class="max-w-[860px] mx-auto p-6 border border-solid border-gray-100 rounded gap-4 my-6">
				<div class="flex gap-4 flex-col">
					<span class="text-center text-[28px] font-bold text-black">
						Outcome Name
					</span>
					<img class="max-w-full rounded" src="https://media.secondstreetapp.com/250194?width=1170&height=1170&quality=60" />
					<p class="ssOutcomeDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eu eleifend lectus, congue blandit quam. Ut pretium, felis sit amet rhoncus finibus.</p>
				</div>
			</div>
			${
        message
          ? `<div class="max-w-[860px] mx-auto p-6 border border-solid border-gray-100 rounded gap-4 my-6">
			${message}
				</div>`
          : ''
      }

  <div class="max-w-md rounded mx-auto flex gap-1 border border-solid template-color-primary-border-color p-4 items-center flex-row" style="background-color: ${primaryColor}10;" >
	<span class="text-lg text-black font-semibold">
		${isQuizTrivia ? 'You got 1 out of 3 correct!' : 'Not the result you expected?'}
	</span>

	<div class="flex-auto"></div>
	<div class="text-black flex items-center gap-2 h-11">
		<span class="material-icons">
			cached
		</span>
		<span class="uppercase flex-shrink-0">
			Retake
		</span>
	</div>
	</div>
</div>

  	<div class="flex flex-col gap-3 p-6 items-center justify-center">
	  <span class="text-lg text-black font-semibold flex-shrink-0">
        Share your result and see what your friends get!
      </span>
		<ul class="ssShareListCompact ssShareList">
			<li class="ssShareListItem">
			<a class="ssShareLink ssShareLinkFacebook" href="#" >
			<i class="ssIcon-facebook"></i>
			<span class="ssShareLinkText">Share</span>
			</a>
			</li>
			<li class="ssShareListItem">
			<a class="ssShareLink ssShareLinkTwitter" href="#">
				<i class="ssIcon-twitter"></i>
				<span class="ssShareLinkText">Tweet</span>
			</a>
			</li>
			<li class="ssShareListItem">
			<a class="ssShareLink ssShareLinkEmail" href="#">
				<i class="ssIcon-envelope"></i>
				<span class="ssShareLinkText">Email</span>
			</a>
			</li>
			<li class="ssShareListItem">
			<a class="ssShareLink ssShareLinkPinterest" href="#">
				<i class="ssIcon-pinterest"></i>
				<span class="ssShareLinkText">Pin It</span>
			</a>
			</li>
		</ul>
	</div>
  </div>
`;
