/* eslint-disable @typescript-eslint/restrict-template-expressions */
import type Store from '@ember-data/store';
import Route from '@ember/routing/route';
import Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import Arc from '@ss/arc/arc/arc';
import TemplateCustomizationController from 'partner/controllers/organizations/organization/organization-promotions/organization-promotion/setup/designs/current';
import MatchupModel from 'partner/models/matchup';
import type CurrentService from 'partner/services/current';
import type SettingsService from 'partner/services/settings';
import { unloadAllRecords } from 'partner/utils/store-unload';
import { findSuitableThemes } from 'partner/utils/themes';
import type { ModelFrom } from 'partner/utils/types';
import RSVP from 'rsvp';

export type Model = ModelFrom<SetupDesignTemplateCustomizationRoute>;

const ORGANIZATION_PROMOTION_SETUP_ROUTE =
  'organizations.organization.organization-promotions.organization-promotion.setup';

const ORGANIZATION_PROMOTION_SETUP_DESIGNS_ROUTE =
  'organizations.organization.organization-promotions.organization-promotion.setup.designs';

export default class SetupDesignTemplateCustomizationRoute extends Route {
  @service declare settings: SettingsService;
  @service declare current: CurrentService;
  @service declare store: Store;

  beforeModel(transition: Transition) {
    void super.beforeModel(transition);
    unloadAllRecords(this.store, 'theme');
  }

  async model() {
    const setupModel = this.modelFor(ORGANIZATION_PROMOTION_SETUP_ROUTE) as { matchups: MatchupModel[] };
    const setupDesignsModel = this.modelFor(ORGANIZATION_PROMOTION_SETUP_DESIGNS_ROUTE) as { design: any };

    const { design } = setupDesignsModel;

    return RSVP.hash({
      _settingsDipsUrl: this.settings.preload('dips_url'),
      _settingsNewDesignTemplate: this.settings.preload(['new_design_template', 'registration_type'], { force: true }),
      design,
      designTemplate: design.get('designTemplate'),
      themes: this.store.query('theme', { reload: true }).then(x => x.toArray()),
      matchups: this.store.query('matchup', { excludeSecondaryMatchups: true }).then(x => x.toArray()),
      matchupGroups: this.current.promotion.isBallot
        ? this.store.query('matchup-group', {}).then(x => x.toArray())
        : [],
      designTokens: this.store.query('design-token', {}).then(x => x.toArray()),
      questions: this.current.promotion.isPoll ? this.store.query('question', {}).then(x => x.toArray()) : [],
      designTokenContents: design
        ? this.store.query('design-token-content', { designId: design.id }).then(x => x.toArray())
        : [],
      useOldTemplateSetting: this.store.queryRecord('setting', { key: 'use_old_design_templates' }),
      sweepstakes: this.store.queryRecord('sweepstakes', this.current.toParams()),
    });
  }

  setupController(controller: TemplateCustomizationController, model: Model, transition: Transition): void {
    super.setupController(controller, model, transition);

    if (controller.views.firstObject) {
      controller.selectedView = controller.views.firstObject;
    }

    if (model.design?.tokenContents) {
      controller.facebookToken = model.design.tokenContents.find(
        (tokenContent: any) => tokenContent.token.tokenContentType == 'Facebook'
      );

      controller.xToken = model.design.tokenContents.find(
        (tokenContent: any) => tokenContent.token.tokenContentType == 'Twitter'
      );

      controller.emailToken = model.design.tokenContents.find(
        (tokenContent: any) => tokenContent.token.tokenContentType == 'Email'
      );

      controller.galleryLayoutToken = model.design.tokenContents.find(
        ({ tokenCategory }: { tokenCategory: string }) => tokenCategory == 'Gallery Layout'
      );

      controller.thanksToken = model.design.tokenContents.find(
        ({ tokenCategory }: { tokenCategory: string }) => tokenCategory == 'Thank You Message'
      );
    }

    controller.deviceType = 'desktop';
    controller.selectedLibraryTab = 'elements';
    controller.selectedLibrarySettingsTab = 'attributes';
    controller.showPreview = false;
    controller.expandedSections = [];
    controller.refresh = new Date();
    controller.showSaveTemplateModal = false;
    controller.showSwitchTemplateModal = false;
    controller.isSaveDirty = false;
    controller.error = '';
    controller.selectedTheme = undefined;
    controller.blockToInject = undefined;

    if (model.design) {
      controller.content = JSON.parse(model.design.renderedContent as string);
    }

    controller.arc = new Arc(controller.content);
    controller.arc.setCallback(() => controller.saveTemplateTask.perform());
    controller.selectedTheme = findSuitableThemes(model.themes, this.current).firstObject;
    controller.changePromotionView();
  }
}
