/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import InheritableModel from 'partner/-base/inheritable-model';

const { attr } = DS;

export default InheritableModel.extend({
  //region Properties
  organizationId: attr('number'),
  isScrape: attr('boolean'),
  content: attr('string'),
  contentUrl: attr('string'),
  //endregion
});
