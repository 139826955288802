/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */ // FIXME
import Component from '@ember/component';
import { computed, get, observer, set } from '@ember/object';
import { not } from '@ember/object/computed';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import duration from 'secondstreet-common/utils/duration';

export default Component.extend({
  //region Ember Hooks
  classNames: ['dashboard-card'],
  classNameBindings: ['empty-state-condition:empty-state', 'noTemplate:no-content'],
  screen: service(),
  permissions: service(),
  didRender() {
    this._super(...arguments);
    set(this, 'hasRendered', true);
    // unconsumed properties do not trigger observers
    // so i am tricking ember in thinking that i am using
    // screen.width and hasRendered so the
    // observer, heightChanged, can track them
    get(this, 'screen.width');
  },
  //endregion

  //region Attributes
  'report-download-text': 'Download',
  'use-multiple-round-modal': false,
  'paper-entries-type': null,
  'report-permissions-entities': null,
  'third-button-permissions-entities': null,
  /**
   * Inform the dashboard page that you're about to import either votes or nominations
   * @type Function
   * @param {string} type
   */
  'start-importing-entries': type => type,
  //endregion

  //region Properties
  hasRendered: false,
  //endregion

  //region Computed Properties
  hasReportPermissionsEntities: computed('report-permissions-entities', function () {
    const entities = this['report-permissions-entities'] || '';
    const accessLevel = this.permissions.getAccessLevel(entities);
    return isPresent(entities) && accessLevel.view;
  }),
  hasViewPermissionsEntities: computed('view-permissions-entities', function () {
    const entities = this['view-permissions-entities'] || '';
    const accessLevel = this.permissions.getAccessLevel(entities);
    return isPresent(entities) && accessLevel.view;
  }),
  hasThirdButtonPermissionsEntities: computed('third-button-permissions-entities', function () {
    const entities = this['third-button-permissions-entities'] || '';
    const accessLevel = this.permissions.getAccessLevel(entities);
    return isPresent(entities) && (accessLevel.administer || accessLevel.view);
  }),
  noTemplate: not('template'),
  showTitleCount: computed('title-count', function () {
    return this['title-count'] !== undefined;
  }),
  //endregion

  //region Observers
  heightChanged: observer('screen.width', 'hasRendered', 'height-changed', function () {
    debounce(
      this,
      () => {
        if (this['height-changed']) {
          this['height-changed'](this.element.offsetHeight);
        }
      },
      duration(1000)
    );
  }),
  //endregion

  //region Actions
  actions: {
    reportDownloadAction(reportType, matchupId, fieldId) {
      set(this, 'isPreparingDownload', true);
      const action = this['report-download-action'];
      const onSuccess = () => {
        set(this, 'isPreparingDownload', false);
      };
      action(reportType, matchupId, fieldId).then(onSuccess);
    },
    showMultipleRoundEntriesModal() {
      this['multiple-round-modal-action']();
    },
  },
  //endregion
});
