/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { belongsTo } = DS;

export default BaseModel.extend({
  //region Relationships
  organization: belongsTo('organization'),
  organizationPromotion: belongsTo('organization-promotion'),
  optinField: belongsTo('field'),
  sharedDataField: belongsTo('field', { async: false }),
  //endregion
});
