/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
/* eslint-disable ember/use-ember-get-and-set */ // FIXME
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';
import InheritableModel from 'partner/-base/inheritable-model';
import Copyable from 'partner/mixins/copyable-model';

/**
 * Represents the value for a certain element type for a certain ownerEntity (org, promo, orgPromo).
 * The value can be either in elementContent OR mediaItemId.
 * @see {partner/models/template-element-type}
 * @mixes {partner/mixins/inheritable-model}
 * @type {DS.Model}
 */
export default InheritableModel.extend(Copyable, {
  //region Properties
  elementContent: DS.attr('string'),
  languageId: DS.attr('number'),
  mediaItemId: DS.attr('number'),
  audienceId: DS.attr('number'),
  //endregion

  //region Relationships
  templateElementType: DS.belongsTo('template-element-type', { async: false }),
  //endregion

  templateElementCategory: alias('templateElementType.category'),
  templateElementSortOrder: alias('templateElementType.sortOrder'),

  //region Computed Properties
  /**
   * @type {Ember.ComputedProperty}
   * @returns {Boolean}
   */
  checked: computed('isInherited', 'hasDirtyAttributes', function () {
    return !this.isInherited || this.hasDirtyAttributes;
  }),
  /**
   * Determines whether the design-element is currently
   * enabled or disabled
   * @type {Ember.ComputedProperty}
   * @returns {Boolean}
   */
  isDisabled: computed('elementContent', 'mediaItemId', 'templateElementType.usesJson', {
    get() {
      return (
        (isEmpty(this.elementContent) && isEmpty(this.mediaItemId)) ||
        (this.get('templateElementType.usesJson') && !this.chartVisible)
      );
    },
    set(key, value) {
      //If you are disabling... (setting to true)
      if (value) {
        if (this.get('templateElementType.usesJson')) {
          this.set('chartVisible', false);
        } else {
          if (this.get('templateElementType.usesMediaItemId')) {
            this.set('mediaItemId', null);
          } else {
            this.set('elementContent', '');
          }
        }
      } else {
        //If you are enabling... (setting to false)
        if (this.get('templateElementType.usesJson')) {
          this.set('chartVisible', true);
        }
      }

      return value;
    },
  }),
  chartVisible: computed('elementContent', 'templateElementType.usesJson', {
    get() {
      if (this.get('templateElementType.usesJson')) {
        return JSON.parse(this.elementContent).isVisible;
      }
      return;
    },
    set(key, value) {
      this._setElementContentJson(value, 'isVisible');
      return value;
    },
  }),
  chartLabel: computed('elementContent', 'templateElementType.usesJson', {
    get() {
      if (this.get('templateElementType.usesJson')) {
        return JSON.parse(this.elementContent).label;
      }
      return;
    },
    set(key, value) {
      this._setElementContentJson(value, 'label');
      return value;
    },
  }),
  allowDisable: computed('templateElementType.name', function () {
    return this.templateElementType.name !== 'Color';
  }),
  //endregion

  //region Methods
  _setElementContentJson(value, property) {
    const jsonObj = JSON.parse(this.elementContent);
    jsonObj[property] = value;
    this.set('elementContent', JSON.stringify(jsonObj));
  },
  //endregion
});
