/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ParamsType } from 'partner/services/preview';
import { statusText } from './ballot-group';
import { allEntrants, featuredEntrants } from './ballot-wide-scroll';
import { header } from './common';

const allGroups = (params?: { justCategories: boolean }) => {
  return {
    uniqueTypeNumber: '11',
    deletable: true,
    type: 'promotion-heading',
    blocks: [],
    data: {
      level: 1,
      text: params?.justCategories ? 'Categories' : '<i class="material-icons">home</i>   All Groups',
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-11 {\n border-bottom: 1px solid #e2e2e2; margin-top:16px; display:flex; align-items: center; gap:16px; \n  }\n  \n  `,
    },
    styles: {
      font: {
        color: '#000000',
        weight: 400,
        family: 'Open Sans',
        size: '16',
        align: 'center',
      },
      size: {
        custom: true,
        height: 48,
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const expandedGroup = () => {
  return {
    uniqueTypeNumber: '12',
    deletable: true,
    type: 'promotion-heading',
    blocks: [],
    data: {
      level: 1,
      text: '<i class="material-icons text-primary">keyboard_arrow_down</i> Group 1',
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-12 {\n display:flex; align-items: center; gap:6px; \n  }\n  \n  `,
    },
    styles: {
      font: {
        color: '#2574db',
        weight: 400,
        family: 'Open Sans',
        size: '15',
        align: 'center',
      },
      size: {
        custom: true,
        height: 46,
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const collapsedGroup = () => {
  return {
    uniqueTypeNumber: '13',
    deletable: true,
    type: 'promotion-heading',
    blocks: [],
    data: {
      level: 1,
      text: '<i class="material-icons">keyboard_arrow_right</i> Group 2',
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-13 {\n display:flex; align-items: center; gap:6px; \n  }\n  \n  `,
    },
    styles: {
      font: {
        color: '#000000',
        weight: 400,
        family: 'Open Sans',
        size: '15',
        align: 'center',
      },
      size: {
        custom: true,
        height: 46,
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const category = (i?: number) => {
  return {
    uniqueTypeNumber: `14${i}`,
    deletable: true,
    type: 'promotion-heading',
    blocks: [],
    data: {
      level: 1,
      text: 'Category',
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-heading-promotion-heading-14${i} {\n display:flex; align-items: center; gap:16px; \n  }\n
      \n  `,
    },
    styles: {
      font: {
        color: i == 0 ? '#2574db' : '#000000',
        weight: 400,
        family: 'Open Sans',
        size: '13',
        align: 'center',
      },
      size: {
        custom: true,
        height: 38,
      },
      spacing: {
        paddingInline: 32,
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

export default (params?: ParamsType) => {
  return [
    header({
      title: 'Already Voted?',
      promotionColor: params?.promotionColor,
      promotionTextColor: params?.promotionTextColor,
    }),
    {
      uniqueTypeNumber: '3',
      deletable: true,
      type: 'promotion-box',
      blocks: [
        {
          uniqueTypeNumber: '4',
          deletable: true,
          type: 'promotion-box',
          blocks:
            params?.hasCategories || params?.hasGroups
              ? [
                  allGroups({ justCategories: !params?.hasGroups }),
                  ...(params?.hasGroups ? [expandedGroup()] : []),
                  ...(params?.hasCategories
                    ? [category(0), category(1), category(2), category(3), category(4), category(5)]
                    : []),

                  ...(params?.hasGroups ? [collapsedGroup()] : []),
                ]
              : [],
          data: {
            additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-4 {\n  ${
              params?.isMobile || (!params?.hasCategories && !params?.hasGroups) ? 'display:none;' : ''
            } width:30%; flex-shrink:0; \n  }\n  \n  `,
          },
          styles: {
            layout: {
              display: 'flex',
              direction: 'column',
            },
            spacing: {},
          },
          dataMobile: {
            additionalCss:
              '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-4 {\n display:none; \n  }\n  \n  ',
          },
          stylesMobile: {},
          dataTablet: {},
          stylesTablet: {},
        },
        {
          uniqueTypeNumber: '5',
          deletable: true,
          type: 'promotion-box',
          blocks: [
            statusText({
              marginTop: 16,
            }),
            {
              uniqueTypeNumber: '26',
              deletable: true,
              type: 'promotion-box',
              blocks: [
                ...(params?.hasCategories
                  ? [
                      {
                        uniqueTypeNumber: '23',
                        deletable: true,
                        type: 'promotion-heading',
                        blocks: [],
                        data: {
                          level: 1,
                          text: 'Category',
                          additionalCss: ``,
                        },
                        styles: {
                          font: {
                            color: '#000000',
                            weight: 600,
                            family: 'Open Sans',
                            size: '16',
                          },
                        },
                        dataMobile: {},
                        stylesMobile: {},
                        dataTablet: {},
                        stylesTablet: {},
                      },
                    ]
                  : []),
                featuredEntrants({ narrowView: true }),
                allEntrants({ narrowView: true }),
              ],
              data: {
                additionalCss: '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-26 {\n \n  }\n  \n  ',
              },
              styles: {
                layout: {
                  display: 'flex',
                  direction: 'column',
                  gapX: 20,
                  gapY: 20,
                },
                spacing: {
                  paddingInline: 16,
                  paddingBlock: 16,
                },
                background: {
                  type: 'color',
                  color: '#ffffff',
                },
                border: {
                  radius: 4,
                },
              },
              dataMobile: {},
              stylesMobile: {},
              dataTablet: {},
              stylesTablet: {},
            },
          ],
          data: {
            additionalCss: `.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-5 {\n flex-shrink:0; ${
              params?.isMobile || (!params?.hasCategories && !params?.hasGroups) ? 'width:100%;' : 'width:70%;'
            } \n  }\n  \n  `,
          },
          styles: {
            layout: {
              display: 'flex',
              direction: 'column',
            },
            spacing: {},
          },
          dataMobile: {
            additionalCss:
              '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-5 {\n flex-shrink:0; width:100%; \n  }\n  \n  ',
          },
          stylesMobile: {},
          dataTablet: {},
          stylesTablet: {},
        },
      ],
      data: {
        additionalCss: '.arc-promotion-promotion-0 .arc-box-box-3 {\n  \n  }\n  \n  ',
      },
      styles: {
        layout: {
          display: 'flex',
          direction: 'row',
        },
        spacing: {
          paddingInline: 16,
        },
        background: {
          type: 'color',
          color: '#eeeeee',
        },
      },
      dataMobile: {},
      stylesMobile: {},
      dataTablet: {},
      stylesTablet: {},
    },
  ];
};
