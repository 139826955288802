/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { assert } from '@ember/debug';
import { get, set, setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import enums from 'ember-cli-ss-enums/services/enums';
import { task } from 'ember-concurrency';
import AppContextRoute from 'partner/mixins/app-context-route';
import findMatchupPlacingCriteria from 'partner/utils/find-matchup-placing-criteria';
import { createMatchupGroupViews } from 'partner/utils/matchup-group-views';
import RSVP from 'rsvp';
import { alphabeticalByProperty } from 'secondstreet-common/utils/sorting';

/**
 * @class
 * @property {Matchup} selectedMatchup
 * @property {Matchup[]} matchups
 * @property {OrganizationPromotion} organizationPromotion
 * @property {MatchupEntryRank[]} matchupEntryRanks
 * @property {MatchupPlace} matchupPlace
 * @property {Form} registrationForm
 * @property {String} dipsUrl
 */
class WinnersModel {
  constructor(options) {
    setProperties(this, options);
  }
}

/**
 * /o/:organization_id/op/:organization_promotion_id/winners
 */
export default Route.extend(AppContextRoute, {
  enums: service(),
  router: service(),
  snackbar: service(),
  store: service(),
  permissions: service(),
  current: service(),
  settings: service(),

  queryParams: {
    matchupId: { refreshModel: true },
  },

  beforeModel() {
    if (!this.permissions.getAccessLevel('MatchupEntryRank').administer) {
      this.router.replaceWith('organizations.organization.organization-promotions.organization-promotion.dashboard');
    }
  },

  /**
   * @returns {Promise} - Resolves with a {@link WinnersRouteModel}
   */
  model(params) {
    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');
    const organizationPromotionModel = this.modelFor(
      'organizations.organization.organization-promotions.organization-promotion'
    );

    const organizationPromotion = get(organizationPromotionModel, 'organizationPromotion');

    const organizationPromotionId = get(organizationPromotion, 'id');
    const { promotionId } = organizationPromotion;

    return this.findMatchups(organizationId, organizationPromotionId, params)
      .then(async matchups => {
        const formTypeId = this.enums.findWhere('FORM_TYPE', {
          name: 'Registration',
        });
        const matchupPlacingCriteriaId = params.secondaryMatchup
          ? findMatchupPlacingCriteria('Sweepstakes')
          : findMatchupPlacingCriteria(get(organizationPromotion, 'promotion.promotionTypeName'));

        const selectedMatchup = params.matchupId
          ? matchups.findBy('id', params.matchupId)
          : this.relevantMatchupFrom(matchups, get(organizationPromotion, 'matchupsAsCategories'));
        assert(
          'No matchup found, you are likely viewing this page on a promotion that cannot have winners.',
          selectedMatchup
        );
        const matchupId = get(selectedMatchup, 'id');
        this.matchupEntryRanksParams = {
          matchupId,
          includeLosers: organizationPromotion.promotion.isBallot && !params.secondaryMatchup,
          ...this.current.toSyndicatedParams(),
        };

        const hash = await RSVP.hash({
          isSecondaryMatchup: params.secondaryMatchup,
          selectedMatchup,
          matchups: get(organizationPromotion, 'matchupsAsCategories')
            ? matchups.sortBy('displayOrder')
            : matchups.sortBy('startDate').map(matchup => {
                set(matchup, '_dynamicRoundName', `Round ${matchup.iteration}`);
                return matchup;
              }),
          matchupGroups: this.store
            .query('matchup-group', { organizationId, organizationPromotionId })
            .then(x => x.toArray().sortBy('displayOrder')),
          ballotMatchupSummaries: this.store.query('ballot-matchup-summary', {
            organizationPromotionId,
          }),
          matchupEntryRanks: this.store
            .query('matchupEntryRank', this.matchupEntryRanksParams)
            .then(x => x.sortBy('rank')),
          matchupEntries:
            organizationPromotion.promotion.isBallot && !params.secondaryMatchup
              ? this.store.query('matchupEntry', {
                  matchupId,
                  organizationId,
                  ...(organizationPromotion.hasSyndicates ? { promotionId } : { organizationPromotionId }),
                })
              : [],
          matchupPlace: this.store
            .query('matchupPlace', {
              matchupPlacingCriteriaId,
              organizationId,
              organizationPromotionId,
            })
            .then(x =>
              // For ballots, there can be more than one matchup place so we want to store all of them
              get(organizationPromotion, 'promotion.isBallot')
                ? x.toArray().sortBy('endRank')
                : x.findBy('startRank', 1)
            ),
          winnerSetting: this.store.queryRecord('setting', {
            key: 'pick_individual_winners',
            ownerEntityTypeId: this.enums.findWhere('ENTITY_TYPE', {
              name: 'Promotion',
            }),
            ownerEntityId: organizationPromotion.promotionId,
            targetEntityTypeId: this.enums.findWhere('ENTITY_TYPE', {
              name: 'Matchup',
            }),
            targetEntityId: selectedMatchup.id,
          }),
          registrationForm: this.store
            .query('form', {
              organizationId,
              organizationPromotionId,
              formTypeId,
            })
            .then(x => get(x.sortBy('isInherited'), 'lastObject')),
          _settings: this.settings.preload(['dips_url', 'category_sort_criteria']),
          organizationPromotion,
          promotionWinnerRestrictionsSetting: this.store.queryRecord('setting', {
            key: 'Random_Drawing_Winner_Ineligibility_Days',
            organizationId,
            organizationPromotionId,
          }),
          entryForm: this.fetchEntryForm(),
          sharingDesignTokenContents: this.fetchSharingDesignTokenContents(),
        });

        return {
          ...hash,
          dipsUrl: this.settings.getFor('dips_url'),
        };
      })
      .then(x => new WinnersModel(x));
  },

  setupController(controller, model) {
    const { ballotMatchupSummaries, organizationPromotion } = model;
    const sortedSummaries =
      this.settings.getValueFor('category_sort_criteria') == '1'
        ? ballotMatchupSummaries.toArray().sort(alphabeticalByProperty('name'))
        : ballotMatchupSummaries.toArray().sortBy('displayOrder');
    if (organizationPromotion.promotion.isBallot) {
      set(controller, 'allMatchupGroupViews', createMatchupGroupViews(sortedSummaries, controller.matchupId));
    }

    return this._super(...arguments);
  },

  redirect(model, transition) {
    if (!model.isSecondaryMatchup) {
      const firstMatchup = get(
        get(model, 'matchups').sortBy('matchupGroup.displayOrder', 'displayOrder'),
        'firstObject'
      );
      const matchupId = transition.to.queryParams.matchupId || get(firstMatchup, 'id');
      return this.router.transitionTo({ queryParams: { matchupId } });
    }
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      const model = this.modelFor('organizations.organization.organization-promotions.organization-promotion.winners');
      set(
        model,
        'selectedMatchup',
        this.relevantMatchupFrom(get(model, 'matchups'), get(model, 'organizationPromotion.matchupsAsCategories'))
      );
      set(controller, 'secondaryMatchup', null);
      set(controller, 'matchupId', null);
    }
  },

  /**
   * Finds the correct array of Matchups based on what kind of Sweepstakes we're picking winners for.
   * @param {Number|String} organizationId
   * @param {Number|String} organizationPromotionId
   * @returns {Promise} - Resolves with an array of zero, one, or many {@link Matchup}s.
   */
  findMatchups(organizationId, organizationPromotionId, params) {
    const promotionType = get(
      this.modelFor('organizations.organization.organization-promotions.organization-promotion'),
      'organizationPromotion.promotion.promotionType'
    );
    if (
      !params.secondaryMatchup &&
      (promotionType === 'Sweepstakes' ||
        promotionType === 'UGCVoting' ||
        promotionType === 'UGCSweepstakes' ||
        promotionType === 'Ballot')
    ) {
      return this.store.query('matchup', {
        organizationId,
        organizationPromotionId,
        excludeSecondaryMatchups: true,
      });
    }

    return this.store.query('sweepstakes', { organizationId, organizationPromotionId }).then(sweepstakes => {
      if (get(sweepstakes, 'firstObject.isEnabled')) {
        return get(sweepstakes, 'firstObject.matchup').then(matchup => [matchup]);
      }
      return [];
    });
  },

  /**
   * Finds the most "current" matchup in an array, according to their dates.
   * @param {Matchup[]} matchups
   * @param {Boolean} matchupsAsCategories
   * @returns {Matchup}
   */
  relevantMatchupFrom(matchups, matchupsAsCategories) {
    if (matchupsAsCategories) {
      return get(matchups, 'firstObject');
    }
    const sortedMatchups = matchups.sortBy('startDate');
    const today = new Date();
    return sortedMatchups.reduce(
      (pValue, matchup) => (today >= get(matchup, 'startDate') ? matchup : pValue),
      get(sortedMatchups, 'firstObject')
    );
  },

  fetchEntryForm() {
    const formTypeId = enums.findWhere('FORM_TYPE', { name: 'EntrySubmission' });
    return this.store.queryRecord('form', { formTypeId });
  },

  async updateMatchupGroupViews() {
    const ballotMatchupSummaries = await this.store.query('ballot-matchup-summary', {
      organizationPromotionId: this.controller.organizationPromotion.id,
    });

    set(
      this,
      'controller.allMatchupGroupViews',
      createMatchupGroupViews(ballotMatchupSummaries.toArray(), this.controller.matchupId)
    );
  },

  savePublishWinnersTask: task(function* (bool) {
    const model = this.modelFor('organizations.organization.organization-promotions.organization-promotion.winners');
    const controller = this.controllerFor(
      'organizations.organization.organization-promotions.organization-promotion.winners'
    );
    const matchup = get(model, 'selectedMatchup');
    set(controller, 'updatingWinnersPublishing', true);
    set(matchup, 'isWinnersPublished', bool);
    yield matchup.save();
    set(controller, 'isWinnersPublished', bool);
    set(controller, 'updatingWinnersPublishing', false);
    const matchupEntryRanks = yield this.store.query('matchupEntryRank', this.matchupEntryRanksParams);
    set(model, 'matchupEntryRanks', matchupEntryRanks.sortBy('rank'));
  }).restartable(),

  async fetchSharingDesignTokenContents() {
    const designTemplateTypeId = this.enums.findWhere('TEMPLATE_TYPE', { name: 'Promotion' });

    const { promotionDesign } = await RSVP.hash({
      promotionDesign: this.store.queryRecord('design', { designTemplateType: designTemplateTypeId }),
      designTemplates: this.store.query('design-template', { designTemplateTypeId }),
    });

    return promotionDesign
      ? this.store
          .query('design-token-content', { designId: promotionDesign.id })
          .then(x => x.toArray().filter(token => token.designToken.category == 'Sharing'))
      : [];
  },

  actions: {
    updateMatchupGroupViews() {
      this.updateMatchupGroupViews();
    },
    refreshWinners() {
      this.refresh();
    },
    async setPublishWinners(bool) {
      const model = this.modelFor('organizations.organization.organization-promotions.organization-promotion.winners');
      const controller = this.controllerFor(
        'organizations.organization.organization-promotions.organization-promotion.winners'
      );
      if (!isBlank(get(model, 'matchupEntryRanks'))) {
        await this.savePublishWinnersTask.perform(bool);
        this.snackbar
          .show(
            get(controller, 'winners.length') + get(controller, 'changedPublishWinnersMessage'),
            'Undo',
            5000,
            null,
            null,
            'blvd-snackbar--extra-margin-bottom'
          )
          .then(async () => {
            await this.savePublishWinnersTask.perform(!bool);
          });
      }
    },
    loading(transition) {
      const controller = this.controllerFor(
        'organizations.organization.organization-promotions.organization-promotion.winners'
      );
      set(controller, 'loadingModel', true);
      transition.promise.finally(() => {
        set(controller, 'loadingModel', false);
      });
    },
  },
  //endregion
});
