import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes>\n  <Ui::Sortable\n    @items={{sort-by \"displayOrder\" this.formFields}}\n    @onChange={{this.reorderFields}}\n    @disabled={{@disabled}}\n    as |formField|\n  >\n    <EntryForm::EntryField\n      @formField={{formField}}\n      @saveForm={{@saveForm}}\n      @editFormField={{fn @editFormField formField}}\n      @copyFormField={{fn @copyFormField formField}}\n      @deleteFormField={{fn @deleteFormField formField}}\n      @disabled={{@disabled}}\n      data-test-form-field\n    />\n  </Ui::Sortable>\n</div>\n", {"contents":"<div ...attributes>\n  <Ui::Sortable\n    @items={{sort-by \"displayOrder\" this.formFields}}\n    @onChange={{this.reorderFields}}\n    @disabled={{@disabled}}\n    as |formField|\n  >\n    <EntryForm::EntryField\n      @formField={{formField}}\n      @saveForm={{@saveForm}}\n      @editFormField={{fn @editFormField formField}}\n      @copyFormField={{fn @copyFormField formField}}\n      @deleteFormField={{fn @deleteFormField formField}}\n      @disabled={{@disabled}}\n      data-test-form-field\n    />\n  </Ui::Sortable>\n</div>\n","moduleName":"partner/components/entry-form/additional-fields.hbs","parseOptions":{"srcName":"partner/components/entry-form/additional-fields.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class EntryFormAdditionalFieldsComponent extends Component {
  /**
   * Returns only the additional fields
   * We use the organizationId of the field
   * as we know for sure that the fixed fields have an
   * organizationId of 1
   */
  get formFields() {
    return this.args.formFields.filter(({ isRoot }) => !isRoot);
  }

  @action
  reorderFields(formFields) {
    formFields.map((formField, index) => (formField.displayOrder = index + 5));
    this.args.saveForm();
  }
}
