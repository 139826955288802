/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { mapBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

export default Component.extend({
  //region Attributes
  'org-user-attribute': null,
  fields: null,
  /**
   * All organization user attributes that share the same user attribute type ID.
   * Relevant for multiple checkbox fields.
   */
  'bundled-org-user-attributes': null,
  'save-user'() {},
  'delete-org-attribute'() {},
  'toggle-multiple-checkbox'() {},
  'create-org-attribute'() {},
  //endregion

  //region Dependencies
  deliberateConfirmation: service('deliberate-confirmation'),
  //endregion

  //region Ember Hooks
  classNames: ['consumer-user-custom-data', 'group', 'relative'],
  //endregion

  //region Computed Properties
  relevantField: computed('fields', 'org-user-attribute.userAttributeType.fieldId', function () {
    return this.fields.findBy('id', `${get(this, 'org-user-attribute.userAttributeType.fieldId')}`);
  }),

  checkboxBooleanValue: computed('org-user-attribute.value', 'relevantField.isSingleCheckbox', function () {
    return get(this, 'relevantField.isSingleCheckbox') ? get(this, 'org-user-attribute.value') === 'true' : undefined;
  }),

  selectedFieldOption: computed('relevantField.fieldOptions.@each.id', 'org-user-attribute.value', function () {
    return get(this, 'relevantField.fieldOptions').findBy('id', `${get(this, 'org-user-attribute.value')}`);
  }),

  confirmDeleteFieldText: computed(() => ({
    promptText: "Are you sure you want to remove this user's data?",
    cancelButtonText: 'No',
    confirmButtonText: 'Yes, I want to remove this data',
  })),

  valuesOfBundledOrgUserAttributes: mapBy('bundled-org-user-attributes', 'value'),
  //endregion

  //region Actions
  actions: {
    //Fun fact: since we already save on blur, this will automatically save after the input field is blurred
    saveOnEnter({ key, currentTarget }) {
      if (key === 'Enter') {
        currentTarget.blur();
      }
    },
    validateInputValue(value) {
      //Prevents user from being able to send empty string into number field, which would return 500 server error
      if (get(this, 'relevantField.isNumberInput') && !value) {
        set(this, 'org-user-attribute.value', '0');
      } else {
        set(this, 'org-user-attribute.value', value);
      }
    },
    changeSelectedValue(value) {
      set(this, 'org-user-attribute.value', get(value, 'id'));
    },
    toggleCheckboxValue() {
      set(this, 'org-user-attribute.value', !this.checkboxBooleanValue);
    },
    toggleMultipleCheckbox(id, value) {
      if (value) {
        // before creating a brand new org user attribute from the checkbox,
        // first see if there is an org user attribute that does not have a value.
        const orgUserAttributeWithNoValue = this['bundled-org-user-attributes'].find(x => isBlank(get(x, 'value')));
        if (orgUserAttributeWithNoValue) {
          set(orgUserAttributeWithNoValue, 'value', id);
          this['save-user']();
        } else {
          this['create-org-attribute'](this.relevantField, id);
        }
      } else {
        const relevantOrgUserAttribute = this['bundled-org-user-attributes'].findBy('value', id);
        set(relevantOrgUserAttribute, 'value', null);
        this['save-user']();
      }
    },
    async showConfirmDeleteField() {
      const { deliberateConfirmation } = this;
      const confirmed = await deliberateConfirmation.show(this.confirmDeleteFieldText);

      if (confirmed) {
        this['bundled-org-user-attributes'].map(this['delete-org-attribute']);
      }

      deliberateConfirmation.resetConfirmedStatus();
    },
  },
  //endregion
});
