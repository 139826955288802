import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<TemplateDesigner::Controls::Wrapper>\n  <TemplateDesigner::Controls::Label\n    @label=\"type\"\n    @onReset={{if @ownData.columns (fn @onDataChange \"columns\" undefined)}}\n    @deviceType={{@deviceType}}\n    @inheritingFrom={{compute @getInheritingDataFromDevice \"columns\"}}\n  />\n  <TemplateDesigner::Controls::ButtonGroup\n    @items={{array\n      (hash icon=\"rectangle\" key=\"1\" onSelect=(fn this.onColumnChange \"1\"))\n      (hash svg=\"grid-1-1\" key=\"1:1\" onSelect=(fn this.onColumnChange \"1:1\"))\n      (hash svg=\"grid-2-1\" key=\"2:1\" onSelect=(fn this.onColumnChange \"2:1\"))\n      (hash svg=\"grid-1-2\" key=\"1:2\" onSelect=(fn this.onColumnChange \"1:2\"))\n      (hash icon=\"view_column\" key=\"1:1:1\" onSelect=(fn this.onColumnChange \"1:1:1\"))\n    }}\n    @selectedKey={{@data.columns}}\n  />\n</TemplateDesigner::Controls::Wrapper>", {"contents":"<TemplateDesigner::Controls::Wrapper>\n  <TemplateDesigner::Controls::Label\n    @label=\"type\"\n    @onReset={{if @ownData.columns (fn @onDataChange \"columns\" undefined)}}\n    @deviceType={{@deviceType}}\n    @inheritingFrom={{compute @getInheritingDataFromDevice \"columns\"}}\n  />\n  <TemplateDesigner::Controls::ButtonGroup\n    @items={{array\n      (hash icon=\"rectangle\" key=\"1\" onSelect=(fn this.onColumnChange \"1\"))\n      (hash svg=\"grid-1-1\" key=\"1:1\" onSelect=(fn this.onColumnChange \"1:1\"))\n      (hash svg=\"grid-2-1\" key=\"2:1\" onSelect=(fn this.onColumnChange \"2:1\"))\n      (hash svg=\"grid-1-2\" key=\"1:2\" onSelect=(fn this.onColumnChange \"1:2\"))\n      (hash icon=\"view_column\" key=\"1:1:1\" onSelect=(fn this.onColumnChange \"1:1:1\"))\n    }}\n    @selectedKey={{@data.columns}}\n  />\n</TemplateDesigner::Controls::Wrapper>","moduleName":"partner/components/template-designer/library/attributes/columns.hbs","parseOptions":{"srcName":"partner/components/template-designer/library/attributes/columns.hbs"}});
import { action } from '@ember/object';

import Component from '@glimmer/component';
import type { Columns, SsArcAttributeSignature } from '@ss/arc/arc/types';
import { ArcGridBlock } from '@ss/arc/arc/blocks';
import { later } from '@ember/runloop';

export default class TemplateDesignerLibraryAttributesColumnsComponent extends Component<SsArcAttributeSignature> {
  @action
  onColumnChange(layout: Columns) {
    const block = this.args.blockUnit as ArcGridBlock;
    this.args.onDataChange('columns', layout);

    later(() => {
      switch (layout) {
        case '1':
          break;
        case '1:1':
        case '1:2':
        case '2:1':
          if (block.blocks.length < 2) {
            block.blocks.push(this.args.arc.createBlock({ type: 'box' }));
          }
          break;
        case '1:1:1':
          Array.from({ length: 3 - block.blocks.length }).forEach(() =>
            block.blocks.push(this.args.arc.createBlock({ type: 'box' }))
          );
          break;
      }
    }, 300);
  }
}
