/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { getProperties, set, setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AppContextRoute from 'partner/mixins/app-context-route';
import RSVP from 'rsvp';

const OPT_OUT_TEXT_TOKEN = '{{System.OptOutText}}';

export default Route.extend(AppContextRoute, {
  //region Ember Dependencies
  features: service(),
  router: service(),
  store: service(),
  //endregion

  //region Ember Hooks
  model() {
    return RSVP.hash({
      template: this.store.createRecord('message-body-template'),
    });
  },
  resetController(controller) {
    setProperties(controller, {
      templateMethod: 'secondStreetTemplate',
      messageVersionId: null,
      messageCampaignId: null,
      organizationPromotionId: null,
      audienceId: null,
      messageCampaignType: null,
    });
  },

  //region Actions
  actions: {
    async saveEmailTemplate() {
      const model = this.modelFor(this.routeName);
      const newTemplate = model.template;
      const {
        templateMethod,
        messageVersionId,
        messageCampaignId,
        organizationPromotionId,
        audienceId,
        messageCampaignTypeId,
      } = getProperties(
        this.controller,
        'templateMethod',
        'messageVersionId',
        'messageCampaignId',
        'organizationPromotionId',
        'audienceId',
        'messageCampaignTypeId'
      );

      const queryParams = {};
      if (templateMethod === 'customHtml') {
        set(newTemplate, 'body', OPT_OUT_TEXT_TOKEN);
      } else {
        setProperties(queryParams, {
          isNewTemplate: true,
          messageVersionId,
          messageCampaignId,
          organizationPromotionId,
          audienceId,
          messageCampaignTypeId,
        });
        if (templateMethod === 'secondStreetTemplate') {
          queryParams.isSelectingTemplate = true;
        }
      }

      await newTemplate.save();
      this.router.transitionTo(
        'organizations.organization.email.message-body-templates.message-body-template',
        newTemplate.id,
        {
          queryParams,
        }
      );
    },
  },
  //endregion
});
