import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes>\n  {{#each this.sections as |section|}}\n    <TemplateDesigner::Controls::Accordion\n      @title={{section.title}}\n      @expanded={{section.expanded}}\n      @toggleExpanded={{toggle \"expanded\" section}}\n    >\n      <div class=\"flex flex-wrap gap-2 px-4 pb-4\">\n        {{#each section.items as |draggableItem|}}\n          <TemplateDesigner::DraggableBlock @blockUnit={{draggableItem}} @arc={{@arc}} />\n        {{/each}}\n      </div>\n    </TemplateDesigner::Controls::Accordion>\n  {{/each}}\n</div>", {"contents":"<div ...attributes>\n  {{#each this.sections as |section|}}\n    <TemplateDesigner::Controls::Accordion\n      @title={{section.title}}\n      @expanded={{section.expanded}}\n      @toggleExpanded={{toggle \"expanded\" section}}\n    >\n      <div class=\"flex flex-wrap gap-2 px-4 pb-4\">\n        {{#each section.items as |draggableItem|}}\n          <TemplateDesigner::DraggableBlock @blockUnit={{draggableItem}} @arc={{@arc}} />\n        {{/each}}\n      </div>\n    </TemplateDesigner::Controls::Accordion>\n  {{/each}}\n</div>","moduleName":"partner/components/template-designer/library/elements.hbs","parseOptions":{"srcName":"partner/components/template-designer/library/elements.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class TemplateDesignerLibraryElementsComponent extends Component {
  @tracked sections = [
    {
      title: 'Text',
      expanded: true,
      items: [
        { name: 'Heading', iconName: 'title', type: 'heading' },
        { name: 'Text', iconName: 'sort', type: 'text' },
      ],
    },
    {
      title: 'Media',
      expanded: true,
      items: [
        { name: 'Image', iconName: 'image', type: 'image' },
        { name: 'Video', iconName: 'play_circle', type: 'video' },
        { name: 'Banner Ad', iconName: 'featured_video', type: 'banner-ad' },
      ],
    },
    {
      title: 'Miscellaneous',
      expanded: true,
      items: [
        { name: 'Box', iconName: 'check_box_outline_blank', type: 'box' },
        { name: 'Grid', iconName: 'view_column', type: 'grid' },
        { name: 'Button', iconName: 'open_in_new', type: 'button' },
        { name: 'Social Media', iconName: 'share', type: 'social' },
        { name: 'Countdown', iconName: 'timer', type: 'countdown' },
      ],
    },
  ];
}
