/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-mixins */
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, or } from '@ember/object/computed';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import enums from 'ember-cli-ss-enums/services/enums';
import moment from 'moment';
import Copyable from 'partner/mixins/copyable-model';
import dirtyProperty from 'partner/utils/dirty-property';
import BaseModel from 'secondstreet-common/models/base';

@makeBooleanProperties('MESSAGE_SENDING_STATUS_TYPE', 'name', 'sendingStatusTypeId')
export default class Message extends BaseModel.extend(Copyable) {
  @attr('number') organizationId;
  @attr('number') statusTypeId;
  @attr('number') communicationMediumTypeId;
  @attr('number') sendingStatusTypeId;
  @attr('number') testPercentage;
  @attr('number') testWinningCriteriaId;
  @attr('number') testDurationOpenCount;
  @attr('number') testDurationMinutes;
  @attr('boolean') isApprovalRequired;
  @attr('string') approvalRecipients;
  @attr('number') approvalMinutesBeforeSend;

  @alias('schedules') schedule;
  @belongsTo('schedule', { async: false }) schedules;
  @hasMany('message-version', { async: false }) messageVersions;
  @belongsTo('message-campaign', { async: true }) messageCampaign;

  @or('isScheduled', 'isSent', 'isSending', 'isSentAndScheduled', 'isTestSend') isScheduledSentOrSending;
  @enums.computed('name', 'sendingStatusTypeId', 'sendingStatusTypeId', 'MESSAGE_SENDING_STATUS_TYPE')
  sendingStatusType;
  @enums.computed('name', 'messageTestWinningCriteriaId', 'messageTestWinningCriteriaId') testWinningCriteria;

  @computed('isIncomplete', 'isScheduled')
  get hasStarted() {
    return !this.isIncomplete && !this.isScheduled;
  }

  @dirtyProperty('sendingStatusTypeId') statusTypeDirty;
  @dirtyProperty('testDurationMinutes') isTestDurationMinutesDirty;
  @dirtyProperty('testDurationOpenCount') isTestDurationOpenCountDirty;
  @dirtyProperty('testPercentage') isTestPercentageDirty;
  @dirtyProperty('testWinningCriteriaId') isTestWinningCriteriaIdDirty;

  @computed('testDurationMinutes', 'schedule.startDate')
  get endOfABTestingDate() {
    const { startDate } = this.schedule;
    if (!startDate) {
      return null;
    }
    return moment(startDate)
      .add(this.testDurationMinutes || 0, 'minutes')
      .toDate();
  }
}
