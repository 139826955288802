import Component from '@glimmer/component';

export default class EntryFormFieldBaseComponent extends Component {
  get entryFieldValue() {
    return this.args.entryFieldValues[0];
  }

  get fieldId() {
    return +this.args.formField.field.id;
  }

  get fieldOptions() {
    return this.args.formField.field.fieldOptions;
  }
}
