import { formatMoney } from 'secondstreet-common/utils/format-money';

export default ({
  promotionProductPrice,
  basicsCallToActionIsEnabled,
  basicsCallToAction,
  productName,
  basicsButtonText,
  productDetailsTextIsEnabled,
  productDetailsText,
  supportInfo,
  productImageIsEnabled,
  productImageSource,
}) => `
  <div class="payment-card payment-card--left-aligned">
    ${
      productImageIsEnabled
        ? `<div class="product-image" data-token="productImage">${`<h1 class="product-name product-name--mobile" data-token="productName">${productName}</h1>`}<img class="product-image__image" src=${productImageSource}></div>`
        : ''
    }
    <div class="payment-card__info">
      ${`<h1 class="product-name ${
        productImageIsEnabled ? 'product-name--desktop' : ''
      }" data-token="productName">${productName}</h1>`}
      ${promotionProductPrice ? `<h2 class="product-price">$${formatMoney(promotionProductPrice, 2)}</h2>` : ''}
      ${
        basicsCallToActionIsEnabled
          ? `<div class="payment-card__dynamic-text" data-token="basicsCallToAction">${basicsCallToAction}</div>`
          : ''
      }
      <div data-token="basicsButtonText">
        <button type="button" class="ssButton ssFormButton ssButtonPrimary template-color-primary-background-color">${basicsButtonText}</button>
      </div>
    </div>
  </div>
  <div class="payment-footer">
    ${
      productDetailsTextIsEnabled
        ? `<div class="payment-footer__primary-text" data-token="productDetailsText">${productDetailsText}</div>`
        : ''
    }
    <div data-token="supportInfo">${supportInfo}</div>
  </div>
  <div class="ssSharing">
    <ul class="ssShareListCompact ssShareList">
      <li class="ssShareListItem ssShareListItemFacebook">
        <a class="ssShareLink ssShareLinkFacebook" data-token="facebook" href="#" >
          <i class="ssIcon-facebook" aria-label="share on facebook"></i>
        </a>
      </li>
      <li class="ssShareListItem ssShareListItemTwitter">
        <a class="ssShareLink ssShareLinkTwitter" data-token="twitter" href="#">
          <i class="ssIcon-twitter" aria-label="share on twitter"></i>
        </a>
      </li>
      <li class="ssShareListItem ssShareListItemEmail">
        <a class="ssShareLink ssShareLinkEmail" data-token="email" href="#" >
          <i class="ssIcon-envelope" aria-label="share with email"></i>
        </a>
      </li>
      <li class="ssShareListItem ssShareListItemPinterest">
        <a class="ssShareLink ssShareLinkPinterest" data-token="pinterest" href="#" >
          <i class="ssIcon-pinterest" aria-label="share on pinterest"></i>
        </a>
      </li>
    </ul>
  </div>
`;
