import { computed, get } from '@ember/object';

const { keys } = Object;

/**
 * Are Attributes Changed
 * @param hash - Array of objects where keys are pointers to DS.Model instances on `this`, and
 *               values are arrays of properties to care if are dirty
 * @returns {Ember.ComputedProperty}
 */
export default function (hash) {
  const paths = keys(hash).map(path => `${path}.{hasChangedAttributes,${hash[path].join(',')}}`);
  return computed(...paths, function () {
    if (!this.hasChangedAttributes) {
      return false;
    }
    return keys(hash).any(path => {
      const model = get(this, path);
      if (!model) {
        return false;
      }

      const changed = model.changedAttributes();
      return !!hash[path].any(prop => Object.prototype.hasOwnProperty.call(changed, prop));
    });
  });
}
