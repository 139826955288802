/* eslint-disable ember/no-new-mixins, ember/no-mixins */
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import AppContextRoute from 'partner/mixins/app-context-route';
import { determineDateRange } from 'partner/utils/performance-report';
import type { DateRangeType } from 'partner/utils/performance-report';
import type { ModelFrom } from 'partner/utils/types';
import PerformanceReportCommonController from './performance-report-controller';

const ROWS_PER_PAGE = 25;

export type Model = ModelFrom<PerformanceReportCommonRote>;

export type ParamsType = {
  sortColumn?: string;
  sortDirection?: string;
  dateRange: DateRangeType;
  start?: string;
  end?: string;
  organizationIds: string;
  view: string;
  type: string;
  category: string;
  name: string;
  page?: number;
  promotionSubTypeIds: string;
  status: number;
  pageSize: number | undefined;
};

export default class PerformanceReportCommonRote extends Route.extend(AppContextRoute) {
  declare controller: PerformanceReportCommonController;

  queryParams = {
    view: {
      refreshModel: true,
      replace: true,
    },
    page: {
      refreshModel: true,
      replace: true,
    },
    sortColumn: {
      refreshModel: true,
      replace: true,
    },
    sortDirection: {
      refreshModel: true,
      replace: true,
    },
    organizationIds: {
      refreshModel: true,
      replace: true,
    },
    type: {
      refreshModel: true,
      replace: true,
    },
    category: {
      refreshModel: true,
      replace: true,
    },
    name: {
      refreshModel: true,
      replace: true,
    },
    dateRange: {
      refreshModel: true,
      replace: true,
    },
    start: {
      refreshModel: true,
      replace: true,
    },
    end: {
      refreshModel: true,
      replace: true,
    },
    promotionSubTypeIds: {
      refreshModel: true,
      replace: true,
    },
    status: {
      refreshModel: true,
      replace: true,
    },
  };

  assembleQueryParameters(params: ParamsType) {
    const {
      sortColumn,
      sortDirection,
      page,
      organizationIds,
      type,
      category,
      name,
      dateRange,
      start,
      end,
      promotionSubTypeIds,
      status,
      pageSize,
    } = params;
    const { startDate, endDate } = determineDateRange(dateRange, start, end);
    return {
      pageIndex: page,
      pageSize: pageSize || ROWS_PER_PAGE,
      sortColumn,
      sortDirection,
      organizationIds,
      messageCampaignTypeId: type,
      messageCampaignCategoryId: category,
      messageCampaignName: name,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      promotionSubTypeIds,
      searchValue: name,
      isActive: status ? status == 1 : null,
    };
  }

  beforeModel(transition: any) {
    if (this.controller && transition.from?.name == transition.to?.name) {
      this.controller.isLoading = true;
    }
  }

  setupController(controller: PerformanceReportCommonController, model: Model) {
    super.setupController(controller, model);

    controller.isLoading = false;
  }

  @action
  loading() {
    // Prevent the loading template from being rendered
    return !this.controller?.isLoading;
  }
}
