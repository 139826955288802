/* eslint-disable ember/no-mixins, ember/no-new-mixins */
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { firstByProperty } from 'partner/utils/computed';

export default Mixin.create({
  newUsersChart: firstByProperty('name', 'Organization User', 'model.eventCharts'),
  allUsersChart: firstByProperty('name', 'Organization Promotion User', 'model.eventCharts'),
  totalNewUsers: computed('newUsersChart.categories.@each.value', function () {
    return this.newUsersChart ? this.newUsersChart.categories?.reduce((prev, current) => prev + current.value, 0) : 0;
  }),
  totalExistingUsers: computed('totalNewUsers', 'allUsersChart.categories.@each.value', function () {
    return this.allUsersChart
      ? this.allUsersChart.categories?.reduce((prev, current) => prev + current.value, 0) - (this.totalNewUsers || 0)
      : 0;
  }),
  newVotersChart: firstByProperty('name', 'Unique People With Votes', 'model.eventCharts'),
  allVotersChart: firstByProperty('name', 'Vote', 'model.eventCharts'),
  newNominationsChart: firstByProperty('name', 'Unique People With Entries', 'model.eventCharts'),
  allNominationsChart: firstByProperty('name', 'Entry', 'model.eventCharts'),
});
