import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class TokensRoute extends Route {
  @service router;

  model(params) {
    return params.token_id;
  }

  afterModel(tokenId) {
    this.router.replaceWith('organizations.organization.email.tokens', { queryParams: { tokenId } });
  }
}
