/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-mixins */
import Controller from '@ember/controller';
import { readOnly, sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import HasCategoricalCharts from 'partner/mixins/has-categorical-charts';
import HasEventCharts from 'partner/mixins/has-event-charts';
import NeedsOrganizationPromotionController from 'partner/mixins/needs-organization-promotion-controller';
import { firstByProperty } from 'partner/utils/computed';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';

export default class OrganizationsOrganizationOrganizationPromotionsOrganizationPromotionDashboardQuizController extends Controller.extend(
  HasCategoricalCharts,
  HasEventCharts,
  NeedsOrganizationPromotionController
) {
  @service permissions;
  @service reports;
  @service('snackbar') snackbar;

  @tracked currentWinnerPage = 1;
  @tracked isReportsModalVisible = false;
  winnersCardTransition = 'toLeft';

  @readOnly('organizationPromotion.kpi')
  totalEntries;

  @readOnly('organizationPromotion.numEligibleUsers')
  numEligibleUsers;

  @readOnly('model.matchups.firstObject.startDate')
  contestStartDate;

  @readOnly('model.matchups.firstObject.endDate')
  contestEndDate;

  winnerSorting = ['entry.matchup.displayOrder', 'rank'];

  @sort('model.winners', 'winnerSorting')
  sortedWinners;

  get hasReportPermissionsEntities() {
    const entities = 'OrganizationUser,UserReport,Entry';
    const accessLevel = this.permissions.getAccessLevel(entities);
    return isPresent(entities) && accessLevel.view;
  }

  get hasViewPermissionsEntities() {
    const entities = 'OrganizationUser,Entry';
    const accessLevel = this.permissions.getAccessLevel(entities);
    return isPresent(entities) && accessLevel.view;
  }

  get mediaType() {
    return this.organizationPromotion.promotion.isUGCSweepstakesVideo ? 'videos' : 'photos';
  }

  get winnersHeader() {
    const winnersCount = this.model.winners.length;
    return `After all the ${this.mediaType} were collected, ${winnersCount} ${
      winnersCount === 1 ? 'person was' : 'people were'
    } randomly selected as ${winnersCount === 1 ? 'the winner' : 'winners'}.`;
  }

  get currentWinner() {
    return this.model.winners.length ? this.sortedWinners.objectAt(this.currentWinnerPage - 1) : null;
  }

  get sweepsCardWinnersPagingInfo() {
    return this.model.winners.length > 1 ? `(${this.currentWinnerPage} of ${this.model.winners.length})` : '';
  }

  get matchupsForReportModal() {
    return this.model.matchups.sortBy('displayOrder').map(item => ({
      id: item.id,
      name: `Round ${item.iteration}`,
      isPreparingDownload: false,
    }));
  }

  @firstByProperty('name', 'Entry', 'model.eventCharts')
  entriesChart;

  downloadReportsTask = task({ drop: true }, async () => {
    if (this.model.matchups.length > 1) {
      this.isReportsModalVisible = true;
      return;
    }

    try {
      await this.reports.download('Winners', {
        data: {
          matchup_id: this.model.matchups.firstObject.id,
        },
      });
    } catch (e) {
      this.snackbar.exception(e);
    }
  });
}
