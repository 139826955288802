import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import type { Model } from 'partner/routes/organizations/organization/double-optin-confirmation';

export default class OrganizationsOrganizationDoubleOptinConfirmationController extends Controller {
  declare model: Model;

  get messageCampaignTypeViews() {
    const { messageCampaign } = this.model;
    return [
      {
        messageCampaignViews: [
          {
            messageCampaign,
            messageVersions: messageCampaign.messageVersions,
          },
        ],
      },
    ];
  }

  get messageVersion() {
    return this.model.messageCampaign.messageVersions.firstObject;
  }

  get dynamicTokens() {
    return this.model.tokens.filterBy('dynamicTokenType');
  }

  saveTask = task({ drop: true }, async design => {
    const dirtyTokenContents = design.tokenContents.filterBy('hasDirtyAttributes');
    await Promise.all(dirtyTokenContents.map((x: any) => x.save()));
    if (design.hasDirtyAttributes) {
      await design.save();
    }
  });
}
