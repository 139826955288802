/* eslint-disable ember/no-on-calls-in-components, ember/no-jquery, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { observer, set } from '@ember/object';
import { on } from '@ember/object/evented';
import $ from 'jquery';

export default Component.extend({
  tagName: 'simple-menu',
  classNameBindings: ['isExpanded', 'add-list:add-list-simple-menu'],
  isExpanded: false,

  //region Attributes
  /**
   * By defaut, we disable some simple menu functionality if user doesn't have certain administer permissions.
   * By setting this to true, we will remove those restrictions for users with view permissions.
   */
  'permit-view-only': false,
  'add-list': false,
  'on-close'() {},
  //endregion

  //region Ember Hooks
  didInsertElement() {
    this._super(...arguments);
    $('body').on('close.all.simpleMenu', (event, elementId) => {
      if (!this.isDestroyed && this.elementId !== elementId) {
        set(this, 'isExpanded', false);
        this['on-close']();
      }
    });
  },

  willDestroyElement() {
    this._super(...arguments);
    $('body').off('close.all.simpleMenu');
  },
  //endregion

  //region Observers
  enableClose: on(
    'init',
    observer('isExpanded', function () {
      const { isExpanded } = this;
      if (isExpanded) {
        $('body').one('click', () => {
          if (!this.isDestroyed) {
            set(this, 'isExpanded', false);
            this['on-close']();
          }
        });
      }
    })
  ),
  //endregion

  //region Methods
  toggleMenu() {
    this.toggleProperty('isExpanded');
    if (this.isExpanded) {
      const $body = $('body');
      //close any open tagging search boxes
      $body.trigger('click.interestTags');
      //close all other open simple menus (id will be used to exclude itself from event)
      $body.trigger('close.all.simpleMenu', [this.elementId]);
    }
  },
  //endregion

  //region Actions
  actions: {
    close() {
      set(this, 'isExpanded', false);
      this['on-close']();
    },
  },
  //endregion
});
