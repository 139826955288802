/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';

export default Controller.extend({
  //region Ember Hooks
  queryParams: ['showUploadModal'],
  organizationController: controller('organizations.organization'),
  audiencesController: controller('organizations.organization.data.audiences'),
  audienceController: controller('organizations.organization.data.audiences.audience'),
  //endregion

  //region Properties
  showUploadModal: false,
  isDownloadingReport: false,
  //endregion
});
