/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import moment from 'moment';
import { fullName } from 'partner/utils/computed';
import BaseModel from 'secondstreet-common/models/base';

const getOrganizationUserAttribute = function (attributeName, attributeProperty) {
  return computed(`organizationUserAttributes.@each.{userAttributeTypeName,${attributeProperty}}`, function () {
    const relevantOrgUserAttribute = this.organizationUserAttributes.findBy('userAttributeTypeName', attributeName);
    return relevantOrgUserAttribute ? relevantOrgUserAttribute[attributeProperty] : null;
  });
};

export default BaseModel.extend({
  enums: service(),
  //region Properties
  emailAddress: DS.attr('string'),
  birthDate: DS.attr('date-only'),
  birthDay: DS.attr('number'),
  birthMonth: DS.attr('number'),
  birthYear: DS.attr('number'),
  firstName: DS.attr('string'),
  middleName: DS.attr('string'),
  gender: DS.attr('string'),
  lastActivityDate: DS.attr('date'),
  lastName: DS.attr('string'),
  organizationId: DS.attr('number'),
  statusTypeId: DS.attr('number'),
  userId: DS.attr('number'),
  userTypeId: DS.attr('number'),
  username: DS.attr('string'),
  addressSummary: DS.attr('string'), // Calculated on the server side for the winners page
  //endregion

  //region Computed Properties
  name: fullName(),
  age: computed('birthDate', function () {
    return this.birthDate ? moment().diff(this.birthDate, 'years') : null;
  }),
  postalCode: getOrganizationUserAttribute('Postal Code', 'value'),
  phoneNumber: getOrganizationUserAttribute('Phone', 'value'),
  userGenderId: getOrganizationUserAttribute('Gender', 'value'),

  userGender: computed('userGenderId', function () {
    return this.userGenderId && this.enums.findWhere('GENDER_FILTER_TYPE', { id: +this.userGenderId }, 'name');
  }),
  //endregion

  //region Relationships
  organizationUserAttributes: DS.hasMany('organization-user-attribute', {
    async: false,
  }),
  //endregion
});
