/* eslint-disable ember/no-mixins */
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import type CurrentService from 'partner/services/current';
import type Store from '@ember-data/store';
import type { ModelFrom } from 'partner/utils/types';
import type PerformanceReportController from 'partner/controllers/organizations/organization/performance-report';

import PerformanceReportCommonRote, { ParamsType } from 'partner/-base/performance-report-route';
import RSVP from 'rsvp';

export type Model = ModelFrom<OrganizationsOrganizationPerformanceReportRoute>;

export default class OrganizationsOrganizationPerformanceReportRoute extends PerformanceReportCommonRote {
  @service declare store: Store;
  @service declare current: CurrentService;

  async model(params: ParamsType) {
    const { results } = await RSVP.hash({
      results: this.store.query(
        'organization-performance-statistic',
        this.assembleQueryParameters({
          ...params,
          page: 1,
          pageSize: 10000,
        })
      ),
      _orgs: this.current.organization.isChain ? this.store.findAll('organization') : [],
    });

    const { meta } = results as unknown as { meta: { performanceStatisticsTotalCounts: object } };

    const organizationPerformanceStatistics = results.toArray();
    if (
      organizationPerformanceStatistics.length &&
      this.current.organization.isChain &&
      !organizationPerformanceStatistics.findBy('organizationId', this.current.organization.id)
    ) {
      organizationPerformanceStatistics.addObject(
        this.store.peekRecord('organization-performance-statistic', this.current.organization.id) ||
          this.store.createRecord('organization-performance-statistic', {
            id: this.current.organization.id,
          })
      );
    }
    return {
      organizationPerformanceStatistics,
      meta,
      selectedOrgs: params.organizationIds,
    };
  }

  setupController(controller: PerformanceReportController, model: Model) {
    super.setupController(controller, model);
    set(controller, 'tempSelectedOrgs', model.selectedOrgs);
  }
}
