/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller, { inject as controller } from '@ember/controller';
import { readOnly } from '@ember/object/computed';

export default Controller.extend({
  //region Dependencies
  audienceController: controller('organizations.organization.data.audiences.audience'),
  //endregion

  //region Computed Properties
  isSubscriptionAudience: readOnly('audienceController.model.audience.isSubscriptionAudience'),
  //endregion
});
