/* eslint-disable ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import camelCase from 'lodash/camelCase';
import fromPairs from 'lodash/fromPairs';
import snakeCase from 'lodash/snakeCase';
import toPairs from 'lodash/toPairs';

const { Transform } = DS;

/**
 * Given an array of objects, transforms the keys of those objects
 */
export default Transform.extend({
  /**
   * [{ firstName: "Billy", lastName: "Bob" }] to [{ first_name: "Billy", last_name: "Bob" }]
   * @param array
   */
  serialize(array) {
    if (array) {
      return array.map(obj => fromPairs(toPairs(obj).map(([key, value]) => [snakeCase(key), value])));
    }
    return null;
  },
  /**
   * [{ first_name: "Billy", last_name: "Bob" }] to [{ firstName: "Billy", lastName: "Bob" }]
   * @param array
   */
  deserialize(array) {
    return array.map(obj => fromPairs(toPairs(obj).map(([key, value]) => [camelCase(key), value])));
  },
});
