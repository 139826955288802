/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import { computed, get } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

export default Mixin.create({
  enums: service(),
  session: service(),

  isAdminUser: computed('session.data.authenticated.organization_users.user_type_id', function () {
    const userTypeId = get(this, 'session.data.authenticated.organization_users.user_type_id');
    const userType = this.enums.findWhere('USER_TYPE', { id: userTypeId }, 'name');
    return ['SecondStreetAdmin', 'SuperAdmin'].includes(userType);
  }),
});
