/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { set } from '@ember/object';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { run } from '@ember/runloop';
/**
 * @param is-retina {Boolean} If true, this will request an image at double size, but display it as a number of CSS pixels as specified in `width` and `height`.
 */
export default Component.extend({
  //region Ember Hooks
  tagName: 'img',
  attributeBindings: ['width', 'height', 'src', 'alt', 'cropmode', 'anchor', 'isLoaded:data-loaded'],
  //endregion

  //region Attributes
  /**
   * @type MediaItem
   */
  mediaItem: null,
  /**
   * Not required if you pass in a MediaItem
   * @type {String}
   */
  dipsUrl: null,
  /**
   * Not required if you pass in a MediaItem
   * @type {Number}
   */
  mediaItemId: null,
  width: null,
  defaultWidth: computed('height', function () {
    return this.height ? null : 300;
  }),
  height: null,
  alt: null,
  cropmode: null,
  anchor: null,
  'is-retina': false,
  rotate: null,
  /**
   * Will be added to image url in an attempt to prevent caching by browser
   */
  'cache-bust': null,
  //endregion

  init() {
    this._super(...arguments);
    if (!this.width) {
      set(this, 'width', this.defaultWidth);
    }
  },

  src: computed(
    'dipsUrl',
    'mediaItemId',
    'width',
    'height',
    'cropmode',
    'anchor',
    'is-retina',
    'rotate',
    'cache-bust',
    function () {
      const { mediaItem } = this;
      const { mediaItemId } = this;
      const { dipsUrl } = this;
      const { width } = this;
      const { height } = this;
      const { cropmode } = this;
      const { anchor } = this;
      const { rotate } = this;
      const cacheBust = this['cache-bust'];
      const multiplier = this['is-retina'] ? 2 : 1;

      const queryString = [
        width ? `width=${width * multiplier}` : null,
        height ? `height=${height * multiplier}` : null,
        cropmode ? `cropmode=${cropmode}` : null,
        anchor ? `anchor=${anchor}` : null,
        rotate ? `rotate=${rotate}` : null,
        cacheBust ? `cacheBust=${cacheBust}` : null,
      ]
        .compact()
        .join('&');

      //MediaItem entity already contains the dipsUrl (thanks API!)
      if (mediaItem) {
        return `//${mediaItem.dipsUrl}?${queryString}`;
      }

      if (dipsUrl && mediaItemId) {
        return `//${dipsUrl}/${mediaItemId}?${queryString}`;
      }
      return;
    }
  ),

  didInsertElement() {
    this._super(...arguments);
    this.onLoad = () => {
      run(() => {
        this.set('isLoaded', true);
      });
    };
    this.element.addEventListener('load', this.onLoad);
  },

  willDestroyElement() {
    this._super(...arguments);
    this.element.removeEventListener('load', this.onLoad);
  },
});
