/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';

export default Component.extend({
  //region Attributes
  audience: null,
  'download-audience-report': x => x,
  'download-in-process': false,
  //endregion

  //region Properties
  alwaysShowDownload: false,
  showDirtyMessage: true,
  //endregion

  //region Computed Properties
  showDirtyCssClass: computed('showEmptyState', 'isAnythingDirty', function () {
    return this.showEmptyState ? false : this.isAnythingDirty;
  }),
  isProcessingCount: alias('audience.isProcessing'),
  isCalculatingCount: alias('audience.isCalculating'),
  hideDownloader: computed('isAnythingDirty', 'alwaysShowDownload', function () {
    if (!this.enableDownload) {
      return true;
    }
    return this.alwaysShowDownload ? false : this.isAnythingDirty;
  }),
  dirtyMessage: computed('counted-entity', 'showCustomDirtyMessage', 'customDirtyMessage', function () {
    return this.showCustomDirtyMessage
      ? this.customDirtyMessage
      : `${this['counted-entity']} Have Changed. Please Save to Recalculate.`;
  }),
  //endregion
});
