import { attr } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';

export default class SearchEntrantProfileModel extends BaseModel {
  @attr('string') declare name: string;
  @attr('string') declare entryIds: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'search-entrant-profile': SearchEntrantProfileModel;
  }
}
