import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"ssTile full-calendar {{if this.showDefaultEvents 'default-view'}}\" ...attributes>\n  {{#if this.setupTask.isRunning}}\n    <MdcLinearProgress @indeterminate={{this.setupTask.isRunning}} />\n  {{else}}\n    <StaticProgressBar @list-items={{@items}} />\n  {{/if}}\n\n  <div {{did-insert (perform this.setupTask)}} {{will-destroy this.cleanup}}></div>\n</div>\n", {"contents":"<div class=\"ssTile full-calendar {{if this.showDefaultEvents 'default-view'}}\" ...attributes>\n  {{#if this.setupTask.isRunning}}\n    <MdcLinearProgress @indeterminate={{this.setupTask.isRunning}} />\n  {{else}}\n    <StaticProgressBar @list-items={{@items}} />\n  {{/if}}\n\n  <div {{did-insert (perform this.setupTask)}} {{will-destroy this.cleanup}}></div>\n</div>\n","moduleName":"partner/components/calendar/full-calendar.hbs","parseOptions":{"srcName":"partner/components/calendar/full-calendar.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { run } from '@ember/runloop';
import moment from 'moment';
import { PROMO_TYPE_CATEGORY_ID_MAP } from 'partner/utils/constants';
import formatNumber from 'secondstreet-common/utils/format-number';
import { task } from 'ember-concurrency';
import ENV from 'partner/config/environment';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type { Tippy } from 'tippy.js';
import type { Calendar, EventContentArg, EventClickArg, EventHoveringArg } from '@fullcalendar/core';
import type DefaultFormatService from 'partner/services/default-format';

const html = String.raw;

const CALENDAR_EVENT_TYPE_COLORS: Record<string, string> = {
  Quiz: '#673ab7',
  VotersChoice: '#9423a9',
  UGCVoting: '#05bbd3',
  UGCGallery: '#ff0000',
  Sweepstakes: '#009688',
  Pickem: '#0277bd',
  PickemRank: '#0277bd',
  PickemStandard: '#0277bd',
  PickemBracketUpFront: '#0277bd',
  PickemWithKnockout: '#0277bd',
  Ballot: '#9423a9',
  Poll: '#c51162',
  Survey: '#c51162',
  LegacyBracket: '#0277bd',
  VotingBracket: '#9c27b0',
  UGCSweepstakes: '#009688',
  SingleEmail: '#dd2c00',
  SingleSMS: '#9423a9',
  DripCampaign: '#ffab00',
  Newsletter: '#ff6d00',
  Birthday: '#ffab00',
  Welcome: '#ffab00',
  Invite: '#dd2c00',
  ThankYou: '#009688',
  WeddingAnniversary: '#ffab00',
  EventSignup: '#0557c1',
};

function hexToRgb(hex?: string): RGBA | null {
  if (!hex) return null;

  const [, red, green, blue] = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) || [];

  return red && green && blue ? { r: parseInt(red, 16), g: parseInt(green, 16), b: parseInt(blue, 16), a: 1 } : null;
}

function endDateCheck(item: any) {
  if (item.isSent) return item.dateSent;
  if (item.isSingleEmail || item.isInvite) return item.startDate;

  return moment().add(2, 'years').toDate();
}

type RGBA = { r: number; g: number; b: number; a: number };
type Event = {
  id: string;
  isPromotion: boolean | undefined;
  itemRGB: RGBA;
  itemId: any;
  title: any;
  actualStartDate: string;
  actualEndDate: string;
  start: any;
  end: any;
  backgroundColor: string;
  event: any;
  eventType: any;
  className: string;
};

type ExtendEvent<T extends { event: any }> = T & { event: T['event'] & { extendedProps: Event } };

interface FullCalendarSignature {
  Element: HTMLDivElement;
  Args: {
    organization: any;
    items: any[];
    isPromotion?: boolean;
    promotionTypeCategory?: string;
  };
}

export default class FullCalendarComponent extends Component<FullCalendarSignature> {
  @service declare router: RouterService;
  @service declare enums: EnumsService;
  @service declare store: Store;
  @service declare defaultFormat: DefaultFormatService;

  @tracked showDefaultEvents = true;
  @tracked allItems: any[] = [];
  @tracked events: Event[] = [];

  private calendar?: Calendar;
  private tippy?: Tippy;

  setupTask = task({ drop: true }, async (element: HTMLDivElement) => {
    const [{ Calendar }, { default: dayGridPlugin }, { default: timelinePlugin }, { default: tippy }] =
      await Promise.all([
        import('@fullcalendar/core'),
        import('@fullcalendar/daygrid'),
        import('@fullcalendar/resource-timeline'),
        import('tippy.js'),
      ]);

    this.tippy = tippy;

    this.calendar = new Calendar(element, {
      schedulerLicenseKey: ENV.fullCalendar.license,
      plugins: [dayGridPlugin, timelinePlugin],
      eventContent: this.renderEvent,
      eventClick: this.gotoEvent,
      eventMouseEnter: this.showToolTip,
      eventMouseLeave: this.hideToolTip,
      displayEventTime: false,
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'title',
        center: '',
        right: `${
          this.args.isPromotion && this.args.promotionTypeCategory !== 'interactiveContent' ? '' : 'showAll hideAll '
        }dayGridMonth timelineCustom`,
      },
      footerToolbar: { left: '', center: '', right: 'today prev,next' },
      buttonText: {
        dayGridMonth: 'Calendar View',
        timelineYear: 'Timeline View',
        today: 'Today',
      },
      views: {
        dayGridMonth: {
          dayMaxEventRows: 3,
          dayMaxEvents: 5,
        },
        timelineCustom: {
          displayEventTime: false,
          type: 'timeline',
          buttonText: 'Year View',
          duration: { years: 1 },
          slotDuration: { months: 1 },
        },
      },
      customButtons: {
        showAll: { text: 'Show Never-ending Items', click: this.toggleAllEvents },
        hideAll: { text: 'Hide Never-ending Items', click: this.toggleAllEvents },
      },
    });

    this.calendar.render();

    await this.fetchEvents();

    this.calendar.addEventSource(this.events);
  });

  @action
  cleanup() {
    run(() => this.calendar?.destroy());
  }

  async fetchEvents() {
    const result = this.args.isPromotion
      ? await this.store.query('organization-promotion', {
          pageIndex: 1,
          pageSize: 3500,
          promotionTypeCategoryId: this.args.promotionTypeCategory
            ? (PROMO_TYPE_CATEGORY_ID_MAP as Record<string, number>)[this.args.promotionTypeCategory]
            : null,
        })
      : await this.store.query('message-campaign', {
          pageIndex: 1,
          pageSize: 3500,
        });

    this.allItems = result.toArray();
    this.mapItemsToEvents();
  }

  private renderEvent = ({ event: { id, title, extendedProps: event } }: ExtendEvent<EventContentArg>) => {
    const e = this.events.findBy('id', id);

    if (!e) return '';

    const element = document.createElement('div');
    element.className = 'flex items-center gap-1 w-full p-1 overflow-hidden';
    element.append(
      document.createRange().createContextualFragment(html`
        <i class="ssIcon ssTypeIcon ssIcon-dynamic ssIcon--calendar-view flex-shrink-0"></i>
        <span class="truncate">${e.title}</span>
      `)
    );

    this.tippy?.(element, {
      content: html`
        <div
          class="material-list-tile material-list-tile__centered-icons with-avatar multi-line ssEditable ${event.eventType} "
        >
          <div class="material-list-action ember-view">
            <i class="ssIcon ssTypeIcon ssIcon-dynamic" title="73694"></i>
            <div class="material-list-column">
              <div class="material-list-text">
                <div class="material-list-header material-list-header_calendar" title="${title}">${title}</div>
                <div class="material-list-subheader">
                  ${event.actualStartDate === event.actualEndDate
                    ? event.actualStartDate
                    : `${event.actualStartDate} - ${event.actualEndDate}`}
                </div>
              </div>
            </div>
            <div class="material-list-column organization-promotion-list-item__kpi">
              <div class="material-list-header">
                ${event.isPromotion ? formatNumber(event.event.kpi) : formatNumber(event.event.audienceMemberCount)}
              </div>
              <div class="material-list-subheader">
                ${event.isPromotion
                  ? event.event.promotion.isVotersChoice || event.event.promotion.isBallot
                    ? 'Votes'
                    : 'Entries'
                  : 'Recipients'}
              </div>
            </div>
          </div>
        </div>
      `,
      theme: 'light',
      interactive: false,
      followCursor: 'initial',
    });

    return {
      domNodes: [element],
    };
  };

  private showToolTip = ({ el, event: { id } }: EventHoveringArg) => {
    const event = this.events.findBy('id', id);

    if (!event) return;

    el.style.backgroundColor = `rgba(${event.itemRGB.r},${event.itemRGB.g},${event.itemRGB.b},.3)`;
  };

  private hideToolTip = ({ el, event: { id } }: EventHoveringArg) => {
    const event = this.events.findBy('id', id);

    if (el && event) {
      el.style.backgroundColor = `rgba(${event.itemRGB.r},${event.itemRGB.g},${event.itemRGB.b},.15)`;
    }
  };

  private gotoEvent = ({ event: { id } }: EventClickArg) => {
    const event = this.events.findBy('id', id);

    if (!event) return;

    if (event.isPromotion) {
      if (event.event.isOldPlatform) {
        void this.router.transitionTo('organizations.organization.go', 'ManageContest', {
          queryParams: { organizationPromotionId: event.event.id },
        });
      } else {
        void this.router.transitionTo(
          'organizations.organization.organization-promotions.organization-promotion',
          event.event.id
        );
      }
    } else {
      if (event.event.isLegacyMessage) {
        void this.router.transitionTo('organizations.organization.go', 'EBlastManageMessage', {
          queryParams: { legacyMessageId: event.event.legacyMessageId },
        });
      } else {
        void this.router.transitionTo('organizations.organization.message-campaigns.message-campaign', event.event.id);
      }
    }
  };

  private toggleAllEvents = () => {
    this.showDefaultEvents = !this.showDefaultEvents;
    this.mapItemsToEvents();

    this.calendar?.removeAllEventSources();
    this.calendar?.addEventSource(this.events);
  };

  private mapItemsToEvents() {
    let items;

    if (this.args.isPromotion) {
      items =
        (this.showDefaultEvents ? this.allItems.filter(item => item.startDate && item.endDate) : this.allItems) || [];
    } else {
      items = this.showDefaultEvents
        ? this.allItems.filter(item => item.isSingleEmail || (item.isInvite && !item.isArchived))
        : this.allItems.filter(item => !item.isArchived) || [];
    }

    this.events = items.map<Event>(item => {
      const key: string = this.args.isPromotion
        ? item.promotion.promotionType
        : this.enums.findWhere('MESSAGE_CAMPAIGN_TYPE', { id: item.messageCampaignTypeId }, 'name');
      const itemRGB = hexToRgb(CALENDAR_EVENT_TYPE_COLORS[key]) || { r: 255, g: 255, b: 255, a: 1 };

      itemRGB.a = 0.5;

      if (!item.isPromotion && item.messageCampaignTypeId === 1) {
        item.endDate = item.startDate;
      }

      return {
        id: item.id as string,
        isPromotion: this.args.isPromotion,
        itemRGB,
        itemId: item.id,
        title: item.name,
        actualStartDate: item.startDate
          ? moment(item.startDate).format(this.defaultFormat.getDateFormat())
          : 'No Start Date',
        actualEndDate: item.endDate
          ? moment(item.endDate).format(this.defaultFormat.getDateFormat())
          : this.args.isPromotion
          ? 'No End Date'
          : item.isSent
          ? moment(item.dateSent).format(this.defaultFormat.getDateFormat())
          : 'No End Date',
        start: item.startDate,
        end: item.endDate || endDateCheck(item),
        backgroundColor: `rgba(${itemRGB.r},${itemRGB.g},${itemRGB.b},.15)`,
        event: item,
        eventType: this.args.isPromotion
          ? item.promotion.promotionSubTypeName
          : this.enums.findWhere('MESSAGE_CAMPAIGN_TYPE', { id: item.messageCampaignTypeId }, 'name'),
        className: `${
          this.args.isPromotion
            ? (item.promotion.promotionSubTypeName as string)
            : this.enums.findWhere('MESSAGE_CAMPAIGN_TYPE', { id: item.messageCampaignTypeId }, 'name')
        } full-calendar-event`,
      };
    });
  }
}
