import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default class OrganizationsOrganizationMessageCampaignsMessageCampaignSetupTagsRoute extends Route {
  @service store;
  @service router;

  queryParams = {
    id: {},
  };

  async beforeModel(transition) {
    if (transition.to.queryParams.id) return;

    const messageVersions = await this.getMessageVersions();

    if (messageVersions.firstObject) {
      transition.abort();
      this.router.transitionTo(this.fullRouteName, { queryParams: { id: messageVersions.firstObject.id } });
    }
  }

  async model() {
    return RSVP.hash({
      messageCampaigns: [this.messageCampaign],
      versions: this.getMessageVersions(),
    });
  }

  async afterModel() {
    this.send('checkChecklistStep', this.fullRouteName);
  }

  get messageCampaignModel() {
    return this.modelFor('organizations.organization.message-campaigns.message-campaign');
  }

  get messageCampaign() {
    return this.messageCampaignModel.messageCampaign;
  }

  async getMessageVersions() {
    if (!this.versions) {
      const messageCampaignId = this.messageCampaign.id;

      if (this.messageCampaign.isNewsletter && this.messageCampaign.dateSent) {
        const response = await this.store.query('message-version-history', {
          messageCampaignId,
          includeInteractionCount: true,
        });

        this.versions = response.sortBy('dateSent:desc');
      } else {
        await this.store.query('message', {
          messageCampaignId,
          includeInteractionCount: true,
        });

        this.versions = this.messageCampaign.messageVersions;
      }
    }
    return this.versions;
  }

  deactivate() {
    this.versions = null;
  }
}
