/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller, { inject as controller } from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';

import type SnackbarService from 'secondstreet-common/services/snackbar';
import type SettingsService from 'partner/services/settings';
import type { Model } from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/legal';

const SETUP_LEGAL_ROUTE = 'organizations.organization.organization-promotions.organization-promotion.setup.legal';
const ORGANIZATION_PROMOTION_ROUTE = 'organizations.organization.organization-promotions.organization-promotion';

interface SourceOption {
  value: boolean;
  label: string;
}

export default class OrganizationSetupLegalController extends Controller {
  @service declare settings: SettingsService;
  @service declare snackbar: SnackbarService;

  declare model: Model;

  queryParams = ['designId'];

  sourceOptions: SourceOption[] = [
    { value: false, label: 'Source Promotion' },
    {
      value: true,
      label: 'Child Organization Default',
    },
  ];

  closeDropdownHandler: (() => void) | null = null;

  @tracked designId: string | null = null;
  @tracked isAnythingSaving = false;
  @tracked isSourceSettingsSaving = false;
  @tracked selectedRulesSource: SourceOption | undefined = this.sourceOptions[0];
  @tracked selectedTermsSource: SourceOption | undefined = this.sourceOptions[0];
  @tracked selectedPrivacySource: SourceOption | undefined = this.sourceOptions[0];

  // @ts-ignore
  @controller(ORGANIZATION_PROMOTION_ROUTE)
  declare organizationPromotionController: any;

  @computed('model.designs', 'designId')
  get activeDesign() {
    return this.model.designs.findBy('id', this.designId);
  }

  @computed('organizationPromotionController.model.organizationPromotion.promotion.promotionType')
  get minimumAgeEntryType() {
    switch (this.organizationPromotionController.model.organizationPromotion.promotion.promotionType) {
      case 'UGCVoting':
      case 'Sweepstakes':
      case 'UGCSweepstakes':
        return 'contest';
      case 'Quiz':
      case 'UGCGallery':
      case 'Poll':
        return 'sweepstakes';
      case 'Ballot':
        return 'ballot';
      default:
        return 'promotion';
    }
  }

  get isSourceSettingsDirty() {
    return (
      this.model.promotionRulesSourceSetting.value !== this.selectedRulesSource?.value ||
      this.model.promotionTermsSourceSetting.value !== this.selectedTermsSource?.value ||
      this.model.promotionPrivacySourceSetting.value !== this.selectedPrivacySource?.value
    );
  }

  checkLegalDesignCompletion() {
    if (this.model.designs.isEvery('isComplete')) {
      this.send('checkChecklistStep', SETUP_LEGAL_ROUTE);
    } else {
      this.send('uncheckChecklistStep', SETUP_LEGAL_ROUTE);
    }
  }

  @action
  setup() {
    this.selectedRulesSource = this.sourceOptions.findBy('value', this.model.promotionRulesSourceSetting.value);
    this.selectedTermsSource = this.sourceOptions.findBy('value', this.model.promotionTermsSourceSetting.value);
    this.selectedPrivacySource = this.sourceOptions.findBy('value', this.model.promotionPrivacySourceSetting.value);
  }

  @action
  openedSourceOptions(dropdown: any) {
    this.closeDropdownHandler = this.closeSourceDropdown(dropdown);
    window.addEventListener('scroll', this.closeDropdownHandler);
  }

  @action
  cleanup() {
    if (this.closeDropdownHandler) {
      window.removeEventListener('scroll', this.closeDropdownHandler);
      this.closeDropdownHandler = null;
    }
  }

  @action
  closeSourceDropdown(dropdown: any) {
    return () => {
      dropdown.actions.close();
    };
  }

  @action
  changeSourceSetting(option: SourceOption, sourceType: string) {
    switch (sourceType) {
      case 'rules':
        this.selectedRulesSource = option;
        break;
      case 'terms':
        this.selectedTermsSource = option;
        break;
      case 'privacy':
        this.selectedPrivacySource = option;
        break;
      default:
        break;
    }
  }

  @action
  setActiveDesign(id: string) {
    set(this, 'designId', id);
  }

  @action
  async saveSourceSettings() {
    this.isSourceSettingsSaving = true;

    this.model.promotionRulesSourceSetting.value = this.selectedRulesSource?.value;
    this.model.promotionTermsSourceSetting.value = this.selectedTermsSource?.value;
    this.model.promotionPrivacySourceSetting.value = this.selectedPrivacySource?.value;

    try {
      if (this.model.promotionRulesSourceSetting.isDirty) {
        await this.model.promotionRulesSourceSetting.save();
      }

      if (this.model.promotionTermsSourceSetting.isDirty) {
        await this.model.promotionTermsSourceSetting.save();
      }

      if (this.model.promotionPrivacySourceSetting.isDirty) {
        await this.model.promotionPrivacySourceSetting.save();
      }
    } finally {
      this.isSourceSettingsSaving = false;
    }
  }

  @action
  async saveSetting(setting: any) {
    this.isAnythingSaving = true;

    // @ts-ignore
    const currentSetting = this.model[setting];

    try {
      if (currentSetting.isInherited) {
        const settingToSave = currentSetting.createCopy(['id', 'isInherited', 'ownerEntityTypeId', 'ownerEntityId']);
        currentSetting.rollbackAttributes();
        // @ts-ignore
        this.model[setting] = settingToSave;
        return await settingToSave.save();
      }

      return await currentSetting.save();
    } finally {
      this.isAnythingSaving = false;
    }
  }

  @action
  async saveCustomDesign(template: any) {
    const customDesigns = this.model.designs.filterBy('isCustom');
    this.isAnythingSaving = true;

    try {
      let legalSetting = '';

      if (template === 'Rules') {
        legalSetting = 'rule_is_locked';
      } else if (template === 'Terms of Service') {
        legalSetting = 'terms_of_service_is_locked';
      } else legalSetting = 'private_policy_is_locked';

      const currentSetting = this.settings.getFor(legalSetting as any);
      currentSetting.value = this.activeDesign.isLocked;

      try {
        await currentSetting?.save();
      } catch (e) {
        //it's alright even if this fails.
        console.error(e);
      }

      this.activeDesign.isApproved = true;
      await this.activeDesign.save();

      this.checkLegalDesignCompletion();

      if (!customDesigns.isEvery('isApproved')) {
        const nextUnapprovedDesign = customDesigns.findBy('isApproved', false);
        set(this, 'designId', nextUnapprovedDesign.id);
      }
    } catch (error) {
      this.snackbar.show(this.activeDesign.errors.firstObject.message, '', 5000, 'error');
    } finally {
      this.isAnythingSaving = false;
    }
  }

  @action
  async saveTemplateDesign(dirtyDesignTokenContents: any) {
    this.isAnythingSaving = true;

    this.activeDesign.isApproved = true;

    this.checkLegalDesignCompletion();

    await RSVP.all(dirtyDesignTokenContents.map((designTokenContent: any) => designTokenContent.save()));
    await this.activeDesign.save();

    this.isAnythingSaving = false;
  }
}
