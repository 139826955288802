/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { TextField } from '@ember/legacy-built-in-components';
import { observer } from '@ember/object';
import $ from 'jquery';

/**
 * See the official docs for info on configuration.
 *
 * @property {String} hex - Two-way binding for the chosen hex value
 * @property {Number} opacity - Two-way binding for the chosen opacity
 * @see http://labs.abeautifulsite.net/jquery-minicolors/
 * @typedef {Ember.TextField} MiniColorsComponent
 * @fires MiniColorsComponent#changed
 * @fires MiniColorsComponent#show
 * @fires MiniColorsComponent#hide
 */
export default TextField.extend({
  'data-animation-speed': 50,
  'data-animation-easing': 'swing',
  'data-change-delay': 0,
  'data-control': 'hue',
  'data-data-uris': true,
  'data-hide-speed': 100,
  'data-inline': false,
  'data-letter-case': 'lowercase',
  'data-opacity': false,
  'data-position': 'bottom left',
  'data-show-speed': 100,
  'data-theme': 'default',
  hex: null,
  opacity: null,
  resetHexValue: null,
  changed() {},
  hide() {},
  show() {},

  //region Ember Hooks
  /**
   * This fixs a issue with the color picker inside design flyouts.
   * If you need the color picker to be aware of new content, then change
   * resetHexValue at the same time.
   */
  updateDefaultValue: observer('resetHexValue', function () {
    $(this.element).minicolors('value', this.hex);
  }),
  willDestroyElement() {
    this._super(...arguments);
    $(this.element).minicolors('destroy');
  },
  didInsertElement() {
    this._super(...arguments);
    const component = this;
    $(this.element).minicolors({
      animationSpeed: this['data-animation-speed'],
      animationEasing: this['data-animation-easing'],
      changeDelay: this['data-change-delay'],
      control: this['data-control'],
      dataUris: this['data-data-uris'],
      defaultValue: this.hex,
      hideSpeed: this['data-hide-speed'],
      inline: this['data-inline'],
      letterCase: this['data-letter-case'],
      opacity: this['data-opacity'],
      position: this['data-position'],
      showSpeed: this['data-show-speed'],
      theme: this['data-theme'],
      change() {
        component.send('changed', ...arguments);
      },
      hide() {
        component.hide(...arguments);
      },
      show() {
        component.show(...arguments);
      },
    });
  },
  //endregion
  actions: {
    changed(hex) {
      if (this.hex !== hex) {
        this.changed(hex);
      }
    },
  },
});
