import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class IndexRoute extends Route {
  @service router;

  redirect() {
    return this.router.replaceWith(
      'organizations.organization.organization-promotions.organization-promotion.moderate-nominations.merge'
    );
  }
}
