import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"px-6 relative\">\n  <Ui::Tabs\n    @tabs={{array\n      (hash key=\"basics\" label=\"Basic Details\")\n      (hash key=\"locations\" label=\"Locations\" disabled=@disabled)\n      (hash key=\"link-entrants\" label=\"Other Categories\" disabled=@disabled)\n      (hash key=\"gallery\" label=\"Gallery\" disabled=@disabled)\n    }}\n    @current={{@current}}\n    @onChange={{this.onChange}}\n  />\n\n  {{#if @disabled}}\n    <Ui::InfoTooltip\n      @tooltip=\"Tabs are disabled until entrant title is saved\"\n      @tooltipSide=\"bottom-end\"\n      class=\"absolute top-2 right-6 z-110\"\n    />\n  {{/if}}\n</div>\n", {"contents":"<div class=\"px-6 relative\">\n  <Ui::Tabs\n    @tabs={{array\n      (hash key=\"basics\" label=\"Basic Details\")\n      (hash key=\"locations\" label=\"Locations\" disabled=@disabled)\n      (hash key=\"link-entrants\" label=\"Other Categories\" disabled=@disabled)\n      (hash key=\"gallery\" label=\"Gallery\" disabled=@disabled)\n    }}\n    @current={{@current}}\n    @onChange={{this.onChange}}\n  />\n\n  {{#if @disabled}}\n    <Ui::InfoTooltip\n      @tooltip=\"Tabs are disabled until entrant title is saved\"\n      @tooltipSide=\"bottom-end\"\n      class=\"absolute top-2 right-6 z-110\"\n    />\n  {{/if}}\n</div>\n","moduleName":"partner/components/ballot/matchup-entry-form/tabs.hbs","parseOptions":{"srcName":"partner/components/ballot/matchup-entry-form/tabs.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type { BallotMatchupEntryFormTab } from 'partner/types';
import type DirtyTrackerService from 'partner/services/dirty-tracker';

export interface BallotMatchupEntryFormTabsSignature {
  Element: HTMLDivElement;
  Args: {
    current: BallotMatchupEntryFormTab;
    onChange: (tab: BallotMatchupEntryFormTab) => void;
  };
}

export default class BallotMatchupEntryFormTabsComponent extends Component<BallotMatchupEntryFormTabsSignature> {
  @service declare dirtyTracker: DirtyTrackerService;

  @action
  onChange(key: BallotMatchupEntryFormTab) {
    if (this.dirtyTracker.confirm()) {
      this.args.onChange(key);
    }
  }
}
