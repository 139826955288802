import { attr, belongsTo } from '@ember-data/model';
import BaseModel from 'secondstreet-common/models/base';
import type VotingEntryModel from './voting-entry';

export default class MatchupEntryStatisticModel extends BaseModel {
  // Attributes
  @attr('string') declare entryName: string;
  @attr('number') declare numEligibleEntries: number;
  @attr('number') declare numEligibleEntriesPotentialFraud: number;
  @attr('number') declare numEligibleVotes: number;
  @attr('number') declare numEligibleVotesPotentialFraud: number;
  @attr('number') declare numEntriesPotentialFraud: number;
  @attr('number') declare numVotesPotentialFraud: number;

  @belongsTo('voting-entry', { async: false }) declare entry: VotingEntryModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'matchup-entry-statistic': MatchupEntryStatisticModel;
  }
}
