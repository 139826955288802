/* eslint-disable ember/use-ember-data-rfc-395-imports, @typescript-eslint/restrict-template-expressions */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Store from '@ember-data/store';
import EnumsService from 'ember-cli-ss-enums/services/enums';
import DomainModel from 'partner/models/domain';
import DS from 'ember-data';

const protocolMatcher = new RegExp('^(https?://)?(([^/.:]{1,}.)*?[^/.:]{1,}.[^/.:]{1,})(:d*)?$');

export interface DomainAdministrationSignature {
  Element: HTMLDivElement;
  Args: {
    domains: DomainModel[];
    updateDomains: () => void;
  };
}

class Domain {
  @tracked id?: string | null = null;
  @tracked addEdit?: boolean = false;
  @tracked domainType?: string | undefined = undefined;
}

export default class DomainAdministrationComponent extends Component<DomainAdministrationSignature> {
  get domains() {
    return this.args.domains;
  }

  @service declare enums: EnumsService;
  @service declare store: Store;

  @tracked
  isDefault = false;

  @tracked domainErrors: DS.Errors | null = null;

  @tracked
  chosenDomain: Domain = new Domain();

  get isSubdomain() {
    return this.chosenDomain.domainType === 'Web';
  }

  async _saveDomain(domain: DomainModel) {
    try {
      domain.isPrimary = this.chosenDomain.domainType === 'EmbeddedHostSource' ? false : this.isDefault;
      await domain.save();
      this.args.updateDomains();
      this.closeModal();
    } catch (_) {
      this.domainErrors = domain.errors;
    }
  }

  createNewDomain(domain: DomainModel) {
    let hostname: string | undefined, name: string | undefined;

    if (this.chosenDomain.domainType === 'Web') {
      hostname = `${domain.subdomain}.secondstreetapp.com`;
      name = `http://${hostname}`;
    } else if (this.chosenDomain.domainType === 'EmbeddedHostSource') {
      name = domain.subdomain;
      while (name.endsWith('/')) {
        name = name.slice(0, -1);
      }
      hostname = name.match(protocolMatcher)?.[2];
    }

    return this.store.createRecord('domain', {
      hostname,
      name,
      domainTypeId: this.enums.findWhere('DOMAIN_TYPE', { name: this.chosenDomain.domainType }),
    });
  }

  @action
  editDomain(domain: DomainModel) {
    this.isDefault = domain.isPrimary;
    this.chosenDomain = domain;
    this.chosenDomain.addEdit = true;
  }

  @action
  createDomain(domainType: string) {
    this.chosenDomain = { addEdit: true, domainType };
  }

  @action
  async removeDomain(domain: DomainModel) {
    await domain.destroyRecord();
    this.closeModal();
  }

  @action
  saveDomain(domain: DomainModel) {
    const input = document.querySelector<HTMLInputElement>('.ss-material-input');

    if (!input?.checkValidity()) {
      input?.reportValidity();
      return;
    } else if (isEmpty(domain.id)) {
      void this._saveDomain(this.createNewDomain(domain));
    } else {
      void this._saveDomain(domain);
    }
  }

  @action
  closeModal() {
    this.chosenDomain = new Domain();
    this.domainErrors = null;
    this.isDefault = false;
  }

  @action
  toggleDefault(domain: DomainModel) {
    this.isDefault = !this.isDefault;
    domain.isPrimary = this.isDefault;
  }
}
