/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, getProperties, set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import flatten from 'lodash/flatten';
import { setMessageIsConfirmed } from 'partner/utils/message-campaign-helpers';
import { generateBodyAndSave } from 'partner/utils/message-version';
import { createTokenContentsForMessageVersion } from 'partner/utils/message-version';
import RSVP from 'rsvp';

const AUDIENCE_ROUTE = 'organizations.organization.data.audiences.audience';

const doesNotHaveMessaging = context =>
  !get(context.modelFor('organizations.organization'), 'organization.hasMessaging');

export default Route.extend({
  //region Dependencies
  enums: service(),
  router: service(),
  store: service(),
  settings: service(),

  //endregion

  //region Ember Hooks
  async model() {
    if (doesNotHaveMessaging(this)) {
      return;
    }

    const organizationId = get(this.modelFor('organizations.organization'), 'organization.id');

    const messageCampaign = await this.retrieveMessageCampaign();
    const messageCampaignId = get(messageCampaign, 'id');

    const messages = await this.store.query('message', { messageCampaignId });

    const hash = await RSVP.hash({
      locations: this.store.query('location', { isPrimary: true, organizationId }),
      tokenContents: this.store.query('message-version-template-token-content', { messageCampaignId }),
      templates: this.store.query('message-body-template', { organizationId }),
      tokens: this.store.query('token', { organizationId }),
      senderAccounts: this.store.query('sender-account', { organizationId }),
      _settings: this.settings.preload('dips_url'),
      tokenFallbackSettings: this.store.query('setting', { key: 'messaging_dynamic_token_fallback' }),
      messageCampaign,
      messages,
      messageVersions: messages.mapBy('messageVersions.firstObject').toArray(),
    });

    return {
      ...hash,
      dipsUrl: this.settings.getFor('dips_url'),
    };
  },
  async afterModel(model, transition) {
    if (doesNotHaveMessaging(this)) {
      return;
    }

    const { tokens, messageVersions, dipsUrl } = getProperties(model, 'tokens', 'messageVersions', 'dipsUrl');
    const templateMessageVersions = messageVersions.filterBy('isTemplate');
    // in case a message version uses a deleted custom template, which would not be returned in a GET to message body templates
    await Promise.all(templateMessageVersions.mapBy('messageBodyTemplate'));

    // run this on every route load in case new tokens were added to a custom template
    await Promise.all(
      flatten(
        templateMessageVersions.map(messageVersion => {
          // eslint-disable-next-line ember/use-ember-get-and-set
          const templateTokens = messageVersion.template.get('tokens');
          return createTokenContentsForMessageVersion(messageVersion, templateTokens, this.store).map(newTokenContent =>
            newTokenContent.save()
          );
        })
      )
    );
    // run this on every route load in case a custom template was edited
    if (templateMessageVersions.length) {
      generateBodyAndSave(templateMessageVersions, dipsUrl.value, tokens);
    }

    try {
      // save changes to message versions
      await RSVP.allSettled(messageVersions.map(mv => mv.save()));
    } finally {
      if (messageVersions.rejectBy('isValid').length) {
        setMessageIsConfirmed({
          usesSMC: false,
          messageCampaign: model.messageCampaign,
          value: false,
        });
      }
    }

    const { messageVersionId } = transition.to.queryParams;

    if (!messageVersionId) {
      transition.abort();
      const queryParams = { messageVersionId: messageVersions.firstObject.id };
      this.router.replaceWith(this.fullRouteName, { queryParams });
    }
  },
  setupController(controller) {
    this._super(...arguments);
    if (doesNotHaveMessaging(this)) {
      set(controller, 'showWelcomeEmailUpsell', true);
    }
  },
  resetController(controller) {
    set(controller, 'showWelcomeEmailUpsell', false);
  },
  //endregion

  //region Methods
  async retrieveMessageCampaign() {
    const messageCampaignTypeId = this.enums.findWhere('MESSAGE_CAMPAIGN_TYPE', { name: 'Welcome' }, 'id');
    const audienceId = get(this.modelFor(AUDIENCE_ROUTE), 'audience.id');
    const messageCampaigns = await this.store.query('message-campaign', { messageCampaignTypeId, audienceId });

    let messageCampaign = get(messageCampaigns, 'firstObject');

    if (!messageCampaign) {
      messageCampaign = await this.createMessageCampaign();
    }

    return messageCampaign;
  },
  async createMessageCampaign() {
    const messageCampaignPresetId = this.enums.findWhere('MESSAGE_CAMPAIGN_PRESETS', { name: 'Welcome' }, 'id');
    const audienceId = get(this.modelFor(AUDIENCE_ROUTE), 'audience.id');
    const audienceName = get(this.modelFor(AUDIENCE_ROUTE), 'audience.name');

    return this.store
      .createRecord('message-campaign', {
        messageCampaignPresetId,
        audienceId,
        name: `${audienceName} Welcome Email`,
      })
      .save();
  },
  //endregion
});
