import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type SettingsService from 'partner/services/settings';
import { action } from '@ember/object';
import type { SortParamsType } from 'partner/-base/promotion-ads-controller';
import PromotionAdsController from 'partner/-base/promotion-ads-controller';
import { task } from 'ember-concurrency';
import type CurrentService from 'partner/services/current';
import type AdMetricsService from 'partner/services/ad-metrics';

export default class OrganizationPromotionAdMetricsController extends PromotionAdsController {
  @service declare settings: SettingsService;
  @service declare current: CurrentService;
  @service declare adMetrics: AdMetricsService;

  @tracked sortParams: SortParamsType = {
    column: 'impressions',
    direction: 'desc',
  };

  get params() {
    return {
      searchTerm: this.searchTerm,
      allowSorting: true,
      sortColumn: this.sortParams.column,
      sortDirection: this.sortParams.direction,
    };
  }

  get adStats() {
    return this.filteredAds.filterBy('isBannerAd');
  }

  @action
  onSortChange(sortParams: SortParamsType) {
    this.sortParams = {
      ...sortParams,
    };
  }

  downloadAdsReportTask = task({ drop: true }, async () => {
    await this.adMetrics.downloadAdMetricsCSV(this.adStats);
  });
}
