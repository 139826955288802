/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr } from '@ember-data/model';
import { isArray } from '@ember/array';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { dasherize } from '@ember/string';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import BaseModel from 'secondstreet-common/models/base';
import { addValues } from 'secondstreet-common/utils/functional';
import { endpoint, queryString } from 'secondstreet-common/utils/url';

const MULTIPLE_CHOICE_FIELD_TYPES = Object.freeze(['SelectSingle', 'SelectMultiple', 'RadioButtons', 'Checkboxes']);
const MAX_VERTICAL_BAR_CATEGORIES = 5;
const MAX_HORIZONTAL_BAR_CATEGORIES = 20;

export default class CategoricalChartModel extends BaseModel {
  @service('current') current;
  @service('enums') enums;
  @service('session') session;

  @attr('string') name;
  @attr('number') sourceTypeId;
  @attr('string') suggestedChartType;
  @attr('number') total;
  @attr('number') sourceTypeDisplayOrder;
  @attr('number') sourceTypeChartId;
  @attr('number') fieldTypeId;

  @tracked isOptin;
  @tracked rawCategories;

  get fieldType() {
    return this.enums.findWhere('FIELD_TYPE', { id: this.fieldTypeId }, 'name');
  }

  @computed('suggestedChartType', 'isMultipleChoiceFieldType', 'categories.length')
  get chartType() {
    let type = dasherize(this.suggestedChartType);

    if (
      this.isMultipleChoiceFieldType &&
      type === 'vertical-bar' &&
      this.categories.length > MAX_VERTICAL_BAR_CATEGORIES
    ) {
      type = 'horizontal-bar';
    }

    return type;
  }

  get formattedName() {
    const regex = /\{\{Organization\.Name\}\}/g;
    return this.name?.replace(regex, this.current.organization.name);
  }

  @computed('rawCategories.[]')
  get safeRawCategories() {
    return this.rawCategories || [];
  }

  @computed('safeRawCategories.@each.isAnswered')
  get answeredCategories() {
    return this.safeRawCategories.filter(x => x.isAnswered);
  }

  @computed('answeredCategories.@each.value')
  get sortedCategories() {
    return this.answeredCategories.sortBy('value');
  }

  @computed('sortedCategories.@each.value')
  get reverseSortedCategories() {
    return this.sortedCategories.reverse();
  }

  @computed('isMultipleChoiceFieldType', 'answeredCategories.@each.{label,value}')
  get categories() {
    if (!this.isMultipleChoiceFieldType) return this.answeredCategories;

    let categories = this.answeredCategories.filter(x => x.label !== 'Unknown');

    if (categories.length > MAX_VERTICAL_BAR_CATEGORIES) {
      categories = categories.sortBy('value').reverse();

      if (categories.length > MAX_HORIZONTAL_BAR_CATEGORIES) {
        categories = categories.slice(0, MAX_HORIZONTAL_BAR_CATEGORIES);
      }
    }

    return categories;
  }

  /**
   * Used by quizzes to include outcomes that didn't get entries
   */
  @computed('safeRawCategories.@each.value')
  get unfilteredReverseSortedCategories() {
    return this.safeRawCategories.sortBy('value').reverse();
  }

  @computed('rawCategories.@each.{value,isAnswered}')
  get percentAnswered() {
    if (isEmpty(this.rawCategories)) return 0;

    const unanswered = this.rawCategories.filter(x => !x.isAnswered).reduce(addValues('value'), 0);
    const total = this.rawCategories.reduce(addValues('value'), 0);

    return Math.round(100 - (unanswered / total) * 100) || 0;
  }

  @computed('rawCategories.@each.{value,label}')
  get percentKnown() {
    if (isEmpty(this.rawCategories)) return 0;

    const unanswered = this.rawCategories.filter(x => x.label === 'Unknown').reduce(addValues('value'), 0);
    const total = this.rawCategories.reduce(addValues('value'), 0);

    return Math.round(100 - (unanswered / total) * 100) || 0;
  }

  @computed('rawCategories')
  get areCategoriesLoaded() {
    return isArray(this.rawCategories);
  }

  @computed('areCategoriesLoaded', 'categories.length')
  get areCategoriesLoadedAndEmpty() {
    return this.areCategoriesLoaded && this.categories.length === 0;
  }

  @computed('safeRawCategories.@each.value')
  get rawCategoriesPercentage() {
    return categoriesPercentage(this.safeRawCategories);
  }

  @computed('answeredCategories.@each.value')
  get categoriesPercentage() {
    return categoriesPercentage(this.answeredCategories);
  }

  @computed('answeredCategories.@each.{value,label}')
  get optinAnsweredCount() {
    return (
      this._optinAnsweredCount || this.answeredCategories.filter(x => x.label === 'New').get('firstObject.value') || 0
    );
  }

  set optinAnsweredCount(value) {
    return (this._optinAnsweredCount = value);
  }

  @computed('answeredCategories.@each.label')
  get transformedCategories() {
    return this._transformedCategories || this.answeredCategories.filter(x => x.label !== 'Unknown');
  }

  set transformedCategories(value) {
    return (this._transformedCategories = value);
  }

  @computed('fieldType')
  get isMultipleChoiceFieldType() {
    return MULTIPLE_CHOICE_FIELD_TYPES.includes(this.fieldType);
  }

  @computed('chartType', 'answeredCategories.@each.label')
  get isTop20Shown() {
    return (
      this.answeredCategories.filter(x => x.label !== 'Unknown').length > MAX_HORIZONTAL_BAR_CATEGORIES &&
      this.chartType === 'horizontal-bar'
    );
  }

  async fetchCategories(extraParams = {}) {
    const params = {
      sourceTypeId: this.sourceTypeId,
      sourceTypeChartId: this.sourceTypeChartId,
      ...this.current.toSyndicatedParams(),
      ...extraParams,
    };

    const { categorical_chart_categories } = await this.session.request(
      `${endpoint('categoricalChartCategories')}?${queryString(params, { removeEmptyValues: true })}`
    );

    this.rawCategories = (categorical_chart_categories || []).map((category, index) => ({
      value: category.count,
      label: category.name,
      latitude: category.latitude || 0,
      longitude: category.longitude || 0,
      relatedUrl: category.related_url || 0,
      isAnswered: category.count > 0,
      displayOrder: category.display_order || index,
    }));

    return this.rawCategories;
  }
}

/**
 * @typedef {Object} CategoricalChartDataPoint
 * @property {Number} value
 * @property {String} label
 * @property {Number} displayOrder
 * @property {Boolean} isAnswered
 * @property {String?} relatedUrl
 * @property {Number?} latitude
 * @property {Number?} longitude
 */

/**
 * @param {Array<Object>} categories
 * @returns {Array<CategoricalChartDataPoint>}
 */
function categoriesPercentage(categories) {
  const total = categories.reduce(addValues('value'), 0);

  return categories.map((category, index) => ({
    value: category.value > 0 ? category.value / total : 0,
    label: category.label,
    latitude: category.latitude || 0,
    longitude: category.longitude || 0,
    relatedUrl: category.related_url || 0,
    isAnswered: category.value > 0,
    displayOrder: category.display_order || index,
  }));
}
