import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-invalid-interactive }}\n{{! I clearly need to track the mousedown event on this div, ESLint }}\n<div\n  {{on \"mousedown\" this.trackDragDistance}}\n  {{on \"mouseover\" this.onMouseOver}}\n  {{on \"mouseout\" this.onMouseOut}}\n  {{did-insert this.attachDocumentEvents}}\n  {{will-destroy this.detachDocumentEvents}}\n  ...attributes\n>\n  {{yield\n    (hash\n      Overlay=(component\n        \"entry-form/block-dragging-overlay\"\n        isVisible=this.shouldDisplayOverlay\n        onDragOver=this.onDragOver\n        displayMessage=@displayMessage\n      )\n    )\n  }}\n</div>\n", {"contents":"{{! template-lint-disable no-invalid-interactive }}\n{{! I clearly need to track the mousedown event on this div, ESLint }}\n<div\n  {{on \"mousedown\" this.trackDragDistance}}\n  {{on \"mouseover\" this.onMouseOver}}\n  {{on \"mouseout\" this.onMouseOut}}\n  {{did-insert this.attachDocumentEvents}}\n  {{will-destroy this.detachDocumentEvents}}\n  ...attributes\n>\n  {{yield\n    (hash\n      Overlay=(component\n        \"entry-form/block-dragging-overlay\"\n        isVisible=this.shouldDisplayOverlay\n        onDragOver=this.onDragOver\n        displayMessage=@displayMessage\n      )\n    )\n  }}\n</div>\n","moduleName":"partner/components/entry-form/block-dragging.hbs","parseOptions":{"srcName":"partner/components/entry-form/block-dragging.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import duration from 'secondstreet-common/utils/duration';

const DRAG_THRESHOLD = 8;
const HIDE_OVERLAY_DELAY_DURATION = 1000;

export default class EntryFormBlockDraggingComponent extends Component {
  @tracked draggedDistance = 0;
  @tracked isDraggingOver = false;
  @tracked showOverlay = false;

  get shouldDisplayOverlay() {
    // Add a bit of drag threshold, since even clicks can produce a bit of "dragging"
    return (
      this.draggedDistance > DRAG_THRESHOLD || this.isDraggingOver || this.args.forceShowOverlay || this.showOverlay
    );
  }

  @action
  trackDragDistance() {
    this.delayHideOverlayTask.cancelAll();
    this.isTrackingDragDistance = true;
  }

  @action
  onMouseMove(e) {
    if (!this.isTrackingDragDistance || this.shouldDisplayOverlay) return;

    this.draggedDistance = this.draggedDistance + Math.abs(e.movementX) + Math.abs(e.movementY);

    if (this.shouldDisplayOverlay) {
      document.body.classList.add('entry-form-block-dragging-overlay__disable-select');
    }
  }

  @action
  onMouseUp() {
    this.isTrackingDragDistance = false;
    document.body.classList.remove('entry-form-block-dragging-overlay__disable-select');

    this.delayHideOverlayTask.perform();
  }

  @action
  onMouseOver() {
    if (!this.args.isOrganzationLevelInheritedForm) return;
    this.showOverlay = true;
    if (this.shouldDisplayOverlay) {
      document.body.classList.add('entry-form-block-dragging-overlay__disable-select');
    }
  }

  @action
  onMouseOut() {
    if (!this.args.isOrganzationLevelInheritedForm) return;
    this.showOverlay = false;
    document.body.classList.remove('entry-form-block-dragging-overlay__disable-select');
  }

  @action
  onDragOver() {
    this.delayHideOverlayTask.perform();

    this.isTrackingDragDistance = false;
    this.isDraggingOver = true;

    if (!this.isDraggingOver) {
      document.body.classList.add('entry-form-block-dragging-overlay__disable-select');
    }
  }

  @action
  attachDocumentEvents() {
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  @action
  detachDocumentEvents() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  delayHideOverlayTask = task({ restartable: true }, async () => {
    await timeout(duration(HIDE_OVERLAY_DELAY_DURATION));

    this.draggedDistance = 0;
    this.isDraggingOver = false;
  });
}
