import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class TagsController extends Controller {
  @service
  router;

  queryParams = ['id'];

  @tracked
  id = null;

  @action
  onVersionChange(id) {
    this.id = id;
  }
}
