import { attr, hasMany, SyncHasMany } from '@ember-data/model';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import type EnumsService from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';
import type EntityInterestTag from './entity-interest-tag';
import type InterestTagModel from './interest-tag';

export default class TaggableTokenModel extends BaseModel {
  @service declare enums: EnumsService;
  @service declare store: Store;

  @attr('string') declare item: string;
  @attr('number') declare interactionsCount: number;
  @attr('string') declare itemCategory: string;
  @attr('number') declare itemId: number;
  @attr('string') declare itemName: string;
  @attr('number') declare messageId: number;
  @attr('number') declare messageVersionId: number;
  @attr('string', { defaultValue: 'LinkableText' }) declare itemContentType: string;
  @attr('number') declare entityTypeId: number;
  @attr('number') declare entityId: number;

  @hasMany('entity-interest-tag', { async: false }) declare entityInterestTags: SyncHasMany<EntityInterestTag>;

  get name() {
    return this.itemName;
  }

  get icon() {
    return this.enums.findWhere('TOKEN_CONTENT_TYPE', { name: this.itemContentType }, 'materialIconKey') || 'link';
  }

  get interestTags() {
    return this.entityInterestTags.map(entityInterestTag => entityInterestTag.get('interestTag')?.content);
  }

  fetchInterestTags(): Promise<InterestTagModel[]> {
    return Promise.all(this.entityInterestTags.mapBy('interestTag'));
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'taggable-token': TaggableTokenModel;
  }
}
