/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
/* eslint-disable ember/no-global-jquery */ // FIXME
import { getOwner } from '@ember/application';
import Component from '@ember/component';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import ENV from 'partner/config/environment';
import { endpoint } from 'secondstreet-common/utils/url';

export default Component.extend({
  //region Dependencies
  tour: service(),
  confetti: service(),
  session: service(),
  //endregion

  //region Ember Hooks
  didRender() {
    this._super(...arguments);
    if (ENV.environment !== 'test') {
      return this.startJoyride();
    }
  },
  //endregion

  //region Methods
  async startJoyride() {
    const [LS_KEY, LS_VALUE] = ['organization-tour-complete', 'true'];
    const { tour } = this;

    if (get(tour, 'isActive')) {
      return;
    }

    set(tour, 'defaults', {
      classes: 'shepherd-element shepherd-open shepherd-theme-arrows',
      showCancelLink: false,
    });
    set(tour, 'modal', true);
    tour.on('complete', () => {
      window.scrollTo(0, 0);
      this.confetti.trigger('start');
      this._newPlatformJoyrideCompleted(true);
    });

    const scrollHandler = el => {
      const yOffset = $(el).offset().top - 275;
      window.scrollTo(0, yOffset);
    };

    const scrollHandlerTop = () => {
      window.scrollTo(0, 0);
      // Resize app nav to properly position joyride content
      setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
    };

    const steps = [
      //Step 1
      {
        id: 'dashboard-tab',
        attachTo: {
          element: '.side-nav-content-item--dashboard',
          on: 'right',
        },
        title: 'Welcome!',
        text: 'This is the engagement dashboard-- your home base for Upland Second Street.',
        buttons: [
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
      },
      //Step 2
      {
        id: 'recent-work',
        attachTo: {
          element: '.dashboard-user-history',
          on: 'top',
        },
        title: 'Recent Campaigns',
        text: 'Quickly access what you’ve been working on lately.',
        scrollTo: true,
        scrollToHandler: scrollHandler,
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
      },
      //Step 3
      {
        id: 'recent-campaigns',
        attachTo: {
          element: '.recently-engaging-stats',
          on: 'top',
        },
        title: 'Recent Engagement',
        text: 'Get an at-a-glance view of your active campaigns.',
        scrollTo: true,
        scrollToHandler: scrollHandler,
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
      },
      //Step 4
      {
        id: 'database',
        attachTo: {
          element: '.joyride-container__growth',
          on: 'top',
        },
        title: 'Database Growth',
        text: 'Keep track of your progress by looking at your database growth over time.',
        scrollTo: true,
        scrollToHandler: scrollHandler,
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
      },
      // //Step 5
      {
        id: 'demographics',
        attachTo: {
          element: '.joyride-container__demographics',
          on: 'top',
        },
        title: 'Demographics',
        text: 'Understand your audience demographics by looking at trends in their Age, Gender, and Location.',
        scrollTo: true,
        scrollToHandler: scrollHandler,
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
      },
      //Step 6
      {
        id: 'promotions-tab',
        attachTo: {
          element: '.side-nav-content-item--promotions',
          on: 'right',
        },
        title: 'Contests',
        text: 'Create sweepstakes and photo & video contests.',
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
      },
      //Step 7
      {
        id: 'interactive-content-tab',
        attachTo: {
          element: '.side-nav-content-item--interactiveContent',
          on: 'right',
        },
        title: 'Interactive Content',
        text: 'Create quizzes, ballots, voting brackets, surveys, polls, and galleries.',
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
      },
      //Step 8
      {
        id: 'email-tab',
        attachTo: {
          element: '.side-nav-content-item--email',
          on: 'right',
        },
        title: 'Email',
        text: 'Contact your audience with single emails, newsletters, birthday messages, and more.',
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
      },
      //Step 9
      {
        id: 'audiences-tab',
        attachTo: {
          element: '.side-nav-content-item--audiences',
          on: 'right',
        },
        title: 'Audiences',
        text: 'Create and manage segments of your database based on interests, demographics, and behavior.',
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
      },
      //Step 10
      {
        id: 'events-tab',
        attachTo: {
          element: '.side-nav-content-item--events',
          on: 'right',
        },
        title: 'Events',
        text: 'Invite people to sign up for virtual or in-person events.',
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
      },
      // Step 11 - Support Panel
      {
        id: 'support-panel',
        classes: 'organization-joyride__support-panel-step shepherd-element shepherd-open shepherd-theme-arrows',
        attachTo: {
          element: '.ss-support-toggle',
          on: 'bottom',
        },
        title: 'Feeling Stuck?',
        text: 'Search our help center, or submit a help ticket.',
        scrollTo: true,
        scrollToHandler: scrollHandlerTop,
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Done',
            type: 'next',
          },
        ],
      },
    ];

    // Don't show steps if the element isn't present.
    // This most often happens if the user's permissions are not sufficient to see things.
    await tour.addSteps(
      steps
        .map(step => {
          const el = document.querySelector(step.attachTo.element);
          return !el || el.style.display === 'none' ? undefined : step;
        })
        .compact()
    );

    if (
      window.localStorage.getItem(LS_KEY) === LS_VALUE &&
      !get(this.session, 'data.authenticated.is_new_platform_joyride_complete')
    ) {
      this._newPlatformJoyrideCompleted(true);
    } else if (!get(this.session, 'data.authenticated.is_new_platform_joyride_complete')) {
      tour.start();
    }
  },
  /**
   * Send an ajax call to /sessions to mark the joyride complete
   * @param {Boolean} bool
   */
  _newPlatformJoyrideCompleted(bool) {
    set(this.session, 'data.authenticated.is_new_platform_joyride_complete', bool);
    const json = JSON.stringify(get(this.session, 'data.authenticated'));

    return this.session.request(endpoint('sessions'), {
      type: 'PUT',
      data: `{"sessions":[${json}]}`,
      headers: get(getOwner(this).lookup('adapter:application'), 'headers'),
      contentType: 'application/json',
    });
  },
});
