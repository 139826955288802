/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal, gte, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import enums from 'ember-cli-ss-enums/services/enums';
import moment from 'moment';
import { computedRecurringWords, computedSchedulePrefix } from 'partner/utils/computed-schedule-date-summaries';
import dirtyProperty from 'partner/utils/dirty-property';
import BaseModel from 'secondstreet-common/models/base';

@makeBooleanProperties('MESSAGE_CAMPAIGN_TYPE')
export default class SingleMessageCampaign extends BaseModel {
  @service enums;

  @attr('number') organizationId;
  @attr('string') name;
  @attr('number') messageCampaignTypeId;
  @attr('number') statusTypeId;
  @attr('string') scheduleDescription;
  @attr('number') audienceMemberCount;
  @attr('string') additionalRecipients;
  @attr('string') externalId;
  @attr('number') scheduleId;
  @attr('number') scheduleTypeId;
  @attr('number') scheduleDelayDatepartTypeId;
  @attr('number') scheduleDelayValue;
  @attr('string', { defaultValue: '' }) scheduleRecurringPattern;
  @attr('date') scheduleStartDate;
  @attr('date-only') scheduleEndDate;
  @attr('string') scheduleStartTime;
  @attr('boolean') isScheduled;
  @attr('number') scheduleNumberOfRecurrences;
  @attr('number') messageId;
  @attr('number') messageCommunicationMediumTypeId;
  // This is meant to be a read-only property, so the serializer has it
  // configured not to serialize into the JSON for PUT and POST requests.
  @attr('number') messageSendingStatusTypeId;
  @attr('boolean') isConfirmed;
  @attr('number') messageTestPercentage;
  @attr('number') messageTestWinningCriteriaId;
  @attr('number') messageTestDurationOpenCount;
  @attr('number') messageTestDurationMinutes;
  @attr('number') displayOrder;
  @attr('boolean') messageIsApprovalRequired;
  @attr('string') messageApprovalRecipients;
  @attr('number') messageApprovalMinutesBeforeSend;
  @attr('boolean') sendNow;
  @attr('boolean') sendOnConfirm;
  @attr('number') sendNowOffset;
  @attr('boolean') sendToEngagedOnly;
  @attr('boolean') sendToUnregisteredOnly;

  @hasMany('message-version', { async: false }) messageVersions;
  @belongsTo('message-campaign-category', { async: false }) messageCampaignCategory;

  @computed('messageSendingStatusType')
  get isScheduledSentOrSending() {
    const types = ['Scheduled', 'Sent', 'Sending', 'SentAndScheduled', 'TestSend'];
    return types.indexOf(this.messageSendingStatusType) >= 0;
  }

  @computedRecurringWords(
    'scheduleRecurringPattern',
    'scheduleStartDate',
    'scheduleDelayDatepartTypeId',
    'scheduleDelayValue'
  )
  recurringWords;

  @computedSchedulePrefix('messageCampaignType', 'isSent') schedulePrefix;
  @enums.computed('name', 'messageCampaignTypeId') messageCampaignType;
  @enums.computed('name', 'scheduleRecurringDatepartTypeId', 'scheduleRecurringDatepartTypeId', 'DATEPART_TYPE')
  scheduleDelayDatepartType;
  @enums.computed('name', 'messageSendingStatusTypeId', 'messageSendingStatusTypeId', 'MESSAGE_SENDING_STATUS_TYPE')
  messageSendingStatusType;
  @dirtyProperty('messageTestPercentage') isMessageTestPercentageDirty;
  @dirtyProperty('isScheduled') isIsScheduledDirty;
  @dirtyProperty('messageTestDurationOpenCount') isMessageTestDurationOpenCountDirty;
  @dirtyProperty('messageTestDurationMinutes') isMessageTestDurationMinutesDirty;
  @dirtyProperty('messageTestWinningCriteriaId') isMessageTestWinningCriteriaIdDirty;
  @dirtyProperty('scheduleStartDate') isScheduleStartDateDirty;
  @enums.computed('name', 'messageTestWinningCriteriaId', 'messageTestWinningCriteriaId') messageTestWinningCriteria;
  @gte('audienceMemberCount', 1) hasRecipients;

  @computed('messageSendingStatusType', 'undoPhase')
  get hasStarted() {
    const status = this.messageSendingStatusType;
    return (status !== 'Incomplete' && status !== 'Scheduled') || this.undoPhase;
  }

  @equal('messageSendingStatusType', 'Sent') hasSent;
  @equal('messageSendingStatusType', 'Sending') isSending;

  @computed('scheduleStartDate')
  get formattedScheduleStartDate() {
    if (this.scheduleStartDate) {
      const date = moment(this.scheduleStartDate);
      return `${date.format('dddd, MMMM Do YYYY')} at ${date.format('hh:mma')}`;
    }
    return null;
  }

  @computed('additionalRecipients')
  get additionalRecipientsArray() {
    return isEmpty(this.additionalRecipients) ? [] : this.additionalRecipients.split(';');
  }

  @or('isNewsletter', 'isBirthday', 'isDripCampaign', 'isWeddingAnniversary', 'isReminder', 'isReceipt')
  noABTesting;

  /**
   * This is NOT the end of the entire campaign. It is the end of the A/B test period.
   */
  @computed('scheduleStartDate', 'messageTestDurationMinutes')
  get endOfABTestingDate() {
    const { scheduleStartDate } = this;
    if (scheduleStartDate) {
      const messageTestDurationMinutes = this.messageTestDurationMinutes || 0;
      return moment(scheduleStartDate).add(messageTestDurationMinutes, 'minutes').toDate();
    }
    return null;
  }

  /**
   * @property {Boolean}
   * This is set when you confirm a sendOnConfirm-type campaign, to temporarily disable the UI while undo is an option
   */
  undoPhase = false;

  @computed('errors.length')
  get hasScheduleStartDateErrors() {
    return this.errors.mapBy('attribute').includes('scheduleStartDate');
  }
}
