import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import type SettingsService from 'partner/services/settings';

interface Params {
  width: number;
  height: number;
  cropmode: 'fill' | 'fit';
  anchor: 'center';
}

export default class DipsUrlForHelper extends Helper {
  @service declare settings: SettingsService;

  compute([id]: [string], params: Partial<Params> = {}): string {
    const query = new URLSearchParams({
      width: `${(params.width || 1170) * this.dpi}`,
      height: `${(params.height || 1170) * this.dpi}`,
      cropmode: params.cropmode || 'fill',
      anchor: params.anchor || 'center',
      quality: '60',
      format: 'auto',
    });

    return `https://${this.dipsUrl}/${id}?${query.toString()}`;
  }

  private get dipsUrl(): string {
    return this.settings.getValueFor('dips_url');
  }

  private get dpi() {
    return Math.ceil(window.devicePixelRatio || 1);
  }
}
