/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { or } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import enums from 'ember-cli-ss-enums/services/enums';
import dirtyProperty from 'partner/utils/dirty-property';
import BaseModel from 'secondstreet-common/models/base';
import type MediaItemModel from './media-item';
import type PromotionModel from './promotion';
import type QuestionOptionOutcomeWeightModel from './question-option-outcome-weight';

export default class OutcomeModel extends BaseModel {
  @attr('string') declare name: string;
  @attr('string') declare description: string;
  @attr('number') declare outcomeTypeId: number;
  @attr('number') declare maxPoints: number; // TODO: We should calculate this on the client as well
  @attr('number') declare startPercent: number;
  @attr('number') declare endPercent: number;
  @attr('number') declare probabilityPercent: number;
  @attr('number') declare gameId: number;
  @attr('number') declare statusTypeId: number;

  @belongsTo('promotion', { async: false }) declare promotion: PromotionModel;
  @belongsTo('media-item', { async: false }) declare mediaItem: MediaItemModel;
  @hasMany('question-option-outcome-weight', { async: false })
  declare questionOptionOutcomeWeights: SyncHasMany<QuestionOptionOutcomeWeightModel>;

  @enums.computed('name', 'outcomeTypeId') declare outcomeType: string;
  @or('isStartPercentDirty', 'isEndPercentDirty') declare isPercentDirty: boolean;

  @computed('isStartPercentDirty', 'isEndPercentDirty', 'startPercent', 'endPercent')
  get percentStatus() {
    return !this.isStartPercentDirty &&
      !isEmpty(this.startPercent) &&
      !this.isEndPercentDirty &&
      !isEmpty(this.endPercent)
      ? 'bestPractice'
      : 'incomplete';
  }

  get detailsStatus() {
    return !this.isNameDirty && !isEmpty(this.name) ? 'bestPractice' : 'incomplete';
  }

  @or('isMediaItemDirty', 'isNameDirty', 'isDescriptionDirty') declare isDetailsDirty: boolean;
  @dirtyProperty('endPercent') declare isEndPercentDirty: boolean;
  @dirtyProperty('startPercent') declare isStartPercentDirty: boolean;
  @dirtyProperty('mediaItem') declare isMediaItemDirty: boolean;
  @dirtyProperty('name') declare isNameDirty: boolean;
  @dirtyProperty('description') declare isDescriptionDirty: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    outcome: OutcomeModel;
  }
}
