/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set, setProperties } from '@ember/object';
import { debounce, run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import ENV from 'partner/config/environment';
import { BREAKPOINTS } from 'partner/utils/constants';
import { split } from 'secondstreet-common/utils/array';
import { spreadIf } from 'secondstreet-common/utils/functional';

const PAGE_SIZE = 15;

export default Component.extend({
  //region Ember Hooks
  classNames: ['image-library'],

  async didInsertElement() {
    this._super();

    if (!this.unsplashApp) {
      const unsplashApp = await import('unsplash-js');
      if (!this.isDestroyed && !this.isDestroying) {
        run(() => this.safeSet('unsplash', unsplashApp));
      }
    }

    await this.loadOrganizationPhotos();
  },
  //endregion

  //region Attributes
  'close-library'() {},
  'set-image-by-url'() {},
  'set-image-by-dips'() {},
  'dips-url': null,
  'hide-unsplash': false,
  //endregion

  //region Dependencies
  store: service(),
  screen: service(),
  features: service(),
  //endregion

  //region Properties
  unsplashSearchInput: '',
  hasUnsplashError: false,
  cannotRequestMoreUnsplashPhotos: false,
  orgSearchInput: '',
  showSearch: false,
  loadingImages: false,
  loadingMoreImages: false,
  reachedLastPageOrgPhotos: false,
  reachedLastPageUnsplash: false,
  unsplashPageIndex: 1,
  orgPhotosPageIndex: 1,
  unsplashPhotos: null,
  organizationPhotos: null,
  viewingUnsplashLibrary: false,
  unsplash: null,
  //endregion

  //region Computed Properties
  hideUnsplash: computed('features.hasSecondStreetLibrary', 'hide-unsplash', function () {
    return this['hide-unsplash'] || !get(this, 'features.hasSecondStreetLibrary');
  }),
  /**
   * @property {Array}
   */
  columns: computed(
    'viewingUnsplashLibrary',
    'organizationPhotos.[]',
    'unsplashPhotos.[]',
    'screen.width',
    function () {
      const screenWidth = get(this, 'screen.width');
      const columnCount = screenWidth < BREAKPOINTS.MEDIUM ? 1 : screenWidth > BREAKPOINTS.LARGE ? 3 : 2;
      const photos = this.viewingUnsplashLibrary ? this.unsplashPhotos : this.organizationPhotos;

      return split(columnCount)(photos);
    }
  ),
  unsplashApp: computed('unsplash', function () {
    if (!this.unsplash) {
      return;
    }

    return this.unsplash.createApi({
      accessKey: ENV.unsplash.applicationId,
    });
  }),
  //endregion

  //region Hooks
  init() {
    this._super(...arguments);
    if (!this.organizationPhotos) {
      this.safeSet('organizationPhotos', []);
    }
    if (!this.unsplashPhotos) {
      this.safeSet('unsplashPhotos', []);
    }
  },
  //endregion

  //region Methods
  async searchUnsplash(searchValue) {
    run(() => {
      this.safeSet('unsplashPageIndex', 1);
      this.safeSet('loadingImages', true);
    });
    if (isEmpty(searchValue)) {
      try {
        setProperties(this, {
          cannotRequestMoreUnsplashPhotos: false,
          hasUnsplashError: false,
        });

        const photosResponse = await this.unsplashApp.photos.list();
        const photos = photosResponse.response.results;

        await this.preloadImages(photos, 'urls.thumb');

        run(() => this.safeSet('unsplashPhotos', photos));
      } catch (ex) {
        this.safeSet('hasUnsplashError', true);
      } finally {
        run(() => this.safeSet('loadingImages', false));
      }
    } else {
      try {
        setProperties(this, {
          cannotRequestMoreUnsplashPhotos: false,
          hasUnsplashError: false,
        });

        const photosResponse = await this.unsplashApp.search.getPhotos({
          query: searchValue,
          page: 1,
          perPage: PAGE_SIZE,
        });

        const photos = photosResponse.response.results;

        await this.preloadImages(photos, 'urls.thumb');

        run(() => this.safeSet('unsplashPhotos', photos));
      } catch (ex) {
        this.safeSet('hasUnsplashError', true);
      } finally {
        run(() => this.safeSet('loadingImages', false));
      }
    }
  },
  async preloadImages(photos, urlProperty) {
    try {
      const result = await Promise.all(
        photos.map(
          item =>
            new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = () => resolve(item);
              img.onerror = reject;
              img.src = item[urlProperty];
            })
        )
      );
      return result;
    } catch (e) {
      console.error(e);
    }
  },
  async loadOrganizationPhotos(searchValue = '') {
    run(() => this.safeSet('loadingImages', true));
    const orgPhotos = await this.store.query('media-item', {
      mediaTypeId: 1,
      pageIndex: 1,
      pageSize: PAGE_SIZE,
      ...spreadIf(isPresent(searchValue), { searchValue }),
    });
    await this.preloadImages(orgPhotos, 'dipsUrl');
    run(() => {
      this.safeSet('loadingImages', false);
      this.safeSet('organizationPhotos', orgPhotos.sortBy('id').reverse());
    });
  },
  requestNextPage() {
    if (this.viewingUnsplashLibrary && !this.reachedLastPageUnsplash) {
      this.requestNextUnsplashPage();
    } else if (!this.viewingUnsplashLibrary && !this.reachedLastPageOrgPhotos) {
      this.requestNextOrgPhotosPage();
    }
  },
  async requestNextUnsplashPage() {
    this.safeSet('loadingMoreImages', true);
    this.safeSet('unsplashPageIndex', this.unsplashPageIndex + 1);
    if (!this.unsplashSearchInput) {
      try {
        setProperties(this, {
          cannotRequestMoreUnsplashPhotos: false,
          hasUnsplashError: false,
        });

        const photosResponse = await this.unsplashApp.photos.list({
          page: this.unsplashPageIndex,
          perPage: PAGE_SIZE,
        });
        const photos = photosResponse.response.results;

        if (isPresent(photos)) {
          run(() => this.safeSet('unsplashPhotos', this.unsplashPhotos.concat(photos)));
        } else {
          run(() => this.safeSet('reachedLastPageUnsplash', true));
        }
      } catch (ex) {
        this.safeSet('cannotRequestMoreUnsplashPhotos', true);
        this.safeSet('reachedLastPageUnsplash', true);
      }
    } else {
      try {
        setProperties(this, {
          cannotRequestMoreUnsplashPhotos: false,
          hasUnsplashError: false,
        });

        const photosResponse = await this.unsplashApp.search.getPhotos({
          query: this.unsplashSearchInput,
          page: this.unsplashPageIndex,
          perPage: PAGE_SIZE,
        });
        const photos = photosResponse.response.results;

        if (isPresent(photos)) {
          run(() => this.safeSet('unsplashPhotos', this.unsplashPhotos.concat(photos)));
        } else {
          run(() => this.safeSet('reachedLastPageUnsplash', true));
        }
      } catch (ex) {
        this.safeSet('cannotRequestMoreUnsplashPhotos', true);
        this.safeSet('reachedLastPageUnsplash', true);
      }
    }
    run(() => this.safeSet('loadingMoreImages', false));
  },
  async requestNextOrgPhotosPage() {
    this.safeSet('loadingMoreImages', true);
    this.safeSet('orgPhotosPageIndex', this.orgPhotosPageIndex + 1);
    const hasSearch = isPresent(this.orgSearchInput);
    const orgPhotos = await this.store.query('media-item', {
      mediaTypeId: 1,
      pageIndex: this.orgPhotosPageIndex,
      pageSize: PAGE_SIZE,
      ...spreadIf(hasSearch, { searchValue: this.orgSearchInput }),
    });
    if (isPresent(orgPhotos.toArray())) {
      run(() => this.safeSet('organizationPhotos', this.organizationPhotos.concat(orgPhotos.sortBy('id').reverse())));
    } else {
      run(() => this.safeSet('reachedLastPageOrgPhotos', true));
    }
    run(() => this.safeSet('loadingMoreImages', false));
  },

  listPhotos() {},

  safeSet(property, value) {
    if (!this.isDestroyed && !this.isDestroying) {
      set(this, property, value);
    }
  },
  //endregion

  //region Actions
  actions: {
    scrolled() {
      const bottomOfGallery = document.querySelector('.image-library__contents').getBoundingClientRect().bottom;
      const bottomOfContainer = document
        .querySelector('.image-library__content-container')
        .getBoundingClientRect().bottom;

      if (bottomOfGallery < bottomOfContainer + 300) {
        // request more images once you've scrolled to the bottom.
        // 300 pixels provides a buffer so that the request for more images starts sooner than the user actually scrolling to the bottom.
        this.requestNextPage();
      }
    },

    updateSearch(input) {
      const searchValue = input.currentTarget.value;
      this.safeSet('orgSearchInput', searchValue);

      if (this.viewingUnsplashLibrary) {
        this.safeSet('reachedLastPageUnsplash', false);
        this.safeSet('unsplashPageIndex', 1);
        debounce(this, this.searchUnsplash, searchValue, 250);
      } else {
        this.safeSet('reachedLastPageOrgPhotos', false);
        this.safeSet('orgPhotosPageIndex', 1);
        debounce(this, this.loadOrganizationPhotos, searchValue, 250);
      }
    },

    triggerUnsplashDownload(photo) {
      // this pings Unsplash to let them and the artist know his or her art has been used. Required by their API guidelines.
      // https://medium.com/unsplash/unsplash-api-guidelines-triggering-a-download-c39b24e99e02
      try {
        this.unsplashApp.photos.trackDownload(photo);
      } catch (ex) {
        this.safeSet('hasUnsplashError', true);
      }
    },

    async loadCuratedContent() {
      this.safeSet('loadingImages', true);

      try {
        setProperties(this, {
          cannotRequestMoreUnsplashPhotos: false,
          hasUnsplashError: false,
        });

        const photosResponse = await this.unsplashApp.photos.list();
        const photos = photosResponse.response.results;

        await this.preloadImages(photos, 'urls.thumb');
        this.safeSet('unsplashPhotos', photos);
      } catch (ex) {
        this.safeSet('hasUnsplashError', true);
      } finally {
        this.safeSet('loadingImages', false);
      }
    },
  },
  //endregion
});
