/* eslint-disable ember/no-classic-classes, ember/use-ember-data-rfc-395-imports */
import { camelize } from '@ember/string';
import DS from 'ember-data';

export default DS.Transform.extend({
  /**
   * Instantiate a DS.Errors object and fill it.
   * @param serialized
   * @returns {DS.Errors}
   */
  deserialize(serialized) {
    serialized = Array.isArray(serialized) ? serialized : [];
    return serialized.map(error => ({
      property: camelize(error.property),
      message: error.message,
    }));
  },
  /**
   * Don't send errors back into the server
   * @param deserialized
   * @returns {undefined}
   */
  serialize() {
    return undefined;
  },
});
