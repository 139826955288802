/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed } from '@ember/object';

const icon = icon => `<i class="material-icons">${icon}</i>`;

export default Component.extend({
  //region Ember Hooks
  tagName: '',
  //endregion

  //region Attributes
  /**
   * @property {Function}
   */
  'toggle-list-content': () => {},
  /**
   * @property {Boolean}
   */
  'is-expanded': false,
  /**
   * @property {String}
   */
  'category-name': null,
  /**
   * @property {Number}
   */
  index: null,
  /**
   * @property {Boolean}
   */
  errors: false,
  /**
   * @property {Boolean}
   */
  dirty: false,
  /**
   * @property {Boolean}
   */
  complete: false,
  //endregion

  //region Computed Properties
  /**
   * @returns {String}
   * @param errors
   * @param complete
   * @param dirty
   */
  state: computed('errors', 'complete', 'dirty', function () {
    return this.errors ? 'invalid' : this.complete ? (this.dirty ? 'dirty' : 'complete') : 'incomplete';
  }),
  /**
   * @returns {String | Number}
   * @param state
   * @param index
   */
  icon: computed('state', 'index', function () {
    const { state } = this;
    const { index } = this;
    return state === 'invalid' ? icon('warning') : state === 'dirty' || state === 'complete' ? icon('done') : index;
  }),
  //endregion

  //region Actions
  actions: {
    toggleListContent() {
      this['toggle-list-content']();
    },
  },
  //endregion
});
