/* eslint-disable ember/no-computed-properties-in-native-classes */
import { attr } from '@ember-data/model';
import { isArray } from '@ember/array';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import cloneDeep from 'lodash/cloneDeep';
import BaseModel from 'secondstreet-common/models/base';
import { endpoint, queryString } from 'secondstreet-common/utils/url';

export default class EventChartModel extends BaseModel {
  @service('current') current;
  @service('session') session;

  @attr('string') name;
  @attr('date') startDate;
  @attr('date') endDate;
  @attr('number') eventSummaryRangeTypeId;

  @tracked categories;

  @computed('categories')
  get areCategoriesLoaded() {
    return isArray(this.categories);
  }

  @computed('areCategoriesLoaded', 'categories.@each.value')
  get areCategoriesLoadedAndEmpty() {
    return this.areCategoriesLoaded && !this.categories.any(category => category.value > 0);
  }

  @computed('categories.@each.value')
  get cumulativeCategories() {
    // back-end returns data points with value of zero, omit those data points from cumulative count since nothing changed
    const copied = cloneDeep(this.categories).filterBy('value');

    copied.forEach((cat, i, arr) => {
      if (i > 0) cat.value += arr[i - 1].value;
    });

    return copied;
  }

  async fetchCategories(extraParams = {}) {
    const params = {
      startDate: this.startDate ? this.startDate.toISOString() : null,
      endDate: this.endDate ? this.endDate.toISOString() : null,
      eventSummaryRangeTypeId: this.eventSummaryRangeTypeId,
      eventChartId: this.id,
      ...this.current.toSyndicatedParams(),
      ...extraParams,
    };

    const { event_chart_categories } = await this.session.request(
      `${endpoint('eventChartCategories')}?${queryString(params, { removeEmptyValues: true })}`
    );

    this.categories = event_chart_categories.map(category => ({
      time: new Date(category.start_date),
      value: category[this.eventChartValueProperty || 'count'],
      aggregate: category.aggregate,
    }));

    return this.categories;
  }
}
