/* eslint-disable ember/no-mixins, ember/no-new-mixins*/
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import AppContextRoute from 'partner/mixins/app-context-route';
import ResetScrollRoute from 'partner/mixins/reset-scroll-route';
import { get } from '@ember/object';

export default class AudienceCommonRoute extends Route.extend(AppContextRoute, ResetScrollRoute) {
  @service snackbar;
  @service store;
  @service router;

  queryParams = {
    statusTypeId: { refreshModel: true },
    pageIndex: { refreshModel: true },
    sortColumn: { refreshModel: true },
    sortDirection: { refreshModel: true },
    isSegmentedAudience: { refreshModel: true },
    searchValue: { refreshModel: true },
  };

  model(params) {
    if (!params.isSegmentedAudience && params.sortColumn == null && params.sortDirection == null) {
      params.sortColumn = 'isStarred';
      params.sortDirection = 'Desc';
    }
    const audiencesPromise = this.store.query('audience', {
      pageSize: 25,
      ...params,
    });

    const organizationFields = get(this.modelFor('organizations.organization.data.audiences'), 'fields');

    return audiencesPromise.then(result => ({
      audiences: result.toArray(),
      meta: result.meta,
      fields: organizationFields.toArray(),
    }));
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.showFilters = controller.sortColumn || controller.sortDirection || controller.searchValue;
  }
}
