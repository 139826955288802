/* eslint-disable ember/closure-actions, ember/no-new-mixins, ember/no-mixins, ember/no-get */
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

/**
 * Common logic for loading data needed by AudienceFilters
 */
export default Mixin.create({
  enums: service(),
  store: service(),

  /**
   * All the data needed to show AudienceFilters
   * @returns {*}
   */
  loadAudienceFilterData(loadAudiences = true) {
    const { organization } = this.modelFor('organizations.organization');
    const organizationId = organization.id;
    const { onlyHasInteractiveContentOrContests } = organization;
    const taggableEntityCategoryId = this.enums.findWhere('TAGGABLE_ENTITY_CATEGORY', {
      categoryName: 'Ecommerce',
    });

    // optins are not side loaded, so fetch them for performance
    const hash = {
      onlyHasInteractiveContentOrContests,
      fields: this.store.findAll('field'),
      organization,
      hasData: organization.hasData,
      organizationPromotions: this.store.query('organization-promotion', {
        organizationId,
      }),
    };

    if (loadAudiences) {
      hash.audiences = this.store.query('audience', { organizationId }).then(x => x.sortBy('name'));
    }

    return RSVP.hash(hash).then(hash => {
      //Need to load interest tags before loading the TaggableEntities
      if (onlyHasInteractiveContentOrContests) {
        return RSVP.hash(hash);
      }
      hash.offerTaggableEntities = this.store.query('taggableEntity', {
        organizationId,
        taggableEntityCategoryId,
        tier: 1,
      });
      return RSVP.hash(hash);
    });
  },
});
