/* global Redactor */

Redactor.add('plugin', 'tokens', {
  translations: {
    en: {
      tokens: {
        addToken: 'Token',
      },
    },
  },
  defaults: {
    tokens: [],
  },
  start() {
    if (this.opts.get('tokens.tokens').length) {
      this.app.addbar.add('tokens', {
        title: '## tokens.addToken ##',
        icon: '<i class="material-icons text-lg text-black leading-none">stars</i>',
        command: 'tokens.popup',
        position: {
          before: 'text',
        },
      });
    }
  },
  popup(e, button) {
    const items = this.opts
      .get('tokens.tokens')
      .filter(token => ('key' in token && token.key !== '') || 'templateTypeId' in token)
      .map(token => ({
        title: token.name,
        command: 'tokens.select',
        params: {
          name: token.name,
          token: token.token,
        },
      }));

    const dropdown = this.app.create('dropdown', 'tokens', {
      title: 'Add Token',
      width: '240px',
      items,
    });

    this.app.dropdown.open(e, button, dropdown);
  },
  select(params) {
    this.app.broadcast('tokens.changeDynamicTokenContent', {
      name: params.name,
      key: params.token.replace('{{', '').replace('}}', ''),
    });

    this.app.editor.insertContent({ html: params.token });

    this.app.dropdown.close();
  },
});
