/* eslint-disable ember/no-observers, ember/use-ember-get-and-set */ // FIXME
import { observer } from '@ember/object';
import { isEmpty } from '@ember/utils';

/**
 * Local storage observer factory
 * Generates an observer function that mirrors a property to local storage
 * @param {string} key - Key of property and local storage
 * @returns {function} - the observer
 */
export default function (key) {
  return observer(key, function () {
    const property = this.get(key);
    if (isEmpty(property)) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, property);
    }
  });
}
