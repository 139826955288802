/* eslint-disable ember/no-mixins */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import SetupQuizController from 'partner/controllers/organizations/organization/organization-promotions/organization-promotion/setup/quiz';
import PromotionTypeRoute from 'partner/mixins/promotion-type-route';
import CurrentService from 'partner/services/current';
import SettingsService from 'partner/services/settings';
import type { ModelFrom } from 'partner/utils/types';
import RSVP from 'rsvp';

export type SetupQuizModel = ModelFrom<SetupQuizRoute>;

export default class SetupQuizRoute extends Route.extend(PromotionTypeRoute) {
  @service declare current: CurrentService;
  @service declare settings: SettingsService;
  @service declare store: Store;

  declare controller: SetupQuizController;

  async model() {
    return RSVP.hash({
      questions: this.store.query('question', {}).then(x => x.toArray()),
      outcomes: this.store.query('outcome', {}).then(x => x.toArray()),
      promotion: this.current.promotion,
      _settings: this.settings.preload('dips_url'),
    });
  }

  /**
   * Check and then update the Quiz or Poll checklist status. Status based on the status of questions and outcomes.
   * This action should be called anytime questions or outcomes are modified.
   */
  @action
  updateCheckboxStatuses() {
    const isOutcomesCheckable =
      !this.current.promotion.isQuizPersonality || this.controller.outcomesStatus === 'bestPractice';
    const isQuestionsCheckable = this.controller.questionsStatus === 'bestPractice';

    if (isOutcomesCheckable && isQuestionsCheckable) {
      this.controller.send(
        'checkChecklistStep',
        'organizations.organization.organization-promotions.organization-promotion.setup.quiz'
      );
    } else {
      this.controller.send(
        'uncheckChecklistStep',
        'organizations.organization.organization-promotions.organization-promotion.setup.quiz'
      );
    }
  }
}

declare module '@ember/routing/route' {
  interface Registry {
    'organizations.organization.organization-promotions.organization-promotion.setup.quiz': SetupQuizRoute;
  }
}
