/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import moment from 'moment';

// for presets, first rounds are full durations (eg 24 full hours) whereas subsequent rounds are full durations minus one minute
const ROUND_DURATION_OFFSET = 60000;

export default Component.extend({
  //region Ember Hooks
  tagName: '',
  //endregion

  //region Computed Properties
  /**
   * The getter should just set the value at component instantialization, so no dependent keys
   * Once we turn this into a Glimmer component, move the setInitialDurationValue call to the constructor method
   */
  selectedDuration: computed({
    get() {
      return this.setInitialDurationValue();
    },
    set(key, value) {
      return value;
    },
  }),
  /**
   * View object of the next upcoming round (or first round if there are no votes yet)
   * @returns {startDate: Date, endDate: Date, hasError: Boolean, round: Number }
   * @type {Ember.ComputedProperty}
   */
  firstEditableRound: computed('rounds', 'numberOfVotes', function () {
    const now = new Date();
    if (this.numberOfVotes === 0) {
      return this.rounds.findBy('round', 1);
    }
    return this.rounds.find(round => now <= round.startDate);
  }),
  //endregion

  //region Methods
  setInitialDurationValue() {
    // Assume Custom Dates
    const customDuration = this.durationOptions.findBy('name', 'Set Manually');

    const now = new Date();
    const lastRound = this.rounds.lastObject;
    let startingRound;
    if (now < lastRound.startDate) {
      startingRound = this.firstEditableRound;
    } else if (now < lastRound.endDate) {
      return customDuration;
    } else {
      startingRound = this.rounds.firstObject;
    }

    // First Active Round Check - millisecond duration has to be one of the allowed durations
    const durationTestValue = startingRound.endDate - startingRound.startDate;
    const possiblePreset = this.durationOptions.findBy(
      'duration',
      startingRound.round === 1 ? durationTestValue : durationTestValue + ROUND_DURATION_OFFSET
    );

    if (!possiblePreset) {
      return customDuration;
    }

    // Subsequent Round Check - all subsequent rounds must be one minute less than the first round duration
    for (let i = startingRound.round; i < this.rounds.length; i++) {
      const roundDuration = this.rounds[i].endDate - this.rounds[i].startDate + ROUND_DURATION_OFFSET;
      if (roundDuration !== possiblePreset.duration) {
        return customDuration;
      }
    }
    return possiblePreset;
  },
  changeActiveRoundEndDate(selectedDuration) {
    const activeRoundStartDate = moment(this.firstEditableRound.startDate);
    let newRoundEndDate = activeRoundStartDate.add(selectedDuration.momentInterval, selectedDuration.momentValue);
    if (this.firstEditableRound.round > 1) {
      newRoundEndDate = newRoundEndDate.subtract(1, 'minute');
    }
    set(this.firstEditableRound, 'endDate', newRoundEndDate.toDate());
  },
  shiftFutureRoundDates(selectedDuration) {
    for (let i = this.firstEditableRound.round; i < this.rounds.length; i++) {
      set(
        this.rounds[i],
        'startDate',
        moment(this.rounds[i - 1].endDate)
          .add(1, 'minute')
          .toDate()
      );
      set(
        this.rounds[i],
        'endDate',
        moment(this.rounds[i].startDate)
          .add(selectedDuration.momentInterval, selectedDuration.momentValue)
          .subtract(1, 'minute')
          .toDate()
      );
    }
  },
  changeDateWithPreset(newDate) {
    const { selectedDuration } = this;
    set(this.firstEditableRound, 'startDate', newDate);
    this.changeActiveRoundEndDate(selectedDuration);
    this.shiftFutureRoundDates(selectedDuration);
  },
  delegateChangeDateCustom(round, dateType, newDate) {
    if (dateType === 'endDate') {
      this.changeEndDateCustom(round, newDate);
    }

    if (dateType === 'startDate') {
      this.changeStartDateCustom(round, newDate);
    }
  },
  changeStartDateCustom(round, newDate) {
    const previousRound = this.rounds.findBy('round', round.round - 1);
    const now = new Date();

    set(round, 'startDate', newDate);

    if (
      !previousRound ||
      now > previousRound.endDate ||
      newDate > previousRound.endDate ||
      newDate <= previousRound.startDate
    ) {
      return;
    }

    set(previousRound, 'endDate', moment(newDate).subtract(1, 'minute').toDate());
  },
  changeEndDateCustom(round, newDate) {
    const nextRound = this.rounds.findBy('round', round.round + 1);
    const delta = newDate - round.endDate;
    set(round, 'endDate', newDate);

    if (!nextRound || newDate < nextRound.startDate) {
      return;
    }

    for (let i = round.round; i < this.rounds.length; i++) {
      const oldStartDate = moment(this.rounds[i].startDate);
      const oldEndDate = moment(this.rounds[i].endDate);
      set(this.rounds[i], 'startDate', oldStartDate.add(delta).toDate());
      set(this.rounds[i], 'endDate', oldEndDate.add(delta).toDate());
    }
  },
  //endregion

  //region Actions
  actions: {
    delegateChangeDate(round, dateType, newDate) {
      const now = new Date();
      if (newDate < now) {
        set(round, `${dateType}TimeError`, true);
        return;
      }

      set(round, `${dateType}TimeError`, false);
      if (this.selectedDuration.name === 'Set Manually') {
        this.delegateChangeDateCustom(round, dateType, newDate);
      } else {
        this.changeDateWithPreset(newDate);
      }
      this.saveDates(this.rounds);
    },
    /**
     * Transforms dates in preset mode to selected durationOption object
     * @function actions:changeDuration
     * @param {Object} durationOption - durationOption object that will be used to modify dates
     * @returns {undefined}
     */
    changeDuration(selectedDuration) {
      this.rounds.forEach(round => {
        if (round.startDateTimeError) set(round, 'startDateTimeError', false);
        if (round.endDateTimeError) set(round, 'endDateTimeError', false);
      });

      // No dates are changed when switching to manual option
      set(this, 'selectedDuration', selectedDuration);
      if (selectedDuration.name === 'Set Manually') {
        return;
      }
      this.changeActiveRoundEndDate(selectedDuration);
      this.shiftFutureRoundDates(selectedDuration);
      this.saveDates(this.rounds);
    },
  },
  //endregion
});
