/* eslint-disable ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import moment from 'moment';

const { Transform } = DS;

export default Transform.extend({
  /**
   * @param {String} value
   * @returns {Date|String}
   */
  deserialize: value =>
    value !== undefined
      ? /^\d{4}-\d{2}-\d{2}/.test(value)
        ? moment(value).local().toDate()
        : value.toString()
      : undefined,
  /**
   * @param {Date|String} value
   * @returns {String}
   */
  serialize: value =>
    value ? (value instanceof Date ? moment(value).local().format('YYYY-MM-DD') : value) : undefined,
});
