/* eslint-disable ember/no-computed-properties-in-native-classes */
import { AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { makeBooleanProperties } from 'ember-cli-ss-enums/enums-decorator';
import enums from 'ember-cli-ss-enums/services/enums';
import BaseModel from 'secondstreet-common/models/base';
import type EntryModel from './entry';
import type MatchupModel from './matchup';

const SOCIAL_FIELDS = [
  'Facebook Url',
  'Twitter',
  'YouTube Channel',
  'Instagram Handle',
  'TikTok Handle',
  'SnapChat Handle',
  'LinkedIn Profile Url',
  'Vimeo Channel Url',
];

@makeBooleanProperties('ENTRY_FIELD', 'name', 'fieldId')
export default class EntryFieldValueModel extends BaseModel {
  @attr() declare value: number | string | boolean;
  @attr('number') declare fieldId: number;
  @attr('number') declare languageId: number;
  @attr('number') declare entryId: number;

  @belongsTo('entry', { async: false, inverse: 'entryFieldValues' }) declare entry: EntryModel;
  @belongsTo('matchup', { async: true }) declare matchup: AsyncBelongsTo<MatchupModel>;
  @belongsTo('field-option', { async: false }) fieldOption: any;

  @readOnly('matchup.id') declare matchupId: MatchupModel['id'];

  @computed('value', 'isEntrySourcePostDateCreated')
  get localDate() {
    return this.isEntrySourcePostDateCreated && (typeof this.value == 'string' || typeof this.value == 'number')
      ? new Date(this.value)
      : null;
  }

  @enums.computed('name', 'fieldId', 'entryFieldId', 'ENTRY_FIELD')
  declare fieldType: string;

  @computed('fieldType')
  get isSocialField() {
    return SOCIAL_FIELDS.includes(this.fieldType);
  }

  // props from makeBooleanProperties
  declare isEntrySourcePostDateCreated: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'entry-field-value': EntryFieldValueModel;
  }
}
