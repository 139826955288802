/* eslint-disable ember/closure-actions, ember/no-mixins, ember/no-jquery, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/require-tagless-components, ember/no-classic-components, ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import FormDesignerFormFieldContentsMixin from 'partner/mixins/form-designer-form-field-contents';

export default Component.extend(FormDesignerFormFieldContentsMixin, {
  sortedFieldOptions: computed('form-field.field.fieldOptions.@each.displayOrder', function () {
    return (get(this, 'form-field.field.fieldOptions') || []).sortBy('displayOrder');
  }),
});
