/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import BaseRoute from 'partner/routes/organizations/organization/organization-promotions/organization-promotion/setup/quiz/questions/base';
import RSVP from 'rsvp';

/**
 * Question Route
 * /o/:organization_id/op/:organization_promotion_id/setup/quiz/questions/:question_id
 * @type {Ember.Route}
 */
export default BaseRoute.extend({
  router: service(),
  store: service(),

  //region Ember Hooks
  model(params) {
    return RSVP.hash({
      question: this.store.find('question', params.question_id),
      outcomes: get(
        this.modelFor('organizations.organization.organization-promotions.organization-promotion.setup.quiz'),
        'outcomes'
      ),
      questions: get(
        this.modelFor('organizations.organization.organization-promotions.organization-promotion.setup.quiz'),
        'questions'
      ),
    });
  },
  resetController(controller) {
    set(controller, 'balance', false);
  },
  queryParams: {
    balance: {
      replace: true,
    },
  },
  //endregion

  //region Methods
  /**
   * Transition to a page for creating a new question
   */
  newQuestion() {
    if (this.isQuestionValid) {
      set(this, 'currentModel.question', null);
      this.router.transitionTo(
        'organizations.organization.organization-promotions.organization-promotion.setup.quiz.questions.new'
      );
    }
  },
  //endregion
});
