import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<TemplateDesigner::Controls::Wrapper>\n  <TemplateDesigner::Controls::Label @label=\"URL\" />\n  <TemplateDesigner::Controls::TextInput @onChange={{this.onURLChange}} @value={{@data.url}} />\n</TemplateDesigner::Controls::Wrapper>\n\n<TemplateDesigner::Controls::Wrapper>\n  <div class=\"flex gap-2 items-center\">\n    <Ui::Checkbox\n      @checked={{eq @data.target \"_blank\"}}\n      {{on \"click\" this.onTargetChange}}\n      class=\"hover:[&>i]:!text-primary {{if @data.newTab '[&>i]:!text-primary'}}\"\n    />\n    Open in new tab\n  </div>\n</TemplateDesigner::Controls::Wrapper>", {"contents":"<TemplateDesigner::Controls::Wrapper>\n  <TemplateDesigner::Controls::Label @label=\"URL\" />\n  <TemplateDesigner::Controls::TextInput @onChange={{this.onURLChange}} @value={{@data.url}} />\n</TemplateDesigner::Controls::Wrapper>\n\n<TemplateDesigner::Controls::Wrapper>\n  <div class=\"flex gap-2 items-center\">\n    <Ui::Checkbox\n      @checked={{eq @data.target \"_blank\"}}\n      {{on \"click\" this.onTargetChange}}\n      class=\"hover:[&>i]:!text-primary {{if @data.newTab '[&>i]:!text-primary'}}\"\n    />\n    Open in new tab\n  </div>\n</TemplateDesigner::Controls::Wrapper>","moduleName":"partner/components/template-designer/library/attributes/button-settings.hbs","parseOptions":{"srcName":"partner/components/template-designer/library/attributes/button-settings.hbs"}});
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type { ArcButtonBlockAttributes, SsArcAttributeSignature } from '@ss/arc/arc/types';
import type { ArcButtonBlock } from '@ss/arc/arc/blocks';
import { ensureProtocol } from 'secondstreet-common/utils/url';

export default class TemplateDesignerLibraryAttributesButtonSettingsComponent extends Component<SsArcAttributeSignature> {
  get block() {
    return this.args.blockUnit as ArcButtonBlock;
  }

  get data() {
    return this.args.data as ArcButtonBlockAttributes;
  }

  @action
  onURLChange({ target }: { target: HTMLInputElement }) {
    this.args.onDataChange('url', ensureProtocol(target.value)?.trim());
  }

  @action
  onTargetChange() {
    this.args.onDataChange('target', this.data.target == '_blank' ? '_parent' : '_blank');
  }
}
