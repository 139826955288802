/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ParamsType } from 'partner/services/preview';
import { statusText } from './ballot-group';
import { allEntrants, featuredEntrants, mainLayout } from './ballot-wide-scroll';
import { header } from './common';

const textCategory = (id: string) => {
  return {
    uniqueTypeNumber: '3',
    deletable: true,
    type: 'promotion-grid',
    blocks: [
      navButton({ id: '53', text: 'Previous', icon: 'keyboard_double_arrow_left', simple: true }),
      {
        uniqueTypeNumber: id,
        deletable: true,
        type: 'promotion-heading',
        blocks: [],
        data: {
          level: 1,
          text: 'Category',
          additionalCss: ``,
        },
        styles: {
          font: {
            color: '#000000',
            weight: 700,
            family: 'Open Sans',
            size: '18',
            align: 'center',
          },
        },
        dataMobile: {},
        stylesMobile: {},
        dataTablet: {},
        stylesTablet: {},
      },
      navButton({ id: '54', text: 'Next', icon: 'keyboard_double_arrow_right', reverse: true, simple: true }),
    ],
    data: {
      columns: '1:1:1',
      additionalCss:
        '.arc-promotion-promotion-0 .arc-promotion-grid-promotion-grid-3 {\n justify-items: center;  \n  }\n  \n  ',
    },
    styles: {
      layout: {
        display: 'grid',
        direction: 'row',
        gapX: '20',
        gapY: '20',
      },
      size: {},
      spacing: {
        paddingInline: 20,
      },
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

const navButton = ({
  id,
  text,
  icon,
  reverse,
  simple,
}: {
  id: string;
  text: string;
  icon: string;
  reverse?: boolean;
  simple?: boolean;
}) => {
  return {
    uniqueTypeNumber: id,
    deletable: true,
    type: 'promotion-button',
    blocks: [],
    data: {
      href: '',
      text: `<i class="material-icons text-lg">${icon}</i> <span class="inner-text">${text}<span>`,
      target: '_blank',
      url: '',
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-button-promotion-button-${id} span {\n display: flex;\n     align-items: center; ${
        reverse ? 'flex-direction: row-reverse' : ''
      } \n  }\n  \n  `,
    },
    styles: {
      border: {
        radius: 4,
        style: simple ? 'none' : 'solid',
        width: 2,
        color: '#4caf50',
      },
      spacing: {
        paddingBlock: 4,
        paddingInline: 64,
      },
      background: {
        type: 'color',
        color: '#ffffff',
      },
      size: {},
      font: {
        size: simple ? 14 : 16,
        color: simple ? '#6b7786' : '#4caf50',
        weight: simple ? 700 : 400,
      },
    },
    dataMobile: {
      additionalCss: `.arc-promotion-promotion-0 .arc-promotion-button-promotion-button-${id} .inner-text { display:none; }`,
    },
    stylesMobile: {
      spacing: {
        paddingBlock: 2,
        paddingInline: 16,
      },
    },
    dataTablet: {},
    stylesTablet: {},
  };
};

const navigationButtons = () => {
  return {
    uniqueTypeNumber: '35',
    deletable: true,
    type: 'promotion-box',
    blocks: [
      navButton({ id: '51', text: 'CATEGORY', icon: 'chevron_left' }),
      navButton({ id: '52', text: 'CATEGORY', icon: 'chevron_right', reverse: true }),
    ],
    data: {
      additionalCss: '.arc-promotion-promotion-0 .arc-box-box-35 {\n }\n  \n  ',
    },
    styles: {
      layout: {
        display: 'flex',
        direction: 'row',
        align: 'center',
        justify: 'center',
        gapX: 20,
        gapY: 20,
      },
      spacing: {},
    },
    dataMobile: {},
    stylesMobile: {},
    dataTablet: {},
    stylesTablet: {},
  };
};

export default (params?: ParamsType) => [
  header({
    title: 'Already Voted?',
    promotionColor: params?.promotionColor,
    promotionTextColor: params?.promotionTextColor,
  }),
  mainLayout([
    {
      uniqueTypeNumber: '25',
      deletable: true,
      type: 'promotion-box',
      blocks: [
        statusText({
          marginTop: 16,
        }),
        {
          uniqueTypeNumber: '26',
          deletable: true,
          type: 'promotion-box',
          blocks: [
            textCategory('21'),
            featuredEntrants({ gridColumnLayout: '1:1:1' }),
            allEntrants(),
            navigationButtons(),
          ],
          data: {
            additionalCss: '.arc-promotion-promotion-0 .arc-box-box-26 {\n\n  }\n  \n  ',
          },
          styles: {
            layout: {
              display: 'flex',
              direction: 'column',
              align: 'center',
              justify: 'center',
              gapX: 20,
              gapY: 20,
            },
            spacing: {},
          },
          dataMobile: {},
          stylesMobile: {},
          dataTablet: {},
          stylesTablet: {},
        },
      ],
      data: {
        additionalCss:
          '.arc-promotion-promotion-0 .arc-promotion-box-promotion-box-25 {\n padding-bottom: 20px; \n  }\n  \n  ',
      },
      styles: {
        layout: {
          display: 'flex',
          direction: 'column',
          align: 'center',
          justify: 'center',
          gapX: 20,
          gapY: 20,
        },
        spacing: {
          paddingInline: 16,
        },
      },
      dataMobile: {},
      stylesMobile: {},
      dataTablet: {},
      stylesTablet: {},
    },
  ]),
];
