import type { default as Store, Snapshot } from '@ember-data/store';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import { ModelSchema } from 'ember-data';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type ModelRegistry from 'ember-data/types/registries/model';
import type MatchupEntryModel from 'partner/models/matchup-entry';
import type SentryService from 'partner/services/sentry';
import type RSVP from 'rsvp';
import type LoggerService from 'secondstreet-common/services/logger';
import ApplicationAdapter from './application';

type Result = {
  matchup_entries: [
    {
      id: number;
      matchup_id: number;
      entries: {
        id: number;
        entry_field_values: {
          id: number;
          field_id: number;
          value: string;
        }[];
      };
    }
  ];
};

export default class MatchupEntryAdapter extends ApplicationAdapter {
  @service declare logger: LoggerService;
  @service declare sentry: SentryService;

  createRecord<K extends keyof ModelRegistry = 'matchup-entry'>(
    store: Store,
    type: ModelSchema<K>,
    snapshot: Snapshot<K>
  ): RSVP.Promise<any> {
    return super.createRecord(store, type, snapshot).then((result: Result) => {
      this.unloadPreviousRelationshipsFor(snapshot.record as MatchupEntryModel);

      return result;
    });
  }

  updateRecord<K extends keyof ModelRegistry = 'matchup-entry'>(
    store: Store,
    type: ModelSchema<K>,
    snapshot: Snapshot<K>
  ): RSVP.Promise<any> {
    return super.updateRecord(store, type, snapshot).then((result: Result) => {
      this.unloadPreviousRelationshipsFor(snapshot.record as MatchupEntryModel);

      return result;
    });
  }

  /**
   * Ember Data cannot handle cases when new relationships are create in the same request,
   * so it keeps the old ones in memory as "new" ones (without an id).
   * We need to unload the "entry" as well as the backend returns a new address,
   * which keeps the existing record in dirty state.
   */
  private unloadPreviousRelationshipsFor(matchupEntry: MatchupEntryModel) {
    if (!matchupEntry) return;

    try {
      matchupEntry.entryLocations.filterBy('isNew').forEach(entryLocation => entryLocation.unloadRecord());

      if (matchupEntry.entry) {
        matchupEntry.entry.entryFieldValues
          .toArray()
          // Create a copy as unloadRecord modifies the array
          // another possible solution might be to use the `result`
          // and compare to remove the ones that are not in the result
          .slice()
          .forEach(entryFieldValue => entryFieldValue?.unloadRecord());

        if (matchupEntry.entry.get('hasDirtyAttributes')) {
          matchupEntry.entry.unloadRecord();
        }
      }
    } catch (e) {
      this.sentry.captureException(e);
      this.logger.error(e);
    }
  }
}
