/* eslint-disable ember/no-mixins, ember/no-get, ember/no-observers, ember/no-classic-classes, ember/no-actions-hash */
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { mapBy, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import HasCategoricalCharts from 'partner/mixins/has-categorical-charts';
import HasEventCharts from 'partner/mixins/has-event-charts';
import NeedsOrganizationPromotionController from 'partner/mixins/needs-organization-promotion-controller';
import TickTock from 'partner/mixins/tick-tock';
import { emptyTimeSeriesData } from 'partner/utils/charts';
import { firstByProperty } from 'partner/utils/computed';

/**
 * Dashboard UGC Voting Controller
 * /o/:organization_id/op/:organization_promotion_id/dashboard/ugc-voting
 * @type {Ember.Controller}
 */
export default Controller.extend(HasCategoricalCharts, HasEventCharts, TickTock, NeedsOrganizationPromotionController, {
  //region Properties
  selectedRound: null,
  limitData: true,
  currentSweepsWinnerPage: 1,
  winnersCardTransition: 'toLeft',
  //endregion

  //region Dependencies
  permissions: service(),
  //endregion

  //region Computed Properties
  contestSelectionStartDate: readOnly('model.matchups.firstObject.selectionStartDate'),
  contestSelectionEndDate: readOnly('model.matchups.lastObject.selectionEndDate'),
  numEligibleVotes: readOnly('organizationPromotion.numEligibleVotes'),
  numEligibleUsers: readOnly('organizationPromotion.numEligibleUsers'),
  totalEntries: readOnly('organizationPromotion.kpi'),
  uniqueEntries: readOnly('model.organizationPromotion.numUniquePeopleWithEnteries'),
  numUniqueVotes: readOnly('model.organizationPromotion.numUniquePeopleWithVotes'),
  contestStartDate: readOnly('model.matchups.firstObject.startDate'),
  contestEndDate: readOnly('model.matchups.lastObject.endDate'),
  votesEmptyStateText: computed('contestStartDate', 'tock', function () {
    return this.contestStartDate > this.tock ? 'Waiting for Voting to Start' : 'Waiting for Votes';
  }),
  emptyTimeSeriesData: computed(() => emptyTimeSeriesData(10)()),
  votesChart: firstByProperty('name', 'Vote', 'model.eventCharts'),
  entriesChart: firstByProperty('name', 'Entry', 'model.eventCharts'),
  votesChartProperties: computed(
    'votesChart.{areCategoriesLoadedAndEmpty,cumulativeCategories}',
    'emptyTimeSeriesData',
    function () {
      const loadedAndEmpty = this.votesChart.areCategoriesLoadedAndEmpty;
      const { cumulativeCategories } = this.votesChart;
      const { emptyTimeSeriesData } = this;
      return this.getChartProperties(loadedAndEmpty, cumulativeCategories, emptyTimeSeriesData);
    }
  ),
  finalMatchup: computed('model.matchups.@each.iteration', function () {
    return this.model.matchups.sortBy('iteration').lastObject;
  }),
  winnerMatchups: mapBy('model.winners', 'matchup'),
  noUCGVotingWinner: computed('finalMatchup.id', 'winnerMatchups.@each.id', 'tock', 'contestEndDate', function () {
    const winnerMatchupIds = this.winnerMatchups.mapBy('id');
    return this.contestEndDate > this.tock || !winnerMatchupIds.includes(this.finalMatchup.id);
  }),
  hasUCGVotingEnded: computed('contestEndDate', function () {
    return this.contestEndDate <= new Date();
  }),
  hasUCGVotingStarted: computed('contestStartDate', function () {
    return this.contestStartDate <= new Date();
  }),
  winnersCardEmptyStateMessage: computed('tock', 'contestStartDate', function () {
    return this.contestStartDate > this.tock ? 'Voting has not started yet.' : 'Voting is in progress.';
  }),

  sweepsCardWinnersPagingInfo: computed('currentSweepsWinnerPage', 'model.sweepstakesWinners.length', function () {
    const numSweepsWinners = this.model.sweepstakesWinners.length;
    return numSweepsWinners > 1 ? `(${this.currentSweepsWinnerPage} of ${numSweepsWinners})` : false;
  }),
  //endregion

  //region Methods
  getChartProperties(loadedAndEmpty, cumulativeCategories, emptyTimeSeriesData) {
    return {
      data: loadedAndEmpty ? emptyTimeSeriesData : cumulativeCategories,
      color: loadedAndEmpty ? 'rgb(172,172,172)' : 'rgb(44, 121, 229)',
    };
  },
  //endregion
});
