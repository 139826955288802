/* eslint-disable ember/use-ember-data-rfc-395-imports, ember/no-mixins, ember/no-get, ember/no-classic-classes */
import { computed, set } from '@ember/object';
import DS from 'ember-data';
import BaseModel from 'secondstreet-common/models/base';

const { attr, hasMany } = DS;

export default BaseModel.extend({
  //region Properties
  endDate: attr('date'),
  entriesPerMatchupMax: attr('number'),
  entriesPerMatchupMin: attr('number'),
  entrySchemaId: attr('number'),
  gameTypeId: attr('number'),
  isEnabled: attr('boolean'),
  isPrimary: attr('boolean'),
  iterations: attr('number'),
  matchupsPerIterationMax: attr('number'),
  matchupsPerIterationMin: attr('number'),
  startDate: attr('date'),
  //endregion

  //region Relationships
  matchups: hasMany(),
  //endregion

  //region Computed Properties
  /**
   * Proxy property that will allow us to display a blank value to indicate "no limit"
   * on the interface instead of a "-1" which could confuse users
   */
  displayEntriesPerMatchupMax: computed('entriesPerMatchupMax', {
    get() {
      return this.entriesPerMatchupMax === -1 ? null : this.entriesPerMatchupMax;
    },
    set(key, value) {
      set(this, 'entriesPerMatchupMax', value);
      return value;
    },
  }),
  //endregion
});
